import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, notification } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { CaseNote } from 'interfaces/caseNote';

import { HeadingTemplate } from 'components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';

import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { deleteNote, postCaseNote, putCaseNote } from 'utils/http/DocumentService/CaseNotes/caseNotes';
import { downloadClientCaseNoteWithAttachments } from 'utils/http/UtilityService/downloadCaseNote';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import Button from 'components/v2/Button/Button';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PatientDetailsNoteDetailsForm from './components/PatientDetailsNoteDetailsForm/PatientDetailsNoteDetailsForm';
import PatientDetailsNoteDetailsDisplay from './components/PatientDetailsNoteDetailsDisplay/PatientDetailsNoteDetailsDisplay';

import styles from './PatientDetailsNoteDetails.module.scss';
import { CaseNoteFormatSettings } from '../../PatientDetailsNotes';
import { safeGuardingName } from '../PatientDetailsNoteFormatSettingsModal/PatientDetailsNoteFormatSettingsModal';

const IS_DEVELOPMENT = isDevelopmentENV();

interface PatientDetailsNoteDetailsProps {
  recordId: string;
  profileId: string;
  caseNoteData: CaseNote;
  caseNoteHeadingTemplates: HeadingTemplate[];
  token: string;
  isEditing: boolean;
  isReadOnly: boolean;
  isClosedClient?: boolean;
  formatSettings: CaseNoteFormatSettings[];
  onDeleteNote: () => void;
  onValueChange: (b: boolean) => void;
  onDeleteTemplate: (headingTemplateId: string) => void;
  onEditNoteClick: () => void;
  onSubmitTemplate: (newHeadingTemplate: HeadingTemplate) => void;
  onUpdateNoteDetails: (newNote: CaseNote, isDraftSave: boolean) => void;
}

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const reconstructCaseNote = (caseNoteNode: Node): Document => {
  const newDocument = new Document();
  const newHTML = newDocument.createElement('html');
  const newHead = document.head.cloneNode(true);
  const newBody = newDocument.createElement('body');
  newBody.setAttribute('style', 'padding: 20px');
  newBody.appendChild(caseNoteNode.cloneNode(true));

  newHTML.appendChild(newHead);
  newHTML.appendChild(newBody);
  newDocument.appendChild(newHTML);
  const styleSheets = newDocument.querySelectorAll("link[rel='stylesheet' i]");

  styleSheets.forEach((styleSheet) => {
    const extractedHref = styleSheet.getAttribute('href');
    if (extractedHref && !isValidUrl(extractedHref)) {
      styleSheet.setAttribute('href', `${document.location.origin}${extractedHref}`);
    }
  });

  return newDocument;
};

const downloadCaseNote = (file: Blob, fileName: string): void => {
  const hiddenLink = document.createElement('a'); // ToDo: Is this really hidden??
  hiddenLink.href = window.URL.createObjectURL(file);
  hiddenLink.download = `${fileName}.zip`;
  hiddenLink.click();
  hiddenLink.remove();
};

const PatientDetailsNoteDetails = ({
  recordId,
  profileId,
  caseNoteData,
  caseNoteHeadingTemplates,
  token,
  isEditing,
  isReadOnly,
  isClosedClient,
  onValueChange,
  onDeleteNote,
  onDeleteTemplate,
  onEditNoteClick,
  onSubmitTemplate,
  onUpdateNoteDetails,
  formatSettings
}: PatientDetailsNoteDetailsProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const { getAccessTokenSilently } = useAuth0();
  const path = useParams() as { assessmentOrReportId: string };
  const getAccessToken = () => getAccessTokenSilently({ audience: process.env.REACT_APP_API_AUDIENCE });
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [caseNoteNode, setCaseNoteNode] = useState<Node | null>(null);
  const [isCaseNoteDownloading, setIsCaseNoteDownloading] = useState<boolean>(false);
  const [caseNoteDetails, setCaseNoteDetails] = useState(caseNoteData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [caseNoteId, setCaseNoteId] = useState(path.assessmentOrReportId);

  const caseNoteRef = useCallback((renderedNode) => {
    if (renderedNode) {
      setCaseNoteNode(renderedNode);
    }
  }, []);

  const checkingSafeGuarding = formatSettings.map((formatSettingsObj) =>
    formatSettingsObj.fields.find((fieldObj) => fieldObj.name === safeGuardingName)
  );
  const safeguardingAlertExistInFormatSettings = checkingSafeGuarding.length > 0;

  useEffect(() => {
    setCaseNoteDetails(caseNoteData);
    setCaseNoteId(caseNoteData._id);
  }, [caseNoteData]);

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const token = await getAccessToken();
      await deleteNote(token, recordId, caseNoteId);

      onDeleteNote();
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to delete this case note' });
    }

    setIsDeleting(false);
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this note?',
      onOk: handleDelete
    });
  };

  let isRunning = false;
  const handleSaveFunc = async (values: CaseNote, triggerBtnSave: boolean) => {
    if (!isRunning) {
      const token = await getAccessToken();
      isRunning = true;
      setIsSubmitting(true);
      triggerBtnSave && setSaveButtonStatus('active');

      const bodyPayload = {
        title: values.title,
        fields: values.fields,
        body: values.body,
        attachments: values.attachments,
        safeguardingAlert: values.safeguardingAlert,
        safeguardingReason: values.safeguardingReason
      };

      if (caseNoteId === 'new') {
        try {
          const callPostCaseNote = await postCaseNote(token, recordId, bodyPayload);
          const createdCaseNote = await callPostCaseNote.json();

          setCaseNoteId(createdCaseNote._id);
          setCaseNoteDetails(createdCaseNote);
          onUpdateNoteDetails(createdCaseNote, !triggerBtnSave);
          setIsSubmitting(false);
          onValueChange(false);
          if (triggerBtnSave) {
            setSaveButtonStatus('finished');
            setTimeout(() => setSaveButtonStatus(''), 2000);
          } else {
            navigate(`${CLIENTS.BASE}/${recordId}/notes/${createdCaseNote._id}`);
          }
        } catch (ex) {
          triggerBtnSave && setSaveButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to save your note.' });
        }
      } else {
        try {
          await putCaseNote(token, recordId, caseNoteId, bodyPayload);
          const massageValueWithUpdatedTime = {
            ...values,
            _id: caseNoteId,
            updatedAt: moment().format()
          };
          onUpdateNoteDetails(massageValueWithUpdatedTime, !triggerBtnSave);
          setIsSubmitting(false);
          onValueChange(false);
          if (triggerBtnSave) {
            setSaveButtonStatus('finished');
            setTimeout(() => setSaveButtonStatus(''), 2000);
          }
        } catch (ex) {
          console.error(ex);
          triggerBtnSave && setSaveButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to update your note.' });
        }
      }

      isRunning = false;
    }
  };

  const handleSubmit = async (values: CaseNote, draftSave: boolean) => {
    if (!isSubmitting) {
      await handleSaveFunc(values, !draftSave);
    }
  };

  const onDownloadNoteClick = async (fileName: string, caseNoteNode: Node | null) => {
    if (caseNoteNode) {
      setIsCaseNoteDownloading(true);

      try {
        const token = await getAccessToken();
        const htmlNode = reconstructCaseNote(caseNoteNode);
        const retrievedPDF = await downloadClientCaseNoteWithAttachments(token, recordId, caseNoteId, htmlNode);
        downloadCaseNote(retrievedPDF, fileName);
      } catch (ex) {
        console.error(ex);

        notification.error({ message: 'Something went wrong while trying to download your note' });
      }

      setIsCaseNoteDownloading(false);
    }
  };

  return (
    <div className={styles.container}>
      {isDeleting && (
        <div className={styles.deleting}>
          <LoadingDot />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.createdAt}>
          <div className={styles.label}>CREATED:</div>
          <div className={styles.value}>{moment(caseNoteDetails.createdAt).format('DD/MM/YYYY hh:mm A')}</div>
        </div>
        {caseNoteDetails.updatedAt !== caseNoteDetails.createdAt && caseNoteId !== 'new' && (
          <div className={styles.updatedAt}>
            <div className={styles.label}>LAST EDIT:</div>
            <div className={styles.value}>{moment(caseNoteDetails.updatedAt).format('DD/MM/YYYY hh:mm A')}</div>
          </div>
        )}
        {isEditing && caseNoteId !== 'new' && (
          <Button className={styles.deleteNoteButton} onClick={handleClickDelete}>
            DELETE NOTE
          </Button>
        )}
      </div>
      {isEditing ? (
        <PatientDetailsNoteDetailsForm
          recordId={recordId}
          profileId={profileId}
          noteId={caseNoteId}
          isSubmitting={isSubmitting}
          formData={caseNoteDetails}
          saveButtonStatus={saveButtonStatus}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
          caseNoteHeadingTemplates={caseNoteHeadingTemplates}
          token={token}
          onDeleteTemplate={onDeleteTemplate}
          onSubmitTemplate={onSubmitTemplate}
          safeguardingAlertExistInFormatSettings={safeguardingAlertExistInFormatSettings}
        />
      ) : (
        <div className={styles.noteDetails}>
          <div ref={caseNoteRef}>
            <PatientDetailsNoteDetailsDisplay
              recordId={recordId}
              data={caseNoteDetails}
              showSafeguardingAlert={Boolean(
                (safeguardingAlertExistInFormatSettings &&
                  caseNoteDetails.fields.find((field) => field.name === safeGuardingName)) ||
                  !safeguardingAlertExistInFormatSettings
              )}
            />
          </div>
          <div className={styles.buttonContainer}>
            {!isReadOnly && (
              <Button
                className={styles.editButton}
                variant="secondary"
                onClick={() => isClosedClient || onEditNoteClick()}
                disabled={isClosedClient}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>create</i>
                Edit this note
              </Button>
            )}
            <div className={styles.rightButtons}>
              <Button
                className={styles.downloadButton}
                activeClassName={styles.downloadActive}
                variant="secondary"
                disabled={isCaseNoteDownloading}
                onClick={() => {
                  onDownloadNoteClick(caseNoteDetails.title, caseNoteNode);
                }}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>download</i>
                {isCaseNoteDownloading ? 'Downloading...' : 'Download Note'}
              </Button>
              {IS_DEVELOPMENT && (
                <Button className={styles.shareButton} variant="primary" disabled={isClosedClient}>
                  Share Case Note
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsNoteDetails;
