import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import { Formik } from 'formik';
import { AppointmentType, ParticipantType, RateType } from 'interfaces/Schedule/AppointmentType';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import { memo } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './AppointmentTypeForm.module.scss';
import AppointmentDescription from './components/AppointmentDescription/AppointmentDescription';
import AppointmentTypeName from './components/AppointmentTypeName/AppointmentTypeName';
import Assignments from './components/Assignments/Assignments';
import BookingRules from './components/BookingRules/BookingRules';
import Duration from './components/Duration/Duration';
import PaymentRequired from './components/PaymentRequired/PaymentRequired';
import Rate from './components/Rate/Rate';
import { appointmentTypeSchema, initialValues, initialValuesUpdate } from './constants';
import AppointmentParticipantType from './components/AppointmentParticipantType/AppointmentParticipantType';
import { useTranslation } from 'react-i18next';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';

interface AppointmentTypeFormProps {
  data?: AppointmentType;
  onSubmit: (values: Partial<AppointmentType>) => void;
}

const AppointmentTypeForm = ({ data, onSubmit }: AppointmentTypeFormProps) => {
  const { isEdgeUser, isEdgeAdminView } = useGetAccountPackageView();
  const { isGroupsFeatureToggle } = useGetFeatureToggle();
  const initialUpdate = data && initialValuesUpdate(data);
  const [t] = useTranslation();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();
  const { token } = useGetAccessToken();
  const { paymentMethods, isPaymentMethodsLoading } = useFetchPaymentMethods(token);

  const handleSubmit = (values: typeof initialValues) => {
    const payload = {
      ...values,
      rate: Number(values.rate),
      rateType: values.participantType === ParticipantType.OneToOne ? RateType.PerSession : values.rateType,
      duration: values.duration === 'custom' ? Number(values.customDuration) : Number(values.duration)
    };

    onSubmit(payload);
  };

  return (
    <Formik
      initialValues={initialUpdate || initialValues}
      validationSchema={appointmentTypeSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ submitForm, values }) => (
        <div className={styles.container}>
          <div className={styles.infoRow}>
            {isPaymentMethodsLoading ? (
              <LoadingDot />
            ) : (
              <>
                <div className={styles.name}>
                  <AppointmentTypeName />
                </div>
                <AppointmentDescription />
                {isEdgeUser && isGroupsFeatureToggle && <AppointmentParticipantType disabled={!!data} />}
                {values.participantType === ParticipantType.Group ? (
                  <div className={styles.groupSubInfo}>
                    <Duration />
                    {!isAppointmentRateReadDenied && <Rate vertical rateTypeQuestion />}
                  </div>
                ) : (
                  <>
                    <div className={styles.subInfoRow}>
                      <Duration />
                      {!isAppointmentRateReadDenied && <Rate />}
                    </div>
                    {isEdgeAdminView && <Assignments />}
                    <BookingRules />
                    {paymentMethods.some(
                      ({ paymentType, stripeStatus }) => paymentType === 'stripe' && stripeStatus === 'completed'
                    ) && <PaymentRequired />}
                  </>
                )}
                <div className={styles.footer}>
                  <Button onClick={submitForm}>Next to set up schedule</Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default memo(AppointmentTypeForm);
