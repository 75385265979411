import { MouseEvent } from 'react';
import styles from './ClientSelected.module.scss';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { PatientAssessment } from 'pages/Assessments/components/SendAdhocAssessmentModal/interfaces';
import { canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import classnames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { getName } from 'utils/general';
import { Dropdown, Menu } from 'antd';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useTranslation } from 'react-i18next';

interface ClientSelectedProps {
  patientAssessment: PatientAssessment;
  participationType?: ParticipantType;
  isShowCheckBox?: boolean;
  proxyProfiles?: PatientAssessment[];
  selectedProxyProfileId?: string;
  onSelectClientProfile?: (isSelected: boolean, clientId: string) => void;
  handleProxyProfileChange?: (value: string) => void;
}

const ClientSelected = ({
  patientAssessment,
  isShowCheckBox,
  proxyProfiles,
  selectedProxyProfileId,
  onSelectClientProfile,
  handleProxyProfileChange
}: ClientSelectedProps) => {
  const { client, isSelected } = patientAssessment;
  const isSmsCommsAllowed = client.role === 'child' || canClientReceiveSms(client.communicationPreference);
  const [t] = useTranslation();

  const handleProxyProfileClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      className={classnames(styles.profileWrapper, !isSmsCommsAllowed && styles.disableProfileWrapper)}
      onClick={
        isSmsCommsAllowed ? () => onSelectClientProfile && onSelectClientProfile(!isSelected, client._id) : undefined
      }
    >
      {isShowCheckBox && (
        <CheckBox
          id={`${client._id}_profile`}
          value={isSelected}
          onChange={(e) => onSelectClientProfile && onSelectClientProfile(e.target.checked, client._id)}
          disabled={!isSmsCommsAllowed}
        />
      )}
      <div className={styles.profile}>
        <ClientProfileAvatar
          avatarUrl={client.avatar}
          initialsName={client.initials}
          initialClassName={styles.initialAvatar}
        />
      </div>
      <div className={styles.description}>
        <div className={styles.name}>{patientAssessment.client && getName(patientAssessment.client)}</div>
        {patientAssessment?.client.role === 'child' && proxyProfiles && (
          <div onClick={(e) => handleProxyProfileClick(e)} className={styles.note}>
            (
            <Dropdown
              className={styles.proxyProfilesDropdown}
              overlay={
                <Menu
                  selectedKeys={proxyProfiles.map(({ client }) => client.name)}
                  onClick={({ key }) => handleProxyProfileChange && handleProxyProfileChange(key as string)}
                >
                  {proxyProfiles.map(({ client }) => (
                    <Menu.Item key={client._id}>
                      <div>{client.name}</div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                {proxyProfiles.find(({ client }) => client._id === selectedProxyProfileId)?.client.name}{' '}
                <DownOutlined />
              </div>
            </Dropdown>
            will receive the request)
          </div>
        )}
      </div>
      {!isSmsCommsAllowed && <HelpOutLineWithTooltips id={`noAllowAdd`} desc={t('form.disallowed_sms.description')} />}
    </div>
  );
};

export default ClientSelected;
