import { useState } from 'react';
import CreateAppointmentTypeModal from '../CreateAppointmentTypeModal/CreateAppointmentTypeModal';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './AppointmentTypeCard.module.scss';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import BookablePractitioner from './components/BookablePractitioner/BookablePractitioner';
import SessionMode from './components/SessionMode/SessionMode';
import SessionSettings from './components/SessionSettings/SessionSettings';
import SessionAvailableDate from './components/SessionAvailableDate/SessionAvailableDate';
import SessionParticipantType from './components/SessionParticipantType/SessionParticipantType';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface AppointmentTypeCardProps {
  appointmentType: AppointmentType;
  fetchAppointmentTypes: () => void;
}

const AppointmentTypeCard = ({ appointmentType, fetchAppointmentTypes }: AppointmentTypeCardProps) => {
  const [showAppointmentTypeModal, setShowAppointmentTypeModal] = useState(false);
  const { isEdgeUser, isEdgeUserView } = useGetAccountPackageView();
  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  return (
    <>
      <div
        className={`${isEdgeUserView ? styles.appointmentTypeCardView : styles.appointmentTypeCard} ${
          appointmentType.isActive ? styles.active : styles.inactive
        }`}
        onClick={() => setShowAppointmentTypeModal(!isEdgeUserView)}
      >
        <div className={styles.appointmentInfoCard}>
          <div className={styles.leftContent}>
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <div className={styles.name}>{appointmentType.name}</div>
                <div className={`${styles.activeLabel} ${appointmentType.isActive ? styles.active : styles.inactive}`}>
                  {appointmentType.isActive ? 'OPEN TO BOOK' : 'DRAFT - NOT BOOKABLE'}
                </div>
              </div>
              {appointmentType.description && <div className={styles.description}>{appointmentType.description}</div>}
            </div>
            <SessionSettings appointmentType={appointmentType} />
            <SessionAvailableDate appointmentType={appointmentType} />
          </div>
          <div className={styles.rightContent}>
            {isEdgeUser && isGroupsFeatureToggle && (
              <SessionParticipantType participantType={appointmentType.participantType} />
            )}
            {isEdgeUser && appointmentType.participantType === ParticipantType.OneToOne && (
              <BookablePractitioner assignmentMode={appointmentType.assignmentMode} />
            )}
            <SessionMode deliveryOptions={appointmentType.deliveryOptions} />
          </div>
        </div>
        <div className={styles.footer}>
          {appointmentType?.bookingRules?.new.available && appointmentType?.bookingRules?.existing.available && (
            <>
              <strong>New</strong>&nbsp;and&nbsp;<strong>Existing</strong>
            </>
          )}
          {appointmentType?.bookingRules?.new.available && !appointmentType?.bookingRules?.existing.available && (
            <strong>New</strong>
          )}
          {!appointmentType?.bookingRules?.new.available && appointmentType?.bookingRules?.existing.available && (
            <strong>Existing</strong>
          )}
          &nbsp;clients
        </div>
      </div>
      <CreateAppointmentTypeModal
        visible={showAppointmentTypeModal}
        data={appointmentType}
        onPost={fetchAppointmentTypes}
        onClose={() => setShowAppointmentTypeModal(false)}
      />
    </>
  );
};

export default AppointmentTypeCard;
