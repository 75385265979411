import styles from './QuestionListWithCheckBox.module.scss';

export interface QuestionListWithCheckBoxProps {
  id: string;
  label: string;
  groupId: string;
  questionId: string;
  isDraftQ: boolean;
  isChecked: boolean;
  onCheckChange: any;
}

const QuestionListWithCheckBox = ({ id, label, groupId, questionId, isDraftQ, isChecked, onCheckChange }: QuestionListWithCheckBoxProps) => {

  const onChangeCheckValue = (checkBoxVal: boolean) => {
    const value = {
      id: id,
      stem: label,
      isChecked: checkBoxVal,
      questionId: questionId,
      isDraftQ: isDraftQ
    };

    onCheckChange(groupId, value);
  };

  return (
    <label className={styles.container} htmlFor={questionId}>
      <div className={styles.questionBox}>
        <input
          className={styles.checkbox}
          id={questionId}
          type={'checkbox'}
          name={groupId}
          checked={isChecked}
          onChange={(e) => onChangeCheckValue(e.target.checked)}
        />
        <div className={styles.labelWrapper}>
          <div className={styles.label}>{label}</div>
        </div>
      </div>
    </label>
  );
};

export default QuestionListWithCheckBox;
