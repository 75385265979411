import { useContext } from 'react';
import { UserContext } from '../../UserContextProvider';

export const useGetAccountSettings = () => {
  const { accountSettings, profileSettings } = useContext(UserContext);

  return {
    accountSettings,
    profileSettings
  };
};
