/* eslint-disable complexity */
import { MouseEvent, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { HeadingTemplate } from 'components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';
import { useGenerateHeadingTemplateDetails } from 'utils/hooks/headingTemplate';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import Button from 'components/Button/Button';
import LoadingButton from 'components/v2/Button/Button';
import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import PatientDetailsNoteDetails from './components/PatientDetailsNoteDetails/PatientDetailsNoteDetails';
import PatientDetailsNoteFormatSettingsModal, {
  safeGuardingName,
  useFetchCaseNoteFormatSettingsList
} from './components/PatientDetailsNoteFormatSettingsModal/PatientDetailsNoteFormatSettingsModal';
import PatientDetailsNotesListing from './components/PatientDetailsNotesListing/PatientDetailsNotesListing';
import { CaseNote } from 'interfaces/caseNote';
import { fields } from './components/PatientDetailsNoteFormatSettingsModal/defaultFormatSettingsFields.json';
import { clientRecordsInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import styles from './PatientDetailsNotes.module.scss';
import {
  useFetchCaseNote,
  useFetchCaseNoteFormatSettingsByRecordId,
  useFetchCaseNoteHeadingTemplates,
  useFetchCaseNotes
} from './hooks/getCaseNoteInfo';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const IS_DEVELOPMENT = isDevelopmentENV();

export interface Note {
  _id: string;
  clinicianId: string;
  clientId: string;
  title: string;
  fields: {
    name: string;
    type: string;
    choices: string[];
    maxSelection: number;
    mandatory: boolean;
  }[];
  body: string;
  attachments: {
    _id: string;
    title: string;
  }[];
  createdAt: Date | string;
  updatedAt?: Date | string;
}

export interface CaseNoteFormatSettingsFields {
  _id?: string;
  name: string;
  type: 'date' | 'link' | 'multipleChoice' | 'multipleChoiceCount' | 'number' | 'text' | 'time' | 'checkbox';
  linkType?: string;
  commonChoices?: { label: string; options?: { label: string; value: string }[]; value?: string }[];
  customCommonChoices?: { label: string; options?: { label: string; value: string }[]; value?: string }[];
  customChoices?: { label: string; options?: { label: string; value: string }[]; value?: string }[];
  choiceType?: 'common' | 'custom';
  multiple?: boolean;
  active: boolean;
  editable: boolean;
  mandatory: boolean;
}

export interface CaseNoteFormatSettings {
  _id?: string;
  type: 'template' | 'custom';
  templateName: string;
  fields: CaseNoteFormatSettingsFields[];
  defaultHeadingTemplateId?: string;
}

interface PatientDetailsNotesProps {
  recordId: string;
  profileId: string;
  currentTab: string;
  clientRecordData: clientRecordsInterface;
  noteId?: string;
  token: string;
}

const PatientDetailsNotes = ({
  recordId,
  profileId,
  clientRecordData,
  currentTab,
  noteId,
  token
}: PatientDetailsNotesProps) => {
  const navigate = useNavigate();
  const path = useParams() as { assessmentOrReportId: string };
  const { CLIENTS } = useRoutesGenerator();
  const { isHelmFeatureToggle } = useGetFeatureToggle();
  const { assessmentList, isLoadingAssessmentList, generateHeadingTemplate } = useGenerateHeadingTemplateDetails(
    token,
    recordId,
    profileId
  );

  const { caseNote, isCaseNoteLoading, fetchCaseNote } = useFetchCaseNote(recordId, noteId);
  const { caseNotes, isCaseNotesLoading, setCaseNotes } = useFetchCaseNotes(token, recordId);

  const { caseNoteFormatSettingsList, isCaseNoteFormatSettingsListLoading } = useFetchCaseNoteFormatSettingsList(
    recordId,
    true
  );

  const { cNFormatSettingsDetails, isCNFormatSettingsLoading, fetchCaseNoteFormatSettings } =
    useFetchCaseNoteFormatSettingsByRecordId(token, recordId);

  const { caseNoteHeadingTemplates, areCaseNoteHeadingTemplatesLoading, setCaseNoteHeadingTemplates } =
    useFetchCaseNoteHeadingTemplates(token);

  const [caseNoteList, setCaseNoteList] = useState<CaseNote[]>([]);
  const [caseNoteDetail, setCaseNoteDetail] = useState<CaseNote>();
  const [caseNoteSearchVal, setCaseNoteSearchVal] = useState('');
  const [addNoteButtonStatus, setAddNoteButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isDraftSave, setIsDraftSave] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isValueChange, setIsValueChange] = useState(false);
  const [isPatientDetailsNoteFormatSettingsModalVisible, setIsPatientDetailsNoteFormatSettingsModalVisible] =
    useState(false);
  const { auth0ClinicianId } = useGetClinicianId();
  const { isNormalUserView } = useGetAccountPackageView();
  const [t] = useTranslation();

  useEffect(() => {
    setCaseNoteList(caseNotes);
  }, [caseNotes, auth0ClinicianId]);

  useEffect(() => {
    setCaseNoteDetail(caseNote);
  }, [caseNote, auth0ClinicianId]);

  const handleFormatSettingsModalCancel = (refetchFormatSettings?: boolean) => {
    setIsPatientDetailsNoteFormatSettingsModalVisible(false);
    refetchFormatSettings && fetchCaseNoteFormatSettings(token);
  };

  const handleRedirect = (id: string) => {
    const idPath = id ? `/${id}` : '';
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}${idPath}`);
  };

  const handleAddNote = async () => {
    setAddNoteButtonStatus('active');
    try {
      const payload: Record<string, any> = {
        _id: 'new',
        title: '',
        attachments: [],
        body: '<div><br><div>',
        clinicianId: auth0ClinicianId,
        createdAt: moment().toISOString()
      };

      if (cNFormatSettingsDetails?.defaultHeadingTemplateId) {
        const foundHeadingTemplate = caseNoteHeadingTemplates.find(
          (template) => template._id === cNFormatSettingsDetails?.defaultHeadingTemplateId
        );

        if (foundHeadingTemplate) {
          payload.body = await generateHeadingTemplate(foundHeadingTemplate.body);
        }
      }

      const defaultFieldSettings = isHelmFeatureToggle
        ? (fields.filter((obj) => obj.name !== safeGuardingName) as CaseNoteFormatSettings['fields'])
        : (fields as CaseNoteFormatSettings['fields']);

      payload.fields = (cNFormatSettingsDetails?.fields || defaultFieldSettings)
        .map(
          ({
            name,
            type,
            linkType,
            choiceType,
            commonChoices,
            customChoices,
            customCommonChoices,
            active,
            mandatory,
            multiple
          }) => {
            if (!active) {
              return undefined;
            }

            const field: Record<string, any> = {
              _id: uuidv4(),
              name,
              type,
              mandatory
            };

            if (type === 'link') {
              field.linkType = linkType;
            } else if (type === 'multipleChoice' || type === 'multipleChoiceCount') {
              let options: Record<string, any>[] = [];

              if (choiceType === 'common') {
                options = commonChoices?.concat(customCommonChoices || []) || [];
              } else {
                options = customChoices || [];
              }

              field.multiple = multiple;
              field.options = options;
            }

            return field;
          }
        )
        .filter((field) => !!field);

      setCaseNoteDetail(payload as CaseNote);
      setCaseNoteList([payload as CaseNote, ...caseNoteList]);

      setIsEditing(true);

      handleRedirect('new');

      setAddNoteButtonStatus('finished');

      setTimeout(() => setAddNoteButtonStatus(''), 500);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to add a note' });
    }
  };

  const handleClickNewNote = () => {
    if (clientRecordData.recordStatus === RecordStatus.Closed) {
      return;
    }
    if (isEditing || isValueChange) {
      Modal.confirm({
        title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
        onOk: () => {
          handleAddNote();
        }
      });
    } else {
      handleAddNote();
    }
  };

  const handleNavigateNote = (id: string) => {
    setIsDraftSave(false);
    setIsEditing(false);
    setCaseNoteList(caseNoteList.filter((caseNoteObj) => caseNoteObj._id !== 'new'));
    handleRedirect(id);
    fetchCaseNote(id);
  };

  const handleClickNote = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    const noteId = path.assessmentOrReportId;
    if (noteId !== 'new' ? id !== noteId : id !== 'new') {
      if ((isEditing && isValueChange) || (isEditing && noteId === 'new')) {
        Modal.confirm({
          title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
          onOk: () => handleNavigateNote(id)
        });
      } else {
        handleNavigateNote(id);
      }
    }
  };

  const handleEditNoteClick = () => {
    setIsEditing(true);
  };

  const handleDeleteNote = () => {
    setIsEditing(false);
    setCaseNoteDetail(undefined);
    setCaseNoteList(caseNoteList.filter((caseNote) => caseNote._id !== noteId));
    setCaseNotes(caseNoteList.filter((caseNote) => caseNote._id !== noteId));
    handleRedirect('');
  };

  const handleUpdatedNote = (updatedNote: CaseNote, isDraft: boolean) => {
    setIsDraftSave(isDraft);
    const newNoteList = [
      updatedNote,
      ...caseNoteList.filter((caseNoteObj) => caseNoteObj._id !== 'new' && caseNoteObj._id !== updatedNote._id)
    ];
    setCaseNoteDetail(updatedNote);
    setCaseNoteList(newNoteList);
    setCaseNotes(newNoteList);

    if (!isDraft) {
      handleRedirect(updatedNote._id);
      setIsEditing(false);
    }
  };

  const handleDeleteTemplate = (headingTemplateId: string) => {
    setCaseNoteHeadingTemplates((headingTemplates) =>
      headingTemplates.filter((headingTemplate) => headingTemplate._id !== headingTemplateId)
    );
  };

  const handleSubmitTemplate = (newHeadingTemplate: HeadingTemplate) => {
    setCaseNoteHeadingTemplates((headingTemplates) => [...headingTemplates, newHeadingTemplate]);
  };

  const handleSearchKeyUp = (event: any) => {
    const fieldValue = event.target.value.toLowerCase() || '';
    if (event.keyCode === 13) {
      setCaseNoteSearchVal(fieldValue);
      if (fieldValue.length > 0) {
        const newCaseNoteList = caseNotes.filter((caseNoteObj) => {
          return caseNoteObj.title.toLowerCase().includes(fieldValue);
        });
        setCaseNoteList(newCaseNoteList);
      } else {
        setCaseNoteList(caseNotes);
      }
    }
  };

  const contentLoading = isDraftSave
    ? false
    : isCaseNoteLoading || addNoteButtonStatus || isCaseNoteFormatSettingsListLoading;

  return (
    <div className={styles.container}>
      {isCaseNotesLoading || areCaseNoteHeadingTemplatesLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <PatientDetailsNoteFormatSettingsModal
            recordId={recordId}
            caseNoteHeadingTemplates={caseNoteHeadingTemplates}
            defaultCaseNoteFormatSettings={cNFormatSettingsDetails}
            visible={isPatientDetailsNoteFormatSettingsModalVisible}
            onCancel={handleFormatSettingsModalCancel}
            onSubmitTemplate={handleSubmitTemplate}
          />
          <HeadingAssessment isLoading={isLoadingAssessmentList} assessmentData={assessmentList} />
          <div className={styles.content}>
            <div className={styles.noteListing}>
              <div className={styles.header}>
                <span className={styles.caseNotesCount}>
                  {caseNoteList.filter((note) => note._id).length} case notes saved
                </span>
              </div>
              <LoadingButton
                className={styles.addNoteButton}
                status={addNoteButtonStatus}
                disabled={
                  !!addNoteButtonStatus ||
                  isCNFormatSettingsLoading ||
                  isEditing ||
                  clientRecordData.recordStatus === RecordStatus.Closed
                }
                onClick={handleClickNewNote}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                NEW NOTE
              </LoadingButton>
              {isEditing && (
                <>
                  <div className={styles.warningMessage}>
                    Save or close the current note editing to create a new one.
                  </div>
                </>
              )}
              <PatientDetailsNotesListing
                searchValue={caseNoteSearchVal}
                noteId={noteId}
                notes={caseNoteList}
                isDisabled={isEditing}
                onClickNote={handleClickNote}
              />
            </div>
            <div className={styles.noteDetailsContainer}>
              <div className={styles.header}>
                <div className={styles.inputContainer}>
                  {IS_DEVELOPMENT && (
                    <MaterialInput
                      id="search-notes"
                      label="Search notes"
                      onKeyUp={(e) => handleSearchKeyUp(e)}
                      required
                    />
                  )}
                </div>
                {clientRecordData.clinicianAuth0Ids[0] === auth0ClinicianId && (
                  <Button
                    className={styles.noteFormatButton}
                    variant="secondary"
                    disabled={isCNFormatSettingsLoading || clientRecordData.recordStatus === RecordStatus.Closed}
                    onClick={() => setIsPatientDetailsNoteFormatSettingsModalVisible(true)}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>settings</i>
                    Manage note format
                  </Button>
                )}
              </div>
              {caseNoteList.length === 0 && !isCaseNoteLoading ? (
                <div className={styles.noCaseNotes}>
                  <div className={styles.text}>{t('label.no_client_case_notes')}</div>
                  <LoadingButton
                    className={styles.button}
                    status={addNoteButtonStatus}
                    disabled={
                      !!addNoteButtonStatus ||
                      isCNFormatSettingsLoading ||
                      !!caseNoteList.find((caseNote) => caseNote._id === 'new') ||
                      clientRecordData.recordStatus === RecordStatus.Closed
                    }
                    onClick={handleClickNewNote}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                    Add new note
                  </LoadingButton>
                </div>
              ) : !noteId ? (
                <div className={styles.noNoteSelected}>
                  <div className={styles.text}>No case note selected.</div>
                  <LoadingButton
                    className={styles.button}
                    status={addNoteButtonStatus}
                    disabled={
                      !!addNoteButtonStatus ||
                      isCNFormatSettingsLoading ||
                      !!caseNoteList.find((caseNote) => caseNote._id === 'new') ||
                      clientRecordData.recordStatus === RecordStatus.Closed
                    }
                    onClick={handleClickNewNote}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                    Add new note
                  </LoadingButton>
                </div>
              ) : contentLoading ? (
                <div className={styles.loading}>
                  <LoadingDot />
                </div>
              ) : caseNoteDetail ? (
                <PatientDetailsNoteDetails
                  recordId={recordId}
                  profileId={profileId}
                  caseNoteData={caseNoteDetail}
                  caseNoteHeadingTemplates={caseNoteHeadingTemplates}
                  formatSettings={caseNoteFormatSettingsList}
                  token={token}
                  isEditing={isEditing}
                  onValueChange={(val) => setIsValueChange(val)}
                  onDeleteNote={handleDeleteNote}
                  onDeleteTemplate={handleDeleteTemplate}
                  onEditNoteClick={handleEditNoteClick}
                  onSubmitTemplate={handleSubmitTemplate}
                  onUpdateNoteDetails={handleUpdatedNote}
                  isReadOnly={caseNote && !isNormalUserView ? caseNote?.clinicianId !== auth0ClinicianId : false}
                  isClosedClient={clientRecordData.recordStatus === RecordStatus.Closed}
                />
              ) : (
                <div className={styles.noNoteFound}>
                  <div className={styles.text}>Note not found.</div>
                  <LoadingButton
                    className={styles.button}
                    status={addNoteButtonStatus}
                    disabled={
                      !!addNoteButtonStatus ||
                      isCNFormatSettingsLoading ||
                      !!caseNoteList.find((caseNote) => caseNote._id === 'new') ||
                      clientRecordData.recordStatus === RecordStatus.Closed
                    }
                    onClick={handleClickNewNote}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                    Add new note
                  </LoadingButton>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientDetailsNotes;
