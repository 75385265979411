import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import Button from 'components/Button/Button';
import LoadingButton from 'components/v2/Button/Button';
import { HelmControlInterface } from 'interfaces/Profile/Profile';
import { useEffect, useState } from 'react';
import { updateClinicianHelmControl, uploadHelmControlPhoto } from 'utils/http/ClinicianProfileService/Profile/profile';
import styles from './HelmControl.module.scss';
import { Languages } from './Languages';
import Checkbox from 'components/v2/Checkbox/Checkbox';
import Select from 'components/Select/CommonSelect/Select';
import classNames from 'classnames';
import ImagesInput from './components/ImagesInput/ImagesInput';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import HelmControlUploadPhoto from './components/HelmControlUploadPhoto/HelmControlUploadPhoto';
import { SelectInterface } from 'interfaces/caseNote';

const languages: SelectInterface[] = Object.keys(Languages).map((key) => ({
  value: key,
  label: Languages[key as keyof typeof Languages]
}));

const shortDescMaxLength = 800;
const quoteMaxLength = 140;
const welcomeNoteMaxLength = 500;

export interface HelmControlUploadedPhotos {
  headerPhoto?: File;
  additionalPhotos?: File[];
  replacedAdditionalPhotos?: { [url: string]: File };
  bioPhoto?: File;
  appointmentPhoto?: File;
}

interface HelmControlProps {
  profile: HelmControlInterface;
}

const HelmControl = ({ profile }: HelmControlProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [showUploadPhotos, setShowUploadPhotos] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [values, setValues] = useState<HelmControlInterface>(profile);
  const [errors, setErrors] = useState<HelmControlInterface>({});

  const setFieldValue = (key: 'shortDescription' | 'quote' | 'welcomeNote', value: any) => {
    values[key] = value;
    setValues({ ...values });
    if (
      typeof values.shortDescription === 'string' &&
      values.shortDescription.length > 0 &&
      (values.shortDescription.length < 230 || values.shortDescription.length > shortDescMaxLength)
    ) {
      setErrors({
        ...errors,
        shortDescription: `Short description should between 230 and ${shortDescMaxLength} characters`
      });
    } else {
      setErrors({
        ...errors,
        shortDescription: undefined
      });
    }
  };

  const validate = () => {
    return !Object.values(errors).reduce((res, item) => res || item, false);
  };

  const setBonafides = (key: 'languages' | 'style' | 'yearsOfExperience' | 'qualifications', value?: any) => {
    if (!values.bonaFides) {
      values.bonaFides = { yearsOfExperience: {}, style: {}, languages: {}, qualifications: {} };
    }
    if (values.bonaFides && values.bonaFides[key]) {
      values.bonaFides[key].value = value;
    }
    setValues({ ...values, bonaFides: { ...values.bonaFides, [key]: { ...values.bonaFides[key] } } });
  };

  const toggleBonafides = (
    key: 'languages' | 'style' | 'yearsOfExperience' | 'qualifications' | 'medicare',
    active: boolean
  ) => {
    if (!values.bonaFides) {
      values.bonaFides = { yearsOfExperience: {}, style: {}, languages: {}, qualifications: {}, medicare: {} };
    }
    if (values.bonaFides && values.bonaFides[key]) {
      values.bonaFides[key]!.active = active;
    }
    setValues({ ...values, bonaFides: { ...values.bonaFides, [key]: { ...values.bonaFides[key] } } });
  };

  const [uploadedPhotos, setUploadedPhotos] = useState<HelmControlUploadedPhotos>({});
  const [uploadedPhotosObjectURLs, setUploadedPhotosObjectURLs] = useState<{
    headerPhoto?: string;
    additionalPhotos?: string[];
    replacedAdditionalPhotos?: { [url: string]: string };
    bioPhoto?: string;
    appointmentPhoto?: string;
  }>({});

  useEffect(() => {
    setUploadedPhotosObjectURLs({
      headerPhoto: uploadedPhotos.headerPhoto ? URL.createObjectURL(uploadedPhotos.headerPhoto) : undefined,
      additionalPhotos: uploadedPhotos.additionalPhotos
        ? uploadedPhotos.additionalPhotos.map((i) => URL.createObjectURL(i))
        : [],
      replacedAdditionalPhotos: uploadedPhotos.replacedAdditionalPhotos
        ? Object.keys(uploadedPhotos.replacedAdditionalPhotos).reduce(
            (res, key) => ({ ...res, [key]: URL.createObjectURL(uploadedPhotos.replacedAdditionalPhotos![key]) }),
            {}
          )
        : {},
      bioPhoto: uploadedPhotos.bioPhoto ? URL.createObjectURL(uploadedPhotos.bioPhoto) : undefined,
      appointmentPhoto: uploadedPhotos.appointmentPhoto
        ? URL.createObjectURL(uploadedPhotos.appointmentPhoto)
        : undefined
    });

    return () => {
      [
        uploadedPhotosObjectURLs.headerPhoto,
        ...(uploadedPhotosObjectURLs.additionalPhotos || []),
        ...Object.values(uploadedPhotosObjectURLs.replacedAdditionalPhotos || {}),
        uploadedPhotosObjectURLs.bioPhoto,
        uploadedPhotosObjectURLs.appointmentPhoto
      ].forEach((i) => i && URL.revokeObjectURL(i));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedPhotos]);

  const [editingUploadedExtraPhoto, setEditingUploadedExtraPhoto] = useState<File>();
  const [editingExtraPhoto, setEditingExtraPhoto] = useState<string>();

  const [removedAdditionalPhotos, setRemovedAdditionalPhotos] = useState<string[]>([]);

  const checkFile = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;
    return isJpgOrPng && isLt2M;
  };

  const handleUpdateProfile = async (values: HelmControlInterface) => {
    if (validate()) {
      try {
        setSaveButtonStatus('active');
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        values.additionalPhotos = (values.additionalPhotos || []).filter(
          (item) => !removedAdditionalPhotos.includes(item)
        );

        let headerPhoto, additionalPhotos, bioPhoto, appointmentPhoto;
        if (uploadedPhotos.headerPhoto) {
          const data = new FormData();
          data.append('file', uploadedPhotos.headerPhoto);
          headerPhoto = uploadHelmControlPhoto(token, data);
        }
        additionalPhotos = Object.values(uploadedPhotos.replacedAdditionalPhotos || {})
          .concat(uploadedPhotos.additionalPhotos || [])
          .map((item) => {
            const data = new FormData();
            data.append('file', item);
            return uploadHelmControlPhoto(token, data);
          });
        if (uploadedPhotos.bioPhoto) {
          const data = new FormData();
          data.append('file', uploadedPhotos.bioPhoto);
          bioPhoto = uploadHelmControlPhoto(token, data);
        }
        if (uploadedPhotos.appointmentPhoto) {
          const data = new FormData();
          data.append('file', uploadedPhotos.appointmentPhoto);
          appointmentPhoto = uploadHelmControlPhoto(token, data);
        }

        const [headerPhotoUrl, bioPhotoUrl, appointmentPhotoUrl, ...additionalPhotoUrls] = await Promise.all([
          headerPhoto,
          bioPhoto,
          appointmentPhoto,
          ...(additionalPhotos ? additionalPhotos : [])
        ]);

        if (headerPhotoUrl) {
          values.headerPhoto = (await headerPhotoUrl.json()).url;
        }
        if (additionalPhotoUrls) {
          const newAdditionalPhoto = (await Promise.all(additionalPhotoUrls.map((i) => i.json()))).map((i) => i.url);
          values.additionalPhotos = [...values.additionalPhotos, ...newAdditionalPhoto];
        }
        if (bioPhotoUrl) {
          values.bioPhoto = (await bioPhotoUrl.json()).url;
        }
        if (appointmentPhotoUrl) {
          values.appointmentPhoto = (await appointmentPhotoUrl.json()).url;
        }

        const massagePayload = {
          ...values,
          headerPhoto: values.headerPhoto || '',
          quote: values.quote || '',
          shortDescription: values.shortDescription || '',
          welcomeNote: values.welcomeNote || '',
          bonaFides: {
            languages: {
              active: values.bonaFides?.languages?.active || false,
              value: values.bonaFides?.languages?.value || []
            },
            yearsOfExperience: {
              active: values.bonaFides?.yearsOfExperience?.active || false,
              value: values.bonaFides?.yearsOfExperience?.value || 0
            },
            style: {
              active: values.bonaFides?.style?.active || false,
              value: values.bonaFides?.style?.value || ''
            },
            qualifications: {
              active: values.bonaFides?.qualifications?.active || false,
              value: values.bonaFides?.qualifications?.value || []
            },
            medicare: {
              active: values.bonaFides?.medicare?.active || false,
              value: values.bonaFides?.medicare?.active ? 'Medicare rebates available' : ''
            }
          }
        };

        await updateClinicianHelmControl(token, massagePayload);

        setSaveButtonStatus('finished');
        setValues({ ...values });

        setUploadedPhotos({ ...uploadedPhotos, additionalPhotos: undefined, replacedAdditionalPhotos: {} });

        notification.success({
          message: 'Profile updated',
          closeIcon: <span className="success">OK</span>
        });

        setTimeout(() => setSaveButtonStatus(''), 2000);
      } catch (ex) {
        console.error(ex);
        setSaveButtonStatus('');
        notification.error({ message: 'Something went wrong while trying to update your helm profile' });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Helm Public Profile</div>
        <div className={styles.description}>
          Set up and control your profile information. Changes may take up to 30 minutes to be visible on the site
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.section}>
          <div className={styles.title}>Set profile visibility</div>
          <ToggleSwitch
            checkLabel="Show Profile"
            unCheckLabel="Hide Profile"
            id="isProfileListed"
            isCheckedProps={values.isProfileListed || false}
            onChangeProps={(e) => setValues({ ...values, isProfileListed: e.target.checked })}
            buttonWidth={300}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Upload your hero image</div>
          <HelmControlUploadPhoto
            photoField="headerPhoto"
            values={values}
            setValues={setValues}
            uploadedPhotos={uploadedPhotos}
            uploadedPhotosObjectURLs={uploadedPhotosObjectURLs}
            setUploadedPhotos={setUploadedPhotos}
            title="Upload your hero image"
            defaultSize={[750, 750]}
          />
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Upload supporting images</div>
          <div className={styles.description}>Add up to 6 images for your gallery</div>
          <div className={styles.photoList}>
            {values.additionalPhotos
              ?.filter((url) => !removedAdditionalPhotos.includes(url))
              .map((url, index) => (
                <div key={index} className={styles.photoListItem} onClick={() => setEditingExtraPhoto(url)}>
                  <img src={url} alt="currentAdditionalPhoto" />
                </div>
              ))}
            {uploadedPhotos.replacedAdditionalPhotos &&
              Object.keys(uploadedPhotos.replacedAdditionalPhotos || {}).map((url) => (
                <div className={styles.photoListItem} onClick={() => setEditingExtraPhoto(url)}>
                  <img src={uploadedPhotosObjectURLs.replacedAdditionalPhotos?.[url]} alt="replacedAdditionalPhoto" />
                </div>
              ))}

            {uploadedPhotos.additionalPhotos &&
              uploadedPhotos.additionalPhotos.length > 0 &&
              uploadedPhotos.additionalPhotos?.map((i, index) => (
                <div className={styles.photoListItem} onClick={() => setEditingUploadedExtraPhoto(i)}>
                  <img src={uploadedPhotosObjectURLs.additionalPhotos?.[index]} alt="additionalPhoto" />
                </div>
              ))}
          </div>

          {editingUploadedExtraPhoto && (
            <ImagesInput
              title="Edit supporting image"
              visible
              defaultSize={[750, 750]}
              onUpload={(files: File[]) =>
                setUploadedPhotos({
                  ...uploadedPhotos,
                  additionalPhotos: [
                    ...(uploadedPhotos.additionalPhotos || []).filter((file) => file !== editingUploadedExtraPhoto),
                    files[0]
                  ]
                })
              }
              onClose={() => setEditingUploadedExtraPhoto(undefined)}
              accept="image/png, image/jpeg"
              maxImages={1}
              check={checkFile}
              initFiles={[editingUploadedExtraPhoto]}
              onRemoveFile={(file: File) => {
                //only 1 image, file corresponding to editingUploadedExtraPhoto
                setUploadedPhotos({
                  ...uploadedPhotos,
                  additionalPhotos: uploadedPhotos.additionalPhotos?.filter(
                    (file) => file !== editingUploadedExtraPhoto
                  )
                });
              }}
            />
          )}
          {editingExtraPhoto && (
            <ImagesInput
              title="Edit supporting image"
              visible
              defaultSize={[750, 750]}
              onUpload={(files: File[]) => {
                setUploadedPhotos({
                  ...uploadedPhotos,
                  replacedAdditionalPhotos: {
                    ...uploadedPhotos.replacedAdditionalPhotos,
                    [editingExtraPhoto]: files[0]
                  }
                });
                setRemovedAdditionalPhotos([...removedAdditionalPhotos, editingExtraPhoto]);
              }}
              onClose={() => setEditingExtraPhoto(undefined)}
              accept="image/png, image/jpeg"
              initFiles={
                uploadedPhotos.replacedAdditionalPhotos?.[editingExtraPhoto]
                  ? [uploadedPhotos.replacedAdditionalPhotos[editingExtraPhoto]]
                  : undefined
              }
              initUrls={!uploadedPhotos.replacedAdditionalPhotos?.[editingExtraPhoto] ? [editingExtraPhoto] : undefined}
              maxImages={1}
              onRemoveFile={(file: File) => {
                //only 1 image, file corresponding to editingExtraPhoto
                delete uploadedPhotos.replacedAdditionalPhotos?.[editingExtraPhoto];
                setUploadedPhotos({
                  ...uploadedPhotos,
                  replacedAdditionalPhotos: { ...uploadedPhotos.replacedAdditionalPhotos }
                });
                setRemovedAdditionalPhotos([...removedAdditionalPhotos, editingExtraPhoto]);
              }}
            />
          )}
          {6 -
            ((values.additionalPhotos?.length || 0) - removedAdditionalPhotos.length) -
            ((uploadedPhotos.additionalPhotos?.length || 0) +
              Object.keys(uploadedPhotos.replacedAdditionalPhotos || {}).length) >
            0 && (
            <Button className={styles.uploadButton} onClick={() => setShowUploadPhotos(true)}>
              <i className="material-icons-outlined">image</i>
              Upload Photo
            </Button>
          )}
          <ImagesInput
            title="Upload supporting images"
            visible={showUploadPhotos}
            defaultSize={[750, 750]}
            onUpload={(files: File[]) =>
              setUploadedPhotos({
                ...uploadedPhotos,
                additionalPhotos: [...(uploadedPhotos.additionalPhotos || []), ...files]
              })
            }
            onClose={() => setShowUploadPhotos(false)}
            accept="image/png, image/jpeg"
            maxImages={1}
            check={checkFile}
            clearOnClose
          />
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Your short profile description </div>
          <div className={styles.description}>This appears on your card in the counsellor list view</div>
          <div className={styles.bioContainer}>
            <textarea
              className={styles.bioInput}
              maxLength={shortDescMaxLength}
              rows={4}
              value={values.shortDescription || ''}
              onChange={(e) => setFieldValue('shortDescription', e.target.value)}
            />
            <div className={styles.characterCount}>
              {values.shortDescription?.length || 0} / {shortDescMaxLength} characters
            </div>
          </div>
          <ErrorMessage
            error={`Short description should between 230 and ${shortDescMaxLength} characters`}
            visible={errors.shortDescription !== undefined}
          />
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Upload mid profile key image</div>
          <HelmControlUploadPhoto
            photoField="bioPhoto"
            values={values}
            setValues={setValues}
            uploadedPhotos={uploadedPhotos}
            uploadedPhotosObjectURLs={uploadedPhotosObjectURLs}
            setUploadedPhotos={setUploadedPhotos}
            title="Upload mid profile key image"
            defaultSize={[820, 600]}
          />
        </div>

        <div className={classNames(styles.section)}>
          <div className={styles.title}>Set up your credentials</div>
          <div className={styles.worksDetail}>
            <div className={styles.row}>
              <Checkbox
                label=""
                checked={values.bonaFides?.style?.active || false}
                onChange={(e) => toggleBonafides('style', e)}
              />
              <div className={styles.checkboxDetail}>
                <div className={styles.col1}>Style</div>
                <div className={styles.col2}>
                  <MaterialInput
                    containerClassName={styles.inputContainer}
                    label="Information"
                    labelClassName={styles.label}
                    name="bonaFides.style.value"
                    required
                    value={values.bonaFides?.style?.value}
                    className={styles.helmInput}
                    onChange={(e) => setBonafides('style', e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.checkBoxRow}>
              <Checkbox
                label=""
                checked={values.bonaFides?.languages?.active || false}
                onChange={(e) => toggleBonafides('languages', e)}
              />
              <div className={styles.checkboxDetail}>
                <div className={styles.col1}>Languages</div>
                <div className={styles.col2}>
                  <div className={styles.label}>Languages</div>
                  <Select
                    styles={{
                      container: (base) => ({ ...base, width: '100%' }),
                      control: (controlBase) => ({
                        ...controlBase,
                        minHeight: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderBottom: `1px solid ${styles.greyColor}`,
                        borderRadius: 0,
                        boxShadow: 'none'
                      }),
                      multiValue: (base) => ({
                        ...base,
                        backgroundColor: styles.blueColor,
                        borderRadius: 20,
                        padding: 2,
                        color: 'white',
                        fontSize: '12px'
                      }),
                      multiValueRemove: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: styles.removeColor
                        }
                      })
                    }}
                    options={languages?.filter(
                      (language) => !values.bonaFides?.languages?.value?.includes(language.value)
                    )}
                    placeholder="Search here"
                    closeMenuOnSelect={false}
                    value={
                      languages?.filter((language) =>
                        values.bonaFides?.languages?.value?.includes(language.value)
                      ) as any
                    }
                    isMulti
                    onChange={(languages: any) =>
                      setBonafides(
                        'languages',
                        (languages || []).map((language: any) => language.value)
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <Checkbox
                label=""
                checked={values.bonaFides?.yearsOfExperience?.active || false}
                onChange={(e) => toggleBonafides('yearsOfExperience', e)}
              />
              <div className={styles.checkboxDetail}>
                <div className={styles.col1}>Year of</div>
                <div className={styles.col2}>
                  <MaterialInput
                    containerClassName={styles.inputContainer}
                    label="Working Experience"
                    labelClassName={styles.label}
                    name="bonaFides.yearsOfExperience.value"
                    required
                    value={values.bonaFides?.yearsOfExperience?.value}
                    className={styles.helmInput}
                    onChange={(e) => setBonafides('yearsOfExperience', e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.checkBoxRow}>
              <Checkbox
                label=""
                checked={values.bonaFides?.qualifications?.active || false}
                onChange={(e) => toggleBonafides('qualifications', e)}
              />
              <div className={styles.checkboxDetail}>
                <div className={styles.col1}>Qualifications</div>
                <div className={styles.col2}>
                  <div className={styles.label}>Information (Press enter to create)</div>
                  <Select
                    styles={{
                      container: (base) => ({ ...base, width: '100%' }),
                      control: (controlBase) => ({
                        ...controlBase,
                        minHeight: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderBottom: `1px solid ${styles.greyColor}`,
                        borderRadius: 0,
                        boxShadow: 'none'
                      }),
                      multiValue: (base) => ({
                        ...base,
                        backgroundColor: styles.blueColor,
                        borderRadius: 20,
                        padding: 2,
                        color: 'white',
                        fontSize: '12px'
                      }),
                      multiValueRemove: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: styles.removeColor
                        }
                      }),
                      menu: () => ({
                        display: 'none !important'
                      }),
                      indicatorsContainer: () => ({
                        display: 'none !important'
                      })
                    }}
                    placeholder="Type in your qualifications"
                    closeMenuOnSelect={false}
                    value={
                      values.bonaFides?.qualifications?.value?.map((item) => ({
                        value: item,
                        label: item
                      })) as any
                    }
                    isMulti
                    onChange={(items: any) =>
                      setBonafides(
                        'qualifications',
                        (items || []).map((i: any) => i.value)
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <Checkbox
                label=""
                checked={values.bonaFides?.medicare?.active || false}
                onChange={(e) => toggleBonafides('medicare', e)}
              />
              <div className={styles.checkboxDetail}>
                <div className={styles.col1}>Medicare</div>
                <div className={styles.col2} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Upload your calendar profile photo</div>
          <HelmControlUploadPhoto
            photoField="appointmentPhoto"
            values={values}
            setValues={setValues}
            uploadedPhotos={uploadedPhotos}
            uploadedPhotosObjectURLs={uploadedPhotosObjectURLs}
            setUploadedPhotos={setUploadedPhotos}
            title="Upload your calendar profile photo"
            defaultSize={[450, 450]}
          />
        </div>

        <div className={styles.section}>
          <div className={styles.title}>Quote</div>
          <div className={styles.description}>A short quote or phrase you want to call out to prospective clients</div>
          <div className={styles.bioContainer}>
            <textarea
              className={styles.bioInput}
              maxLength={quoteMaxLength}
              rows={4}
              value={values.quote || ''}
              onChange={(e) => setFieldValue('quote', e.target.value)}
              placeholder="This quote will show in your profile details page."
            />
            <div className={styles.characterCount}>
              {values.quote?.length || 0} / {quoteMaxLength} characters
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Welcome Note</div>
          <div className={styles.description}>
            For new clients booking their first session, this note will appear on their confirmation page.
          </div>
          <div className={styles.bioContainer}>
            <textarea
              className={styles.bioInput}
              maxLength={welcomeNoteMaxLength}
              rows={4}
              value={values.welcomeNote || ''}
              onChange={(e) => setFieldValue('welcomeNote', e.target.value)}
              placeholder="Welcome note for your customer after they book session with you"
            />
            <div className={styles.characterCount}>
              {values.welcomeNote?.length || 0} / {welcomeNoteMaxLength} characters
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <LoadingButton
            className={styles.saveButton}
            status={saveButtonStatus}
            disabled={!!saveButtonStatus || !validate()}
            onClick={() => handleUpdateProfile(values)}
            id={'saveProfileId'}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default HelmControl;
