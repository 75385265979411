import { ReportTemplateWidgetId } from 'interfaces/Reports/reportTemplate';
import { AssessmentChart } from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { ReportWidgetType } from 'interfaces/Reports/report';
import { CaseNoteFormatSettingsFields } from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/PatientDetailsNotes';

export enum TemplateWidgetView {
  Menu = 'widgetMenu',
  Psychometric = 'psychometric',
  ClientProfile = 'clientProfile',
  Question = 'question'
}

interface ClientProfileWidgetItems {
  title: string;
  label: string;
  widgetId: ReportTemplateWidgetId;
}

export interface PsychometricWidgetItemsCharts extends Omit<AssessmentChart, 'max'> {
  key?: string;
}

interface PsychometricWidgetItems {
  id: string;
  widgetId: ReportTemplateWidgetId;
  label: string;
  charts: PsychometricWidgetItemsCharts[];
}

interface ReportTemplateWidgetMenu {
  id: string;
  label: string;
  widgetType: ReportWidgetType;
}

interface QuestionWidgetItems {
  widgetId: ReportTemplateWidgetId;
  questionDetails: CaseNoteFormatSettingsFields;
}

export interface ClientProfileWidgetInterface extends ReportTemplateWidgetMenu {
  items: ClientProfileWidgetItems[];
}

export interface PsychometricWidgetInterface extends ReportTemplateWidgetMenu {
  items: PsychometricWidgetItems[];
}

export interface QuestionWidgetInterface extends ReportTemplateWidgetMenu {
  items: QuestionWidgetItems;
}
