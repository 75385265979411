import momentTz from 'moment-timezone';

export const defaultTimezone =
  process.env.REACT_APP_GAE_REGION === 'au'
    ? 'Australia/Melbourne'
    : process.env.REACT_APP_GAE_REGION === 'gb'
    ? 'Europe/London'
    : 'Asia/Kuala_Lumpur';

export const getTimeZoneDateTime = (dateTime?: string, timeZoneSetting?: string) =>
  momentTz.tz(dateTime, timeZoneSetting || defaultTimezone);
