import { useState } from 'react';
import { TimeSlotInterface } from '../../constants';
import styles from './TimeSlotList.module.scss';

interface TimeSlotListProps {
  availableSlots: TimeSlotInterface[];
}

const TimeSlotList = (props: TimeSlotListProps) => {
  const { availableSlots } = props;
  const [expand, setExpand] = useState(false);

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <div className={styles.container}>
      <div style={{}} className={styles.slotList}>
        {!expand &&
          Array(3)
            .fill(null)
            .map(
              (item: any, index: number) =>
                availableSlots[index] && (
                  <div key={`slot-${index}`} className={styles.timeSlot}>
                    {availableSlots[index].startTime} to {availableSlots[index].endTime}
                  </div>
                )
            )}
        {expand &&
          availableSlots.map((slot: any, index: number) => (
            <div key={`slot-${index}`} className={styles.timeSlot}>
              {slot.startTime} to {slot.endTime}
            </div>
          ))}
      </div>
      {availableSlots && availableSlots.length > 3 && !expand && (
        <div className={styles.controlLink} onClick={onExpand}>
          + <span className={styles.moreTimesNumber}>{availableSlots.length - 3}</span> more times
          <div className={`material-icons-outlined ${styles.expandIcon}`}>expand_more</div>
        </div>
      )}
      {availableSlots && availableSlots.length > 3 && expand && (
        <div className={styles.controlLink} onClick={onExpand}>
          Show less
          <div className={`material-icons-outlined ${styles.expandIcon}`}>expand_less</div>
        </div>
      )}
    </div>
  );
};

export default TimeSlotList;
