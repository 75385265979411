import { useEffect, useState } from 'react';
import { notification } from 'antd';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import PatientDetailsReportsHeader from './components/PatientDetailsReportsHeader/PatientDetailsReportsHeader';
import styles from './PatientDetailsReports.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { getReport, getReportByAdmin } from 'utils/http/DocumentService/Reports/reports';
import { Report, ReportClientTab } from 'interfaces/Reports/report';
import ReportClientTabPreview from '../../../../../Report/ReportClientTabPreview/ReportClientTabPreview';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

interface PatientDetailsReportsProps {
  recordId: string;
  currentTab: string;
  reportId?: string;
  reportList: ReportClientTab[];
  clientRecordData: clientRecordsInterface;
  isLoadingReportList: boolean;
}

const PatientDetailsReports = ({
  recordId,
  currentTab,
  reportId,
  reportList,
  clientRecordData,
  isLoadingReportList
}: PatientDetailsReportsProps) => {
  const { token } = useGetAccessToken();
  const [isLoadingReportData, setIsLoadingReportData] = useState(true);
  const [reportData, setReportData] = useState<Report>({} as Report);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const fetchReportDetails = async (patientReportId: string) => {
    if (patientReportId) {
      setIsLoadingReportData(true);

      try {
        const getReportRes =
          isEdgeAdminView || isEdgeReceptionist
            ? await getReportByAdmin(token, patientReportId)
            : await getReport(token, patientReportId);
        const report = await getReportRes.json();
        setReportData({
          ...report,
          clientRecord: clientRecordData
        });
        setIsLoadingReportData(false);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get this reports detail' });
      }
    } else {
      setIsLoadingReportData(false);
    }
  };

  useEffect(() => {
    const id = reportId || '';
    if (token) {
      fetchReportDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, token, recordId]);

  return (
    <div className={styles.container}>
      {isLoadingReportList ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          {reportId && reportList.length > 0 ? (
            <>
              <PatientDetailsReportsHeader
                recordId={recordId}
                currentTab={currentTab}
                reportId={reportId}
                reportList={reportList}
              />
              {isLoadingReportData ? (
                <div className={styles.loading}>
                  <LoadingCircle />
                </div>
              ) : (
                <ReportClientTabPreview reportData={reportData} />
              )}
            </>
          ) : (
            <>No report created yet or there is no report selected</>
          )}
        </>
      )}
    </div>
  );
};

export default PatientDetailsReports;
