import { Question } from 'components/PatientQuestionForm/AssessmentInterfaces';
import { Assessment } from '../../PatientDetailsAssessments';
import FreeText from './components/FreeText/FreeText';
import MultipleAnswers from './components/MultipleAnswers/MultipleAnswers';
import MultipleChoice from './components/MultipleChoice/MultipleChoice';
import Numeric from './components/Numeric/Numeric';
import SelectOther from './components/SelectOther/SelectOther';
import SimpleSlider from './components/SimpleSlider/SimpleSlider';
import styles from './PatientAnswer.module.scss';
import './PatientAnswer.scss';
interface PatientAnswerProps {
  sections: Assessment['sections'];
  showName?: boolean;
  hideNoItemsSection?: boolean;
}

const getQuestionComponent = (question: Question, index: number) => {
  switch (question.questionType) {
    case 'multipleAnswers':
      return <MultipleAnswers key={index} question={question} />;
    case 'multipleChoice':
      return <MultipleChoice key={index} question={question} />;
    case 'simpleSlider':
      return <SimpleSlider key={index} question={question} />;
    case 'numeric':
      return <Numeric key={index} question={question} />;
    case 'freeText':
      return <FreeText key={index} question={question} />;
    case 'selectOther':
      return <SelectOther key={index} question={question} />;

    default:
      throw new Error(`Question type ${question.questionType} is not supported in this report`);
  }
};

const PatientAnswer = ({ sections, showName = true, hideNoItemsSection = true }: PatientAnswerProps) => {
  return (
    <div className={styles.container}>
      {sections.map(
        ({ name, questions }, index) =>
          !(hideNoItemsSection && questions.length === 0) && (
            <div key={index} className={styles.section}>
              {showName && name && <div className="section" dangerouslySetInnerHTML={{ __html: name }} />}
              {questions.map((question, index) => getQuestionComponent(question, index))}
            </div>
          )
      )}
    </div>
  );
};

export default PatientAnswer;
