import { useEffect, useState } from 'react';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { getPracticeInfo, getPractitionerListing } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { RoomType } from 'interfaces/Schedule/Room';
import { getRoomList } from 'utils/http/ScheduleService/Rooms/rooms';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { getClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { AccessRight } from 'interfaces/Clients/clinician';

export const useFetchFilterList = (token: string) => {
  const { accountId } = useGetAccountId();

  const [roomList, setRoomList] = useState<RoomType[]>([]);
  const [practitionersList, setPractitionersList] = useState<PractitionersDetailsInterface[]>([]);

  const [isRoomListLoading, setIsRoomListLoading] = useState(true);
  const [isPractitionersListLoading, setIsPractitionersListLoading] = useState(true);
  const { isEdgeUserView, isEdgeAdminView, isEdgeUser, isEdgeReceptionist } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();

  const fetchPractitionersList = async (token: string) => {
    setIsPractitionersListLoading(true);
    let practitionerListData: any;

    if (isEdgeAdminView || isEdgeReceptionist) {
      const practitionersData = await getPractitionerListing(
        token,
        accountId,
        `status=active&withAccessRights=${[AccessRight.Admin, AccessRight.User, AccessRight.Mentor].join(',')}`
      );
      practitionerListData = await practitionersData.json();
    }
    let practiceDataMassage: any;
    if (isEdgeUserView) {
      const response = await getClinicianProfile(token);
      const data = await response.json();
      practiceDataMassage = {
        _id: isEdgeUserView ? auth0ClinicianId : '',
        name: data.name,
        avatar: data.avatar,
        mobileNumber: data.mobileNumber
      };
    } else {
      const practiceData = await getPracticeInfo(token, accountId);
      const data = await practiceData.json();
      practiceDataMassage = {
        _id: isEdgeUserView ? auth0ClinicianId : '',
        name: data.name,
        avatar: data.logo,
        mobileNumber: data.mobileNumber
      };
    }

    const massageData = [practiceDataMassage, ...(practitionerListData ? practitionerListData.practitionerList : [])];

    setPractitionersList(massageData);
    setIsPractitionersListLoading(false);
  };

  const fetchRoomList = async (token: string) => {
    setIsRoomListLoading(true);
    const response = await getRoomList(token);
    const data = await response.json();
    setRoomList(data);
    setIsRoomListLoading(false);
  };

  useEffect(() => {
    if (token) {
      if (isEdgeUser) {
        fetchPractitionersList(token);
      }
      fetchRoomList(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { practitionersList, isPractitionersListLoading, roomList, isRoomListLoading };
};
