import styles from './ClientOrGroupInfo.module.scss';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { getName } from 'utils/general';
import { PatientAssessment } from 'pages/Assessments/components/SendAdhocAssessmentModal/interfaces';

interface ClientOrGroupInfoProps {
  patientAssessments?: PatientAssessment[];
  group?: GroupsFromAPI;
  selected?: string[];
  participationType: ParticipantType;
}

const ClientOrGroupInfo = ({ group, selected, participationType, patientAssessments }: ClientOrGroupInfoProps) => {
  return (
    <div className={styles.container}>
      {participationType === ParticipantType.Group ? (
        <div className={styles.groupWrapper}>
          <div className={styles.groupInformation}>
            <div className={styles.groupName}>{group?.name}</div>
            <div className={styles.activeMembers}>{`${group?.clientCount} Active Members`}</div>
          </div>
          <div className={styles.selected}>
            {selected?.map((item, index) => (
              <div key={index} className={styles.selectedPill}>
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : (
        patientAssessments &&
        patientAssessments.map((patientAssessment, index) => {
          const { client, isSelected, selectedAssessments } = patientAssessment;
          return (
            isSelected &&
            selectedAssessments.length > 0 && (
              <div key={index} className={styles.profileWrapper}>
                <div className={styles.profile}>
                  <ClientProfileAvatar
                    avatarUrl={client.avatar}
                    initialsName={client.initials}
                    initialClassName={styles.initialAvatar}
                  />
                </div>
                <div className={styles.name}>{client && getName(client)}</div>
                <div className={styles.selected}>
                  {selectedAssessments.map((item, index) => (
                    <div key={index} className={styles.selectedPill}>
                      {item.shortCode}
                    </div>
                  ))}
                </div>
              </div>
            )
          );
        })
      )}
    </div>
  );
};

export default ClientOrGroupInfo;
