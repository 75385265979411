import { useEffect, useState } from 'react';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import { clientRecordsInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';

import Button from 'components/v2/Button/Button';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { patchReassignClient } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './PatientAssignment.module.scss';
import { notification, Modal } from 'antd';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useTranslation } from 'react-i18next';
import MentorSelect from '../MentorSelect/MentorSelect';
import Radio from 'components/Radio/Radio';

interface PatientAssignmentProps {
  recordId: string;
  clientRecordData: clientRecordsInterface;
  isClientRecordLoading: boolean;
}

const PatientAssignment = ({ isClientRecordLoading, clientRecordData, recordId }: PatientAssignmentProps) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [selectedCId, setSelectedCId] = useState(clientRecordData.clinicianAuth0Ids[0] || '');
  const [assessorId, setAssessorId] = useState(clientRecordData.assessorIds?.[0] || '');
  const [mentorId, setMentorId] = useState(clientRecordData.mentorIds?.[0] || '');
  const [linkTeacherToClassLead, setLinkTeacherToClassLead] = useState(
    clientRecordData.linkTeacherToAssessor ? 'notManual' : 'manual'
  );
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const { isMentorFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  useEffect(() => {
    if (!isClientRecordLoading) {
      setAssessorId(clientRecordData.assessorIds?.[0] || '');
      setMentorId(clientRecordData.mentorIds?.[0] || '');
      setSelectedCId(clientRecordData.clinicianAuth0Ids[0] || '');
    }
  }, [
    isClientRecordLoading,
    clientRecordData.assessorIds,
    clientRecordData.clinicianAuth0Ids,
    clientRecordData.mentorIds
  ]);

  const handleSelectPractitioner = (value?: { _id: string }) => {
    setSelectedCId(value?._id || '');
  };

  const handleSelectTeacher = (value?: { _id: string }) => {
    if (linkTeacherToClassLead === 'manual' && value?._id) {
      setAssessorId(value?._id);
    }
    setMentorId(value?._id || '');
  };

  const handleWarningModal = () => {
    if (clientRecordData.recordStatus === RecordStatus.Closed) {
      return;
    }
    Modal.confirm({
      title: 'Confirm save message',
      content: t('form.confirm.save_client_assignment'),

      onOk: async () => {
        setSaveStatus('active');
        try {
          const payload = {
            clinicianAuth0Ids: selectedCId ? [selectedCId] : [],
            assessorIds: assessorId && linkTeacherToClassLead === 'manual' ? [assessorId] : [],
            mentorIds: mentorId ? [mentorId] : [],
            linkTeacherToAssessor: linkTeacherToClassLead === 'notManual'
          };
          await patchReassignClient(token, accountId, recordId, payload);
          setSaveStatus('finished');

          // to reflect without refresh
          if (linkTeacherToClassLead === 'notManual') {
            setAssessorId(mentorId);
          }

          notification.success({
            message: t('form.success.save_client_assignment'),
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setTimeout(() => {
            setSaveStatus('');
          }, 500);
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: t('form.error.save_client_assignment'),
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setSaveStatus('');
        }
      }
    });
  };

  return (
    <div className={styles.boxContainer}>
      {isClientRecordLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.title}>Wellbeing Lead Assigned</div>
          <div className={styles.desc}>
            Set the primary wellbeing lead responsible for this student. They can view and add to this student's
            profile.
          </div>
          <div className={styles.listWrapper}>
            <ClinicianSelect
              className={styles.select}
              onSelect={handleSelectPractitioner}
              includePractice
              selectedId={selectedCId}
              disabled={clientRecordData.recordStatus === 'closed'}
              filterRoles={[AccessRight.User, AccessRight.Admin]}
              profileBadgeClassname={styles.profileBadgeClassName}
            />
          </div>

          {/* Still need update */}
          {isMentorFeatureToggle && (
            <>
              <div className={styles.title}>Class Lead Assigned</div>
              <div className={styles.desc}>Assessor relationship from your connected school MIS</div>
              <Radio
                name="teacherAssignment"
                options={[
                  { label: 'Based on Class Lead', value: 'notManual' },
                  { label: 'Control Manually', value: 'manual' }
                ]}
                value={linkTeacherToClassLead}
                onChange={(e) => setLinkTeacherToClassLead(e.target.value)}
                vertical={false}
              />
              <div className={styles.listWrapper}>
                <div>
                  <div className={styles.selectLabel}>
                    {linkTeacherToClassLead === 'notManual'
                      ? 'Class lead is designated assessor for the whole class'
                      : 'Class lead'}
                  </div>
                  <MentorSelect
                    className={styles.select}
                    onSelect={handleSelectTeacher}
                    selectedId={mentorId}
                    profileBadgeClassname={styles.profileBadgeClassName}
                    disabled
                  />
                </div>
              </div>
            </>
          )}
          {linkTeacherToClassLead === 'manual' && (
            <>
              <div className={styles.selectLabel}>Select Assessor</div>
              <div className={styles.listWrapper}>
                <ClinicianSelect
                  className={styles.select}
                  onSelect={(value) => {
                    setAssessorId(value?._id || '');
                  }}
                  selectedId={assessorId}
                  disabled={clientRecordData.recordStatus === 'closed'}
                  filterRoles={[AccessRight.Admin, AccessRight.Mentor, AccessRight.Receptionist, AccessRight.User]}
                  profileBadgeClassname={styles.profileBadgeClassName}
                  iconClassname={styles.dropdownIcon}
                />
              </div>
            </>
          )}

          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              type={'submit'}
              status={saveStatus}
              onClick={handleWarningModal}
              disabled={clientRecordData.recordStatus === RecordStatus.Closed}
            >
              Save Assignment Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientAssignment;
