import * as yup from 'yup';
import { ChildClientErrorInterface } from '../ChildClientInterface';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import i18n from 'i18n';

const childFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('validation.client_firstname.require')),
    lastName: yup.string().required(i18n.t('validation.client_lastname.require')),
    dateOfBirth: yup.string().test('Date of Birth', i18n.t('validation.dob.invalid'), (value) => {
      if (value && value?.length > 0) {
        return moment(value, MOMENTJS_DATE_FORMAT).format(MOMENTJS_DATE_FORMAT) === value;
      } else {
        return true;
      }
    })
  });

export const validationChildField = (clientVal: ChildClientErrorInterface) => {
  const validationErrors: ChildClientErrorInterface = {
    firstName: '',
    lastName: '',
    dateOfBirth: ''
  };

  try {
    childFormSchema().validateSync(clientVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof ChildClientErrorInterface] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
