import { notification } from 'antd';
import Select from 'components/Select/CommonSelect/Select';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { debounce } from 'lodash';
import { useState } from 'react';
import { updateAppointmentStatus } from 'utils/http/appointment';

import styles from './MarkStatus.module.scss';

export enum AppointmentStatusOption {
  Attended = 'Attended',
  DidNotAttend = 'Did Not Attend',
  ClientDidNotAttend = 'Client Did Not Attend',
  ClinicianDidNotAttend = 'Clinician Did Not Attend',
  RescheduledOnce = 'Rescheduled Once',
  RescheduledTwice = 'Rescheduled Twice',
  InvoiceSent = 'Invoice Sent',
  InvoicePaid = 'Invoice Paid',
  DepositPaid = 'Deposit Paid',
  DepositRequired = 'Deposit Required'
}

export const AppointmentMarkOption = Object.values(AppointmentStatusOption).map((obj) => ({
  label: obj,
  value: obj
}));

type MarkStatusProps = {
  appointment: AppointmentSlots;
  token: string;
  onFinishMarkStatus: () => void;
};

const MarkStatus = ({ appointment, token, onFinishMarkStatus }: MarkStatusProps) => {
  const [markedStatus, setMarkedStatus] = useState<string[]>(appointment.markedStatus || []);

  const handleMarkedStatusChange = async (status: string) => {
    if (status) {
      const cache = [...markedStatus];
      try {
        const newMarkedStatus = [...markedStatus, status];
        setMarkedStatus(newMarkedStatus);
        await updateAppointmentStatus(token, appointment._id, newMarkedStatus);
        onFinishMarkStatus();
      } catch (err) {
        setMarkedStatus([...cache]);
        console.error(err);
        notification.error({
          message: `Something went wrong while trying to add tags.`
        });
      }
    }
  };

  const handleRemoveStatus = async (status: string) => {
    if (status) {
      const cache = [...markedStatus];
      try {
        const foundStatusIdx = markedStatus.findIndex((stt) => stt === status);
        markedStatus.splice(foundStatusIdx, 1);
        setMarkedStatus([...markedStatus]);
        await updateAppointmentStatus(token, appointment._id, markedStatus);
        onFinishMarkStatus();
      } catch (err) {
        setMarkedStatus([...cache]);
        console.error(err);
        notification.error({
          message: `Something went wrong while trying to remove tags.`
        });
      }
    }
  };

  const addValueDebounce = debounce((value: string) => {
    handleMarkedStatusChange(value);
  }, 2000);

  const handleInputChange = (value: string) => {
    addValueDebounce(value);
  };

  return (
    <div>
      <div className={styles.markedStatusValueContainer}>
        {markedStatus?.map((status, index) => (
          <div className={styles.markedStatus} key={index}>
            {status}
            <div className={styles.iconContainer} onClick={() => handleRemoveStatus(status)}>
              <i className={`material-icons-outlined ${styles.icon}`}>close</i>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.label}>Mark appointment</div>
      <div className={styles.markAppointmentSelect}>
        <Select
          styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
          options={AppointmentMarkOption.filter((mark) => markedStatus?.indexOf(mark.value) === -1)}
          placeholder="Type here"
          value={''}
          closeMenuOnSelect={false}
          onChange={(status) => handleMarkedStatusChange(status?.value || '')}
          onInputChange={(value) => handleInputChange(value)}
        />
      </div>
    </div>
  );
};

export default MarkStatus;
