import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

import { getMCIAssessmentDetails } from 'store/CheckIn/GetMCIAssessmentDetails/action';
import { getMCIAssessmentQuestionTemplate } from 'store/CheckIn/GetMCIAssessmentQuestionTemplate/action';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import AssessmentsDetailsContent from './components/AssessmentsDetailsContent/AssessmentsDetailsContent';

import { MicroCheckInAssessmentStruct } from './Interface';

import styles from './MicroCheckInDetails.module.scss';

const MicroCheckInDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const path = useParams() as { assessmentId: string };
  const [isLoading, setLoading] = useState(true);

  const [assessmentData, setAssessmentData] = useState<MicroCheckInAssessmentStruct>();

  const [questionTemplate, setQuestionTemplate] = useState();

  const fetchAssessment = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    let callGetMCIDetails;

    if (path.assessmentId === 'newMCI') {
      callGetMCIDetails = {
        id: 'newMCI',
        name: '',
        description: '',
        assessmentType: 'microCheckIn',
        isDefault: false,
        isPublished: false,
        isExisting: false,
        questionSets: []
      };
    } else {
      callGetMCIDetails = await getMCIAssessmentDetails(token, path.assessmentId);
    }

    const callGetMCIQuestionTemplate = await getMCIAssessmentQuestionTemplate(token);

    if (callGetMCIDetails) {
      setAssessmentData(callGetMCIDetails as any);
    }

    if (callGetMCIQuestionTemplate) {
      setQuestionTemplate(callGetMCIQuestionTemplate.questionSets as any);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  return (
    <HelmetWrapper title={'CORDS - Micro Check In Assessments'}>
      <ContentLayout>
        {!isLoading ? (
          <div className={styles.container}>
            <AssessmentsDetailsContent
              data={assessmentData}
              isEdit={assessmentData?.isExisting || false}
              qTemplateData={questionTemplate}
            />
          </div>
        ) : (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default MicroCheckInDetails;
