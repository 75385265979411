import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import { useField } from 'formik';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { useFetchAvailableRoomList } from 'utils/hooks/GetRoomList/useFetchRoomList';
import styles from './Room.module.scss';

type RoomProps = {
  appointment: AppointmentSlots;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

const Room = ({ appointment, isEditing, setIsEditing }: RoomProps) => {
  const [, { value: roomId }, { setValue: setRoomId }] = useField('roomId');
  const [, { value: roomName }, { setValue: setRoomName }] = useField('roomName');
  const { availableRoomList, isAvailableRoomListLoading } = useFetchAvailableRoomList({
    date: appointment.date,
    startTime: appointment.startTime,
    endTime: appointment.endTime
  });

  const selectRoom = (id: string, name: string) => {
    setRoomId(id);
    setRoomName(name);
  };

  return (
    <div className={styles.eventInformationContainer}>
      <div className={styles.iconContainer}>
        <span className="material-icons-outlined">meeting_room</span>
      </div>
      <div className={styles.description}>
        {roomId ? (
          <>
            <div className={styles.descriptionText}>{roomName}</div>
            {appointment.deliveryType !== DeliveryType.BeSpoke.toString() && (
              <Button variant="link" onClick={() => setIsEditing(true)}>
                Change room
              </Button>
            )}
          </>
        ) : (
          appointment.deliveryType !== DeliveryType.BeSpoke.toString() && (
            <Button variant="link" onClick={() => setIsEditing(true)}>
              Add a room
            </Button>
          )
        )}
        {isEditing && isAvailableRoomListLoading && <LoadingDot />}
        {isEditing && !isAvailableRoomListLoading && (
          <>
            {appointment.room && (
              <Button
                variant={roomId === appointment.room.roomId ? 'pill-active' : 'pill'}
                icon={roomId === appointment.room.roomId ? 'done' : undefined}
                iconPostFix
                onClick={() => selectRoom(appointment.room?.roomId!, appointment.room?.roomName!)}
              >
                {appointment.room.roomName}
              </Button>
            )}
            {availableRoomList.map((room, index) => (
              <Button
                variant={roomId === room._id ? 'pill-active' : 'pill'}
                icon={roomId === room._id ? 'done' : undefined}
                iconPostFix
                onClick={() => selectRoom(room._id, room.name)}
                key={index}
              >
                {room.name}
              </Button>
            ))}
            {appointment.room || availableRoomList?.length ? (
              <Button variant="link" onClick={() => selectRoom('', '')} className={styles.noRoomNeeded}>
                No room needed
              </Button>
            ) : (
              <Button variant="link" onClick={() => setIsEditing(false)} className={styles.noRoomNeeded}>
                Sorry no rooms available
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Room;
