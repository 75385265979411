import styles from './ClientProfileAvatar.module.scss';
import classnames from 'classnames';

const CLIENT_AVATAR_COLOR = '#8AA8D3';
export interface ClientProfileAvatarProps {
  avatarUrl?: string;
  initialsName?: string;
  avatarClassName?: string;
  initialClassName?: string;
  statusCircle?: 'active' | 'inactive';
  fullAvatarBg?: boolean;
}

const ClientProfileAvatar = ({
  avatarUrl,
  initialsName,
  avatarClassName,
  initialClassName,
  statusCircle,
  fullAvatarBg
}: ClientProfileAvatarProps) =>
  avatarUrl ? (
    <img
      src={avatarUrl}
      className={classnames(
        styles.imgProfile,
        avatarClassName,
        statusCircle && styles[statusCircle],
        fullAvatarBg && styles.avatarBg
      )}
      alt={'profile'}
    />
  ) : (
    <div
      style={{ backgroundColor: CLIENT_AVATAR_COLOR }}
      className={classnames(styles.initialProfile, initialClassName, statusCircle && styles[statusCircle])}
    >
      {initialsName}
    </div>
  );

export default ClientProfileAvatar;
