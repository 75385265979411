import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getInitialOBAssessmentByRecordId } from 'utils/http/CheckInService/ReportList/reportList';
import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';

export const useFetchSurveyFormDetail = (token: string, clientRecordId: string, surveyResponseId: string) => {
  const [surveyFormDetails, setSurveyFormDetails] = useState<FirstAssessment>();
  const [isSurveyFormDetailsLoading, setIsSurveyFormDetailsLoading] = useState(true);

  const fetchSurveyFormDetails = useCallback(
    async (token: string) => {
      try {
        setIsSurveyFormDetailsLoading(true);
        const getSurveyResponseById = await getInitialOBAssessmentByRecordId(token, clientRecordId, surveyResponseId);
        const survey = await getSurveyResponseById.json();
        setSurveyFormDetails(survey);

        setIsSurveyFormDetailsLoading(false);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your groups survey detail' });
      }
    },
    [clientRecordId, surveyResponseId]
  );

  useEffect(() => {
    if (token) {
      fetchSurveyFormDetails(token);
    }
  }, [fetchSurveyFormDetails, token]);

  return { surveyFormDetails, isSurveyFormDetailsLoading, fetchSurveyFormDetails };
};
