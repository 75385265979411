import classNames from 'classnames';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import Radio from 'components/v2/Radio/Radio';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useState } from 'react';

import styles from './SDQAssessmentList.module.scss';

const massageSDQSequence = (assessmentList: ClinicalAssessmentStruct[]) =>
  assessmentList.sort((a, b) => ((a.metadata?.sdqSequence || 1) < (b.metadata?.sdqSequence || 1) ? -1 : 1));

interface SDQAssessmentListProps {
  onSelect: () => void;
  onChange: (item: ClinicalAssessmentStruct) => void;
  assessmentList: ClinicalAssessmentStruct[];
  isAssessmentLoading: boolean;
  selectedItem?: ClinicalAssessmentStruct;
}

const SDQAssessmentList = ({
  onSelect,
  onChange,
  assessmentList,
  isAssessmentLoading,
  selectedItem
}: SDQAssessmentListProps) => {
  const [readAllClickedOnItem, setReadAllClickedOnItem] = useState<ClinicalAssessmentStruct>();
  const SDQAssessmentList = massageSDQSequence(
    assessmentList.filter((i) => i.metadata?.group?.toLocaleLowerCase() === 'sdq')
  );

  return isAssessmentLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.list}>
      <div className={styles.title}>Select which SDQ assessment</div>
      {SDQAssessmentList.map((item) => (
        <div className={classNames(styles.item, selectedItem === item && styles.active)} key={item.id}>
          <Radio
            className={styles.radio}
            label=""
            name="assessment"
            value={item.id}
            checked={selectedItem === item}
            onChange={() => {
              onChange(item);
            }}
          />
          <div
            onClick={() => {
              onChange(item);
            }}
            className={classNames(styles.optionDetails, readAllClickedOnItem === item && styles.readAllClicked)}
          >
            <div className={styles.title}>{item.title}</div>
            <div className={styles.description}>{item.description}</div>
            {readAllClickedOnItem !== item && (
              <div className={styles.readMore} onClick={() => setReadAllClickedOnItem(item)}>
                <i className={`material-icons ${styles.arrowIcon}`}>arrow_drop_down</i>
                Read all
              </div>
            )}
          </div>
        </div>
      ))}

      <div className={styles.nextButton}>
        <Button disabled={!selectedItem} onClick={() => selectedItem && onSelect()}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default SDQAssessmentList;
