import { notification } from 'antd';
import { WondeSchoolInfo } from 'pages/ControlPanel/Interfaces/MisConnection';
import { useCallback, useEffect, useState } from 'react';
import { getSchoolList } from 'utils/http/ClinicianProfileService/MisSchools/misSchools';

export const useFetchSchoolList = (token: string, query: string) => {
  const [schools, setSchools] = useState<WondeSchoolInfo[]>([]);
  const [isSchoolListLoading, setIsSchoolListLoading] = useState(false);

  const fetchSchoolList = useCallback(async (token: string, query: string) => {
    setIsSchoolListLoading(true);
    try {
      const schoolListResponse = await getSchoolList(token, query);
      const { schools: schoolList } = (await schoolListResponse.json()) as { schools: WondeSchoolInfo[] };

      setSchools(schoolList);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get school informations' });
    }
    setIsSchoolListLoading(false);
  }, []);

  useEffect(() => {
    if (token && query.length > 3) {
      fetchSchoolList(token, query);
    }
  }, [fetchSchoolList, token, query]);

  return { schools, isSchoolListLoading };
};
