import { DatePicker as AntdDatePicker } from 'antd';
import styles from './DatePicker.module.scss';
import moment, { Moment } from 'moment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

type DatePickerProps = {
  date?: string;
  dateFormat?: string;
  error?: boolean;
  onChangeValue: (value: Moment | null) => void;
};

const DATE_FORMAT = 'dddd, Do MMMM';

const DatePicker = ({ date, dateFormat = DATE_FORMAT, error, onChangeValue }: DatePickerProps) => {
  return (
    <div>
      <AntdDatePicker
        className={styles.picker}
        disabledDate={(current) => current && current < moment().startOf('day')}
        suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
        onChange={onChangeValue}
        allowClear={false}
        value={date ? moment(date, 'YYYY-MM-DD') : undefined}
        format={dateFormat}
      />
      <ErrorMessage className={styles.customTimeError} error={'Please select date'} visible={!!error} />
    </div>
  );
};

export default DatePicker;
