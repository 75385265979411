import { ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useField } from 'formik';

import { Question, Option } from '../../../../../../AssessmentInterfaces';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ScaleBox from './components/ScaleBox/ScaleBox';

import { scales as defaultScales } from './HexSliderData.json';

import styles from './HexSlider.module.scss';

interface HexSliderProps {
  question: Question;
  defaultPatientDetails?: { name: string; picture: string };
}

const HexSlider = ({ question, defaultPatientDetails }: HexSliderProps) => {
  const { user } = useAuth0();
  const [{ value: formValue }, { error, touched }, { setValue }] = useField(`${question.id}.value`);
  let scales = question.scales ?? defaultScales;

  const handleRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(e.target.value, 10);

    if (value) {
      setValue(value);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.scaleBarContainer}>
          {scales.map(({ key, colour, value }) => (
            <ScaleBox key={key} colour={colour} formValue={formValue} value={value} onChange={setValue} />
          ))}
        </div>
        <div className={styles.labelContainer}>
          <div className={styles.labelBox}>
            {(question.options as Option[])?.map(({ key, description }) => (
              <div key={key}>{description}</div>
            ))}
          </div>
        </div>
        <div className={styles.sliderContainer}>
          <div className={styles.slider}>
            <input
              className={styles.hexSliderRange}
              type="range"
              min={
                question.scales
                  ? question.scales?.reduce((prev, curr) => (prev.value < curr.value ? prev : curr)).value
                  : 1
              }
              max={
                question.scales
                  ? question.scales?.reduce((prev, curr) => (prev.value > curr.value ? prev : curr)).value
                  : 11
              }
              value={formValue}
              onChange={handleRangeChange}
            />
          </div>
          <div className={styles.pointerContainer} data-value={formValue}>
            <div className={styles.label}>
              <div className={styles.circleContainer}>
                <div className={styles.triangle} />
                <div className={styles.circle}>
                  <div className={styles.profileWrapper}>
                    <img
                      className={styles.profile}
                      src={user?.picture || defaultPatientDetails?.picture}
                      alt="Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default HexSlider;
