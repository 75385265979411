import React from 'react';
import styles from './Filters.module.scss';
import ClassFilter from './ClassFilter';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import GroupFilter from './GroupFilter';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import TeacherFilter from './TeacherFilter';
import { AccessRight, MinifiedClinician } from 'interfaces/Clients/clinician';

export const PER_PAGE = 50;

interface Props {
  classFilteredList: string[];
  setClassFilteredList: React.Dispatch<React.SetStateAction<string[]>>;

  groupFilteredList: GroupsFromAPI[];
  setGroupFilteredList: React.Dispatch<React.SetStateAction<GroupsFromAPI[]>>;

  teacherFilteredList: MinifiedClinician[];
  setTeacherFilteredList: React.Dispatch<React.SetStateAction<MinifiedClinician[]>>;

  assessorFilteredList: MinifiedClinician[];
  setAssessorFilteredList: React.Dispatch<React.SetStateAction<MinifiedClinician[]>>;

  resetPage: () => void;
}

const Filters = ({
  classFilteredList,
  setClassFilteredList,
  groupFilteredList,
  setGroupFilteredList,
  teacherFilteredList,
  setTeacherFilteredList,
  assessorFilteredList,
  setAssessorFilteredList,
  resetPage
}: Props) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const { accountId } = useGetAccountId();

  return (
    <div className={styles.filterWrapper}>
      <p className={styles.filterTitle}>FILTER BY:</p>
      <ClassFilter
        token={token}
        asAdmin={isEdgeAdminView}
        accountId={accountId}
        classFilteredList={classFilteredList}
        setClassFilteredList={setClassFilteredList}
        resetPage={resetPage}
      />
      <GroupFilter
        token={token}
        asUser={isEdgeUserView}
        accountId={accountId}
        groupFilteredList={groupFilteredList}
        setGroupFilteredList={setGroupFilteredList}
        resetPage={resetPage}
      />
      {isEdgeAdminView && (
        <>
          <TeacherFilter
            token={token}
            accountId={accountId}
            accessRights={[AccessRight.Mentor]}
            label="Teacher"
            teacherFilteredList={teacherFilteredList}
            setTeacherFilteredList={setTeacherFilteredList}
            resetPage={resetPage}
          />
          <TeacherFilter
            token={token}
            accountId={accountId}
            label="Assessor"
            teacherFilteredList={assessorFilteredList}
            setTeacherFilteredList={setAssessorFilteredList}
            resetPage={resetPage}
          />
        </>
      )}
    </div>
  );
};

export default Filters;
