import { ChangeEvent } from 'react';
import TimePicker from 'components/v2/TimePicker/TimePicker';
import classnames from 'classnames';
import { useField } from 'formik';
import moment from 'moment';

import styles from './DayTimeSelection.module.scss';
import './DayTimeSelection.scss';

const TIME_FORMAT = 'h:mm a';

interface DayTimeSelectionProps {
  day: string;
  onStartTimeAllClick: (newStartTime: string, endTime: string) => void;
  onEndTimeAllClick: (startTime: string, newEndTime: string) => void;
}

const DayTimeSelection = ({ day }: DayTimeSelectionProps) => {
  const [{ value: isActive }, , isActiveHelper] = useField(`dayTimeSelections.autoFit.${day}.isActive`);
  const [{ value: startTime }, metaStartTime, startTimeHelper] = useField(`dayTimeSelections.autoFit.${day}.startTime`);
  const [{ value: endTime }, metaEndTime, endTimeHelper] = useField(`dayTimeSelections.autoFit.${day}.endTime`);
  const [, { error }] = useField(`dayTimeSelections.autoFit.${day}`);

  const handleIsActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    isActiveHelper.setValue(e.target.checked);
  };

  const handleChange = (setValue: Function) => (selectedTime: string) => {
    const time = moment(selectedTime, TIME_FORMAT);

    if (time.isValid()) {
      setValue(time.format('HH:mm'));
    }
  };

  return (
    <div className={classnames(styles.container, isActive && styles.active)}>
      <div className={styles.checkboxContainer}>
        <input
          className={styles.checkbox}
          id={`checkbox-${day}-isActive`}
          type="checkbox"
          checked={isActive}
          onChange={handleIsActiveChange}
        />
        <label className={styles.label} htmlFor={`checkbox-${day}-isActive`}>
          {day}
        </label>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.timeContainer}>
          <div className={styles.startTimeContainer}>
            <TimePicker
              time={startTime}
              error={metaStartTime.touched && !!metaStartTime.error}
              onChange={handleChange(startTimeHelper.setValue)}
            />
          </div>
          <div className={styles.endTimeContainer}>
            <TimePicker
              time={endTime}
              error={metaEndTime.touched && !!metaEndTime.error}
              onChange={handleChange(endTimeHelper.setValue)}
            />
          </div>
        </div>
        {typeof error == 'string' && isActive && <div className={styles.errorText}>{error}</div>}
      </div>
    </div>
  );
};

export default DayTimeSelection;
