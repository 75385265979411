import { ConsentFormResponseList, GroupDetailsTab, GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import GroupPsychometricMenuList from './components/GroupPsychometricMenuList/GroupPsychometricMenuList';
import GroupSessionMenuList from './components/GroupSessionMenuList/GroupSessionMenuList';
import GroupSurveyFormMenuList from './components/GroupSurveyFormMenuList/GroupSurveyFormMenuList';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import GroupConsentFormMenuList from './components/GroupConsentFormMenuList/GroupConsentFormMenuList';
import { TFunction } from 'react-i18next';

export interface GroupSideMenuListProps {
  id: GroupDetailsTab;
  icon: string;
  label: string;
  desc: string | JSX.Element;
  isActive: boolean;
}

const IS_DEVELOPMENT = isDevelopmentENV();

export const groupSideMenu = ({
  groupId,
  contentId,
  childContentId,
  allowEditAssigned,
  psychometricList,
  psychometricLoading,
  surveyFormMenuList,
  surveyResponseListLoading,
  isEdgeReceptionist,
  consentFormMenuList,
  t
}: {
  groupId: string;
  contentId: string;
  childContentId: string;
  allowEditAssigned: boolean;
  psychometricList: GroupPsychometricMenu[];
  psychometricLoading: boolean;
  surveyFormMenuList: SurveyFormResponseList[];
  surveyResponseListLoading: boolean;
  isEdgeReceptionist: boolean;
  consentFormMenuList: ConsentFormResponseList[];
  t: TFunction;
}) => [
  {
    id: GroupDetailsTab.Details,
    icon: 'manage_accounts',
    label: t('sidemenu.group.details'),
    desc: t('sidemenu.group.details.description'),
    isActive: false
  },
  {
    id: GroupDetailsTab.Members,
    icon: 'supervised_user_circle',
    label: t('sidemenu.group.members'),
    desc: t('sidemenu.group.members.description'),
    isActive: false
  },
  {
    id: GroupDetailsTab.Sessions,
    icon: 'event',
    label: t('sidemenu.group.sessions'),
    desc: <GroupSessionMenuList groupId={groupId} activeId={contentId} isLoading={false} />,
    isActive: false
  },
  {
    id: GroupDetailsTab.Psychometrics,
    icon: 'assessment',
    label: t('sidemenu.group.psychometrics'),
    desc: (
      <GroupPsychometricMenuList
        groupId={groupId}
        activeId={contentId}
        childActiveId={childContentId}
        isLoading={psychometricLoading}
        psychometricList={psychometricList}
      />
    ),
    isActive: false
  },
  {
    id: GroupDetailsTab.Survey,
    icon: 'grading',
    label: t('sidemenu.group.survey'),
    desc: (
      <GroupSurveyFormMenuList
        groupId={groupId}
        activeId={contentId}
        isLoading={surveyResponseListLoading}
        surveyFormMenuList={surveyFormMenuList}
      />
    ),
    isActive: false
  },
  ...(IS_DEVELOPMENT
    ? [
        {
          id: GroupDetailsTab.Consent,
          icon: 'grading',
          label: t('sidemenu.group.consent'),
          desc: (
            <GroupConsentFormMenuList
              groupId={groupId}
              activeId={contentId}
              isLoading={false}
              consentFormMenuList={consentFormMenuList}
            />
          ),
          isActive: false
        }
      ]
    : []),
  ...(!isEdgeReceptionist
    ? [
        {
          id: GroupDetailsTab.CaseNotes,
          icon: 'folder_open',
          label: t('sidemenu.group.case_notes'),
          desc: t('sidemenu.group.case_notes.description'),
          isActive: false
        },
        {
          id: GroupDetailsTab.Attachments,
          icon: 'attach_file',
          label: t('sidemenu.group.attachments'),
          desc: t('sidemenu.group.attachments.description'),
          isActive: false
        }
      ]
    : []),
  {
    id: GroupDetailsTab.Contacts,
    icon: 'contacts',
    label: t('sidemenu.group.contacts'),
    desc: t('sidemenu.group.contacts.description'),
    isActive: false
  },
  ...(allowEditAssigned
    ? [
        {
          id: GroupDetailsTab.PractitionerAssigned,
          icon: 'account_tree',
          label: t('sidemenu.group.practitioner_assigned'),
          desc: t('sidemenu.group.practitioner_assigned.description'),
          isActive: false
        }
      ]
    : []),
  ...(!isEdgeReceptionist
    ? [
        {
          id: GroupDetailsTab.PathwayManagement,
          icon: 'call_split',
          label: t('sidemenu.group.pathway_management'),
          desc: t('sidemenu.group.pathway_management.description'),
          isActive: false
        }
      ]
    : [])
];
