import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { RoomType } from 'interfaces/Schedule/Room';
import { getRoom } from 'utils/http/ScheduleService/Rooms/rooms';

export const useFetchRoom = (roomId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const [room, setRoom] = useState<RoomType>();
  const [isRoomLoading, setIsRoomLoading] = useState(true);

  const fetchRoom = async () => {
    setIsRoomLoading(true);
    if (roomId) {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const response = await getRoom(token, roomId);
      const data = await response.json();

      setRoom(data);
    } else {
      setRoom(undefined);
    }

    setIsRoomLoading(false);
  };

  useEffect(() => {
    fetchRoom();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  return { room, isRoomLoading };
};
