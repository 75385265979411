import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import styles from './CaseNoteHeaderImageBtn.module.scss';
import AddAssessmentModal from '../AddAssessmentModal/AddAssessmentModal';
import AddJournalDetailModal from './components/AddJournalDetailModal/AddJournalDetailModal';
import AddQuickNoteModal from '../AddQuickNoteModal/AddQuickNoteModal';
import {
  deleteCaseNoteHeadingTemplate,
  postCaseNoteHeadingTemplate
} from 'utils/http/DocumentService/CaseNotes/caseNotes';
import HeadingTemplateBuilder from 'components/HeadingTemplate/HeadingTemplateBuilder/HeadingTemplateBuilder';
import HeadingTemplateList from 'components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';
import { HeadingTemplate } from 'components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

interface CaseNoteHeaderImageBtnProps {
  recordId: string;
  profileId: string;
  caseNoteBodyVal: string;
  onChangeBody: any;
  caseNoteHeadingTemplates: HeadingTemplate[];
  onDeleteTemplate: (headingTemplateId: string) => void;
  onSubmitTemplate: (newHeadingTemplate: HeadingTemplate) => void;
  token: string;
}

const CaseNoteHeaderImageBtn = ({
  recordId,
  profileId,
  caseNoteBodyVal,
  onChangeBody,
  caseNoteHeadingTemplates,
  token,
  onDeleteTemplate,
  onSubmitTemplate
}: CaseNoteHeaderImageBtnProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const node = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();

  const [isAddAssessmentModalVisible, setIsAddAssessmentModalVisible] = useState(false);
  const [isAddJournalDetailModalVisible, setIsAddJournalDetailModalVisible] = useState(false);
  const [isAddQuickNoteModalVisible, setIsAddQuickNoteModalVisible] = useState(false);
  const [showHeadingTemplate, setShowHeadingTemplate] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleAddAssessmentModalCancel = (newAssessmentImages?: string) => {
    setIsAddAssessmentModalVisible(false);
    newAssessmentImages && onChangeBody(caseNoteBodyVal + newAssessmentImages);
  };

  const handleAddJournalDetailModalCancel = (newJournalDetailImages?: string) => {
    setIsAddJournalDetailModalVisible(false);
    newJournalDetailImages && onChangeBody(caseNoteBodyVal + newJournalDetailImages);
  };

  const handleAddQuickNoteModalCancel = (newQuickNoteImages?: string) => {
    setIsAddQuickNoteModalVisible(false);
    newQuickNoteImages && onChangeBody(caseNoteBodyVal + newQuickNoteImages);
  };

  const handleDropDownMenu = (typeVal: string) => {
    switch (typeVal) {
      case 'assessmentModal':
        setIsAddAssessmentModalVisible(true);
        break;
      case 'journalDetailModal':
        setIsAddJournalDetailModalVisible(true);
        break;
      case 'quickNoteModal':
        setIsAddQuickNoteModalVisible(true);
        break;
    }
    setShowMenu(false);
  };

  const handleAddImageClick = () => {
    setShowMenu(false);
    const addImageButton = document.querySelector<HTMLButtonElement>('.ql-image');
    addImageButton?.click?.();
  };

  const onShowingTemplateModal = () => {
    setShowHeadingTemplate(true);
  };

  const handleSubmitTemplate = async (templateName: string, templateBody: string) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      }).catch(() => '');

      const payload = {
        title: templateName,
        body: templateBody
      };
      const callPostHeadingTemplate = await postCaseNoteHeadingTemplate(token, payload);
      const createdHeadingTemplate = await callPostHeadingTemplate.json();

      onSubmitTemplate(createdHeadingTemplate);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to create this template' });
    }
  };

  const handleRemoveTemplateItem = async (templateId: string) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      }).catch(() => '');

      await deleteCaseNoteHeadingTemplate(templateId, token);

      onDeleteTemplate(templateId);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to delete this template' });
    }
  };

  const handleSelectTemplate = (selectedHeadingTemplate: HeadingTemplate) => {
    onChangeBody(`${caseNoteBodyVal}${selectedHeadingTemplate.body}`);
  };

  return (
    <>
      <AddAssessmentModal
        recordId={recordId}
        visible={isAddAssessmentModalVisible}
        onCancel={handleAddAssessmentModalCancel}
      />
      <AddJournalDetailModal
        recordId={recordId}
        profileId={profileId}
        visible={isAddJournalDetailModalVisible}
        onCancel={handleAddJournalDetailModalCancel}
      />
      <AddQuickNoteModal
        recordId={recordId}
        visible={isAddQuickNoteModalVisible}
        onCancel={handleAddQuickNoteModalCancel}
      />
      {profileId && (
        <div className={styles.headingTemplateContainer}>
          <HeadingTemplateList
            onSelectTemplate={handleSelectTemplate}
            templateData={caseNoteHeadingTemplates}
            onShowHeadingTemplate={onShowingTemplateModal}
            recordId={recordId}
            profileId={profileId}
            onRemoveTemplateItem={handleRemoveTemplateItem}
            token={token}
          />
          <HeadingTemplateBuilder
            onCloseModal={() => setShowHeadingTemplate(false)}
            onSubmitTemplate={handleSubmitTemplate}
            visible={showHeadingTemplate}
          />
        </div>
      )}
      <div ref={node} className={styles.rightSection}>
        <div className={styles.insertWidgetsButton} onClick={() => setShowMenu(!showMenu)}>
          <i className={`material-icons ${styles.icon}`}>perm_media</i>
          Insert data or images
        </div>
        <div className={styles.menuWrapper}>
          <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
            <div className={styles.listBox} onClick={() => handleDropDownMenu('assessmentModal')}>
              <div className={styles.listTitle}>{t('client_case_notes.assessment_summary')}</div>
              <div className={styles.listDesc}>{t('client_case_notes.assessment_summary.description')}</div>
            </div>
            <div className={styles.listBox} onClick={() => handleDropDownMenu('journalDetailModal')}>
              <div className={styles.listTitle}>{t('client_case_notes.journal_detail')}</div>
              <div className={styles.listDesc}>{t('client_case_notes.journal_detail.description')}</div>
            </div>
            <div className={styles.listBox} onClick={() => handleDropDownMenu('quickNoteModal')}>
              <div className={styles.listTitle}>{t('client_case_notes.quick_note')}</div>
              <div className={styles.listDesc}>{t('client_case_notes.quick_note.description')}</div>
            </div>
            <div className={styles.listBox} onClick={handleAddImageClick}>
              <div className={styles.listTitle}>{t('client_case_notes.add_image')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseNoteHeaderImageBtn;
