import styles from './SessionParticipantType.module.scss';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';

interface SessionParticipantTypeProps {
  participantType: AppointmentType['participantType'];
}

const SessionParticipantType = ({ participantType }: SessionParticipantTypeProps) => {
  const renderParticipantType = () => {
    switch (participantType) {
      case ParticipantType.OneToOne:
        return {
          icon: 'person',
          label: 'One to one appointment'
        };
      case ParticipantType.Group:
        return {
          icon: 'groups',
          label: 'Group appointment'
        };
      default:
        return {
          icon: 'person',
          label: 'One to one appointment'
        };
    }
  };

  const { icon, label } = renderParticipantType();

  return (
    <div className={styles.container}>
      <div className={styles.label}>APPOINTMENT TYPE</div>
      <div className={styles.participation}>
        <i className={`material-icons ${styles.icon}`}>{icon}</i>
        {label}
      </div>
    </div>
  );
};

export default SessionParticipantType;
