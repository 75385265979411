import classNames from 'classnames';
import { ChangeEvent } from 'react';
import styles from './Radio.module.scss';

type RadioProps = {
  name: string;
  value: string;
  checked: boolean;
  label: string;
  labelClass?: string;
  className?: string;
  onChange: (value: string) => void;
};

const Radio = ({ name, checked, value, label, onChange, className, labelClass }: RadioProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange(e.target.value);
    }
  };
  return (
    <label className={classNames(styles.container, className)}>
      <input type="radio" name={name} onChange={handleChange} checked={checked} value={value} />
      <div className={classNames(styles.label, labelClass)}>{label}</div>
    </label>
  );
};

export default Radio;
