import { DatePicker } from 'antd';
import classnames from 'classnames';
import moment, { Moment } from 'moment';

import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import {
  ActionPlanAssessment,
  ActionPlanAttachment,
  ActionPlanFormInterface,
  ActionPlanStatus,
  ActionPlanTaskItem
} from '../../interfaces';

import { statusOptions, progressOption } from '../../constants';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import PillButtonGroup from 'components/v2/PIllButtonGroup/PIllButtonGroup';
import StatusSelector from 'components/v2/StatusSelector/StatusSelector';
import ActionPlanAttachments from './components/ActionPlanAttachments/ActionPlanAttachments';
import TaskList from './components/TaskList/TaskList';

import styles from './ActionPlanForm.module.scss';
import Select from 'components/Select/CommonSelect/Select';
import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';
import InterventionList, { ActionPlanInterventionItem } from './components/InterventionList/InterventionList';

interface ActionPlanFormProps {
  actionPlan: ActionPlanFormInterface;
  clientRecordId: string;
  assessmentList?: ClinicalAssessmentResponseList[];
  errorMessage: {
    title: string;
    ownerAuth0Id: string;
    dueDate: string;
    tasksAndInterventions: string;
    interventions?: string;
  };
  checkIsTaskValid: (task: ActionPlanTaskItem, index: number) => boolean;
  checkIsInterventionValid: (task: ActionPlanInterventionItem, index: number) => boolean;
  onChangeValues: (
    key: keyof ActionPlanFormInterface,
    value: ActionPlanFormInterface[keyof ActionPlanFormInterface]
  ) => void;
  onChangeUploadingAttachments: (value: boolean) => void;
}

const ActionPlanForm = ({
  actionPlan,
  clientRecordId,
  errorMessage,
  assessmentList,
  checkIsTaskValid,
  checkIsInterventionValid,
  onChangeValues,
  onChangeUploadingAttachments
}: ActionPlanFormProps) => {
  const handleSelectEventOwner = (owner: PractitionersDetailsInterface | undefined) => {
    if (owner) {
      onChangeValues('ownerAuth0Id', owner._id);
      return;
    }
  };

  const handleDueDateChange = (value: Moment | null) => {
    if (value) {
      const dateString = value.format('YYYY-MM-DD');
      onChangeValues('dueDate', dateString);
    } else {
      onChangeValues('dueDate', '');
    }
  };

  const handleAssessmentsChange = (e: unknown) => {
    const val = e as { label: string; value: string }[];
    onChangeValues(
      'attachedAssessments',
      val?.map((item) => {
        const selectedAssessment = assessmentList?.find(({ _id }) => _id === item.value);
        return {
          id: selectedAssessment?.id,
          clinicalAssessmentResponseId: item.value,
          createdAt: selectedAssessment?.createdAt
        } as ActionPlanAssessment;
      }) || []
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.content}>
          <div className={styles.title}>PLAN DETAILS</div>
          <div className={styles.nameWrapper}>
            <div className={classnames(styles.fieldContainer, errorMessage.title && styles.fieldError)}>
              <MaterialInput
                id={`actionPlanTitle`}
                label={'TITLE'}
                value={actionPlan.title}
                required
                onChange={(e) => onChangeValues('title', e.target.value)}
              />
              <div className={styles.fieldError}>{errorMessage.title}</div>
            </div>
          </div>
          <div className={styles.statusWrapper}>
            <div className={styles.label}>STATUS</div>
            <PillButtonGroup
              options={statusOptions}
              value={actionPlan.status}
              onChange={(val) => onChangeValues('status', val)}
            />
          </div>
          {actionPlan.status === ActionPlanStatus.Active && (
            <div className={styles.progressWrapper}>
              <div className={styles.label}>PROGRESS</div>
              <StatusSelector
                options={progressOption}
                selectedValue={actionPlan.progress}
                onSelect={(e) => onChangeValues('progress', e)}
              />
            </div>
          )}
          <div className={styles.descriptionWrapper}>
            <div className={styles.label}>DESCRIPTION</div>
            <textarea
              className={styles.textarea}
              value={actionPlan.description}
              placeholder={'Please fill in the plan description'}
              onChange={(e) => onChangeValues('description', e.target.value)}
            />
          </div>
          <div className={styles.dueDateWrapper}>
            <div className={styles.label}>REVIEW/DUE DATE</div>
            <DatePicker
              format={'DD MMM YYYY'}
              className={styles.dateInput}
              suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
              value={actionPlan.dueDate ? moment(actionPlan.dueDate, 'YYYY-MM-DD') : undefined}
              onChange={(e) => handleDueDateChange(e)}
            />
            <ErrorMessage
              className={styles.errorMargin}
              visible={!!errorMessage.dueDate}
              error={errorMessage.dueDate}
            />
          </div>
          <div className={styles.ownerWrapper}>
            <div className={styles.label}>OWNER</div>
            <ClinicianSelect
              includePractice={false}
              filterRoles={[AccessRight.Admin, AccessRight.User]}
              profileBadgeClassname={styles.profileBadgeStyle}
              onSelect={handleSelectEventOwner}
              selectedId={actionPlan.ownerAuth0Id}
            />
            <ErrorMessage
              className={styles.customTimeError}
              error={errorMessage.ownerAuth0Id}
              visible={!!errorMessage.ownerAuth0Id}
            />
          </div>
          <div className={styles.assessmentsWrapper}>
            <div className={styles.label}>ATTACHED WITH ASSESSMENT</div>
            {!actionPlan._id ? (
              <Select
                isMulti
                options={assessmentList?.map((item) => ({
                  label: `${item.id} ${moment(item.createdAt).format('DD/MM/YYYY')}`,
                  value: item._id
                }))}
                placeholder={'Select assessment'}
                onChange={handleAssessmentsChange}
                isSearchable={false}
                isClearable={false}
                smallCaretDown
                smallCaretDownClass={styles.smallCaretDownClass}
                styles={{
                  container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
                  valueContainer: (base: any) => ({
                    ...base,
                    minHeight: '30px',
                    padding: '8px'
                  }),
                  control: (controlBase: any) => ({
                    ...controlBase,
                    minHeight: '54px',
                    backgroundColor: 'transparent',
                    border: `1px solid ${styles.greyColor}`,
                    borderRadius: '8px',
                    boxShadow: 'none'
                  }),
                  singleValue: (singleValueBase: any) => ({
                    ...singleValueBase,
                    fontSize: '10px',
                    lineHeight: '22px'
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: styles.blueColor,
                    borderRadius: 25,
                    color: styles.whiteColor,
                    padding: 4,
                    fontSize: '14px'
                  })
                }}
              />
            ) : actionPlan.attachedAssessments.length > 0 ? (
              actionPlan.attachedAssessments.map((item, index) => (
                <span key={index}>{`${index + 1}. ${item.id} ${moment(item.createdAt).format('DD/MM/YYYY')}`}</span>
              ))
            ) : (
              <span>No attached assessments</span>
            )}
          </div>
          <div className={styles.attachmentWrapper}>
            <div className={styles.label}>ATTACHMENTS</div>
            <ActionPlanAttachments
              attachments={actionPlan.attachments}
              clientRecordId={clientRecordId}
              onChangeAttachments={(val: ActionPlanAttachment[]) => onChangeValues('attachments', val)}
              onChangeUploadStatus={onChangeUploadingAttachments}
            />
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        {errorMessage.tasksAndInterventions && (
          <label className={styles.errorMessage}>{errorMessage.tasksAndInterventions}</label>
        )}
        <div className={styles.interventionListWrapper}>
          <div className={styles.label}>INTERVENTION LIST</div>
          <InterventionList
            isEdit={!!actionPlan._id}
            clientRecordId={clientRecordId}
            interventionList={actionPlan.interventions || []}
            onChange={(val) => onChangeValues('interventions', val)}
            checkIsValid={checkIsInterventionValid}
            attachedAssessments={actionPlan.attachedAssessments || []}
          />
        </div>
        <div className={styles.taskListWrapper}>
          <div className={styles.label}>TASK LIST</div>
          <TaskList
            taskList={actionPlan.tasks}
            onChangeTasks={(val) => onChangeValues('tasks', val)}
            checkIsTaskValid={checkIsTaskValid}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionPlanForm;
