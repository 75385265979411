import { notification } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { getCheckInInsights } from '../http/CheckInService/ClientGraphInsights/checkInInsights';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';

interface ChartData {
  date: string;
  uv: number;
}

const formatGraphData = (data: { date: string; value: number }[]): ChartData[] =>
  data.map(({ date, value }) => ({ date, uv: value }));

export const useFetchOverallCheckInGraph = (token: string, clientId: string, profileId?: string) => {
  const [overallCheckInGraph, setOverallCheckInGraph] = useState<ChartData[]>([]);
  const [isOverallCheckInGraphLoading, setIsOverallCheckInGraphLoading] = useState(true);

  const fetchOverallCheckInGraph = async (dateRange: number) => {
    setIsOverallCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      const queryParam = `${
        profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
      }&startDate=${startDate}&endDate=${endDate}&type=overall`;

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setOverallCheckInGraph(formatGraphData(insights));
      } else {
        throw new Error();
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to get this patient's overall check in insights"
      });
    }

    setIsOverallCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchOverallCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { overallCheckInGraph, isOverallCheckInGraphLoading, fetchOverallCheckInGraph };
};

export const useFetchEnergyCheckInGraph = (token: string, clientId: string, profileId?: string) => {
  const [energyCheckInGraph, setEnergyCheckInGraph] = useState<ChartData[]>([]);
  const [isEnergyCheckInGraphLoading, setIsEnergyCheckInGraphLoading] = useState(true);

  const fetchEnergyCheckInGraph = async (dateRange: number) => {
    setIsEnergyCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');
      const queryParam = `${
        profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
      }&startDate=${startDate}&endDate=${endDate}&type=energy`;

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setEnergyCheckInGraph(formatGraphData(insights));
      } else {
        throw new Error();
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to get this patient's energy check in insights"
      });
    }

    setIsEnergyCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchEnergyCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { energyCheckInGraph, isEnergyCheckInGraphLoading, fetchEnergyCheckInGraph };
};

export const useFetchSleepCheckInGraph = (token: string, clientId: string, profileId?: string) => {
  const [sleepCheckInGraph, setSleepCheckInGraph] = useState<ChartData[]>([]);
  const [isSleepCheckInGraphLoading, setIsSleepCheckInGraphLoading] = useState(true);

  const fetchSleepCheckInGraph = async (dateRange: number) => {
    setIsSleepCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');
      const queryParam = `${
        profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
      }&startDate=${startDate}&endDate=${endDate}&type=sleep`;

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setSleepCheckInGraph(formatGraphData(insights));
      } else {
        throw new Error();
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to get this patient's sleep check in insights"
      });
    }

    setIsSleepCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchSleepCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { sleepCheckInGraph, isSleepCheckInGraphLoading, fetchSleepCheckInGraph };
};

export const useFetchAppetiteCheckInGraph = (token: string, clientId: string, profileId?: string) => {
  const [appetiteCheckInGraph, setAppetiteCheckInGraph] = useState<ChartData[]>([]);
  const [isAppetiteCheckInGraphLoading, setIsAppetiteCheckInGraphLoading] = useState(true);

  const fetchAppetiteCheckInGraph = async (dateRange: number) => {
    setIsAppetiteCheckInGraphLoading(true);

    if (clientId) {
      try {
        const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');
        const queryParam = `${
          profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
        }&startDate=${startDate}&endDate=${endDate}&type=appetite`;

        const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
        const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

        if (insights) {
          setAppetiteCheckInGraph(formatGraphData(insights));
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({
          message: "Something went wrong while trying to get this patient's appetite check in insights"
        });
      }
    }

    setIsAppetiteCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppetiteCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appetiteCheckInGraph, isAppetiteCheckInGraphLoading, fetchAppetiteCheckInGraph };
};

export const useFetchAllGraph = (token: string, recordId: string, profileId: string) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [overallCheckInGraph, setOverallCheckInGraph] = useState<ChartData[]>([]);
  const [energyCheckInGraph, setEnergyCheckInGraph] = useState<ChartData[]>([]);
  const [appetiteCheckInGraph, setAppetiteCheckInGraph] = useState<ChartData[]>([]);
  const [sleepCheckInGraph, setSleepCheckInGraph] = useState<ChartData[]>([]);
  const [isAllGraphLoading, setAllGraphLoading] = useState(true);

  const getParam = (startDate: string, endDate: string, type: string) => {
    return `clientRecordId=${recordId}&clientProfileId=${profileId}&startDate=${startDate}&endDate=${endDate}&type=${type}`;
  };

  const fetchAllGraph = async (dateRange: number) => {
    setAllGraphLoading(true);

    if (profileId && !isEdgeReceptionist) {
      try {
        const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');
        const getOverallInsights = await getCheckInInsights(token, getParam(startDate, endDate, 'overall'));
        const getEnergyInsights = await getCheckInInsights(token, getParam(startDate, endDate, 'energy'));
        const getAppetiteInsights = await getCheckInInsights(token, getParam(startDate, endDate, 'appetite'));
        const getSleepInsights = await getCheckInInsights(token, getParam(startDate, endDate, 'sleep'));
        const overallInsights = await getOverallInsights.json();
        const energyInsights = await getEnergyInsights.json();
        const appetiteInsights = await getAppetiteInsights.json();
        const sleepInsights = await getSleepInsights.json();

        if (appetiteInsights) {
          setOverallCheckInGraph(formatGraphData(overallInsights.insights));
          setEnergyCheckInGraph(formatGraphData(energyInsights.insights));
          setAppetiteCheckInGraph(formatGraphData(appetiteInsights.insights));
          setSleepCheckInGraph(formatGraphData(sleepInsights.insights));
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to get all graph data'
        });
      }
    }

    setAllGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAllGraph(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    graphData: {
      overall: {
        title: 'Overall, how are you feeling',
        graphData: overallCheckInGraph
      },
      energy: {
        title: 'How are  your energy levels?',
        graphData: energyCheckInGraph
      },
      sleep: {
        title: 'How have you slept the last few nights?',
        graphData: sleepCheckInGraph
      },
      appetite: {
        title: 'How are your appetite and eating habits?',
        graphData: appetiteCheckInGraph
      }
    },
    isAllGraphLoading,
    fetchAllGraph
  };
};
