import LoadingDot from 'components/LoadingDot/LoadingDot';
import { useFetchAllClientProfileEncryptDetails } from 'utils/hooks/GetClient/clientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './PatientDetailsReferrers.module.scss';
import ReferralDocuments from './components/ReferralDocuments/ReferralDocuments';
import GPDetails from './components/GPDetails/GPDetails';
import KeyClientContacts from './components/KeyClientContacts/KeyClientContacts';
import ReferralInformation from './components/ReferralInformation/ReferralInformation';

interface PatientDetailsReferrersProps {
  recordId: string;
}

const PatientDetailsReferrers = (props: PatientDetailsReferrersProps) => {
  const { recordId } = props;

  const { token } = useGetAccessToken();
  const {
    clientEncryptDetails,
    clientEncryptDetailsLoading,
    fetchClientEncryptDetails
  } = useFetchAllClientProfileEncryptDetails(token, recordId);

  return (
    <div className={styles.referrersContainer}>
      {clientEncryptDetailsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <ReferralInformation
            clientRecordId={recordId}
            sourceTags={clientEncryptDetails.referral?.sourceTags || []}
            detail={clientEncryptDetails.referral?.detail || ''}
            date={clientEncryptDetails.referral?.date || ''}
            fetchClientEncryptDetails={fetchClientEncryptDetails}
          />
          <ReferralDocuments clientRecordData={clientEncryptDetails} />
          <GPDetails
            token={token}
            clientRecordId={recordId}
            generalPractitionerId={clientEncryptDetails.referral?.generalPractitionerId || ''}
          />
          <KeyClientContacts
            keyClientContacts={clientEncryptDetails.keyClientContacts || []}
            clientRecordId={recordId}
            fetchClientEncryptDetails={fetchClientEncryptDetails}
          />
        </>
      )}
    </div>
  );
};

export default PatientDetailsReferrers;
