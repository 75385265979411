export interface WondeSchoolInfo {
  id: string;
  name: string;
  establishmentNumber?: string;
  urn?: string;
  address?: string;
  postcode?: string;
}

export enum SchoolConnectionStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Connected = 'connected',
  Revoked = 'revoked'
}

export interface AutoSyncInfo {
  enabled: boolean;
  teacher: boolean;
  student: boolean;
  assignedTeacher: boolean;
}

export interface ConnectSchoolInfo extends WondeSchoolInfo {
  status: SchoolConnectionStatus;
  connectedBy?: string;
  connectedAt?: string;
  autoSync?: AutoSyncInfo;
}

export interface ContactInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  notes?: string;
}

export enum ImportDataType {
  Students = 'students',
  Teachers = 'teachers',
  AssignedTeacher = 'assignedTeacher'
}

export enum ImportDataStatus {
  Processing = 'processing',
  Success = 'success',
  Failed = 'failed'
}

export interface DataImportHistory {
  _id: string;
  type: ImportDataType;
  status: ImportDataStatus;
  count?: number;
  createdAt: string;
}
