import { MouseEvent, useEffect, useState } from 'react';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import { clientRecordsInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import { SurveyClientTab } from 'interfaces/Reports/report';

import Button from 'components/Button/Button';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SendConsentModal from './components/SendSurveyModal/SendSurveyModal';

import styles from './PatientDetailsSurveysList.module.scss';

interface PatientDetailsAssessmentListProps {
  recordId: string;
  currentTab: string;
  assessmentOrReportId?: string;
  clientRecordData: clientRecordsInterface;
  surveyList: SurveyClientTab[];
  isLoading: boolean;
}

const PatientDetailsSurveysList = ({
  recordId,
  currentTab,
  assessmentOrReportId,
  clientRecordData,
  surveyList,
  isLoading
}: PatientDetailsAssessmentListProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const [isSendSurveyModalVisible, setIsSendSurveyModalVisible] = useState(false);

  useEffect(() => {
    if (!assessmentOrReportId && !isLoading && surveyList && surveyList[0]?._id) {
      navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${surveyList[0]._id}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyList, isLoading]);

  const handleClickAssessment = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${id}`);
  };

  return (
    <>
      <SendConsentModal
        clientRecordId={recordId}
        clientProfileList={clientRecordData.clientProfiles}
        visible={isSendSurveyModalVisible}
        onCancel={() => setIsSendSurveyModalVisible(false)}
        isSingleClientProfile={!clientRecordData.clientProfiles.find((item) => item.role !== 'child')}
      />
      {isLoading ? (
        <LoadingCircle />
      ) : surveyList && surveyList.length > 0 ? (
        <div className={styles.container}>
          {surveyList.map(({ _id, name, createdAt }) => (
            <div id={_id} className={styles.link} key={_id} onClick={handleClickAssessment(_id)}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={assessmentOrReportId === _id ? SolidCircle : RegularCircle}
              />
              <div className={styles.label}>
                <span className={styles.name}>{name}</span>
                <span>{moment(createdAt).format('DD/MM/YY')}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>No survey created yet</>
      )}

      <Button
        className={styles.sendSurveyButton}
        variant={'secondary'}
        onClick={() => clientRecordData.recordStatus === RecordStatus.Closed || setIsSendSurveyModalVisible(true)}
        disabled={clientRecordData.recordStatus === RecordStatus.Closed}
      >
        <i className={`material-icons-outlined ${styles.icon}`}>send</i>
        Generate survey
      </Button>
    </>
  );
};

export default PatientDetailsSurveysList;
