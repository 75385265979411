import { useState } from 'react';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import ReportHeader from './components/ReportHeader/ReportHeader';

import ReportList from './components/ReportList/ReportList';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchReportList } from 'utils/hooks/reports';
import ReportCreateNewModal from './components/ReportCreateNewModal/ReportCreateNewModal';
import { useFetchClientRecordList } from 'utils/hooks/GetClient/clientList';

const ReportsList = () => {
  const { token } = useGetAccessToken();
  const { reportListData, isReportListDataLoading } = useFetchReportList(token);
  const { clientList } = useFetchClientRecordList(token);
  const [showCreateNewModal, setShowCreateNewModal] = useState(false);

  const createNewReport = () => {
    setShowCreateNewModal(true);
  };

  return (
    <HelmetWrapper title={'CORDS - Reports'}>
      <ContentLayout>
        <ReportHeader onClick={createNewReport} />
        <ReportList
          data={reportListData}
          reportDataLoading={isReportListDataLoading}
          onClickNewReport={createNewReport}
        />
        <ReportCreateNewModal
          clientRecordList={clientList}
          isShowCreateNewModal={showCreateNewModal}
          onCloseModal={() => setShowCreateNewModal(false)}
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportsList;
