import classnames from 'classnames';
import { startCase } from 'lodash';

import styles from './Status.module.scss';

interface StatusProps {
  status: string;
}

const Status = ({ status }: StatusProps) => (
  <div className={classnames(styles.container, styles[status])}>{startCase(status).toUpperCase()}</div>
);

export default Status;
