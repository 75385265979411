import { useEffect, useState } from 'react';

import styles from './ToggleSwitchV2.module.scss';

export interface toggleListType {
  id: string;
  label: string;
  count?: number;
  isActive: boolean;
}

interface ToggleSwitchV2Props {
  id: string;
  toggleList: toggleListType[];
  onChangeStatus: (value: toggleListType) => void;
  isLoading?: boolean;
}

const ToggleSwitchV2 = ({ id, toggleList, onChangeStatus, isLoading }: ToggleSwitchV2Props) => {
  const [indicatorLocation, setIndicatorLocation] = useState(0);
  const [switchList, setSwitchList] = useState(toggleList);

  useEffect(() => {
    setSwitchList(toggleList);
    const getCurrentActiveValue = toggleList.find((listObj) => listObj.isActive) as toggleListType;
    const indicatorLocation = parseFloat(`${(toggleList.indexOf(getCurrentActiveValue) / toggleList.length) * 100}`);
    setIndicatorLocation(indicatorLocation);
  }, [toggleList]);

  const pillWidth = 100 / toggleList.length;

  const mapActiveToggle = (selectedValue: toggleListType) => {
    return toggleList.map((obj) => {
      return {
        id: obj.id,
        label: obj.label,
        count: obj.count,
        isActive: selectedValue.id === obj.id
      };
    });
  };

  const handleChange = (selectedValue: toggleListType) => {
    const getCurrentActiveValue = switchList.find((listObj) => listObj.isActive);
    if ((getCurrentActiveValue && getCurrentActiveValue.id) !== selectedValue.id) {
      const indicatorLocation = parseFloat(`${(switchList.indexOf(selectedValue) / 3) * 100}`);
      setIndicatorLocation(indicatorLocation);
      const newData = mapActiveToggle(selectedValue);
      setSwitchList(newData);
      onChangeStatus(selectedValue);
    }
  };

  return (
    <div className={styles.container} id={id}>
      <div className={styles.wrapper}>
        {switchList.map((item, index) => (
          <span
            onClick={() => (!isLoading ? handleChange(item) : false)}
            key={index}
            className={isLoading ? styles.pillWrapperDisable : styles.pillWrapper}
            style={{ width: `${pillWidth}%` }}
          >
            <input type={'radio'} name={'option'} value={item.id} id={`toggleSwitch-${index}`} />
            <label htmlFor={`toggleSwitch-${index}`} className={item.isActive ? styles.labelActive : styles.label}>
              {item.label}
              {item.count !== undefined && <div className={styles.count}>{item.count}</div>}
            </label>
          </span>
        ))}
        <div style={{ width: `${pillWidth}%`, left: `${indicatorLocation}%` }} className={styles.indicator} />
      </div>
    </div>
  );
};

export default ToggleSwitchV2;
