import styles from './OccurrencesField.module.scss';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { RECURRING_APPOINTMENT_AMOUNT_OPTIONS } from 'pages/Appointments/constants';
import { getShortOrdinalNumber } from 'utils/general';
import { useField } from 'formik';
import { getLastRecurringAppointmentDate } from 'utils/appointment';

const OccurrencesField = () => {
  const [{ value: date }, ,] = useField('date');
  const [{ value: isRecurring }, ,] = useField('isRecurring');
  const [{ value: frequency }, ,] = useField('frequency');
  const [{ value: occurrences }, ,] = useField('occurrences');

  return (
    <div className={styles.container}>
      <FormikSelect
        name="occurrences"
        label="For how many occurrences"
        options={RECURRING_APPOINTMENT_AMOUNT_OPTIONS}
      />
      {isRecurring && frequency > 0 && occurrences > 0 && (
        <div className={styles.lastAppointmentDate}>
          {getShortOrdinalNumber(Number(occurrences) + 1)} appointment will be on{' '}
          {getLastRecurringAppointmentDate(date, frequency, occurrences)}
        </div>
      )}
    </div>
  );
};

export default OccurrencesField;
