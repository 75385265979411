import { ChangeEvent, InputHTMLAttributes, useMemo } from 'react';

import { Question, Option } from '../../../../../../AssessmentInterfaces';

import CheckboxGroup from './components/CheckboxGroup/CheckboxGroup';
import Radio from 'components/Radio/Radio';

import styles from './CheckboxRadio.module.scss';

interface CheckboxRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  question: Question;
  hasError?: boolean;
  value?: string | number | string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxRadio = ({ question, hasError, value, onChange, ...props }: CheckboxRadioProps) => {
  const { options, areOptionsLong, isCheckbox } = useMemo(() => {
    const renderOptions = {
      options: [] as { label: string; value: string | number }[],
      areOptionsLong: false,
      isCheckbox: question.maxSelection === 0
    };

    if (question.options) {
      renderOptions.options = (question.options as Option[]).map(({ description, key }) => ({
        label: description,
        value: key
      }));
      renderOptions.areOptionsLong =
        !(question.options as Option[]).every((option) => option.description.length < 25) &&
        question.options.length > 2;
    }

    return renderOptions;
  }, [question]);

  return isCheckbox ? (
    <CheckboxGroup
      name={question.id}
      options={options}
      value={value as string[]}
      onChange={onChange}
      hasError={hasError}
      {...props}
    />
  ) : (
    <Radio
      labelClassName={styles.radioLabel}
      variant="button"
      options={options}
      name={question.id}
      vertical={areOptionsLong}
      value={value as string | number}
      onChange={onChange}
      hasError={hasError}
      {...props}
    />
  );
};

export default CheckboxRadio;
