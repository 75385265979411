import { TabIds } from '../../Patient';
import { Skeleton } from 'antd';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './PatientHeader.module.scss';
import { useTranslation } from 'react-i18next';
import Button from 'components/v2/Button/Button';
import CORDSSendAssessmentModal from '../../../Assessments/components/CORDSSendAssessmentModal/CORDSSendAssessmentModal';
import React, { useState } from 'react';

interface PatientHeaderProps {
  total: number;
  isLoading: boolean;
  currentTabFilter: TabIds['tabId'];
  onAddClientClick: () => void;
  onAddProspectClient: () => void;
}

const PatientHeader = ({
  total,
  isLoading,
  currentTabFilter,
  onAddClientClick,
  onAddProspectClient
}: PatientHeaderProps) => {
  const { isEdgeUser } = useGetAccountPackageView();
  const [t] = useTranslation();
  const [sendAdhocAssessmentVisible, setSendAdhocAssessmentVisible] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          {isLoading ? (
            <Skeleton.Button active className={styles.loading} />
          ) : (
            <div className={styles.title}>{t('header.client', { count: total, status: currentTabFilter })}</div>
          )}
        </div>
      </div>
      <div className={styles.rightSection}>
        {currentTabFilter === 'prospect' && isEdgeUser ? (
          <div id="addNewProBtn" className={styles.addNewBtnWrapper} onClick={() => onAddProspectClient()}>
            <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
            {t('button.add_new_prospect_client')}
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.requestAssessmentBtn}
              variant={'secondary'}
              onClick={() => setSendAdhocAssessmentVisible(true)}
            >
              <i className={`material-icons ${styles.icon}`}>send</i>
              Request Assessment
            </Button>
            <CORDSSendAssessmentModal
              visible={sendAdhocAssessmentVisible}
              onClose={() => setSendAdhocAssessmentVisible(false)}
            />
            <div id="addNewBtn" className={styles.addNewBtnWrapper} onClick={() => onAddClientClick()}>
              <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
              {t('button.add_new_client')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientHeader;
