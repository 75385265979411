import SearchBar from 'components/SearchBar/SearchBar';
import React from 'react';
import styles from './AssessorAssessmentHeader.module.scss';

interface Props {
  handleSearch: (val: string) => void;
}
const AssessorAssessmentHeader = ({ handleSearch }: Props) => {
  return (
    <div className={styles.container}>
      <p>Your Assessments</p>
      <div className={styles.searchbarWrapper}>
        <SearchBar
          placeholder={'Search by student name or ID'}
          value={''}
          withSearchButton
          onSearch={handleSearch}
          label={'SEARCH FOR:'}
        />
      </div>
    </div>
  );
};

export default AssessorAssessmentHeader;
