import styles from './ChartToolTip.module.scss';

const ChartToolTip = ({ payload, active, formatter }: any) => {
  if (active && payload && payload.length > 0) {
    const actual = formatter ? formatter(payload[0].payload.uv) : Math.floor(payload[0].payload.uv);

    return <div className={styles.container}>{actual}</div>;
  }
  return null;
};

export default ChartToolTip;
