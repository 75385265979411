import { ChangeEvent } from 'react';

import styles from './CheckBox.module.scss';
import classnames from 'classnames';

interface AddClientCheckBoxProps {
  id: string;
  value: boolean;
  className?: string;
  inputClassName?: string;
  label?: any;
  labelClassName?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  disabledClassName?: string;
}

const CheckBox = ({
  id,
  value,
  className,
  inputClassName,
  onChange,
  label,
  labelClassName,
  disabled,
  disabledClassName
}: AddClientCheckBoxProps) => {
  return (
    <label className={classnames(disabled ? styles.disabledContainer : styles.container, className)} htmlFor={id}>
      <input
        disabled={disabled}
        className={classnames(styles.checkbox, disabled && disabledClassName, inputClassName)}
        id={id}
        type={'checkbox'}
        checked={value}
        onChange={onChange}
      />
      {label && <div className={classnames(styles.checkBoxLabel, labelClassName)}>{label}</div>}
    </label>
  );
};

export default CheckBox;
