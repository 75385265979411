import { BentResponse } from 'bent';

import { httpClient } from '../httpClient';

const url = process.env.REACT_APP_AUTH_SERVICE_URL;

const postAuth = httpClient('POST', url!, 201);

export const getEmailUsed = (email: string) =>
  httpClient(
    'GET',
    url!,
    200
  )(`/verification/check-email-used?email=${encodeURIComponent(email)}`) as Promise<BentResponse>;

export const createVerification = (body: { numberToVerify: string }) =>
  postAuth('/verification/create', body) as Promise<BentResponse>;

export const checkVerification = (body: { numberToVerify: string; code: string }) =>
  postAuth('/verification/check', body);

export const resendVerificationEmail = (body: { email: string; clientId: string }) =>
  postAuth('/resend-verification-email', body);
