import { useState } from 'react';

import styles from './SelectClientOrGroup.module.scss';
import ParticipationHeader from './components/ParticipationHeader/ParticipationHeader';
import { ParticipantType } from '../../interfaces/Schedule/AppointmentType';
import GroupList from './components/GroupList/GroupList';
import ClientRecordList from './components/ClientRecordList/ClientRecordList';
import { GroupsFromAPI, GroupStatus } from '../../pages/Groups/Interfaces/Groups';
import { clientRecordsInterface } from '../../interfaces/Clients/clientsRecord';
import { ClinicianListInterface } from '../v2/ClinicianSelect/ClinicianSelect';
import { useFetchGroupsAppointmentList } from './components/GroupList/hooks/getGroupMemberList';
import { useGetAccessToken } from '../../utils/hooks/token';
import { useFetchClientRecordByClinician } from '../v2/PatientSelect/hooks/GetClientList';

interface SelectClientOrGroupProps {
  selectedClinician?: Partial<ClinicianListInterface>;
  defaultRecordId?: string;
  defaultRecordIds?: string[];
  defaultGroupId?: string;
  defaultParticipationType?: ParticipantType;
  onChangeSelectedParticipationType?: (val: ParticipantType) => void;
  isListLoading?: (isLoadingVal: boolean) => void;
  handleSelectGroup?: (val: GroupsFromAPI, preventCloseModal?: boolean) => void;
  handleSelectClientRecord?: (val: clientRecordsInterface) => void;
  enableGroupSelection?: boolean;
  disableSelectionToggle?: boolean;
  showAllStatusClient?: boolean;
  descriptionRender?: (item: clientRecordsInterface) => string;
  isMulti?: boolean;
  communicationPreference?: string;
  filter?: (item: clientRecordsInterface) => boolean;
}

const SelectClientOrGroup = ({
  selectedClinician,
  defaultRecordId,
  defaultGroupId,
  defaultParticipationType,
  onChangeSelectedParticipationType,
  isListLoading,
  handleSelectGroup,
  handleSelectClientRecord,
  enableGroupSelection,
  disableSelectionToggle,
  showAllStatusClient,
  descriptionRender,
  isMulti,
  defaultRecordIds,
  communicationPreference,
  filter
}: SelectClientOrGroupProps) => {
  const { token } = useGetAccessToken();
  const [selectedParticipationType, setSelectedParticipationType] = useState<ParticipantType>(
    defaultParticipationType || ParticipantType.OneToOne
  );

  const { groupList, isGroupListLoading, sortByGroupName } = useFetchGroupsAppointmentList(
    token,
    selectedClinician?._id,
    GroupStatus.Active,
    enableGroupSelection
  );

  const {
    bookableClients,
    isBookableClientsLoading,
    sortClientsByName,
    fetchBookablePatients
  } = useFetchClientRecordByClinician(selectedClinician?._id, showAllStatusClient, communicationPreference);

  const [isSortAsc, setIsSortAsc] = useState(true);

  return (
    <div className={styles.container}>
      <ParticipationHeader
        selectedParticipantType={selectedParticipationType}
        handleChangeParticipation={(val) => {
          setSelectedParticipationType(val);
          onChangeSelectedParticipationType && onChangeSelectedParticipationType(val);
        }}
        selectedSort={isSortAsc}
        onChangeSort={(val: boolean) => {
          sortByGroupName(val);
          sortClientsByName(!val ? 'desc' : 'asc');
          setIsSortAsc(val);
        }}
        onSuccessAddNewClient={() => {
          fetchBookablePatients();
        }}
        enableGroupSelection={enableGroupSelection}
        disableToggle={disableSelectionToggle}
      />

      {selectedParticipationType === ParticipantType.Group && enableGroupSelection ? (
        <GroupList
          groupList={groupList.groupList}
          onSelectGroups={(val, preventCloseModal) => {
            handleSelectGroup && handleSelectGroup(val, preventCloseModal);
          }}
          selectedGroupId={defaultGroupId}
          onGroupListLoading={isListLoading}
          isLoading={isGroupListLoading}
        />
      ) : (
        <ClientRecordList
          clientRecords={bookableClients}
          selectedClientRecordId={defaultRecordId}
          handleSelectClientRecord={(val) => {
            handleSelectClientRecord && handleSelectClientRecord(val!);
          }}
          descriptionRender={descriptionRender}
          isMulti={isMulti}
          selectedClientRecordIds={defaultRecordIds}
          onClientListLoading={isListLoading}
          isLoading={isBookableClientsLoading}
          hideAvatar
          filter={filter}
        />
      )}
    </div>
  );
};

export default SelectClientOrGroup;
