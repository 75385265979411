import Radio from 'components/Radio/Radio';
import Button from 'components/v2/Button/Button';
import { useState } from 'react';

import styles from './ReviewSection.module.scss';

interface ReviewSectionProps {
  btnStatus: '' | 'active' | 'finished';
  onSubmitReview: ({ reviewType, message }: { reviewType: string; message?: string }) => void;
}

const ReviewSection = ({ btnStatus, onSubmitReview }: ReviewSectionProps) => {
  const [reviewType, setReviewType] = useState<'feedback' | 'approved' | 'changesRequested'>('feedback');
  const [message, setMessage] = useState('');

  const onClickSubmitReview = () => {
    const payload = message ?? undefined;
    onSubmitReview({ reviewType, message: payload });
  };

  return (
    <div className={styles.container}>
      <div>
        This document is <span className={styles.status}>In Review</span>.
      </div>
      <div>
        You can approve this document as is, make edits to it yourself and then approve, or return it to the author for
        revisions.
      </div>
      <div className={styles.reviewTypeContainer}>
        <div className={styles.reviewQuestion}>What is the outcome of your review?</div>
        <Radio
          className={styles.radioButton}
          options={[
            {
              value: 'feedback',
              label: 'Feedback',
              subLabel: "Submit feedback but don't approve"
            },
            {
              value: 'approved',
              label: 'Approve',
              subLabel: 'Submit feedback and approve document'
            },
            {
              value: 'changesRequested',
              label: 'Request changes',
              subLabel: 'Submit feedback that must be addressed'
            }
          ]}
          value={reviewType}
          labelClassName={styles.labelClass}
          onChange={(e) => setReviewType(e.target.value as 'feedback' | 'approved' | 'changesRequested')}
          alwaysShowSubLabel
          subLabelClassName={styles.subLabelClass}
        />
      </div>
      <div className={styles.feedbackCommentContainer}>
        <div className={styles.addComment}>Add a comment with your outcome to author:</div>
        <textarea className={styles.textarea} rows={6} value={message} onChange={(e) => setMessage(e.target.value)} />
      </div>
      <Button status={btnStatus} className={styles.button} onClick={onClickSubmitReview}>
        Submit Review
      </Button>
    </div>
  );
};

export default ReviewSection;
