import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

import styles from './WidgetWrapper.module.scss';

interface WidgetWrapperProps {
  id: number;
  children: any;
  position: any;
  dimensions: any;
  onClickRemoveBtn: any;
  onResizeWidget: any;
  allowDrag: boolean;
  data: any;
  allowAdjustHeight?: boolean;
}

export const WidgetWrapper = ({
  id,
  children,
  position,
  dimensions,
  onClickRemoveBtn,
  onResizeWidget,
  allowDrag,
  data,
  allowAdjustHeight
}: WidgetWrapperProps) => {
  const [isAbleDrag, setIsAbleDrag] = useState(allowDrag);
  const [itemDimensions, setItemDimensions] = useState(dimensions);
  useEffect(() => {
    setIsAbleDrag(allowDrag);
    setItemDimensions(dimensions);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowDrag, dimensions]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'insightsWidget',
    item: {
      id,
      position: { left: position.left, top: position.top },
      dimensions,
      type: 'insightsWidget',
      data
    },
    canDrag: allowDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const onClickResizeStart = () => {
    setIsAbleDrag(false);
  };

  const onClickResizeStop = (event: any, direction: any, htmlEle: any) => {
    event.preventDefault();
    onResizeWidget(id, htmlEle.clientWidth, allowAdjustHeight ? htmlEle.clientHeight : 'auto');
    setIsAbleDrag(true);
  };

  const onClickRemoveWidget = (e: any) => {
    e.preventDefault();
    onClickRemoveBtn(id);
  };

  return (
    <div
      className={allowDrag ? styles.DraggableContainer : styles.container}
      style={{
        left: position.left,
        top: position.top,
        width: itemDimensions.width,
        height: itemDimensions.height,
        zIndex: data.editMode || isDragging ? 100 : 1
      }}
    >
      <div className={styles.closeBtnWrapper}>
        <div className={styles.btnFixedWrap}>
          <div className={styles.closeButton}>
            <i onClick={(e) => onClickRemoveWidget(e)} className={`material-icons ${styles.closeIcon}`}>
              close
            </i>
            <div className={styles.closeLabel}>Delete component</div>
          </div>
        </div>
      </div>
      <div className={styles.widgetContainer} ref={isAbleDrag ? drag : null}>
        {!isDragging ? (
          <Resizable
            enable={{
              top: false,
              bottom: !!allowAdjustHeight,
              right: true,
              left: false,
              bottomRight: !!allowAdjustHeight
            }}
            onResizeStart={onClickResizeStart}
            minWidth={dimensions.minWidth}
            maxWidth={dimensions.maxWidth}
            size={{
              width: dimensions.width,
              height: dimensions.height
            }}
            defaultSize={{
              width: dimensions.width,
              height: dimensions.height
            }}
            onResizeStop={(e: MouseEvent | TouchEvent, direction: Direction, htmlEle: HTMLElement) =>
              onClickResizeStop(e, direction, htmlEle)
            }
            handleComponent={{
              right: <div className={isAbleDrag ? styles.resizeHandle : styles.hideResizeHandle} />
            }}
          >
            {children}
          </Resizable>
        ) : (
          <div ref={preview} className={styles.box}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
