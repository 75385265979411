import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { getActionPlanNotes } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { ActionPlanNote } from 'MHH/pages/ActionPlanCreator/interfaces';

export const useFetchActionPlanNotes = (token: string, recordId: string, actionPlanId: string) => {
  const [notes, setNotes] = useState<ActionPlanNote[]>([]);
  const [isNoteLoading, setIsNoteLoading] = useState(true);

  const fetchActionPlanNotes = useCallback(
    async (token: string, actionPlanId: string) => {
      setIsNoteLoading(true);
      try {
        if (!actionPlanId) {
          return;
        }
        const callActionPlans = await getActionPlanNotes({ token, clientRecordId: recordId, actionPlanId });

        const actionPlansData = await callActionPlans.json();

        if (actionPlansData && Array.isArray(actionPlansData)) {
          setNotes(actionPlansData);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get your plan notes.' });
      }

      setIsNoteLoading(false);
    },
    [recordId]
  );

  useEffect(() => {
    if (token) {
      fetchActionPlanNotes(token, actionPlanId);
    }
  }, [token, fetchActionPlanNotes, actionPlanId]);

  return { notes, isNoteLoading, setNotes, fetchActionPlanNotes };
};
