import TextEditorWidget from './components/TextEditorWidget/TextEditorWidget';
import styles from './TextEditorWrapper.module.scss';

interface TextEditorWrapperProps {
  onClickWidgetMenu: () => void;
}

const TextEditorWrapper = ({ onClickWidgetMenu }: TextEditorWrapperProps) => {
  return (
    <>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          <i className={`material-icons ${styles.titleIcon}`}>title</i>
          Click to add text
        </div>
        <div className={styles.insertTemplateBtn} onClick={onClickWidgetMenu}>
          Insert Template
          <i className={`material-icons ${styles.plusIcon}`}>add</i>
        </div>
      </div>
      <TextEditorWidget />
    </>
  );
};

export default TextEditorWrapper;
