import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

export const getCaseNotesByRecordId = (token: string, recordId: string, withContents?: boolean) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes${withContents ? '?withContents=1' : ''}`
  ) as Promise<BentResponse>;

export const getCaseNoteByCaseNoteId = (token: string, recordId: string, caseNoteId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes/${caseNoteId}`
  ) as Promise<BentResponse>;

export const postCaseNote = (token: string, recordId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes`,
    payload
  ) as Promise<BentResponse>;

export const putCaseNote = (token: string, recordId: string, caseNoteId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes/${caseNoteId}`,
    payload
  ) as Promise<BentResponse>;

export const deleteNote = (token: string, recordId: string, caseNoteId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes/${caseNoteId}`
  ) as Promise<BentResponse>;

export const getCaseNoteFormatAvailableSettingsByRecordId = (token: string, recordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes/formatSettings:availableSettings`
  ) as Promise<BentResponse>;

export const getCaseNoteFormatSettingsByRecordId = (token: string, recordId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes/formatSettings`
  ) as Promise<BentResponse>;

export const putCaseNoteFormatSettings = (token: string, recordId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, [201, 204], { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/case-notes/formatSettings`,
    payload
  );

export const getCaseNoteHeadingTemplates = (token: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    '/case-note-heading-templates'
  ) as Promise<BentResponse>;

export const postCaseNoteHeadingTemplate = (token: string, body: Record<string, any>) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })(
    '/case-note-heading-template',
    body
  ) as Promise<BentResponse>;

export const deleteCaseNoteHeadingTemplate = (templateId: any, token: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(`/case-note-heading-template/${templateId}`);
