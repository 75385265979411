import styles from './Label.module.scss';

interface LabelProps {
  label: string;
}

const Label = ({ label }: LabelProps) => (
  <div className={styles.container}>
    <div className={styles.label}>{label}</div>
  </div>
);

export default Label;
