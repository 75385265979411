import enUK from './en/en-uk.json';
import { getTerminology } from './en/terminology';

const resources = {
  en: {
    translations: enUK,
    terminology: getTerminology()
  }
} as const;

export default resources;
