export enum StageType {
  Sequential = 'sequential',
  Anytime = 'anytime'
}

export interface StageValidateField {
  name: string;
}

export interface PathwayStageItem {
  _id: string;
  stageType?: StageType;
  sequence?: number;
  name: string;
  description?: string;
  count: number;
}

export interface PathwayStageList {
  anytime: PathwayStageItem[];
  sequential: PathwayStageItem[];
}

export interface StageForm extends Omit<PathwayStageItem, '_id' | 'count'> {
  _id?: string;
}

export interface EditStageForm extends Omit<PathwayStageItem, 'count'> {}
