import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { DeliveryType } from './AppointmentType';
import { GroupDetails } from '../../pages/Groups/Interfaces/GroupsSession';
import { GroupsFromAPI } from '../../pages/Groups/Interfaces/Groups';

export enum AttendeeParticipationStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
  Unknown = 'unknown'
}

export enum ClientRescheduleRequestStatus {
  Pending = 'pending',
  Rejected = 'rejected'
}

export interface Attendee {
  clinicianId?: string;
  clientRecordId?: string;
  clientProfileId?: string;
  email?: string;
  participationStatus?: AttendeeParticipationStatus;
  name?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  avatar?: string;
}

export interface GetAppointmentsQueryParams {
  from: string;
  to: string;
  clinicianIds: string[];
}

export interface GetAppointmentsByClincianIdOrRoomIdQueryParams {
  from?: string;
  to?: string;
  roomIds: string[];
  clinicianIds: string[];
}

export interface AppointmentSlots {
  _id: string;
  accountId: string;
  clinicianId?: string;
  sessionTypeId?: string;
  sessionTypeName?: string;
  date?: string;
  startTime: string;
  endTime: string;
  availability?: 'open' | 'blocked' | 'busy' | 'booked' | 'request';
  deliveryType?: DeliveryType;
  clientRecord?: clientRecordsInterface;
  confirmation?: {
    clinician: boolean;
    patient: boolean;
  };
  deliveryOptions?: string[];
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  phoneCallInstructions?: string;
  rate?: number;
  gap?: number;
  bookingRules?: {
    new: {
      available: boolean;
      rule: 'instant' | 'request';
    };
    existing: {
      available: boolean;
      rule: 'instant' | 'request';
    };
  };
  bookingRule?: 'instant' | 'request';
  recurringAppointmentId?: string;
  room?: {
    roomId: string;
    roomName: string;
  };
  markedStatus?: string[];
  title: string;
  recurrings: any[];
  notes?: string;
  type: string;
  integration?: {
    profileName?: string;
  };
  attendees?: Attendee[];
  groupId?: string;
  groupDetails: GroupDetails;
  group: GroupsFromAPI;
  clientRescheduleDetails?: {
    count: number;
    status?: ClientRescheduleRequestStatus;
    date?: string;
    startTime?: string;
    endTime?: string;
  };
}
