import { useState } from 'react';
import { Modal } from 'antd';
import { toPng } from 'html-to-image';

import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';

import Button from 'components/v2/Button/Button';
import AssessmentList from './components/AssessmentList/AssessmentList';

import styles from './AddAssessmentModal.module.scss';

interface AddAssessmentModalProps {
  recordId: string;
  visible: boolean;
  onCancel: (newAssessmentImages?: string) => void;
}

const AddAssessmentModal = ({ recordId, visible, onCancel }: AddAssessmentModalProps) => {
  const [selectedAssessments, setSelectedAssessments] = useState<Assessment[]>([]);

  const [addButtonStatus, setAddButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSelectAssessment = (assessment: Assessment) => {
    if (selectedAssessments.map((assessment) => assessment._id).includes(assessment._id)) {
      setSelectedAssessments(
        selectedAssessments.filter((selectedAssessment) => selectedAssessment._id !== assessment._id)
      );
    } else {
      setSelectedAssessments([...selectedAssessments, assessment]);
    }
  };

  const handleAddAssessmentClick = async () => {
    setAddButtonStatus('active');

    let newAssessmentImages = '<div>';

    for (let i = 0; i < selectedAssessments.length; i++) {
      const element = document.getElementById(`add-assessment-card-${selectedAssessments[i]._id}`);

      if (element) {
        try {
          const dataUrl = await toPng(element);

          newAssessmentImages += `<img src="${dataUrl}" width="200"><br><br>`;
        } catch (ex) {
          console.error(ex);
        }
      }
    }

    newAssessmentImages += '</div>';

    setAddButtonStatus('finished');
    onCancel(newAssessmentImages);

    setTimeout(() => {
      setAddButtonStatus('');
      setSelectedAssessments([]);
    }, 500);
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      width={768}
      footer={null}
      visible={visible}
      onCancel={() => onCancel()}
      destroyOnClose
    >
      <div className={styles.header}>Add Assessment Summary</div>
      <div className={styles.content}>
        <div className={styles.description}>Select the assessments to embed this note</div>
        <AssessmentList
          recordId={recordId}
          selectedAssessments={selectedAssessments}
          onSelectAssessment={handleSelectAssessment}
        />
        <div className={styles.buttonContainer}>
          <Button className={styles.button} status={addButtonStatus} onClick={handleAddAssessmentClick}>
            Add Assessments to this note
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddAssessmentModal;
