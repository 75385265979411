import { useField } from 'formik';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import HiddenInput, { HiddenInputProps } from './HiddenInput';

interface FormikHiddenInputProps extends Omit<HiddenInputProps, 'hasError'> {
  id: string;
}

const FormikHiddenInput = ({ id, ...props }: FormikHiddenInputProps) => {
  const [field, { error, touched }] = useField(id);

  return (
    <div>
      <HiddenInput {...props} {...field} id={id} />
      <ErrorMessage error={error} visible={touched} />
    </div>
  );
};

export default FormikHiddenInput;
