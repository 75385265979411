import { Groups } from 'pages/Groups/Interfaces/Groups';
import styles from './ActiveGroupListCard.module.scss';
import ActiveGroupListingItem from './components/ActiveGroupListingItem';
import columnStyles from './GroupRowSection.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ActiveGroupListCardProps {
  groupListing: Groups[];
  searchValue: string;
}

const ActiveGroupListCard = ({ groupListing, searchValue }: ActiveGroupListCardProps) => {
  const { isGroupsTagsToggle } = useGetFeatureToggle();
  return groupListing.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <div className={columnStyles.groupNameSection}>Group Name</div>
        <div className={columnStyles.typeSection}>Type</div>
        <div className={columnStyles.createdSection}>Created</div>
        {isGroupsTagsToggle && <div className={columnStyles.groupTagSection}>Group Tag</div>}
        <div className={columnStyles.groupMemberSection}>Group Members</div>
        <div className={columnStyles.groupLeadSection}>Group Lead</div>
        <div className={columnStyles.descriptionSection}>Description</div>
        <div className={columnStyles.buttonSection} />
      </div>
      {groupListing.map((groupListingObj, index) => (
        <ActiveGroupListingItem key={index} groupListingItem={groupListingObj} searchValue={searchValue} />
      ))}
    </>
  ) : (
    <div>There is no data for the group</div>
  );
};

export default ActiveGroupListCard;
