import * as yup from 'yup';
import { ChildGuardianErrorInterface } from '../ChildGuardianInterface';
import { ClientDetails } from '../../../../../AddPatientModalV2Interface';
import i18n from 'i18n';

export interface ChildGuardianFormValidationInterface extends ClientDetails {
  isFirstGuardian: boolean;
}

const childGuardianFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('Please enter first name'),
    lastName: yup.string().required('Please enter last name'),
    role: yup.string().required(i18n.t('validation.client_role.require')),
    isPrimaryContact: yup.boolean(),
    email: yup.string().when('isFirstGuardian', {
      is: true,
      then: yup.string().email('Please enter a valid email address').required('Please enter email address'),
      otherwise: yup.string().email('Please enter a valid email address').nullable()
    }),
    mobileNumber: yup.string().when('isFirstGuardian', {
      is: true,
      then: yup.string().min(12, 'Mobile number must be 10 digits or longer').required('Please enter mobile number'),
      otherwise: yup.string().nullable()
    })
  });

export const validationChildGuardianField = (value: ChildGuardianFormValidationInterface) => {
  const validationErrors: ChildGuardianErrorInterface = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  };
  try {
    childGuardianFormSchema().validateSync(value, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path as keyof ChildGuardianErrorInterface] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
