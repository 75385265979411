import { useEffect, useRef, useState, FC, MouseEvent as ReactMouseEvent } from 'react';
import ProfileBadge from '../ProfileBadge/ProfileBadge';
import { useFetchPractitionerList } from './hooks/GetPractitionersList';
import { useGetAccessToken } from '../../../utils/hooks/token';
import styles from './ClinicianSelect.module.scss';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { Modal, Skeleton } from 'antd';
import classnames from 'classnames';
import { AccessRight } from 'interfaces/Clients/clinician';

export interface ClinicianListInterface {
  _id: string;
  name: string;
  avatar: string;
  accessRight: string;
}

export interface ClinicianSelectProps {
  onSelect: (clinician?: PractitionersDetailsInterface) => void;
  onRemove?: () => void;
  includePractice?: boolean;
  selectedId?: string;
  allowEmpty?: boolean;
  disabled?: boolean;
  excludedClinicianIds?: string[];
  customClinicianIds?: string[];
  backgroundColor?: string;
  filterRoles?: AccessRight[];
  removeWarningMessage?: string;
  profileBadgeClassname?: string;
  placeholder?: string;
  materialStyle?: boolean;
  hideAvatar?: boolean;
  className?: string;
  noInitChange?: boolean;
  icon?: string;
  iconClassname?: string;
}

const ClinicianSelect: FC<ClinicianSelectProps> = ({
  onSelect,
  onRemove,
  includePractice,
  selectedId,
  allowEmpty = false,
  excludedClinicianIds,
  customClinicianIds,
  disabled,
  backgroundColor,
  filterRoles,
  removeWarningMessage,
  profileBadgeClassname,
  placeholder,
  materialStyle,
  hideAvatar,
  className,
  noInitChange,
  icon,
  iconClassname
}) => {
  const { token } = useGetAccessToken();
  const node = useRef<HTMLDivElement>(null);
  const { practitionersList, isPractitionersListLoading } = useFetchPractitionerList(
    token,
    includePractice,
    filterRoles
  );
  const [selectedClinician, setSelectedClinician] = useState<ClinicianListInterface>();
  const [showList, setShowList] = useState(false);
  const [clinicianList, setClinicianList] = useState(practitionersList);

  const filteredClinicianList = customClinicianIds
    ? clinicianList.filter((practitioner) => customClinicianIds.includes(practitioner._id))
    : excludedClinicianIds
    ? clinicianList.filter((practitioner) => !excludedClinicianIds.includes(practitioner._id))
    : clinicianList;

  useEffect(() => {
    if (!isPractitionersListLoading) {
      if (allowEmpty && selectedId === undefined && selectedClinician !== undefined) {
        setSelectedClinician(undefined);
      } else if (selectedClinician === undefined || selectedClinician?._id !== selectedId) {
        const data = practitionersList.find((profile) => profile._id === (selectedId || ''));
        setSelectedClinician(data);
        !noInitChange && onSelect(data);
      }

      setClinicianList(practitionersList);
    }
  }, [
    isPractitionersListLoading,
    practitionersList,
    selectedClinician,
    selectedId,
    allowEmpty,
    noInitChange,
    onSelect
  ]);

  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof HTMLElement && node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleUnassignWarningModal = (event: ReactMouseEvent) => {
    Modal.confirm({
      title: removeWarningMessage,
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => {
        handleRemoveUser(event);
      }
    });
  };

  const handleSelectUser = (id?: string) => {
    const data = practitionersList.find((profile) => profile._id === id);
    if (data) {
      setSelectedClinician(data);
      onSelect(data);
    } else {
      setSelectedClinician(undefined);
      onSelect(undefined);
    }
    setShowList(false);
  };

  const handleRemoveUser = (event: ReactMouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    handleSelectUser(undefined);
    setShowList(false);
    if (onRemove) {
      onRemove();
    }
  };

  const handleChangeSearch = (value: string) => {
    const filterData = practitionersList.filter((obj) => obj.name.toLowerCase().includes(value.toLowerCase()));
    setClinicianList(filterData);
  };

  return (
    <div ref={node} className={classnames(styles.container, className)}>
      {isPractitionersListLoading ? (
        <div className={classnames(materialStyle ? styles.materialLoadingBox : styles.loadingBox)}>
          <Skeleton.Input active className={styles.skeletonLoading} />
        </div>
      ) : (
        <>
          <div
            className={classnames(
              materialStyle ? styles.selectedMaterialBox : styles.selectedBox,
              disabled && styles.disabled
            )}
            onClick={() => {
              !disabled && !(allowEmpty && selectedClinician) && setShowList(!showList);
            }}
            style={{ backgroundColor }}
          >
            {hideAvatar ? (
              <div>{selectedClinician?.name ?? ''}</div>
            ) : (
              <ProfileBadge
                className={profileBadgeClassname}
                name={selectedClinician?.name || placeholder}
                avatar={selectedClinician?.avatar}
              />
            )}
            {selectedClinician?._id && allowEmpty && !disabled ? (
              <span
                onClick={(e) => {
                  removeWarningMessage ? handleUnassignWarningModal(e) : handleRemoveUser(e);
                }}
                className={`material-icons-outlined ${styles.closeIcon}`}
              >
                close
              </span>
            ) : !disabled ? (
              <span className={classnames(`material-icons-outlined ${styles.icon}`, iconClassname)}>
                {icon || 'arrow_drop_down'}
              </span>
            ) : null}
          </div>
          <div className={styles.listWrapper}>
            <div className={showList ? styles.listShow : styles.listHide}>
              <div className={styles.searchBox}>
                <span className={`material-icons ${styles.searchIcon}`}>search</span>
                <input
                  type={'text'}
                  autoComplete={'off'}
                  className={styles.searchInput}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <div className={styles.itemWrapper}>
                {filteredClinicianList.map((profile) => (
                  <div
                    className={selectedClinician?._id === profile._id ? styles.itemSelected : styles.item}
                    key={profile._id}
                    onClick={() => handleSelectUser(profile._id)}
                  >
                    {hideAvatar ? (
                      <div>{profile.name}</div>
                    ) : (
                      <ProfileBadge className={profileBadgeClassname} name={profile.name} avatar={profile.avatar} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClinicianSelect;
