import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { putClientsRecord } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { EditClientDetails, editClientForm } from '../EditClientModalInterface';

const massageClientProfiles = (
  clientProfileData: editClientForm['clientProfiles'],
  recordType: editClientForm['recordType']
) => {
  let newCPList = [];
  for (let cpObj of clientProfileData) {
    newCPList.push({
      ...(!cpObj.isNewContact && {
        _id: cpObj._id
      }),
      firstName: cpObj.firstName,
      lastName: cpObj.lastName,
      ...(cpObj.dateOfBirth && {
        dateOfBirth: cpObj.dateOfBirth
      }),
      ...(typeof cpObj.isPrimaryContact === 'boolean' && {
        isPrimaryContact: cpObj.isPrimaryContact
      }),
      ...(cpObj.role && {
        role: cpObj.role
      }),
      ...(cpObj.email && {
        email: cpObj.email
      }),
      ...(cpObj.isEmailThirdParty && {
        isEmailThirdParty: cpObj.isEmailThirdParty
      }),
      ...(cpObj.mobileNumber && {
        mobileNumber: cpObj.mobileNumber?.replace(/[^0-9+]+/g, '')
      }),
      ...(cpObj.isMobileNumberThirdParty && {
        isMobileNumberThirdParty: cpObj.isMobileNumberThirdParty
      }),
      ...(cpObj.role !== 'child' && {
        communicationPreference: cpObj.communicationPreference
          ? cpObj.communicationPreference
          : CommunicationPreference.NoPreference
      }),
      ...(recordType !== 'child' &&
        cpObj.profileType && {
          profileType: cpObj.profileType
        })
    } as EditClientDetails);
  }

  return newCPList;
};

export const putUpdateClientData = async (token: string, clientVal: editClientForm) => {
  const massageData = {
    ...(clientVal.caseId && {
      caseId: clientVal.caseId
    }),
    ...(clientVal.class && {
      class: clientVal.class
    }),
    clientProfiles: massageClientProfiles(clientVal.clientProfiles, clientVal.recordType),
    ...(clientVal.clientCapability && {
      clientCapability: {
        profileType: clientVal.clientCapability.profileType
      }
    }),
    recordStatus: clientVal.recordStatus
  };

  const callPostClientsRecord = (await putClientsRecord(token, clientVal._id, massageData)) as any;
  return await callPostClientsRecord.json();
};
