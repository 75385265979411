import { BentResponse } from 'bent';
import {
  GetAppointmentsByClincianIdOrRoomIdQueryParams,
  GetAppointmentsQueryParams
} from 'interfaces/Schedule/Appointment';

import { httpClient } from '../../../httpClient';
import queryString from 'query-string';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAppointments = (token: string, accountId: string, params: GetAppointmentsQueryParams) => {
  const clinicianIds =
    params.clinicianIds.length === 1 && params.clinicianIds[0] === '' ? ',' : params.clinicianIds.join(',');
  const query = `?from=${params.from}&to=${params.to}&clinicianIds=${clinicianIds}`;
  return httpClient('GET', url!, [200, 400, 401, 403, 404, 500], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/appointments:getByClinicianIds${query}`
  ) as Promise<BentResponse>;
};

export const getAppointmentsByClincianIdOrRoomId = (
  token: string,
  accountId: string,
  params: GetAppointmentsByClincianIdOrRoomIdQueryParams
) => {
  const clinicianIds =
    params.clinicianIds.length === 1 && params.clinicianIds[0] === '' ? ',' : params.clinicianIds.join(',');
  const roomIds = params.roomIds.join(',');
  const massageParam = {
    from: params.from,
    to: params.to,
    roomIds,
    clinicianIds
  };

  const qParam = queryString.stringify(massageParam);
  return httpClient('GET', url!, [200, 400, 401, 403, 404, 500], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/appointments:getByClinicianIds?${qParam}`
  ) as Promise<BentResponse>;
};

export const getAppointmentById = (token: string, accountId: string, appointmentId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/appointments/${appointmentId}`
  ) as Promise<BentResponse>;

export const getAppointmentsCount = (token: string, today: string) => {
  return httpClient('GET', url!, [200], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/appointments/count?today=${today}`
  ) as Promise<BentResponse>;
};

export const getClientAppointmentsByDateRange = (token: string, from?: string, to?: string, clientRecordId?: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/appointments?${queryString.stringify({ from, to, clientRecordId })}`) as Promise<BentResponse>;

export const getGroupAppointmentsByDateRange = (token: string, groupId: string, from?: string, to?: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/groups/${groupId}/client-records/appointments?${queryString.stringify({ from, to })}`) as Promise<BentResponse>;

export const postApproveRescheduleRequest = (token: string, appointmentId: string) =>
  httpClient('POST', url!, [204, 403, 404, 409], {
    Authorization: `Bearer ${token}`
  })(`/appointments/${appointmentId}/reschedule/request:approve`) as Promise<BentResponse>;

export const postRejectRescheduleRequest = (token: string, appointmentId: string, payload: { note?: string }) =>
  httpClient('POST', url!, [204, 404], {
    Authorization: `Bearer ${token}`
  })(`/appointments/${appointmentId}/reschedule/request:reject`, payload) as Promise<BentResponse>;
