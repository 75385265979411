export enum GroupType {
  Registration = 'REGISTRATION',
  Year = 'YEAR',
  SEN = 'SEN',
  PP = 'PP',
  Other = 'OTHER'
}

export const GROUP_TYPE = [
  { value: GroupType.Registration, label: 'Registration' },
  { value: GroupType.Year, label: 'Year' },
  { value: GroupType.SEN, label: 'SEN' },
  { value: GroupType.PP, label: 'PP' },
  { value: GroupType.Other, label: 'Other' },
  { value: undefined, label: '' }
];

export const CREATABLE_GROUP_TYPE = [
  { value: GroupType.SEN, label: 'SEN' },
  { value: GroupType.PP, label: 'PP' },
  { value: GroupType.Other, label: 'Other' },
  { value: undefined, label: '' }
];

export interface CreateGroupRequestPayload {
  groupId?: string;
  name: string;
  leadClinicianAuth0Id: string;
  description?: string;
  classLeadId?: string;
  type: GroupType | string;
  typeName?: string;
}

export interface CreateGroupValidateField {
  name: string;
  type: string;
  typeName: string;
}
