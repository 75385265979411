import styles from './Attendees.module.scss';
import AttendeesSelection from './components/AttendeesSelection/AttendeesSelection';
import SelectedAttendees from './components/SelectedAttendees/SelectedAttendees';

import { Attendee } from 'interfaces/Schedule/Appointment';

type AttendeesProps = {
  clinicianId: string;
  selectedAttendees: Attendee[];
  setSelectedAttendees(attendees: Attendee[]): void;
  recordId?: string;
};

const Attendees = ({ clinicianId, selectedAttendees, setSelectedAttendees, recordId }: AttendeesProps) => {
  const addAttendee = (attendee: Attendee) => {
    if (
      selectedAttendees.filter(
        ({ clinicianId, clientProfileId, email }) =>
          (clinicianId && attendee.clinicianId === clinicianId) ||
          (clientProfileId && attendee.clientProfileId === clientProfileId) ||
          (email && attendee.email === email)
      ).length === 0
    ) {
      setSelectedAttendees([...selectedAttendees, attendee]);
    }
  };

  const addAttendees = (attendees: Attendee[]) => {
    setSelectedAttendees([...selectedAttendees, ...attendees]);
  };

  const removeAttendee = (deleteIndex: number) => {
    setSelectedAttendees(selectedAttendees.filter((item, index) => index !== deleteIndex));
  };

  return (
    <div className={styles.container}>
      <div>
        <span className="material-icons-outlined">people</span>
      </div>
      <div style={{ width: '45%' }}>
        <AttendeesSelection
          clinicianId={clinicianId}
          addAttendee={addAttendee}
          selectedAttendees={selectedAttendees}
          addAttendees={addAttendees}
        />
      </div>
      <div style={{ maxWidth: '45%' }}>
        <SelectedAttendees attendees={selectedAttendees} addAttendee={addAttendee} removeAttendee={removeAttendee} />
      </div>
    </div>
  );
};

export default Attendees;
