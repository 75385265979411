import { useState } from 'react';
import styles from './ReferralInformation.module.scss';
import AddEditReferralInformation from './components/AddEditReferralInformation/AddEditReferralInformation';
import classNames from 'classnames';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';

interface ReferralInformationProps {
  clientRecordId: string;
  sourceTags: string[];
  detail: string;
  date: string;
  fetchClientEncryptDetails: () => void;
}

const ReferralInformation = ({
  clientRecordId,
  sourceTags,
  detail,
  date,
  fetchClientEncryptDetails
}: ReferralInformationProps) => {
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const [isAddEdit, setIsAddEdit] = useState(false);

  const onShowAddEditModal = () => {
    setIsAddEdit(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Referral Information</div>
      <div className={styles.referralInformationDetail}>
        {sourceTags.length > 0 || detail.length > 0 || date.length > 0 ? (
          <>
            {sourceTags.length > 0 && (
              <div className={styles.referralTypes}>
                Referral Type
                <div className={styles.types}>
                  {sourceTags?.map((type, index) => (
                    <div key={`type_${index}`} className={styles.pill}>
                      {type}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {date.length > 0 && (
              <div className={styles.referralDate}>
                <div className={styles.dateHeader}>
                  Date
                  {medicareRebateFeatureToggle && (
                    <HelpOutLineWithTooltips
                      id="referral-date"
                      iconClass={styles.icon}
                      desc="This referral date will be automatically used in future Medicare claims"
                    />
                  )}
                </div>
                <div className={styles.date}>{date}</div>
              </div>
            )}
            {detail.length > 0 && (
              <div className={styles.referralDetails}>
                Detail
                <div className={styles.detail}>{detail}</div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.noRefferal}>No referral information added</div>
        )}
        <div
          className={classNames(
            styles.editButton,
            sourceTags.length > 0 || detail.length > 0 ? styles.referralEdit : ''
          )}
          onClick={onShowAddEditModal}
        >
          <i className={`material-icons ${styles.editIcon}`}>mode_edit</i>
          Edit
        </div>
      </div>
      <AddEditReferralInformation
        clientRecordId={clientRecordId}
        visible={isAddEdit}
        sourceTags={sourceTags}
        detail={detail}
        date={date}
        onCancel={() => {
          setIsAddEdit(false);
        }}
        fetchClientEncryptDetails={fetchClientEncryptDetails}
      />
    </div>
  );
};

export default ReferralInformation;
