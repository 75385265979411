import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PractitionerCardItem from './components/PractitionerCardItem/PractitionerCardItem';

import styles from './ActivePractitionerCard.module.scss';
import columnStyles from './PractitionerRowCard.module.scss';
import { PractitionersDetailsInterface, PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import classnames from 'classnames';
import PaginationList from 'components/PaginationList/PaginationList';
import { useTranslation } from 'react-i18next';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

interface ActivePractitionerCardProps {
  practitionersList: PractitionersDetailsInterface[];
  isPractitionersListLoading: boolean;
  paging: PractitionersListing['paging'];
  onPageChange: (paging: string) => void;
  onDeactivatePractitioner: (id: string) => void;
  onChangePractitionerRole: () => void;
}

const ActivePractitionerCard = ({
  practitionersList,
  isPractitionersListLoading,
  paging,
  onPageChange,
  onDeactivatePractitioner,
  onChangePractitionerRole
}: ActivePractitionerCardProps) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [t] = useTranslation();

  return isPractitionersListLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : practitionersList.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <div className={classnames(columnStyles.profileSection, styles.profileLabel)}>
          {t('label.practitioner.capitalize')}
        </div>
        <div className={columnStyles.roleSection}>Role</div>
        <div className={columnStyles.mobileSection}>Mobile</div>
        <div className={columnStyles.emailSection}>Email</div>
        {/* <div className={columnStyles.scheduleSection}>Schedule this week</div> */}
        <div className={columnStyles.caseLoadSection}>Number of students</div>
        <div className={columnStyles.caseLoadSection}>Assessment</div>
        <div className={columnStyles.buttonSection} />
      </div>
      {practitionersList.map((practitionersListObj, index) => {
        return (
          <PractitionerCardItem
            key={index}
            id={index}
            PractitionerData={practitionersListObj}
            onDeactivatePractitioner={onDeactivatePractitioner}
            onChangePractitionerRole={onChangePractitionerRole}
            lastItem={practitionersList.length - 1 === index}
            accountId={accountId}
            token={token}
          />
        );
      })}
      {paging.totalPage > 1 && (
        <div className={styles.paginationWrapper}>
          <PaginationList paging={paging} onPageChange={onPageChange} />
        </div>
      )}
    </>
  ) : (
    <div>{t('label.no_data_for_practitioner')}</div>
  );
};

export default ActivePractitionerCard;
