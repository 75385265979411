import { useEffect, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './PatientActionPlan.module.scss';
import { ActionPlanResponse, useFetchActionPlans } from './hooks/getActionPlan';
import classNames from 'classnames';
import Badge from 'components/Badge/Badge';
import moment from 'moment';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import Button from 'components/v2/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import NoteModal, { Note } from 'components/v2/NoteModal/NoteModal';
import { notification, Skeleton } from 'antd';
import {
  patchInterventionCompletedStatus,
  patchTaskCompletedStatus,
  postActionPlanNote,
  putActionPlan
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import PlanNoteList from './components/PlanNoteList/PlanNoteList';
import { useFetchActionPlanNotes } from './hooks/getPlanNote';
import { ActionPlanAttachment, ActionPlanIntervention, ActionPlanStatus } from 'MHH/pages/ActionPlanCreator/interfaces';
import { progressOption, statusOptions } from 'MHH/pages/ActionPlanCreator/constants';
import TaskList from 'MHH/pages/ActionPlanCreator/components/ActionPlanForm/components/TaskList/TaskList';
import ActionPlanAttachments from 'MHH/pages/ActionPlanCreator/components/ActionPlanForm/components/ActionPlanAttachments/ActionPlanAttachments';
import InterventionList, {
  ActionPlanInterventionItem
} from 'MHH/pages/ActionPlanCreator/components/ActionPlanForm/components/InterventionList/InterventionList';

interface PatientActionPlanProps {
  recordId: string;
  actionPlanId?: string;
}

const PatientActionPlan = ({ recordId, actionPlanId }: PatientActionPlanProps) => {
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const { actionPlans, isActionPlanLoading, fetchActionPlans } = useFetchActionPlans(token, recordId);
  const { notes, isNoteLoading, fetchActionPlanNotes } = useFetchActionPlanNotes(
    token,
    recordId,
    actionPlanId || actionPlans[0]?._id || ''
  );
  const [selectedPlan, setSelectedPlan] = useState<ActionPlanResponse>();
  const { CLIENTS } = useRoutesGenerator();
  const [noteStatus, setNoteStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkTaskAndIntStatus, setCheckTaskAndIntStatus] = useState<'' | 'active' | 'finished'>('');
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);

  const groupActionPlanInterventions = (interventions: ActionPlanIntervention[]) => {
    const groupedInt: ActionPlanInterventionItem[] = [];
    (interventions || []).forEach((item) => {
      if (item.focusArea) {
        const foundInterventionIndex = groupedInt.findIndex((int) => int.focusArea === item.focusArea);
        if (foundInterventionIndex === -1) {
          groupedInt.unshift({
            ...item,
            selectedInterventionIds: item.interventionId ? [item.interventionId] : [],
            subInterventions: [item]
          });
        } else {
          groupedInt[foundInterventionIndex] = {
            ...item,
            selectedInterventionIds: [
              ...(groupedInt[foundInterventionIndex].selectedInterventionIds || []),
              ...(item.interventionId ? [item.interventionId] : [])
            ],
            subInterventions: [...(groupedInt[foundInterventionIndex].subInterventions || []), item]
          };
        }
      } else {
        groupedInt.push(item);
      }
    });
    return groupedInt;
  };
  useEffect(() => {
    if (!actionPlanId && actionPlans[0]?._id) {
      navigate(`${CLIENTS.BASE}/${recordId}/action-plans/${actionPlans[0]._id}`);
    }

    setSelectedPlan(actionPlans.find(({ _id }) => actionPlanId === _id));
  }, [CLIENTS.BASE, actionPlanId, actionPlans, navigate, recordId, selectedPlan]);

  const handleSelectActionPlan = (id: string) => {
    navigate(`${CLIENTS.BASE}/${recordId}/action-plans/${id}`);
  };

  const handleCheckTaskList = async (taskId: string, isCompleted: boolean) => {
    try {
      setCheckTaskAndIntStatus('active');
      selectedPlan &&
        (await patchTaskCompletedStatus({
          token,
          body: { isCompleted },
          clientRecordId: recordId,
          actionPlanId: selectedPlan._id || '',
          taskId
        }));
      setCheckTaskAndIntStatus('finished');
      notification.success({
        message: `Task ${isCompleted ? 'completed' : 'not completed'}.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      selectedPlan?._id && fetchActionPlans(token);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Fail to update task completed status.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCheckTaskAndIntStatus('');
  };

  const handleCheckInterventionList = async (interventionId: string, isCompleted: boolean) => {
    try {
      setCheckTaskAndIntStatus('active');
      selectedPlan &&
        (await patchInterventionCompletedStatus({
          token,
          body: { isCompleted },
          clientRecordId: recordId,
          actionPlanId: selectedPlan._id || '',
          interventionId
        }));
      setCheckTaskAndIntStatus('finished');
      notification.success({
        message: `Task ${isCompleted ? 'completed' : 'not completed'}.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      selectedPlan?._id && fetchActionPlans(token);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Fail to update task completed status.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCheckTaskAndIntStatus('');
  };

  const handleCreatePlanNote = async (note: Note) => {
    try {
      const payload = {
        title: note.title,
        body: note.content
      };
      setNoteStatus('active');
      selectedPlan &&
        (await postActionPlanNote({
          token,
          body: payload,
          clientRecordId: recordId,
          actionPlanId: selectedPlan._id || ''
        }));
      setNoteStatus('finished');
      setShowNoteModal(false);
      notification.success({
        message: `Action plan note created.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      selectedPlan?._id && fetchActionPlanNotes(token, selectedPlan._id);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Fail to create action plan note.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setNoteStatus('');
  };

  const handleUpdateAttachments = async (attachments: ActionPlanAttachment[]) => {
    try {
      setCheckTaskAndIntStatus('active');
      selectedPlan &&
        (await putActionPlan({
          token,
          body: { ...selectedPlan, attachments },
          clientRecordId: recordId,
          actionPlanId: selectedPlan._id || ''
        }));
      setCheckTaskAndIntStatus('finished');
      notification.success({
        message: `Attachments uploaded`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      selectedPlan?._id && fetchActionPlans(token);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Fail to update attachment.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCheckTaskAndIntStatus('');
  };

  return isActionPlanLoading ? (
    <div className={styles.loadingContainer}>
      <Skeleton active />
    </div>
  ) : actionPlans.length > 0 ? (
    <>
      <div className={styles.actionPlanList}>
        {actionPlans.map((plan) => (
          <div
            className={styles.actionPlanNameBox}
            key={plan._id}
            onClick={() => handleSelectActionPlan(plan._id || '')}
          >
            <span className={classNames(styles.actionPlanName, selectedPlan?._id === plan._id && styles.selectedPlan)}>
              {plan.title}
            </span>
          </div>
        ))}
      </div>
      <div className={styles.boxContainer}>
        <div className={styles.container}>
          <div className={styles.actionPlanWrapper}>
            <div className={styles.statusSection}>
              <div className={styles.headerSection}>
                <div className={styles.title}>STATUS</div>
                <Badge
                  label={statusOptions.find((option) => selectedPlan?.status === option.value)?.label || ''}
                  className={styles.badge}
                  backgroundColor={
                    statusOptions.find((option) => selectedPlan?.status === option.value)?.color || '#3f52ff'
                  }
                />
              </div>
              {selectedPlan?.status === ActionPlanStatus.Active && (
                <div className={styles.headerSection}>
                  <div className={styles.title}>PROGRESS</div>
                  <Badge
                    label={progressOption.find((option) => selectedPlan?.progress === option.value)?.label || ''}
                    className={styles.badge}
                    backgroundColor={
                      progressOption.find((option) => selectedPlan?.progress === option.value)?.color || '#3f52ff'
                    }
                  />
                </div>
              )}
              <span className={styles.fieldContent}>
                Created at: {moment(selectedPlan?.createdAt).format('DD MMM YYYY')}
              </span>
              <span className={styles.fieldContent}>
                Name: <label className={styles.planName}>{selectedPlan?.title}</label>
              </span>
              <span className={styles.fieldContent}>
                Description:
                <br />
                <span className={styles.description}>{selectedPlan?.description || '-'}</span>
              </span>
              <span className={styles.fieldContent}>
                Review by: {moment(selectedPlan?.dueDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
              </span>
              <span className={styles.fieldContent}>
                ASSESSMENT ATTACHED
                <span className={styles.description}>
                  {selectedPlan?.attachedAssessments?.length ? (
                    selectedPlan.attachedAssessments.map((item, index) => (
                      <span key={index}>
                        <br />
                        {`${index + 1}. ${item.id} ${moment(item.createdAt).format('DD/MM/YYYY')}`}
                      </span>
                    ))
                  ) : (
                    <span>
                      <br />
                      No attached assessments
                    </span>
                  )}
                </span>
              </span>
            </div>
            <div className={styles.ownerSection}>
              <div className={styles.title}>PLAN OWNER</div>
              <ClinicianAvatar
                customContainerClass={styles.avatarContainer}
                customNameClass={styles.clinicianName}
                avatarUrl={selectedPlan?.ownerProfile.avatar}
                name={selectedPlan?.ownerProfile.name || '-'}
                avatarSize={100}
              />
              <span className={styles.fieldContent}>Mobile: {selectedPlan?.ownerProfile.mobileNumber || '-'}</span>
              <span className={styles.fieldContent}>Email: {selectedPlan?.ownerProfile.email || '-'}</span>
              <div className={styles.attachmentsSection}>
                <div className={styles.title}>ATTACHMENTS</div>
                <ActionPlanAttachments
                  attachments={selectedPlan?.attachments || []}
                  clientRecordId={recordId}
                  onChangeAttachments={handleUpdateAttachments}
                />
              </div>
            </div>
          </div>
          {!!selectedPlan?.interventions?.length && (
            <div>
              <div className={styles.title}>INTERVENTION LIST</div>
              <InterventionList
                isView
                interventionList={groupActionPlanInterventions(selectedPlan.interventions)}
                onCheck={handleCheckInterventionList}
                checkStatus={checkTaskAndIntStatus}
                attachedAssessments={[]}
              />
            </div>
          )}
          {!!selectedPlan?.tasks?.length && (
            <div>
              <div className={styles.title}>TASK LIST</div>
              <TaskList
                isView
                taskList={selectedPlan.tasks}
                onCheckTask={handleCheckTaskList}
                checkTaskStatus={checkTaskAndIntStatus}
              />
            </div>
          )}
          <div className={styles.editButton}>
            <Link
              className={styles.secondaryButton}
              to={`${CLIENTS.BASE}/${recordId}/action-plans/${selectedPlan?._id}/edit`}
            >
              Update / Change Plan
            </Link>
          </div>
          <div className={styles.statusUpdateWrapper}>
            <div className={styles.title}>STATUS UPDATES</div>
            <PlanNoteList notes={notes} isLoading={isNoteLoading} />
            <Button className={styles.secondaryButton} onClick={() => setShowNoteModal(true)}>
              Add Note
            </Button>
          </div>
          {showNoteModal && (
            <NoteModal
              visible={showNoteModal}
              status={noteStatus}
              onCreated={handleCreatePlanNote}
              onClose={() => setShowNoteModal(false)}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <div className={styles.boxContainer}>There is no action plan</div>
  );
};

export default PatientActionPlan;
