import share from 'assets/images/share.svg';
import classNames from 'classnames';
import { Report } from 'interfaces/Reports/report';
import { useTranslation } from 'react-i18next';

import ShareSideBox from './ShareSideBox/ShareSideBox';
import styles from './ShareTab.module.scss';

interface ShareTabProps {
  visible: boolean;
  data: Report;
  isClientShared: boolean;
  onChangeSharedList: (sharedList: Report['shareDetails']['accessors']) => void;
}

const ShareTab = ({ visible, data, isClientShared, onChangeSharedList }: ShareTabProps) => {
  const { _id, status, clientRecord } = data;
  const [t] = useTranslation();

  return (
    <div className={classNames(styles.container, !visible && styles.hidden)}>
      {status === 'published' ? (
        <ShareSideBox
          reportId={_id}
          isClientShared={isClientShared}
          allowCommunicationWithEmail={clientRecord.allowCommunicationWithEmail}
          onChangeSharedList={onChangeSharedList}
        />
      ) : (
        <div className={styles.notShareableContainer}>
          <div className={styles.iconContainer}>
            <img className={styles.icon} src={share} alt={'shareIcon'} />
          </div>
          <div className={styles.message}>{t('form.error.share_to_client_before_publish')}</div>
        </div>
      )}
    </div>
  );
};

export default ShareTab;
