import { InterventionDetailsTab } from 'MHH/pages/Interventions/Interfaces/Interventions';
import { TFunction } from 'react-i18next';

export interface InterventionSideMenuListProps {
  id: InterventionDetailsTab;
  icon: string;
  label: string;
  desc: string | JSX.Element;
  isActive: boolean;
}

export const interventionsSideMenu = ({
  allowEditAssigned,
  isEdgeReceptionist,
  t
}: {
  allowEditAssigned: boolean;
  isEdgeReceptionist: boolean;
  t: TFunction;
}) => [
  {
    id: InterventionDetailsTab.Details,
    icon: 'manage_accounts',
    label: t('sidemenu.intervention.details'),
    desc: t('sidemenu.group.details.description'),
    isActive: false
  },
  {
    id: InterventionDetailsTab.Members,
    icon: 'supervised_user_circle',
    label: t('sidemenu.group.members'),
    desc: t('sidemenu.intervention.members.description'),
    isActive: false
  },
  // {
  //   id: GroupDetailsTab.Sessions,
  //   icon: 'event',
  //   label: t('sidemenu.group.sessions'),
  //   desc: <GroupSessionMenuList groupId={groupId} activeId={contentId} isLoading={false} />,
  //   isActive: false
  // },
  // {
  //   id: GroupDetailsTab.Psychometrics,
  //   icon: 'assessment',
  //   label: t('sidemenu.group.psychometrics'),
  //   desc: (
  //     <GroupPsychometricMenuList
  //       groupId={groupId}
  //       activeId={contentId}
  //       childActiveId={childContentId}
  //       isLoading={psychometricLoading}
  //       psychometricList={psychometricList}
  //     />
  //   ),
  //   isActive: false
  // },
  // {
  //   id: GroupDetailsTab.Survey,
  //   icon: 'grading',
  //   label: t('sidemenu.group.survey'),
  //   desc: (
  //     <GroupSurveyFormMenuList
  //       groupId={groupId}
  //       activeId={contentId}
  //       isLoading={surveyResponseListLoading}
  //       surveyFormMenuList={surveyFormMenuList}
  //     />
  //   ),
  //   isActive: false
  // },
  // ...(IS_DEVELOPMENT
  //   ? [
  //       {
  //         id: GroupDetailsTab.Consent,
  //         icon: 'grading',
  //         label: t('sidemenu.group.consent'),
  //         desc: (
  //           <GroupConsentFormMenuList
  //             groupId={groupId}
  //             activeId={contentId}
  //             isLoading={false}
  //             consentFormMenuList={consentFormMenuList}
  //           />
  //         ),
  //         isActive: false
  //       }
  //     ]
  //   : []),
  // ...(!isEdgeReceptionist
  //   ? [
  //       {
  //         id: GroupDetailsTab.CaseNotes,
  //         icon: 'folder_open',
  //         label: t('sidemenu.intervention.case_notes'),
  //         desc: t('sidemenu.intervention.case_notes.description'),
  //         isActive: false
  //       },
  //       {
  //         id: GroupDetailsTab.Attachments,
  //         icon: 'attach_file',
  //         label: t('sidemenu.group.attachments'),
  //         desc: t('sidemenu.intervention.attachments.description'),
  //         isActive: false
  //       }
  //     ]
  //   : []),
  {
    id: InterventionDetailsTab.Contacts,
    icon: 'contacts',
    label: t('sidemenu.group.contacts'),
    desc: t('sidemenu.intervention.contacts.description'),
    isActive: false
  },
  ...(allowEditAssigned
    ? [
        {
          id: InterventionDetailsTab.PractitionerAssigned,
          icon: 'account_tree',
          label: 'Trained staff',
          desc: 'Connect trained staff - intervention',
          isActive: false
        }
      ]
    : []),
  ...(!isEdgeReceptionist
    ? [
        {
          id: InterventionDetailsTab.PathwayManagement,
          icon: 'call_split',
          label: t('sidemenu.group.pathway_management'),
          desc: t('sidemenu.group.pathway_management.description'),
          isActive: false
        }
      ]
    : [])
];
