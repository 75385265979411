import { useState, useEffect } from 'react';
import GPCard from './components/GPCard/GPCard';
import styles from './GPDetails.module.scss';
import { GeneralPractitionerInterface } from './interface';

import { useFetchGeneralPractitionerById } from './components/AddEditClientGPModal/hooks/getGeneralPractitionerList';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { CLIENT_GP_INITIAL } from './components/AddEditClientGPModal/constants';
import AddEditClientGPModal from './components/AddEditClientGPModal/AddEditClientGPModal';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface GPDetailsProps {
  token: string;
  clientRecordId: string;
  generalPractitionerId: string;
}

const GPDetails = ({ token, clientRecordId, generalPractitionerId }: GPDetailsProps) => {
  const [isAddEdit, setIsAddEdit] = useState(false);
  const {
    generalPractitioner,
    isGeneralPractitionerLoading,
    fetchGeneralPractitionerById
  } = useFetchGeneralPractitionerById(token, generalPractitionerId);
  const [selectedGP, setSelectedGP] = useState<GeneralPractitionerInterface>(generalPractitioner);

  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  useEffect(() => {
    setSelectedGP(generalPractitioner);
  }, [generalPractitioner]);

  const onShowModal = () => {
    setIsAddEdit(true);
  };

  const onRefreshGPDetail = (newGPId?: string) => {
    if (newGPId) {
      fetchGeneralPractitionerById(newGPId);
    } else {
      setSelectedGP({} as GeneralPractitionerInterface);
    }
  };

  return generalPractitionerId && isGeneralPractitionerLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <>
      <div className={styles.gpDetailsContainer}>
        <div className={styles.title}>GP Details</div>
        {Object.keys(generalPractitioner).length === 0 && <div className={styles.noGPTitle}>No GP data added</div>}
        <GPCard
          onShowAddEditModal={() => onShowModal()}
          generalPractitioner={selectedGP || ({} as GeneralPractitionerInterface)}
          showMedicareField={medicareRebateFeatureToggle}
        />
      </div>
      <AddEditClientGPModal
        clientRecordId={clientRecordId}
        visible={isAddEdit}
        initialGP={selectedGP._id ? selectedGP : CLIENT_GP_INITIAL}
        showMedicareField={medicareRebateFeatureToggle}
        onCancel={() => {
          setIsAddEdit(false);
        }}
        onRefreshGP={onRefreshGPDetail}
      />
    </>
  );
};

export default GPDetails;
