import { Skeleton } from 'antd';
import Button from 'components/v2/Button/Button';
import { Location } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';

import AddLocationModal from './components/AddLocationModal/AddLocationModal';
import LocationCard from './components/LocationCard/LocationCard';
import styles from './MedicareLocation.module.scss';

interface MedicareLocationProps {
  locations: Location[];
  isLoading: boolean;
  refetchMedicareLocations: () => void;
  refetchMedicareLocationsSilently: () => void;
}

const MedicareLocation = ({
  locations,
  isLoading,
  refetchMedicareLocations,
  refetchMedicareLocationsSilently
}: MedicareLocationProps) => {
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Location</div>
        <div className={styles.description}>Add your location to get a location ID.</div>
      </div>
      {isLoading ? (
        <Skeleton.Input active className={styles.loading} />
      ) : (
        <>
          {locations.length > 0 && (
            <div className={styles.locationsContainer}>
              {locations.map((location, index) => (
                <LocationCard
                  key={index}
                  location={location}
                  refetchMedicareLocations={refetchMedicareLocationsSilently}
                />
              ))}
            </div>
          )}
          <Button
            className={styles.button}
            variant={locations.length > 0 ? 'secondary' : 'primary'}
            icon={'add_circle_outline'}
            onClick={() => setShowAddLocationModal(true)}
          >
            Add Location
          </Button>
          <AddLocationModal
            visible={showAddLocationModal}
            setShowAddLocationModal={setShowAddLocationModal}
            refetchMedicareLocations={refetchMedicareLocations}
          />
        </>
      )}
    </div>
  );
};

export default MedicareLocation;
