import PillButtonGroup from 'components/v2/PIllButtonGroup/PIllButtonGroup';
import { useField } from 'formik';
import { ScheduleOption } from 'interfaces/Schedule/AppointmentType';

const options = [
  { value: ScheduleOption.FixedTime, label: 'FIXED TIME' },
  { value: ScheduleOption.AutoFit, label: 'AUTO FIT' }
];

const ScheduleType = () => {
  const [{ value }, , { setValue }] = useField('dayTimeSelections.kind');

  const handleClick = (option: string) => {
    setValue(option);
  };

  return <PillButtonGroup options={options} value={value} onChange={handleClick} />;
};

export default ScheduleType;
