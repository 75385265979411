import { notification } from 'antd';
import { GroupListData } from 'pages/Groups/Interfaces/Groups';
import { useState } from 'react';
import { getGroupListingByAccountId } from 'utils/http/ClinicianProfileService/Groups/Groups';

export const useFetchAvailableGroups = (token: string, accountId: string) => {
  const [isGroupListLoading, setIsGroupListLoading] = useState(false);
  const [groupList, setGroupList] = useState<GroupListData>();

  const fetchAvailableGroups = async () => {
    setIsGroupListLoading(true);
    try {
      const groupsData = await getGroupListingByAccountId(token, accountId, '?status=active');
      const groupListingData = (await groupsData.json()) as GroupListData;
      setGroupList(groupListingData);
      setIsGroupListLoading(false);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get available groups' });
    }

    setIsGroupListLoading(false);
  };

  return { groupList, isGroupListLoading, fetchAvailableGroups };
};
