import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';

import 'react-quill/dist/quill.snow.css';
import styles from './WidgetTextEditor.module.scss';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

const DEFAULT_TOOLBAR = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
  [
    { color: [] },
    { background: [] },
    { list: 'ordered' },
    { list: 'bullet' },
    { indent: '-1' },
    { indent: '+1' },
    { align: [] }
  ],
  ['link', 'image']
];

export interface WidgetTextEditorProps {
  className?: string;
  containerClass?: string;
  value: string;
  fullHeightEditor?: boolean;
  noBackground?: boolean;
  onChangeValue: any;
  enableMention?: boolean;
  basicMention?: boolean;
  toolBar?: any[];
  readOnly?: boolean;
}

const WidgetTextEditor = ({
  className,
  containerClass,
  value,
  fullHeightEditor,
  noBackground,
  onChangeValue,
  enableMention,
  basicMention,
  toolBar,
  readOnly = false
}: WidgetTextEditorProps) => {
  const suggestPeople = (searchTerm: string) => {
    const allVariable = [
      { id: 1, value: '{{CLIENT_NAME}}' },
      { id: 2, value: '{{CLIENT_DOB}}' },
      { id: 3, value: '{{FIRST_APPOINTMENT_DATE}}' },
      { id: 4, value: '{{RECENT_APPOINTMENT_DATE}}' },
      { id: 5, value: '{{APPOINTMENT_COUNT}}' },
      { id: 6, value: '{{FIRST_ASSESSMENT}}' },
      { id: 7, value: '{{FIRST_ASSESSMENT_DATE}}' },
      { id: 8, value: '{{RECENT_ASSESSMENT}}' },
      { id: 9, value: '{{RECENT_ASSESSMENT_DATE}}' }
    ];
    return allVariable.filter((key) => key.value.toLowerCase().includes(searchTerm.toLowerCase())).splice(0, 6);
  };

  const clientBasicInfo = (searchTerm: string) => {
    const allVariable = [
      { id: 1, value: '{{FIRST_NAME}}' },
      { id: 2, value: '{{LAST_NAME}}' }
    ];
    return allVariable.filter((key) => key.value.toLowerCase().includes(searchTerm.toLowerCase())).splice(0, 6);
  };

  const modules = useMemo(
    () => ({
      toolbar: toolBar && toolBar.length > 0 ? toolBar : DEFAULT_TOOLBAR,
      imageResize: {
        handleStyles: {
          backgroundColor: styles.blackColor,
          border: 'none',
          color: styles.whiteColor
        },
        modules: ['Resize', 'DisplaySize']
      },
      imageCompress: {
        quality: 0.5,
        maxWidth: 800,
        maxHeight: 800,
        imageType: 'image/png',
        debug: false
      },
      ...(enableMention && {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['{{'],
          mentionContainerClass: styles.mentionListContainer,
          listItemClass: styles.mentionList,
          showDenotationChar: false,
          dataAttributes: [],
          source: function (searchTerm: any, renderList: any) {
            const matchedPeople = suggestPeople(searchTerm);
            renderList(matchedPeople);
          }
        }
      }),
      ...(basicMention && {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['{{'],
          mentionContainerClass: styles.mentionListContainer,
          listItemClass: styles.mentionList,
          showDenotationChar: false,
          dataAttributes: [],
          source: function (searchTerm: any, renderList: any) {
            const matchedVariable = clientBasicInfo(searchTerm);
            renderList(matchedVariable);
          }
        }
      })
    }),
    [enableMention, basicMention, toolBar]
  );

  const [defaultValue, setDefaultValue] = useState(value);

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  const handleOnChange = (val: any) => {
    setDefaultValue(val);
    onChangeValue(val);
  };

  return (
    <div
      className={classnames(
        `${fullHeightEditor ? 'widgetFullHeightEditor' : 'widgetEditor'} ${
          noBackground ? styles.containerToolbar : styles.container
        }`,
        containerClass
      )}
    >
      <ReactQuill
        className={classnames(styles.textEditor, className)}
        value={defaultValue}
        modules={modules}
        onChange={(e) => handleOnChange(e)}
        readOnly={readOnly}
      />
    </div>
  );
};

export default WidgetTextEditor;
