import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { CaseNote } from 'interfaces/caseNote';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import styles from './MultipleChoiceCountWrapper.module.scss';
import SelectWithCount from 'components/SelectWithCount/SelectWithCount';

interface MultipleChoiceCountProps {
  field: CaseNote['fields'][number];
  isValidated: boolean;
  className?: string;
  isDisabled?: boolean;
  onChangeFieldInput: (id: string, value: any) => void;
}

const MultipleChoiceCountWrapper = ({
  field,
  isValidated,
  className,
  isDisabled,
  onChangeFieldInput
}: MultipleChoiceCountProps) => {
  const [multipleCountData, setMultipleCountData] = useState(field as CaseNote['fields'][number]);

  useEffect(() => {
    setMultipleCountData(field);
  }, [field]);

  return (
    <div
      className={classnames(styles.headingField, multipleCountData.multiple && styles.multipleSelectField, className)}
    >
      <SelectWithCount
        id={multipleCountData._id}
        label={`${multipleCountData.name} ${multipleCountData.mandatory ? '*' : ''}`}
        options={multipleCountData.options}
        onChangeValue={onChangeFieldInput}
      />
      <ErrorMessage className={styles.error} error={'Please select at least one item'} visible={isValidated} />
    </div>
  );
};

export default MultipleChoiceCountWrapper;
