import { useMemo, useState } from 'react';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { Attendee } from 'interfaces/Schedule/Appointment';

import { getName } from 'utils/general';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import { useFetchPractitionerList } from 'components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { useFetchClientRecordByClinician } from 'components/v2/PatientSelect/hooks/GetClientList';
import PillButtonGroup from 'components/v2/PIllButtonGroup/PIllButtonGroup';
import ClientAttendeesList from './components/ClientAttendeesList/ClientAttendeesList';
import ClientProfileAttendeesList from './components/ClientProfileAttendeesList/ClientProfileAttendeesList';
import PractitionerAttendeesList from './components/PractitionerAttendeesList/PractitionerAttendeesList';

import styles from './AttendeesSelection.module.scss';
import { useTranslation } from 'react-i18next';

interface AttendeesSelectionProps {
  clinicianId: string;
  addAttendee(attendee: Attendee): void;
  selectedAttendees: Attendee[];
  addAttendees(attendees: Attendee[]): void;
}

const AttendeesSelection = ({ clinicianId, selectedAttendees, addAttendee, addAttendees }: AttendeesSelectionProps) => {
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [contactType, setContactType] = useState('clients');
  const [t] = useTranslation();

  const contactTypes =
    isEdgeAdminView || isEdgeReceptionist
      ? [
          { label: t('label.clients.capitalize'), value: 'clients' },
          { label: t('label.colleagues.capitalize'), value: 'colleagues' }
        ]
      : [{ label: t('label.clients.capitalize'), value: 'clients' }];

  const [selectedClientRecordId, setSelectedClientRecordId] = useState('');

  const { bookableClients, isBookableClientsLoading } = useFetchClientRecordByClinician(clinicianId);

  const { token } = useGetAccessToken();
  const { practitionersList, isPractitionersListLoading } = useFetchPractitionerList(token, false);

  const selectedClientRecord = useMemo(() => {
    const foundClientRecord = bookableClients.find((bookableClient) => bookableClient._id === selectedClientRecordId);

    if (foundClientRecord) {
      return {
        ...foundClientRecord,
        clientProfiles: foundClientRecord.clientProfiles.filter(
          (clientProfile) => !selectedAttendees.some((attendee) => attendee.clientProfileId === clientProfile._id)
        )
      };
    }
  }, [bookableClients, selectedAttendees, selectedClientRecordId]);

  const filteredBookableClients = useMemo(
    () =>
      bookableClients.filter((bookableClient) =>
        bookableClient.clientProfiles.some(
          (clientProfile) => !selectedAttendees.some((attendee) => attendee.clientProfileId === clientProfile._id)
        )
      ),
    [bookableClients, selectedAttendees]
  );

  const filteredPractitionersList = useMemo(
    () =>
      practitionersList.filter(
        (practitioner) =>
          practitioner._id !== clinicianId &&
          !selectedAttendees.some((attendee) => attendee.clinicianId === practitioner._id)
      ),
    [clinicianId, practitionersList, selectedAttendees]
  );

  const handleAddClientProfile = (clientProfile: clientRecordsInterface['clientProfiles'][number]) =>
    addAttendee({
      clientRecordId: selectedClientRecordId,
      clientProfileId: clientProfile._id,
      name: getName(clientProfile),
      initials: clientProfile.initials,
      initialsBackgroundColor: clientProfile.initialsBackgroundColor,
      avatar: clientProfile.avatar
    });

  const handleAddClientRecord = (clientRecord: clientRecordsInterface) => {
    const clientProfilesToBeAdded: Attendee[] = [];

    clientRecord.clientProfiles.forEach(
      (clientProfile) =>
        !selectedAttendees.some((attendee) => attendee.clientProfileId === clientProfile._id) &&
        clientProfilesToBeAdded.push({
          clientRecordId: clientRecord._id,
          clientProfileId: clientProfile._id,
          name: getName(clientProfile),
          initials: clientProfile.initials,
          initialsBackgroundColor: clientProfile.initialsBackgroundColor,
          avatar: clientProfile.avatar,
          clinicianId: clientRecord.mentorIds ? clientRecord.mentorIds[0] : ''
        })
    );

    addAttendees(clientProfilesToBeAdded);
  };

  const handleClickShowContacts = (clientRecordId: string) => {
    setSelectedClientRecordId(clientRecordId);
  };

  const handleAddPractitioner = (practitioner: PractitionersDetailsInterface) =>
    addAttendee({
      clinicianId: practitioner._id,
      name: practitioner.name,
      email: practitioner.email,
      avatar: practitioner.avatar
    });

  const onSelectContactType = (type: string) => {
    setContactType(type);
    setSelectedClientRecordId('');
  };

  return (
    <div className={styles.container}>
      {selectedClientRecordId && selectedClientRecord ? (
        <ClientProfileAttendeesList
          clientRecord={selectedClientRecord}
          onAddClientProfile={handleAddClientProfile}
          onBack={() => setSelectedClientRecordId('')}
        />
      ) : (
        <>
          <div className={styles.buttonsContainer}>
            <PillButtonGroup options={contactTypes} value={contactType} onChange={onSelectContactType} compact />
          </div>
          <div className={styles.listContainer}>
            {contactType === 'clients' ? (
              <ClientAttendeesList
                clientAttendees={filteredBookableClients}
                isLoading={isBookableClientsLoading}
                onAddClientRecord={handleAddClientRecord}
                onClickShowContacts={handleClickShowContacts}
              />
            ) : (
              <PractitionerAttendeesList
                practitionerAttendees={filteredPractitionersList}
                isLoading={isPractitionersListLoading}
                onAddPractitioner={handleAddPractitioner}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AttendeesSelection;
