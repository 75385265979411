import WidgetItemWrapper from '../WidgetItemWrapper/WidgetItemWrapper';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import AssessmentWidget from '../../../../../../../../../components/WidgetComponents/AssessmentWidget/AssessmentWidget';
import styles from './AssessmentWrapper.module.scss';
import { useTranslation } from 'react-i18next';

interface AssessmentWrapperProps {
  isLoading: boolean;
  assessmentData: any;
}

const AssessmentWrapper = ({ isLoading, assessmentData }: AssessmentWrapperProps) => {
  const [t] = useTranslation();

  return isLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div>
      {assessmentData.length > 0 ? (
        assessmentData.map((assessmentObj: any, index: any) => (
          <WidgetItemWrapper data={assessmentObj} widgetType={'assessmentWidget'} key={index}>
            <AssessmentWidget assessmentData={assessmentObj} />
          </WidgetItemWrapper>
        ))
      ) : (
        <div>{t('label.no_client_assessment_data')}</div>
      )}
    </div>
  );
};

export default AssessmentWrapper;
