import styles from './NoCaseNoteDetail.module.scss';
import LoadingButton from 'components/v2/Button/Button';

interface NoCaseNoteDetailProps {
  message: string;
  addNoteButtonStatus: '' | 'active' | 'finished';
  isAddNoteButtonDisabled: boolean;
  onClickNewNote: () => void;
}

const NoCaseNoteDetail = ({
  message,
  addNoteButtonStatus,
  isAddNoteButtonDisabled,
  onClickNewNote
}: NoCaseNoteDetailProps) => {
  return (
    <>
      <div className={styles.text}>{message}</div>
      <LoadingButton
        className={styles.button}
        status={addNoteButtonStatus}
        disabled={isAddNoteButtonDisabled}
        onClick={onClickNewNote}
      >
        <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
        Add new note
      </LoadingButton>
    </>
  );
};

export default NoCaseNoteDetail;
