import { useEffect, useState, KeyboardEvent } from 'react';

import styles from './ChildClientForm.module.scss';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { validationChildField } from './validation/ChildClientFormValidation';
import { ClientDetails } from '../../../../AddPatientModalV2Interface';
import { ChildClientErrorInterface } from './ChildClientInterface';
import { convertDateFormat } from 'utils/dateChecker';
import { useTranslation } from 'react-i18next';

interface ChildClientFormProps {
  id: string | number;
  label?: string;
  clientDetail: ClientDetails;
  onChangeClientField: any;
  checkValidation: boolean;
  shadowBox?: boolean;
  classValue?: string;
  onChangeClass?: (value: string) => void;
}

const ChildClientForm = ({
  id,
  label,
  clientDetail,
  onChangeClientField,
  classValue,
  onChangeClass,
  checkValidation,
  shadowBox
}: ChildClientFormProps) => {
  const [clientField, setClientField] = useState(clientDetail);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    class: ''
  } as ChildClientErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      validatePart1Field(clientField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const validatePart1Field = (clientVal: ChildClientErrorInterface) => {
    const validate = validationChildField(clientVal);
    setErrorMessage(validate as ChildClientErrorInterface);
    return validate;
  };

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientField,
      [key]: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const handleKeyDownDateChange = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = clientField.dateOfBirth?.slice(0, clientField.dateOfBirth.length - 1);
      const newClientField = {
        ...clientField,
        dateOfBirth: newValue
      };
      setClientField(newClientField);
      onChangeClientField(newClientField);
      if (checkValidation) {
        validatePart1Field(newClientField);
      }
    }
  };

  const handleDateChange = (dateValue: string) => {
    const newDateFormat = convertDateFormat(dateValue);
    const newClientField = {
      ...clientField,
      dateOfBirth: newDateFormat
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const [t] = useTranslation();

  return (
    <div className={shadowBox ? styles.containerWithBox : styles.container}>
      {shadowBox && <div className={styles.labelContainer}>{label && <div className={styles.label}>{label}</div>}</div>}
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`childClientFirstName`}
          label={`${t('form.client_first_name')}*`}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientField.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}>
        <MaterialInput
          id={`childClientLastName`}
          label={`${t('form.client_last_name')}*`}
          onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
          value={clientField.lastName}
          required
        />
        {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
      </div>
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.dateOfBirth && styles.fieldError)}
      >
        <MaterialInput
          placeholder={'DD/MM/YYYY'}
          id={`childClientDOB`}
          label={'Date of Birth'}
          onKeyDown={(e) => handleKeyDownDateChange(e)}
          onChange={(e) => handleDateChange(e.target.value)}
          value={clientField.dateOfBirth}
          type={'tel'}
          pattern={'[0-9\\/]*'}
          maxLength={10}
          autoComplete={'off'}
        />
        {checkValidation && errorMessage.dateOfBirth && (
          <div className={styles.fieldError}>{errorMessage.dateOfBirth}</div>
        )}
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && !classValue && styles.fieldError)}>
        <MaterialInput
          id={`childClientClass`}
          label={`${t('form.client_class')}*`}
          onChange={(e) => onChangeClass?.(e.target.value)}
          value={classValue}
          required
        />
        {checkValidation && !classValue && (
          <div className={styles.fieldError}>{t('validation.client_class.require')}</div>
        )}
      </div>
    </div>
  );
};

export default ChildClientForm;
