import { useState } from 'react';
import WidgetItemWrapper from '../WidgetItemWrapper/WidgetItemWrapper';

import InsightsChartWidget from '../../../../../../../../../components/WidgetComponents/InsightsChartWidget/InsightsChartWidget';
import BarLineChartWidget from '../../../../../../../../../components/WidgetComponents/BarLineChartWidget/BarLineChartWidget';
import LoadingDot from 'components/LoadingDot/LoadingDot';

import styles from './GraphWrapper.module.scss';

interface GraphWrapperProps {
  isAllGraphLoading: boolean;
  graphData: any;
}

const GraphWrapper = ({ isAllGraphLoading, graphData }: GraphWrapperProps) => {
  const [overallDateRange, setOverallDateRange] = useState(30);
  const [energyDateRange, setEnergyDateRange] = useState(30);
  const [sleepDateRange, setSleepDateRange] = useState(30);
  const [appetiteDateRange, setAppetiteDateRange] = useState(30);

  return isAllGraphLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div>
      {graphData.overall && (
        <WidgetItemWrapper
          data={{ ...graphData.overall, graphFilter: overallDateRange }}
          widgetType={'insightsChartWidget'}
        >
          <InsightsChartWidget
            data={{ ...graphData.overall, graphFilter: overallDateRange }}
            onDateRangeChange={setOverallDateRange}
          />
        </WidgetItemWrapper>
      )}
      {graphData.energy && (
        <WidgetItemWrapper
          data={{ ...graphData.energy, graphFilter: energyDateRange }}
          widgetType={'insightsChartWidget'}
        >
          <InsightsChartWidget
            data={{ ...graphData.energy, graphFilter: energyDateRange }}
            onDateRangeChange={setEnergyDateRange}
          />
        </WidgetItemWrapper>
      )}
      {graphData.sleep && (
        <WidgetItemWrapper data={{ ...graphData.sleep, graphFilter: sleepDateRange }} widgetType={'barLineChartWidget'}>
          <BarLineChartWidget
            data={{ ...graphData.sleep, graphFilter: sleepDateRange }}
            onDateRangeChange={setSleepDateRange}
          />
        </WidgetItemWrapper>
      )}
      {graphData.appetite && (
        <WidgetItemWrapper
          data={{ ...graphData.appetite, graphFilter: appetiteDateRange }}
          widgetType={'barLineChartWidget'}
        >
          <BarLineChartWidget
            data={{ ...graphData.appetite, graphFilter: appetiteDateRange }}
            onDateRangeChange={setAppetiteDateRange}
          />
        </WidgetItemWrapper>
      )}
    </div>
  );
};

export default GraphWrapper;
