import { Modal, notification } from 'antd';
import scheduleImg from 'assets/images/scheduleImg.png';
import LoadingButton from 'components/v2/Button/Button';
import { CronofyCalendar, CronofyProfile } from 'interfaces/Cronofy/cronofyProfile';
import { useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { putCalendarConfig } from 'utils/http/ScheduleService/Cronofy/Cronofy';

import { PROVIDER_MAP } from '../ConnectedProfiles/components/ConnectedProfile/ConnectedProfile';
import styles from './CalendarConnectionModal.module.scss';
import ConnectedCalendar from './components/ConnectedCalendar/ConnectedCalendar';
import ModalHeader from './components/ModalHeader/ModalHeader';

interface CalendarConnectionModalProps {
  cronofyProfile: CronofyProfile;
  visible: boolean;
  onCancel: () => void;
}

const CalendarConnectionModal = ({
  cronofyProfile: cronofyProfilePayload,
  visible,
  onCancel
}: CalendarConnectionModalProps) => {
  const [cronofyProfile, setCronofyProfile] = useState(cronofyProfilePayload!);
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');

  const { isEdgeAdminView } = useGetAccountPackageView();
  const { token } = useGetAccessToken();

  const handleSubmit = async ({ id, calendars, configuration }: CronofyProfile) => {
    setSubmitButtonState('active');
    const payload = {
      profiles: [
        {
          id,
          calendars: calendars.map(({ id, configuration }) => ({
            id,
            configuration: {
              ...configuration,
              active: !configuration.disable // active will be the same with disable on first sync modal
            }
          })),
          configuration
        }
      ],
      asAdmin: isEdgeAdminView
    };
    try {
      await putCalendarConfig(token, payload);
      setSubmitButtonState('finished');
      setTimeout(() => {
        notification.success({
          message: `Calendar configurations updated.`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }, 1000);
      setTimeout(() => {
        notification.success({
          message: 'Syncing calendar events in background...',
          closeIcon: <span className={'notify'}>OK</span>
        });
      }, 3000);
      onCancel();
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Calendar configurations failed to update.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleCalendarChange = (updatedCalendar: CronofyCalendar) => {
    const updatedList = {
      ...cronofyProfile,
      calendars: cronofyProfile.calendars.map((calendar) =>
        calendar.id === updatedCalendar.id ? updatedCalendar : calendar
      )
    };
    setCronofyProfile(updatedList);
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<ModalHeader />}
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      width="600px"
    >
      <div className={styles.contentContainer}>
        <div className={styles.successMessageContainer}>
          <div className={styles.successIcons}>
            <img src={scheduleImg} className={styles.calendarIcon} alt="Calendar icon" />
            <div className={styles.checkIconRelativeWrapper}>
              <div className={styles.checkIconAbsoluteWrapper}>
                <i className={`material-icons-round ${styles.checkIcon}`}>check</i>
              </div>
            </div>
          </div>
          <div className={styles.messageContainer}>
            <div className={styles.messageTitle}>Connection Complete</div>
            <div className={styles.message}>Your calendar has been successfully connected to;</div>
            <div className={styles.emailContainer}>
              <img
                src={PROVIDER_MAP[cronofyProfile.provider].thumbnail}
                className={styles.emailDomainIcon}
                alt="Provider thumbnail"
              />
              <div className={styles.emailInfoContainer}>
                <div className={styles.emailAddress}>{cronofyProfile.email}</div>
                <div className={styles.emailDomain}>{PROVIDER_MAP[cronofyProfile.provider].name}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.syncCalendarContainer}>
          <div className={styles.header}>
            <div className={styles.title}>Sync calendar</div>
            <div className={styles.message}>
              There are <b>{cronofyProfile.calendars.length} calendars</b> we can connect with from your authorised
              account
            </div>
          </div>
          <div className={styles.calendarContentContainer}>
            {cronofyProfile.calendars.map((cal, index) => (
              <ConnectedCalendar
                cronofyCalendar={cal}
                index={index + 1}
                key={index}
                handleCalendarChange={handleCalendarChange}
              />
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <LoadingButton
              className={styles.syncButton}
              status={submitButtonState}
              onClick={() => handleSubmit(cronofyProfile)}
            >
              Run first calendar sync
            </LoadingButton>
            <div>Please note the first sync can take a few minutes to complete</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarConnectionModal;
