export enum IntegrationType {
  Account = 'account',
  Calendar = 'calendar',
  Finance = 'finance',
  Claim = 'claim'
}

export enum IntegrationStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Pending = 'pending',
  PendingAuth = 'pendingAuth',
  PendingMapping = 'pendingMapping'
}

export interface Integration {
  id: string;
  type: IntegrationType;
  provider: string;
  status: IntegrationStatus;
  label: string;
  connectedBy?: string;
  connectedAt?: string;
}
