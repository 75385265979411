import LoadingDot from 'components/LoadingDot/LoadingDot';
import { Integration as IntegrationInterface } from 'pages/ControlPanel/Interfaces/Integration';

import Integration from './components/Integration/Integration';
import styles from './IntegrationDetailsContentDisplay.module.scss';

interface IntegrationDetailsContentDisplayProps {
  integration: IntegrationInterface;
  isLoading: boolean;
}

const IntegrationDetailsContentDisplay = ({ integration, isLoading }: IntegrationDetailsContentDisplayProps) => {
  return isLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      <Integration integration={integration} />
    </div>
  );
};

export default IntegrationDetailsContentDisplay;
