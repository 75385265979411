import { GeneralPractitionerInterface } from '../../interface';
import styles from './GPCard.module.scss';
import doctorAvatar from 'assets/images/drImage.png';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';

interface GPCardProps {
  generalPractitioner: GeneralPractitionerInterface;
  showMedicareField: boolean;
  onShowAddEditModal?: () => void;
}

const GPCard = ({ generalPractitioner, showMedicareField, onShowAddEditModal }: GPCardProps) => {
  const [t] = useTranslation();

  const handleCopyContent = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      notification.success({
        message: 'Copied!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <div className={styles.gpCardContainer}>
      <div className={styles.header}>
        <div className={styles.title}>{t('label.client_gp').toUpperCase()}</div>
        {Object.keys(generalPractitioner).length > 0 && (
          <div className={styles.editButton} onClick={onShowAddEditModal}>
            <i className={`material-icons ${styles.editIcon}`}>mode_edit</i>
            Edit
          </div>
        )}
      </div>
      {Object.keys(generalPractitioner).length < 1 ? (
        <div className={styles.noGPDetail}>
          <div className={styles.doctorAvatar}>
            <img src={doctorAvatar} alt={'Dr Avatar'} />
          </div>
          <div className={styles.content}>
            No GP details added.
            <div className={styles.addButton} onClick={onShowAddEditModal}>
              Add them now
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.cardContent}>
            <div className={styles.leftContent}>Name</div>
            <div className={styles.divider} />
            <div className={`${styles.rightContent} ${styles.gpName}`}>{generalPractitioner.name}</div>
          </div>
          {showMedicareField && generalPractitioner.medicareProviderNumber && (
            <div className={styles.cardContent}>
              <div className={`${styles.leftContent} ${styles.medicareProviderNumber}`}>
                Provider No.
                <HelpOutLineWithTooltips
                  id="gp-details-medicare-provider-number"
                  desc="This Medicare provider number will be automatically used in future claims for assigned clients"
                />
              </div>
              <div className={styles.divider} />
              <div className={`${styles.rightContent} ${styles.medicareProviderNumber}`}>
                {generalPractitioner.medicareProviderNumber}
              </div>
            </div>
          )}
          {generalPractitioner.practiceName && (
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>Practice</div>
              <div className={styles.divider} />
              <div className={styles.rightContent}>{generalPractitioner.practiceName}</div>
            </div>
          )}
          {generalPractitioner.address && (
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>Address</div>
              <div className={styles.divider} />
              <div className={styles.rightContent}>{generalPractitioner.address}</div>
            </div>
          )}
          {generalPractitioner.landline && (
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>Landline</div>
              <div className={styles.divider} />
              <div className={styles.rightContent}>{generalPractitioner.landline}</div>
            </div>
          )}
          {generalPractitioner.mobileNumber && (
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>Mobile</div>
              <div className={styles.divider} />
              <div className={`${styles.rightContent} ${styles.mobileNumber}`}>
                {generalPractitioner.mobileNumber}
                <div className={styles.copyBtn} onClick={() => handleCopyContent(generalPractitioner.mobileNumber)}>
                  <i className={`material-icons ${styles.icon}`}>content_copy</i>
                </div>
              </div>
            </div>
          )}
          {generalPractitioner.email && (
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>Email</div>
              <div className={styles.divider} />
              <div className={`${styles.rightContent} ${styles.email}`}>
                {generalPractitioner.email}
                <div className={styles.copyBtn} onClick={() => handleCopyContent(generalPractitioner.email)}>
                  <i className={`material-icons ${styles.icon}`}>content_copy</i>
                </div>
              </div>
            </div>
          )}
          {generalPractitioner.fax && (
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>Fax</div>
              <div className={styles.divider} />
              <div className={`${styles.rightContent} ${styles.fax}`}>
                {generalPractitioner.fax}
                <div className={styles.copyBtn} onClick={() => handleCopyContent(generalPractitioner.fax)}>
                  <i className={`material-icons ${styles.icon}`}>content_copy</i>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GPCard;
