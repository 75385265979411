import { useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';

import styles from './PatientDetailsAssessmentHeader.module.scss';

interface PatientDetailsAssessmentsHeaderProps {
  recordId: string;
  currentTab?: string;
  assessmentId?: string;
  assessmentList?: ClinicalAssessmentResponseList[];
}

const PatientDetailsAssessmentHeader = ({
  recordId,
  currentTab,
  assessmentId,
  assessmentList
}: PatientDetailsAssessmentsHeaderProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const { currentAssessment, previousAssessment, nextAssessment } = useMemo(() => {
    const assessmentHeaderItems: {
      currentAssessment?: ClinicalAssessmentResponseList;
      previousAssessment?: ClinicalAssessmentResponseList;
      nextAssessment?: ClinicalAssessmentResponseList;
    } = { currentAssessment: undefined, previousAssessment: undefined, nextAssessment: undefined };

    if (assessmentList) {
      const currentAssessment = assessmentList.find((assessmentItem) => assessmentItem._id === assessmentId);

      if (currentAssessment) {
        assessmentHeaderItems.currentAssessment = currentAssessment;

        const currentIndex = assessmentList.indexOf(currentAssessment);

        if (currentIndex > -1) {
          assessmentHeaderItems.previousAssessment = assessmentList[currentIndex - 1];
          assessmentHeaderItems.nextAssessment = assessmentList[currentIndex + 1];
        }
      }
    }

    return assessmentHeaderItems;
  }, [assessmentId, assessmentList]);

  const handleAssessmentClick = (id: string) => {
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${id}`);

    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  return (
    <div className={styles.container}>
      {previousAssessment && (
        <div
          className={`${styles.btnBox} ${styles.left}`}
          onClick={() => handleAssessmentClick(previousAssessment._id)}
        >
          <i className={`material-icons ${styles.icon}`}>chevron_left</i>
          <div className={styles.date}>
            <div className={styles.label}>{previousAssessment.id}</div>
            {moment(previousAssessment.createdAt).format('DD/MM/YY')}
          </div>
        </div>
      )}
      {currentAssessment && (
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{currentAssessment.id}</div>
          <div className={styles.date}>{moment(currentAssessment.createdAt).format('DD/MM/YY')}</div>
        </div>
      )}
      {nextAssessment && (
        <div className={`${styles.btnBox} ${styles.right}`} onClick={() => handleAssessmentClick(nextAssessment._id)}>
          <div className={styles.date}>
            <div className={styles.label}>{nextAssessment.id}</div>
            {moment(nextAssessment.createdAt).format('DD/MM/YY')}
          </div>
          <i className={`material-icons ${styles.icon}`}>chevron_right</i>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsAssessmentHeader;
