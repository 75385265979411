import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

export const getReportTemplate = (token: string, templateId: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/reportTemplates/${templateId}`
  ) as Promise<BentResponse>;

export const getReportTemplateList = (token: string, isAdmin?: boolean) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/reportTemplates${isAdmin ? `?asAdmin=true` : ''}`
  ) as Promise<BentResponse>;

export const postReportTemplate = (token: string, body: Record<string, any>) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })('/reportTemplates', body) as Promise<BentResponse>;

export const putReportTemplate = (token: string, reportTemplateId: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(
    `/reportTemplates/${reportTemplateId}`,
    body
  ) as Promise<BentResponse>;

export const deleteReportTemplate = (token: string, reportTemplateId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/reportTemplates/${reportTemplateId}`
  ) as Promise<BentResponse>;
