import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useField } from 'formik';
import styles from './AppointmentTypeName.module.scss';
import classnames from 'classnames';

const AppointmentTypeName = () => {
  const [{ value }, meta, { setValue }] = useField('name');
  return (
    <div className={classnames(styles.fieldContainer)}>
      <MaterialInput
        id={`appointmentTypeName`}
        label={'Name of appointment type*'}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        required
      />
      {meta.touched && <div className={styles.fieldError}>{meta.error}</div>}
    </div>
  );
};

export default AppointmentTypeName;
