import { Modal, notification } from 'antd';
import Button from 'components/v2/Button/Button';
import { useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postRevokeSchoolConnectionRequest } from 'utils/http/ClinicianProfileService/MisSchools/misSchools';
import styles from './DisconnectModal.module.scss';

interface DisconnectModalProp {
  visible?: boolean;
  onClose: () => void;
  refetchSchoolConnection: () => void;
}

export const DisconnectModal = ({ visible, onClose, refetchSchoolConnection }: DisconnectModalProp) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');

  const handleDisconnectMIS = async () => {
    setSubmitButtonState('active');
    try {
      await postRevokeSchoolConnectionRequest(token, accountId);
      notification.success({ message: 'MIS School connection disconnected.' });
      setSubmitButtonState('finished');
      onClose();
      refetchSchoolConnection();
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Fail to disconnect MIS school connection.'
      });
    }
    setSubmitButtonState('');
  };

  return (
    <Modal bodyStyle={{ padding: 0 }} width={500} footer={null} visible={visible} closable={false}>
      <div className={styles.container}>
        <span className={styles.label}>Are you sure you want to disconnect MIS?</span>
        <div className={styles.footer}>
          <Button className={styles.cancelButton} onClick={onClose} id="cancelButton">
            Cancel
          </Button>
          <Button
            className={styles.nextButton}
            status={submitButtonState}
            onClick={() => handleDisconnectMIS()}
            id="disconnectButton"
          >
            Disconnect
          </Button>
        </div>
      </div>
    </Modal>
  );
};
