import { Skeleton } from 'antd';
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  ReferenceLine,
  Cell,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { ChartData } from 'interfaces/Clients/chart';
import ChartToolTip from '../ChartToolTip/ChartToolTip';

import styles from './BarLineChart.module.scss';

interface BarLineChartProps {
  data: ChartData['insights'];
  tooltipFormatter?: (value: number) => string;
  yDomain: [number, number];
  yLabel: string;
  yTicks: number[];
  yTickFormatter?: (value: number) => string;
  isLoading: boolean;
  height?: number;
}

const BarLineChart = ({
  data,
  tooltipFormatter,
  yDomain,
  yLabel,
  yTicks,
  yTickFormatter,
  isLoading,
  height
}: BarLineChartProps) => {
  const formatXAxis = (tickItem: any) => {
    return moment(tickItem).format('D MMM');
  };

  return (
    <div className={styles.container} style={{ height: height ?? 300 }}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: -30 }}>
            <CartesianGrid strokeDasharray="6 6" stroke="#D3D8DD" vertical={false} />
            <XAxis
              dataKey="date"
              tick={{ fill: '#9e9e9e', fontSize: '12' }}
              tickFormatter={formatXAxis}
              tickMargin={12}
              stroke={'#fff'}
            />
            <YAxis
              tick={{ fill: '#9e9e9e', fontSize: '12' }}
              stroke={'#fff'}
              domain={yDomain}
              label={{ value: yLabel, angle: -90, fill: '#9e9e9e', fontSize: '12' }}
              ticks={yTicks}
              tickFormatter={yTickFormatter}
              width={80}
            />
            <Tooltip content={<ChartToolTip formatter={tooltipFormatter} />} />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
                <stop offset="0" stopColor="#5780d4" />
                <stop offset="1" stopColor="#fff" />
              </linearGradient>
              <linearGradient id="colorUvNegative" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
                <stop offset="0" stopColor="#fff" />
                <stop offset="1" stopColor="#5780d4" />
              </linearGradient>
            </defs>
            <Bar dataKey="uv" barSize={18} fill="url(#colorUv)" radius={[5, 5, 0, 0]}>
              {data.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={entry.uv > 0 ? 'url(#colorUv)' : 'url(#colorUvNegative)'} />
              ))}
            </Bar>
            <Line
              type="linear"
              dataKey="uv"
              stroke="#5767ff"
              isAnimationActive={false}
              dot={{ stroke: styles.blueColor, strokeWidth: 3, r: 5, fill: '#fff', border: '2px solid red' }}
            />
            <ReferenceLine y={0} stroke="#61696c" />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default BarLineChart;
