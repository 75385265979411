import moment, { Moment } from 'moment';

export const MOMENTJS_FORMAT_DATE = 'YYYY-MM-DD';

export const getLastRecurringAppointmentDate = (startDate: string, frequency: string, occurrences: string) => {
  return moment(startDate, 'YYYY-MM-DD')
    .add(Number(frequency) * Number(occurrences), 'days')
    .format('DD MMMM YYYY');
};

export const getAllDatesInWeek = (date: Moment) => [
  date.clone().day(0).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(1).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(2).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(3).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(4).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(5).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(6).format(MOMENTJS_FORMAT_DATE)
];
