import { useEffect, useState } from 'react';
import styles from './EditGroupModal.module.scss';
import { notification } from 'antd';
import EditGroupForm, { EditGroupValidateField } from './formType/EditAdultForm/EditGroupForm';
import { useGetAccessToken } from 'utils/hooks/token';
import { EditGroupFormInterface } from './EditGroupModalInterface';
import { AttachedClientRecordStatus, Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import Button from 'components/v2/Button/Button';
import GroupStatusButtons from './components/groupStatusButtons';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { validateGroupId } from 'components/Groups/CreateGroupModal/components/GroupForm/validation/GroupFormValidation';
import { putGroup } from 'utils/http/ClinicianProfileService/Groups/Groups';
import ModalV2 from 'components/ModalV2/ModalV2';
import { GroupType } from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';

interface EditGroupModalProps {
  groupData: Groups;
  onCancel: (isUpdated: boolean) => void;
  visible: boolean;
  onRefreshData?: () => void;
}

const EditGroupModal = ({ groupData, visible, onCancel, onRefreshData }: EditGroupModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [groupFormData, setGroupFormData] = useState<EditGroupFormInterface>(groupData);
  const { accountId } = useGetAccountId();
  const [errorMessage, setErrorMessage] = useState<EditGroupValidateField>({
    name: '',
    groupId: '',
    type: '',
    typeName: ''
  });

  useEffect(() => {
    setGroupFormData(groupData);
  }, [groupData]);

  const handleChangeGroupDetail = (groupData: EditGroupFormInterface) => {
    if (!groupData.name) {
      setErrorMessage({ ...errorMessage, name: "Please enter group's name" });
    } else {
      setErrorMessage({ ...errorMessage, name: '' });
    }
    setGroupFormData(groupData);
  };

  const checkIsDuplicateGroupId = async (token: string, groupIdValue?: string, excludeGroupIdValue?: string) => {
    const isSameGroupIdValue = excludeGroupIdValue === groupIdValue;
    if (groupIdValue && !isSameGroupIdValue) {
      const pastValidateGroupId = await validateGroupId(token, accountId, groupIdValue);
      return pastValidateGroupId.statusCode !== 200;
    } else {
      return false;
    }
  };

  const validateGroupField = async (groupFieldVal: EditGroupFormInterface) => {
    const isGroupIdDuplicateExists = await checkIsDuplicateGroupId(token, groupFieldVal.groupId, groupData.groupId);
    if (!groupFieldVal.name) {
      setErrorMessage({ ...errorMessage, name: "Please enter group's name" });
      return;
    }
    if (groupFieldVal.type === GroupType.Other && !groupFieldVal.typeName) {
      setErrorMessage({ ...errorMessage, typeName: 'Please select group type name' });
      return;
    }

    const checkFieldHaveError = Object.values(errorMessage).some((value) => value !== '');
    if (checkFieldHaveError || isGroupIdDuplicateExists) {
      setSubmitStatus('');
    }

    return !checkFieldHaveError && !isGroupIdDuplicateExists;
  };
  const handleSubmit = async () => {
    if (await validateGroupField(groupFormData)) {
      setSubmitStatus('active');
      try {
        const payload = {
          status: groupFormData.status,
          groupId: groupFormData.groupId,
          name: groupFormData.name,
          leadClinicianAuth0Id: groupFormData.leadClinicianAuth0Id,
          description: groupFormData.description,
          clientStatus: groupFormData.clientStatus,
          type: groupFormData.type,
          ...(groupFormData.type === GroupType.Registration && { classLeadId: groupFormData.classLeadId })
        };
        await putGroup(token, accountId, groupData._id, payload);
        setSubmitStatus('finished');
        setTimeout(() => {
          setSubmitStatus('');
          onRefreshData && onRefreshData();
          onCancel(true);
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: 'New group fail to create',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const onStatusGroupChange = (status: boolean, clientStatus?: AttachedClientRecordStatus) => {
    setGroupFormData({
      ...groupFormData,
      status: status ? GroupStatus.Active : GroupStatus.Closed,
      ...(clientStatus && { clientStatus })
    });
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      title={'Edit Group Information'}
      isModalOpen={visible}
      onModalClose={() => onCancel(false)}
    >
      <div>
        <div>
          <div className={styles.subTitle}>GROUP STATUS</div>
          <GroupStatusButtons groupData={groupData} onChange={onStatusGroupChange} />
        </div>
        {groupData.status !== GroupStatus.Closed && (
          <div className={styles.formContainer}>
            <EditGroupForm
              selectedClinicianId={groupData.leadClinicianAuth0Id}
              practitionerList={groupData.clinicianAuth0Ids}
              onChangeGroupField={handleChangeGroupDetail}
              groupFormField={groupFormData}
              accountId={accountId}
              errorMessage={errorMessage}
              excludeGroupIdValue={groupData.groupId}
            />
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            status={submitStatus}
            disabled={submitStatus !== '' && groupData.status === GroupStatus.Closed}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </ModalV2>
  );
};

export default EditGroupModal;
