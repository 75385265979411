import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import classnames from 'classnames';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchInvoiceSettings } from 'utils/hooks/invoice';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentsInvoicedStatus } from 'utils/http/appointment';
import {
  getInvoices,
  getPaymentMethods,
  putInvoiceStatus,
  putResendInvoice
} from 'utils/http/BillingService/Invoice/invoice';
import { postConnectStripe } from 'utils/http/BillingService/Invoice/stripe';
import AppointmentStatus from './components/AppointmentStatus/AppointmentStatus';
import InvoiceListing from './components/InvoiceListing/InvoiceListing';
import InvoiceSettingsComponent from './components/InvoiceSettings/InvoiceSettings';
import LinkToAccountancy from './components/LinkToAccountancy/LinkToAccountancy';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';
import PaymentStatus from './components/PaymentStatus/PaymentStatus';
import { AppointmentsStatus, Invoice, InvoiceStatus, PaymentMethod } from './interface';
import styles from './Invoices.module.scss';

const useFetchAppointmentsStatus = (token: string) => {
  const [appointmentsStatus, setAppointmentsStatus] = useState<AppointmentsStatus>();
  const [isAppointmentsStatusLoading, setIsAppointmentsStatusLoading] = useState(true);

  const fetchAppointmentsStatus = async (token: string) => {
    try {
      const callAppointmentsStatus = await getAppointmentsInvoicedStatus(token);

      const appointmentsStatus = await callAppointmentsStatus.json();

      if (appointmentsStatus) {
        setAppointmentsStatus(appointmentsStatus);
      }
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get your appointments' invoiced status." });
    }

    setIsAppointmentsStatusLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppointmentsStatus(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appointmentsStatus, isAppointmentsStatusLoading };
};

const useFetchInvoices = (token: string) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(true);

  const fetchInvoices = async (token: string) => {
    try {
      const callInvoices = await getInvoices(token);

      const invoices = await callInvoices.json();

      if (invoices && Array.isArray(invoices)) {
        const finalInvoices = invoices.map((invoice) => ({
          ...invoice,
          status: new Date(invoice.dueDate) < new Date() && invoice.status === 'issued' ? 'overdue' : invoice.status
        }));
        setInvoices(finalInvoices);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your payment methods.' });
    }

    setIsInvoicesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchInvoices(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { invoices, isInvoicesLoading, setInvoices };
};

export const useFetchPaymentMethods = (token: string) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const fetchPaymentMethods = async (token: string) => {
    try {
      const callPaymentMethods = await getPaymentMethods(token);

      const { paymentMethods } = await callPaymentMethods.json().catch(() => ({}));

      if (paymentMethods && Array.isArray(paymentMethods)) {
        setPaymentMethods(paymentMethods);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your payment methods.' });
    }

    setIsPaymentMethodsLoading(false);
  };

  const refetchPaymentMethods = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    }).catch(() => '');

    if (token) {
      fetchPaymentMethods(token);
    }
  };

  useEffect(() => {
    if (token) {
      fetchPaymentMethods(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { paymentMethods, isPaymentMethodsLoading, refetchPaymentMethods };
};

const Invoices = () => {
  const { token } = useGetAccessToken();
  const { INVOICES } = useRoutesGenerator();
  const { user, getAccessTokenSilently } = useAuth0();
  const { invoiceId } = useParams<{ invoiceId?: string }>();
  const { appointmentsStatus, isAppointmentsStatusLoading } = useFetchAppointmentsStatus(token);
  const { invoices, isInvoicesLoading, setInvoices } = useFetchInvoices(token);
  const { invoiceSettings, isInvoiceLoading, setInvoiceSettings } = useFetchInvoiceSettings(token, user);
  const { paymentMethods, isPaymentMethodsLoading, refetchPaymentMethods } = useFetchPaymentMethods(token);
  const [invoiceIdProcessing, setInvoiceIdProcessing] = useState<string>('');
  const { search } = useLocation();
  const { stripeRefresh } = queryString.parse(search);
  const { isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();

  const [isStripeRefreshLoading, setIsStripeRefreshLoading] = useState(false);
  const [isInvoiceSettingsModalVisible, setIsInvoiceSettingsModalVisible] = useState(false);
  const [isPaymentMethodsModalVisible, setIsPaymentMethodsModalVisible] = useState(false);

  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  const groupInvoiceListing = invoices.filter((groupInvoiceObj) => groupInvoiceObj.group);
  const clientInvoiceListing = invoices.filter((clientInvoiceObj) => clientInvoiceObj.clientRecord);

  const callPostConnectStripe = async () => {
    const stripeUrl = await (await postConnectStripe(token)).text();

    window.location.href = stripeUrl;
  };

  useEffect(() => {
    if (stripeRefresh) {
      setIsStripeRefreshLoading(true);
      if (token) {
        callPostConnectStripe();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeRefresh, token]);

  useEffect(() => {
    if (invoiceId === 'invoice-settings') {
      setIsInvoiceSettingsModalVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  const handleAddPaymentMethodClick = () => {
    setIsPaymentMethodsModalVisible(true);
  };

  const onChangeStatus = async (_id: string, status: 'closed' | 'confirmPaid') => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    }).catch(() => '');

    if (token) {
      try {
        setInvoiceIdProcessing(_id);
        await putInvoiceStatus(token, _id, status);
        const newInvoices = invoices;
        const updatedInvoice = newInvoices.find((invoice) => invoice._id === _id);
        updatedInvoice && (updatedInvoice.status = status as InvoiceStatus);
        setInvoices(newInvoices);

        notification.success({
          message: "Successfully updated this invoice's status!",
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setInvoiceIdProcessing('');
      } catch (ex) {
        setInvoiceIdProcessing('');
        notification.error({ message: "Something went wrong while trying to update this invoice's status" });
      }
    }
  };

  const onResendInvoice = async (_id: string) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    }).catch(() => '');

    if (token) {
      try {
        setInvoiceIdProcessing(_id);
        await putResendInvoice(token, _id);

        notification.success({
          message: 'Successfully resent this invoice!',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setInvoiceIdProcessing('');
      } catch (ex) {
        setInvoiceIdProcessing('');
        notification.error({ message: "Something went wrong while trying to update this invoice's status" });
      }
    }
  };

  return (
    <HelmetWrapper title={'CORDS - Invoices'}>
      <ContentLayout className={classnames(isStripeRefreshLoading && styles.loading)}>
        <div className={styles.header}>
          <span className={styles.title}>Invoice Summary</span>
          <Link className={styles.link} to={INVOICES.NEW} id={'createNewInvoiceId'}>
            <i className={`material-icons-outlined ${styles.icon}`}>receipt</i>
            Create New Invoice
          </Link>
        </div>
        <div className={styles.content}>
          <div className={styles.leftCol}>
            {(isEdgeAdminView || isEdgeReceptionist || isNormalUserView) && (
              <InvoiceSettingsComponent
                invoiceSettings={invoiceSettings}
                paymentMethods={paymentMethods}
                isInvoiceSettingsLoading={isInvoiceLoading}
                isInvoiceSettingsModalVisible={isInvoiceSettingsModalVisible}
                isPaymentMethodsLoading={isPaymentMethodsLoading}
                onAddPaymentMethodClick={handleAddPaymentMethodClick}
                setInvoiceSettings={setInvoiceSettings}
                setIsInvoiceSettingsModalVisible={setIsInvoiceSettingsModalVisible}
              />
            )}
            <PaymentMethods
              paymentMethods={paymentMethods}
              isPaymentMethodsLoading={isPaymentMethodsLoading}
              isPaymentMethodsModalVisible={isPaymentMethodsModalVisible}
              setIsPaymentMethodsModalVisible={setIsPaymentMethodsModalVisible}
              onSavePaymentMethods={refetchPaymentMethods}
              isEditingAllowed={isEdgeAdminView || isEdgeReceptionist || isNormalUserView}
            />
            {(isEdgeAdminView || isEdgeReceptionist || isNormalUserView) && <LinkToAccountancy />}
          </div>
          <div className={styles.rightCol}>
            <div className={styles.statuses}>
              <div className={styles.appointmentStatus} id={'appointmentStatusContainerId'}>
                <AppointmentStatus
                  appointmentsStatus={appointmentsStatus}
                  isAppointmentsStatusLoading={isAppointmentsStatusLoading}
                />
              </div>

              <div className={styles.paymentStatus} id={'paymentStatusContainerId'}>
                <PaymentStatus
                  invoices={clientInvoiceListing}
                  groupInvoices={groupInvoiceListing}
                  enableGroupSelection={isGroupsFeatureToggle}
                />
              </div>
            </div>
            <div className={styles.listing} id={'invoiceListingId'}>
              <InvoiceListing
                invoices={clientInvoiceListing}
                groupInvoices={groupInvoiceListing}
                isInvoicesLoading={isInvoicesLoading}
                allowCreateNewInvoice
                onChangeStatus={onChangeStatus}
                onResendInvoice={onResendInvoice}
                invoiceIdProcessing={invoiceIdProcessing}
                enableGroupSelection={isGroupsFeatureToggle}
              />
            </div>
          </div>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Invoices;
