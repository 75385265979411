import CheckBox from 'components/CheckBox/CheckBox';
import classnames from 'classnames';
import moment from 'moment';
import columnStyles from '../../OutstandingAssessmentsListColumn.module.scss';
import styles from './OutstandingAssessmentItem.module.scss';
import { OutstandingAssessment } from 'pages/AllAssessments/types';
import { ProfileTagsOption } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsProfile/components/DropdownSearchable/interface';
import { useRef, useState } from 'react';

interface OutstandingAssessmentItemProps {
  assessment: OutstandingAssessment;
  profileTags: ProfileTagsOption[];
  isSelected: boolean;
  disableCheckbox: boolean;
  onCancelAssessment: () => void;
  onSelectAssessment: (checked: boolean) => void;
  onSendReminder: () => void;
}

const OutstandingAssessmentItem = ({
  assessment: { _id, createdAt, clinicalAssessmentIds, clientRecord, clientProfile, clinicianProfile },
  profileTags,
  isSelected,
  disableCheckbox,
  onCancelAssessment,
  onSelectAssessment,
  onSendReminder
}: OutstandingAssessmentItemProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleSelectButtonClick = () => {
    if (showMenu) {
      handleCloseMenu();
    } else {
      document.addEventListener('click', handleCloseMenu);
    }

    setShowMenu(!showMenu);
  };

  return (
    <div className={styles.container}>
      <div className={columnStyles.checkBoxSection}>
        <CheckBox
          inputClassName={styles.checkBox}
          id={_id}
          value={isSelected}
          onChange={(e) => onSelectAssessment(e.target.checked)}
          disabled={disableCheckbox}
        />
      </div>
      <div className={columnStyles.requestDate}>{moment(createdAt).format('YYYY-MM-DD')}</div>
      <div className={classnames(columnStyles.assessmentType, styles.assessmentType)}>
        {clinicalAssessmentIds.map((id, i) => (
          <div key={i}>{id}</div>
        ))}
      </div>
      <div className={classnames(columnStyles.student, styles.student)}>
        <div className={styles.name}>{clientProfile.firstName}</div>
      </div>
      <div className={columnStyles.class}>{clientRecord.class || '-'}</div>
      <div className={classnames(columnStyles.staffMember, styles.staffMember)}>{clinicianProfile?.name || '-'}</div>
      <div className={classnames(columnStyles.flags, styles.flags)}>
        {clientRecord.tags && clientRecord.tags.length > 0
          ? clientRecord.tags.map((tagId, i) => (
              <div className={styles.flagPill} key={i}>
                {profileTags.find(({ _id }) => _id === tagId)?.label}
              </div>
            ))
          : '-'}
      </div>
      <div className={classnames(columnStyles.buttonSection, styles.buttons)}>
        <div ref={menuRef} className={styles.actionButtonWrapper}>
          <div className={styles.actionButton} onClick={handleSelectButtonClick}>
            Actions
            <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
          </div>
          <div className={styles.menuWrapper}>
            <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
              <div className={styles.listBox} onClick={onSendReminder}>
                <div className={styles.listTitle}>Send Reminder</div>
              </div>
              <div className={styles.listBox} onClick={onCancelAssessment}>
                <div className={styles.listTitle}>Cancel Assessment</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutstandingAssessmentItem;
