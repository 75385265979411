import { Dropdown, Menu } from 'antd';
import { useMemo, useState } from 'react';
import HelpOutLineWithTooltips from '../../../../components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { Invoice } from '../../interface';
import styles from './PaymentStatus.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const MONEY_SYMBOL = GAE_REGION === 'au' ? '$' : GAE_REGION === 'gb' ? '£' : undefined;
const PARTICIPANT_FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Client', value: 'client' },
  { label: 'Group', value: 'group' }
];

interface PaymentStatusProps {
  invoices: Invoice[];
  groupInvoices?: Invoice[];
  enableGroupSelection?: boolean;
}

const PaymentStatus = ({ invoices, groupInvoices=[], enableGroupSelection }: PaymentStatusProps) => {
  const [filter, setFilter] = useState<'all' | 'client' | 'group'>('all');

  const paymentStatusByFilterStatus = (invoiceList: Invoice[]) => {
    return invoiceList.reduce(
      (totals, { invoiceAmount, status }) => {
        if (status !== 'draft' && !isNaN(invoiceAmount)) {
          totals[status] = totals[status] + invoiceAmount;
        }
        return totals;
      },
      {
        closed: 0,
        confirmPaid: 0,
        issued: 0,
        markedPaid: 0,
        overdue: 0
      }
    );
  };

  const { closed, confirmPaid, issued, markedPaid, overdue } = useMemo(() => {
    const mergeInvoice = [...invoices, ...groupInvoices];
    if (enableGroupSelection) {
      if (filter === 'group') {
        return paymentStatusByFilterStatus(groupInvoices);
      } else if (filter === 'client') {
        return paymentStatusByFilterStatus(invoices);
      }
      return paymentStatusByFilterStatus(mergeInvoice);
    } else {
      return paymentStatusByFilterStatus(invoices);
    }
  }, [invoices, groupInvoices, filter, enableGroupSelection]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Total invoice value and status overview</div>
          <HelpOutLineWithTooltips
            id={'totalInvoiceHeaderInfo'}
            desc={
              'This is a summary of the total actual amounts invoiced grouped by their status (including any taxes or discounts you have added).  Each invoice can only live in one status at a time.'
            }
          />
        </div>
        {enableGroupSelection && (
          <div className={styles.filter}>
            Show:
            <Dropdown
              overlay={
                <Menu onClick={(e) => setFilter(String(e.key) as 'all' | 'client' | 'group')}>
                  {PARTICIPANT_FILTER_OPTIONS.map((option) => (
                    <Menu.Item key={option.value}>{option.label}</Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.dropdownText}>
                {PARTICIPANT_FILTER_OPTIONS.find((option) => option.value === filter)?.label}
                <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      <div className={styles.statusesContainer}>
        <div className={styles.statusContainer}>
          <div className={styles.issuedPill}>ISSUED</div>
          <div className={styles.label}>
            {MONEY_SYMBOL}
            {issued.toFixed(2)}
          </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={styles.overduePill}>OVERDUE</div>
          <div className={styles.label}>
            {MONEY_SYMBOL}
            {overdue.toFixed(2)}
          </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={styles.markedPaidPill}>MARKED PAID</div>
          <div className={styles.label}>
            {MONEY_SYMBOL}
            {markedPaid.toFixed(2)}
          </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={styles.confirmPaidPill}>CONFIRM PAID</div>
          <div className={styles.label}>
            {MONEY_SYMBOL}
            {confirmPaid.toFixed(2)}
          </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={styles.closedPill}>CLOSED</div>
          <div className={styles.label}>
            {MONEY_SYMBOL}
            {closed.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
