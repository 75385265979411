import { useState } from 'react';
import styles from './ChangeAssessorBulk.module.scss';
import { Modal, notification } from 'antd';
import Button from 'components/v2/Button/Button';
import { patchReassignMentorToMultipleClients } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useTranslation } from 'react-i18next';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';

interface ChangeAssessorBulkProps {
  selectedStudentIds: string[];
  visible: boolean;
  onCancel: () => void;
  onUpdateSuccess: () => void;
}

const ChangeAssessorBulk = ({ visible, selectedStudentIds, onCancel, onUpdateSuccess }: ChangeAssessorBulkProps) => {
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [selectedAssessor, setSelectedAssessor] = useState<PractitionersDetailsInterface | undefined>(undefined);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = async () => {
    setSubmitStatus('active');

    const payload = {
      clientRecordIds: selectedStudentIds,
      assessorId: selectedAssessor?._id || ''
    };

    try {
      await patchReassignMentorToMultipleClients(token, accountId, payload);
      setSubmitStatus('finished');
      setTimeout(() => {
        notification.success({
          message: selectedAssessor
            ? t('form.success.change_assessor_bulk', {
                numOfStudent: selectedStudentIds.length.toString(),
                teacherName: selectedAssessor?.name.toString()
              })
            : t('form.success.change_blank_assessor_bulk', {
                numOfStudent: selectedStudentIds.length.toString()
              }),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        setSelectedAssessor(undefined);
        onUpdateSuccess();
        onCancel();
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: t('form.error.change_teacher_bulk'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleCancel = () => {
    setSelectedAssessor(undefined);
    onCancel();
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.header}>Bulk update Assessor Assignments</div>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={515}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          Change
          <span className={styles.selectedCount}>{` ${selectedStudentIds.length} `}</span>
          student{selectedStudentIds.length === 1 ? "'s" : "s'"} record{selectedStudentIds.length !== 1 && 's'} to be
          assigned to:
        </div>
        <div className={styles.teacherAssignmentWrapper}>
          <div className={styles.description}>
            <div className={styles.main}>Assessor Assignment</div>
            <div className={styles.sub}>
              Set the assessor who will be required to complete the Teacher assessments for this student.
            </div>
          </div>
          <div className={styles.teacherSelect}>
            <ClinicianSelect
              onSelect={(value) => {
                setSelectedAssessor(value as PractitionersDetailsInterface);
              }}
              profileBadgeClassname={styles.profileBadgeClassName}
              selectedId={selectedAssessor?._id}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant={'primary'} className={styles.proceedBtn} status={submitStatus} onClick={handleSubmit}>
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeAssessorBulk;
