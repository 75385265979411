import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const CHECK_IN_SERVICE_URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getOnboardingListByRecordId = (token: string, recordId: string) =>
  httpClient('GET', CHECK_IN_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/survey-responses`
  ) as Promise<BentResponse>;

export const getInitialOBAssessmentByRecordId = (token: string, recordId: string, onboardingAssessmentId: string) =>
  httpClient('GET', CHECK_IN_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/survey-responses/${onboardingAssessmentId}`
  ) as Promise<BentResponse>;
