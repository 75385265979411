import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { clientRecordsInterface, GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { sortBy } from 'lodash';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getName } from 'utils/general';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

export const useFetchClientRecordByClinician = (
  clinicianId?: string,
  showAllStatusClient?: boolean,
  communicationPreference?: string
) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { getAccessTokenSilently } = useAuth0();

  const [bookableClients, setBookableClients] = useState<clientRecordsInterface[]>([]);
  const [isBookableClientsLoading, setIsBookableClientsLoading] = useState(false);
  const [t] = useTranslation();

  const fetchBookablePatients = useCallback(
    async (clinicianId?: string) => {
      setIsBookableClientsLoading(true);
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        if ((isEdgeAdminView || isEdgeReceptionist) && accountId) {
          const filterParam = {
            recordStatus: showAllStatusClient ? undefined : 'active',
            clinicianId: clinicianId ? clinicianId : undefined,
            ...(communicationPreference && {
              communicationPreference: communicationPreference
            })
          };
          const qParam = queryString.stringify(filterParam);
          const qString = qParam ? `?${qParam}` : '';
          const clientsData = await getClientsRecordByAccountId(token, accountId, qString);
          const clientsRecordData: GetClientProfileInterface = await clientsData.json();

          const sortingMassage = sortBy(clientsRecordData.clientRecords, (clientObj) =>
            getName(clientObj.clientProfiles[0]).toLowerCase()
          );
          setBookableClients(sortingMassage);
        } else {
          const filterParam = {
            recordStatus: 'active',
            ...(communicationPreference && {
              communicationPreference: communicationPreference
            })
          };
          const qParam = queryString.stringify(filterParam);
          const qString = qParam ? `?${qParam}` : '';

          const callGetActivePatients = await getClientsRecord(token, qString);
          const data = (await callGetActivePatients.json()) as GetClientProfileInterface;

          setBookableClients(sortBy(data.clientRecords, (patient) => getName(patient.clientProfiles[0]).toLowerCase()));
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: t('form.error.failed_to_retrieve_client_record') });
      }

      setIsBookableClientsLoading(false);
    },
    [
      accountId,
      getAccessTokenSilently,
      isEdgeAdminView,
      isEdgeReceptionist,
      showAllStatusClient,
      t,
      communicationPreference
    ]
  );

  const sortClientsByName = (sort = 'asc') => {
    const sorted = sortBy(bookableClients, (patient) => getName(patient.clientProfiles[0]).toLowerCase());
    if (sort === 'desc') {
      sorted.reverse();
    }
    setBookableClients(sorted);
  };

  useEffect(() => {
    fetchBookablePatients(clinicianId);
  }, [clinicianId, fetchBookablePatients]);

  return { setBookableClients, bookableClients, isBookableClientsLoading, sortClientsByName, fetchBookablePatients };
};
