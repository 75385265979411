import { useState } from 'react';
import styles from './FinderWizardSelect.module.scss';
import { Dropdown, Menu } from 'antd';
import { OptionInterface } from '../../constants';

interface FinderWizardSelectProps {
  label: string;
  defaultOptionProps: OptionInterface;
  options: OptionInterface[];
}

const FinderWizardSelect = (props: FinderWizardSelectProps) => {
  const { label, options, defaultOptionProps } = props;
  const [selected, setSelected] = useState<OptionInterface>(defaultOptionProps);

  const onSelect = (selectedValue: string) => {
    setSelected(options.find((option) => option.value === selectedValue) || defaultOptionProps);
  };

  return (
    <>
      <div className={styles.selectLabel}>{label}</div>
      <Dropdown
        className={styles.dropdown}
        overlay={
          <Menu className={styles.listOption} onClick={(event) => onSelect(String(event.key))}>
            {options.map((option: any) => (
              <Menu.Item key={option.value} className={option.value === selected.value ? styles.selectedSortedBy : ''}>
                {option.label}
              </Menu.Item>
            ))}
          </Menu>
        }
        trigger={['click']}
      >
        <div className={styles.dropdownText}>
          {selected.label}
          <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
        </div>
      </Dropdown>
    </>
  );
};

export default FinderWizardSelect;
