import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { debounce } from 'lodash';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { ClinicalAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';

import PatientDetailsAssessmentHeader from './components/PatientDetailsAssessmentHeader/PatientDetailsAssessmentHeader';
import ScoreChart from './components/ScoreChart/ScoreChart';
import PatientAnswer from './components/PatientAnswer/PatientAnswer';

import styles from './PatientDetailsAssessments.module.scss';
import PizzaChart1 from './components/PizzaChart1/PizzaChart1';
import { getClinicalAssessmentByAssessmentId } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';

interface PatientDetailsAssessmentsProps {
  recordId?: string;
  patientId?: string;
  currentTab?: string;
  assessmentId?: string;
  assessmentList?: ClinicalAssessmentResponseList[];
  isLoadingAssessmentList?: boolean;
  showHeader?: boolean;
  assessment?: Assessment;
  isLoadingAssessment?: boolean;
}

export interface AssessmentChart {
  key?: string;
  label?: string;
  max: number;
  total: number;
  ranges: { score: string; label: string }[];
  scales: Required<ClinicalAssessment>['scales'][number]['ranges'];
}

export interface Assessment extends ClinicalAssessment {
  _id: string;
  clientProfileId: string;
  clinicianId: string;
  patientId: string;
  questionsResponsesId: string;
  charts: AssessmentChart[];
  referenceScore: { [key: string]: string[] };
  processedScore: {
    [key: string]: number;
  };
  createdAt: Date;
}

export const useFetchAssessmentByAssessmentId = (clientId: string, assessmentId?: string) => {
  const [assessment, setAssessment] = useState<Assessment | undefined>();
  const [isLoadingAssessment, setIsLoadingAssessment] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const fetchAssessment = async (assessmentId: string) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const queryParam = `clientRecordId=${clientId}`;

      const getRequest = getClinicalAssessmentByAssessmentId;

      const callGetClinicalAssessmentResponseById = await getRequest(token, assessmentId, queryParam);

      const assessment = await callGetClinicalAssessmentResponseById.json();

      setAssessment(assessment);
      setIsLoadingAssessment(false);
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get this patient's assessments" });
      setIsLoadingAssessment(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAssessmentWithDebounce = useCallback(debounce(fetchAssessment, 300), []);

  useEffect(() => {
    if (assessmentId) {
      setIsLoadingAssessment(true);
      fetchAssessmentWithDebounce(assessmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);

  return { assessment, isLoadingAssessment };
};

const PatientDetailsAssessments = ({
  recordId,
  patientId,
  currentTab,
  assessmentId,
  assessmentList,
  isLoadingAssessmentList,
  showHeader = true,
  assessment: assessmentProps,
  isLoadingAssessment
}: PatientDetailsAssessmentsProps) => {
  const clientId = recordId || patientId || '';

  const { assessment: assessmentSelf, isLoadingAssessment: isLoadingAssessmentSelf } = useFetchAssessmentByAssessmentId(
    clientId,
    isLoadingAssessment !== undefined ? undefined : assessmentId
  );
  const assessment = isLoadingAssessment !== undefined ? assessmentProps : assessmentSelf;
  const isAssessmentLoading = isLoadingAssessment !== undefined ? isLoadingAssessment : isLoadingAssessmentSelf;

  return (
    <div className={styles.container}>
      {isLoadingAssessmentList ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : assessmentId ? (
        <>
          {showHeader && (
            <PatientDetailsAssessmentHeader
              recordId={clientId}
              currentTab={currentTab}
              assessmentId={assessmentId}
              assessmentList={assessmentList}
            />
          )}
          {isAssessmentLoading ? (
            <div className={styles.loading}>
              <LoadingCircle />
            </div>
          ) : (
            assessment && (
              <div className={styles.contentContainer}>
                <div className={styles.descriptionBox}>
                  {assessment.charts.map((chart, index) => (
                    <PizzaChart1 key={index} chart={chart} showAlternateColor={assessment.id === 'CA'} />
                  ))}
                  <div className={styles.descWrapper}>
                    <div className={styles.title}>About</div>
                    <div className={styles.desc}>{assessment.description || 'No description provided'}</div>
                  </div>
                  <div>
                    {assessment.charts.length > 0 && <div className={styles.title}>Score Interpretations</div>}
                    {assessment.charts.map((chart, index) => (
                      <ScoreChart key={index} chart={chart} showAlternateColor={assessment.id === 'CA'} />
                    ))}
                  </div>
                </div>
                <PatientAnswer sections={assessment.sections} />
              </div>
            )
          )}
        </>
      ) : (
        <>No assessment selected</>
      )}
    </div>
  );
};

export default PatientDetailsAssessments;
