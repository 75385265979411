import Button from 'components/Button/Button';
import columnStyles from '../../AssessmentSummary.module.scss';
import styles from './AssessmentItem.module.scss';
import { useEffect, useRef, useState } from 'react';
import { ClientAssessment } from '../../hooks/useGetAllClientAssessments';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

interface AssessmentItemProps {
  assessment: ClientAssessment;
  clientRecordId: string;
  onCancelAssessment: () => Promise<void>;
  onSendReminder: () => Promise<void>;
}

const AssessmentItem = ({
  assessment: { _id, clinicalAssessmentIds, createdAt, isComplete },
  clientRecordId,
  onCancelAssessment,
  onSendReminder
}: AssessmentItemProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const menuNode = useRef<HTMLDivElement>(null);
  const [menuExpand, setMenuExpand] = useState(false);
  const [isCancellingAssessment, setIsCancellingAssessment] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);

  const isLoading = isCancellingAssessment || isSendingReminder;

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSendReminder = async () => {
    setIsSendingReminder(true);

    await onSendReminder();

    setIsSendingReminder(false);
    setMenuExpand(false);
  };

  const handleCancelAssessment = async () => {
    setIsCancellingAssessment(true);

    await onCancelAssessment();

    setIsCancellingAssessment(false);
    setMenuExpand(false);
  };

  return (
    <div className={styles.container}>
      <div className={columnStyles.assessmentName}>
        {clinicalAssessmentIds.map((id, i) => (
          <div key={i}>{id}</div>
        ))}
      </div>
      <div className={columnStyles.requestDate}>{moment(createdAt).format('DD/MM/YYYY')}</div>
      <div className={columnStyles.status}>
        <i className={`material-icons ${isComplete ? styles.completedIcon : styles.outstandingIcon}`}>check_circle</i>
      </div>
      <div className={columnStyles.button} ref={menuNode}>
        {isComplete ? (
          <Link className={styles.actionButton} to={`${CLIENTS.BASE}/${clientRecordId}/assessments/${_id}`}>
            <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
          </Link>
        ) : (
          <Button className={styles.actionButton} onClick={() => !isLoading && setMenuExpand(!menuExpand)}>
            <i className={`material-icons ${styles.icon}`}>more_vert</i>
          </Button>
        )}
        <div className={styles.menuWrapper}>
          <div className={menuExpand ? styles.menuShow : styles.menuHide}>
            <div className={styles.itemCard} onClick={() => !isLoading && handleSendReminder()}>
              Send Reminder
              {isSendingReminder && (
                <div className={styles.loading}>
                  <LoadingCircle />
                </div>
              )}
            </div>
            <div className={styles.itemCard} onClick={() => !isLoading && handleCancelAssessment()}>
              Cancel Assessment
              {isCancellingAssessment && (
                <div className={styles.loading}>
                  <LoadingCircle />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentItem;
