/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react';
import classnames from 'classnames';
import { flatMapDeep } from 'lodash';
import { Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import styles from './Select.module.scss';
import { Skeleton } from 'antd';

export interface SelectProps extends Omit<Props, 'value'> {
  labelClass?: string;
  label?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  styles?: Props['styles'];
  value?: string;
  smallCaretDown?: boolean;
  smallCaretDownClass?: string;
  loading?: boolean;
  isDisabled?: boolean;
  onChange?: Props['onChange'];
  onInputChange?: Props['onInputChange'];
  components?: Props['components'];
  isSearchable?: boolean;
}

const innerStyles: Props['styles'] = {
  container: (base) => ({
    ...base,
    width: 'calc(100% - 12px)'
  }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${styles.greyColor}`,
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: '48px'
  }),
  option: (base: any, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? styles.yellowColor
      : isFocused
      ? styles.lightYellowColor
      : undefined,
    ':active': {
      ...base[':active'],
      backgroundColor: !isDisabled ? (isSelected ? data.color : styles.yellowColor) : undefined
    }
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  menu: (base) => ({ ...base, marginTop: 0, width: '100% !important' }),
  placeholder: (base) => ({ ...base, fontSize: 13, lineHeight: '18px' }),
  singleValue: (base) => ({ ...base, color: '#343434' }),
  valueContainer: (base) => ({ ...base, paddingLeft: 15 }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: styles.blueColor,
    borderRadius: 25,
    color: styles.whiteColor,
    padding: 4
  }),
  multiValueLabel: (base) => ({ ...base, color: styles.whiteColor })
};

const StyledSelect = ({
  className,
  labelClass,
  label,
  hasError,
  noSpacing,
  options,
  styles: propStyles,
  value,
  smallCaretDown,
  smallCaretDownClass,
  loading,
  isDisabled,
  components,
  isSearchable,
  ...props
}: SelectProps) => {
  const selectClasses = classnames(styles.select, hasError && styles.error, noSpacing && styles.noSpacing);

  const flattenedOptions = useMemo(() => {
    if (options) {
      return flatMapDeep([...options], (option: any) => option.options ?? [option]);
    } else {
      return [];
    }
  }, [options]);

  const DropdownIndicator = () => {
    return <i className={classnames('material-icons', styles.icon, smallCaretDownClass)}>arrow_drop_down</i>;
  };

  return (
    <div className={className}>
      {label && <div className={classnames(styles.label, labelClass)}>{label}</div>}
      {loading ? (
        <div className={styles.loadingWrapper}>
          <Skeleton.Input className={styles.loading} active />
        </div>
      ) : (
        <CreatableSelect
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          className={selectClasses}
          styles={{ ...innerStyles, ...propStyles }}
          value={flattenedOptions.find((option) => option.value === value) || value}
          options={options}
          components={{
            ...components,
            ...(smallCaretDown && {
              DropdownIndicator
            })
          }}
          {...props}
        />
      )}
    </div>
  );
};

export default StyledSelect;
