import styles from './HelpOutLineWithTooltips.module.scss';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

interface InvoiceTooltipsProps {
  id: string;
  desc: string;
  iconClass?: string;
  tooltipsClass?: string;
}

const HelpOutLineWithTooltips = ({ id, desc, iconClass, tooltipsClass }: InvoiceTooltipsProps) => {
  return (
    <>
      <i className={`material-icons ${styles.toolTipsIcon} ${iconClass}`} data-tip data-for={id}>
        help_outline
      </i>
      <ReactTooltip id={id} className={classnames(styles.tooltip, tooltipsClass)}>
        <div dangerouslySetInnerHTML={{ __html: desc }} />
      </ReactTooltip>
    </>
  );
};

export default HelpOutLineWithTooltips;
