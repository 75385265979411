import { useTranslation } from 'react-i18next';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

import ClientInfo from '../components/ClientInfo/ClientInfo';
import ConsentDetails from '../components/ConsentDetails/ConsentDetails';
import { ProfileTagsOption } from '../components/DropdownSearchable/interface';
import GroupInformation from '../components/GroupInformation/GroupInformation';
import ProfileTags from '../components/ProfileTags/ProfileTags';
import styles from './AdultDetails.module.scss';

interface AdultDetailsProps {
  clientRecordData: clientRecordsEncryptedInterface;
  profileTags: ProfileTagsOption[];
  onResendInvitation: () => void;
  onCancelInvitation: () => void;
  onSelectedTags: (tag: ProfileTagsOption) => void;
  isInvitationProcessing: boolean;
  consentListData: ConsentFormListInterface[];
  refetchClientDetail?: () => void;
}

const AdultDetails = ({
  clientRecordData,
  profileTags,
  onResendInvitation,
  onCancelInvitation,
  onSelectedTags,
  isInvitationProcessing,
  consentListData,
  refetchClientDetail
}: AdultDetailsProps) => {
  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('title.client_information')}</div>
      <div className={styles.clientInfoSection}>
        <ClientInfo
          clientRecordData={clientRecordData}
          onResendInvitation={onResendInvitation}
          onCancelInvitation={onCancelInvitation}
          isInvitationProcessing={isInvitationProcessing}
          refetchClientDetail={refetchClientDetail}
        />
      </div>
      {isGroupsFeatureToggle && (
        <GroupInformation
          clientRecordId={clientRecordData._id}
          attachedGroups={clientRecordData.attachedGroups || []}
          onRefreshData={refetchClientDetail}
        />
      )}
      <div className={styles.profileTags}>
        <ProfileTags
          profileTags={profileTags}
          onSelectedTags={onSelectedTags}
          isReadOnly={clientRecordData.recordStatus === 'closed'}
        />
      </div>
      <div className={styles.docSection} id={'docSectionId'}>
        <ConsentDetails consentListData={consentListData} clientRecordData={clientRecordData} />
      </div>
    </div>
  );
};

export default AdultDetails;
