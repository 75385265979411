import classnames from 'classnames';
import { InputHTMLAttributes, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';

import LoadingCircle from '../LoadingCircle/LoadingCircle';
import styles from './MaterialInput.module.scss';

export interface MaterialInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  containerClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  isLoading?: boolean;
  showDisabledLock?: boolean;
  disabledTooltips?: string;
}

const MaterialInput = ({
  id,
  label,
  className,
  containerClassName,
  labelClassName,
  isLoading,
  value,
  placeholder,
  showDisabledLock,
  disabledTooltips,
  ...props
}: MaterialInputProps) => {
  return (
    <div className={classnames(styles.container, containerClassName)}>
      <input
        {...props}
        id={id}
        className={classnames(isLoading ? styles.fieldWithLoading : styles.field, className)}
        type={props.type || 'text'}
        autoComplete={'off'}
        placeholder={placeholder}
        value={value}
      />
      <label className={classnames(styles.label, labelClassName, placeholder && styles.maximizeLabel)} htmlFor={id}>
        {label}
      </label>
      {showDisabledLock && (
        <>
          <i className={`material-icons-outlined ${styles.lockIcon}`} data-tip data-for={id}>
            lock
          </i>
          {disabledTooltips && <ReactTooltip id={id}>{disabledTooltips}</ReactTooltip>}
        </>
      )}
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <div className={styles.loadingBox}>
            <LoadingCircle />
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialInput;
