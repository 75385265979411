import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { CaseNoteFormatSettings } from '../interfaces';
import { getGroupCaseNoteFormatAvailableSettingsByGroupId } from 'utils/http/DocumentService/CaseNotes/groupCaseNotes';

export const useFetchCaseNoteFormatSettingsList = (token: string, groupId: string, visible: boolean) => {
  const [caseNoteFormatSettingsList, setCaseNoteFormatSettingsList] = useState<CaseNoteFormatSettings[]>([]);
  const [isCaseNoteFormatSettingsListLoading, setIsCaseNoteFormatSettingsListLoading] = useState(false);

  const fetchCaseNoteFormatSettingsList = async (groupId: string) => {
    setIsCaseNoteFormatSettingsListLoading(true);

    try {
      const getGroupCaseNoteFormatSettingsByGroupId = await getGroupCaseNoteFormatAvailableSettingsByGroupId(
        token,
        groupId
      );
      const caseGroupCaseNoteFormatSettingsList = await getGroupCaseNoteFormatSettingsByGroupId.json();
      setCaseNoteFormatSettingsList(caseGroupCaseNoteFormatSettingsList);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to fetch your list of case notes format settings'
      });
    }
    setIsCaseNoteFormatSettingsListLoading(false);
  };

  useEffect(() => {
    if (token && visible && groupId) {
      fetchCaseNoteFormatSettingsList(groupId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, groupId, visible]);

  return { caseNoteFormatSettingsList, isCaseNoteFormatSettingsListLoading };
};
