import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getMCIList = (token: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(`/micro-check-ins`) as Promise<BentResponse>;

export const getMCIClientList = (token: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/micro-check-ins/clients`
  ) as Promise<BentResponse>;

export const getAssignedMCIByRecordProfileId = (token: string, recordId: string, profileId: string) =>
  httpClient('GET', URL!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/client-profiles/${profileId}/micro-check-ins`
  ) as Promise<BentResponse>;

export const putAssignedMCI = (
  token: string,
  body: {
    clientAuth0Id?: string; // for test client use
    clientRecordId?: string;
    clientProfileId?: string;
    microCheckInId: string;
  }
) => {
  return httpClient('PUT', URL!, 204, { Authorization: `Bearer ${token}` })(`/micro-check-ins/clients/assignment`, {
    ...body
  });
};

export const putAssignedMCIList = (token: string, body: any) => {
  return httpClient('PUT', URL!, 204, { Authorization: `Bearer ${token}` })(`/micro-check-ins/clients`, body);
};
