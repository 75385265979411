import { useEffect, useRef, useState } from 'react';
import styles from './GroupTags.module.scss';
import { Groups, GroupTag } from 'pages/Groups/Interfaces/Groups';

interface GroupTagsProps {
  groupSelectedTag: Groups['tags'];
  groupTagList: Groups['tags'];
}

const GroupTags = ({ groupSelectedTag, groupTagList }: GroupTagsProps) => {
  const tagListRef = useRef<HTMLDivElement>(null);

  const massageTagList = (tags: Groups['tags']) =>
    groupTagList.filter((oriObj) => tags.findIndex((obj) => obj._id === oriObj._id) === -1);

  const [selectedTag, setSelectedTag] = useState(groupSelectedTag);
  const [tagList, setTagList] = useState(massageTagList(groupSelectedTag));
  const [showTagList, setShowTagList] = useState<boolean>(false);

  const handleClick = (e: any) => {
    if (tagListRef.current?.contains(e.target)) {
      return;
    }
    setShowTagList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleRemoveTag = (tagId: string) => {
    const newTagList = selectedTag.filter((q) => q._id !== tagId);
    setSelectedTag(newTagList);
    setTagList(massageTagList(newTagList));
    // call remove Tag api here
  };

  const handleChangeValue = (value: string) => {
    const searchTags = groupTagList.filter((q) => q.label.toLowerCase().includes(value.toLowerCase()));
    setTagList(searchTags);
  };

  const handleSelectTag = (tag: GroupTag) => {
    const newSelectedTag = [...selectedTag, tag];
    setSelectedTag(newSelectedTag);
    setTagList(massageTagList(newSelectedTag));
    // call add Tag api here
    setShowTagList(false);
  };

  return (
    <div className={styles.container}>
      {
        groupTagList.length > 0 && (
          <div className={styles.tagSelectContainer}>
            <div className={styles.label}>Add Tags</div>
            <div className={styles.selectContainer} ref={tagListRef}>
              <div className={styles.tagSearchInputWrapper} onClick={() => setShowTagList(true)}>
                <input
                  className={styles.tagSearchInput}
                  placeholder={'Search here'}
                  onChange={(e) => handleChangeValue(e.target.value)}
                />
                <div className={styles.arrowIconWrapper}>
                  <span className={`material-icons-outlined ${styles.arrowIcon}`}>arrow_drop_down</span>
                </div>
              </div>
              <div>
                {showTagList && (
                  <div className={styles.tagListWrapper}>
                    {tagList.length > 0 ? (
                      <>
                        {tagList.map((tagObj, index) => (
                          <div className={styles.tagValue} onClick={() => handleSelectTag(tagObj)} key={index}>
                            {tagObj.label}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className={styles.noTagOption}>No option</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      }
      <div className={styles.tagValueWrapper}>
        {selectedTag.map((tagObj, index) => (
          <div className={styles.tag} key={index}>
            {tagObj.label}
            <span onClick={() => handleRemoveTag(tagObj._id)} className={`material-icons-outlined ${styles.closeIcon}`}>
              close
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupTags;
