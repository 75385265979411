import { useMemo } from 'react';
import styles from './PractitionerOutstandingAssessmentsModal.module.scss';
import useGetAdhocAssessmentListsWithPaginationByClinicianId from 'pages/PractitionersListing/hooks/useGetAdhocAssessmentListsWithPaginationByClinicianId';
import { Modal } from 'antd';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PaginationList from 'components/PaginationList/PaginationList';
import moment from 'moment';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';

const CLINICAL_ASSESSMENTS_PER_PAGE = 5;

interface Props {
  accountId: string;
  practitioner: PractitionersDetailsInterface;
  visible: boolean;
  onClose: () => void;
  outstandingAssessmentCounts: number;
}

const PractitionerOutstandingAssessmentsModal = ({
  accountId,
  practitioner,
  visible,
  onClose,
  outstandingAssessmentCounts
}: Props) => {
  const totalPage = useMemo(
    () => Math.ceil(outstandingAssessmentCounts / CLINICAL_ASSESSMENTS_PER_PAGE),
    [outstandingAssessmentCounts]
  );

  const { isLoading, assessmentList, currentPage, handleNavigate } =
    useGetAdhocAssessmentListsWithPaginationByClinicianId({
      accountId,
      clinicianId: practitioner._id,
      totalPage,
      assessmentsPerPage: CLINICAL_ASSESSMENTS_PER_PAGE,
      status: 'pending',
      disabled: !visible
    });

  return (
    <Modal
      title={
        <div className={styles.title}>
          View <span className={styles.practitionerName}>{practitioner.name}</span> Outstanding Assessment
        </div>
      }
      className={styles.modalContainer}
      onCancel={onClose}
      open={visible}
      footer={null}
    >
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Assessment Name</th>
                <th>Date Requested</th>
                {/* TODO: cancel assessment feature */}
                {/* <th /> */}
              </tr>
            </thead>
            <tbody>
              {assessmentList.map((assessment) => (
                <tr key={assessment._id}>
                  <td>{assessment.assignedClient?.firstName ?? '-'}</td>
                  <td>{assessment.assessment?.clinicalAssessment.id}</td>
                  <td>{moment(assessment.createdAt).format('DD/MM/yyyy')}</td>
                  {/* TODO: cancel assessment feature */}
                  {/* <td>
                    <Button variant="secondary">Cancel Assessment</Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!isLoading && outstandingAssessmentCounts > CLINICAL_ASSESSMENTS_PER_PAGE && (
        <div className={styles.paginationWrapper}>
          <PaginationList
            paging={{
              page: currentPage,
              perPage: CLINICAL_ASSESSMENTS_PER_PAGE,
              totalItem: outstandingAssessmentCounts,
              totalPage
            }}
            onPageChange={(paging) => handleNavigate(Number(paging))}
          />
        </div>
      )}
    </Modal>
  );
};

export default PractitionerOutstandingAssessmentsModal;
