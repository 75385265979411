import Button from 'components/Button/Button';

import styles from './LinkToAccountancy.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Link } from 'react-router-dom';

const LinkToAccountancy = () => {
  const { CONTROL_PANEL } = useRoutesGenerator();
  return (
    <div className={styles.container}>
      <div className={styles.title}>Link to accountancy</div>
      <div className={styles.description}>
        Connect your invoice data to accountancy software like Xero and QuickBooks
      </div>
      <Link to={CONTROL_PANEL.SYSTEM_CONNECTIONS}>
        <Button className={styles.button} variant="secondary">
          <i className={`material-icons-outlined ${styles.icon}`}>link</i>
          Explore connections
        </Button>
      </Link>
    </div>
  );
};

export default LinkToAccountancy;
