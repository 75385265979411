import { AppointmentType } from './AppointmentType';

export interface TimeSlot {
  startTime: string;
  endTime: string;
}

export enum NoAvailabilityReason {
  TimeHasPassed = 'timeHasPassed',
  IsPublicHoliday = 'isPublicHoliday',
  MaxForwardAvailability = 'maxForwardAvailability',
  MaxSessionReached = 'maxSessionReached',
  MinTimeBufferBeforeBooking = 'minTimeBufferBeforeBooking',
  NotInRoomSchedule = 'notInRoomSchedule',
  NotInClinicianSchedule = 'notInClinicianSchedule',
  NotInAppointmentSchedule = 'notInAppointmentTypeSchedule',
  RoomBusy = 'roomBusy',
  ClinicianBusy = 'clinicianBusy',
  CannotFitGap = 'cannotFitGap'
}

export type AvailabilityTimeSlot =
  | ({ isAvailable: true } & TimeSlot)
  | ({ isAvailable: false; noAvailabilityReason: NoAvailabilityReason } & TimeSlot);

export type AvailabilityTimeSlotsByDate = Record<
  string,
  | { isAvailable: true; timeSlots: AvailabilityTimeSlot[] }
  | { isAvailable: false; noAvailabilityReason: NoAvailabilityReason }
>;

export interface AppointmentTypeAvailabilities {
  appointmentType: AppointmentType;
  timeSlots: AvailabilityTimeSlotsByDate;
}
