import classnames from 'classnames';
import Button from 'components/Button/Button';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AppointmentAlert.module.scss';
import { AppointmentAlertInterface, AppointmentAlertType } from './interfaces';

interface AppointmentAlertProps {
  alert: AppointmentAlertInterface;
  token: string;
  onSelectClient: (clientRecordId: string) => void;
  onClearAlert: (alertId: string) => void;
}

const formatDurationString = (minutes: number) => {
  if (minutes <= 0) {
    return 'After appointment start time';
  }
  const duration = moment.duration(minutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  if (hours === 0) {
    return '< 1 hour before';
  } else if (hours <= 72) {
    return `${hours} hours before`;
  }
  const humanizedDuration = duration.humanize();
  return `${humanizedDuration} before`;
};

const AppointmentAlert = ({ alert, onSelectClient, onClearAlert }: AppointmentAlertProps) => {
  const { _id: alertId, title, appointment, action } = alert;
  const { clientRecord, clientRescheduleDetails } = appointment;

  const [isLoading, setIsLoading] = useState(false);

  const isCancelled = action.type === AppointmentAlertType.Cancelled;
  const isChanged = action.type === AppointmentAlertType.Changed;

  const onClickClearAlert = async (alertId: string) => {
    setIsLoading(true);
    await onClearAlert(alertId);
    setIsLoading(false);
  };

  const [t] = useTranslation();

  return (
    <div className={classnames(styles.container, isCancelled ? styles.cancelled : styles.tagged)}>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <button
          className={`${styles.clearAlertBtn} ${isLoading ? styles.clearAlertBtnBusy : ''}`}
          onClick={() => onClickClearAlert(alertId)}
        >
          Clear Alert
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles.clientInfoWrapper}>
          <div className={styles.avatarAndName}>
            <ClientAvatar
              clientData={
                clientRecord?.clientProfiles as Pick<
                  clientProfilesInterface,
                  'firstName' | 'lastName' | 'name' | 'avatar' | 'initials' | 'initialsBackgroundColor'
                >[]
              }
              avatarSize={45}
              isEllipsisName={false}
              displayLimit={2}
              nameClassName={styles.name}
              initialsClassName={styles.name}
              containerClassName={styles.containerClassName}
            />
          </div>
          <div className={styles.dateTime}>
            <span className={styles.title}>{isChanged ? 'CURRENT ' : ''}APPOINTMENT</span>
            <span className={styles.date}>{moment(appointment.date).format('dddd DD MMMM')}</span>
            <span className={styles.time}>
              {`${moment(appointment.startTime, 'HH:mm').format('HH:mmA')} - ${moment(
                appointment.endTime,
                'HH:mm'
              ).format('HH:mmA')}`}
            </span>
          </div>
        </div>
        <div className={styles.clientAppointmentWrapper}>
          {isChanged && clientRescheduleDetails ? (
            <div className={styles.dateTime}>
              <span className={styles.title}>APPOINTMENT UPDATE</span>
              <span className={styles.date}>{moment(clientRescheduleDetails.date).format('dddd DD MMMM')}</span>
              <span className={styles.time}>
                {`${moment(clientRescheduleDetails.startTime, 'HH:mm').format('HH:mmA')} - ${moment(
                  clientRescheduleDetails.endTime,
                  'HH:mm'
                ).format('HH:mmA')}`}
              </span>
            </div>
          ) : (
            <div className={styles.appointmentTime}>
              <span className={styles.Status}>{action.type.toUpperCase()}</span>
              <span className={styles.date}>{moment(action.createdAt).format('dddd DD MMMM')}</span>
              <span className={styles.By}>{`by ${action.actor}`}</span>
            </div>
          )}
          {isCancelled && action.minutesBeforeAppointment !== undefined && (
            <div className={styles.cancelledTime}>
              <span>{formatDurationString(action.minutesBeforeAppointment)}</span>
            </div>
          )}
          <div className={styles.actions}>
            <Button className={styles.button} onClick={() => onSelectClient(clientRecord?._id)}>
              {t('button.view_client_profile')}
              <i className={`material-icons-outlined ${styles.icon}`}>arrow_forward_ios</i>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentAlert;
