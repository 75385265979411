import { Modal, notification } from 'antd';
import classNames from 'classnames';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { useState } from 'react';
import { useGetAppointmentTypeById } from 'utils/hooks/AppointmentTypes/useGetAppointmentTypeById';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchPracticeInfo } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useGetAccessToken } from 'utils/hooks/token';
import { putGroupSession } from 'utils/http/ScheduleService/Groups/groups';
import { RescheduleChangeTimePayload, submitRescheduleRequest } from 'utils/http/appointment';

import { FREE_BUSY_APPOINTMENT_TYPES } from '../../Calendar/components/CalendarView/CalendarView';
import BespokeDetails from './components/BespokeDetails/BespokeDetails';
import EditBespokeForm from './components/EditBespokeForm/EditBespokeForm';
import EditEvent from './components/EditEvent/EditEvent';
import EventDetails from './components/EventDetails/EventDetails';
import FreeBusyEvent from './components/FreeBusyEvent/FreeBusyEvent';
import styles from './EventInformationModal.module.scss';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { BentResponse } from 'bent';

interface EventInformationModalProps {
  groupSessionLabel?: string;
  visible: boolean;
  appointment?: AppointmentSlots;
  appointmentId?: string;
  group: number;
  hideEditGroupBtn?: boolean;
  onClose: () => void;
  onEditComplete: () => void;
}
const EventInformationModal = ({
  groupSessionLabel,
  appointment,
  visible,
  group = 0,
  hideEditGroupBtn,
  onClose,
  onEditComplete
}: EventInformationModalProps) => {
  const { token } = useGetAccessToken();

  const { appointmentType, isAppointmentTypeLoading } = useGetAppointmentTypeById(token, appointment?.sessionTypeId!);
  const { practiceData, isPracticeDataLoading } = useFetchPracticeInfo();
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringRescheduleButtonStatus, setRecurringRescheduleButtonStatus] = useState<ButtonStatusType>('');
  const [recurringAllRescheduleButtonStatus, setRecurringAllRescheduleButtonStatus] = useState<ButtonStatusType>('');
  const [updatePayload, setUpdatePayload] = useState<any>();

  const [isEditMode, setIsEditMode] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();

  const onCancel = () => {
    onClose();
    setIsEditMode(false);
  };

  const handleEditComplete = () => {
    setIsEditMode(false);
    onEditComplete();
  };

  const isBespoke = appointment?.deliveryType === DeliveryType.BeSpoke;

  let date, endTime, recurringAppointmentId: string | undefined, startTime;

  if (appointment) {
    date = appointment.date;
    endTime = appointment.endTime;
    recurringAppointmentId = appointment.recurringAppointmentId;
    startTime = appointment.startTime;
  }

  const title = !appointment ? (
    'No appointment selected'
  ) : !isBespoke ? (
    <div className={isEditMode ? styles.editTitleContainer : styles.titleContainer}>
      {isEditMode && <div className={styles.title}>EDITING</div>}
      <div>
        {moment(appointment.startTime, 'HH:mm').format('hh:mm')}
        {' - '}
        {moment(appointment.endTime, 'HH:mm').format('hh:mm A')}
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div>{moment(appointment.date, 'YYYY-MM-DD').format('dddd, Do MMMM')}</div>
      {!FREE_BUSY_APPOINTMENT_TYPES.includes(appointment.type) &&
        appointment.type !== 'reserved' &&
        appointment.deliveryType !== DeliveryType.BeSpoke.toString() &&
        !isEditMode && (
          <Button className={styles.changeDayOrTime} variant="link" icon="edit" onClick={() => setIsEditMode(true)}>
            Change day or time
          </Button>
        )}
      {isEditMode && (
        <Button variant="link" icon="chevron_left" onClick={() => setIsEditMode(false)}>
          Back
        </Button>
      )}
    </div>
  ) : isEditMode ? (
    'EDIT EVENT'
  ) : (
    <div className={styles.bespokeTitle}>
      <div className={styles.title}>
        <span>{appointment.title}</span>
        {appointment.recurringAppointmentId && <span className="material-icons-outlined">repeat</span>}
      </div>
      <Button variant="link" icon="edit" onClick={() => setIsEditMode(true)}>
        Edit this event
      </Button>
    </div>
  );

  const callRescheduleAppointment = async (
    includeRecurringAppointments: boolean,
    updatePayload: any,
    setSubmitStatus: Function
  ) => {
    try {
      setSubmitStatus('active');
      if (appointment) {
        const { sessionTypeId, _id, groupId } = appointment;

        const payload: RescheduleChangeTimePayload = {
          asAdmin: isEdgeAdminView,
          includeRecurringAppointments,
          appointmentTypeId: sessionTypeId!,
          appointmentId: _id,
          note: '',
          ...updatePayload
        };

        let response;

        if (groupId) {
          response = (await putGroupSession(token, groupId, _id, payload)) as BentResponse;
        } else {
          response = (await submitRescheduleRequest(payload, token)) as BentResponse;
        }

        if (response.statusCode === 204) {
          Modal.destroyAll();

          setSubmitStatus('finished');

          setTimeout(() => {
            setSubmitStatus('');
            handleEditComplete();
            setIsRecurring(false);
          }, 2000);

          notification.success({
            message: 'Your appointment has been updated',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else if (response.statusCode === 409) {
          notification.error({ message: (await response.json()).message ?? 'Something went wrong.' });
          setSubmitStatus('');
        } else {
          notification.error({ message: 'Something went wrong.' });
          setSubmitStatus('');
        }
      }
    } catch (e) {
      notification.error({ message: 'Something went wrong.' });
      setSubmitStatus('');
      console.error({ e });
    }
  };

  const handleUpdateAppointment = async (payload: any, setSubmitStatus: Function) => {
    if (recurringAppointmentId) {
      setIsRecurring(true);
      setUpdatePayload(payload);
    } else {
      await callRescheduleAppointment(false, payload, setSubmitStatus);
    }
  };

  const generateContent = (appointment: AppointmentSlots, appointmentType?: AppointmentType) => {
    if (isEditMode && !isBespoke) {
      return (
        <EditEvent
          appointment={appointment}
          appointmentType={appointmentType}
          handleUpdateAppointment={handleUpdateAppointment}
        />
      );
    } else if (isEditMode && isBespoke) {
      return (
        <EditBespokeForm
          appointment={appointment}
          handleUpdateAppointment={handleUpdateAppointment}
          onEditComplete={onEditComplete}
        />
      );
    } else if (isBespoke) {
      return (
        <BespokeDetails
          appointment={appointment}
          practice={practiceData?.practice}
          token={token}
          onFinishMarkStatus={onEditComplete}
        />
      );
    } else {
      return (
        <EventDetails
          groupSessionLabel={groupSessionLabel}
          appointment={appointment}
          appointmentType={appointmentType}
          practice={practiceData?.practice}
          token={token}
          isLoading={isPracticeDataLoading || (!!appointment.sessionTypeId && isAppointmentTypeLoading)}
          onFinishMarkStatus={onEditComplete}
          handleUpdateAppointment={handleUpdateAppointment}
          hideEditGroupBtn={hideEditGroupBtn}
        />
      );
    }
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        borderRadius: '16px'
      }}
      width={820}
      visible={visible}
      footer={null}
      destroyOnClose
      closable={false}
      className={styles.modal}
    >
      <>
        <Modal
          visible={isRecurring}
          okType={'primary'}
          title={'This appointment is a recurring appointment.'}
          closable
          onCancel={() => setIsRecurring(false)}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className={styles.confirmModalAppointmentDescription}>
            <div>
              <strong>Date:</strong> {moment(date, 'YYYY-MM-DD').format('dddd DD MMMM YYYY')}
            </div>
            <div>
              <strong>Time:</strong> {`${startTime} - ${endTime}`}
            </div>
          </div>
          <div className={styles.confirmModalConfirmCancel}>
            Would you like to reschedule all related recurring appointments?
          </div>
          <div className={styles.confirmModalButtonContainer}>
            <Button
              className={`${styles.modalButton} ${
                recurringRescheduleButtonStatus !== '' ? styles.modalButtonBusy : ''
              }`}
              onClick={() => callRescheduleAppointment(true, updatePayload, setRecurringAllRescheduleButtonStatus)}
              disabled={recurringAllRescheduleButtonStatus !== '' || recurringRescheduleButtonStatus !== ''}
              status={recurringAllRescheduleButtonStatus}
            >
              Reschedule all recurring appointments
            </Button>
            <Button
              className={`${styles.modalButton} ${
                recurringRescheduleButtonStatus !== '' ? styles.modalButtonBusy : ''
              }`}
              onClick={() => callRescheduleAppointment(false, updatePayload, setRecurringRescheduleButtonStatus)}
              disabled={recurringAllRescheduleButtonStatus !== '' || recurringRescheduleButtonStatus !== ''}
              status={recurringRescheduleButtonStatus}
            >
              Reschedule this appointment
            </Button>
            <Button
              className={styles.modalCancelButton}
              onClick={() => {
                Modal.destroyAll();
                setIsRecurring(false);
              }}
            >
              Don't reschedule this appointment
            </Button>
          </div>
        </Modal>
        <div
          className={classNames(
            styles.container,
            !isBespoke && styles[`group${group}`],
            isBespoke && !isEditMode && styles.bespoke,
            isBespoke && isEditMode && styles.bespokeEdit,
            appointment?.type && appointment.type === 'reserved' && styles.reserved
          )}
        >
          <ModalHeader title={title} onCancel={onCancel} />
          {appointment && FREE_BUSY_APPOINTMENT_TYPES.includes(appointment.type) ? (
            <FreeBusyEvent appointment={appointment} onToggleFreeBusy={onEditComplete} />
          ) : appointment ? (
            generateContent(appointment, appointmentType)
          ) : (
            <div className={styles.noAppointment}>No appointment details</div>
          )}
        </div>
      </>
    </Modal>
  );
};

export default EventInformationModal;
