import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';

export const MEDICARE_ROLES_OPTIONS = [
  {
    label: 'Clinical Psychologist',
    value: ClinicianMedicareRole.ClinicalPsychologists
  },
  {
    label: 'Registered Psychologist',
    value: ClinicianMedicareRole.RegisteredPsychologists
  },
  {
    label: 'Occupational Therapist',
    value: ClinicianMedicareRole.OccupationalTherapists
  },
  {
    label: 'Social Worker',
    value: ClinicianMedicareRole.SocialWorkers
  }
];
