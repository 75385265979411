import { HTMLProps } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';

import styles from './Tag.module.scss';
import LoadingCircle from '../LoadingCircle/LoadingCircle';

export interface TagProps {
  type?: 'info' | 'normal' | 'active';
  label?: string;
  counter?: number;
  isLoading?: boolean;
}

const Tag = ({
  type = 'normal',
  label = '',
  counter = 0,
  className,
  isLoading,
  ...props
}: TagProps & HTMLProps<HTMLButtonElement>) => {
  const typeStyle = get({ normal: styles.normal, info: styles.info, active: styles.active }, type, styles.normal);
  return (
    <button {...props} className={classnames(className, styles.container, typeStyle)}>
      <div className={styles.label}>{label}</div>
      {isLoading ? <LoadingCircle /> : <div className={styles.counter}>{counter}</div>}
    </button>
  );
};

export default Tag;
