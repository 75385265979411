import { Field, FieldProps, useField } from 'formik';
import Autocomplete from 'react-google-autocomplete';
import styles from './FormikPlaceMaterialInput.module.scss';
import classnames from 'classnames';
import { InputHTMLAttributes } from 'react';

interface FormikPlaceMaterialInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  className?: string;
  containerClassName?: string;
  name: string;
  props?: any;
}

const FormikPlaceMaterialInput = ({
  label,
  className,
  containerClassName,
  name,
  ...props
}: FormikPlaceMaterialInputProps) => {
  const [, , { setValue }] = useField(name);

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <div className={classnames(styles.container, containerClassName)}>
          <Autocomplete
            {...props}
            {...field}
            id={name}
            inputAutocompleteValue={'off'}
            className={classnames(styles.locationInput, className)}
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onPlaceSelected={(place) => {
              setValue(place.formatted_address || place.name);
            }}
            options={{
              types: ['geocode'],
              fields: ['address_component', 'formatted_address', 'geometry']
            }}
          />
          {label && <label className={styles.label}>{label}</label>}
        </div>
      )}
    </Field>
  );
};

export default FormikPlaceMaterialInput;
