import * as yup from 'yup';
import { config } from 'config/config';

export const NEW_CLIENT_CONTACT_INITIAL = {
  _id: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  formattedMobileNumber: '',
  tags: [],
  email: ''
};

export const CONTACT_TAG_OPTIONS = [
  'Partner',
  'Next of Kin',
  'Emergency Contact',
  'Husband',
  'Wife',
  'Son',
  'Daughter',
  'Family Member',
  'Employer',
  'Teacher',
  'Doctor',
  'Care Provider',
  'Bill Payer',
  'Safeguarding',
  'Human Resources',
  'Occupational Health',
  'Nurse',
  'Consent to share data',
  'No consent to share'
];

export const clientContactValidation = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  mobileNumber: yup
    .string()
    .min(config.mobileNumberMinLength, `Enter at least ${config.mobileNumberMinLength} characters`),
  email: yup.string().email('Please enter a valid email').nullable(true)
});
