import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import styles from './ContentDeliveryIntroduction.module.scss';
import footerBackground from '../../../assets/images/content-delivery-faq-background.png';
import bazaarLogo from '../../../assets/images/bazaar/bazaarLogo.png';
import wideLogo from '../../../assets/images/bazaar/wideLogo.png';
import bazaar1 from '../../../assets/images/bazaar/bazaar1.png';
import bazaar2 from '../../../assets/images/bazaar/bazaar2.png';
import bazaar3 from '../../../assets/images/bazaar/bazaar3.png';
import bazaar4 from '../../../assets/images/bazaar/bazaar4.png';
import Profile from '../Profile/Profile';
import { useNavigate } from 'react-router-dom';

const ContentDeliveryIntroduction = () => {
  const data = {
    id: 'COD123',
    logoUrl: bazaarLogo,
    logoBackgroundColor: styles.blueColor,
    title: 'Bazaar',
    subtitle: 'A marketplace for the mind.',
    description:
      'Bazaar blends elements of CBT, Mindfulness and more to help young people and young adults aged 15-30 understand their mind. Training skills, tools and techniques to support yourself now and in the future.',
    broughtToYouByLogo: wideLogo,
    chapterDescription:
      'Bazaar is a innovative and creative therapeutic intervention that has a strong evidence basis for reducing symptoms of anxiety, depression, low mood, stress and panic attacks.',
    numberOfChapterCompleted: 3,
    chapters: [
      {
        chapterImageUrl: bazaar1,
        title: 'Without Boarders',
        description:
          'What is CBT? How does it work? Learn about the causes of anxiety & depression. Study the Vicious Cycle and begin your Mood Diary.',
        status: 'COMPLETE'
      },
      {
        chapterImageUrl: bazaar2,
        title: 'Broken Records',
        description:
          'What are problematic thoughts? Learn how to set SMART aims and make small changes that create a big impact.',
        status: 'COMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Shape and Form',
        description: 'Small Changes, Big Impact! (continued). Distraction techniques. Twisted Thinking.',
        status: 'COMPLETE'
      },
      {
        chapterImageUrl: bazaar4,
        title: 'Walk With Me',
        description: 'Mindfulness, what is it? Having a mindful approach, and how to deal with thoughts.',
        status: 'INCOMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Strike A Light',
        description:
          'Challenging Twisted Thinking. Thought Records (advanced). Re-evaluating thoughts. Problem solving solutions. Understanding other issues.',
        status: 'INCOMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Strike A Light',
        description:
          'Challenging Twisted Thinking. Thought Records (advanced). Re-evaluating thoughts. Problem solving solutions. Understanding other issues.',
        status: 'INCOMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Strike A Light',
        description:
          'Challenging Twisted Thinking. Thought Records (advanced). Re-evaluating thoughts. Problem solving solutions. Understanding other issues.',
        status: 'INCOMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Strike A Light',
        description:
          'Challenging Twisted Thinking. Thought Records (advanced). Re-evaluating thoughts. Problem solving solutions. Understanding other issues.',
        status: 'INCOMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Strike A Light',
        description:
          'Challenging Twisted Thinking. Thought Records (advanced). Re-evaluating thoughts. Problem solving solutions. Understanding other issues.',
        status: 'INCOMPLETE'
      },
      {
        chapterImageUrl: bazaar3,
        title: 'Strike A Light',
        description:
          'Challenging Twisted Thinking. Thought Records (advanced). Re-evaluating thoughts. Problem solving solutions. Understanding other issues.',
        status: 'INCOMPLETE'
      }
    ],
    faq: [
      {
        question: 'This is the question being asked here?',
        answer:
          'Our core focus is addressing the pain points for front line mental health practitioners. Using technology and data our goal is to help you in the important work you do with your clients.  And we go further to add more insight and even links to other pages.'
      },
      {
        question: 'This is the question being asked here?',
        answer:
          'Our core focus is addressing the pain points for front line mental health practitioners. Using technology and data our goal is to help you in the important work you do with your clients.  And we go further to add more insight and even links to other pages.'
      },
      {
        question: 'This is the question being asked here?',
        answer:
          'Our core focus is addressing the pain points for front line mental health practitioners. Using technology and data our goal is to help you in the important work you do with your clients.  And we go further to add more insight and even links to other pages.'
      },
      {
        question: 'This is the question being asked here?',
        answer:
          'Our core focus is addressing the pain points for front line mental health practitioners. Using technology and data our goal is to help you in the important work you do with your clients.  And we go further to add more insight and even links to other pages.'
      },
      {
        question: 'This is the question being asked here?',
        answer:
          'Our core focus is addressing the pain points for front line mental health practitioners. Using technology and data our goal is to help you in the important work you do with your clients.  And we go further to add more insight and even links to other pages.'
      },
      {
        question: 'This is the question being asked here?',
        answer:
          'Our core focus is addressing the pain points for front line mental health practitioners. Using technology and data our goal is to help you in the important work you do with your clients.  And we go further to add more insight and even links to other pages.'
      }
    ]
  };

  const navigate = useNavigate();
  const goToChapter = (chapterNumber: number) => {
    navigate(`/contentDelivery/${data.id}/chapters/${chapterNumber}/pages/1`);
  };

  return (
    <>
      <Profile />
      <div className={styles.container}>
        <div className={styles.gradientBackground} />
        <div className={styles.header}>
          <div
            className={styles.logoContainer}
            style={{
              backgroundColor: data.logoBackgroundColor
            }}
          >
            <img src={data.logoUrl} alt="logo" />
          </div>
          <div className={styles.detailsContainer}>
            <div className={styles.title}>{data.title}</div>
            <div className={styles.subtitle}>{data.subtitle}</div>
            <div className={styles.description}>{data.description}</div>
            <div className={styles.broughtToYouBy}>BROUGHT TO YOU BY</div>
            <div className={styles.broughtToYouByLogoContainer}>
              <img src={data.broughtToYouByLogo} alt="logo" />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.innerBody}>
            <div className={styles.chapters}>{data.chapters.length} chapters</div>
            <div className={styles.chapterDescription}>{data.chapterDescription}</div>
            {data.chapters.map((chapter, index) => (
              <div
                key={index}
                className={classNames(
                  styles.chapterContainer,
                  chapter.status === 'INCOMPLETE' && index > data.numberOfChapterCompleted && styles.incomplete
                )}
              >
                <div className={styles.chapterImageContainer}>
                  <img src={chapter.chapterImageUrl} alt={chapter.title} />
                </div>
                <div className={styles.chapterNumber}>{index + 1}.</div>
                <div className={styles.chapterDetails}>
                  <div className={styles.chapterTitle}>
                    <span>{chapter.title}</span>
                    {chapter.status === 'COMPLETE' && (
                      <div className={styles.mobileCompleteText}>
                        <span className="material-icons-outlined">check_circle</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.description}>{chapter.description}</div>
                  <div className={styles.actionContainer}>
                    {chapter.status === 'COMPLETE' && (
                      <>
                        <div className={styles.completeText}>
                          <span className="material-icons-outlined">check_circle</span>{' '}
                          <span className={styles.text}>COMPLETE</span>
                        </div>
                        <Button variant="link" icon="chevron_right" iconPostFix onClick={() => goToChapter(index)}>
                          Revisit this chapter
                        </Button>
                      </>
                    )}
                    {index === data.numberOfChapterCompleted && (
                      <Button
                        variant="primary"
                        icon="chevron_right"
                        iconPostFix
                        className={styles.startButton}
                        onClick={() => goToChapter(index)}
                      >
                        START THIS CHAPTER
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={styles.footer}
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url(${footerBackground})`
        }}
      >
        <div className={styles.innerContainer}>
          <div className={styles.faqContainer}>
            <div className={styles.title}>Frequently asked questions</div>
            <div className={styles.content}>
              {data.faq.map((faq, index) => (
                <div key={index} className={styles.faq}>
                  <div className={styles.question}>{faq.question}</div>
                  <div className={styles.answer}>{faq.answer}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.contactUsContainer}>
            <div className={styles.text}>Have a question or need more support? Please contact us here</div>
            <Button variant="primary" icon="chevron_right" iconPostFix className={styles.contactUsButton}>
              CONTACT US
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentDeliveryIntroduction;
