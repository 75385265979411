import classNames from 'classnames';
import { CommentWidgetInterface } from 'pages/Report/ReportDetails/interface';

import styles from './CommentTab.module.scss';
import Comment from './components/Comment/Comment';

interface CommentTabProps {
  visible: boolean;
  comments: CommentWidgetInterface[];
  isAuthor: boolean;
  handleExpandComment: (id: string) => void;
}

const CommentTab = ({ visible, comments, isAuthor, handleExpandComment }: CommentTabProps) => (
  <div className={classNames(styles.container, !visible && styles.hidden)}>
    <div className={styles.commentSection}>
      {comments.length > 0 ? (
        comments.map(({ _id, comments }) => (
          <div className={styles.commentContainer} key={_id}>
            <Comment id={_id} comments={comments} handleExpandComment={handleExpandComment} />
          </div>
        ))
      ) : (
        <div className={styles.emptyCommentContainer}>
          <div className={styles.emptyCommentMessage}>{`${
            isAuthor ? 'Reviewers' : 'Author'
          } can see these comments.`}</div>
          <div className={styles.emptyCommentMessage}>Just click on the report to add the first comment.</div>
        </div>
      )}
    </div>
  </div>
);

export default CommentTab;
