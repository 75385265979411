import { AssessmentRole, ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useEffect, useState } from 'react';
import { getClinicalAssessmentTemplate } from 'store/CheckIn/GetClinicalAssessmentTemplate/action';

export const massageSQDSequence = (assessmentList: ClinicalAssessmentStruct[]) => {
  const filterCordsAssessment = assessmentList.filter((assessmentObj) => assessmentObj.metadata?.cords);
  return filterCordsAssessment.map((assessmentObj, index) => ({
    ...assessmentObj,
    title: assessmentObj.metadata?.cords?.shortTitle || '',
    ...(assessmentObj.metadata && {
      metadata: {
        ...assessmentObj.metadata,
        sdqSequence:
          assessmentObj.metadata.role === AssessmentRole.Teacher
            ? 1
            : assessmentObj.metadata.role === AssessmentRole.Parent
            ? 2
            : assessmentObj.metadata.role === AssessmentRole.Student
            ? 3
            : index
      }
    })
  }));
};

export const useFetchAssessmentList = (token: string) => {
  const [assessmentList, setAssessmentList] = useState<ClinicalAssessmentStruct[]>([]);
  const [isAssessmentLoading, setIsAssessmentLoading] = useState<boolean>(true);

  const fetchAssessmentList = async () => {
    const assessmentList = await getClinicalAssessmentTemplate(token);

    if (Array.isArray(assessmentList)) {
      setAssessmentList(massageSQDSequence(assessmentList));
    }
    setIsAssessmentLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssessmentList();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { assessmentList, isAssessmentLoading };
};
