import { ReportDimension } from './report';
import { CaseNoteField } from '../caseNote';

export enum ReportTemplateStatus {
  Draft = 'draft',
  Published = 'published'
}

export interface ReportTemplateItem {
  id: string;
  position: {
    top: number;
    left: number;
  };
  dimensions: {
    width: number | string;
    height: number | string;
  };
  details: any;
}

export interface ReportTemplate {
  _id: string;
  title: string;
  status: ReportTemplateStatus;
  updatedAt: Date;
  createdBy?: {
    _id: string;
    name: string;
  };
  isEditing?: boolean;
  asAdmin?: boolean;
  template: {
    dimensions: ReportDimension;
  };
  items: ReportTemplateItem[];
}

export interface ReportTemplateValidationInterface {
  title: boolean;
  items: boolean;
}

export enum ReportTemplateWidgetId {
  RecentPsychometric = 'recentPsychometric',
  EarliestPsychometric = 'earliestPsychometric',
  PractitionerAssignment = 'practitionerAssignment',
  ClientName = 'clientName',
  ClientDOB = 'clientDOB',
  ClientEmail = 'clientEmail',
  ClientMobile = 'clientMobile',
  Questions = 'questions'
}

export interface ReportTemplateCaseNoteField extends Omit<CaseNoteField, 'options'> {
  options: {
    _id: string;
    label: string;
    subLabel?: string;
    count?: number;
    options?: { label: string; value: string }[];
    value?: string;
  }[];
}
