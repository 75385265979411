import { useEffect, useRef, useState } from 'react';
import { Row, Col, Menu, Dropdown, Skeleton, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { map, groupBy } from 'lodash';
import moment from 'moment';
import InfiniteScroller from 'react-infinite-scroller';

import { getPatientActivityFeed } from 'utils/http/CheckInService/ClientActivity/activityFeed';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import MonthActivityFeed from './components/MonthActivityFeed/MonthActivityFeed';

import styles from './ActivityFeed.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { massageCORDSActivityLabel } from 'utils/CORDS/massageActivityFeedLabel';

const FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Appointments', value: 'appointment' },
  { label: 'User Updates', value: 'user' },
  { label: 'Check-ins', value: 'check-in' },
  { label: 'Profile Updates', value: 'profile' },
  { label: 'Student Updates', value: 'patient' },
  { label: 'Assessments', value: 'assessment' },
  { label: 'Survey', value: 'onboarding' },
  { label: 'Invoices', value: 'invoice' },
  { label: 'Reports', value: 'report' },
  { label: 'Notes', value: 'note' }
];

export enum ActivityActor {
  Student = 'student',
  Staff = 'staff',
  WellbeingTeam = 'wellbeing team',
  System = 'system'
}

export interface PatientActivity {
  clinicianId?: string;
  patientId?: string;
  clientRecordId?: string;
  clientProfileId?: string;
  originId?: string;
  clinicianName: string;
  accountId: string;
  object:
    | 'appointment'
    | 'appointment change request'
    | 'user'
    | 'check-in'
    | 'profile'
    | 'student'
    | 'assessment'
    | 'onboarding'
    | 'invoice'
    | 'report'
    | 'client profile'
    | 'student profile'
    | 'note'
    | 'communication preference'
    | 'survey'
    | 'mentor'
    | 'group'
    | 'intervention'
    | 'staff'
    | 'wellbeing team'
    | 'action plan'
    | 'case note'
    | 'assessor';
  action:
    | 'attended'
    | 'shared'
    | 'booked'
    | 'signed up'
    | 'onboarded'
    | 'updated'
    | 'rescheduled'
    | 'cancelled'
    | 'initiated'
    | 'sent'
    | 'issued'
    | 'resent'
    | 'claim paid'
    | 'confirm paid'
    | 'published'
    | 'unpublished'
    | 'revoked'
    | 'closed'
    | 'activated'
    | 'generated'
    | 'changed'
    | 'approved'
    | 'rejected';
  actor: ActivityActor;
  description: string;
  body?: string;
  createdAt: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
}

const useListActivityFeed = (patientId: string, selectedFilter: string, refreshActivity: number) => {
  const { getAccessTokenSilently } = useAuth0();

  const [fetching, setFetching] = useState(false);
  const [activityFeed, setActivityFeed] = useState<PatientActivity[]>([]);
  const [hasMoreActivity, setHasMoreActivity] = useState(true);
  const [lastActivityId, setLastActivityId] = useState('');
  const [lastNoteId, setLastNoteId] = useState('');

  const pageSize = 20;

  const initialFetch = async () => {
    setFetching(true);

    await fetchActivityFeed(true);

    setFetching(false);
  };

  const fetchActivityFeed = async (fetchNew: boolean) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const callGetPatientActivityByPatientId = await getPatientActivityFeed(
        token,
        patientId,
        selectedFilter,
        pageSize,
        fetchNew ? '' : lastActivityId,
        fetchNew ? '' : lastNoteId
      );
      const { data, count, nextActivityId, nextNoteId } = await callGetPatientActivityByPatientId.json();

      if (fetchNew) {
        setActivityFeed(massageCORDSActivityLabel(data));
      } else {
        setActivityFeed([...activityFeed, ...massageCORDSActivityLabel(data)]);
      }
      setHasMoreActivity(count === pageSize);
      setLastActivityId(nextActivityId || '');
      setLastNoteId(nextNoteId || '');
    } catch (e) {
      console.error(e);
      notification.error({ message: "Some thing went wrong while trying to fetch this patient's activity" });
    }
  };

  useEffect(() => {
    initialFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshActivity, selectedFilter]);

  return { fetching, activityFeed, hasMoreActivity, fetchActivityFeed };
};

interface ActivityFeedProps {
  clientRecordData: clientRecordsInterface;
  refreshActivity: number;
  profile: ProfileInterface;
}

const ActivityFeed = ({ clientRecordData, refreshActivity, profile }: ActivityFeedProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('all');

  const { fetching, activityFeed, hasMoreActivity, fetchActivityFeed } = useListActivityFeed(
    clientRecordData._id,
    selectedFilter,
    refreshActivity
  );

  const infiniteScrollContainerRef = useRef<HTMLDivElement>(null);

  const handleFilterClick = (filter: string) => {
    if (filter === selectedFilter) {
      setSelectedFilter('all');
    } else {
      setSelectedFilter(filter);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Activity Feed</div>
        <div className={styles.headerFilter}>
          <Row gutter={16}>
            <Col>Filter</Col>
            <Col>
              <Dropdown
                overlay={
                  <Menu selectedKeys={[selectedFilter]} onClick={({ key }) => handleFilterClick(key as string)}>
                    {FILTER_OPTIONS.map((option) => (
                      <Menu.Item className={styles.list} key={option.value}>
                        <div>{option.label}</div>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <div className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                  {FILTER_OPTIONS.find((option) => option.value === selectedFilter)?.label} <DownOutlined />
                </div>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.content}>
        {fetching ? (
          <Skeleton active />
        ) : !activityFeed.length ? (
          <div className={styles.noActivity}>No activity recorded.</div>
        ) : (
          <div className={styles.monthGroupContainer} ref={infiniteScrollContainerRef}>
            <InfiniteScroller
              getScrollParent={() => infiniteScrollContainerRef.current}
              initialLoad={false}
              loader={
                <div className={styles.activityFeedLoading} key={0}>
                  <LoadingCircle />
                </div>
              }
              loadMore={() => fetchActivityFeed(false)}
              useWindow={false}
              hasMore={hasMoreActivity}
            >
              {map(
                groupBy(activityFeed, (activity) => moment(activity.createdAt).format('MMMM YYYY')),
                (items, monthString) => {
                  return (
                    <MonthActivityFeed
                      key={monthString}
                      items={items}
                      monthString={monthString}
                      clientRecordData={clientRecordData}
                      profile={profile}
                    />
                  );
                }
              )}
            </InfiniteScroller>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityFeed;
