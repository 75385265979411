import { useMemo } from 'react';

import { Assessment } from '../../PatientDetailsAssessments';

import styles from './ScoreChart.module.scss';

interface ScoreChartProps {
  chart: Assessment['charts'][number];
  showAlternateColor: boolean;
}

const ScoreChart = ({ chart, showAlternateColor }: ScoreChartProps) => {
  const COLOR_CONFIG: { [key: number]: string[] } = {
    1: ['#f9a501'],
    2: ['#f5d779', '#f9a501'],
    3: ['#c4c4c4', '#f4e9dc', '#f9a501'],
    4: showAlternateColor ? ['#57ba20', '#f8e870', '#f2a756', '#f8826a'] : ['#c4c4c4', '#f4e9dc', '#f5d779', '#f9a501'],
    5: ['#c4c4c4', '#f4e9dc', '#f5d779', '#f9a501', '#ff5722'],
    6: ['#c4c4c4', '#f4e9dc', '#f5d779', '#f9bd01', '#f9a501', '#ff5722']
  };

  const widthCalc = 100 / chart.scales.length;

  const pointerPosition = useMemo(() => {
    const { total, scales } = chart;

    const foundScale = scales.find((scale) => total >= scale.min && total <= scale.max);

    if (foundScale) {
      const index = scales.indexOf(foundScale);
      const value = total - foundScale.min;
      const difference = foundScale.max - foundScale.min;

      if (index >= -1) {
        return Math.ceil((index + value / difference) * widthCalc);
      }
    }

    return 0;
  }, [chart, widthCalc]);

  return (
    <div className={styles.container}>
      {chart.key && <div className={styles.rangeTitle}>{chart.key}</div>}
      <div className={styles.chartContainer}>
        <div className={styles.arrowWrapper}>
          <div style={{ left: `${pointerPosition}%` }} className={styles.arrow}>
            <div className={styles.label}>{chart.total}</div>
            <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
          </div>
        </div>
        <div className={styles.chart}>
          {chart.scales.map((scale, index) => {
            const lastItem = index === chart.scales.length - 1;
            return (
              <div
                key={index}
                className={styles.part}
                style={{
                  backgroundColor: COLOR_CONFIG[chart.scales.length]?.[index],
                  width: `${lastItem ? widthCalc + index : widthCalc - 1}%`
                }}
              />
            );
          })}
        </div>
        <div className={styles.descWrapper}>
          {chart.scales.map((scale, index) => {
            const lastItem = index === chart.scales.length - 1;
            return (
              <div
                key={index}
                className={styles.labelPart}
                style={{ width: `${lastItem ? widthCalc + index : widthCalc - 1}%` }}
              >
                <div className={styles.value}>{scale.min}</div>
                {lastItem && <div className={styles.lastValue}>{scale.max}</div>}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {chart.ranges.map((range, index) => (
          <div key={index}>
            <strong>{range.score}</strong> = {range.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScoreChart;
