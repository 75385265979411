import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Auth0ProviderWithHistory from 'components/Auth/Auth0ProviderWithHistory';

import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import ContentDeliveryLayout from 'layouts/ContentDeliveryLayout/ContentDeliveryLayout';

import Forbidden403 from 'pages/403/Forbidden403';
import NotFound404 from 'pages/404/NotFound404';
import PracticeSignUp from 'pages/SignUp/PracticeSignUp/PracticeSignUp';
import ClinicianSignUp from 'pages/SignUp/ClinicianSignUp/ClinicianSignUp';
import Login from 'pages/Login/Login';
import Unverified from 'pages/Unverified/Unverified';
import AuthRoutes from './Routes/AuthRoutes';
import ContentDeliveryIntroduction from 'pages/ContentDelivery/ContentDeliveryIntroduction/ContentDeliveryIntroduction';
import ContentDeliveryPage from 'pages/ContentDelivery/ContentDeliveryPage/ContentDeliveryPage';
import SetPassword from 'pages/SetPassword/SetPassword';
import appRoute from 'components/AppRoute/AppRoute';
import SetPasswordLayout from 'layouts/SetPasswordLayout/SetPasswordLayout';

const App = () => (
  <Router>
    <Auth0ProviderWithHistory>
      <Routes>
        {appRoute({ path: '/403', component: Forbidden403, layout: NoAuthLayout })}
        {appRoute({ path: '/404', component: NotFound404, layout: NoAuthLayout })}
        {appRoute({ path: '/signup', component: ClinicianSignUp, layout: NoAuthLayout })}
        {appRoute({ path: '/:practiceSlugUrl/signup', component: PracticeSignUp, layout: NoAuthLayout })}
        {appRoute({ path: '/', component: Login, layout: NoAuthLayout })}
        {appRoute({ path: '/login', component: Login, layout: NoAuthLayout })}
        {appRoute({ path: '/unverified', component: Unverified, layout: NoAuthLayout })}
        {appRoute({
          path: '/set-password',
          component: SetPassword,
          layout: SetPasswordLayout
        })}
        {appRoute({
          path: `/contentDelivery/:contentDeliveryId`,
          component: ContentDeliveryIntroduction,
          layout: ContentDeliveryLayout
        })}
        {appRoute({
          path: `/contentDelivery/:contentDeliveryId/chapters/:chapterNumber/pages/:pageNumber`,
          component: ContentDeliveryPage,
          layout: ContentDeliveryLayout
        })}
        <Route element={<AuthLayout />}>
          <Route path="*" element={<AuthRoutes />} />
        </Route>
      </Routes>
    </Auth0ProviderWithHistory>
  </Router>
);

export default App;
