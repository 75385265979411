import { notification } from 'antd';
import classNames from 'classnames';
import { Integration, IntegrationType } from 'pages/ControlPanel/Interfaces/Integration';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { postMedicareIntegration } from 'utils/http/BillingService/Invoice/claimingcom';
import { postConnectCodat } from 'utils/http/BillingService/Invoice/codat';
import { v4 as uuidv4 } from 'uuid';

import Status from '../ConnectedIntegration/components/components/Status/Status';
import styles from './IntegrationItem.module.scss';

interface IntegrationItemProps {
  item: {
    icon: string;
    provider: string;
    label: string;
    type: string;
    description: string;
  };
  integration?: Integration;
  isCodatConnected?: boolean;
  selectIntegration: (type: string, id: string) => void;
  setShowDisconnectWarning: (bool: boolean) => void;
  setNewIntegrationLabel: (label: string) => void;
}

const IntegrationItem = ({
  item: { icon, label, type, provider, description },
  integration,
  isCodatConnected,
  selectIntegration,
  setShowDisconnectWarning,
  setNewIntegrationLabel
}: IntegrationItemProps) => {
  const { CALENDAR, INVOICES } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const redirectToCronofy = () => {
    const randomizedState = uuidv4();
    const CRONOFY_AUTH_STATE_LS_KEY = 'cronofy-calendar-link-auth';
    const calendarLinkingUrl = `${window.location.origin}${CALENDAR.SETTINGS_CLD_LINKING}`;

    localStorage.setItem(CRONOFY_AUTH_STATE_LS_KEY, randomizedState);

    window.location.href = encodeURI(
      `${
        process.env.REACT_APP_CRONOFY_URL ?? 'https://app-uk.cronofy.com'
      }/oauth/authorize?response_type=code&client_id=${
        process.env.REACT_APP_CRONOFY_CLIENT_ID ?? '0Rp6DcLQFeNWnAUwMySDCzw37Bo0Fs41'
      }&redirect_uri=${calendarLinkingUrl}&scope=read_write&state=${randomizedState}`
    );
  };

  const redirectToCodat = async () => {
    const codatUrl = await (await postConnectCodat(token)).text();
    window.open(codatUrl);
  };

  const setupMedicare = async () => {
    const medicare = (await (await postMedicareIntegration(token)).json()) as Pick<
      Integration,
      'id' | 'status' | 'connectedAt' | 'connectedBy'
    >;
    selectIntegration(IntegrationType.Claim, medicare.id);
  };

  const handleOnClick = async () => {
    if (isLoading) {
      return;
    }
    try {
      if (type === IntegrationType.Account && isCodatConnected && !integration) {
        setNewIntegrationLabel(label);
        setShowDisconnectWarning(true);
        return;
      }
      setIsLoading(true);
      if (integration) {
        selectIntegration(integration.type, integration.id);
      } else {
        switch (type) {
          case IntegrationType.Account:
            await redirectToCodat();
            break;
          case IntegrationType.Calendar:
            await redirectToCronofy();
            break;
          case IntegrationType.Finance:
            navigate(INVOICES.BASE);
            break;
          case IntegrationType.Claim:
            await setupMedicare();
            break;
        }
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: `Something went wrong while trying connecting to ${label}` });
    }

    setIsLoading(false);
  };

  return (
    <div className={classNames(styles.container, isLoading && styles.loading)} onClick={handleOnClick}>
      <div className={styles.iconContainer}>
        <div className={styles.iconWrapper}>
          <img src={icon} alt={`${provider}-icon`} className={styles.icon} />
        </div>
        {integration?.status && (
          <div className={styles.statusContainer}>
            <Status status={integration.status} />
          </div>
        )}
      </div>
      <div className={styles.detail}>
        <div className={styles.name}>{label}</div>
        <div className={styles.desc}>{description}</div>
      </div>
    </div>
  );
};

export default IntegrationItem;
