import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  getInterventionTags,
  getInterventionListingByAccountId,
  getInterventionList
} from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import { InterventionListData, Interventions, InterventionTag } from 'MHH/pages/Interventions/Interfaces/Interventions';

export const useFetchInterventionList = (token: string) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [interventionList, setInterventionList] = useState<Interventions[]>([]);
  const [isInterventionListLoading, setIsInterventionListLoading] = useState(true);

  const fetchInterventionList = useCallback(
    async (token: string) => {
      try {
        setIsInterventionListLoading(true);

        const interventionsTags = await getInterventionTags(token, accountId);
        const interventionsTagsData: InterventionTag[] = await interventionsTags.json();

        if (isEdgeAdminView || isEdgeReceptionist) {
          const interventionsData = await getInterventionListingByAccountId(token, accountId);
          const interventionListingData: InterventionListData = await interventionsData.json();
          const interventionListsWithTags: Interventions[] = interventionListingData.interventions.map((obj) => ({
            ...obj,
            tags: obj.tags
              .map((tagsObj) => {
                return interventionsTagsData.find(({ _id }) => _id === tagsObj);
              })
              .filter((val) => val !== undefined)
          })) as Interventions[];

          setInterventionList(interventionListsWithTags);
        } else {
          const interventionListData = await getInterventionList(token);
          const interventionListingData: InterventionListData = await interventionListData.json();
          const interventionListsWithTags: Interventions[] = interventionListingData.interventions.map((obj) => ({
            ...obj,
            tags: obj.tags
              .map((tagsObj) => {
                return interventionsTagsData.find(({ _id }) => _id === tagsObj);
              })
              .filter((val) => val !== undefined)
          })) as Interventions[];

          setInterventionList(interventionListsWithTags);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your intervention list' });
      }
      setIsInterventionListLoading(false);
    },
    [accountId, isEdgeAdminView, isEdgeReceptionist]
  );

  useEffect(() => {
    if (token) {
      fetchInterventionList(token);
    }
  }, [fetchInterventionList, token]);

  return { interventionList, isInterventionListLoading, fetchInterventionList };
};
