import WidgetCard from '../WidgetsListContent/components/WidgetCard/WidgetCard';
import { QuestionWidgetInterface, TemplateWidgetView } from '../../interface';
import { ReportWidgetType } from 'interfaces/Reports/report';
import { ReportTemplateWidgetId } from 'interfaces/Reports/reportTemplate';

const QuestionWidgetMenuList: QuestionWidgetInterface = {
  id: TemplateWidgetView.Question,
  label: 'Create question',
  widgetType: ReportWidgetType.CaseNoteQuestionWidget,
  items: {
    widgetId: ReportTemplateWidgetId.Questions,
    questionDetails: {
      name: 'New Field Label',
      type: 'text',
      active: false,
      editable: false,
      mandatory: false
    }
  }
};

const QuestionMenu = () => (
  <WidgetCard
    data={{
      widgetId: QuestionWidgetMenuList.id,
      width: 400,
      questionDetails: QuestionWidgetMenuList.items.questionDetails
    }}
    widgetType={QuestionWidgetMenuList.widgetType}
    itemLabel={'Create Question'}
    inUsed={false}
  />
);

export default QuestionMenu;
