import { useEffect, useState } from 'react';
import { mciListInterface } from 'interfaces/checkInService/mciAssessment';
import { getAssignedMCIByRecordProfileId, getMCIList } from 'utils/http/CheckInService/MicroCheckIn/mci';

export const useFetchMCIList = (token: string) => {
  const [MCIs, setMCIs] = useState<mciListInterface[]>([]);
  const [isMCIsLoading, setIsMCIsLoading] = useState(false);

  const fetchAssessment = async () => {
    setIsMCIsLoading(true);
    const callGetMCIAssessments = await getMCIList(token);
    const MCIData = await callGetMCIAssessments.json();

    const newMCIData = [
      {
        value: '',
        label: 'None',
        isPublished: true
      }
    ];

    for (let obj of MCIData.microCheckIns) {
      if (obj.isPublished) {
        newMCIData.push({
          value: obj.id,
          label: obj.name,
          isPublished: obj.isPublished
        });
      }
    }

    setMCIs(newMCIData);
    setIsMCIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { MCIs, isMCIsLoading };
};

export const useFetchAssignedMCI = (token: string, recordId: string, profileId: string) => {
  const [assignedMCI, setAssignedMCI] = useState<string | undefined>(undefined);
  const [isAssignedMCILoading, setIsAssignedMCILoading] = useState(false);

  const fetchAssignedMCI = async () => {
    setIsAssignedMCILoading(true);
    const callGetAssignedMCIByPatientId = await getAssignedMCIByRecordProfileId(token, recordId, profileId);
    const assignedMCI = await callGetAssignedMCIByPatientId.text();

    setAssignedMCI(assignedMCI);
    setIsAssignedMCILoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssignedMCI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { setAssignedMCI, assignedMCI, isAssignedMCILoading };
};
