import { useEffect, useState } from 'react';
import ModalV2 from 'components/ModalV2/ModalV2';

import styles from './TemplatePreview.module.scss';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import DndPreviewSection from 'pages/Report/components/ReportBuilderPreview/components/ReportDraftContent/components/DndPreviewSection/DndPreviewSection';

interface TemplatePreviewProps {
  showPreviewModal: boolean;
  setShowPreviewModal: (val: boolean) => void;
  templateData: ReportTemplate;
}

const TemplatePreview = ({ showPreviewModal, setShowPreviewModal, templateData }: TemplatePreviewProps) => {
  const [templateDetail, setTemplateDetail] = useState<ReportTemplate>(templateData);

  useEffect(() => {
    setTemplateDetail(templateData);
  }, [templateData, showPreviewModal]);

  return (
    <ModalV2
      containerClassName={styles.container}
      isModalOpen={showPreviewModal}
      title={`Template Builder Preview`}
      onModalClose={() => setShowPreviewModal(!showPreviewModal)}
      bodyStyle={{ padding: '16px' }}
    >
      <div className={styles.content}>
        <DndPreviewSection
          dndDimension={templateDetail.template.dimensions}
          reportItems={templateDetail.items}
          templatePreview
        />
      </div>
    </ModalV2>
  );
};

export default TemplatePreview;
