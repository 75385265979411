import Tag from 'components/Tag/Tag';
import SearchBar from 'components/SearchBar/SearchBar';
import styles from './AllAssessmentsFilters.module.scss';
import { AllAssessmentFilters, AllAssessmentTabId } from 'pages/AllAssessments/types';

interface AllAssessmentsFiltersProps {
  filters: AllAssessmentFilters;
  filterType: AllAssessmentTabId;
  outstandingAssessmentCount?: number;
  onChangeAssessmentTab: (tabId: AllAssessmentTabId) => void;
  onChangeFilters: (filters: AllAssessmentFilters) => void;
}

const AllAssessmentsFilters = ({
  filters,
  filterType,
  outstandingAssessmentCount,
  onChangeAssessmentTab,
  onChangeFilters
}: AllAssessmentsFiltersProps) => {
  const handleSearch = (value: string) => {
    onChangeFilters({ ...filters, name: value });
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper} id={'clientFilterStatusId'}>
        <div className={styles.filterWrapperTitle}>Show</div>
        <div className={styles.filterWrapperTags}>
          <div className={styles.tagWrapper}>
            <Tag
              label="Outstanding"
              type={filterType === AllAssessmentTabId.Outstanding ? 'active' : 'normal'}
              counter={outstandingAssessmentCount}
              onClick={() => onChangeAssessmentTab(AllAssessmentTabId.Outstanding)}
              isLoading={outstandingAssessmentCount === undefined}
            />
          </div>
          {/* <div className={styles.tagWrapper}>
            <Tag
              label="Outstanding"
              type={filterType === AllAssessmentTabId.Outstanding ? 'active' : 'normal'}
              counter={outstandingAssessmentCount}
              onClick={() => onChangeAssessmentTab(AllAssessmentTabId.Outstanding)}
              isLoading={outstandingAssessmentCount === undefined}
            />
          </div> */}
        </div>
      </div>
      <div className={styles.searchBarWrapper}>
        <div className={styles.searchBarCard}>
          <SearchBar
            placeholder={'Search by student name or ID'}
            value={filters.name}
            withSearchButton
            onSearch={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default AllAssessmentsFilters;
