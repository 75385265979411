import { useState } from 'react';
import { Modal } from 'antd';
import { Formik } from 'formik';
import 'react-phone-input-2/lib/style.css';
import * as yup from 'yup';

import { createVerification } from 'utils/http/verification';

import TwoFAInput from 'components/MobileConfirm/TwoFAInput/TwoFAInput';
import Button from 'components/v2/Button/Button';

import styles from './ConfirmUpdateContactDetailsModal.module.scss';

const twoFASchema = yup.object().shape({
  '2fa': yup
    .array()
    .of(yup.string())
    .test({
      name: '2fa',
      message: 'Please enter the 6-digit code sent to your mobile number',
      test: (value) => (value as string[]).filter((value) => !!value).length === 6
    })
});

interface ConfirmUpdateContactDetailsModalProps {
  mobileNumber: string;
  visible: boolean;
  onCancel: (email?: string, mobileNumber?: string) => void;
  onSubmit: () => Promise<void>;
  onVerifyError: () => void;
}

const ConfirmUpdateContactDetailsModal = ({
  mobileNumber,
  visible,
  onCancel,
  onSubmit,
  onVerifyError
}: ConfirmUpdateContactDetailsModalProps) => {
  const [confirmationSubmitButtonStatus, setConfirmationSubmitButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [verificationButtonSubmitButtonStatus, setVerificationButtonSubmitButtonStatus] = useState<
    '' | 'active' | 'finished'
  >('');
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleCancel = () => {
    onCancel();
    setConfirmationSubmitButtonStatus('');
    setVerificationButtonSubmitButtonStatus('');
    setIsVerified(false);
    setIsVerifying(false);
  };

  const handleCreateVerification = async () => {
    setConfirmationSubmitButtonStatus('active');

    const body = {
      numberToVerify: mobileNumber
    };

    try {
      const createVerificationStatus = await createVerification(body);

      if ((await createVerificationStatus.text()) === 'pending') {
        setConfirmationSubmitButtonStatus('finished');
        setIsVerifying(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      onVerifyError();
      setConfirmationSubmitButtonStatus('');
    }
  };

  const handleTwoFASuccess = () => {
    setIsVerified(true);
  };

  const handleSubmit = async () => {
    setVerificationButtonSubmitButtonStatus('active');

    await onSubmit();

    setVerificationButtonSubmitButtonStatus('finished');

    setTimeout(() => setVerificationButtonSubmitButtonStatus(''), 2000);
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      width={512}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div className={styles.header}>Update contact details</div>
      <Formik
        initialValues={{
          '2fa': ['', '', '', '', '', ''],
          mobileNumber: Number.parseInt(mobileNumber, 10).toString()
        }}
        validationSchema={twoFASchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {isVerifying ? (
          <div className={styles.verificationContainer}>
            <TwoFAInput onSuccess={handleTwoFASuccess} />
            {isVerified && (
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.submitButton}
                  status={verificationButtonSubmitButtonStatus}
                  disabled={!!verificationButtonSubmitButtonStatus}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.confirmationContainer}>
            <div className={styles.title}>Are you sure you want to update your contact details?</div>
            <div className={styles.description}>
              As you've changed your email or mobile number, you will need to complete two-factor authentication.
            </div>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.submitButton}
                status={confirmationSubmitButtonStatus}
                disabled={!!confirmationSubmitButtonStatus}
                onClick={handleCreateVerification}
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default ConfirmUpdateContactDetailsModal;
