import bent from 'bent';
import { ClinicalAssessmentsResponse } from 'interfaces/checkInService/clinicalAssessment';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';

const getData = (authKey: string) => {
  const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;
  const checkInRequest = bent<ClinicalAssessmentsResponse>(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return checkInRequest('/clinical-assessments');
};

const massageData = (data: ClinicalAssessmentsResponse) => {
  return [
    ...data.clinicalAssessments.map((clinicalAssessment) => ({
      ...clinicalAssessment,
      isChecked: false
    }))
  ];
};

export const getClinicalAssessmentTemplate = async (token: string): Promise<ClinicalAssessmentStruct[]> => {
  const templateData = await getData(token);
  return massageData(templateData);
};
