import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import AppointmentTypeSelect from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';
import Button from 'components/v2/Button/Button';
import CustomiseSchedule from 'components/v2/CustomiseSchedule/CustomiseSchedule';
import FormSection from 'components/v2/FormSection/FormSection';
import { Formik } from 'formik';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';

import { appointmentSchema } from '../../../EventCreationModal/components/EventForm/components/ScheduleForm/constants';
import styles from './EditEvent.module.scss';

interface EditEventProps {
  appointment: AppointmentSlots;
  appointmentType?: AppointmentType;
  handleUpdateAppointment: (payload: any, setSubmitStatus: Function) => void;
}

const EditEvent = ({ appointment, appointmentType, handleUpdateAppointment }: EditEventProps) => {
  const { deliveryType } = appointment;
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [changeNote, setChangeNote] = useState('');
  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState(appointment?.sessionTypeId || '');
  const [selectedAppointmentTypeName, setSelectedAppointmentTypeName] = useState(appointment?.sessionTypeName || '');
  const [selectedSlot, setSelectedSlot] = useState<SelectedAppointmentSlot>();
  const [isCustomising, setIsCustomising] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSelectAppointmentType = (type?: AppointmentType & Required<Pick<AppointmentType, '_id'>>) => {
    setSelectedAppointmentTypeId(type?._id || '');
    setSelectedAppointmentTypeName(type?.name || '');
    setSelectedSlot(undefined);
  };

  const handleChangeSlot = (slot?: SelectedAppointmentSlot) => {
    setSelectedSlot(slot);
    setErrorMessage('');
  };

  const handleUpdate = async () => {
    await handleUpdateAppointment(
      {
        appointmentTypeId: selectedAppointmentTypeId,
        date: selectedSlot?.date,
        startTime: selectedSlot?.startTime,
        endTime: selectedSlot?.endTime,
        note: changeNote,
        room: appointment.room,
        customised: isCustomising
      },
      setSubmitStatus
    );
  };
  return (
    <Formik initialValues={{}} validationSchema={appointmentSchema} onSubmit={handleUpdate}>
      {({ submitForm }) => (
        <>
          <div className={styles.container}>
            {deliveryType !== DeliveryType.BeSpoke.toString() ? (
              <>
                <FormSection title="Appointment Type">
                  <AppointmentTypeSelect
                    clinicianId={appointment.clinicianId || ''}
                    onSelect={onSelectAppointmentType}
                    horizontal
                    hideTitle
                    preSelectedAppointmentTypeId={appointment.sessionTypeId}
                    selectedParticipantType={appointmentType?.participantType || ParticipantType.OneToOne}
                  />
                </FormSection>
                {!_.isEmpty(selectedAppointmentTypeId) && (
                  <div>
                    {isCustomising ? (
                      <CustomiseSchedule
                        timeslot={selectedSlot}
                        onChangeSchedule={setSelectedSlot}
                        setIsCustomising={setIsCustomising}
                      />
                    ) : (
                      <AvailableTimeSelect
                        appointmentTypeId={selectedAppointmentTypeId}
                        onSelectSlot={handleChangeSlot}
                        selectedClinicianId={appointment.clinicianId}
                        isEditing
                        setIsCustomising={setIsCustomising}
                        selectedRoomId={appointment.room?.roomId}
                      />
                    )}
                  </div>
                )}

                <ErrorMessage error={errorMessage} visible={!!errorMessage} />

                <div className={styles.changeNoteContainer}>
                  <div className={styles.label}>Include a change note</div>
                  <textarea className={styles.textarea} onChange={(e) => setChangeNote(e.target.value)} />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {selectedAppointmentTypeId && !_.isEmpty(selectedSlot) && (
            <div className={styles.footer}>
              <div>
                <div className={styles.label}>CHANGING TO</div>
                <div className={styles.detailsContainer}>
                  <div className={styles.data}>{selectedAppointmentTypeName}</div>
                  <div className={styles.label}>ON</div>
                  {selectedSlot && (
                    <div className={styles.data}>
                      <div>{moment(`${selectedSlot.date}`, 'YYYY-MM-DD').format('dddd Do MMMM')}</div>
                      <div>
                        {moment(`${selectedSlot.startTime}`, 'HH:mm').format('hh:mm')}
                        {' to '}
                        {moment(`${selectedSlot.endTime}`, 'HH:mm').format('hh:mm a')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Button status={submitStatus} className={styles.button} onClick={submitForm}>
                Reschedule Appointment
              </Button>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

export default EditEvent;
