import bent from 'bent';
import { AssessmentType } from 'interfaces/firstAssessment';

const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

const getData = (authKey: string) => {
  const dataServiceReportRequest = bent(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return dataServiceReportRequest('/micro-check-ins');
};

const massageAssessment = (data: any) => {
  const massageFirstAssessment = [];
  for (let obj of data) {
    massageFirstAssessment.push({
      id: obj.id.toString(),
      name: obj.name,
      isPublished: obj.isPublished,
      isDefault: obj.isDefault,
      clinicalAssessments: obj.clinicalAssessments,
      assessmentType: AssessmentType.MicroCheckIn as const,
      createdAt: obj.createdAt,
      updatedAt: obj.updatedAt,
      clients: obj.clients
    });
  }

  return massageFirstAssessment;
};

const massageData = (data: any) => {
  return massageAssessment(data.microCheckIns);
};

export const getMicroCheckInAssessments = async (token: string) => {
  const reportData = await getData(token);
  return massageData(reportData);
};
