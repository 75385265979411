import { useState } from 'react';

import HelpOutLineWithTooltips from '../../../HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import Radio from '../../../Radio/Radio';
import OptionLabel from '../../../OptionLabel/OptionLabel';

import styles from './CapabilityForm.module.scss';
import { newClientForm } from '../../AddPatientModalV2Interface';
import { useTranslation } from 'react-i18next';

interface CapabilityFormProps {
  capabilityData: newClientForm['clientCapability'];
  onChangeCapabilityField: any;
  recordOnly?: boolean;
  fullProfileDesc?: string;
  recordDesc?: string;
}

const CapabilityForm = ({
  capabilityData,
  onChangeCapabilityField,
  recordOnly,
  fullProfileDesc,
  recordDesc
}: CapabilityFormProps) => {
  const [clientCapabilityDetails, setClientCapabilityDetails] = useState(capabilityData);
  const [t] = useTranslation();

  const handleClientCapability = (value: newClientForm['clientCapability']['profileType']) => {
    const details = {
      ...clientCapabilityDetails,
      profileType: value
    };
    setClientCapabilityDetails(details);
    onChangeCapabilityField(details);
  };

  return (
    <div id="capabilityId">
      <div className={styles.title}>
        Set client capability
        <HelpOutLineWithTooltips
          id={'clientCapability'}
          desc={'Decide whether to have full profile or profile record only'}
        />
      </div>
      <Radio
        name={'clientCapability'}
        disabled={recordOnly}
        options={[
          {
            value: 'full',
            label: (
              <OptionLabel
                isSelected={clientCapabilityDetails.profileType === 'full'}
                title={t('client_capability.full')}
                desc={fullProfileDesc}
                disabledTitle={recordOnly}
                highlightTitle
              />
            )
          }
        ]}
        value={clientCapabilityDetails.profileType}
        onChange={(e) => handleClientCapability(e.target.value as newClientForm['clientCapability']['profileType'])}
      />
      <Radio
        name={'clientCapability'}
        options={[
          {
            value: 'recordOnly',
            label: (
              <OptionLabel
                isSelected={clientCapabilityDetails.profileType === 'recordOnly'}
                title={t('client_capability.record_only')}
                desc={recordDesc}
                highlightTitle
              />
            )
          }
        ]}
        value={clientCapabilityDetails.profileType}
        onChange={(e) => handleClientCapability(e.target.value as newClientForm['clientCapability']['profileType'])}
      />
    </div>
  );
};

export default CapabilityForm;
