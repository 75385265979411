/* eslint-disable react/no-multi-comp */
import { useField } from 'formik';
import { useMemo } from 'react';

import { Question } from '../../../../AssessmentInterfaces';

import BarSlider from './components/BarSlider/BarSlider';
import FreeText from './components/FreeText/FreeText';
import HexSlider from './components/HexSlider/HexSlider';
import MultipleAnswer from './components/MultipleAnswers/MultipleAnswers';
import MultipleChoice from './components/MultipleChoice/MultipleChoice';
import MultipleChoiceFreetext from './components/MultipleChoiceFreeText/MultipleChoiceFreeText';
import Numeric from './components/Numeric/Numeric';
import SelectOther from './components/SelectOther/SelectOther';
import Vote from './components/Vote/Vote';

import styles from './Question.module.scss';

interface QuestionComponentInterface {
  question: Question;
  title?: string;
  reverseStemAndHideTitle?: boolean;
  defaultPatientDetails?: { name: string; picture: string };
}

const getInputComponent = (
  questionType: Question['questionType']
): ((props: { question: Question; defaultPatientDetails?: { name: string; picture: string } }) => JSX.Element) => {
  switch (questionType) {
    case 'barSlider':
      return BarSlider;
    case 'freeText':
      return FreeText;
    case 'hexSlider':
      return HexSlider;
    case 'multipleAnswers':
      return MultipleAnswer;
    case 'multipleChoice':
      return MultipleChoice;
    case 'multipleChoiceFreeText':
      return MultipleChoiceFreetext;
    case 'numeric':
      return Numeric;
    case 'selectOther':
      return SelectOther;
    case 'vote':
      return Vote;

    default:
      return () => <></>;
  }
};

const QuestionComponent = ({
  question,
  title,
  reverseStemAndHideTitle,
  defaultPatientDetails
}: QuestionComponentInterface) => {
  const { questionType, stem, stimulus } = question;

  const [, { error }] = useField({
    name: `${question.id}.value`,
    multiple: question.maxSelection === 0
  });

  const InputComponent = useMemo(() => getInputComponent(questionType), [questionType]);

  return (
    <div className={styles.container} id={error ? 'assessment-invalid-question' : ''}>
      <div className={styles.title}>{reverseStemAndHideTitle && stem ? stem : stimulus ? stem : title}</div>
      {!reverseStemAndHideTitle && stimulus ? (
        <span className={styles.stem}>{stimulus}</span>
      ) : !reverseStemAndHideTitle ? (
        <span className={styles.stem}>{stem}</span>
      ) : null}
      <InputComponent question={question} defaultPatientDetails={defaultPatientDetails} />
    </div>
  );
};

export default QuestionComponent;
