import styles from './StageOptionList.module.scss';
import cardStyles from '../../GroupConfigurationActionMenu.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { PathwayStageItem } from '../../../../PathwayManagement/interface';

interface StageOptionListProps {
  sequentialList: Groups['stagesConfiguration']['sequential'];
  anytimeList: Groups['stagesConfiguration']['anytime'];
  onSelectStage: (stageValue: PathwayStageItem) => void;
}

const StageOptionList = ({ sequentialList, anytimeList, onSelectStage }: StageOptionListProps) => {
  return (
    <div className={styles.container}>
      {sequentialList.length > 0 && (
        <div className={styles.stageWrapper}>
          {sequentialList.map((stage, index) => (
            <div key={index} className={cardStyles.itemCard} onClick={() => onSelectStage(stage)}>
              <div className={cardStyles.label}>
                {stage.sequence}. {stage.name}
              </div>
            </div>
          ))}
        </div>
      )}
      {anytimeList.length > 0 && (
        <div className={styles.stageWrapper}>
          <div className={styles.stageLabel}>Anytime stage</div>
          {anytimeList.map((stage, index) => (
            <div key={index} className={cardStyles.itemCard} onClick={() => onSelectStage(stage)}>
              <div className={cardStyles.label}>{stage.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StageOptionList;
