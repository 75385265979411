import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

import Button from 'components/v2/Button/Button';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import styles from './ClientAttendeesList.module.scss';

interface ClientAttendeesListProps {
  clientAttendees: clientRecordsInterface[];
  isLoading: boolean;
  onAddClientRecord: (clientRecord: clientRecordsInterface) => void;
  onClickShowContacts: (clientRecordId: string) => void;
}

const ClientAttendeesList = ({
  clientAttendees,
  isLoading,
  onAddClientRecord,
  onClickShowContacts
}: ClientAttendeesListProps) => {
  return isLoading ? (
    <div className={styles.loadingContainer}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.clientAttendeesContainer}>
      {clientAttendees.map((clientAttendee, index) => {
        const { _id, clientProfiles, recordType } = clientAttendee;

        const clientProfileList =
          recordType === 'child'
            ? clientProfiles.filter((profileType) => profileType.role === 'child')
            : clientProfiles;

        return (
          <div className={styles.client} key={index}>
            <div className={styles.clientName}>
              <ClientAvatar
                key={index}
                clientData={clientProfileList}
                avatarSize={30}
                initialsClassName={styles.initialName}
                nameClassName={styles.clientName}
                horizontal
                displayLimit={recordType === 'couple' ? 2 : 1}
                displayFirstNameOnly={recordType === 'couple'}
              />
              <Button
                variant="link"
                icon="add"
                className={styles.addButton}
                onClick={() => onAddClientRecord(clientAttendee)} //handleAddMultipleClientProfiles
              >
                Add
              </Button>
            </div>
            {clientProfiles.length > 1 && (
              <Button
                variant="link"
                iconPostFix
                icon="chevron_right"
                className={styles.contactButton}
                onClick={() => onClickShowContacts(_id)} //setShowContactsForClientRecord
              >
                Contacts
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ClientAttendeesList;
