import { useEffect, useState } from 'react';
import { notification } from 'antd';

import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { SendSurveyForm } from '../interfaces';

import { getFirstAssessments } from 'store/CheckIn/GetFirstAssessment/action';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';

import { CheckBoxListing } from 'components/AddPatientModalV2/AddPatientModalV2Interface';

const getSurveyList = async (token: string) => {
  const getSurveys = await getFirstAssessments(token);
  const surveyOptionList = getSurveys
    .filter((oBObj) => oBObj.isPublished)
    .map(({ id, name, isDefault }) => ({
      label: name,
      value: id,
      isDefault
    }));
  return surveyOptionList;
};

export const usePrefillProfileSurveyForm = (token: string, clientProfileList: clientProfilesInterface[]) => {
  const [sendSurveyForm, setSendSurveyForm] = useState({} as SendSurveyForm);
  const [prefillLoading, setPrefillLoading] = useState(true);
  const [surveyList, setSurveyList] = useState<CheckBoxListing[]>([]);
  const [profileOption, setProfileOption] = useState<CheckBoxListing[]>([]);

  const fetchPrefillProfilesData = async (clientProfileList: clientProfilesInterface[]) => {
    setPrefillLoading(true);

    try {
      const surveyOptionList = await getSurveyList(token);

      if (!surveyOptionList) {
        return;
      }

      setSurveyList(surveyOptionList);

      const defaultSurveyForm = surveyOptionList.find((survey) => survey.isDefault);
      const primaryProfile = clientProfileList.find((profile) => profile.isPrimaryContact);

      const newProfileList = clientProfileList.map(
        ({
          _id,
          name,
          avatar,
          initials,
          initialsBackgroundColor,
          role,
          firstName,
          lastName,
          communicationPreference,
          hasEmail,
          hasMobileNumber
        }) => {
          const isCommunicationsNotAllowed =
            role !== 'child' &&
            !(
              (canClientReceiveEmail(communicationPreference) && hasEmail) ||
              (canClientReceiveSms(communicationPreference) && hasMobileNumber)
            );

          return {
            profileId: _id,
            name,
            firstName,
            lastName,
            avatar,
            initials,
            initialsBackgroundColor,
            role,
            communicationPreference,
            hasEmail,
            hasMobileNumber,
            surveys: defaultSurveyForm ? [defaultSurveyForm] : [],
            isChecked: clientProfileList.length === 1 ? !isCommunicationsNotAllowed : false,
            isCommunicationsNotAllowed,
            isValid: true,
            receiver:
              role === 'child' && primaryProfile
                ? {
                    _id: primaryProfile._id,
                    name: primaryProfile.name,
                    isValid: true
                  }
                : undefined
          };
        }
      );

      const newSendSurveyForm = {
        profileList: newProfileList,
        inviteTime: { sendAt: 'now' },
        note: ''
      };

      setSendSurveyForm(newSendSurveyForm);

      setProfileOption(
        newProfileList
          .filter((profile) => profile.role !== 'child' && !profile.isCommunicationsNotAllowed)
          .map(({ profileId, firstName, lastName }, index) => ({
            label: `${firstName} ${lastName}`,
            value: profileId,
            isDefault: index === 0
          })) as CheckBoxListing[]
      );
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get your survey list.' });
    }

    setPrefillLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPrefillProfilesData(clientProfileList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientProfileList]);

  return { sendSurveyForm, setSendSurveyForm, prefillLoading, surveyList, profileOption, fetchPrefillProfilesData };
};
