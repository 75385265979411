import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { PracticeInfoInterface, ProfileInterface } from 'interfaces/Profile/Profile';
import { SALUTATION_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileAboutYou/ProfileAboutYou';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPracticeInfo } from '../../http/ClinicianProfileService/Accounts/accounts';
import { getClinicianProfile, getClinicianProfileById } from '../../http/ClinicianProfileService/Profile/profile';
import { useGetAccountId } from '../GetAccountInfo/getAccountId';

const defaultWorkingSchedule = {
  monday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  tuesday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  wednesday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  thursday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  friday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  saturday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  sunday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  }
};

const defaultCaseLoad = {
  caseLoad: {
    availableSlot: 0
  }
};

export const useFetchProfile = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [profile, setProfile] = useState<ProfileInterface>({} as ProfileInterface);
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });
      const response = await getClinicianProfile(token);
      const data = await response.json();

      const massageData = {
        ...data,
        consultantLocations: data.consultantLocations.length > 0 ? data.consultantLocations : [''],
        caseLoad: data.caseLoad ? data.caseLoad : defaultCaseLoad,
        workingSchedule: data.workingSchedule ? data.workingSchedule : defaultWorkingSchedule,
        title: data?.title
          ? SALUTATION_OPTIONS.find((obj) => obj.value === data.title)?.value !== undefined
            ? data.title
            : 'Other'
          : 'None',
        titleValue: data.title
      };

      setProfile(massageData);
      setIsProfileLoading(false);
    })();
  }, [getAccessTokenSilently]);

  return { profile, isProfileLoading, setProfile };
};

export const useFetchProfileById = (clinicianId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [t] = useTranslation();

  const [profile, setProfile] = useState<ProfileInterface>();
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const fetchProfile = async (clinicianId: string) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const response = await getClinicianProfileById(token, clinicianId);
      const data = await response.json();

      const massageData = {
        ...data,
        consultantLocations: data.consultantLocations.length > 0 ? data.consultantLocations : [''],
        caseLoad: data.caseLoad ? data.caseLoad : defaultCaseLoad,
        workingSchedule: data.workingSchedule ? data.workingSchedule : defaultWorkingSchedule,
        title: data?.title
          ? SALUTATION_OPTIONS.find((obj) => obj.value === data.title)?.value !== undefined
            ? data.title
            : 'Other'
          : 'None',
        titleValue: data.title
      };

      setProfile(massageData);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: t('form.error.fetch_practitioner_profile') });
    }

    setIsProfileLoading(false);
  };

  useEffect(() => {
    if (clinicianId) {
      fetchProfile(clinicianId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicianId]);

  return { profile, isProfileLoading, setProfile };
};

export const useFetchPracticeInfo = () => {
  const { accountId } = useGetAccountId();
  const { getAccessTokenSilently } = useAuth0();

  const [practiceData, setPracticeData] = useState<PracticeInfoInterface>({ practice: {} });
  const [isPracticeDataLoading, setIsPracticeDataLoading] = useState(true);

  const fetchPracticeInfo = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    const response = await getPracticeInfo(token, accountId);
    const data = await response.json();

    const massageData = {
      practice: {
        ...data,
        logo: data.logo ?? '',
        name: data.name ?? '',
        mobileNumber: data.mobileNumber ?? '',
        locations: data.locations ?? ['']
      }
    };

    setPracticeData(massageData);

    setIsPracticeDataLoading(false);
  };

  useEffect(() => {
    fetchPracticeInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { practiceData, isPracticeDataLoading };
};
