import cardStyles from './StageCard.module.scss';
import styles from './StageCardLoading.module.scss';
import { Skeleton } from 'antd';

const StageCardLoading = () => {
  return (
    <div className={cardStyles.container}>
      <div className={cardStyles.header}>
        <Skeleton.Input active className={styles.headerLoading} />
      </div>
      <div className={cardStyles.desc}>
        <Skeleton.Input active className={styles.descLoading} />
      </div>
    </div>
  );
};

export default StageCardLoading;
