import { notification } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  getGeneralPractitionerById,
  getGeneralPractitionerList
} from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { GeneralPractitionerInterface } from '../../../interface';

export const useFetchGeneralPractitionerList = (token: string, refreshGPList: number) => {
  const { accountId } = useGetAccountId();
  const [gpList, setGPList] = useState<GeneralPractitionerInterface[]>([]);
  const [isGPListLoading, setIsGPListLoading] = useState(true);
  const [t] = useTranslation();

  const fetchGeneralPractitionerList = useCallback(
    async (token: string) => {
      setIsGPListLoading(true);

      try {
        const callGetGPList = await getGeneralPractitionerList(token, accountId);
        const gpList = await callGetGPList.json();
        setGPList(gpList);
      } catch (ex) {
        notification.error({ message: t('form.error.failed_to_retrieve_general_practitioners') });
      } finally {
        setIsGPListLoading(false);
      }
    },
    [accountId, t]
  );

  useEffect(() => {
    if (token) {
      fetchGeneralPractitionerList(token);
    }
  }, [token, refreshGPList, fetchGeneralPractitionerList]);

  return { gpList, isGPListLoading };
};

export const useFetchGeneralPractitionerById = (token: string, generalPractitionerId: string) => {
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();

  const [generalPractitioner, setGeneralPractitioner] = useState<GeneralPractitionerInterface>(
    {} as GeneralPractitionerInterface
  );
  const [isGeneralPractitionerLoading, setIsGeneralPractitionerLoading] = useState(true);

  const fetchGeneralPractitionerById = useCallback(
    async (generalPractitionerId: string) => {
      setIsGeneralPractitionerLoading(true);

      try {
        const callGetGPById = await getGeneralPractitionerById(token, accountId, generalPractitionerId);
        const gpData = await callGetGPById.json();
        setGeneralPractitioner(gpData);
      } catch (ex) {
        notification.error({ message: t('form.error.failed_to_retrieve_general_practitioners') });
      } finally {
        setIsGeneralPractitionerLoading(false);
      }
    },
    [token, accountId, t]
  );

  useEffect(() => {
    if (token && generalPractitionerId.length > 0) {
      fetchGeneralPractitionerById(generalPractitionerId);
    }
  }, [token, fetchGeneralPractitionerById, generalPractitionerId]);

  return { generalPractitioner, isGeneralPractitionerLoading, fetchGeneralPractitionerById };
};
