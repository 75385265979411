import styles from './TimeRange.module.scss';

import moment from 'moment';
import { TimeSlotInterface, TIME_FORMAT } from '../../constants';

interface TimeRangeProps {
  availableSlots: TimeSlotInterface[];
  listTransformX: number;
}

const TimeRange = (props: TimeRangeProps) => {
  const { availableSlots, listTransformX } = props;

  const renderRangeItem = (startTime: string, endTime: string, index: number) => {
    const duration = moment(endTime, TIME_FORMAT).diff(moment(startTime, TIME_FORMAT), 'minutes');
    const left = moment(startTime, TIME_FORMAT).diff(moment('05:00', TIME_FORMAT), 'minutes');
    /**
     * Only show slot from 15 munites
     * -20px: 1hour = 40px => middle of hour
     * x(2/3): 60 munites ~ 40px
     */
    return (
      duration >= 15 && (
        <div
          key={`range-${index}`}
          style={{ marginLeft: left * (2 / 3) + 20, width: duration * (2 / 3) }}
          className={styles.range}
          onClick={() => {}}
        />
      )
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.timeRangeList} style={{ transform: `translate3d(${listTransformX}px, 0, 0)` }}>
        {availableSlots.map((slot: any, index: number) => {
          return renderRangeItem(slot.startTime, slot.endTime, index);
        })}
      </div>
    </div>
  );
};

export default TimeRange;
