import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getAdhocAssessmentCountsByClinicianId = (token: string, accountId: string, clinicianId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}/ad-hoc-assessments:getCounts`
  ) as Promise<BentResponse>;

// for admin/receptionist only
export const getAdhocAssessmentsByClinicianIdWithMinifiedClientProfiles = (
  token: string,
  accountId: string,
  clinicianId: string,
  filter?: {
    perPage?: number;
    page?: number;
    status?: 'done' | 'pending';
  }
) => {
  let query = '';
  if (filter) {
    const { status, perPage, page } = filter;
    query = new URLSearchParams({
      ...(status && { status }),
      ...(perPage && { perPage: perPage.toString() }),
      ...(page && { page: page.toString() })
    }).toString();
  }

  return httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}/ad-hoc-assessments:getAssessmentsWithMinifiedClientProfiles${
      query ? `?${query}` : ''
    }`
  ) as Promise<BentResponse>;
};

export const getAssessorAssessments = (
  token: string,
  args: {
    perPage: number;
    page: number;
    status?: 'pending' | 'done';
    name?: string;
  }
) => {
  const query = new URLSearchParams({
    ...(args.perPage && { perPage: args.perPage.toString() }),
    ...(args.page && { page: args.page.toString() }),
    ...(args.status && { status: args.status }),
    ...(args.name && { name: args.name })
  }).toString();

  return httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/minified-assessor-assessments${query ? `?${query}` : ''}`
  ) as Promise<BentResponse>;
};

export const getOutstandingAssessments = (token: string, accountId: string, args?: { clientRecordIds?: string[] }) => {
  const query =
    args &&
    new URLSearchParams({
      ...(args.clientRecordIds && { clientRecordIds: args.clientRecordIds.join(',') })
    }).toString();

  return httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/assessments:getAllOutstandingAssessments${query ? `?${query}` : ''}`
  ) as Promise<BentResponse>;
};

export const postCancelAssessments = (token: string, accountId: string, payload: Record<string, unknown>) =>
  httpClient('POST', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/assessments:cancelAssessments`,
    payload
  ) as Promise<BentResponse>;

export const postSendAssessmentReminders = (token: string, accountId: string, payload: Record<string, unknown>) =>
  httpClient('POST', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/assessments:sendReminders`,
    payload
  ) as Promise<BentResponse>;
