import { useState } from 'react';

import { AppointmentSlots } from 'interfaces/Schedule/Appointment';

import styles from './FreeBusyEvent.module.scss';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { Link } from 'react-router-dom';
import { updateAppointmentFreeBusyToggle } from 'utils/http/appointment';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';

interface FreeBusyEventProps {
  appointment: AppointmentSlots;
  onToggleFreeBusy: () => void;
}

const FreeBusyEvent = ({ appointment, onToggleFreeBusy }: FreeBusyEventProps) => {
  const [freeBusy, setFreeBusy] = useState(appointment.type === 'free');
  const [isFreeBusyTogglePending, setIsFreeBusyTogglePending] = useState(false);
  const { token } = useGetAccessToken();

  const handleOnFreeBusyToggle = async () => {
    setIsFreeBusyTogglePending(true);
    try {
      setFreeBusy(!freeBusy);
      await updateAppointmentFreeBusyToggle(token, appointment._id);
      setIsFreeBusyTogglePending(false);
      notification.success({
        message: `Successfully toggled free-busy status.`
      });
      onToggleFreeBusy();
    } catch {
      setIsFreeBusyTogglePending(false);

      notification.error({
        message: `Something went wrong while trying to toggle free-busy.`
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{appointment.title}</div>
      <div className={styles.toggleContainer}>
        <div className={styles.label}>YOUR SCHEDULE MARKED AS</div>
        <div className={styles.toggle}>
          <ToggleSwitch
            id={appointment._id}
            checkLabel={'FREE'}
            unCheckLabel={'BUSY'}
            isCheckedProps={freeBusy}
            onChangeProps={handleOnFreeBusyToggle}
            disabled={isFreeBusyTogglePending}
          />
        </div>
      </div>
      <div className={styles.profileNameContainer}>
        <div className={styles.label}>EVENT FROM</div>
        <div className={styles.profileName}>{appointment.integration?.profileName}</div>
      </div>
      <div className={styles.footerContainer}>
        <Link to={'calendar/settings/calendarLinking'} className={styles.link}>
          Calendar connection settings <i className={`material-icons`}>arrow_forward_ios</i>
        </Link>
        <div className={styles.freeBusyDisclaimer}>
          To make changes, or delete this event, you will need to update your settings or update the event in the
          original calendar
        </div>
      </div>
    </div>
  );
};

export default FreeBusyEvent;
