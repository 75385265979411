import commentCursor from 'assets/images/comment-cursor.png';
import moment from 'moment';
import CommentWidget from 'pages/Report/components/WidgetComponents/CommentWidget/CommentWidget';
import { MouseEvent, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useGetAccessToken } from 'utils/hooks/token';
import { addNewComment, addNewReply, moveComment } from 'utils/http/DocumentService/Reports/reports';
import { v4 as uuid } from 'uuid';

import { CommentWidgetInterface } from '../../../../../../interface';
import { CommentWrapper } from './components/CommentWrapper/CommentWrapper';
import styles from './DropCommentSection.module.scss';

export interface DropCommentSectionProps {
  reportId: string;
  onChangeComments: (items: any[]) => void;
  dndDimension: any;
  items: CommentWidgetInterface[];
  clinicianDetails: any;
  allowedAddComment: boolean;
}

const DropCommentSection = ({
  reportId,
  dndDimension,
  onChangeComments,
  items: commentList,
  clinicianDetails,
  allowedAddComment
}: DropCommentSectionProps) => {
  const { token } = useGetAccessToken();
  const getRef = useRef<HTMLDivElement>(null);

  const [, dropRef] = useDrop({
    accept: 'commentsWidget',
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: (item: any, monitor: any) => {
      const initialOffset = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.position.left + initialOffset.x);
      const top = Math.round(item.position.top + initialOffset.y);
      moveBox(item.id, left, top);
      return undefined;
    }
  });

  const moveBox = (id: string, left: number, top: number) => {
    const newPostition = {
      left,
      top
    };
    const newMoveWidgetList = commentList.map((comment) => {
      if (comment._id === id) {
        return {
          ...comment,
          position: newPostition,
          rightAligned: left > 840 - 350
        };
      }
      return comment;
    });

    onChangeComments(newMoveWidgetList);
    moveComment(token, reportId, id, {
      position: newPostition
    });
  };

  const handleFindPosition = (event: MouseEvent) => {
    if (allowedAddComment) {
      event.preventDefault();
      event.stopPropagation();

      const offsetLocation = {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY
      };

      addNewData(offsetLocation);
    }
  };

  const addNewData = (dropTargetXy: any) => {
    const newExpandComment = commentList
      .filter((commentObj) => commentObj.from !== 'newWidget' && commentObj.comments.length > 0)
      .map((commentObj) => ({
        ...commentObj,
        from: 'widget'
      }));

    const newGroup = [
      ...newExpandComment,
      {
        position: {
          top: dropTargetXy.y - 20,
          left: dropTargetXy.x - 20
        },
        from: 'newWidget',
        _id: uuid(),
        rightAligned: dropTargetXy.x - 20 > 840 - 350,
        comments: []
      }
    ];
    onChangeComments(newGroup);
  };

  const handleExpandItem = (commentId: string, focusBool: boolean) => {
    const massageCommentList = commentList
      .filter(({ from, comments }) => from !== 'newWidget' || (from === 'newWidget' && comments.length > 0))
      .map((commentObj) => ({
        ...commentObj,
        expand: commentObj._id === commentId ? focusBool : commentObj.expand,
        from: commentObj._id === commentId ? 'widget' : commentObj.from
      }));

    onChangeComments(massageCommentList);
  };

  const handleFocusOutside = () => {
    const filteredCommentList = commentList.filter(
      (comment) => comment.from !== 'newWidget' || (comment.from === 'newWidget' && comment.comments.length > 0)
    );
    onChangeComments(filteredCommentList);
  };

  const onAddComment = async (id: string, value: string) => {
    const foundComment = commentList.find((comment) => comment._id === id);
    const newCommentItem = {
      content: value,
      commentedBy: {
        name: clinicianDetails.clinician.name,
        avatar: clinicianDetails.clinician.avatar
      },
      commentedAt: moment().toISOString()
    };

    if (foundComment) {
      if (foundComment.comments.length > 0) {
        const newReply = await (
          await addNewReply(token, reportId, id, {
            content: value
          })
        ).json();
        if (newReply.commentedBy) {
          newCommentItem.commentedBy = newReply.commentedBy;
        }
      } else {
        const newComment = await (
          await addNewComment(token, reportId, {
            _id: id,
            content: value,
            position: foundComment.position
          })
        ).json();
        if (newComment.comments[0].commentedBy) {
          newCommentItem.commentedBy = newComment.comments[0].commentedBy;
        }
      }
    }

    const newCommentList = commentList.map((comment) => {
      if (comment._id === id) {
        const newComment = { ...comment, from: 'widget' };
        newComment.comments.push(newCommentItem);

        return newComment;
      }

      return comment;
    });

    onChangeComments(newCommentList);
  };

  return (
    <div
      ref={getRef}
      id={'dndContentSection'}
      style={{
        height: dndDimension.height,
        width: dndDimension.width,
        position: 'absolute'
      }}
    >
      <div
        ref={dropRef}
        className={styles.container}
        style={{
          height: dndDimension.height,
          width: dndDimension.width,
          cursor: allowedAddComment ? `url('${commentCursor}') 0 20, pointer` : 'auto'
        }}
        onClick={(e) => handleFindPosition(e)}
      >
        {commentList.map((itemObj) => (
          <CommentWrapper
            key={itemObj._id}
            id={itemObj._id}
            position={{
              top: itemObj.position.top,
              left: itemObj.position.left
            }}
            dimensions={{
              height: 'auto',
              width: 'max-content',
              minWidth: 40,
              maxWidth: 200
            }}
            allowDrag={allowedAddComment}
            data={itemObj}
          >
            <div className={styles.widgetWrapper} id={itemObj._id}>
              <CommentWidget
                clinician={clinicianDetails.clinician}
                commentItem={itemObj}
                replyAllowed={allowedAddComment}
                onAddComment={onAddComment}
                handleFocusOutside={handleFocusOutside}
                handleExpandItem={handleExpandItem}
              />
            </div>
          </CommentWrapper>
        ))}
      </div>
    </div>
  );
};

export default DropCommentSection;
