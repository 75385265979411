export const COMMUNICATION_OPTIONS = [
  { label: 'No Preference', value: 'noPreference' },
  { label: 'Only send SMS, no E-mail', value: 'sms' },
  { label: 'Only send E-mail, no SMS', value: 'email' },
  { label: 'Don’t send either SMS or E-mail', value: 'none' }
];

export const REFERRAL_SOURCE_TAGS = [
  'GP',
  'Website',
  'Employer',
  'Inbound call',
  'Social media',
  'Community',
  'Client',
  'Psychiatrist/Psychologist',
  'Insurance company'
];
