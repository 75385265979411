import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getAdhocAssessmentCountsByClinicianId } from 'utils/http/CheckInService/Assessment/adhocAssessment';

export interface ClinicalAssessmentResponseCounts {
  total: number;
  done: number;
  pending: number;
}

interface Props {
  accountId: string;
  clinicianId: string;
  token: string;
}

const useGetAdhocAssessmentCountsByClinicianId = ({ accountId, clinicianId, token }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [assessmentCounts, setAssessmentCounts] = useState<ClinicalAssessmentResponseCounts>({
    total: 0,
    done: 0,
    pending: 0
  });

  const fetchCounts = async (token: string) => {
    setIsLoading(true);
    try {
      const response = await getAdhocAssessmentCountsByClinicianId(token, accountId, clinicianId);
      const data = (await response.json()) as ClinicalAssessmentResponseCounts;
      setAssessmentCounts(data);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error occured when trying to fetch clinician's clinical assessment counts"
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCounts(token);
    }
    // eslint-disable-next-line
  }, [token, accountId, clinicianId]);

  return {
    isLoading,
    assessmentCounts,
    refetch: fetchCounts
  };
};

export default useGetAdhocAssessmentCountsByClinicianId;
