import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import Button from 'components/v2/Button/Button';
import { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchReportTemplate } from 'utils/hooks/reports';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  ReportTemplate,
  ReportTemplateStatus,
  ReportTemplateValidationInterface
} from 'interfaces/Reports/reportTemplate';
import TemplateContent from './components/TemplateContent/TemplateContent';
import TemplateSideBar from './components/TemplateSideBar/TemplateSideBar';
import styles from './ReportTemplateEditor.module.scss';
import { defaultReportTemplateValidation } from './constants';
import moment from 'moment';
import { timeDiff } from 'utils/timeDifferent';
import { useTime } from '../ReportDetails/components/ReportBuilder/ReportBuilder';
import { debounce } from 'lodash';
import TemplatePreview from './components/TemplatePreview/TemplatePreview';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { postReportTemplate, putReportTemplate } from 'utils/http/DocumentService/Reports/reportsTemplate';
import { notification } from 'antd';
import { ReportDimension } from 'interfaces/Reports/report';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const ReportTemplateEditor = () => {
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { templateId = '' } = useParams<{ templateId: string }>();
  const isNewTemplateId = templateId === 'newTemplate';
  const { templateData, isTemplateDataLoading } = useFetchReportTemplate(token, templateId);
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const [savingStatus, setSavingStatus] = useState<'' | 'saving' | 'saved'>('');
  const [saveBtnStatus, setSaveBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEditTime, setShowEditTime] = useState(false);
  const [reportTemplateData, setReportTemplateData] = useState<ReportTemplate>(templateData);
  const [reportTemplateValidation, setReportTemplateValidation] = useState<ReportTemplateValidationInterface>(
    defaultReportTemplateValidation
  );

  const now = useTime(savingStatus === 'saving' || isTemplateDataLoading ? 1000 : 10000, reportTemplateData.updatedAt);
  const currentTime = moment();
  const lastUpdateTime = moment(reportTemplateData.updatedAt);
  const formattedDuration = reportTemplateData.isEditing
    ? timeDiff(currentTime, now)
    : timeDiff(currentTime, lastUpdateTime);

  useEffect(() => {
    setShowEditTime(!isTemplateDataLoading);
  }, [isTemplateDataLoading]);

  useEffect(() => {
    if (templateData) {
      setReportTemplateData(templateData);
    }
  }, [templateData]);
  const reportPrevPath = localStorage.getItem('reportPrevPath');

  const onClickExit = () => {
    navigate(`${REPORTS.BASE}${reportPrevPath ? `/${reportPrevPath}?widgetMenu=reportTemplateView` : ''}`);
  };

  const debouncedSave = useMemo(
    () =>
      debounce(
        async (templateDraftData: ReportTemplate) => {
          if (templateDraftData.title.length > 0 && templateDraftData.items.length > 0) {
            await handleSaveReportTemplate(templateDraftData);
          }
        },
        isNewTemplateId ? 100 : 3000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNewTemplateId, token]
  );

  const saveTemplateData = (newData: ReportTemplate) => {
    const newTemplateData: ReportTemplate = {
      ...reportTemplateData,
      ...newData,
      isEditing: true,
      updatedAt: new Date()
    };
    setReportTemplateData(newTemplateData);
    if (!isSubmitting) {
      setSavingStatus(Object.keys(newTemplateData).length !== 0 ? 'saving' : '');
      debouncedSave(newTemplateData);
    }
  };

  const onChangeTemplateTitle = (title: string) => {
    saveTemplateData({ ...reportTemplateData, title });
    setReportTemplateValidation({
      ...reportTemplateValidation,
      title: title.length <= 0
    });
  };

  const onChangeTemplateStatus = (status: ReportTemplate['status']) => {
    saveTemplateData({ ...reportTemplateData, status });
  };

  const onChangeTemplateDimensions = (dimensions: ReportDimension) => {
    if (reportTemplateData.isEditing) {
      saveTemplateData({ ...reportTemplateData, template: { dimensions } });
    }
  };

  const onChangeTemplateItems = (items: ReportTemplate['items']) => {
    saveTemplateData({
      ...reportTemplateData,
      items
    });
    setReportTemplateValidation({
      ...reportTemplateValidation,
      items: items.length <= 0
    });
  };

  const handleSaveReportTemplate = async (newTemplateData: ReportTemplate, withBtnStatus?: boolean) => {
    setSubmitting(true);
    if (withBtnStatus) {
      setSaveBtnStatus('active');
    }
    if (isNewTemplateId) {
      try {
        const { _id, ...newTemplateDataObj } = newTemplateData;
        const massagePOSTPayload = {
          ...newTemplateDataObj,
          asAdmin: isEdgeAdminView || isEdgeReceptionistView
        };
        const createdNewReportTemplateId = await (await postReportTemplate(token, massagePOSTPayload)).text();
        navigate(`${REPORTS.TEMPLATE_EDITOR}/${createdNewReportTemplateId}`);
        if (withBtnStatus) {
          setSaveBtnStatus('finished');
          notification.success({
            message: 'Report Template has been saved.',
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setTimeout(() => {
            setSaveBtnStatus('');
          }, 1000);
        }
      } catch (ex) {
        setSaveBtnStatus('');
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to create report template' });
      }
    } else {
      try {
        const massageUpdatePayload = {
          ...newTemplateData,
          _id: templateId
        };
        await putReportTemplate(token, templateId, massageUpdatePayload);
        if (withBtnStatus) {
          setSaveBtnStatus('finished');
          notification.success({
            message: 'Report Template has been updated.',
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setTimeout(() => {
            setSaveBtnStatus('');
          }, 1000);
        }
      } catch (ex) {
        setSaveBtnStatus('');
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to update the report template' });
      }
    }

    setSavingStatus(Object.keys(newTemplateData).length !== 0 ? 'saved' : '');
    setSubmitting(false);
  };

  const onClickSaveTemplate = async () => {
    setReportTemplateValidation({
      title: reportTemplateData.title.length <= 0,
      items: reportTemplateData.items.length <= 0
    });
    if (reportTemplateData.title.length > 0 && reportTemplateData.items.length > 0) {
      setSavingStatus(Object.keys(reportTemplateData).length !== 0 ? 'saving' : '');
      await handleSaveReportTemplate(reportTemplateData, true);
    }
  };

  return (
    <HelmetWrapper title={'CORDS - Report Template Editor'}>
      <ContentLayout>
        <div id={'reportTemplateEditor'} />
        <DndProvider backend={HTML5Backend}>
          <div className={styles.container}>
            <div className={styles.leftSection}>
              <div className={styles.titleSection}>
                <div className={styles.title}>Template editor</div>
                <div className={styles.backButton} onClick={onClickExit}>
                  <i className={`material-icons-outlined`}>arrow_back_ios</i>
                  <div className={styles.backButtonLabel}>
                    {reportPrevPath ? 'Back to report' : 'Back to reports & letters'}
                  </div>
                </div>
              </div>
              <TemplateSideBar
                title={reportTemplateData.title}
                titleValidation={reportTemplateValidation.title}
                status={reportTemplateData.status}
                templateContent={reportTemplateData.items}
                onChangeTemplateTitle={onChangeTemplateTitle}
                onChangeTemplateStatus={(e) => onChangeTemplateStatus(e as ReportTemplateStatus)}
              />
            </div>
            <div className={styles.rightSection}>
              <div className={styles.templateHeader}>
                <div className={styles.rightHeadingWrapper}>
                  {!isNewTemplateId && (
                    <div className={!showEditTime ? styles.lastEditMessageHide : styles.lastEditMessage}>
                      Last edit was {formattedDuration}
                    </div>
                  )}
                  <Button
                    className={styles.draftBtn}
                    variant={'secondary'}
                    onClick={onClickSaveTemplate}
                    activeClassName={styles.draftActive}
                    status={saveBtnStatus}
                    disabled={
                      reportTemplateData.title.length <= 0 ||
                      reportTemplateData.items.length <= 0 ||
                      savingStatus === 'saving'
                    }
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant={'primary'}
                    className={styles.previewBtn}
                    disabled={reportTemplateData.items.length <= 0}
                    onClick={() => setShowPreviewModal(true)}
                  >
                    View Template Preview
                  </Button>
                </div>
              </div>
              <TemplateContent
                templateData={reportTemplateData}
                isLoading={isTemplateDataLoading}
                validation={reportTemplateValidation.items}
                onChangeTemplateDimensions={onChangeTemplateDimensions}
                onChangeTemplateItems={onChangeTemplateItems}
              />
            </div>
          </div>
          <TemplatePreview
            showPreviewModal={showPreviewModal}
            setShowPreviewModal={setShowPreviewModal}
            templateData={reportTemplateData}
          />
        </DndProvider>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportTemplateEditor;
