import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useField } from 'formik';
import styles from './AppointmentTitle.module.scss';

const AppointmentTitle = () => {
  const [{ value }, meta, { setValue }] = useField('title');
  return (
    <div className={classNames(styles.fieldContainer)}>
      <MaterialInput
        id={`bespokeTitle`}
        label={'Event Title*'}
        onBlur={(e) => setValue(e.target.value)}
        defaultValue={value}
        required
      />
      {meta.touched && <div className={styles.fieldError}>{meta.error}</div>}
    </div>
  );
};

export default AppointmentTitle;
