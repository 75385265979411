import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useParams } from 'react-router-dom';

import { useFetchProfile } from '../../../utils/hooks/GetProfileDetails/getProfileInfo';
import ControlPanelContent from './components/ControlPanelContent/ControlPanelContent';
import ControlPanelHeader from './components/ControlPanelHeader/ControlPanelHeader';
import styles from './ControlPanel.module.scss';

const ControlPanel = () => {
  const { controlPanelTab } = useParams<{ controlPanelTab?: string }>();
  const { profile, isProfileLoading, setProfile } = useFetchProfile();

  return (
    <HelmetWrapper title="CORDS - Control Panel">
      <ContentLayout className={styles.container}>
        <div className={styles.container}>
          <ControlPanelHeader />
          <ControlPanelContent
            profile={profile}
            controlPanelTab={controlPanelTab}
            isLoading={isProfileLoading}
            onUpdateProfile={setProfile}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ControlPanel;
