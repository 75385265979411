import styles from './ClientProfileWidget.module.scss';

interface ClientProfileWidgetProps {
  data: any;
  id?: any;
  previewMode?: boolean;
}

const ClientProfileWidget = ({ data, id, previewMode }: ClientProfileWidgetProps) => (
  <div className={previewMode ? styles.previewContainer : styles.container} id={id}>
    <div className={styles.title}>{data.title}</div>
  </div>
);

export default ClientProfileWidget;
