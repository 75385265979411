import { CSSProperties, ReactElement } from 'react';
import { Modal } from 'antd';

import styles from './ModalV2.module.scss';
import classNames from 'classnames';
import ModalHeader from './components/ModalHeader/ModalHeader';

interface ModalV2Props {
  isModalOpen: boolean;
  title?: string | ReactElement;
  totalStep?: number;
  currentStep?: number;
  isCompleted?: boolean;
  children?: any;
  closable?: boolean;
  containerClassName?: string;
  onModalClose: () => void;
  bodyStyle?: CSSProperties;
  hideClose?: boolean;
  mask?: boolean;
  onClickBackStepBtn?: () => void;
}

const ModalV2 = ({
  isModalOpen,
  title,
  totalStep,
  currentStep,
  isCompleted,
  children,
  closable = false,
  containerClassName,
  onModalClose,
  bodyStyle,
  hideClose,
  mask,
  onClickBackStepBtn
}: ModalV2Props) => {
  return (
    <Modal
      bodyStyle={bodyStyle}
      mask={mask}
      className={classNames(styles.container, containerClassName)}
      title={
        title ? (
          <ModalHeader
            title={title}
            totalStep={totalStep}
            currentStep={currentStep}
            isCompleted={isCompleted}
            onCancel={onModalClose}
            hideClose={hideClose}
            onClickBackStepBtn={onClickBackStepBtn}
          />
        ) : null
      }
      visible={isModalOpen}
      footer={null}
      closable={closable}
    >
      {children}
    </Modal>
  );
};

export default ModalV2;
