import { useEffect, useState } from 'react';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './SessionDetails.module.scss';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button from 'components/v2/Button/Button';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import AttendanceLogList from './components/AttendanceLogList/AttendanceLogList';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification, Skeleton } from 'antd';
import SessionInfoBox from './components/SessionInfoBox/SessionInfoBox';
import SessionInstruction from './components/SessionInstruction/SessionInstruction';
import { useFetchGroupSessionDetails } from './hooks/getGroupSessionDetails';
import { GroupSessionDetailsPath } from 'pages/Groups/Interfaces/GroupsSession';
import { useFetchGroupDetails } from 'pages/Groups/GroupDetails/hooks/getGroupDetails';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import moment from 'moment';
import { putGroupSessionDetails } from 'utils/http/ScheduleService/Groups/groups';

const SessionDetails = () => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const path = useParams<{ [key in keyof GroupSessionDetailsPath]: string }>();
  const groupId = path.groupId ?? '';
  const sessionId = path.sessionId ?? '';
  const { accountId } = useGetAccountId();
  const { GROUPS } = useRoutesGenerator();
  const location = useLocation();
  const queryParam: { fromList?: string } = queryString.parse(location.search);
  const groupSessionListPrevPath = localStorage.getItem('groupSessionListPrevPath');
  const [slSubmitStatus, setSlSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const { groupSessionDetails, isGroupSessionDetailsLoading, fetchGroupDetails } = useFetchGroupSessionDetails(
    token,
    groupId,
    sessionId
  );
  const { groupDetails, isGroupDetailsLoading } = useFetchGroupDetails(token, groupId, accountId);

  const [sessionLabel, setSessionLabel] = useState<string>('');
  const [savedSessionLabel, setSavedSessionLabel] = useState<string>('');

  useEffect(() => {
    setSessionLabel(groupSessionDetails?.groupDetails.label || '');
    setSavedSessionLabel(groupSessionDetails?.groupDetails.label || '');
  }, [groupSessionDetails]);

  useEffect(() => {
    if (!queryParam.fromList && groupSessionListPrevPath) {
      localStorage.setItem('groupSessionListPrevPath', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackBtn = () => {
    navigate(`${GROUPS.BASE}/${groupId}/sessions${groupSessionListPrevPath ? `/${groupSessionListPrevPath}` : ''}`);
  };

  const handleSaveLabel = async () => {
    setSlSubmitStatus('active');
    try {
      const payload = {
        label: sessionLabel
      };
      await putGroupSessionDetails(token, groupId, sessionId, payload);
      setSavedSessionLabel(sessionLabel);
      setSlSubmitStatus('finished');
      notification.success({
        message: 'Session label saved',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setSlSubmitStatus('');
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSlSubmitStatus('');
      notification.error({
        message: 'Session label fail to save',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleEditAppointmentComplete = () => {
    fetchGroupDetails(token);
  };

  return (
    <HelmetWrapper title={'CORDS - Group Session Details'}>
      <div className={styles.containerWrapper}>
        <ContentLayout>
          {isGroupSessionDetailsLoading || isGroupDetailsLoading || !groupSessionDetails || !groupDetails ? (
            <div className={styles.container}>
              <Skeleton.Input active className={styles.loadingTitle} />
              <Skeleton.Input active className={styles.loadingHeaderDesc} />
              <Skeleton active />
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.title}>{groupDetails.name}</div>
              <div className={styles.headerDetails}>
                <div className={styles.date}>
                  {moment(groupSessionDetails.date, 'YYYY-MM-DD').format('dddd, Do MMMM')}
                </div>
                <div className={styles.labelField}>
                  <MaterialInput
                    label={'Label'}
                    value={sessionLabel}
                    onChange={(e) => setSessionLabel(e.target.value)}
                    className={styles.labelInput}
                    required
                  />
                  {sessionLabel !== savedSessionLabel && (
                    <Button className={styles.labelSaveBtn} status={slSubmitStatus} onClick={handleSaveLabel}>
                      Save Label
                    </Button>
                  )}
                </div>
              </div>
              <div className={styles.backBtnWrapper}>
                <Button variant={'link'} className={styles.backBtn} onClick={() => handleBackBtn()}>
                  <i className={`material-icons ${styles.backIcon}`}>arrow_back_ios</i>
                  Back to all sessions
                </Button>
              </div>
              <div className={styles.sessionBoxWrapper}>
                <SessionInfoBox
                  sessionLabel={savedSessionLabel}
                  groupDetails={groupDetails}
                  groupSessionDetails={groupSessionDetails}
                  groupId={groupId}
                  sessionId={sessionId}
                  onEditAppointmentComplete={handleEditAppointmentComplete}
                />
                <SessionInstruction
                  instructions={groupSessionDetails.groupDetails.instructions || ''}
                  groupId={groupId}
                  sessionId={sessionId}
                />
              </div>
              <AttendanceLogList
                groupId={groupId}
                sessionId={sessionId}
                sessionClientRecord={groupSessionDetails.groupDetails.attendees}
                isAttendanceListLoading={isGroupSessionDetailsLoading}
              />
            </div>
          )}
        </ContentLayout>
      </div>
    </HelmetWrapper>
  );
};

export default SessionDetails;
