import { MouseEvent, useEffect, useState } from 'react';
import styles from './GroupCaseNote.module.scss';
import { GroupDetailsTab, Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import { useFetchCaseNoteHeadingTemplates } from './hooks/getCaseNoteHeadingTemplates';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import CaseNoteListing from './components/CaseNoteListing/CaseNoteListing';
import {
  useFetchCaseNote,
  useFetchCaseNoteFormatSettingsByGroupId,
  useFetchGroupCaseNoteList
} from './hooks/getCaseNoteInfo';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import NoCaseNoteDetail from './components/NoCaseNoteDetail/NoCaseNoteDetail';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import Button from 'components/Button/Button';
import { Modal, notification } from 'antd';
import moment from 'moment';
import { useGenerateHeadingTemplateDetails } from './hooks/headingTemplate';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fields } from './defaultGroupFormatSettingsFields.json';
import GroupCaseNoteDetail from './components/GroupCaseNoteDetail/GroupCaseNoteDetail';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { HeadingTemplate } from './components/GroupCaseNoteHeadingTemplateList/GroupCaseNoteHeadingTemplateList';
import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import GroupCaseNoteFormatSettingsModal from './components/GroupCaseNoteFormatSettingsModal/GroupCaseNoteFormatSettingsModal';
import { CaseNoteFormatSettings, GroupCaseNoteInterface } from './interfaces';
import { useTranslation } from 'react-i18next';
import { useFetchCaseNoteFormatSettingsList } from './hooks/getCaseNoteFormatSettings';
import { safeGuardingName } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteFormatSettingsModal/PatientDetailsNoteFormatSettingsModal';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface GroupCaseNoteProps {
  groupDetails: Groups;
  caseNoteId?: string;
  activeGroupTab: GroupDetailsTab;
}

const GroupCaseNote = ({ groupDetails, caseNoteId, activeGroupTab }: GroupCaseNoteProps) => {
  const navigate = useNavigate();
  const path = useParams() as { contentId: string };
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isHelmFeatureToggle } = useGetFeatureToggle();
  const { GROUPS } = useRoutesGenerator();
  const { isNormalUserView, isEdgeAdminUser } = useGetAccountPackageView();

  const { caseNoteHeadingTemplates, areCaseNoteHeadingTemplatesLoading, setCaseNoteHeadingTemplates } =
    useFetchCaseNoteHeadingTemplates(token);
  const { caseNoteList, isCaseNoteListLoading, setGroupCaseNotes } = useFetchGroupCaseNoteList(token, groupDetails._id);
  const { caseNote, isCaseNoteLoading, fetchCaseNote } = useFetchCaseNote(token, groupDetails._id, caseNoteId);
  const { caseNoteFormatSettingByGroupId, isCaseNoteFormatSettingByGroupIdLoading, fetchCaseNoteFormatSettings } =
    useFetchCaseNoteFormatSettingsByGroupId(token, groupDetails._id);
  const { generateHeadingTemplate } = useGenerateHeadingTemplateDetails(token, groupDetails._id);

  const [addNoteButtonStatus, setAddNoteButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseNotes, setCaseNotes] = useState<GroupCaseNoteInterface[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [isDraftSave, setIsDraftSave] = useState<boolean>(false);
  const [caseNoteDetail, setCaseNoteDetail] = useState<GroupCaseNoteInterface>();
  const [isGroupCaseNoteFormatSettingsVisible, setGroupCaseNoteFormatSettingsVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isValueChange, setIsValueChange] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setCaseNotes(caseNoteList);
  }, [caseNoteList]);

  useEffect(() => {
    setCaseNoteDetail(caseNote);
  }, [caseNote, auth0ClinicianId]);

  const { caseNoteFormatSettingsList, isCaseNoteFormatSettingsListLoading } = useFetchCaseNoteFormatSettingsList(
    token,
    groupDetails._id,
    true
  );

  const handleRedirect = (id: string) => {
    const idPath = id ? `/${id}` : '';
    navigate(`${GROUPS.BASE}/${groupDetails._id}/${activeGroupTab}${idPath}`);
  };

  const onCancelGroupCaseNoteFormatSettingsModal = (refetchFormatSettings?: boolean) => {
    setGroupCaseNoteFormatSettingsVisible(false);
    refetchFormatSettings && fetchCaseNoteFormatSettings(token);
  };

  const handleAddCaseNote = async () => {
    setAddNoteButtonStatus('active');
    try {
      const payload: Record<string, any> = {
        _id: 'new',
        title: '',
        attachments: [],
        body: '<div><br><div>',
        clinicianId: auth0ClinicianId,
        createdAt: moment().toISOString()
      };

      if (caseNoteFormatSettingByGroupId?.defaultHeadingTemplateId) {
        const foundHeadingTemplate = caseNoteHeadingTemplates.find(
          (template) => template._id === caseNoteFormatSettingByGroupId?.defaultHeadingTemplateId
        );

        if (foundHeadingTemplate) {
          payload.body = await generateHeadingTemplate(foundHeadingTemplate.body);
        }
      }

      const defaultFieldSettings = isHelmFeatureToggle
        ? (fields.filter((obj) => obj.name !== safeGuardingName) as CaseNoteFormatSettings['fields'])
        : (fields as CaseNoteFormatSettings['fields']);

      payload.fields = (caseNoteFormatSettingByGroupId?.fields || defaultFieldSettings)
        .map(
          ({
            name,
            type,
            linkType,
            choiceType,
            commonChoices,
            customChoices,
            customCommonChoices,
            active,
            mandatory,
            multiple
          }) => {
            if (!active) {
              return undefined;
            }

            const field: Record<string, any> = {
              _id: uuidv4(),
              name,
              type,
              mandatory
            };

            if (type === 'link') {
              field.linkType = linkType;
            } else if (type === 'multipleChoice' || type === 'multipleChoiceCount') {
              let options: Record<string, any>[] = [];

              if (choiceType === 'common') {
                options = commonChoices?.concat(customCommonChoices || []) || [];
              } else {
                options = customChoices || [];
              }

              field.multiple = multiple;
              field.options = options;
            }

            return field;
          }
        )
        .filter((field) => !!field);

      setCaseNoteDetail(payload as GroupCaseNoteInterface);
      setCaseNotes([payload as GroupCaseNoteInterface, ...caseNotes]);

      setIsEditing(true);

      handleRedirect('new');

      setAddNoteButtonStatus('finished');

      setTimeout(() => setAddNoteButtonStatus(''), 500);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to add a note' });
    }
  };

  const onClickNewNote = () => {
    if (groupDetails.status === GroupStatus.Closed) {
      return;
    }
    if (isEditing || isValueChange) {
      Modal.confirm({
        title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
        onOk: () => handleAddCaseNote()
      });
    } else {
      handleAddCaseNote();
    }
  };

  const handleNavigateNote = (id: string) => {
    setIsDraftSave(false);
    setIsEditing(false);
    setCaseNotes(caseNotes.filter((caseNoteObj) => caseNoteObj._id !== 'new'));
    handleRedirect(id);
    fetchCaseNote(id);
  };

  const onSelectNote = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    const noteId = path.contentId;
    if (noteId !== 'new' ? id !== noteId : id !== 'new') {
      if ((isEditing && isValueChange) || (isEditing && noteId === 'new')) {
        Modal.confirm({
          title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
          onOk: () => handleNavigateNote(id)
        });
      } else {
        handleNavigateNote(id);
      }
    }
  };

  const handleSearchKeyUp = (event: any) => {
    const fieldValue = event.target.value.toLowerCase() || '';
    if (event.keyCode === 13) {
      setSearchValue(fieldValue);
      if (fieldValue.length > 0) {
        const newCaseNotes = caseNoteList.filter((caseNoteObj) => {
          return caseNoteObj.title.toLowerCase().includes(fieldValue);
        });
        setCaseNotes(newCaseNotes);
      } else {
        setCaseNotes(caseNoteList);
      }
    }
  };

  const handleDeleteNote = () => {
    setIsEditing(false);
    setCaseNoteDetail(undefined);
    fetchCaseNote(undefined);
    setCaseNotes(caseNotes.filter((caseNote) => caseNote._id !== caseNoteId));
    setGroupCaseNotes(caseNotes.filter((caseNote) => caseNote._id !== caseNoteId));
    handleRedirect('');
  };

  const handleDeleteTemplate = (headingTemplateId: string) => {
    setCaseNoteHeadingTemplates((headingTemplates) =>
      headingTemplates.filter((headingTemplate) => headingTemplate._id !== headingTemplateId)
    );
  };

  const handleEditNoteClick = () => {
    setIsEditing(true);
  };

  const onAddTemplate = (newHeadingTemplate: HeadingTemplate) => {
    setCaseNoteHeadingTemplates((headingTemplates) => [...headingTemplates, newHeadingTemplate]);
  };

  const handleUpdatedNote = (updatedNote: GroupCaseNoteInterface, isDraft: boolean) => {
    setIsDraftSave(isDraft);
    const newNoteList = [
      updatedNote,
      ...caseNotes.filter((caseNoteObj) => caseNoteObj._id !== 'new' && caseNoteObj._id !== updatedNote._id)
    ];
    setCaseNoteDetail(updatedNote);
    setCaseNotes(newNoteList);
    setGroupCaseNotes(newNoteList);

    if (!isDraft) {
      handleRedirect(updatedNote._id);
      setIsEditing(false);
    }
  };

  const contentLoading = isDraftSave ? false : isCaseNoteLoading || addNoteButtonStatus;
  const isAddNoteButtonDisabled =
    !!addNoteButtonStatus ||
    isCaseNoteFormatSettingByGroupIdLoading ||
    isEditing ||
    groupDetails.status === GroupStatus.Closed ||
    isCaseNoteFormatSettingsListLoading;

  return (
    <div className={styles.container}>
      {isCaseNoteListLoading || areCaseNoteHeadingTemplatesLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <GroupCaseNoteFormatSettingsModal
            token={token}
            groupId={groupDetails._id}
            caseNoteHeadingTemplates={caseNoteHeadingTemplates}
            defaultCaseNoteFormatSettings={caseNoteFormatSettingByGroupId}
            visible={isGroupCaseNoteFormatSettingsVisible}
            onCancel={onCancelGroupCaseNoteFormatSettingsModal}
            onAddTemplate={onAddTemplate}
          />
          <HeadingAssessment isLoading={[].length > 0} assessmentData={[]} />
          <div className={styles.header}>
            <span className={styles.caseNotesCount}>
              {caseNotes.filter((note) => note._id).length} case notes saved
            </span>
            <div className={styles.inputContainer}>
              <MaterialInput id="search-notes" label="Search notes" onKeyUp={(e) => handleSearchKeyUp(e)} required />
            </div>
            {isEdgeAdminUser && (
              <Button
                className={styles.noteFormatButton}
                variant="secondary"
                disabled={isCaseNoteFormatSettingByGroupIdLoading || groupDetails.status === GroupStatus.Closed}
                onClick={() => setGroupCaseNoteFormatSettingsVisible(true)}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>settings</i>
                Manage note format
              </Button>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.noteListing}>
              <CaseNoteListing
                noteId={caseNoteId}
                caseNotes={caseNotes}
                searchValue={searchValue}
                addNoteButtonStatus={addNoteButtonStatus}
                isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                onClickNewNote={onClickNewNote}
                onSelectNote={onSelectNote}
                isEditing={isEditing}
              />
            </div>
            <div className={styles.noteDetailsContainer}>
              {caseNotes.length === 0 && !isCaseNoteLoading ? (
                <div className={styles.noCaseNotes}>
                  <NoCaseNoteDetail
                    message={t('label.no_client_case_notes')}
                    addNoteButtonStatus={addNoteButtonStatus}
                    isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                    onClickNewNote={onClickNewNote}
                  />
                </div>
              ) : !caseNoteId ? (
                <div className={styles.noCaseNoteSelected}>
                  <NoCaseNoteDetail
                    message={'No case note selected.'}
                    addNoteButtonStatus={addNoteButtonStatus}
                    isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                    onClickNewNote={onClickNewNote}
                  />
                </div>
              ) : contentLoading ? (
                <div className={styles.loading}>
                  <LoadingDot />
                </div>
              ) : caseNoteDetail ? (
                <GroupCaseNoteDetail
                  groupId={groupDetails._id}
                  caseNoteData={caseNoteDetail}
                  caseNoteHeadingTemplates={caseNoteHeadingTemplates}
                  token={token}
                  isEditing={isEditing}
                  onValueChange={(val) => setIsValueChange(val)}
                  onDeleteNote={handleDeleteNote}
                  onDeleteTemplate={handleDeleteTemplate}
                  onEditNoteClick={handleEditNoteClick}
                  onAddTemplate={onAddTemplate}
                  onUpdateNoteDetails={handleUpdatedNote}
                  isReadOnly={caseNote && !isNormalUserView ? caseNote?.clinicianId !== auth0ClinicianId : false}
                  isClosedClient={groupDetails.status === GroupStatus.Closed}
                  formatSettings={caseNoteFormatSettingsList}
                />
              ) : (
                <div className={styles.noCaseNoteFound}>
                  <NoCaseNoteDetail
                    message={'Note not found.'}
                    addNoteButtonStatus={addNoteButtonStatus}
                    isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                    onClickNewNote={onClickNewNote}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupCaseNote;
