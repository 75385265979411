import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getInterventionLibrary } from 'utils/http/ClinicianProfileService/Interventions/interventionLibrary';
import { InterventionLibraryInterface } from '../interfaces/InterventionLibrary';

export const useFetchInterventionLibrary = (token: string) => {
  const [interventionLibrary, setInterventionLibrary] = useState<InterventionLibraryInterface[]>([]);
  const [isInterventionLibraryLoading, setIsInterventionLibraryLoading] = useState(true);

  const fetchInterventionLibrary = useCallback(async (token: string) => {
    try {
      setIsInterventionLibraryLoading(true);
      const res = await getInterventionLibrary(token);
      const data = await res.json();
      setInterventionLibrary(data);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get intervention library' });
    }
    setIsInterventionLibraryLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      fetchInterventionLibrary(token);
    }
  }, [fetchInterventionLibrary, token]);

  return { interventionLibrary, isInterventionLibraryLoading, fetchInterventionLibrary };
};
