import { useContext } from 'react';

import { CustomFeature, UserContext } from '../UserContextProvider';
import { useGetAccountPackageView } from '../hooks/GetAccountInfo/accountPackageView';

export const useGetFeatureToggle = () => {
  const { isNormalUserView, isEdgeUserView, isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { customFeatures } = useContext(UserContext);
  const isMentorFeatureToggle = customFeatures.includes(CustomFeature.Mentor);
  const isGroupsFeatureToggle = isEdgeUserView || isEdgeAdminView || isEdgeReceptionist;
  const isInterventionsFeatureToggle = isEdgeUserView || isEdgeAdminView || isEdgeReceptionist;
  const isEnableStonlyGuide = isNormalUserView;

  const isAnalyticFeatureToggle = customFeatures.includes(CustomFeature.Analytics);
  const helmContentFeatureToggle = customFeatures.includes(CustomFeature.HelmContent);
  const helmFeatureToggle = customFeatures.includes(CustomFeature.Helm);
  const medicareRebateFeatureToggle = customFeatures.includes(CustomFeature.MedicareRebate);
  const isHelmFeatureToggle = customFeatures.includes(CustomFeature.Helm);
  const isGroupsTagsToggle = false;
  const isInterventionsTagsToggle = false;
  const isRegionAU = process.env.REACT_APP_GAE_REGION === 'au';
  const isRegionUK = process.env.REACT_APP_GAE_REGION === 'gb';
  const isVadcFeatureToggle = customFeatures.includes(CustomFeature.Vadc);
  const isRequestApprovalByDefault = customFeatures.includes(CustomFeature.RequestApprovalByDefault);
  const viewCaseNotesTemplateFeature = customFeatures.includes(CustomFeature.CaseNotesTemplate) && isEdgeAdminView;

  return {
    isAnalyticFeatureToggle,
    isEnableStonlyGuide,
    isGroupsFeatureToggle,
    isGroupsTagsToggle,
    isInterventionsFeatureToggle,
    isInterventionsTagsToggle,
    isHelmFeatureToggle,
    isMentorFeatureToggle,
    isRegionAU,
    isRegionUK,
    isVadcFeatureToggle,
    helmFeatureToggle,
    helmContentFeatureToggle,
    medicareRebateFeatureToggle,
    isRequestApprovalByDefault,
    viewCaseNotesTemplateFeature
  };
};
