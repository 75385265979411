import { Skeleton } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { DataImportHistory, ImportDataStatus, ImportDataType } from 'pages/ControlPanel/Interfaces/MisConnection';
import { convertStringToTitleCase } from 'utils/general';
import styles from './ImportHistoryTable.module.scss';

interface ImportHistoryTableProps {
  histories: DataImportHistory[];
  isLoading: boolean;
}

const getDataTypeLabel = (type: ImportDataType) => {
  switch (type) {
    case ImportDataType.AssignedTeacher:
      return 'Teacher assignment for student';
    case ImportDataType.Teachers:
      return 'Teacher';
    case ImportDataType.Students:
      return 'Student';
  }
};

const getStatusIcon = (status: ImportDataStatus) => {
  switch (status) {
    case ImportDataStatus.Success:
      return 'check_circle';
    case ImportDataStatus.Processing:
      return 'hourglass_top';
    case ImportDataStatus.Failed:
      return 'warning';
  }
};

export const ImportHistoryTable = ({ histories, isLoading }: ImportHistoryTableProps) => {
  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className={styles.title}>History</div>
          <div className={styles.header}>
            <div className={styles.headerItem}>DATA TYPE</div>
            <div className={styles.headerItem}>DATE OF IMPORT</div>
            <div className={styles.headerItem}>STATUS</div>
            <div className={styles.headerItem}>NUMBER OF RECORD UPDATED</div>
          </div>
          <div className={styles.histories}>
            {histories.length === 0 ? (
              <div className={styles.noField}>No records</div>
            ) : (
              histories.map((history) => (
                <div className={styles.historyRow}>
                  <div className={styles.field}>{getDataTypeLabel(history.type)}</div>
                  <div className={styles.field}>{moment(history.createdAt).format('DD/MM/YYYY')}</div>
                  <div className={styles.status}>
                    <div className={styles.statusIconContainer}>
                      <i
                        className={classNames('material-icons', styles[history.status])}
                        data-tip
                        data-for={history._id}
                      >
                        {getStatusIcon(history.status)}
                      </i>
                    </div>
                    <div className={styles.statusMessageContainer}>
                      <div className={styles.statusMessage}>{convertStringToTitleCase(history.status)}</div>
                    </div>
                  </div>
                  <div className={styles.field}>{history.count || '-'}</div>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};
