import styles from './AttachmentCard.module.scss';
import path from 'path';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import { GroupAttachmentInterface } from '../../interfaces';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

interface AttachmentCardProps {
  baseLink: string;
  isDeleting: boolean;
  ableToDelete: boolean;
  attachment: GroupAttachmentInterface;
  onDeleteAttachmentClick: () => void;
  onDownloadAttachmentClick: () => void;
}

const AttachmentCard = ({
  baseLink,
  isDeleting,
  ableToDelete,
  attachment: { caseNote, createdAt, title, thumbnail },
  onDeleteAttachmentClick,
  onDownloadAttachmentClick
}: AttachmentCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.previewContainer}>
        {thumbnail ? (
          <img src={thumbnail} alt={'thumbnail'} />
        ) : (
          <span className={styles.fileType}>{path.extname(title).toUpperCase()}</span>
        )}
      </div>
      <Button className={styles.titleButton} variant="secondary" onClick={onDownloadAttachmentClick}>
        {title}
      </Button>
      <div className={styles.date}>Added {moment(createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
      {caseNote?._id && (
        <div className={styles.linked}>
          Linked to{' '}
          <Link className={styles.link} to={baseLink}>
            {caseNote.title}
          </Link>
        </div>
      )}
      {ableToDelete ? (
        <Button
          className={styles.deleteButton}
          variant="secondary"
          disabled={isDeleting}
          onClick={onDeleteAttachmentClick}
        >
          DELETE
        </Button>
      ) : (
        <i className={`material-icons-outlined ${styles.lockIcon}`}>lock</i>
      )}
    </div>
  );
};

export default AttachmentCard;
