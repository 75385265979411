import { Modal, notification } from 'antd';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Location } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateLocation } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './ConfirmActivationModal.module.scss';

interface ConfirmActivationModalProps {
  visible: boolean;
  location: Location;
  setShowConfirmActivationModal: (bool: boolean) => void;
  refetchMedicareLocations: () => void;
}

const ConfirmActivationModal = ({
  visible,
  location: { name: locationName, minorId, active: locationActive },
  setShowConfirmActivationModal,
  refetchMedicareLocations
}: ConfirmActivationModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const action = locationActive ? 'deactivate' : 'reactivate';
  const descriptions = locationActive
    ? [
        'You can not submit any new claims using this location ID, historical claims are not affected.',
        'Any specific provider numbers that are connected to this location ID will also be deactivated.'
      ]
    : [
        'You can begin submitting new claims using this location ID.',
        'Any specific provider numbers that are connected to this location ID will also be reactivated.'
      ];

  const handleCloseModal = () => {
    setShowConfirmActivationModal(false);
  };

  const handleConfirmChangeActive = async () => {
    setSubmitStatus('active');
    try {
      await updateLocation(token, minorId, { active: !locationActive });

      refetchMedicareLocations();
      handleCloseModal();
      setSubmitStatus('finished');

      notification.success({
        message: `Location ${action}d`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({ message: `Something went wrong while trying to ${action} this location` });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      visible={visible}
      closable
      title={null}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <i className={`material-icons ${styles.icon}`}>report_gmailerrorred</i>
          <div className={styles.messageContainer}>
            <div className={styles.title}>{`Are you sure you want to ${action} ${locationName} ?`}</div>
            <div className={styles.descriptionContainer}>
              <div>{`When this location is ${action}d:`}</div>
              <ul className={styles.descriptionList}>
                {descriptions.map((description, index) => (
                  <li key={index}>{description}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChangeActive} status={submitStatus}>
            {locationActive ? 'Confirm deactivation' : 'Yes'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActivationModal;
