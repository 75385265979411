import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { noteList } from '../http/CheckInService/Notes/notes';
import { useTranslation } from 'react-i18next';

export interface QuickNote {
  _id: string;
  title: string;
  body: string;
  createdAt: string;
}

export const useFetchQuickNotes = (token: string, recordId: string) => {
  const [quickNotesData, setQuickNotesData] = useState<QuickNote[]>([]);
  const [isQuickNotesLoading, setIsQuickNotesLoading] = useState(true);
  const [t] = useTranslation();

  const fetchQuickNotes = async () => {
    setIsQuickNotesLoading(true);

    if (recordId) {
      try {
        const getNotesByPatientId = await noteList(token, recordId);

        const { data } = await getNotesByPatientId.json();

        if (Array.isArray(data)) {
          setQuickNotesData(data as any);
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({ message: t('form.error.fetch_client_quick_notes') });
      }
    }

    setIsQuickNotesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchQuickNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { quickNotesData, isQuickNotesLoading, fetchQuickNotes };
};
