import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { GroupListData, GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';

import styles from './GroupList.module.scss';

interface GroupListProps {
  groupList: GroupListData['groups'];
  selectedGroupId?: string;
  onSelectGroups: (val: GroupsFromAPI, preventCloseModal?: boolean) => void;
  onGroupListLoading?: (val: boolean) => void;
  isLoading: boolean;
}

const GroupList = ({ groupList, selectedGroupId, onSelectGroups, onGroupListLoading, isLoading }: GroupListProps) => {
  const [selectedGroupData, setSelectedGroupData] = useState<GroupsFromAPI>();

  useEffect(() => {
    onGroupListLoading && onGroupListLoading(isLoading);
    const getSelectedGroupData = groupList.find((gObj) => gObj._id === selectedGroupId);
    if (getSelectedGroupData) {
      setSelectedGroupData(getSelectedGroupData);
      onSelectGroups(getSelectedGroupData, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId, groupList, onGroupListLoading]);

  // useEffect(() => {
  //   sortByGroupName(sortGroupAsc);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortGroupAsc]);

  const handleSelectGroup = (groupData: GroupsFromAPI) => {
    setSelectedGroupData(groupData);
    onSelectGroups(groupData);
  };

  return isLoading ? (
    <div className={styles.loadingContainer}>
      {[...Array(6)].map((obj, i) => (
        <div key={i} className={styles.loadingBox}>
          <Skeleton.Input active className={styles.loadingName} />
        </div>
      ))}
    </div>
  ) : (
    <div className={styles.container}>
      {groupList.length > 0 ? (
        <>
          {groupList.map((groupObj, index) => {
            return (
              <div
                key={index}
                onClick={() => handleSelectGroup(groupObj)}
                className={classNames(styles.groupBox, {
                  [styles.active]: selectedGroupData?._id === groupObj._id
                })}
              >
                <div className={styles.groupInfoWrapper}>
                  <div className={styles.name}>{groupObj.name}</div>
                  <div className={styles.activeMember}>
                    {groupObj.clientCount} Active Member
                    {groupObj.clientCount !== 1 && 's'}
                  </div>
                </div>
                {selectedGroupData?._id === groupObj._id && (
                  <div className={styles.selectedBadge}>
                    <span className="material-icons">check_circle</span>
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.noFoundLabel}>No group record found</div>
      )}
    </div>
  );
};

export default GroupList;
