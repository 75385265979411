import { Integration } from 'pages/ControlPanel/Interfaces/Integration';

import ConnectedIntegrationRow from './components/ConnectedIntegrationRow';
import styles from './ConnectedIntegration.module.scss';

interface ConnectedIntegrationProps {
  connectedIntegrations: Integration[];
  selectIntegration: (type: string, id: string) => void;
}

const ConnectedIntegration = ({ connectedIntegrations, selectIntegration }: ConnectedIntegrationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerItem}>CONNECTION</div>
        <div className={styles.headerItem}>CONNECTED SINCE</div>
        <div className={styles.headerItem}>STATUS</div>
        <div className={styles.headerItem} />
      </div>
      {connectedIntegrations.map((integration, index) => (
        <ConnectedIntegrationRow key={index} integration={integration} selectIntegration={selectIntegration} />
      ))}
    </div>
  );
};

export default ConnectedIntegration;
