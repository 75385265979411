import { notification } from 'antd';
import { ConnectSchoolInfo } from 'pages/ControlPanel/Interfaces/MisConnection';
import { useCallback, useEffect, useState } from 'react';
import { getSchoolConnectionInfo } from 'utils/http/ClinicianProfileService/MisSchools/misSchools';

export const useFetchSchoolConnection = (token: string, accountId: string) => {
  const [schoolConnection, setschoolConnection] = useState<ConnectSchoolInfo>();
  const [isSchoolConnectionLoading, setIsSchoolConnectionLoading] = useState(true);

  const fetchSchoolConnection = useCallback(async (token: string, accountId: string) => {
    setIsSchoolConnectionLoading(true);
    try {
      const schoolConnectionResponse = await getSchoolConnectionInfo(token, accountId);

      if (schoolConnectionResponse.statusCode === 200) {
        const schoolConnection = (await schoolConnectionResponse.json()) as ConnectSchoolInfo;
        setschoolConnection(schoolConnection);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get MIS school connection' });
    }
    setIsSchoolConnectionLoading(false);
  }, []);

  const refetchSchoolConnection = () => {
    fetchSchoolConnection(token, accountId);
  };

  useEffect(() => {
    if (token) {
      fetchSchoolConnection(token, accountId);
    }
  }, [fetchSchoolConnection, token, accountId]);

  return { schoolConnection, isSchoolConnectionLoading, refetchSchoolConnection };
};
