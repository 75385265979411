import WidgetItemWrapper from '../WidgetItemWrapper/WidgetItemWrapper';
import JournalCommentWidget from '../../../../../../../../../components/WidgetComponents/JournalCommentWidget/JournalCommentWidget';
import LoadingDot from 'components/LoadingDot/LoadingDot';

import styles from './MicroJournalWrapper.module.scss';
import { useTranslation } from 'react-i18next';

interface MicroJournalWrapperProps {
  isMicroJournalEntriesLoading: boolean;
  microJournalEntriesData: any;
}

const MicroJournalWrapper = ({ isMicroJournalEntriesLoading, microJournalEntriesData }: MicroJournalWrapperProps) => {
  const [t] = useTranslation();

  return isMicroJournalEntriesLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <>
      {microJournalEntriesData.length > 0 ? (
        microJournalEntriesData.map((obj: any, index: string) => (
          <WidgetItemWrapper data={obj} widgetType={'journalCommentWidget'} key={index}>
            <JournalCommentWidget data={obj} />
          </WidgetItemWrapper>
        ))
      ) : (
        <div>{t('label.no_client_journal_comments')}</div>
      )}
    </>
  );
};

export default MicroJournalWrapper;
