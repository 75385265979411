import { notification } from 'antd';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import {
  getAllClientEncryptedDataByClientRecordId,
  getCORDSClientRecordByRecordId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

export const useFetchClientRecordById = (token: string, recordId?: string, accountId?: string, isMHH?: boolean) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const [clientRecordData, setClientRecordData] = useState<clientRecordsInterface>({} as clientRecordsInterface);
  const [isClientRecordLoading, setIsClientRecordLoading] = useState(true);
  const [t] = useTranslation();

  const fetchClientRecord = useCallback(
    async (token: string) => {
      try {
        if (!recordId) {
          return;
        }
        const clientData = isMHH
          ? await getCORDSClientRecordByRecordId(token, recordId)
          : await getAllClientEncryptedDataByClientRecordId(token, recordId);
        const clientRecordData = (await clientData.json()) as clientRecordsInterface;

        const massageData = {
          ...clientRecordData,
          isCompleteRecords:
            clientRecordData.clientProfiles[0].profileType === 'full' &&
            clientRecordData.clientProfiles[0].signupInvitation &&
            !!clientRecordData.clientProfiles[0].signupInvitation?.signupDate,
          allowCommunicationWithEmail: clientRecordData.clientProfiles.some(
            (profileObj) =>
              profileObj.role !== 'child' &&
              profileObj.isPrimaryContact &&
              canClientReceiveEmail(profileObj.communicationPreference) &&
              profileObj.hasEmail
          ),
          allowCommunicationWithSms: clientRecordData.clientProfiles.some(
            (profileObj) =>
              profileObj.role !== 'child' &&
              profileObj.isPrimaryContact &&
              canClientReceiveSms(profileObj.communicationPreference) &&
              profileObj.hasMobileNumber
          )
        } as clientRecordsInterface;

        setClientRecordData(massageData);
      } catch (ex) {
        notification.error({ message: t('form.error.fetch_client_record_by_id') });
        if (isErrorBentStatusError(ex) && ex.statusCode === 404) {
          navigate(CLIENTS.BASE);
        }
      }

      setIsClientRecordLoading(false);
    },
    [CLIENTS.BASE, navigate, recordId, t, isMHH]
  );

  useEffect(() => {
    if (token && recordId) {
      fetchClientRecord(token);
    }
  }, [token, recordId, fetchClientRecord]);

  return { clientRecordData, isClientRecordLoading, fetchClientRecord, setClientRecordData };
};
