import { useState } from 'react';
import styles from './AppointmentStatusTagsForm.module.scss';
import { AppointmentStatusOverviewConfiguration } from 'interfaces/Clients/clientsRecord';
import CheckBox from 'components/CheckBox/CheckBox';
import { AppointmentStatusOption } from 'pages/Calendar/components/Modals/EventInformationModal/components/MarkStatus/MarkStatus';
import { toCamelCase } from 'utils/generateCamelCase';

export enum AppointmentTargetLimitOption {
  Target = 'Target',
  Limit = 'Limit'
}

const combineDisplayTag = { ...AppointmentTargetLimitOption, ...AppointmentStatusOption };

const AppointmentDisplayTags = Object.values(combineDisplayTag).map((obj) => ({
  label: obj,
  value: obj
}));

interface TagsField {
  id: string;
  label: string;
  isChecked: boolean;
}

interface AppointmentStatusTagsFormProps {
  displayTagCount: AppointmentStatusOverviewConfiguration['displayTagCount'];
  onChangeDisplayTag: (val: AppointmentStatusOverviewConfiguration['displayTagCount']) => void;
}

const AppointmentStatusTagsForm = ({ displayTagCount, onChangeDisplayTag }: AppointmentStatusTagsFormProps) => {
  const generateDisplayTags: TagsField[] = AppointmentDisplayTags.map((optionObj) => ({
    id: toCamelCase(optionObj.value) || '',
    label: optionObj.value,
    isChecked: !!displayTagCount.find((obj) => obj === optionObj.value)
  }));

  const [tagsField, setTagsField] = useState<TagsField[]>(generateDisplayTags);

  const handleChangeDisplayTags = (id: string, val: boolean) => {
    const newTagsField: TagsField[] = [];
    for (let obj of tagsField) {
      newTagsField.push({
        ...obj,
        isChecked: obj.id === id ? val : obj.isChecked
      });
    }
    setTagsField(newTagsField);

    const getOnlyCheckField = newTagsField.filter((tagsObj) => tagsObj.isChecked).map((obj) => obj.label);
    onChangeDisplayTag(getOnlyCheckField);
  };

  return (
    <>
      <div className={styles.title}>SHOW COUNT FOR</div>
      <div className={styles.fieldContainer}>
        {tagsField.map((optionObj, index) => (
          <div className={styles.tagWrapper} key={index}>
            <CheckBox
              className={styles.checkBoxWrapper}
              inputClassName={styles.checkBox}
              id={optionObj.id}
              value={optionObj.isChecked}
              label={optionObj.label}
              labelClassName={optionObj.isChecked ? styles.activeLabel : styles.label}
              onChange={(e) => handleChangeDisplayTags(optionObj.id, e.target.checked)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default AppointmentStatusTagsForm;
