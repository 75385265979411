import { useEffect, useState } from 'react';
import styles from './InterventionList.module.scss';
import { ActionPlanAssessment, ActionPlanIntervention } from '../../../../interfaces';
import Button from 'components/v2/Button/Button';
import InterventionItem from './components/InterventionItem/InterventionItem';
import { actionPlanFormErrors, focusAreaMapping } from '../../../../constants';
import classnames from 'classnames';
import InterventionDetail from './components/InterventionListDetail/InterventionDetail';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchInterventionList } from 'MHH/pages/ActionPlanEditor/hook/getInterventionList';
import { InterventionsFromAPI, SdqRecommendation } from 'MHH/pages/Interventions/Interfaces/Interventions';
import { useFetchAssessmentByAssessmentId } from './hooks/getClinicalAssessmentsByIds';
import { Skeleton } from 'antd';
import NoActionModal from './components/NoActionModal/NoActionModal';

export interface ActionPlanInterventionItem extends ActionPlanIntervention {
  focusArea?: string;
  scoreScale?: string[];
  score?: number;
  selectedInterventionIds?: string[];
  subInterventions?: ActionPlanInterventionItem[];
  recommendations?: InterventionsFromAPI[];
  isEdit?: boolean;
  isOld?: boolean;
  isView?: boolean;
  isDefault?: boolean;
  errorMessage?: { [key: string]: string };
}

interface InterventionListProps {
  isView?: boolean;
  isEdit?: boolean;
  clientRecordId?: string;
  interventionList: ActionPlanInterventionItem[];
  checkStatus?: '' | 'active' | 'finished';
  attachedAssessments: ActionPlanAssessment[];
  onChange?: (interventions: ActionPlanInterventionItem[]) => void;
  checkIsValid?: (interventions: ActionPlanInterventionItem, index: number) => boolean;
  onCheck?: (interventions: string, isCompleted: boolean) => void;
}

const focusAreaList = Object.keys(focusAreaMapping);

const InterventionList = ({
  isView,
  isEdit,
  interventionList,
  clientRecordId,
  checkStatus,
  attachedAssessments,
  onChange,
  checkIsValid,
  onCheck
}: InterventionListProps) => {
  const { token } = useGetAccessToken();
  const { interventionList: interventionOptions } = useFetchInterventionList(token);
  const [selectedIntervention, setSelectedIntervention] = useState<ActionPlanInterventionItem>();
  const [selectedNoActionInterventionIndex, setSelectedNoActionInterventionIndex] = useState<number>();
  const { assessments, isLoadingAssessment } = useFetchAssessmentByAssessmentId(
    clientRecordId || '',
    attachedAssessments
  );
  const [groupedFocusArea, setGroupedFocusArea] = useState<{ [key: string]: { label?: string[]; score?: number } }>({});

  useEffect(() => {
    if (isView && selectedIntervention) {
      const foundInterventionItem =
        interventionList
          .find(({ subInterventions }) => subInterventions?.some(({ _id }) => _id === selectedIntervention._id))
          ?.subInterventions?.find(({ _id }) => _id === selectedIntervention._id) ||
        interventionList.find(({ _id }) => _id === selectedIntervention._id);

      setSelectedIntervention(foundInterventionItem);
    }
  }, [isView, selectedIntervention, interventionList]);

  const checkIsRecommended = ({
    score,
    sdqRecomendations,
    focusArea
  }: {
    score: number | undefined;
    sdqRecomendations?: SdqRecommendation[];
    focusArea: string;
  }) => {
    if (score === undefined) {
      return false;
    }

    const foundScale = sdqRecomendations?.find((item) => item.scale === focusArea);

    if (!foundScale) {
      return false;
    }

    const minComparison = score >= (foundScale.min || 0);
    const maxComparison = !foundScale.max || score <= foundScale.max;
    const equalComparison = isNaN(Number(foundScale.equals)) || score === foundScale.equals;

    return minComparison && maxComparison && equalComparison;
  };

  useEffect(() => {
    if (assessments) {
      //Group focus area from clinical assessment
      const tempGroup: { [key: string]: { label?: string[]; score?: number } } = {};
      assessments
        ?.filter(({ _id }) =>
          attachedAssessments.some(({ clinicalAssessmentResponseId }) => _id === clinicalAssessmentResponseId)
        )
        .forEach((assessment) => {
          assessment.charts.forEach((item) => {
            if (!item || !item.key || !item.label || item.key.includes('Total')) {
              return;
            }
            const key = item.key.replace(' score', '');
            if (!focusAreaMapping[key]) {
              return;
            }
            tempGroup[key] = {
              ...tempGroup[key],
              ...(['High', 'Very high'].includes(item.label) && {
                label: [...(tempGroup[key]?.label || []), item.label]
              }),
              score: (tempGroup[key]?.score || 0) > item.total ? tempGroup[key].score : item.total
            };
          });
        });

      //Mapping score scale, recomendation into intervention
      const newInt = [...interventionList];
      Object.keys(tempGroup).forEach((key) => {
        if (!tempGroup[key].label) {
          return;
        }
        const foundIntervention = newInt.findIndex((item) => item.focusArea === key);
        if (foundIntervention > -1 && newInt[foundIntervention]) {
          newInt[foundIntervention] = {
            ...newInt[foundIntervention],
            scoreScale: tempGroup[key].label,
            score: tempGroup[key].score,
            recommendations: interventionOptions.filter((item) =>
              checkIsRecommended({
                score: tempGroup[key].score,
                focusArea: focusAreaMapping[key],
                sdqRecomendations: item.sdqRecommendations
              })
            ),
            isDefault: true
          };

          return;
        }

        //For creator ui when attached assessment change
        if (!isEdit && !isView) {
          newInt.push({
            focusArea: key,
            scoreScale: tempGroup[key].label,
            interventionId: '',
            selectedInterventionIds: [],
            score: tempGroup[key].score,
            recommendations: interventionOptions.filter((item) =>
              checkIsRecommended({
                score: tempGroup[key].score,
                focusArea: focusAreaMapping[key],
                sdqRecomendations: item.sdqRecommendations
              })
            ),
            name: '',
            description: '',
            dueDate: '',
            isCompleted: false,
            isEdit: true,
            isDefault: true
          });
        }
      });
      setGroupedFocusArea(tempGroup);
      onChange?.(
        assessments.length === 0
          ? newInt.filter((int) => int.isOld || int.selectedInterventionIds?.length || !int.focusArea)
          : newInt
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedAssessments, assessments, isLoadingAssessment]);

  const handleAddNewIntervention = () => {
    const newInt = [
      ...interventionList,
      {
        interventionId: '',
        selectedInterventionIds: [],
        name: '',
        description: '',
        dueDate: '',
        isCompleted: false,
        isEdit: true
      }
    ];
    onChange && onChange(newInt);
  };

  const handleCheck = (id: string, isCompleted: boolean) => {
    onCheck?.(id, isCompleted);
  };

  const handleEdit = (index: number, subIntervention?: ActionPlanInterventionItem) => {
    if (isView) {
      setSelectedIntervention(
        selectedIntervention?._id === (subIntervention?._id || interventionList[index]._id)
          ? undefined
          : subIntervention || interventionList[index]
      );
      return;
    } else {
      const newInterventions = [...interventionList];

      if (newInterventions[index].noAction) {
        setSelectedNoActionInterventionIndex(index);
      } else {
        newInterventions[index].isEdit = true;
        onChange && onChange(newInterventions);
      }
    }
  };

  const handleDelete = (index: number) => {
    const newInterventions = [...interventionList];
    newInterventions.splice(index, 1);
    onChange && onChange(newInterventions);
  };

  const handleChangeValues = (
    key: keyof ActionPlanInterventionItem,
    index: number,
    value: ActionPlanInterventionItem[keyof ActionPlanInterventionItem]
  ) => {
    const newInterventions = [...interventionList];
    newInterventions[index] = {
      ...newInterventions[index],
      [key]: value,
      errorMessage: {
        ...newInterventions[index].errorMessage,
        [key]: !value ? actionPlanFormErrors[key] : ''
      },
      ...(key === 'focusArea' &&
        typeof value === 'string' && {
          recommendations: interventionOptions.filter((item) =>
            checkIsRecommended({
              score: groupedFocusArea[value]?.score,
              focusArea: focusAreaMapping[value],
              sdqRecomendations: item.sdqRecommendations
            })
          )
        })
    };
    onChange?.(newInterventions);
  };

  const handleTakeNoAction = (index: number) => {
    setSelectedNoActionInterventionIndex(index);
  };

  const handleCloseNoAction = () => {
    setSelectedNoActionInterventionIndex(undefined);
  };

  const handleSaveNoAction = (noAction: Required<ActionPlanIntervention>['noAction']) => {
    if (typeof selectedNoActionInterventionIndex === 'number') {
      const newInterventions = [...interventionList];
      newInterventions[selectedNoActionInterventionIndex] = {
        ...newInterventions[selectedNoActionInterventionIndex],
        noAction,
        isEdit: false,
        isOld: true
      };
      onChange?.(newInterventions);
      handleCloseNoAction();
    }
  };

  const splitInterventionsToSubmit = (interventions: ActionPlanInterventionItem[]) => {
    const finalInterventions: ActionPlanInterventionItem[] = [];

    interventions.forEach((item) => {
      if (item.focusArea || item.isEdit) {
        finalInterventions.push({
          ...item,
          ...(item.subInterventions && {
            subInterventions: item.subInterventions.map((subIntervention) => ({
              ...subIntervention,
              dueDate: item.dueDate
            }))
          })
        });
        return;
      }

      const splittedIntervention =
        (item.selectedInterventionIds || [item.interventionId]).map((id) => {
          const foundInterventionItem = interventionOptions.find(({ _id }) => _id === id);

          return {
            ...(item as ActionPlanIntervention),
            interventionId: id,
            selectedInterventionIds: id ? [id] : [],
            ...(foundInterventionItem && {
              name: foundInterventionItem.name,
              description: foundInterventionItem.description || ''
            })
          };
        }) || [];
      finalInterventions.push(...splittedIntervention);
    });

    return finalInterventions.sort((int1, int2) => {
      if (int1.focusArea && !int2.focusArea) {
        return -1;
      } else if (!int1.focusArea && int2.focusArea) {
        return 1;
      } else if ((int1.isEdit && int2.isEdit) || (int1.focusArea && int2.focusArea)) {
        return 0;
      } else if (!int1.isEdit && int2.isEdit) {
        return -1;
      } else if (int1.isOld) {
        return 0;
      } else {
        return 1;
      }
    });
  };

  const handleSave = (index: number) => {
    if (checkIsValid && !checkIsValid(interventionList[index], index)) {
      return;
    }
    const newInterventions = [...interventionList];
    newInterventions[index].isEdit = false;
    newInterventions[index].isOld = true;
    onChange?.(splitInterventionsToSubmit(newInterventions));
  };

  return isLoadingAssessment ? (
    <Skeleton />
  ) : (
    <>
      <NoActionModal
        visible={typeof selectedNoActionInterventionIndex === 'number'}
        noAction={
          typeof selectedNoActionInterventionIndex === 'number'
            ? interventionList[selectedNoActionInterventionIndex].noAction
            : undefined
        }
        onClose={handleCloseNoAction}
        onSave={handleSaveNoAction}
      />
      {interventionList.map((intervention, index) => (
        <div className={styles.interventionContainer} key={index}>
          <div className={classnames(styles.intervention, isView && styles.viewIntervention)}>
            <InterventionItem
              isView={isView}
              key={index}
              intervention={intervention}
              index={index}
              focusAreaOptions={focusAreaList.filter(
                (area) => !interventionList.some(({ focusArea }) => focusArea === area)
              )}
              interventionOptions={interventionOptions}
              selectedId={
                (selectedIntervention?._id === intervention._id && intervention._id) ||
                intervention.subInterventions?.find(
                  (subIntervention) => subIntervention._id === selectedIntervention?._id
                )?._id
              }
              onChangeValues={handleChangeValues}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onTakeNoAction={handleTakeNoAction}
              onSave={handleSave}
            />
          </div>
          {isView &&
            (selectedIntervention?._id === intervention._id ||
              intervention.subInterventions?.find(({ _id }) => _id === selectedIntervention?._id)) && (
              <div className={styles.interventionDetail}>
                <InterventionDetail
                  intervention={selectedIntervention || intervention}
                  onSaveCompletedStatus={handleCheck}
                  saveStatus={checkStatus}
                />
              </div>
            )}
        </div>
      ))}
      {!isView && (
        <Button
          className={styles.addNewInterventionButton}
          disabled={false}
          onClick={handleAddNewIntervention}
          id="addInterventionBtnId"
        >
          <i className="material-icons-outlined">add_circle_outline</i>
          {`Add ${interventionList?.length ? 'another' : ''} intervention`}
        </Button>
      )}
    </>
  );
};

export default InterventionList;
