import { Skeleton, notification } from 'antd';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { PROVIDER_ICON } from 'pages/ControlPanel/ControlPanel/constants';
import { Integration, IntegrationStatus, IntegrationType } from 'pages/ControlPanel/Interfaces/Integration';
import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useGetAccessToken } from 'utils/hooks/token';
import { postConnectCodat } from 'utils/http/BillingService/Invoice/codat';

import styles from './IntegrationDetailsHeader.module.scss';

interface IntegrationDetailsHeaderProps {
  integration: Integration;
  isLoading: boolean;
  setShowDataMappingModal: (bool: boolean) => void;
  setShowDisconnectModal: (bool: boolean) => void;
}

const IntegrationDetailsHeader = ({
  integration: { provider, status, type, label },
  isLoading,
  setShowDataMappingModal,
  setShowDisconnectModal
}: IntegrationDetailsHeaderProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');

  const redirectToCodat = async () => {
    setSubmitStatus('active');
    try {
      const codatUrl = await (await postConnectCodat(token)).text();
      setSubmitStatus('finished');
      window.open(codatUrl);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: `Something went wrong while trying connecting to ${label}` });
    }
    setSubmitStatus('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.providerIconContainer}>
        {isLoading ? (
          <>
            <Skeleton.Input className={styles.loading} active />
          </>
        ) : (
          <img
            src={PROVIDER_ICON[provider as keyof typeof PROVIDER_ICON]}
            alt={`${provider}-icon`}
            className={styles.icon}
          />
        )}
      </div>
      <div className={styles.actionButtonContainer}>
        {isLoading ? (
          <Skeleton.Input className={styles.loading} active />
        ) : (
          <>
            {type !== IntegrationType.Claim &&
              [IntegrationStatus.Connected, IntegrationStatus.PendingAuth, IntegrationStatus.PendingMapping].includes(
                status
              ) && (
                <>
                  <Button
                    className={styles.connectButton}
                    variant="secondary"
                    disabled={type !== IntegrationType.Account}
                    data-tip
                    data-for={'disconnectButton'}
                    onClick={() => setShowDisconnectModal(true)}
                  >
                    Disconnect integration
                  </Button>
                  {type !== IntegrationType.Account ? (
                    <ReactTooltip id={'disconnectButton'} className={styles.tooltip} place={'bottom'}>
                      Please contact us to disconnect this integration
                    </ReactTooltip>
                  ) : (
                    <Button className={styles.dataMappingButton} onClick={() => setShowDataMappingModal(true)}>
                      Edit Data Mapping
                    </Button>
                  )}
                </>
              )}
            {status === IntegrationStatus.Disconnected && (
              <Button className={styles.connectButton} onClick={redirectToCodat} status={submitStatus}>
                Reconnect
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default IntegrationDetailsHeader;
