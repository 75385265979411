import React, { useState } from 'react';
import { Skeleton } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { clientRecordsInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import Button from 'components/v2/Button/Button';
import EventCreationModal from 'pages/Calendar/components/Modals/EventCreationModal/EventCreationModal';
import PatientInvoiceRulesModal from '../PatientDetailsContent/components/PatientDetailsInvoices/components/PatientInvoiceRulesModal/PatientInvoiceRulesModal';

import styles from './PatientDetailsHeader.module.scss';
import queryString from 'query-string';
import ClientProfileAvatar, { ClientProfileAvatarProps } from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { combineName } from 'utils/general';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { canClientReceiveSms } from '../../../../utils/helpers/checkClientCommunicationPreference';
import CORDSSendAssessmentModal from 'pages/Assessments/components/CORDSSendAssessmentModal/CORDSSendAssessmentModal';
import SubmitPsychometricsModal from '../PatientDetailsContent/components/SubmitPsychometricsModal/SubmitPsychometricsModal';

export interface PatientDetailsHeaderProps {
  currentTab: string;
  clientRecordId: string;
  clientRecordData: clientRecordsInterface;
  invoiceSettingsPaymentMethodsReady: boolean;
  isInvoiceSettingsPaymentMethodsReadyLoading: boolean;
  isClientRecordLoading: boolean;
  allowCreateNewInvoice: boolean;
  allowSubmitPsychometric: boolean;
  setClientRecordData: (clientRecordsInterface: clientRecordsInterface) => void;
}

const PatientDetailsHeader = ({
  currentTab,
  clientRecordId,
  clientRecordData,
  invoiceSettingsPaymentMethodsReady,
  isInvoiceSettingsPaymentMethodsReadyLoading,
  isClientRecordLoading,
  allowCreateNewInvoice,
  allowSubmitPsychometric,
  setClientRecordData
}: PatientDetailsHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { INVOICES, CLIENTS } = useRoutesGenerator();
  const [isCreateApptModalVisible, setIsCreateApptModalVisible] = useState(false);
  const [isSendAssessmentModalVisible, setIsSendAssessmentModalVisible] = useState(false);
  const [isPatientInvoiceRulesModalVisible, setIsPatientInvoiceRulesModalVisible] = useState(false);
  const queryParam: { page?: string } = queryString.parse(location.search);
  const [isAssessmentModalVisible, setIsAssessmentModalVisible] = useState(false);

  const handlePatientInvoiceRulesClose = (newInvoiceSettings: clientRecordsInterface['invoiceSummary']['settings']) => {
    clientRecordData &&
      newInvoiceSettings &&
      setClientRecordData({
        ...clientRecordData,
        invoiceSummary: {
          ...clientRecordData.invoiceSummary,
          settings: newInvoiceSettings
        }
      });
    setIsPatientInvoiceRulesModalVisible(false);
  };

  const handleBackBtn = () => {
    const qParam = queryString.stringify(queryParam);
    navigate(
      `${CLIENTS.BASE}${clientRecordData.recordStatus === 'closed' ? '/closed' : ''}${qParam ? `?${qParam}` : ''}`
    );
  };

  const [t] = useTranslation();

  const canReceiveSmsProfiles =
    !isClientRecordLoading &&
    clientRecordData.clientProfiles.some((client) => canClientReceiveSms(client.communicationPreference));

  const getAvatarsAndInitials = () => {
    let avatarList: ClientProfileAvatarProps[] = [];
    let nameList: string[] = [];
    const { clientProfiles } = clientRecordData;
    switch (clientRecordData.recordType) {
      case 'adult': {
        avatarList.push({
          avatarUrl: clientProfiles[0].avatar,
          initialsName: clientProfiles[0].initials
        });
        nameList.push(combineName(clientProfiles));
        break;
      }

      case 'child': {
        clientProfiles
          .filter((clientP) => clientP.role === 'child')
          .forEach((clientPr) => {
            avatarList.push({
              avatarUrl: clientPr.avatar,
              initialsName: clientPr.initials
            });
            nameList.push(combineName(clientProfiles.filter((clientPName) => clientPName.role === 'child')));
          });
        break;
      }

      case 'couple': {
        clientProfiles.forEach((clientP) => {
          avatarList.push({
            avatarUrl: clientP.avatar,
            initialsName: clientP.initials
          });
          nameList.push(clientP.firstName);
        });
        break;
      }

      default:
        break;
    }
    return { avatarList, nameList };
  };

  const renderAvatarAndName = () => {
    const { avatarList, nameList } = getAvatarsAndInitials();
    return (
      <div className={styles.avatarAndNameContainer}>
        <div className={styles.avatarSection}>
          {avatarList.map((item, index) => (
            <ClientProfileAvatar
              key={`avatar_${index}`}
              avatarUrl={item.avatarUrl}
              initialsName={item.initialsName}
              avatarClassName={styles.avatar}
              initialClassName={styles.initials}
            />
          ))}
        </div>
        <div className={styles.nameSection}>
          <span className={styles.profileName}>
            {nameList.map((name, index) => `${name} ${index < nameList.length - 1 ? '& ' : ''}`)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {!isClientRecordLoading && (
        <>
          <EventCreationModal
            visible={isCreateApptModalVisible}
            defaultClient={clientRecordData}
            onClose={() => setIsCreateApptModalVisible(false)}
            onCreateEditSuccess={() => {
              navigate(`?refetch`);
            }}
          />
          <PatientInvoiceRulesModal
            clientRecordData={clientRecordData}
            invoiceSettingsPaymentMethodsReady={invoiceSettingsPaymentMethodsReady}
            visible={isPatientInvoiceRulesModalVisible}
            onClose={handlePatientInvoiceRulesClose}
          />
        </>
      )}
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <div className={styles.titleWrapper}>
            {isClientRecordLoading ? (
              <div className={styles.title}>
                <Skeleton.Button active />
              </div>
            ) : (
              renderAvatarAndName()
            )}
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={() => handleBackBtn()}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                {t('header.view_all_clients')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          {!isClientRecordLoading &&
            clientRecordData.recordStatus !== RecordStatus.Closed &&
            allowSubmitPsychometric &&
            ['profile', 'assessments'].includes(currentTab) && (
              <Button onClick={() => setIsAssessmentModalVisible(true)}>Submit assessment</Button>
            )}
          {currentTab === 'invoices' && allowCreateNewInvoice && (
            <>
              <Button
                className={styles.invoiceSettingsButton}
                variant="secondary"
                disabled={
                  isInvoiceSettingsPaymentMethodsReadyLoading || clientRecordData.recordStatus === RecordStatus.Closed
                }
                onClick={() => setIsPatientInvoiceRulesModalVisible(true)}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>settings</i>
                {t('button.manage_client_invoice_rules')}
              </Button>
              <Link
                className={classNames(styles.createInvoiceButton, {
                  [styles.disabled]: clientRecordData.recordStatus === RecordStatus.Closed
                })}
                to={clientRecordData.recordStatus !== RecordStatus.Closed ? { pathname: INVOICES.NEW } : {}}
                state={
                  clientRecordData.recordStatus !== RecordStatus.Closed
                    ? { invoice: { clientRecord: { _id: clientRecordData?._id } } }
                    : {}
                }
              >
                <i className={`material-icons-outlined ${styles.icon}`}>receipt</i>
                Create New Invoice
              </Link>
            </>
          )}
          {currentTab === 'action-plans' && (
            <Link
              className={classNames(styles.createActionPlanButton, {
                [styles.disabled]: clientRecordData.recordStatus === RecordStatus.Closed
              })}
              to={`${CLIENTS.BASE}/${clientRecordId}/action-plans/new`}
            >
              Create New Action Plan
            </Link>
          )}
          {currentTab === 'assessments' && canReceiveSmsProfiles && (
            <>
              <CORDSSendAssessmentModal
                visible={isSendAssessmentModalVisible}
                onClose={() => setIsSendAssessmentModalVisible(false)}
                defaultSelectedStudents={[clientRecordData]}
              />
              <div>
                <Button onClick={() => setIsSendAssessmentModalVisible(true)}>
                  <i className={`material-icons-outlined ${styles.icon}`}>send</i>&nbsp; Send assessment
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <SubmitPsychometricsModal
        visible={isAssessmentModalVisible}
        clientRecordId={clientRecordId}
        onCloseModal={() => setIsAssessmentModalVisible(false)}
      />
    </>
  );
};

export default PatientDetailsHeader;
