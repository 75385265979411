import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import { Route, Routes } from 'react-router-dom';

import StudentListing from 'MHH/pages/Teacher/StudentListing/StudentListing';
import Profile from 'MHH/pages/Profile/Profile';
import { useCORDSTeacherRoutesGenerator } from 'MHH/utils/Path/CORDSRoutesGenerator';
import StudentDetails from 'MHH/pages/Teacher/StudentDetails/StudentDetails';

const TeacherRoutes = () => {
  const { STUDENT, PROFILE } = useCORDSTeacherRoutesGenerator();

  console.log(STUDENT.QUESTION, STUDENT.LISTING, PROFILE);

  return (
    <Routes>
      <Route path={STUDENT.QUESTION} element={<StudentDetails />} />
      <Route path={STUDENT.LISTING} element={<StudentListing />} />
      <Route path={PROFILE} element={<Profile />} />
      <Route path={'*'} element={<RedirectTo404 />} />
    </Routes>
  );
};

export default TeacherRoutes;
