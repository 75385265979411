import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { getActionPlans } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { ActionPlanInterface } from 'MHH/pages/ActionPlanCreator/interfaces';

export type ActionPlanResponse = ActionPlanInterface & {
  ownerProfile: { avatar: string; email: string; mobileNumber?: string; name: string };
};
export const useFetchActionPlans = (token: string, recordId: string) => {
  const [actionPlans, setActionPlans] = useState<ActionPlanResponse[]>([]);
  const [isActionPlanLoading, setIsActionPlanLoading] = useState(true);

  const fetchActionPlans = useCallback(
    async (token: string) => {
      try {
        const callActionPlans = await getActionPlans(token, recordId);

        const actionPlansData = await callActionPlans.json();

        if (actionPlansData && Array.isArray(actionPlansData)) {
          setActionPlans(actionPlansData);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get your action plans.' });
      }

      setIsActionPlanLoading(false);
    },
    [recordId]
  );

  useEffect(() => {
    if (token) {
      fetchActionPlans(token);
    }
  }, [token, fetchActionPlans]);

  return { actionPlans, isActionPlanLoading, setActionPlans, fetchActionPlans };
};
