import { InvoiceViewProps } from 'components/InvoiceView/InvoiceView';

import styles from './MedicareDetails.module.scss';

interface MedicareDetailsProps {
  medicare: Required<InvoiceViewProps>['medicare'];
}

const MedicareDetails = ({ medicare }: MedicareDetailsProps) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>Medicare Claimant Details</div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Name:</div>
            <div className={styles.value}>{medicare.claimant?.name || '-'}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Date of Birth:</div>
            <div className={styles.value}>{medicare.claimant?.dateOfBirth || '-'}</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Medicare Card No.:</div>
            <div className={styles.value}>{medicare.claimant?.medicareNumber || '-'}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Phone Number:</div>
            <div className={styles.value}>{medicare.claimant?.phoneNumber || '-'}</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>IRN:</div>
            <div className={styles.value}>{medicare.claimant?.irn || '-'}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Address:</div>
            <div className={styles.value}>{medicare.claimant?.address || '-'}</div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.title}>Referral Details</div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Referral Name:</div>
            <div className={styles.value}>{medicare.referral?.name || '-'}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Date of Referral:</div>
            <div className={styles.value}>{medicare.referral?.date || '-'}</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Provider Number:</div>
            <div className={styles.value}>{medicare.referral?.providerNumber || '-'}</div>
          </div>
          <div className={styles.field} />
        </div>
      </div>
      <div>
        <div className={styles.title}>Service Provider Details</div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Provider Name:</div>
            <div className={styles.value}>{medicare.serviceProvider?.name || '-'}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Provider Number:</div>
            <div className={styles.value}>{medicare.serviceProvider?.providerNumber || '-'}</div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.title}>MBS Claim Details</div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Date of Service:</div>
            <div className={styles.value}>{medicare.claim?.dateOfService || '-'}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>MBS Code:</div>
            <div className={styles.value}>{medicare.claim?.mbsCode || '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicareDetails;
