import classNames from 'classnames';
import styles from './PillButtonGroup.module.scss';

interface PillButtonGroupProps {
  options: {
    value: string;
    label: string;
  }[];
  value: string;
  onChange: (value: string) => void;
  compact?: boolean;
}

const PillButtonGroup = ({ options, value, onChange, compact = false }: PillButtonGroupProps) => {
  const handleClick = (option: string) => () => {
    onChange(option);
  };

  return (
    <div className={styles.container}>
      {options.map((option, index) => (
        <button
          key={index}
          className={classNames(styles.button, {
            [styles.active]: value === option.value,
            [styles.compact]: compact
          })}
          onClick={handleClick(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default PillButtonGroup;
