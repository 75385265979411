import styles from './WidgetsListTemplate.module.scss';

interface WidgetsTemplateProps {
  title?: string;
  onClickBackBtn: () => void;
  children?: any;
}

const WidgetsListTemplate = ({ title, onClickBackBtn, children }: WidgetsTemplateProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.insightsBack} onClick={onClickBackBtn}>
        <i className={`material-icons ${styles.backIcon}`}>keyboard_arrow_left</i>
        Back to all elements
      </div>
      {title && <div className={styles.insightsTitle}>{title}</div>}
      <div className={styles.insightsMenuContent}>{children}</div>
    </div>
  );
};

export default WidgetsListTemplate;
