import { MouseEvent } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { CaseNote } from 'interfaces/caseNote';

import styles from './PatientDetailsNotesListing.module.scss';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

interface PatientDetailsNotesListingProps {
  noteId?: string;
  notes: CaseNote[];
  isDisabled: boolean;
  onClickNote: (_id: string) => (e: MouseEvent) => void;
  searchValue: string;
}

const PatientDetailsNotesListing = ({
  noteId,
  notes,
  isDisabled,
  onClickNote,
  searchValue
}: PatientDetailsNotesListingProps) => {
  const { auth0ClinicianId } = useGetClinicianId();

  return (
    <div className={styles.container}>
      {notes.length > 0 ? (
        notes.map(({ _id, attachments, title, updatedAt, clinicianId, safeguardingAlert }) => (
          <div
            className={classnames(styles.noteItem, _id === noteId && styles.selected, isDisabled && styles.disabled)}
            key={_id}
            onClick={!isDisabled ? onClickNote(_id) : () => {}}
          >
            <div className={styles.noteTitle}>
              {title || 'New Note'}
              {clinicianId && clinicianId !== auth0ClinicianId && (
                <i className={`material-icons-outlined ${styles.icon}`}>lock</i>
              )}
            </div>
            <div className={styles.noteContent}>
              <div className={styles.noteDate}>{moment(updatedAt).format('DD/MM/YYYY hh:mm A')}</div>
              {attachments.length > 0 && (
                <div className={styles.noteAttachmentsCount}>
                  <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
                  {attachments.length}
                </div>
              )}
              {safeguardingAlert && (
                <div className={styles.safeguardingAlert}>
                  <i className={`material-icons ${styles.safeguardingAlertIcon}`}>report_problem</i>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <>{searchValue !== '' && <div className={styles.noValue}>No case note match {searchValue}</div>}</>
      )}
    </div>
  );
};

export default PatientDetailsNotesListing;
