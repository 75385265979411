import styles from './GroupPractitioner.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { useTranslation } from 'react-i18next';

interface GroupPractitionerProps {
  leadClinicianProfile: Groups['leadClinicianProfile'];
  clinicianProfiles: Groups['clinicianProfiles'];
}

const GroupPractitioner = ({ leadClinicianProfile, clinicianProfiles }: GroupPractitionerProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leadProfileWrapper}>
        <div className={styles.subTitle}>LEAD</div>
        <div className={styles.leadAvatarWrapper}>
          <img src={leadClinicianProfile?.avatar} className={styles.leadAvatarImg} alt={'lead profile'} />
        </div>
        <div className={styles.contactWrapper}>
          <div className={styles.name}>{`${leadClinicianProfile.title ?? ''} ${leadClinicianProfile.name}`}</div>
          {leadClinicianProfile.mobileNumber && (
            <div className={styles.contact}>
              <div>Mobile:&nbsp;</div>
              <div className={styles.value}>{leadClinicianProfile.mobileNumber}</div>
            </div>
          )}
          {leadClinicianProfile.email && (
            <div className={styles.contact}>
              <div>Email:&nbsp;</div>
              <div className={styles.value}>{leadClinicianProfile.email}</div>
            </div>
          )}
        </div>
      </div>
      {clinicianProfiles.length > 0 && (
        <div className={styles.practitionerWrapper}>
          <div className={styles.subTitle}>{t('label.practitioner').toUpperCase()}</div>
          {clinicianProfiles.map((cPObj, index) => (
            <div className={styles.practitionerInfo} key={index}>
              <div className={styles.practitionerAvatarWrapper}>
                <img
                  className={styles.practitionerAvatarImg}
                  src={cPObj.avatar}
                  alt={`practitioner profile ${index}`}
                />
              </div>
              <div className={styles.contactWrapper}>
                <div className={styles.name}>{`${cPObj.title} ${cPObj.name}`}</div>
                <div className={styles.contact}>
                  <div>Mobile:&nbsp;</div>
                  <div className={styles.value}>{cPObj.mobileNumber}</div>
                </div>
                <div className={styles.contact}>
                  <div>Email:&nbsp;</div>
                  <div className={styles.value}>{cPObj.email}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupPractitioner;
