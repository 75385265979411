import styles from './OnboardingContentView.module.scss';
import AssessmentsHeader from '../../../components/AssessmentsHeader/AssessmentsHeader';
import { FirstAssessmentStruct } from 'interfaces/firstAssessment';
import QuestionSectionView from './components/QuestionSectionView/QuestionSectionView';

interface OnboardingContentViewProps {
  data: FirstAssessmentStruct;
}

const OnboardingContentView = ({ data }: OnboardingContentViewProps) => {
  return (
    <div>
      <AssessmentsHeader title={'Survey'} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.leftWrapper}>
            <div className={styles.titleBox}>
              <div className={styles.title}>Name of survey</div>
              <div className={styles.value}>{data.name}</div>
            </div>
            <div className={styles.diffNameBox}>
              {!!data.diffName && (
                <>
                  <div className={styles.title}>Survey's name for clients</div>
                  <div className={styles.value}>{data.diffName}</div>
                </>
              )}
            </div>
            {!!data.description && (
              <div className={styles.descBox}>
                <div className={styles.title}>Short description of this assessment</div>
                <div className={styles.value}>{data.description}</div>
              </div>
            )}
            <div className={styles.title}>Tags</div>
            <div className={styles.tagBox}>
              {data.tags?.map((obj) => (
                <div className={styles.tagItem}>{obj}</div>
              ))}
            </div>
            {!!data.note && (
              <div className={styles.noteBox}>
                <div className={styles.title}>Intro note for recipient</div>
                <div className={`ql-editor ${styles.text}`} dangerouslySetInnerHTML={{ __html: data.note || '' }} />
              </div>
            )}
          </div>
          <div className={styles.rightWrapper}>
            <QuestionSectionView questionList={data.questionSets} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingContentView;
