import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';

import styles from './SearchBar.module.scss';

interface SearchBarProps {
  placeholder?: string;
  value?: string;
  onSearch: (searchValue: string) => void;
  withSearchButton?: boolean;
  label?: string;
  containerClassName?: string;
  searchOnChange?: boolean;
}

const SearchBar = ({
  placeholder,
  value,
  onSearch,
  withSearchButton,
  label,
  containerClassName,
  searchOnChange
}: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState(value || '');

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && (searchValue.length === 0 || searchValue.length > 2)) {
      onSearch(searchValue);
    }
  };

  const handleOnChange = (val: string) => {
    setSearchValue(val);
    if (searchOnChange) {
      onSearch(val);
    }
  };

  const handleOnSearch = () => {
    if (searchValue.length === 0 || searchValue.length > 2) {
      onSearch(searchValue);
    }
  };

  return (
    <div className={classNames(styles.container, containerClassName)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.searchBarContainer} id={'clientSearchBarId'}>
        <input
          className={withSearchButton ? styles.searchInputWithBtn : styles.searchInput}
          value={searchValue}
          placeholder={placeholder}
          onChange={(e) => handleOnChange(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {withSearchButton && (
          <div className={styles.searchButton} onClick={handleOnSearch}>
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
