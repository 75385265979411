import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './GroupsList.module.scss';
import GroupListHeader from './components/GroupListHeader/GroupListHeader';
import GroupListingContent from './components/GroupListContent/GroupListingContent';

import { groupListFilterParameter, GroupStatus } from '../Interfaces/Groups';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchGroupList } from './hooks/getGroupList';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import queryString from 'query-string';
import CreateGroupModal from 'components/Groups/CreateGroupModal/CreateGroupModal';

const GroupsList = () => {
  const { token } = useGetAccessToken();
  const { GROUPS } = useRoutesGenerator();
  const navigate = useNavigate();
  const path = useParams<{ tabId: GroupStatus }>();
  const location = useLocation();

  const queryParam: {
    q?: string;
  } = queryString.parse(location.search);

  const [showCreateNewGroup, setShowCreateNewGroup] = useState(false);

  const [filterStatus, setFilterStatus] = useState<GroupStatus>(path.tabId ?? GroupStatus.Active);

  const [pageLoading, setPageLoading] = useState(false);

  const filterValue: groupListFilterParameter = {
    status: filterStatus,
    q: queryParam.q || ''
  };

  const { groupList, isGroupListLoading, fetchGroupList } = useFetchGroupList(token);

  const onChangeTabFilter = async (tabValue: GroupStatus) => {
    if (tabValue !== filterStatus) {
      setPageLoading(true);
      setFilterStatus(tabValue);
      const newQParam = {
        ...(filterValue.q && {
          q: filterValue.q
        })
      };

      const qParam = queryString.stringify(newQParam);
      navigate(`${GROUPS.BASE}/${tabValue}${qParam && tabValue ? `?${qParam}` : ''}`);
      setPageLoading(false);
    }
  };

  const onHandleSearch = (q: string) => {
    navigate(`${GROUPS.BASE}/${filterStatus}${q ? `?q=${q}` : ''}`);
  };

  const onResetModal = () => {
    fetchGroupList(token);
    setShowCreateNewGroup(false);
  };

  return (
    <HelmetWrapper title={'CORDS - Groups'}>
      <ContentLayout>
        <div className={styles.container}>
          <GroupListHeader onCreateGroupClick={() => setShowCreateNewGroup(true)} />
          <GroupListingContent
            groupListing={groupList}
            isGroupListLoading={isGroupListLoading}
            onChangeTabFilter={onChangeTabFilter}
            onHandleSearch={onHandleSearch}
            isPageLoading={pageLoading}
            groupStatus={filterStatus}
            searchValue={filterValue.q}
          />
        </div>
        <CreateGroupModal
          visible={showCreateNewGroup}
          onReset={onResetModal}
          onCloseModal={() => setShowCreateNewGroup(false)}
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default GroupsList;
