import { ButtonHTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'danger' | 'clinician-primary';
}

const Button = ({ children, className, variant, ...props }: ButtonProps): ReactElement<ButtonProps> => {
  const buttonClasses = classnames(styles.button, variant && styles[variant], className);

  return (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  );
};

export default Button;
