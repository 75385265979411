import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { notification, Modal } from 'antd';

import { MicroCheckInAssessmentStruct } from 'pages/Assessments/Interface';

import { getMicroCheckInAssessments } from 'store/CheckIn/GetMCIAssessment/action';
import { getName } from 'utils/general';
import { canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { putAssignedMCIList } from 'utils/http/CheckInService/MicroCheckIn/mci';

import AddPatientModalV2 from 'components/AddPatientModalV2/AddPatientModalV2';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SubmitButton from 'components/v2/Button/Button';

import styles from './ClientAssignList.module.scss';
import { ClientAssignInterface, MCIClientSaveStruct, MCIClientStruct } from './components/interfaces';
import ClientAssignItem from './components/ClientAssignItem/ClientAssignItem';
import { useTranslation } from 'react-i18next';

export interface ClientAssignListProps {
  isEditMode: boolean;
  isShowModal: boolean;
  onCloseModal: () => void;
  clientListLoading: boolean;
  clientListData: ClientAssignInterface[];
  onSaveClientList: (newList: ClientAssignInterface[]) => void;
}

const ClientAssignList = ({
  isEditMode,
  isShowModal,
  onCloseModal,
  clientListLoading,
  clientListData,
  onSaveClientList
}: ClientAssignListProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [mCIAssessmentTemplate, setMCIAssessmentTemplate] = useState<MicroCheckInAssessmentStruct[]>([]);
  const [mCIClientList, setMCIClientList] = useState<ClientAssignInterface[]>(clientListData);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const [t] = useTranslation();

  const sortClient = (list: ClientAssignInterface[], sortAsc: boolean) => {
    return list.sort((a, b) => {
      const firstProfile = a.clientProfiles[0];
      const secondProfile = b.clientProfiles[0];

      const clientA = getName(firstProfile);
      const clientB = getName(secondProfile);

      return sortAsc ? clientA.localeCompare(clientB) : clientB.localeCompare(clientA);
    });
  };

  const fetchAssessment = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });
    setIsLoading(true);
    const callGetMCIAssessments = await getMicroCheckInAssessments(token);
    const mCIData = callGetMCIAssessments.filter((mci: { isPublished: boolean }) => mci.isPublished) as any;

    setMCIAssessmentTemplate(mCIData);

    setIsLoading(false);
  };

  useEffect(() => {
    if (isEditMode) {
      fetchAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    setMCIClientList(sortClient(clientListData, true));
  }, [clientListData]);

  const massageProfileData = (clientProfiles: MCIClientStruct[], profileId: string, questionId?: string) => {
    let newClientProfile = [] as MCIClientStruct[];
    clientProfiles.map((clientProfileObj) =>
      newClientProfile.push({
        ...clientProfileObj,
        microCheckInId: clientProfileObj._id === profileId ? questionId ?? null : clientProfileObj.microCheckInId
      })
    );

    return newClientProfile;
  };

  const onSelectProgram = (recordId: string, profileId: string, questionId?: string) => {
    let newClientRecord = [] as ClientAssignInterface[];
    mCIClientList.map((clientRecordObj) =>
      newClientRecord.push({
        ...clientRecordObj,
        clientProfiles: massageProfileData(clientRecordObj.clientProfiles, profileId, questionId)
      })
    );
    setMCIClientList(newClientRecord);
  };

  const massageRecordIdToProfile = (profileData: MCIClientStruct[], recordId: string) => {
    let newClientProfileWithRecordId = [] as MCIClientSaveStruct[];
    profileData
      .filter(
        ({ clientAuth0Id, communicationPreference }) => !!clientAuth0Id && canClientReceiveSms(communicationPreference)
      )
      .map((clientProfileObj) =>
        newClientProfileWithRecordId.push({
          clientRecordId: recordId,
          clientAuth0Id: clientProfileObj.clientAuth0Id ?? '',
          clientProfileId: clientProfileObj._id,
          microCheckInId: clientProfileObj.microCheckInId ?? null
        })
      );

    return newClientProfileWithRecordId;
  };

  const massageSaveData = (profileData: ClientAssignInterface[]) => {
    let newClientRecord = [] as any;
    profileData.map((clientRecord) =>
      newClientRecord.push(...massageRecordIdToProfile(clientRecord.clientProfiles, clientRecord._id))
    );

    return newClientRecord;
  };

  const saveClientData = async () => {
    if (mCIClientList.length > 0) {
      setSaveStatus('active');
      const massageClientProfile = massageSaveData(mCIClientList);

      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        await putAssignedMCIList(token, massageClientProfile);

        notification.success({
          message: t('form.success.update_client_client'),
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });

        setSaveStatus('finished');

        setTimeout(() => {
          setSaveStatus('');
          onSaveClientList(mCIClientList);
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setSaveStatus('');
      }
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.title}>{t('title.assign_client_to_checkin')}</div>}
      visible={isShowModal}
      onCancel={onCloseModal}
      footer={null}
    >
      <div className={styles.container}>
        {isLoading || clientListLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingCircle />
          </div>
        ) : (
          <>
            {mCIClientList.length > 0 ? (
              <>
                <div className={styles.headerWrapper}>
                  <div className={styles.label}>{t('label.select_client')}</div>
                  <SubmitButton
                    disabled={saveStatus !== ''}
                    status={saveStatus}
                    onClick={() => saveClientData()}
                    className={styles.saveBtn}
                  >
                    Save
                  </SubmitButton>
                </div>
                <div className={styles.clientList}>
                  {mCIClientList.map((clientAssign, index) => (
                    <div key={index}>
                      <ClientAssignItem
                        clientAssign={clientAssign}
                        mCIAssessmentTemplate={mCIAssessmentTemplate}
                        onSelectProgram={onSelectProgram}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className={styles.noClientContainer}>
                <div>{t('label.no_client_record_found_create_one')}</div>
                <SubmitButton
                  variant={'link'}
                  icon={'add'}
                  onClick={() => {
                    setShowAddClient(true);
                  }}
                >
                  {t('button.add_new_client')}
                </SubmitButton>
              </div>
            )}
          </>
        )}
        <AddPatientModalV2 visible={showAddClient} onCancel={() => setShowAddClient(false)} />
      </div>
    </Modal>
  );
};

export default ClientAssignList;
