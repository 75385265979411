import { useEffect, useMemo, useState } from 'react';

import { HeadingTemplate } from 'components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';
import { CaseNote } from 'interfaces/caseNote';

import LoadingButton from 'components/v2/Button/Button';
import WidgetTextEditor from 'components/WidgetTextEditor/WidgetTextEditor';

import styles from './PatientDetailsNoteDetailsForm.module.scss';
import CaseNoteHeaderOption from './components/CaseNoteHeaderOption/CaseNoteHeaderOption';
import CaseNoteHeaderImageBtn from './components/CaseNoteHeaderImageBtn/CaseNoteHeaderImageBtn';
import CaseNoteAttachmentBtn from './components/CaseNoteAttachmentBtn/CaseNoteAttachmentBtn';
import _, { debounce } from 'lodash';
import { safeGuardingName } from '../../../PatientDetailsNoteFormatSettingsModal/PatientDetailsNoteFormatSettingsModal';

interface PatientDetailsNoteDetailsFormProps {
  recordId: string;
  profileId: string;
  caseNoteHeadingTemplates: HeadingTemplate[];
  onDeleteTemplate: (headingTemplateId: string) => void;
  isSubmitting: boolean;
  onSubmitTemplate: (newHeadingTemplate: HeadingTemplate) => void;
  noteId: string;
  token: string;
  formData: CaseNote;
  saveButtonStatus: '' | 'active' | 'finished';
  onSubmit: any;
  onValueChange: (b: boolean) => void;
  safeguardingAlertExistInFormatSettings: boolean;
}

const PatientDetailsNoteDetailsForm = ({
  recordId,
  profileId,
  caseNoteHeadingTemplates,
  formData,
  isSubmitting,
  saveButtonStatus,
  onSubmit,
  noteId,
  token,
  onDeleteTemplate,
  onSubmitTemplate,
  onValueChange,
  safeguardingAlertExistInFormatSettings
}: PatientDetailsNoteDetailsFormProps) => {
  const { _id, ...caseNoteInitialData } = formData;
  const [caseNoteDetails, setCaseNoteDetails] = useState(caseNoteInitialData);
  const [caseNoteBody, setCaseNoteBody] = useState(caseNoteInitialData.body);
  const [isShowError, setIsShowError] = useState(false);
  const [startEditing, setIsStartEditing] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);

  useEffect(() => {
    if (noteId === 'new') {
      const { _id, ...caseNoteInitialData } = formData;
      setCaseNoteDetails(caseNoteInitialData);
      setCaseNoteBody(caseNoteInitialData.body);
    }
  }, [formData, noteId]);

  const debouncedSave = useMemo(
    () =>
      debounce(
        async (caseNote, startEditingVal) => {
          const safeguarding =
            !caseNote.safeguardingAlert || (caseNote.safeguardingAlert && !_.isEmpty(caseNote.safeguardingReason));

          if (
            validateField(caseNote) &&
            safeguarding &&
            caseNote.title.length > 0 &&
            !isSubmitting &&
            startEditingVal
          ) {
            await onSubmit(caseNote, true);
          }
        },
        noteId === 'new' ? 1000 : 2000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noteId, isSubmitting, onSubmit]
  );

  const onChangeCaseNoteBody = (value: string) => {
    setIsStartEditing(true);
    setCaseNoteBody(value);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: value
    };
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleTitleChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      title: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleSafeguardingAlertChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingAlert: val,
      safeguardingReason: val ? caseNoteDetails.safeguardingReason : '',
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleSafegaurdingReasonChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingReason: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleFieldChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      fields: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleAttachmentChange = async (attachmentVal: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: caseNoteBody,
      attachments: attachmentVal
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
    }
  };

  const handleAttachmentUploadStatus = (uploadStatus: boolean) => {
    setUploadStatus(uploadStatus);
  };

  const validateField = (caseNoteValue: Omit<CaseNote, '_id'>) => {
    const fieldWithMandatory = caseNoteValue.fields.filter((obj) => obj.mandatory && obj.name !== 'Safeguarding Alert');

    const checkFieldValue = fieldWithMandatory.filter((obj) =>
      obj.type !== 'multipleChoiceCount'
        ? !obj.value
        : obj.options.filter((countObj: any) => countObj.count > 0).length <= 0
    );

    const invalidFieldNumber = fieldWithMandatory.filter(
      (obj) => obj.type === 'number' && !obj.value?.match('^[0-9]*$')
    );

    const isSafeguardingMandatory =
      (safeguardingAlertExistInFormatSettings &&
        caseNoteValue.fields.find((obj) => obj.name === safeGuardingName)?.mandatory === true) ||
      false;

    const safeguardingValidate =
      ((isSafeguardingMandatory && caseNoteValue && typeof caseNoteValue.safeguardingAlert === 'boolean') ||
        !isSafeguardingMandatory) &&
      (!caseNoteValue.safeguardingAlert ||
        (caseNoteValue.safeguardingAlert && !_.isEmpty(caseNoteValue.safeguardingReason)));

    return (
      caseNoteValue.title.length > 0 &&
      safeguardingValidate &&
      checkFieldValue.length <= 0 &&
      invalidFieldNumber.length === 0
    );
  };

  const handleSubmit = () => {
    const checkMandatoryFill = validateField(caseNoteDetails);
    setIsShowError(!checkMandatoryFill);
    if (checkMandatoryFill && caseNoteDetails.title) {
      const caseNoteObj = {
        ...caseNoteDetails,
        body: caseNoteBody
      };
      onSubmit(caseNoteObj, false);
    }
  };

  return (
    <>
      <CaseNoteHeaderOption
        noteId={noteId}
        recordId={recordId}
        fieldsData={caseNoteDetails.fields}
        safeguardingAlert={caseNoteDetails.safeguardingAlert}
        safeguardingReason={caseNoteDetails.safeguardingReason}
        title={caseNoteDetails.title}
        onChangeFieldValue={handleFieldChange}
        onChangeTitle={handleTitleChange}
        onChangeSafeguardingAlert={handleSafeguardingAlertChange}
        onChangeSafeguardingReason={handleSafegaurdingReasonChange}
        isValidated={isShowError}
      />
      <div className={styles.editor}>
        <WidgetTextEditor
          className="case-notes-text-editor"
          containerClass={styles.textEditorContainer}
          value={caseNoteBody}
          onChangeValue={(value: string) => onChangeCaseNoteBody(value)}
          fullHeightEditor
        />
        <CaseNoteHeaderImageBtn
          recordId={recordId}
          profileId={profileId}
          caseNoteBodyVal={caseNoteBody}
          onChangeBody={onChangeCaseNoteBody}
          caseNoteHeadingTemplates={caseNoteHeadingTemplates}
          token={token}
          onDeleteTemplate={onDeleteTemplate}
          onSubmitTemplate={onSubmitTemplate}
        />
        <CaseNoteAttachmentBtn
          caseNoteAttachmentData={caseNoteDetails.attachments}
          token={token}
          recordId={recordId}
          uploadStatus={handleAttachmentUploadStatus}
          onChangeCaseNoteAttachment={handleAttachmentChange}
        />
      </div>
      <div className={styles.saveButtonContainer}>
        <LoadingButton
          className={styles.saveButton}
          type="submit"
          status={saveButtonStatus}
          disabled={uploadStatus || isSubmitting}
          onClick={!isSubmitting ? handleSubmit : undefined}
        >
          {uploadStatus ? 'Uploading...' : isSubmitting ? 'Saving...' : 'Save Note'}
        </LoadingButton>
      </div>
    </>
  );
};

export default PatientDetailsNoteDetailsForm;
