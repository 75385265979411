import styles from './SessionSettings.module.scss';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;

type SessionSettingsProps = {
  appointmentType: AppointmentType;
};

const SessionSettings = ({ appointmentType }: SessionSettingsProps) => {
  const { isEdgeUser } = useGetAccountPackageView();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();

  const showRate = () => {
    const { hidePublicRate, rate } = appointmentType;
    return hidePublicRate || isAppointmentRateReadDenied ? 'Private' : rate;
  };

  return (
    <div className={styles.container}>
      <div className={styles.symbolInfo}>
        {GAE_REGION === 'au' ? (
          <i className="material-icons-outlined">monetization_on</i>
        ) : (
          <span className="material-icons-outlined">£</span>
        )}
        <div className={styles.value}>{showRate()}</div>
      </div>
      <div className={styles.symbolInfo}>
        <i className="material-icons-outlined">schedule</i>
        <div className={styles.value}>{appointmentType.duration} minutes</div>
      </div>
      {isEdgeUser && (
        <div className={styles.symbolInfo}>
          <i className="material-icons-outlined">meeting_room</i>
          <div className={styles.value}>
            {appointmentType.roomSettings?.required || appointmentType.roomAny ? 'Yes' : 'No'}
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionSettings;
