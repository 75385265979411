import { ProfileTagsOption } from '../DropdownSearchable/interface';
import DropdownSearchable from '../DropdownSearchable/DropdownSearchable';
import styles from './ProfileTags.module.scss';
interface ReferralDocsDetailsProps {
  profileTags: ProfileTagsOption[];
  isReadOnly?: boolean;
  onSelectedTags: (tag: ProfileTagsOption) => void;
}
const ProfileTags = ({ profileTags, isReadOnly, onSelectedTags }: ReferralDocsDetailsProps) => {
  const renderSelectedTagItem = (tag: ProfileTagsOption, index: number) => {
    return (
      <div key={`tagsItem_${index}`} className={styles.tagItem}>
        {tag.label}
      </div>
    );
  };

  return (
    <div className={styles.propfileTagsContainer} id={`profileTagsId`}>
      {/* Title */}
      {!isReadOnly && profileTags.filter((tag) => tag.isSelected).length === 0 && (
        <div className={styles.title}>
          Profile Tags
          <div className={styles.subTitle}>Tag this profile to support your reporting</div>
        </div>
      )}

      {/* Selected Tags */}
      {profileTags.length > 0 && (
        <div className={styles.selectedTagsContainer}>
          {profileTags.filter((tag) => tag.isSelected).map((tag, index) => renderSelectedTagItem(tag, index))}
        </div>
      )}

      {/* Tags dropdown */}
      {!isReadOnly && (
        <div className={styles.tagsDropdownContainer}>
          <DropdownSearchable
            label={'Add Tag'}
            profileTags={profileTags}
            searchBoxPlaceholder={'Search here'}
            isMultipleChoice
            selectedOptionsProps={profileTags.filter((tag) => tag.isSelected)}
            onSelecteOptionProps={onSelectedTags}
            optionsListLabel={'Tag options'}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileTags;
