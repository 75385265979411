import classNames from 'classnames';
import styles from './ProfileBadge.module.scss';

interface ProfileBadgeProps {
  className?: string;
  name?: string;
  avatar?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  nameClassName?: string;
  noEllipsisName?: boolean;
}

const ProfileBadge = ({
  className,
  name,
  avatar,
  initials,
  initialsBackgroundColor,
  nameClassName,
  noEllipsisName
}: ProfileBadgeProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.avatarContainer} style={{ backgroundColor: initialsBackgroundColor }}>
        {avatar ? (
          <img className={styles.avatarImg} src={avatar} alt={name} />
        ) : initials ? (
          <div className={styles.initials}>{initials}</div>
        ) : (
          <i className={`material-icons ${styles.defaultLogo}`}>person</i>
        )}
      </div>
      <div className={classNames(noEllipsisName ? styles.name : styles.ellipsisName, nameClassName)}>{name}</div>
    </div>
  );
};

export default ProfileBadge;
