import { AppointmentTypeAvailabilities } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import moment, { Moment } from 'moment';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';

export const getNextAvailableDate = ({
  currentDate,
  appointmentTypeAvailabilities
}: {
  currentDate: Moment;
  appointmentTypeAvailabilities?: AppointmentTypeAvailabilities;
}) => {
  const currentDateString = currentDate.clone().day(7).format(MOMENTJS_FORMAT_DATE);
  const nextAvailableDate =
    appointmentTypeAvailabilities &&
    Object.entries(appointmentTypeAvailabilities.timeSlots).find(
      ([date, timeSlots]) =>
        date >= currentDateString && timeSlots.isAvailable && timeSlots.timeSlots.some(({ isAvailable }) => isAvailable)
    );

  return nextAvailableDate ? moment(nextAvailableDate[0], MOMENTJS_FORMAT_DATE) : undefined;
};
