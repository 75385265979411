import LoadingDot from 'components/LoadingDot/LoadingDot';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import Button from 'components/v2/Button/Button';
import { clientRecordsInterface, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { AssessmentRole, ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useFetchClinician } from 'utils/hooks/clinician';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import styles from './CORDSAssessmentStudentSelect.module.scss';

interface CORDSAssessmentStudentSelectProps {
  onSelect: () => void;
  selectedAssessment?: ClinicalAssessmentStruct;
  selectedStudents: clientRecordsInterface[];
  setSelectedStudents: (selectedStudents: clientRecordsInterface[]) => void;
}

const CORDSAssessmentStudentSelect = ({
  onSelect,
  selectedAssessment,
  selectedStudents,
  setSelectedStudents
}: CORDSAssessmentStudentSelectProps) => {
  const { auth0ClinicianId } = useGetClinicianId();
  const { clinician, isClinicianLoading } = useFetchClinician(auth0ClinicianId);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const assessmentRole = selectedAssessment?.metadata?.role;

  const filterStudentHasAssessor = (item: clientRecordsInterface) => !!item.assessorIds && item.assessorIds.length > 0;

  const filterStudentHasGuardianParentProfile = (item: clientRecordsInterface) =>
    !!item.clientProfiles.find((i) => i.role !== 'child' && i.isPrimaryContact);

  const studentListFilter =
    assessmentRole === AssessmentRole.Teacher
      ? filterStudentHasAssessor
      : assessmentRole === AssessmentRole.Parent
      ? filterStudentHasGuardianParentProfile
      : undefined;

  return isClinicianLoading ? (
    <LoadingDot />
  ) : (
    <div className={styles.wrapper}>
      <SelectClientOrGroup
        selectedClinician={isEdgeAdminView ? undefined : clinician}
        handleSelectClientRecord={(item) => {
          const isItemInTheSelectedStudents = !!selectedStudents.find((obj) => obj._id === item._id);

          let updatedSelectedStudents: clientRecordsInterface[] = [];
          if (isItemInTheSelectedStudents) {
            updatedSelectedStudents = selectedStudents.filter((i) => i._id !== item._id);
          } else {
            updatedSelectedStudents = [...selectedStudents, item];
          }
          setSelectedStudents(updatedSelectedStudents);
        }}
        filter={studentListFilter}
        isMulti
        descriptionRender={(val) => val.class || '--'}
        defaultRecordIds={selectedStudents.map((i) => i._id)}
        enableGroupSelection={false}
        communicationPreference={
          assessmentRole !== AssessmentRole.Teacher
            ? [CommunicationPreference.NoPreference, CommunicationPreference.SMS].join(',')
            : ''
        }
      />
      <div className={styles.footer}>
        <Button disabled={selectedStudents.length === 0} onClick={() => onSelect()}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CORDSAssessmentStudentSelect;
