import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FirstAssessmentResponse, FirstAssessmentsResponse } from 'interfaces/checkInService/firstAssessment';
import { getSurveyList } from 'utils/http/CheckInService/Assessment/firstAssessment';

export const useFetchGroupSurveyList = (token: string) => {
  const [surveyList, setSurveyList] = useState<FirstAssessmentResponse[]>([]);
  const [isSurveyListLoading, setIsSurveyListLoading] = useState(true);

  const fetchGroupSurveyList = useCallback(async (token: string) => {
    try {
      const getSurveyListData = await getSurveyList(token);
      const surveyListData: FirstAssessmentsResponse = await getSurveyListData.json();
      const massageActiveSurvey = surveyListData.firstAssessments.filter((surveyObj) => surveyObj.isPublished);
      setSurveyList(massageActiveSurvey);
      setIsSurveyListLoading(false);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get this survey list' });
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchGroupSurveyList(token);
    }
  }, [fetchGroupSurveyList, token]);

  return { surveyList, isSurveyListLoading, fetchGroupSurveyList };
};
