import {
  Eventcalendar,
  MbscEventcalendarView,
  MbscPageLoadingEvent,
  momentTimezone,
  setOptions
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import { notification } from 'antd';
import Button from 'components/v2/Button/Button';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import EventCreationModal from 'pages/Calendar/components/Modals/EventCreationModal/EventCreationModal';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getTimeZoneDateTime } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentById } from 'utils/http/ScheduleService/Appointments/Appointments';
import styles from './CalendarView.module.scss';
import CalendarEvent from './components/CalendarEvent/CalendarEvent';
import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import { useAppointmentEvents } from './hooks/useAppointmentEvents';

setOptions({
  theme: 'material',
  themeVariant: 'light'
});

export const FREE_BUSY_APPOINTMENT_TYPES = ['free', 'busy'];

type CalendarViewProps = {
  selectedFilters: CalendarFilterInterface[];
  timezone: string;
};

interface SelectedFilters {
  clinicianIds: string[];
  roomIds: string[];
}

const CalendarView = ({ selectedFilters, timezone }: CalendarViewProps) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>();
  const [view, setView] = useState<'week' | 'day'>('week');
  const [calendarDate, setCalendarDate] = useState<MbscPageLoadingEvent>();
  const [isEventCreationVisible, setIsEventCreationVisible] = useState(false);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedAppointmentGroup, setSelectedAppointmentGroup] = useState<number>(0);

  const { events, getAllAppointmentsByFilter, fetchAppointmentsWithDebounce } = useAppointmentEvents(
    selectedFilters,
    timezone,
    calendarDate
  );

  const { search } = useLocation();
  const { appointmentId }: { appointmentId?: string } = queryString.parse(search);

  //set today background color
  const todayBackground = useMemo(() => {
    const todayDate = new Date().toDateString();

    return {
      start: new Date(`${todayDate} 00:00:00`),
      end: new Date(`${todayDate} 23:59:59`),
      background: 'rgba(63, 82, 255, 0.05)'
    };
  }, []);

  const selectedAppointment = useMemo(() => {
    const { clinicianIds, roomIds } = selectedFilters.reduce(
      (acc, crr) => {
        const key: keyof SelectedFilters = !crr.isClinician ? 'roomIds' : 'clinicianIds';
        acc[key].push(crr._id);

        return acc;
      },
      { clinicianIds: [], roomIds: [] } as SelectedFilters
    );
    if (selectedAppointmentId) {
      return getAllAppointmentsByFilter({ roomIds, clinicianIds }).find(({ _id }) => _id === selectedAppointmentId);
    }
    return undefined;
  }, [selectedAppointmentId, getAllAppointmentsByFilter, selectedFilters]);

  momentTimezone.moment = momentTz;

  const onCreateEditSuccess = () => {
    fetchAppointmentsWithDebounce(true);
    handleCloseSetAppointmentModal();
  };

  const queryAppointment = useCallback(
    async (appointmentId: string) => {
      try {
        const appointmentResponse = await getAppointmentById(token, accountId, appointmentId);
        const appointment = await appointmentResponse.json();
        setSelectedCalendarDate(appointment.date);
        setSelectedAppointmentId(appointmentId);
        notification.success({
          message: 'Appointment info fetched.',
          closeIcon: <span className={'success'}>OK</span>
        });
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Failed to fetch appointment info.',
          duration: 2,
          closeIcon: <span className={'success'}>OK</span>
        });
      }
    },
    [accountId, token]
  );

  useEffect(() => {
    if (token && appointmentId) {
      queryAppointment(appointmentId);
    }
  }, [appointmentId, queryAppointment, token]);

  const onPageLoading = useCallback(async (event) => {
    setCalendarDate(event);
  }, []);

  const onEventClick = useCallback((event) => {
    let id = event?.event?.id;
    if (!_.isEmpty(id)) {
      id = id.split('_')[0];
      setSelectedAppointmentId(id);
      setSelectedAppointmentGroup(event?.event?.group);
    }
  }, []);

  const renderScheduleEvent = useCallback(
    (data) => {
      const renderGapEvent = (data: any) => {
        const eventPeriod = moment
          .duration(
            getTimeZoneDateTime(data.original.end, timezone).diff(getTimeZoneDateTime(data.original.start, timezone))
          )
          .asMinutes();
        const eventHeight = (eventPeriod * 2400) / 1440;
        const gapHeight = (data.original.gap * 2400) / 1440;
        const mainHeight = eventHeight + gapHeight;

        return (
          <div style={{ height: mainHeight }}>
            <div style={{ height: eventHeight }} className={styles.eventContainer}>
              <CalendarEvent data={data} />
            </div>
            <div style={{ height: gapHeight, top: eventHeight }} className={styles.gapContainer}>
              <div className={styles.gapMakeup} />
              <div className={styles.gapText}>GAP</div>
            </div>
          </div>
        );
      };
      return data && (data.original?.gap > 0 ? renderGapEvent(data) : <CalendarEvent data={data} />);
    },
    [timezone]
  );

  const onViewChange = (view: any) => {
    setView(view);
  };

  const handleCloseAppointmentInformationModal = () => {
    setSelectedAppointmentId('');
    setSelectedCalendarDate(undefined);
  };

  const renderHeader = useCallback(() => <CalendarHeader view={view} onViewChange={onViewChange} />, [view]);

  const viewData = useMemo<MbscEventcalendarView>(() => {
    return {
      schedule: {
        type: view,
        allDay: false,
        startDay: 1,
        endDay: 0,
        timeCellStep: 30
      }
    };
  }, [view]);

  const handleCloseSetAppointmentModal = () => {
    setIsEventCreationVisible(false);
  };

  const showBespokeEvent = (date: Date) => {
    setIsEventCreationVisible(true);
    setSelectedDate(date);
  };

  return (
    <>
      <Eventcalendar
        height="75vh"
        renderHeader={renderHeader}
        renderScheduleEvent={renderScheduleEvent}
        selectedDate={selectedCalendarDate}
        colors={[todayBackground]}
        data={events}
        view={viewData}
        onEventClick={onEventClick}
        onCellClick={(args) => showBespokeEvent(args.date)}
        onPageLoading={onPageLoading}
        dataTimezone={timezone}
        displayTimezone={timezone}
        timezonePlugin={momentTimezone}
      />
      <EventInformationModal
        visible={!!selectedAppointment}
        onClose={handleCloseAppointmentInformationModal}
        appointmentId={selectedAppointmentId}
        appointment={selectedAppointment}
        onEditComplete={() => fetchAppointmentsWithDebounce(true)}
        group={selectedAppointmentGroup}
      />
      <EventCreationModal
        date={selectedDate}
        visible={isEventCreationVisible}
        onClose={() => handleCloseSetAppointmentModal()}
        onCreateEditSuccess={onCreateEditSuccess}
      />
      <Button
        className={styles.actionButton}
        icon="add_circle_outline"
        onClick={() => {
          setIsEventCreationVisible(true);
        }}
      >
        Set Appointment
      </Button>
    </>
  );
};

export default CalendarView;
