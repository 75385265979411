import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';

import { useFetchAssessmentByRecordProfileId } from 'utils/hooks/GetAssessmentList/assessment';
import { useGetAccessToken } from 'utils/hooks/token';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import AssessmentCard from './components/AssessmentCard/AssessmentCard';

import styles from './AssessmentList.module.scss';
import { useTranslation } from 'react-i18next';

interface AssessmentListProps {
  recordId: string;
  selectedAssessments: Assessment[];
  onSelectAssessment: (assessment: Assessment) => void;
}

const AssessmentList = ({ recordId, selectedAssessments, onSelectAssessment }: AssessmentListProps) => {
  const { token } = useGetAccessToken();
  const { assessmentList, isLoadingAssessmentList } = useFetchAssessmentByRecordProfileId(token, recordId);
  const [t] = useTranslation();

  return isLoadingAssessmentList ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      {assessmentList.length > 0 ? (
        assessmentList.map((assessment, index) => (
          <AssessmentCard
            key={index}
            assessmentData={assessment}
            selected={selectedAssessments.map((assessment) => assessment._id).includes(assessment._id)}
            onClick={() => onSelectAssessment(assessment)}
          />
        ))
      ) : (
        <div>{t('label.no_client_assessment_data')}</div>
      )}
    </div>
  );
};

export default AssessmentList;
