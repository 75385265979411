export enum AssessmentType {
  Survey = 'survey',
  MicroCheckIn = 'microCheckIn'
}

export interface BackgroundQuestion {
  question: string;
  subQuestion?: string;
  isChecked: boolean;
}

export interface QuestionSetStruct {
  id?: string;
  sectionName: string;
  questions: QuestionStruct[];
}

export interface QuestionStruct {
  id?: string;
  isChecked: boolean;
  isDraftQ?: boolean;
  stem?: string;
}

export enum AssessmentRole {
  Student = 'student',
  Parent = 'parent',
  Teacher = 'teacher',
  Client = 'client'
}

export interface ClinicalAssessmentStruct {
  description?: string;
  title?: string;
  id: string;
  shortCode: string;
  isChecked: boolean;
  metadata?: {
    group?: string;
    role?: AssessmentRole;
    sdqSequence?: number;
    cords?: {
      shortTitle: string;
      isCordsAssessment: boolean;
    };
  };
}

export interface FirstAssessmentStruct {
  id?: string;
  name: string;
  diffName?: string;
  tags?: string[];
  description?: string;
  note?: string;
  isPublished: boolean;
  isDefault: boolean;
  questionSets: QuestionSetStruct[];
  backgroundQuestions: BackgroundQuestion[];
  createdAt: Date;
  updatedAt?: Date;
}

export interface MicroCheckInAssessmentStruct {
  id: string;
  name: string;
  description: string;
  isPublished: boolean;
  isDefault: boolean;
  assessmentType: AssessmentType.MicroCheckIn;
  questionSet: QuestionSetStruct;
  createdAt: string;
  updatedAt: string;
}
