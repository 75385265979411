import { WidgetPreviewWrapper } from './components/WidgetPreviewWrapper/WidgetPreviewWrapper';
import styles from './DndPreviewSection.module.scss';
import JournalCommentWidget from '../../../../../WidgetComponents/JournalCommentWidget/JournalCommentWidget';
import NotesWidget from '../../../../../WidgetComponents/NotesWidget/NotesWidget';
import AssessmentWidget from '../../../../../WidgetComponents/AssessmentWidget/AssessmentWidget';
import InsightsChartWidget from '../../../../../WidgetComponents/InsightsChartWidget/InsightsChartWidget';
import BarLineChartWidget from '../../../../../WidgetComponents/BarLineChartWidget/BarLineChartWidget';
import HeadingWidget from '../../../../../WidgetComponents/HeadingWidget/HeadingWidget';
import BodyTextWidget from '../../../../../WidgetComponents/BodyTextWidget/BodyTextWidget';
import FootNotesTextWidget from '../../../../../WidgetComponents/FootNotesTextWidget/FootNotesTextWidget';
import { ReportWidgetType } from 'interfaces/Reports/report';
import ClientProfileWidget from '../../../../../WidgetComponents/ClientProfileWidget/ClientProfileWidget';
import QuestionWidget from '../../../../../WidgetComponents/QuestionWidget/QuestionWidget';

interface DndPreviewSectionProps {
  reportItems: any;
  dndDimension: any;
  templatePreview?: boolean;
}

const DndPreviewSection = ({ reportItems, dndDimension, templatePreview }: DndPreviewSectionProps) => {
  return (
    <div
      className={styles.container}
      style={{
        height: dndDimension.height || 900,
        width: dndDimension.width || 840
      }}
    >
      {reportItems.map((itemObj: any, index: any) => {
        const widgetType = itemObj.details.type;
        const leftPos = itemObj.position.left;
        const topPos = itemObj.position.top;

        if (widgetType === ReportWidgetType.HeadingWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <HeadingWidget id={itemObj.id} data={itemObj.details} previewMode />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.BodyTextWidget || widgetType === ReportWidgetType.BodyTextTemplateWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <BodyTextWidget id={itemObj.id} data={itemObj.details} previewMode />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.FootNotesTextWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <FootNotesTextWidget id={itemObj.id} data={itemObj.details} previewMode />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.JournalCommentWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <JournalCommentWidget data={itemObj.details} />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.InsightsChartWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <InsightsChartWidget data={itemObj.details} isPreview />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.BarLineChartWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <BarLineChartWidget data={itemObj.details} isPreview />
            </WidgetPreviewWrapper>
          );
        }

        if (
          widgetType === ReportWidgetType.AssessmentWidget ||
          widgetType === ReportWidgetType.AssessmentTemplateWidget
        ) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <AssessmentWidget assessmentData={itemObj.details} />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.ClientProfileWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <ClientProfileWidget id={itemObj.id} data={itemObj.details} previewMode />
            </WidgetPreviewWrapper>
          );
        }

        if (widgetType === ReportWidgetType.CaseNoteQuestionWidget) {
          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <QuestionWidget
                id={itemObj.id}
                data={itemObj.details}
                viewMode={templatePreview ? 'preview' : 'display'}
              />
            </WidgetPreviewWrapper>
          );
        }

        return (
          <WidgetPreviewWrapper
            key={index}
            position={{
              top: topPos,
              left: leftPos
            }}
            dimensions={{
              height: itemObj.dimensions.height,
              width: itemObj.dimensions.width,
              minWidth: 200,
              maxWidth: 840
            }}
          >
            <NotesWidget data={itemObj.details} isPreview />
          </WidgetPreviewWrapper>
        );
      })}
    </div>
  );
};

export default DndPreviewSection;
