import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import './index.scss';

import 'i18n';

import App from 'App';

const SENTRY_HOST = process.env.REACT_APP_SENTRY_HOST;

const sentryBrowserTracing = new Integrations.BrowserTracing();
const sentryCaptureConsole = new CaptureConsole({ levels: ['error'] });

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_HOST,
    integrations: [sentryBrowserTracing, sentryCaptureConsole],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
