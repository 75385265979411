import { useEffect, useState } from 'react';
import HelpOutLineWithTooltips from '../HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import moment, { Moment } from 'moment';
import Radio from '../Radio/Radio';
import { DatePicker } from 'antd';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { inviteTimeInterface } from './InviteTimeInterface';

import styles from './InviteTimeForm.module.scss';

interface InviteTimeFormProps {
  inviteTimeForm: inviteTimeInterface;
  checkValidation: boolean;
  onChangeInviteField: any;
  title: string;
  tooltipsMessage: string;
  disabled?: boolean;
}

const now = moment().format('HH:mm');
const TIME_OPTION = [
  { label: 'Now', value: 'now' },
  { label: `${now < '09:00' ? 'Today' : 'Tomorrow morning'} at 9AM`, value: '9' },
  { label: `${now < '13:00' ? 'Today' : 'Tomorrow afternoon'} at 1PM`, value: '13' },
  { label: 'Custom schedule', value: 'custom' }
];

const InviteTimeForm = ({
  inviteTimeForm,
  checkValidation,
  onChangeInviteField,
  title,
  tooltipsMessage,
  disabled
}: InviteTimeFormProps) => {
  const [inviteField, setInviteField] = useState(inviteTimeForm);
  const [isShowError, setShowError] = useState(checkValidation);

  useEffect(() => {
    if (checkValidation) {
      setShowError(inviteField.sendAt === 'custom' && !inviteField.customSendTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const onChangeSendTime = (val: string) => {
    const newInviteField = {
      ...inviteField,
      sendAt: val
    };
    setInviteField(newInviteField);
    onChangeInviteField(newInviteField);
  };

  const handleCustomSendTimeChange = (value: Moment | null) => {
    const newInviteField = {
      ...inviteField,
      customSendTime: value ? value.format('YYYY-MM-DD HH:mm') : ''
    };
    setShowError(newInviteField.sendAt === 'custom' && !newInviteField.customSendTime);
    setInviteField(newInviteField);
    onChangeInviteField(newInviteField);
  };

  return (
    <>
      {title && title.length > 0 && (
        <div className={styles.title}>
          {title}
          <HelpOutLineWithTooltips id={'inviteSendTimeToolTips'} desc={tooltipsMessage} />
        </div>
      )}
      <div className={styles.radioContainer}>
        <Radio
          disabled={disabled}
          className={styles.radioContainer}
          options={TIME_OPTION}
          value={inviteField.sendAt}
          vertical
          onChange={(e) => onChangeSendTime(e.target.value)}
        />
        {inviteField.sendAt === 'custom' && (
          <>
            <DatePicker
              disabled={disabled}
              className="send-invoice-date-picker"
              disabledDate={(current) => current && current < moment().startOf('day')}
              suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
              showTime={{ format: 'HH:mm', minuteStep: 15 }}
              value={inviteField.customSendTime ? moment(inviteField.customSendTime, 'YYYY-MM-DD HH:mm') : undefined}
              onChange={(e) => handleCustomSendTimeChange(e)}
            />
            <ErrorMessage className={styles.customTimeError} error={'Please select time'} visible={isShowError} />
          </>
        )}
      </div>
    </>
  );
};

export default InviteTimeForm;
