import UKTerms from './UKTerms/UKTerms';
import AUTerms from './AUTerms/AUTerms';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {

  return (
    <div className={styles.container}>
      {process.env.REACT_APP_GAE_REGION === 'au' ? <AUTerms /> : <UKTerms />}
    </div>
  );
};

export default TermsAndConditions;
