import { ClinicianQuestionResponsesWithMinifiedClientRecord } from 'pages/AssessorAssessments/hooks/useGetPaginatedAssessorAssessments';
import styles from './ClinicalAssessmentLists.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import { QuestionResponses } from 'pages/AssessorAssessments/types';

interface Props {
  assessments: ClinicianQuestionResponsesWithMinifiedClientRecord[];
  handleCompleteAssessment: (assessment: QuestionResponses) => void;
}

export const ClinicalAssessmentLists = ({ assessments, handleCompleteAssessment }: Props) => {
  if (assessments.length === 0) {
    return <div>No assessment found.</div>;
  }

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th className={styles.nameColumn}>NAME</th>
            <th className={styles.classColumn}>CLASS</th>
            <th className={styles.assessmentColumn}>PENDING ASSESSMENTS</th>
            <th className={styles.dateColumn}>DATE REQUESTED</th>
            <th className={styles.submitColumn} />
            <th />
          </tr>
        </thead>
        <tbody>
          {assessments.map((data) => (
            <tr key={data._id}>
              <td className={styles.nameColumn}>
                {`${data.clientRecord?.clientProfiles[0]?.firstName}${
                  data.clientRecord?.clientProfiles[0]?.lastName
                    ? ` ${data.clientRecord?.clientProfiles[0]?.lastName}`
                    : ''
                }` || '-'}
              </td>
              <td className={styles.classColumn}>{data.clientRecord?.class || '-'}</td>
              <td className={styles.assessmentColumn}>{data.assessment.clinicalAssessment?.id || '-'}</td>
              <td className={styles.dateColumn}>{moment(data.createdAt).format('DD/MM/yyyy')}</td>
              <td className={classNames(styles.submitColumn, styles.submitContainer)}>
                {data.status === 'pending' && (
                  <Button variant="primary" type="button" onClick={() => handleCompleteAssessment(data)}>
                    Complete Assessment
                  </Button>
                )}
                {data.status === 'done' && (
                  <div className={styles.checkContainer}>
                    <i className={`material-icons ${styles.icon}`}>check_circle</i>
                    <div>
                      <div>Submitted On</div>
                      <div className={styles.dateText}>{moment(data.updatedAt).format('DD/MM/yyyy')}</div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
