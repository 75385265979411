import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from './GroupDetailsHeader.module.scss';

import Button from 'components/v2/Button/Button';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { GroupDetailsTab, Groups, GroupStatus } from '../../../Interfaces/Groups';
import { Skeleton } from 'antd';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import AddGroupMembers from '../GroupContent/components/AddGroupMembers/AddGroupMembers';
import SendSurveyModal from '../GroupContent/components/SurveyForms/components/SendSurveyModal/SendSurveyModal';
import AddStageModal from '../GroupContent/components/PathwayManagement/components/StageForm/AddStageModal/AddStageModal';
import SendConsentFormsModal from '../GroupContent/components/ConsentForms/components/SendConsentFormsModal/SendConsentFormsModal';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import CORDSSendGroupAssessmentModal from 'pages/Assessments/components/CORDSSendAssessmentModal/CORDSSendGroupAssessmentModal';
import { GroupType } from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';
import { UserContext } from 'utils/UserContextProvider';

export interface GroupDetailsHeaderProps {
  groupDetails?: Groups;
  isGroupDetailsLoading: boolean;
  currentGroupTab: GroupDetailsTab;
  refetchPsychometricList: () => void;
  refetchGroupSurveyResponseList: () => void;
}

const GroupDetailsHeader = ({
  groupDetails,
  isGroupDetailsLoading,
  currentGroupTab,
  refetchGroupSurveyResponseList
}: GroupDetailsHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth0ClinicianId } = useGetClinicianId();
  const { GROUPS } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const { account } = useContext(UserContext);

  const queryParam: { q?: string } = queryString.parse(location.search);
  const groupId = useParams<{ groupId: string }>();
  const qParam = queryString.stringify(queryParam);
  const [visibleAddMember, setVisibleAddMember] = useState<boolean>(false);
  const [visibleSendAssessment, setVisibleSendAssessment] = useState<boolean>(false);
  const [visibleSendSurveyForms, setVisibleSendSurveyForms] = useState<boolean>(false);
  const [visibleAddNewStage, setVisibleAddNewStage] = useState<boolean>(false);
  const [visibleSendConsentForms, setVisibleSendConsentForms] = useState<boolean>(false);
  const notEditable =
    account.misSchool?.status === 'connected' &&
    groupDetails &&
    [GroupType.Registration, GroupType.Year].includes(groupDetails.type);

  useEffect(() => {
    localStorage.setItem('groupListSearchValue', qParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // no want to have dep in purpose, no want to keep setItem for everytime change path

  const handleBackBtn = () => {
    const groupListSearchValue = localStorage.getItem('groupListSearchValue');
    const status = groupDetails?.status ?? GroupStatus.Active;
    navigate(`${GROUPS.BASE}/${status}${groupListSearchValue ? `?${groupListSearchValue}` : ''}`);
  };

  // const handleCancel = () => {
  //   setVisibleSendAssessment(false);
  // };

  const isGroupLeader = auth0ClinicianId === groupDetails?.leadClinicianAuth0Id;

  const getRightHeaderComponent = () => {
    switch (currentGroupTab) {
      case GroupDetailsTab.Members:
        return (
          isEdgeAdminView && (
            <>
              {!notEditable && (
                <Button onClick={() => setVisibleAddMember(true)}>
                  <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                  Add New Member
                </Button>
              )}
              <AddGroupMembers
                title={groupDetails?.name || ''}
                showModal={visibleAddMember}
                setShowModal={(value) => setVisibleAddMember(value)}
              />
            </>
          )
        );
      case GroupDetailsTab.Psychometrics:
        return (
          (isEdgeAdminView || isEdgeUserView) && (
            <>
              <Button onClick={() => setVisibleSendAssessment(true)}>
                <i className={`material-icons ${styles.icon}`}>send</i>
                Send Assessment
              </Button>
              {!isGroupDetailsLoading && (
                <CORDSSendGroupAssessmentModal
                  visible={visibleSendAssessment}
                  onClose={() => setVisibleSendAssessment(false)}
                  defaultGroup={groupDetails}
                />
              )}
            </>
          )
        );
      case GroupDetailsTab.Survey:
        return (
          <>
            <Button onClick={() => setVisibleSendSurveyForms(true)}>
              <i className={`material-icons ${styles.icon}`}>send</i>
              Send Forms
            </Button>
            <SendSurveyModal
              groupId={groupId.groupId!}
              showModal={visibleSendSurveyForms}
              handleCancel={() => {
                setVisibleSendSurveyForms(false);
              }}
              handleSuccess={refetchGroupSurveyResponseList}
            />
          </>
        );
      case GroupDetailsTab.PathwayManagement:
        return (
          (isEdgeAdminView || isGroupLeader) && (
            <>
              <Button onClick={() => setVisibleAddNewStage(true)}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                Add New Stage
              </Button>
              <AddStageModal
                groupId={groupId.groupId!}
                showModal={visibleAddNewStage}
                setShowModal={() => setVisibleAddNewStage(false)}
              />
            </>
          )
        );
      case GroupDetailsTab.Consent:
        return (
          <>
            <Button onClick={() => setVisibleSendConsentForms(true)}>
              <i className={`material-icons ${styles.icon}`}>send</i>
              Send Consent Forms
            </Button>

            <SendConsentFormsModal
              groupId={groupId.groupId!}
              showModal={visibleSendConsentForms}
              handleCancel={() => setVisibleSendConsentForms(false)}
            />
          </>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftWrapper}>
        {isGroupDetailsLoading ? (
          <div className={styles.loadingWrapper}>
            <Skeleton.Input active className={styles.loading} />
          </div>
        ) : (
          <div className={styles.title}>{groupDetails?.name}</div>
        )}
        <Button variant={'link'} className={styles.backBtn} onClick={() => handleBackBtn()}>
          <i className={`material-icons ${styles.backIcon}`}>arrow_back_ios</i>
          View all groups
        </Button>
      </div>
      <div className={styles.rightWrapper}>{getRightHeaderComponent()}</div>
    </div>
  );
};

export default GroupDetailsHeader;
