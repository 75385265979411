import { useEffect, useState } from 'react';
import styles from './SortBtn.module.scss';

export enum SortType {
  asc = 1,
  desc = -1,
  noSort = 0
}

interface SortBtnProps {
  id?: string;
  selectedSortName?: SortType;
  onChangeSort: (val: SortType, columnName?: string) => void;
  disabled?: boolean;
  selectedColumn?: string;
  columnName?: string;
  filledArrow?: boolean;
  showOnlyActiveArrow?: boolean;
  onlyVisibleForSelectedColumn?: boolean;
}

const SortBtn = ({
  id,
  onChangeSort,
  selectedSortName = 0,
  disabled,
  selectedColumn,
  columnName,
  filledArrow,
  showOnlyActiveArrow,
  onlyVisibleForSelectedColumn = true
}: SortBtnProps) => {
  const [isAscSort, setIsAscSort] = useState<SortType>(selectedSortName);

  useEffect(() => {
    setIsAscSort(selectedSortName);
  }, [selectedSortName]);

  const handleSortBtn = (sortVal: SortType) => {
    setIsAscSort(sortVal);
    onChangeSort(sortVal, columnName);
  };

  const visible = columnName === selectedColumn ? true : !onlyVisibleForSelectedColumn;

  return (
    <div
      id={id}
      className={styles.container}
      onClick={() => (!disabled ? handleSortBtn(isAscSort === SortType.asc ? SortType.desc : SortType.asc) : false)}
    >
      {visible && (
        <>
          {!(showOnlyActiveArrow && isAscSort !== SortType.desc) && (
            <i
              className={`material-icons ${
                columnName === selectedColumn && isAscSort === SortType.desc ? styles.sortIconActive : styles.sortIcon
              }`}
            >
              {filledArrow ? 'arrow_drop_up' : 'keyboard_arrow_up'}
            </i>
          )}
          {!(showOnlyActiveArrow && isAscSort === SortType.desc) && (
            <i
              className={`material-icons ${
                columnName === selectedColumn && isAscSort === SortType.asc ? styles.sortIconActive : styles.sortIcon
              }`}
            >
              {filledArrow ? 'arrow_drop_down' : 'keyboard_arrow_down'}
            </i>
          )}
        </>
      )}
    </div>
  );
};

export default SortBtn;
