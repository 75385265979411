import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

import { getFirstAssessmentDetails } from 'store/CheckIn/GetFirstAssessmentDetails/action';
import { getFirstAssessmentQuestionTemplate } from 'store/CheckIn/GetFirstAssessmentQuestionTemplate/action';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import AssessmentsDetailsContent from './components/AssessmentsDetailsContent/AssessmentsDetailsContent';

import styles from './OnBoardingDetails.module.scss';
import { BackgroundQuestion, FirstAssessmentStruct, QuestionSetStruct } from 'interfaces/firstAssessment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import OnboardingContentView from './components/OnboardingContentView/OnboardingContentView';

export const NEW_ASSESSMENT_TEMPORARY_ID = 'newFa';

const initQuestion: BackgroundQuestion[] = [
  {
    question: "Please provide a brief summary of what you'd like to work on",
    isChecked: true
  },
  {
    question: 'Have you been referred by a GP or other provider?',
    subQuestion: 'If YES then asked to include relevant details + digital copy of referral',
    isChecked: true
  },
  {
    question: 'Have you worked with a psychologist or therapist before?',
    isChecked: true
  },
  {
    question: 'Are you taking any prescription medication?',
    subQuestion: 'If YES then asked to include relevant details',
    isChecked: true
  }
];

const OnBoardingDetails = () => {
  const path = useParams() as { assessmentId: string };
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setLoading] = useState(true);
  const [assessmentData, setAssessmentData] = useState<FirstAssessmentStruct>();
  const [questionTemplate, setQuestionTemplate] = useState<QuestionSetStruct[]>();

  const { isEdgeUserView, isNormalUserView, isEdgeAdminView } = useGetAccountPackageView();

  const fetchAssessment = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    let callGetFADetails: FirstAssessmentStruct;

    if (path.assessmentId === NEW_ASSESSMENT_TEMPORARY_ID) {
      callGetFADetails = {
        id: NEW_ASSESSMENT_TEMPORARY_ID,
        name: '',
        description: '',
        isDefault: false,
        isPublished: false,
        questionSets: [],
        backgroundQuestions: initQuestion,
        createdAt: new Date(),
        updatedAt: new Date()
      };
    } else {
      callGetFADetails = await getFirstAssessmentDetails(token, path.assessmentId);
    }

    let callGetFAQuestionTemplate: Pick<FirstAssessmentStruct, 'questionSets'>;

    if (isEdgeAdminView || isNormalUserView) {
      callGetFAQuestionTemplate = await getFirstAssessmentQuestionTemplate(token);

      if (callGetFAQuestionTemplate) {
        setQuestionTemplate(callGetFAQuestionTemplate.questionSets);
      } else {
        setQuestionTemplate([]);
      }
    } else {
      setQuestionTemplate([]);
    }

    if (callGetFADetails) {
      setAssessmentData(callGetFADetails);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);
  return (
    <HelmetWrapper title={'CORDS - Surveys'}>
      <ContentLayout>
        {!isLoading && assessmentData ? (
          <div className={styles.container}>
            {isEdgeUserView ? (
              <OnboardingContentView data={assessmentData} />
            ) : (
              <AssessmentsDetailsContent
                data={assessmentData}
                isEdit={path.assessmentId !== NEW_ASSESSMENT_TEMPORARY_ID}
                qTemplateData={questionTemplate}
                readOnly={isEdgeUserView}
              />
            )}
          </div>
        ) : (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default OnBoardingDetails;
