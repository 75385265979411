import styles from './ExpandableContent.module.scss';

interface ExpandableContentProps {
  expanded: boolean;
  minHeight?: boolean;
  className?: string;
  children: any;
}

const ExpandableContent = ({ expanded, children, minHeight, className }: ExpandableContentProps) => {
  const hideAllContent = expanded ? styles.showContent : styles.hideContent;
  const hideWithHeight = expanded ? styles.showContent : styles.hideMinHeightContent;
  return <div className={`${className} ${minHeight ? hideWithHeight : hideAllContent}`}>{children}</div>;
};

export default ExpandableContent;
