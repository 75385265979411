import { useState } from 'react';
import { Modal, notification } from 'antd';

import styles from './AddPractitionerModal.module.scss';
import ModalHeader from './components/ModalHeader/ModalHeader';
import { PractitionerInviteInterface, PractitionerProfiles } from './AddPractitionerModalInterface';
import Button from '../v2/Button/Button';
import PractitionerForm from './components/PractitionerForm/PractitionerForm';
import { scrollToView } from '../../utils/scrollToView';
import InviteComplete from './components/InviteComplete/InviteComplete';
import { postPractitionerInvite } from '../../utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccessToken } from '../../utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { PRACTITIONER_DEFAULT_ROLES } from './components/PractitionerForm/constants';
import { useTranslation } from 'react-i18next';

interface AddPatientModalV2Props {
  onCancel?: () => void;
  onResetForm?: () => void;
  visible: boolean;
}

const initAddPractitioner = {
  role: PRACTITIONER_DEFAULT_ROLES,
  emails: []
} as PractitionerProfiles;

const AddPractitionerModal = ({ onResetForm = () => {}, visible, ...props }: AddPatientModalV2Props) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [practitionerFormData, setPractitionerFormData] = useState(initAddPractitioner);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [formCompleted, setFormCompleted] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [isSubmitChecking, setSubmitChecking] = useState(false);
  const [invitedClient, setInvitedClient] = useState<PractitionerInviteInterface>({
    emailsSent: [],
    emailsRegistered: []
  });
  const [t] = useTranslation();

  const handleEmailField = (emails: PractitionerProfiles['emails']) => {
    setPractitionerFormData({
      ...practitionerFormData,
      emails
    });
  };

  const handleChangeRole = (role: PractitionerProfiles['role']) => {
    setPractitionerFormData({
      ...practitionerFormData,
      role
    });
  };

  const handleSubmit = async () => {
    setPageSubmitStatus('active');
    setSubmitChecking(true);
    if (practitionerFormData.emails.length > 0 && emailValue.length === 0) {
      try {
        const getResponse = await postPractitionerInvite(token, accountId, practitionerFormData);
        const resInvitedData = await getResponse.json();
        setInvitedClient(resInvitedData);

        setTimeout(() => {
          setPageSubmitStatus('finished');
        }, 500);
        setTimeout(() => {
          setFormCompleted(true);
          scrollToView('modalHeader', true);
          notification.success({
            message: t('form.success.practitioner_invitation_sent'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setPageSubmitStatus('');
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({
          message: t('form.error.failed_to_invite_practitioner'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    } else {
      setPageSubmitStatus('');
      setTimeout(() => {
        setSubmitChecking(false);
      }, 50);
    }
  };

  const handleResetForm = () => {
    setPageSubmitStatus('');
    setPractitionerFormData({
      role: PRACTITIONER_DEFAULT_ROLES,
      emails: []
    });
    setFormCompleted(false);
    onResetForm();
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<ModalHeader completePage={formCompleted} />}
      visible={visible}
      {...props}
      footer={null}
    >
      <div className={styles.container}>
        {!formCompleted ? (
          <div>
            <PractitionerForm
              practitionerDetails={practitionerFormData.emails}
              onChangeEmailField={handleEmailField}
              onChangeEmailValue={(emailVal) => setEmailValue(emailVal)}
              onChangeRole={handleChangeRole}
              checkValidation={isSubmitChecking}
            />
            <div className={styles.submitButtonContainer}>
              <Button
                disabled={practitionerFormData.emails.length <= 0 && emailValue.length <= 0}
                status={pageSubmitStatus}
                onClick={handleSubmit}
              >
                Send Invitation
              </Button>
            </div>
          </div>
        ) : (
          <InviteComplete
            practitionerDetail={invitedClient}
            selectedRole={practitionerFormData.role}
            onResetForm={handleResetForm}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddPractitionerModal;
