import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { FC } from 'react';
import styles from './FormSection.module.scss';

interface FormSectionProps {
  title: string;
  subtitle?: string;
  help?: string;
}

const FormSection: FC<FormSectionProps> = ({ title, subtitle, help, children }) => {
  return (
    <div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        {help && <HelpOutLineWithTooltips id={`${title}-form-section-help`} desc={help} iconClass={styles.helpIcon} />}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      <div>{children}</div>
    </div>
  );
};

export default FormSection;
