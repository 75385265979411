import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useMemo, useState } from 'react';
import styles from './EditGroupForm.module.scss';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateGroupId } from 'components/Groups/CreateGroupModal/components/GroupForm/validation/GroupFormValidation';
import { EditGroupFormInterface } from '../../EditGroupModalInterface';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { GROUP_TYPE, GroupType } from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';

export interface EditGroupValidateField {
  name: string;
  groupId: string;
  type: string;
  typeName: string;
}

interface GroupFromProps {
  selectedClinicianId: string;
  practitionerList: string[];
  groupFormField: EditGroupFormInterface;
  onChangeGroupField: any;
  errorMessage: EditGroupValidateField;
  accountId: string;
  excludeGroupIdValue?: string;
}

const EditGroupForm = ({
  selectedClinicianId,
  practitionerList,
  groupFormField,
  onChangeGroupField,
  errorMessage,
  accountId,
  excludeGroupIdValue
}: GroupFromProps) => {
  const { token } = useGetAccessToken();
  const [duplicateGroupId, setDuplicateGroupId] = useState(false);
  const [isCheckingGroupIdDuplicate, setIsCheckingGroupIdDuplicate] = useState(false);

  const debouncedCheckGroupIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateGroupId(token, accountId, value);
        setDuplicateGroupId(duplicate.statusCode !== 200);
        setIsCheckingGroupIdDuplicate(false);
      }, 1000),
    [token, accountId]
  );

  const validateDuplicateGroupId = async (groupIdValue: string) => {
    if (excludeGroupIdValue === groupIdValue) {
      setDuplicateGroupId(false);
      return;
    }
    if (token) {
      setIsCheckingGroupIdDuplicate(true);
      await debouncedCheckGroupIdDuplicate(groupIdValue);
    }
  };

  const onSelectClinician = (selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      const newGroupClinicianField = {
        ...groupFormField,
        leadClinicianAuth0Id: selectedClinician._id
      };
      onChangeGroupField(newGroupClinicianField);
    }
  };

  const handleChangeFieldValue = async (key: string, val: string) => {
    const newGroupField = {
      ...groupFormField,
      [key]: val
    };
    if (key === 'groupId' && val) {
      await validateDuplicateGroupId(val);
    }
    onChangeGroupField(newGroupField);
  };

  return (
    <>
      <div className={classNames(styles.fieldContainer, duplicateGroupId && styles.fieldError)}>
        <MaterialInput
          id={`groupId-${groupFormField.groupId}`}
          label={`Group ID`}
          maxLength={20}
          isLoading={isCheckingGroupIdDuplicate}
          onChange={(e) => handleChangeFieldValue('groupId', e.target.value)}
          value={groupFormField.groupId}
          required
        />
        {duplicateGroupId && <div className={styles.fieldError}>{'This group id is already in use'}</div>}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.groupLeadLabel}>Group Lead</div>
        <ClinicianSelect
          includePractice
          selectedId={selectedClinicianId}
          filterRoles={[AccessRight.Admin]}
          onSelect={(clinician) => onSelectClinician(clinician)}
          excludedClinicianIds={practitionerList}
          materialStyle
          hideAvatar
          noInitChange
        />
      </div>
      <div className={styles.rowFields}>
        <div className={classNames(styles.fieldContainer, errorMessage.name && styles.fieldError)}>
          <MaterialInput
            id={`groupName`}
            label={`Group Name*`}
            required
            value={groupFormField.name}
            onChange={(e) => handleChangeFieldValue('name', e.target.value)}
          />
          {errorMessage.name && <div className={styles.fieldError}>{errorMessage.name}</div>}
        </div>
        <div
          className={classNames(styles.fieldContainer, styles.groupTypeField, errorMessage.type && styles.fieldError)}
        >
          <MaterialSelect
            id={`groupType`}
            label={`Group Type`}
            value={groupFormField.type as string}
            onChange={(value) => handleChangeFieldValue('type', value)}
            options={GROUP_TYPE}
            isSearchable
            required
          />
          {errorMessage.type && <div className={styles.fieldError}>{errorMessage.type}</div>}
        </div>
      </div>

      <div className={styles.typeNameRowFields}>
        <div className={styles.fieldContainer}>
          <div className={styles.label}>Group Short Description</div>
          <textarea
            className={styles.textarea}
            value={groupFormField.description}
            onChange={(e) => handleChangeFieldValue('description', e.target.value)}
          />
        </div>
        {groupFormField.type === GroupType.Other && (
          <div
            className={classNames(styles.fieldContainer, styles.groupTypeField, errorMessage.name && styles.fieldError)}
          >
            <MaterialInput
              id={`typeName`}
              label={`Enter Name of Type`}
              required
              value={groupFormField.typeName}
              onChange={(e) => handleChangeFieldValue('typeName', e.target.value)}
            />
            {errorMessage.typeName && <div className={styles.fieldError}>{errorMessage.typeName}</div>}
          </div>
        )}
      </div>
    </>
  );
};

export default EditGroupForm;
