import styles from './ChildInfoBox.module.scss';
import { clientProfilesEncrypted, clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import ClientContactDetails from '../../../ClientContactDetails/ClientContactDetails';
import { getShortOrdinalNumber } from 'utils/general';

interface ClientInfoProps {
  clientRecordData: clientRecordsEncryptedInterface;
  horizontal: boolean;
}

const ChildInfoBox = ({ clientRecordData, horizontal }: ClientInfoProps) => {
  const clientProfile = clientRecordData.clientProfiles[0] as clientProfilesEncrypted;

  const childContactList = clientRecordData.clientProfiles.filter((clientObj) => clientObj.role !== 'child');

  const isProfileMoreThan2 = clientRecordData.clientProfiles.length > 2;

  return (
    <div className={isProfileMoreThan2 || !horizontal ? styles.container : styles.rowContainer}>
      <ClientContactDetails
        clientProfileData={clientProfile}
        title={'Personal Information'}
        caseId={clientRecordData?.caseId}
        classValue={clientRecordData.class}
      />
      <div className={styles.contactWrapper}>
        {childContactList.map((clientObj, index) => (
          <ClientContactDetails
            clientProfileData={clientObj}
            title={`${getShortOrdinalNumber(index + 1)} Contact`}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default ChildInfoBox;
