import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  getGroupListByGroupId,
  getGroupListingByAccountId,
  getGroupTags
} from 'utils/http/ClinicianProfileService/Groups/Groups';
import { GroupListData, Groups, GroupTag } from '../../Interfaces/Groups';

export const useFetchGroupList = (token: string) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [groupList, setGroupList] = useState<Groups[]>([]);
  const [isGroupListLoading, setIsGroupListLoading] = useState(true);

  const fetchGroupList = useCallback(
    async (token: string) => {
      try {
        setIsGroupListLoading(true);

        const groupsTags = await getGroupTags(token, accountId);
        const groupsTagsData: GroupTag[] = await groupsTags.json();

        if (isEdgeAdminView || isEdgeReceptionist) {
          const groupsData = await getGroupListingByAccountId(token, accountId);
          const groupListingData: GroupListData = await groupsData.json();
          const groupListsWithGroupTags: Groups[] = groupListingData.groups.map((obj) => ({
            ...obj,
            tags: obj.tags
              .map((tagsObj) => {
                return groupsTagsData.find(({ _id }) => _id === tagsObj);
              })
              .filter((val) => val !== undefined)
          })) as Groups[];

          setGroupList(groupListsWithGroupTags);
        } else {
          const groupsData = await getGroupListByGroupId(token);
          const groupListingData: GroupListData = await groupsData.json();
          const groupListsWithGroupTags: Groups[] = groupListingData.groups.map((obj) => ({
            ...obj,
            tags: obj.tags
              .map((tagsObj) => {
                return groupsTagsData.find(({ _id }) => _id === tagsObj);
              })
              .filter((val) => val !== undefined)
          })) as Groups[];

          setGroupList(groupListsWithGroupTags);
        }
      } catch (ex) {
        console.log(ex);
        notification.error({ message: 'Something went wrong while trying to get your groups list' });
      }
      setIsGroupListLoading(false);
    },
    [accountId, isEdgeAdminView, isEdgeReceptionist]
  );

  useEffect(() => {
    if (token) {
      fetchGroupList(token);
    }
  }, [fetchGroupList, token]);

  return { groupList, isGroupListLoading, fetchGroupList };
};
