import { Input, Modal, notification, Skeleton } from 'antd';
import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button from 'components/v2/Button/Button';
import { useFetchSchoolList } from 'pages/ControlPanel/ControlPanel/hooks/getMisSchoolData';
import { ContactInfo, WondeSchoolInfo } from 'pages/ControlPanel/Interfaces/MisConnection';
import { useMemo, useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postSchoolConnectionRequest } from 'utils/http/ClinicianProfileService/MisSchools/misSchools';
import styles from './ConnectSchoolModal.module.scss';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { debounce } from 'lodash';

interface ConnectSchoolModalProps {
  visible?: boolean;
  onClose: () => void;
  refetchSchoolConnection: () => void;
}

export const ConnectSchoolModal = ({ visible, onClose, refetchSchoolConnection }: ConnectSchoolModalProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [selectedSchool, setSelectedSchool] = useState<WondeSchoolInfo>();
  const [step, setStep] = useState<number>(1);
  const [contact, setContact] = useState<ContactInfo>({});
  const [query, setQuery] = useState('');
  const { schools, isSchoolListLoading } = useFetchSchoolList(token, query);
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');

  const debouncedUpdateQuery = useMemo(
    () =>
      debounce(async (value) => {
        setQuery(value);
      }, 1000),
    []
  );

  const handleChangeFieldValue = async (key: keyof ContactInfo, value: string) => {
    const newContact = {
      ...contact,
      [key]: value
    };
    setContact(newContact);
  };

  const handleSubmit = async () => {
    if (!selectedSchool) {
      return;
    }
    setSubmitButtonState('active');
    try {
      await postSchoolConnectionRequest(token, accountId, {
        schoolId: selectedSchool.id,
        contact: Object.keys(contact).length === 0 ? undefined : contact
      });
      notification.success({ message: 'School access request sent.' });
      setStep(1);
      setContact({});
      setSelectedSchool(undefined);
      setSubmitButtonState('finished');
      onClose();
      refetchSchoolConnection();
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 400) {
        const error = (await ex.json()) as { message: string };
        notification.error({
          message: error.message || 'First name and last name are required',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } else {
        console.error(ex);
        notification.error({
          message: 'Fail to send school access request.'
        });
      }
    }
    setSubmitButtonState('');
  };

  const getStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className={styles.searchContainer}>
              <span className={styles.title}>Search postcode, URN or establishment number.</span>
              <div className={styles.searchInputContainer}>
                <i className={`material-icons-outlined ${styles.searchIcon}`}>search</i>
                <input
                  className={styles.searchBar}
                  placeholder={'Search'}
                  onChange={(e) => debouncedUpdateQuery(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.searchResultsContainer}>
              {isSchoolListLoading
                ? [...Array(3)].map((item, index) => (
                    <div className={styles.loadingContainer} key={index}>
                      <div className={styles.loadingCard}>
                        <Skeleton.Input className={styles.loadingTitle} active />
                        <Skeleton.Input className={styles.loadingDescription} active />
                      </div>
                    </div>
                  ))
                : schools.map((school) => (
                    <div
                      className={classNames(styles.item, selectedSchool?.id === school.id && styles.selected)}
                      onClick={() => setSelectedSchool(school)}
                      key={school.id}
                    >
                      <div className={styles.detail}>
                        <span className={styles.label}>{`${school.name}${
                          school.establishmentNumber ? ` (${school.establishmentNumber})` : ''
                        }`}</span>
                        <span className={styles.address}>{`${school.address}${
                          school.postcode ? ` ${school.postcode}` : ''
                        }`}</span>
                      </div>
                      <i className={`material-icons ${styles.checkIcon}`}>check_circle</i>
                    </div>
                  ))}
            </div>
            <div className={styles.footer}>
              <Button className={styles.cancelButton} onClick={onClose} id="cancelButton">
                Cancel
              </Button>
              <Button
                className={styles.nextButton}
                disabled={!selectedSchool}
                onClick={() => setStep(2)}
                id="nextButton"
              >
                Next Step
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.descriptionContainer}>
              <div className={styles.description}>You are connecting to {selectedSchool?.name}.</div>
              <div className={styles.description}>
                To help us get your application approved as quickly as possible, please provide details of your contact
                at the school. You can skip this step if you don't have a prefered contact at the school.
              </div>
            </div>
            <div className={styles.inputSection}>
              <MaterialInput
                id={`firstName`}
                label={'First Name'}
                labelClassName={styles.fieldName}
                onChange={(event) => handleChangeFieldValue('firstName', event.target.value)}
              />
              <MaterialInput
                id={`lastName`}
                label={'Last Name'}
                labelClassName={styles.fieldName}
                onChange={(event) => handleChangeFieldValue('lastName', event.target.value)}
              />
              <MaterialInput
                id={`email`}
                label={'Email Address'}
                labelClassName={styles.fieldName}
                onChange={(event) => handleChangeFieldValue('email', event.target.value)}
              />
              <MaterialInput
                id={`mobileNumber`}
                label={'Mobile Number'}
                labelClassName={styles.fieldName}
                onChange={(event) => handleChangeFieldValue('mobileNumber', event.target.value)}
              />
              <>
                <label className={styles.fieldName}>Note</label>
                <Input.TextArea
                  className={styles.addNoteTextArea}
                  rows={5}
                  onChange={(e) => handleChangeFieldValue('notes', e.target.value)}
                />
              </>
            </div>
            <div className={styles.footer}>
              <Button className={styles.cancelButton} onClick={() => setStep(1)} id="cancelStep2Button">
                Cancel
              </Button>
              <Button className={styles.nextButton} status={submitButtonState} onClick={handleSubmit} id="saveButton">
                Confirm
              </Button>
            </div>
          </>
        );
    }
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      title={<span className={styles.title}>Connect to your school</span>}
      width={800}
      footer={null}
      visible={visible}
      onCancel={onClose}
      destroyOnClose
    >
      <div className={styles.container}>{getStep()}</div>
    </Modal>
  );
};
