import { Formik } from 'formik';
import RoomName from './components/RoomName/RoomName';
import MaxOccupancy from './components/MaxOccupancy/MaxOccupancy';
import { initialValues, roomFormSchema } from './constants';
import styles from './RoomForm.module.scss';
import FormSection from 'components/v2/FormSection/FormSection';
import TimeAvailable from './components/TimeAvailable/TimeAvailable';
import RoomStatus from './components/RoomStatus/RoomStatus';
import Button from 'components/v2/Button/Button';
import { CreateRoomType, RoomType } from 'interfaces/Schedule/Room';

interface RoomFormProps {
  isLoading: boolean;
  isEdit?: boolean;
  room?: RoomType;
  dataError?: string[];
  onSubmit: (value: CreateRoomType, roomStatus: string) => void;
}

const RoomForm = ({ room, isLoading, isEdit = false, dataError = [], onSubmit }: RoomFormProps) => {
  const formValues = isEdit
    ? ({
        ...room,
        roomStatus: room?.isBookable ? 'openForBooking' : 'pauseBooking'
      } as typeof initialValues)
    : initialValues;

  const handleSubmit = (formValues: typeof initialValues) => {
    const { roomStatus, ...restFormValues } = formValues;
    onSubmit(
      {
        ...restFormValues,
        isBookable: formValues.roomStatus === 'openForBooking'
      },
      formValues.roomStatus
    );
  };
  return (
    <Formik initialValues={formValues} validationSchema={roomFormSchema} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <div className={styles.container}>
          <RoomName />
          <MaxOccupancy />
          <FormSection
            title="Set when available for booking"
            subtitle="Allocate the times and days for this room to be available. To break the days up so you can have certain times it is not available select to ‘split the day’."
            help="If attached to an appointment type, the availability of the room is dependent on the available time slots of the appointment type. Changes to the availability of the room cannot be made if there are existing appointments in the future."
          >
            <div className={styles.timeAvailableContainer}>
              <TimeAvailable />
            </div>
          </FormSection>
          <FormSection
            title="Set room status"
            help="Any changes to the room status only applies to future bookings towards this room."
          >
            <RoomStatus isEdit={isEdit} />
          </FormSection>
          {dataError.length > 0 && (
            <div className={styles.dataErrorContainer}>
              <ul>
                {dataError.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </div>
          )}
          <div className={styles.buttonContainer}>
            <Button className={styles.saveButton} onClick={submitForm} status={isLoading ? 'active' : ''}>
              Save changes
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default RoomForm;
