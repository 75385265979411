import Button from 'components/Button/Button';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { Invoice } from 'pages/Invoices/interface';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { MONEY_SYMBOL, STATUS_LABELS } from '../ClientInvoiceItem/ClientInvoiceItem';
import InvoiceActionMenu from '../InvoiceActionMenu/InvoiceActionMenu';
import styles from './GroupInvoiceItem.module.scss';

interface GroupInvoiceItemProps {
  invoice: Invoice;
  onChangeStatus: (_id: string, status: 'closed' | 'confirmPaid', groupDetail?: Invoice['group']) => void;
  onInvoiceClick: () => void;
  onResendInvoice: (_id: string, groupDetail?: Invoice['group']) => void;
  isInvoiceActionProcessing: boolean;
  selectParticipationType?: ParticipantType;
}

const GroupInvoiceItem = ({
  invoice,
  onChangeStatus,
  onInvoiceClick,
  onResendInvoice,
  isInvoiceActionProcessing
}: GroupInvoiceItemProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { isEdgeAdminView, isEdgeUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const { _id, description, issueDate, invoiceAmount, invoiceId, status, group } = invoice;
  const draftPath =
    (isEdgeAdminView ? `/admin` : isEdgeReceptionist ? '/receptionist' : isEdgeUserView ? '/user' : '') +
    `/invoices/${_id}/edit`;
  return (
    <div className={styles.container}>
      <div className={styles.nameWrapper}>
        <div className={styles.nameLabel}>{group?.name}</div>
      </div>

      {status === 'draft' ? (
        <Link className={styles.invoiceId} to={{ pathname: draftPath }}>
          {invoiceId}
        </Link>
      ) : (
        <div className={styles.invoiceId}>
          <Button className={styles.button} onClick={onInvoiceClick}>
            {invoiceId}
          </Button>
        </div>
      )}
      <span className={styles.issueDate}>{issueDate}</span>
      <span className={styles.description}>
        {description ? (description.length > 70 ? `${description.slice(0, 70)}...` : description) : '-'}
      </span>
      <span className={styles.invoiceAmount}>
        {MONEY_SYMBOL}
        {invoiceAmount.toFixed(2)}
      </span>
      <div className={styles.statusWrapper}>
        <span className={`${styles.status} ${styles[status]}`}>{STATUS_LABELS[status]}</span>
      </div>
      <div ref={menuRef} className={styles.actionButtonContainer}>
        <InvoiceActionMenu
          isInvoiceActionProcessing={isInvoiceActionProcessing}
          status={status}
          invoiceId={_id}
          onChangeStatus={onChangeStatus}
          onResendInvoice={onResendInvoice}
          groupInvoiceDetail={invoice.group}
        />
      </div>
    </div>
  );
};

export default GroupInvoiceItem;
