import { useState } from 'react';
import styles from './SignatureSection.module.scss';
import SignatureBox from './components/SignatureBox/SignatureBox';

export interface SignatureSectionProps {
  clinician?: {
    avatar: string;
    email: any;
    mobileNumber: any;
    name: string;
  };
  signVal: {
    drawVal: string;
    textVal: string;
    type: string;
  };
  extraDetailsVal: {
    valediction: string;
    email: string;
    mobileNumber: string;
    name: string;
    other: string;
  };
  previewMode?: boolean;
  showError?: boolean;
  onChangeSignature?: (
    signatureVal: { drawVal: string; textVal: string; type: string },
    extraDetailsVal: {
      email: string;
      mobileNumber: string;
      name: string;
      other: string;
    }
  ) => void;
}

const SignatureSection = ({
  clinician,
  previewMode,
  extraDetailsVal,
  signVal,
  showError,
  onChangeSignature
}: SignatureSectionProps) => {
  const [isShowSignature, setShowSignature] = useState(false);
  const [signatureVal, setSignatureVal] = useState(signVal);
  const [extraDetails, setExtraDetails] = useState(extraDetailsVal);

  const onClickClose = () => {
    setShowSignature(false);
  };

  const onClickShowSignature = () => {
    setShowSignature(true);
  };

  const saveNewSignature = (newSignatureVal: typeof signatureVal, newExtraDetails: typeof extraDetails) => {
    setSignatureVal(newSignatureVal);
    setExtraDetails(newExtraDetails);

    if (onChangeSignature) {
      onChangeSignature(newSignatureVal, newExtraDetails);
    }
  };

  if (previewMode) {
    return (
      <div className={styles.previewContainer}>
        <div className={styles.signLabel}>{extraDetailsVal.valediction}</div>
        <div className={styles.signature}>
          {signVal.type === 'typeVer' ? (
            signVal.textVal
          ) : (
            <img className={styles.signatureImg} alt={'signature'} src={signVal.drawVal} />
          )}
        </div>
        <div className={styles.extraDetailsWrapperPreview}>
          {extraDetailsVal.name && <div className={styles.extraDetails}>{extraDetailsVal.name}</div>}
          {extraDetailsVal.mobileNumber && <div className={styles.extraDetails}>{extraDetailsVal.mobileNumber}</div>}
          {extraDetailsVal.email && <div className={styles.extraDetails}>{extraDetailsVal.email}</div>}
          {extraDetailsVal.other && <div className={styles.extraDetails}>{extraDetailsVal.other}</div>}
        </div>
      </div>
    );
  }

  return (
    <div id={'signatureSection'} className={styles.container}>
      {signatureVal.textVal.length <= 0 && signatureVal.drawVal.length <= 0 ? (
        <div className={styles.signatureInput}>
          <div className={styles.card} onClick={() => onClickShowSignature()}>
            <div className={styles.label}>Kind Regards:</div>
            <div className={styles.signBox}>Click to add signature</div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.signLabel}>{extraDetailsVal.valediction}</div>
          <div onClick={() => onClickShowSignature()}>
            <div className={styles.signature}>
              {signatureVal.type === 'typeVer' ? (
                signatureVal.textVal
              ) : (
                <img className={styles.signatureImg} alt={'signature'} src={signatureVal.drawVal} />
              )}
            </div>
            <div className={styles.extraDetailsWrapper}>
              {extraDetailsVal.name && <div className={styles.extraDetails}>{extraDetailsVal.name}</div>}
              {extraDetailsVal.mobileNumber && (
                <div className={styles.extraDetails}>{extraDetailsVal.mobileNumber}</div>
              )}
              {extraDetailsVal.email && <div className={styles.extraDetails}>{extraDetailsVal.email}</div>}
              {extraDetailsVal.other && <div className={styles.extraDetails}>{extraDetailsVal.other}</div>}
            </div>
          </div>
        </div>
      )}
      <SignatureBox
        clinician={clinician}
        extraDetailsValue={extraDetailsVal}
        value={signatureVal}
        visible={isShowSignature}
        onCloseSignature={onClickClose}
        onUpdateSignature={saveNewSignature}
      />
      {showError && <div className={styles.signatureError}>Please sign the signature</div>}
    </div>
  );
};

export default SignatureSection;
