import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import styles from './InvoiceActionMenu.module.scss';
import { useRef, useState } from 'react';
import { Invoice } from 'pages/Invoices/interface';

interface InvoiceActionMenuProps {
  isInvoiceActionProcessing: boolean;
  status: Invoice['status'];
  onChangeStatus: (_id: string, status: 'closed' | 'confirmPaid', groupDetail?: Invoice['group']) => void;
  onResendInvoice: (_id: string, groupDetail?: Invoice['group']) => void;
  invoiceId: string;
  groupInvoiceDetail?: Invoice['group'];
}

const InvoiceActionMenu = ({
  isInvoiceActionProcessing,
  status,
  onChangeStatus,
  onResendInvoice,
  invoiceId,
  groupInvoiceDetail
}: InvoiceActionMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleSelectButtonClick = () => {
    if (showMenu) {
      handleCloseMenu();
    } else {
      document.addEventListener('click', handleCloseMenu);
    }

    setShowMenu(!showMenu);
  };

  const handleResendInvoice = () => {
    handleCloseMenu();
    onResendInvoice(invoiceId, groupInvoiceDetail);
  };

  const handleChangeStatus = (status: 'closed' | 'confirmPaid') => () => {
    handleCloseMenu();
    onChangeStatus(invoiceId, status, groupInvoiceDetail);
  };

  return (
    <div ref={menuRef} className={styles.actionButtonWrapper}>
      {isInvoiceActionProcessing ? (
        <div className={styles.actionButton}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.actionButton} onClick={handleSelectButtonClick}>
          <>
            Select...
            <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
          </>
        </div>
      )}
      <div className={styles.menuWrapper}>
        <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
          {status !== 'draft' && (
            <div className={styles.listBox}>
              <div className={styles.listTitle} onClick={handleResendInvoice}>
                Resend Invoice
              </div>
            </div>
          )}
          <div className={styles.listBox} onClick={handleChangeStatus('confirmPaid')}>
            <div className={styles.listTitle}>Change Status to Paid</div>
          </div>
          <div className={styles.listBox} onClick={handleChangeStatus('closed')}>
            <div className={styles.listTitle}>Change Status to Closed</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceActionMenu;
