import { notification } from 'antd';
import FilterDropdown from 'components/FilterDropDown/FilterDropDown';
import React, { useEffect, useState } from 'react';
import { getClassLists } from 'utils/http/ClinicianProfileService/Accounts/accounts';

interface Props {
  token: string;
  asAdmin: boolean;
  accountId: string;
  classFilteredList: string[];
  setClassFilteredList: React.Dispatch<React.SetStateAction<string[]>>;
  resetPage: () => void;
}

const ClassFilter = ({ token, asAdmin, accountId, classFilteredList, setClassFilteredList, resetPage }: Props) => {
  const [classLists, setClassLists] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchClassLists = async (token: string) => {
    setIsLoading(true);
    try {
      const request = await getClassLists(token, accountId, asAdmin);
      const lists = (await request.json()) as string[];
      setClassLists(lists);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch class lists"
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      if (classFilteredList.length > 0) {
        // reset filter when change view
        setClassFilteredList([]);
      }
      fetchClassLists(token);
    }
    // eslint-disable-next-line
  }, [token, asAdmin]);

  return (
    <FilterDropdown
      id="class-filter"
      menuItems={classLists.map((className) => ({
        _id: className,
        name: className
      }))}
      onChangeItem={(obj) => {
        const { item } = obj;
        if (item) {
          resetPage();
          const isSelected = classFilteredList.includes(item._id);
          if (isSelected) {
            setClassFilteredList((list) => list.filter((className) => className !== item._id));
          } else {
            setClassFilteredList((list) => [...list, item._id]);
          }
        }
      }}
      selectedFilterList={classFilteredList.map((className) => ({
        _id: className,
        name: className
      }))}
      loading={isLoading}
    >
      Class
    </FilterDropdown>
  );
};

export default ClassFilter;
