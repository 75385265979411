import { TFunction } from 'react-i18next';

export const MAPPING_FIELDS = [
  {
    id: 'taxRates',
    label: 'Tax Rates',
    required: true,
    active: true
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    required: true,
    active: true
  },
  {
    id: 'emailAddress',
    label: 'Email Address',
    required: false,
    active: false
  },
  {
    id: 'contactNumber',
    label: 'Contact Number',
    required: false,
    active: false
  },
  {
    id: 'invoiceNumber',
    label: 'Invoice Number',
    required: false,
    active: false
  },
  {
    id: 'notes',
    label: 'Notes',
    required: false,
    active: false
  },
  {
    id: 'invoiceItemDescription',
    label: 'Invoice Item Description',
    required: false,
    active: false
  }
];

export const PAYMENT_TYPES = [
  {
    id: 'default',
    label: 'Default',
    required: true,
    active: true
  },
  {
    id: 'stripe',
    label: 'Stripe (Coming soon)',
    required: true,
    active: false
  }
];

export const getMappingOptions = (t: TFunction) => [
  {
    value: 'clientNickname',
    label: t('form.client_nickname')
  },
  {
    value: 'fullName',
    label: 'Full Name'
  },
  {
    value: 'lastName,firstName',
    label: 'Last Name, First Name'
  },
  {
    value: 'firstName',
    label: 'First Name'
  },
  {
    value: 'lastName',
    label: 'Last Name'
  },
  {
    value: 'clientId',
    label: t('form.client_id')
  },
  {
    value: 'emailAddress',
    label: 'Email Address'
  },
  {
    value: 'mobilePhoneNumber',
    label: 'Mobile Phone Number'
  },
  {
    value: 'tacklitInvoiceId',
    label: 'Tacklit Invoice ID'
  },
  {
    value: 'tacklitCustomReferenceId',
    label: 'Invoice ID - Clinician Reference - Appointment Type Reference'
  },
  {
    value: 'tacklitInvoiceDescription',
    label: 'Invoice Description'
  },
  {
    value: 'tacklitInvoiceNote',
    label: 'Tacklit Invoice Note'
  },
  {
    value: 'tacklitInvoiceItemDescription',
    label: 'Tacklit Invoice Item Overview'
  }
  // not available yet
  // {
  //   value: 'clinicianBillingCode',
  //   label: 'Clinician Billing Code'
  // }
];

export const INVOICE_MANDATORY_FIELDS = [
  { id: '1', required: true, active: true, selectedField: 'Invoice Due Date', label: 'Due Date' },
  {
    id: '2',
    required: true,
    active: true,
    selectedField: 'Invoice Issue Date',
    label: 'Issue Date'
  },
  { id: '3', required: true, active: true, selectedField: 'Invoice Amount', label: 'Amount' },
  { id: '4', required: true, active: true, selectedField: 'Invoice Discount', label: 'Discount' },
  { id: '5', required: true, active: true, selectedField: 'Payment Status', label: 'Status' }
];

export enum EditType {
  Fields = 'fields',
  Accounts = 'accounts',
  Items = 'items',
  Payments = 'payments'
}
