import { useCallback, useEffect, useState } from 'react';
import styles from './InterventionListContent.module.scss';
import SearchBar from 'components/SearchBar/SearchBar';
import Tag from 'components/Tag/Tag';
import ActiveInterventionListCard from './components/ActiveInterventionListCard/ActiveInterventionListCard';
import ClosedInterventionListCard from './components/ClosedInterventionListCard/ClosedInterventionListCard';
import { Interventions, InterventionStatus } from 'MHH/pages/Interventions/Interfaces/Interventions';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

interface InterventionListContentProps {
  interventionList: Interventions[];
  isInterventionListLoading: boolean;
  onChangeTabFilter: (tabValue: InterventionStatus) => void;
  onHandleSearch: (q: string) => void;
  isPageLoading: boolean;
  interventionStatus: InterventionStatus;
  searchValue: string;
}

const InterventionListContent = ({
  interventionList,
  isInterventionListLoading,
  onChangeTabFilter,
  onHandleSearch,
  isPageLoading,
  interventionStatus,
  searchValue
}: InterventionListContentProps) => {
  const listLoading = isInterventionListLoading || isPageLoading;
  const [activeInterventionList, setActiveInterventionList] = useState<Interventions[]>([]);
  const [closedInterventionList, setClosedInterventionList] = useState<Interventions[]>([]);

  const handleFilterValue = useCallback(
    (searchValue: string) => {
      const getFilterList = interventionList.filter((listObj) =>
        listObj.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      const activeList = getFilterList.filter((listObj) => listObj.status === 'active');
      const closedList = getFilterList.filter((listObj) => listObj.status === 'closed');
      setActiveInterventionList(activeList);
      setClosedInterventionList(closedList);
    },
    [interventionList]
  );

  useEffect(() => {
    handleFilterValue(searchValue);
  }, [searchValue, handleFilterValue]);

  const onChangeTabStatus = (tabValue: InterventionStatus) => {
    onChangeTabFilter(tabValue);
  };

  const onSearchHandle = (val: string) => {
    handleFilterValue(val);
    onHandleSearch(val);
  };

  const interventionsContentComponents = () => {
    switch (interventionStatus) {
      case InterventionStatus.Active:
        return <ActiveInterventionListCard interventionListing={activeInterventionList} searchValue={searchValue} />;
      case InterventionStatus.Closed:
        return <ClosedInterventionListCard interventionListing={closedInterventionList} searchValue={searchValue} />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.filterWrapper}>
          <div className={styles.filterWrapperTitle}>Show</div>
          <div className={styles.filterWrapperTags}>
            <Tag
              label="Active"
              type={interventionStatus === InterventionStatus.Active ? 'active' : 'normal'}
              onClick={() => onChangeTabStatus(InterventionStatus.Active)}
              counter={activeInterventionList.length}
            />
            <Tag
              label="Closed"
              type={interventionStatus === InterventionStatus.Closed ? 'active' : 'normal'}
              onClick={() => onChangeTabStatus(InterventionStatus.Closed)}
              counter={closedInterventionList.length}
            />
          </div>
        </div>
        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarCard}>
            <SearchBar
              placeholder={'Search by intervention name'}
              value={searchValue}
              withSearchButton
              onSearch={onSearchHandle}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {listLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          interventionsContentComponents()
        )}
      </div>
    </div>
  );
};

export default InterventionListContent;
