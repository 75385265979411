import classNames from 'classnames';
import styles from './SearchBar.module.scss';

interface SearchBarProps {
  placeholder?: string;
  searchValue?: string;
  setSearchValue: (searchValue: string) => void;
  containerClassName?: string;
  clearTextIcon?: boolean;
}

const SearchBar = ({ placeholder, searchValue, setSearchValue, containerClassName, clearTextIcon }: SearchBarProps) => {
  return (
    <div
      className={classNames(
        styles.searchBar,
        containerClassName,
        searchValue && styles.hasValue,
        clearTextIcon && styles.clearable
      )}
    >
      <input
        className={styles.input}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={placeholder || 'Search'}
      />
      <div className={styles.searchBarIcon}>
        <i className="material-icons-outlined">search</i>
      </div>
      {clearTextIcon && searchValue && (
        <div className={styles.clearTextIcon} onClick={() => setSearchValue('')}>
          <i className="material-icons-outlined">backspace</i>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
