import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './SwitchProfile.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SwitchProfileProps {
  userInfo?: ProfileInterface;
  onSwitchProfile: () => void;
}

const SwitchProfile = ({ userInfo, onSwitchProfile }: SwitchProfileProps) => {
  const navigate = useNavigate();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();

  const handleSwitchLink = (link: string) => {
    navigate(`${link}/students`);
    onSwitchProfile();
  };

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.label}>TOGGLE PROFILE</div>
      <div
        className={isEdgeAdminView ? styles.practiceActive : styles.profileWrapper}
        onClick={() => handleSwitchLink(`/admin`)}
      >
        <div className={styles.box}>
          {userInfo?.practice?.logo ? (
            <img src={userInfo?.practice?.logo} alt={'profilePic'} className={styles.profileImg} />
          ) : (
            <i className={`material-icons ${styles.defaultLogo}`}>person</i>
          )}
          <div className={styles.infoBox}>
            <div className={styles.name}>{userInfo?.practices ? userInfo?.practice?.name : userInfo?.name}</div>
            <div className={styles.practiceBoxContainer}>
              <div className={styles.practiceBox}>School Leader View</div>
            </div>
          </div>
          <i className={`material-icons-outlined ${styles.rightIcon}`}>arrow_forward_ios</i>
        </div>
      </div>
      <div
        className={isEdgeUserView ? styles.clinicianActive : styles.profileWrapper}
        onClick={() => handleSwitchLink(`/user`)}
      >
        <div className={styles.box}>
          <img src={userInfo?.avatar} alt={'profilePic'} className={styles.profileImg} />
          <div className={styles.infoBox}>
            <div className={styles.name}>{userInfo?.name}</div>
            <div className={styles.practiceBoxContainer}>
              <div className={styles.practitionerBox}>{t('menu.practitioner_view')}</div>
            </div>
          </div>
          <i className={`material-icons-outlined ${styles.rightIcon}`}>arrow_forward_ios</i>
        </div>
      </div>
    </div>
  );
};

export default SwitchProfile;
