import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  UnassignedInterventionMemberList,
  UnassignedInterventionMemberListFilter
} from 'MHH/pages/Interventions/Interfaces/Interventions';
import { getUnassignedMembersByInterventionId } from 'utils/http/ClinicianProfileService/Interventions/InterventionMembers';
import queryString from 'query-string';

export const useFetchAssignedInterventionMemberList = (
  token: string,
  accountId: string,
  interventionId: string,
  filterValue?: UnassignedInterventionMemberListFilter
) => {
  const [unassignedInterventionMembers, setUnassignedInterventionMembers] = useState<UnassignedInterventionMemberList>({
    clientRecords: [],
    paging: {
      page: 1,
      perPage: 20,
      totalItem: 0
    }
  });
  const [isUnassignedInterventionMembersLoading, setIsUnassignedInterventionMembersLoading] = useState(true);

  const fetchUnassignedInterventionMemberList = useCallback(
    async (token: string, filterValue?: UnassignedInterventionMemberListFilter) => {
      try {
        setIsUnassignedInterventionMembersLoading(true);
        const qParam = queryString.stringify(filterValue || {});

        const getMemberData = await getUnassignedMembersByInterventionId(token, accountId, interventionId, qParam);
        const memberListData: UnassignedInterventionMemberList = await getMemberData.json();

        setUnassignedInterventionMembers(memberListData);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your unassigned member list' });
      }
      setIsUnassignedInterventionMembersLoading(false);
    },
    [interventionId, accountId]
  );

  useEffect(() => {
    if (token) {
      fetchUnassignedInterventionMemberList(token, filterValue);
    }
  }, [fetchUnassignedInterventionMemberList, token, filterValue]);

  return {
    unassignedInterventionMembers,
    isUnassignedInterventionMembersLoading,
    fetchUnassignedInterventionMemberList
  };
};
