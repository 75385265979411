import AssessmentWidget from 'pages/Report/components/WidgetComponents/AssessmentWidget/AssessmentWidget';
import styles from './HeadingAssessment.module.scss';

interface HeadingAssessmentProps {
  isLoading: boolean;
  assessmentData: any;
}

const HeadingAssessment = ({ isLoading, assessmentData }: HeadingAssessmentProps) => {
  return !isLoading && assessmentData.length > 0 ? (
    <div className={styles.hideContainer}>
      {assessmentData.map((assessmentObj: any, index: any) => {
        let id = '';
        if (index === 0) {
          id = 'firstAssessment';
        } else if (index + 1 === assessmentData.length) {
          id = 'mostRecentAssessment';
        } else {
          id = 'assessment';
        }

        return (
          <div className={styles.container} id={id} key={index}>
            <AssessmentWidget assessmentData={assessmentObj} />
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default HeadingAssessment;
