export const MAX_OF_FILTER = 4;

export const FILTER_COLOURS = [
  'rgba(252, 219, 211, 0.5)',
  'rgba(37, 190, 105, 0.5)',
  'rgba(161, 68, 246, 0.5)',
  'rgba(222, 61, 131, 0.5)'
];

export const DEFAULT_FILTER = {
  _id: '',
  name: '',
  avatar: '',
  isClinician: true
};
