import { useEffect, useState } from 'react';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { getAllClientEncryptedDataByClientRecordId } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

export const useFetchAppointmentClientDetails = (token: string, recordId?: string) => {
  const [clientEncryptDetails, setClientEncryptDetails] = useState<clientRecordsEncryptedInterface>();
  const [clientEncryptDetailsLoading, setClientEncryptDetailsLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (token && recordId) {
      const fetchClientEncryptDetails = async () => {
        setClientEncryptDetailsLoading(true);
        try {
          const getClientEncryptResponses = await getAllClientEncryptedDataByClientRecordId(token, recordId);
          const clientDetailsRes = (await getClientEncryptResponses.json()) as clientRecordsEncryptedInterface;
          setClientEncryptDetails(clientDetailsRes);
        } catch (ex) {
          notification.error({ message: t('form.error.failed_to_retrieve_this_client_detail') });
        }
        setClientEncryptDetailsLoading(false);
      };

      fetchClientEncryptDetails();
    }
  }, [recordId, token, t]);

  return { clientEncryptDetails, clientEncryptDetailsLoading };
};
