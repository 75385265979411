/* eslint-disable no-nested-ternary, complexity */
import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { useFormikContext, Field, FieldProps, FormikValues } from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactTooltip from 'react-tooltip';

import { createVerification } from 'utils/http/verification';

import Button from 'components/v2/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import FormikInput from 'components/Input/FormikInput';
import SubmitButton from '../SubmitButton/SubmitButton';
import TwoFAInput from './components/TwoFAInput/TwoFAInput';

import styles from './Security.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const PREFERRED_COUNTRY = GAE_REGION === 'au' ? 'au' : GAE_REGION === 'gb' ? 'gb' : undefined;

const Security = () => {
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [isPasswordErrored, setIsPasswordErrored] = useState(false);
  const [isValidateInitiated, setIsValidateInitiated] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordError, setPasswordError] = useState('');
  const [sendCodeStatus, setSendCodeStatus] = useState<'' | 'active' | 'finished'>('');

  const { values, errors, handleSubmit, setErrors, setFieldTouched, validateField } = useFormikContext<FormikValues>();

  const {
    passwordLengthValid,
    passwordIncludesLowercaseUppercaseNumber,
    passwordIncludesSpecialCharacters
  } = useMemo(() => {
    const { password = '' } = values;

    const passwordLengthValid = password.length >= 8;
    const passwordIncludesLowercaseUppercaseNumber = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,}/g.test(password);
    const passwordIncludesSpecialCharacters = /.*[!@#$%^&*]+.*/g.test(password);

    return { passwordLengthValid, passwordIncludesLowercaseUppercaseNumber, passwordIncludesSpecialCharacters };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.password]);

  const validatePassword = () => {
    if (
      passwordScore === 4 &&
      passwordLengthValid &&
      passwordIncludesLowercaseUppercaseNumber &&
      passwordIncludesSpecialCharacters
    ) {
      setIsPasswordValidated(true);
      setIsPasswordErrored(false);
    } else {
      setIsPasswordValidated(false);
      setIsPasswordErrored(true);

      if (passwordScore < 4) {
        setPasswordError('Password must be strong to continue');
      } else if (!passwordLengthValid) {
        setPasswordError('Password must be at least 8 characters long');
      } else if (!passwordIncludesLowercaseUppercaseNumber) {
        setPasswordError('Password must contain an uppercase character, a lowercase character, and a number');
      } else if (!passwordIncludesSpecialCharacters) {
        setPasswordError('Password must contain a special character (!@#$%^&*)');
      }
    }
  };

  const updatePasswordStrength = (score: number) => {
    setPasswordScore(score);
  };

  const handleOnRequestCode = async (): Promise<void> => {
    setFieldTouched('mobileNumber', true);
    validateField('mobileNumber');
    setSendCodeStatus('active');

    if (errors.mobileNumber) {
      setSendCodeStatus('');
      return;
    }

    const { mobileNumber } = values;

    const body = {
      numberToVerify: `+${mobileNumber}`
    };

    try {
      const createVerificationStatus = await createVerification(body);

      if ((await createVerificationStatus.text()) === 'pending') {
        setSendCodeStatus('finished');
        setTimeout(() => {
          setIsValidateInitiated(true);
          setSendCodeStatus('');
        }, 2000);
      } else {
        setSendCodeStatus('');
        throw new Error();
      }
    } catch (err) {
      setSendCodeStatus('');
      setErrors({ ...errors, mobileNumber: 'Invalid phone number' });
    }
  };

  const handleOnTryAgain = () => {
    setIsValidateInitiated(false);
  };

  const handleOn2FASuccess = () => {
    handleSubmit();
  };

  const strongPassword =
    passwordScore >= 4 &&
    passwordLengthValid &&
    passwordIncludesLowercaseUppercaseNumber &&
    passwordIncludesSpecialCharacters;

  return (
    <>
      <div className={styles.title}>
        <span className={styles.titleHeading}>Secure your account and data</span>
        <div className={styles.progressIndicatorContainer}>
          <div
            className={classnames(styles.progressIndicator, isPasswordValidated ? styles.complete : styles.current)}
          />
          <div
            className={classnames(
              styles.progressIndicator,
              isValidateInitiated && styles.complete,
              isPasswordValidated && !isValidateInitiated && styles.current
            )}
          />
          <div className={classnames(styles.progressIndicator, isValidateInitiated && styles.current)} />
        </div>
      </div>
      <div className={styles.collapse}>
        <div className={classnames(styles.passwordSection, isPasswordValidated && styles.validated)}>
          <span className={`heading ${styles.heading}`}>
            Compliant with GDPR and Data Protection standards. We use bank grade encryption for all data on our
            platform.
          </span>
          <span className="heading">
            First, <strong>set up a secure password</strong> for your account that is:
          </span>
          <span className={passwordIncludesLowercaseUppercaseNumber ? styles.suggestionSuccess : styles.suggestion}>
            <i className={`material-icons ${styles.checkIcon}`}>
              {passwordIncludesLowercaseUppercaseNumber ? 'done' : 'remove'}
            </i>
            <span className={styles.label}>Contains uppercase characters, lowercase characters, and numbers</span>
          </span>
          <span className={passwordIncludesSpecialCharacters ? styles.suggestionSuccess : styles.suggestion}>
            <i className={`material-icons ${styles.checkIcon}`}>
              {passwordIncludesSpecialCharacters ? 'done' : 'remove'}
            </i>
            <span className={styles.label}>Contains special characters (!@#$%^&*)</span>
          </span>
          <span className={strongPassword ? styles.suggestionSuccess : styles.suggestion}>
            <i className={`material-icons ${styles.checkIcon}`}>{strongPassword ? 'done' : 'remove'}</i>
            <div className={styles.passwordStrengthWrapper}>
              <span className={styles.label}>
                Password must be <span className={styles.strong}>STRONG</span>
              </span>
              <div className={styles.passwordStrengthBox}>
                <div className={classnames(styles.passwordStrengthIndicatorContainer, strongPassword && styles.valid)}>
                  <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 1 && styles.active)} />
                  <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 2 && styles.active)} />
                  <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 3 && styles.active)} />
                  <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 4 && styles.active)} />
                </div>
                <PasswordStrengthBar
                  className={styles.passwordStrengthBar}
                  minLength={8}
                  password={values.password}
                  scoreWordStyle={{
                    color: '#343434',
                    fontWeight: 600,
                    fontSize: '13px',
                    marginTop: 0,
                    textTransform: 'capitalize'
                  }}
                  onChangeScore={updatePasswordStrength}
                />
              </div>
            </div>
          </span>
          <div className={styles.passwordContainer}>
            <FormikInput
              className={styles.password}
              label="Set Secure Password"
              name="password"
              type="password"
              required
            />
          </div>
          {isPasswordErrored && passwordError && <span className={styles.passwordDesc}>{passwordError}</span>}
          <SubmitButton variant="primary" type="button" onClick={validatePassword}>
            Set Password
          </SubmitButton>
        </div>
      </div>
      <div className={styles.collapse}>
        <div
          className={classnames(
            styles.mobileNumberSection,
            (!isPasswordValidated || isValidateInitiated) && styles.codeRequested
          )}
        >
          <div className={styles.title}>
            <span className={'heading'}>
              Second, <strong>set up two factor authentication</strong>{' '}
            </span>
            <span className={styles.whatsThis} data-tip data-for="two-factor-authentication">
              What's this?
              <i className={`material-icons ${styles.icon}`}>help_outline</i>
            </span>
          </div>
          <ReactTooltip id="two-factor-authentication" className={styles.tooltip}>
            Setting up and using two-factor authentication (2FA) is one of the simplest and most effective strategies
            for increasing account security. We use your mobile as a secondary way to validate you when you log in as on
            top of your username and password, so when you log in you may be asked to input a unique one time code we
            send via SMS to your phone.
          </ReactTooltip>
          <div className={styles.mobileNumberContainer}>
            <Field name="mobileNumber">
              {({ field, form, meta }: FieldProps) => (
                <div className={styles.mobileNumber}>
                  <span className={styles.label}>Mobile Number</span>
                  <PhoneInput
                    inputClass={styles.mobileNumberInput}
                    buttonClass={styles.countrySelect}
                    dropdownClass={styles.countryDropdown}
                    inputProps={{ onBlur: field.onBlur, name: field.name }}
                    value={values.mobileNumber}
                    onChange={(value, data, event, formattedValue) => {
                      form.setFieldValue('mobileNumber', value);
                      form.setFieldValue('formattedMobileNumber', formattedValue);
                      return;
                    }}
                    onEnterKeyPress={handleOnRequestCode}
                    {...(PREFERRED_COUNTRY && { country: PREFERRED_COUNTRY, preferredCountries: [PREFERRED_COUNTRY] })}
                  />
                  <ErrorMessage error={meta.error} visible={meta.touched} />
                </div>
              )}
            </Field>
            <div className={styles.sendCodeButtonWrapper}>
              <Button
                className={styles.sendCodeBtn}
                type="button"
                status={sendCodeStatus}
                onClick={handleOnRequestCode}
              >
                Send code via SMS
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.collapse}>
        <div className={classnames(styles.twoFASection, isValidateInitiated && styles.codeRequested)}>
          <TwoFAInput onSuccess={handleOn2FASuccess} />
          <span className={styles.noCode}>
            Haven't received a code?{' '}
            <span onClick={handleOnTryAgain} className={styles.tryAgain}>
              Check phone number then try again
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Security;
