import { Translation } from 'i18n/types';
import { AccessRight } from 'interfaces/Clients/clinician';

export interface PractitionerRoleInterface {
  value: AccessRight;
  label: Translation;
  description: Translation;
}

export const PRACTITIONER_ROLES: { [key in AccessRight]: PractitionerRoleInterface } = {
  [AccessRight.Admin]: {
    value: AccessRight.Admin,
    label: 'options.roles.super_user',
    description: 'options.roles.super_user.description'
  },
  [AccessRight.User]: {
    value: AccessRight.User,
    label: 'options.roles.practitioner',
    description: 'options.roles.practitioner.description'
  },
  [AccessRight.Receptionist]: {
    value: AccessRight.Receptionist,
    label: 'options.roles.administrator',
    description: 'options.roles.administrator.description'
  },
  [AccessRight.Mentor]: {
    value: AccessRight.Mentor,
    label: 'options.roles.mentor',
    description: 'options.roles.mentor.description'
  }
};

export const PRACTITIONER_DEFAULT_ROLES = PRACTITIONER_ROLES[AccessRight.Admin].value;
