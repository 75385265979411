import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';
import { AccessRight } from 'interfaces/Clients/clinician';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getClinicianProfile = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/profile') as Promise<BentResponse>;

export const getClinicianProfileById = (token: string, clinicianId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId}/profile`
  ) as Promise<BentResponse>;

export const getMinifiedCliniciansByAccountId = (
  token: string,
  accountId: string,
  args: {
    accessRights?: AccessRight[];
    status?: 'active' | 'closed';
    perPage?: number;
    page?: number;
  }
) => {
  const { accessRights, status, perPage, page } = args;
  const query = new URLSearchParams({
    ...(accessRights?.length && { accessRights: accessRights.join(',') }),
    ...(status && { status: status.toString() }),
    ...(perPage && { perPage: perPage.toString() }),
    ...(page && { page: page.toString() })
  }).toString();
  return httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/accounts/${accountId}/clinicians/minified${query ? `?${query}` : ''}`) as Promise<BentResponse>;
};

export const updateClinicianProfile = (token: string, body: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })('/profile', body);

export const patchClinicianMedicareRole = (token: string, role: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })('/clinicians/me/medicare', { role });

export const uploadHelmControlPhoto = (token: string, formData: FormData) =>
  new Promise((resolve, reject) => {
    fetch(`${url}/clinicians/me/helmControl:uploadImage`, {
      method: 'POST',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((res) => {
        if (res.status === 201) {
          resolve(res);
        } else {
          reject(res.statusText);
        }
      })
      .catch((err) => reject(err));
  }) as Promise<{ [key: string]: any }>;

export const updateClinicianHelmControl = (token: string, body: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    '/clinicians/me/helmControl',
    body
  ) as Promise<BentResponse>;
