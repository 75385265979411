import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  progressVal: number;
  barColor?: string;
}

const ProgressBar = ({ progressVal, barColor }: ProgressBarProps) => {
  const progressValue = progressVal + '%';
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <div className={styles.bar} style={{ width: progressValue, background: barColor }} />
      </div>
    </div>
  );
};

export default ProgressBar;
