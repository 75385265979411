import { useState } from 'react';

import styles from './ModalHeader.module.scss';
import ProgressBar from '../../../ProgressBar/ProgressBar';
import { newClientForm } from '../../AddPatientModalV2Interface';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getName } from 'utils/general';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useTranslation } from 'react-i18next';

interface ModalHeaderProps {
  clientDetails: newClientForm;
  selectedClinicianId: string;
  onChangeClinician: (b: string) => void;
  totalStep: number;
  isCompleted: boolean;
  onClickBackBtn?: any;
  currentStep: number;
  prospectConvert?: boolean;
}

const ModalHeader = ({
  clientDetails,
  selectedClinicianId,
  onChangeClinician,
  totalStep,
  isCompleted,
  currentStep,
  onClickBackBtn,
  prospectConvert
}: ModalHeaderProps) => {
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [selectedName, setSelectedName] = useState('');
  const [t] = useTranslation();

  const percentPerStep = Math.floor(100 / totalStep);
  const progressVal = percentPerStep * currentStep;

  const getClientName = clientDetails.clientProfiles
    .map((val: any) => {
      return getName(val);
    })
    .join(', ')
    .replace(/,(?!.*,)/gim, ' and');

  const newClientHeaderMessage = {
    title: isCompleted
      ? t('client.creation.step.complete', { name: getClientName })
      : currentStep === 1
      ? t('client.creation.step.1', { extend: isEdgeAdminView || isEdgeReceptionist ? ' for ' : '' })
      : t('client.creation.step.2', {
          name: getClientName,
          extend: isEdgeAdminView || isEdgeReceptionist ? ` for ${selectedName}` : ''
        }),
    desc: !isCompleted && currentStep === 1 ? t('client.creation.description') : ''
  };

  const convertHeaderMessage = {
    title: isCompleted
      ? t('client.convertion.step.complete', { name: getClientName })
      : currentStep === 1
      ? t('client.convertion.step.1', { extend: isEdgeAdminView || isEdgeReceptionist ? ' for ' : '' })
      : t('client.convertion.step.2', {
          name: getClientName,
          extend: isEdgeAdminView || isEdgeReceptionist ? ` for ${selectedName}` : ''
        }),
    desc: !isCompleted && currentStep === 1 ? t('client.convertion.description') : ''
  };

  const headerMessage = prospectConvert ? convertHeaderMessage : newClientHeaderMessage;

  const onSelectClinician = (selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      setSelectedName(selectedClinician.name);
      onChangeClinician(selectedClinician._id);
    }
  };

  return (
    <>
      <div id={'modalHeader'} />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {headerMessage.title}
            {(isEdgeAdminView || isEdgeReceptionist) && currentStep === 1 && (
              <div className={styles.selector}>
                <ClinicianSelect
                  includePractice
                  filterRoles={[AccessRight.Admin, AccessRight.User]}
                  selectedId={selectedClinicianId}
                  onSelect={(clinician) => onSelectClinician(clinician)}
                  allowEmpty={false}
                />
              </div>
            )}
          </div>
          <div className={styles.desc}>{headerMessage.desc}</div>
        </div>
        <div className={styles.backBtnContainer}>
          {currentStep !== 1 && !isCompleted && (
            <div id="backBtnId" className={styles.backBtn} onClick={onClickBackBtn}>
              <i className={`material-icons-outlined ${styles.icon}`}>arrow_back_ios</i>
              <span>Back</span>
            </div>
          )}
        </div>
        <div className={styles.progressContainer}>
          <div className={isCompleted ? styles.hideContent : styles.showContent}>
            <div className={styles.label}>
              STEP {currentStep} OF {totalStep}
            </div>
            <div className={styles.barContainer}>
              <ProgressBar progressVal={progressVal} barColor={styles.blueColor} />
            </div>
          </div>
          <div className={isCompleted ? styles.showContent : styles.hideContent}>
            <div className={styles.label}>COMPLETED</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalHeader;
