import { HTMLProps } from 'react';
import moment from 'moment';

import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import DateDescBox from '../../../Components/DateDescBox/DateDescBox';

import styles from './ClientClosedItem.module.scss';
import columnStyles from '../../ClosedClientColumn.module.scss';
import { getName } from 'utils/general';
import { getDayDiff } from 'utils/helpers/DateDiff';

interface ClientClosedItemProps {
  clientData: clientProfilesInterface;
  clientRecordData: clientRecordsInterface;
  recordType: clientRecordsInterface['recordType'];
  clientCreatedAt: string;
  clientUpdatedAt: string;
  onSelectClient: () => void;
}

const ClientClosedItem = ({
  clientData,
  recordType,
  clientCreatedAt,
  clientUpdatedAt,
  clientRecordData,
  onSelectClient
}: ClientClosedItemProps & HTMLProps<HTMLDivElement>) => {
  return (
    <div className={styles.container} onClick={onSelectClient}>
      <div className={columnStyles.profileSection}>
        <ClientAvatar name={getName(clientData)} avatarUrl={clientData.avatar} initialsName={clientData.initials} />
      </div>
      <div className={columnStyles.cSinceSection}>
        <DateDescBox
          title={moment(clientCreatedAt).format('D MMM YYYY')}
          desc={getDayDiff(moment(), moment(clientCreatedAt))}
        />
      </div>
      <div className={columnStyles.cSinceSection}>
        <DateDescBox title={moment(clientUpdatedAt).format('D MMM YYYY')} />
      </div>
      <div className={columnStyles.buttonSection}>
        <div>
          <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
        </div>
      </div>
    </div>
  );
};

export default ClientClosedItem;
