import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useField } from 'formik';
import styles from './AppointmentDescription.module.scss';

const AppointmentDescription = () => {
  const [{ value }, , { setValue }] = useField('description');
  return (
    <div className={classNames(styles.fieldContainer)}>
      <MaterialInput
        id={`appointmentTypeDesc`}
        label={'Add short description to aid clients when booking'}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        required
      />
    </div>
  );
};

export default AppointmentDescription;
