import edit from 'assets/images/edit.svg';
import { startCase } from 'lodash';

import styles from './UnableEditMessage.module.scss';

interface UnableEditMessageProps {
  status: string;
  isAuthor: boolean;
  onBackToEditing: () => void;
}

const getMessage = (status: string, isAuthor: boolean) => {
  const defaultMessage = (
    <>
      Unable to edit because this report is in <span className={styles.status}>{startCase(status)} Status</span>.
    </>
  );
  switch (status) {
    case 'draft':
      return isAuthor ? defaultMessage : 'Unable to edit because this report is reviewed.';
    case 'published':
      return 'Only unpublished report are able to back to editing mode.';
    default:
      return defaultMessage;
  }
};

const UnableEditMessage = ({ status, isAuthor, onBackToEditing }: UnableEditMessageProps) => {
  const showBacktoEditButton = !['draft', 'published'].includes(status);

  return (
    <div className={styles.container}>
      <img src={edit} alt={'shareIcon'} />
      <div className={styles.messageContainer}>
        <div className={styles.message}>{getMessage(status, isAuthor)}</div>
        {showBacktoEditButton && (
          <div className={styles.backButton} onClick={onBackToEditing}>
            <i className={`material-icons ${styles.editIcon}`}>edit</i>
            <div className={styles.backMessage}>Back to editing mode</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UnableEditMessage;
