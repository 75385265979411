/* eslint-disable max-depth */
import { ChangeEvent, useEffect, useState } from 'react';
import { notification } from 'antd';

import LoadingButton from 'components/v2/Button/Button';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PatientDetailsAttachmentCard from './components/PatientDetailsAttachmentCard/PatientDetailsAttachmentCard';

import NoAttachments from './no-attachments.png';

import styles from './PatientDetailsAttachments.module.scss';
import {
  getAttachmentsByRecordId,
  uploadAttachmentByRecordId,
  uploadAttachmentThumbnailByRecordId
} from 'utils/http/DocumentService/Attachments/attachments';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface Attachment {
  _id: string;
  clinicianId: string;
  clientId: string;
  title: string;
  caseNote?: {
    _id: string;
    title: string;
  };
  fileName: string;
  mimeType: string;
  fileSize: number;
  thumbnail?: string;
  createdAt: Date | string;
}

const MAX_FILE_SIZE = 19 * 1024 * 1024;

const MAX_HEIGHT = 140;
const MAX_WIDTH = 200;

const resizeAndCropImage = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    try {
      const image = new Image();

      const canvas = document.createElement('canvas');

      canvas.style.display = 'none';

      image.onload = () => {
        let resizeHeight = image.height;
        let resizeWidth = image.width;

        if (resizeHeight > MAX_HEIGHT) {
          resizeWidth = (MAX_HEIGHT / resizeHeight) * resizeWidth;
          resizeHeight = MAX_HEIGHT;
        }

        if (resizeWidth > MAX_WIDTH) {
          resizeHeight = (MAX_WIDTH / resizeWidth) * resizeHeight;
          resizeWidth = MAX_WIDTH;
        }

        canvas.height = resizeHeight;
        canvas.width = resizeWidth;

        canvas.getContext('2d')?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toDataURL();

        canvas.remove();
        image.remove();

        resolve(dataUrl);
      };

      image.src = URL.createObjectURL(file);
    } catch (ex) {
      reject(ex);
    }
  });
};

const useFetchAttachments = (token: string, recordId: string) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(true);

  const fetchAttachments = async (token: string) => {
    try {
      const callGetAttachments = await getAttachmentsByRecordId(token, recordId);
      const attachments = (await callGetAttachments.json()) as Attachment[];

      const sortByCreatedAt = attachments.sort((attObj1, attObj2) =>
        moment(attObj2.createdAt).diff(moment(attObj1.createdAt))
      );

      setAttachments(sortByCreatedAt);
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get this patient's attachments" });
    }

    setIsAttachmentsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAttachments(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { attachments, isAttachmentsLoading, setAttachments };
};

interface PatientDetailsAttachmentsProps {
  recordId: string;
  token: string;
  isReadOnly?: boolean;
}

const PatientDetailsAttachments = ({ recordId, token, isReadOnly }: PatientDetailsAttachmentsProps) => {
  const { attachments, isAttachmentsLoading, setAttachments } = useFetchAttachments(token, recordId);
  const [t] = useTranslation();

  const [addAttachmentButtonStatus, setAddAttachmentButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleDeleteAttachment = (attachmentId: string) => {
    setAttachments(attachments.filter((attachment) => attachment._id !== attachmentId));
  };

  const handleUploadAttachmentClick = () => {
    document.getElementById('patient-upload-attachment')?.click();
  };

  const handleUploadAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setAddAttachmentButtonStatus('active');

      const uploadedAttachments: Attachment[] = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > MAX_FILE_SIZE) {
          notification.error({ message: `File ${currentFile.name} is too big` });
          continue;
        }

        const payload: Record<string, any> = {};

        try {
          const uploadedAttachmentDetail = await uploadAttachmentByRecordId(token, recordId, currentFile, payload);

          if (/(image\/)\w+/g.test(currentFile.type)) {
            const attachmentId = uploadedAttachmentDetail._id || '';
            const dataUrl = await resizeAndCropImage(currentFile).catch((ex) => console.error(ex));

            if (!dataUrl) {
              notification.error({ message: 'Something went wrong while trying to compress your image.' });
              continue;
            }

            const dataArray = dataUrl.split(',');
            const mimeType = dataArray[0].match(/:(.*?);/)?.[1];
            const dataStrings = atob(dataArray[1]);
            let dataLength = dataStrings.length;
            const actualData = new Uint8Array(dataLength);

            while (dataLength--) {
              actualData[dataLength] = dataStrings.charCodeAt(dataLength);
            }

            const thumbnailFile = new File([actualData], `${currentFile.name}-thumbnail`, { type: mimeType });

            const responseWithThumbnail = await uploadAttachmentThumbnailByRecordId(
              token,
              recordId,
              attachmentId,
              thumbnailFile
            );

            uploadedAttachments.push(responseWithThumbnail);
          } else {
            uploadedAttachments.push(uploadedAttachmentDetail);
          }
        } catch (ex) {
          notification.error({ message: `Failed to upload file ${currentFile.name}` });
          continue;
        }
      }

      if (uploadedAttachments.length > 0) {
        setAddAttachmentButtonStatus('finished');

        notification.success({
          message: `Successfully uploaded ${uploadedAttachments.length} ${
            uploadedAttachments.length === 1 ? 'attachment' : 'attachments'
          }.`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });

        setAttachments([...uploadedAttachments, ...attachments]);

        setTimeout(() => setAddAttachmentButtonStatus(''), 1000);
      } else {
        setAddAttachmentButtonStatus('');
      }
    }
  };

  return (
    <div className={styles.container}>
      {isAttachmentsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.attachmentsCount}>
              {attachments.filter((attachment) => attachment._id).length} attachments saved
            </span>
            <LoadingButton
              className={styles.uploadButton}
              status={addAttachmentButtonStatus}
              disabled={!!addAttachmentButtonStatus || isReadOnly}
              onClick={handleUploadAttachmentClick}
            >
              <i className={`material-icons-outlined ${styles.icon}`}>upload</i>
              Upload new attachment
            </LoadingButton>
            <input
              className={styles.uploadInput}
              id="patient-upload-attachment"
              type="file"
              accept="image/png,image/jpeg,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
              onChange={handleUploadAttachment}
            />
          </div>
          {attachments.length === 0 ? (
            <div className={styles.noAttachments}>
              <img className={styles.image} src={NoAttachments} alt="No Invoices" />
              <div className={styles.prompt}>
                <div className={styles.text}>{t('label.no_attachment_to_client')}</div>
                <LoadingButton
                  className={styles.button}
                  status={addAttachmentButtonStatus}
                  disabled={!!addAttachmentButtonStatus || isReadOnly}
                  onClick={() => isReadOnly || handleUploadAttachmentClick()}
                >
                  <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                  Upload new attachment
                </LoadingButton>
              </div>
            </div>
          ) : (
            <div className={styles.attachmentsContainer}>
              {attachments.map((attachment) => (
                <PatientDetailsAttachmentCard
                  key={attachment._id}
                  attachment={attachment}
                  recordId={recordId}
                  onDeleteAttachment={() => handleDeleteAttachment(attachment._id)}
                  isReadOnly={isReadOnly}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PatientDetailsAttachments;
