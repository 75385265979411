import moment from 'moment';

export const getSendInviteOptions = () => {
  const now = moment().format('HH:mm');

  const options = [{ label: 'Now', value: 'now' }];

  if (now < '09:00') {
    options.push({ label: 'Today at 9AM', value: '9' });
  }

  if (now < '13:00') {
    options.push({ label: 'Today at 1PM', value: '13' });
  }

  if (now > '09:00') {
    options.push({ label: 'Tomorrow morning at 9AM', value: '9' });
  }

  if (now > '13:00') {
    options.push({ label: 'Tomorrow afternoon at 1PM', value: '13' });
  }

  options.push({ label: 'Custom schedule', value: 'custom' });

  return options;
};
