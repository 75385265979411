import styles from './AvatarProfile.module.scss';

interface AvatarProps {
  name: string;
  avatar?: string;
  initials?: string;
}

const AvatarProfile = ({ name, avatar, initials }: AvatarProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        {avatar ? (
          <img className={styles.avatar} src={avatar} alt={`${name}-avatar`} />
        ) : (
          <div className={styles.initials}>{initials}</div>
        )}
      </div>
      <div className={styles.nameContainer}>{name}</div>
    </div>
  );
};

export default AvatarProfile;
