import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Modal, notification } from 'antd';
import classnames from 'classnames';
import styles from './AddEditReferralInformation.module.scss';
import SearchableSelect, { OptionInterface } from 'components/Select/SearchableSelect/SearchableSelect';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import LoadingButton from 'components/v2/Button/Button';
import { putClientReferral } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useGetAccessToken } from 'utils/hooks/token';
import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { convertDateFormat } from 'utils/dateChecker';
import { validateReferralDate } from './validateReferralDate';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface AddEditReferralInformationProps {
  clientRecordId: string;
  sourceTags: string[];
  detail: string;
  date: string;
  visible: boolean;
  onCancel: () => void;
  fetchClientEncryptDetails: () => void;
}

const AddEditReferralInformation = ({
  clientRecordId,
  sourceTags,
  detail,
  date,
  visible,
  onCancel,
  fetchClientEncryptDetails
}: AddEditReferralInformationProps) => {
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const [selectedReferralSourceTags, setSelectedReferralSourceTags] = useState<OptionInterface[]>(
    sourceTags?.map((item) => ({ _id: item, label: item })) || []
  );
  const [referralDetail, setReferralDetail] = useState(detail);
  const [referralDate, setReferralDate] = useState(date);
  const [referralDateError, setReferralDateError] = useState<string>();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const { token } = useGetAccessToken();

  const onSelectReferralSource = (selectedOption: OptionInterface) => {
    if (selectedReferralSourceTags.some((item) => item._id === selectedOption._id)) {
      setSelectedReferralSourceTags(selectedReferralSourceTags.filter((item) => item._id !== selectedOption._id));
    } else {
      setSelectedReferralSourceTags([...selectedReferralSourceTags, selectedOption]);
    }
  };

  const handleChangeReferralDate = (referralDate: string) => {
    setReferralDate(referralDate);

    const referralDateError = validateReferralDate(referralDate);
    setReferralDateError(referralDateError);
  };

  const handleReferralDateKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = referralDate.slice(0, referralDate.length - 1);
      handleChangeReferralDate(newValue);
    }
  };

  const handleReferralDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDateFormat = convertDateFormat(e.target.value);
    handleChangeReferralDate(newDateFormat);
  };

  const handleSubmit = async () => {
    const referralDateError = validateReferralDate(referralDate);
    setReferralDateError(referralDateError);

    if (!referralDateError) {
      setSubmitStatus('active');
      const payload = {
        referralSourceTags: selectedReferralSourceTags.map((tag) => tag._id),
        referralDetail,
        ...(referralDate && { referralDate })
      };
      try {
        await putClientReferral(token, clientRecordId, payload);
        setSubmitStatus('finished');
        setTimeout(() => {
          notification.success({
            message: `Referral source has been updated.`,
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitStatus('');
          onCancel();
          fetchClientEncryptDetails();
        }, 1000);
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to edit referral source. Please try again'
        });
        setSubmitStatus('');
      }
    }
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        borderRadius: '16px'
      }}
      width={720}
      footer={null}
      visible={visible}
      destroyOnClose
      closable={false}
      className={styles.modal}
    >
      <div className={styles.header}>
        <div className={styles.title}>{`${sourceTags || detail ? 'Edit' : 'Add'} Referral Information`}</div>
        <button onClick={onCancel} className={styles.closeButton}>
          <span className="material-icons-outlined">close</span>
        </button>
      </div>

      <div className={styles.content}>
        {/* Selected Referral Source */}
        {selectedReferralSourceTags.length > 0 && (
          <div className={styles.selectedReferralTypesContainer}>
            {selectedReferralSourceTags.map((referralType, index) => (
              <div key={`referralSource${index}`} className={styles.pill}>
                {referralType.label}
              </div>
            ))}
          </div>
        )}
        {/* Referral Source dropdown */}
        <div className={styles.dropdownContainer}>
          <SearchableSelect
            className={styles.rsDropdown}
            label={'Select Referral Source'}
            options={REFERRAL_SOURCE_TAGS.map((item) => ({ _id: item, label: item }))}
            searchBoxPlaceholder={'Select Source'}
            isMultipleChoice
            selectedOptionsProps={selectedReferralSourceTags}
            onSelecteOptionProps={onSelectReferralSource}
            loadingEnable
            disabled={submitStatus !== ''}
          />
        </div>
        <div className={classnames(styles.dateContainer, referralDateError && styles.error)}>
          <div className={styles.inputContainer}>
            <MaterialInput
              label="Referral Date"
              placeholder={'DD/MM/YYYY'}
              value={referralDate}
              onKeyDown={handleReferralDateKeyDown}
              onChange={handleReferralDateChange}
              type={'tel'}
              maxLength={10}
            />
            {medicareRebateFeatureToggle && (
              <div className={styles.tooltip}>
                <HelpOutLineWithTooltips
                  id="referral-date"
                  desc="This referral date will be automatically used in future Medicare claims"
                />
              </div>
            )}
          </div>
          {referralDateError && <div className={styles.error}>{referralDateError}</div>}
        </div>
        <div className={styles.detailContainer}>
          <MaterialInput
            label={'Referral Detail'}
            value={referralDetail}
            onChange={(event) => {
              setReferralDetail(event.target.value);
            }}
            disabled={submitStatus !== ''}
          />
        </div>
        <LoadingButton
          className={styles.saveButton}
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </div>
    </Modal>
  );
};

export default AddEditReferralInformation;
