import { Skeleton } from 'antd';
import _ from 'lodash';
import { useEffect, useRef, useState, MouseEvent } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useLogout } from 'utils/hooks/logout';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import Notification from './components/Notification/Notification';
import SwitchProfile from './components/SwitchProfile/SwitchProfile';
import styles from './MyProfile.module.scss';
import { useCORDSTeacherRoutesGenerator } from 'MHH/utils/Path/CORDSRoutesGenerator';
import ModalV2 from 'components/ModalV2/ModalV2';
import { useNavigate } from 'react-router-dom';

const MyProfile = () => {
  const node = useRef<HTMLDivElement>(null);
  const { isEdgeAdminView, isEdgeAdminUser, isMentorUser } = useGetAccountPackageView();
  const { CONTROL_PANEL } = useRoutesGenerator();
  const { PROFILE } = useCORDSTeacherRoutesGenerator();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;
  const { profile, isProfileLoading } = useFetchProfile();

  const profileAvatar = isEdgeAdminView ? profile?.practice?.logo : profile?.avatar;
  const profileName = isEdgeAdminView ? profile?.practice?.name : profile?.name;
  const [showAnalyticLogout, setShowAnalyticLogout] = useState(false);

  const { logout } = useLogout();

  const handleLogout = async () => {
    setShowAnalyticLogout(true);
    setTimeout(() => {
      setShowAnalyticLogout(false);
      logout();
    }, 2000);
  };

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const setOpen = (val: boolean) => {
    setShowMenu(val);
  };

  const handleProfile = () => {
    if (isMentorUser) {
      navigate(PROFILE);
    } else {
      isEdgeAdminView ? navigate(CONTROL_PANEL.SCHOOL) : navigate(CONTROL_PANEL.BASE);
    }
    setShowMenu(false);
  };

  const handleNotification = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return isProfileLoading ? (
    <Skeleton.Button active className={styles.loading} />
  ) : (
    <div ref={node} className={styles.container}>
      <div className={styles.profileWrapper} onClick={() => setOpen(!showMenu)}>
        <div className={styles.profileAvatar}>
          {!_.isEmpty(profileAvatar) ? (
            <img src={profileAvatar} alt={'profilePic'} className={styles.profileImg} />
          ) : (
            <i className={`material-icons ${styles.defaultLogo}`}>person</i>
          )}
        </div>
        <div className={styles.profileName}>
          <div className={styles.name}>{profileName}</div>
          {!isEdgeAdminView && profile?.practice && (
            <div className={styles.practiceName}>{profile?.practice?.name}</div>
          )}
        </div>
        <div className={styles.notification}>
          {!isMentorUser && (
            <div onClick={(e) => handleNotification(e)}>
              <Notification />
            </div>
          )}
        </div>
        <div className={styles.settings}>
          <div className={showMenu ? styles.settingsBtnActive : styles.settingsBtn}>
            <i className={`material-icons-outlined`}>{'settings'}</i>
          </div>
        </div>
      </div>
      {/* Profile Dropdown */}
      <div className={showMenu ? styles.dropdownShow : styles.dropdownHide}>
        <ul className={styles.dropdownList}>
          <li className={styles.dropdownItem} onClick={handleProfile}>
            <div className={styles.itemBtn}>
              <div className={styles.text}>Update my profile</div>
              <i className={`material-icons-outlined ${styles.rightIcon}`}>arrow_forward_ios</i>
            </div>
          </li>
          <li className={styles.dropdownItem} onClick={handleLogout}>
            <div className={styles.itemBtn}>
              <div className={styles.text}>Log out</div>
              <i className={`material-icons-outlined ${styles.exitIcon}`}>exit_to_app</i>
            </div>
          </li>
          {isEdgeAdminUser && <SwitchProfile userInfo={profile} onSwitchProfile={() => setOpen(false)} />}
        </ul>
      </div>
      {ANALYTICS_URL && (
        <ModalV2
          containerClassName={styles.analyticLogoutContainer}
          isModalOpen={showAnalyticLogout}
          title={'Logout'}
          onModalClose={() => setShowAnalyticLogout(false)}
          mask={false}
        >
          <iframe title={'analytic'} width={'100%'} height={'100%'} src={`${ANALYTICS_URL}/logout`} frameBorder={0} />
        </ModalV2>
      )}
    </div>
  );
};

export default MyProfile;
