import { useEffect, useRef, useState } from 'react';
import styles from './GroupCaseNoteHeadingTemplateList.module.scss';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import { useGenerateHeadingTemplateDetails } from '../../hooks/headingTemplate';
import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

export interface HeadingTemplate {
  _id: string;
  title: string;
  body: string;
}

interface HeadingTemplateListProps {
  token: string;
  groupId: string;
  templateData: HeadingTemplate[];
  onShowHeadingTemplate: () => void;
  onSelectTemplate: (selectedHeadingTemplate: HeadingTemplate) => void;
  onRemoveTemplateItem: (removedTemplateId: string) => void;
}

const GroupCaseNoteHeadingTemplateList = ({
  token,
  groupId,
  templateData,
  onShowHeadingTemplate,
  onSelectTemplate,
  onRemoveTemplateItem
}: HeadingTemplateListProps) => {
  const { isEdgeAdminUser } = useGetAccountPackageView();
  const headingRef = useRef<HTMLDivElement>(null);
  const [showHeadingMenu, setShowHeadingMenu] = useState(false);
  const [removeHeading, setRemoveHeading] = useState({
    id: '',
    visible: false
  });

  const { generateHeadingTemplate } = useGenerateHeadingTemplateDetails(token, groupId);

  const handleClick = (e: any) => {
    if (headingRef.current?.contains(e.target)) {
      return;
    }
    setShowHeadingMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowingTemplateModal = () => {
    onShowHeadingTemplate();
    setShowHeadingMenu(false);
  };

  const onSelectHeadingTemplate = async (val: HeadingTemplate) => {
    const result = await generateHeadingTemplate(val.body);

    const afterMassageBody: HeadingTemplate = {
      ...val,
      body: result
    };
    onSelectTemplate(afterMassageBody);
    setShowHeadingMenu(false);
  };

  const onRemoveSelectedHT = async () => {
    onRemoveTemplateItem(removeHeading.id);
    setRemoveHeading({ id: '', visible: false });
  };

  const onCancelSelectedHT = () => {
    setShowHeadingMenu(false);
    setRemoveHeading({ id: '', visible: false });
  };

  return (
    <div ref={headingRef} className={styles.headingWrapper}>
      <div className={styles.headingBtn} onClick={() => setShowHeadingMenu(!showHeadingMenu)}>
        Insert Template
        <i className={`material-icons ${styles.headingIcon}`}>add</i>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showHeadingMenu ? styles.menuBoxShow : styles.menuBoxHide}>
          {templateData.length > 0 &&
            templateData.map((obj, index: number) => (
              <div className={styles.listWrapper} key={index}>
                <div className={styles.list} onClick={() => onSelectHeadingTemplate(obj)}>
                  {obj.title}
                </div>
                {isEdgeAdminUser && (
                  <div
                    className={styles.removeBtnWrapper}
                    onClick={() => setRemoveHeading({ id: obj._id, visible: true })}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>remove_circle</i>
                  </div>
                )}
              </div>
            ))}
          {isEdgeAdminUser && (
            <div className={styles.createListBtn} onClick={() => onShowingTemplateModal()}>
              + Create template
            </div>
          )}
        </div>
      </div>
      {/* TODO: Group's assessment data */}
      <HeadingAssessment isLoading={[].length > 0} assessmentData={[]} />
      <ConfirmationBox
        visible={removeHeading.visible}
        title={'Are you sure you want to delete this template?'}
        confirmBtnLabel={'Confirm'}
        cancelBtnLabel={'Cancel'}
        onSubmitConfirm={onRemoveSelectedHT}
        onSubmitCancel={onCancelSelectedHT}
      />
    </div>
  );
};

export default GroupCaseNoteHeadingTemplateList;
