import QRCode from 'qrcode.react';
import { notification } from 'antd';
import logo from 'assets/images/tacklit-icon.png';
import { clientRecordsInterface } from '../../AddPatientModalV2Interface';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ClientCreation.module.scss';
import { getName } from 'utils/general';
import { useTranslation } from 'react-i18next';

interface ClientCreationProps {
  clientRecords: clientRecordsInterface;
  onResetForm: () => void;
}

const ClientCreation = ({ clientRecords, onResetForm }: ClientCreationProps) => {
  const { CALENDAR, CLIENTS } = useRoutesGenerator();
  const [t] = useTranslation();

  const isOnboardingExist =
    clientRecords.clientProfiles && !!clientRecords.clientProfiles.filter((obj) => obj.onboardingSettings);
  const isFacilitatedExist =
    clientRecords.clientProfiles &&
    clientRecords.clientProfiles.filter(
      (obj) => isOnboardingExist && obj.onboardingSettings?.onboardingMethod === 'facilitated'
    ).length > 0;
  const singleInvitation = clientRecords.clientProfiles && clientRecords.clientProfiles.length <= 1;
  const isRecordOnly = clientRecords.clientProfiles && clientRecords.clientProfiles[0].profileType === 'recordOnly';

  const handleCopyContent = async (onBoardingLink: string) => {
    try {
      await navigator.clipboard.writeText(onBoardingLink);
      notification.success({
        message: 'Copied!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  let lang = {
    title: '',
    desc: ''
  };

  if (isFacilitatedExist) {
    lang.title = t('client.creation.complete.facilitated_exist');
    lang.desc = t('client.creation.complete.facilitated_exist.description');
  } else if (isOnboardingExist && !isRecordOnly) {
    lang.title = t('client.creation.complete.onboarding_exist');
    lang.desc = t('client.creation.complete.onboarding_exist.description');
  } else if (isOnboardingExist && isRecordOnly) {
    lang.title = t('client.creation.complete.onboarding_exist_n_record_only');
    lang.desc = t('client.creation.complete.onboarding_exist_n_record_only.description');
  } else {
    lang.title = t('client.creation.complete.other');
    lang.desc = t('client.creation.complete.other.description');
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{lang.title}</div>
      <div className={styles.desc}>
        {isOnboardingExist && isRecordOnly && !isFacilitatedExist && (
          <a
            className={styles.link}
            href={`${CLIENTS.BASE}/${clientRecords._id}/profile`}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            Link to profile
          </a>
        )}
        {lang.desc}
      </div>
      {isFacilitatedExist ? (
        <div className={styles.facilitatedContainer}>
          {clientRecords.clientProfiles
            .filter((obj) => obj.onboardingSettings?.onboardingMethod === 'facilitated')
            .map((clientObj, index) => {
              const facilitatedLink = clientObj.onboardingSettings?.facilitationUrl || '';
              return (
                <div className={styles.facilitatedWrapper} key={index}>
                  <div className={styles.clientName}>{clientObj.name}</div>
                  <div className={styles.linkContainer}>
                    <div className={styles.linkWrapper}>
                      <a className={styles.link} href={facilitatedLink} target={'_blank'} rel={'noopener noreferrer'}>
                        {facilitatedLink}
                      </a>
                      <div className={styles.copyBtn} onClick={() => handleCopyContent(facilitatedLink)}>
                        <i className={`material-icons`}>content_copy</i>
                      </div>
                    </div>
                    {!singleInvitation && (
                      <a
                        className={styles.openLinkBtn}
                        href={facilitatedLink}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <i className={`material-icons`}>add_circle_outline</i>
                        <div className={styles.label}>Open link in new tab</div>
                      </a>
                    )}
                  </div>
                  <div className={styles.qrCodeWrapper}>
                    {facilitatedLink && (
                      <QRCode
                        value={facilitatedLink}
                        size={180}
                        bgColor={styles.whiteColor}
                        fgColor={styles.blackColor}
                        level={'L'}
                        includeMargin={false}
                        renderAs={'svg'}
                        imageSettings={{
                          src: logo,
                          height: 24,
                          width: 24,
                          excavate: true
                        }}
                      />
                    )}
                  </div>
                  {singleInvitation && (
                    <div className={styles.footerButtonWrapper}>
                      <div
                        id={'createAnotherNewClient'}
                        className={styles.createNewClientBtn}
                        onClick={() => onResetForm()}
                      >
                        <i className={`material-icons`}>add_circle_outline</i>
                        <div className={styles.newClientLabel}>{t('label.create_another_new_client')}</div>
                      </div>
                      <div className={styles.singleLinkWrapper}>
                        <a
                          id={'openLinkInNewTab'}
                          className={styles.openLinkBtn}
                          href={facilitatedLink}
                          target={'_blank'}
                          rel={'noopener noreferrer'}
                        >
                          <i className={`material-icons`}>add_circle_outline</i>
                          <div className={styles.label}>Open link in new tab</div>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <div className={styles.appointmentBtnWrapper}>
            <a className={styles.openLinkBtn} href={CALENDAR.BASE} target={'_self'} rel={'noopener noreferrer'}>
              <i className={`material-icons`}>add_circle_outline</i>
              <div className={styles.label}>Schedule an appointment for {getName(clientRecords.clientProfiles[0])}</div>
            </a>
          </div>
          <div className={styles.newClientBtnWrapper}>
            <div id={'createAnotherNewClient'} className={styles.createNewClientBtn} onClick={() => onResetForm()}>
              <i className={`material-icons`}>add_circle_outline</i>
              <div className={styles.newClientLabel}>{t('label.create_another_new_client')}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientCreation;
