import { useEffect, useMemo, useState } from 'react';
import styles from './EditCoupleForm.module.scss';
import { ClientDetails, newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import ClientForm from 'components/AddPatientModalV2/formType/CoupleForm/components/ClientForm/ClientForm';
import { editClientForm } from 'components/EditClientModal/EditClientModalInterface';
import Button from 'components/v2/Button/Button';
import { useFetchSignedUpProfile } from 'components/EditClientModal/hooks/CheckSignUpProfile';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingDot from 'components/LoadingDot/LoadingDot';
// import EditProfileType from 'components/EditClientModal/components/EditProfileType/EditProfileType';
import { clientProfilesInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import classnames from 'classnames';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import { debounce } from 'lodash';
import { validateCaseId } from '../../../AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import ProfileStatusButtons from '../../components/profileStatusButtons';
import { useTranslation } from 'react-i18next';

interface EditCoupleFormProps {
  clientRecordId: string;
  clientDetail: editClientForm['clientProfiles'];
  onChangeClientField: any;
  checkValidation: boolean;
  submitStatus: '' | 'active' | 'finished';
  onClickSubmit: () => void;
  profileType: clientProfilesInterface['profileType'];
  onChangeProfileType: (val: clientProfilesInterface['profileType']) => void;
  caseIdValue: editClientForm['caseId'];
  onChangeCaseId: (value: editClientForm['caseId']) => void;
  excludeCaseIdChecking?: string;
  recordStatus: editClientForm['recordStatus'];
  onStatusProfileChange: (val: boolean) => void;
  onClickSaveClosedClient: () => void;
}

const EditCoupleForm = (props: EditCoupleFormProps) => {
  const {
    clientDetail,
    checkValidation,
    submitStatus,
    onChangeClientField,
    clientRecordId,
    profileType,
    caseIdValue,
    onChangeCaseId,
    excludeCaseIdChecking,
    onClickSubmit,
    recordStatus,
    onStatusProfileChange,
    onClickSaveClosedClient
    // onChangeProfileType,
  } = props;
  const { token } = useGetAccessToken();
  const { signedUpProfiles, isSUPLoading } = useFetchSignedUpProfile(token, clientRecordId);

  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  useEffect(() => {
    const isSameCaseIdValue = excludeCaseIdChecking === caseIdValue;
    if (checkValidation && caseIdValue && caseIdValue.length > 0 && !isSameCaseIdValue) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        email: obj._id === val._id ? val.email : obj.email,
        mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token]
  );

  const validateDuplicateCaseId = async (newCaseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(newCaseIdValue);
    }
  };

  const handleCaseIdChange = async (newCaseIdValue: string) => {
    onChangeCaseId(newCaseIdValue);
    const isSameCaseIdValue = excludeCaseIdChecking === newCaseIdValue;
    if (checkValidation && newCaseIdValue.length > 0 && !isSameCaseIdValue) {
      await validateDuplicateCaseId(newCaseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const [t] = useTranslation();

  return isSUPLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      {/* TODO
      <EditProfileType recordOnly profileTypeProps={profileType} onChangeProfileTypeProps={onChangeProfileType} /> */}
      <div className={styles.clientDetailTitle}>COUPLE PROFILE DETAILS</div>
      <div>
        <div className={styles.subTitle}>PROFILE STATUS</div>
        <ProfileStatusButtons value={recordStatus} onChange={onStatusProfileChange} />
      </div>
      {recordStatus !== RecordStatus.Closed && (
        <>
          <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
            <MaterialInput
              id={`clientCaseId`}
              label={t('form.client_id')}
              onChange={(e) => handleCaseIdChange(e.target.value)}
              isLoading={isCheckingCaseIdDuplicate}
              value={caseIdValue}
              maxLength={20}
              required
            />
            {checkValidation && duplicateCaseId && (
              <div className={styles.fieldError}>{t('form.error.duplicate_client_id')}</div>
            )}
          </div>
          <div className={styles.clientsContainer}>
            <div className={styles.clientTitle}>{t('label.client.capitalize').toUpperCase()} 1</div>
            <ClientForm
              id={`Client0`}
              clientDetail={clientDetail[0]}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              other={clientDetail[1]}
              disableEmailField={signedUpProfiles.includes(clientDetail[0]._id)}
              isFirstClient
              capabilityData={{ profileType: profileType } as newClientForm['clientCapability']}
            />
          </div>
          <div className={styles.clientsContainer}>
            <div className={styles.clientTitle}>{t('label.client.capitalize').toUpperCase()} 2</div>
            <ClientForm
              id={`Client1`}
              clientDetail={clientDetail[1]}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              other={clientDetail[0]}
              disableEmailField={signedUpProfiles.includes(clientDetail[1]._id)}
              isFirstClient={false}
              capabilityData={{ profileType: profileType } as newClientForm['clientCapability']}
            />
          </div>
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={recordStatus !== RecordStatus.Closed ? onClickSubmit : onClickSaveClosedClient}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditCoupleForm;
