import styles from './ClientOrGroupSelected.module.scss';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { Skeleton } from 'antd';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { PatientAssessment } from 'pages/Assessments/components/SendAdhocAssessmentModal/interfaces';
import ClientSelected from '../ClientSelected/ClientSelected';

interface ClientOrGroupSelectedProps {
  group?: GroupsFromAPI;
  patientAssessment?: PatientAssessment;
  participationType?: ParticipantType;
  isShowCheckBox?: boolean;
  proxyProfiles?: PatientAssessment[];
  selectedProxyProfileId?: string;
  onSelectClientProfile?: (isSelected: boolean, clientId: string) => void;
  handleProxyProfileChange?: (value: string) => void;
}

const ClientOrGroupSelected = ({
  group,
  participationType,
  patientAssessment,
  proxyProfiles,
  isShowCheckBox,
  selectedProxyProfileId,
  onSelectClientProfile,
  handleProxyProfileChange
}: ClientOrGroupSelectedProps) => {
  return (participationType === ParticipantType.OneToOne && !patientAssessment) ||
    (!group && participationType === ParticipantType.Group) ? (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingBox}>
        <Skeleton.Input active className={styles.loadingName} />
      </div>
    </div>
  ) : participationType === ParticipantType.OneToOne && patientAssessment ? (
    <ClientSelected
      patientAssessment={patientAssessment}
      participationType={participationType}
      isShowCheckBox={isShowCheckBox}
      proxyProfiles={proxyProfiles}
      selectedProxyProfileId={selectedProxyProfileId}
      onSelectClientProfile={onSelectClientProfile}
      handleProxyProfileChange={handleProxyProfileChange}
    />
  ) : (
    <div className={styles.groupWrapper}>
      <div className={styles.groupInformation}>
        <div className={styles.groupName}>{group?.name}</div>
        <div className={styles.activeMembers}>{`${group?.clientCount} Active Members`}</div>
      </div>
    </div>
  );
};

export default ClientOrGroupSelected;
