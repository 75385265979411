import { Modal, notification } from 'antd';
import classNames from 'classnames';
import { useFetchAssessmentList } from 'components/ClientOrGroupPickAssessment/getAssessmentList';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import SendAdhocAssessmentModalV2 from '../SendAdhocAssessmentModalV2/SendAdhocAssessmentModalV2';
import SDQAssessmentList from './components/SDQAssessmentList';

import styles from './CORDSSendAssessmentModal.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import CORDSAssessmentGroupSelect from './components/CORDSAssessmentGroupSelect';
import { postCordsAdHocAssessmentGroup } from 'utils/http/CheckInService/Assessment/groupAssessment';

interface Props {
  visible: boolean;
  onClose: () => void;
  defaultGroup?: Groups;
}

const CORDSSendGroupAssessmentModal = ({ visible, onClose, defaultGroup }: Props) => {
  const { token } = useGetAccessToken();

  const [expanding, setExpanding] = useState<'sendToSelected' | 'sendOtherAssessment' | ''>(
    defaultGroup ? 'sendToSelected' : ''
  );
  const [step, setStep] = useState<'selectAssessment' | 'selectGroup' | 'confirm'>('selectAssessment');
  const { assessmentList, isAssessmentLoading } = useFetchAssessmentList(token);
  const [sendBtnStatus, setSendBtnStatus] = useState<ButtonStatusType>('');

  const [selectedAssessment, setSelectedAssessment] = useState<ClinicalAssessmentStruct>();
  const [selectedGroup, setSelectedGroup] = useState<Groups | undefined>(defaultGroup);

  const isConfirmingSendToSelected = expanding === 'sendToSelected' && step === 'confirm';
  const isSelectingGroup = step === 'selectGroup';

  const onSend = async () => {
    if (!selectedGroup || !selectedAssessment) return;

    setSendBtnStatus('active');
    try {
      const payload = {
        clinicalAssessmentId: selectedAssessment.id,
        sendAt: 'now'
      };
      await postCordsAdHocAssessmentGroup(token, selectedGroup._id, payload);

      notification.success({
        message: `You have sent the ${selectedAssessment.id} to the selected group.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setSendBtnStatus('finished');
      setTimeout(() => {
        setSendBtnStatus('');
        setSelectedAssessment(undefined);
        setSelectedGroup(defaultGroup);
        setExpanding(defaultGroup ? 'sendToSelected' : '');
        handleClose();
      }, 1000);
    } catch (err) {
      console.error(err);
      notification.error({ message: 'Something went wrong while trying to send this assessment. Please try again' });
      setSendBtnStatus('');
    }
  };

  const getConfirmContent = () => {
    return (
      <div className={styles.confirmContent}>
        You will request <span className={styles.highLight}>{selectedAssessment?.id}</span> for this group.
        <div className={styles.footer}>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={sendBtnStatus !== ''} status={sendBtnStatus} onClick={onSend}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  const handleClose = () => {
    setStep('selectAssessment');
    onClose();
  };

  return (
    <>
      <Modal
        footer={null}
        title={
          <div className={styles.header}>
            <div className={classNames(selectedGroup && styles.headerTitle)}>
              Request Assessment{selectedGroup && ' for'}
              {selectedGroup && (
                <div className={styles.studentTitle}>
                  {selectedGroup?.name}
                  {!['confirm', 'selectGroup'].includes(step) && (
                    <Button
                      variant="link"
                      type="button"
                      className={styles.buttonChange}
                      onClick={() => setStep('selectGroup')}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>edit</i>
                      Change Group
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className={styles.headerRightButtons}>
              {isConfirmingSendToSelected ? (
                <div className={styles.backToAssessmentList} onClick={() => setStep('selectGroup')}>
                  <i className="material-icons-outlined">arrow_back_ios</i>Select Group
                </div>
              ) : (
                isSelectingGroup && (
                  <div className={styles.backToAssessmentList} onClick={() => setStep('selectAssessment')}>
                    <i className="material-icons-outlined">arrow_back_ios</i>Select Assessment
                  </div>
                )
              )}

              <i className="material-icons-outlined">close</i>
            </div>
          </div>
        }
        visible={visible && expanding !== 'sendOtherAssessment'}
        onCancel={handleClose}
        className={styles.modal}
        closeIcon={<></>}
      >
        <div className={classNames(styles.body, !isConfirmingSendToSelected && !isSelectingGroup && styles.visible)}>
          <div className={classNames(styles.expandItem, expanding === 'sendToSelected' && styles.expanded)}>
            <div
              className={styles.title}
              onClick={() => setExpanding(expanding === 'sendToSelected' ? '' : 'sendToSelected')}
            >
              <div className={styles.text}>Send SDQ for selected group</div>
              <div className={styles.icon}>
                {expanding === 'sendToSelected' ? (
                  <i className="material-icons-outlined">expand_more</i>
                ) : (
                  <i className="material-icons-outlined">chevron_right</i>
                )}
              </div>
            </div>
            {expanding === 'sendToSelected' && (
              <div className={styles.content}>
                <SDQAssessmentList
                  selectedItem={selectedAssessment}
                  assessmentList={assessmentList}
                  isAssessmentLoading={isAssessmentLoading}
                  onChange={(item) => {
                    setSelectedAssessment(item);
                  }}
                  onSelect={() => (selectedGroup ? setStep('confirm') : setStep('selectGroup'))}
                />
              </div>
            )}
          </div>

          <div className={classNames(styles.expandItem, expanding === 'sendOtherAssessment' && styles.expanded)}>
            <div className={styles.title} onClick={() => setExpanding('sendOtherAssessment')}>
              <div className={styles.text}>Send other assessment</div>
              <div className={styles.icon}>
                <i className="material-icons-outlined">chevron_right</i>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.selectStudent, isSelectingGroup && styles.visible)}>
          <CORDSAssessmentGroupSelect
            selectedAssessment={selectedAssessment}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            onSelect={() => (selectedAssessment ? setStep('confirm') : setStep('selectAssessment'))}
          />
        </div>
        <div className={classNames(styles.confirm, isConfirmingSendToSelected && styles.visible)}>
          {getConfirmContent()}
        </div>
      </Modal>
      {expanding === 'sendOtherAssessment' && (
        <div className={styles.content}>
          <SendAdhocAssessmentModalV2
            token={token}
            visible={expanding === 'sendOtherAssessment'}
            onCancel={() => setExpanding('')}
          />
        </div>
      )}
    </>
  );
};

export default CORDSSendGroupAssessmentModal;
