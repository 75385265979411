import { ChangeEvent, MouseEvent, useState } from 'react';
import styles from './HeadingTemplateBuilder.module.scss';
import Modal from 'components/Modal/Modal';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button from 'components/v2/Button/Button';
import WidgetTextEditor from 'components/WidgetTextEditor/WidgetTextEditor';
import { notification } from 'antd';

interface HeadingTemplateBuilderProps {
  visible: boolean;
  onSubmitTemplate: (templateName: string, templateBody: string) => Promise<void>;
  onCloseModal: () => void;
}

const HeadingTemplateBuilder = ({ visible, onCloseModal, onSubmitTemplate }: HeadingTemplateBuilderProps) => {
  const [nameOfTemplate, setNameOfTemplate] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [saveTemplateStatus, setSaveTemplateStatus] = useState<'' | 'active' | 'finished'>('');
  const [error, setError] = useState({ title: false, body: false, bodyError: '' });

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setNameOfTemplate(e.target.value);
    setError({
      ...error,
      title: e.target.value.length <= 0
    });
  };

  const isParenthesisMatching = (str: any) => {
    let stack = [];
    let open = {
      '{': '}'
    } as any;
    let closed = {
      '}': true
    } as any;

    for (let i = 0; i < str.length; i++) {
      let char = str[i];
      if (open[char]) {
        stack.push(char);
      } else if (closed[char]) {
        if (open[stack.pop()] !== char) return false;
      }
    }
    return stack.length === 0;
  };

  const checkBodyTemplate = (bodyValue: string) => {
    let bodyErrorCheck = '';
    if (bodyValue.length > 0) {
      if (!isParenthesisMatching(templateContent)) {
        bodyErrorCheck = 'Curly bracket must be close';
      } else {
        bodyErrorCheck = '';
      }
    } else {
      bodyErrorCheck = 'Template body cannot be blank';
    }

    return bodyErrorCheck;
  };

  const handleSaveTemplate = async (e: MouseEvent) => {
    e.preventDefault();
    setSaveTemplateStatus('active');
    const bodyValue = templateContent.replace(/<(?!img)(.|\n)*?>/g, '').trim();
    if (nameOfTemplate.length <= 0 || templateContent.length <= 0 || !isParenthesisMatching(templateContent)) {
      const bodyErrorCheck = checkBodyTemplate(bodyValue);
      setError({
        title: nameOfTemplate.length <= 0,
        body: bodyErrorCheck.length > 0,
        bodyError: bodyErrorCheck
      });
      setSaveTemplateStatus('');
    } else {
      try {
        await onSubmitTemplate(nameOfTemplate, templateContent);

        setSaveTemplateStatus('finished');

        notification.success({
          message: 'Template added',
          duration: 2,
          closeIcon: <span className="success">OK</span>,
          onClose: () => {
            setSaveTemplateStatus('');
            setNameOfTemplate('');
            setTemplateContent('');
            onCloseModal();
          }
        });
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to save your template.' });
      }
    }
  };

  const onChangeBody = (body: any) => {
    setTemplateContent(body);
    const value = body.replace(/<(?!img)(.|\n)*?>/g, '').trim();
    setError({
      ...error,
      body: value.length <= 0
    });
  };

  return (
    <Modal isModalOpen={visible}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Create Template</div>
          <i onClick={onCloseModal} className={`material-icons ${styles.icon}`}>
            close
          </i>
        </div>
        <div className={styles.content}>
          <div>
            <MaterialInput
              id={'templateTitle'}
              label={'Template Title'}
              className={styles.reportName}
              value={nameOfTemplate}
              onChange={onChangeTitle}
              required
            />
            {error.title && <div className={styles.error}>Please insert template title</div>}
          </div>
          <div className={styles.editorWrapper}>
            <WidgetTextEditor value={templateContent} onChangeValue={(e: any) => onChangeBody(e)} enableMention />
            {error.body && <div className={styles.error}>{error.bodyError}</div>}
          </div>
          <div className={styles.buttonWrapper}>
            <Button className={styles.button} status={saveTemplateStatus} type="button" onClick={handleSaveTemplate}>
              Save Headings Template
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HeadingTemplateBuilder;
