import { useState } from 'react';
import { Report, ReportContactDetails } from 'interfaces/Reports/report';
import moment from 'moment';

import styles from './ReportContentHeader.module.scss';
import ReportHeaderEdit from './components/ReportHeaderEdit/ReportHeaderEdit';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { combineName } from 'utils/general';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useTranslation } from 'react-i18next';

export interface ReportContentHeaderProps {
  reportName: string;
  clientRecord: Report['clientRecord'];
  clinicianDetails: Report;
  clinicianContactData: ReportContactDetails;
  onChangeContactDetails?: any;
  isPreview?: boolean;
  issueDate: Report['issueDate'];
}

const ReportContentHeader = ({
  reportName,
  clientRecord,
  clinicianDetails,
  clinicianContactData,
  onChangeContactDetails,
  isPreview,
  issueDate
}: ReportContentHeaderProps) => {
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [isEditHeadingOpen, setIsEditHeadingOpen] = useState(false);
  const [t] = useTranslation();
  const [contactDetail, setContactDetail] = useState(clinicianContactData);

  const handleChangeContactDetails = (cDInfoVal: ReportContactDetails) => {
    onChangeContactDetails(cDInfoVal);
    setContactDetail(cDInfoVal);
  };

  const primaryClientProfileName =
    clientRecord.recordType === 'child'
      ? clientRecord.clientProfiles.filter((profileType) => profileType.role === 'child')
      : clientRecord.clientProfiles;

  const clientPrimaryName = clientRecord?.clientProfiles
    ? combineName(primaryClientProfileName, clientRecord.recordType === 'couple')
    : '-';

  return (
    <div className={styles.container} id={'reportHeaderId'}>
      <div className={styles.practiceInfoWrapper}>
        <div className={styles.practiceCard}>
          {clinicianDetails.practice && (
            <>
              {clinicianDetails.practice.logo && (
                <div className={styles.practiceImgWrapper}>
                  <img src={clinicianDetails.practice.logo} alt={'practice logo'} className={styles.practiceImg} />
                </div>
              )}
              {isPreview ? (
                <div className={styles.contactDetailInfoPreview}>
                  <span className={styles.name}>{clinicianDetails.practice.name}</span>
                  <div className={styles.infoPreviewWrapper}>
                    {contactDetail.practice.address.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.address.value}</div>
                    )}
                    {contactDetail.practice.mobileNumber.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.mobileNumber.value}</div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.contactDetailInfo} onClick={() => setIsEditHeadingOpen(true)}>
                  <span className={styles.name}>{clinicianDetails.practice.name}</span>
                  <div className={!isEdgeReceptionist ? styles.infoWrapper : styles.infoWrapperNoHover}>
                    <div className={styles.editBadge}>
                      <div className={styles.editIconWrapper}>
                        <i className={`material-icons ${styles.icon}`}>mode</i>
                      </div>
                    </div>
                    {contactDetail.practice.address.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.address.value}</div>
                    )}
                    {contactDetail.practice.mobileNumber.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.mobileNumber.value}</div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!isEdgeAdminView && !isEdgeReceptionist && (
          <div className={styles.practitionerCard}>
            {clinicianDetails.clinician.avatar && (
              <div className={styles.practiceImgWrapper}>
                <img src={clinicianDetails.clinician.avatar} alt={'profile'} className={styles.img} />
              </div>
            )}
            {isPreview ? (
              <div className={styles.contactDetailInfoPreview}>
                <span className={styles.name}>{clinicianDetails.clinician.name}</span>
                <div className={styles.infoPreviewWrapper}>
                  {contactDetail.clinician.mobileNumber.isVisible && clinicianDetails.clinician.name && (
                    <div className={styles.detailInfo}>{contactDetail.clinician.mobileNumber.value}</div>
                  )}
                  {contactDetail.clinician.email.isVisible && clinicianDetails.clinician.name && (
                    <div className={styles.detailInfo}>{contactDetail.clinician.email.value}</div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.contactDetailInfo} onClick={() => setIsEditHeadingOpen(true)}>
                <span className={styles.name}>{clinicianDetails.clinician.name}</span>
                <div className={styles.infoWrapper}>
                  <div className={styles.editBadge}>
                    <div className={styles.editIconWrapper}>
                      <i className={`material-icons ${styles.icon}`}>mode</i>
                    </div>
                  </div>
                  {contactDetail.clinician.mobileNumber.isVisible && (
                    <div className={styles.detailInfo}>{contactDetail.clinician.mobileNumber.value}</div>
                  )}
                  {contactDetail.clinician.email.isVisible && (
                    <div className={styles.detailInfo}>{contactDetail.clinician.email.value}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.patientInfoWrapper}>
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>{t('form.client_name')}:</span> {clientPrimaryName}
        </div>
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>Report Name:</span> {reportName || '-'}
        </div>
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>Date of report:</span>{' '}
          {moment(issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}
        </div>
      </div>
      {!isEdgeReceptionist && (
        <ReportHeaderEdit
          visible={isEditHeadingOpen}
          onCloseModal={() => setIsEditHeadingOpen(false)}
          clinicianDetails={clinicianDetails}
          clinicianContactDetails={contactDetail}
          onChangeContactDetails={handleChangeContactDetails}
        />
      )}
    </div>
  );
};

export default ReportContentHeader;
