import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import { useGetAccessToken } from 'utils/hooks/token';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './StudentDetails.module.scss';
import { useFetchClientRecordById } from 'pages/PatientDetails/hooks/GetClientRecord';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import StudentDetailContent from './components/StudentDetailContent/StudentDetailContent';
import { useFetchTeacherPendingAssessments } from '../../../../pages/PatientDetails/components/PatientDetailsContent/hooks/actions';

export interface StudentDetailsParamInterface {
  recordId: string;
  assessmentId?: string;
}

const StudentDetails = () => {
  const path = useParams() as unknown as StudentDetailsParamInterface;

  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const { clientRecordData, isClientRecordLoading } = useFetchClientRecordById(token, path.recordId, accountId, true);
  const { assessmentList, isAssessmentListLoading, fetchAssessmentList } = useFetchTeacherPendingAssessments(
    token,
    path.recordId
  );

  return (
    <HelmetWrapper title={'CORDS - Student Details'}>
      <ContentLayout whiteBg>
        <div className={styles.container}>
          {isClientRecordLoading ? (
            <div className={styles.loadingWrapper}>
              <div className={styles.sideBarLoading}>
                <Skeleton active />
              </div>
              <div className={styles.contentLoading}>
                <Skeleton active />
              </div>
            </div>
          ) : (
            <StudentDetailContent
              assessmentList={assessmentList}
              isAssessmentListLoading={isAssessmentListLoading}
              refetchAssessmentList={fetchAssessmentList}
              isClientLoading={isClientRecordLoading}
              clientRecordData={clientRecordData}
            />
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default StudentDetails;
