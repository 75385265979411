import { Modal, notification } from 'antd';
import classNames from 'classnames';
import Loading from 'components/Loading/Loading';
import Button from 'components/v2/Button/Button';
import { ChangeEvent, createRef, RefObject, useEffect, useRef, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import { useGetAccessToken } from 'utils/hooks/token';

import ImageCropper from './ImageCropper';
import styles from './ImagesInput.module.scss';

interface ImagesInputProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onUpload: (values: File[]) => void;
  accept?: string;
  maxImages?: number;
  clearOnClose?: boolean;
  defaultSize?: [number, number];
  originalSize?: boolean;
  check?: (f: File) => boolean;
  initFiles?: File[];
  onRemoveFile?: (f: File) => void;
  initUrls?: string[];
  resize?: boolean;
}

const ImagesInput = ({
  title,
  visible,
  onUpload,
  onClose: propsOnClose,
  accept,
  maxImages,
  clearOnClose,
  defaultSize,
  originalSize,
  check,
  initFiles,
  onRemoveFile,
  initUrls,
  resize
}: ImagesInputProps) => {
  const { token } = useGetAccessToken();
  const [resultFiles, setResultFiles] = useState<File[]>([]);
  const [urls, setUrls] = useState<(string | null)[]>([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  const inputRef = createRef<HTMLInputElement>();

  const handleRemoveFile = (index: number, isCleanUp?: boolean) => {
    if (urls[index]) {
      URL.revokeObjectURL(urls[index]!);
      urls.splice(index, 1);
      const removedFile = resultFiles.splice(index, 1)[0];
      setUrls([...urls]);
      setResultFiles([...resultFiles]);

      if (index <= editingIndex) {
        setEditingIndex(editingIndex - 1);
      }
      setActiveEditorRef(index, undefined);
      if (isCleanUp) {
        return;
      }
      onRemoveFile?.(removedFile);
    }
  };

  const handleUploadNew = (index: number) => {
    handleRemoveFile(index, true);
    setEditingIndex(-1);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleAddFile = (e?: ChangeEvent<HTMLInputElement>, files?: File[]) => {
    let fileList = Array.from(e?.target.files || files || []);
    if (check) {
      const allValid = fileList.reduce((res, item) => res && check(item), true);
      if (!allValid) {
        notification.info({
          message: 'Some items does not match requirements',
          duration: 6000
        });
        fileList = fileList.filter((item) => check(item));
      }
    }

    if (maxImages === 0) {
      return;
    }
    setUrls([
      ...urls,
      ...fileList.slice(0, maxImages ? maxImages - urls.length : undefined).map((i) => URL.createObjectURL(i))
    ]);
    setResultFiles([...resultFiles, ...fileList.slice(0, maxImages ? maxImages - urls.length : undefined)]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    if (maxImages === 1) {
      setEditingIndex(0);
    }
  };

  const handleAddInitUrls = () => {
    let tUrls: string[] = [];
    let tFiles: File[] = [];
    initUrls!.forEach((url, index) => {
      const filename = url.replace('https://storage.googleapis.com/', '').split('/').slice(1).join('/');
      tFiles = [...tFiles, new File([], filename)];
      tUrls = [...tUrls, url];
    });
    if (maxImages === 0) {
      return;
    }
    setUrls([...urls, ...tUrls]);
    setResultFiles([...resultFiles, ...tFiles]);
    if (maxImages === 1) {
      setEditingIndex(0);
    }
  };

  const getFileFromCanvas = async (editorRef: RefObject<ReactAvatarEditor>, filename: string, fileType?: string) => {
    try {
      if (editorRef) {
        const canvas = editorRef.current?.getImageScaledToCanvas();

        const res = new Promise((resolve, reject) =>
          canvas?.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], `${filename}`, { type: fileType });
              resolve(file);
            }
            reject(null);
          })
        );
        return res as Promise<File>;
      }
    } catch (ex) {
      return null;
    }
  };

  const handleUpload = async () => {
    if (editorsRefs.current) {
      const files = await Promise.all(
        editorsRefs.current.map((i, index) => getFileFromCanvas(i, resultFiles[index].name, resultFiles[index].type))
      );
      const filtered = files.filter((item) => item) as File[];
      onUpload(filtered);
      onClose();
    }
  };

  const onClose = !clearOnClose
    ? propsOnClose
    : () => {
        urls.forEach((i, index) => {
          handleRemoveFile(index, true);
        });
        propsOnClose();
      };

  const editorsRefs = useRef<RefObject<ReactAvatarEditor>[]>([]);

  const setActiveEditorRef = (index: number, ref: RefObject<ReactAvatarEditor> | undefined) => {
    if (ref) {
      editorsRefs.current[index] = ref;
    } else {
      editorsRefs.current.splice(index, 1);
    }
  };

  useEffect(() => {
    return () => urls.forEach((i) => i && URL.revokeObjectURL(i));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      if (initFiles) {
        handleAddFile(undefined, initFiles);
      } else if (Array.isArray(initUrls)) {
        handleAddInitUrls();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const isEditing = editingIndex >= 0 && editingIndex < urls.length;
  const isMulti = !maxImages || maxImages > 1;
  const id = useRef((Math.random() * 10000) % 10000);
  return (
    <Modal
      title={<div className={styles.modalTitle}>{title}</div>}
      visible={visible}
      className={styles.modal}
      width={!isMulti ? 'min(500px, 90%)' : '90%'}
      footer={null}
      onCancel={onClose}
    >
      {loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <>
          {isMulti && <div className={styles.tip}>Click on the image to edit photo.</div>}
          <div className={classNames(styles.body, urls.length === 0 && styles.init, !isMulti && styles.singleFile)}>
            <div className={styles.preview}>
              {isEditing && isMulti && (
                <div className={styles.closeEditor} onClick={() => setEditingIndex(-1)}>
                  <i className="material-icons">close</i>
                </div>
              )}
              <div className={classNames(styles.items, !isEditing && styles.fullWidth)}>
                {urls.map(
                  (i, index) =>
                    i && (
                      <div
                        className={classNames(styles.item, editingIndex === index ? styles.edittor : '')}
                        onClick={() => setEditingIndex(index)}
                      >
                        <ImageCropper
                          edittorClassName={styles.itemEdittor}
                          key={index}
                          imgSrc={i}
                          handleRemove={() => handleRemoveFile(index)}
                          handleUploadNew={() => handleUploadNew(index)}
                          borderRadius={0}
                          showControl={editingIndex === index}
                          showSize={!(isEditing && editingIndex === index)}
                          showRemove={isEditing}
                          setRef={(ref: RefObject<ReactAvatarEditor>) => setActiveEditorRef(index, ref)}
                          defaultSize={defaultSize}
                          originalSize={originalSize}
                          onLoadImgSrc={() => setLoading(false)}
                          resize={resize}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
            <label
              htmlFor={'upload-image' + id.current}
              className={styles.inputLabel}
              hidden={(!isMulti && urls.length > 0) || urls.length === maxImages}
            >
              <input
                hidden
                id={'upload-image' + id.current}
                ref={inputRef}
                type="file"
                multiple={isMulti}
                accept={accept}
                onChange={handleAddFile}
              />
              {urls.length === 0 ? (
                <div className={styles.uploadIcon}>
                  <i className="material-icons-outlined">image</i>
                </div>
              ) : undefined}
              {urls.length === 0 ? 'Click to upload photo' : 'Click to add photo'}
            </label>
            {maxImages === 0 && <div className={styles.maxImageZeroLabel}>You're to upload 0 more images</div>}
            <div className={styles.footer}>
              <Button onClick={handleUpload} disabled={urls.length === 0} className={styles.uploadButton}>
                Upload
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ImagesInput;
