import { useEffect, useState } from 'react';
import styles from './Members.module.scss';
import MemberFilter from './components/MemberFilter/MemberFilter';
import { toggleListType } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import MemberList from './components/MemberList/MemberList';
import { useFetchInterventionMemberList } from './hooks/getInterventionMemberList';
import { useGetAccessToken } from 'utils/hooks/token';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  AttachedClientRecordStatus,
  InterventionMemberListFilterParameter,
  Interventions
} from 'MHH/pages/Interventions/Interfaces/Interventions';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { SortType } from 'components/TableList/SortBtn/SortBtn';

interface MembersProps {
  interventionDetails: Interventions;
}

const Members = ({ interventionDetails }: MembersProps) => {
  const navigate = useNavigate();
  const { INTERVENTIONS } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const { interventionId = '' } = useParams<{ interventionId: string }>();
  const location = useLocation();
  const queryParam: InterventionMemberListFilterParameter = queryString.parse(location.search);

  const [searchQueryParam, setSearchQueryParam] = useState<InterventionMemberListFilterParameter>({
    q: queryParam.q || '',
    page: queryParam.page && !isNaN(parseInt(queryParam.page, 10)) ? queryParam.page : '1',
    perPage: queryParam.perPage && !isNaN(parseInt(queryParam.perPage, 10)) ? queryParam.perPage : '20',
    sortByClientName:
      queryParam.sortByClientName && Object.values(SortType).includes(queryParam.sortByClientName)
        ? queryParam.sortByClientName
        : undefined,
    sortByDateJoined:
      queryParam.sortByDateJoined && Object.values(SortType).includes(queryParam.sortByDateJoined)
        ? queryParam.sortByDateJoined
        : queryParam.sortByClientName && Object.values(SortType).includes(queryParam.sortByClientName)
        ? undefined
        : -1,
    status:
      queryParam.status &&
      Object.values(AttachedClientRecordStatus).includes(queryParam.status as AttachedClientRecordStatus)
        ? queryParam.status
        : AttachedClientRecordStatus.All
  });

  const [refreshList, setRefreshList] = useState(0);
  const { interventionMembers, isInterventionMembersLoading } = useFetchInterventionMemberList(
    token,
    interventionId,
    searchQueryParam,
    refreshList
  );

  const setNewParam = (newParamValue: InterventionMemberListFilterParameter) => {
    setSearchQueryParam(newParamValue);
    const paramStringify = queryString.stringify(newParamValue);
    navigate(`${INTERVENTIONS.BASE}/${interventionId}/members?${paramStringify}`);
  };

  useEffect(() => {
    if (token && location.search === '?refetch') {
      setNewParam({
        ...searchQueryParam,
        page: '1'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChangeStatus = (statusList: toggleListType) => {
    const newParam = {
      ...searchQueryParam,
      page: '1',
      status: statusList.id
    };
    setNewParam(newParam);
  };

  const handleSearch = (searchValue: string) => {
    if (searchQueryParam.q !== searchValue) {
      const newParam = {
        ...searchQueryParam,
        page: '1',
        q: searchValue
      };
      setNewParam(newParam);
    }
  };

  const handleChangeDateJoinedSort = (sortName: SortType) => {
    const newParam = {
      ...searchQueryParam,
      sortByDateJoined: sortName,
      sortByClientName: undefined
    };
    setNewParam(newParam);
  };

  const handleChangeNameSort = (sortName: SortType) => {
    const newParam = {
      ...searchQueryParam,
      sortByClientName: sortName,
      sortByDateJoined: undefined
    };
    setNewParam(newParam);
  };

  const handleChangePerPage = (perPageValue: number) => {
    if (parseInt(searchQueryParam.perPage as string, 10) !== perPageValue) {
      const newParam = {
        ...searchQueryParam,
        page: '1',
        perPage: perPageValue.toString()
      };
      setNewParam(newParam);
    }
  };

  const handlePageChange = (pageValue: number) => {
    const newParam = {
      ...searchQueryParam,
      page: pageValue.toString()
    };
    setNewParam(newParam);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Members</div>
      <div className={styles.content}>
        <MemberFilter
          interventionMembers={interventionMembers}
          isInterventionMembersLoading={isInterventionMembersLoading}
          searchValue={searchQueryParam.q || ''}
          selectedStatus={searchQueryParam.status as AttachedClientRecordStatus}
          handleChangeStatus={handleChangeStatus}
          handleSearch={handleSearch}
        />
        <MemberList
          interventionId={interventionId}
          interventionDetails={interventionDetails}
          interventionMembers={interventionMembers}
          isInterventionMembersLoading={isInterventionMembersLoading}
          selectedPerPage={parseInt(searchQueryParam.perPage as string, 10)}
          selectedSortDateJoined={searchQueryParam.sortByDateJoined}
          selectedSortName={searchQueryParam.sortByClientName}
          selectedPage={parseInt(searchQueryParam.page as string, 10)}
          onChangeDateJoinedSort={handleChangeDateJoinedSort}
          onChangeNameSort={handleChangeNameSort}
          onChangePerPage={handleChangePerPage}
          onChangePage={handlePageChange}
          onRefreshList={() => {
            setRefreshList(refreshList + 1);
          }}
        />
      </div>
    </div>
  );
};

export default Members;
