import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './GroupDetails.module.scss';
import { useParams } from 'react-router-dom';
import { GroupDetailsId, GroupDetailsTab } from '../Interfaces/Groups';
import GroupSideMenu from './components/GroupSideMenu/GroupSideMenu';
import GroupDetailsHeader from './components/GroupDetailsHeader/GroupDetailsHeader';
import { useFetchGroupDetails } from './hooks/getGroupDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import GroupContent from './components/GroupContent/GroupContent';
import { Skeleton } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchPsychometricList } from './hooks/getPsychometricMenuList';
import { useFetchConsentMenuList } from './hooks/getConsentFormsMenu';
import { useFetchGroupSurveyResponseList } from './hooks/getSurveyMenuList';

const GroupDetails = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const path = useParams<{ [key in keyof GroupDetailsId]: string | undefined }>();
  const groupId = path.groupId ?? '';
  const groupTab = (path.groupTab as GroupDetailsTab) ?? GroupDetailsTab.Details;
  const contentId = path.contentId ?? '';
  const childContentId = path.childContentId ?? '';

  const { groupDetails, groupTagList, isGroupDetailsLoading, fetchGroupDetails } = useFetchGroupDetails(
    token,
    groupId,
    accountId
  );
  const { profile, isProfileLoading } = useFetchProfile();
  const { surveyResponseList, isSurveyResponseListLoading, fetchGroupSurveyResponseList } =
    useFetchGroupSurveyResponseList(token, groupId);
  const { consentFormMenuList, isConsentFormMenuListLoading } = useFetchConsentMenuList(token, groupId);

  const { psychometricList, isPsychometricListLoading, fetchPsychometricList } = useFetchPsychometricList(
    token,
    groupId
  );

  const allowEditAssigned = isEdgeAdminView || auth0ClinicianId === groupDetails?.leadClinicianAuth0Id;

  return (
    <HelmetWrapper title={'CORDS - Group Details'}>
      <ContentLayout>
        <div className={styles.container}>
          <GroupDetailsHeader
            groupDetails={groupDetails}
            isGroupDetailsLoading={isGroupDetailsLoading || isConsentFormMenuListLoading}
            refetchPsychometricList={() => fetchPsychometricList(token)}
            refetchGroupSurveyResponseList={() => fetchGroupSurveyResponseList(token)}
            currentGroupTab={groupTab}
          />
          {isProfileLoading || !groupDetails || isConsentFormMenuListLoading ? (
            <div className={styles.loadingWrapper}>
              <div className={styles.sideBarLoading}>
                {[...Array(3)].map((obj, i) => (
                  <div key={i} className={styles.loadingCard}>
                    <Skeleton.Avatar active />
                    <div className={styles.loadingDescWrapper}>
                      <Skeleton.Input active className={styles.loadingTitle} />
                      <Skeleton.Input active className={styles.loadingDesc} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.contentLoading}>
                <Skeleton active />
              </div>
            </div>
          ) : (
            <div className={styles.content}>
              <GroupSideMenu
                groupId={groupId}
                activeGroupTab={groupTab}
                contentId={contentId}
                childContentId={childContentId}
                psychometricList={psychometricList}
                psychometricLoading={isPsychometricListLoading}
                surveyFormMenuList={surveyResponseList}
                surveyResponseListLoading={isSurveyResponseListLoading}
                allowEditAssigned={allowEditAssigned}
                consentFormMenuList={consentFormMenuList}
              />
              <GroupContent
                groupDetails={groupDetails}
                isGroupDetailsLoading={isGroupDetailsLoading}
                groupTagList={groupTagList}
                activeGroupTab={groupTab}
                contentId={contentId}
                childContentId={childContentId}
                fetchGroupDetails={fetchGroupDetails}
                profile={profile}
                psychometricMenuList={psychometricList}
                surveyFormMenuList={surveyResponseList}
                surveyResponseListLoading={isSurveyResponseListLoading}
                allowEditAssigned={allowEditAssigned}
                isEdgeReceptionist={isEdgeReceptionist}
                consentFormMenuList={consentFormMenuList}
                refetchPsychometricList={() => {
                  fetchPsychometricList(token);
                }}
              />
            </div>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default GroupDetails;
