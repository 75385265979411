import styles from './Tags.module.scss';

const Tags = ({ tags }: { tags: string[] }) => (
  <div className={styles.tags}>
    {tags.map((i, index) => (
      <div className={styles.tag} key={index}>
        {i}
      </div>
    ))}
  </div>
);

export default Tags;
