import OptionLabel from 'components/OptionLabel/OptionLabel';
import Radio from 'components/Radio/Radio';
import { useTranslation } from 'react-i18next';
import { StageForm, StageType } from '../../../../../../interface';
import styles from './StageFormType.module.scss';

interface StageFormProps {
  id: string;
  stageType?: StageForm['stageType'];
  onChangeStageType: (value: StageForm['stageType']) => void;
  disabled?: boolean;
  showLockIcon?: boolean;
}

const StageFormType = ({ id, stageType, onChangeStageType, disabled, showLockIcon }: StageFormProps) => {
  const handleGroupStageType = (value: StageForm['stageType']) => {
    onChangeStageType(value);
  };
  const [t] = useTranslation();

  return (
    <div id={id} className={styles.container}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Stage type</div>
        {disabled && (
          <div className={styles.buttonWrapper}>
            <i className={`material-icons-outlined ${styles.lockIcon}`}>lock</i>
          </div>
        )}
      </div>
      <Radio
        name={id}
        disabled={disabled}
        options={[
          {
            value: 'sequential',
            label: (
              <OptionLabel
                isSelected={stageType === StageType.Sequential}
                title={t('group_pathway.stage_options.sequential')}
                desc={t('group_pathway.stage_options.sequential.description')}
                descClassName={disabled ? styles.disabled : ''}
                titleClassName={disabled ? styles.disabled : ''}
                highlightTitle
              />
            )
          },
          {
            value: 'anytime',
            label: (
              <OptionLabel
                isSelected={stageType === StageType.Anytime}
                title={t('group_pathway.stage_options.anytime')}
                desc={t('group_pathway.stage_options.anytime.description')}
                descClassName={disabled ? styles.disabled : ''}
                titleClassName={disabled ? styles.disabled : ''}
                highlightTitle
              />
            )
          }
        ]}
        value={stageType}
        vertical
        onChange={(e) => handleGroupStageType(e.target.value as StageForm['stageType'])}
      />
    </div>
  );
};

export default StageFormType;
