import styles from './PaginationList.module.scss';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import ReactPaginate from 'react-paginate';

interface PaginationListProps {
  paging: PractitionersListing['paging'];
  onPageChange: (paging: string) => void;
}

const PaginationList = ({ paging, onPageChange }: PaginationListProps) => {
  const onClickPageNumber = (value: any) => {
    onPageChange(value);
  };

  return (
    <ReactPaginate
      previousLabel={
        <span className={styles.label}>
          <FontAwesomeIcon className={styles.arrowLeft} icon={faAngleLeft} />
        </span>
      }
      nextLabel={
        <span className={styles.label}>
          <FontAwesomeIcon className={styles.arrowRight} icon={faAngleRight} />
        </span>
      }
      breakLabel={'...'}
      pageCount={Math.ceil(paging.totalItem / paging.perPage) || 1}
      forcePage={paging.page - 1}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={(val) => onClickPageNumber(val.selected + 1)}
      containerClassName={styles.pagination}
      activeClassName={styles.active}
      previousClassName={styles.btn}
      nextClassName={styles.btn}
    />
  );
};

export default PaginationList;
