import { FormListingInterface } from 'interfaces/Form/FormInterface';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import { NEW_ASSESSMENT_TEMPORARY_ID } from '../../../../../AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import styles from './AssessmentColumnBase.module.scss';

interface AssessmentColumnProps {
  name: string;
  columnType: string;
  data: FormListingInterface[];
  showCreateCard?: boolean;
  children?: any;
  viewOnly?: boolean;
  id: string;
}

const AssessmentColumnBase = ({
  name,
  columnType,
  data,
  showCreateCard,
  children,
  viewOnly,
  id
}: AssessmentColumnProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView } = useGetAccountPackageView();
  const [t] = useTranslation();

  const { viewCaseNotesTemplateFeature } = useGetFeatureToggle();

  const node = useRef<HTMLDivElement>(null);
  const [showCreateMenu, setShowCreateMenu] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const setOpen = (val: boolean) => {
    setShowCreateMenu(val);
  };

  const onSelectNewFa = () => {
    navigate(`${FORMS.BASE}/survey/${NEW_ASSESSMENT_TEMPORARY_ID}`);
  };

  const onSelectNewCa = () => {
    navigate(`${FORMS.BASE}/microCheckIn/newMCI`);
  };

  const onHandleNewConsentForm = () => {
    navigate(`${FORMS.BASE}/consent/new`);
  };

  const onHandleNewCaseNote = () => {
    navigate(`${FORMS.BASE}/caseNotes/new`);
  };

  const showOAc = isEdgeAdminView || isNormalUserView;
  const showMCIc = isEdgeUserView || isNormalUserView;
  const showConsent = isEdgeAdminView || isNormalUserView;

  return (
    <div className={styles.container} id={id}>
      <div className={styles.header}>
        <div className={styles.title}>
          {name}
          {viewOnly && <i className={`material-icons ${styles.editOffIcon}`}>edit_off</i>}
        </div>
        <div>
          {data.length || 0} {columnType}
        </div>
      </div>
      <div className={styles.content}>
        {children}
        {showCreateCard && (
          <div className={styles.cNewCardContainer}>
            <div ref={node}>
              <div className={styles.addNewBtnWrapper} onClick={() => setOpen(!showCreateMenu)}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                Create new template
              </div>
              <div className={styles.menuWrapper}>
                <div className={showCreateMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                  {showOAc && (
                    <div className={styles.listBox} onClick={onSelectNewFa}>
                      <div className={styles.listTitle}>{t('assessment_table.survey')}</div>
                      <div className={styles.listDesc}>{t('assessment_table.survey.description')}</div>
                    </div>
                  )}
                  {showMCIc && (
                    <div className={styles.listBox} onClick={onSelectNewCa}>
                      <div className={styles.listTitle}>{t('assessment_table.micro_checkin')}</div>
                      <div className={styles.listDesc}>{t('assessment_table.micro_checkin.description')}</div>
                    </div>
                  )}
                  {showConsent && (
                    <div className={styles.listBox} onClick={onHandleNewConsentForm}>
                      <div className={styles.listTitle}>{t('assessment_table.consent_form')}</div>
                      <div className={styles.listDesc}>{t('assessment_table.consent_form.description')}</div>
                    </div>
                  )}
                  {viewCaseNotesTemplateFeature && (
                    <div className={styles.listBox} onClick={onHandleNewCaseNote}>
                      <div className={styles.listTitle}>Case Note</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentColumnBase;
