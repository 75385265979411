import styles from './SortingFilter.module.scss';

const SortingFilter = ({ onClickSort, selectedValue, isSortAsc }: any) => {
  const onClickSortBtn = () => {
    onClickSort();
  };

  return (
    <div>
      <div className={styles.filterWrapper}>
        <div className={styles.filterLabel}>Sorted by:</div>
        <div className={styles.filter} onClick={() => onClickSortBtn()}>
          {selectedValue}
          <i className={`material-icons ${styles.icon}`}>{isSortAsc ? 'arrow_drop_down' : 'arrow_drop_up'}</i>
        </div>
      </div>
    </div>
  );
};

export default SortingFilter;
