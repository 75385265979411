import { useEffect, useRef, useState } from 'react';
import { PathwayStageItem } from '../../../../interface';
import EditStageModal from '../../../StageForm/EditStageModal/EditStageModal';
import styles from './StageActionMenu.module.scss';

interface StageActionMenuProps {
  groupId: string;
  stageItem: PathwayStageItem;
  onRefetchList?: () => void;
  onOpenDeleteModal: (stageItemToBeDeleted: PathwayStageItem) => void;
}

const StageActionMenu = ({ groupId, stageItem, onRefetchList, onOpenDeleteModal }: StageActionMenuProps) => {
  const [menuExpand, setMenuExpand] = useState(false);
  const menuNode = useRef<HTMLDivElement>(null);
  const [editStageModal, setEditStageModal] = useState<boolean>(false);

  const handleExpandMenu = () => {
    setMenuExpand(!menuExpand);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <div ref={menuNode} className={styles.container}>
      <div onClick={handleExpandMenu} className={styles.buttonWrapper}>
        <i className={`material-icons ${styles.vertIcon}`}>more_vert</i>
      </div>
      <div className={styles.menuWrapper}>
        <div className={menuExpand ? styles.menuShow : styles.menuHide}>
          <div
            className={styles.itemCard}
            onClick={() => {
              setMenuExpand(false);
              setEditStageModal(true);
            }}
          >
            <i className={`material-icons-outlined ${styles.itemIcon}`}>edit</i>
            <div className={styles.label}>Edit Stage</div>
          </div>
          <EditStageModal
            groupId={groupId}
            showModal={editStageModal}
            setShowModal={() => setEditStageModal(false)}
            stageFormFieldData={stageItem}
            onSuccessEdit={() => onRefetchList && onRefetchList()}
          />
          <div
            className={styles.itemCard}
            onClick={() => {
              setMenuExpand(false);
              onOpenDeleteModal(stageItem);
            }}
          >
            <i className={`material-icons-outlined ${styles.itemIcon}`}>delete</i>
            <div className={styles.label}>Delete Stage</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageActionMenu;
