export enum AssessmentType {
  Survey = 'survey',
  MicroCheckIn = 'microCheckIn'
}

export interface FirstAssessmentStruct {
  id: string;
  name: string;
  description?: string;
  isPublished: boolean;
  isDefault: boolean;
  assessmentType: AssessmentType.Survey;
  clinicalAssessmentIds?: string[];
  clinicalAssessments?: { id: string; isChecked: boolean }[];
  questionSets: QuestionSetStruct[];
  createdAt: Date;
  updatedAt: Date;
}

export interface MicroCheckInAssessmentStruct {
  id: string;
  name: string;
  description: string;
  isPublished: boolean;
  isDefault: boolean;
  assessmentType: AssessmentType.MicroCheckIn;
  questionSet: QuestionSetStruct;
  createdAt: string;
  updatedAt: string;
}

interface QuestionSetStruct {
  id?: string;
  sectionName: string;
  questions: QuestionStruct[];
}

export interface QuestionStruct {
  id?: string;
  isChecked: boolean;
  stem?: string;
}
