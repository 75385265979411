import Button, { ButtonProps } from 'components/Button/Button';

import styles from './SubmitButton.module.scss';
import classNames from 'classnames';

const SubmitButton = ({ ...props }: ButtonProps) => {
  return <Button {...props} className={classNames(styles.button, props.className)} />;
};

export default SubmitButton;
