import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton, notification } from 'antd';
import classNames from 'classnames';
import AddPatientModalV2 from 'components/AddPatientModalV2/AddPatientModalV2';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useTranslation } from 'react-i18next';
import {
  CommunicationPreference,
  clientProfilesInterface,
  clientRecordsInterface
} from 'interfaces/Clients/clientsRecord';
import { ReactNode, useEffect, useState } from 'react';
import { getName } from 'utils/general';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';

import Button from '../Button/Button';
import ProfileBadge from '../ProfileBadge/ProfileBadge';
import styles from './PatientSelect.module.scss';

const useFetchBookablePatients = (signUpOnly?: boolean, communicationPreference?: CommunicationPreference[]) => {
  const { getAccessTokenSilently } = useAuth0();

  const [bookablePatients, setBookablePatients] = useState<clientRecordsInterface[]>([]);
  const [isBookablePatientsLoading, setIsBookablePatientsLoading] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();

  const fetchBookablePatients = async () => {
    setIsBookablePatientsLoading(true);

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      if (isEdgeAdminView && accountId) {
        const callGetActivePatients = await getClientsRecordByAccountId(
          token,
          accountId,
          `?recordStatus=active${signUpOnly ? `&hasSignedUp=${signUpOnly}` : ''}${
            communicationPreference?.length ? `&communicationPreference=${communicationPreference.join(',')}` : ''
          }`
        );

        const data: { clientRecords: clientRecordsInterface[] } = await callGetActivePatients.json();

        if (communicationPreference?.includes(CommunicationPreference.Email)) {
          setBookablePatients(
            data.clientRecords.filter((clientRecord) =>
              clientRecord.clientProfiles.some((clientProfile) => clientProfile.hasEmail)
            )
          );
        } else if (communicationPreference?.includes(CommunicationPreference.SMS)) {
          setBookablePatients(
            data.clientRecords.filter((clientRecord) =>
              clientRecord.clientProfiles.some((clientProfile) => clientProfile.hasMobileNumber)
            )
          );
        }
      } else {
        const callGetActivePatients = await getClientsRecord(
          token,
          `?recordStatus=active${signUpOnly ? `&hasSignedUp=${signUpOnly}` : ''}${
            communicationPreference?.length ? `&communicationPreference=${communicationPreference.join(',')}` : ''
          }`
        );

        const data: { clientRecords: clientRecordsInterface[] } = await callGetActivePatients.json();

        if (communicationPreference?.includes(CommunicationPreference.Email)) {
          setBookablePatients(
            data.clientRecords.filter((clientRecord) =>
              clientRecord.clientProfiles.some((clientProfile) => clientProfile.hasEmail)
            )
          );
        } else if (communicationPreference?.includes(CommunicationPreference.SMS)) {
          setBookablePatients(
            data.clientRecords.filter((clientRecord) =>
              clientRecord.clientProfiles.some((clientProfile) => clientProfile.hasMobileNumber)
            )
          );
        }
      }
    } catch (ex) {
      console.error(ex);

      notification.error({ message: t('form.error.failed_to_retrieve_client_record') });
    }

    setIsBookablePatientsLoading(false);
  };

  useEffect(() => {
    fetchBookablePatients();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { setBookablePatients, bookablePatients, isBookablePatientsLoading };
};

interface PatientSelectProps {
  compact?: boolean;
  searchable?: boolean;
  noItemLabel?: ReactNode;
  handleSelectPatients: (clients: clientProfilesInterface[], recordId: string) => void;
  signUpOnly?: boolean;
  communicationPreference?: CommunicationPreference[];
  selectedClientRecordId?: string;
}

const PatientSelect = ({
  compact = false,
  searchable,
  noItemLabel,
  handleSelectPatients,
  signUpOnly,
  communicationPreference,
  selectedClientRecordId
}: PatientSelectProps) => {
  const { setBookablePatients, bookablePatients, isBookablePatientsLoading } = useFetchBookablePatients(
    signUpOnly,
    communicationPreference
  );
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [showAddClient, setShowAddClient] = useState(false);

  const [selectedPatientId, setSelectedPatientId] = useState<string>(selectedClientRecordId || '');
  const [t] = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const filteredClientList = bookablePatients.filter((clientRecordItem: clientRecordsInterface) => {
    const findClientName = clientRecordItem.clientProfiles.reduce(
      (res, clientProfileObj) =>
        res || getName(clientProfileObj).toLowerCase().includes(searchValue.toLowerCase().trim()),
      false
    );
    return findClientName;
  });

  useEffect(() => {
    setBookablePatients(sortClient(bookablePatients, true));
  }, [bookablePatients, setBookablePatients]);

  useEffect(() => {
    setSelectedPatientId(selectedClientRecordId || '');
  }, [selectedClientRecordId]);

  const sortClient = (list: clientRecordsInterface[], sortAsc: boolean) => {
    return list.sort((a, b) => {
      const clientA = getName(a.clientProfiles[0]);
      const clientB = getName(b.clientProfiles[0]);

      return sortAsc ? clientA.localeCompare(clientB) : clientB.localeCompare(clientA);
    });
  };

  const toggleSort = () => {
    setIsSortAsc(!isSortAsc);
    setBookablePatients(sortClient(bookablePatients, !isSortAsc));
  };

  const handleOnClick = (primaryProfile: clientProfilesInterface, recordId: string) => () => {
    setSelectedPatientId(recordId);
    handleSelectPatients([primaryProfile], recordId);
    setShowList(false);
  };

  const [showList, setShowList] = useState(false);
  const selectedPatient = bookablePatients.find((clientObj) => clientObj._id === selectedPatientId)?.clientProfiles[0];

  const handleChangeSearch = (value: string) => {
    const filterData = bookablePatients.filter(
      (obj) =>
        obj.clientProfiles[0].name.toLowerCase().includes(value.toLowerCase()) ||
        (obj.clientProfiles[0].firstName &&
          obj.clientProfiles[0].firstName.toLowerCase().includes(value.toLowerCase())) ||
        (obj.clientProfiles[0].lastName && obj.clientProfiles[0].lastName.toLowerCase().includes(value.toLowerCase()))
    );
    setBookablePatients(filterData);
  };

  const handleMultiProfileClientOnClick = (clientProfiles: clientProfilesInterface[], recordId: string) => {
    setSelectedPatientId(recordId);
    handleSelectPatients(clientProfiles, recordId);
    setShowList(false);
  };

  const renderMultipleProfileClient = (
    recordType: 'couple' | 'child',
    clientObj: clientRecordsInterface,
    index: number
  ) => {
    const { clientProfiles } = clientObj;
    const recordId = clientObj._id;
    return (
      <div
        onClick={() => handleMultiProfileClientOnClick(clientProfiles, recordId)}
        className={classNames(styles.patient, {
          [styles.active]: selectedPatientId === recordId
        })}
        key={index}
      >
        <div className={styles.multiProfileContainer}>
          {clientProfiles.map((profile, index) => (
            <div className={styles.avatarNameContainer} key={index}>
              <div className={styles.profile}>
                <ClientProfileAvatar avatarUrl={profile.avatar} initialsName={profile.initials} />
              </div>
              <div className={styles.name}>{getName(profile)}</div>
            </div>
          ))}
        </div>
        {selectedPatientId === recordId && (
          <div className={styles.selectedBadge}>
            <span className="material-icons">check_circle</span>
          </div>
        )}
      </div>
    );
  };

  const renderPatientItem = (clientObj: clientRecordsInterface, index: number) => {
    const { _id: recordId, clientProfiles, recordType } = clientObj;
    if (recordType === 'couple' || recordType === 'child') {
      return renderMultipleProfileClient(recordType, clientObj, index);
    }

    const primaryProfile = clientProfiles[0];

    if (!primaryProfile) {
      return null;
    }
    return (
      <div
        onClick={handleOnClick(primaryProfile, recordId)}
        className={classNames(styles.patient, {
          [styles.active]: selectedPatientId === recordId
        })}
        key={index}
      >
        <div className={styles.avatarNameContainer}>
          <div className={styles.profile}>
            <ClientProfileAvatar avatarUrl={primaryProfile.avatar} initialsName={primaryProfile.initials} />
          </div>
          <div className={styles.name}>{getName(primaryProfile)}</div>
        </div>
        {selectedPatientId === recordId && (
          <div className={styles.selectedBadge}>
            <span className="material-icons">check_circle</span>
          </div>
        )}
      </div>
    );
  };

  const renderPatientList = () => {
    return isBookablePatientsLoading ? (
      <div className={styles.loadingSkeleton}>
        <Skeleton active />
      </div>
    ) : searchable && filteredClientList.length === 0 ? (
      <div className={styles.noItems}>
        <i className="material-icons-outlined">search_off</i>
        No matching student found
      </div>
    ) : (
      <div className={styles.patientContainer}>
        {filteredClientList.map((clientObj, index) => renderPatientItem(clientObj, index))}
      </div>
    );
  };

  return compact ? (
    <div className={styles.compactContainer}>
      {isBookablePatientsLoading ? (
        <div className={styles.loadingBox}>Loading...</div>
      ) : (
        <>
          <div className={styles.selectedBox} onClick={() => setShowList(!showList)}>
            <ProfileBadge
              name={selectedPatient ? getName(selectedPatient) : ''}
              initialsBackgroundColor={selectedPatient?.initialsBackgroundColor}
              initials={selectedPatient?.initials}
              avatar={selectedPatient?.avatar}
            />
            <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
          </div>
          <div className={styles.listWrapper}>
            <div className={showList ? styles.listShow : styles.listHide}>
              <div className={styles.searchBox}>
                <span className={`material-icons ${styles.searchIcon}`}>search</span>
                <input
                  type={'text'}
                  autoComplete={'off'}
                  className={styles.searchInput}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <div className={styles.itemWrapper}>
                {bookablePatients.map((clientObj) => {
                  const profile = clientObj.clientProfiles[0];
                  return (
                    <div
                      className={selectedPatientId === clientObj._id ? styles.itemSelected : styles.item}
                      key={profile._id}
                      onClick={handleOnClick(profile, clientObj._id)}
                    >
                      <ProfileBadge
                        name={getName(profile)}
                        initials={profile.initials}
                        initialsBackgroundColor={profile.initialsBackgroundColor}
                        avatar={profile.avatar}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className={styles.container}>
      <AddPatientModalV2 visible={showAddClient} onCancel={() => setShowAddClient(false)} />
      <div className={styles.header}>
        <div className={styles.navigation}>
          <span className={styles.title}>{t('label.select_client')}</span>
        </div>
        <div className={styles.controls}>
          <div className={styles.sortContainer}>
            <div>Sorted by: </div>
            <span onClick={() => toggleSort()}>
              {isSortAsc ? 'A - Z' : 'Z - A'}
              <span className={`material-icons ${styles.arrow}`}>
                {isSortAsc ? 'arrow_drop_down' : 'arrow_drop_up'}
              </span>
            </span>
          </div>
          <Button variant="link" icon="add" onClick={() => setShowAddClient(true)}>
            {t('button.add_new_client')}
          </Button>
        </div>
      </div>
      {searchable && (
        <div className={styles.searchBar}>
          <input
            className={styles.input}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
          />
          <div className={styles.searchBarIcon}>
            <i className="material-icons-outlined">search</i>
          </div>
        </div>
      )}
      {renderPatientList()}
    </div>
  );
};

export default PatientSelect;
