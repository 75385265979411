import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { SchedulingRulesType } from 'interfaces/Schedule/SchedulingRules';
import { getAccountLevelRules, putAccountLevelRules } from 'utils/http/accountLevelRules';

export const useFetchSchedulingRules = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [schedulingRules, setSchedulingRules] = useState<SchedulingRulesType>();
  const [isSchedulingRulesLoading, setIsSchedulingRulesLoading] = useState(true);
  const [isSchedulingRulesUpdating, setIsSchedulingRulesUpdating] = useState(false);

  const updateSchedulingRules = async (rules: SchedulingRulesType) => {
    setIsSchedulingRulesUpdating(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    const response = await putAccountLevelRules(token, rules);
    setIsSchedulingRulesUpdating(false);
    return response;
  };

  const fetchSchedulingRules = async () => {
    setIsSchedulingRulesLoading(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    const response = await getAccountLevelRules(token);
    const data = await response.json();

    setSchedulingRules(data);

    setIsSchedulingRulesLoading(false);
  };

  useEffect(() => {
    fetchSchedulingRules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { schedulingRules, isSchedulingRulesLoading, updateSchedulingRules, isSchedulingRulesUpdating };
};
