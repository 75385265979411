import RoomSelect from 'components/v2/RoomSelect/RoomSelect';
import { useField } from 'formik';
import { useEffect } from 'react';
import styles from './RoomOption.module.scss';
import Radio from 'components/Radio/Radio';
import _ from 'lodash';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';

const ROOM_REQUIRED_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

type RoomOptionProps = {
  appointmentType: AppointmentType;
};

const RoomOption = ({ appointmentType }: RoomOptionProps) => {
  const [{ value: roomRequired }, , { setValue: setRoomRequired }] = useField('roomRequired');
  const [, , { setValue: setRoomInfo }] = useField('roomInfo');

  useEffect(() => {
    setRoomRequired(appointmentType.roomAny || appointmentType.roomSettings?.required ? 'yes' : 'no');
    if (appointmentType.roomSettings?.required && !_.isEmpty(appointmentType.roomSettings?.preferredRoom)) {
      const preferredRoom = appointmentType.roomSettings?.preferredRoom;
      setRoomInfo(
        JSON.stringify({
          roomId: preferredRoom.roomId,
          roomName: preferredRoom.name
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentType]);

  return (
    <div className={styles.container}>
      <div className={styles.roomRequiredLabel}>Add a room?</div>
      <div className={styles.roomRequiredContainer}>
        <div className={styles.roomNotRequiredContainer}>
          <Radio
            options={appointmentType.roomSettings?.required ? [ROOM_REQUIRED_OPTIONS[0]] : ROOM_REQUIRED_OPTIONS}
            value={roomRequired}
            onChange={(e) => setRoomRequired(e.target.value)}
            vertical
            className={styles.radioClass}
            labelClassName={styles.radioLabel}
          />
        </div>
        {roomRequired === 'yes' && (
          <div className={styles.roomNameSelect}>
            <RoomSelect
              preferredRoomId={
                appointmentType.roomSettings?.required ? appointmentType.roomSettings?.preferredRoom.roomId : undefined
              }
              menuPlacement="top"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RoomOption;
