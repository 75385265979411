import styles from './ReferralDocuments.module.scss';

import { clientRecordsEncryptedInterface, referralFilesInterface } from 'interfaces/Clients/clientsRecord';
import { notification } from 'antd';
import classNames from 'classnames';
import { getDecryptedDocument } from 'utils/http/ClinicianProfileService/DecryptedDocument/download';
import mime from 'mime-types';
import { useGetAccessToken } from 'utils/hooks/token';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

interface ReferralDocumentsProps {
  clientRecordData: clientRecordsEncryptedInterface;
}

const ReferralDocuments = ({ clientRecordData }: ReferralDocumentsProps) => {
  const { token } = useGetAccessToken();
  // const handleDelete = () => {};

  // const onDeleteRefDoc = (fileData: referralFilesInterface) => {
  //   Modal.confirm({
  //     title: `Are you sure you want to permanently delete ${fileData.fileName}?`,
  //     onOk: handleDelete
  //   });
  // };

  const handleDownloadAttachment = async (attachment: referralFilesInterface) => {
    try {
      const callGetDecryptedDocument = await getDecryptedDocument(token, attachment.bucketName, attachment.fileName);

      const decryptedDocument = await callGetDecryptedDocument.arrayBuffer();

      const documentBlob = new Blob([decryptedDocument], {
        type: mime.lookup(attachment.fileName) || 'application/octet-stream'
      });

      window.open(URL.createObjectURL(documentBlob), '_blank');
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  // const onAddRefAttachment = () => {
  //   if (clientRecordData.recordStatus !== RecordStatus.Closed) {
  //     return;
  //   }
  // };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Referral Documents</div>
      <div className={styles.referralContent}>
        {clientRecordData.referral?.files?.map((fileObj, index) => {
          const formattedFileName = fileObj.fileName.substring(fileObj.fileName.indexOf('-') + 1);
          const formattedCreatedTime = moment(fileObj.createdAt).format(MOMENTJS_DATE_FORMAT);
          const getClientDetails = clientRecordData.clientProfiles.find(
            (clientObj) => clientObj._id === fileObj.clientProfileId
          );
          const clientName = getClientDetails ? `${getClientDetails.firstName} ${getClientDetails.lastName}` : '';
          return (
            <div key={index} className={styles.refDocRecord}>
              <div className={styles.refDocName}>
                <i className={classNames('material-icons', styles.listIcon)}>done</i>
                {formattedFileName}
              </div>

              <div className={styles.refDocAddedDetails}>
                Added <b>{formattedCreatedTime}</b> by <b>{clientName}</b>
              </div>
              <div className={styles.viewAction}>
                <button onClick={() => handleDownloadAttachment(fileObj)}>
                  <i className={'material-icons-outlined'}>attach_file</i>
                  View
                </button>
              </div>
              {/* {clientRecordData.recordStatus !== RecordStatus.Closed && (
                <div className={styles.deleteAction}>
                  <button onClick={() => onDeleteRefDoc(fileObj)}>Delete</button>
                </div>
              )} */}
            </div>
          );
        })}
      </div>
      {/* <div className={styles.addRefAttachment}>
        <Button
          className={styles.button}
          variant="secondary"
          onClick={onAddRefAttachment}
          disabled={clientRecordData.recordStatus === RecordStatus.Closed}
        >
          <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
          Add referral attachment
        </Button>
      </div> */}
    </div>
  );
};

export default ReferralDocuments;
