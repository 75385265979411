import { AppointmentType } from '../../../../types';
import styles from './FixedTime.module.scss';
import FixedTimeSelection from './components/FixedTimeSelection/FixedTimeSelection';
import { useField } from 'formik';

const weekDays: (keyof AppointmentType['fixedTimeSelections'])[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

interface FixedTimeProps {
  duration: number;
}

const FixedTime = ({ duration }: FixedTimeProps) => {
  const [, { error, touched }] = useField('dayTimeSelections.fixedTime');
  return (
    <div className={styles.container}>
      <div className={styles.cell}>
        <div className={styles.label}>Select day available</div>
      </div>
      <div>
        <div className={styles.durationText}>
          Appointment run time for
          <span className={styles.duration}>&nbsp;{duration}</span>
          <span className={styles.durationMinutes}>&nbsp;minutes</span>
        </div>
        <div className={styles.label}>Set start times</div>
      </div>
      {weekDays.map((day) => (
        <FixedTimeSelection day={day} duration={duration} key={day} />
      ))}
      {typeof error === 'string' && touched && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default FixedTime;
