import { HTMLAttributes } from 'react';
import styles from './SelectConsentForms.module.scss';
import classnames from 'classnames';
import { GroupConsentForm, GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import Radio from 'components/Radio/Radio';
import SubmitButton from 'components/v2/Button/Button';
import ClientOrGroupSelected from 'components/ClientOrGroupPickAssessment/components/ClientOrGroupSelected/ClientOrGroupSelected';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { Divider } from 'antd';

const CONSENT_FORM_OPTIONS = [
  {
    id: 'v1',
    name: 'Consent form V1'
  },
  {
    id: 'v2',
    name: 'Consent form V2'
  },
  {
    id: 'v3',
    name: 'Consent form V3'
  },
  {
    id: 'v4',
    name: 'Consent form V4'
  }
];

interface SelectConsentFormsProps {
  selectedGroup?: GroupsFromAPI;
  selectedConsentForm?: GroupConsentForm;
  onNextClick: () => void;
  onSelectConsentForm: (item: GroupConsentForm) => void;
}

const SelectConsentForms = ({
  selectedGroup,
  selectedConsentForm,
  onSelectConsentForm,
  onNextClick,
  className
}: SelectConsentFormsProps & HTMLAttributes<HTMLDivElement>) => {
  const selectConsent = (selectedId: string) => {
    const selectedItem = CONSENT_FORM_OPTIONS.filter((item) => item.id === selectedId)[0];
    onSelectConsentForm(selectedItem);
  };

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.consentGroupSelect}>
        <ClientOrGroupSelected participationType={ParticipantType.Group} group={selectedGroup} />
        <Divider style={{ margin: 0 }} />
        <div className={styles.selectConsentGroupWrapper}>
          <div className={styles.selectConsentGroupTitle}>Select Forms</div>
          <div className={styles.consentFormItems}>
            <div className={styles.consentFormItem}>
              <Radio
                labelClassName={styles.labelClassName}
                name={'consentForm'}
                options={CONSENT_FORM_OPTIONS.map((item) => ({ label: item.name, value: item.id }))}
                value={selectedConsentForm?.id}
                onChange={(e) => selectConsent(e.target.value)}
                vertical
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.submitBtn}>
        <SubmitButton disabled={!selectedConsentForm} onClick={onNextClick}>
          Next
        </SubmitButton>
      </div>
    </div>
  );
};

export default SelectConsentForms;
