import { notification } from 'antd';
import { useState } from 'react';
import { getVadcStructureQuestions } from 'utils/http/CheckInService/Assessment/firstAssessment';

interface VadcQuestionPayload {
  id: string;
  name: string;
  description?: string;
  questionType: string;
  maxSelection?: number;
  options?: { key?: string; value?: string; description: string }[];
}

export interface VadcQuestion extends VadcQuestionPayload {
  structuredQuestionId: string;
}

export const useFetchVadcQuestions = (token: string) => {
  const [isVadcQuestionsLoading, setIsVadcQuestionsLoading] = useState(false);
  const [vadcQuestions, setVadcQuestions] = useState<VadcQuestionPayload[]>([]);

  const fetchVadcQuestions = async () => {
    setIsVadcQuestionsLoading(true);
    try {
      const callQuestions = (await (await getVadcStructureQuestions(token)).json()) as VadcQuestionPayload[];
      setVadcQuestions(callQuestions);
      setIsVadcQuestionsLoading(false);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get vadc questions' });
    }

    setIsVadcQuestionsLoading(false);
  };

  return { vadcQuestions, isVadcQuestionsLoading, fetchVadcQuestions };
};
