import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/v2/Radio/Radio';
import { useField } from 'formik';
import { AppointmentType, DeliveryType, DELIVERY_TYPE_LABELS } from 'interfaces/Schedule/AppointmentType';
import { useState, useEffect } from 'react';
import styles from './DeliveryModeSelect.module.scss';

interface DeliveryModeSelectProps {
  appointmentType?: AppointmentType;
  selectedDeliveryType?: string;
  showAdditionalInput?: boolean;
  onChangeDeliveryType?: (value: string) => void;
}

const DeliveryModeSelect = ({
  appointmentType,
  selectedDeliveryType,
  onChangeDeliveryType,
  showAdditionalInput
}: DeliveryModeSelectProps) => {
  const [{ value: deliveryType }, , { setValue: setDeliveryType }] = useField('deliveryType');
  const [locationProps, , locationHelper] = useField('faceToFaceLocation');
  const [callProps, , callHelper] = useField('videoCallInstructions');
  const [phoneProps, , phoneHelper] = useField('phoneCallInstructions');
  const [selected, setSelected] = useState(selectedDeliveryType || deliveryType || DeliveryType.FaceToFace);

  useEffect(() => {
    if (!selectedDeliveryType && !deliveryType && appointmentType) {
      setDeliveryType(appointmentType.deliveryOptions?.[0]);
      locationHelper.setValue(appointmentType.faceToFaceLocation);
      callHelper.setValue(appointmentType.videoCallInstructions || appointmentType.defaultVideoCallInstructions);
      phoneHelper.setValue(appointmentType.phoneCallInstructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentType]);

  const onSelected = (value: string) => {
    setSelected(value);
    setDeliveryType(value);
    onChangeDeliveryType?.(value);
  };

  const telegraphTextField = (deliveryMode: DeliveryType) => (
    <div className={styles.subField}>
      <label className={styles.label}>How to connect</label>
      <textarea
        id={'howToConnect'}
        className={styles.textarea}
        rows={4}
        maxLength={320}
        value={deliveryMode === DeliveryType.VideoCall ? callProps.value : phoneProps.value}
        onChange={(e) =>
          deliveryMode === DeliveryType.VideoCall
            ? callHelper.setValue(e.target.value)
            : phoneHelper.setValue(e.target.value)
        }
      />
    </div>
  );

  return appointmentType?.deliveryOptions && appointmentType?.deliveryOptions.length > 0 ? (
    <div className={styles.container}>
      {appointmentType?.deliveryOptions.includes(DeliveryType.FaceToFace) && (
        <div>
          <Radio
            name="deliveryType"
            value={DeliveryType.FaceToFace}
            label={DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace]}
            checked={selected === DeliveryType.FaceToFace}
            onChange={onSelected}
          />
          {showAdditionalInput && selected === DeliveryType.FaceToFace && (
            <div className={styles.subField}>
              <MaterialInput
                id={`location`}
                label={'Location'}
                onChange={(e) => locationHelper.setValue(e.target.value)}
                value={locationProps.value}
                required
                style={{
                  background: 'transparent'
                }}
              />
            </div>
          )}
        </div>
      )}
      {appointmentType?.deliveryOptions.includes(DeliveryType.PhoneCall) && (
        <div>
          <Radio
            name="deliveryType"
            value={DeliveryType.PhoneCall}
            label={DELIVERY_TYPE_LABELS[DeliveryType.PhoneCall]}
            checked={selected === DeliveryType.PhoneCall}
            onChange={onSelected}
          />
          {showAdditionalInput &&
            selected === DeliveryType.PhoneCall &&
            !!appointmentType.phoneCallInstructions &&
            telegraphTextField(DeliveryType.PhoneCall)}
        </div>
      )}
      {appointmentType?.deliveryOptions.includes(DeliveryType.VideoCall) && (
        <div>
          <Radio
            name="deliveryType"
            value={DeliveryType.VideoCall}
            label={DELIVERY_TYPE_LABELS[DeliveryType.VideoCall]}
            checked={selected === DeliveryType.VideoCall}
            onChange={onSelected}
          />
          {showAdditionalInput && selected === DeliveryType.VideoCall && telegraphTextField(DeliveryType.VideoCall)}
        </div>
      )}
    </div>
  ) : (
    <div className={styles.noDeliveryOption}>No delivery type for this appointment type.</div>
  );
};

export default DeliveryModeSelect;
