import { Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import styles from './ActiveInterventionListCard.module.scss';
import ActiveInterventionListingItem from './components/ActiveInterventionListingItem';
import columnStyles from './InterventionRowSection.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ActiveInterventionListCardProps {
  interventionListing: Interventions[];
  searchValue: string;
}

const ActiveInterventionListCard = ({ interventionListing, searchValue }: ActiveInterventionListCardProps) => {
  const { isInterventionsTagsToggle } = useGetFeatureToggle();
  return interventionListing.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <div className={columnStyles.interventionNameSection}>Intervention Name</div>
        <div className={columnStyles.createdSection}>Created</div>
        {isInterventionsTagsToggle && <div className={columnStyles.interventionTagSection}>Intervention Tag</div>}
        <div className={columnStyles.interventionMemberSection}>Intervention Members</div>
        <div className={columnStyles.interventionLeadSection}>Intervention Lead</div>
        <div className={columnStyles.descriptionSection}>Description</div>
        <div className={columnStyles.buttonSection} />
      </div>
      {interventionListing.map((listingObj, index) => (
        <ActiveInterventionListingItem key={index} interventionListingItem={listingObj} searchValue={searchValue} />
      ))}
    </>
  ) : (
    <div>There is no data for Intervention</div>
  );
};

export default ActiveInterventionListCard;
