import { Modal, notification } from 'antd';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Integration } from 'pages/ControlPanel/Interfaces/Integration';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { deleteIntegration } from 'utils/http/BillingService/Invoice/codat';

import styles from './DisconnectIntegrationModal.module.scss';

interface DisconnectIntegrationModalProps {
  integration: Integration;
  visible: boolean;
  setShowDisconnectModal: (bool: boolean) => void;
  refetchSystemIntegration: () => void;
}

const DisconnectIntegrationModal = ({
  integration,
  visible,
  setShowDisconnectModal,
  refetchSystemIntegration
}: DisconnectIntegrationModalProps) => {
  const { token } = useGetAccessToken();
  const { id, label } = integration;
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');

  const handleConfirmDisconnect = async () => {
    setSubmitStatus('active');
    try {
      await deleteIntegration(token, id);

      notification.warning({
        message: `Disconnecting ${label} in progress...`,
        description: 'Upon completion you can connect to another service.',
        duration: 2,
        closeIcon: <span className="notify">OK</span>
      });
      setShowDisconnectModal(false);
      refetchSystemIntegration();
      setSubmitStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to disconnect' });
    }
    setSubmitStatus('');
  };

  const handleCloseModal = () => setShowDisconnectModal(false);

  return (
    <Modal
      className={styles.container}
      visible={visible}
      closable
      title={null}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.icon}>
            <i className={'material-icons'}>report_gmailerrorred</i>
          </div>
          <div className={styles.messageContainer}>
            <div className={styles.title}>{`Are you sure you want to disconnect ${label}?`}</div>
            <div className={styles.message}>{`If Yes, we will not sync your data to ${label}.`}</div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDisconnect} status={submitStatus}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisconnectIntegrationModal;
