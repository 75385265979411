import { useState, ChangeEvent } from 'react';
import styles from './ActionPlanAttachments.module.scss';
import { notification } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { resizeAndCropImage } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteAttachmentBtn/components/resizeCropImage';
import { ActionPlanAttachment } from '../../../../interfaces';
import {
  downloadAttachmentByRecordId,
  uploadAttachmentByRecordId,
  uploadAttachmentThumbnailByRecordId
} from 'utils/http/DocumentService/Attachments/attachments';
import mimetypes from 'mime-types';
import { useAuth0 } from '@auth0/auth0-react';

const MAX_FILE_SIZE = 19 * 1024 * 1024;

interface ActionPlanAttachmentsProps {
  attachments: ActionPlanAttachment[];
  clientRecordId: string;
  onChangeUploadStatus?: (val: boolean) => void;
  onChangeAttachments: (attachments: ActionPlanAttachment[]) => void;
}

const ActionPlanAttachments = ({
  attachments = [],
  clientRecordId,
  onChangeUploadStatus,
  onChangeAttachments
}: ActionPlanAttachmentsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [uploadAttachmentLoading, setUploadAttachmentLoading] = useState(false);

  const handleUploadAttachmentClick = () => {
    document.getElementById('action-plan-upload-attachment')?.click();
  };

  const handleDeleteAttachment = (attachmentId: string) => {
    onChangeAttachments(attachments.filter((attachment) => attachment.attachmentId !== attachmentId));
  };

  const handleUploadAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files.length === 0) {
        return;
      }
      setUploadAttachmentLoading(true);
      onChangeUploadStatus && onChangeUploadStatus(true);

      const uploadedAttachments: ActionPlanAttachment[] = [];

      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        for (let i = 0; i < files.length; i++) {
          const currentFile = files[i];

          if (currentFile.size > MAX_FILE_SIZE) {
            notification.error({ message: `File ${currentFile.name} is too big` });
            continue;
          }

          const payload: Record<string, any> = {};

          try {
            const uploadedAttachmentDetail = await uploadAttachmentByRecordId(
              token,
              clientRecordId,
              currentFile,
              payload
            );

            if (/(image\/)\w+/g.test(currentFile.type)) {
              const attachmentId = uploadedAttachmentDetail._id || '';
              const dataUrl = await resizeAndCropImage(currentFile);

              if (!dataUrl) {
                notification.error({ message: 'Something went wrong while trying to compress your image.' });
                continue;
              }

              const dataArray = dataUrl.split(',');
              const mimeType = dataArray[0].match(/:(.*?);/)?.[1];
              const dataStrings = atob(dataArray[1]);
              let dataLength = dataStrings.length;
              const actualData = new Uint8Array(dataLength);

              while (dataLength--) {
                actualData[dataLength] = dataStrings.charCodeAt(dataLength);
              }

              const thumbnailFile = new File([actualData], `${currentFile.name}-thumbnail`, { type: mimeType });

              const responseWithThumbnail = await uploadAttachmentThumbnailByRecordId(
                token,
                clientRecordId,
                attachmentId,
                thumbnailFile
              );

              uploadedAttachments.push({
                attachmentId: responseWithThumbnail._id,
                title: responseWithThumbnail.title
              });
            } else {
              uploadedAttachments.push({
                attachmentId: uploadedAttachmentDetail._id,
                title: uploadedAttachmentDetail.title
              });
            }
          } catch (ex) {
            notification.error({ message: `Failed to upload file ${currentFile.name}` });
            continue;
          }
        }

        if (uploadedAttachments.length > 0) {
          notification.success({
            message: `Successfully uploaded ${uploadedAttachments.length} ${
              uploadedAttachments.length === 1 ? 'attachment' : 'attachments'
            }.`,
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          const newAttachments = [...attachments, ...uploadedAttachments];
          onChangeAttachments(newAttachments);
          setUploadAttachmentLoading(false);
          onChangeUploadStatus && onChangeUploadStatus(false);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to upload your attachments' });
        setUploadAttachmentLoading(false);
        onChangeUploadStatus && onChangeUploadStatus(false);
      }
    }
  };

  const onDownloadAttachmentClick = async (attachment: ActionPlanAttachment) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });
    try {
      const callGetAttachmentById = await downloadAttachmentByRecordId(token, clientRecordId, attachment.attachmentId);
      const attachmentData = await callGetAttachmentById.arrayBuffer();

      const documentBlob = new Blob([attachmentData], {
        type: mimetypes.lookup(attachment.title) || 'application/octet-stream'
      });

      const anchor = document.createElement('a');

      anchor.href = URL.createObjectURL(documentBlob);
      anchor.download = attachment.title;
      anchor.click();
      anchor.remove();
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  return (
    <div className={styles.attachmentsContainer}>
      {attachments.length > 0 &&
        attachments.map((attachment) => (
          <div className={styles.attachmentContainer} key={attachment.attachmentId}>
            <div className={styles.fileName} onClick={() => onDownloadAttachmentClick(attachment)}>
              <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
              {attachment.title}
            </div>
            <span onClick={() => handleDeleteAttachment(attachment.attachmentId)}>Remove</span>
          </div>
        ))}
      {uploadAttachmentLoading ? (
        <div className={styles.uploadingLabel}>
          <div className={styles.icon}>
            <LoadingCircle />
          </div>
          Uploading...
        </div>
      ) : (
        <div className={styles.addAttachmentsButton} onClick={handleUploadAttachmentClick}>
          <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
          Add files
        </div>
      )}
      <input
        className={styles.addAttachmentInput}
        id="action-plan-upload-attachment"
        type="file"
        accept="image/png,image/jpeg,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        multiple
        onChange={handleUploadAttachment}
      />
    </div>
  );
};

export default ActionPlanAttachments;
