import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';
import { SessionStatus } from '../../../../pages/Groups/Interfaces/GroupsSession';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getGroupSessionDetailsById = (token: string, groupId: string, sessionId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/sessions/${sessionId}`
  ) as Promise<BentResponse>;

export const putGroupSessionDetails = (
  token: string,
  groupId: string,
  sessionId: string,
  body: Record<string, any>
) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/sessions/${sessionId}/details`,
    body
  ) as Promise<BentResponse>;
};

export const getGroupSessionList = (token: string, groupId: string, sessionStatus?: SessionStatus) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/sessions${sessionStatus ? `?sessionStatus=${sessionStatus}` : ''}`
  ) as Promise<BentResponse>;

export const postGroupAppointments = (groupId: string, payload: any, token: string) =>
  httpClient('POST', url!, [204, 409], { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/sessions`,
    payload
  ) as Promise<BentResponse>;

export const deleteGroupAppointment = (token: string, groupId: string, sessionId: string, body: Record<string, any>) =>
  httpClient('DELETE', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/groups/${groupId}/sessions/${sessionId}`, body);

export const putGroupSession = (token: string, groupId: string, sessionId: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, [204, 409], { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/sessions/${sessionId}`,
    body
  ) as Promise<BentResponse>;
};
