import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getGroupListByGroupId, getGroupListingByAccountId } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { GroupListData, GroupStatus } from 'pages/Groups/Interfaces/Groups';

interface scrollingGroupList {
  groupList: GroupListData['groups'];
  isSortByAsc: boolean;
}

const sortGroupName = (list: GroupListData['groups'], sortAsc: boolean) => {
  return list.sort((a, b) => {
    const groupA = a.name;
    const groupB = b.name;

    return sortAsc ? groupA.localeCompare(groupB) : groupB.localeCompare(groupA);
  });
};

const massageAndSortData = (groupList: GroupListData['groups'], clinicianId?: string, status?: GroupStatus) => {
  const massageList: GroupListData['groups'] = groupList.filter(
    (groupObj) =>
      groupObj.status === status &&
      (!clinicianId ||
        groupObj.leadClinicianAuth0Id === clinicianId ||
        groupObj.clinicianAuth0Ids.includes(clinicianId))
  );
  return sortGroupName(massageList, true);
};

export const useFetchGroupsAppointmentList = (
  token: string,
  clinicianId?: string,
  status?: GroupStatus,
  enableGroupSelection?: boolean
) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [groupList, setGroupList] = useState<scrollingGroupList>({
    groupList: [],
    isSortByAsc: true
  });

  const [isGroupListLoading, setIsGroupListLoading] = useState(true);

  const fetchGroupList = useCallback(
    async (token: string) => {
      if (enableGroupSelection) {
        try {
          setIsGroupListLoading(true);
          if ((isEdgeAdminView || isEdgeReceptionist) && accountId) {
            const groupsData = await getGroupListingByAccountId(token, accountId);
            const groupListingData = (await groupsData.json()) as GroupListData;
            const sortGroupNameAsc = massageAndSortData(groupListingData.groups, clinicianId, status);
            setGroupList({
              groupList: sortGroupNameAsc,
              isSortByAsc: true
            });
          } else {
            const groupsData = await getGroupListByGroupId(token);
            const groupListingData = (await groupsData.json()) as GroupListData;
            const sortGroupNameAsc = massageAndSortData(groupListingData.groups, clinicianId, status);
            setGroupList({
              groupList: sortGroupNameAsc,
              isSortByAsc: true
            });
          }
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to get your groups list' });
        }
        setIsGroupListLoading(false);
      }
      setIsGroupListLoading(false);
    },
    [accountId, clinicianId, status, isEdgeAdminView, isEdgeReceptionist, enableGroupSelection]
  );

  const sortByGroupName = (isSortByAsc: boolean) => {
    const sortedList = sortGroupName(groupList.groupList, isSortByAsc);
    setGroupList({
      groupList: sortedList,
      isSortByAsc: isSortByAsc
    });
  };

  useEffect(() => {
    if (token) {
      fetchGroupList(token);
    }
  }, [fetchGroupList, token]);

  return { groupList, isGroupListLoading, sortByGroupName, fetchGroupList };
};
