import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getInterventionListingByAccountId = (token: string, accountId: string, status?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions${status ?? ''}`
  ) as Promise<BentResponse>;

export const getInterventionList = (token: string, status?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/interventions${status ?? ''}`
  ) as Promise<BentResponse>;

export const getInterventionTags = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/intervention-tags`
  ) as Promise<BentResponse>;

export const getInterventionDetailsByInterventionId = (token: string, interventionId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/interventions/${interventionId}`
  ) as Promise<BentResponse>;

export const postNewIntervention = (token: string, accountId: string, body: Record<string, any>) => {
  return httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions`,
    body
  );
};

export const patchReassignIntervention = (
  token: string,
  accountId: string,
  interventionId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}:reassign`,
    payload
  ) as Promise<BentResponse>;

export const postKeyContact = (token: string, accountId: string, interventionId: string, body: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/key-contacts`,
    body
  ) as Promise<BentResponse>;

export const deleteKeyContact = (token: string, accountId: string, interventionId: string, keyContactId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/key-contacts/${keyContactId}`
  ) as Promise<BentResponse>;

export const putIntervention = (
  token: string,
  accountId: string,
  interventionId: string,
  body: Record<string, any>
) => {
  return httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}`,
    body
  );
};

export const putCheckInterventionIdAvailability = (token: string, accountId: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions:checkInterventionIdAvailability`,
    body
  );
};

export const patchStageIntervention = (
  token: string,
  accountId: string,
  interventionId: string,
  clientRecordId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/client-records/${clientRecordId}:updateStage`,
    payload
  ) as Promise<BentResponse>;

export const patchRemoveClient = (token: string, accountId: string, interventionId: string, clientRecordId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/client-records/${clientRecordId}:removeClient`
  ) as Promise<BentResponse>;

export const patchUpdateStatus = (
  token: string,
  accountId: string,
  interventionId: string,
  clientRecordId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/client-records/${clientRecordId}:updateStatus`,
    payload
  ) as Promise<BentResponse>;
