import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { Assessment } from '../../../pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { getClinicalAssessmentDetailsResponse } from '../../http/CheckInService/Assessment/clinicalAssessment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

export const useFetchAssessmentByRecordProfileId = (token: string, recordId: string) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);
  const [isLoadingAssessmentList, setIsLoadingAssessmentList] = useState(true);

  const fetchAssessment = async () => {
    setIsLoadingAssessmentList(true);

    if (recordId && !isEdgeReceptionist) {
      try {
        const getCAssessmentResponse = await getClinicalAssessmentDetailsResponse(token, recordId);

        const assessment = await getCAssessmentResponse.json();

        setAssessmentList(assessment);
      } catch (ex) {
        notification.error({ message: "Something went wrong while trying to get this record's assessments details" });
      }
    }

    setIsLoadingAssessmentList(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { assessmentList, isLoadingAssessmentList, fetchAssessment };
};
