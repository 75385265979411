import { useEffect, useState } from 'react';

import styles from './ChildGuardianForm.module.scss';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { validationChildGuardianField } from './validation/ChildGuardianFormValidation';
import { ClientDetails } from '../../../../AddPatientModalV2Interface';
import { ChildGuardianErrorInterface } from './ChildGuardianInterface';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import CheckBox from 'components/CheckBox/CheckBox';
import { COMMUNICATION_OPTIONS } from 'components/AddPatientModalV2/constants';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

export const ROLE_TYPE_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Step Mother', value: 'stepMother' },
  { label: 'Step Father', value: 'stepFather' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'Other', value: 'other' }
];

interface ChildGuardianFormProps {
  id: string | number;
  label?: string;
  clientDetail: ClientDetails;
  onChangeClientField: any;
  checkValidation: boolean;
  shadowBox?: boolean;
  isFirstGuardian: boolean;
  updateDetail: number;
}

const ChildGuardianForm = ({
  id,
  label,
  clientDetail,
  onChangeClientField,
  checkValidation,
  shadowBox,
  isFirstGuardian,
  updateDetail
}: ChildGuardianFormProps) => {
  const [clientField, setClientField] = useState(clientDetail);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  } as ChildGuardianErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      validatePart1Field(clientField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  useEffect(() => {
    setClientField(clientDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetail]);

  const changeIsPrimaryContact = (v: boolean) => {
    const newClientField = {
      ...clientField,
      isPrimaryContact: v,
      communicationPreference: v ? CommunicationPreference.NoPreference : clientField.communicationPreference
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const validatePart1Field = (clientVal: ClientDetails) => {
    const validate = validationChildGuardianField({ ...clientVal, isFirstGuardian: isFirstGuardian });
    setErrorMessage(validate as ChildGuardianErrorInterface);
    return validate;
  };

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientField,
      [key]: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const handleSelectRole = (value: ClientDetails['role']) => {
    const newClientField = {
      ...clientField,
      role: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const onCommunicationPreferenceChange = (value: ClientDetails['communicationPreference']) => {
    const newClientField = {
      ...clientField,
      communicationPreference: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
  };

  const [t] = useTranslation();

  const hasValues = clientField.firstName || clientField.lastName || clientField.email || clientField.mobileNumber;

  return (
    <div className={shadowBox ? styles.containerWithBox : styles.container}>
      {shadowBox && <div className={styles.labelContainer}>{label && <div className={styles.label}>{label}</div>}</div>}
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`clientFirstName${id}`}
          label={`${t('form.firstName')}${hasValues ? '*' : ''}`}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientField.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={styles.twoQuestionWrapper}>
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}
        >
          <MaterialInput
            id={`clientLastName${id}`}
            label={`${t('form.lastName')}${hasValues ? '*' : ''}`}
            onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
            value={clientField.lastName}
            required
          />
          {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
        </div>
        <div className={styles.roleFieldContainer}>
          <MaterialSelect
            id={`relationshipType${id}`}
            label={`${t('form.relationship')}${hasValues ? '*' : ''}`}
            isSearchable={false}
            options={ROLE_TYPE_OPTIONS}
            value={clientField.role as string}
            onChange={handleSelectRole}
            isError={checkValidation && !!errorMessage.role}
          />
          {checkValidation && errorMessage.role && <div className={styles.fieldError}>{errorMessage.role}</div>}
        </div>
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.email && styles.fieldError)}>
        <MaterialInput
          id={`clientEmail${id}`}
          label={`${t('form.email')}${isFirstGuardian && hasValues ? '*' : ''}`}
          onChange={(e) => handleChangeFieldValue('email', e.target.value)}
          value={clientField.email}
          required
        />
        {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
      </div>
      <div className={styles.phoneContainer}>
        <MaterialPhoneInput
          id={`clientMobileNumber${id}`}
          label={`Mobile Number ${isFirstGuardian && hasValues ? '*' : ''}`}
          onChange={(e: string) => handleChangeFieldValue('mobileNumber', e)}
          value={clientField.mobileNumber || ''}
          isError={checkValidation && !!errorMessage.mobileNumber}
        />
        {checkValidation && errorMessage.mobileNumber && (
          <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
        )}
      </div>
      <div className={styles.primaryFieldContainer}>
        <CheckBox
          id={`isPrimaryGuardian${id}`}
          value={clientDetail.isPrimaryContact ? clientDetail.isPrimaryContact : false}
          label={'Contact will receive messages, documents details for this profile'}
          onChange={(e) => changeIsPrimaryContact(e.target.checked)}
        />
      </div>
      <div className={classnames(styles.preferenceContainer, styles.fieldContainer)}>
        <MaterialSelect
          id={'clientCommunicationPreference'}
          label={'Communication Preference'}
          isSearchable={false}
          options={COMMUNICATION_OPTIONS}
          value={clientDetail.communicationPreference || CommunicationPreference.NoPreference}
          onChange={onCommunicationPreferenceChange}
          isDisabled={clientDetail.isPrimaryContact}
        />
        {clientDetail.communicationPreference === 'none' && (
          <div className={styles.noneComms}>{t('form.error.client_no_digital_message')}</div>
        )}
      </div>
    </div>
  );
};

export default ChildGuardianForm;
