import moment from 'moment';
import * as Yup from 'yup';
export interface TimeSlotType {
  startTime: string;
  endTime: string;
}

export interface DayType {
  isAvailable: boolean;
  timeSlots: TimeSlotType[];
}

const day: DayType = {
  isAvailable: false,
  timeSlots: []
};

export const initialValues = {
  name: '',
  maxOccupancy: 1,

  availability: {
    monday: day,
    tuesday: day,
    wednesday: day,
    thursday: day,
    friday: day,
    saturday: day,
    sunday: day
  },
  roomStatus: 'openForBooking'
};

const timeFormat = 'hh:mm A';

const timeSlot = Yup.object({
  startTime: Yup.string().required(),
  endTime: Yup.string().required()
}).test('validTimeSequence', 'End time must later then start time', (value) => {
  return moment(value?.startTime, timeFormat).diff(moment(value?.endTime, timeFormat)) < 0;
});

const availableTimeSchema = Yup.object({
  isAvailable: Yup.boolean(),
  timeSlots: Yup.array(timeSlot).test('noOverlapTime', 'Time slots overlapped', (value) => {
    let result = [];
    if (value) {
      for (let a = 0; a < value.length; a++) {
        const startTimeA = moment(value[a]?.startTime, timeFormat);
        const endTimeA = moment(value[a]?.endTime, timeFormat);
        result[a] = true;
        for (let b = 0; b < value.length; b++) {
          const startTimeB = moment(value[b]?.startTime, timeFormat);
          const endTimeB = moment(value[b]?.endTime, timeFormat);
          if (a !== b) {
            //if not same index
            if (
              (startTimeA.diff(startTimeB) > 0 && startTimeA.diff(endTimeB) < 0) ||
              (endTimeA.diff(startTimeB) > 0 && endTimeA.diff(endTimeB) < 0) ||
              startTimeA.isSame(startTimeB) ||
              endTimeA.isSame(endTimeB)
            ) {
              return false;
            }
          }
        }
      }
    }
    return true;
  })
});

export const roomFormSchema = Yup.object({
  name: Yup.string().required("Please insert room's name"),
  maxOccupancy: Yup.number().required('Please select maximum occupancy of the room').min(1),
  availability: Yup.object({
    monday: availableTimeSchema,
    tuesday: availableTimeSchema,
    wednesday: availableTimeSchema,
    thursday: availableTimeSchema,
    friday: availableTimeSchema,
    saturday: availableTimeSchema,
    sunday: availableTimeSchema
  }),
  roomStatus: Yup.string()
});
