import styles from './InterventionStatusBadge.module.scss';
import { InterventionStatus } from 'MHH/pages/Interventions/Interfaces/Interventions';

interface InterventionStatusBadgeProps {
  interventionStatus: InterventionStatus;
}

const InterventionStatusBadge = ({ interventionStatus }: InterventionStatusBadgeProps) => {
  const generateStatusBadge = (status: InterventionStatus) => {
    switch (status) {
      case 'active':
        return {
          label: 'ACTIVE',
          badgeStyles: styles.activeBadge
        };
      case 'closed':
        return {
          label: 'CLOSED',
          badgeStyles: styles.closedBadge
        };
      default:
        console.error('Something went wrong while trying to generate your record status badge');
        return {
          label: '',
          badgeStyles: ''
        };
    }
  };

  return (
    <div className={generateStatusBadge(interventionStatus).badgeStyles}>
      <div>{generateStatusBadge(interventionStatus).label}</div>
    </div>
  );
};

export default InterventionStatusBadge;
