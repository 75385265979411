import { putCheckInterventionIdAvailability } from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import * as yup from 'yup';
import {
  CreateInterventionRequestPayload,
  CreateInterventionValidateField
} from '../../../CreateInterventionModalInterfaces';

const interventionFormSchema = yup.object().shape({
  name: yup.string().required('Please enter the intervention name'),
  interventionLibraryItemId: yup.string().required('Please select intervention library')
});

export const validationInterventionNameForm = (interventionVal: CreateInterventionRequestPayload) => {
  const validationErrors: CreateInterventionValidateField = {
    name: '',
    interventionLibraryItemId: ''
  };
  try {
    interventionFormSchema.validateSync(interventionVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof CreateInterventionValidateField] = error.message;
        }
      });
      return validationErrors;
    }
  }
};

export const validateInterventionId = async (token: string, accountId: string, interventionId: string) => {
  try {
    const body = {
      interventionId
    };
    await putCheckInterventionIdAvailability(token, accountId, body);
    return {
      interventionId,
      statusCode: 200
    };
  } catch (ex) {
    if (isErrorBentStatusError(ex)) {
      return {
        interventionId,
        statusCode: ex.statusCode
      };
    }
    throw ex;
  }
};
