import { notification } from 'antd';
import { ClinicalAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';
import {
  ClinicalAssessmentResponseList,
  TeacherPendingAssessmentResponse
} from 'interfaces/checkInService/clinicalAssessment';
import { ReportClientTab, SurveyClientTab } from 'interfaces/Reports/report';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import {
  getAssessment,
  getClinicalAssessmentList,
  getStudentPendingAssessmentList
} from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { getOnboardingListByRecordId } from 'utils/http/CheckInService/ReportList/reportList';
import { getReportList, getReportListByAdmin } from 'utils/http/DocumentService/Reports/reports';
import { massageSQDSequence } from '../../../../../components/ClientOrGroupPickAssessment/getAssessmentList';
import { getClinicalAssessmentTemplate } from '../../../../../store/CheckIn/GetClinicalAssessmentTemplate/action';

const massageNameInId = (records: ClinicalAssessmentResponseList[]) => {
  const addApostrophe = (name: string): string => (name.slice(-1) === 's' ? `${name}'` : `${name}'s`);
  return records.map((record) => ({
    ...record,
    id: `${addApostrophe(record.assessedBy)} ${record.id}`
  }));
};

export const useFetchAssessmentResponse = (token: string, recordId: string, nameInId?: boolean) => {
  const [assessmentResponse, setAssessmentsResponse] = useState<ClinicalAssessmentResponseList[] | undefined>(
    undefined
  );
  const [isAssessmentListLoading, setIsAssessmentListLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();

  const fetchAssessmentList = async () => {
    try {
      const callGetClinicalAssessmentResponsesListByPatientId =
        !isEdgeReceptionist && (await getClinicalAssessmentList(token, recordId));

      const assessmentTemplateList = await getClinicalAssessmentTemplate(token);
      const assessmentList = massageSQDSequence(assessmentTemplateList);

      const assessmentListRes: ClinicalAssessmentResponseList[] =
        callGetClinicalAssessmentResponsesListByPatientId &&
        (await callGetClinicalAssessmentResponsesListByPatientId.json());

      const massageAssessmentList = assessmentListRes.map((obj) => ({
        ...obj,
        id: assessmentList.find((assessmentObj) => assessmentObj.id === obj.id)?.metadata?.cords?.shortTitle || obj.id
      }));

      setAssessmentsResponse(!nameInId ? massageAssessmentList : massageNameInId(massageAssessmentList));
      setIsAssessmentListLoading(false);
    } catch (ex) {
      notification.error({ message: t('form.error.fetch_client_assessment_list') });
    }
  };

  useEffect(() => {
    if (token) {
      fetchAssessmentList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { assessmentResponse, isAssessmentListLoading };
};

export const useFetchTeacherPendingAssessments = (token: string, recordId: string) => {
  const [assessmentList, setAssessmentsList] = useState<TeacherPendingAssessmentResponse[]>([]);
  const [isAssessmentListLoading, setIsAssessmentListLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();

  const fetchAssessmentList = useCallback(async () => {
    setIsAssessmentListLoading(true);
    try {
      const res = !isEdgeReceptionist && (await getStudentPendingAssessmentList(token, recordId));

      const assessmentList = res && (await res.json());

      setAssessmentsList(assessmentList);
      setIsAssessmentListLoading(false);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: t('form.error.fetch_client_assessment_list') });
      setIsAssessmentListLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, recordId]);

  useEffect(() => {
    if (token) {
      fetchAssessmentList();
    }
  }, [token, fetchAssessmentList]);

  return { assessmentList, isAssessmentListLoading, fetchAssessmentList };
};

export const useFetchReportList = (token: string, recordId: string) => {
  const [reportList, setReportList] = useState<ReportClientTab[]>([]);
  const [isReportListLoading, setIsReportListLoading] = useState(true);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();

  const fetchReportList = async () => {
    if (!isEdgeReceptionist) {
      try {
        const getReportListData = isEdgeAdminView
          ? await getReportListByAdmin(token, `?clientRecordId=${recordId}`)
          : await getReportList(token, `?clientRecordId=${recordId}&reportStatus=published`);
        const reportData = (await getReportListData.json()) as {
          _id: string;
          status: 'draft' | 'published' | 'unpublished';
          reportName: string;
          createdAt: string;
        }[];

        const reportList = reportData
          .filter(({ status }) => status === 'published')
          .sort(({ createdAt: reportACreatedAt }, { createdAt: reportBCreatedAt }) =>
            reportBCreatedAt.localeCompare(reportACreatedAt)
          )
          .map(({ _id, reportName, createdAt }) => ({ _id, reportName, createdAt }))
          .filter((reportItems): reportItems is ReportClientTab => !!reportItems);

        // ToDo: To type this properly. Now we are dealing with string.
        setReportList(reportList);
        setIsReportListLoading(false);
      } catch (ex) {
        console.log(ex);
        notification.error({ message: t('form.error.fetch_client_report') });
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchReportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { reportList, isReportListLoading };
};

export const useFetchSurveyList = (token: string, recordId: string) => {
  const [surveyList, setSurveyList] = useState<SurveyClientTab[]>([]);
  const [isSurveyListLoading, setSurveyListLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();

  const fetchSurveyList = async () => {
    if (!isEdgeReceptionist) {
      try {
        const getSurveyInitList = await getOnboardingListByRecordId(token, recordId);
        const surveyData = (await getSurveyInitList.json()) as { _id: string; name: string; createdAt: string }[];

        const surveyList = surveyData
          .sort(({ createdAt: surveyACreatedAt }, { createdAt: surveyBCreatedAt }) =>
            surveyBCreatedAt.localeCompare(surveyACreatedAt)
          )
          .map(({ _id, name, createdAt }) => ({ _id, name, createdAt }));

        setSurveyList(surveyList);
        setSurveyListLoading(false);
      } catch (ex) {
        console.log(ex);
        notification.error({ message: t('form.error.fetch_client_survey') });
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchSurveyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { surveyList, isSurveyListLoading };
};

export const useFetchAssessment = (token: string, assessmentId: string) => {
  const [assessment, setAssessment] = useState<ClinicalAssessment>();
  const [isAssessmentLoading, setIsAssessmentLoading] = useState(false);

  const fetchAssessment = useCallback(
    async (token) => {
      if (assessmentId) {
        setIsAssessmentLoading(true);
        try {
          const getAssessmentResponse = await getAssessment(token, assessmentId);
          const assessmentDetails: ClinicalAssessment = await getAssessmentResponse.json();

          setAssessment(assessmentDetails);
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong whole trying to get assessment' });
        }
        setIsAssessmentLoading(false);
      }
    },
    [assessmentId]
  );

  useEffect(() => {
    if (token) {
      fetchAssessment(token);
    }
  }, [fetchAssessment, token]);

  return { assessment, isAssessmentLoading };
};
