import { ChangeEvent, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Row, Spin, Upload, notification } from 'antd';
import { UploadProps } from 'antd/lib/upload/Upload';
import ReactAvatarEditor from 'react-avatar-editor';

import Button from 'components/Button/Button';

import './AvatarEditor.scss';
import styles from './AvatarEditor.module.scss';

interface AvatarEditorProps {
  borderRadius: number;
  filenameSuffix: string;
  imgSrc?: string;
  placeholder: string;
  title: string;
  visible: boolean;
  isAvatarUploading: boolean;
  onBeforeUpload: UploadProps['beforeUpload'];
  onCancel: () => void;
  onClose: () => void;
  onUploadAvatar: (props: any) => void;
}

const AvatarEditor = ({
  borderRadius,
  filenameSuffix,
  imgSrc,
  placeholder,
  title,
  visible,
  isAvatarUploading,
  onBeforeUpload,
  onCancel,
  onClose,
  onUploadAvatar
}: AvatarEditorProps) => {
  const { user } = useAuth0();

  const editorRef = useRef<ReactAvatarEditor>(null);

  const [scale, setScale] = useState('1.00');
  const [rotation, setRotation] = useState(0);

  const resetEditor = () => {
    setRotation(0);
    setScale('1.00');
  };

  const handleCancel = () => {
    resetEditor();
    onCancel();
  };

  const handleClose = () => {
    resetEditor();
    onClose();
  };

  const handleScaleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setScale(e.target.value);
  };

  const handleRotateLeft = () => {
    if (rotation - 90 === -360) {
      setRotation(0);
    } else {
      setRotation(rotation - 90);
    }
  };

  const handleResetRotation = () => {
    setRotation(0);
  };

  const handleRotateRight = () => {
    if (rotation + 90 === 360) {
      setRotation(0);
    } else {
      setRotation(rotation + 90);
    }
  };

  const handleSavePicture = () => {
    try {
      if (editorRef) {
        const canvas = editorRef.current?.getImage();

        canvas?.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], `${user?.sub}-${filenameSuffix}.png`);

            onUploadAvatar({ file, onSuccess: handleClose });
          }
        });
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to upload your image' });
    }
  };

  const handleReuploadAvatar = (props: any) => {
    setRotation(0);
    setScale('1.00');

    onUploadAvatar && onUploadAvatar(props);
  };

  return (
    <Modal bodyStyle={{ padding: 0 }} footer={null} visible={visible} onCancel={handleCancel}>
      <div className={`${styles.container} clinician-profile-avatar-editor`}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>
          {!imgSrc && (
            <Upload
              className={`${styles.upload} circular-upload`}
              name="avatar"
              disabled={isAvatarUploading}
              showUploadList={false}
              beforeUpload={onBeforeUpload}
              customRequest={onUploadAvatar}
            >
              <div className={styles.uploadBody}>
                {isAvatarUploading ? (
                  <Spin size="small" spinning />
                ) : (
                  <>
                    <Row justify="center">
                      <FontAwesomeIcon icon={faCamera} size="2x" />
                    </Row>
                    <Row>
                      <div className={styles.iconSubtitle}>{placeholder}</div>
                    </Row>
                  </>
                )}
              </div>
            </Upload>
          )}
          {imgSrc && (
            <>
              <ReactAvatarEditor
                ref={editorRef}
                image={imgSrc}
                width={400}
                height={400}
                borderRadius={borderRadius}
                scale={Number(scale)}
                rotate={rotation}
                crossOrigin="anonymous"
              />
              <div className={styles.scaleSliderContainer}>
                <span className={styles.scale}>Scale: {scale}</span>
                <input
                  className={styles.focusRangeBar}
                  type={'range'}
                  min={0.01}
                  max={10}
                  step={0.01}
                  value={scale}
                  onChange={handleScaleChange}
                />
              </div>
              <div className={styles.rotationButtonsContainer}>
                <Button className={styles.rotationButton} onClick={handleRotateLeft}>
                  Rotate left
                </Button>
                <Button className={styles.rotationButton} onClick={handleResetRotation}>
                  Reset rotation
                </Button>
                <Button className={styles.rotationButton} onClick={handleRotateRight}>
                  Rotate right
                </Button>
              </div>
              <div className={styles.orDivider}>
                <span className={styles.orText}>or</span>
              </div>
              <div className={styles.submitButtonsContainer}>
                <Button className={`${styles.submitButton} ${styles.saveButton}`} onClick={handleSavePicture}>
                  SAVE PICTURE
                </Button>
                <Upload
                  name="avatar"
                  disabled={isAvatarUploading}
                  showUploadList={false}
                  beforeUpload={onBeforeUpload}
                  customRequest={handleReuploadAvatar}
                >
                  <Button className={`${styles.submitButton} ${styles.uploadButton}`} disabled={isAvatarUploading}>
                    UPLOAD NEW PICTURE
                  </Button>
                </Upload>
                <Button className={`${styles.submitButton} ${styles.cancelButton}`} onClick={handleCancel}>
                  CANCEL
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AvatarEditor;
