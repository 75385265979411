import { notification } from 'antd';
import { DataImportHistory } from 'pages/ControlPanel/Interfaces/MisConnection';
import { useCallback, useEffect, useState } from 'react';
import { getSchoolDataImportHistory } from 'utils/http/ClinicianProfileService/MisSchools/misSchools';

export const useFetchSchoolDataImportHistory = (token: string, accountId: string) => {
  const [dataImportHistory, setDataImportHistory] = useState<DataImportHistory[]>([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);

  const fetchSchoolDataImportHistory = useCallback(async (token: string, accountId: string) => {
    setIsHistoryLoading(true);
    try {
      const schoolConnectionResponse = await getSchoolDataImportHistory(token, accountId);
      const { history: schoolConnection } = (await schoolConnectionResponse.json()) as { history: DataImportHistory[] };

      setDataImportHistory(schoolConnection);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get data import history' });
    }
    setIsHistoryLoading(false);
  }, []);

  const refetchSchoolDataImportHistory = () => {
    fetchSchoolDataImportHistory(token, accountId);
  };

  useEffect(() => {
    if (token) {
      fetchSchoolDataImportHistory(token, accountId);
    }
  }, [fetchSchoolDataImportHistory, token, accountId]);

  return { dataImportHistory, isHistoryLoading, refetchSchoolDataImportHistory };
};
