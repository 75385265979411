import { useContext, useState } from 'react';

import styles from './Details.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import GroupInfo from './components/GroupInfo/GroupInfo';
import GroupActivity from './components/GroupActivity/GroupActivity';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import EditGroupModal from './components/EditClientModal/EditGroupModal';
import { useGetAccessToken } from 'utils/hooks/token';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { GroupType } from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';
import { UserContext } from 'utils/UserContextProvider';

export interface DetailsProps {
  groupDetails: Groups;
  groupTagList: Groups['tags'];
  profile: ProfileInterface;
  fetchGroupDetails: (token: string) => void;
}

const Details = ({ groupDetails, groupTagList, profile, fetchGroupDetails }: DetailsProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { account } = useContext(UserContext);
  const { token } = useGetAccessToken();
  const [editGroupModal, setEditGroupModal] = useState<boolean>(false);
  const notEditable =
    account.misSchool?.status === 'connected' && [GroupType.Registration, GroupType.Year].includes(groupDetails.type);
  return (
    <div className={styles.container}>
      <div className={styles.editLabelWrapper}>
        <div className={styles.title}>Group Information</div>
        {isEdgeAdminView && (
          <>
            {!notEditable && (
              <div className={styles.editBtn} onClick={() => setEditGroupModal(true)}>
                <span className={`material-icons-outlined ${styles.icon}`}>edit</span>
                Edit
              </div>
            )}
            <EditGroupModal
              groupData={groupDetails}
              onCancel={() => setEditGroupModal(false)}
              visible={editGroupModal}
              onRefreshData={() => fetchGroupDetails(token)}
            />
          </>
        )}
      </div>
      <GroupInfo groupDetails={groupDetails} groupTagList={groupTagList} />
      <div className={styles.activityWrapper}>
        <div className={styles.title}>Group Activity Feed</div>
        <GroupActivity groupDetails={groupDetails} profile={profile} />
      </div>
    </div>
  );
};

export default Details;
