import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import {
  getInterventionList,
  getInterventionListingByAccountId
} from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import { InterventionListData, InterventionStatus } from 'MHH/pages/Interventions/Interfaces/Interventions';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

export const useFetchInterventionList = (token: string) => {
  const { accountId } = useGetAccountId();
  const [interventionList, setInterventionList] = useState<InterventionListData['interventions']>([]);
  const [isInterventionListLoading, setIsInterventionListLoading] = useState(true);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const fetchInterventionList = useCallback(
    async (token: string) => {
      try {
        const interventionListData =
          isEdgeAdminView || isEdgeReceptionist
            ? await getInterventionListingByAccountId(token, accountId)
            : await getInterventionList(token);
        const interventionListingData: InterventionListData = await interventionListData.json();

        if (interventionListingData) {
          setInterventionList(
            interventionListingData?.interventions?.filter(
              (intervention) => intervention.status === InterventionStatus.Active
            )
          );
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get your intervention list.' });
      }

      setIsInterventionListLoading(false);
    },
    [accountId, isEdgeAdminView, isEdgeReceptionist]
  );

  useEffect(() => {
    if (token) {
      fetchInterventionList(token);
    }
  }, [fetchInterventionList, token]);

  return { interventionList, isInterventionListLoading, setInterventionList, fetchInterventionList };
};
