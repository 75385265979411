import { GroupDetailsTab, Groups } from 'pages/Groups/Interfaces/Groups';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import AnytimeStageCard from './components/AnytimeStageCard/AnytimeStageCard';
import DeleteStageModal from './components/DeleteStageModal/DeleteStageModal';
import SequentialStageCard from './components/SequentialStageCard/SequentialStageCard';
import { useFetchStageList } from './hooks/getStageList';
import { PathwayStageItem } from './interface';
import styles from './PathwayManagement.module.scss';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTranslation } from 'react-i18next';

interface PathManagementProps {
  groupDetail: Groups;
  fetchGroupDetails: (token: string) => void;
}

const PathwayManagement = ({ groupDetail, fetchGroupDetails }: PathManagementProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { token } = useGetAccessToken();
  const { GROUPS } = useRoutesGenerator();

  const paramsPath = useParams<{ groupId: string; groupTab: string }>();
  const groupId = paramsPath.groupId ?? '';

  const { stageListing, isStageListingLoading, fetchStageListing } = useFetchStageList(token, groupId);

  const [stageItemToBeDeleted, setStageItemToBeDeleted] = useState<PathwayStageItem>();
  const [t] = useTranslation();

  useEffect(() => {
    if (token && location.search === '?refetch') {
      fetchStageListing(token);
      fetchGroupDetails(token);
      navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.PathwayManagement}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleRefetchList = () => {
    fetchStageListing(token);
    fetchGroupDetails(token);
    setStageItemToBeDeleted(undefined);
  };

  const handleDeleteModal = (stageItem: PathwayStageItem) => {
    setStageItemToBeDeleted(stageItem);
  };

  const isGroupLeader = isEdgeAdminView || auth0ClinicianId === groupDetail?.leadClinicianAuth0Id;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>Pathway Management</div>
        <div className={styles.content}>
          <div className={styles.subTitle}>Stage</div>
          <div className={styles.stageContainer}>
            <div className={styles.stageWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.stageLabel}>Sequential Stage</div>
                <div className={styles.desc}>You can drag and drop to change the sequence.</div>
              </div>
              <SequentialStageCard
                groupId={groupId}
                sequentialStageList={stageListing?.sequential || []}
                isStageListingLoading={isStageListingLoading}
                onRefetchList={handleRefetchList}
                onOpenDeleteModal={handleDeleteModal}
                allowEditStage={isGroupLeader}
              />
            </div>
            <div className={styles.stageWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.stageLabel}>{t('group_pathway.update_anytime_stage')}</div>
                <div className={styles.desc}>{t('group_pathway.update_anytime_stage.description')}</div>
              </div>
              <AnytimeStageCard
                groupId={groupId}
                anytimeStageList={stageListing?.anytime || []}
                isStageListingLoading={isStageListingLoading}
                onRefetchList={handleRefetchList}
                onOpenDeleteModal={handleDeleteModal}
                allowEditStage={isGroupLeader}
              />
            </div>
          </div>
        </div>
        <DeleteStageModal
          onCloseModal={() => {
            setStageItemToBeDeleted(undefined);
          }}
          stageItemToBeDeleted={stageItemToBeDeleted}
          stageItemList={stageListing}
          groupId={groupId}
          onRefetchList={handleRefetchList}
        />
      </div>
    </div>
  );
};

export default PathwayManagement;
