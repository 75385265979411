import { notification } from 'antd';
import { FormListingInterface } from 'interfaces/Form/FormInterface';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import { FirstAssessmentsResponse } from '../../../interfaces/checkInService/firstAssessment';
import { ConsentFormInterface } from '../../../interfaces/ConsentForm/ConsentFormInterface';
import { getMicroCheckInAssessments } from '../../../store/CheckIn/GetMCIAssessment/action';
import { getSurveyFormListing } from '../../../utils/http/CheckInService/Assessment/surveyAssessment';
import { getConsentFormListing } from '../../../utils/http/DocumentService/ConsentForm/consentForm';

export const useFetchFormList = (token: string) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [formListData, setFormListData] = useState<FormListingInterface[]>([]);
  const [isFormListDataLoading, setIsFormListDataLoading] = useState(true);

  const fetchConsentList = async (token: string) => {
    try {
      const GetSurveyData = (await (await getSurveyFormListing(token)).json()) as FirstAssessmentsResponse;
      const GetMCIData = !isEdgeAdminView ? await getMicroCheckInAssessments(token) : [];
      const GetConsentData = (await (await getConsentFormListing(token)).json()) as ConsentFormInterface[];

      const massageSurveyData = GetSurveyData.firstAssessments.map((surveyObj) => ({
        _id: surveyObj.id,
        formType: 'survey',
        name: surveyObj.name,
        isPublished: surveyObj.isPublished,
        isDefault: surveyObj.isDefault,
        createdAt: surveyObj.createdAt,
        updatedAt: surveyObj.updatedAt,
        tags: surveyObj.tags ?? []
      }));

      const massageMCIData = GetMCIData.map((mciObj) => ({
        _id: mciObj.id,
        formType: mciObj.assessmentType,
        name: mciObj.name,
        isPublished: mciObj.isPublished,
        isDefault: mciObj.isDefault,
        createdAt: mciObj.createdAt,
        updatedAt: mciObj.updatedAt,
        clients: mciObj.clients
      }));

      const massageConsentData = GetConsentData.map((consentObj) => ({
        _id: consentObj._id,
        formType: 'consent',
        name: consentObj.name,
        isPublished: consentObj.isPublished,
        isDefault: consentObj.isDefault,
        createdAt: consentObj.createdAt,
        updatedAt: consentObj.updatedAt
      }));

      const massageData = [...massageConsentData, ...massageSurveyData, ...massageMCIData];

      setFormListData(massageData as FormListingInterface[]);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get form record' });
    }

    setIsFormListDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchConsentList(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { formListData, isFormListDataLoading };
};
