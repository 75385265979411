import FormSection from 'components/v2/FormSection/FormSection';
import Radio from 'components/Radio/Radio';
import styles from './Assignments.module.scss';
import { useField } from 'formik';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchClinicians } from '../../../../hooks/GetClinicians';
import AssignmentClinicians from './components/AssignmentClinicians/AssignmentClinicians';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { AssignmentMode } from 'interfaces/Schedule/AppointmentType';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

import { TFunction, useTranslation } from 'react-i18next';

const getAssignmentOptions = (t: TFunction) => [
  { label: t('calendar.assignment_mode.practice'), value: AssignmentMode.Practice },
  {
    label: t('calendar.assignment_mode.auto_assign'),
    value: AssignmentMode.AutoAssign,
    tooltip: t('calendar.assignment_mode.auto_assign.tooltip')
  },
  { label: t('calendar.assignment_mode.all'), value: AssignmentMode.All },
  { label: t('calendar.assignment_mode.all_mentors'), value: AssignmentMode.AllMentors },
  { label: t('calendar.assignment_mode.selected'), value: AssignmentMode.Selected }
];

const Assignments = () => {
  const { token } = useGetAccessToken();
  const [{ value, onChange }] = useField('assignmentMode');
  const { clinicians, isCliniciansLoading } = useFetchClinicians(token);
  const { isMentorFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  const generateClinicianSelections = () => {
    return isCliniciansLoading ? (
      <div className={styles.loading} style={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingCircle />
      </div>
    ) : clinicians.practitionerList.length > 0 ? (
      <>
        <AssignmentClinicians clinicians={clinicians.practitionerList} />
      </>
    ) : (
      <div>There is no clinicians</div>
    );
  };

  return (
    <FormSection
      title={t('calendar.form.assignments.title')}
      help={t('calendar.form.assignments.help')}
      subtitle={t('calendar.form.assignments.subtitle')}
    >
      <div className={styles.assignmentForm}>
        <Radio
          name="assignmentMode"
          options={getAssignmentOptions(t).filter(
            (option) => option.value !== AssignmentMode.AllMentors || isMentorFeatureToggle
          )}
          value={value}
          onChange={onChange}
          vertical
        />
        {[AssignmentMode.Selected, AssignmentMode.AutoAssign].includes(value) && generateClinicianSelections()}
      </div>
    </FormSection>
  );
};

export default Assignments;
