import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from './InterventionDetailsHeader.module.scss';

import Button from 'components/v2/Button/Button';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import {
  InterventionDetailsTab,
  Interventions,
  InterventionStatus
} from 'MHH/pages/Interventions/Interfaces/Interventions';
import { Skeleton } from 'antd';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import AddInterventionMembers from '../InterventionContent/components/AddInterventionMembers/AddInterventionMembers';
import AddStageModal from '../InterventionContent/components/PathwayManagement/components/StageForm/AddStageModal/AddStageModal';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

export interface InterventionDetailsHeaderProps {
  interventionDetails?: Interventions;
  isInterventionDetailsLoading: boolean;
  currentInterventionTab: InterventionDetailsTab | string;
}

const InterventionDetailsHeader = ({
  interventionDetails,
  isInterventionDetailsLoading,
  currentInterventionTab
}: InterventionDetailsHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth0ClinicianId } = useGetClinicianId();
  const { INTERVENTIONS } = useRoutesGenerator();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const queryParam: { q?: string } = queryString.parse(location.search);
  const { interventionId = '' } = useParams<{ interventionId: string }>();
  const qParam = queryString.stringify(queryParam);
  const [visibleAddMember, setVisibleAddMember] = useState<boolean>(false);
  const [visibleAddNewStage, setVisibleAddNewStage] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem('interventionListSearchValue', qParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // no want to have dep in purpose, no want to keep setItem for everytime change path

  const handleBackBtn = () => {
    const interventionListSearchValue = localStorage.getItem('interventionListSearchValue');
    const status = interventionDetails?.status ?? InterventionStatus.Active;
    navigate(`${INTERVENTIONS.BASE}/${status}${interventionListSearchValue ? `?${interventionListSearchValue}` : ''}`);
  };

  const isGroupLeader = auth0ClinicianId === interventionDetails?.leadClinicianAuth0Id;

  const getRightHeaderComponent = () => {
    switch (currentInterventionTab) {
      case InterventionDetailsTab.Members:
        return (
          isEdgeAdminView && (
            <>
              <Button onClick={() => setVisibleAddMember(true)}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                Add New Member
              </Button>
              <AddInterventionMembers
                title={interventionDetails?.name || ''}
                showModal={visibleAddMember}
                setShowModal={(value) => setVisibleAddMember(value)}
              />
            </>
          )
        );
      case InterventionDetailsTab.PathwayManagement:
        return (
          (isEdgeAdminView || isGroupLeader) && (
            <>
              <Button onClick={() => setVisibleAddNewStage(true)}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                Add New Stage
              </Button>
              <AddStageModal
                interventionId={interventionId}
                showModal={visibleAddNewStage}
                setShowModal={() => setVisibleAddNewStage(false)}
              />
            </>
          )
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftWrapper}>
        {isInterventionDetailsLoading ? (
          <div className={styles.loadingWrapper}>
            <Skeleton.Input active className={styles.loading} />
          </div>
        ) : (
          <div className={styles.title}>{interventionDetails?.name}</div>
        )}
        <Button variant={'link'} className={styles.backBtn} onClick={() => handleBackBtn()}>
          <i className={`material-icons ${styles.backIcon}`}>arrow_back_ios</i>
          View all interventions
        </Button>
      </div>
      <div className={styles.rightWrapper}>{getRightHeaderComponent()}</div>
    </div>
  );
};

export default InterventionDetailsHeader;
