import { useEffect, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { getName } from 'utils/general';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';

import styles from './ClientRecordList.module.scss';

interface ClientRecordListProps {
  clientRecords: clientRecordsInterface[];
  handleSelectClientRecord: (selectedRecord?: clientRecordsInterface) => void;
  selectedClientRecordId?: string;
  selectedClientRecordIds?: string[];
  isMulti?: boolean;
  onClientListLoading?: (isLoading: boolean) => void;
  isLoading: boolean;
  descriptionRender?: (val: clientRecordsInterface) => string;
  hideAvatar?: boolean;
  filter?: (item: clientRecordsInterface) => boolean;
}

const ClientRecordList = ({
  clientRecords,
  handleSelectClientRecord,
  selectedClientRecordId,
  selectedClientRecordIds,
  isMulti,
  descriptionRender,
  onClientListLoading,
  isLoading,
  hideAvatar,
  filter
}: ClientRecordListProps) => {
  const availabilityListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onClientListLoading && onClientListLoading(isLoading);

    if (
      selectedClientRecordId &&
      !isLoading &&
      clientRecords &&
      clientRecords.length > 0 &&
      clientRecords.find((item) => item._id === selectedClientRecordId)
    ) {
      // Scroll to selected item
      const selectedClientElement = document.getElementById('selectedClient');
      selectedClientElement?.scrollIntoView({ block: 'nearest' });
    }
  }, [clientRecords, isLoading, selectedClientRecordId, onClientListLoading]);

  const filteredList = filter ? clientRecords.filter(filter) : clientRecords;

  const [searchValue, setSearchValue] = useState('');

  const searchClientList = filteredList.filter((clientRecordItem: clientRecordsInterface) => {
    const checkString = (searchObj?: string) => searchObj?.toLowerCase().includes(searchValue.toLowerCase().trim());
    return checkString(getName(clientRecordItem.clientProfiles[0])) || checkString(clientRecordItem.class);
  });

  return (
    <>
      <div className={styles.searchBar}>
        <input
          className={styles.input}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
        />
        <div className={styles.searchBarIcon}>
          <i className="material-icons-outlined">search</i>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          {[...Array(6)].map((obj, i) => (
            <div key={i} className={styles.loadingBox}>
              <Skeleton.Avatar active />
              <Skeleton.Input active className={styles.loadingName} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.container}>
          {searchClientList.length > 0 ? (
            <div ref={availabilityListRef}>
              {searchClientList.map((clientObj, index) => {
                const primaryProfile = clientObj.clientProfiles[0];
                const recordId = clientObj._id;

                const clientProfileType = clientObj.recordType;

                if (!primaryProfile) {
                  return null;
                }

                return (
                  <div
                    id={
                      (!isMulti ? selectedClientRecordId === recordId : selectedClientRecordIds?.includes(recordId))
                        ? 'selectedClient'
                        : ''
                    }
                    onClick={() => handleSelectClientRecord(clientObj)}
                    className={classNames(styles.clientBox, {
                      [styles.active]: !isMulti
                        ? selectedClientRecordId === recordId
                        : selectedClientRecordIds?.includes(recordId)
                    })}
                    key={index}
                  >
                    <div>
                      <ClientAvatar
                        name={getName(primaryProfile)}
                        description={descriptionRender ? descriptionRender(clientObj) : undefined}
                        clientData={clientObj.clientProfiles}
                        initialsName={primaryProfile?.initials || ''}
                        avatarUrl={primaryProfile?.avatar}
                        avatarSize={36}
                        initialsClassName={styles.initialName}
                        nameClassName={styles.clientName}
                        horizontal
                        displayFirstNameOnly={clientProfileType === 'couple'}
                        displayLimit={clientProfileType === 'couple' ? 2 : 1}
                        hideAvatar
                      />
                    </div>
                    {(!isMulti ? selectedClientRecordId === recordId : selectedClientRecordIds?.includes(recordId)) && (
                      <div className={styles.selectedBadge}>
                        <span className="material-icons">check_circle</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : searchValue.length > 0 && searchClientList.length === 0 ? (
            <div className={styles.noItems}>
              <i className="material-icons-outlined">search_off</i>
              No matching student found
            </div>
          ) : (
            <div className={styles.noFoundLabel}>No client records found</div>
          )}
        </div>
      )}
    </>
  );
};

export default ClientRecordList;
