import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { UnassignedGroupMemberList, UnassignedGroupMemberListFilter } from 'pages/Groups/Interfaces/Groups';
import { getUnassignedGroupMembersByGroupId } from 'utils/http/ClinicianProfileService/Groups/GroupMembers';
import queryString from 'query-string';

export const useFetchAssignedGroupMemberList = (
  token: string,
  accountId: string,
  groupId: string,
  filterValue?: UnassignedGroupMemberListFilter
) => {
  const [unassignedGroupMembers, setUnassignedGroupMembers] = useState<UnassignedGroupMemberList>({
    clientRecords: [],
    paging: {
      page: 1,
      perPage: 20,
      totalItem: 0
    }
  });
  const [isUnassignedGroupMembersLoading, setIsUnassignedGroupMembersLoading] = useState(true);

  const fetchUnassignedGroupMemberList = useCallback(
    async (token: string, filterValue?: UnassignedGroupMemberListFilter) => {
      try {
        setIsUnassignedGroupMembersLoading(true);
        const qParam = queryString.stringify(filterValue || {});

        const getGroupMemberData = await getUnassignedGroupMembersByGroupId(token, accountId, groupId, qParam);
        const groupMemberListData: UnassignedGroupMemberList = await getGroupMemberData.json();

        setUnassignedGroupMembers(groupMemberListData);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your unassigned groups member list' });
      }
      setIsUnassignedGroupMembersLoading(false);
    },
    [groupId, accountId]
  );

  useEffect(() => {
    if (token) {
      fetchUnassignedGroupMemberList(token, filterValue);
    }
  }, [fetchUnassignedGroupMemberList, token, filterValue]);

  return { unassignedGroupMembers, isUnassignedGroupMembersLoading, fetchUnassignedGroupMemberList };
};
