import { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './SurveyFormListContent.module.scss';
import columnStyles from './SurveyFormListContentColumn.module.scss';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import { SurveyFormInterface } from 'pages/Groups/Interfaces/GroupsSurvey';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { combineName } from 'utils/general';
import { Skeleton } from 'antd';
import ViewAction from './components/ViewAction/ViewAction';

interface SurveyFormListContentProps {
  groupSurveyClientList: SurveyFormInterface[];
  isSurveyFormListLoading: boolean;
}

const SurveyFormListContent = ({ groupSurveyClientList, isSurveyFormListLoading }: SurveyFormListContentProps) => {
  const [sortType, setSortType] = useState<SortType>(SortType.noSort);
  const [sortedSurveyList, setSortedSurveyList] = useState(groupSurveyClientList);

  useEffect(() => {
    if (groupSurveyClientList) {
      setSortedSurveyList(groupSurveyClientList);
      setSortType(SortType.noSort);
    }
  }, [groupSurveyClientList]);

  const onChangeSortType = (sortType: SortType) => {
    setSortType(sortType);
    const newSortedList = groupSurveyClientList.sort((a, b) => {
      const nameA = combineName(a.clientProfiles);
      const nameB = combineName(b.clientProfiles);
      return sortType === SortType.asc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    setSortedSurveyList(newSortedList);
  };

  return (
    <div className={styles.container}>
      <div className={classnames(columnStyles.columnWrapper, styles.header)}>
        <div className={columnStyles.avatar}>AVATAR</div>
        <div className={classnames(columnStyles.fullName, sortType !== SortType.noSort ? columnStyles.active : '')}>
          FULL NAME
          <SortBtn
            columnName={'fullName'}
            selectedSortName={sortType}
            onChangeSort={onChangeSortType}
            disabled={isSurveyFormListLoading}
          />
        </div>
        <div className={columnStyles.surveySent}>SURVEY SENT</div>
        <div className={columnStyles.surveyComplete}>SURVEY COMPLETE</div>
        <div className={columnStyles.view}>VIEW</div>
      </div>
      {isSurveyFormListLoading ? (
        <>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))}
        </>
      ) : sortedSurveyList?.length > 0 ? (
        <>
          {sortedSurveyList?.map((surveyObj, index) => (
            <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
              <div className={columnStyles.avatar}>
                <ClientAvatar clientData={surveyObj.clientProfiles} displayLimit={2} fullAvatarBg hideName />
              </div>
              <div className={classnames(columnStyles.fullName, styles.name)}>
                {combineName(surveyObj.clientProfiles)}
              </div>
              <div className={classnames(columnStyles.surveySent, styles.isSentText)}>
                {surveyObj.clientProfiles.map((profileObj, index) => (
                  <div key={index}>{profileObj.surveySent ? 'Yes' : 'Preference set to not send'}</div>
                ))}
              </div>
              <div className={classnames(columnStyles.surveyComplete, styles.isCompleteText)}>
                {surveyObj.clientProfiles.map((profileObj, index) => (
                  <div key={index}>
                    {!profileObj.surveySent ? 'Preference set to not send' : profileObj.surveyComplete ? 'Yes' : 'No'}
                  </div>
                ))}
              </div>
              <div className={columnStyles.view}>
                <ViewAction surveyFormData={surveyObj} />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.noSurveyFoundContainer}>No Survey found</div>
      )}
    </div>
  );
};

export default SurveyFormListContent;
