import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { HeadingTemplate } from '../components/GroupCaseNoteHeadingTemplateList/GroupCaseNoteHeadingTemplateList';
import { getGroupCaseNoteHeadingTemplates } from 'utils/http/DocumentService/CaseNotes/groupCaseNotes';

export const useFetchCaseNoteHeadingTemplates = (token: string) => {
  const [caseNoteHeadingTemplates, setCaseNoteHeadingTemplates] = useState<HeadingTemplate[]>([]);
  const [areCaseNoteHeadingTemplatesLoading, setAreCaseNoteHeadingTemplatesLoading] = useState(false);

  const fetchCaseNoteHeadingTemplates = async (token: string) => {
    try {
      const callGetCaseNoteHeadingTemplates = await getGroupCaseNoteHeadingTemplates(token);
      const caseNoteHeadingTemplates = await callGetCaseNoteHeadingTemplates.json();
      setCaseNoteHeadingTemplates(caseNoteHeadingTemplates);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your case note heading templates'
      });
    }

    setAreCaseNoteHeadingTemplatesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCaseNoteHeadingTemplates(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { caseNoteHeadingTemplates, areCaseNoteHeadingTemplatesLoading, setCaseNoteHeadingTemplates };
};
