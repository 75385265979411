import { useState, useEffect } from 'react';
import { InvoiceSettings } from '../../pages/Invoices/interface';
import { getInvoiceSettings } from '../http/BillingService/Invoice/invoice';
import { notification } from 'antd';
import { getClinician } from '../http/clinician';

export const useFetchInvoiceSettings = (token: string, user: any) => {
  const [invoiceSettings, setInvoiceSettings] = useState<InvoiceSettings>();
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);

  const fetchInvoiceSettings = async (token: string) => {
    try {
      const callGetClinician = await getClinician(user.sub);
      const data = await callGetClinician.json();
      const { practice } = data;

      const callInvoiceSettings = await getInvoiceSettings(token);
      const { invoiceSettings, medicare } = await callInvoiceSettings.json().catch(() => ({}));
      const isInvoiceSettingCDetailsExist = !!(invoiceSettings && invoiceSettings.contactDetails);
      const { email } = user;
      const mobileNumber = user['https://tacklit.com/mobile_number'];

      const firstPractice = practice ? practice : undefined;

      const { dueDate = 0, note = '', paymentMethods = [], taxRate = 10 } = invoiceSettings || {};

      const massageInvoiceSettingData = {
        dueDate,
        note,
        paymentMethods,
        taxRate,
        contactDetails: {
          practice: {
            address: {
              value: isInvoiceSettingCDetailsExist
                ? invoiceSettings.contactDetails.practice.address.value
                : firstPractice?.locations?.[0],
              isVisible: isInvoiceSettingCDetailsExist
                ? invoiceSettings.contactDetails.practice.address.isVisible
                : true
            },
            mobileNumber: {
              value: isInvoiceSettingCDetailsExist
                ? invoiceSettings.contactDetails.practice.mobileNumber.value
                : firstPractice?.mobileNumber,
              isVisible: isInvoiceSettingCDetailsExist
                ? invoiceSettings.contactDetails.practice.mobileNumber.isVisible
                : true
            }
          },
          clinician: {
            mobileNumber: {
              value: isInvoiceSettingCDetailsExist
                ? invoiceSettings.contactDetails.clinician.mobileNumber.value
                : mobileNumber,
              isVisible: isInvoiceSettingCDetailsExist
                ? invoiceSettings.contactDetails.clinician.mobileNumber.isVisible
                : true
            },
            email: {
              value: isInvoiceSettingCDetailsExist ? invoiceSettings.contactDetails.clinician.email.value : email,
              isVisible: isInvoiceSettingCDetailsExist ? invoiceSettings.contactDetails.clinician.email.isVisible : true
            }
          }
        },
        medicare
      };

      setInvoiceSettings(massageInvoiceSettingData);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your invoice settings.',
        duration: 2
      });
    }
    setIsInvoiceLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchInvoiceSettings(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { invoiceSettings, isInvoiceLoading, setInvoiceSettings };
};
