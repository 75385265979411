import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const postConnectCodat = (token: string) =>
  httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/integrations`
  ) as Promise<BentResponse>;

export const getCodatTaxRates = (token: string, companyId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/taxRates`
  ) as Promise<BentResponse>;

export const getCodatAccounts = (token: string, companyId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/accounts`
  ) as Promise<BentResponse>;

export const getCodatInvoiceItems = (token: string, companyId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/items`
  ) as Promise<BentResponse>;

export const getCodatTrackingCategories = (token: string, companyId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/trackingCategories`
  ) as Promise<BentResponse>;

export const putCodatMappings = (token: string, companyId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/mappings`,
    payload
  ) as Promise<BentResponse>;

export const getCodatMappings = (token: string, companyId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/mappings`
  ) as Promise<BentResponse>;

export const getCodatTransactions = (token: string, companyId: string, filter?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/transactions${filter ? `?status=${filter}` : ''}`
  ) as Promise<BentResponse>;

export const postRetryTransactions = (token: string, companyId: string, transactionId: string) =>
  httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/codat/companies/${companyId}/transactions/${transactionId}:retry`
  ) as Promise<BentResponse>;

export const deleteIntegration = (token: string, companyId: string) =>
  httpClient('DELETE', url!, 200, { Authorization: `Bearer ${token}` })(`/accounts/me/codat/companies/${companyId}`);
