import { useState } from 'react';
import styles from './ConsentForms.module.scss';
import {
  ConsentFormListFilterParameter,
  ConsentFormResponseList,
  GroupDetailsTab
} from 'pages/Groups/Interfaces/Groups';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import { useFetchConsentFormList } from './hooks/getConsentFormList';
import ConsentFormHeader from './components/ConsentFormsHeader/ConsentFormsHeader';
import ConsentFormList from './components/ConsentFormList/ConsentFormList';

interface ConsentFormsProps {
  consentId?: string;
  consentFormMenuList: ConsentFormResponseList[];
}

const ConsentForms = ({ consentId, consentFormMenuList }: ConsentFormsProps) => {
  const { token } = useGetAccessToken();
  const location = useLocation();
  const queryParam: ConsentFormListFilterParameter = queryString.parse(location.search);
  const { groupId = '' } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const [searchQueryParam, setSearchQueryParam] = useState<ConsentFormListFilterParameter>({
    q: queryParam.q || '',
    page: queryParam.page && !isNaN(parseInt(queryParam.page, 10)) ? queryParam.page : '1',
    perPage: queryParam.perPage && !isNaN(parseInt(queryParam.perPage, 10)) ? queryParam.perPage : '20',
    sortType: queryParam.sortType && Object.values(SortType).includes(queryParam.sortType) ? queryParam.sortType : 1,
    sortColumn: queryParam.sortColumn ? queryParam.sortColumn : ''
  });

  const { consentFormList, isConsentFormListLoading } = useFetchConsentFormList(token, groupId, searchQueryParam);

  const setNewParam = (newParamValue: ConsentFormListFilterParameter) => {
    setSearchQueryParam(newParamValue);
    const paramStringify = queryString.stringify(newParamValue);
    navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Consent}/${consentId}?${paramStringify}`);
  };

  const handleChangePerPage = (perPageValue: number) => {
    if (parseInt(searchQueryParam.perPage as string, 10) !== perPageValue) {
      const newParam = {
        ...searchQueryParam,
        page: '1',
        perPage: perPageValue.toString()
      };
      setNewParam(newParam);
    }
  };

  const handlePageChange = (pageValue: number) => {
    const newParam = {
      ...searchQueryParam,
      page: pageValue.toString()
    };
    setNewParam(newParam);
  };

  const handleChangeSortType = (sortType: SortType, sortColumn?: string) => {
    const newParam = {
      ...searchQueryParam,
      sortType: sortType,
      sortColumn: sortColumn
    };
    setNewParam(newParam);
  };

  return (
    <div className={styles.container}>
      <ConsentFormHeader
        groupId={groupId}
        consentFormId={consentId || consentFormMenuList[0]._id} // TODO
        consentFormMenuList={consentFormMenuList}
      />
      <ConsentFormList
        consentFormList={consentFormList}
        isConsentFormListLoading={isConsentFormListLoading}
        sortColumn={searchQueryParam.sortColumn || ''}
        selectedPerPage={parseInt(searchQueryParam.perPage as string, 10)}
        selectedPage={parseInt(searchQueryParam.page as string, 10)}
        sortType={searchQueryParam.sortType as SortType}
        onChangeSortType={handleChangeSortType}
        onChangePerPage={handleChangePerPage}
        onChangePage={handlePageChange}
      />
    </div>
  );
};

export default ConsentForms;
