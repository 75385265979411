import { MinifiedClinician } from 'interfaces/Clients/clinician';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { useMemo, useState } from 'react';

const useClientListingFilter = () => {
  const [classFilteredList, setClassFilteredList] = useState<string[]>([]);
  const [groupFilteredList, setGroupFilteredList] = useState<GroupsFromAPI[]>([]);
  const [teacherFilteredList, setTeacherFilteredList] = useState<MinifiedClinician[]>([]);
  const [assessorFilteredList, setAssessorFilteredList] = useState<MinifiedClinician[]>([]);

  const filterQuery = useMemo(() => {
    const classes = classFilteredList.length > 0 ? classFilteredList.join(',') : '';
    const groups = groupFilteredList.length > 0 ? groupFilteredList.map((group) => group._id).join(',') : '';
    const teachers = teacherFilteredList.length > 0 ? teacherFilteredList.map((teacher) => teacher._id).join(',') : '';
    const assessors =
      assessorFilteredList.length > 0 ? assessorFilteredList.map((assessor) => assessor._id).join(',') : '';

    if (!classes && !groups && !teachers && !assessors) {
      return '';
    }

    return new URLSearchParams({
      ...(classes && { classes }),
      ...(groups && { groupIds: groups }),
      ...(teachers && { clinicianAuth0Ids: teachers }),
      ...(assessors && { assessorIds: assessors })
    }).toString();
  }, [assessorFilteredList, classFilteredList, groupFilteredList, teacherFilteredList]);

  return {
    filterQuery,
    classFilteredList,
    setClassFilteredList,
    groupFilteredList,
    setGroupFilteredList,
    teacherFilteredList,
    setTeacherFilteredList,
    assessorFilteredList,
    setAssessorFilteredList
  };
};

export default useClientListingFilter;
