import PatientAnswer from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/components/PatientAnswer/PatientAnswer';
import PizzaChart1 from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/components/PizzaChart1/PizzaChart1';
import ScoreChart from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/components/ScoreChart/ScoreChart';
import styles from './PsychometricDetailContent.module.scss';
import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';

interface PsychometricDetailProps {
  psychometricDetails: Assessment;
}

const PsychometricDetailContent = ({ psychometricDetails }: PsychometricDetailProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.descriptionBox}>
          {psychometricDetails.charts.map((chart, index) => (
            <PizzaChart1 key={index} chart={chart} showAlternateColor={psychometricDetails.id === 'CA'} />
          ))}
          <div className={styles.descWrapper}>
            <div className={styles.title}>About</div>
            <div className={styles.desc}>{psychometricDetails.description || 'No description provided'}</div>
          </div>
          <div>
            {psychometricDetails.charts.length > 0 && <div className={styles.title}>Score Interpretations</div>}
            {psychometricDetails.charts.map((chart, index) => (
              <ScoreChart key={index} chart={chart} showAlternateColor={psychometricDetails.id === 'CA'} />
            ))}
          </div>
        </div>
        <PatientAnswer sections={psychometricDetails.sections} />
      </div>
    </div>
  );
};

export default PsychometricDetailContent;
