import { HTMLAttributes } from 'react';
import styles from './ClientPickAssessment.module.scss';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { PatientAssessment } from '../../SendAdhocAssessmentModal/interfaces';
import { Button, Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import classnames from 'classnames';
import ClientOrGroupPickAssessment from 'components/ClientOrGroupPickAssessment/ClientOrGroupPickAssessment';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import SubmitButton from 'components/v2/Button/Button';
import { useTranslation } from 'react-i18next';

interface ClientPickAssessmentProps {
  token: string;
  patientAssessments: PatientAssessment[];
  proxyProfiles?: PatientAssessment[];
  selectedProxyProfileId?: string;
  noReselectClient?: boolean;
  onBackClick: () => void;
  onNextClick: () => void;
  onAddSelectedAssessment: (item: ClinicalAssessmentStruct, clientId?: string) => void;
  onRemoveSelectedAssessment: (item: ClinicalAssessmentStruct, clientId?: string) => void;
  onSelectClientProfile: (isSelected: boolean, clientId: string) => void;
  handleProxyProfileChange: (value: string) => void;
}

const ClientPickAssessment = ({
  token,
  patientAssessments,
  noReselectClient,
  proxyProfiles,
  selectedProxyProfileId,
  className,
  handleProxyProfileChange,
  onBackClick,
  onNextClick,
  onAddSelectedAssessment,
  onRemoveSelectedAssessment,
  onSelectClientProfile
}: ClientPickAssessmentProps & HTMLAttributes<HTMLDivElement>) => {
  const [t] = useTranslation();

  const isDisableNextButton = () => {
    let isDisable = patientAssessments.filter((patient) => patient.isSelected).length < 1;
    if (!isDisable) {
      isDisable = !patientAssessments.some((patient) => {
        return patient.selectedAssessments.length > 0;
      });
    }
    return isDisable;
  };

  return (
    <div className={classnames(styles.container, className)}>
      {!noReselectClient && (
        <Button type="text" className={styles.backBtn} onClick={() => onBackClick()}>
          <Row gutter={8} align="middle">
            <Col className={styles.backBtnIcon}>
              <LeftOutlined />
            </Col>
            <Col>{t('label.change_client')}</Col>
          </Row>
        </Button>
      )}
      <div className={styles.pickAssessmentWrapper}>
        {patientAssessments.map((patientAssessment, index) => (
          <div key={patientAssessment.client._id} className={styles.clientItem}>
            <ClientOrGroupPickAssessment
              clientId={patientAssessment.client._id}
              selectedProxyProfileId={selectedProxyProfileId}
              onSelectClientProfile={onSelectClientProfile}
              isShowCheckBox={patientAssessments.length > 1}
              patientAssessment={patientAssessment}
              participationType={ParticipantType.OneToOne}
              selectedAssessments={patientAssessment.selectedAssessments}
              onAddSelectedAssessment={onAddSelectedAssessment}
              onRemoveSelectedAssessment={onRemoveSelectedAssessment}
              proxyProfiles={proxyProfiles}
              handleProxyProfileChange={handleProxyProfileChange}
            />
          </div>
        ))}
      </div>
      <div className={styles.submitBtn}>
        <SubmitButton disabled={isDisableNextButton()} onClick={onNextClick}>
          Next
        </SubmitButton>
      </div>
    </div>
  );
};

export default ClientPickAssessment;
