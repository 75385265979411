import { notification } from 'antd';
import Button from 'components/v2/Button/Button';
import { CronofyCalendar, CronofyProfile } from 'interfaces/Cronofy/cronofyProfile';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { postUnlinkCalendar, putCalendarConfig } from 'utils/http/ScheduleService/Cronofy/Cronofy';

import ConnectedProfile from './components/ConnectedProfile/ConnectedProfile';
import styles from './ConnectedProfiles.module.scss';

const renderHeaderWrapper = () => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.connectDomain}>Account</div>
      <div className={styles.connectSetting}>Calendars</div>
      <div className={styles.connectStatus}>Status</div>
    </div>
  );
};

interface ConnectedProfilesProps {
  profiles: CronofyProfile[];
  saveButton?: any;
}

const ConnectedProfiles = ({ profiles, saveButton }: ConnectedProfilesProps) => {
  const [profilesPayload, setProfilesPayload] = useState(profiles);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { token } = useGetAccessToken();

  useEffect(() => {
    setProfilesPayload(profiles);
  }, [profiles]);

  const handleCalendarChange = (updatedCalendar: CronofyCalendar) => {
    const updatedList = profilesPayload.map(({ id, provider, email, calendars, configuration }) => ({
      id,
      provider,
      email,
      calendars: calendars.map((calendar) => (calendar.id === updatedCalendar.id ? updatedCalendar : calendar)),
      configuration
    }));
    setProfilesPayload(updatedList);
    setShowSaveButton(true);
  };

  const handleShowAsPrivateChange = (id: string) => {
    const updatedList = profilesPayload.map((profile) => {
      if (profile.id === id) {
        return { ...profile, configuration: { showAsPrivate: !profile.configuration.showAsPrivate } };
      }
      return profile;
    });
    setProfilesPayload(updatedList);
    setShowSaveButton(true);
  };

  const handleSubmit = async (profilesPayload: CronofyProfile[]) => {
    const payload = {
      profiles: profilesPayload.map(({ id, calendars, configuration }) => ({
        id,
        calendars: calendars.map(({ id, configuration }) => ({
          id,
          configuration
        })),
        configuration
      })),
      asAdmin: isEdgeAdminView
    };
    try {
      await putCalendarConfig(token, payload);
      setTimeout(() => {
        notification.success({
          message: `Calendar configurations updated.`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }, 1000);
      setShowSaveButton(false);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Calendar configurations failed to update.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleUnlinkCalendar = async (id: string) => {
    const payload = {
      profileId: id,
      asAdmin: isEdgeAdminView
    };

    try {
      await postUnlinkCalendar(token, payload);
      setProfilesPayload(profilesPayload.filter((profile) => profile.id !== id));
      setTimeout(() => {
        notification.success({
          message: `Calendar configurations updated.`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }, 1000);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Calendar failed to unlink.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <div className={styles.connectedCalendarsContainer}>
      <div className={styles.connectedCalendars}>
        {profilesPayload.length > 0 ? (
          <>
            {renderHeaderWrapper()}
            {profilesPayload.map((profile, index) => (
              <div className={styles.calendarLinkingContainer} key={index}>
                <ConnectedProfile
                  cronofyProfile={profile}
                  handleCalendarChange={handleCalendarChange}
                  handleUnlinkCalendar={handleUnlinkCalendar}
                  handleShowAsPrivateChange={handleShowAsPrivateChange}
                />
              </div>
            ))}
          </>
        ) : (
          <div>Connect a calendar to start.</div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {saveButton}
        <Button
          hidden={!showSaveButton}
          variant="primary"
          onClick={() => handleSubmit(profilesPayload)}
          className={styles.saveButton}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ConnectedProfiles;
