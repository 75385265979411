import styles from './ClosedGroupListCard.module.scss';
import columnStyles from './ClosedGroupColumn.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import ClosedGroupItem from './components/ClosedGroupListItem';

interface ClosedGroupListCardProps {
  groupListing: Groups[];
  searchValue: string;
}

const ClosedGroupListCard = ({ groupListing, searchValue }: ClosedGroupListCardProps) => {
  return groupListing.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <div className={columnStyles.GroupNameSection}>Group Name</div>
        <div className={columnStyles.GSinceSection}>Group Since</div>
        <div className={columnStyles.GSinceSection}>Group Closed</div>
        <div className={columnStyles.GroupLeadSection}>Group Lead</div>
      </div>
      {groupListing.map((groupListObj, index) => (
        <ClosedGroupItem groupListingItem={groupListObj} key={index} searchValue={searchValue} />
      ))}
    </>
  ) : (
    <div>There is no data for the group</div>
  );
};

export default ClosedGroupListCard;
