import styles from './Profile.module.scss';
import darkLogo from '../../../assets/images/bazaar/darkLogo.png';
import profile1 from '../../../assets/images/bazaar/profile1.png';
import profile2 from '../../../assets/images/bazaar/profile2.png';

const Profile = () => {
  return (
    <div className={styles.container}>
      <div className={styles.profilesContainer}>
        <img src={darkLogo} alt="logo" />
        <img src={profile1} alt="profile1" />
        <img src={profile2} alt="profile2" />
        <div className={styles.namesContainer}>
          <div className={styles.name}>Sara</div>
          <div className={styles.plus}>+</div>
          <div className={styles.name}>Lynsey</div>
        </div>
      </div>
      <button className={styles.exitContainer}>
        <span className="material-icons-outlined">logout</span>
      </button>
    </div>
  );
};

export default Profile;
