import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from './CalendarHeader.module.scss';
import { getTimeZoneDateTime } from 'utils/hooks/GetTimezones/getTimezones';
import momentTz from 'moment-timezone';

export interface CalendarHeaderProps {
  profileData: ProfileInterface;
}

const CalendarHeader = ({ profileData }: CalendarHeaderProps) => {
  const { CALENDAR } = useRoutesGenerator();
  const { isEdgeReceptionist } = useGetAccountPackageView();

  const timeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timers = setInterval(() => {
      if (timeRef.current) {
        timeRef.current.innerText = getTimeZoneDateTime(
          momentTz().toISOString(),
          profileData.accountSettings?.timezone
        ).format('LLLL');
      }
    }, 5000);

    return () => clearInterval(timers);
  }, [profileData.accountSettings?.timezone]);

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Calendar</div>
          <div className={styles.date} ref={timeRef}>
            {timeRef.current?.innerText}
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        {isEdgeReceptionist || (
          <Link to={CALENDAR.SETTINGS_APT_TYPE} className={styles.configureBtnWrapper} id={'manageSettingId'}>
            <i className={`material-icons ${styles.icon}`}>settings</i>
            Manage calendar settings
          </Link>
        )}
      </div>
    </div>
  );
};

export default CalendarHeader;
