import { useState } from 'react';
import styles from './ClientInfo.module.scss';
import {
  clientProfilesEncrypted,
  clientRecordsEncryptedInterface,
  CommunicationPreference
} from 'interfaces/Clients/clientsRecord';
import ClientPersonalDetails from '../../components/ClientPersonalDetails/ClientPersonalDetails';
import EditClientModal from 'components/EditClientModal/EditClientModal';
import { editClientForm } from 'components/EditClientModal/EditClientModalInterface';
import AdultInfoBox from './component/AdultInfoBox/AdultInfoBox';
import ChildInfoBox from './component/ChildInfoBox/ChildInfoBox';
import CoupleInfoBox from './component/CoupleInfoBox/CoupleInfoBox';
import { useTranslation } from 'react-i18next';
import AssessmentSummary from './component/AssessmentSummary/AssessmentSummary';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface ClientInfoProps {
  clientRecordData: clientRecordsEncryptedInterface;
  onResendInvitation: () => void;
  onCancelInvitation: () => void;
  isInvitationProcessing: boolean;
  refetchClientDetail?: () => void;
}

const ClientInfo = ({
  clientRecordData,
  onResendInvitation,
  onCancelInvitation,
  isInvitationProcessing,
  refetchClientDetail
}: ClientInfoProps) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [showEditModal, setShowEditModal] = useState(false);

  const clientProfile = clientRecordData.clientProfiles[0] as clientProfilesEncrypted;

  const massageClientProfile = (clientDetails: clientRecordsEncryptedInterface['clientProfiles']) => {
    const newEditClientField = [] as editClientForm['clientProfiles'];
    for (let obj of clientDetails) {
      newEditClientField.push({
        _id: obj._id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        ...(obj.email && {
          email: obj.email
        }),
        ...(obj.mobileNumber && {
          mobileNumber: obj.mobileNumber
        }),
        ...(obj.isEmailThirdParty && {
          isEmailThirdParty: obj.isEmailThirdParty
        }),
        ...(obj.isMobileNumberThirdParty && {
          isMobileNumberThirdParty: obj.isMobileNumberThirdParty
        }),
        ...(obj.dateOfBirth && {
          dateOfBirth: obj.dateOfBirth
        }),
        ...(obj.isPrimaryContact && {
          isPrimaryContact: obj.isPrimaryContact
        }),
        ...(obj.role && {
          role: obj.role
        }),
        onboarding: {
          isCheckOnBoarding: !!obj.onboardingSettings?.firstAssessmentId,
          onboardingSurvey: obj.onboardingSettings?.firstAssessmentId,
          onboardingMethod: obj.onboardingSettings?.onboardingMethod as 'sendToClient' | 'facilitated'
        },
        consentForm: {
          isCheckOnConsentForm: false,
          consentFormId: ''
        },
        ...(obj.role !== 'child' && {
          communicationPreference: obj.communicationPreference
            ? obj.communicationPreference
            : CommunicationPreference.NoPreference
        }),
        ...(obj.profileType && {
          profileType: obj.profileType
        })
      });
    }
    return newEditClientField;
  };

  const editMassageData = {
    ...clientRecordData,
    _id: clientRecordData._id,
    clinicianAuth0Ids: clientRecordData.clinicianAuth0Ids,
    recordStatus: clientRecordData.recordStatus,
    recordType: clientRecordData.recordType,
    clientProfiles: massageClientProfile(clientRecordData.clientProfiles),
    clientCapability: {
      profileType: clientRecordData.clientProfiles[0].profileType
    }
  } as editClientForm;

  const getInfoBox = () => {
    switch (clientRecordData.recordType) {
      case 'adult':
        return <AdultInfoBox clientRecordData={clientRecordData} />;
      case 'child':
        return <ChildInfoBox clientRecordData={clientRecordData} horizontal={isEdgeReceptionist} />;
      case 'couple':
        return <CoupleInfoBox clientRecordData={clientRecordData} horizontal={isEdgeReceptionist} />;
      default:
        return <AdultInfoBox clientRecordData={clientRecordData} />;
    }
  };

  const [t] = useTranslation();

  return (
    <div className={styles.container} id={'clientInfoId'}>
      <div className={styles.infoWrapper}>
        <ClientPersonalDetails
          clientRecordData={clientRecordData}
          clientProfileData={clientProfile}
          onResendInvitation={onResendInvitation}
          onCancelInvitation={onCancelInvitation}
          isInvitationProcessing={isInvitationProcessing}
        />
        <div className={styles.subTitle}>
          {t('title.client_information').toUpperCase()}
          <div className={styles.editBtn} onClick={() => setShowEditModal(true)}>
            <i className={`material-icons ${styles.editIcon}`}>mode_edit</i>
            Edit
          </div>
        </div>
        {isEdgeReceptionist ? (
          getInfoBox()
        ) : (
          <div className={styles.detailsContainer}>
            <div className={styles.card}>{getInfoBox()}</div>
            <div className={styles.card}>
              <AssessmentSummary accountId={clientRecordData.accountId} clientRecordId={clientRecordData._id} />
            </div>
          </div>
        )}
      </div>
      <EditClientModal
        clientRecordData={editMassageData}
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
        onRefreshData={refetchClientDetail}
      />
    </div>
  );
};

export default ClientInfo;
