import { InvoiceViewProps } from '../../InvoiceView';
import { calculateInvoiceTotals } from './calculateInvoiceTotals';

import styles from './InvoiceTotal.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const MONEY_SYMBOL = GAE_REGION === 'au' ? '$' : GAE_REGION === 'gb' ? '£' : undefined;

interface InvoiceTotalProps {
  discount: InvoiceViewProps['discount'];
  items: InvoiceViewProps['items'];
  taxRate: InvoiceViewProps['taxRate'];
  status: InvoiceViewProps['status'];
}

const InvoiceTotal = ({ discount, items, taxRate, status }: InvoiceTotalProps) => {
  const { totalCost, totalTax, totalPayment } = calculateInvoiceTotals(items, taxRate, discount?.type, discount?.value);

  return (
    <div className={styles.container}>
      <span className={styles.totalLabel}>TOTAL</span>
      <span className={styles.costTotal}>
        {MONEY_SYMBOL}
        {totalCost}
      </span>
      <span className={styles.taxTotal}>
        {MONEY_SYMBOL}
        {totalTax}
      </span>
      <span className={styles.finalTotal}>
        {status !== 'confirmPaid' && <span className={styles.label}>TO PAY</span>}
        {MONEY_SYMBOL}
        {totalPayment}
      </span>
    </div>
  );
};

export default InvoiceTotal;
