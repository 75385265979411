import { QuickNote } from '../../AddQuickNoteModal';
import { useFetchQuickNotes } from 'utils/hooks/notes';
import { useGetAccessToken } from 'utils/hooks/token';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import QuickNoteCard from './components/QuickNoteCard/QuickNoteCard';

import styles from './QuickNoteList.module.scss';
import { useTranslation } from 'react-i18next';

interface QuickNoteListProps {
  recordId: string;
  selectedQuickNotes: QuickNote[];
  onSelectQuickNote: (quickNote: QuickNote) => void;
}

const QuickNoteList = ({ recordId, selectedQuickNotes, onSelectQuickNote }: QuickNoteListProps) => {
  const { token } = useGetAccessToken();
  const [t] = useTranslation();

  const { quickNotesData, isQuickNotesLoading } = useFetchQuickNotes(token, recordId);

  return isQuickNotesLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      {quickNotesData.length > 0 ? (
        quickNotesData.map((quickNoteObj, index) => (
          <QuickNoteCard
            key={index}
            quickNote={quickNoteObj}
            selected={selectedQuickNotes.map((quickNote) => quickNote._id).includes(quickNoteObj._id)}
            onClick={() => onSelectQuickNote(quickNoteObj)}
          />
        ))
      ) : (
        <div>{t('label.no_client_quick_note')}</div>
      )}
    </div>
  );
};

export default QuickNoteList;
