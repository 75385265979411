import styles from './AssessorAssessments.module.scss';
import useGetPaginatedAssessorAssessments from './hooks/useGetPaginatedAssessorAssessments';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import Loading from 'components/Loading/Loading';
import PaginationList from 'components/PaginationList/PaginationList';
import { ClinicalAssessmentLists } from './components/ClinicalAssessmentLists/ClinicalAssessmentLists';
import AssessorAssessmentHeader from './components/AssessorAssessmentHeader/AssessorAssessmentHeader';
import { useState } from 'react';
import SubmitAssessmentModal from './components/SubmitAssessmentModal/SubmitAssessmentModal';
import { QuestionResponses } from './types';

const AssessorAssessments = () => {
  const [selectedAssessmentForModal, setSelectedAssessmentForModal] = useState<QuestionResponses | null>(null);
  const [searchedName, setSearchedName] = useState('');
  const {
    isLoading: isLoadingAssessments,
    clinicalAssessments,
    perPage,
    navigatePage,
    currentPage,
    totalPage,
    totalItems,
    refetchAssessments
  } = useGetPaginatedAssessorAssessments({
    name: searchedName
  });

  const handleSearch = (val: string) => {
    if (isLoadingAssessments) return;
    setSearchedName(val);
    navigatePage(1);
  };

  return (
    <HelmetWrapper title={'CORDS - My Assessments'}>
      <AssessorAssessmentHeader handleSearch={handleSearch} />
      <ContentLayout>
        {isLoadingAssessments ? (
          <div className={styles.loadingContent}>
            <Loading />
          </div>
        ) : (
          <div className={styles.container}>
            <ClinicalAssessmentLists
              assessments={clinicalAssessments}
              handleCompleteAssessment={setSelectedAssessmentForModal}
            />
            <SubmitAssessmentModal
              assessmentData={selectedAssessmentForModal}
              onModalClose={() => setSelectedAssessmentForModal(null)}
              postCompleteAssessmentAction={refetchAssessments}
            />
            {totalPage > currentPage && (
              <PaginationList
                paging={{
                  page: currentPage,
                  perPage,
                  totalItem: totalItems,
                  totalPage
                }}
                onPageChange={(paging) => navigatePage(Number(paging))}
              />
            )}
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default AssessorAssessments;
