import styles from './Badge.module.scss';
import classnames from 'classnames';

interface BadgeProps {
  label: string;
  className?: any;
  backgroundColor?: string;
}

const Badge = ({ label, className, backgroundColor }: BadgeProps) => {
  return (
    <div className={classnames(styles.container, className)} style={{ backgroundColor }}>
      {label}
    </div>
  );
};

export default Badge;
