import { useMemo } from 'react';

import styles from './PizzaChart1.module.scss';
import { Assessment } from '../../PatientDetailsAssessments';

interface PizzaChartProps {
  chart: Assessment['charts'][number];
  showAlternateColor: boolean;
}

const generatePizzaChart = (rangeVal: number, showAlternateColor: boolean) => {
  switch (rangeVal) {
    case 6:
      return {
        minDeg: -30,
        maxDeg: 240,
        innerDeg: 40,
        pizzaBg: [
          {
            outer: 0,
            color: '#c4c4c4'
          },
          {
            outer: 40,
            color: '#f4e9dc'
          },
          {
            outer: 80,
            color: '#f5d779'
          },
          {
            outer: 120,
            color: '#f9bd01'
          },
          {
            outer: 160,
            color: '#f9a501'
          },
          {
            outer: 200,
            color: '#ff5722'
          },
          {
            outer: 240,
            color: '#fff'
          }
        ]
      };
    case 5:
      return {
        minDeg: -30,
        maxDeg: 240,
        innerDeg: 48,
        pizzaBg: [
          {
            outer: 0,
            color: '#c4c4c4'
          },
          {
            outer: 48,
            color: '#f4e9dc'
          },
          {
            outer: 96,
            color: '#f5d779'
          },
          {
            outer: 144,
            color: '#f9a501'
          },
          {
            outer: 192,
            color: '#ff5722'
          },
          {
            outer: 240,
            color: '#fff'
          }
        ]
      };
    case 4:
      if (showAlternateColor) {
        return {
          minDeg: -30,
          maxDeg: 240,
          innerDeg: 60,
          pizzaBg: [
            {
              outer: 0,
              color: '#57ba20'
            },
            {
              outer: 60,
              color: '#f8e870'
            },
            {
              outer: 120,
              color: '#f2a756'
            },
            {
              outer: 180,
              color: '#f8826a'
            },
            {
              outer: 240,
              color: '#fff'
            }
          ]
        };
      } else {
        return {
          minDeg: -30,
          maxDeg: 240,
          innerDeg: 60,
          pizzaBg: [
            {
              outer: 0,
              color: '#c4c4c4'
            },
            {
              outer: 60,
              color: '#f4e9dc'
            },
            {
              outer: 120,
              color: '#f5d779'
            },
            {
              outer: 180,
              color: '#f9a501'
            },
            {
              outer: 240,
              color: '#fff'
            }
          ]
        };
      }
    case 3:
      return {
        minDeg: -30,
        maxDeg: 240,
        innerDeg: 80,
        pizzaBg: [
          {
            outer: 0,
            color: '#c4c4c4'
          },
          {
            outer: 80,
            color: '#f4e9dc'
          },
          {
            outer: 160,
            color: '#f9a501'
          },
          {
            outer: 240,
            color: '#fff'
          }
        ]
      };
    case 2:
      return {
        minDeg: -30,
        maxDeg: 240,
        innerDeg: 120,
        pizzaBg: [
          {
            outer: 0,
            color: '#f5d779'
          },
          {
            outer: 120,
            color: '#f9a501'
          },
          {
            outer: 240,
            color: '#fff'
          }
        ]
      };
    case 1:
      return {
        minDeg: -30,
        maxDeg: 240,
        innerDeg: 120,
        pizzaBg: [
          {
            outer: 0,
            color: '#f9a501'
          },
          {
            outer: 120,
            color: '#f9a501'
          },
          {
            outer: 240,
            color: '#fff'
          }
        ]
      };

    default:
      return {
        minDeg: -30,
        maxDeg: 240,
        innerDeg: 120,
        pizzaBg: [
          {
            outer: 0,
            color: '#f9a501'
          },
          {
            outer: 120,
            color: '#f9a501'
          },
          {
            outer: 240,
            color: '#fff'
          }
        ]
      };
  }
};

const PizzaChart1 = ({ chart, showAlternateColor }: PizzaChartProps) => {
  const { maxDeg, minDeg, innerDeg, pizzaBg } = generatePizzaChart(chart.scales.length, showAlternateColor);

  const needlePosition = useMemo(() => {
    const { total, scales } = chart;

    const foundScale = scales.find((scale) => total >= scale.min && total <= scale.max);

    if (foundScale) {
      const index = scales.indexOf(foundScale);
      const value = total - foundScale.min;
      const difference = foundScale.max - foundScale.min;
      const sectorAngle = maxDeg / scales.length;

      if (index >= -1) {
        return Math.ceil(index * sectorAngle + (value * sectorAngle) / difference);
      }
    }

    return 0;
  }, [chart, maxDeg]);

  return (
    <div className={styles.container}>
      <div className={styles.pizzaChartWrapper}>
        <div className={styles.pieWrapper}>
          {
            // @ts-ignore
            pizzaBg.map((obj: any, index: number) => (
              <div style={{ transform: `rotate(${obj.outer}deg)` }} className={styles.pieOuter} key={index}>
                <div
                  style={{ transform: `rotate(${innerDeg}deg)`, backgroundColor: `${obj.color}` }}
                  className={styles.pie}
                />
              </div>
            ))
          }
        </div>
        <div className={styles.needle} style={{ transform: `rotate(${minDeg}deg)` }}>
          <div style={{ transform: `rotate(${needlePosition}deg)` }} className={styles.needleInner} />
        </div>
        <div className={styles.circle}>
          <div className={styles.currentValue}>{chart.total}</div>
          <div className={styles.ofValue}>out of {chart.max}</div>
        </div>
      </div>
      <div className={styles.desc}>{chart.label}</div>
    </div>
  );
};

export default PizzaChart1;
