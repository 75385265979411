import { Divider } from 'antd';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import OptionList from '../../pages/Groups/GroupDetails/components/GroupContent/components/Common/OptionList/OptionList';
import styles from './ClientOrGroupPickAssessment.module.scss';
import ClientOrGroupSelected from './components/ClientOrGroupSelected/ClientOrGroupSelected';
import { PatientAssessment } from 'pages/Assessments/components/SendAdhocAssessmentModal/interfaces';
import { Skeleton } from 'antd';
import { useFetchAssessmentList } from './getAssessmentList';
import { useGetAccessToken } from 'utils/hooks/token';

interface ClientOrGroupPickAssessmentProps {
  clientId?: string;
  key?: string;
  isShowCheckBox?: boolean;
  proxyProfiles?: PatientAssessment[];
  patientAssessment?: PatientAssessment;
  selectedGroup?: GroupsFromAPI;
  selectedAssessments: ClinicalAssessmentStruct[];
  participationType: ParticipantType;
  selectedProxyProfileId?: string;
  onAddSelectedAssessment: (item: ClinicalAssessmentStruct, clientId?: string) => void;
  onRemoveSelectedAssessment: (item: ClinicalAssessmentStruct, clientId?: string) => void;
  onSelectClientProfile?: (isSelected: boolean, clientId: string) => void;
  handleProxyProfileChange?: (value: string) => void;
}

const ClientOrGroupPickAssessment = ({
  clientId,
  isShowCheckBox,
  patientAssessment,
  proxyProfiles,
  selectedGroup,
  participationType,
  selectedAssessments,
  selectedProxyProfileId,
  onSelectClientProfile,
  onAddSelectedAssessment,
  onRemoveSelectedAssessment,
  handleProxyProfileChange
}: ClientOrGroupPickAssessmentProps) => {
  const { token } = useGetAccessToken();
  const { assessmentList, isAssessmentLoading } = useFetchAssessmentList(token);

  const onSelectAssessmentHandle = (isChecked: boolean, id: string) => {
    const selected = assessmentList?.filter((assessment) => assessment.id === id)[0];
    if (isChecked && assessmentList && selectedAssessments.length < 3) {
      onAddSelectedAssessment(selected, clientId);
    } else {
      onRemoveSelectedAssessment(selected, clientId);
    }
  };

  const listOption = assessmentList?.map((assessment) => ({
    id: assessment.id,
    title: assessment.title || '',
    description: assessment.description || ''
  }));

  const selectedOptions = selectedAssessments?.map((assessment) => ({
    id: assessment.id,
    title: assessment.title || '',
    description: assessment.description || ''
  }));

  return (
    <div className={styles.container}>
      <ClientOrGroupSelected
        selectedProxyProfileId={selectedProxyProfileId}
        onSelectClientProfile={onSelectClientProfile}
        isShowCheckBox={isShowCheckBox}
        proxyProfiles={proxyProfiles}
        patientAssessment={patientAssessment}
        participationType={participationType}
        group={selectedGroup}
        handleProxyProfileChange={handleProxyProfileChange}
      />
      {(participationType === ParticipantType.Group || patientAssessment?.isSelected) && (
        <>
          {isAssessmentLoading ? (
            <div className={styles.loadingContainer}>
              {[...Array(6)].map((obj, i) => (
                <div key={i} className={styles.loadingBox}>
                  <Skeleton.Input active className={styles.loadingName} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <Divider style={{ margin: 0 }} />
              <div className={styles.selectAssessmentWrapper}>
                <OptionList
                  clientId={clientId}
                  title={'Select Assessment'}
                  subTitle={'You can add a maximum of 3'}
                  listOption={listOption}
                  selectedOptions={selectedOptions || []}
                  onSelect={onSelectAssessmentHandle}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ClientOrGroupPickAssessment;
