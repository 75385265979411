import { ProfileInterface } from 'interfaces/Profile/Profile';
import { capitalize } from 'lodash';

const formatTerminology = (labels: Record<string, string>) => {
  const { client, clients, practitioner, practitioners, mentor } = labels;

  const terminology = {
    client,
    clients,
    practitioner,
    practitioners,
    mentor,
    'client.capitalize': capitalize(client),
    'clients.capitalize': capitalize(clients),
    'practitioner.capitalize': capitalize(practitioner),
    'practitioners.capitalize': capitalize(practitioners),
    'mentor.capitalize': capitalize(mentor)
  };

  return terminology;
};

export const getTerminology = (accountSettings?: ProfileInterface['accountSettings']) => {
  if (accountSettings && accountSettings.customLabels) {
    const { customLabels } = accountSettings;

    return formatTerminology(customLabels);
  }

  return formatTerminology({
    client: 'student',
    clients: 'students',
    practitioner: 'wellbeing team',
    practitioners: 'wellbeing teams',
    mentor: 'staff'
  });
};
