import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import Analytics from 'pages/Analytics/Analytics';
import MicroCheckInDetails from 'pages/AssessmentDetails/MicroCheckInDetails/MicroCheckInDetails';
import OnBoardingDetails from 'pages/AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import Assessments from 'pages/Assessments/Assessments';
import Calendar from 'pages/Calendar/components/Calendar/Calendar';
import ControlPanel from 'pages/ControlPanel/ControlPanel/ControlPanel';
import IntegrationDetails from 'pages/ControlPanel/IntegrationDetails/IntegrationDetails';
import Patient from 'pages/Patient/Patient';
import PatientDetails from 'pages/PatientDetails/PatientDetails';
import PractitionersListing from 'pages/PractitionersListing/PractitionersListing';
import ReportDetails from 'pages/Report/ReportDetails/ReportDetails';
import ReportsList from 'pages/Report/ReportsList/ReportsList';
import ReportTemplateEditor from 'pages/Report/ReportTemplateEditor/ReportTemplateEditor';
import { Route, Routes } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ActionPlanCreator from 'MHH/pages/ActionPlanCreator/ActionPlanCreator';
import ActionPlanEditor from 'MHH/pages/ActionPlanEditor/ActionPlanEditor';
import InterventionLibrary from 'MHH/pages/InterventionLibrary/InterventionLibrary';
import InterventionDetails from 'MHH/pages/Interventions/InterventionDetails/InterventionDetails';
import InterventionsList from 'MHH/pages/Interventions/InterventionsList/InterventionsList';
import AllAssessments from 'pages/AllAssessments/AllAssessments';
import CaseNoteTemplate from 'pages/AssessmentDetails/CaseNoteTemplate/CaseNoteTemplate';
import AssessorAssessments from 'pages/AssessorAssessments/AssessorAssessments';
import CalendarSettings from '../pages/Calendar/components/CalendarSettings/CalendarSettings';
import ConsentForm from '../pages/ConsentForm/ConsentForm';
import SessionDetails from '../pages/Groups/GroupDetails/components/GroupContent/components/Sessions/SessionDetails/SessionDetails';
import GroupDetails from '../pages/Groups/GroupDetails/GroupDetails';
import GroupsList from '../pages/Groups/GroupsList/GroupsList';
import HelmContent from '../pages/HelmContent/HelmContent';

const AppRoutes = () => {
  const { isEdgeAdminView, isEdgeUserView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();
  const {
    isGroupsFeatureToggle,
    isInterventionsFeatureToggle,
    helmContentFeatureToggle,
    viewCaseNotesTemplateFeature
  } = useGetFeatureToggle();

  return (
    <Routes>
      <Route path={`students/:recordId/action-plans/new`} element={<ActionPlanCreator />} />
      <Route path={`students/:recordId/action-plans/:actionPlanId/edit`} element={<ActionPlanEditor />} />
      <Route path={`students/:tabId`} element={<Patient />} />
      <Route path={`students`} element={<Patient />} />
      <Route path={`intervention-library`} element={<InterventionLibrary />} />
      <Route path={`students/:recordId/:patientTab`} element={<PatientDetails />} />
      <Route path={`students/:recordId/:patientTab/:assessmentOrReportOrPlanId`} element={<PatientDetails />} />
      {isGroupsFeatureToggle && <Route path={`groups/:tabId`} element={<GroupsList />} />}
      {isGroupsFeatureToggle && <Route path={`groups`} element={<GroupsList />} />}
      {isGroupsFeatureToggle && <Route path={`groups/:groupId/:groupTab`} element={<GroupDetails />} />}
      {isGroupsFeatureToggle && <Route path={`groups/:groupId/:groupTab/:contentId`} element={<GroupDetails />} />}
      {isGroupsFeatureToggle && (
        <Route
          path={`groups/:groupId/:groupTab/:contentId/:clinicalAssessmentId/:childContentId`}
          element={<GroupDetails />}
        />
      )}
      {isGroupsFeatureToggle && (
        <Route path={`groups/:groupId/sessionDetail/:sessionId`} element={<SessionDetails />} />
      )}
      {(isNormalUserView || isEdgeUserView || isEdgeAdminView) && (
        <>
          <Route path={`assessments/:tabId`} element={isEdgeAdminView ? <AllAssessments /> : <AssessorAssessments />} />
          <Route path={`assessments`} element={isEdgeAdminView ? <AllAssessments /> : <AssessorAssessments />} />
        </>
      )}
      {isInterventionsFeatureToggle && (
        <Route path={`interventions/:interventionId/:interventionTab/:contentId`} element={<InterventionDetails />} />
      )}
      {isInterventionsFeatureToggle && (
        <Route path={`interventions/:interventionId/:interventionTab`} element={<InterventionDetails />} />
      )}
      {isInterventionsFeatureToggle && (
        <Route
          path={`interventions/:interventionId/:groupTab/:contentId/:clinicalAssessmentId/:childContentId`}
          element={<InterventionDetails />}
        />
      )}
      {isInterventionsFeatureToggle && <Route path={`interventions/:tabId`} element={<InterventionsList />} />}
      {isInterventionsFeatureToggle && <Route path={`interventions`} element={<InterventionsList />} />}

      {!isEdgeReceptionist && <Route path={`forms`} element={<Assessments />} />}
      <Route path={`forms/survey/:assessmentId`} element={<OnBoardingDetails />} />
      {(isNormalUserView || isEdgeUserView) && (
        <Route path={`forms/microCheckIn/:assessmentId`} element={<MicroCheckInDetails />} />
      )}
      {(isNormalUserView || isEdgeAdminView) && <Route path={`forms/consent/:consentId`} element={<ConsentForm />} />}
      {viewCaseNotesTemplateFeature && (
        <Route path={`forms/caseNotes/:caseNoteTemplateId`} element={<CaseNoteTemplate />} />
      )}
      <Route path={`calendar`} element={<Calendar />} />
      <Route path={`calendar/settings/:tab`} element={<CalendarSettings />} />
      <Route path={`control-panel/:controlPanelTab`} element={<ControlPanel />} />
      <Route path={`control-panel/system-connections/:integrationId`} element={<IntegrationDetails />} />
      <Route path={`reports`} element={<ReportsList />} />
      <Route path={`reports/templates/:templateId`} element={<ReportTemplateEditor />} />
      <Route path={`reports/:reportId`} element={<ReportDetails />} />
      {isEdgeAdminView && <Route path={`practitioners`} element={<PractitionersListing />} />}
      <Route path={`analytics`} element={<Analytics />} />
      {helmContentFeatureToggle && <Route path={`helm-content`} element={<HelmContent />} />}
      <Route path={'*'} element={<RedirectTo404 />} />
    </Routes>
  );
};

export default AppRoutes;
