import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

export enum AvailabilitiesViewType {
  Simple = 'simple',
  Detailed = 'detailed'
}

interface Props {
  onChange: (currentViewType: AvailabilitiesViewType) => void;
  currentViewType: AvailabilitiesViewType;
}

const AvailabilitiesViewTypeToggle = ({ onChange, currentViewType }: Props) => (
  <ToggleSwitch
    id="availabitliesDetailToggle"
    checkLabel="SIMPLE"
    unCheckLabel="DETAILED"
    onChangeProps={(event) => {
      if (event.target.checked) {
        onChange(AvailabilitiesViewType.Simple);
      } else {
        onChange(AvailabilitiesViewType.Detailed);
      }
    }}
    isCheckedProps={currentViewType === AvailabilitiesViewType.Simple}
  />
);

export default AvailabilitiesViewTypeToggle;
