import { useEffect, useMemo, useState } from 'react';

import styles from './ProspectFieldForm.module.scss';
import { newProspectClientForm, prospectValidateField } from '../../AddProspectClientInterface';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import classnames from 'classnames';
import MaterialPhoneInput from '../../../MaterialPhoneInput/MaterialPhoneInput';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateCaseId } from 'components/AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import SearchableSelect, { OptionInterface } from 'components/Select/SearchableSelect/SearchableSelect';
import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { useTranslation } from 'react-i18next';

interface ProspectFieldFormProps {
  prospectClientField: newProspectClientForm;
  checkValidation: boolean;
  onChangeClientField: any;
  errorMessage: prospectValidateField;
}

const ProspectFieldForm = ({
  prospectClientField,
  checkValidation,
  onChangeClientField,
  errorMessage
}: ProspectFieldFormProps) => {
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);
  const { token } = useGetAccessToken();
  const [selectedReferralSourceTags, setSelectedReferralSourceTags] = useState<OptionInterface[]>([]);

  useEffect(() => {
    if (prospectClientField && prospectClientField.sourceTags) {
      setSelectedReferralSourceTags(prospectClientField.sourceTags?.map((item) => ({ _id: item, label: item })));
    }
  }, [prospectClientField]);

  useEffect(() => {
    if (checkValidation && prospectClientField.caseId && prospectClientField.caseId.length > 0) {
      validateDuplicateCaseId(prospectClientField.caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value, prospectClientField.clientRecordId);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token, prospectClientField.clientRecordId]
  );

  const validateDuplicateCaseId = async (caseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(caseIdValue);
    }
  };

  const handleChangeFieldValue = async (key: string, value: string | string[] | boolean) => {
    const newProspectField = {
      ...prospectClientField,
      [key]: value
    };
    onChangeClientField(newProspectField);
    if (key === 'caseId' && checkValidation && value) {
      await validateDuplicateCaseId(value.toString());
    } else {
      setDuplicateCaseId(false);
    }
  };

  const onSelectReferralSourceTags = (selectedOption: OptionInterface) => {
    let newReferralSourceTags = [];
    if (selectedReferralSourceTags.some((item) => item._id === selectedOption._id)) {
      newReferralSourceTags = selectedReferralSourceTags.filter((item) => item._id !== selectedOption._id);
    } else {
      newReferralSourceTags = [...selectedReferralSourceTags, selectedOption];
    }
    setSelectedReferralSourceTags(newReferralSourceTags);
    handleChangeFieldValue(
      'sourceTags',
      newReferralSourceTags.map((item) => item._id)
    );
  };

  const [t] = useTranslation();

  return (
    <>
      <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
        <MaterialInput
          id={`clientCaseId-${prospectClientField.clientRecordId}`}
          label={t('form.client_id')}
          isLoading={isCheckingCaseIdDuplicate}
          onChange={(e) => handleChangeFieldValue('caseId', e.target.value)}
          value={prospectClientField.caseId}
          maxLength={20}
        />
        {checkValidation && duplicateCaseId && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_id')}</div>
        )}
      </div>
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`contactFirstname`}
          label={'Contact First Name*'}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={prospectClientField.firstName}
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}>
        <MaterialInput
          id={`contactLastname`}
          label={'Contact Last Name*'}
          onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
          value={prospectClientField.lastName}
        />
        {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.email && styles.fieldError)}>
        <MaterialInput
          id={`email`}
          label={'Email Address*'}
          onChange={(e) => handleChangeFieldValue('email', e.target.value)}
          value={prospectClientField.email}
        />
        {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
      </div>
      <div className={styles.phoneContainer}>
        <MaterialPhoneInput
          id={`mobileNumber`}
          label={'Mobile Number*'}
          onChange={(e: string) => handleChangeFieldValue('mobileNumber', e)}
          value={prospectClientField.mobileNumber}
          isError={checkValidation && !!errorMessage.mobileNumber}
        />
        {checkValidation && errorMessage.mobileNumber && (
          <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
        )}
      </div>
      {/* Selected Referral Source */}
      {selectedReferralSourceTags.length > 0 && (
        <div className={styles.selectedReferralTypessContainer}>
          {selectedReferralSourceTags.map((referralType, index) => (
            <div key={`referralSource${index}`} className={styles.pill}>
              {referralType.label}
            </div>
          ))}
        </div>
      )}
      {/* Referral Source dropdown */}
      <div className={styles.dropdownContainer}>
        <SearchableSelect
          className={styles.rsDropdown}
          label={'Select Referral Source'}
          options={REFERRAL_SOURCE_TAGS.map((item) => ({ _id: item, label: item }))}
          searchBoxPlaceholder={'Select Source'}
          isMultipleChoice
          selectedOptionsProps={selectedReferralSourceTags}
          onSelecteOptionProps={onSelectReferralSourceTags}
          loadingEnable
        />
      </div>
      <div className={styles.fieldContainer}>
        <MaterialInput
          id={`referralDetail`}
          label={'Referral Detail'}
          value={prospectClientField.detail}
          onChange={(e) => handleChangeFieldValue('detail', e.target.value)}
          required
        />
      </div>
    </>
  );
};

export default ProspectFieldForm;
