import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { HTMLProps } from 'react';
import styles from './Input.module.scss';

interface InputProps {
  error?: string;
  label?: string;
  help?: string;
  prefix?: string;
  inputProps?: HTMLProps<HTMLInputElement>;
  className?: string;
  inputClassName?: string;
}

const Input = ({ error, label, help, prefix, inputProps, className, inputClassName }: InputProps) => {
  return (
    <div className={className}>
      <div className={styles.labelContainer}>
        {label && <div className={styles.label}>{label}</div>}
        {help && <HelpOutLineWithTooltips id={`${inputProps?.id}-help`} desc={help} iconClass={styles.helpIcon} />}
      </div>
      <div
        className={classNames(
          styles.inputContainer,
          {
            [styles.error]: error
          },
          inputClassName
        )}
      >
        {prefix && <div className={styles.prefixContainer}>{prefix}</div>}
        <input className={styles.inputField} {...inputProps} />
      </div>
      {error && <ErrorMessage error={error} visible />}
    </div>
  );
};

export default Input;
