import { useState } from 'react';
import styles from './GroupCaseNoteInsert.module.scss';
import { notification } from 'antd';
import HeadingTemplateBuilder from 'components/HeadingTemplate/HeadingTemplateBuilder/HeadingTemplateBuilder';
import GroupCaseNoteHeadingTemplateList, {
  HeadingTemplate
} from '../GroupCaseNoteHeadingTemplateList/GroupCaseNoteHeadingTemplateList';
import {
  deleteGroupCaseNoteHeadingTemplate,
  postGroupCaseNoteHeadingTemplate
} from 'utils/http/DocumentService/CaseNotes/groupCaseNotes';

interface GroupCaseNoteInsertProps {
  groupId: string;
  caseNoteBodyVal: string;
  onChangeBody: any;
  caseNoteHeadingTemplates: HeadingTemplate[];
  onDeleteTemplate: (headingTemplateId: string) => void;
  onAddTemplate: (newHeadingTemplate: HeadingTemplate) => void;
  token: string;
}

const GroupCaseNoteInsert = ({
  groupId,
  caseNoteBodyVal,
  onChangeBody,
  caseNoteHeadingTemplates,
  token,
  onDeleteTemplate,
  onAddTemplate
}: GroupCaseNoteInsertProps) => {
  const [showHeadingTemplate, setShowHeadingTemplate] = useState(false);

  const onSelectTemplate = (selectedHeadingTemplate: HeadingTemplate) => {
    onChangeBody(`${caseNoteBodyVal}${selectedHeadingTemplate.body}`);
  };

  const addTemplate = async (templateName: string, templateBody: string) => {
    try {
      const payload = {
        title: templateName,
        body: templateBody
      };
      const callPostGroupHeadingTemplate = await postGroupCaseNoteHeadingTemplate(token, payload);
      const createdHeadingTemplate = await callPostGroupHeadingTemplate.json();
      onAddTemplate(createdHeadingTemplate);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to create this template' });
    }
  };

  const deleteTemplate = async (templateId: string) => {
    try {
      await deleteGroupCaseNoteHeadingTemplate(templateId, token);
      onDeleteTemplate(templateId);
      notification.success({
        message: 'Template deleted!',
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to delete this template' });
    }
  };

  return (
    <div className={styles.headingTemplateContainer}>
      <GroupCaseNoteHeadingTemplateList
        token={token}
        groupId={groupId}
        templateData={caseNoteHeadingTemplates}
        onSelectTemplate={onSelectTemplate}
        onRemoveTemplateItem={deleteTemplate}
        onShowHeadingTemplate={() => setShowHeadingTemplate(true)}
      />
      <HeadingTemplateBuilder
        onCloseModal={() => setShowHeadingTemplate(false)}
        onSubmitTemplate={addTemplate}
        visible={showHeadingTemplate}
      />
    </div>
  );
};

export default GroupCaseNoteInsert;
