import { useCallback, useEffect, useState } from 'react';

import { ConsentFormResponseList, GroupDetailsId, GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import styles from './GroupSideMenu.module.scss';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { groupSideMenu, GroupSideMenuListProps } from './constants';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTranslation } from 'react-i18next';

export interface GroupSideMenuProps {
  groupId: GroupDetailsId['groupId'];
  activeGroupTab: GroupDetailsId['groupTab'];
  contentId: string;
  childContentId: string;
  allowEditAssigned: boolean;
  psychometricList: GroupPsychometricMenu[];
  psychometricLoading: boolean;
  surveyFormMenuList: SurveyFormResponseList[];
  surveyResponseListLoading: boolean;
  consentFormMenuList: ConsentFormResponseList[];
}

const GroupSideMenu = ({
  groupId,
  activeGroupTab,
  contentId,
  childContentId,
  allowEditAssigned,
  psychometricList,
  psychometricLoading,
  surveyFormMenuList,
  surveyResponseListLoading,
  consentFormMenuList
}: GroupSideMenuProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();
  const [t] = useTranslation();

  const { isEdgeReceptionist } = useGetAccountPackageView();

  const mapActiveBtn = useCallback(
    (menu: GroupSideMenuListProps[]) => {
      const massageMenu = menu.filter((obj) => !!obj);
      return massageMenu.map((obj) => {
        const newSideMenu = { ...obj };
        if (activeGroupTab === obj.id) {
          newSideMenu.isActive = true;
        }
        return newSideMenu;
      });
    },
    [activeGroupTab]
  );

  const sideMenu: GroupSideMenuListProps[] = groupSideMenu({
    groupId,
    contentId,
    childContentId,
    allowEditAssigned,
    psychometricList,
    psychometricLoading,
    surveyFormMenuList,
    surveyResponseListLoading,
    isEdgeReceptionist,
    consentFormMenuList,
    t
  });

  const newMenu = mapActiveBtn(sideMenu);
  const [sideMenuWithActive, setSideMenuWithActive] = useState<GroupSideMenuListProps[]>(newMenu);

  useEffect(() => {
    setSideMenuWithActive(newMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapActiveBtn, contentId, childContentId, psychometricLoading, surveyResponseListLoading]);

  const onClickSideMenu = (id: string) => {
    if (activeGroupTab !== id) {
      navigate(`${GROUPS.BASE}/${groupId}/${id}`);
    }
  };

  return (
    <div className={styles.container}>
      {sideMenuWithActive.map((obj, index) => (
        <div
          className={obj.isActive ? styles.menuCardActive : styles.menuCard}
          key={index}
          onClick={() => onClickSideMenu(obj.id)}
        >
          <i className={`${obj.isActive ? 'material-icons' : 'material-icons-outlined'} ${styles.menuIcon}`}>
            {obj.icon}
          </i>
          <div className={styles.labelWrapper}>
            <div className={styles.label}>{obj.label}</div>
            {obj.isActive && <div className={styles.desc}>{obj.desc}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupSideMenu;
