import { useEffect, useRef, useState } from 'react';

import styles from './QuestionWidget.module.scss';
import NotesFormatSettingsFieldsEditor from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteFormatSettingsModal/components/PatientDetailsNoteFormatSettingsFields/components/PatientDetailsNoteFormatSettingsFieldsEditor/NotesFormatSettingsFieldsEditor';
import CaseNoteFieldType from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/CaseNoteFieldType/CaseNoteFieldType';
import { ReportTemplateCaseNoteField } from 'interfaces/Reports/reportTemplate';
import classnames from 'classnames';

interface QuestionWidgetProps {
  data: { questionDetails: ReportTemplateCaseNoteField };
  id?: any;
  onChangeQuestionSettings?: (id: string, val: ReportTemplateCaseNoteField, editModeVal: boolean) => void;
  viewMode?: 'preview' | 'template' | 'display' | 'edit';
  editMode?: boolean;
  showMoveCursor?: boolean;
  onChangeQuestionValue?: (id: string, val: ReportTemplateCaseNoteField) => void;
}

const QuestionWidget = ({
  data,
  id,
  onChangeQuestionSettings,
  viewMode,
  editMode,
  showMoveCursor,
  onChangeQuestionValue
}: QuestionWidgetProps) => {
  const questionRef = useRef<HTMLDivElement>(null);
  const [isShowInput, setShowInput] = useState(false);
  const [previewInputVal, setPreviewInputVal] = useState({
    id: id,
    value: ''
  });

  useEffect(() => {
    setPreviewInputVal({
      id: id,
      value: ''
    });
  }, [data.questionDetails.type, id]);

  const handleClick = (e: any) => {
    const preventClose = document.querySelector('#templateFormatField');
    if (questionRef.current?.contains(e.target) || preventClose) {
      return;
    }
    setShowInput(false);
    if (onChangeQuestionSettings && editMode) {
      onChangeQuestionSettings(id, data.questionDetails, false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onClickEditText = () => {
    setShowInput(true);
    onChangeQuestionSettings && onChangeQuestionSettings(id, data.questionDetails, true);
  };

  const handleChangeQuestionSettings = (val: any) => {
    onChangeQuestionSettings && onChangeQuestionSettings(id, val, true);
  };

  const handleOnChangeFieldInput = (fieldId: string, val: string) => {
    const massageToCaseNoteField: ReportTemplateCaseNoteField = {
      ...data.questionDetails,
      _id: fieldId,
      value: val,
      options: data.questionDetails.options
    };
    onChangeQuestionValue && onChangeQuestionValue(id, massageToCaseNoteField);
  };

  if (viewMode === 'preview') {
    return (
      <div className={styles.previewContainer}>
        <CaseNoteFieldType
          caseNoteFieldData={{
            ...data.questionDetails,
            _id: previewInputVal.id,
            value: previewInputVal.value,
            options: data.questionDetails.options
          }}
          handleOnChangeFieldInput={(fieldId, val) =>
            setPreviewInputVal({
              id: previewInputVal.id,
              value: val
            })
          }
          menuPortalTarget={'.ant-modal-wrap'}
          fullWidth
          withPlaceholder
        />
      </div>
    );
  }

  if (viewMode === 'template') {
    return (
      <div className={classnames(styles.previewContainer, showMoveCursor && styles.showMoveCursor)}>
        <CaseNoteFieldType
          caseNoteFieldData={{
            ...data.questionDetails,
            _id: data.questionDetails._id,
            value: data.questionDetails.value,
            options: data.questionDetails.options
          }}
          handleOnChangeFieldInput={(fieldId, val) => handleOnChangeFieldInput(fieldId, val)}
          menuPortalTarget={'body'}
          fullWidth
          withPlaceholder
        />
      </div>
    );
  }

  if (viewMode === 'display') {
    return (
      <div className={styles.previewContainer}>
        <div className={styles.label}>{data.questionDetails.name}</div>
        {data.questionDetails.type === 'multipleChoice' ? (
          <>
            {data.questionDetails.multiple ? (
              <div className={styles.value}>
                {data.questionDetails.value ? data.questionDetails.value.map((val: any) => val.label).join(', ') : '-'}
              </div>
            ) : (
              <div className={styles.value}>{data.questionDetails.value ? data.questionDetails.value.label : '-'}</div>
            )}
          </>
        ) : (
          <div className={styles.value}>{data.questionDetails.value ? data.questionDetails.value : '-'}</div>
        )}
      </div>
    );
  }

  return !isShowInput ? (
    <div className={styles.container} onClick={onClickEditText}>
      <CaseNoteFieldType
        caseNoteFieldData={{
          ...data.questionDetails,
          _id: previewInputVal.id,
          value: previewInputVal.value,
          options: data.questionDetails.options
        }}
        handleOnChangeFieldInput={() => {}}
        isDisabled
        fullWidth
        withPlaceholder
      />
    </div>
  ) : (
    <div className={styles.containerWrapper} ref={questionRef}>
      <NotesFormatSettingsFieldsEditor
        selectedField={data.questionDetails}
        onChangeField={handleChangeQuestionSettings}
      />
    </div>
  );
};

export default QuestionWidget;
