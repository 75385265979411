import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  SegmentedGroup,
  SegmentedItem
} from '@mobiscroll/react';
import styles from './CalendarHeader.module.scss';
import './MobiscrollCustom.scss';

type CalendarHeaderProps = {
  view: string;
  onViewChange: Function;
};

const CalendarHeader = ({ view, onViewChange }: CalendarHeaderProps) => {
  const onChange = (event: any) => {
    onViewChange(event.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.nav} id={'calendarNavId'}>
        <CalendarNav className="cal-header-nav" />
        <div className="cal-header-picker">
          <SegmentedGroup value={view} onChange={onChange}>
            <SegmentedItem value="week">Week</SegmentedItem>
            <SegmentedItem value="day">Day</SegmentedItem>
          </SegmentedGroup>
        </div>
        <CalendarPrev className="cal-header-prev" />
        <CalendarToday className="cal-header-today" />
        <CalendarNext className="cal-header-next" />
      </div>
    </div>
  );
};

export default CalendarHeader;
