import { useEffect, useState } from 'react';

import styles from './QuickNoteForm.module.scss';
import CheckBox from '../../../CheckBox/CheckBox';
import TextEditor from '../../../v2/TextEditor/TextEditor';
import OptionLabel from '../../../OptionLabel/OptionLabel';
import { newClientForm } from '../../AddPatientModalV2Interface';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';

interface QuickNoteFormProps {
  clientQuickNote: newClientForm['quickNote'];
  checkValidation: boolean;
  onChangeQuiteNote: any;
}

const QuickNoteForm = ({ clientQuickNote, checkValidation, onChangeQuiteNote }: QuickNoteFormProps) => {
  const [quickNote, setQuickNote] = useState(clientQuickNote);
  const [isShowError, setShowError] = useState(checkValidation);
  const [t] = useTranslation();

  useEffect(() => {
    if (checkValidation) {
      setShowError(quickNote.note.replace(/<(?!img)(.|\n)*?>/g, '').trim().length < 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const onChangeField = (val: string) => {
    const newQuickNoteData = {
      ...quickNote,
      note: val
    };
    setQuickNote(newQuickNoteData);
    onChangeQuiteNote(newQuickNoteData);
    setShowError(val.replace(/<(?!img)(.|\n)*?>/g, '').trim().length < 1);
  };

  const onChangeIncludeNote = () => {
    const newQuickNoteData = {
      ...quickNote,
      includeQuickNote: !quickNote.includeQuickNote
    };
    setQuickNote(newQuickNoteData);
    onChangeQuiteNote(newQuickNoteData);
  };

  const includePrivateNote = quickNote.includeQuickNote;
  return (
    <div className={includePrivateNote ? styles.highlightContainer : styles.container}>
      <div className={styles.checkBoxContainer}>
        <CheckBox
          id={'clientPrivateNoteCheck'}
          value={includePrivateNote}
          label={
            <OptionLabel
              title={t('client.creation.private_note')}
              desc={t('client.creation.private_note.description')}
            />
          }
          onChange={() => onChangeIncludeNote()}
        />
      </div>
      <div className={includePrivateNote ? styles.noteContainer : styles.noteContainerHidden}>
        <TextEditor id={'clientPrivateNote'} value={quickNote.note} onChange={(e) => onChangeField(e)} />
        <ErrorMessage
          className={styles.error}
          error={'Please have at least one character to create note'}
          visible={isShowError}
        />
      </div>
    </div>
  );
};

export default QuickNoteForm;
