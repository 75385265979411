import { v4 as uuid } from 'uuid';

import { newClientForm } from '../AddPatientModalV2Interface';

const defaultAdultDetails = {
  clinicianAuth0Ids: [''],
  recordType: 'adult',
  referralSourceTags: [],
  referralDetail: '',
  caseId: '',
  clientProfiles: [
    {
      _id: uuid(),
      firstName: '',
      lastName: '',
      email: '',
      isEmailThirdParty: false,
      mobileNumber: '',
      isMobileNumberThirdParty: false,
      onboarding: {
        isCheckOnBoarding: true,
        onboardingSurvey: '',
        onboardingMethod: 'sendToClient'
      },
      consentForm: {
        isCheckOnConsentForm: true,
        consentFormId: ''
      },
      isPrimaryContact: true,
      communicationPreference: 'noPreference'
    }
  ],
  clientCapability: {
    profileType: 'full'
  },
  signupInvitationSettings: {
    sendAt: 'now',
    customSendTime: ''
  },
  quickNote: {
    note: '',
    includeQuickNote: false
  }
} as newClientForm;

const defaultChildDetails = {
  clinicianAuth0Ids: [''],
  recordType: 'child',
  referralSourceTags: [],
  referralDetail: '',
  caseId: '',
  class: '',
  clientProfiles: [
    {
      _id: uuid(),
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      onboarding: {
        onboardingSurvey: '',
        isCheckOnBoarding: true,
        onboardingMethod: 'facilitated'
      },
      consentForm: {
        isCheckOnConsentForm: true,
        consentFormId: ''
      },
      role: 'child'
    },
    {
      _id: uuid(),
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      onboarding: {
        onboardingSurvey: '',
        isCheckOnBoarding: true,
        onboardingMethod: 'facilitated'
      },
      consentForm: {
        isCheckOnConsentForm: true,
        consentFormId: ''
      },
      role: '',
      isPrimaryContact: true,
      communicationPreference: 'noPreference'
    }
  ],
  clientCapability: {
    profileType: 'recordOnly'
  },
  agreementField: {
    agreementType: 'Standard',
    agreement: false
  },
  signupInvitationSettings: {
    sendAt: 'now',
    customSendTime: ''
  },
  quickNote: {
    note: '',
    includeQuickNote: false
  }
} as newClientForm;

const defaultCoupleDetails = {
  clinicianAuth0Ids: [''],
  recordType: 'couple',
  referralSourceTags: [],
  referralDetail: '',
  caseId: '',
  clientProfiles: [
    {
      _id: uuid(),
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      onboarding: {
        isCheckOnBoarding: true,
        onboardingSurvey: '',
        onboardingMethod: 'sendToClient'
      },
      consentForm: {
        isCheckOnConsentForm: true,
        consentFormId: ''
      },
      isPrimaryContact: true,
      communicationPreference: 'noPreference'
    },
    {
      _id: uuid(),
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      onboarding: {
        isCheckOnBoarding: true,
        onboardingSurvey: '',
        onboardingMethod: 'sendToClient'
      },
      consentForm: {
        isCheckOnConsentForm: true,
        consentFormId: ''
      },
      isPrimaryContact: false,
      communicationPreference: 'noPreference'
    }
  ],
  clientCapability: {
    profileType: 'full'
  },
  agreementField: {
    agreementType: 'Standard',
    agreement: false
  },
  signupInvitationSettings: {
    sendAt: 'now',
    customSendTime: ''
  },
  quickNote: {
    note: '',
    includeQuickNote: false
  }
} as newClientForm;

const initValueGenerator = (defaultDetails: newClientForm, selectedClinicianId?: string): newClientForm => ({
  ...defaultDetails,
  clinicianAuth0Ids: selectedClinicianId ? [selectedClinicianId] : []
});

export const useGetInitValueByType = (selectedClinicianId?: string) => {
  const AdultInit = initValueGenerator(defaultAdultDetails, selectedClinicianId);
  const ChildInit = initValueGenerator(defaultChildDetails, selectedClinicianId);
  const CoupleInit = initValueGenerator(defaultCoupleDetails, selectedClinicianId);

  return {
    AdultInit,
    ChildInit,
    CoupleInit
  };
};
