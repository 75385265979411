import bent from 'bent';
import { FirstAssessmentResponse } from 'interfaces/checkInService/firstAssessment';
import { AssessmentType, FirstAssessmentStruct } from 'pages/Assessments/Interface';

const getData = (authKey: string) => {
  const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;
  const getFirstAssessmentsRequest = bent<{ firstAssessments: FirstAssessmentResponse[] }>(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return getFirstAssessmentsRequest('/first-assessments');
};

const massageAssessment = (firstAssessments: FirstAssessmentResponse[]) => {
  return firstAssessments.map((surveyObj) => ({
    id: surveyObj.id,
    name: surveyObj.name,
    isPublished: surveyObj.isPublished,
    isDefault: surveyObj.isDefault,
    clinicalAssessmentIds: surveyObj.clinicalAssessmentIds,
    tags: surveyObj.tags,
    assessmentType: AssessmentType.Survey as const,
    createdAt: surveyObj.createdAt,
    updatedAt: surveyObj.updatedAt
  }));
};

export const getFirstAssessments = async (
  token: string
): Promise<Omit<FirstAssessmentStruct, 'questionSets' | 'description'>[]> => {
  const firstAssessments = (await getData(token)).firstAssessments;
  return massageAssessment(firstAssessments);
};
