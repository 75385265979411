import { faPhone, faUserFriends, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/react';
import styles from './CalendarEvent.module.scss';
import classnames from 'classnames';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { DeliveryType, DELIVERY_TYPE_LABELS } from 'interfaces/Schedule/AppointmentType';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

type Profile = {
  _id: string;
  clientAuth0Id?: string;
  avatar?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  name: string;
};

export type CustomCalendarEvent = MbscCalendarEvent & {
  clientRecord?: clientRecordsInterface;
  profile: Profile;
  deliveryType?: string;
  group: string;
  groupId?: string;
  room?: string;
  profileName?: string;
  type?: string;
  gap: number;
};

type CalendarEventProps = {
  data: MbscCalendarEventData & {
    original: CustomCalendarEvent;
  };
};

const getDeliveryTypeProps = (deliveryType?: string) => {
  switch (deliveryType) {
    case DeliveryType.BeSpoke:
      return {
        text: DELIVERY_TYPE_LABELS[DeliveryType.BeSpoke],
        icon: <FontAwesomeIcon icon={faUserFriends} />
      };
    case DeliveryType.FaceToFace:
      return {
        text: DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace],
        icon: <FontAwesomeIcon icon={faUserFriends} />
      };
    case DeliveryType.PhoneCall:
      return {
        text: DELIVERY_TYPE_LABELS[DeliveryType.PhoneCall],
        icon: <FontAwesomeIcon icon={faPhone} />
      };
    case DeliveryType.VideoCall:
      return {
        text: DELIVERY_TYPE_LABELS[DeliveryType.VideoCall],
        icon: <FontAwesomeIcon icon={faVideo} />
      };
    default:
      return { text: '', icon: null };
  }
};

const CalendarEvent = ({ data }: CalendarEventProps) => {
  const { clientRecord, deliveryType, group, groupId, groupTitle, room, type, profileName } = data.original;
  const deliveryTypeProps = getDeliveryTypeProps(deliveryType);

  const SelectClientProfiles =
    clientRecord?.recordType === 'child'
      ? clientRecord?.clientProfiles.filter((profileType) => profileType.role === 'child')
      : clientRecord?.clientProfiles;

  const renderOneToOneEventCard = () => (
    <div className={styles.details}>
      {clientRecord?.clientProfiles && (
        <ClientAvatar
          clientData={SelectClientProfiles}
          avatarSize={30}
          displayFirstNameOnly={clientRecord?.recordType === 'couple'}
          displayLimit={clientRecord?.recordType === 'couple' ? 2 : 1}
          horizontal
          initialsClassName={styles.initialName}
          nameClassName={styles.clientName}
          overlayCircle
        />
      )}
      <div>
        {data.title} {data.title && room && '|'}
        {room} {room && deliveryTypeProps && '|'} {deliveryTypeProps.icon} {deliveryTypeProps.text}
      </div>
      {type && ['free', 'busy'].includes(type) ? (
        <div> {profileName} </div>
      ) : (
        <div>
          {data.start} - {data.end}
        </div>
      )}
    </div>
  );

  const renderGroupEventCard = () => (
    <div className={styles.groupCard}>
      <div className={styles.groupCardHeading}>
        <i className={`material-icons ${styles.groupIcon}`}>groups</i>
        <div className={styles.groupName}>{groupTitle}</div>
      </div>
      <div>
        {room} {room && deliveryTypeProps && '|'} {deliveryTypeProps.icon} {deliveryTypeProps.text}
      </div>
      <div>
        {data.start} - {data.end}
      </div>
    </div>
  );

  return (
    <div
      className={classnames(
        styles.container,
        Number(group) >= 0 ? styles[`group${group}`] : undefined,
        type && ['free', 'busy', 'reserved'].includes(type) && styles[type]
      )}
    >
      <div className={styles.body}>{groupId ? renderGroupEventCard() : renderOneToOneEventCard()}</div>
    </div>
  );
};

export default CalendarEvent;
