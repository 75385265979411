import parse from 'html-react-parser';
import moment from 'moment';

import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { convertStringToTitleCase } from 'utils/general';

import { ProfileInterface } from 'interfaces/Profile/Profile';
import { ActivityActor, PatientActivity } from '../../../../ActivityFeed';

import styles from './ActivityItem.module.scss';

const getIcon = (object: PatientActivity['object']) => {
  switch (object) {
    case 'note':
      return 'speaker_notes';

    case 'case note':
      return 'speaker_notes';

    case 'appointment':
    case 'appointment change request':
      return 'event';

    case 'assessment':
    case 'check-in':
    case 'survey':
    case 'onboarding':
      return 'fact_check';

    case 'student':
    case 'profile':
    case 'user':
      return 'account_circle';

    case 'invoice':
      return 'receipt';

    case 'report':
      return 'assignment';

    case 'student profile':
    case 'client profile':
    case 'mentor':
    case 'assessor':
    case 'wellbeing team':
    case 'staff':
      return 'person';

    case 'communication preference':
      return 'settings';

    case 'group':
    case 'intervention':
      return 'groups';

    case 'action plan':
      return 'next_plan';

    default:
      console.warn('Failed to get icon for patient activity');
      return '';
  }
};

const getTitle = (object: PatientActivity['object'], patientName?: string) => {
  if (object === 'note') {
    return 'Quick Note';
  } else if (object === 'communication preference' && patientName) {
    return `${convertStringToTitleCase(object)} of ${patientName}`;
  } else {
    return convertStringToTitleCase(object);
  }
};

interface ActivityItemProps {
  item: PatientActivity;
  patientName?: string;
  profile: ProfileInterface;
}

const ActivityItem = ({ item, patientName, profile }: ActivityItemProps) => {
  const icon = getIcon(item.object);
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const practiceName = profile.practice?.name;

  return (
    <>
      <div>{icon && <span className={`${styles.activityIcon} material-icons-outlined`}>{icon}</span>}</div>
      <div>
        <div className={styles.activityTitle}>
          <span>{getTitle(item.object, patientName)} </span>
          <b>{item.action.toUpperCase()}</b>
          {` by ${
            item.actor === ActivityActor.WellbeingTeam
              ? isEdgeAdminView
                ? item.clinicianId
                  ? item.clinicianName
                  : practiceName
                : isNormalUserView
                ? 'You'
                : item.clinicianId === auth0ClinicianId
                ? 'You'
                : item.clinicianId
                ? item.clinicianName
                : practiceName
              : item.actor === ActivityActor.Student
              ? patientName
              : 'CORDS'
          }`}
        </div>
        <div className={styles.date}>
          {moment(item.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY' })} - {parse(item.description)}
        </div>
        {item.body && (
          <div className={`ql-snow`}>
            <div className={`ql-editor ${styles.activityContent}`} dangerouslySetInnerHTML={{ __html: item.body }} />
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityItem;
