import { ProfileInterface } from 'interfaces/Profile/Profile';
import { TimeSlot } from 'interfaces/Schedule/Room';
import moment from 'moment';
import * as Yup from 'yup';

interface AvailableTimeWS {
  isAvailable: boolean;
  timeSlots?: TimeSlot[];
}

export interface ProfileInterfaceWS extends ProfileInterface {
  availability: {
    monday: AvailableTimeWS;
    tuesday: AvailableTimeWS;
    wednesday: AvailableTimeWS;
    thursday: AvailableTimeWS;
    friday: AvailableTimeWS;
    saturday: AvailableTimeWS;
    sunday: AvailableTimeWS;
  };
}

const timeFormat = 'HH:mm A';

const timeSlot = Yup.object({
  startTime: Yup.string().required(),
  endTime: Yup.string().required()
}).test('validTimeSequence', 'End time must later then start time', (value) => {
  return moment(value?.startTime, timeFormat).diff(moment(value?.endTime, timeFormat)) < 0;
});

const availableTimeSchema = Yup.object({
  isAvailable: Yup.boolean(),
  timeSlots: Yup.array(timeSlot)
    .when('isAvailable', {
      is: true,
      then: Yup.array(timeSlot)
        .min(1, 'Please select at least one timeslot')
        .required('Please select at least one timeslot'),
      otherwise: Yup.array(timeSlot)
    })
    .test('noOverlapTime', 'Time slots overlapped', (value) => {
      let result = [];
      if (value) {
        for (let a = 0; a < value.length; a++) {
          const startTimeA = moment(value[a]?.startTime, timeFormat);
          const endTimeA = moment(value[a]?.endTime, timeFormat);
          result[a] = true;
          for (let b = 0; b < value.length; b++) {
            const startTimeB = moment(value[b]?.startTime, timeFormat);
            const endTimeB = moment(value[b]?.endTime, timeFormat);
            if (a !== b) {
              //if not same index
              if (
                (startTimeA.diff(startTimeB) > 0 && startTimeA.diff(endTimeB) < 0) ||
                (endTimeA.diff(startTimeB) > 0 && endTimeA.diff(endTimeB) < 0) ||
                startTimeA.isSame(startTimeB) ||
                endTimeA.isSame(endTimeB)
              ) {
                return false;
              }
            }
          }
        }
      }
      return true;
    })
});

export const profileWorkingScheduleSchema = Yup.object({
  availability: Yup.object({
    monday: availableTimeSchema,
    tuesday: availableTimeSchema,
    wednesday: availableTimeSchema,
    thursday: availableTimeSchema,
    friday: availableTimeSchema,
    saturday: availableTimeSchema,
    sunday: availableTimeSchema
  })
});
