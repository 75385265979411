import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';

export const canClientReceiveCommunication = (communicationPreference?: CommunicationPreference) =>
  !!communicationPreference && communicationPreference !== CommunicationPreference.NONE;

export const canClientReceiveEmail = (communicationPreference?: CommunicationPreference) =>
  !!communicationPreference &&
  [CommunicationPreference.NoPreference, CommunicationPreference.Email].includes(communicationPreference);

export const canClientReceiveSms = (communicationPreference?: CommunicationPreference) =>
  !!communicationPreference &&
  [CommunicationPreference.NoPreference, CommunicationPreference.SMS].includes(communicationPreference);
