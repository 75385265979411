import { useMemo } from 'react';
import moment from 'moment';

import styles from './BarLineChartWidget.module.scss';
import BarLineChart from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsInsights/components/BarLineChart/BarLineChart';
import CardHeader from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsInsights/components/CardHeader/CardHeader';
import { useAuth0 } from '@auth0/auth0-react';

interface BarLineChartWidgetProps {
  data: any;
  isPreview?: boolean;
  onDateRangeChange?: (dateRange: number) => void;
}

const BarLineChartWidget = ({ data, isPreview, onDateRangeChange }: BarLineChartWidgetProps) => {
  const isOverallCheckInGraphLoading = false;
  const { user } = useAuth0();
  const isRedBull = user && user['https://tacklit.com/TEMPIsRedBull'];

  const redBullSleepTickFormatter = (value: number) => {
    const sleepValue = value;

    if (sleepValue === 1) {
      return '< 4';
    } else if (sleepValue === 5) {
      return '10+';
    } else {
      return '6 - 7';
    }
  };

  const redBullAppetiteTickFormatter = (value: number) => {
    if (value === 1) {
      return 'Terrible';
    } else if (value === 5) {
      return 'Awesome';
    } else {
      return 'OK';
    }
  };

  const appetiteTickFormatter = (value: number) => {
    if (value === -18) {
      return 'Under';
    } else if (value === 18) {
      return 'Over';
    } else {
      return 'OK';
    }
  };

  const sleepTickFormatter = (value: number) => {
    const sleepValue = value + 8;

    if (sleepValue === 4) {
      return `<${sleepValue}`;
    } else if (sleepValue === 12) {
      return `${sleepValue}+`;
    } else {
      return `${sleepValue}`;
    }
  };

  const handleSelectDateRange = (dateRange: number) => {
    onDateRangeChange?.(dateRange);
  };

  const graphData = useMemo(() => {
    const { graphFilter, graphData } = data;

    const startDate = moment().subtract(graphFilter, 'days').format('YYYY-MM-DD');

    return graphData.filter((datum: any) => datum.date >= startDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container}>
      <CardHeader
        defaultDateRange={data.graphFilter}
        isHideFilter={isPreview}
        title={data.title}
        onSelectDateRange={handleSelectDateRange}
      />
      {isRedBull ? (
        <BarLineChart
          data={graphData}
          yDomain={[1, 5]}
          yLabel={data.label === 'sleepHours' ? '' : ''}
          yTicks={[1, 3, 5]}
          yTickFormatter={data.label === 'sleepHours' ? redBullSleepTickFormatter : redBullAppetiteTickFormatter}
          isLoading={isOverallCheckInGraphLoading}
          height={200}
        />
      ) : (
        <BarLineChart
          data={graphData}
          yDomain={[-18, 18]}
          yLabel={data.label === 'sleepHours' ? 'Hours per night' : 'Eating patterns'}
          yTicks={[-18, 0, 18]}
          yTickFormatter={data.label === 'sleepHours' ? sleepTickFormatter : appetiteTickFormatter}
          isLoading={isOverallCheckInGraphLoading}
          height={200}
        />
      )}
    </div>
  );
};

export default BarLineChartWidget;
