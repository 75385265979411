import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import CalendarView from './components/CalendarView/CalendarView';

import styles from './Calendar.module.scss';
import { useState } from 'react';
import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import CalendarFilterSection from './components/CalendarFilterSection/CalendarFilterSection';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import FinderWizardModal from '../Modals/FinderWizardModal/FinderWizardModal';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import Button from 'components/v2/Button/Button';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';

const Calendar = () => {
  const { isEdgeUser, isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const [isFinderWizardVisible, setIsFinderWizardVisible] = useState(false);
  const IS_DEVELOPMENT = isDevelopmentENV();

  const defaultFilters =
    isEdgeAdminView || isEdgeReceptionistView
      ? ([{ _id: '', isClinician: true }] as CalendarFilterInterface[])
      : ([{ _id: auth0ClinicianId, isClinician: true }] as CalendarFilterInterface[]);

  const [selectedFilters, setSelectedFilter] = useState<CalendarFilterInterface[]>(defaultFilters);

  const { profile } = useFetchProfile();
  const { accountSettings } = useGetAccountSettings();

  const handleCloseFinderWizardModal = () => {
    setIsFinderWizardVisible(false);
  };

  return (
    <HelmetWrapper title={'CORDS - Calendar'}>
      <ContentLayout className={styles.contentLayout}>
        <CalendarHeader profileData={profile} />
        {isEdgeUser && (
          <CalendarFilterSection selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilter} />
        )}
        {IS_DEVELOPMENT && (
          <Button
            className={styles.finderWizardButton}
            icon="auto_awesome"
            onClick={() => setIsFinderWizardVisible(true)}
          >
            Finder Wizard
          </Button>
        )}
        <div className={styles.calendarWrapper} id={'calendarWrapperId'}>
          <CalendarView selectedFilters={selectedFilters} timezone={accountSettings?.timezone || defaultTimezone} />
        </div>

        <FinderWizardModal visible={isFinderWizardVisible} onClose={() => handleCloseFinderWizardModal()} />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Calendar;
