import styles from './ContentDeliveryPage.module.scss';
import sampleBackground from './image.png';
import classNames from 'classnames';
import wideLogo from '../../../assets/images/bazaar/wideLogo.png';
import Profile from '../Profile/Profile';

const ContentDeliveryPage = () => {
  const data = {
    title: 'Walk With Me',
    subtitle: 'Mindfulness: what is it? Having a mindful approach, and how to deal with thoughts.',
    broughtToYouBy: 'Open Door',
    broughtToYouByLogo: wideLogo,
    lastUpdate: 'Nov 2, 2020',
    content: [
      {
        id: '1',
        data:
          '<strong>There’s nothing more damaging to aspiring entrepreneurs than mere inaction.</strong> I can attest to this myself as I spent the entirety of my mid-twenties talking about starting a business, but I never did.',
        type: 'text'
      },
      {
        id: '2',
        data:
          'Aenean sed adipiscing diam donec adipiscing tristique risus nec feugiat auctor urna nunc id cursus metus aliquam eleifend, arcu dictum varius duis at consectetur lorem donec massa sapien, sed risus ultricies tristique nulla aliquet. Morbi tristique senectus et netus et, nibh nisl condimentum id venenatis a condimentum vitae sapien?',
        type: 'text'
      },
      {
        id: '3',
        data: 'https://picsum.photos/400/200',
        type: 'image'
      },
      {
        id: '4',
        data: '<h2><strong>1. An Ending and a beginning</h2><strong>',
        type: 'text'
      },
      {
        id: '5',
        data: 'https://vimeo.com/637403296',
        type: 'vimeo'
      },
      {
        id: '6',
        data: '<a href="#">Ultricies tristique nulla aliquet -></a>',
        type: 'text'
      },
      {
        id: '7',
        data: '<h2><strong>2. How To Apply This:</h2><strong>',
        type: 'text'
      },
      {
        id: '8',
        data:
          'Bibendum enim facilisis gravida neque convallis. Convallis posuere morbi leo urna molestie turpis in eu mi bibendum neque egestas. Est ante in nibh mauris cursus mattis molestie aliquam purus sit amet luctus vulputate sapien nec sagittis aliquam enim nec dui nunc mattis enim, sit amet nulla facilisi morbi tempus iaculis urna id. Blandit cursus risus at ultrices mi tempus imperdiet nulla.',
        type: 'text'
      }
    ]
  };
  return (
    <>
      <Profile />
      <div className={styles.container}>
        <img className={styles.headerBackground} src={sampleBackground} alt="logo" />
        <div className={styles.header}>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.subtitle}>{data.subtitle}</div>
          <div className={styles.bottomLine}>
            <div className={styles.broughtToYouByLogo}>
              <img src={data.broughtToYouByLogo} alt={data.broughtToYouBy} />
            </div>
            <span>By</span>
            <div className={styles.broughtToYouBy}>{data.broughtToYouBy}</div>
            <span>Last Updated {data.lastUpdate}</span>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.innerBody}>
            {data.content.map((content) => {
              switch (content.type) {
                case 'text':
                  return <p dangerouslySetInnerHTML={{ __html: content.data }} />;
                case 'image':
                  return <img src={content.data} alt={content.id} />;
                case 'vimeo':
                  return (
                    <iframe
                      src={`${content.data.replace('https://vimeo.com', 'https://player.vimeo.com/video')}`}
                      width="640"
                      height="427"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="video"
                    />
                  );
                default:
                  return undefined;
              }
            })}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.innerContainer}>
          <button className={classNames(styles.buttonContainer, styles.back)}>
            <div className={styles.line1}>PREVIOUS SECTION</div>
            <div className={styles.line2}>Lorem ipsum dolor amet sit consect adipiscing.</div>
            <div className={styles.leftAlign}>
              <span className="material-icons-outlined">arrow_back</span>
            </div>
          </button>
          <button className={classNames(styles.buttonContainer, styles.next)}>
            <div className={styles.line1}>NEXT SECTION</div>
            <div className={styles.line2}>Lorem ipsum dolor amet sit consect adipiscing.</div>
            <div className={styles.rightAlign}>
              <span className="material-icons-outlined">arrow_forward</span>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default ContentDeliveryPage;
