import { useEffect, useState } from 'react';

import styles from './ConsentSelect.module.scss';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { CheckBoxListing } from '../../../../AddPatientModalV2Interface';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

interface ConsentSelectProps {
  id: string;
  clientName?: string;
  value: string;
  checkValidation: boolean;
  consentList: CheckBoxListing[];
  isListLoading: boolean;
  selectClassName?: string;
  onChangeConsentValue: (b: string) => void;
}

const ConsentSelect = ({
  id,
  clientName,
  value,
  checkValidation,
  consentList,
  isListLoading,
  selectClassName,
  onChangeConsentValue
}: ConsentSelectProps) => {
  const [isShowError, setShowError] = useState(checkValidation && !value);

  useEffect(() => {
    if (checkValidation) {
      setShowError(checkValidation && !value);
    }
  }, [checkValidation, value]);

  const handleConsentChange = (val: string) => {
    onChangeConsentValue(val);
  };

  return (
    <div className={selectClassName}>
      <MaterialSelect
        id={`clientConsentForm${id}`}
        label={clientName ? `${clientName} agreement` : 'Select consent form'}
        isSearchable={false}
        options={consentList}
        value={value}
        onChange={(val: string) => handleConsentChange(val)}
        loading={isListLoading}
      />
      <ErrorMessage
        className={styles.error}
        error={'Please select consent form'}
        visible={checkValidation && isShowError}
      />
    </div>
  );
};

export default ConsentSelect;
