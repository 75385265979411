import { notification } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { getMicroJournalEntries } from '../http/CheckInService/MicroJournal/microJournal';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';

export interface MicroJournalEntry {
  _id: string;
  date: string;
  title: string;
  response: string;
  tags?: Record<string, string>[];
}

export const useFetchMicroJournalEntries = (
  token: string,
  clientId: string,
  profileId?: string,
  dateRange?: number
) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [microJournalEntries, setMicroJournalEntries] = useState<MicroJournalEntry[]>([]);
  const [isMicroJournalEntriesLoading, setIsMicroJournalEntriesLoading] = useState(true);

  const fetchMicroJournalEntries = async (dateRange?: number) => {
    setIsMicroJournalEntriesLoading(true);

    if (clientId && !isEdgeReceptionist) {
      try {
        const startDate = dateRange
          ? moment().subtract(dateRange, 'days').format('YYYY-MM-DD')
          : moment('2020-10-01').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');

        const queryParam = `${
          profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
        }&startDate=${startDate}&endDate=${endDate}`;

        const callGetMicroJournalEntriesByPatientId = await getMicroJournalEntries(token, queryParam);
        const { entries } = await callGetMicroJournalEntriesByPatientId.json();

        if (Array.isArray(entries)) {
          setMicroJournalEntries(entries);
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({
          message: "Something went wrong while trying to get this patient's micro journal entries"
        });
      }
    }

    setIsMicroJournalEntriesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchMicroJournalEntries(dateRange ? dateRange : 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { microJournalEntries, isMicroJournalEntriesLoading, fetchMicroJournalEntries };
};
