import bent from 'bent';
import { v4 as uuid } from 'uuid';
const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

const getData = (authKey: string) => {
  const checkInRequest = bent(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return checkInRequest('/micro-check-ins/templates/me');
};

const massageQuestions = (data: any) => {
  const massageQ = [];
  for (let obj of data) {
    massageQ.push({
      ...obj, // To populate types and options
      id: obj.id,
      stem: obj.stem,
      order: obj.order,
      isChecked: obj.isChecked,
      refKey: obj.refKey,
      questionId: uuid()
    });
  }
  return massageQ;
};

const massageQuestionSet = (data: any) => {
  const massageQSet = [];
  for (let obj of data) {
    massageQSet.push({
      sectionName: obj.sectionName,
      askFrequency: obj.askFrequency,
      questions: massageQuestions(obj.questions),
      refKey: obj.refKey
    });
  }
  return massageQSet;
};

const massageData = (data: any) => {
  return {
    questionSets: massageQuestionSet(data.questionSets)
  };
};

export const getMCIAssessmentQuestionTemplate = async (token: string) => {
  try {
    const templateData = await getData(token);
    return massageData(templateData);
  } catch (error) {
    console.error('getMCIAssessmentQuestionTemplate error', error);
  }

  return undefined;
};
