import styles from './OneToOneCard.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { Skeleton } from 'antd';
import moment from 'moment';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { getName } from 'utils/general';
import Button from 'components/v2/Button/Button';
import { useFetchProfileById } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { PracticeInfoInterface } from 'interfaces/Profile/Profile';
import { Link } from 'react-router-dom';
import { useFetchAppointmentClientDetails } from './hooks/GetAppointmentClientDetails';
import { handleCopyContent } from 'utils/CopyFeature';
import { useTranslation } from 'react-i18next';

interface OneToOneCardProps {
  clinicianId?: string;
  clientRecordId: string;
  practice: PracticeInfoInterface['practice'];
}

const OneToOneCard = ({ clinicianId, clientRecordId, practice }: OneToOneCardProps) => {
  const { token } = useGetAccessToken();
  const { CLIENTS } = useRoutesGenerator();

  const { clientEncryptDetails, clientEncryptDetailsLoading } = useFetchAppointmentClientDetails(token, clientRecordId);
  const { profile, isProfileLoading } = useFetchProfileById(clinicianId!);
  const [t] = useTranslation();

  return clientEncryptDetailsLoading || (clinicianId && isProfileLoading) ? (
    <div>
      <Skeleton active />
    </div>
  ) : (
    <div className={styles.container}>
      {clinicianId
        ? profile && profile?.name && <ProfileBadge avatar={profile?.avatar} name={profile.name} />
        : practice.name && <ProfileBadge avatar={practice.logo} name={practice.name} />}
      {clientEncryptDetails &&
        clientEncryptDetails.clientProfiles.map((clientProfile, index) => (
          <div className={styles.clientContainer} key={index}>
            <div className={styles.clientAvatarContainer}>
              <ClientAvatar
                initialsName={clientProfile.initials}
                avatarUrl={clientProfile.avatar}
                avatarSize={36}
                initialsClassName={styles.initialName}
                horizontal
              />
            </div>
            <div className={styles.clientDetailsWrapper}>
              <div>
                <div className={styles.clientName}>{getName(clientProfile)}</div>
                <Link className={styles.link} to={`${CLIENTS.BASE}/${clientRecordId}/profile`}>
                  View profile
                  <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward_ios</i>
                </Link>
              </div>
              <div className={styles.clientDetailsContainer}>
                <div className={styles.contactDetailsContainer}>
                  {clientProfile.mobileNumber && (
                    <>
                      <div className={styles.label}>Mobile</div>
                      <div className={styles.data}>
                        <span>{clientProfile.mobileNumber}</span>
                        <Button
                          variant="link"
                          icon="content_copy"
                          className={styles.copyButton}
                          onClick={() => handleCopyContent(clientProfile.mobileNumber)}
                        />
                      </div>
                    </>
                  )}
                  {clientProfile.email && (
                    <>
                      <div className={styles.label}>Email</div>
                      <div className={styles.data}>
                        <span>{clientProfile.email}</span>
                        <Button
                          variant="link"
                          icon="content_copy"
                          className={styles.copyButton}
                          onClick={() => handleCopyContent(clientProfile.email)}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.clientHistoryContainer}>
                  <div className={styles.clientSinceContainer}>
                    <div className={styles.label}>{t('label.client_since')}</div>
                    <div className={styles.clientSince}>
                      {moment(clientEncryptDetails.createdAt).format('DD MMM YYYY')}
                    </div>
                    <div className={styles.clientSinceDays}>{`(${moment().diff(
                      moment(clientEncryptDetails.createdAt),
                      'days'
                    )} days)`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default OneToOneCard;
