import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import AnalyticsContent from './components/AnalyticsContent/AnalyticsContent';

import styles from './Analytics.module.scss';

const Analytics = () => (
  <HelmetWrapper title="CORDS - Analytics">
    <ContentLayout contentClassName={styles.container} className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            <i className={`material-icons-outlined ${styles.menuIcon}`}>analytics</i>
            Analytics
          </div>
        </div>
      </div>
      <AnalyticsContent />
    </ContentLayout>
  </HelmetWrapper>
);

export default Analytics;
