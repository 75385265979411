import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const patchFirstAssessmentSetDefault = (token: string, firstAssessmentId: string) =>
  httpClient('PATCH', URL!, 204, { Authorization: `Bearer ${token}` })(
    `/first-assessments/${firstAssessmentId}:setDefault`
  ) as Promise<BentResponse>;

export const postSendAdHocSurvey = (token: string, clientRecordId: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 201, {
    Authorization: `Bearer ${token}`
  })(`/client-records/${clientRecordId}:sendAdHocSurvey`, payload) as Promise<BentResponse>;

export const postSendAdHocSurveyAsPractice = (token: string, clientRecordId: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 201, {
    Authorization: `Bearer ${token}`
  })(`/client-records/${clientRecordId}:sendAdHocSurveyAsPractice`, payload) as Promise<BentResponse>;

export const postCreateSurveyLinks = (token: string, clientRecordId: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 201, {
    Authorization: `Bearer ${token}`
  })(`/client-records/${clientRecordId}:createSurveyLinks`, payload) as Promise<BentResponse>;

export const getSurveyList = (token: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(`/first-assessments`) as Promise<BentResponse>;

export const getVadcStructureQuestions = (token: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    '/first-assessment-structured-questions/vadc'
  ) as Promise<BentResponse>;
