import ContentLayout from 'components/ContentLayout/ContentLayout';
import styles from './SetPasswordLayout.module.scss';
import React from 'react';
import logo from 'assets/images/tacklit-logo-white.svg';

interface SetPasswordLayoutProps {
  children?: React.ReactNode;
}

const SetPasswordLayout = ({ children }: SetPasswordLayoutProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ContentLayout>
          <img alt="tacklit logo" className={styles.logo} src={logo} />
        </ContentLayout>
      </div>
      {children}
    </div>
  );
};

export default SetPasswordLayout;
