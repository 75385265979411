import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';
import { CreateRoomType } from 'interfaces/Schedule/Room';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getRoomList = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/rooms') as Promise<BentResponse>;

export const getRoom = (token: string, id: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(`/rooms/${id}`) as Promise<BentResponse>;

export const createRoom = (token: string, body: CreateRoomType) =>
  httpClient('POST', url!, [201, 400], { Authorization: `Bearer ${token}` })('/rooms', body) as Promise<BentResponse>;

export const updateRoom = (token: string, id: string, body: CreateRoomType) =>
  httpClient('PUT', url!, [204, 400, 409], { Authorization: `Bearer ${token}` })(
    `/rooms/${id}`,
    body
  ) as Promise<BentResponse>;

export const updateRoomAndKeepFutureEvent = (token: string, id: string, body: CreateRoomType) =>
  httpClient('PUT', url!, [204, 400, 409], { Authorization: `Bearer ${token}` })(
    `/rooms/${id}:pauseBookingAndKeepFutureEvents`,
    body
  ) as Promise<BentResponse>;

export const updateRoomAndCancelFutureEvent = (token: string, id: string, body: CreateRoomType) =>
  httpClient('PUT', url!, [204, 400, 409], { Authorization: `Bearer ${token}` })(
    `/rooms/${id}:pauseBookingAndCancelFutureEvents`,
    body
  ) as Promise<BentResponse>;

export const getAvailableRoomList = (token: string, qParam: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/rooms:getAvailableRooms?${qParam}`
  ) as Promise<BentResponse>;
