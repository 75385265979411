import Checkbox from 'components/v2/Checkbox/Checkbox';
import styles from './RecurringAppointment.module.scss';
import OccurrencesField from '../../../OccurrencesField/OccurrencesField';
import FrequencyField from '../../../FrequencyField/FrequencyField';
import { useField } from 'formik';

interface RecurringAppointmentProps {
  date: string;
  occurrences: number;
  frequency: number;
}

const RecurringAppointment = ({ date, occurrences, frequency }: RecurringAppointmentProps) => {
  const [{ value: valueRecurring }, , recurringHelper] = useField('isRecurring');

  return (
    <div className={styles.container}>
      <Checkbox
        label="Set as recurring appointment"
        subLabel="Same day and time for more than once"
        checked={valueRecurring}
        onChange={recurringHelper.setValue}
      />
      {valueRecurring && (
        <div className={styles.recurringDetails}>
          <div>
            <FrequencyField />
          </div>
          <div className={styles.recurringTimesContainer}>
            <OccurrencesField />
          </div>
        </div>
      )}
    </div>
  );
};

export default RecurringAppointment;
