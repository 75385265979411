import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import ReactTooltip from 'react-tooltip';

import { getMCIClientList } from 'utils/http/CheckInService/MicroCheckIn/mci';
import ClientAssignList from './components/ClientAssignList/ClientAssignList';

import styles from './ClientsList.module.scss';
import { ClientAssignInterface, MCIClientStruct } from './components/ClientAssignList/components/interfaces';
import { useGetAccessToken } from 'utils/hooks/token';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { getName } from 'utils/general';
import { useTranslation } from 'react-i18next';

interface ClientsListProps {
  assessmentId: string;
  isEditMode: boolean;
}

const ClientsList = ({ assessmentId, isEditMode }: ClientsListProps) => {
  const { token } = useGetAccessToken();
  const [showAddClientList, setShowAddClientList] = useState(false);
  const [mCIClientList, setMCIClientList] = useState<ClientAssignInterface[]>([]);
  const [assessmentClientList, setAssessmentClientList] = useState<MCIClientStruct[]>([]);
  const [clientListLoading, setClientListLoading] = useState(true);
  const [t] = useTranslation();

  const fetchClients = async () => {
    setClientListLoading(true);
    const callClientList = await getMCIClientList(token);
    const clientListData = (await callClientList.json()) as ClientAssignInterface[];

    setAssessmentClientList(massageAssignmentList(clientListData));
    setMCIClientList(clientListData);
    setClientListLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const addClientBtn = () => {
    setShowAddClientList(true);
  };

  const closeModal = () => {
    setShowAddClientList(false);
  };

  const massageProfileSameAssessmenId = (profileData: MCIClientStruct[]) => {
    let newClientProfileWithRecordId = [] as MCIClientStruct[];
    profileData
      .filter(({ microCheckInId }) => microCheckInId === assessmentId)
      .map((clientProfileObj) =>
        newClientProfileWithRecordId.push({
          ...clientProfileObj
        })
      );

    return newClientProfileWithRecordId;
  };

  const massageAssignmentList = (assignmentClientList: ClientAssignInterface[]) => {
    let newClientRecord = [] as MCIClientStruct[];
    assignmentClientList.map((clientRecord) =>
      newClientRecord.push(...massageProfileSameAssessmenId(clientRecord.clientProfiles))
    );
    return newClientRecord;
  };

  const onSaveClientList = (newList: ClientAssignInterface[]) => {
    setClientListLoading(true);

    const newAssessmentClient =
      newList && newList.length > 0
        ? newList.filter(
            ({ clientProfiles }) =>
              clientProfiles.filter(({ microCheckInId }) => microCheckInId === assessmentId).length > 0
          )
        : [];

    setMCIClientList(newList);
    setAssessmentClientList(massageAssignmentList(newAssessmentClient));
    setShowAddClientList(false);
    setClientListLoading(false);
  };

  return (
    <div className={styles.container}>
      {!isEditMode && (
        <>
          <div className={styles.inactive} data-tip data-for="inactive" />
          <ReactTooltip id="inactive" className={styles.tooltip}>
            {t('client_program.tooltip')}
          </ReactTooltip>
        </>
      )}
      <div className={styles.clientTitle}>{t('client_program.title')}</div>
      <div className={styles.clientDesc}>
        <div>{t('client_program.description', { count: assessmentClientList.length })}</div>
        <div className={styles.addClientBtn} onClick={addClientBtn}>
          {t('button.add_or_change_client')}
        </div>
      </div>
      {clientListLoading ? (
        <Skeleton className={styles.container} />
      ) : (
        <div>
          {assessmentClientList.map((clientProfileObj, index) => (
            <div className={styles.clientCard} key={index}>
              <ClientAvatar
                name={getName(clientProfileObj)}
                avatarUrl={clientProfileObj.avatar}
                initialsName={clientProfileObj.initials}
                avatarSize={40}
                horizontal
              />
            </div>
          ))}
        </div>
      )}
      <ClientAssignList
        isEditMode={isEditMode}
        isShowModal={showAddClientList}
        onCloseModal={closeModal}
        clientListLoading={clientListLoading}
        clientListData={mCIClientList}
        onSaveClientList={onSaveClientList}
      />
    </div>
  );
};

export default ClientsList;
