import styles from './AttendanceCheckbox.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';
import classnames from 'classnames';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';

interface Option {
  value: string;
  label: string;
}

interface AttendanceCheckboxProps {
  clientRecordId: string;
  attended?: boolean;
  reasonForNonAttendance?: string;
  onChangeAttendance: (clientRecordId: string, isAttend: boolean | undefined, reason?: string) => void;
  disabled: boolean;
}

const generateReasonList = (absenceReasonsList: string[]) => {
  let nonAttendList: Option[] = [];
  for (let obj of absenceReasonsList) {
    nonAttendList.push({
      label: obj,
      value: obj
    });
  }
  return nonAttendList;
};

const AttendanceCheckbox = ({
  clientRecordId,
  attended,
  reasonForNonAttendance,
  onChangeAttendance,
  disabled
}: AttendanceCheckboxProps) => {
  const { accountSettings } = useGetAccountSettings();
  const handleChangeValue = (attendedVal: boolean) => {
    const reason = attendedVal ? undefined : reasonForNonAttendance;
    onChangeAttendance(clientRecordId, attendedVal, reason);
  };
  const NO_ATTENDED_REASON = generateReasonList(accountSettings?.absenceReasons || []);

  const handleSelectReason = (val: string) => {
    onChangeAttendance(clientRecordId, attended, val);
  };

  const yesValue = attended !== undefined ? attended : false;
  const noValue = attended !== undefined ? !attended : false;

  return (
    <div className={styles.container}>
      <CheckBox
        id={`attendedYes${clientRecordId}`}
        value={yesValue}
        label={'Yes'}
        onChange={() => handleChangeValue(true)}
        labelClassName={classnames(yesValue ? styles.labelActive : styles.label, disabled && styles.labelLoading)}
        disabled={disabled}
        disabledClassName={yesValue ? styles.disabledActive : styles.disabled}
      />
      <CheckBox
        id={`attendedNo${clientRecordId}`}
        value={noValue}
        label={'No'}
        onChange={() => handleChangeValue(false)}
        labelClassName={classnames(noValue ? styles.labelActive : styles.label, disabled && styles.labelLoading)}
        disabled={disabled}
        disabledClassName={noValue ? styles.disabledActive : styles.disabled}
      />
      {noValue && NO_ATTENDED_REASON.length > 0 && (
        <MaterialDropdown
          id={`reason${clientRecordId}`}
          label={'Select reason for non attendance'}
          dropDownList={NO_ATTENDED_REASON}
          selectedValue={reasonForNonAttendance || ''}
          onChangeValue={(val: string) => handleSelectReason(val)}
          fieldClassName={styles.reasonSelect}
        />
      )}
    </div>
  );
};

export default AttendanceCheckbox;
