import { useMemo } from 'react';
import moment from 'moment';
import { SurveyClientTab } from 'interfaces/Reports/report';

import styles from './PatientDetailsSurveysHeader.module.scss';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

interface PatientDetailsSurveysHeaderProps {
  recordId: string;
  currentTab: string;
  surveyId?: string;
  surveyList?: SurveyClientTab[];
}

const PatientDetailsSurveysHeader = ({
  recordId,
  currentTab,
  surveyId,
  surveyList
}: PatientDetailsSurveysHeaderProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const { currentSurvey, previousSurvey, nextSurvey } = useMemo(() => {
    const surveyHeaderItems: {
      currentSurvey?: SurveyClientTab;
      previousSurvey?: SurveyClientTab;
      nextSurvey?: SurveyClientTab;
    } = { currentSurvey: undefined, previousSurvey: undefined, nextSurvey: undefined };

    if (surveyList) {
      const currentSurvey = surveyList.find((surveyItem) => surveyItem._id === surveyId);

      if (currentSurvey) {
        surveyHeaderItems.currentSurvey = currentSurvey;

        const currentIndex = surveyList.indexOf(currentSurvey);

        if (currentIndex > -1) {
          surveyHeaderItems.previousSurvey = surveyList[currentIndex - 1];
          surveyHeaderItems.nextSurvey = surveyList[currentIndex + 1];
        }
      }
    }

    return surveyHeaderItems;
  }, [surveyId, surveyList]);

  const handleSurveyClick = (id: string) => {
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${id}`);

    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  return (
    <div className={styles.container}>
      {previousSurvey && (
        <div className={`${styles.btnBox} ${styles.left}`} onClick={() => handleSurveyClick(previousSurvey._id)}>
          <i className={`material-icons ${styles.icon}`}>chevron_left</i>
          <div className={styles.date}>
            <div className={styles.label}>{previousSurvey.name}</div>{' '}
            {moment(previousSurvey.createdAt).format(MOMENTJS_DATE_FORMAT)}
          </div>
        </div>
      )}
      {currentSurvey && (
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{currentSurvey.name}</div>
          <div className={styles.date}>{moment(currentSurvey.createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
        </div>
      )}
      {nextSurvey && (
        <div className={`${styles.btnBox} ${styles.right}`} onClick={() => handleSurveyClick(nextSurvey._id)}>
          <div className={styles.date}>
            <div className={styles.label}>{nextSurvey.name}</div>{' '}
            {moment(nextSurvey.createdAt).format(MOMENTJS_DATE_FORMAT)}
          </div>
          <i className={`material-icons ${styles.icon}`}>chevron_right</i>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsSurveysHeader;
