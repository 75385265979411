import PolicyContainer from './PolicyContainer';

const CORDSPrivacy = () => (
  <PolicyContainer>
    <iframe
      title={'cordsPrivacy'}
      height={'300px'}
      width={'100%'}
      frameBorder={'0'}
      src={'https://storage.googleapis.com/tacklit-email-assets/CORDS-UK-Privacy-Policy.html'}
    />
  </PolicyContainer>
);

export default CORDSPrivacy;
