import { useState } from 'react';
import Button from 'components/v2/Button/Button';
import SessionAssigned from '../SessionAssigned/SessionAssigned';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import { GroupSessionDetails } from 'pages/Groups/Interfaces/GroupsSession';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import moment from 'moment';
import { DELIVERY_TYPE_ICONS, DELIVERY_TYPE_LABELS, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './SessionInfoBox.module.scss';
import { putGroupSessionDetails } from 'utils/http/ScheduleService/Groups/groups';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';

interface SessionInfoBoxProps {
  sessionLabel: string;
  groupDetails: Groups;
  groupSessionDetails: GroupSessionDetails;
  groupId: string;
  sessionId: string;
  onEditAppointmentComplete: () => void;
}

const SessionInfoBox = ({
  sessionLabel,
  groupDetails,
  groupSessionDetails,
  groupId,
  sessionId,
  onEditAppointmentComplete
}: SessionInfoBoxProps) => {
  const { auth0ClinicianId } = useGetClinicianId();
  const { token } = useGetAccessToken();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [showEditAppointmentModal, setShowEditAppointmentModal] = useState<boolean>(false);
  const allowEditSessionInfo = isEdgeAdminView || auth0ClinicianId === groupDetails.leadClinicianAuth0Id;

  const handleChangeFacilitator = async (
    facilitatorList: GroupSessionDetails['groupDetails']['deliveringPractitionerIds']
  ) => {
    try {
      const payload = {
        deliveringPractitionerIds: facilitatorList?.filter((clinicianId) => clinicianId)
      };
      await putGroupSessionDetails(token, groupId, sessionId, payload);
      notification.success({
        message: 'Facilitator updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Facilitator fail to updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const generateDeliveryDesc = (deliveryType: DeliveryType) => {
    switch (deliveryType) {
      case DeliveryType.PhoneCall:
        return <div className={styles.detailDesc}>{groupSessionDetails.phoneCallInstructions}</div>;
      case DeliveryType.VideoCall:
        return <div className={styles.detailDesc}>{groupSessionDetails.videoCallInstructions}</div>;
      case DeliveryType.FaceToFace:
        return <div className={styles.detailDesc}>{groupSessionDetails.faceToFaceLocation}</div>;
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.dateInfo}>
        <div className={styles.time}>
          {moment(groupSessionDetails.startTime, 'HH:mm').format('hh:mm A')}
          {' - '}
          {moment(groupSessionDetails.endTime, 'HH:mm').format('hh:mm A')}
        </div>
        <div className={styles.appointmentDate}>
          {moment(groupSessionDetails.date, 'YYYY-MM-DD').format('dddd, Do MMMM')}
        </div>
        <Button
          variant={'link'}
          className={styles.editBtn}
          onClick={() => {
            setShowEditAppointmentModal(true);
          }}
        >
          <span className={`material-icons-outlined ${styles.icon}`}>edit</span>
          Change appointment
        </Button>
        <EventInformationModal
          groupSessionLabel={sessionLabel}
          visible={showEditAppointmentModal}
          onClose={() => {
            setShowEditAppointmentModal(false);
          }}
          group={0}
          appointmentId={groupSessionDetails._id}
          appointment={groupSessionDetails}
          hideEditGroupBtn
          onEditComplete={() => {
            onEditAppointmentComplete();
          }}
        />
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.detailCard}>
          <div className={`material-icons ${styles.detailIcon}`}>event</div>
          <div className={styles.descWrapper}>
            <div className={styles.detailTitle}>{groupSessionDetails?.name || groupSessionDetails.sessionTypeName}</div>
            {groupSessionDetails.sessionTypeDescription && (
              <div className={styles.detailDesc}>{groupSessionDetails.sessionTypeDescription}</div>
            )}
          </div>
        </div>
        {groupSessionDetails.room && (
          <div className={styles.detailCard}>
            <div className={`material-icons ${styles.detailIcon}`}>meeting_room</div>
            <div className={styles.descWrapper}>
              <div className={styles.detailTitle}>{groupSessionDetails.room.roomName}</div>
            </div>
          </div>
        )}
        <div className={styles.detailCard}>
          <div className={`material-icons ${styles.detailIcon}`}>
            {DELIVERY_TYPE_ICONS[groupSessionDetails.deliveryType!]}
          </div>
          <div className={styles.descWrapper}>
            <div className={styles.detailTitle}>{DELIVERY_TYPE_LABELS[groupSessionDetails.deliveryType!]}</div>
            {generateDeliveryDesc(groupSessionDetails.deliveryType!)}
          </div>
        </div>
      </div>
      <SessionAssigned
        groupDetails={groupDetails}
        selectedPractitioner={groupSessionDetails.groupDetails.deliveringPractitionerIds}
        selectedPractitionerProfile={groupSessionDetails.groupDetails.deliveringPractitionerProfiles}
        onChangeFacilitator={handleChangeFacilitator}
        allowEdit={allowEditSessionInfo}
      />
    </div>
  );
};

export default SessionInfoBox;
