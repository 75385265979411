import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { clientRecordsInterface } from '../../../interfaces/Clients/clientsRecord';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from '../../http/ClinicianProfileService/ClientRecords/clientRecords';
import { useGetAccountId } from '../GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const massageClientRecordData = (clientRecordData: clientRecordsInterface[]) =>
  clientRecordData.map((clientRecordObj) => ({
    ...clientRecordObj,
    allowCommunicationWithEmail: clientRecordObj.clientProfiles.some(
      (profileObj) =>
        profileObj.role !== 'child' &&
        profileObj.isPrimaryContact &&
        canClientReceiveEmail(profileObj.communicationPreference) &&
        profileObj.hasEmail
    ),
    allowCommunicationWithSms: clientRecordObj.clientProfiles.some(
      (profileObj) =>
        profileObj.role !== 'child' &&
        profileObj.isPrimaryContact &&
        canClientReceiveSms(profileObj.communicationPreference) &&
        profileObj.hasMobileNumber
    )
  }));

export const useFetchClientRecordList = (token: string, signUpOnly?: boolean, noStatusFilter?: boolean) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [clientList, setClientList] = useState<clientRecordsInterface[]>([]);
  const [isClientListLoading, setIsClientListLoading] = useState(true);
  const [t] = useTranslation();

  const fetchClients = async (token: string) => {
    try {
      const queryParam = {
        ...(!noStatusFilter && { recordStatus: 'active' }),
        ...(signUpOnly && { hasSignedUp: signUpOnly })
      };
      const generateQueryParam = queryString.stringify(queryParam);
      const qParam = generateQueryParam ? `?${generateQueryParam}` : '';

      const clientsData =
        (isEdgeAdminView || isEdgeReceptionist) && accountId
          ? await (await getClientsRecordByAccountId(token, accountId, qParam)).json()
          : await (await getClientsRecord(token, qParam)).json();

      const massageClientRecord = massageClientRecordData(clientsData.clientRecords);
      setClientList(massageClientRecord);
    } catch (ex) {
      notification.error({ message: t('form.error.fetch_client_report_listing') });
    }

    setIsClientListLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchClients(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { clientList, isClientListLoading };
};
