import styles from './DateDescBox.module.scss';

interface DateDescBoxProps {
  title: string;
  desc?: string;
}

const DateDescBox = ({ title, desc }: DateDescBoxProps) => {
  return (
    <>
      <div className={styles.title}>{title}</div>
      {desc && <div className={styles.desc}>{desc}</div>}
    </>
  );
};

export default DateDescBox;
