import { useMemo, useState } from 'react';

import {
  CheckInQuestion,
  ClinicalAssessment,
  MicroJournalQuestion,
  OnboardingQuestion
} from '../../AssessmentInterfaces';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import IndividualQuestions from './components/IndividualQuestions/IndividualQuestions';
import QuestionSet from './components/QuestionSet/QuestionSet';
import Stimulus from './components/Stimulus/Stimulus';

import { PatientQuestionFormHeaderProps } from '../../PatientQuestionForm';

import styles from './Assessment.module.scss';

interface AssessmentProps {
  assessment: CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion;
  completedQuestions: number;
  totalQuestions?: number;
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  reverseStemAndHideTitle: boolean;
  isSkippable: boolean;
  isFirstQuestionSet: boolean;
  onBack: () => void;
  onSkip: () => void;
  onSubmit: (
    assessmentId: string,
    values: any,
    setStageToNextQuestion?: boolean
  ) => Promise<CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion | undefined>;
  onSaveAndExit: () => void;
  submitBtnText?: JSX.Element;
  submitBtnClass?: string;
  isLoading?: boolean;
  disableFullscreenLoading?: boolean;
}

const Assessment = ({
  assessment,
  completedQuestions,
  totalQuestions,
  defaultPatientDetails,
  header,
  reverseStemAndHideTitle,
  isSkippable,
  isFirstQuestionSet,
  onBack,
  onSkip,
  onSubmit,
  onSaveAndExit,
  submitBtnText,
  submitBtnClass,
  isLoading,
  disableFullscreenLoading
}: AssessmentProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { questionType, stimulus, metadata } = useMemo(() => {
    if ('questionType' in assessment) {
      const { questionType, stimulus, metadata } = assessment;

      return { questionType, stimulus, metadata };
    }

    return {};
  }, [assessment]);

  const { id, title, sections } = assessment;

  const handleSubmit = (values: { [key: string]: Record<string, any> }, setStageToNextQuestion?: boolean) =>
    onSubmit(id, values, setStageToNextQuestion);

  return (
    <>
      {!disableFullscreenLoading && (isSubmitting || isLoading) && (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      )}
      {questionType === 'questionSet' ? (
        <>
          <div className={styles.title}>{metadata?.cords?.shortTitle || title}</div>
          <Stimulus stimulus={stimulus} />
          <QuestionSet
            sections={sections}
            completedQuestions={completedQuestions}
            totalQuestions={totalQuestions}
            defaultPatientDetails={defaultPatientDetails}
            header={header}
            isSkippable={isSkippable}
            isFirstQuestionSet={isFirstQuestionSet}
            onBack={onBack}
            onSkip={onSkip}
            onSubmit={handleSubmit}
            setIsSubmitting={setIsSubmitting}
            onSaveAndExit={onSaveAndExit}
            submitBtnText={submitBtnText}
            submitBtnClass={submitBtnClass}
          />
        </>
      ) : (
        <IndividualQuestions
          sections={sections}
          completedQuestions={completedQuestions}
          totalQuestions={totalQuestions}
          defaultPatientDetails={defaultPatientDetails}
          header={header}
          title={title}
          reverseStemAndHideTitle={reverseStemAndHideTitle}
          isSkippable={isSkippable}
          isFirstQuestionSet={isFirstQuestionSet}
          onBack={onBack}
          onSkip={onSkip}
          onSubmit={handleSubmit}
          setIsSubmitting={setIsSubmitting}
          onSaveAndExit={onSaveAndExit}
        />
      )}
    </>
  );
};

export default Assessment;
