import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const getAttachmentsByGroupId = (token: string, groupId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/attachments`
  ) as Promise<BentResponse>;

export const downloadAttachmentByGroupId = (token: string, groupId: string, attachmentId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/attachments/${attachmentId}:download`
  ) as Promise<BentResponse>;

export const uploadAttachmentByGroupId = async (
  token: string,
  groupId: string,
  file: File,
  body?: Record<string, any>
) => {
  const formData = new FormData();

  formData.append('file', file);

  for (const key in body) {
    formData.append(key, body[key]);
  }

  const request = new XMLHttpRequest();
  request.responseType = 'json';

  return new Promise<any>((resolve, reject) => {
    request.open('POST', `${url}/groups/${groupId}/attachments`, true);
    request.setRequestHeader('Authorization', `Bearer ${token}`);

    request.onload = () => {
      if (request.status === 201) {
        resolve(request.response);
      } else if (request.status === 400) {
        reject('Your file is too big. Please select a file that is smaller than 10MB');
      }
    };

    request.send(formData);
  });
};

export const uploadAttachmentThumbnailByGroupId = async (
  token: string,
  groupId: string,
  attachmentId: string,
  file: File
) => {
  const formData = new FormData();

  formData.append('file', file);

  const request = new XMLHttpRequest();
  request.responseType = 'json';

  return new Promise<any>((resolve, reject) => {
    request.open('PATCH', `${url}/groups/${groupId}/attachments/${attachmentId}/thumbnail`, true);
    request.setRequestHeader('Authorization', `Bearer ${token}`);

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.response);
      } else if (request.status === 400) {
        reject('Your file is too big. Please select a file that is smaller than 10MB');
      }
    };

    request.send(formData);
  });
};

export const deleteAttachmentByGroupId = (token: string, groupId: string, attachmentId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/attachments/${attachmentId}`
  ) as Promise<BentResponse>;
