import { useEffect, useState } from 'react';

import { useGetAccessToken } from 'utils/hooks/token';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import PatientHeader from './components/PatientHeader/PatientHeader';

import styles from './Patient.module.scss';
import HelmetWrapper from '../../components/HelmetWrapper/HelmetWrapper';

import AddPatientModalV2 from '../../components/AddPatientModalV2/AddPatientModalV2';
import ClientListContent from './components/ClientListContent/ClientListContent';
import {
  useFetchClientsRecord,
  useFetchClientsRecordCount
} from './components/ClientListContent/hooks/GetClientListInfo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import {
  putCancelInvitation,
  putResendInvitation
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { PractitionersStatus } from '../../interfaces/Practitioners/practitionersListing';
import queryString from 'query-string';
import { ClientStatus, ProspectStatus } from '../../interfaces/Clients/clientsRecord';
import { useRoutesGenerator } from '../../utils/hooks/Path/RoutesGenerator';
import AddProspectClient from '../../components/AddProspectClient/AddProspectClient';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { useTranslation } from 'react-i18next';
import ChangeTeacherBulk from './components/ChangeAssessorBulk/ChangeAssessorBulk';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ChangeWellBeingLeaderBulk from './components/ChangeWellBeingLeaderBulk/ChangeWellBeingLeaderBulk';
import useClientListingFilter from './components/ClientListContent/hooks/useClientListingFilter';

export interface TabIds {
  tabId?: ClientStatus['status'];
}
export interface SortBy {
  clientName?: number;
  clientSince?: number;
  studentClass?: number;
  latestAssessmentScore?: number;
}

export const CLIENT_LIST_SORT_INFO = 'client-list-sort-info';

const PatientPage = () => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const path = useParams<{ [key in keyof TabIds]: string }>();
  const location = useLocation();
  const { firstPath, isEdgeAdminView } = useGetAccountPackageView();
  const queryParam: {
    q?: string;
    page?: string;
    status?: PractitionersStatus['status'];
    prospectStatus?: ProspectStatus;
  } = queryString.parse(location.search);

  const [showAddClient, setShowAddClient] = useState(false);
  const [showAddProspect, setShowAddProspect] = useState(false);
  const [showChangeTeacherBulk, setShowChangeTeacherBulk] = useState(false);
  const [showAssignWellBeingLeaderBulk, setShowAssignWellBeingLeaderBulk] = useState(false);
  const [filterType, setFilterType] = useState<TabIds['tabId']>((path.tabId as ClientStatus['status']) ?? 'active');
  const [searchVal, setSearchVal] = useState<string>(queryParam.q ?? '');

  const [pageLoading, setPageLoading] = useState(false);
  const [recordIdProcessing, setRecordIdProcessing] = useState<string>('');
  const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([]);
  const [t] = useTranslation();

  const savedSort = localStorage.getItem(CLIENT_LIST_SORT_INFO);
  const sortBy = savedSort ? (JSON.parse(savedSort) as SortBy) : {};
  const filterValue = {
    status: filterType,
    perPage: 10,
    page: queryParam.page || 1,
    q: queryParam.q || '',
    prospectStatus: queryParam.prospectStatus || ProspectStatus.Prospect,
    sortByClientName: sortBy.clientName || sortBy.studentClass ? sortBy.clientName : 1,
    sortByClass: sortBy.studentClass,
    sortByLatestAssessmentScore: sortBy.latestAssessmentScore
  };

  const {
    filterQuery,
    classFilteredList,
    setClassFilteredList,
    groupFilteredList,
    setGroupFilteredList,
    teacherFilteredList,
    setTeacherFilteredList,
    assessorFilteredList,
    setAssessorFilteredList
  } = useClientListingFilter();

  const { clientRecordList, isClientRecordListLoading, fetchClientsRecord } = useFetchClientsRecord(
    token,
    {
      ...filterValue,
      additionalQuery: filterQuery
    },
    true
  );
  const { clientsCount, isClientsCountLoading, fetchClientsCount } = useFetchClientsRecordCount(token, {
    ...filterValue,
    additionalQuery: filterQuery
  });

  useEffect(() => {
    if (selectedStudentIds.length > 0) {
      setSelectedStudentIds([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPath]);

  const onSelectStudent = (studentId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedStudentIds([...selectedStudentIds, studentId]);
    } else {
      setSelectedStudentIds(selectedStudentIds.filter((id) => id !== studentId));
    }
  };

  const onChangeTabFilter = async (tabValue: TabIds['tabId']) => {
    if (tabValue !== filterType) {
      setPageLoading(true);
      setFilterType(tabValue);
      const newQParam = {
        ...(filterValue.q && {
          q: filterValue.q
        }),
        page: 1
      };
      const qParam = queryString.stringify(newQParam);
      navigate(`${CLIENTS.BASE}/${tabValue}${qParam ? `?${qParam}` : ''}`);

      const option = {
        perPage: filterValue.perPage,
        page: 1,
        status: tabValue,
        q: filterValue.q,
        sortByClientName: filterValue.sortByClientName || 1,
        sortByClass: filterValue.sortByClass,
        sortByLatestAssessmentScore: filterValue.sortByLatestAssessmentScore,
        additionalQuery: filterQuery
      };

      await fetchClientsRecord(token, option);
      setPageLoading(false);
    }
  };

  const onPageChange = async (page: string, sortBy?: SortBy) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.q && {
        q: filterValue.q
      }),
      page
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${CLIENTS.BASE}/${filterType}${qParam ? `?${qParam}` : ''}`);
    const option = {
      perPage: filterValue.perPage,
      page: Number.parseInt(page, 10),
      status: filterType as ClientStatus['status'],
      q: searchVal,
      sortByClientName: sortBy?.clientName || 1,
      sortByClientSince: sortBy?.clientSince,
      sortByClass: sortBy?.studentClass,
      sortByLatestAssessmentScore: sortBy?.latestAssessmentScore,
      additionalQuery: filterQuery
    };
    await fetchClientsRecord(token, option);
    setPageLoading(false);
  };

  const onProspectStatusChange = async (prospectStatus: ProspectStatus) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.q && {
        q: filterValue.q
      }),
      page: 1,
      ...(prospectStatus === 'closed' && {
        prospectStatus
      })
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${CLIENTS.BASE}/${filterType}${qParam ? `?${qParam}` : ''}`);
    const option = {
      perPage: filterValue.perPage,
      page: 1,
      status: filterType as ClientStatus['status'],
      q: searchVal,
      prospectStatus: prospectStatus
    };
    await fetchClientsRecord(token, option);
    setPageLoading(false);
  };

  const onSearch = async (q: string) => {
    setPageLoading(true);
    const newQParam = {
      page: 1,
      q,
      ...(filterValue.prospectStatus === 'closed' && {
        prospectStatus: filterValue.prospectStatus
      })
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${CLIENTS.BASE}/${filterType}${qParam ? `?${qParam}` : ''}`);
    const option = {
      perPage: filterValue.perPage,
      page: 1,
      status: filterType as ClientStatus['status'],
      q,
      sortByClientName: sortBy?.clientName || 1,
      sortByClientSince: sortBy?.clientSince,
      sortByClass: sortBy?.studentClass,
      sortByLatestAssessmentScore: sortBy?.latestAssessmentScore,
      additionalQuery: filterQuery,
      prospectStatus: filterValue.prospectStatus
    };
    setSearchVal(q);
    await fetchClientsRecord(token, option);
    await fetchClientsCount(token, option);
    setPageLoading(false);
  };

  const onResendInvitation = async (recordId: string) => {
    if (token) {
      try {
        setRecordIdProcessing(recordId);
        await putResendInvitation(token, recordId);
        const newFilterValue = {
          status: 'active' as ClientStatus['status'],
          perPage: filterValue.perPage,
          page: queryParam.page || 1,
          q: ''
        };

        await fetchClientsRecord(token, newFilterValue);
        notification.success({
          message: t('form.success.signup_invitation'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setRecordIdProcessing('');
      } catch (ex) {
        if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
          notification.destroy();
          notification.error({
            message: t('form.error.signup_invitation_limited_communication'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else {
          console.error(ex);
          notification.error({ message: t('form.error.signup_invitation') });
        }
        setRecordIdProcessing('');
      }
    }
  };

  const onCancelInvitation = async (recordId: string) => {
    if (token) {
      try {
        setRecordIdProcessing(recordId);
        await putCancelInvitation(token, recordId);
        const newFilterValue = {
          status: 'active' as ClientStatus['status'],
          perPage: filterValue.perPage,
          page: queryParam.page || 1,
          q: ''
        };
        await fetchClientsRecord(token, newFilterValue);
        notification.success({
          message: 'Signup invitation is cancelled',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setRecordIdProcessing('');
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to cancel this signup invitation' });
        setRecordIdProcessing('');
      }
    }
  };

  const handleContentUpdate = () => {
    fetchClientsRecord(token, filterValue);
    fetchClientsCount(token, filterValue);
  };

  return (
    <HelmetWrapper title={t('label.tacklit_clients')}>
      <ContentLayout>
        <div className={styles.container}>
          <PatientHeader
            total={clientsCount[filterType ? filterType : 'active'] || 0}
            isLoading={isClientRecordListLoading || isClientsCountLoading}
            currentTabFilter={filterType}
            onAddClientClick={() => setShowAddClient(true)}
            onAddProspectClient={() => setShowAddProspect(true)}
          />
          <ClientListContent
            clientRecordList={clientRecordList}
            token={token}
            isClientRecordListLoading={isClientRecordListLoading}
            clientsCount={clientsCount}
            onChangeTabFilter={onChangeTabFilter}
            onResendInvitation={onResendInvitation}
            onCancelInvitation={onCancelInvitation}
            recordIdProcessing={recordIdProcessing}
            onPageChange={onPageChange}
            onSearch={onSearch}
            isPageLoading={pageLoading}
            isCountLoading={isClientsCountLoading}
            handleContentUpdate={handleContentUpdate}
            handleProspectStatus={onProspectStatusChange}
            selectedStudentIds={selectedStudentIds}
            onSelectStudent={onSelectStudent}
            onChangeTeacherBulkClick={() => setShowChangeTeacherBulk(true)}
            onChangeWellBeingLeaderBulkClick={() => setShowAssignWellBeingLeaderBulk(true)}
            classFilteredList={classFilteredList}
            setClassFilteredList={setClassFilteredList}
            groupFilteredList={groupFilteredList}
            setGroupFilteredList={setGroupFilteredList}
            teacherFilteredList={teacherFilteredList}
            setTeacherFilteredList={setTeacherFilteredList}
            assessorFilteredList={assessorFilteredList}
            setAssessorFilteredList={setAssessorFilteredList}
          />
        </div>
        <AddPatientModalV2
          visible={showAddClient}
          onCancel={(isUpdated: boolean) => {
            setShowAddClient(false);
            fetchClientsRecord(token, filterValue);
            if (isUpdated) {
              handleContentUpdate();
              fetchClientsRecord(token, filterValue);
            }
          }}
          onResetForm={() => {
            fetchClientsRecord(token, filterValue);
          }}
        />
        <AddProspectClient
          visible={showAddProspect}
          onCancel={(isUpdated: boolean) => {
            setShowAddProspect(false);
            fetchClientsRecord(token, filterValue);
            if (isUpdated) {
              handleContentUpdate();
            }
          }}
        />
        <ChangeTeacherBulk
          selectedStudentIds={selectedStudentIds}
          visible={showChangeTeacherBulk}
          onCancel={() => {
            setShowChangeTeacherBulk(false);
          }}
          onUpdateSuccess={() => {
            setSelectedStudentIds([]);
            fetchClientsRecord(token, filterValue);
          }}
        />
        {isEdgeAdminView && (
          <ChangeWellBeingLeaderBulk
            selectedStudentIds={selectedStudentIds}
            visible={showAssignWellBeingLeaderBulk}
            onCancel={() => {
              setShowAssignWellBeingLeaderBulk(false);
            }}
            onUpdateSuccess={() => {
              setSelectedStudentIds([]);
              fetchClientsRecord(token, filterValue);
            }}
          />
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PatientPage;
