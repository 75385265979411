import { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/v2/Button/Button';
import { useGetAccessToken } from 'utils/hooks/token';
import { getInvoiceSettingsPaymentMethodsReady } from 'utils/http/BillingService/Invoice/invoice';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import PatientDetailsContent from './components/PatientDetailsContent/PatientDetailsContent';
import PatientDetailsHeader from './components/PatientDetailsHeader/PatientDetailsHeader';

import styles from './PatientDetails.module.scss';
import { useFetchClientRecordById } from './hooks/GetClientRecord';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { RecordStatus } from 'interfaces/Clients/clientsRecord';
import { useRoutesGenerator } from '../../utils/hooks/Path/RoutesGenerator';

const useFetchInvoiceSettingsPaymentMethodsReady = (token: string) => {
  const [invoiceSettingsPaymentMethodsReady, setInvoiceSettingsPaymentMethodsReady] = useState<boolean>(false);
  const [isInvoiceSettingsPaymentMethodsReadyLoading, setIsInvoiceSettingsPaymentMethodsReadyLoading] = useState(true);
  const { isEdgeAdminView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const fetchInvoiceSettingsPaymentMethodsReady = async (token: string) => {
    try {
      if (isEdgeAdminView || isNormalUserView || isEdgeReceptionist) {
        const callInvoiceSettingsPaymentMethodsReady = await getInvoiceSettingsPaymentMethodsReady(token);

        const { ready } = await callInvoiceSettingsPaymentMethodsReady.json();

        setInvoiceSettingsPaymentMethodsReady(ready);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your payment methods status.' });
    }

    setIsInvoiceSettingsPaymentMethodsReadyLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchInvoiceSettingsPaymentMethodsReady(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { invoiceSettingsPaymentMethodsReady, isInvoiceSettingsPaymentMethodsReadyLoading };
};

const PatientDetails = () => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const path = useParams<{ recordId: string; patientTab: string; assessmentOrReportOrPlanId?: string }>();

  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const { clientRecordData, isClientRecordLoading, fetchClientRecord, setClientRecordData } = useFetchClientRecordById(
    token,
    path.recordId,
    accountId
  );
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();

  const { invoiceSettingsPaymentMethodsReady, isInvoiceSettingsPaymentMethodsReadyLoading } =
    useFetchInvoiceSettingsPaymentMethodsReady(token);

  const handleNewActionPlan = () => {
    navigate(`${CLIENTS.BASE}/${path.recordId}/action-plans/new`);
  };

  return (
    <HelmetWrapper title={'CORDS - Student Details'}>
      <ContentLayout className={styles.container}>
        <div className={styles.container}>
          <PatientDetailsHeader
            currentTab={path.patientTab ?? ''}
            clientRecordId={path.recordId ?? ''}
            clientRecordData={clientRecordData}
            invoiceSettingsPaymentMethodsReady={invoiceSettingsPaymentMethodsReady}
            isInvoiceSettingsPaymentMethodsReadyLoading={isInvoiceSettingsPaymentMethodsReadyLoading}
            isClientRecordLoading={isClientRecordLoading}
            setClientRecordData={setClientRecordData}
            allowCreateNewInvoice={isEdgeAdminView || isNormalUserView || isEdgeReceptionist}
            allowSubmitPsychometric={isEdgeAdminView || isEdgeUserView}
          />
          {isClientRecordLoading ? (
            <div className={styles.loadingWrapper}>
              <div className={styles.sideBarLoading}>
                <Skeleton active />
              </div>
              <div className={styles.contentLoading}>
                <Skeleton active />
              </div>
            </div>
          ) : (
            <PatientDetailsContent
              recordId={path.recordId!}
              profileId={clientRecordData.clientProfiles[0]._id}
              isClientLoading={isClientRecordLoading}
              clientRecordData={clientRecordData}
              currentTab={path.patientTab!}
              assessmentOrReportOrPlanId={path.assessmentOrReportOrPlanId}
              allowCreateNewInvoice={isEdgeAdminView || isNormalUserView}
              fetchClientRecord={fetchClientRecord}
            />
          )}
          {path.patientTab !== 'notes' && path.patientTab !== 'action-plans' && (
            <Button
              className={styles.createActionPlanBtn}
              disabled={clientRecordData.recordStatus === RecordStatus.Closed}
              onClick={handleNewActionPlan}
              id={'addActionPlanBtnId'}
            >
              <i className={`material-icons ${styles.addIcon}`}>add_circle_outline</i>
              CREATE ACTION PLAN
            </Button>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PatientDetails;
