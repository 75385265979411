import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import { postCaseNoteTemplate, updateCaseNoteTemplate } from 'utils/http/DocumentService/CaseNotes/caseNotesTemplate';
import { CaseNoteTemplate } from '../interface';

export const usePathQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useCaseNoteTemplateSubmit = () => {
  const { token } = useGetAccessToken();

  const createTemplate = async (template: CaseNoteTemplate) => {
    const id = (await (await postCaseNoteTemplate(token, template)).json()) as string;
    return id;
  };

  const updateTemplate = async (caseNoteTemplateId: string, template: CaseNoteTemplate) => {
    await updateCaseNoteTemplate(token, caseNoteTemplateId, template);
  };

  return { createTemplate, updateTemplate };
};

export default useCaseNoteTemplateSubmit;
