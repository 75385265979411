import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { getAppointmentsByClientRecordIds } from 'utils/http/appointment';
import { clientListFilterParameter, GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { appointmentByClientRecordId, AppointmentDocument } from 'interfaces/Clients/Appointment';
import moment from 'moment';
import { sortBy } from 'lodash';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import queryString from 'query-string';
import { getClinicalAssessmentTemplate } from '../../../../../../../store/CheckIn/GetClinicalAssessmentTemplate/action';
import { massageSQDSequence } from '../../../../../../../components/ClientOrGroupPickAssessment/getAssessmentList';

export const useFetchClientsRecord = (token: string, option: clientListFilterParameter) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [clientRecordList, setClientRecordList] = useState<GetClientProfileInterface>({
    clientRecords: [],
    recordStatusCounts: {
      active: 0,
      closed: 0
    },
    paging: {
      page: 1,
      perPage: 10,
      totalItem: 10,
      totalPage: 1
    }
  } as GetClientProfileInterface);
  const [isClientRecordListLoading, setIsClientRecordListLoading] = useState(true);

  const fetchClientsRecord = async (token: string, option: clientListFilterParameter) => {
    try {
      const queryParam = {
        perPage: option.perPage,
        page: option.page,
        recordStatus: option.status === 'prospect' ? option.prospectStatus ?? 'prospect' : option.status,
        q: option.q,
        isMHH: option.isMHH,
        profileType: option.status === 'prospect' ? 'prospect' : 'full,recordOnly',
        sortByClientName: option.sortByClientName,
        sortByClientSince: option.sortByClientSince
      };

      const qParam = queryString.stringify(queryParam);
      const qString = qParam ? `?${qParam}` : '';

      const assessmentTemplateList = await getClinicalAssessmentTemplate(token);
      const assessmentList = massageSQDSequence(assessmentTemplateList);

      if ((isEdgeAdminView || isEdgeReceptionist) && accountId) {
        const clientsData = await getClientsRecordByAccountId(token, accountId, qString);
        const clientsRecordData: GetClientProfileInterface = await clientsData.json();

        const massageClientsRecordData = {
          ...clientsRecordData,
          clientRecords: clientsRecordData.clientRecords.map((obj) => ({
            ...obj,
            assessment: {
              ...obj.assessment,
              name:
                assessmentList.find((assessmentObj) => assessmentObj.id === obj.assessment?.name)?.metadata?.cords
                  ?.shortTitle || obj.assessment?.name
            }
          }))
        } as GetClientProfileInterface;

        const clientRecordIds = massageClientsRecordData.clientRecords
          .map((clientListObj) => clientListObj._id)
          .join(',');

        const appointmentsData = await getAppointmentsByClientRecordIds(
          accountId,
          '2020-01-01',
          moment(new Date()).add(3, 'months').format('YYYY-MM-DD'),
          token,
          clientRecordIds
        );

        const appointmentsByClientRecordId: appointmentByClientRecordId[] = await appointmentsData.json();

        for (const key in massageClientsRecordData.clientRecords) {
          const matchedData = appointmentsByClientRecordId.find(
            (appointment) => appointment._id === massageClientsRecordData.clientRecords[key]._id
          );

          if (matchedData) {
            massageClientsRecordData.clientRecords[key].appointmentStatistics = massagePatientAppointments(
              matchedData.totalAppointments
            );
          } else {
            massageClientsRecordData.clientRecords[key].appointmentStatistics = {
              completedAppointmentsTotal: 0,
              upcomingAppointmentsTotal: 0
            };
          }
        }

        setClientRecordList(massageClientsRecordData);
      } else {
        const clientsData = await getClientsRecord(token, qString);
        const clientsRecordData: GetClientProfileInterface = await clientsData.json();

        const massageClientsRecordData = {
          ...clientsRecordData,
          clientRecords: clientsRecordData.clientRecords.map((obj) => ({
            ...obj,
            assessment: {
              ...obj.assessment,
              name:
                assessmentList.find((assessmentObj) => assessmentObj.id === obj.assessment?.name)?.metadata?.cords
                  ?.shortTitle || obj.assessment?.name
            }
          }))
        } as GetClientProfileInterface;

        setClientRecordList(massageClientsRecordData);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get your student list' });
    }

    setIsClientRecordListLoading(false);
  };

  useEffect(() => {
    if (token) {
      setIsClientRecordListLoading(true);
      fetchClientsRecord(token, option);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEdgeAdminView]);

  return { clientRecordList, isClientRecordListLoading, fetchClientsRecord };
};

const massagePatientAppointments = (
  patientAppointments: AppointmentDocument[]
): {
  upcomingAppointmentsTotal: number;
  upcomingAppointments: AppointmentDocument[];
  completedAppointmentsTotal: number;
  completedAppointments: {
    month: string;
    appointments: (AppointmentDocument & { appointmentNo: number; month: string })[];
  }[];
} => {
  const upcomingAppointments = sortBy(
    patientAppointments.filter(
      (patientAppt) => moment(new Date()) <= moment(patientAppt.date + ' ' + patientAppt.startTime)
    ),
    (it) => `${it.date} ${it.startTime}`
  );

  const completedAppointments = patientAppointments
    .filter((patientAppt) => moment(new Date()) >= moment(patientAppt.date + ' ' + patientAppt.startTime))
    .sort((apptA, apptB) => moment(apptB.date + ' ' + apptB.startTime).diff(moment(apptA.date + ' ' + apptA.startTime)))
    .map((completedAppt, index, array) => {
      return {
        ...completedAppt,
        appointmentNo: array.length - index,
        month: moment(completedAppt.date).format('MMMM YYYY')
      };
    });

  const months = [...Array.from(new Set(completedAppointments.map((compAppt) => compAppt.month).values()))];

  const completedAppointmentsGroupedByMonth = months.map((month) => ({
    month,
    appointments: completedAppointments.filter((compAppt) => compAppt.month === month)
  }));

  return {
    upcomingAppointmentsTotal: upcomingAppointments.length,
    upcomingAppointments,
    completedAppointmentsTotal: completedAppointments.length,
    completedAppointments: completedAppointmentsGroupedByMonth
  };
};
