import { Helmet } from 'react-helmet';

interface HelmetWrapperProps {
  title?: string;
  children?: any;
}

const HelmetWrapper = ({ children, title }: HelmetWrapperProps) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default HelmetWrapper;
