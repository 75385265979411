import { useMemo } from 'react';
import moment from 'moment';

import styles from './InsightsChartWidget.module.scss';
import InsightsLineChart from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsInsights/components/InsightsLineChart/InsightsLineChart';
import CardHeader from '../../../../PatientDetails/components/PatientDetailsContent/components/PatientDetailsInsights/components/CardHeader/CardHeader';

interface InsightsChartWidgetProps {
  data: any;
  isPreview?: boolean;
  onDateRangeChange?: (dateRange: number) => void;
}

const InsightsChartWidget = ({ data, onDateRangeChange, isPreview }: InsightsChartWidgetProps) => {
  const isOverallCheckInGraphLoading = false;

  const handleSelectDateRange = (dateRange: number) => {
    onDateRangeChange?.(dateRange);
  };

  const graphData = useMemo(() => {
    const { graphFilter, graphData } = data;

    const startDate = moment().subtract(graphFilter, 'days').format('YYYY-MM-DD');

    return graphData.filter((datum: any) => datum.date >= startDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container}>
      <CardHeader
        defaultDateRange={data.graphFilter}
        isHideFilter={isPreview}
        title={data.title}
        onSelectDateRange={handleSelectDateRange}
      />
      <InsightsLineChart
        data={graphData}
        yDomain={[1, 11]}
        yTicks={[1, 6, 11]}
        isLoading={isOverallCheckInGraphLoading}
        height={200}
      />
    </div>
  );
};

export default InsightsChartWidget;
