import ModalV2 from 'components/ModalV2/ModalV2';
import React, { useState } from 'react';
import styles from './SubmitAssessmentModal.module.scss';
import { notification } from 'antd';
import { QuestionResponses } from 'pages/AssessorAssessments/types';
import Assessment from 'components/PatientQuestionForm/components/Assessment/Assessment';
import Button from 'components/v2/Button/Button';
import { useGetAccessToken } from 'utils/hooks/token';
import { putCompleteStudentPendingAssessment } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { formatAssessmentResponse } from 'utils/CORDS/formatAssessmentResponse';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

interface Props {
  assessmentData: QuestionResponses | null; // if null === close modal
  onModalClose: () => void;
  postCompleteAssessmentAction?: () => void | Promise<void>;
}
const SubmitAssessmentModal = ({ assessmentData, onModalClose, postCompleteAssessmentAction }: Props) => {
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = async (assessmentId: string, values: { [key: string]: Record<string, any> }) => {
    setSubmitStatus('active');

    try {
      if (assessmentData && token) {
        await putCompleteStudentPendingAssessment(token, auth0ClinicianId, assessmentData._id, {
          status: 'done',
          assessmentId,
          responses: formatAssessmentResponse({ values, assessmentId })
        });
        setSubmitStatus('finished');
        notification.success({ message: 'Assessment response is submitted' });
        postCompleteAssessmentAction?.();
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to submit this assessment' });
    }

    setSubmitStatus('');
    onModalClose();

    return undefined;
  };

  return (
    <ModalV2
      containerClassName={styles.container}
      isModalOpen={!!assessmentData}
      title={'Submit Assessment'}
      onModalClose={onModalClose}
    >
      {assessmentData?.assessment.clinicalAssessment ? (
        <Assessment
          assessment={assessmentData.assessment.clinicalAssessment}
          completedQuestions={0}
          submitBtnText={
            <Button status={submitStatus} className={styles.submitButton}>
              Submit
            </Button>
          }
          submitBtnClass={styles.submitButtonContainer}
          onSubmit={handleSubmit}
          reverseStemAndHideTitle={false}
          isSkippable={false}
          isFirstQuestionSet={false}
          header={() => <></>}
          onBack={() => null}
          onSaveAndExit={() => null}
          onSkip={() => null}
          disableFullscreenLoading
        />
      ) : (
        <div>Selected assessment not found</div>
      )}
    </ModalV2>
  );
};

export default SubmitAssessmentModal;
