import ActivityFeed from './components/ActivityFeed/ActivityFeed';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import styles from './PatientDetailsActivity.module.scss';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ProfileInterface } from 'interfaces/Profile/Profile';

interface PatientDetailsActivityProps {
  clientRecordData: clientRecordsInterface;
  isClientRecordLoading: boolean;
  profile: ProfileInterface;
  isProfileLoading: boolean;
  refreshActivity?: number;
}

const PatientDetailsActivity = ({
  clientRecordData,
  isClientRecordLoading,
  refreshActivity = 0,
  profile,
  isProfileLoading
}: PatientDetailsActivityProps) => {
  return (
    <div className={styles.container}>
      {isClientRecordLoading || isProfileLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <ActivityFeed clientRecordData={clientRecordData} refreshActivity={refreshActivity} profile={profile} />
      )}
    </div>
  );
};

export default PatientDetailsActivity;
