import { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import styles from './ClientForm.module.scss';
import { ClientDetails } from '../../../../AddPatientModalV2Interface';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { ClientFormProps, ClientFormPropsErrorInterface } from '../../interface';
import { validationClientFormField } from '../../validation/ClientFormValidation';
import CheckBox from 'components/CheckBox/CheckBox';
import _ from 'lodash';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { checkPhoneNumber } from 'utils/http/NotifService/phoneValidator';
import { validateEmail } from 'components/AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { COMMUNICATION_OPTIONS } from 'components/AddPatientModalV2/constants';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

const ClientForm = (props: ClientFormProps) => {
  const {
    id,
    checkValidation,
    clientDetail,
    isFirstClient,
    onChangeClientField,
    other,
    disableEmailField,
    capabilityData
  } = props;
  const { token } = useGetAccessToken();
  const [clientField, setClientField] = useState(clientDetail);
  const [otherClient, setOtherClient] = useState(other);
  const [oldData, setOldData] = useState(clientDetail);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  } as ClientFormPropsErrorInterface);

  const [isEmailInUse, setIsEmailInUse] = useState(false);
  const [isCheckingEmailInUse, setIsCheckingEmailInUse] = useState(false);

  const [invalidPhone, setInvalidPhone] = useState(false);
  const [isCheckingPhone, setIsCheckingPhone] = useState(false);

  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isDuplicatePhone, setIsDuplicatePhone] = useState(false);

  useEffect(() => {
    if (checkValidation) {
      const validate = validateField(clientField);

      if (validate?.email === '' && clientField.email && oldData.email !== clientField.email) {
        validateDuplicateEmail(clientField.email);
      }
      if (validate?.mobileNumber === '' && clientField.mobileNumber && clientField.mobileNumber?.length > 3) {
        validatePhoneNumber(clientField.mobileNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation, capabilityData]);

  useEffect(() => {
    if (!_.isEmpty(other)) {
      setOtherClient(other);
      if (clientField?.email && clientField.email !== other.email && isDuplicateEmail) {
        setIsDuplicateEmail(false);
      }
      if (clientField.mobileNumber !== other.mobileNumber && isDuplicatePhone) {
        setIsDuplicatePhone(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);

  // Get client Detail before edit
  useEffect(() => {
    if (!_.isEmpty(clientDetail)) {
      setOldData(clientDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateField = (clientVal: ClientDetails) => {
    const validate = validationClientFormField({
      ...clientVal,
      isFirstClient: isFirstClient,
      profileType: capabilityData?.profileType
    });
    setErrorMessage(validate as ClientFormPropsErrorInterface);
    return validate;
  };

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientField,
      [key]: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validateField(newClientField);
    }
  };

  const debouncedCheckDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateEmail(token, value);
        setIsEmailInUse(duplicate.statusCode !== 200);
        setIsCheckingEmailInUse(false);
      }, 1000),
    [token]
  );

  const validateDuplicateEmail = async (emailValue: string) => {
    if (token) {
      setIsCheckingEmailInUse(true);
      await debouncedCheckDuplicate(emailValue);
    }
  };

  const handleChangeEmail = async (value: string) => {
    const newClientField = {
      ...clientField,
      email: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (value.length > 0 && value === otherClient.email) {
      setIsDuplicateEmail(true);
    } else {
      setIsDuplicateEmail(false);
    }
    if (checkValidation) {
      const validate = validateField(newClientField);
      if (_.isEmpty(validate?.email) && oldData.email !== value) {
        await validateDuplicateEmail(value);
      } else {
        setIsEmailInUse(false);
      }
    }
  };

  const debouncedValidatePhoneNumber = useMemo(
    () =>
      debounce(async (value) => {
        try {
          const duplicate = await checkPhoneNumber(value);
          setInvalidPhone(duplicate.statusCode !== 204);
          setIsCheckingPhone(false);
        } catch (e) {
          setInvalidPhone(true);
          setIsCheckingPhone(false);
        }
      }, 500),
    []
  );

  const validatePhoneNumber = async (value: string) => {
    setIsCheckingPhone(true);
    await debouncedValidatePhoneNumber(value);
  };

  const handleChangePhoneNumber = async (value: string) => {
    const phoneNumber = value.replace(/[^0-9+]+/g, '');
    const newClientField = {
      ...clientField,
      mobileNumber: phoneNumber
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (phoneNumber.length > 0 && phoneNumber === otherClient.mobileNumber) {
      setIsDuplicatePhone(true);
    } else {
      setIsDuplicatePhone(false);
    }
    if (checkValidation) {
      const validate = validateField(newClientField);
      if (validate?.mobileNumber === '' && value.length > 3) {
        await validatePhoneNumber(phoneNumber);
      } else {
        setInvalidPhone(false);
      }
    }
  };

  const onCommunicationPreferenceChange = (value: ClientDetails['communicationPreference']) => {
    const newClientField = {
      ...clientField,
      communicationPreference: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
  };

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`clientFirstName${id}`}
          label={`${t('form.client_first_name')}*`}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientField.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>

      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}>
        <MaterialInput
          id={`clientLastName${id}`}
          label={`${t('form.client_last_name')}*`}
          onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
          value={clientField.lastName}
          required
        />
        {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
      </div>

      <div className={styles.withCheckBoxContainer}>
        <div
          className={classnames(
            styles.fieldContainer,
            checkValidation && (errorMessage.email || isEmailInUse || isDuplicateEmail) && styles.fieldError
          )}
        >
          <MaterialInput
            id={`clientEmail${id}`}
            label={`${t('form.client_email')}${capabilityData?.profileType === 'full' && isFirstClient ? '*' : ''}`}
            onChange={(e) => handleChangeEmail(e.target.value)}
            isLoading={isCheckingEmailInUse}
            value={clientField.email}
            required
            disabled={disableEmailField}
          />
          {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
          {checkValidation && isEmailInUse && (
            <div className={styles.fieldError}>{t('form.error.client_email_in_use')}</div>
          )}
          {checkValidation && _.isEmpty(errorMessage.email) && !isEmailInUse && isDuplicateEmail && (
            <div className={styles.fieldError}>{t('form.error.duplicate_client_email')}</div>
          )}
        </div>
        {disableEmailField && (
          <div className={styles.disableMessageContainer}>
            <i className={`material-icons ${styles.lockIcon}`}>lock</i>
            <div className={styles.disabledMessage}>
              {t('form.error.client_verified')}
              <HelpOutLineWithTooltips
                id={'emailUpdateDisabled'}
                desc={t('form.error.client_verified.description')}
                iconClass={styles.helpIcon}
              />
            </div>
          </div>
        )}
      </div>

      <div className={styles.phoneContainer}>
        <MaterialPhoneInput
          id={`clientMobileNumber${id}`}
          label={`Mobile Number ${capabilityData?.profileType === 'full' && isFirstClient ? '*' : ''}`}
          onChange={(e: string) => handleChangePhoneNumber(e)}
          value={clientField.mobileNumber || ''}
          isLoading={isCheckingPhone}
          isError={checkValidation && (!!errorMessage.mobileNumber || invalidPhone || isDuplicatePhone)}
        />
        {checkValidation && errorMessage.mobileNumber && (
          <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
        )}
        {checkValidation && invalidPhone && <div className={styles.fieldError}>Please enter a valid mobile number</div>}
        {checkValidation && !invalidPhone && isDuplicatePhone && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_mobile_number_')}</div>
        )}
      </div>

      <div className={styles.primaryFieldContainer}>
        <CheckBox
          id={`isPrimaryContact${id}`}
          value={clientDetail.isPrimaryContact ? clientDetail.isPrimaryContact : false}
          disabled={isFirstClient}
          label={'Contact will receive messages, documents and appointment details for this profile'}
          onChange={(e) => handleChangeFieldValue('isPrimaryContact', e.target.checked)}
        />
      </div>

      <div className={classnames(styles.preferenceContainer, styles.fieldContainer)}>
        <MaterialSelect
          id={'clientCommunicationPreference'}
          label={'Communication Preference'}
          isSearchable={false}
          options={COMMUNICATION_OPTIONS}
          value={clientDetail.communicationPreference || CommunicationPreference.NoPreference}
          onChange={onCommunicationPreferenceChange}
        />
        {clientDetail.communicationPreference === 'none' && (
          <div className={styles.noneComms}>{t('form.error.client_no_digital_message')}</div>
        )}
      </div>
    </div>
  );
};

export default ClientForm;
