import { useState } from 'react';
import styles from './FinderWizardModal.module.scss';

import { Dropdown, Modal, Menu } from 'antd';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import {
  APPOINTMENT_TYPES,
  OCCUPANCY_OPTIONS,
  PRACTITIONER_RESULT_TEMP,
  ROOM_RESULT_TEMP,
  SORTED_BY_OPTIONS,
  DURATION_TIME_OPTIONS,
  DAY_OPTIONS,
  TIME_OPTIONS,
  TIME_RANGE_SLIDER
} from './constants';
import FinderWizardSelect from './components/FinderWizardSelect/FinderWizardSelect';
import FinderWizardMultipleSelect from './components/FinderWizardMultipleSelect/FinderWizardMultipleSelect';
import FinderWizardRecord from './components/FinderWizardRecord/FinderWizardRecord';

import { useTranslation } from 'react-i18next';

interface FinderWizardModalProps {
  visible: boolean;
  onClose: () => void;
}

const FinderWizardModal = (props: FinderWizardModalProps) => {
  const { visible, onClose } = props;

  const [sortedBy, setSortedBy] = useState(SORTED_BY_OPTIONS[1]);
  const [isFilterByPractitioner, setIsFilterByPractitioner] = useState(true);
  const [listTransformX, setListTransformX] = useState(-160);

  const onScrollToRight = () => {
    if (listTransformX > TIME_RANGE_SLIDER.BEHIND_BOUNDER) {
      setListTransformX(listTransformX - TIME_RANGE_SLIDER.STEP);
    }
  };

  const onScrollToLeft = () => {
    if (listTransformX < TIME_RANGE_SLIDER.FRONT_BOUNDER) {
      setListTransformX(listTransformX + TIME_RANGE_SLIDER.STEP);
    }
  };

  const onChangeObjectTypeFilter = () => {
    setIsFilterByPractitioner(!isFilterByPractitioner);
  };

  const onSelectSortedBy = (selectedValue: string) => {
    setSortedBy(SORTED_BY_OPTIONS.find((option) => option.value === selectedValue) || SORTED_BY_OPTIONS[1]);
  };
  const [t] = useTranslation();

  return (
    <Modal
      bodyStyle={{ padding: 0, borderRadius: '25px' }}
      className={styles.modalContainer}
      visible={visible}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.mainTitle}>Finder Wizard</div>
            <div className={styles.subTitle}>Quickly and easily find the next available slot</div>
          </div>
          <button onClick={onClose} className={styles.closeButton}>
            <span className="material-icons-outlined">close</span>
          </button>
        </div>

        <div className={styles.body}>
          <div className={styles.bodyTitle}>Find an available</div>
          <div className={styles.filterSection}>
            <div className={styles.objectTypeFilter}>
              <ToggleSwitch
                id={'objectTypeFilter'}
                checkLabel={'ROOM'}
                buttonWidth={276}
                unCheckLabel={t('label.practitioner')}
                isCheckedProps={!isFilterByPractitioner}
                onChangeProps={() => {
                  onChangeObjectTypeFilter();
                }}
              />
            </div>
            {/* Dynamic filter by Finder Object */}
            {isFilterByPractitioner ? (
              <div className={styles.appointmentTypeFilter}>
                <FinderWizardSelect
                  label={'Appointment Type'}
                  defaultOptionProps={APPOINTMENT_TYPES[0]}
                  options={APPOINTMENT_TYPES}
                />
              </div>
            ) : (
              <>
                <div className={styles.occupancyFilter}>
                  <FinderWizardSelect
                    label={'Occupancy'}
                    defaultOptionProps={OCCUPANCY_OPTIONS[0]}
                    options={OCCUPANCY_OPTIONS}
                  />
                </div>
                <div className={styles.durationFilter}>
                  <FinderWizardSelect
                    label={'Duration (mins)'}
                    defaultOptionProps={DURATION_TIME_OPTIONS[0]}
                    options={DURATION_TIME_OPTIONS}
                  />
                </div>
              </>
            )}
            <div className={`${isFilterByPractitioner ? styles.dayOrTimeFilter : styles.dayFilter}`}>
              <FinderWizardMultipleSelect
                label={'Filter day or time?'}
                isShowTime={isFilterByPractitioner}
                daySelectedProp={[...DAY_OPTIONS]}
                timeSelectedProp={[...TIME_OPTIONS]}
                onChangeDayProp={() => {}}
              />
            </div>
          </div>
          <div className={styles.result}>
            <div className={styles.resultFilter}>
              <div className={styles.sortedBy}>
                Sorted by:
                <Dropdown
                  overlay={
                    <Menu onClick={(event) => onSelectSortedBy(String(event.key))}>
                      {SORTED_BY_OPTIONS.map((option) => (
                        <Menu.Item
                          key={option.value}
                          className={option.value === sortedBy.value ? styles.selectedSortedBy : ''}
                        >
                          {option.label}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <div className={styles.dropdownText}>
                    {sortedBy.label}
                    <i className={`material-icons-outlined ${styles.sortedByIcon}`}>arrow_drop_down</i>
                  </div>
                </Dropdown>
              </div>

              {!isFilterByPractitioner && (
                <div className={styles.timeRangeControlContainer}>
                  <div
                    className={`${styles.leftControl} 
                    ${listTransformX === TIME_RANGE_SLIDER.FRONT_BOUNDER ? styles.disabled : ''}`}
                    onClick={onScrollToLeft}
                  >
                    <span className="material-icons-outlined">keyboard_arrow_left</span>
                    EARLIER
                  </div>
                  <div className={styles.timeRange}>
                    <div
                      style={{ transform: `translate3d(${listTransformX}px, 0, 0)` }}
                      className={styles.timeRangeSlider}
                    >
                      {/* Show 5AM to 09PM - Default 9AM - 5PM*/}
                      {Array(17)
                        .fill(null)
                        .map((item, index) => {
                          index = index + 5;
                          return (
                            <div key={`times-${index}`} className={styles.time}>
                              {index < 12 ? `${index}AM` : index === 12 ? `${index}PM` : `${index - 12}PM`}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    className={`${styles.rightControl} 
                    ${listTransformX === TIME_RANGE_SLIDER.BEHIND_BOUNDER ? styles.disabled : ''}`}
                    onClick={onScrollToRight}
                  >
                    <span className="material-icons-outlined">keyboard_arrow_right</span>
                    LATER
                  </div>
                </div>
              )}
            </div>

            <div className={styles.resultTable}>
              {/* TODO: Replaced by real data */}
              {isFilterByPractitioner
                ? PRACTITIONER_RESULT_TEMP.map(
                    (record, index) =>
                      record && (
                        <FinderWizardRecord
                          key={`record-${index}`}
                          isFilterByPractitioner={isFilterByPractitioner}
                          listTransformX={listTransformX}
                          record={record}
                        />
                      )
                  )
                : ROOM_RESULT_TEMP.map(
                    (record, index) =>
                      record && (
                        <FinderWizardRecord
                          key={`record-${index}`}
                          isFilterByPractitioner={isFilterByPractitioner}
                          listTransformX={listTransformX}
                          record={record}
                        />
                      )
                  )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FinderWizardModal;
