import { Modal, notification } from 'antd';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Location } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateLocation } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './EditLocationModal.module.scss';

interface EditLocationModalProps {
  visible: boolean;
  location: Location;
  setShowEditLocationModal: (bool: boolean) => void;
  refetchMedicareLocations: () => void;
}

const EditLocationModal = ({
  visible,
  location,
  setShowEditLocationModal,
  refetchMedicareLocations
}: EditLocationModalProps) => {
  const { token } = useGetAccessToken();
  const [locationName, setLocationName] = useState(location.name);
  const [address, setAddress] = useState(location.address ?? '');
  const [locationNameValidationError, setLocationNameValidationError] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');

  const handleCloseModal = () => {
    setShowEditLocationModal(false);
  };

  const onChangeLocationName = (value: string) => {
    if (locationNameValidationError && value.length > 2 && value.length < 501) {
      setLocationNameValidationError(false);
    }
    setLocationName(value);
  };

  const onSubmit = async () => {
    setSubmitStatus('active');
    if (locationName === '' || locationName.length < 3 || locationName.length > 500) {
      setLocationNameValidationError(true);
      setSubmitStatus('');
      return;
    }
    try {
      await updateLocation(token, location.minorId, {
        name: locationName,
        address
      });

      notification.success({
        message: 'Location updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      refetchMedicareLocations();
      handleCloseModal();
      setSubmitStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to edit location' });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      visible={visible}
      closable
      title={<div className={styles.title}>Edit Location Details</div>}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.locationNameInput}>
            <MaterialInput
              id={'locationName'}
              label={'Location Name*'}
              value={locationName}
              onChange={(e) => onChangeLocationName(e.target.value)}
              required
            />
            {locationNameValidationError && (
              <div className={styles.error}>Please enter the location name (3 - 500 characters)</div>
            )}
          </div>
          <MaterialInput
            id={'locationAddres'}
            label={'Address'}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div className={styles.footer}>
          <Button className={styles.button} onClick={onSubmit} status={submitStatus}>
            Edit Location
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditLocationModal;
