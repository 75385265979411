import classNames from 'classnames';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { useState } from 'react';
import { getName } from 'utils/general';

import AvatarProfile from '../AvatarProfile/AvatarProfile';
import styles from './SharedPerson.module.scss';

interface SharedPersonProps {
  details: {
    _id: string;
    email?: string;
    isClient?: boolean;
    uniqueLink: string;
    sentAt: Date | string;
  };
  clientProfile: clientProfilesInterface;
  onClickRevokeAccess: (id: string, name?: string) => void;
}

const SharedPerson = ({ details: { _id, email, isClient }, clientProfile, onClickRevokeAccess }: SharedPersonProps) => {
  const [isRevoking, setIsRevoking] = useState(false);
  const clientName = getName(clientProfile);
  const handleRevokeAccess = async () => {
    setIsRevoking(true);
    await onClickRevokeAccess(_id, isClient ? clientName : email);
    setIsRevoking(false);
  };

  return (
    <div className={classNames(styles.container, isRevoking && styles.revoking)}>
      <div className={styles.personInfo}>
        {isClient ? (
          <AvatarProfile name={clientName} avatar={clientProfile.avatar} initials={clientProfile.initials} />
        ) : (
          <div className={styles.email}>{email}</div>
        )}
      </div>
      <div className={styles.revokeButton}>
        <div className={styles.revokeButtonLabel} onClick={handleRevokeAccess}>
          Revoke Access
        </div>
      </div>
    </div>
  );
};

export default SharedPerson;
