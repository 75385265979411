import classnames from 'classnames';
import { ActionPlanIntervention } from 'MHH/pages/ActionPlanCreator/interfaces';
import styles from './NoActionItem.module.scss';

interface NoActionItemProps {
  noAction: Required<ActionPlanIntervention>['noAction'];
  isSelected: boolean;
}

const NoActionItem = ({ noAction, isSelected }: NoActionItemProps) => {
  return (
    <div className={classnames(styles.viewContainer, isSelected && styles.selectedIntervention)}>
      <div className={styles.description}>
        <div className={styles.title}>Take no action</div>
        <div className={styles.reason}>Reason: {noAction.reason}</div>
      </div>
      <i className={`material-icons ${styles.chevronRightIcon}`}>chevron_right</i>
    </div>
  );
};

export default NoActionItem;
