import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, notification } from 'antd';
import mimetypes from 'mime-types';
import moment from 'moment';
import path from 'path';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Attachment } from '../../PatientDetailsAttachments';

import {
  deleteAttachmentByRecordId,
  downloadAttachmentByRecordId
} from 'utils/http/DocumentService/Attachments/attachments';

import Button from 'components/Button/Button';

import styles from './PatientDetailsAttachmentCard.module.scss';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

interface PatientDetailsAttachmentCardProps {
  attachment: Attachment;
  recordId: string;
  isReadOnly?: boolean;
  onDeleteAttachment: () => void;
}

const PatientDetailsAttachmentCard = ({
  attachment: { _id, caseNote, createdAt, title, thumbnail, clinicianId },
  recordId,
  isReadOnly,
  onDeleteAttachment
}: PatientDetailsAttachmentCardProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { CLIENTS } = useRoutesGenerator();

  const [isDeleting, setIsDeleting] = useState(false);

  const { auth0ClinicianId } = useGetClinicianId();

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      await deleteAttachmentByRecordId(token, recordId, _id);

      onDeleteAttachment();
    } catch (ex) {
      console.error(ex);

      setIsDeleting(false);

      notification.error({ message: 'Something went wrong while trying to delete this attachment.' });
    }
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this attachment?',
      onOk: handleDelete
    });
  };

  const handleDownloadAttachment = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const callGetAttachmentById = await downloadAttachmentByRecordId(token, recordId, _id);

      const attachment = await callGetAttachmentById.arrayBuffer();

      const documentBlob = new Blob([attachment], {
        type: mimetypes.lookup(title) || 'application/octet-stream'
      });

      const anchor = document.createElement('a');

      anchor.href = URL.createObjectURL(documentBlob);
      anchor.download = title;
      anchor.click();
      anchor.remove();
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.previewContainer}>
        {thumbnail ? (
          <img src={thumbnail} alt={'thumbnail'} />
        ) : (
          <span className={styles.fileType}>{path.extname(title).toUpperCase()}</span>
        )}
      </div>
      <Button className={styles.titleButton} variant="secondary" onClick={handleDownloadAttachment}>
        {title}
      </Button>
      <div className={styles.date}>Added {moment(createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
      {caseNote?._id && (
        <div className={styles.linked}>
          Linked to{' '}
          <Link className={styles.link} to={`${CLIENTS.BASE}/${recordId}/notes/${caseNote._id}`}>
            {caseNote.title}
          </Link>
        </div>
      )}
      {auth0ClinicianId === clinicianId && !isReadOnly ? (
        <Button className={styles.deleteButton} variant="secondary" disabled={isDeleting} onClick={handleClickDelete}>
          DELETE
        </Button>
      ) : (
        <i className={`material-icons-outlined ${styles.lockIcon}`}>lock</i>
      )}
    </div>
  );
};

export default PatientDetailsAttachmentCard;
