import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MEDICARE_ROLES_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileMedicare/constants';
import { PopulatedProvider } from 'pages/ControlPanel/Interfaces/Claimingcom';

import styles from './ProviderTable.module.scss';

interface ProviderTableProps {
  providers: PopulatedProvider[];
}

const getRoleLabel = (role: ClinicianMedicareRole) => MEDICARE_ROLES_OPTIONS.find(({ value }) => value === role)?.label;

const ProviderTable = ({ providers }: ProviderTableProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.headerItem}>NAME</div>
      <div className={styles.headerItem}>PROVIDER NAME</div>
      <div className={styles.headerItem}>PROVIDER ID</div>
      <div className={styles.headerItem}>LOCATION ID</div>
      <div className={styles.headerItem}>ROLE TYPE</div>
      <div className={styles.headerItem}>STATUS</div>
    </div>
    {providers.map(
      ({ clinicianName, name, providerNumber, locationMinorId, clinicianRole, active, locationActive }, index) => (
        <div key={index} className={styles.row}>
          <div className={styles.nameItem}>{clinicianName ?? '-'}</div>
          <div className={styles.nameItem}>{name}</div>
          <div className={styles.item}>{providerNumber}</div>
          <div className={styles.item}>{locationMinorId}</div>
          <div className={styles.item}>{clinicianRole ? getRoleLabel(clinicianRole) : '-'}</div>
          <div className={styles.item}>{active && locationActive ? 'Active' : 'Deactivated'}</div>
        </div>
      )
    )}
  </div>
);
export default ProviderTable;
