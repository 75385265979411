import { memo, useState } from 'react';
import { Modal, notification } from 'antd';
import AppointmentTypeForm from '../AppointmentTypeForm/AppointmentTypeForm';
import styles from './CreateAppointmentTypeModal.module.scss';
import AppointmentScheduleForm from '../AppointmentScheduleForm/AppointmentScheduleForm';
import AppointmentDeliveryForm from '../AppointmentDeliveryForm/AppointmentDeliveryForm';
import classNames from 'classnames';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { useGetAccessToken } from 'utils/hooks/token';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { postSessionType, putSessionType } from 'utils/http/sessionType';

interface CreateAppointmentProps {
  visible: boolean;
  data?: AppointmentType;
  onPost: (onSubmitAppointmentType: Partial<AppointmentType>) => void;
  onClose: (createdSessionType?: AppointmentType) => void;
}

const CreateAppointmentTypeModal = ({ visible, data, onPost, onClose }: CreateAppointmentProps) => {
  const { token } = useGetAccessToken();
  const [error, setError] = useState('');

  const totalSteps = 3;
  const title = [
    <div>{data ? 'Update' : 'Create a new'} appointment type</div>,
    <div>
      Set up <span className={styles.highlighted}>General</span> schedule and format
    </div>,
    <div>
      Set up <span className={styles.highlighted}>General</span> delivery mode
    </div>
  ];

  const [step, setStep] = useState<number>(1);
  const [values, setValues] = useState<Partial<AppointmentType>>();
  const [duration, setDuration] = useState<number>(data?.duration || 0);

  const onCancel = () => {
    setStep(1);
    onClose();
  };

  const handleBackButton = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onSubmit = (nextStep: number) => async (newValues: Partial<AppointmentType>) => {
    if (nextStep === 1) {
      setError('');
      const payload: Partial<AppointmentType> = {
        ...values,
        ...newValues,
        ...(newValues.participantType === ParticipantType.Group && { assignmentMode: undefined })
      };

      try {
        let message = '';
        if (data && data._id) {
          await putSessionType(data._id, payload, token);
          message = 'Appointment type updated.';
        } else {
          await postSessionType(payload, token);
          message = 'Appointment type created.';
        }
        notification.success({
          message,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        onClose();
        setStep(1);
        onPost(payload);
      } catch (err) {
        console.error(err);
        const errMess = data ? 'update' : 'create';
        notification.error({
          message: `Something went wrong while trying to ${errMess}.`
        });
        setError(errMess);
      }
    } else {
      setValues({
        ...values,
        ...newValues
      });
      if (newValues?.duration) {
        setDuration(newValues.duration);
      }
      setStep(nextStep);
    }
  };

  return (
    <Modal bodyStyle={{ padding: 0 }} width={720} visible={visible} footer={null} destroyOnClose closable={false}>
      <ModalHeader
        title={title[step - 1]}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={handleBackButton}
        onCancel={onCancel}
      />
      <div className={classNames(styles.formContainer, { [styles.visible]: step === 1 })}>
        <AppointmentTypeForm data={data} onSubmit={onSubmit(2)} />
      </div>
      <div className={classNames(styles.formContainer, { [styles.visible]: step === 2 })}>
        <AppointmentScheduleForm data={data} onSubmit={onSubmit(3)} duration={duration} formValues={values} />
      </div>
      <div className={classNames(styles.formContainer, { [styles.visible]: step === 3 })}>
        <AppointmentDeliveryForm data={data} errMess={error} onSubmit={onSubmit(1)} />
      </div>
    </Modal>
  );
};

export default memo(CreateAppointmentTypeModal);
