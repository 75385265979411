import styles from './ModalHeader.module.scss';

const ModalHeader = () => (
  <>
    <div id={'modalHeader'} />
    <div className={styles.title}>Calendar Connection</div>
  </>
);

export default ModalHeader;
