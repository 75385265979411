import styles from './PsychometricDetailHeader.module.scss';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { combineName } from 'utils/general';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';

interface PsychometricDetailHeaderProps {
  goBack: () => void;
  assessmentClientId: string;
  clientProfile: clientProfilesInterface[];
}

const PsychometricDetailHeader = ({ assessmentClientId, clientProfile, goBack }: PsychometricDetailHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.goBack} onClick={goBack}>
        <i className={`material-icons ${styles.icon}`}>chevron_left</i>
        Back
      </div>
      <div className={styles.info}>
        <ClientAvatar clientData={clientProfile} displayLimit={1} hideName />
        <div className={styles.names}>
          <div className={styles.profileName}>{combineName(clientProfile)}</div>
          <div className={styles.psychometricName}>{assessmentClientId}</div>
        </div>
      </div>
    </div>
  );
};

export default PsychometricDetailHeader;
