import { Modal, Skeleton, notification } from 'antd';
import Button from 'components/v2/Button/Button';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import { useGetClinicianId } from '../../utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from '../../utils/hooks/token';
import { scrollToView } from '../../utils/scrollToView';
import InviteTimeForm from '../InviteTimeForm/InviteTimeForm';
import styles from './AddPatientModalV2.module.scss';
import { ClientDetails, clientRecordsInterface, newClientForm } from './AddPatientModalV2Interface';
import ClientCreation from './components/ClientCreation/ClientCreation';
import ModalHeader from './components/ModalHeader/ModalHeader';
import Onboarding from './components/Onboarding/Onboarding';
import QuickNoteForm from './components/QuickNoteForm/QuickNoteForm';
import AdultForm from './formType/AdultForm/AdultForm';
import ChildForm from './formType/ChildForm/ChildForm';
import CoupleForm from './formType/CoupleForm/CoupleForm';
import { postNewClientData } from './hooks/PostNewClient';
import { usePrefillNewClientForm } from './hooks/PrefillValue';
import { useGetInitValueByType } from './InitValue/initValue';
import {
  checkIsDuplicateCaseId,
  validateAdultForm,
  validateChildForm,
  validateCoupleForm
} from './validation/SubmitFormValidation';

interface AddPatientModalV2Props {
  onCancel?: (isUpdated: boolean) => void;
  onResetForm?: () => void;
  visible: boolean;
}

const AddPatientModalV2 = ({ onResetForm = () => {}, visible, onCancel, ...props }: AddPatientModalV2Props) => {
  const { token } = useGetAccessToken();
  const { isEdgeReceptionist, isEdgeAdminView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const [selectedClinicianId, setSelectedClinicianId] = useState(
    isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId
  );
  const [t] = useTranslation();

  const { ChildInit } = useGetInitValueByType(selectedClinicianId);

  useEffect(() => {
    setSelectedClinicianId(isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId);
  }, [isEdgeAdminView, auth0ClinicianId, isEdgeReceptionist]);

  const {
    clientFormData,
    setClientFormData,
    onBoardingList,
    defaultOnBoardingId,
    consentList,
    defaultConsentId,
    prefillLoading,
    fetchPrefillClientData
  } = usePrefillNewClientForm(token, ChildInit);

  const [page1SubmitStatus, setPage1SubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [page2SubmitStatus, setPage2SubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const [pageStep, setPageStep] = useState(1);
  const [formCompleted, setFormCompleted] = useState(false);

  const [isSubmitPage1, setIsSubmitPage1] = useState(false);
  const [isSubmitPage2, setIsSubmitPage2] = useState(false);

  const [clientRecords, setClientRecords] = useState({} as clientRecordsInterface);

  const handleBackStepBtn = () => {
    setPageStep(1);
  };

  const handleChangeReferral = (value: string[]) => {
    setClientFormData({
      ...clientFormData,
      referralSourceTags: value
    });
  };

  const handleChangeReferralDetail = (value: string) => {
    setClientFormData({
      ...clientFormData,
      referralDetail: value
    });
  };

  const handleChangeCaseId = (value: newClientForm['caseId']) => {
    setClientFormData({
      ...clientFormData,
      caseId: value
    });
  };

  const handleChangeClass = (value: newClientForm['class']) => {
    setClientFormData({
      ...clientFormData,
      class: value
    });
  };

  const handleChangeClientDetail = (val: newClientForm['clientProfiles']) => {
    setClientFormData({
      ...clientFormData,
      clientProfiles: val
    });
  };

  const handleChangeClientDetailStep2 = (val: ClientDetails) => {
    const newClientProfiles = clientFormData.clientProfiles.map((client) => {
      return client._id === val._id ? val : client;
    });
    handleChangeClientDetail(newClientProfiles);
  };

  const handleChangeCapability = (val: newClientForm['clientCapability']) => {
    const clientProfiles = clientFormData.clientProfiles.map((client) => {
      return {
        ...client,
        onboarding: {
          ...client.onboarding,
          onboardingMethod: val.profileType !== 'full' ? 'facilitated' : client.onboarding.onboardingMethod
        }
      };
    });
    setClientFormData({
      ...clientFormData,
      clientCapability: val,
      clientProfiles: clientProfiles
    });
  };

  const handleChangeOnboardDetail = (val: ClientDetails['onboarding'], clientId: string) => {
    const clientProfiles = clientFormData.clientProfiles.map((client) => ({
      ...client,
      onboarding: client._id === clientId ? (client.onboarding = val) : client.onboarding
    }));
    const newClientForm = {
      ...clientFormData,
      clientProfiles: clientProfiles
    };
    setClientFormData(newClientForm);
  };

  const handleChangeConsentDetail = (val: ClientDetails['consentForm'], clientId: string) => {
    const clientProfiles = clientFormData.clientProfiles.map((client) => ({
      ...client,
      consentForm: client._id === clientId ? (client.consentForm = val) : client.consentForm
    }));
    setClientFormData({
      ...clientFormData,
      clientProfiles: clientProfiles
    });
  };

  const handleChangeInviteDetail = (val: newClientForm['signupInvitationSettings']) => {
    setClientFormData({
      ...clientFormData,
      signupInvitationSettings: val
    });
  };

  const handleChangeQuickNote = (val: newClientForm['quickNote']) => {
    setClientFormData({
      ...clientFormData,
      quickNote: val
    });
  };

  const handleChangeSelectedClinician = (val: string) => {
    setSelectedClinicianId(val);
    setClientFormData({
      ...clientFormData,
      clinicianAuth0Ids: val ? [val] : []
    });
  };

  const validatePart1Field = async (recordType: newClientForm['recordType'], clientVal: newClientForm) => {
    const isCaseIdDuplicateExist = await checkIsDuplicateCaseId(token, clientVal.caseId);
    if (recordType === 'adult') {
      const { isAdultFormErrorExist } = await validateAdultForm(
        token,
        clientVal.clientProfiles,
        clientVal.clientCapability.profileType
      );
      if (isAdultFormErrorExist || isCaseIdDuplicateExist) {
        setPage1SubmitStatus('');
      }
      return !isAdultFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'child') {
      handleChangeClientDetail(
        clientVal.clientProfiles.filter(
          (i) => !(i.role !== 'child' && !i.firstName && !i.lastName && !i.email && !i.mobileNumber)
        )
      );
      const { isChildFormErrorExist } = await validateChildForm(
        clientVal.clientProfiles.filter(
          (i) => !(i.role !== 'child' && !i.firstName && !i.lastName && !i.email && !i.mobileNumber)
        )
      );

      if (isChildFormErrorExist || isCaseIdDuplicateExist) {
        setPage1SubmitStatus('');
      }
      return !isChildFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'couple') {
      const { isClientFormErrorExist, isDuplicateEmail, isDuplicatePhone } = await validateCoupleForm(
        token,
        clientVal.clientProfiles
      );
      if (isClientFormErrorExist || isDuplicateEmail || isDuplicatePhone || isCaseIdDuplicateExist) {
        setPage1SubmitStatus('');
      }
      return !isClientFormErrorExist && !isDuplicateEmail && !isDuplicatePhone && !isCaseIdDuplicateExist;
    }
  };

  const validatePart2Field = async (clientVal: newClientForm) => {
    if (await validatePart1Field(clientVal.recordType, clientVal)) {
      const signupInvitationChecking =
        clientVal.signupInvitationSettings.sendAt !== 'custom' || !!clientVal.signupInvitationSettings.customSendTime;
      const quickNoteChecking =
        !clientVal.quickNote.includeQuickNote ||
        clientVal.quickNote.note.replace(/<(?!img)(.|\n)*?>/g, '').trim().length > 0;
      const isOnboardingErrorExist =
        clientVal.clientProfiles.filter(
          (clientObj) => clientObj.onboarding.isCheckOnBoarding && _.isEmpty(clientObj.onboarding.onboardingSurvey)
        ).length > 0;

      const consentFormErrorExist =
        clientVal.clientProfiles.filter(
          (clientObj) => clientObj.consentForm?.isCheckOnConsentForm && _.isEmpty(clientObj.consentForm.consentFormId)
        ).length > 0;

      if (!signupInvitationChecking || !quickNoteChecking || consentFormErrorExist || isOnboardingErrorExist) {
        setPage2SubmitStatus('');
      }

      return signupInvitationChecking && quickNoteChecking && !consentFormErrorExist && !isOnboardingErrorExist;
    }
  };

  const handleSubmitPart1 = async () => {
    setPage1SubmitStatus('active');
    setIsSubmitPage1(true);
    if (await validatePart1Field(clientFormData.recordType, clientFormData)) {
      setTimeout(() => {
        setPageStep(2);
        scrollToView('modalHeader', true);
        setPage1SubmitStatus('');
      }, 500);
    }
  };

  const handleSubmitPart2 = async () => {
    setPage2SubmitStatus('active');
    setIsSubmitPage2(true);

    if (await validatePart2Field(clientFormData)) {
      try {
        const result = await postNewClientData(token, clientFormData, selectedClinicianId);
        setClientRecords(result.createdClientRecord);
        setTimeout(() => {
          setPage2SubmitStatus('finished');
        }, 500);
        setTimeout(() => {
          setFormCompleted(true);
          scrollToView('modalHeader', true);
          notification.success({
            message: t('form.success.create_client'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setPage2SubmitStatus('');
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setPage2SubmitStatus('');
        notification.error({
          message: t('form.error.create_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const handleResetForm = async () => {
    setPage1SubmitStatus('');
    setPage2SubmitStatus('');
    setPageStep(1);
    setFormCompleted(false);
    setIsSubmitPage1(false);
    setIsSubmitPage2(false);
    setClientRecords({} as clientRecordsInterface);
    await fetchPrefillClientData(ChildInit);
    onResetForm();
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={
        <ModalHeader
          clientDetails={clientFormData}
          selectedClinicianId={selectedClinicianId || ''}
          onChangeClinician={(selectedId) => handleChangeSelectedClinician(selectedId)}
          totalStep={2}
          isCompleted={formCompleted}
          currentStep={pageStep}
          onClickBackBtn={handleBackStepBtn}
        />
      }
      visible={visible}
      {...props}
      onCancel={() => {
        onCancel && onCancel(formCompleted);
        if (formCompleted) {
          handleResetForm();
        }
      }}
      footer={null}
    >
      <div className={styles.container}>
        {pageStep === 1 && !formCompleted && (
          <div>
            <div className={prefillLoading ? styles.contentLoadingContainer : styles.contentContainer}>
              {prefillLoading ? (
                <div className={styles.loadingContainer}>
                  <Skeleton active />
                  <Skeleton active />
                </div>
              ) : (
                <>
                  {clientFormData.recordType === 'adult' && (
                    <AdultForm
                      referralSourceTags={clientFormData.referralSourceTags}
                      referralDetail={clientFormData.referralDetail}
                      clientDetail={clientFormData.clientProfiles}
                      clientCapability={clientFormData.clientCapability}
                      onChangeClientField={handleChangeClientDetail}
                      onChangeClientCapability={handleChangeCapability}
                      onChangeReferral={handleChangeReferral}
                      onChangeReferralDetail={handleChangeReferralDetail}
                      caseIdValue={clientFormData.caseId}
                      onChangeCaseId={handleChangeCaseId}
                      checkValidation={isSubmitPage1}
                    />
                  )}
                  {clientFormData.recordType === 'child' && (
                    <ChildForm
                      referralSourceTags={clientFormData.referralSourceTags}
                      referralDetail={clientFormData.referralDetail}
                      clientDetail={clientFormData.clientProfiles}
                      clientCapability={clientFormData.clientCapability}
                      onChangeClientField={handleChangeClientDetail}
                      onChangeClass={handleChangeClass}
                      classValue={clientFormData.class}
                      onChangeClientCapability={handleChangeCapability}
                      onChangeReferral={handleChangeReferral}
                      onChangeReferralDetail={handleChangeReferralDetail}
                      caseIdValue={clientFormData.caseId}
                      onChangeCaseId={handleChangeCaseId}
                      isOnboardingListExist={onBoardingList.length > 0}
                      isContentListExist={consentList.length > 0}
                      defaultOnBoardingId={defaultOnBoardingId}
                      defaultConsentId={defaultConsentId}
                      checkValidation={isSubmitPage1}
                    />
                  )}
                  {clientFormData.recordType === 'couple' && (
                    <CoupleForm
                      referralSourceTags={clientFormData.referralSourceTags}
                      referralDetail={clientFormData.referralDetail}
                      clientDetail={clientFormData.clientProfiles}
                      clientCapability={clientFormData.clientCapability}
                      onChangeReferral={handleChangeReferral}
                      onChangeReferralDetail={handleChangeReferralDetail}
                      onChangeClientField={handleChangeClientDetail}
                      onChangeClientCapability={handleChangeCapability}
                      caseIdValue={clientFormData.caseId}
                      onChangeCaseId={handleChangeCaseId}
                      checkValidation={isSubmitPage1}
                    />
                  )}
                  <div className={styles.submitButtonContainer}>
                    <Button
                      disabled={page1SubmitStatus !== ''}
                      status={page1SubmitStatus}
                      onClick={handleSubmitPart1}
                      id="nextStepBtnId"
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {pageStep === 2 && !formCompleted && (
          <div>
            {clientFormData.clientProfiles &&
              clientFormData.clientProfiles.length > 0 &&
              clientFormData.clientProfiles.map((client, index) => (
                <Onboarding
                  key={`onboarding_${index}`}
                  clientProfile={client}
                  onChangeConsentField={handleChangeConsentDetail}
                  onChangeOnboardField={handleChangeOnboardDetail}
                  handleChangeClientDetail={handleChangeClientDetailStep2}
                  isListLoading={prefillLoading}
                  onBoardingList={onBoardingList}
                  consentList={consentList}
                  onboardOption={clientFormData.recordType === 'adult' || clientFormData.recordType === 'couple'}
                  checkValidation={isSubmitPage2}
                  profileType={clientFormData.clientCapability.profileType}
                />
              ))}
            {clientFormData.clientCapability.profileType === 'full' && (
              <div className={styles.inviteFormWrapper}>
                <InviteTimeForm
                  title={t('label.send_welcome_documents_to_complete')}
                  tooltipsMessage={t('label.send_invite.tooltip')}
                  inviteTimeForm={clientFormData.signupInvitationSettings}
                  checkValidation={isSubmitPage2}
                  onChangeInviteField={handleChangeInviteDetail}
                />
              </div>
            )}
            <QuickNoteForm
              clientQuickNote={clientFormData.quickNote}
              checkValidation={isSubmitPage2}
              onChangeQuiteNote={handleChangeQuickNote}
            />
            <div id="completeBtnId" className={styles.submitButtonContainer}>
              <Button status={page2SubmitStatus} disabled={page2SubmitStatus !== ''} onClick={handleSubmitPart2}>
                {t('button.complete_client_setup')}
              </Button>
            </div>
          </div>
        )}
        {formCompleted && <ClientCreation clientRecords={clientRecords} onResetForm={handleResetForm} />}
      </div>
    </Modal>
  );
};

export default AddPatientModalV2;
