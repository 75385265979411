import { useEffect, useState } from 'react';
import { notification, Popconfirm } from 'antd';

import Button from 'components/v2/Button/Button';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useFetchConsentForm } from './hooks/GetConsentDetail';
import { useGetAccessToken } from 'utils/hooks/token';
import { Skeleton } from 'antd';
import WidgetTextEditor from 'components/WidgetTextEditor/WidgetTextEditor';
import { useFetchPracticeInfo } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import CheckBox from 'components/CheckBox/CheckBox';
import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';
import PillButtonGroup from 'components/v2/PIllButtonGroup/PIllButtonGroup';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  deleteConsentFormByFormId,
  postConsentForm,
  putConsentForm
} from 'utils/http/DocumentService/ConsentForm/consentForm';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ConsentContent.module.scss';
import { useTranslation } from 'react-i18next';

const options = [
  { value: 'draft', label: 'DRAFT' },
  { value: 'published', label: 'PUBLISHED' }
];

const errorMessageInit = {
  name: 'Please enter title for this consent form',
  body: 'Consent body cannot be blank',
  signature: 'Please select at least one signature type'
};

const ConsentContent = () => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const { token } = useGetAccessToken();
  const location = useLocation();
  const isNewConsentForm = location.pathname.includes('new');
  const { consentFormData, isConsentFormDataLoading } = useFetchConsentForm(token);
  const { practiceData, isPracticeDataLoading } = useFetchPracticeInfo();
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const [consentForm, setConsentForm] = useState({} as ConsentFormInterface);
  const [errorMessage, setErrorMessage] = useState({
    name: '',
    body: '',
    signature: ''
  });
  const [checkValidation, setCheckValidation] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setConsentForm(consentFormData);
  }, [consentFormData]);

  const handleChangeName = (value: string) => {
    const newConsentField = {
      ...consentForm,
      name: value
    };
    setConsentForm(newConsentField);
    setErrorMessage({
      ...errorMessage,
      name: value.length < 1 ? errorMessageInit.name : ''
    });
  };

  const handleChangeBody = (value: string) => {
    const newConsentField = {
      ...consentForm,
      body: value
    };
    setConsentForm(newConsentField);
    setErrorMessage({
      ...errorMessage,
      body: value.replace(/<(?!img)(.|\n)*?>/g, '').trim().length < 1 ? errorMessageInit.body : ''
    });
  };

  const onHandleChangeCheckBox = (type: string, val: boolean) => {
    const newConsentField = {
      ...consentForm,
      signatureRequired: {
        client: type === 'client' ? val : consentForm.signatureRequired.client,
        other: type === 'other' ? val : consentForm.signatureRequired.other
      }
    };
    setConsentForm(newConsentField);

    setErrorMessage({
      ...errorMessage,
      signature:
        !newConsentField.signatureRequired.client && !newConsentField.signatureRequired.other
          ? errorMessageInit.signature
          : ''
    });
  };

  const handleStatusChange = (val: string) => {
    const newConsentField = {
      ...consentForm,
      isPublished: val === 'published'
    };
    setConsentForm(newConsentField);
  };

  const validation = (data: ConsentFormInterface) => {
    const checkName = data.name.length < 1;
    const checkBody = data.body.replace(/<(?!img)(.|\n)*?>/g, '').trim().length < 1;
    const checkSignature = !data.signatureRequired.client && !data.signatureRequired.other;

    setErrorMessage({
      name: checkName ? errorMessageInit.name : '',
      body: checkBody ? errorMessageInit.body : '',
      signature: checkSignature ? errorMessageInit.signature : ''
    });

    return !checkName && !checkBody && !checkSignature;
  };

  const handleNewForm = async () => {
    try {
      const massagePayload = {
        name: consentForm.name,
        body: consentForm.body,
        isPublished: consentForm.isPublished,
        isDefault: consentForm.isDefault,
        signatureRequired: consentForm.signatureRequired
      };
      const res = await (await postConsentForm(token, massagePayload)).json();
      setSaveStatus('finished');
      navigate(`${FORMS.BASE}/consent/${res._id}`);
      setConsentForm(res);
      setTimeout(() => {
        notification.success({
          message: `Consent form ${isNewConsentForm ? 'created' : 'updated'}`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSaveStatus('');
        setCheckValidation(false);
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setSaveStatus('');
      notification.error({
        message: 'Create consent form fail',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleUpdateForm = async () => {
    try {
      const massagePayload = {
        name: consentForm.name,
        body: consentForm.body,
        isPublished: consentForm.isPublished,
        isDefault: consentForm.isDefault,
        signatureRequired: consentForm.signatureRequired
      };
      await putConsentForm(token, consentForm._id, massagePayload);
      setSaveStatus('finished');
      setTimeout(() => {
        notification.success({
          message: `Consent form updated`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSaveStatus('');
        setCheckValidation(false);
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setSaveStatus('');
      notification.error({
        message: 'Update consent form fail',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const onHandleSave = async () => {
    setCheckValidation(true);
    if (validation(consentForm)) {
      setSaveStatus('active');
      isNewConsentForm ? await handleNewForm() : await handleUpdateForm();
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteConsentFormByFormId(token, consentForm._id);
      notification.success({
        message: `Consent form removed`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      navigate(`${FORMS.BASE}`);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Remove consent form fail',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return isConsentFormDataLoading || isPracticeDataLoading ? (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <Skeleton active />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.loadingPracticeWrapper}>
          <Skeleton.Input active className={styles.loadingPracticeInfo} />
          <Skeleton.Input active className={styles.loadingPracticeInfo} />
          <Skeleton.Input active className={styles.loadingPracticeInfo} />
        </div>
        <Skeleton active />
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.content}>
          <div className={styles.nameWrapper}>
            <div
              className={classnames(styles.fieldContainer, checkValidation && errorMessage.name && styles.fieldError)}
            >
              <MaterialInput
                id={`nameOfAgreement`}
                label={'Name of agreement'}
                value={consentForm.name}
                onChange={(e) => handleChangeName(e.target.value)}
              />
              <div className={styles.fieldError}>{errorMessage.name}</div>
            </div>
          </div>
          <div className={styles.statusWrapper}>
            <PillButtonGroup
              options={options}
              value={consentForm.isPublished ? 'published' : 'draft'}
              onChange={handleStatusChange}
            />
          </div>
          <Button className={styles.saveBtn} status={saveStatus} onClick={onHandleSave}>
            Save Agreement Changes
          </Button>
        </div>
        <div className={styles.deleteWrapper}>
          <Popconfirm
            placement={'topLeft'}
            title={'Are you sure you want to delete this template?'}
            onConfirm={handleDeleteTemplate}
          >
            <div className={styles.deleteBtn}>Delete this template</div>
          </Popconfirm>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div>
          <div className={styles.practiceInfoWrapper}>
            {practiceData.practice?.logo && (
              <img className={styles.image} src={practiceData.practice?.logo} alt="Practice logo" />
            )}
            <div className={styles.practiceDetails}>
              <div className={styles.name}>{practiceData.practice?.name}</div>
              {practiceData.practice?.locations && practiceData.practice?.locations.length > 0 && (
                <div className={styles.detail}>{practiceData.practice?.locations[0]}</div>
              )}
              {practiceData.practice?.mobileNumber && (
                <div className={styles.detail}>{practiceData.practice?.mobileNumber}</div>
              )}
            </div>
          </div>
          <div className={styles.textEditorWrapper}>
            <WidgetTextEditor
              className={'consentFormEditor'}
              containerClass={styles.textEditorContainer}
              value={consentForm.body}
              onChangeValue={(value: string) => handleChangeBody(value)}
              basicMention
            />
            <div className={styles.errorMessage}>{errorMessage.body}</div>
          </div>
          <div className={styles.signatureWrapper}>
            <div className={styles.signatureTitle}>Signature requirements</div>
            <div className={styles.signatureDesc}>Confirm understanding and agree to terms</div>
            <div className={styles.checkBoxWrapper}>
              <CheckBox
                id={`clientSignature`}
                value={consentForm.signatureRequired.client}
                label={t('label.client_digital_signature')}
                onChange={(e) => onHandleChangeCheckBox('client', e.target.checked)}
              />
              <div className={styles.checkBoxField}>
                <CheckBox
                  id={`parentGuardianSignature`}
                  value={consentForm.signatureRequired.other}
                  label={'Parent or Guardian digital signature'}
                  onChange={(e) => onHandleChangeCheckBox('other', e.target.checked)}
                />
              </div>
            </div>
          </div>
          <div className={styles.errorMessage}>{errorMessage.signature}</div>
        </div>
      </div>
    </div>
  );
};

export default ConsentContent;
