export const useRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = ['admin', 'teacher', 'user', 'receptionist'].includes(firstPath) ? `/${firstPath}` : '';

  return {
    CLIENTS: {
      BASE: `${initPath}/students`
    },
    GROUPS: {
      BASE: `${initPath}/groups`,
      SURVEY: {
        DETAIL: 'detail'
      }
    },
    INTERVENTIONS: {
      BASE: `${initPath}/interventions`,
      SURVEY: {
        DETAIL: 'detail'
      }
    },
    PRACTITIONER: {
      BASE: `${initPath}/practitioners`
    },
    ASSESSMENT: {
      BASE: `${initPath}/assessments`
    },
    FORMS: {
      BASE: `${initPath}/forms`
    },
    MESSAGES: {
      BASE: `${initPath}/messages`,
      BROADCAST: `${initPath}/messages/broadcastMessage`
    },
    REPORTS: {
      BASE: `${initPath}/reports`,
      NEW: `${initPath}/reports/newReport`,
      TEMPLATE_EDITOR: `${initPath}/reports/templates`,
      NEW_TEMPLATE_EDITOR: `${initPath}/reports/templates/newTemplate`
    },
    CALENDAR: {
      BASE: `${initPath}/calendar`,
      DAY: `${initPath}/calendar/view/day`,
      WEEK: `${initPath}/calendar/view/week`,
      APT_SETUP: `${initPath}/calendar/appointment/setup`,
      APT_SETUP_ACC_LEVEL: `${initPath}/calendar/appointment/setup/account-level-rules`,
      SETTINGS: `${initPath}/calendar/settings`,
      SETTINGS_APT_TYPE: `${initPath}/calendar/settings/appointmentTypes`,
      SETTINGS_CLD_LINKING: `${initPath}/calendar/settings/calendarLinking`
    },
    INVOICES: {
      BASE: `${initPath}/invoices`,
      NEW: `${initPath}/invoices/new`
    },
    CONTROL_PANEL: {
      BASE: `${initPath}/control-panel`,
      SCHOOL: `${initPath}/control-panel/school`,
      ABOUT_YOU: `${initPath}/control-panel/about-you`,
      SYSTEM_CONNECTIONS: `${initPath}/control-panel/system-connections`
    },
    MENTOR: {
      BASE: initPath,
      CLIENTS: `${initPath}/clients`,
      ABOUT_YOU: `${initPath}/about-you`,
      SCHEDULE: `${initPath}/schedule`
    },
    ANALYTICS: {
      BASE: `${initPath}/analytics`
    },
    HELM_CONTENT: {
      BASE: `${initPath}/helm-content`
    },
    INTERVENTION_LIBRARY: `${initPath}/intervention-library`,
    ACTIONPLAN: {
      BASE: initPath
    }
  };
};
