import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { ActionPlanAssessment } from 'MHH/pages/ActionPlanCreator/interfaces';
import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { useCallback, useEffect, useState } from 'react';
import { getClinicalAssessmentByAssessmentId } from 'utils/http/CheckInService/Assessment/clinicalAssessment';

export const useFetchAssessmentByAssessmentId = (clientId: string, attachedAssessments: ActionPlanAssessment[]) => {
  const [assessments, setAssessments] = useState<Assessment[] | undefined>();
  const [isLoadingAssessment, setIsLoadingAssessment] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const fetchAssessments = useCallback(
    async (attachedAssessments: ActionPlanAssessment[]) => {
      const assessmentIdsToFetch = attachedAssessments
        .filter(
          ({ id, clinicalAssessmentResponseId }) =>
            id.includes('SDQ') && !assessments?.some(({ _id }) => _id === clinicalAssessmentResponseId)
        )
        .map(({ clinicalAssessmentResponseId }) => clinicalAssessmentResponseId);

      const newAssessments = [
        ...(assessments?.filter(({ _id }) =>
          attachedAssessments.some(({ clinicalAssessmentResponseId }) => _id === clinicalAssessmentResponseId)
        ) || [])
      ];

      if (assessmentIdsToFetch.length > 0) {
        try {
          setIsLoadingAssessment(true);
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });

          const queryParam = `clientRecordId=${clientId}`;

          const fetchedAssessments: Assessment[] = await Promise.all(
            assessmentIdsToFetch.map(async (assessmentId) => {
              try {
                const callGetClinicalAssessmentResponseById = await getClinicalAssessmentByAssessmentId(
                  token,
                  assessmentId,
                  queryParam
                );
                const assessment = await callGetClinicalAssessmentResponseById.json();
                return assessment;
              } catch (ex) {
                notification.error({ message: "Something went wrong while trying to get this patient's assessments" });
              }
            })
          );
          newAssessments.push(...fetchedAssessments.filter((assessment) => !!assessment));
        } catch (ex) {
          notification.error({ message: "Something went wrong while trying to get this patient's assessments" });
        }
      }

      setAssessments(newAssessments);
      setIsLoadingAssessment(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId, getAccessTokenSilently]
  );

  useEffect(() => {
    if (attachedAssessments.length > 0) {
      fetchAssessments(attachedAssessments);
      return;
    }
    setAssessments([]);
  }, [attachedAssessments, fetchAssessments]);

  return { assessments, isLoadingAssessment, fetchAssessments };
};
