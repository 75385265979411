const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const reconstructCaseNote = (caseNoteNode: Node): Document => {
  const newDocument = new Document();
  const newHTML = newDocument.createElement('html');
  const newHead = document.head.cloneNode(true);
  const newBody = newDocument.createElement('body');
  newBody.setAttribute('style', 'padding: 20px');
  newBody.appendChild(caseNoteNode.cloneNode(true));

  newHTML.appendChild(newHead);
  newHTML.appendChild(newBody);
  newDocument.appendChild(newHTML);
  const styleSheets = newDocument.querySelectorAll("link[rel='stylesheet' i]");

  styleSheets.forEach((styleSheet) => {
    const extractedHref = styleSheet.getAttribute('href');
    if (extractedHref && !isValidUrl(extractedHref)) {
      styleSheet.setAttribute('href', `${document.location.origin}${extractedHref}`);
    }
  });

  return newDocument;
};

export const downloadCaseNote = (file: Blob, fileName: string): void => {
  const hiddenLink = document.createElement('a'); // ToDo: Is this really hidden??
  hiddenLink.href = window.URL.createObjectURL(file);
  hiddenLink.download = `${fileName}.zip`;
  hiddenLink.click();
  hiddenLink.remove();
};
