import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';

import { AppointmentsStatus, InvoiceStatus } from 'pages/Invoices/interface';

import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentsInvoicedStatusByPatientId } from 'utils/http/appointment';
import { putInvoiceStatus, putResendInvoice } from 'utils/http/BillingService/Invoice/invoice';

import AppointmentStatus from 'pages/Invoices/components/AppointmentStatus/AppointmentStatus';
import InvoiceListing from 'pages/Invoices/components/InvoiceListing/InvoiceListing';
import PaymentStatus from 'pages/Invoices/components/PaymentStatus/PaymentStatus';

import styles from './PatientDetailsInvoices.module.scss';
import { useFetchInvoices } from './hooks/getInvoices';

const useFetchAppointmentsStatus = (token: string, clientRecordId: string) => {
  const [appointmentsStatus, setAppointmentsStatus] = useState<AppointmentsStatus>();
  const [isAppointmentsStatusLoading, setIsAppointmentsStatusLoading] = useState(true);

  const fetchAppointmentsStatus = async (token: string) => {
    try {
      const callAppointmentsStatus = await getAppointmentsInvoicedStatusByPatientId(token, clientRecordId);

      const appointmentsStatus = await callAppointmentsStatus.json();

      if (appointmentsStatus) {
        setAppointmentsStatus(appointmentsStatus);
      }
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get your appointments' invoiced status." });
    }

    setIsAppointmentsStatusLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppointmentsStatus(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appointmentsStatus, isAppointmentsStatusLoading };
};

interface PatientDetailsInvoicesProps {
  recordId: string;
  allowCreateNewInvoice: boolean;
}

const PatientDetailsInvoices = ({ recordId, allowCreateNewInvoice }: PatientDetailsInvoicesProps) => {
  const { token } = useGetAccessToken();

  const { getAccessTokenSilently } = useAuth0();

  const { appointmentsStatus, isAppointmentsStatusLoading } = useFetchAppointmentsStatus(token, recordId);
  const { invoices, isInvoicesLoading, setInvoices } = useFetchInvoices(token, recordId);

  const [invoiceIdProcessing, setInvoiceIdProcessing] = useState<string>('');

  const onChangeStatus = async (_id: string, status: 'closed' | 'confirmPaid') => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    }).catch(() => '');

    if (token) {
      try {
        setInvoiceIdProcessing(_id);
        await putInvoiceStatus(token, _id, status);

        const newInvoices = [...invoices];
        const updatedInvoice = newInvoices.find((invoice) => invoice._id === _id);
        updatedInvoice && (updatedInvoice.status = status as InvoiceStatus);
        setInvoices(newInvoices);

        notification.success({
          message: "Successfully updated this invoice's status!",
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setInvoiceIdProcessing('');
      } catch (ex) {
        setInvoiceIdProcessing('');
        notification.error({ message: "Something went wrong while trying to update this invoice's status" });
      }
    }
  };

  const onResendInvoice = async (_id: string) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    }).catch(() => '');

    if (token) {
      try {
        setInvoiceIdProcessing(_id);
        await putResendInvoice(token, _id);

        notification.success({
          message: 'Successfully resent this invoice!',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setInvoiceIdProcessing('');
      } catch (ex) {
        setInvoiceIdProcessing('');
        notification.error({ message: "Something went wrong while trying to update this invoice's status" });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.statuses}>
        <div className={styles.appointmentStatus}>
          <AppointmentStatus
            appointmentsStatus={appointmentsStatus}
            isAppointmentsStatusLoading={isAppointmentsStatusLoading}
          />
        </div>
        <div className={styles.paymentStatus}>
          <PaymentStatus invoices={invoices} enableGroupSelection={false} />
        </div>
      </div>
      <div className={styles.listing} id={'clientInvoiceListing'}>
        <InvoiceListing
          invoices={invoices}
          allowCreateNewInvoice={allowCreateNewInvoice}
          isInvoicesLoading={isInvoicesLoading}
          onChangeStatus={onChangeStatus}
          onResendInvoice={onResendInvoice}
          invoiceIdProcessing={invoiceIdProcessing}
          enableGroupSelection={false}
        />
      </div>
    </div>
  );
};

export default PatientDetailsInvoices;
