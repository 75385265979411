import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { getCheckSignedUpProfiles } from 'utils/http/PatientProfileService/checkEmailSignUp';

export const useFetchSignedUpProfile = (token: string, clientRecordId: string) => {
  const [signedUpProfiles, setSignedUpProfiles] = useState<string[]>([]);
  const [isSUPLoading, setIsSUPLoading] = useState(true);

  const fetchSignedUpProfiles = async (token: string) => {
    try {
      const callGetSignedUpProfile = await (await getCheckSignedUpProfiles(token, clientRecordId)).json();

      setSignedUpProfiles(callGetSignedUpProfile.signedUpProfiles);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your signed up profile.' });
    }

    setIsSUPLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchSignedUpProfiles(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { signedUpProfiles, isSUPLoading };
};
