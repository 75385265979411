import styles from './StageCard.module.scss';
import StageActionMenu from './components/StageActionMenu/StageActionMenu';
import { PathwayStageItem } from '../../interface';

interface StageCardProps {
  groupId: string;
  stageItem: PathwayStageItem;
  sequence?: number;
  onRefetchList?: () => void;
  onOpenDeleteModal: (stageItemToBeDeleted: PathwayStageItem) => void;
  allowEditStage: boolean;
}

const StageCard = ({
  groupId,
  stageItem,
  sequence,
  onRefetchList,
  onOpenDeleteModal,
  allowEditStage
}: StageCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          {sequence && (
            <div className={styles.sequenceWrapper}>
              <div className={styles.sequenceBadge}>{sequence}</div>
            </div>
          )}
          <div className={styles.title}>{stageItem.name}</div>
        </div>
        <div>
          {allowEditStage && (
            <StageActionMenu
              groupId={groupId}
              stageItem={stageItem}
              onRefetchList={onRefetchList}
              onOpenDeleteModal={onOpenDeleteModal}
            />
          )}
        </div>
      </div>
      {stageItem.description && <div className={styles.desc}>{stageItem.description}</div>}
      <div className={styles.memberInStageWrapper}>
        <div className={styles.label}>Member in stage</div>
        <div className={styles.totalMember}>{stageItem.count}</div>
      </div>
    </div>
  );
};

export default StageCard;
