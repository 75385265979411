import bent from 'bent';

const url = process.env.REACT_APP_UTILITY_SERVICE_URL;

if (!url) {
  throw new Error('Environment variables does not have REACT_APP_UTILITY_SERVICE_URL');
}

const downloadCaseNoteWithAttachments = (caseNoteType: 'clientRecord' | 'group') => async (
  token: string,
  documentId: string,
  id: string,
  payload: Document
): Promise<Blob> => {
  const xmlSerializer = new XMLSerializer();
  const encodedPayload = xmlSerializer.serializeToString(payload);
  const response = await bent('POST', 'buffer', url, 200, {
    Authorization: `Bearer ${token}`
  })(
    `/${caseNoteType === 'clientRecord' ? 'client-records' : 'groups'}/${documentId}/case-notes/${id}:download`,
    encodedPayload,
    {
      'Content-Type': 'text/plain',
      'Content-Length': encodedPayload.length
    }
  );

  return new Blob([response], { type: 'application/zip' });
};

export const downloadClientCaseNoteWithAttachments = downloadCaseNoteWithAttachments('clientRecord');
export const downloadGroupCaseNoteWithAttachments = downloadCaseNoteWithAttachments('group');
