import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { ConsentFormListFilterParameter, ConsentFormListInterface } from 'pages/Groups/Interfaces/Groups';

export const useFetchConsentFormList = (
  token: string,
  groupId: string,
  filterValue?: ConsentFormListFilterParameter
) => {
  const [consentFormList, setSurveyFormList] = useState<ConsentFormListInterface>({
    consentFormList: [],
    count: {
      active: 0,
      completed: 0,
      archived: 0,
      all: 0
    },
    paging: {
      page: 1,
      perPage: 20,
      totalItem: 0
    }
  });

  const [isConsentFormListLoading, setIsConsentFormListLoading] = useState(true);

  const fetchConsentFormList = useCallback(async (token: string) => {
    try {
      setIsConsentFormListLoading(true);
      // const massageParam = {
      //   ...filterValue,
      //   ...(filterValue?.status === 'all' && {
      //     status: undefined
      //   })
      // };
      // const qParam = queryString.stringify(massageParam || {});
      // TODO
      // const getSurveyFormListData = await getSurveyFormListByGroupId(token, groupId, qParam);
      // const surveyFormListData: SurveyFormList = await getSurveyFormListData.json();
      // const sumCount = Object.values(surveyFormListData.count).reduce((a: number, b: number) => a + b) ?? 0;
      const consentFormList = [
        {
          _id: '1',
          recordType: 'adult' as 'adult' | 'couple' | 'minor' | 'group' | 'child',
          clientProfiles: [
            {
              avatar: 'https://storage.googleapis.com/patient-avatars/342476c0f5214ae67bda1fd28648595e.png',
              firstName: 'abu',
              lastName: 'uba',
              initials: 'AU',
              initialsBackgroundColor: '#9e9d9c',
              name: 'abu uba'
            }
          ],
          isFormSent: true,
          isFormSigned: true,
          dateSigned: '22/10/2021'
        },
        {
          _id: '2',
          recordType: 'couple' as 'adult' | 'couple' | 'minor' | 'group' | 'child',
          clientProfiles: [
            {
              avatar: 'https://storage.googleapis.com/patient-avatars/d60ae1b9506dbc98df9ee7e7c30e04ee.png',
              firstName: 'Evil',
              lastName: 'Lala',
              initials: 'AU',
              initialsBackgroundColor: '#9e9d9c',
              name: 'Evil Lala'
            },
            {
              avatar: 'https://storage.googleapis.com/patient-avatars/4075ba31431d630f00ffd6c3295bca1e.png',
              firstName: 'Alex',
              lastName: 'Lyly',
              initials: 'AU',
              initialsBackgroundColor: '#9e9d9c',
              name: 'Alex Lyly'
            }
          ],
          isFormSent: true,
          isFormSigned: true,
          dateSigned: '22/10/2021'
        },
        {
          _id: '2',
          recordType: 'couple' as 'adult' | 'couple' | 'minor' | 'group' | 'child',
          clientProfiles: [
            {
              avatar: 'https://storage.googleapis.com/patient-avatars/d60ae1b9506dbc98df9ee7e7c30e04ee.png',
              firstName: 'Evil',
              lastName: 'Lala',
              initials: 'AU',
              initialsBackgroundColor: '#9e9d9c',
              name: 'Evil Lala'
            },
            {
              avatar: 'https://storage.googleapis.com/patient-avatars/4075ba31431d630f00ffd6c3295bca1e.png',
              firstName: 'Alex',
              lastName: 'Lyly',
              initials: 'AU',
              initialsBackgroundColor: '#9e9d9c',
              name: 'Alex Lyly'
            }
          ],
          isFormSent: true,
          isFormSigned: true,
          dateSigned: '22/10/2021'
        }
      ];
      const massageRes = {
        consentFormList: consentFormList,
        paging: {
          page: 1,
          perPage: 20,
          totalItem: 3
        }
      } as ConsentFormListInterface;

      setSurveyFormList(massageRes);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get your groups const form list' });
    }
    setIsConsentFormListLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      fetchConsentFormList(token);
    }
  }, [fetchConsentFormList, token]);

  return { consentFormList, isConsentFormListLoading, fetchConsentFormList };
};
