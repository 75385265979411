import { useState } from 'react';
import { Modal } from 'antd';
import { toPng } from 'html-to-image';

import Button from 'components/v2/Button/Button';
import QuickNoteList from './components/QuickNoteList/QuickNoteList';

import styles from './AddQuickNoteModal.module.scss';

export interface QuickNote {
  _id: string;
  title: string;
  body: string;
  createdAt: string;
}

interface AddQuickNoteModalProps {
  recordId: string;
  visible: boolean;
  onCancel: (newQuickNoteImages?: string) => void;
}

const AddQuickNoteModal = ({ recordId, visible, onCancel }: AddQuickNoteModalProps) => {
  const [selectedQuickNotes, setSelectedQuickNotes] = useState<QuickNote[]>([]);

  const [addButtonStatus, setAddButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSelectQuickNote = (quickNote: QuickNote) => {
    if (selectedQuickNotes.map((quickNote) => quickNote._id).includes(quickNote._id)) {
      setSelectedQuickNotes(selectedQuickNotes.filter((selectedQuickNote) => selectedQuickNote._id !== quickNote._id));
    } else {
      setSelectedQuickNotes([...selectedQuickNotes, quickNote]);
    }
  };

  const handleAddQuickNoteClick = async () => {
    setAddButtonStatus('active');

    let newQuickNoteImages = '<div>';

    for (let i = 0; i < selectedQuickNotes.length; i++) {
      const element = document.getElementById(`add-quick-note-card-${selectedQuickNotes[i]._id}`);

      if (element) {
        try {
          const dataUrl = await toPng(element);

          newQuickNoteImages += `<img src="${dataUrl}" width="200"><br><br>`;
        } catch (ex) {
          console.error(ex);
        }
      }
    }

    newQuickNoteImages += '</div>';

    setAddButtonStatus('finished');
    onCancel(newQuickNoteImages);

    setTimeout(() => {
      setAddButtonStatus('');
      setSelectedQuickNotes([]);
    }, 500);
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      width={768}
      footer={null}
      visible={visible}
      onCancel={() => onCancel()}
      destroyOnClose
    >
      <div className={styles.header}>Add Quick Note</div>
      <div className={styles.content}>
        <div className={styles.description}>Select the quickNotes to embed this note</div>
        <QuickNoteList
          recordId={recordId}
          selectedQuickNotes={selectedQuickNotes}
          onSelectQuickNote={handleSelectQuickNote}
        />
        <div className={styles.buttonContainer}>
          <Button className={styles.button} status={addButtonStatus} onClick={handleAddQuickNoteClick}>
            Add Quick Notes to this note
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddQuickNoteModal;
