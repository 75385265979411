import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ChangeEvent } from 'react';

import Label from '../Label/Label';
import styles from './TitleSection.module.scss';

interface TitleSectionProps {
  readOnly: boolean;
  nameOfReport: string;
  reportNameValidation?: boolean;
  handleChangeReportName: () => void;
  changeReportName: (value: ChangeEvent<HTMLInputElement>) => void;
}

const TitleSection = ({
  readOnly,
  nameOfReport,
  reportNameValidation,
  handleChangeReportName,
  changeReportName
}: TitleSectionProps) =>
  readOnly ? (
    <div className={styles.titleContainer}>
      <Label label={'TITLE'} />
      <div>{nameOfReport}</div>
    </div>
  ) : (
    <div className={styles.titleInputContainer}>
      <MaterialInput
        id={'title'}
        label={'Title'}
        className={styles.reportName}
        value={nameOfReport}
        onChange={(e) => {
          changeReportName(e);
        }}
        onBlur={handleChangeReportName}
        maxLength={30}
        required
      />
      {reportNameValidation && <div className={styles.error}>Please enter name of report</div>}
    </div>
  );

export default TitleSection;
