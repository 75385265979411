import { DatePicker } from 'antd';
import classnames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialLabel from 'components/MaterialLabel/MaterialLabel';
import { CaseNote } from 'interfaces/caseNote';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { toCamelCase } from '../../../../../../../../../../../../../../utils/generateCamelCase';
import styles from './DateWrapper.module.scss';

interface DateWrapperProps {
  field: CaseNote['fields'][number];
  isValidated: boolean;
  className?: string;
  isDisabled?: boolean;
  placeholder?: string;
  onChangeFieldInput: (id: string, value: any) => void;
}

const DateWrapper = ({
  field,
  isValidated,
  className,
  isDisabled,
  placeholder,
  onChangeFieldInput
}: DateWrapperProps) => {
  const minimisedLabel = field.value?.length > 0 || !!field.value || !!placeholder;
  const [dateFieldData, setDateFieldData] = useState({} as CaseNote['fields'][number]);
  const [isLabelMinimised, setIsLabelMinimised] = useState(minimisedLabel);

  useEffect(() => {
    setDateFieldData(field);
    setIsLabelMinimised(minimisedLabel);
  }, [field, minimisedLabel]);

  const handleChange = (val: any) => {
    onChangeFieldInput(dateFieldData._id, moment(val).format(MOMENTJS_DATE_FORMAT));
    setIsLabelMinimised(val?.length > 0 || !!val);
  };

  const handleFocus = () => {
    setIsLabelMinimised(true);
  };

  const handleBlur = (value: string) => {
    setIsLabelMinimised(value.length > 0);
  };

  return (
    <div className={classnames(styles.headingField, className)}>
      <MaterialLabel
        label={`${dateFieldData.name} ${dateFieldData.mandatory ? '*' : ''}`}
        isLabelMinimised={isLabelMinimised}
      />
      <DatePicker
        id={toCamelCase(dateFieldData.name)}
        picker="date"
        className={styles.date}
        suffixIcon={<i className={`material-icons icon`}>event</i>}
        showTime={false}
        format={MOMENTJS_DATE_FORMAT}
        placeholder={placeholder}
        value={dateFieldData.value ? moment(dateFieldData.value, MOMENTJS_DATE_FORMAT) : undefined}
        onChange={handleChange}
        onFocus={() => handleFocus()}
        onBlur={(e) => handleBlur(e.target.value)}
        allowClear={false}
        disabled={isDisabled}
      />
      <ErrorMessage
        className={styles.error}
        error={`Please choose ${dateFieldData.name && dateFieldData.name.toLocaleLowerCase()}`}
        visible={isValidated && dateFieldData.mandatory && !dateFieldData.value}
      />
    </div>
  );
};

export default DateWrapper;
