import { useEffect, useState } from 'react';
import SortingFilter from 'components/SortingFilter/SortingFilter';
import { Modal } from 'antd';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button from 'components/v2/Button/Button';

import styles from './ReportCreateNewModal.module.scss';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { combineName, getName } from 'utils/general';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const sortClient = (list: clientRecordsInterface[], sortAsc: boolean) => {
  return list.sort((a, b) => {
    const clientA = getName(a.clientProfiles[0]);
    const clientB = getName(b.clientProfiles[0]);

    return sortAsc ? clientA.localeCompare(clientB) : clientB.localeCompare(clientA);
  });
};

interface ReportCreateNewModalProps {
  clientRecordList: clientRecordsInterface[];
  isShowCreateNewModal: boolean;
  onCloseModal: any;
}

const ReportCreateNewModal = ({ clientRecordList, isShowCreateNewModal, onCloseModal }: ReportCreateNewModalProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const [clientList, setClientList] = useState(clientRecordList);
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [selectedCId, setSelectedCId] = useState('');
  const [saveTemplateStatus, setSaveTemplateStatus] = useState<'' | 'active' | 'finished'>('');
  const [reportName, setReportName] = useState('');
  const [validation, setValidation] = useState({
    reportName: false,
    selectedClient: false
  });
  const [t] = useTranslation();

  useEffect(() => {
    setClientList(sortClient(clientRecordList, isSortAsc));
  }, [clientRecordList, isSortAsc]);

  const handleSelectClientRecordId = (clientRecordId: string) => {
    setSelectedCId(clientRecordId);
    setValidation({
      ...validation,
      selectedClient: clientRecordId.length <= 0
    });
  };

  const onSortClient = () => {
    setIsSortAsc(!isSortAsc);
    setClientList(sortClient(clientRecordList, !isSortAsc));
  };

  const handleChangeReportName = (val: string) => {
    setReportName(val);
    setValidation({
      ...validation,
      reportName: val.length <= 0
    });
  };

  const onSubmitCreateReport = (e: any) => {
    e.preventDefault();
    setSaveTemplateStatus('active');

    if (reportName.length <= 0 || selectedCId.length <= 0) {
      setValidation({
        reportName: reportName.length <= 0,
        selectedClient: selectedCId.length <= 0
      });
      setSaveTemplateStatus('');
    } else {
      setTimeout(() => {
        setSaveTemplateStatus('finished');
        const redirectPath = `${REPORTS.NEW}?reportName=${encodeURIComponent(
          reportName
        )}&clientRecordId=${encodeURIComponent(selectedCId)}`;
        navigate(redirectPath);
      }, 600);
    }
  };

  return (
    <Modal visible={isShowCreateNewModal} bodyStyle={{ padding: 0 }} footer={null} onCancel={onCloseModal} forceRender>
      <div className={styles.header}>
        <div className={styles.title}>Create Report</div>
      </div>
      <div className={styles.content} id={'createReportModalId'}>
        <div className={styles.reportNameWrapper}>
          <MaterialInput
            id={'nameOfReport'}
            label={'Name of report'}
            className={styles.reportName}
            value={reportName}
            onChange={(e) => handleChangeReportName(e.target.value)}
            maxLength={30}
          />
          <div className={styles.note}>You can edit this later</div>
          {validation.reportName && <div className={styles.error}>Please enter name of report</div>}
        </div>
        <div className={styles.secondHeader}>
          <div className={styles.selectClientLabel}>{t('label.select_client')}</div>
          <SortingFilter
            onClickSort={onSortClient}
            selectedValue={isSortAsc ? 'A - Z' : 'Z - A'}
            isSortAsc={isSortAsc}
          />
        </div>
        <div className={styles.list}>
          {clientList.map(({ _id, clientProfiles, recordType }) => {
            const clientProfileList = recordType === 'child' ? clientProfiles.slice(0, 1) : clientProfiles;
            return (
              <div className={styles.clientListCard} key={_id} onClick={() => handleSelectClientRecordId(_id)}>
                <div className={styles.clientList}>
                  {clientProfileList.map((cPObj, index) => (
                    <div className={styles.clientListWrapper} key={index}>
                      <div className={styles.avatarWrapper}>
                        <ClientProfileAvatar avatarUrl={cPObj.avatar} initialsName={cPObj.initials} />
                      </div>
                    </div>
                  ))}
                  <span className={styles.name}>{combineName(clientProfileList)}</span>
                </div>
                {selectedCId === _id && (
                  <div className={styles.checkIconWrapper}>
                    <i className={`material-icons-outlined ${styles.checkIcon}`}>check_circle</i>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {validation.selectedClient && <div className={styles.error}>{t('label.select_client_before_proceed')}</div>}
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.createReportBtn}
            status={saveTemplateStatus}
            type="button"
            onClick={(e) => onSubmitCreateReport(e)}
          >
            Start editing this report
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportCreateNewModal;
