import columnStyles from './OutstandingAssessmentsListColumn.module.scss';
import styles from './OutstandingAssessmentsList.module.scss';
import Button from 'components/v2/Button/Button';
import PaginationList from 'components/PaginationList/PaginationList';
import { useGetOutstandingAssessments } from './hooks/useGetOutstandingAssessments';
import { Skeleton, notification } from 'antd';
import { useFetchProfileTags } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsProfile/hooks/GetAllProfileTags';
import { useGetAccessToken } from 'utils/hooks/token';
import { useEffect, useState } from 'react';
import { AllAssessmentFilters, OutstandingAssessmentType } from 'pages/AllAssessments/types';
import OutstandingAssessmentItem from './components/OutstandingAssessmentItem/OutstandingAssessmentItem';
import {
  postCancelAssessments,
  postSendAssessmentReminders
} from 'utils/http/CheckInService/Assessment/adhocAssessment';

interface SelectedAssessment {
  _id: string;
  assessmentType: OutstandingAssessmentType;
}

interface OutstandingAssessmentsListProps {
  accountId: string;
  filters: AllAssessmentFilters;
  visible: boolean;
  updateOutstandingAssessmentsCount: (count: number) => void;
}

const OutstandingAssessmentsList = ({
  accountId,
  filters,
  visible,
  updateOutstandingAssessmentsCount
}: OutstandingAssessmentsListProps) => {
  const { token } = useGetAccessToken();
  const {
    isLoading,
    assessments,
    navigatePage,
    currentPage,
    totalPage,
    totalItems,
    refetchAssessments
  } = useGetOutstandingAssessments({ accountId, filters });
  const { profileTags, isProfileTagsLoading } = useFetchProfileTags(token);

  const [selectedAssessments, setSelectedAssessments] = useState<SelectedAssessment[]>([]);

  useEffect(() => {
    if (!isLoading) {
      updateOutstandingAssessmentsCount(totalItems);
    }
  }, [isLoading, totalItems, updateOutstandingAssessmentsCount]);

  const handleCheckAssessment = (selectedAssessment: SelectedAssessment) => (checked: boolean) => {
    if (checked) {
      setSelectedAssessments([...selectedAssessments, selectedAssessment]);
    } else {
      setSelectedAssessments(selectedAssessments.filter(({ _id }) => _id !== selectedAssessment._id));
    }
  };

  const handleCancelAssessment = (selectedAssessments: SelectedAssessment[]) => async () => {
    try {
      await postCancelAssessments(token, accountId, { assessments: selectedAssessments });

      refetchAssessments();
      setSelectedAssessments([]);
      notification.success({
        message: `Successfully cancelled ${selectedAssessments.length} assessment${
          selectedAssessments.length === 1 ? '' : 's'
        }`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({
        message: `Something went wrong while trying to cancel the selected assessment${
          selectedAssessments.length === 1 ? '' : 's'
        }.`
      });
    }
  };

  const handleSendAssessmentReminder = (selectedAssessments: SelectedAssessment[]) => async () => {
    try {
      await postSendAssessmentReminders(token, accountId, { assessments: selectedAssessments });

      setSelectedAssessments([]);
      notification.success({
        message: `Successfully sent reminder${selectedAssessments.length === 1 ? '' : 's'} for ${
          selectedAssessments.length
        } assessment${selectedAssessments.length === 1 ? '' : 's'}`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({
        message: `Something went wrong while trying to send reminder${
          selectedAssessments.length === 1 ? '' : 's'
        } for the selected assessment${selectedAssessments.length === 1 ? '' : 's'}.`
      });
    }
  };

  return visible ? (
    <>
      {selectedAssessments.length > 0 && (
        <div className={styles.bulkButtonWrapper}>
          <Button
            className={styles.bulkButton}
            variant={'primary'}
            onClick={handleSendAssessmentReminder(selectedAssessments)}
          >
            Send Reminder
          </Button>
          <Button
            className={styles.bulkButton}
            variant={'primary'}
            onClick={handleCancelAssessment(selectedAssessments)}
          >
            Cancel Assessment
          </Button>
        </div>
      )}
      <div className={styles.header}>
        <div className={columnStyles.checkBoxSection} />
        <div className={columnStyles.requestDate}>Request Date</div>
        <div className={columnStyles.assessmentType}>Assessment Type</div>
        <div className={columnStyles.student}>Student</div>
        <div className={columnStyles.class}>Class</div>
        <div className={columnStyles.staffMember}>Staff Member</div>
        <div className={columnStyles.flags}>Flags</div>
        <div className={columnStyles.buttonSection} />
      </div>
      <div className={styles.assessmentsContainer}>
        {isLoading || isProfileTagsLoading ? (
          [...Array(10)].map((_, i) => <Skeleton.Input key={i} className={styles.loading} active />)
        ) : assessments.length ? (
          <>
            {assessments.map((assessment, i) => (
              <OutstandingAssessmentItem
                key={i}
                assessment={assessment}
                profileTags={profileTags}
                isSelected={selectedAssessments.some((selectedAssessment) => selectedAssessment._id === assessment._id)}
                disableCheckbox={
                  selectedAssessments.length >= 20 &&
                  !selectedAssessments.some((selectedAssessment) => selectedAssessment._id === assessment._id)
                }
                onCancelAssessment={handleCancelAssessment([{ _id: assessment._id, assessmentType: assessment.type }])}
                onSelectAssessment={handleCheckAssessment({ _id: assessment._id, assessmentType: assessment.type })}
                onSendReminder={handleSendAssessmentReminder([
                  { _id: assessment._id, assessmentType: assessment.type }
                ])}
              />
            ))}
            <PaginationList
              paging={{
                page: currentPage,
                perPage: 10,
                totalItem: totalItems,
                totalPage
              }}
              onPageChange={(paging) => navigatePage(Number(paging))}
            />
          </>
        ) : (
          'No outstanding assessments found'
        )}
      </div>
    </>
  ) : null;
};

export default OutstandingAssessmentsList;
