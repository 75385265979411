import { useState } from 'react';

import styles from './AddInterventionMembers.module.scss';
import UnassignedMemberList from './components/UnassignedMemberList/UnassignedMemberList';
import { useGetAccessToken } from 'utils/hooks/token';
import { useNavigate, useParams } from 'react-router-dom';
import { UnassignedInterventionMemberListFilter } from 'MHH/pages/Interventions/Interfaces/Interventions';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import ModalV2 from 'components/ModalV2/ModalV2';
import SearchBar from 'components/SearchBar/SearchBar';
import { useFetchAssignedInterventionMemberList } from './hooks/getAssginedInterventionMemberList';
import { notification } from 'antd';
import { patchAssignedInterventionMember } from 'utils/http/ClinicianProfileService/Interventions/InterventionMembers';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import Button from 'components/v2/Button/Button';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface AddInterventionMembersProps {
  title: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const defaultFilter = {
  q: '',
  page: 1,
  perPage: 20,
  sortByClientName: SortType.asc
};

const AddInterventionMembers = ({ title, showModal, setShowModal }: AddInterventionMembersProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const navigate = useNavigate();
  const { INTERVENTIONS } = useRoutesGenerator();
  const { interventionId = '' } = useParams<{ interventionId: string }>();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState<UnassignedInterventionMemberListFilter>(defaultFilter);

  const {
    unassignedInterventionMembers,
    isUnassignedInterventionMembersLoading,
    fetchUnassignedInterventionMemberList
  } = useFetchAssignedInterventionMemberList(token, accountId, interventionId, searchFilter);

  const setNewParam = (newParamValue: UnassignedInterventionMemberListFilter) => {
    setSearchFilter(newParamValue);
  };

  const handleSearch = (searchValue: string) => {
    if (searchFilter.q !== searchValue) {
      const newParam = {
        ...searchFilter,
        page: 1,
        q: searchValue
      };
      setNewParam(newParam);
    }
  };

  const handleChangeNameSort = (sortName: SortType) => {
    const newParam = {
      ...searchFilter,
      sortByClientName: sortName
    };
    setNewParam(newParam);
  };

  const handleChangePerPage = (perPageValue: number) => {
    if (searchFilter.perPage !== perPageValue) {
      const newParam = {
        ...searchFilter,
        page: 1,
        perPage: perPageValue
      };
      setNewParam(newParam);
    }
  };

  const handlePageChange = (pageValue: number) => {
    const newParam = {
      ...searchFilter,
      page: pageValue
    };
    setNewParam(newParam);
  };

  const handleSelectMember = (clientRecordId: string, value: boolean) => {
    if (value) {
      const addMember = [...selectedList, clientRecordId];
      setSelectedList(addMember);
    } else {
      const removeMember = selectedList.filter((selectedId) => selectedId !== clientRecordId);
      setSelectedList(removeMember);
    }
  };

  const handleAddMember = async () => {
    setSubmitStatus('active');

    try {
      await patchAssignedInterventionMember(token, accountId, interventionId, selectedList);
      setSubmitStatus('finished');
      setTimeout(() => {
        setShowModal(false);
        setSelectedList([]);
        setSubmitStatus('');
        setSearchFilter(defaultFilter);
        fetchUnassignedInterventionMemberList(token, defaultFilter);
        notification.success({
          message: 'Member list updated.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        navigate(`${INTERVENTIONS.BASE}/${interventionId}/members?refetch`);
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: 'Member list update fail.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <ModalV2
      containerClassName={styles.container}
      isModalOpen={showModal}
      title={`Add New Member to ${title}`}
      onModalClose={() => setShowModal(!showModal)}
    >
      <div className={styles.content}>
        <div className={styles.searchContainer}>
          <SearchBar
            placeholder={'Search by name or ID'}
            value={searchFilter.q || ''}
            withSearchButton
            onSearch={handleSearch}
            containerClassName={styles.search}
            label={'SEARCH FOR:'}
          />
        </div>
        <UnassignedMemberList
          interventionMembers={unassignedInterventionMembers}
          isInterventionMembersLoading={isUnassignedInterventionMembersLoading}
          selectedPage={searchFilter.page}
          selectedPerPage={searchFilter.perPage}
          selectedSortName={searchFilter.sortByClientName as SortType}
          selectedMemberList={selectedList}
          onChangeNameSort={handleChangeNameSort}
          onChangePerPage={handleChangePerPage}
          onChangePage={handlePageChange}
          onSelectMember={handleSelectMember}
        />
      </div>
      {selectedList.length > 0 && (
        <div className={styles.actionWrapper}>
          <Button status={submitStatus} onClick={handleAddMember}>
            Add {selectedList.length} {selectedList.length > 1 ? 'Members' : 'Member'}
          </Button>
        </div>
      )}
    </ModalV2>
  );
};

export default AddInterventionMembers;
