import { useTranslation } from 'react-i18next';

import { ReportBuilderWidgetView } from 'interfaces/Reports/report';
import WidgetsListMenu from './components/WidgetsListMenu/WidgetsListMenu';

import styles from './WidgetSection.module.scss';

interface WidgetSectionProps {
  title: string;
  icon: string;
  clientRecordId?: string;
  onClickWidgetMenu: (type: ReportBuilderWidgetView) => void;
  menuItems: { id: ReportBuilderWidgetView; label: string; count: number; isLoading: boolean }[];
}

const WidgetSection = ({ title, icon, menuItems, clientRecordId, onClickWidgetMenu }: WidgetSectionProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.widgetTitle}>
        <div className={styles.title}>
          <i className={`material-icons ${styles.titleIcon}`}>{icon}</i>
          {title}
        </div>
      </div>
      {!clientRecordId ? (
        <div className={styles.selectClientLabel}>{t('label.select_client_to_load_more_widget')}</div>
      ) : (
        <WidgetsListMenu onClickMenuBtn={onClickWidgetMenu} menuItems={menuItems} />
      )}
    </div>
  );
};

export default WidgetSection;
