import { ProfileInterface } from 'interfaces/Profile/Profile';
import {
  AttachedGroup,
  AttachedInterventions,
  clientProfilesInterface,
  clientRecordsInterface
} from 'interfaces/Clients/clientsRecord';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import { PathwayStageList } from '../InterventionDetails/components/InterventionContent/components/PathwayManagement/interface';

export enum InterventionStatus {
  Active = 'active',
  Closed = 'closed'
}

export enum AttachedClientRecordStatus {
  All = 'all',
  Active = 'active',
  Archived = 'archived',
  Completed = 'completed'
}

export enum InterventionStage {
  Referral = 'Referral',
  Induction = 'Induction',
  BridgingSupport = 'Bridging Support',
  BeforeProgram = 'Before Program',
  Session = 'Session 1 - 8',
  AfterPrograme = 'After Program',
  Withdrawn = 'Withdrawn'
}

export interface interventionListFilterParameter {
  status?: InterventionStatus;
  q: string;
}

export enum InterventionDetailsTab {
  Details = 'details',
  Members = 'members',
  Sessions = 'sessions',
  Psychometrics = 'psychometrics',
  Survey = 'survey',
  Consent = 'consent',
  CaseNotes = 'notes',
  Reports = 'reports',
  Attachments = 'attachments',
  Contacts = 'contacts',
  PractitionerAssigned = 'assignment',
  PathwayManagement = 'pathway'
}

export interface InterventionDetailsId {
  contentId?: string;
  interventionId: string;
  interventionTab: InterventionDetailsTab;
  childContentId?: string;
}

export interface InterventionPsychometricDetailsId extends InterventionDetailsId {
  clientRecordId: string;
  responseId: string;
}

export interface KeyContact {
  _id: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
  tags?: string[];
}

export interface LeadClinicianProfile {
  avatar?: string;
  title?: string;
  name: string;
  mobileNumber?: string;
  email?: string;
}

export interface Interventions {
  _id: string;
  status: InterventionStatus;
  interventionId?: string;
  interventionLibraryItemId: string;
  name: string;
  leadClinicianAuth0Id: string;
  leadClinicianProfile: LeadClinicianProfile;
  clinicianAuth0Ids: string[];
  description?: string;
  tags: InterventionTag[];
  clinicianProfiles: ProfileInterface[];
  keyContacts: KeyContact[];
  clientCount: number;
  createdAt: Date;
  updatedAt: Date;
  stagesConfiguration: PathwayStageList;
}

export interface GroupsWithSelected extends Interventions {
  isSelected?: boolean;
}

export interface GroupsLeadFromAPI extends LeadClinicianProfile {
  logo?: string;
}

export interface SdqRecommendation {
  scale: string;
  equals?: number;
  min?: number;
  max?: number;
}
export interface InterventionsFromAPI extends Omit<Interventions, 'tags' | 'leadClinicianProfile'> {
  tags: string[];
  leadClinicianProfile: GroupsLeadFromAPI;
  leadClinicianAuth0Id: string;
  sdqRecommendations?: SdqRecommendation[];
}

export interface InterventionListData {
  interventions: InterventionsFromAPI[];
  count: {
    active: number;
    closed: number;
  };
}

export interface InterventionTag {
  _id: string;
  label: string;
  accountId: string;
}

export interface ClientRecordWithGroupStatus extends clientRecordsInterface {
  groupStatus: Exclude<AttachedClientRecordStatus, AttachedClientRecordStatus.All>;
  groupStage: string;
  attachedGroups: AttachedGroup[];
  attachedInterventions?: AttachedInterventions[];
}

export interface InterventionMemberList {
  clientRecords: ClientRecordWithGroupStatus[];
  count: {
    active: number;
    completed: number;
    archived: number;
    all: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
  };
}

export interface InterventionMemberListFilterParameter {
  status?: string;
  q?: string;
  page?: string;
  perPage?: string;
  sortByClientName?: SortType;
  sortByDateJoined?: SortType;
}

export interface InterventionAssessmentClient {
  clientRecordId: string;
  clientProfiles: AssessmentClientProfile[];
}

export interface UnassignedInterventionMemberList {
  clientRecords: ClientRecordWithGroupStatus[];
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
  };
}

export interface UnassignedInterventionMemberListFilter {
  q: string;
  page: number;
  perPage: number;
  sortByClientName: SortType;
}

type FormSendStatus =
  | {
      isSent: true;
    }
  | {
      isSent: false;
      sendAt: string; // YYYY-MM-DD hh:mmA Z
    };

export type InterventionPsychometricMenu = FormSendStatus & {
  _id: string;
  clinicalAssessmentId: string;
  createdAt: string;
};

export interface ConsentFormInterface {
  _id: string;
  clientProfiles: {
    avatar: string;
    firstName: string;
    lastName: string;
    initials: string;
    initialsBackgroundColor: string;
    name: string;
  }[];
  recordType: 'adult' | 'couple' | 'minor' | 'group' | 'child';
  isFormSent: boolean;
  isFormSigned: boolean;
  dateSigned: string;
}

export interface ConsentFormListInterface {
  consentFormList: ConsentFormInterface[];
  count: {
    active: number;
    completed: number;
    archived: number;
    all: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
  };
}

export interface ConsentFormResponseList {
  _id: string;
  name: string;
  createdAt: string;
}

export interface ConsentFormListFilterParameter {
  status?: string;
  q?: string;
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortType?: SortType;
}

export interface GroupConsentForm {
  id: string;
  name: string;
}

export interface AssessmentClientProfile extends clientProfilesInterface {
  clientProfileId: string;
  assessmentSent: boolean;
  assessmentComplete: boolean;
  processedScore?: any;
}
