import { useField } from 'formik';

import { Option, Question } from '../../../../../../AssessmentInterfaces';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import EmojiButton from './components/EmojiButton/EmojiButton';

import styles from './Vote.module.scss';

interface VoteProps {
  question: Question;
}

const Vote = ({ question }: VoteProps) => {
  const [, { error, touched }] = useField(`${question.id}.value`);

  return (
    <>
      <div className={styles.container}>
        {(question.options as Option[]).map(({ key, description }) => (
          <EmojiButton key={key} label={description} name={key} questionId={question.id} />
        ))}
      </div>
      {typeof error === 'string' && <ErrorMessage error={error} visible={touched && !!error} />}
    </>
  );
};

export default Vote;
