import { useCallback, useEffect, useState } from 'react';
import { Groups, GroupsFromAPI, GroupTag } from '../../Interfaces/Groups';
import { getGroupDetailsByGroupId, getGroupTags } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { PathwayStageItem, StageType } from '../components/GroupContent/components/PathwayManagement/interface';

export const useFetchGroupDetails = (token: string, groupId: string, accountId: string) => {
  const [groupDetails, setGroupDetails] = useState<Groups>();
  const [groupTagList, setGroupTagList] = useState<GroupTag[]>([]);
  const [isGroupDetailsLoading, setIsGroupDetailsLoading] = useState(true);

  const fetchGroupDetails = useCallback(
    async (token: string) => {
      setIsGroupDetailsLoading(true);

      const getGroupData = await getGroupDetailsByGroupId(token, groupId);
      const groupDetailData: GroupsFromAPI = await getGroupData.json();

      const getGroupTagsData = await getGroupTags(token, accountId);
      const groupsTagsData: GroupTag[] = await getGroupTagsData.json();

      setGroupTagList(groupsTagsData);

      const massageSequentialStage = (stageData: PathwayStageItem[]) => {
        return stageData.map((stageObj, index) => ({
          ...stageObj,
          sequence: index + 1,
          stageType: StageType.Sequential
        }));
      };

      const MASSAGE_GROUP_DETAILS: Groups = {
        ...groupDetailData,
        leadClinicianProfile: {
          avatar: groupDetailData.leadClinicianProfile.avatar || groupDetailData.leadClinicianProfile.logo,
          title: groupDetailData.leadClinicianProfile.title || '',
          name: groupDetailData.leadClinicianProfile.name,
          mobileNumber: groupDetailData.leadClinicianProfile.mobileNumber || '',
          email: groupDetailData.leadClinicianProfile.email || ''
        },
        stagesConfiguration: {
          anytime: groupDetailData.stagesConfiguration?.anytime || [],
          sequential: groupDetailData.stagesConfiguration?.sequential
            ? massageSequentialStage(groupDetailData.stagesConfiguration.sequential)
            : []
        },
        tags: groupDetailData.tags
          .map((tagsObj) => groupsTagsData.find(({ _id }) => _id === tagsObj))
          .filter((val): val is GroupTag => val !== undefined)
      };

      setGroupDetails(MASSAGE_GROUP_DETAILS);
      setIsGroupDetailsLoading(false);
    },
    [groupId, accountId]
  );

  useEffect(() => {
    if (token) {
      fetchGroupDetails(token);
    }
  }, [fetchGroupDetails, token]);

  return { groupDetails, isGroupDetailsLoading, groupTagList, fetchGroupDetails };
};
