import { useEffect, useState } from 'react';
import { getHeadingTemplate } from '../http/DocumentService/Reports/reports';
import { notification } from 'antd';
import { useFetchAppointmentsByPatient } from './appointment';
import moment from 'moment';
import Handlebars from 'handlebars';
import { toPng } from 'html-to-image';
import { useFetchAssessmentByRecordProfileId } from './GetAssessmentList/assessment';
import { useTranslation } from 'react-i18next';
import { useFetchPractitionerList } from '../../components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useFetchClientRecordById } from '../../pages/PatientDetails/hooks/GetClientRecord';

export const useFetchHeadingTemplate = (token: string) => {
  const [headingTemplateData, setHeadingTemplateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [t] = useTranslation();

  const fetchHeadingTemplate = async () => {
    setIsLoading(true);

    try {
      const getHeadingTemplateResponse = await getHeadingTemplate(token);

      const headingTemplate = await getHeadingTemplateResponse.json();

      setHeadingTemplateData(headingTemplate);
    } catch (ex) {
      notification.error({ message: t('form.error.fetch_practitioner_heading_template') });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchHeadingTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { headingTemplateData, isLoading, fetchHeadingTemplate };
};

export const fixHeadingTemplateBodyString = (body: string) => {
  let fixedBody = body.replaceAll(/{{([a-zA-Z\d-_.]+)}}/gm, `##$1##`);
  fixedBody = fixedBody.replaceAll('{', '').replaceAll('}', '');
  fixedBody = fixedBody.replaceAll(/##([a-zA-Z\d-_.]+)##/gm, `{{$1}}`);
  return fixedBody;
};

export const useGenerateHeadingTemplateDetails = (token: string, recordId: string, profileId: string) => {
  const { appointmentsData, fetchAppointment } = useFetchAppointmentsByPatient(
    '2020-01-01',
    moment(new Date()).add(3, 'months').format('YYYY-MM-DD'),
    token,
    recordId
  ) as any;
  const { practitionersList, fetchPractitionersList } = useFetchPractitionerList(token, true, [
    AccessRight.Admin,
    AccessRight.User
  ]);
  const { clientRecordData, fetchClientRecord } = useFetchClientRecordById(token, recordId);
  const { assessmentList, isLoadingAssessmentList, fetchAssessment } = useFetchAssessmentByRecordProfileId(
    token,
    recordId
  );

  const generateAssessment = async (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      try {
        const dataUrl = await toPng(element);
        return `<img src="${dataUrl}" width="300" alt="assessment" />`;
      } catch (ex) {
        console.error(ex);
      }
    }
  };

  const generateHeadingTemplate = async (body: string) => {
    const fixedBody = fixHeadingTemplateBodyString(body);
    const regex1 = /<span[^<>]*class="mention"[^<>]*>(.*?)<\/span>{{|{{/gm;
    const regex2 = /}}<\/span>\ufeff<\/span>|}}/gm;
    const generateBodyPart1 = fixedBody.replace(regex1, '{{{[');
    const generateBodyPart2 = generateBodyPart1.replace(regex2, ']}}}');
    const headingTemplate = Handlebars.compile(generateBodyPart2);
    const firstAssessment = await generateAssessment('firstAssessment');
    const mostRecentAssessment = await generateAssessment('mostRecentAssessment');

    const firstAppointDate =
      appointmentsData.length > 0 ? moment(appointmentsData[0].createTime).format('dddd DD MMMM YYYY \\at hh:mmA') : '';
    const lastAppointDate =
      appointmentsData.length > 0
        ? moment(appointmentsData[appointmentsData.length - 1].createTime).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';
    const firstAssessmentDate =
      assessmentList.length > 0 ? moment(assessmentList[0].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA') : '';
    const lastAssessmentDate =
      assessmentList.length > 0
        ? moment(assessmentList[assessmentList.length - 1].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';

    const clientProfileData = clientRecordData.clientProfiles.filter((cpObj) => cpObj._id === profileId)[0];

    const getPractitionerId =
      clientRecordData.clinicianAuth0Ids.length > 0 ? clientRecordData.clinicianAuth0Ids[0] : '';
    const getPractitionerDetails = practitionersList.find(
      (practitionerObj) => practitionerObj._id === getPractitionerId
    );
    const getPractitionerName = getPractitionerDetails?.name || '';

    const variableList = {
      CLIENT_NAME: `${clientProfileData.firstName} ${clientProfileData.lastName}`,
      CLIENT_DOB: moment(clientProfileData.dateOfBirth, 'DD-MM-YYYY').format('D MMM YYYY'),
      CLIENT_EMAIL: clientProfileData.email,
      CLIENT_MOBILE: clientProfileData.mobileNumber,
      FIRST_APPOINTMENT_DATE: firstAppointDate,
      RECENT_APPOINTMENT_DATE: lastAppointDate,
      APPOINTMENT_COUNT: appointmentsData.length,
      FIRST_ASSESSMENT: firstAssessment,
      FIRST_ASSESSMENT_DATE: firstAssessmentDate,
      RECENT_ASSESSMENT: mostRecentAssessment,
      RECENT_ASSESSMENT_DATE: lastAssessmentDate,
      PRACTITIONER_NAME: getPractitionerName
    };

    return headingTemplate(variableList);
  };

  return {
    assessmentList,
    fetchAppointment,
    isLoadingAssessmentList,
    fetchAssessment,
    fetchPractitionersList,
    fetchClientRecord,
    generateHeadingTemplate
  };
};
