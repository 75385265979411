import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { getClinicalAssessmentByAssessmentId } from 'utils/http/CheckInService/Assessment/clinicalAssessment';

export const useFetchPsychometricDetails = (token: string, psychometricId: string, clientRecordId: string) => {
  const [psychometricDetails, setPsychometricDetails] = useState<Assessment>();
  const [isPsychometricDetailsLoading, setPsychometricDetailsLoading] = useState(true);

  const fetchPsychometricDetails = useCallback(
    async (token: string) => {
      try {
        setPsychometricDetailsLoading(true);
        const getPsychometricDetailData = await getClinicalAssessmentByAssessmentId(
          token,
          psychometricId,
          `clientRecordId=${clientRecordId}`
        );
        const psychometricDetailData: Assessment = await getPsychometricDetailData.json();
        setPsychometricDetails(psychometricDetailData);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your groups psychometric details' });
      }
      setPsychometricDetailsLoading(false);
    },
    [psychometricId, clientRecordId]
  );

  return { psychometricDetails, isPsychometricDetailsLoading, fetchPsychometricDetails };
};
