import PolicyContainer from './PolicyContainer';

const TermsAndConditions = () => (
  <PolicyContainer>
    <iframe
      title={'cordsTermsOfUse'}
      height={'300px'}
      width={'100%'}
      frameBorder={'0'}
      src={'https://storage.googleapis.com/tacklit-static-files-dev/cords/terms_and_condition.html'}
    />
  </PolicyContainer>
);

export default TermsAndConditions;
