import { BentResponse } from 'bent';
import { CronofyTokenExchangeBody, PostUnlinkCalendarBody, PutCalendarConfigsBody } from 'interfaces/Schedule/Cronofy';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getCronofyProfiles = (token: string, queryString?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/cronofy/profiles${queryString}`
  ) as Promise<BentResponse>;

export const postCronofyToken = (token: string, body: CronofyTokenExchangeBody) =>
  httpClient('POST', url!, [201, 400], { Authorization: `Bearer ${token}` })(
    '/cronofy/exchangeToken',
    body
  ) as Promise<BentResponse>;

export const putCalendarConfig = (token: string, body: PutCalendarConfigsBody) =>
  httpClient('PUT', url!, 202, { Authorization: `Bearer ${token}` })(
    '/cronofy/putCalendarConfigs',
    body
  ) as Promise<BentResponse>;

export const postUnlinkCalendar = (token: string, body: PostUnlinkCalendarBody) =>
  httpClient('POST', url!, 202, { Authorization: `Bearer ${token}` })(
    '/cronofy/unlinkCalendar',
    body
  ) as Promise<BentResponse>;
