import { Modal } from 'antd';
import styles from './ConsentDetailsModal.module.scss';
import moment from 'moment';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useTranslation } from 'react-i18next';

interface ConsentDetailsModalProps {
  onCancel?: () => void;
  visible: boolean;
  consentFormDetail: ConsentFormListInterface;
  clientName: string;
}

const ConsentDetailsModal = ({ onCancel, visible, consentFormDetail, clientName }: ConsentDetailsModalProps) => {
  const [t] = useTranslation();
  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.title}>{consentFormDetail.name}</div>}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <div className={styles.container}>
        <div className={styles.practiceInfoWrapper}>
          {consentFormDetail.practice?.logo && (
            <img className={styles.image} src={consentFormDetail.practice?.logo} alt="Practice logo" />
          )}
          <div className={styles.practiceDetails}>
            <div className={styles.name}>{consentFormDetail.practice?.name}</div>
            {consentFormDetail.practice?.locations && consentFormDetail.practice?.locations.length > 0 && (
              <div className={styles.detail}>{consentFormDetail.practice?.locations[0]}</div>
            )}
            {consentFormDetail.practice?.mobileNumber && (
              <div className={styles.detail}>{consentFormDetail.practice?.mobileNumber}</div>
            )}
          </div>
        </div>
        <div className={styles.clientInfoWrapper}>
          <div>
            <span className={styles.clientLabel}>{t('form.client_name')}:</span> <span>{clientName}</span>
          </div>
          <div>
            <span className={styles.clientLabel}>Date of Consent Form:</span>{' '}
            <span>{moment().format(MOMENTJS_DATE_FORMAT)}</span>
          </div>
        </div>
        <div className={styles.contentBodyWrapper}>
          <div dangerouslySetInnerHTML={{ __html: consentFormDetail.body }} />
        </div>
        <div className={styles.signatureContainer}>
          {consentFormDetail.signature && consentFormDetail.signature.client && (
            <div className={styles.signatureWrapper}>
              <div className={styles.signLabel}>{t('form.client_signed')}:</div>
              {consentFormDetail.signature.client?.type && (
                <div className={styles.previewSignature}>
                  <div className={styles.signature}>
                    {consentFormDetail.signature.client.type === 'typeVer' ? (
                      consentFormDetail.signature.client.textVal
                    ) : (
                      <img
                        className={styles.signatureImg}
                        alt={'signature'}
                        src={consentFormDetail.signature.client.drawVal}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {consentFormDetail.signature && consentFormDetail.signature.other && (
            <div className={styles.signatureWrapper}>
              <div className={styles.signLabel}>Parent or Guardian Signed:</div>
              {consentFormDetail.signature.other?.type && (
                <div className={styles.previewSignature}>
                  <div className={styles.signature}>
                    {consentFormDetail.signature.other.type === 'typeVer' ? (
                      consentFormDetail.signature.other.textVal
                    ) : (
                      <img
                        className={styles.signatureImg}
                        alt={'signature'}
                        src={consentFormDetail.signature.other.drawVal}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConsentDetailsModal;
