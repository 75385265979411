import { Route, Routes } from 'react-router-dom';
import { useGetAccountPackage } from '../utils/hooks/GetAccountInfo/accountPackage';
import AppRoutes from './AppRoutes';
import RedirectTo404 from '../components/RedirectTo404/RedirectTo404';
import TeacherRoutes from './User/TeacherRoutes';
import AssessorAssessments from 'pages/AssessorAssessments/AssessorAssessments';

const AuthRoutes = () => {
  const { isEdgeAdminUser, isEdgeReceptionist, userAccessConfig, isMentorUser } = useGetAccountPackage();
  switch (userAccessConfig.plan) {
    case 'edge':
      return isEdgeAdminUser ? (
        <Routes>
          <Route path="/admin/*" element={<AppRoutes />} />
          <Route path="/user/*" element={<AppRoutes />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      ) : isEdgeReceptionist ? (
        <Routes>
          <Route path="/receptionist/*" element={<AppRoutes />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      ) : isMentorUser ? (
        <Routes>
          <Route path="/*" element={<TeacherRoutes />} />
          <Route path="/user/assessments" element={<AssessorAssessments />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/user/*" element={<AppRoutes />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      );
    default:
      return (
        <Routes>
          <Route path="/" element={<AppRoutes />} />
        </Routes>
      );
  }
};

export default AuthRoutes;
