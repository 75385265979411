import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './InterventionsList.module.scss';
import InterventionListHeader from './components/InterventionListHeader/InterventionListHeader';
import InterventionListContent from './components/InterventionListContent/InterventionListContent';

import { interventionListFilterParameter, InterventionStatus } from 'MHH/pages/Interventions/Interfaces/Interventions';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchInterventionList } from './hooks/getInterventionList';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import queryString from 'query-string';
import CreateInterventionModal from './components/CreateInterventionModal/CreateInterventionModal';

const InterventionsList = () => {
  const { token } = useGetAccessToken();
  const { INTERVENTIONS } = useRoutesGenerator();
  const navigate = useNavigate();
  const path = useParams<{ tabId: InterventionStatus }>();
  const location = useLocation();

  const queryParam: {
    q?: string;
  } = queryString.parse(location.search);

  const [showCreateNewIntervention, setShowCreateNewIntervention] = useState(false);

  const [filterStatus, setFilterStatus] = useState<InterventionStatus>(path.tabId ?? InterventionStatus.Active);

  const [pageLoading, setPageLoading] = useState(false);

  const filterValue: interventionListFilterParameter = {
    status: filterStatus,
    q: queryParam.q || ''
  };

  const { interventionList, isInterventionListLoading, fetchInterventionList } = useFetchInterventionList(token);

  const onChangeTabFilter = async (tabValue: InterventionStatus) => {
    if (tabValue !== filterStatus) {
      setPageLoading(true);
      setFilterStatus(tabValue);
      const newQParam = {
        ...(filterValue.q && {
          q: filterValue.q
        })
      };

      const qParam = queryString.stringify(newQParam);
      navigate(`${INTERVENTIONS.BASE}/${tabValue}${qParam && tabValue ? `?${qParam}` : ''}`);
      setPageLoading(false);
    }
  };

  const onHandleSearch = (q: string) => {
    navigate(`${INTERVENTIONS.BASE}/${filterStatus}${q ? `?q=${q}` : ''}`);
  };

  const onResetModal = () => {
    fetchInterventionList(token);
    setShowCreateNewIntervention(false);
  };

  return (
    <HelmetWrapper title={'CORDS - Interventions'}>
      <ContentLayout>
        <div className={styles.container}>
          <InterventionListHeader onCreateInterventionClick={() => setShowCreateNewIntervention(true)} />
          <InterventionListContent
            interventionList={interventionList}
            isInterventionListLoading={isInterventionListLoading}
            onChangeTabFilter={onChangeTabFilter}
            onHandleSearch={onHandleSearch}
            isPageLoading={pageLoading}
            interventionStatus={filterStatus}
            searchValue={filterValue.q}
          />
        </div>
        <CreateInterventionModal
          visible={showCreateNewIntervention}
          onReset={onResetModal}
          onCloseModal={() => setShowCreateNewIntervention(false)}
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InterventionsList;
