import { Modal, notification } from 'antd';
import location from 'assets/images/location.svg';
import classnames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { postLocation } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './AddLocationModal.module.scss';

interface AddLocationModalProps {
  visible: boolean;
  setShowAddLocationModal: (bool: boolean) => void;
  refetchMedicareLocations: () => void;
}

const AddLocationModal = ({ visible, setShowAddLocationModal, refetchMedicareLocations }: AddLocationModalProps) => {
  const { token } = useGetAccessToken();
  const [locationName, setLocationName] = useState('');
  const [address, setAddress] = useState('');
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [validationErrors, setValidationErrors] = useState({ locationName: false, agreement: false });
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');

  const initForm = () => {
    setLocationName('');
    setAddress('');
    setAgreementChecked(false);
    setValidationErrors({ locationName: false, agreement: false });
  };

  const handleCloseModal = () => {
    setShowAddLocationModal(false);
    initForm();
  };

  const onChangeLocationName = (value: string) => {
    if (validationErrors.locationName && value.length > 2 && value.length < 501) {
      setValidationErrors({ ...validationErrors, locationName: false });
    }
    setLocationName(value);
  };

  const onChangeAgreementChecked = () => {
    if (validationErrors.agreement) {
      setValidationErrors({ ...validationErrors, agreement: false });
    }
    setAgreementChecked(!agreementChecked);
  };

  const onSubmit = async () => {
    setSubmitStatus('active');
    const locationNameError = locationName === '' || locationName.length < 3 || locationName.length > 500;
    if (locationNameError || !agreementChecked) {
      setValidationErrors({
        locationName: locationNameError,
        agreement: !agreementChecked
      });

      setSubmitStatus('');
      return;
    }

    try {
      await postLocation(token, { name: locationName, address: address === '' ? undefined : address });

      notification.success({
        message: 'Location added',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      handleCloseModal();
      refetchMedicareLocations();

      setSubmitStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to add location' });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      visible={visible}
      closable
      title={<div className={styles.title}>Add Location</div>}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.descriptionsContainer}>
          <img src={location} alt={'location'} className={styles.icon} />
          <div className={styles.instructionsContainer}>
            <div className={styles.title}>To Generate Location ID</div>
            <div className={styles.instructions}>
              <div>1. Name your delivery location.</div>
              <div>2. Include address of the location.</div>
            </div>
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.locationNameInput}>
            <MaterialInput
              id={'locationName'}
              label={'Location Name*'}
              value={locationName}
              onChange={(e) => onChangeLocationName(e.target.value)}
              required
            />
            {validationErrors.locationName && (
              <div className={styles.error}>Please enter the location name (3 - 500 characters)</div>
            )}
          </div>
          <MaterialInput
            id={'locationAddres'}
            label={'Address'}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div className={styles.agreementContainer}>
          <CheckBox
            className={styles.checkbox}
            id={'agreement'}
            value={agreementChecked}
            onChange={onChangeAgreementChecked}
          />
          <div>
            <div
              className={classnames(styles.statement, agreementChecked ? styles.checked : styles.unchecked)}
              onClick={onChangeAgreementChecked}
            >
              I confirm I wish to generate a location ID connected to Tacklit for this location, the location
              information is accurate and I have authority to create this new location ID.
            </div>
            {validationErrors.agreement && (
              <div className={styles.error}>Please check this box if you wish to generate location ID</div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <Button className={styles.button} onClick={onSubmit} status={submitStatus}>
            Generate Location ID
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddLocationModal;
