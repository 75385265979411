import acal from 'assets/images/acal.svg';
import exchange from 'assets/images/exchange.svg';
import gcal from 'assets/images/gcal.svg';
import medicare from 'assets/images/medicare.svg';
import outlook from 'assets/images/outlook.svg';
import quickBooks from 'assets/images/quickbooks.svg';
import stripe from 'assets/images/stripe.svg';
import xero from 'assets/images/xero.svg';

export const PROVIDER_ICON = {
  google: gcal,
  live_connect: outlook,
  exchange,
  apple: acal,
  xero,
  quickBooksOnline: quickBooks,
  stripe,
  medicare
};

export const PROVIDER_LABEL = {
  google: 'Google Calendar',
  live_connect: 'Outlook Calendar',
  exchange: 'Exchange Calendar',
  apple: 'Apple iCal',
  xero: 'Xero',
  quickBooksOnline: 'QuickBooks',
  stripe: 'Stripe',
  medicare: 'Medicare'
};

export const CALENDAR_INTEGRATIONS = [
  {
    icon: PROVIDER_ICON.google,
    provider: 'google',
    label: PROVIDER_LABEL.google,
    type: 'calendar',
    description: 'Connect your Google calendar to Tacklit to manage your schedule and availability in one place',
    summary:
      'Google calendar - Her eyebrows were a shade darker than her hair. They were thick and almost horizontal, emphasizing the depth of her eyes. She was rather handsome than beautiful. Her face was captivating by reason of a certain frankness of expression and a contradictory subtle play of features. Her manner was engaging.'
  },
  {
    icon: PROVIDER_ICON.live_connect,
    provider: 'live_connect',
    label: PROVIDER_LABEL.live_connect,
    type: 'calendar',
    description: 'Connect your Google calendar to Tacklit to manage your schedule and availability in one place',
    summary:
      'Outlook - He wandered down the stairs and into the basement. The damp, musty smell of unuse hung in the air. A single, small window let in a glimmer of light, but this simply made the shadows in the basement deeper. He inhaled deeply and looked around at a mess that had been accumulating for over 25 years. He was positive that this was the place he wanted to live.'
  },
  {
    icon: PROVIDER_ICON.exchange,
    provider: 'exchange',
    label: PROVIDER_LABEL.exchange,
    type: 'calendar',
    description: 'Connect your Google calendar to Tacklit to manage your schedule and availability in one place',
    summary:
      " Exchange - At that moment, she realized that she had created her current life. It wasn't the life she wanted, but she took responsibility for how it currently stood. Something clicked and she saw that every choice she made to this point in her life had led to where her life stood at this very moment even if she knew this wasn't where she wanted to be. She determined to choose to change it."
  },
  {
    icon: PROVIDER_ICON.apple,
    provider: 'apple',
    type: 'calendar',
    label: PROVIDER_LABEL.apple,
    description: 'Connect your Google calendar to Tacklit to manage your schedule and availability in one place',
    summary:
      "Apple - The box sat on the desk next to the computer. It had arrived earlier in the day and business had interrupted her opening it earlier. She didn't who had sent it and briefly wondered who it might have been. As she began to unwrap it, she had no idea that opening it would completely change her life."
  }
];

export const FINANCE_INTEGRATIONS = [
  {
    icon: PROVIDER_ICON.stripe,
    provider: 'stripe',
    label: PROVIDER_LABEL.stripe,
    type: 'finance',
    description: 'Connect Tacklit to a secure checkout to allow clients to pay on booking or via invoice',
    summary:
      'Stripe accepts one-off payments by giving your customers their preferred payment options through Stripe.  Integrate into your Tacklit booking flow and invoicing with ease, making it fast and simple for your clients to pay. Start accepting payments from all major credit, debit and digital wallets in minutes.'
  },
  {
    icon: PROVIDER_ICON.xero,
    provider: 'xero',
    label: PROVIDER_LABEL.xero,
    type: 'account',
    description: 'Connect Xero to Tacklit to automate invoicing, payments and appointment reconciliation',
    summary:
      'Xero is an all-in-one accounting software. Xero enables you to see your invoices, contacts and accounts online in one place. Connect and set up bank feeds from your bank accounts so transactions are imported securely into Xero each business day. Use Xero to collaborate in real time and work with your accountant, bookkeeper or employees anywhere by accessing the same up-to-date information at the same time.'
  },
  {
    icon: PROVIDER_ICON.quickBooksOnline,
    provider: 'quickBooksOnline',
    label: PROVIDER_LABEL.quickBooksOnline,
    type: 'account',
    description: 'Connect QuickBooks to Tacklit to automate all your accounting and finance data',
    summary:
      'QuickBooks is an accounting software that helps to track expenses, manage cash flow, customise invoices, run financial reports and more - all in one place. Data is stored in the cloud that allows you to access anytime and allows you to run your business from any device. It builds better business insights that create reports and collaborate with your advisor to see how your business is doing and get ready for tax time.'
  }
];

export const CLAIM_INTEGRATIONS = [
  {
    icon: PROVIDER_ICON.medicare,
    provider: 'medicare',
    label: PROVIDER_LABEL.medicare,
    type: 'claim',
    description: 'Connect Tacklit to Medicare to allow your client to do the claim from Medicare.',
    summary:
      'The quickest and easiest way to claim for your client after they pay. You need the client to show you their medicare card and make the electronic claim on the spot. This integration will help you to process the claim to medicare as soon as possible and pay your client Medicare benefit to your client account for the EFTPOS card that the client used to pay or the bank account they’ve registered with Medicare.'
  }
];
