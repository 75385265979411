import { notification } from 'antd';
import { CronofyProfile } from 'interfaces/Cronofy/cronofyProfile';
import { useEffect, useState } from 'react';
import { getCronofyProfiles } from 'utils/http/ScheduleService/Cronofy/Cronofy';

import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';

export const useFetchCronofyProfiles = (token: string) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [cronofyProfiles, setCronofyProfiles] = useState<CronofyProfile[]>([]);
  const [isCronofyProfilesLoading, setIsCronofyProfilesLoading] = useState(true);
  const fetchCronofyProfiles = async (token: string) => {
    setIsCronofyProfilesLoading(true);

    try {
      const qParam = `${isEdgeAdminView ? `?asAdmin=true` : ''}`;
      const callCronofyProfiles = await getCronofyProfiles(token, qParam);
      const cronofyProfilesData = await callCronofyProfiles.json();

      setCronofyProfiles(cronofyProfilesData);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to fetch your calendars' });
    }

    setIsCronofyProfilesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCronofyProfiles(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { cronofyProfiles, isCronofyProfilesLoading, fetchCronofyProfiles };
};
