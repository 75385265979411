import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentsByClincianIdOrRoomId } from 'utils/http/ScheduleService/Appointments/Appointments';
import momentTz from 'moment-timezone';
import { MbscPageLoadingEvent } from '@mobiscroll/react';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import { notification } from 'antd';
import { debounce } from 'lodash';
import { getTimeZoneDateTime } from 'utils/hooks/GetTimezones/getTimezones';
import { CustomCalendarEvent } from '../components/CalendarEvent/CalendarEvent';

interface SelectedFilters {
  clinicianIds: string[];
  roomIds: string[];
}

const PRE_FETCH_WEEKS = 1;

const REFETCH_AFTER_MINUTES = 10;

export const useAppointmentEvents = (
  selectedFilters: CalendarFilterInterface[],
  timezone: string,
  calendarDate?: MbscPageLoadingEvent
) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [showFetchingNotif, setShowFetchingNotif] = useState(true);
  const [appointments, setAppointments] = useState<{ [key: string]: { [key: string]: AppointmentSlots[] } }>({});
  const [refreshIntervalId, setRefreshIntervalId] = useState<NodeJS.Timeout>();
  const [events, setEvents] = useState<CustomCalendarEvent[]>([]);
  const { auth0ClinicianId } = useGetClinicianId();

  useEffect(() => {
    return () => {
      if (refreshIntervalId) {
        clearInterval(refreshIntervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refCurrentFilterFetchedCalendarDates = useRef<string[]>([]);
  useEffect(() => {
    refCurrentFilterFetchedCalendarDates.current = [];
  }, [selectedFilters]);

  const getAllAppointmentsByFilter = useCallback(
    ({ roomIds, clinicianIds }: { roomIds?: string[]; clinicianIds?: string[] }): AppointmentSlots[] => {
      const res = [
        ...(roomIds || [])
          .concat(clinicianIds || [])
          .map((i) => appointments[i])
          .concat(clinicianIds?.includes('') ? [appointments[auth0ClinicianId]] : [])
          .filter((i) => i)
          .map((obj) => Object.values(obj).reduce((biglist, list) => [...biglist, ...list], []))
          .reduce((biglist, list) => [...biglist, ...list], [])
      ];
      return res.filter((item, index) => index === res.findIndex((i) => i._id === item._id));
    },
    [appointments, auth0ClinicianId]
  );

  const updateCalendar = useCallback(
    ({
      appts,
      from,
      to,
      roomIds,
      clinicianIds
    }: {
      appts: (AppointmentSlots & { isRoomFilter?: boolean })[];
      from: string;
      to: string;
      roomIds: string[];
      clinicianIds: string[];
    }) => {
      setAppointments((appointments) => {
        roomIds.forEach((i) => {
          let filteredAppts = appts.filter((item) => item.isRoomFilter && item.room?.roomId === i);
          appointments[i] = { ...appointments[i], [`${from}-${to}`]: filteredAppts };
        });

        let otherClinicianAppts: AppointmentSlots[] = [];

        clinicianIds
          .filter((id) => id !== auth0ClinicianId && id !== '')
          .forEach((i) => {
            let filteredAppts = appts.filter((item) => !item.isRoomFilter && item.clinicianId === i);
            appointments[i] = { ...appointments[i], [`${from}-${to}`]: filteredAppts };
            otherClinicianAppts = [...otherClinicianAppts, ...filteredAppts];
          });
        return clinicianIds.includes(auth0ClinicianId) || clinicianIds.includes('')
          ? {
              ...appointments,
              [auth0ClinicianId]: {
                ...appointments[auth0ClinicianId],
                [`${from}-${to}`]: appts.filter((item) => !item.isRoomFilter && !otherClinicianAppts.includes(item))
              }
            }
          : {
              ...appointments
            };
      });
    },
    [auth0ClinicianId]
  );

  useEffect(() => {
    const { clinicianIds, roomIds } = selectedFilters.reduce(
      (acc, crr) => {
        const key: keyof SelectedFilters = !crr.isClinician ? 'roomIds' : 'clinicianIds';
        acc[key].push(crr._id);

        return acc;
      },
      { clinicianIds: [], roomIds: [] } as SelectedFilters
    );
    const getGroup = (appointment: any) => {
      let result;
      if (appointment.isRoomFilter) {
        result = selectedFilters.findIndex((filter) => {
          return !filter.isClinician && filter._id === appointment.room.roomId;
        });
      } else if (appointment.groupId) {
        result = selectedFilters.findIndex((filter) => {
          return (
            appointment.group?.leadClinicianAuth0Id === filter._id ||
            filter._id === '' ||
            appointment.group?.clinicianAuth0Ids.filter((clinicianId: string) => clinicianId === filter._id).length > 0
          );
        });
      } else {
        result = selectedFilters.findIndex((filter) => {
          return filter.isClinician && (filter._id === appointment.clinicianId || filter._id === appointment.accountId);
        });
      }

      return result;
    };
    const formattedEvents: (CustomCalendarEvent | any)[] =
      getAllAppointmentsByFilter({ roomIds, clinicianIds })?.map((appointment, index) => {
        const group = getGroup(appointment);

        return {
          key: `${appointment._id}_${group}`,
          id: `${appointment._id}_${index}`,
          start: getTimeZoneDateTime(`${appointment.date} ${appointment.startTime}`, timezone),
          end: getTimeZoneDateTime(`${appointment.date} ${appointment.endTime}`, timezone),
          title: appointment.title,
          profile: appointment?.clientRecord?.clientProfiles?.[0],
          clientRecord: appointment?.clientRecord,
          deliveryType: appointment.deliveryType,
          group,
          room: appointment.room?.roomName,
          profileName: appointment.integration?.profileName,
          type: appointment.type,
          groupId: appointment.groupId,
          groupTitle: appointment.group?.name,
          gap: appointment.gap
        };
      }) || [];
    setEvents(formattedEvents);
  }, [getAllAppointmentsByFilter, selectedFilters, timezone]);

  const showFetchingNotification = () => {
    notification.destroy();
    notification.warning({
      message: 'Fetching calendar events...',
      closeIcon: <span className={'notify'}>OK</span>
    });
  };

  const getAppointmentsAPI = (from: string, to: string, roomIds: string[], clinicianIds: string[]) => {
    getAppointmentsByClincianIdOrRoomId(token, accountId, {
      from,
      to,
      roomIds,
      clinicianIds
    })
      .then((response) => response.json())
      .then((appts: any[]) => {
        if (Array.isArray(appts)) {
          updateCalendar({ appts: appts, from, to, roomIds, clinicianIds });
          if (!refCurrentFilterFetchedCalendarDates.current.includes(from)) {
            refCurrentFilterFetchedCalendarDates.current = refCurrentFilterFetchedCalendarDates.current.concat(from);
          }
        }
      });
  };

  const fetchAppointments = (force?: boolean) => {
    const forceUpdate = Boolean(force);
    if (forceUpdate) {
      setShowFetchingNotif(true);
      refCurrentFilterFetchedCalendarDates.current = [];
    }
    const from = momentTz(calendarDate!.firstDay).format('YYYY-MM-DD');
    const to = momentTz(calendarDate!.firstDay).add(7, 'day').format('YYYY-MM-DD');
    const { clinicianIds, roomIds } = selectedFilters.reduce(
      (acc, crr) => {
        const key: keyof SelectedFilters = !crr.isClinician ? 'roomIds' : 'clinicianIds';
        acc[key].push(crr._id);

        return acc;
      },
      { clinicianIds: [], roomIds: [] } as SelectedFilters
    );

    if (showFetchingNotif) {
      showFetchingNotification();
      setShowFetchingNotif(false);
      getAppointmentsAPI(from, to, roomIds, clinicianIds);
    } else {
      getAppointmentsAPI(from, to, roomIds, clinicianIds);
    }

    if (refreshIntervalId) {
      clearInterval(refreshIntervalId);
    }
    const intervalId = setInterval(() => {
      getAppointmentsAPI(from, to, roomIds, clinicianIds);
    }, 1000 * 60 * REFETCH_AFTER_MINUTES);
    setRefreshIntervalId(intervalId);

    let preFetchDays: string[] = [];

    for (let i = -PRE_FETCH_WEEKS; i <= PRE_FETCH_WEEKS; i++) {
      if (i !== 0) {
        preFetchDays = [...preFetchDays, momentTz(calendarDate!.firstDay).add(i, 'week').format('YYYY-MM-DD')];
      }
    }
    preFetchDays = preFetchDays.filter((item) => !refCurrentFilterFetchedCalendarDates.current.includes(item));
    preFetchDays.forEach((day) =>
      getAppointmentsAPI(day, momentTz(day).add(7, 'day').format('YYYY-MM-DD'), roomIds, clinicianIds)
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAppointmentsWithDebounce = useCallback(debounce(fetchAppointments, 300), [
    calendarDate,
    token,
    accountId,
    selectedFilters
  ]);

  useEffect(() => {
    if (calendarDate && token && accountId && selectedFilters.length) {
      fetchAppointmentsWithDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDate, token, accountId, selectedFilters]);

  return {
    updateCalendar,
    appointments,
    getAllAppointmentsByFilter,
    events,
    fetchAppointmentsWithDebounce
  };
};
