import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { Link } from 'react-router-dom';
import { combineName } from 'utils/general';
import styles from './SurveyFormDetailHeader.module.scss';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

interface SurveyFormDetailHeaderProps {
  goBackLink: string;
  clientProfiles: clientRecordsInterface['clientProfiles'];
  title: string;
}

const SurveyFormDetailHeader = ({ clientProfiles, title, goBackLink }: SurveyFormDetailHeaderProps) => {
  return (
    <div className={styles.container}>
      <Link className={styles.backBtn} to={goBackLink}>
        <i className={`material-icons ${styles.icon}`}>chevron_left</i>
        Back
      </Link>
      <div className={styles.info}>
        <ClientAvatar fullAvatarBg clientData={clientProfiles} displayLimit={2} hideName />
        <div className={styles.names}>
          <div className={styles.profileName}>{combineName(clientProfiles)}</div>
          <div className={styles.psychometricName}>{title}</div>
        </div>
      </div>
      <div className={styles.rightContent} />
    </div>
  );
};

export default SurveyFormDetailHeader;
