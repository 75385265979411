import { HTMLAttributes } from 'react';
import styles from './SelectSurveyForms.module.scss';
import classnames from 'classnames';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { Divider, Skeleton } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import SubmitButton from 'components/v2/Button/Button';
import ClientOrGroupSelected from 'components/ClientOrGroupPickAssessment/components/ClientOrGroupSelected/ClientOrGroupSelected';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { FirstAssessmentResponse } from 'interfaces/checkInService/firstAssessment';
import { useFetchGroupSurveyList } from '../../hooks/getSurveyList';
import { useGetAccessToken } from 'utils/hooks/token';

interface SelectSurveyFormsProps {
  selectedGroup?: GroupsFromAPI;
  selectedSurveyForms: FirstAssessmentResponse[];
  onNextClick: () => void;
  onAddSelectedSurveyForms: (item: FirstAssessmentResponse) => void;
  onRemoveSelectedSurveyForms: (item: FirstAssessmentResponse) => void;
}

const SelectSurveyForms = ({
  selectedGroup,
  selectedSurveyForms,
  onNextClick,
  onAddSelectedSurveyForms,
  onRemoveSelectedSurveyForms,
  className
}: SelectSurveyFormsProps & HTMLAttributes<HTMLDivElement>) => {
  const { token } = useGetAccessToken();
  const { surveyList, isSurveyListLoading } = useFetchGroupSurveyList(token);

  const onSelectSurveyFormHandle = (isChecked: boolean, surveyForm: FirstAssessmentResponse) => {
    if (isChecked && selectedSurveyForms.length < 3) {
      onAddSelectedSurveyForms(surveyForm);
    } else {
      onRemoveSelectedSurveyForms(surveyForm);
    }
  };

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.surveyGroupSelect}>
        <ClientOrGroupSelected participationType={ParticipantType.Group} group={selectedGroup} />
        <Divider style={{ margin: 0 }} />
        <div className={styles.selectSurveyGroupWrapper}>
          <div className={styles.selectSurveyGroupTitle}>Select Forms</div>
          <div className={styles.selectSurveyGroupSubtitle}>You can add a maximum of 3</div>
          <div className={styles.surveyFormItems}>
            {isSurveyListLoading ? (
              <div className={styles.loadingContainer}>
                {[...Array(8)].map((obj, i) => (
                  <div key={i} className={styles.loadingBox}>
                    <Skeleton.Input active className={styles.loadingName} />
                  </div>
                ))}
              </div>
            ) : (
              <>
                {surveyList.map((surveyObj, index) => {
                  const isSelected = !!selectedSurveyForms.find((item) => item.id === surveyObj.id);
                  return (
                    <div key={index} className={styles.surveyFormItem}>
                      <CheckBox
                        id={`${surveyObj.id}`}
                        value={isSelected}
                        disabled={!isSelected && selectedSurveyForms.length >= 3}
                        onChange={(e) => onSelectSurveyFormHandle(e.target.checked, surveyObj)}
                      />
                      <div className={styles.surveyItemDetail}>
                        <label htmlFor={`${surveyObj.id}`}>
                          <div
                            className={classnames(
                              styles.surveyFormItemName,
                              isSelected ? styles.surveyFormItemChecked : ''
                            )}
                          >
                            {surveyObj.name}
                          </div>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.submitBtn}>
        <SubmitButton disabled={selectedSurveyForms.length < 1} onClick={onNextClick}>
          Next
        </SubmitButton>
      </div>
    </div>
  );
};

export default SelectSurveyForms;
