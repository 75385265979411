import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

import { getName } from 'utils/general';

import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import Button from 'components/v2/Button/Button';

import styles from './ClientProfileAttendeesList.module.scss';

interface ClientProfileAttendeesListProps {
  clientRecord: clientRecordsInterface;
  onAddClientProfile: (clientProfile: clientRecordsInterface['clientProfiles'][number]) => void;
  onBack: () => void;
}

const ClientProfileAttendeesList = ({
  clientRecord: { clientProfiles, recordType },
  onAddClientProfile,
  onBack
}: ClientProfileAttendeesListProps) => {
  return (
    <div className={styles.contactsContainer}>
      <div>
        <Button icon="chevron_left" variant="link" onClick={onBack}>
          BACK TO ALL
        </Button>
      </div>
      {clientProfiles.length ? (
        clientProfiles.map((clientProfile, index) => (
          <div className={styles.contactContainer} key={index}>
            <div className={styles.nameAndTags}>
              {recordType === 'child' && clientProfile.role !== 'child' ? (
                <>
                  <div>{getName(clientProfile)}</div>
                  <div className={styles.pill}>{clientProfile.role}</div>
                </>
              ) : (
                <ClientAvatar
                  key={index}
                  name={getName(clientProfile)}
                  initialsName={clientProfile.initials}
                  avatarUrl={clientProfile.avatar}
                  avatarSize={36}
                  initialsClassName={styles.initialName}
                  nameClassName={styles.clientName}
                  horizontal
                />
              )}
            </div>
            <div className={styles.nameAndTags}>
              <div>{clientProfile.email}</div>
              <Button
                variant="link"
                icon="add"
                className={styles.addButton}
                onClick={() => onAddClientProfile(clientProfile)}
              >
                Add
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div>All contacts have been added</div>
      )}
    </div>
  );
};

export default ClientProfileAttendeesList;
