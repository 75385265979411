export interface OptionInterface {
  label: string;
  value: string;
  subLabel?: string;
}

export interface TimeSlotInterface {
  startTime: string;
  endTime: string;
}

export interface ResultInterface {
  type: string;
  color: string;
  name: string;
  date: string;
  avatar: string;
  availableSlots: TimeSlotInterface[];
}

export const TIME_FORMAT = 'HH:mm';

export const TIME_RANGE_SLIDER = {
  STEP: 160,
  FRONT_BOUNDER: 0,
  BEHIND_BOUNDER: -320
};

export const OCCUPANCY_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' },
  { value: '6', label: '6+' },
  { value: '7', label: '7+' },
  { value: '8', label: '8+' }
];

export const DURATION_TIME_OPTIONS = [
  { label: 'Any', value: 'any' },
  { label: '30+', value: '30' },
  { label: '60+', value: '60' },
  { label: '90+', value: '90' }
];

export const APPOINTMENT_TYPES = [{ value: 'ALL', label: 'All' }];

export const DAY_OPTIONS = [
  { value: 'allDays', label: 'All days' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thurday', label: 'Thurday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' }
];

export const TIME_OPTIONS = [
  { value: 'allTimes', label: 'All times', subLabel: '' },
  { value: 'morning', label: 'Morning', subLabel: 'before 12PM' },
  { value: 'afternoon', label: 'Afternoon', subLabel: '12PM - 5PM' },
  { value: 'evening', label: 'Evening', subLabel: 'after 5PM' }
];

export const SORTED_BY_OPTIONS = [
  { label: 'A - Z', value: 'asc' },
  { label: 'NEXT AVAILABLE', value: 'available' }
];

export const PRACTITIONER_RESULT_TEMP = [
  {
    type: 'practitioner',
    avatar: '',
    color: 'pink',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'blue',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'green',
    name: 'Alpha',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'yellow',
    name: 'Beta',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'pink',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'blue',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'green',
    name: 'Alpha',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'practitioner',
    avatar: '',
    color: 'yellow',
    name: 'Beta',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '09:00',
        endTime: '10:00'
      }
    ]
  }
];

export const ROOM_RESULT_TEMP = [
  {
    type: 'room',
    avatar: '',
    color: 'pink',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '05:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'blue',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '06:00',
        endTime: '10:00'
      },
      {
        startTime: '10:15',
        endTime: '12:00'
      },
      {
        startTime: '13:00',
        endTime: '14:00'
      },
      {
        startTime: '14:15',
        endTime: '15:00'
      },
      {
        startTime: '15:30',
        endTime: '17:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'green',
    name: 'Alpha',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '10:15',
        endTime: '12:00'
      },
      {
        startTime: '13:00',
        endTime: '14:00'
      },
      {
        startTime: '14:15',
        endTime: '15:00'
      },
      {
        startTime: '15:30',
        endTime: '17:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'yellow',
    name: 'Beta',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:46',
        endTime: '10:00'
      },
      {
        startTime: '12:00',
        endTime: '17:00'
      },
      {
        startTime: '19:00',
        endTime: '21:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'pink',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '05:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'blue',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '06:00',
        endTime: '10:00'
      },
      {
        startTime: '10:15',
        endTime: '12:00'
      },
      {
        startTime: '13:00',
        endTime: '14:00'
      },
      {
        startTime: '14:15',
        endTime: '15:00'
      },
      {
        startTime: '15:30',
        endTime: '17:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'green',
    name: 'Alpha',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '10:15',
        endTime: '12:00'
      },
      {
        startTime: '13:00',
        endTime: '14:00'
      },
      {
        startTime: '14:15',
        endTime: '15:00'
      },
      {
        startTime: '15:30',
        endTime: '17:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'yellow',
    name: 'Beta',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:46',
        endTime: '10:00'
      },
      {
        startTime: '12:00',
        endTime: '17:00'
      },
      {
        startTime: '19:00',
        endTime: '21:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'pink',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '05:00',
        endTime: '10:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'blue',
    name: 'Lindsey Stroud',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '06:00',
        endTime: '10:00'
      },
      {
        startTime: '10:15',
        endTime: '12:00'
      },
      {
        startTime: '13:00',
        endTime: '14:00'
      },
      {
        startTime: '14:15',
        endTime: '15:00'
      },
      {
        startTime: '15:30',
        endTime: '17:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'green',
    name: 'Alpha',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:00',
        endTime: '10:00'
      },
      {
        startTime: '10:15',
        endTime: '12:00'
      },
      {
        startTime: '13:00',
        endTime: '14:00'
      },
      {
        startTime: '14:15',
        endTime: '15:00'
      },
      {
        startTime: '15:30',
        endTime: '17:00'
      }
    ]
  },
  {
    type: 'room',
    avatar: '',
    color: 'yellow',
    name: 'Beta',
    date: 'Monday, 7th June',
    availableSlots: [
      {
        startTime: '09:46',
        endTime: '10:00'
      },
      {
        startTime: '12:00',
        endTime: '17:00'
      },
      {
        startTime: '19:00',
        endTime: '21:00'
      }
    ]
  }
];
