import styles from './SessionAvailableDate.module.scss';
import { AppointmentType, ScheduleOption } from 'interfaces/Schedule/AppointmentType';
import WeekdayPills from 'components/v2/WeekdayPills/WeekdayPills';

type SessionAvailableDateProps = {
  appointmentType: AppointmentType;
};

const SessionAvailableDate = ({ appointmentType }: SessionAvailableDateProps) => {
  const specifyDayTimeSelections = (): boolean[] => {
    const { dayTimeSelections } = appointmentType;
    const { kind, autoFit, fixedTime } = dayTimeSelections;
    let selection = kind === ScheduleOption.AutoFit ? autoFit : fixedTime;
    return [
      selection!.Monday?.isActive,
      selection!.Tuesday?.isActive,
      selection!.Wednesday?.isActive,
      selection!.Thursday?.isActive,
      selection!.Friday?.isActive,
      selection!.Saturday?.isActive,
      selection!.Sunday?.isActive
    ];
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>AVAILABLE</div>
      <WeekdayPills weekdays={specifyDayTimeSelections()} />
    </div>
  );
};

export default SessionAvailableDate;
