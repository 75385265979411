import classNames from 'classnames';
import Radio from 'components/Radio/Radio';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useField, useFormikContext } from 'formik';
import { SchedulingRulesType } from 'interfaces/Schedule/SchedulingRules';
import { useMemo } from 'react';
import { useEffect } from 'react';
import {
  RESCHEDULE_LIMIT_OPTIONS,
  RESCHEDULE_START_TIME_RANGE_OPTIONS,
  RESCHEDULE_TIME_RANGE_OPTIONS,
  RESCHEDULE_TOGGLE_OPTIONS
} from '../../constants';

import styles from './RescheduleRules.module.scss';

const populateOnConfirmedOption = (value: number): number => (value === -1 ? 300000 : value);

const RescheduleRules = () => {
  const { values, setFieldValue } = useFormikContext<SchedulingRulesType>();
  const [{ value: rescheduleActive }, , { setValue: setRescheduleActive }] = useField<boolean>(
    `clientReschedule.active`
  );
  const [{ value: rescheduleLimit }, , { setValue: setReschedueLimit }] = useField<Number>(`clientReschedule.limit`);
  const [
    { value: selfManageActive },
    { error: selfManageActiveError },
    { setValue: setSelfManageActive }
  ] = useField<boolean>(`clientReschedule.selfManage.active`);
  const [{ value: requestChangesActive }, , { setValue: setRequestChangesActive }] = useField<boolean>(
    `clientReschedule.requestChanges.active`
  );

  const selfManageFrom = values.clientReschedule.selfManage.value.from;
  const selfManageTo = values.clientReschedule.selfManage.value.to;
  const requestChangesTo = values.clientReschedule.requestChanges.value.to;

  const getNoChangesFromOptions = () => {
    if (selfManageActive && requestChangesActive) {
      return secondLayerTimeOptions;
    } else if (selfManageActive || requestChangesActive) {
      return firstLayerTimeOptions;
    }
    return RESCHEDULE_START_TIME_RANGE_OPTIONS;
  };

  // Filter next available options
  const firstLayerTimeOptions = useMemo(
    () =>
      RESCHEDULE_TIME_RANGE_OPTIONS.filter(
        ({ value }) =>
          value <
          (selfManageActive ? populateOnConfirmedOption(selfManageFrom) : populateOnConfirmedOption(selfManageTo))
      ),
    [selfManageFrom, selfManageActive, selfManageTo]
  );
  const secondLayerTimeOptions = useMemo(
    () =>
      selfManageActive && requestChangesActive
        ? RESCHEDULE_TIME_RANGE_OPTIONS.filter(({ value }) => value < populateOnConfirmedOption(selfManageTo))
        : RESCHEDULE_TIME_RANGE_OPTIONS,
    [selfManageActive, selfManageTo, requestChangesActive]
  );

  // get next available option when existing not applicable
  useEffect(() => {
    if (selfManageActive && requestChangesActive) {
      if (!secondLayerTimeOptions.find(({ value }) => value === requestChangesTo)) {
        if (secondLayerTimeOptions.length > 0) {
          setFieldValue('clientReschedule.requestChanges.value.to', secondLayerTimeOptions[0].value);
        } else {
          setFieldValue('clientReschedule.requestChanges.value.to', undefined);
        }
      }

      if (!firstLayerTimeOptions.find(({ value }) => value === selfManageTo)) {
        if (firstLayerTimeOptions.length > 0) {
          setFieldValue('clientReschedule.selfManage.value.to', firstLayerTimeOptions[0].value);
        } else {
          setFieldValue('clientReschedule.selfManage.value.to', undefined);
        }
      }
    }

    if (!selfManageActive && requestChangesActive) {
      if (!firstLayerTimeOptions.find(({ value }) => value === requestChangesTo)) {
        if (firstLayerTimeOptions.length > 0) {
          setFieldValue('clientReschedule.requestChanges.value.to', firstLayerTimeOptions[0].value);
        } else {
          setFieldValue('clientReschedule.requestChanges.value.to', undefined);
        }
      }
      if (!RESCHEDULE_START_TIME_RANGE_OPTIONS.find(({ value }) => value === selfManageTo)) {
        setFieldValue('clientReschedule.selfManage.value.to', RESCHEDULE_START_TIME_RANGE_OPTIONS[0].value);
      }
    }

    if (selfManageActive && !requestChangesActive) {
      if (!firstLayerTimeOptions.find(({ value }) => value === selfManageTo)) {
        if (firstLayerTimeOptions.length > 0) {
          setFieldValue('clientReschedule.selfManage.value.to', firstLayerTimeOptions[0].value);
        } else {
          setFieldValue('clientReschedule.selfManage.value.to', undefined);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstLayerTimeOptions,
    secondLayerTimeOptions,
    requestChangesActive,
    requestChangesTo,
    selfManageActive,
    selfManageTo
  ]);

  // link from and to range of different rules
  useEffect(() => {
    if (selfManageActive && !requestChangesActive) {
      setFieldValue('clientReschedule.selfManage.value.to', requestChangesTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestChangesTo]);
  useEffect(() => {
    if (!requestChangesActive) {
      if (selfManageActive) {
        setFieldValue('clientReschedule.requestChanges.value.to', selfManageTo);
      } else {
        setFieldValue('clientReschedule.requestChanges.value.to', RESCHEDULE_START_TIME_RANGE_OPTIONS[0].value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfManageActive, requestChangesActive, selfManageTo]);

  return (
    <div className={styles.container}>
      <Radio
        vertical
        name={'enableClientReschedule'}
        options={RESCHEDULE_TOGGLE_OPTIONS}
        value={rescheduleActive.toString()}
        onChange={(e) => setRescheduleActive(e.target.value === 'true')}
      />
      <div className={rescheduleActive ? styles.sectionContainer : styles.hiddenSectionContainer}>
        <div className={classNames(styles.section, styles.active)}>
          <div className={styles.labelContainer}>
            <div className={styles.title}>Set Reschedule Limit</div>
            <div className={styles.description}>
              Assign a limit per appointment to the number of times a change is allowed.
            </div>
          </div>
          <div className={styles.selectionContainer}>
            <Radio
              name={'rescheduleLimit'}
              options={RESCHEDULE_LIMIT_OPTIONS}
              value={rescheduleLimit.toString()}
              onChange={(e) => setReschedueLimit(parseInt(e.target.value, 10))}
              labelClassName={styles.radioLabel}
            />
          </div>
        </div>
        {selfManageActiveError && <div className={styles.error}>Please enable at least one method.</div>}
        <div className={classNames(styles.section, selfManageActive && styles.active)}>
          <div className={styles.labelContainer}>
            <label className={styles.label}>
              <input
                className={styles.checkbox}
                type="checkbox"
                checked={selfManageActive}
                onChange={() => setSelfManageActive(!selfManageActive)}
              />
              <div className={styles.descGroup}>
                <span className={styles.desc}>Allow clients to self manage changes</span>
                <span className={styles.subDesc}>
                  Clients can change online without approval between the following times:
                </span>
              </div>
            </label>
          </div>
          <div className={selfManageActive ? styles.selectionContainer : styles.hiddenSelectionContainer}>
            <div className={styles.timeRangeContainer}>
              <span className={styles.label}>From</span>
              <div>
                <FormikSelect
                  name={`clientReschedule.selfManage.value.from`}
                  options={RESCHEDULE_START_TIME_RANGE_OPTIONS}
                  errorClassName={styles.error}
                  ignoreTouched
                />
              </div>
            </div>
            <div className={styles.timeRangeContainer}>
              <span className={styles.label}>to</span>
              <div>
                <FormikSelect
                  name={`clientReschedule.selfManage.value.to`}
                  options={firstLayerTimeOptions}
                  errorClassName={styles.error}
                  ignoreTouched
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.section, requestChangesActive && styles.active)}>
          <div className={styles.labelContainer}>
            <label className={styles.label}>
              <input
                className={styles.checkbox}
                type="checkbox"
                checked={requestChangesActive}
                onChange={() => setRequestChangesActive(!requestChangesActive)}
              />
              <div className={styles.descGroup}>
                <span className={styles.desc}>Allow clients to request changes</span>
                <span className={styles.subDesc}>
                  Clients can request a change, that needs approval by your team, between the following times:
                </span>
              </div>
            </label>
          </div>
          <div className={requestChangesActive ? styles.selectionContainer : styles.hiddenSelectionContainer}>
            <div className={styles.timeRangeContainer}>
              <span className={styles.label}>From</span>
              <div>
                <FormikSelect
                  name={`clientReschedule.selfManage.value.to`}
                  options={selfManageActive ? firstLayerTimeOptions : RESCHEDULE_START_TIME_RANGE_OPTIONS}
                  errorClassName={styles.error}
                  ignoreTouched
                />
              </div>
            </div>
            <div className={styles.timeRangeContainer}>
              <span className={styles.label}>to</span>
              <div>
                <FormikSelect
                  name={`clientReschedule.requestChanges.value.to`}
                  options={getNoChangesFromOptions()}
                  errorClassName={styles.error}
                  ignoreTouched
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.section, styles.active)}>
          <div className={styles.labelContainer}>
            <label className={styles.label}>
              <input className={styles.checkbox} type="checkbox" checked disabled />
              <div className={styles.descGroup}>
                <span className={styles.desc}>No changes made after</span>
                <span className={styles.subDesc}>Set a cut off for changes after:</span>
              </div>
            </label>
          </div>
          <div className={styles.selectionContainer}>
            <div className={styles.timeRangeContainer}>
              <span className={styles.label}>From</span>
              <div>
                <FormikSelect
                  name={`clientReschedule.requestChanges.value.to`}
                  options={getNoChangesFromOptions()}
                  errorClassName={styles.error}
                  ignoreTouched
                />
              </div>
            </div>
            <div className={styles.timeRangeContainer}>
              <span className={styles.label}>to Time appointment</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescheduleRules;
