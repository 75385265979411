import { clientProfilesInterface } from '../interfaces/Clients/clientsRecord';

export const getShortOrdinalNumber = (value: number | string) => {
  const numberValue = Number(value);

  if (numberValue === 1 || (numberValue > 20 && numberValue % 10 === 1)) {
    return `${value}st`;
  } else if (numberValue === 2 || (numberValue > 20 && numberValue % 10 === 2)) {
    return `${value}nd`;
  } else if (numberValue === 3 || (numberValue > 20 && numberValue % 10 === 3)) {
    return `${value}rd`;
  } else {
    return `${value}th`;
  }
};

export const getName = (
  clientData: { firstName?: string; lastName?: string; name: string },
  firstNameOnly?: boolean
): string => {
  const { firstName, lastName, name } = clientData;
  if (firstName && lastName) {
    return firstNameOnly ? firstName : `${firstName} ${lastName}`;
  }
  return name;
};

export const combineName = (
  clientProfiles: Pick<clientProfilesInterface, 'firstName' | 'lastName' | 'name'>[],
  firstNameOnly?: boolean,
  useSymbol?: boolean
) => {
  return clientProfiles
    .map((val) => getName(val, firstNameOnly))
    .join(', ')
    .replace(/,(?!.*,)/gim, ` ${useSymbol ? '&' : ' and'}`);
};

export const convertStringToTitleCase = (sentence: string) =>
  sentence.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1));
