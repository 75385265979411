import { MouseEvent, useEffect } from 'react';
import styles from './GroupSurveyFormMenuList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { scrollToView } from 'utils/scrollToView';
import classnames from 'classnames';

interface GroupSurveyFormMenuListProps {
  isLoading: boolean;
  groupId: string;
  activeId: string;
  surveyFormMenuList: SurveyFormResponseList[];
}

const GroupSurveyFormMenuList = ({
  isLoading,
  groupId,
  activeId,
  surveyFormMenuList
}: GroupSurveyFormMenuListProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  useEffect(() => {
    scrollToView('selectedGroupSurveyId', true);
  }, [surveyFormMenuList]);

  const handleClickSurveyForm = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Survey}/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          {surveyFormMenuList && surveyFormMenuList?.length > 0 ? (
            <div className={styles.container}>
              {surveyFormMenuList.map(({ _id, name, createdAt, isSent, sendAt }) => (
                <div
                  id={activeId === _id ? `selectedGroupSurveyId` : ''}
                  className={classnames(styles.item, isSent && styles.link)}
                  key={_id}
                  {...(isSent && {
                    onClick: handleClickSurveyForm(_id)
                  })}
                >
                  {isSent ? (
                    <FontAwesomeIcon className={styles.icon} icon={activeId === _id ? SolidCircle : RegularCircle} />
                  ) : (
                    <div className={styles.noIcon} />
                  )}
                  <div className={styles.label}>
                    <span className={styles.name}>{name}</span>
                    <span>
                      {isSent
                        ? moment(createdAt).format('DD/MM/YY')
                        : `scheduled for ${moment(sendAt, 'YYYY-MM-DD hh:mmA Z').format('DD/MM/YY')}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No surveys</div>
          )}
        </>
      )}
    </>
  );
};

export default GroupSurveyFormMenuList;
