import DatePicker from 'components/v2/DatePicker/DatePicker';
import TimePicker from 'components/v2/TimePicker/TimePicker';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { useField } from 'formik';
import { useEffect } from 'react';
import styles from './CustomiseSchedule.module.scss';
import moment, { Moment } from 'moment';
import { AppointmentSlot } from 'components/v2/AvailableTimeSelect/Interface';

interface CustomiseScheduleProps {
  timeslot?: AppointmentSlot;
  onChangeSchedule: (slot: AppointmentSlot) => void;
  appointmentTypeName?: string;
  setIsCustomising: (isCustomising: boolean) => void;
}

const CustomiseSchedule = ({
  timeslot,
  onChangeSchedule,
  appointmentTypeName,
  setIsCustomising
}: CustomiseScheduleProps) => {
  const [{ value: valueDate }, metaDate, dateHelper] = useField('date');
  const [{ value: valueStartTime }, , startTimeHelper] = useField('startTime');
  const [{ value: valueEndTime }, metaEndTime, endTimeHelper] = useField('endTime');

  useEffect(() => {
    if (!valueDate && !valueStartTime && !valueEndTime) {
      dateHelper.setValue(moment().format('YYYY-MM-DD'));
      startTimeHelper.setValue(moment().format('HH:mm'));
      endTimeHelper.setValue(moment().add(1, 'h').format('HH:mm'));
      onChangeSchedule({
        date: moment().format('YYYY-MM-DD'),
        startTime: moment().format('HH:mm'),
        endTime: moment().add(1, 'hour').format('HH:mm')
      });
    }
  }, [dateHelper, endTimeHelper, onChangeSchedule, startTimeHelper, valueDate, valueEndTime, valueStartTime]);

  const handleChangeDate = (value: Moment | null) => {
    if (value) {
      dateHelper.setValue(value.format('YYYY-MM-DD'));
      if (timeslot && onChangeSchedule) {
        onChangeSchedule({
          ...timeslot,
          date: value.format('YYYY-MM-DD')
        });
      }
    } else {
      dateHelper.setValue('');
    }
  };

  const handleChangeStartTime = (val: any) => {
    startTimeHelper.setValue(moment(val, 'hh:mm a').format('HH:mm'));
    if (timeslot && onChangeSchedule) {
      onChangeSchedule({
        ...timeslot,
        startTime: moment(val, 'hh:mm a').format('HH:mm')
      });
    }
  };

  const handleChangeEndTime = (val: any) => {
    endTimeHelper.setValue(moment(val, 'hh:mm a').format('HH:mm'));
    if (timeslot && onChangeSchedule) {
      onChangeSchedule({
        ...timeslot,
        endTime: moment(val, 'hh:mm a').format('HH:mm')
      });
    }
  };

  return (
    <>
      <div className={styles.header}>
        {appointmentTypeName ? (
          <div className={styles.appointmentNameContainer}>
            <div className={styles.label}>CUSTOMISE TIMES FOR</div>
            <div className={styles.text}>{appointmentTypeName}</div>
          </div>
        ) : (
          'Select day and time'
        )}
        <div className={styles.customise} onClick={() => setIsCustomising(false)}>
          <span className="material-icons-outlined">arrow_back_ios</span>
          Choose an open slot
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.fieldsContainer}>
          <div className={styles.field}>
            <div className={styles.dateField}>
              <span className="material-icons-outlined">edit_calendar</span>
              <DatePicker
                date={valueDate ? valueDate : moment().format('YYYY-MM-DD')}
                error={metaDate?.touched && !!metaDate.error}
                onChangeValue={handleChangeDate}
              />
            </div>
            <div className={styles.timeField}>
              <span className="material-icons-outlined">schedule</span>
              <TimePicker
                id={'customiseStartTime'}
                error={metaEndTime?.touched && !!metaEndTime.error}
                time={valueStartTime ? valueStartTime : moment().format('hh:mm a')}
                minuteStep={5}
                onChange={handleChangeStartTime}
              />
              <span>-</span>
              <TimePicker
                id={'customiseEndTime'}
                error={metaEndTime?.touched && !!metaEndTime.error}
                time={valueEndTime ? valueEndTime : moment().add(1, 'h').format('hh:mm a')}
                minuteStep={5}
                onChange={handleChangeEndTime}
              />
              <ErrorMessage
                className={styles.error}
                error={metaEndTime.error}
                visible={metaEndTime?.touched && !!metaEndTime.error}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomiseSchedule;
