import { MouseEvent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import styles from './PatientDetailsInsightsList.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import HelpOutLineWithTooltips from '../../../../../../components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useTranslation } from 'react-i18next';

interface PatientDetailsInsightsListProps {
  clientRecordData: clientRecordsInterface;
  isClientLoading: boolean;
  clientProfileId?: string;
  currentTab: string;
}

const PatientDetailsInsightsList = ({
  clientRecordData,
  isClientLoading,
  clientProfileId,
  currentTab
}: PatientDetailsInsightsListProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const [t] = useTranslation();

  const clientRecordId = clientRecordData._id;
  const clientProfiles = clientRecordData.clientProfiles;
  const checkInInsightUrl = `${CLIENTS.BASE}/${clientRecordId}/${currentTab}/${clientProfiles[0]._id}`;

  useEffect(() => {
    if (!isClientLoading) {
      navigate(checkInInsightUrl);
    }
  }, [isClientLoading, checkInInsightUrl, navigate]);

  const handleClickAssessment = (id: string, isClientSignUp: boolean) => (e: MouseEvent) => {
    if (isClientSignUp) {
      e.stopPropagation();
      navigate(`${CLIENTS.BASE}/${clientRecordId}/${currentTab}/${id}`);
    }
  };

  return isClientLoading ? (
    <LoadingCircle />
  ) : clientRecordData.recordType === 'couple' ? (
    <div className={styles.container}>
      {clientProfiles.map((clientDataObj, index) => (
        <div className={styles.selectContainer}>
          <div
            id={clientDataObj._id}
            className={clientDataObj.signupInvitation?.signupDate ? styles.link : styles.linkDisable}
            key={index}
            onClick={handleClickAssessment(clientDataObj._id, !!clientDataObj.signupInvitation?.signupDate)}
          >
            <FontAwesomeIcon
              className={styles.icon}
              icon={
                clientDataObj.signupInvitation?.signupDate
                  ? clientProfileId === clientDataObj._id
                    ? SolidCircle
                    : RegularCircle
                  : SolidCircle
              }
            />
            <div className={styles.label}>
              <span className={styles.name}>{clientDataObj.firstName}</span>
            </div>
          </div>
          {!clientDataObj.signupInvitation?.signupDate && (
            <div className={styles.noAllowTooltips}>
              <HelpOutLineWithTooltips
                id={`noAllowAdd${index}`}
                desc={t('form.error.signup_invitation_not_allowed_journaling')}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  ) : (
    <>Micro-journaling data</>
  );
};

export default PatientDetailsInsightsList;
