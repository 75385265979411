import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ControlPanelContentDisplay from './components/ControlPanelContentDisplay/ControlPanelContentDisplay';
import ControlPanelSidebar from './components/ControlPanelSidebar/ControlPanelSidebar';
import styles from './ControlPanelContent.module.scss';

interface ControlPanelContentProps {
  profile?: ProfileInterface;
  controlPanelTab?: string;
  isLoading: boolean;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
}

const ControlPanelContent = ({ profile, controlPanelTab, isLoading, onUpdateProfile }: ControlPanelContentProps) => {
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle, helmFeatureToggle } = useGetFeatureToggle();

  return (
    <div className={styles.container}>
      <ControlPanelSidebar
        controlPanelTab={controlPanelTab}
        aboutYouPage={isEdgeUserView || isNormalUserView || isEdgeReceptionist}
        helmControlPage={helmFeatureToggle && isEdgeUserView}
        medicarePage={medicareRebateFeatureToggle && (isEdgeUserView || isNormalUserView)}
        workingSchedulePage={isEdgeUserView}
        practiceProfilePage={isEdgeAdminView || isNormalUserView}
        systemConnectionPage={isEdgeAdminView || isEdgeUserView || isNormalUserView}
        misConnectionPage={isEdgeAdminView}
      />
      <ControlPanelContentDisplay
        profile={profile}
        controlPanelTab={controlPanelTab}
        isLoading={isLoading}
        onUpdateProfile={onUpdateProfile}
        aboutYouPage={isEdgeUserView || isNormalUserView || isEdgeReceptionist}
        helmControlPage={helmFeatureToggle && isEdgeUserView}
        medicarePage={medicareRebateFeatureToggle && (isEdgeUserView || isNormalUserView)}
        workingSchedulePage={isEdgeUserView}
        practiceProfilePage={isEdgeAdminView || isNormalUserView}
        systemConnectionPage={isEdgeAdminView || isEdgeUserView || isNormalUserView}
        misConnectionPage={isEdgeAdminView}
      />
    </div>
  );
};

export default ControlPanelContent;
