import { useState, ChangeEvent, useEffect, useRef } from 'react';
import styles from './SearchableSelect.module.scss';
import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

const DEFAULT_INPUT_PLACEHOLDER = 'Search here';

export interface OptionInterface {
  _id: string;
  label: string;
  isSelected?: boolean;
  isLoading?: boolean;
}

export interface SearchableSelectProps {
  label: string;
  optionsListLabel?: string;
  options: OptionInterface[];
  searchBoxPlaceholder?: string;
  isMultipleChoice: boolean;
  selectedOptionsProps: OptionInterface[];
  onSelecteOptionProps: (selectedOption: OptionInterface) => void;
  loadingEnable?: boolean;
  disabled?: boolean;
  className?: string;
}

const SearchableSelect = ({
  options,
  label,
  optionsListLabel,
  searchBoxPlaceholder,
  selectedOptionsProps,
  loadingEnable,
  disabled,
  className,
  onSelecteOptionProps
}: SearchableSelectProps) => {
  const placeholder =
    searchBoxPlaceholder && searchBoxPlaceholder.length > 0 ? searchBoxPlaceholder : DEFAULT_INPUT_PLACEHOLDER;
  const [searchText, setSearchText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selectedOptionsProps);
  const [optionList, setOptionList] = useState(options);

  const inputNode = useRef<HTMLInputElement>(null);
  const controlIconNode = useRef<HTMLSpanElement>(null);
  const optionsNode = useRef<HTMLDivElement>(null);

  const handleClickOutSide = (event: any) => {
    if (
      inputNode.current?.contains(event.target) ||
      controlIconNode.current?.contains(event.target) ||
      optionsNode.current?.contains(event.target)
    ) {
      return;
    }
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    setSelectedOptions(selectedOptionsProps);
    setOptionList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionsProps, options]);

  const onOpenOptions = () => {
    if (disabled) {
      return;
    }
    if (!showOptions) {
      setShowOptions(!showOptions);
    }
  };

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    const newOptionList = options.filter((item) => {
      return item.label.toLowerCase().includes(value.toLowerCase());
    });
    setOptionList(newOptionList);
  };

  const onOptionSelect = (event: any, optionItem: OptionInterface) => {
    if (optionItem.isLoading) {
      return;
    }
    if (loadingEnable) {
      const option = optionList.find((tag) => tag._id === optionItem._id);
      if (option) {
        option.isLoading = true;
      }
      setOptionList([...optionList]);
    }
    onSelecteOptionProps(optionItem);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.select}>
        <div className={styles.controller}>
          <div className={styles.searchBox}>
            <input
              id="selectedItemId"
              ref={inputNode}
              placeholder={placeholder}
              value={searchText}
              onChange={onSearchTextChange}
              onClick={onOpenOptions}
              disabled={disabled}
            />
          </div>
          <div className={classNames(styles.controlIcon, disabled ? styles.disabled : '')}>
            <span ref={controlIconNode} className={'material-icons-outlined'} onClick={onOpenOptions}>
              arrow_drop_down
            </span>
          </div>
        </div>
        {showOptions && (
          <div className={styles.options} ref={optionsNode}>
            {optionList.length < 1 ? (
              <div className={styles.noOption}>No option</div>
            ) : (
              <>
                {optionsListLabel && optionsListLabel.length > 0 && (
                  <div className={styles.optionsListLabel}>Tag options</div>
                )}
                {optionList.map((optionItem, index) => (
                  <div
                    key={`optionItem_${index}`}
                    className={styles.optionItem}
                    onClick={(event) => onOptionSelect(event, optionItem)}
                  >
                    <div className={styles.itemName}>{optionItem.label}</div>
                    <div className={styles.selectedMark}>
                      {optionItem.isLoading ? (
                        <div className={styles.loadingBox}>
                          <LoadingCircle />
                        </div>
                      ) : (
                        selectedOptions.some((item) => item._id === optionItem._id) && (
                          <span className={classNames(styles.selected, 'material-icons-outlined')}>check</span>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchableSelect;
