import { useEffect, useState } from 'react';
import styles from './PaginationDesc.module.scss';
import { Skeleton } from 'antd';
import Button from 'components/v2/Button/Button';

interface PaginationDescProps {
  currentPage: number;
  perPage: number;
  totalItem: number;
  onPageChange: (pageValue: number) => void;
  isLoading?: boolean;
  label: string;
}

const PaginationDesc = ({ currentPage, perPage, totalItem, onPageChange, isLoading, label }: PaginationDescProps) => {
  const [page, setPage] = useState({
    havePrev: false,
    haveNext: false
  });

  const totalAvailablePage = Math.ceil(totalItem / perPage);
  const startShowingNumber = totalItem > 0 ? (currentPage - 1) * perPage + 1 : 0;
  const endShowingNumber = totalItem > 0 ? (currentPage !== totalAvailablePage ? currentPage * perPage : totalItem) : 0;

  useEffect(() => {
    setPage({
      havePrev: currentPage > 1,
      haveNext: currentPage < totalAvailablePage
    });
  }, [perPage, currentPage, totalAvailablePage]);

  const handleClickPrev = () => {
    if (currentPage > 1) {
      const minusPage = currentPage - 1;
      setPage({
        havePrev: minusPage > 1,
        haveNext: totalAvailablePage > minusPage
      });
      onPageChange(minusPage);
    }
  };

  const handleClickNext = () => {
    if (currentPage < totalAvailablePage) {
      const addPage = currentPage + 1;
      setPage({
        havePrev: addPage > 1,
        haveNext: totalAvailablePage > addPage
      });
      onPageChange(addPage);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <Skeleton.Input style={{ width: '160px', height: '25px', borderRadius: '4px' }} active />
        </div>
      ) : (
        <div className={styles.countLabel}>
          Showing&nbsp;
          {startShowingNumber === endShowingNumber ? (
            <span>{endShowingNumber}</span>
          ) : (
            <>
              <span>{startShowingNumber}</span>&nbsp;to&nbsp;<span>{endShowingNumber}</span>
            </>
          )}
          &nbsp;of&nbsp;
          <span>{totalItem}</span>&nbsp;{label}
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          variant={'link'}
          className={page.havePrev ? styles.btnActive : styles.btn}
          onClick={() => (!isLoading ? handleClickPrev() : false)}
        >
          <i className={`material-icons ${styles.btnIconPrev}`}>arrow_back</i>
          Previous
        </Button>
        <Button
          variant={'link'}
          className={page.haveNext ? styles.btnActive : styles.btn}
          onClick={() => (!isLoading ? handleClickNext() : false)}
        >
          Next
          <i className={`material-icons ${styles.btnIconNext}`}>arrow_forward</i>
        </Button>
      </div>
    </div>
  );
};

export default PaginationDesc;
