import * as yup from 'yup';
import { newProspectClientForm, prospectValidateField } from '../../AddProspectClientInterface';
import { config } from 'config/config';
import i18n from 'i18n';

const { mobileNumberMinLength } = config;

const prospectClientFormSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your contact first name'),
  lastName: yup.string().required('Please enter your contact last name'),
  email: yup.string().email('Please enter a valid email address'),
  mobileNumber: yup
    .string()
    .test(
      'mobile-number-length',
      i18n.t('validation.client_phonenumber.min', { count: mobileNumberMinLength }),
      (value) => !value || value.length > mobileNumberMinLength + 2
    )
});

export const validationProspectClientForm = (clientVal: newProspectClientForm) => {
  const validationErrors: prospectValidateField = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: ''
  };
  try {
    prospectClientFormSchema.validateSync(clientVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof prospectValidateField] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
