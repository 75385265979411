import { notification } from 'antd';
import FilterDropdown from 'components/FilterDropDown/FilterDropDown';
import { GroupListData, GroupsFromAPI, MinifiedGroupListsData } from 'pages/Groups/Interfaces/Groups';
import { useEffect, useState } from 'react';
import { getMinifiedGroups } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { PER_PAGE } from '.';

interface Props {
  token: string;
  asUser: boolean;
  accountId: string;
  groupFilteredList: GroupsFromAPI[];
  setGroupFilteredList: React.Dispatch<React.SetStateAction<GroupsFromAPI[]>>;
  resetPage: () => void;
}

const GroupFilter = ({ token, asUser, accountId, groupFilteredList, setGroupFilteredList, resetPage }: Props) => {
  const [allGroups, setAllGroups] = useState<GroupsFromAPI[]>([]);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const initialFetch = async (token: string) => {
    setIsLoadingInitialData(true);
    try {
      const request = await getMinifiedGroups(token, accountId, asUser, {
        status: 'active',
        perPage: PER_PAGE,
        page: currentPage
      });
      const data = (await request.json()) as MinifiedGroupListsData;
      setTotalPage(Math.ceil(data.paging.totalItems / PER_PAGE));
      setAllGroups(data.groups);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch group lists"
      });
    }
    setIsLoadingInitialData(false);
  };

  const fetchNext = async () => {
    if (!token || totalPage <= currentPage) return;
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setIsFetchingNextPage(true);
    try {
      const request = await getMinifiedGroups(token, accountId, asUser, {
        status: 'active',
        perPage: PER_PAGE,
        page: nextPage
      });
      const data = (await request.json()) as GroupListData;
      setAllGroups((groups) => [...groups, ...data.groups]);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch group lists"
      });
    }
    setIsFetchingNextPage(false);
  };

  useEffect(() => {
    if (token) {
      if (groupFilteredList.length > 0) {
        // reset filter when change view
        setGroupFilteredList([]);
      }
      initialFetch(token);
    }
    // eslint-disable-next-line
  }, [token, asUser]);

  return (
    <FilterDropdown
      id="group-filter"
      menuItems={allGroups.map((group) => ({
        _id: group._id,
        name: group.name
      }))}
      onChangeItem={(obj) => {
        const { item } = obj;
        if (item) {
          resetPage();
          const isSelected = !!groupFilteredList.find((group) => item._id === group._id);
          if (isSelected) {
            setGroupFilteredList((list) => list.filter((group) => group._id !== item._id));
          } else {
            const addedGroup = allGroups.find((group) => group._id === item._id);
            if (addedGroup) {
              setGroupFilteredList((list) => [...list, addedGroup]);
            }
          }
        }
      }}
      selectedFilterList={groupFilteredList.map((group) => ({
        _id: group._id,
        name: group.name
      }))}
      showSearchIcon
      loading={isLoadingInitialData}
      hasMoreData={totalPage > currentPage}
      isFetchingMore={isFetchingNextPage}
      loadMore={() => fetchNext()}
    >
      Group
    </FilterDropdown>
  );
};

export default GroupFilter;
