import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import cordsLogo from 'assets/images/CORDS/CORDS-Logo.png';
import MyProfile from './components/MyProfile/MyProfile';

import styles from './Header.module.scss';

interface HeaderProps {
  showSideBar: MouseEventHandler;
}

const Header = ({ showSideBar }: HeaderProps) => {
  const { isMentorUser } = useGetAccountPackageView();

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <div className={styles.logoWrapper}>
          {!isMentorUser && (
            <div className={styles.menuWrapper}>
              <div className={styles.menu} onClick={showSideBar}>
                <FontAwesomeIcon icon={faBars} />
              </div>
            </div>
          )}
          <a href={'/'} className={styles.cordsLogo} target={'_self'}>
            <img src={cordsLogo} alt={'logo'} />
          </a>
        </div>
      </div>
      <div className={styles.rightContent}>
        <MyProfile />
      </div>
    </div>
  );
};

export default Header;
