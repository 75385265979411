import { InvoiceViewProps } from 'components/InvoiceView/InvoiceView';

export const calculateInvoiceTotals = (
  items: InvoiceViewProps['items'],
  taxRate: number,
  discountType?: 'percent' | 'amount',
  discountValue?: number
) => {
  const discountValueNumber = Number(discountValue);
  const totalItemsCost = items.reduce((finalTotal, item) => finalTotal + Number(item.cost), 0);

  let totalCost = totalItemsCost;

  if (discountType && discountValue !== undefined && !isNaN(discountValue)) {
    if (!isNaN(discountValueNumber) && !isNaN(totalCost)) {
      if (discountType === 'percent' && discountValueNumber <= 100) {
        totalCost = totalItemsCost - (totalItemsCost * Math.round(discountValueNumber)) / 100;
      } else if (discountType === 'amount' && discountValueNumber <= totalItemsCost) {
        totalCost = totalItemsCost - discountValueNumber;
      }
    }
  }

  let totalTax = 0;

  const taxValue = (Number(taxRate) / 100) * Number(totalCost);

  if (!isNaN(taxValue)) {
    totalTax = taxValue;
  }

  const totalPayment = totalCost + totalTax;

  return { totalCost: totalCost.toFixed(2), totalTax: totalTax.toFixed(2), totalPayment: totalPayment.toFixed(2) };
};
