import MaterialInput from 'components/MaterialInput/MaterialInput';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import TextEditorWidget from 'pages/Report/ReportDetails/components/ReportBuilder/components/WidgetSideBar/components/EditTab/components/TextEditorWrapper/components/TextEditorWidget/TextEditorWidget';
import Label from 'pages/Report/ReportDetails/components/ReportBuilder/components/WidgetSideBar/components/ReportInfoSection/components/Label/Label';
import { useState } from 'react';

import WidgetsListMenu from './components/WidgetsListMenu/WidgetsListMenu';
import styles from './TemplateSideBar.module.scss';
import WidgetsListContent from './components/WidgetsListContent/WidgetsListContent';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { ClientProfileWidgetInterface, PsychometricWidgetInterface, TemplateWidgetView } from './interface';
import { MOST_EARLY_ASSESSMENT, MOST_RECENT_ASSESSMENT } from './constants';
import { ReportWidgetType } from 'interfaces/Reports/report';
import { ReportTemplateWidgetId } from 'interfaces/Reports/reportTemplate';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import QuestionMenu from './components/QuestionMenu/QuestionMenu';

interface TemplateSideBarProps {
  title: string;
  titleValidation: boolean;
  status: string;
  templateContent: any;
  onChangeTemplateTitle: (title: string) => void;
  onChangeTemplateStatus: (status: string) => void;
}

const TemplateSideBar = ({
  title,
  titleValidation,
  status,
  templateContent,
  onChangeTemplateTitle,
  onChangeTemplateStatus
}: TemplateSideBarProps) => {
  const { isEdgeUser } = useGetAccountPackageView();
  const [sideBarContentView, setSideBarContentView] = useState<TemplateWidgetView>(TemplateWidgetView.Menu);

  const DRAFT = {
    id: 'draft',
    label: 'Draft',
    isActive: status === 'draft'
  };
  const PUBLISHED = {
    id: 'published',
    label: 'Published',
    isActive: status === 'published'
  };
  const toggleList = [DRAFT, PUBLISHED];

  const AssessmentWidgetMenuList: PsychometricWidgetInterface = {
    id: TemplateWidgetView.Psychometric,
    label: 'Psychometrics',
    widgetType: ReportWidgetType.AssessmentWidget,
    items: [MOST_RECENT_ASSESSMENT, MOST_EARLY_ASSESSMENT]
  };

  const ClientProfileWidgetMenuList: ClientProfileWidgetInterface = {
    id: TemplateWidgetView.ClientProfile,
    label: 'Client Profile',
    widgetType: ReportWidgetType.ClientProfileWidget,
    items: [
      ...(isEdgeUser
        ? [
            {
              title: 'Practitioner Assignment: {{PRACTITIONER_NAME}}',
              label: 'Practitioner Assignment',
              widgetId: ReportTemplateWidgetId.PractitionerAssignment
            }
          ]
        : []),
      {
        title: 'Client Name: {{CLIENT_NAME}}',
        label: 'Client Name',
        widgetId: ReportTemplateWidgetId.ClientName
      },
      {
        title: 'Date of Birth: {{CLIENT_DOB}}',
        label: 'Date of Birth',
        widgetId: ReportTemplateWidgetId.ClientDOB
      },
      {
        title: 'Email Address: {{CLIENT_EMAIL}}',
        label: 'Email Address',
        widgetId: ReportTemplateWidgetId.ClientEmail
      },
      {
        title: 'Mobile Number: {{CLIENT_MOBILE}}',
        label: 'Mobile Number',
        widgetId: ReportTemplateWidgetId.ClientMobile
      }
    ]
  };

  const psychometricMenu = [
    {
      id: AssessmentWidgetMenuList.id,
      label: AssessmentWidgetMenuList.label,
      count: AssessmentWidgetMenuList.items.length
    },
    {
      id: ClientProfileWidgetMenuList.id,
      label: ClientProfileWidgetMenuList.label,
      count: ClientProfileWidgetMenuList.items.length
    }
  ];

  const onClickWidgetMenu = (menuType: TemplateWidgetView) => {
    setSideBarContentView(menuType);
  };

  const onClickBackToAllElement = () => {
    setSideBarContentView(TemplateWidgetView.Menu);
  };

  return (
    <div className={styles.container}>
      <div className={styles.templateDetails}>
        <div className={styles.titleContainer}>
          <MaterialInput
            id={'title'}
            label={'Title'}
            className={styles.titleInput}
            value={title}
            onChange={(e) => {
              onChangeTemplateTitle(e.target.value);
            }}
            maxLength={30}
            required
          />
          {titleValidation && <div className={styles.error}>Please enter template title</div>}
        </div>
        <div className={styles.statusContainer}>
          <Label label={'TEMPLATE STATUS'} />
          <ToggleSwitchV2
            id={'status'}
            toggleList={toggleList}
            onChangeStatus={(val) => onChangeTemplateStatus(val.id)}
          />
        </div>
      </div>
      {sideBarContentView === TemplateWidgetView.Menu ? (
        <div className={styles.widgets}>
          <div className={styles.widgetContainer}>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>
                <i className={`material-icons ${styles.titleIcon}`}>title</i>
                Click to add text
              </div>
              <HelpOutLineWithTooltips
                id={'textWidget'}
                tooltipsClass={styles.tooltips}
                desc={`<div>You can include these data to auto fill</div><div>{{CLIENT_NAME}} , {{CLIENT_DOB}} , {{FIRST_APPOINTMENT_DATE}} , {{RECENT_APPOINTMENT_DATE}} , {{APPOINTMENT_COUNT}} , {{FIRST_ASSESSMENT}}</div>`}
              />
            </div>
            <TextEditorWidget enableMention />
          </div>
          <div className={styles.widgetContainer}>
            <div className={styles.title}>
              <i className={`material-icons ${styles.titleIcon}`}>touch_app</i>
              Embed profile or insight data
            </div>
            <WidgetsListMenu onClickMenuBtn={onClickWidgetMenu} menuItems={psychometricMenu} />
          </div>
          <div className={styles.widgetContainer}>
            <div className={styles.title}>
              <i className={`material-icons-outlined ${styles.titleIcon}`}>contact_support</i>
              Add question
            </div>
            <QuestionMenu />
          </div>
        </div>
      ) : (
        <div className={styles.widgetListContainer}>
          <div className={styles.backButton} onClick={onClickBackToAllElement}>
            <i className={`material-icons-outlined`}>arrow_back_ios</i>
            <div className={styles.backButtonLabel}>Back to all elements</div>
          </div>
          <WidgetsListContent
            clientProfileWidgetMenu={ClientProfileWidgetMenuList}
            psychometricWidgetMenu={AssessmentWidgetMenuList}
            templateContent={templateContent}
            widgetView={sideBarContentView}
          />
        </div>
      )}
    </div>
  );
};

export default TemplateSideBar;
