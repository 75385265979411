import { useState, ChangeEvent } from 'react';
import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import Button from 'components/v2/Button/Button';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { notification } from 'antd';
import styles from './ProfileWorkingSchedule.module.scss';
import { Formik } from 'formik';
import { profileWorkingScheduleSchema, ProfileInterfaceWS } from './constants';
import TimeAvailable from 'pages/Calendar/components/CalendarSettings/components/RoomManagement/components/RoomForm/components/TimeAvailable/TimeAvailable';
import { TimeSlot } from 'interfaces/Schedule/Room';
import moment from 'moment';

const TIME_FORMAT_WITHOUT_ASIDE = 'HH:mm',
  TIME_FORMAT_WITH_ASIDE = 'hh:mm a';

interface ProfileWorkingScheduleProps {
  profile: ProfileInterface;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
}

const ProfileWorkingSchedule = ({ profile, onUpdateProfile }: ProfileWorkingScheduleProps) => {
  const { token } = useGetAccessToken();
  const [caseLoadValue, setCaseLoadValue] = useState<PractitionersDetailsInterface['caseLoad']['availableSlot']>(
    profile.caseLoad.availableSlot
  );
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseLoadError, setCaseLoadError] = useState(false);

  const DEFAULT_TIME_SLOT = { startTime: '08:00 AM', endTime: '06:00 PM' };

  const formValue: ProfileInterfaceWS = {
    ...profile,
    availability: {
      monday: {
        isAvailable: profile.workingSchedule.monday.isActive,
        timeSlots: profile.workingSchedule.monday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.monday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      tuesday: {
        isAvailable: profile.workingSchedule.tuesday.isActive,
        timeSlots: profile.workingSchedule.tuesday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.tuesday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      wednesday: {
        isAvailable: profile.workingSchedule.wednesday.isActive,
        timeSlots: profile.workingSchedule.wednesday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.wednesday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      thursday: {
        isAvailable: profile.workingSchedule.thursday.isActive,
        timeSlots: profile.workingSchedule.thursday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.thursday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      friday: {
        isAvailable: profile.workingSchedule.friday.isActive,
        timeSlots: profile.workingSchedule.friday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.friday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      saturday: {
        isAvailable: profile.workingSchedule.saturday.isActive,
        timeSlots: profile.workingSchedule.saturday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.saturday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      sunday: {
        isAvailable: profile.workingSchedule.sunday.isActive,
        timeSlots: profile.workingSchedule.sunday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.sunday.timeSlots
          : [DEFAULT_TIME_SLOT]
      }
    }
  };

  const handleChangeCaseLoad = (event: ChangeEvent<HTMLInputElement>) => {
    const regexNumberOnly = /^[0-9\b]+$/;
    if (event.target.value === '' || regexNumberOnly.test(event.target.value)) {
      setCaseLoadError(false);
      setCaseLoadValue(parseInt(event.target.value, 10));
    }
  };

  const validateField = () => {
    if (caseLoadValue < 0) {
      setCaseLoadError(true);
      setPageSubmitStatus('');
      return false;
    }
    return true;
  };

  const reFormatTimes = (timeSlots: TimeSlot[]) => {
    if (timeSlots && timeSlots.length > 0) {
      return timeSlots.map((timeSlot) => {
        return {
          startTime: moment(timeSlot.startTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE),
          endTime: moment(timeSlot.endTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE)
        };
      });
    }
    return [] as TimeSlot[];
  };

  const handleSubmit = async (value: ProfileInterfaceWS) => {
    setPageSubmitStatus('active');

    if (validateField()) {
      try {
        const reqBody = {
          ...profile,
          caseLoad: {
            availableSlot: caseLoadValue
          },
          workingSchedule: {
            monday: {
              isActive: value.availability.monday.isAvailable,
              timeSlots: reFormatTimes(value.availability.monday.timeSlots || [])
            },
            tuesday: {
              isActive: value.availability.tuesday.isAvailable,
              timeSlots: reFormatTimes(value.availability.tuesday.timeSlots || [])
            },
            wednesday: {
              isActive: value.availability.wednesday.isAvailable,
              timeSlots: reFormatTimes(value.availability.wednesday.timeSlots || [])
            },
            thursday: {
              isActive: value.availability.thursday.isAvailable,
              timeSlots: reFormatTimes(value.availability.thursday.timeSlots || [])
            },
            friday: {
              isActive: value.availability.friday.isAvailable,
              timeSlots: reFormatTimes(value.availability.friday.timeSlots || [])
            },
            saturday: {
              isActive: value.availability.saturday.isAvailable,
              timeSlots: reFormatTimes(value.availability.saturday.timeSlots || [])
            },
            sunday: {
              isActive: value.availability.sunday.isAvailable,
              timeSlots: reFormatTimes(value.availability.sunday.timeSlots || [])
            }
          }
        };
        await updateClinicianProfile(token, reqBody);
        onUpdateProfile(reqBody as ProfileInterface);
        setPageSubmitStatus('finished');
        notification.success({
          message: 'Working Schedule updated',
          closeIcon: <span className="success">OK</span>
        });
        setTimeout(() => setPageSubmitStatus(''), 2000);
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({ message: 'Something went wrong while trying to update your working schedule info' });
      }
    }
  };

  return (
    <Formik initialValues={formValue} validationSchema={profileWorkingScheduleSchema} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>Working Schedule</div>
            <div className={styles.headerDesc}>Manage your target active caseload and set up your regular schedule</div>
          </div>
          <div className={styles.content}>
            <div className={styles.fieldBoxWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.title}>Caseload</div>
              </div>
              <div className={classnames(styles.fieldContainer, caseLoadError && styles.fieldError)}>
                <div className={styles.fieldLabelWrapper}>
                  <div className={styles.fieldLabel}>Your target active caseload*</div>
                  <HelpOutLineWithTooltips id={'caseLoad'} desc={'Availability for target active '} />
                </div>
                <input
                  type={'tel'}
                  maxLength={4}
                  className={styles.caseLoadField}
                  value={caseLoadValue || 0}
                  onChange={handleChangeCaseLoad}
                />
                {caseLoadError && <div className={styles.fieldError}>Caseload field cannot be blank</div>}
              </div>
            </div>
            <div className={styles.fieldBoxWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.title}>Working Schedule</div>
                <div className={styles.desc}>Set up your regular schedule</div>
              </div>
              <TimeAvailable />
            </div>
            <div className={styles.submitButtonContainer}>
              <Button status={pageSubmitStatus} onClick={submitForm}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ProfileWorkingSchedule;
