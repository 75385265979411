import { useCallback, useEffect, useState } from 'react';
import styles from './GroupListingContent.module.scss';
import SearchBar from 'components/SearchBar/SearchBar';
import Tag from 'components/Tag/Tag';
import ActiveGroupListCard from './components/ActiveGroupListCard/ActiveGroupListCard';
import ClosedGroupListCard from './components/ClosedGroupListCard/ClosedGroupListCard';
import { Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

interface GroupListingContentProps {
  groupListing: Groups[];
  isGroupListLoading: boolean;
  onChangeTabFilter: (tabValue: GroupStatus) => void;
  onHandleSearch: (q: string) => void;
  isPageLoading: boolean;
  groupStatus: GroupStatus;
  searchValue: string;
}

const GroupListingContent = ({
  groupListing,
  isGroupListLoading,
  onChangeTabFilter,
  onHandleSearch,
  isPageLoading,
  groupStatus,
  searchValue
}: GroupListingContentProps) => {
  const listLoading = isGroupListLoading || isPageLoading;
  const [activeGroupList, setActiveGroupList] = useState<Groups[]>([]);
  const [closedGroupList, setClosedGroupList] = useState<Groups[]>([]);

  const handleFilterValue = useCallback(
    (searchValue: string) => {
      const getFilterList = groupListing.filter((listObj) =>
        listObj.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      const activeList = getFilterList.filter((listObj) => listObj.status === 'active');
      const closedList = getFilterList.filter((listObj) => listObj.status === 'closed');
      setActiveGroupList(activeList);
      setClosedGroupList(closedList);
    },
    [groupListing]
  );

  useEffect(() => {
    handleFilterValue(searchValue);
  }, [searchValue, handleFilterValue]);

  const onChangeTabStatus = (tabValue: GroupStatus) => {
    onChangeTabFilter(tabValue);
  };

  const onSearchHandle = (val: string) => {
    handleFilterValue(val);
    onHandleSearch(val);
  };

  const groupsContentComponents = () => {
    switch (groupStatus) {
      case GroupStatus.Active:
        return <ActiveGroupListCard groupListing={activeGroupList} searchValue={searchValue} />;
      case GroupStatus.Closed:
        return <ClosedGroupListCard groupListing={closedGroupList} searchValue={searchValue} />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.filterWrapper}>
          <div className={styles.filterWrapperTitle}>Show</div>
          <div className={styles.filterWrapperTags}>
            <Tag
              label="Active"
              type={groupStatus === GroupStatus.Active ? 'active' : 'normal'}
              onClick={() => onChangeTabStatus(GroupStatus.Active)}
              counter={activeGroupList.length}
            />
            <Tag
              label="Closed"
              type={groupStatus === GroupStatus.Closed ? 'active' : 'normal'}
              onClick={() => onChangeTabStatus(GroupStatus.Closed)}
              counter={closedGroupList.length}
            />
          </div>
        </div>
        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarCard}>
            <SearchBar
              placeholder={'Search by group name'}
              value={searchValue}
              withSearchButton
              onSearch={onSearchHandle}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {listLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          groupsContentComponents()
        )}
      </div>
    </div>
  );
};

export default GroupListingContent;
