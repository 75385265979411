import { useCallback, useEffect, useState } from 'react';
import { PathwayStageItem, PathwayStageList, StageType } from '../interface';
import { getInterventionStageConfiguration } from 'utils/http/ClinicianProfileService/Interventions/InterventionPathway';

type SequentialPathwayStageItem = (PathwayStageItem & Required<Pick<PathwayStageItem, 'sequence'>>)[];

export const useFetchStageList = (token: string, interventionId: string) => {
  const [stageListing, setStageListing] = useState<PathwayStageList>();
  const [isStageListingLoading, setIsStageListingLoading] = useState(true);

  const fetchStageListing = useCallback(
    async (token: string) => {
      setIsStageListingLoading(true);

      const getStage = await getInterventionStageConfiguration(token, interventionId);
      if (getStage.statusCode === 200) {
        const groupStageConfiguration: PathwayStageList = await getStage.json();

        const massageAnytimeStage = (stageData: PathwayStageItem[]) => {
          return stageData.map((stageObj) => ({
            ...stageObj,
            stageType: StageType.Anytime
          }));
        };

        const massageSequentialStage = (stageData: PathwayStageItem[]): SequentialPathwayStageItem => {
          return stageData.map((stageObj, index) => ({
            ...stageObj,
            sequence: index + 1,
            stageType: StageType.Sequential
          }));
        };

        const massageStageConfiguration = {
          anytime: groupStageConfiguration?.anytime ? massageAnytimeStage(groupStageConfiguration.anytime) : [],
          sequential: groupStageConfiguration?.sequential
            ? massageSequentialStage(groupStageConfiguration.sequential)
            : []
        };
        setStageListing(massageStageConfiguration);
      }

      setIsStageListingLoading(false);
    },
    [interventionId]
  );

  useEffect(() => {
    if (token) {
      fetchStageListing(token);
    }
  }, [fetchStageListing, token]);

  return {
    stageListing,
    isStageListingLoading,
    fetchStageListing
  };
};
