import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './GroupListHeader.module.scss';

interface GroupListHeaderProps {
  onCreateGroupClick: () => void;
}

const GroupListHeader = ({ onCreateGroupClick }: GroupListHeaderProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Groups</div>
        </div>
      </div>
      {isEdgeAdminView && (
        <div className={styles.rightSection}>
          <div className={styles.addNewBtnWrapper} onClick={() => onCreateGroupClick()}>
            <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
            Create New Group
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupListHeader;
