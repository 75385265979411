import styles from './GroupMonthActivityFeed.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { GroupActivity } from '../GroupActivity';
import GroupActivityItem from './GroupActivityFeedItem/GroupActivityItem';

interface GroupMonthActivityFeedProps {
  items: GroupActivity[];
  monthString: string;
  groupDetails: Groups;
  profile: ProfileInterface;
}

const GroupMonthActivityFeed = ({ items, monthString, groupDetails, profile }: GroupMonthActivityFeedProps) => {
  return (
    <div className={styles.monthGroup}>
      <div className={styles.monthGroupTitle}>{monthString}</div>
      <div className={styles.monthGroupActivityItems}>
        {items.map((item, index) => {
          return (
            <div key={index} className={styles.activityContainer}>
              <GroupActivityItem item={item} profile={profile} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupMonthActivityFeed;
