import { useField } from 'formik';
import styles from './AutoFit.module.scss';
import DayTimeSelection from './components/DayTimeSelection/DayTimeSelection';
import moment from 'moment';

const TIME_FORMAT = 'HH:mm';

const AutoFit = () => {
  const [{ value }, { error, touched }, { setValue }] = useField('dayTimeSelections.autoFit');
  const handleStartTimeAllClick = (newStartTime: string, endTime: string) => {
    const newValues = { ...value };

    let newEndTime = '';

    if (newStartTime > '23:00') {
      newStartTime = '23:00';
      newEndTime = '23:45';
    } else if (newStartTime >= endTime) {
      newEndTime = moment(newStartTime, TIME_FORMAT).clone().add(1, 'hour').format(TIME_FORMAT);
    }

    newValues.Monday.startTime = newStartTime;
    newValues.Tuesday.startTime = newStartTime;
    newValues.Wednesday.startTime = newStartTime;
    newValues.Thursday.startTime = newStartTime;
    newValues.Friday.startTime = newStartTime;
    newValues.Saturday.startTime = newStartTime;
    newValues.Sunday.startTime = newStartTime;

    if (newEndTime) {
      newValues.Monday.endTime = newEndTime;
      newValues.Tuesday.endTime = newEndTime;
      newValues.Wednesday.endTime = newEndTime;
      newValues.Thursday.endTime = newEndTime;
      newValues.Friday.endTime = newEndTime;
      newValues.Saturday.endTime = newEndTime;
      newValues.Sunday.endTime = newEndTime;
    }

    setValue(newValues);
  };

  const handleEndTimeAllClick = (startTime: string, newEndTime: string) => {
    const newValues = { ...value };

    let newStartTime = '';

    if (newEndTime < '01:00') {
      newStartTime = '00:00';
      newEndTime = '01:00';
    } else if (newEndTime <= startTime) {
      newStartTime = moment(newEndTime, TIME_FORMAT).clone().subtract(1, 'hour').format(TIME_FORMAT);
    }

    newValues.Monday.endTime = newEndTime;
    newValues.Tuesday.endTime = newEndTime;
    newValues.Wednesday.endTime = newEndTime;
    newValues.Thursday.endTime = newEndTime;
    newValues.Friday.endTime = newEndTime;
    newValues.Saturday.endTime = newEndTime;
    newValues.Sunday.endTime = newEndTime;

    if (newStartTime) {
      newValues.Monday.startTime = newStartTime;
      newValues.Tuesday.startTime = newStartTime;
      newValues.Wednesday.startTime = newStartTime;
      newValues.Thursday.startTime = newStartTime;
      newValues.Friday.startTime = newStartTime;
      newValues.Saturday.startTime = newStartTime;
      newValues.Sunday.startTime = newStartTime;
    }

    setValue(newValues);
  };

  return (
    <div className={styles.dayTimeSelections}>
      <div className={styles.header}>
        <span>Select day available</span>
        <span>Set earliest appointment start times</span>
        <span>Set latest appointment finish times</span>
      </div>
      {Object.keys(value).map((day, index) => (
        <DayTimeSelection
          key={index}
          day={day}
          onEndTimeAllClick={handleEndTimeAllClick}
          onStartTimeAllClick={handleStartTimeAllClick}
        />
      ))}
      {typeof error === 'string' && touched && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default AutoFit;
