import { notification } from 'antd';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import { useEffect, useState } from 'react';
import { getConsentFormResponse } from 'utils/http/DocumentService/ConsentForm/consentForm';

export const useFetchConsentList = (token: string, recordId: string) => {
  const [consentListData, setConsentListData] = useState<ConsentFormListInterface[]>([] as ConsentFormListInterface[]);
  const [isConsentListLoading, setIsConsentListLoading] = useState(true);

  const fetchConsentForm = async (token: string) => {
    try {
      const res = await getConsentFormResponse(token, recordId);
      const consentData = (await res.json()) as ConsentFormListInterface[];

      setConsentListData(consentData);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your consent list by id' });
    }

    setIsConsentListLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchConsentForm(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { consentListData, isConsentListLoading };
};
