import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import { useField } from 'formik';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import {
  AppointmentType,
  DELIVERY_TYPE_ICONS,
  DELIVERY_TYPE_LABELS,
  DeliveryType
} from 'interfaces/Schedule/AppointmentType';

import styles from './DeliveryMode.module.scss';

type DeliveryModeProps = {
  appointmentType?: AppointmentType;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isReservedAppointment: boolean;
};

const DeliveryMode = ({ appointmentType, isEditing, setIsEditing, isReservedAppointment }: DeliveryModeProps) => {
  const [, { value: deliveryType }] = useField<AppointmentSlots['deliveryType']>('deliveryType');
  const [, { value: faceToFaceLocation }] = useField('faceToFaceLocation');
  const [, { value: videoCallInstructions }] = useField('videoCallInstructions');
  const [, { value: phoneCallInstructions }] = useField('phoneCallInstructions');
  if (!deliveryType || deliveryType === DeliveryType.BeSpoke.toString()) {
    return null;
  }

  return (
    <div className={classNames(styles.eventInformationContainer, isEditing && styles.flexStart)}>
      <div className={styles.iconContainer}>
        <span className="material-icons-outlined">{DELIVERY_TYPE_ICONS[deliveryType!]}</span>
      </div>
      <div className={styles.description}>
        {!isEditing ? (
          <>
            <div className={styles.descriptionText}>{DELIVERY_TYPE_LABELS[deliveryType!]}</div>
            {deliveryType !== DeliveryType.BeSpoke.toString() && !isReservedAppointment && (
              <Button variant="link" onClick={() => setIsEditing(true)}>
                Change delivery mode
              </Button>
            )}
          </>
        ) : (
          <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
        )}
      </div>
      <div />
      {!isEditing && (
        <>
          {deliveryType === DeliveryType.FaceToFace && (
            <div className={styles.subInfoContainer}>
              {faceToFaceLocation || (
                <Button variant="link" onClick={() => setIsEditing(true)}>
                  Add location details
                </Button>
              )}
            </div>
          )}
          {(deliveryType === DeliveryType.VideoCall || deliveryType === DeliveryType.PhoneCall) && (
            <div className={styles.subInfoContainer}>
              {(deliveryType === DeliveryType.VideoCall && videoCallInstructions) || phoneCallInstructions || (
                <Button variant="link" onClick={() => setIsEditing(true)}>
                  Add telehealth dial in details
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DeliveryMode;
