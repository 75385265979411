/**
 * Checksum algorithm found at https://docs.claiming.com.au/#5a70e3f9-491a-495f-b78f-4318d3a75b1a
 */

const PRACTICE_LOCATION_VALUES: Record<string, number | undefined> = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  J: 18,
  K: 19,
  L: 20,
  M: 21,
  N: 22,
  P: 23,
  Q: 24,
  R: 25,
  T: 26,
  U: 27,
  V: 28,
  W: 29,
  X: 30,
  Y: 31
};

const CHECK_CHARACTERS: Record<number, string | undefined> = {
  0: 'Y',
  1: 'X',
  2: 'W',
  3: 'T',
  4: 'L',
  5: 'K',
  6: 'J',
  7: 'H',
  8: 'F',
  9: 'B',
  10: 'A'
};

export const validateMedicareProviderNumber = (providerNumber: string): string => {
  if (providerNumber.length !== 8) {
    return 'Provider number must be 8 characters long';
  }

  const providerStem = providerNumber.slice(0, 6);
  const practiceLocationCharacter = providerNumber.charAt(6);
  const checkCharacter = providerNumber.charAt(7);

  if (isNaN(Number(providerStem))) {
    return 'The first 6 characters must be numbers';
  }

  const plv = PRACTICE_LOCATION_VALUES[practiceLocationCharacter];

  if (plv === undefined) {
    return 'Invalid provider number';
  }

  const [digit1, digit2, digit3, digit4, digit5, digit6] = providerStem.split('').map((digit) => Number(digit));

  const sum = digit1 * 3 + digit2 * 5 + digit3 * 8 + digit4 * 4 + digit5 * 2 + digit6 + plv * 6;
  const remainder = sum % 11;
  const correctCheckCharacter = CHECK_CHARACTERS[remainder];

  if (checkCharacter !== correctCheckCharacter) {
    return "Checksum didn't match";
  }

  return '';
};
