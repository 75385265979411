import { notification } from 'antd';
import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useEffect, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAssessorAssessments } from 'utils/http/CheckInService/Assessment/adhocAssessment';
import { QuestionResponses } from '../types';

const ASSESSMENTS_PER_PAGE = 4;

export type ClinicianQuestionResponsesWithMinifiedClientRecord = QuestionResponses & {
  clientRecord?: Pick<
    clientRecordsInterface,
    '_id' | 'clinicianAuth0Ids' | 'recordStatus' | 'recordType' | 'assessorIds' | 'class'
  > & {
    accountId: string;
    clientProfiles: Pick<
      clientProfilesInterface,
      '_id' | 'firstName' | 'lastName' | 'name' | 'avatar' | 'profileType' | 'initials'
    >[];
  };
};

interface PaginatedMinifiedClinicianQuestionResponsesWithMinifiedClientRecords {
  assessments: ClinicianQuestionResponsesWithMinifiedClientRecord[];
  paging: {
    totalItems: number;
  };
}

const useGetPaginatedAssessorAssessments = ({
  status,
  perPage = ASSESSMENTS_PER_PAGE,
  name
}: {
  status?: 'pending' | 'done';
  perPage?: number;
  name?: string;
}) => {
  const { token } = useGetAccessToken();
  const [clinicalAssessments, setClinicalAssessments] = useState<ClinicianQuestionResponsesWithMinifiedClientRecord[]>(
    []
  );
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const fetchAssessments = async (token: string) => {
    setIsLoading(true);
    try {
      const request = await getAssessorAssessments(token, {
        perPage,
        page: currentPage,
        status,
        name
      });
      const data = (await request.json()) as PaginatedMinifiedClinicianQuestionResponsesWithMinifiedClientRecords;
      setTotalItems(data.paging.totalItems);
      setTotalPage(Math.ceil(data.paging.totalItems / perPage));
      setClinicalAssessments(data.assessments);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch assessor's assessments"
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssessments(token);
    }
    // eslint-disable-next-line
  }, [token, currentPage, name]); // re-run `fetchAssessments` everytime `currentPage` or `name` state changes

  const navigatePage = (page: number) => {
    if (isLoading || page < 1 || page > totalPage) {
      return;
    }
    setCurrentPage(page);
  };

  return {
    currentPage,
    perPage,
    totalPage,
    totalItems,
    isLoading,
    navigatePage,
    clinicalAssessments,
    refetchAssessments: () => (token ? fetchAssessments(token) : undefined)
  };
};

export default useGetPaginatedAssessorAssessments;
