import { useState } from 'react';
import moment from 'moment';

import styles from './UpComingCardItem.module.scss';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import i18n from 'i18n';
import Button from 'components/v2/Button/Button';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';

interface UpComingCardItemProps {
  appointment: AppointmentSlots;
  isNextEvent: boolean;
  clinicianName: string;
  practiceName: string;
  refetchAppointment: () => void;
}

const UpComingCardItem = ({
  appointment,
  isNextEvent,
  clinicianName,
  practiceName,
  refetchAppointment
}: UpComingCardItemProps) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const appointmentTime = moment(appointment.date + ' ' + appointment.startTime).format('hh:mmA on dddd DD MMMM');
  const duration = moment(appointment.date + ' ' + appointment.endTime).diff(
    moment(appointment.date + ' ' + appointment.startTime),
    'minute'
  );

  return (
    <div className={isNextEvent ? styles.cardNext : styles.card}>
      <div className={styles.calendarIconWrapper}>
        <i className={`material-icons ${styles.calendarIcon}`}>insert_invitation</i>
      </div>
      <div className={styles.appointmentDetailWrapper}>
        {isNextEvent && <div className={styles.nextLabel}>NEXT</div>}
        <div className={styles.appointmentDetail}>
          {duration} minutes, {i18n.t(appointment.deliveryType!)} booked for
          <div className={styles.appointmentTime}>&nbsp;{appointmentTime}</div>
        </div>
        <div className={styles.bookedBy}>
          Appointment booked by{' '}
          {appointment.confirmation?.patient
            ? appointment.clientRecord?.clientProfiles[0].name
            : appointment.clinicianId
            ? clinicianName
            : practiceName}
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button
          variant={'link'}
          className={styles.editBtn}
          onClick={() => {
            setShowEditModal(true);
          }}
        >
          <span className={`material-icons-outlined ${styles.icon}`}>edit</span>
          Edit
        </Button>
        {showEditModal && (
          <EventInformationModal
            visible={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
            appointmentId={appointment._id}
            appointment={appointment}
            hideEditGroupBtn
            onEditComplete={() => refetchAppointment()}
            group={0}
          />
        )}
      </div>
    </div>
  );
};

export default UpComingCardItem;
