import styles from './AssessmentGuideLine.module.scss';

const AssessmentGuideLine = () => {
  return (
    <div className={styles.container}>
      <div className={styles.guideLineTitle}>
        <div className={styles.label}>Check-in Questions</div>
        <div className={styles.desc}>
          <i className={`material-icons ${styles.icon}`}>lock</i>
          <div className={styles.iconDesc}>Questions asked of all clients as part of check-ins</div>
        </div>
      </div>
      <div className={styles.pointDesc}>
        <i className={`material-icons ${styles.checkIcon}`}>done</i>
        Overall, how are you feeling?
      </div>
      <div className={styles.pointDesc}>
        <i className={`material-icons ${styles.checkIcon}`}>done</i>
        How are your energy levels?
      </div>
      <div className={styles.pointDesc}>
        <i className={`material-icons ${styles.checkIcon}`}>done</i>
        How have you slept the last few nights?
      </div>
      <div className={styles.pointDesc}>
        <i className={`material-icons ${styles.checkIcon}`}>done</i>
        How would you describe your appetite and eating habits the last few days?
      </div>
      <div className={styles.pointDesc}>
        <i className={`material-icons ${styles.checkIcon}`}>done</i>
        Which emotions best fit how you're feeling today?
      </div>
    </div>
  );
};

export default AssessmentGuideLine;
