import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import Select from 'components/v2/Select/Select';
import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import AssessmentLabel from 'MHH/components/AssessmentLabel/AssessmentLabel';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import PatientDetailsAssessments, {
  useFetchAssessmentByAssessmentId
} from '../PatientDetailsAssessments/PatientDetailsAssessments';
import styles from './AssessmentResult.module.scss';
import AssessmentResultDataRow from './components/AssessmentResultDataRow/AssessmentResultDataRow';
import AssessmentResultHeader from './components/AssessmentResultHeader/AssessmentResultHeader';
import noAssessment from 'assets/images/noAssessments.svg';
import { canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import CORDSSendAssessmentModal from '../../../../../Assessments/components/CORDSSendAssessmentModal/CORDSSendAssessmentModal';

interface AssessmentResultProps {
  recordId: string;
  clientRecord: clientRecordsInterface;
  assessmentList?: ClinicalAssessmentResponseList[];
  isAssessmentListLoading: boolean;
  assessmentId?: string;
}

const AssessmentResult = ({
  recordId,
  assessmentList,
  clientRecord,
  assessmentId,
  isAssessmentListLoading
}: AssessmentResultProps) => {
  const { t } = useTranslation();
  const [isSendAssessmentModalVisible, setIsSendAssessmentModalVisible] = useState(false);

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<string>();
  const [selectedAssessment_Id, setSelectedAssessment_Id] = useState<string>();
  const { assessment, isLoadingAssessment } = useFetchAssessmentByAssessmentId(recordId, selectedAssessment_Id);
  const [tab, setTab] = useState<'breakdown' | 'trendOverTime'>('breakdown');
  const [isExpandAll, setIsExpandAll] = useState(false);

  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const setSelectedAssessment_IdAndUpdateParam = useCallback(
    (id?: string) => {
      if (id) {
        setSelectedAssessment_Id(id);
        navigate(`${CLIENTS.BASE}/${recordId}/assessments/${id}`);
      }
    },
    [navigate, CLIENTS, recordId]
  );

  useEffect(() => {
    if (assessmentList && !selectedAssessmentId && !selectedAssessment_Id) {
      let selectedAssessment = assessmentList[0];
      if (assessmentId) {
        selectedAssessment = assessmentList.find((i) => i._id === assessmentId) || selectedAssessment;
      }
      setSelectedAssessmentId(selectedAssessment?.id);
      setSelectedAssessment_IdAndUpdateParam(selectedAssessment?._id);
    }
  }, [
    assessmentList,
    assessmentId,
    selectedAssessmentId,
    selectedAssessment_Id,
    setSelectedAssessment_IdAndUpdateParam
  ]);

  const isSDQ = assessment?.id.toLowerCase().includes('sdq');

  const noAssessments = !isAssessmentListLoading && assessmentList?.length === 0;

  const canReceiveSmsProfiles = clientRecord.clientProfiles.some((client) =>
    canClientReceiveSms(client.communicationPreference)
  );

  return (
    <div className={classNames(styles.container, noAssessments && styles.noItemsContainer)}>
      <CORDSSendAssessmentModal
        visible={isSendAssessmentModalVisible}
        onClose={() => setIsSendAssessmentModalVisible(false)}
        defaultSelectedStudents={[clientRecord]}
      />
      <div className={styles.headerContainer}>
        {!noAssessments && (
          <div>
            <Select
              filledArrow
              label="ASSESSMENT"
              isSearchable={false}
              options={assessmentList
                ?.filter((item, idx, arr) => arr.findIndex((i) => i.id === item.id) === idx)
                ?.map((i) => ({ value: i._id, label: i.id }))}
              onChange={(val) => {
                if (val?.label !== selectedAssessmentId) {
                  setSelectedAssessmentId(val?.label);
                  setSelectedAssessment_IdAndUpdateParam(val?.value);
                }
              }}
              //@ts-ignore
              value={
                assessmentList
                  ? selectedAssessmentId
                    ? {
                        value: assessmentList.find((i) => i.id === selectedAssessmentId)!._id,
                        label: selectedAssessmentId
                      }
                    : assessmentList[0]
                    ? { value: assessmentList[0]._id, label: assessmentList[0].id }
                    : undefined
                  : undefined
              }
              isLoading={isAssessmentListLoading}
              containerClass={styles.selectAssessmentId}
              controlStyle={{ boxShadow: 'none' }}
            />
          </div>
        )}
      </div>
      {noAssessments ? (
        <div className={styles.noItems}>
          <img src={noAssessment} className={styles.noItemsImage} alt="No Assessments" />
          <div className={styles.noItemsText}>
            <div className={styles.noItemsTitle}>No assessment have been sent for this client.</div>
            <div className={styles.noItemsSendAssessment}>
              <Tooltip visible={canReceiveSmsProfiles ? false : undefined} title={t('form.disallowed_sms.description')}>
                <div>
                  <Button
                    icon="send"
                    onClick={() => setIsSendAssessmentModalVisible(true)}
                    variant="secondary"
                    disabled={!canReceiveSmsProfiles}
                  >
                    Send assessment
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : !assessment && isLoadingAssessment ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        assessment && (
          <div className={styles.content}>
            {isSDQ && (
              <div className={styles.totalScore}>
                {assessment.charts[0]?.total}
                <AssessmentLabel label={assessment.charts[0]?.label} className={styles.label} />
              </div>
            )}
            <AssessmentResultHeader
              assessmentList={assessmentList}
              isAssessmentListLoading={isAssessmentListLoading}
              setSelectedAssessment_Id={setSelectedAssessment_IdAndUpdateParam}
              selectedAssessmentId={selectedAssessmentId}
              clientRecord={clientRecord}
              selectedAssessment_Id={selectedAssessment_Id}
            />
            {isLoadingAssessment ? (
              <div className={styles.loadingBody}>
                <LoadingCircle />
              </div>
            ) : isSDQ ? (
              <div className={styles.body}>
                <div className={styles.actions}>
                  <div className={styles.tabs}>
                    <div
                      className={classNames(styles.tab, tab === 'breakdown' && styles.active)}
                      onClick={() => setTab('breakdown')}
                    >
                      Breakdown
                    </div>
                    <div
                      className={classNames(styles.tab, tab === 'trendOverTime' && styles.active)}
                      onClick={() => setTab('trendOverTime')}
                    >
                      Trend over time
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    {/* <Button variant="secondary" className={styles.compare}>
                      Compare
                    </Button> */}
                    <Button
                      variant="link"
                      className={styles.expandAll}
                      onClick={() => {
                        setIsExpandAll(!isExpandAll);
                      }}
                    >
                      <i className="material-icons-outlined">
                        {!isExpandAll ? 'add_circle_outline' : 'remove_circle_outline'}
                      </i>
                      {!isExpandAll ? 'Expand all' : 'Hide all'}
                    </Button>
                  </div>
                </div>
                <div className={styles.data}>
                  {assessment.charts.map((item, index) => (
                    <div className={styles.dataRow} key={index}>
                      <AssessmentResultDataRow chart={item} isExpanded={isExpandAll} assessment={assessment} />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={classNames(styles.body, 'cords-assessment-question')}>
                <PatientDetailsAssessments
                  recordId={recordId}
                  assessmentId={assessmentId}
                  assessmentList={assessmentList}
                  isLoadingAssessmentList={isAssessmentListLoading}
                  currentTab="assessments"
                  showHeader={false}
                  assessment={assessment}
                  isLoadingAssessment={isLoadingAssessment}
                />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default AssessmentResult;
