import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getGroupMembersByGroupId = (token: string, groupId: string, qParam?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/groups/${groupId}/client-records${qParam ? `?${qParam}` : ''}`
  ) as Promise<BentResponse>;

export const getUnassignedGroupMembersByGroupId = (
  token: string,
  accountId: string,
  groupId: string,
  qParam?: string
) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/client-records:getUnassignedClients${qParam ? `?${qParam}` : ''}`
  ) as Promise<BentResponse>;

export const patchAssignedGroupMember = (
  token: string,
  accountId: string,
  groupId: string,
  clientRecordIds: string[]
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/client-records`,
    {
      clientRecordIds
    }
  ) as Promise<BentResponse>;
