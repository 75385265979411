import Input from 'components/v2/Input/Input';
import { useField } from 'formik';
import styles from './MaxOccupancy.module.scss';

const RoomName = () => {
  const [inputProps, { touched, error }] = useField('maxOccupancy');
  return (
    <div className={styles.container}>
      <Input
        label="Maximum occupancy"
        error={touched ? error : undefined}
        inputProps={{
          ...inputProps,
          type: 'number'
        }}
      />
    </div>
  );
};

export default RoomName;
