import { Modal } from 'antd';
import classNames from 'classnames';
import { Report, ReportBuilderWidgetView, ReportWidgetType } from 'interfaces/Reports/report';
import { Session } from 'pages/Report/ReportDetails/interface';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchAssessmentByRecordProfileId } from 'utils/hooks/GetAssessmentList/assessment';
import { useFetchAllGraph } from 'utils/hooks/graph';
import { useGenerateHeadingTemplateDetails } from 'utils/hooks/headingTemplate';
import { useFetchMicroJournalEntries } from 'utils/hooks/microJournal';
import { useFetchQuickNotes } from 'utils/hooks/notes';
import { useGetAccessToken } from 'utils/hooks/token';

import AssessmentWrapper from './components/AssessmentWrapper/AssessmentWrapper';
import GraphWrapper from './components/GraphWrapper/GraphWrapper';
import MicroJournalWrapper from './components/MicroJournalWrapper/MicroJournalWrapper';
import NotesWrapper from './components/NotesWrapper/NotesWrapper';
import SessionLock from './components/SessionLock/SessionLock';
import TextEditorWrapper from './components/TextEditorWrapper/TextEditorWrapper';
import UnableEditMessage from './components/UnableEditMessage/UnableEditMessage';
import WidgetSection from './components/WidgetSection/WidgetSection';
import WidgetsListTemplate from './components/WidgetsListTemplate/WidgetsListTemplate';
import styles from './EditTab.module.scss';
import ReportTemplateWrapper from './components/ReportTemplateWrapper/ReportTemplateWrapper';
import { useFetchReportTemplateList } from 'utils/hooks/GetReports/getReportTemplateList';
import { ReportTemplate, ReportTemplateWidgetId } from 'interfaces/Reports/reportTemplate';
import { v4 as uuid } from 'uuid';
import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export interface GenerateTemplateLoadingInterface {
  templateId: string;
  loading: boolean;
}

interface EditTabProps {
  visible: boolean;
  selectedClient: Report['clientRecord'];
  onSelectReportTemplate: (val: ReportTemplate['items'], applyTemplateId: string) => void;
  isClientSelected: boolean;
  status: string;
  session?: Session;
  previewMode: boolean;
  isAuthor: boolean;
  onChangeSession: (bool: boolean) => void;
  onBackToEditMode: () => void;
}

const EditTab = ({
  visible,
  selectedClient,
  onSelectReportTemplate,
  status,
  session,
  previewMode,
  isAuthor,
  onChangeSession,
  onBackToEditMode
}: EditTabProps) => {
  const { token } = useGetAccessToken();
  const location = useLocation();
  const queryParam: { widgetMenu?: ReportBuilderWidgetView } = queryString.parse(location.search);
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [widgetContent, setWidgetContent] = useState<ReportBuilderWidgetView>(
    queryParam.widgetMenu === ReportBuilderWidgetView.ReportTemplateView
      ? queryParam.widgetMenu
      : ReportBuilderWidgetView.WidgetMenuView
  );
  const [generateTemplateLoading, setGenerateTemplateLoading] = useState<GenerateTemplateLoadingInterface>({
    templateId: '',
    loading: false
  });
  const showSessionLock = !isAuthor && status === 'inReview';
  const showUnableToEditMessage = previewMode && ((!isAuthor && status !== 'inReview') || isAuthor);

  const clientRecordId = selectedClient?._id || '';
  const clientProfileId = selectedClient?.clientProfiles?.length > 0 ? selectedClient.clientProfiles[0]._id : '';

  useEffect(() => {
    if (token && clientRecordId) {
      fetchAssessment();
      fetchQuickNotes();
      fetchMicroJournalEntries();
      fetchAllGraph(30);
      fetchReportTemplateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRecordId, token]);

  const { assessmentList, isLoadingAssessmentList, fetchAssessment } = useFetchAssessmentByRecordProfileId(
    token,
    clientRecordId
  );
  const { quickNotesData, isQuickNotesLoading, fetchQuickNotes } = useFetchQuickNotes(token, clientRecordId);
  const { microJournalEntries, isMicroJournalEntriesLoading, fetchMicroJournalEntries } = useFetchMicroJournalEntries(
    token,
    clientRecordId,
    clientProfileId
  );
  const { graphData, isAllGraphLoading, fetchAllGraph } = useFetchAllGraph(token, clientRecordId, clientProfileId);

  const { reportTemplateList, isTemplateListLoading, fetchReportTemplateList } = useFetchReportTemplateList(token);

  const { generateHeadingTemplate } = useGenerateHeadingTemplateDetails(token, clientRecordId, clientProfileId);

  const contents = [
    {
      id: ReportBuilderWidgetView.NotesWidgetView,
      label: 'Quick Note',
      count: quickNotesData.length,
      isLoading: isQuickNotesLoading
    }
  ];

  const insights = [
    {
      id: ReportBuilderWidgetView.PsychometricWidgetView,
      label: 'Psychometrics',
      count: assessmentList.length,
      isLoading: isLoadingAssessmentList
    },
    {
      id: ReportBuilderWidgetView.JournalCommentWidgetView,
      label: 'Micro Journal',
      count: microJournalEntries.length,
      isLoading: isMicroJournalEntriesLoading
    },
    {
      id: ReportBuilderWidgetView.LiveChartWidgetView,
      label: 'Lived Experience Graphs',
      count: isAllGraphLoading ? 0 : 4,
      isLoading: isAllGraphLoading
    }
  ];

  const onClickWidgetMenu = (menuType: ReportBuilderWidgetView) => {
    setWidgetContent(menuType);
  };

  const onSessionUpdate = async (bool: boolean) => {
    await onChangeSession(bool);
    setWidgetContent(ReportBuilderWidgetView.WidgetMenuView);
  };

  const onBackToEditing = () => {
    Modal.confirm({
      title: 'Are you sure you want to return this document to Draft?',
      icon: '',
      content: (
        <div>
          <br />
          <div>
            By taking this document back to draft you can edit and make changes that will cancel the current review
            process.
          </div>
          <br />
          <div>After you have made any changes you can re-start the review process again when ready.</div>
        </div>
      ),
      okText: 'Confirm',
      onOk: onBackToEditMode,
      onCancel: () => {}
    });
  };
  const firstAssessment = assessmentList[0];
  const mostRecentAssessment = assessmentList[assessmentList.length - 1];

  const generateChartData = (chartData: Assessment, widgetId: ReportTemplateWidgetId) => {
    if (widgetId === ReportTemplateWidgetId.EarliestPsychometric && firstAssessment) {
      return {
        id: firstAssessment?.id,
        charts: firstAssessment?.charts,
        createdAt: firstAssessment?.createdAt
      };
    } else if (widgetId === ReportTemplateWidgetId.RecentPsychometric && mostRecentAssessment) {
      return {
        id: mostRecentAssessment?.id,
        charts: mostRecentAssessment?.charts,
        createdAt: mostRecentAssessment?.createdAt
      };
    } else {
      return {};
    }
  };

  const massageTemplateItem = async (reportTemplateItem: ReportTemplate, applyTemplateId: string) => {
    setGenerateTemplateLoading({
      templateId: reportTemplateItem._id,
      loading: true
    });

    const massageRemoveAssessmentIfNotExist = reportTemplateItem.items.filter((objItem) =>
      objItem.details.widgetId === ReportTemplateWidgetId.EarliestPsychometric ||
      objItem.details.widgetId === ReportTemplateWidgetId.RecentPsychometric
        ? (objItem.details.widgetId === ReportTemplateWidgetId.EarliestPsychometric && firstAssessment) ||
          (objItem.details.widgetId === ReportTemplateWidgetId.RecentPsychometric && mostRecentAssessment)
        : objItem
    );

    let newTemplateItem = [];
    for (let obj of massageRemoveAssessmentIfNotExist) {
      newTemplateItem.push({
        ...obj,
        id: uuid(),
        details: {
          ...obj.details,
          applyTemplateId,
          type:
            obj.details.type === ReportWidgetType.BodyTextTemplateWidget
              ? ReportWidgetType.BodyTextWidget
              : obj.details.type,
          ...(obj.details.title && {
            title: await generateHeadingTemplate(obj.details.title)
          }),
          ...(obj.details.charts && generateChartData(obj.details.charts, obj.details.widgetId))
        }
      });
    }
    setGenerateTemplateLoading({
      templateId: reportTemplateItem._id,
      loading: false
    });
    return newTemplateItem;
  };

  const handleSelectTemplate = async (templateData: ReportTemplate) => {
    const applyTemplateId = uuid();
    const massageTemplateData = await massageTemplateItem(templateData, applyTemplateId);
    onSelectReportTemplate(massageTemplateData, applyTemplateId);
  };

  return (
    <div className={classNames(!visible && styles.hidden)}>
      {showSessionLock && <SessionLock session={session} onChangeSession={onSessionUpdate} />}
      {showUnableToEditMessage && (
        <UnableEditMessage status={status} isAuthor={isAuthor} onBackToEditing={onBackToEditing} />
      )}
      {!previewMode && (
        <>
          {widgetContent === ReportBuilderWidgetView.WidgetMenuView ? (
            <>
              <div className={styles.widgetGroup}>
                <TextEditorWrapper
                  onClickWidgetMenu={() => onClickWidgetMenu(ReportBuilderWidgetView.ReportTemplateView)}
                />
              </div>
              {!isEdgeReceptionist && (
                <>
                  <WidgetSection
                    title={'Copy Content From'}
                    icon={'content_copy'}
                    clientRecordId={clientRecordId}
                    menuItems={contents}
                    onClickWidgetMenu={onClickWidgetMenu}
                  />
                  <WidgetSection
                    title={'Embed insights'}
                    icon={'touch_app'}
                    clientRecordId={clientRecordId}
                    menuItems={insights}
                    onClickWidgetMenu={onClickWidgetMenu}
                  />
                </>
              )}
            </>
          ) : (
            <div className={styles.widgetWrapper}>
              {!isEdgeReceptionist && (
                <>
                  {widgetContent === ReportBuilderWidgetView.NotesWidgetView && (
                    <WidgetsListTemplate
                      onClickBackBtn={() => onClickWidgetMenu(ReportBuilderWidgetView.WidgetMenuView)}
                      title={'Quick Note'}
                    >
                      <NotesWrapper isLoading={isQuickNotesLoading} notesData={quickNotesData} />
                    </WidgetsListTemplate>
                  )}
                  {widgetContent === ReportBuilderWidgetView.JournalCommentWidgetView && (
                    <WidgetsListTemplate
                      onClickBackBtn={() => onClickWidgetMenu(ReportBuilderWidgetView.WidgetMenuView)}
                      title={'Micro Journal'}
                    >
                      <MicroJournalWrapper
                        isMicroJournalEntriesLoading={isMicroJournalEntriesLoading}
                        microJournalEntriesData={microJournalEntries}
                      />
                    </WidgetsListTemplate>
                  )}
                  {widgetContent === ReportBuilderWidgetView.LiveChartWidgetView && (
                    <WidgetsListTemplate
                      onClickBackBtn={() => onClickWidgetMenu(ReportBuilderWidgetView.WidgetMenuView)}
                      title={'Lived Experience Graphs'}
                    >
                      <GraphWrapper isAllGraphLoading={isAllGraphLoading} graphData={graphData} />
                    </WidgetsListTemplate>
                  )}
                  {widgetContent === ReportBuilderWidgetView.PsychometricWidgetView && (
                    <WidgetsListTemplate
                      onClickBackBtn={() => onClickWidgetMenu(ReportBuilderWidgetView.WidgetMenuView)}
                      title={'Psychometrics'}
                    >
                      <AssessmentWrapper isLoading={isLoadingAssessmentList} assessmentData={assessmentList} />
                    </WidgetsListTemplate>
                  )}
                </>
              )}
              {widgetContent === ReportBuilderWidgetView.ReportTemplateView && (
                <WidgetsListTemplate onClickBackBtn={() => onClickWidgetMenu(ReportBuilderWidgetView.WidgetMenuView)}>
                  <ReportTemplateWrapper
                    isLoading={isTemplateListLoading}
                    reportTemplateData={reportTemplateList}
                    generateTemplateLoading={generateTemplateLoading}
                    onSelectReportTemplate={handleSelectTemplate}
                    refetchReportTemplate={fetchReportTemplateList}
                  />
                </WidgetsListTemplate>
              )}
              <HeadingAssessment isLoading={isLoadingAssessmentList} assessmentData={assessmentList} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditTab;
