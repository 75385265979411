import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, FormikHelpers } from 'formik';
import { notification } from 'antd';
import { config } from 'config/config';
import { PracticeInfoInterface } from 'interfaces/Profile/Profile';
import * as yup from 'yup';

import { deletePracticeInfo, putPracticeInfo } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchPracticeInfo } from 'utils/hooks/GetProfileDetails/getProfileInfo';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/Button/Button';
import LoadingButton from 'components/v2/Button/Button';
import PracticeItem from './components/PracticeItem/PracticeItem';

import styles from './ProfilePractice.module.scss';

const { mobileNumberMinLength } = config;

const practiceSchema = yup.object().shape({
  practice: yup
    .object()
    .shape({
      locations: yup.array().of(yup.string()),
      logo: yup.string(),
      mobileNumber: yup
        .string()
        .required('Please provide a phone number for this practice')
        .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`),
      name: yup.string().required('Please provide a name for this practice')
    })
    .default(undefined)
    .nullable()
});

type PracticeInfoType = PracticeInfoInterface;

const ProfilePractice = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { accountId } = useGetAccountId();
  const { practiceData, isPracticeDataLoading } = useFetchPracticeInfo();

  const [activePracticeShowError, setActivePracticeShowError] = useState<boolean>(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleAddPractice = (setFieldValue: FormikHelpers<PracticeInfoType>['setFieldValue']) => {
    setFieldValue('practice', { locations: [''], mobileNumber: '', name: '' });
  };

  const handleRemovePractice = (setFieldValue: FormikHelpers<PracticeInfoType>['setFieldValue']) => {
    setFieldValue('practice', undefined);
  };

  const submitData = async (values: PracticeInfoType) => {
    setSaveButtonStatus('active');
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      if (values?.practice) {
        await putPracticeInfo(token, accountId, values.practice);
      } else {
        await deletePracticeInfo(token, accountId);
      }

      setSaveButtonStatus('finished');

      notification.success({
        message: 'School details updated',
        closeIcon: <span className="success">OK</span>
      });

      setTimeout(() => setSaveButtonStatus(''), 2000);
    } catch (ex) {
      console.error(ex);

      setSaveButtonStatus('');

      notification.error({ message: 'Something went wrong while trying to update your school details' });
    }
  };

  const handleSubmit = async (values: PracticeInfoType) => {
    if (values.practice) {
      if (values?.practice.name && values?.practice.mobileNumber) {
        await submitData(values);
      } else {
        setActivePracticeShowError(true);
      }
    } else {
      await submitData(values);
    }
  };

  return isPracticeDataLoading ? (
    <div className={styles.container}>
      <div className={styles.loading}>
        <LoadingDot />
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>School Information</div>
        <div className={styles.description}>Update your school information.</div>
      </div>
      <Formik initialValues={practiceData} validationSchema={practiceSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue, submitForm }) => {
          return (
            <>
              {values.practice ? (
                <PracticeItem
                  originalAvatar={practiceData?.practice?.logo}
                  showError={activePracticeShowError}
                  onHideError={() => setActivePracticeShowError(false)}
                  onRemovePractice={() => handleRemovePractice(setFieldValue)}
                />
              ) : (
                <Button
                  className={styles.addButton}
                  variant="secondary"
                  onClick={() => handleAddPractice(setFieldValue)}
                >
                  <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                  Add practice
                </Button>
              )}
              <div className={styles.buttonContainer}>
                <LoadingButton
                  className={styles.saveButton}
                  status={saveButtonStatus}
                  onClick={submitForm}
                  id={'savePracticeInformationId'}
                >
                  Save
                </LoadingButton>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfilePractice;
