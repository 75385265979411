import bent from 'bent';

const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

const updateData = async (data: any, authKey: string) => {
  const dataServiceReportRequest = bent(
    `${url}`,
    'PUT',
    'json',
    {
      Authorization: `Bearer ${authKey}`
    },
    200
  );
  const assessmentID = data.id;
  return await dataServiceReportRequest(`/micro-check-ins/${assessmentID}`, data);
};

const createData = (data: any, authKey: string) => {
  const dataServiceReportRequest = bent(
    `${url}`,
    'PUT',
    'json',
    {
      Authorization: `Bearer ${authKey}`
    },
    201
  );
  return dataServiceReportRequest(`/micro-check-ins`, data);
};

// const massageClinicalAssessment = (data: any) => {
//   const massageCA = [];
//   for (let obj of data) {
//     if (obj.isChecked) {
//       massageCA.push({
//         id: obj.id
//       });
//     }
//   }
//   return massageCA;
// };

const massageQuestions = (data: any) => {
  const massageQ = [];
  for (let obj of data) {
    const questionId = obj.id && obj.id.toString().includes('newId') ? null : obj.id;
    massageQ.push({
      ...obj,
      id: questionId,
      stem: obj.stem,
      isChecked: obj.isChecked,
      refKey: obj.refKey
    });
  }
  return massageQ;
};

const massageQuestionSet = (data: any) => {
  const massageQS = [];
  for (let obj of data) {
    massageQS.push({
      id: obj.id,
      sectionName: obj.sectionName,
      askFrequency: obj.askFrequency,
      questions: massageQuestions(obj.questions),
      refKey: obj.refKey
    });
  }
  return massageQS;
};

const massageData = (data: any) => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    isPublished: data.isPublished,
    isDefault: data.isDefault,
    // clinicalAssessments: massageClinicalAssessment(data.clinicalAssessments),
    questionSets: massageQuestionSet(data.questionSets)
  };
};

export const updateMCIAssessments = async (data: any, token: string) => {
  try {
    const refactorData = massageData(data);
    return await updateData(refactorData, token);
  } catch (error) {
    console.log('error in calling updateMCIAssessments', error);
  }

  return undefined;
};

export const createMCIAssessments = async (data: any, token: string) => {
  try {
    const refactorData = massageData(data);
    return await createData(refactorData, token);
  } catch (error) {
    console.log('error in calling createMCIAssessments', error);
  }

  return undefined;
};
