import { MouseEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import styles from './GroupSessionMenuList.module.scss';

interface GroupSessionMenuListProps {
  groupId: string;
  activeId: string;
  isLoading: boolean;
}

interface DefaultListProps {
  _id: ParticipantType;
  label: string;
  count: number;
}

const menuList: DefaultListProps[] = [
  {
    _id: ParticipantType.Group,
    label: 'Group',
    count: 0
  }
  // {
  //   _id: ParticipantType.OneToOne,
  //   label: 'One to One',
  //   count: 0
  // }
];

const GroupSessionMenuList = ({ groupId, activeId, isLoading }: GroupSessionMenuListProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const [activePath, setActivePath] = useState<ParticipantType>((activeId || ParticipantType.Group) as ParticipantType);

  useEffect(() => {
    setActivePath((activeId || ParticipantType.Group) as ParticipantType);
  }, [activeId]);

  const handleClickMenu = (id: ParticipantType) => (e: MouseEvent) => {
    e.stopPropagation();
    setActivePath(id);
    navigate(`${GROUPS.BASE}/${groupId}/sessions/${id}`);
  };

  return isLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.container}>
      {menuList.map(({ _id, label, count }) => (
        <div id={_id} className={styles.link} key={_id} onClick={handleClickMenu(_id)}>
          <FontAwesomeIcon className={styles.icon} icon={activePath === _id ? SolidCircle : RegularCircle} />
          <div className={styles.label}>
            <div className={styles.name}>{label}</div>
            {/*<div className={styles.count}>{count}</div>*/}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupSessionMenuList;
