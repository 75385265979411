import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';
import { useEffect, useState } from 'react';
import { getConsentFormListing } from 'utils/http/DocumentService/ConsentForm/consentForm';

import { getFirstAssessments } from '../../../store/CheckIn/GetFirstAssessment/action';
import { CheckBoxListing, newClientForm } from '../AddPatientModalV2Interface';

const getSurveyList = async (token: string) => {
  let onBoardingOptionList: CheckBoxListing[] = [];
  let consentOptionList: CheckBoxListing[] = [];

  const GetOBAssessments = await getFirstAssessments(token);
  onBoardingOptionList = GetOBAssessments.filter((oBObj) => oBObj.isPublished).map(({ id, name, isDefault }) => ({
    label: name,
    value: id,
    isDefault
  }));

  const GetConsentFormList = (await (await getConsentFormListing(token)).json()) as ConsentFormInterface[];
  consentOptionList = GetConsentFormList.filter((consentObj) => consentObj.isPublished).map(
    ({ _id, name, isDefault }) => ({
      label: name,
      value: _id,
      isDefault
    })
  );

  return { onBoardingOptionList, consentOptionList };
};

export const usePrefillNewClientForm = (token: string, clientDetails: newClientForm) => {
  const [clientFormData, setClientFormData] = useState(clientDetails);
  const [prefillLoading, setPrefillLoading] = useState(true);
  const [onBoardingList, setOnBoardingList] = useState<CheckBoxListing[]>([]);
  const [consentList, setConsentList] = useState<CheckBoxListing[]>([]);
  const [fetchSurveyList, setFetchSurveyList] = useState(true);
  const [defaultOnBoardingId, setDefaultOnBoardingId] = useState('');
  const [defaultConsentId, setDefaultConsentId] = useState('');

  const fetchPrefillClientData = async (formData: newClientForm) => {
    setPrefillLoading(true);
    let onBoardingSurveyList = onBoardingList;
    let consentSurveyList = consentList;
    if (fetchSurveyList) {
      const { onBoardingOptionList, consentOptionList } = await getSurveyList(token);
      setOnBoardingList(onBoardingOptionList);
      setConsentList(consentOptionList);
      onBoardingSurveyList = onBoardingOptionList;
      consentSurveyList = consentOptionList;
      setFetchSurveyList(false);
    }
    const defaultOBa = onBoardingSurveyList.find((oBa) => oBa.isDefault)?.value ?? '';
    setDefaultOnBoardingId(defaultOBa);
    const defaultConsentForm = consentSurveyList.find((consentObj) => consentObj.isDefault)?.value ?? '';
    setDefaultConsentId(defaultConsentForm);

    if (onBoardingSurveyList.length === 0) {
      formData.clientProfiles = formData.clientProfiles.map((client) => {
        return {
          ...client,
          onboarding: {
            ...client.onboarding,
            isCheckOnBoarding: false
          }
        };
      });
    }

    const newClientDetails = formData.clientProfiles.map((obj) => ({
      ...obj,
      onboarding: {
        ...obj.onboarding,
        onboardingSurvey: defaultOBa
      },
      consentForm: {
        ...obj.consentForm,
        consentFormId: defaultConsentForm,
        isCheckOnConsentForm: consentSurveyList.length > 0
      }
    }));
    setClientFormData({
      ...formData,
      clientProfiles: newClientDetails
    });
    setPrefillLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPrefillClientData(clientDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    clientFormData,
    setClientFormData,
    prefillLoading,
    onBoardingList,
    defaultOnBoardingId,
    consentList,
    defaultConsentId,
    fetchPrefillClientData
  };
};
