import { BentResponse } from 'bent';
import { httpClient } from '../httpClient';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getSessionTypes = (token: string, ParticipantType?: ParticipantType) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/session-types${ParticipantType ? `?participantType=${ParticipantType}` : ''}`
  ) as Promise<BentResponse>;

export const getSessionTypesByClinicianId = (token: string, clinicianId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinician/${clinicianId}/session-types`
  ) as Promise<BentResponse>;

export const getSessionTypeById = (token: string, sessionTypeId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/session-types/${sessionTypeId}`
  ) as Promise<BentResponse>;

export const postSessionType = (payload: any, token: string) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })('/session-types', payload);

export const putSessionType = (id: string, payload: any, token: string) =>
  httpClient('PUT', url!, 201, { Authorization: `Bearer ${token}` })(`/session-types/${id}`, payload);
