import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { Props } from 'react-select';
import styles from './SessionStartAt.module.scss';

const sessionStartAtOptions = [
  { label: 'On the hour', value: 'onTheHour' },
  { label: 'On the half hour', value: 'onTheHalfHour' },
  { label: 'Mixed', value: 'mixed' }
];

const propStyles: Props['styles'] = {
  container: (base) => ({ ...base, margin: 0 }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${styles.greyColor}`,
    borderRadius: 0,
    boxShadow: 'none'
  }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  dropdownIndicator: (base) => ({ ...base, padding: 0 })
};

const SessionStartAt = () => {
  return (
    <FormikSelect
      name="sessionStartOption"
      label="Start appointments"
      options={sessionStartAtOptions}
      styles={propStyles}
    />
  );
};

export default SessionStartAt;
