import InvoiceViewBasicDetails from './components/InvoiceViewBasicDetails/InvoiceViewBasicDetails';
import InvoiceTotal from './components/InvoiceTotal/InvoiceTotal';
import ItemsList from './components/ItemsList/ItemsList';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';

import styles from './InvoiceView.module.scss';
import { clientRecordsInterface } from '../../interfaces/Clients/clientsRecord';
import { Invoice, PaymentMethod } from 'pages/Invoices/interface';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import MedicareDetails from './components/MedicareDetails/MedicareDetails';

export interface InvoiceViewProps {
  clientRecord: clientRecordsInterface;
  clinician: {
    avatar: string;
    email: string;
    mobileNumber: string;
    name: string;
  };
  description: string;
  discount?: {
    type?: 'percent' | 'amount';
    value?: number;
  };
  dueDate: string;
  invoiceId: string;
  issueDate: string;
  items: {
    appointmentId?: string;
    overview: string;
    cost: number;
    tax: number;
    total: number;
    tags?: string[];
  }[];
  paymentMethods?: Omit<PaymentMethod, '_id'>[];
  practice?: {
    address: string;
    logo?: string;
    mobileNumber: string;
    name: string;
  };
  taxRate: number;
  selectedGroup?: GroupsFromAPI;
  medicare?: Invoice['medicare'];
  status?: Invoice['status'];
}

const InvoiceView = ({
  clientRecord,
  clinician,
  description,
  discount,
  dueDate,
  invoiceId,
  issueDate,
  items,
  paymentMethods,
  practice,
  taxRate,
  selectedGroup,
  medicare,
  status
}: InvoiceViewProps) => {
  return (
    <div className={styles.container}>
      <InvoiceViewBasicDetails
        clientRecord={clientRecord}
        clinician={clinician}
        dueDate={dueDate}
        invoiceId={invoiceId}
        issueDate={issueDate}
        practice={practice}
        selectedGroup={selectedGroup}
      />
      <div className={styles.descriptionContainer}>
        <div className={styles.label}>Description of services</div>
        <div className={styles.description}>{description}</div>
      </div>
      {medicare && <MedicareDetails medicare={medicare} />}
      <ItemsList discount={discount} items={items} />
      <InvoiceTotal discount={discount} items={items} taxRate={taxRate} status={status} />
      {paymentMethods && <PaymentMethods paymentMethods={paymentMethods} />}
    </div>
  );
};

export default InvoiceView;
