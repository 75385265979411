import { ReportTemplate, ReportTemplateStatus } from 'interfaces/Reports/reportTemplate';

export const defaultReportTemplate: ReportTemplate = {
  _id: '',
  title: '',
  status: ReportTemplateStatus.Draft,
  isEditing: false,
  updatedAt: new Date(),
  template: {
    dimensions: {
      height: 300,
      width: 840
    }
  },
  items: []
};

export const defaultReportTemplateValidation = {
  title: false,
  items: false
};
