import { ReactElement } from 'react';
import StepProgressBar from '../StepProgressBar/StepProgressBar';
import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  title: string | ReactElement;
  totalStep?: number;
  currentStep?: number;
  isCompleted?: boolean;
  onCancel: () => void;
  hideClose?: boolean;
  onClickBackStepBtn?: () => void;
}

const ModalHeader = ({
  title,
  totalStep,
  currentStep,
  isCompleted,
  onCancel,
  hideClose,
  onClickBackStepBtn
}: ModalHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
      </div>
      {totalStep && (
        <div className={styles.stepContainer}>
          {onClickBackStepBtn && currentStep && currentStep > 1 ? (
            <div className={styles.stepBackButton} onClick={onClickBackStepBtn}>
              <i className={`material-icons-outlined ${styles.icon}`}>arrow_back_ios</i>
              back
            </div>
          ) : null}
          <StepProgressBar
            className={styles.stepProgressBar}
            totalStep={totalStep}
            currentStep={currentStep || 0}
            isCompleted={isCompleted || false}
          />
        </div>
      )}
      {!hideClose && (
        <div className={styles.closeButton} onClick={onCancel}>
          <span className="material-icons-outlined">close</span>
        </div>
      )}
    </div>
  );
};

export default ModalHeader;
