import * as Yup from 'yup';
import moment from 'moment';
import { Attendee } from 'interfaces/Schedule/Appointment';

export const initialValues = {
  eventOwner: '',
  isRecurring: false,
  frequency: '0',
  occurrences: '0',
  title: '',
  notes: '',
  date: '',
  startTime: '10:00',
  endTime: '12:00',
  faceToFaceLocation: '',
  bookRoom: false,
  roomInfo: '',
  attendees: [] as Attendee[]
};

export const appointmentBeSpokeSchema = Yup.object().shape({
  isRecurring: Yup.boolean(),
  frequency: Yup.number().when('isRecurring', {
    is: true,
    then: Yup.number().moreThan(0, 'Please select frequency'),
    otherwise: Yup.number()
  }),
  occurrences: Yup.number().when('isRecurring', {
    is: true,
    then: Yup.number().moreThan(0, 'Please select occurrences'),
    otherwise: Yup.number()
  }),
  title: Yup.string().required('Please provide a title'),
  date: Yup.string().required(),
  startTime: Yup.string().required('Start time cannot be empty'),
  endTime: Yup.string()
    .required('End time cannot be empty')
    .test('invalidTimeSlot', 'Invalid time slot', function (value) {
      const { startTime } = this.parent;
      const beginningTime = moment(startTime, 'hh:mm');
      const endTime = moment(value, 'hh:mm');
      return beginningTime.isBefore(endTime);
    }),
  faceToFaceLocation: Yup.string(),
  roomInfo: Yup.string().when('bookRoom', {
    is: true,
    then: Yup.string().required('Please choose the room'),
    otherwise: Yup.string()
  })
});
