import { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  CheckInQuestion,
  ClinicalAssessment,
  MicroJournalQuestion,
  OnboardingQuestion,
  Option,
  Section,
  SubQuestion
} from '../../../../AssessmentInterfaces';

import Question from '../Question/Question';
import SubmitButton from '../SubmitButton/SubmitButton';

import { PatientQuestionFormHeaderProps } from '../../../../PatientQuestionForm';

interface IndividualQuestionProps {
  sections: Section[];
  completedQuestions: number;
  totalQuestions?: number;
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  title: string;
  reverseStemAndHideTitle: boolean;
  isSkippable: boolean;
  isFirstQuestionSet: boolean;
  onBack: () => void;
  onSkip: () => void;
  onSubmit: (
    values: any,
    setStageToNextQuestion?: boolean
  ) => Promise<CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion | undefined>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  onSaveAndExit: () => void;
  submitBtnText?: JSX.Element;
  submitBtnClass?: string;
}

const useQuestionStage = () => {
  const [stage, setStage] = useState(-1);

  const nextStage = () => {
    setStage(stage + 1);
  };

  const prevStage = () => {
    if (stage > 0) {
      setStage(stage - 1);
    }
  };

  const resetStage = () => {
    setStage(0);
  };

  return { stage, nextStage, prevStage, resetStage, setStage };
};

const IndividualQuestion = ({
  sections,
  completedQuestions,
  totalQuestions,
  defaultPatientDetails,
  header: Header,
  title,
  reverseStemAndHideTitle,
  isSkippable,
  isFirstQuestionSet,
  onBack,
  onSkip,
  onSubmit,
  setIsSubmitting,
  onSaveAndExit,
  submitBtnText,
  submitBtnClass
}: IndividualQuestionProps) => {
  useEffect(() => {
    if (sections) {
      checkAndSetStage(sections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const questions = useMemo(() => {
    return sections.map((section) => section.questions).flat();
  }, [sections]);

  const { stage, nextStage, prevStage, resetStage, setStage } = useQuestionStage();

  // eslint-disable-next-line complexity
  const { initialValues, validationSchema } = useMemo(() => {
    if (questions[stage]) {
      const { id, maxSelection, questionType, options, response } = questions[stage];

      const initialValues = {} as Record<string, any>;
      const validationSchema = {} as Record<string, any>;

      switch (questionType) {
        case 'barSlider':
          initialValues[id] = response || { value: 20 };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.number().typeError('Please select an option').required('Please answer the question')
              : Yup.number().typeError('Please select an option')
          });
          break;
        case 'freeText':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable ? Yup.string().required('Please answer the question') : Yup.string()
          });
          break;
        case 'hexSlider':
          initialValues[id] = response || { value: 6 };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.number().typeError('Please select an option').required('Please answer the question')
              : Yup.number().typeError('Please select an option')
          });
          break;
        case 'multipleAnswers':
          initialValues[id] = response || {
            value: (options as SubQuestion[]).reduce((initialValues, { id }) => {
              initialValues[id] = '';

              return initialValues;
            }, {} as Record<string, any>)
          };
          validationSchema[id] = Yup.object().shape({
            value: Yup.object().shape(
              (options as SubQuestion[]).reduce((validationSchema, { id }) => {
                validationSchema[id] = !isSkippable ? Yup.string().required('Please select an option') : Yup.string();

                return validationSchema;
              }, {} as Record<string, any>)
            )
          });
          break;
        case 'multipleChoice':
          initialValues[id] = response || { value: maxSelection === 0 ? [] : '' };
          validationSchema[id] = Yup.object().shape({
            value:
              maxSelection === 0
                ? !isSkippable
                  ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                  : Yup.array().of(Yup.string())
                : !isSkippable
                ? Yup.string().required('Please select an option')
                : Yup.string()
          });
          break;
        case 'multipleChoiceFreeText':
          initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
          validationSchema[id] = Yup.object().shape({
            value:
              maxSelection === 0
                ? !isSkippable
                  ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                  : Yup.array().of(Yup.string())
                : !isSkippable
                ? Yup.string().required('Please select an option')
                : Yup.string(),
            otherValue: Yup.string()
          });
          break;
        case 'numeric':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.number().typeError('Please enter a valid number').required('Please answer the question')
              : Yup.number().typeError('Please enter a valid number')
          });
          break;
        case 'selectOther':
          initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
          validationSchema[id] = Yup.object().shape({
            value:
              maxSelection === 0
                ? !isSkippable
                  ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                  : Yup.array().of(Yup.string())
                : !isSkippable
                ? Yup.string().required('Please select an option')
                : Yup.string(),
            otherValue: Yup.string().when('value', {
              is: 'other',
              then: !isSkippable ? Yup.string().required('Please elaborate') : Yup.string(),
              otherwise: Yup.string()
            })
          });
          break;
        case 'vote':
          initialValues[id] = response || {
            value: (options as Option[]).reduce((initialValues, { key }) => {
              initialValues[key] = 0;

              return initialValues;
            }, {} as Record<string, any>)
          };
          validationSchema[id] = Yup.object().shape({
            value: Yup.object().shape(
              (options as Option[]).reduce((validationSchema, { key }) => {
                validationSchema[key] = !isSkippable
                  ? Yup.number().required('Please vote on this question')
                  : Yup.number();

                return validationSchema;
              }, {} as Record<string, any>)
            )
          });
          break;

        default:
          break;
      }

      return { initialValues, validationSchema: Yup.object().shape(validationSchema) };
    } else {
      return { initialValues: {}, validationSchema: Yup.object() };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, stage]);

  const checkAndSetStage = (sections: Section[]) => {
    const questions = sections.map((section) => section.questions).flat();

    let currentStage = 0;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].response) {
        currentStage++;
      } else {
        break;
      }
    }

    if (currentStage < questions.length) {
      setStage(currentStage);
    } else {
      setStage(currentStage - 1);
    }
  };

  const handleBack = () => {
    if (stage > 0) {
      prevStage();
    } else {
      onBack();
    }
  };

  const handleSkip = async (values: any) => {
    const setStageToNextQuestion = stage === questions.length - 1;

    const isTrue = await handleSubmit(values, setStageToNextQuestion);
    if (!isTrue) {
      if (setStageToNextQuestion) {
        resetStage();
        onSkip();
      } else {
        nextStage();
      }
    }
  };

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const handleSubmit = async (values: any, setStageToNextQuestion?: boolean) => {
    setIsSubmitting(true);

    const updatedQuestionSet = await onSubmit(values, setStageToNextQuestion);

    if (updatedQuestionSet) {
      checkAndSetStage(updatedQuestionSet.sections);
      setIsSubmitting(false);
      return true;
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={isSubmitButtonClicked}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize
      >
        <Form className="question-form-container" noValidate>
          <Header
            completedQuestions={completedQuestions}
            totalQuestions={totalQuestions}
            patientDetails={defaultPatientDetails}
            isFirstQuestionSet={isFirstQuestionSet && stage === 0}
            isSkippable={isSkippable}
            onBack={handleBack}
            onSkip={handleSkip}
            onSaveAndExit={onSaveAndExit}
          />
          {questions[stage] && (
            <Question
              question={questions[stage]}
              title={title}
              reverseStemAndHideTitle={reverseStemAndHideTitle}
              defaultPatientDetails={defaultPatientDetails}
            />
          )}
          <SubmitButton type="submit" className={submitBtnClass} onClick={() => setIsSubmitButtonClicked(true)}>
            {submitBtnText || 'Next'}
          </SubmitButton>
        </Form>
      </Formik>
    </>
  );
};

export default IndividualQuestion;
