import { notification } from 'antd';

export const handleCopyContent = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value);
    notification.success({
      message: 'Copied!',
      duration: 1,
      closeIcon: <span className="success">OK</span>
    });
  } catch (err) {
    notification.error({
      message: 'Failed to copy!',
      duration: 1,
      closeIcon: <span className="success">OK</span>
    });
  }
};
