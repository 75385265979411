import classnames from 'classnames';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { combineName } from 'utils/general';

import { clientProfilesInterface } from '../../interfaces/Clients/clientsRecord';
import styles from './ClientAvatar.module.scss';
interface ClientAvatarProps {
  avatarUrl?: string;
  name?: string;
  description?: string;
  initialsName?: string;
  avatarSize?: number;
  horizontal?: boolean;
  nameClassName?: string;
  initialsClassName?: string;
  avatarClassName?: string;
  clientData?: Pick<
    clientProfilesInterface,
    'firstName' | 'lastName' | 'name' | 'avatar' | 'initials' | 'initialsBackgroundColor'
  >[];
  showBalanceLabel?: boolean;
  displayFirstNameOnly?: boolean;
  displayLimit?: number;
  isEllipsisName?: boolean;
  statusCircle?: 'active' | 'inactive';
  containerClassName?: string;
  overlayCircle?: boolean;
  hideName?: boolean;
  fullAvatarBg?: boolean;
  hideAvatar?: boolean;
}

const ClientAvatar = ({
  avatarUrl,
  name,
  description,
  initialsName,
  avatarSize,
  horizontal,
  nameClassName,
  initialsClassName,
  clientData,
  showBalanceLabel,
  displayFirstNameOnly,
  displayLimit,
  isEllipsisName,
  containerClassName,
  avatarClassName,
  statusCircle,
  overlayCircle,
  hideName,
  fullAvatarBg,
  hideAvatar
}: ClientAvatarProps) => {
  const limitAvatarDisplay = displayLimit || 1;
  const size = avatarSize || 50;
  const multipleClient = clientData ? clientData.filter((a, index) => index < limitAvatarDisplay) : [];
  const totalClient = multipleClient?.length || 0;
  const avatarWidth = size + 30 * (totalClient - 1);
  const leftPosition = size > 40 ? size - 20 : size - 10;

  return (
    <div className={classnames(containerClassName, horizontal ? styles.horizontalContainer : styles.container)}>
      {clientData ? (
        <>
          <div className={styles.avatarMultipleBox}>
            {!hideAvatar && (
              <div className={styles.avatarMultipleContainer} style={{ width: avatarWidth }}>
                {multipleClient.map((clientObj, index) => (
                  <div
                    className={classnames(avatarClassName, styles.avatarMultipleWrapper)}
                    key={index}
                    style={{
                      height: `${size}px`,
                      width: `${size}px`,
                      left: overlayCircle ? `${leftPosition * index + 1}px` : ''
                    }}
                  >
                    <ClientProfileAvatar
                      avatarUrl={clientObj.avatar}
                      initialsName={clientObj.initials}
                      initialClassName={initialsClassName}
                      statusCircle={statusCircle}
                      fullAvatarBg={fullAvatarBg}
                    />
                  </div>
                ))}
              </div>
            )}
            {showBalanceLabel && clientData.length - limitAvatarDisplay > 0 && (
              <div className={styles.showBalanceLabel}>+{clientData.length - limitAvatarDisplay}</div>
            )}
          </div>
          {!hideName && (
            <div
              style={isEllipsisName ? { maxWidth: `${avatarSize ? avatarSize + 30 : 0}px` } : {}}
              className={classnames(
                styles.name,
                nameClassName,
                isEllipsisName && styles.ellipsisName,
                hideAvatar && styles.hideAvatar
              )}
            >
              <div>{combineName(multipleClient, displayFirstNameOnly)}</div>
              {description && <div className={styles.description}>{description}</div>}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.avatarWrapper} style={{ height: `${avatarSize}px`, width: `${avatarSize}px` }}>
            <ClientProfileAvatar
              avatarUrl={avatarUrl}
              initialsName={initialsName}
              initialClassName={initialsClassName}
              statusCircle={statusCircle}
            />
          </div>
          {name && (
            <div
              style={isEllipsisName ? { maxWidth: `${avatarSize ? avatarSize + 30 : 0}px` } : {}}
              className={classnames(styles.name, nameClassName, isEllipsisName && styles.ellipsisName)}
            >
              <div>{name}</div>
              {description && <div className={styles.description}>{description}</div>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ClientAvatar;
