import { useState, useEffect } from 'react';
import { getClientAppointmentsByDateRange } from 'utils/http/ScheduleService/Appointments/Appointments';
import { notification } from 'antd';

export const useFetchAppointmentsByPatient = (from: string, to: string, token: string, clientRecordId: string) => {
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAppointment = async () => {
    setIsLoading(true);

    try {
      const getAppointmentResponse = await getClientAppointmentsByDateRange(token, from, to, clientRecordId);

      const appointment = await getAppointmentResponse.json();

      setAppointmentsData(appointment);
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get this patient's appointment" });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appointmentsData, isLoading, fetchAppointment };
};
