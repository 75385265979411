import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import Button from 'components/Button/Button';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import FormikMaterialPhoneInput from 'components/MaterialPhoneInput/FormikMaterialPhoneInput';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import LoadingButton from 'components/v2/Button/Button';
import { Formik, FormikHelpers } from 'formik';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useState } from 'react';
import { updateClinicianContactDetails, uploadAvatar } from 'utils/http/clinician';
import { updateClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { getEmailUsed } from 'utils/http/verification';
import * as yup from 'yup';
import * as Yup from 'yup';

import { salutationOptions } from '../../../../../../../../SignUp/components/AboutYou/AboutYou';
import AvatarEditor from '../AvatarEditor/AvatarEditor';
import ConfirmUpdateContactDetailsModal from './components/ConfirmUpdateContactDetailsModal/ConfirmUpdateContactDetailsModal';
import styles from './ProfileAboutYou.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTranslation } from 'react-i18next';

const PRONOUN_OPTIONS = [
  { label: 'He / Him', value: 'heHim' },
  { label: 'She / Her', value: 'sheHer' },
  { label: 'They / Them', value: 'theyThem' }
];

export const SALUTATION_OPTIONS = [
  ...salutationOptions,
  { label: 'Other', value: 'Other' },
  { label: 'None', value: 'None' }
];

const profileSchema = yup.object().shape({
  avatar: yup.string().nullable(),
  bio: yup.string().nullable(),
  consultantLocations: yup.array().of(yup.string()),
  email: yup.string().required('Please enter your email address'),
  mobileNumber: yup
    .string()
    .min(11, 'Mobile number must be 10 digits or longer')
    .required('Please enter your mobile phone number'),
  name: yup.string().required('Please enter your preferred name'),
  titleValue: Yup.string().when('title', {
    is: (title) => title === 'Other',
    then: Yup.string().required('Please tell us how we should address you'),
    otherwise: Yup.string()
  }),
  pronouns: yup.string().nullable(),
  specialisations: yup.array().of(yup.string())
});

interface ProfileAboutYouProps {
  profile: ProfileInterface;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
}

const ProfileAboutYou = ({ profile, onUpdateProfile }: ProfileAboutYouProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [t] = useTranslation();
  const { isMentorUser } = useGetAccountPackageView();
  const [isAvatarEditorVisible, setIsAvatarEditorVisible] = useState(false);
  const [isEmailAddressInputDisabled, setIsEmailAddressInputDisabled] = useState(true);
  const [isMobileNumberInputDisabled, setIsMobileNumberInputDisabled] = useState(true);
  const [isUpdateContactDetailsModalVisible, setIsUpdateContactDetailsModalVisible] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const beforeInitialUploadAvatar = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: `You can only upload JPG or PNG` });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: `Image cannot be bigger than 2MB!` });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleInitialUploadAvatar = async (
    { file, onSuccess }: any,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    setUploadingAvatar(true);
    const data = new FormData();
    data.append('file', file);
    const res = (await uploadAvatar(data)) as {
      [key: string]: any;
      [key: number]: any;
    };

    const body = await res.json();
    setFieldValue('avatar', body.data.url);
    setUploadingAvatar(false);
    onSuccess(res);
  };

  const handleAvatarEditorOpen = () => {
    setIsAvatarEditorVisible(true);
  };

  const handleAvatarEditorClose = () => {
    setIsAvatarEditorVisible(false);
  };

  const handleUpdateContactDetailsModalClose = () => {
    setIsUpdateContactDetailsModalVisible(false);
  };

  const handleAvatarEditorCancel = (
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue'],
    originalAvatar?: string
  ) => {
    setFieldValue('avatar', originalAvatar);
    handleAvatarEditorClose();
  };

  const handleRemoveSpecialisation = (
    specialisation: string,
    values: ProfileInterface,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    setFieldValue(
      'specialisations',
      values.specialisations.filter((value) => value !== specialisation)
    );
  };

  const handleVerifyError = (setFieldError: FormikHelpers<ProfileInterface>['setFieldError']) => {
    handleUpdateContactDetailsModalClose();

    notification.error({ message: 'Invalid phone number' });
    setFieldError('mobileNumber', 'Invalid phone number');
    setSaveButtonStatus('');
  };

  const handleUpdateProfile = async (values: ProfileInterface) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      await updateClinicianProfile(token, values);

      if (values.email !== profile.email || values.mobileNumber !== profile.mobileNumber) {
        await updateClinicianContactDetails(token, { email: values.email, mobileNumber: values.mobileNumber });
      }

      onUpdateProfile(values);

      handleUpdateContactDetailsModalClose();

      setIsEmailAddressInputDisabled(true);
      setIsMobileNumberInputDisabled(true);
      setSaveButtonStatus('finished');

      notification.success({
        message: 'Profile updated',
        closeIcon: <span className="success">OK</span>
      });

      setTimeout(() => setSaveButtonStatus(''), 2000);
    } catch (ex) {
      console.error(ex);

      setSaveButtonStatus('');

      notification.error({ message: 'Something went wrong while trying to update your schools' });
    }
  };

  const handleSubmit = async (values: ProfileInterface, { setFieldError }: FormikHelpers<ProfileInterface>) => {
    setSaveButtonStatus('active');

    if (values.email !== profile.email || values.mobileNumber !== profile.mobileNumber) {
      try {
        if (values.email !== profile.email) {
          const callGetClinicianEmailUsed = await getEmailUsed(values.email);

          const { used } = await callGetClinicianEmailUsed.json();

          if (used) {
            notification.error({ message: 'Email already in use' });
            setFieldError('email', 'Email already in use');
            setSaveButtonStatus('');
            return;
          }
        }
      } catch (ex) {
        notification.error({ message: 'Invalid email format' });
        setFieldError('email', 'Invalid email format');
        setSaveButtonStatus('');
        return;
      }

      setIsUpdateContactDetailsModalVisible(true);
    } else {
      const checkTitleValueIsNone = values.title !== 'None' ? values.title : '';
      const massageUpdateData = {
        ...values,
        title: values.title === 'Other' ? values.titleValue : checkTitleValueIsNone,
        titleValue: values.title !== 'Other' ? checkTitleValueIsNone : values.titleValue
      };
      await handleUpdateProfile(massageUpdateData);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>About You</div>
        <div className={styles.description}>Write an overview about yourself</div>
      </div>
      <Formik initialValues={profile} validationSchema={profileSchema} onSubmit={handleSubmit}>
        {({ values, setFieldError, setFieldValue, submitForm }) => (
          <>
            <AvatarEditor
              borderRadius={1000}
              filenameSuffix="profile-avatar"
              imgSrc={values.avatar}
              placeholder="Add your profile picture"
              title="Upload profile picture"
              visible={isAvatarEditorVisible}
              isAvatarUploading={uploadingAvatar}
              onBeforeUpload={beforeInitialUploadAvatar}
              onCancel={() => handleAvatarEditorCancel(setFieldValue, values.avatar)}
              onClose={handleAvatarEditorClose}
              onUploadAvatar={(value) => handleInitialUploadAvatar(value, setFieldValue)}
            />
            <ConfirmUpdateContactDetailsModal
              mobileNumber={values.mobileNumber}
              visible={isUpdateContactDetailsModalVisible}
              onCancel={handleUpdateContactDetailsModalClose}
              onSubmit={() => handleUpdateProfile(values)}
              onVerifyError={() => handleVerifyError(setFieldError)}
            />
            <div className={styles.basicDetails}>
              <div className={styles.avatarContainer} id={'profileAvatarId'}>
                {values.avatar ? (
                  <img className={styles.avatar} src={values.avatar} alt="Avatar" onClick={handleAvatarEditorOpen} />
                ) : (
                  <div className={styles.uploadAvatarPrompt} onClick={handleAvatarEditorOpen}>
                    <i className={`material-icons-outlined ${styles.icon}`}>add_a_photo</i>
                    ADD PROFILE PIC
                  </div>
                )}
              </div>
              <div className={styles.detailsInputContainer} id={'detailsInputContainerId'}>
                <div className={styles.name}>
                  {profile?.titleValue} {profile.name}
                </div>
                <div className={styles.salutationContainer}>
                  <div className={styles.salutationOptionContainer}>
                    <div className={styles.label}>Your salutation</div>
                    <FormikSelect
                      styles={{
                        valueContainer: (base: any) => ({
                          ...base,
                          paddingLeft: 0
                        })
                      }}
                      name={'title'}
                      options={SALUTATION_OPTIONS}
                    />
                  </div>
                  {values.title === 'Other' && (
                    <div className={styles.otherValueContainer}>
                      <div className={styles.label}>Salutation</div>
                      <FormikMaterialInput maxLength={8} label="" name={'titleValue'} required />
                    </div>
                  )}
                </div>
                <div className={styles.nameDetailsContainer}>
                  <div className={styles.nameContainer}>
                    <div className={styles.label}>
                      Preferred name
                      <HelpOutLineWithTooltips
                        id={'name-input'}
                        desc={`This is the name we will show to ${t(
                          'label.client'
                        )} whenever you are referenced in a message or communication. You can go informal e.g. just your first name, or more formal e.g. Mr S Howard depending on what best fits your communication style`}
                      />
                    </div>
                    <FormikMaterialInput label="" name="name" required />
                  </div>
                  <div className={styles.pronounsContainer}>
                    <div className={styles.label}>
                      Your pronouns
                      <HelpOutLineWithTooltips
                        id={'pronoun-select'}
                        desc={
                          'Please select your preferred pronouns. This will be used whenever you are referenced in the third person. E.g. Mr Sarah Howard has requested you send her an updated referral '
                        }
                      />
                    </div>
                    <FormikSelect
                      styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                      name="pronouns"
                      options={PRONOUN_OPTIONS}
                    />
                  </div>
                </div>
                <div className={styles.mobileNumberContainer}>
                  {isEmailAddressInputDisabled ? (
                    <div className={styles.mobileNumberValue}>
                      <div className={styles.label}>Mobile phone number</div>
                      <div className={styles.value}>{values.mobileNumber}</div>
                    </div>
                  ) : (
                    <div className={styles.mobileFieldWrapper}>
                      <FormikMaterialPhoneInput
                        className={styles.inputContainer}
                        label="Mobile phone number*"
                        name="mobileNumber"
                        onChange={(e: string) => setFieldValue('mobileNumber', e)}
                        required
                      />
                    </div>
                  )}
                  {isEmailAddressInputDisabled && (
                    <Button
                      className={styles.unlockButton}
                      variant="secondary"
                      onClick={() => setIsEmailAddressInputDisabled(false)}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>lock</i>
                      Unlock to edit
                    </Button>
                  )}
                </div>
                <div className={styles.emailContainer}>
                  {isMobileNumberInputDisabled ? (
                    <div className={styles.emailValue}>
                      <div className={styles.label}>Email address</div>
                      <div className={styles.value}>{values.email}</div>
                    </div>
                  ) : (
                    <FormikMaterialInput
                      className={styles.inputContainer}
                      label="Email address*"
                      name="email"
                      required
                    />
                  )}
                  {isMobileNumberInputDisabled && (
                    <Button
                      className={styles.unlockButton}
                      variant="secondary"
                      onClick={() => setIsMobileNumberInputDisabled(false)}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>lock</i>
                      Unlock to edit
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {!isMentorUser && (
              <>
                <div className={styles.professionalSummaryContainer} id={'professionalSummaryId'}>
                  <div className={styles.title}>Professional Summary</div>
                  <div className={styles.bioContainer}>
                    <textarea
                      className={styles.bioInput}
                      maxLength={1500}
                      rows={4}
                      value={values.bio || ''}
                      onChange={(e) => setFieldValue('bio', e.target.value)}
                    />
                    <span className={styles.characterCount}>
                      {values.bio?.length || 0} / {1500} characters
                    </span>
                  </div>
                </div>
                <div className={styles.specialisationsContainer} id={'specialisationsContainerId'}>
                  <div className={styles.specialisationsValueContainer}>
                    {values.specialisations.map((specialisation) => (
                      <div className={styles.specialisation} key={specialisation}>
                        {specialisation}
                        <div
                          className={styles.iconContainer}
                          onClick={() => handleRemoveSpecialisation(specialisation, values, setFieldValue)}
                        >
                          <i className={`material-icons-outlined ${styles.icon}`}>close</i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className={styles.buttonContainer}>
              <LoadingButton
                className={styles.saveButton}
                status={saveButtonStatus}
                disabled={!!saveButtonStatus}
                onClick={submitForm}
                id={'saveProfileId'}
              >
                Save
              </LoadingButton>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ProfileAboutYou;
