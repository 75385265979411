import { useState } from 'react';
import styles from './SessionInstruction.module.scss';
import Button from 'components/v2/Button/Button';
import ReadMore from 'components/ReadMore/ReadMore';
import SessionIntroModal from './components/SessionIntroModal/SessionIntroModal';

interface SessionInstructionProps {
  instructions: string;
  groupId: string;
  sessionId: string;
}

const SessionInstruction = ({ instructions, groupId, sessionId }: SessionInstructionProps) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [sessionInstructions, setSessionInstructions] = useState(instructions);

  const handleEditIntro = () => {
    setShowEditModal(!showEditModal);
  };

  const handleChangeIntroduction = (val: string) => {
    setSessionInstructions(val);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>INSTRUCTIONS FOR THIS SESSION</div>
        <Button variant={'link'} className={styles.editBtn} onClick={handleEditIntro}>
          <span className={`material-icons-outlined ${styles.icon}`}>edit</span>
          Edit instructions
        </Button>
      </div>
      <div>
        <ReadMore
          bodyClass={styles.bodyClass}
          emptyText={'No Instructions added'}
          text={sessionInstructions}
          maxHeight={180}
        />
      </div>
      <SessionIntroModal
        fieldValue={sessionInstructions}
        onChangeValue={handleChangeIntroduction}
        visible={showEditModal}
        onCloseModal={() => setShowEditModal(false)}
        groupId={groupId}
        sessionId={sessionId}
      />
    </div>
  );
};

export default SessionInstruction;
