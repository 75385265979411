import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './AssessmentQuestionSection.module.scss';
import QuestionCategoryMenu from './components/QuestionCategoryMenu/QuestionCategoryMenu';
import QuestionList from './components/QuestionList/QuestionList';

// import QuestionFocusBar from './components/QuestionFocusBar/QuestionFocusBar';

interface AssessmentQuestionSectionProps {
  questionList: any;
  onChange: any;
  onChangeQuestionList: any;
  onTriggerSave: any;
  qTemplateData: any;
  validation: boolean;
}

const AssessmentQuestionSection = ({
  questionList,
  onChange,
  onChangeQuestionList,
  onTriggerSave,
  qTemplateData,
  validation
}: AssessmentQuestionSectionProps) => {
  const [items, setItems] = useState(questionList);
  const [questionTemplate, setQuestionTemplate] = useState(qTemplateData);

  useEffect(() => {
    setItems(questionList);
  }, [questionList]);

  const onAddNewQuestion = (id: string, val: any) => {

    const addNewQuestion = questionList.filter((x: any) => x.id === id).map(
      (x: any) => {
        const addNewQ = [];
        for (let obj of x.questions) {
          addNewQ.push({
            ...obj,
            isDraftQ: obj.id === val.id ? false : obj.isDraftQ,
          });
        }
        return x.questions = addNewQ;
      });

    let newQList = [];
    for (let data of questionList) {
      newQList.push({
        ...data,
        questions: data.id === id ? addNewQuestion[0] : data.questions
      });
    }

    onChangeQuestionList(newQList);
    setItems(newQList);
  };

  const onChangeNewQuestion = (qGroupId: string, val: any) => {
    questionList
      .filter((x: any) => x.id === qGroupId)
      .map((x: any) => {
        const checkIdExist = x.questions.filter((a: any) => a.id === val.id);
        if (checkIdExist.length > 0) {
          const onChangeNewQ = [];
          for (let obj of x.questions) {
            onChangeNewQ.push({
              ...obj,
              stem: obj.id === val.id ? val.stem : obj.stem,
              isChecked: obj.id === val.id ? val.isChecked : obj.isChecked,
            });
          }
          return x.questions = [
            ...onChangeNewQ
          ];
        } else {
          return x.questions = [
            ...x.questions,
            {
              id: val.id,
              stem: val.stem,
              isChecked: val.isChecked,
              refKey: val.refKey,
              isDraftQ: true,
              questionId: uuid()
            }
          ];
        }
      });
    onChangeQuestionList(questionList);
  };

  const onAddNewTopic = (val: string) => {
    let newTemplateQuestion = [];
    for (let data of questionTemplate) {
      newTemplateQuestion.push({
        ...data
      });
    }

    newTemplateQuestion.push({
      sectionName: val,
      askFrequency: '5',
      questions: [],
      refKey: 'custom'
    });
    setQuestionTemplate(newTemplateQuestion);
  };

  const checkQuestion = (list: any, value: any) => {
    let newQList = [];
    for (let data of list) {
      if (!data.isDraftQ) {
        newQList.push({
          ...data,
          isChecked: data.questionId === value.questionId ? value.isChecked : data.isChecked
        });
      }
    }

    return newQList;
  };

  const onChangeCheckBox = (groupId: string, value: any) => {
    let newQuestionList = [];
    for (let data of items) {
      newQuestionList.push({
        ...data,
        questions: data.id === groupId ? checkQuestion(data.questions, value) : data.questions
      });
    }

    setItems(newQuestionList);
    onChange(newQuestionList);
  };

  const onRemoveItem = (id: string) => {
    const newList = items.filter((q: any) => q.id !== id);
    setItems(newList);
    onChange(newList);
  };

  const onSelectQuestionSet = (qSId: string, qSName: string) => {
    const getTemplateList = questionTemplate.find((obj: any) => obj.sectionName === qSName);

    let updateQuestionList = [];
    for (let data of items) {
      updateQuestionList.push({
        id: data.id,
        sectionName: data.id === qSId ? getTemplateList.sectionName : data.sectionName,
        askFrequency: data.id === qSId ? getTemplateList.askFrequency : data.askFrequency,
        questions: data.id === qSId ? getTemplateList.questions : data.questions,
        refKey: data.id === qSId ? getTemplateList.refKey : data.refKey,
      });
    }
    setItems(updateQuestionList);
    onChange(updateQuestionList);
  };

  return (
    <div>
      {items.map((item: any, index: any) => (
        <div key={index}>
          <div className={!validation && item.sectionName === '' ? styles.questionBoxWithError : styles.questionBox}>
            <div className={styles.header}>
              <QuestionCategoryMenu
                value={item.sectionName}
                data={questionTemplate}
                id={item.id}
                selectedData={items}
                onSelectQuestionSet={onSelectQuestionSet}
                onAddTopic={onAddNewTopic}
              />
            </div>
            <QuestionList
              questionSet={item}
              questionNum={index}
              onAddQuestion={onAddNewQuestion}
              onChangeQuestion={onChangeNewQuestion}
              onChangeCheckBox={onChangeCheckBox}
              onClickSave={onTriggerSave}
            />
            <div className={styles.buttonWrapper}>
              {item.sectionName !== '' ? (
                <div className={styles.moreQuestionBtn}>
                  <i className={`material-icons ${styles.arrowIcon}`}>arrow_drop_down</i>
                  More
                </div>
              ) : (
                <div />
              )}
              <div className={styles.removeQuestionBtn} onClick={() => onRemoveItem(item.id)}>
                Remove
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssessmentQuestionSection;
