import { BentResponse } from 'bent';

import { httpClient } from '../httpClient';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAccountLevelRules = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/account-level-rules') as Promise<BentResponse>;

export const putAccountLevelRules = (token: string, payload: any) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })('/account-level-rules', payload) as Promise<
    BentResponse
  >;
