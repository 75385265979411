import styles from './LastAssessmentModal.module.scss';
import Button from 'components/v2/Button/Button';
import ModalV2 from 'components/ModalV2/ModalV2';
import { useNavigate } from 'react-router-dom';
import { useCORDSTeacherRoutesGenerator } from 'MHH/utils/Path/CORDSRoutesGenerator';

const LastAssessmentModal = ({ visible, onCancel }: { visible: boolean; onCancel: () => void }) => {
  const navigate = useNavigate();
  const { STUDENT } = useCORDSTeacherRoutesGenerator();
  const studentListPrevPath = localStorage.getItem('studentListPrevPath');
  return (
    <ModalV2 isModalOpen={visible} onModalClose={() => onCancel()} hideClose>
      <div className={styles.container}>
        <div className={styles.title}>Thank you.</div>
        <div className={styles.desc}>You have completed all required assessment at this time.</div>
        <div className={styles.backListing}>
          <Button onClick={() => navigate(`/${STUDENT.LISTING}${studentListPrevPath}`)}>Home</Button>
        </div>
      </div>
    </ModalV2>
  );
};

export default LastAssessmentModal;
