import classnames from 'classnames';

import styles from './MaterialLabel.module.scss';
import HelpOutLineWithTooltips from '../HelpOutLineWithTooltips/HelpOutLineWithTooltips';

export interface MaterialLabelProps {
  label: string;
  isLabelMinimised: boolean;
  labelClassName?: string;
  labelHelpWithTooltips?: string;
}

const MaterialLabel = ({ label, isLabelMinimised, labelClassName, labelHelpWithTooltips }: MaterialLabelProps) => {
  return (
    <div className={classnames(styles.label, labelClassName, isLabelMinimised ? styles.onFocus : styles.onBlur)}>
      {label}
      {labelHelpWithTooltips && (
        <HelpOutLineWithTooltips id={label} desc={labelHelpWithTooltips} iconClass={styles.icon} />
      )}
    </div>
  );
};

export default MaterialLabel;
