import { useEffect, useState } from 'react';
import { notification } from 'antd';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';

import styles from './PatientDetailsSurveys.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { SurveyClientTab } from 'interfaces/Reports/report';
import { getInitialOBAssessmentByRecordId } from 'utils/http/CheckInService/ReportList/reportList';
import { MONGODB_OBJECT_ID } from 'utils/regex';
import PatientDetailsSurveysHeader from './components/PatientDetailsSurveysHeader/PatientDetailsSurveysHeader';
import PatientDetailsSurveysContent from './components/PatientDetailsSurveysContent/PatientDetailsSurveysContent';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

interface PatientDetailsSurveysProps {
  recordId: string;
  currentTab: string;
  surveyId?: string;
  surveyList: SurveyClientTab[];
  isLoadingSurveyList: boolean;
  clientRecordData: clientRecordsInterface;
}

const PatientDetailsSurveys = ({
  recordId,
  currentTab,
  surveyId,
  surveyList,
  isLoadingSurveyList,
  clientRecordData
}: PatientDetailsSurveysProps) => {
  const { token } = useGetAccessToken();
  const [isLoadingSurveyData, setIsLoadingSurveyData] = useState(true);
  const [initSurvey, setInitSurvey] = useState<FirstAssessment>({} as FirstAssessment);
  const [isInitSurvey, setIsInitSurvey] = useState(surveyId ? MONGODB_OBJECT_ID.test(surveyId) : false);

  const fetchSurveyDetails = async (patientSurveyId: string) => {
    if (patientSurveyId) {
      setIsLoadingSurveyData(true);

      const isInitSurvey = MONGODB_OBJECT_ID.test(patientSurveyId);

      if (isInitSurvey) {
        setIsInitSurvey(true);

        try {
          const getInitReportResponseById = await getInitialOBAssessmentByRecordId(token, recordId, patientSurveyId);
          const survey = await getInitReportResponseById.json();
          setInitSurvey(survey);
          setIsLoadingSurveyData(false);
        } catch (ex) {
          notification.error({ message: 'Something went wrong while trying to get init survey' });
        }
      } else {
        setIsInitSurvey(false);
      }
    } else {
      setIsLoadingSurveyData(false);
    }
  };

  useEffect(() => {
    const id = surveyId || '';
    if (token) {
      fetchSurveyDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId, token, recordId]);

  const getReferralFile = clientRecordData.referral?.files?.find(
    (fileObj) => fileObj.questionsResponsesId === surveyId
  );

  return (
    <div className={styles.container}>
      {isLoadingSurveyList ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          {surveyId && surveyList.length > 0 ? (
            <>
              <PatientDetailsSurveysHeader
                recordId={recordId}
                currentTab={currentTab}
                surveyId={surveyId}
                surveyList={surveyList}
              />
              {isLoadingSurveyData ? (
                <div className={styles.loading}>
                  <LoadingCircle />
                </div>
              ) : isInitSurvey ? (
                <PatientDetailsSurveysContent survey={initSurvey} referralFile={getReferralFile} />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>No survey created yet or there is no survey selected</>
          )}
        </>
      )}
    </div>
  );
};

export default PatientDetailsSurveys;
