import { Modal, Skeleton, notification } from 'antd';
import classnames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Location } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { validateMedicareProviderNumber } from 'utils/helpers/validateMedicareProviderNumber';
import { useGetAccessToken } from 'utils/hooks/token';
import { postProvider } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './AddProviderModal.module.scss';

interface AddProviderModalProps {
  visible: boolean;
  locations: Location[];
  isLocationsLoading: boolean;
  setShowAddProviderModal: (bool: boolean) => void;
  refetchClinicianMedicareProviders: () => void;
}

const AddProviderModal = ({
  visible,
  locations,
  isLocationsLoading,
  setShowAddProviderModal,
  refetchClinicianMedicareProviders
}: AddProviderModalProps) => {
  const { token } = useGetAccessToken();
  const [providerName, setProviderName] = useState('');
  const [providerNumber, setProviderNumber] = useState('');
  const [locationId, setLocationId] = useState('');
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    providerName: false,
    location: false,
    providerNumber: false,
    agreement: false
  });
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const providerNumberError = validateMedicareProviderNumber(providerNumber);

  const locationOptions = locations.map(({ minorId, name }) => ({ value: minorId, label: name }));
  const selectedLocation = locations.find((location) => locationId === location.minorId);

  const handleCloseModal = () => {
    setShowAddProviderModal(false);
    initForm();
  };

  const initForm = () => {
    setProviderName('');
    setProviderNumber('');
    setLocationId('');
    setAgreementChecked(false);
    setValidationErrors({
      providerName: false,
      location: false,
      providerNumber: false,
      agreement: false
    });
  };

  const onChangeProviderName = (value: string) => {
    if (validationErrors.providerName && value.length > 2 && value.length < 501) {
      setValidationErrors({ ...validationErrors, providerName: false });
    }
    setProviderName(value);
  };

  const onChangeLocationId = (value: string) => {
    if (validationErrors.location) {
      setValidationErrors({ ...validationErrors, location: false });
    }
    setLocationId(value);
  };

  const onChangeProviderNumber = (value: string) => {
    if (validationErrors.providerNumber) {
      setValidationErrors({ ...validationErrors, providerNumber: false });
    }
    setProviderNumber(value.toUpperCase());
  };

  const onChangeAgreementChecked = () => {
    if (validationErrors.agreement) {
      setValidationErrors({ ...validationErrors, agreement: false });
    }
    setAgreementChecked(!agreementChecked);
  };

  const onSubmit = async () => {
    setSubmitStatus('active');
    const providerNameError = providerName === '' || providerName.length < 3 || providerName.length > 500;
    const locationIdError = locationId === '';

    if (providerNameError || locationIdError || providerNumberError || !agreementChecked) {
      setValidationErrors({
        providerName: providerNameError,
        location: locationIdError,
        providerNumber: !!providerNumberError,
        agreement: !agreementChecked
      });

      setSubmitStatus('');
      return;
    }

    try {
      const response = await postProvider(token, locationId, { name: providerName, providerNumber });

      if (response.statusCode === 400) {
        const error = await response.json();
        notification.error({
          message: error.message,
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } else if (response.statusCode === 201) {
        notification.success({
          message: 'Provider added',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        handleCloseModal();
        refetchClinicianMedicareProviders();
        initForm();

        setSubmitStatus('finished');
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to add provider' });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      visible={visible}
      closable
      title={<div className={styles.title}>Create Provider Number</div>}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.inputContainer}>
            <MaterialInput
              className={styles.input}
              id={'providerName'}
              label={'Provider Name*'}
              value={providerName}
              onChange={(e) => onChangeProviderName(e.target.value)}
              required
            />
            <div className={validationErrors.providerName ? styles.showError : styles.hiddenError}>
              Please enter the provider name (3 - 500 characters)
            </div>
          </div>
          {isLocationsLoading ? (
            <Skeleton.Input active className={styles.locationLoading} />
          ) : (
            <div className={styles.locationContainer}>
              <div className={styles.locationInputContainer}>
                <MaterialDropdown
                  labelClassName={styles.dropdownLabel}
                  id={''}
                  label={'Location Name*'}
                  dropDownList={locationOptions}
                  selectedValue={locationId}
                  onChangeValue={(value) => onChangeLocationId(value)}
                />
                <div className={validationErrors.location ? styles.showError : styles.hiddenError}>
                  Please select a location
                </div>
              </div>
              {selectedLocation && (
                <div className={styles.locationDisplayContainer}>
                  <div className={styles.locationDisplayLabel}>Location ID:</div>
                  <div className={styles.locationDisplayValue}>{selectedLocation.minorId}</div>
                </div>
              )}
            </div>
          )}
          <div className={styles.inputContainer}>
            <MaterialInput
              className={styles.input}
              id={'providerNumber'}
              label={'Provider Number*'}
              value={providerNumber}
              onChange={(e) => onChangeProviderNumber(e.target.value)}
              required
            />
            <div className={validationErrors.providerNumber ? styles.showError : styles.hiddenError}>
              {providerNumberError}
            </div>
          </div>
        </div>
        <div className={styles.agreementContainer}>
          <CheckBox
            className={styles.checkbox}
            id={'agreement'}
            value={agreementChecked}
            onChange={onChangeAgreementChecked}
          />
          <div>
            <div
              className={classnames(styles.statement, agreementChecked ? styles.checked : styles.unchecked)}
              onClick={onChangeAgreementChecked}
            >
              I confirm the Provider Number is valid and registered.
            </div>
            <div className={validationErrors.agreement ? styles.showError : styles.hiddenError}>
              Please check this box if you wish to add provider number
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button className={styles.button} onClick={onSubmit} status={submitStatus}>
            Add Provider Number
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddProviderModal;
