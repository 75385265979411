import { useState } from 'react';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import StudentListContent from './components/StudentListContent/StudentListContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import { ClientStatusEnum } from 'interfaces/Clients/clientsRecord';
import queryString from 'query-string';
import { useFetchClientsRecord } from 'MHH/pages/Teacher/StudentListing/components/StudentListContent/hooks/GetClientListInfo';
import { useCORDSTeacherRoutesGenerator } from 'MHH/utils/Path/CORDSRoutesGenerator';

export interface studentListQueryParam {
  q?: string;
  page?: string;
  status?: ClientStatusEnum;
}

const StudentListing = () => {
  const navigate = useNavigate();
  const { STUDENT } = useCORDSTeacherRoutesGenerator();
  const { token } = useGetAccessToken();
  const location = useLocation();
  const queryParam: studentListQueryParam = queryString.parse(location.search);
  const [searchVal, setSearchVal] = useState<string>(queryParam.q ?? '');

  const [pageLoading, setPageLoading] = useState(false);

  const filterValue = {
    status: ClientStatusEnum.Active,
    perPage: 10,
    page: queryParam.page || 1,
    q: queryParam.q || '',
    isMHH: true
  };

  const { clientRecordList, isClientRecordListLoading, fetchClientsRecord } = useFetchClientsRecord(token, filterValue);

  const onPageChange = async (page: string) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.q && {
        q: filterValue.q
      }),
      page
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`/${STUDENT.LISTING}${qParam ? `?${qParam}` : ''}`);
    const option = {
      perPage: filterValue.perPage,
      page: Number.parseInt(page, 10),
      status: ClientStatusEnum.Active,
      q: searchVal,
      isMHH: true
    };
    await fetchClientsRecord(token, option);
    setPageLoading(false);
  };

  const onSearch = async (q: string) => {
    setPageLoading(true);
    const newQParam = {
      page: 1,
      q
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`/${STUDENT.LISTING}/${qParam ? `?${qParam}` : ''}`);
    const option = {
      perPage: filterValue.perPage,
      page: 1,
      status: ClientStatusEnum.Active,
      q,
      isMHH: true
    };
    setSearchVal(q);
    await fetchClientsRecord(token, option);
    setPageLoading(false);
  };

  return (
    <HelmetWrapper title="CORDS - Student Listing">
      <ContentLayout whiteBg>
        <StudentListContent
          clientRecordList={clientRecordList}
          isClientRecordListLoading={isClientRecordListLoading}
          onPageChange={onPageChange}
          onSearch={onSearch}
          isPageLoading={pageLoading}
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};
export default StudentListing;
