import Button from 'components/v2/Button/Button';
import moment from 'moment';
import { ActionPlanInterventionItem } from '../../InterventionList';
import styles from './InterventionDetail.module.scss';

interface InterventionDetailProps {
  intervention: ActionPlanInterventionItem;
  saveStatus?: '' | 'active' | 'finished';
  onSaveCompletedStatus: (id: string, isCompleted: boolean) => void;
}
const InterventionDetail = ({ intervention, saveStatus, onSaveCompletedStatus }: InterventionDetailProps) => {
  return (
    <div className={styles.card}>
      <span className={styles.fieldName}>
        CREATED: <b>{moment(intervention.createdAt).format('DD/MM/YYYY')}</b>
      </span>
      <span className={styles.title}>{intervention.noAction ? 'Take no action' : intervention.name}</span>
      <label className={styles.description}>
        {intervention.noAction ? (
          <>
            Reason: {intervention.noAction.reason}
            <br />
            {intervention.noAction.context}
          </>
        ) : (
          intervention.description
        )}
      </label>
      {intervention.focusArea && (
        <>
          <span className={styles.fieldName}>
            FOCUS AREA
            <br />
            <div className={styles.scoreContainer}>
              <span className={styles.focusArea}>{intervention.focusArea}</span>
              {intervention.scoreScale?.map((score, index) => (
                <div className={styles.badge} key={index}>
                  {score}
                </div>
              ))}
            </div>
          </span>
        </>
      )}
      {!intervention.noAction && (
        <>
          <span className={styles.dueDate}>
            <i className={`material-icons-outlined ${styles.icon}`}>alarm</i>
            {moment(intervention.completedAt || intervention.dueDate, 'YYYY-MM-DD').format('dddd, Do MMMM YYYY')}
          </span>
          <Button
            className={styles.addButton}
            disabled={false}
            status={saveStatus}
            onClick={() => onSaveCompletedStatus(intervention._id || '', !intervention.isCompleted)}
          >
            <span className={'material-icons-outlined'}>{!intervention.isCompleted ? 'task_alt' : 'undo'}</span>
            {`Mark as ${intervention.isCompleted ? 'not ' : ''}done`}
          </Button>
        </>
      )}
    </div>
  );
};

export default InterventionDetail;
