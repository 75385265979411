import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

// Group Case note
export const getGroupCaseNotesByGroupId = (token: string, groupId: string, withContents?: boolean) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes${withContents ? '?withContents=1' : ''}`
  ) as Promise<BentResponse>;

export const getGroupCaseNoteByCaseNoteId = (token: string, groupId: string, caseNoteId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/${caseNoteId}`
  ) as Promise<BentResponse>;

export const postGroupCaseNote = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes`,
    payload
  ) as Promise<BentResponse>;

export const putGroupCaseNote = (token: string, groupId: string, caseNoteId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/${caseNoteId}`,
    payload
  ) as Promise<BentResponse>;

export const deleteGroupCaseNote = (token: string, groupId: string, caseNoteId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/${caseNoteId}`
  ) as Promise<BentResponse>;

// Group format setting
export const getGroupCaseNoteFormatSettingsByGroupId = (token: string, groupId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/formatSettings`
  ) as Promise<BentResponse>;

export const getGroupCaseNoteFormatAvailableSettingsByGroupId = (token: string, groupId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/formatSettings:availableSettings`
  ) as Promise<BentResponse>;

export const postGroupCaseNoteFormatSettings = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/formatSettings`,
    payload
  );

export const putGroupCaseNoteFormatSettings = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, [201, 204], { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/formatSettings`,
    payload
  );

// Group Heading template
export const getGroupCaseNoteHeadingTemplates = (token: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    '/group-case-note-heading-templates'
  ) as Promise<BentResponse>;

export const postGroupCaseNoteHeadingTemplate = (token: string, body: Record<string, any>) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })(
    '/group-case-note-heading-templates',
    body
  ) as Promise<BentResponse>;

export const deleteGroupCaseNoteHeadingTemplate = (templateId: any, token: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/group-case-note-heading-templates/${templateId}`
  );
