import styles from './GroupStatusBadge.module.scss';
import { GroupStatus } from 'pages/Groups/Interfaces/Groups';

interface GroupStatusBadgeProps {
  groupStatus: GroupStatus;
}

const GroupStatusBadge = ({ groupStatus }: GroupStatusBadgeProps) => {
  const generateStatusBadge = (status: GroupStatus) => {
    switch (status) {
      case 'active':
        return {
          label: 'ACTIVE',
          badgeStyles: styles.activeBadge
        };
      case 'closed':
        return {
          label: 'CLOSED',
          badgeStyles: styles.closedBadge
        };
      default:
        console.error('Something went wrong while trying to generate your record status badge');
        return {
          label: '',
          badgeStyles: ''
        };
    }
  };

  return (
    <div className={generateStatusBadge(groupStatus).badgeStyles}>
      <div>{generateStatusBadge(groupStatus).label}</div>
    </div>
  );
};

export default GroupStatusBadge;
