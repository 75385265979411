import { Popconfirm } from 'antd';
import acal from 'assets/images/acal.svg';
import exchange from 'assets/images/exchange.svg';
import gcal from 'assets/images/gcal.svg';
import outlook from 'assets/images/outlook.svg';
import Button from 'components/v2/Button/Button';
import { CronofyCalendar, CronofyProfile } from 'interfaces/Cronofy/cronofyProfile';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ConnectedCalendar from './components/ConnectedCalendar';
import styles from './ConnectedProfile.module.scss';

interface ProviderDetails {
  thumbnail: string;
  name: string;
}

interface Providers {
  apple: ProviderDetails;
  google: ProviderDetails;
  exchange: ProviderDetails;
  live_connect: ProviderDetails;
  [key: string]: ProviderDetails;
}

export const PROVIDER_MAP: Providers = {
  apple: {
    thumbnail: acal,
    name: 'Apple'
  },
  google: {
    thumbnail: gcal,
    name: 'Google'
  },
  exchange: {
    thumbnail: exchange,
    name: 'Exchange'
  },
  live_connect: {
    thumbnail: outlook,
    name: 'Outlook'
  }
};

interface ConnectedProfileProps {
  cronofyProfile: CronofyProfile;
  handleCalendarChange: (calendar: CronofyCalendar) => void;
  handleUnlinkCalendar: (id: string) => void;
  handleShowAsPrivateChange: (id: string) => void;
}

const ConnectedProfile = ({
  cronofyProfile,
  handleCalendarChange,
  handleUnlinkCalendar,
  handleShowAsPrivateChange
}: ConnectedProfileProps) => {
  const [profilePayload, setProfilePayload] = useState(cronofyProfile);
  const { isEdgeUserView } = useGetAccountPackageView();

  useEffect(() => {
    setProfilePayload(cronofyProfile);
  }, [cronofyProfile]);

  return (
    <div className={styles.container}>
      <div className={styles.connectDomain}>
        <div className={styles.domainThumbnail}>
          <img src={PROVIDER_MAP[profilePayload.provider].thumbnail} alt="Provider thumbnail" />
        </div>
        <div className={styles.domainInfoContainer}>
          <div className={styles.domainInfo}>
            <div className={styles.email}>{profilePayload.email}</div>
            <div className={styles.domainName}>{PROVIDER_MAP[profilePayload.provider].name}</div>
          </div>
          <Popconfirm
            placement={'bottom'}
            title={'Are you sure you want to unlink this calendar?'}
            onConfirm={() => handleUnlinkCalendar(profilePayload.id)}
          >
            <Button className={styles.unlinkButton}>Unlink this calendar</Button>
          </Popconfirm>
          {isEdgeUserView && (
            <div className={styles.checkboxContainer}>
              <label className={styles.connectSettingCheckbox}>
                <input
                  checked={profilePayload.configuration.showAsPrivate}
                  className={styles.checkbox}
                  type="checkbox"
                  onChange={() => handleShowAsPrivateChange(profilePayload.id)}
                />
                <span className={styles.connectSettingLabel}>
                  Show as "Private Event" to others who can access my diary
                </span>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className={styles.calendarsContainer}>
        {profilePayload.calendars.map((calendar, index) => (
          <ConnectedCalendar cronofyCalendar={calendar} handleCalendarChange={handleCalendarChange} key={index} />
        ))}
      </div>
    </div>
  );
};

export default ConnectedProfile;
