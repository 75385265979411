import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const getInvoiceIdUsed = (token: string, invoiceId: string, invoiceDocumentId?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/invoices:isInvoiceIdUsed?invoiceId=${invoiceId}${`&invoiceDocumentId=${invoiceDocumentId}` ?? ''}`
  ) as Promise<BentResponse>;

export const getInvoices = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/invoices') as Promise<BentResponse>;

export const getInvoice = (token: string, invoiceId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(`/invoices/${invoiceId}`) as Promise<BentResponse>;

export const getInvoicesByRecordId = (token: string, recordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/invoices?clientRecordId=${recordId}`
  ) as Promise<BentResponse>;

export const getInvoiceSettings = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/invoice-settings') as Promise<BentResponse>;

export const getInvoiceSettingsPaymentMethodsReady = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    '/invoice-settings/payment-methods-ready'
  ) as Promise<BentResponse>;

export const getPaymentMethods = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/payment-methods') as Promise<BentResponse>;

export const postInvoice = (token: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })('/invoices', payload) as Promise<BentResponse>;

export const putInvoice = (token: string, invoiceId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/invoices/${invoiceId}`,
    payload
  ) as Promise<BentResponse>;

export const putInvoiceSettings = (token: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    '/invoice-settings',
    payload
  ) as Promise<BentResponse>;

export const putInvoiceContactDetails = (token: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    '/invoice-settings/contact-details',
    payload
  ) as Promise<BentResponse>;

export const putInvoiceStatus = (token: string, _id: string, status: string) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(`/invoices/${_id}/status`, {
    status
  }) as Promise<BentResponse>;

export const putPaymentMethods = (token: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    '/payment-methods',
    payload
  ) as Promise<BentResponse>;

export const putResendInvoice = (token: string, _id: string) =>
  httpClient('PATCH', url!, 202, { Authorization: `Bearer ${token}` })(
    `/invoices/${_id}:resend`
  ) as Promise<BentResponse>;

export const sendInvoice = (token: string, id: string, payload: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/invoices/${id}:send`,
    payload
  ) as Promise<BentResponse>;
