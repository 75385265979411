import { Modal } from 'antd';
import { useState } from 'react';
import styles from './profileStatusButtons.module.scss';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import { useTranslation } from 'react-i18next';

interface ProfileStatusButtonsProps {
  value: string;
  onChange: (value: boolean) => void;
}

const ProfileStatusButtons = ({ value, onChange }: ProfileStatusButtonsProps) => {
  const [t] = useTranslation();

  const [clientStatus, setClientStatus] = useState<boolean>(value === 'active');

  const handleConfirm = () => {
    setClientStatus(!clientStatus);
    onChange(!clientStatus);
  };

  const handleConfirmClick = () => {
    Modal.confirm({
      title: t('form.confirm.client_close_or_reactivate_account', { status: clientStatus ? 'close' : 'reactivate' }),
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: handleConfirm
    });
  };

  return (
    <div className={styles.statusContainer}>
      <div className={styles.toggle}>
        <ToggleSwitch
          id={'clientActiveStatus'}
          checkLabel={'Active'}
          unCheckLabel={'Closed'}
          isCheckedProps={clientStatus}
          onChangeProps={handleConfirmClick}
          toggleClassName={styles.toggleSwitch}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default ProfileStatusButtons;
