import styles from './AppointmentStatusForm.module.scss';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import { useTranslation } from 'react-i18next';

const TARGET_LIST = [
  {
    value: '0',
    label: 'Not applicable'
  },
  ...[...Array(50)].map((obj, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString()
  }))
];

interface AppointmentStatusFormProps {
  selectedTargetValue: string;
  selectedLimitValue: string;
  onChangeTarget: (val: number) => void;
  onChangeLimit: (val: number) => void;
}

const AppointmentStatusForm = ({
  selectedTargetValue,
  selectedLimitValue,
  onChangeTarget,
  onChangeLimit
}: AppointmentStatusFormProps) => {
  const [t] = useTranslation();
  return (
    <>
      <div className={styles.title}>SET COUNT FOR</div>
      <div className={styles.fieldContainer}>
        <MaterialDropdown
          id={`targetSession`}
          label={t('client_appointment.target_session')}
          dropDownList={TARGET_LIST}
          selectedValue={selectedTargetValue}
          onChangeValue={(val: string) => onChangeTarget(parseInt(val, 10))}
          fieldClassName={styles.reasonSelect}
          labelHelpWithTooltips={t('client_appointment.target_session.description')}
        />
      </div>
      <div className={styles.fieldContainer}>
        <MaterialDropdown
          id={`targetSession`}
          label={t('client_appointment.max_session')}
          dropDownList={TARGET_LIST}
          selectedValue={selectedLimitValue}
          onChangeValue={(val: string) => onChangeLimit(parseInt(val, 10))}
          fieldClassName={styles.reasonSelect}
          labelHelpWithTooltips={t('client_appointment.max_session.description')}
        />
      </div>
    </>
  );
};

export default AppointmentStatusForm;
