import { useField } from 'formik';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Input, { InputProps } from './Input';

interface FormikInputProps extends Omit<InputProps, 'hasError'> {
  name: string;
}

const FormikInput = ({ className, name, ...props }: FormikInputProps) => {
  const [field, { error, touched }] = useField(name);

  return (
    <div className={className}>
      <Input {...props} {...field} hasError={touched && !!error} />
      <ErrorMessage error={error} visible={touched} />
    </div>
  );
};

export default FormikInput;
