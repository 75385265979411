import bent, { Options } from 'bent';

export const httpClient = (
  httpMethod: string,
  url: string,
  expectedStatusCode: number | number[],
  headers?: Options
) => {
  if (headers) {
    return bent(url, httpMethod, expectedStatusCode, headers);
  }
  return bent(url, httpMethod, expectedStatusCode);
};
