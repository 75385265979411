import { useEffect, useState } from 'react';
import ModalAlert from './components/ModalAlert/ModalAlert';

import styles from './ConfirmationBox.module.scss';

interface ConfirmationBoxProps {
  visible: boolean;
  title: string;
  confirmBtnLabel?: string;
  cancelBtnLabel: string;
  onSubmitConfirm?: any;
  onSubmitCancel: any;
}

const ConfirmationBox = ({
  visible,
  title,
  confirmBtnLabel,
  cancelBtnLabel,
  onSubmitConfirm,
  onSubmitCancel
}: ConfirmationBoxProps) => {
  const [showModal, setShowModal] = useState(visible);
  const [triggerClose, setTriggerClose] = useState(false);

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  const onCancel = () => {
    setTriggerClose(true);
    setTimeout(() => {
      setShowModal(false);
      onSubmitCancel();
      setTriggerClose(false);
    }, 600);
  };

  const onConfirm = () => {
    setTriggerClose(true);
    setTimeout(() => {
      setShowModal(false);
      onSubmitConfirm();
      setTriggerClose(false);
    }, 600);
  };

  return (
    <ModalAlert isModalOpen={showModal} modalCloseClass={triggerClose}>
      <div className={styles.wrapperBox}>
        <div dangerouslySetInnerHTML={{ __html: title }} className={styles.label} />
        <div className={styles.btnWrapper}>
          <div className={styles.noBtn} onClick={() => onCancel()}>
            {cancelBtnLabel}
          </div>
          <div className={styles.yesBtn} onClick={() => onConfirm()}>
            {confirmBtnLabel}
          </div>
        </div>
      </div>
    </ModalAlert>
  );
};

export default ConfirmationBox;
