import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const postNote = (token: string, body: Record<string, any>) =>
  httpClient('POST', URL!, 201, { Authorization: `Bearer ${token}` })('/notes', body);

export const noteList = (token: string, recordId: string) => {
  return httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/notes`
  ) as Promise<BentResponse>;
};
