import { useEffect, useMemo, useState } from 'react';
import { Popconfirm } from 'antd';
import { ClientDetails, newClientForm } from '../../AddPatientModalV2Interface';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import ChildClientForm from './components/ChildClientForm/ChildClientForm';
import ChildGuardianForm from './components/ChildGuardianForm/ChildGuardianForm';
import { v4 as uuid } from 'uuid';

import styles from './ChildForm.module.scss';
import classnames from 'classnames';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateCaseId } from '../AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { useTranslation } from 'react-i18next';

interface ChildFormProps {
  clientDetail: newClientForm['clientProfiles'];
  clientCapability: newClientForm['clientCapability'];
  referralSourceTags: newClientForm['referralSourceTags'];
  referralDetail: newClientForm['referralDetail'];
  caseIdValue: newClientForm['caseId'];
  onChangeCaseId: (value: newClientForm['caseId']) => void;
  classValue?: newClientForm['class'];
  onChangeClass?: (value: newClientForm['class']) => void;
  onChangeClientField: any;
  onChangeClientCapability: any;
  onChangeReferral: (value: string[]) => void;
  onChangeReferralDetail: (value: string) => void;
  checkValidation: boolean;
  clientRecordId?: string;
  isOnboardingListExist: boolean;
  isContentListExist: boolean;
  defaultOnBoardingId: string;
  defaultConsentId: string;
}

const maxGuardianField = 5;

const ChildForm = ({
  clientDetail,
  clientCapability,
  caseIdValue,
  onChangeCaseId,
  classValue,
  onChangeClass,
  onChangeClientField,
  onChangeClientCapability,
  checkValidation,
  clientRecordId,
  isOnboardingListExist,
  isContentListExist,
  defaultOnBoardingId,
  defaultConsentId
}: ChildFormProps) => {
  const { token } = useGetAccessToken();
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  useEffect(() => {
    if (checkValidation && caseIdValue && caseIdValue.length > 0) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        ...(obj.role === 'child' && {
          dateOfBirth: obj._id === val._id ? val.dateOfBirth : obj.dateOfBirth
        }),
        ...(obj.role !== 'child' && {
          role: obj._id === val._id ? val.role : obj.role,
          email: obj._id === val._id ? val.email : obj.email,
          mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber
        }),
        ...(obj.role !== 'child' && {
          communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
        })
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value, clientRecordId);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token, clientRecordId]
  );

  const validateDuplicateCaseId = async (caseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(caseIdValue);
    }
  };

  const handleCaseIdChange = async (caseIdValue: string) => {
    onChangeCaseId(caseIdValue);
    if (checkValidation && caseIdValue.length > 0) {
      await validateDuplicateCaseId(caseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const handleAddNewGuardian = () => {
    if (clientDetail.length <= maxGuardianField) {
      const newClientProfile = [
        ...clientDetail,
        {
          _id: uuid(),
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          onboardingSurvey: '',
          consentFormId: '',
          isPrimaryContact: false,
          role: '',
          onboarding: {
            onboardingSurvey: defaultOnBoardingId ?? '',
            isCheckOnBoarding: isOnboardingListExist ?? true,
            onboardingMethod: 'facilitated'
          },
          consentForm: {
            isCheckOnConsentForm: isContentListExist ?? true,
            consentFormId: defaultConsentId ?? ''
          },
          communicationPreference: 'noPreference'
        }
      ];
      onChangeClientField(newClientProfile);
    }
  };

  const handleRemoveGuardian = (removeId: string) => {
    if (clientDetail.length >= 2) {
      const removeByIdList = clientDetail.filter((q) => q._id !== removeId);
      const childProfile = removeByIdList.filter((cp) => cp.role === 'child');
      const guardianProfile = removeByIdList.filter((cp) => cp.role !== 'child');

      const newGuardianField = [];
      for (let obj of guardianProfile) {
        newGuardianField.push({
          ...obj,
          isPrimaryContact: guardianProfile.length < 2 ? true : obj.isPrimaryContact
        });
      }
      const massageField = [...childProfile, ...newGuardianField];
      onChangeClientField(massageField);
    }
  };

  const totalGuardian = clientDetail.filter((obj) => obj.role !== 'child').length;
  const [t] = useTranslation();

  return (
    <>
      <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
        <MaterialInput
          id={`clientCaseId`}
          label={t('form.client_id')}
          onChange={(e) => handleCaseIdChange(e.target.value)}
          isLoading={isCheckingCaseIdDuplicate}
          value={caseIdValue}
          maxLength={20}
          required
        />
        {checkValidation && duplicateCaseId && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_id')}</div>
        )}
      </div>
      <ChildClientForm
        id={'childField'}
        clientDetail={clientDetail.find((obj) => obj.role === 'child') || clientDetail[0]}
        onChangeClientField={handleChangeClientField}
        onChangeClass={onChangeClass}
        classValue={classValue}
        checkValidation={checkValidation}
      />
      {clientDetail
        .filter((obj) => obj.role !== 'child')
        .map((obj, index) => (
          <div className={styles.guardianContainer} key={index}>
            <div className={styles.guardianHeader}>
              <div className={styles.guardianTitle}>PARENT OR GUARDIAN {totalGuardian > 1 && index + 1}</div>
              <Popconfirm
                title="Are you sure to remove this Parent or Guardian?"
                onConfirm={() => handleRemoveGuardian(obj._id)}
                okText="Yes"
                cancelText="No"
                placement={'bottomRight'}
              >
                <div className={`material-icons-outlined ${styles.removeBtn}`}>close</div>
              </Popconfirm>
            </div>
            <ChildGuardianForm
              id={`Guardian${index}`}
              key={index}
              clientDetail={obj}
              onChangeClientField={handleChangeClientField}
              checkValidation={obj.firstName || obj.lastName || obj.email || obj.mobileNumber ? checkValidation : false}
              isFirstGuardian={index === 0}
              updateDetail={clientDetail.length}
            />
          </div>
        ))}
      {clientDetail.length <= maxGuardianField && (
        <div className={styles.addGuardianBtn} onClick={() => handleAddNewGuardian()}>
          <i className={`material-icons-outlined ${styles.icon}`}>add</i>
          Add another parent or guardian
        </div>
      )}
    </>
  );
};

export default ChildForm;
