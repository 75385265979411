import { useState } from 'react';

import Radio from 'components/Radio/Radio';
import Button from 'components/v2/Button/Button';

import styles from './ShareReportBox.module.scss';
import ExpandableContent from 'components/ExpandableContent/ExpandableContent';
import ShareSideBox from '../components/ShareSideBox/ShareSideBox';
import moment from 'moment';
import * as yup from 'yup';
import { Report } from 'interfaces/Reports/report';
import OptionLabel from 'components/OptionLabel/OptionLabel';

const emailSchema = yup.array().of(yup.string().email());

interface ShareInvoiceProps {
  label: string;
  recipientList: Report['shareDetails']['accessors'];
  sendInvoiceButtonStatus: '' | 'active' | 'finished';
  onSaveReport: any;
  clientRecord: Report['clientRecord'];
  onSendReport: (shareDetails: { sendAt: Date; sendClient: boolean; sendTo?: string[]; note?: string }) => void;
}

const ShareReportBox = ({
  label,
  recipientList,
  sendInvoiceButtonStatus,
  clientRecord,
  onSaveReport,
  onSendReport
}: ShareInvoiceProps) => {
  const [saveReport, setSaveReport] = useState('saveOnly');
  const [isShowSendReportContent, setShowSendReportContent] = useState(false);
  const [errors, setErrors] = useState<{ customSendInvoiceTime?: string; emails?: string; sendTo?: string }>({});
  const [shareReportDetail, setShareReportDetail] = useState({
    note: '',
    sendTimeInfo: {
      sendInvoiceType: 'now',
      customSendTime: ''
    },
    sendToInfo: {
      isClientCheck: true,
      isOtherCheck: false,
      otherEmail: ''
    }
  });

  const onChangeReportDetails = (val: object) => {
    const massageData = {
      ...shareReportDetail,
      ...val
    };
    setShareReportDetail(massageData);
  };

  const onHandleShowSendReport = (val: string) => {
    setSaveReport(val);
    setShowSendReportContent(val === 'saveAndShare');
  };

  const onHandleSaveReport = () => {
    onSaveReport();
  };

  const onHandleSendReport = () => {
    const newErrors: typeof errors = {};

    if (shareReportDetail.sendTimeInfo.sendInvoiceType === 'custom' && !shareReportDetail.sendTimeInfo.customSendTime) {
      newErrors.customSendInvoiceTime = 'Please select a date and time to send this invoice';
    }

    if (!shareReportDetail.sendToInfo.isClientCheck && !shareReportDetail.sendToInfo.isOtherCheck) {
      newErrors.sendTo = 'Please select a recipient';
    } else if (shareReportDetail.sendToInfo.isOtherCheck) {
      const emailsArray = shareReportDetail.sendToInfo.otherEmail.split(';').filter((email: string) => !!email);

      if (emailsArray.length === 0) {
        newErrors.emails = 'Please enter a list of semi-colon-separated emails';
      } else {
        try {
          emailSchema.validateSync(emailsArray);
        } catch (ex) {
          newErrors.emails = 'Please enter emails in the correct format';
        }
      }
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      const shareDetails: { sendAt: Date; sendClient: boolean; sendTo?: string[]; note?: string } = {
        sendAt: new Date(),
        sendClient: shareReportDetail.sendToInfo.isClientCheck,
        note: shareReportDetail.note
      };

      if (shareReportDetail.sendTimeInfo.sendInvoiceType === 'now') {
        shareDetails.sendAt = moment().toDate();
      } else {
        shareDetails.sendAt = moment(shareReportDetail.sendTimeInfo.customSendTime, 'YYYY-MM-DD HH:mm').toDate();
      }

      if (shareReportDetail.sendToInfo.isOtherCheck) {
        shareDetails.sendTo = shareReportDetail.sendToInfo.otherEmail.split(';').filter((email: string) => !!email);
      }

      onSendReport(shareDetails);
    }
  };
  const isClientShared = recipientList.filter((obj) => obj.isClient).length > 0;

  return (
    <div className={styles.container}>
      <div className={styles.saveNShareContainer}>
        <div className={styles.title}>Save and Share {label}</div>
        <Radio
          className={styles.saveRadioContainer}
          options={[
            {
              value: 'saveOnly',
              label: (
                <OptionLabel
                  title={'Complete and save only'}
                  desc={'(you can still share later)'}
                  titleClassName={styles.label}
                />
              )
            },
            {
              value: 'saveAndShare',
              label: <OptionLabel title={'Complete, save and share this report'} titleClassName={styles.label} />
            }
          ]}
          name={'saveNShareReport'}
          value={saveReport}
          vertical
          onChange={(e) => onHandleShowSendReport(e.target.value)}
        />
      </div>
      <ExpandableContent expanded={isShowSendReportContent}>
        <ShareSideBox
          allowCommunicationWithEmail={clientRecord.allowCommunicationWithEmail}
          isClientShared={isClientShared}
          data={shareReportDetail}
          onChangeReportDetails={onChangeReportDetails}
          errorsData={errors}
        />
      </ExpandableContent>
      {!isShowSendReportContent ? (
        <Button className={styles.button} type="button" status={sendInvoiceButtonStatus} onClick={onHandleSaveReport}>
          Save Report
        </Button>
      ) : (
        <Button className={styles.button} type="button" status={sendInvoiceButtonStatus} onClick={onHandleSendReport}>
          Send Report
        </Button>
      )}
    </div>
  );
};

export default ShareReportBox;
