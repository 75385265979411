import { notification } from 'antd';
import FilterDropdown from 'components/FilterDropDown/FilterDropDown';
import { AccessRight, MinifiedClinician, PractitionersListMinifiedResponse } from 'interfaces/Clients/clinician';
import { useEffect, useState } from 'react';
import { getMinifiedCliniciansByAccountId } from 'utils/http/ClinicianProfileService/Profile/profile';
import { PER_PAGE } from '.';

interface Props {
  token: string;
  accountId: string;
  accessRights?: AccessRight[];
  label: string;
  teacherFilteredList: MinifiedClinician[];
  setTeacherFilteredList: React.Dispatch<React.SetStateAction<MinifiedClinician[]>>;
  resetPage: () => void;
}

const TeacherFilter = ({
  token,
  accountId,
  accessRights,
  label,
  teacherFilteredList,
  setTeacherFilteredList,
  resetPage
}: Props) => {
  const [allClinicians, setAllClinicians] = useState<MinifiedClinician[]>([]);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const initialFetch = async (token: string) => {
    setIsLoadingInitialData(true);
    try {
      const request = await getMinifiedCliniciansByAccountId(token, accountId, {
        accessRights,
        status: 'active',
        perPage: PER_PAGE,
        page: currentPage
      });
      const data = (await request.json()) as PractitionersListMinifiedResponse;
      setTotalPage(Math.ceil(data.paging.totalItems / PER_PAGE));
      setAllClinicians(data.clinicians);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch teacher list"
      });
    }
    setIsLoadingInitialData(false);
  };

  const fetchNext = async () => {
    if (!token || totalPage <= currentPage) return;
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setIsFetchingNextPage(true);
    try {
      const request = await getMinifiedCliniciansByAccountId(token, accountId, {
        accessRights,
        status: 'active',
        perPage: PER_PAGE,
        page: nextPage
      });
      const data = (await request.json()) as PractitionersListMinifiedResponse;
      setAllClinicians((clinicians) => [...clinicians, ...data.clinicians]);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch teacher list"
      });
    }
    setIsFetchingNextPage(false);
  };

  useEffect(() => {
    if (token) {
      if (teacherFilteredList.length > 0) {
        // reset filter when change view
        setTeacherFilteredList([]);
      }
      initialFetch(token);
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <FilterDropdown
      id={`${label.toLowerCase()}-filter`}
      icon={<i className={`material-icons`}>search</i>}
      menuItems={allClinicians.map((clinician) => ({
        _id: clinician._id,
        name: clinician.name
      }))}
      onChangeItem={(obj) => {
        const { item } = obj;
        if (item) {
          resetPage();
          const isSelected = !!teacherFilteredList.find((clinician) => item._id === clinician._id);
          if (isSelected) {
            setTeacherFilteredList((list) => list.filter((clinician) => clinician._id !== item._id));
          } else {
            const addedTeacher = allClinicians.find((clinician) => clinician._id === item._id);
            if (addedTeacher) {
              setTeacherFilteredList((list) => [...list, addedTeacher]);
            }
          }
        }
      }}
      selectedFilterList={teacherFilteredList.map((teacher) => ({
        _id: teacher._id,
        name: teacher.name
      }))}
      showSearchIcon
      loading={isLoadingInitialData}
      hasMoreData={totalPage > currentPage}
      isFetchingMore={isFetchingNextPage}
      loadMore={() => fetchNext()}
      searchable
    >
      {label}
    </FilterDropdown>
  );
};

export default TeacherFilter;
