import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { CaseNote } from 'interfaces/caseNote';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import MultipleChoiceWrapper from './components/MultipleChoiceWrapper/MultipleChoiceWrapper';
import CaseNoteFieldType from './components/CaseNoteFieldType/CaseNoteFieldType';
import styles from './CaseNoteHeaderOption.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface CaseNoteHeaderOptionProps {
  noteId: string;
  recordId: string;
  fieldsData: CaseNote['fields'];
  title: CaseNote['title'];
  safeguardingAlert?: CaseNote['safeguardingAlert'];
  safeguardingReason?: CaseNote['safeguardingReason'];
  onChangeFieldValue: any;
  onChangeTitle: any;
  onChangeSafeguardingAlert: any;
  onChangeSafeguardingReason: any;
  isValidated: boolean;
}

const safeguardingObj: CaseNote['fields'][number] = {
  _id: 'safeGuardingObj',
  name: process.env.REACT_APP_GAE_REGION === 'au' ? 'Crisis issue identified?' : 'Safeguarding Alert',
  type: 'multipleChoice',
  options: [
    {
      label: '',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
      ]
    }
  ],
  mandatory: true,
  value: ''
};

const CaseNoteHeaderOption = ({
  noteId,
  recordId,
  fieldsData,
  title,
  onChangeFieldValue,
  onChangeTitle,
  safeguardingAlert,
  safeguardingReason,
  onChangeSafeguardingAlert,
  onChangeSafeguardingReason,
  isValidated
}: CaseNoteHeaderOptionProps) => {
  const { helmFeatureToggle } = useGetFeatureToggle();
  const [caseNoteFieldData, setCaseNoteFieldData] = useState<CaseNote['fields']>(fieldsData);
  const [caseNoteTitle, setCaseNoteTitle] = useState(title);
  const [safeguardingAlertState, setSafeguardingAlertState] = useState({
    ...safeguardingObj,
    mandatory:
      fieldsData.filter((data) => data.name === 'Safeguarding Alert')[0]?.mandatory ?? safeguardingObj.mandatory,
    value: safeguardingAlert === undefined ? '' : safeguardingAlert ? 'Yes' : 'No'
  });
  const [safeguardingReasonState, setSafeguardingReasonState] = useState(safeguardingReason);

  useEffect(() => {
    if (noteId === 'new') {
      setCaseNoteFieldData(fieldsData);
    }
  }, [fieldsData, noteId, title]);

  const findReplaceOptionData = (id: string, newOption: any) => {
    const newCollection = [];
    for (let fieldObj of caseNoteFieldData) {
      newCollection.push({
        ...fieldObj,
        options: fieldObj._id === id ? newOption : fieldObj.options
      });
    }
    setCaseNoteFieldData(newCollection);
    return newCollection;
  };

  const handleChangeSelectCount = (id: string, val: any) => {
    const newValue = findReplaceOptionData(id, val);
    onChangeFieldValue(newValue);
  };

  const findReplaceFieldValue = (id: string, value: any) => {
    const newCollection = [];
    for (let fieldObj of caseNoteFieldData) {
      newCollection.push({
        ...fieldObj,
        value: fieldObj._id === id ? value : fieldObj.value
      });
    }
    setCaseNoteFieldData(newCollection);
    return newCollection;
  };

  const handleOnChangeFieldInput = (fieldId: string, val: string) => {
    const newValue = findReplaceFieldValue(fieldId, val);
    onChangeFieldValue(newValue);
  };

  const handleOnChangeTitle = (val: string) => {
    setCaseNoteTitle(val);
    onChangeTitle(val);
  };

  const handleOnChangeSafeguardingAlert = (id: string, value: any) => {
    setSafeguardingAlertState({ ...safeguardingAlertState, value: value.value });
    if (value.value === 'Yes') {
      onChangeSafeguardingAlert(true);
    } else if (value.value === 'No') {
      setSafeguardingReasonState('');
      onChangeSafeguardingAlert(false);
    } else {
      setSafeguardingReasonState('');
      onChangeSafeguardingAlert(undefined);
    }
  };

  const handleOnChangeSafeguardingReason = (value: any) => {
    setSafeguardingReasonState(value);
    onChangeSafeguardingReason(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <MaterialInput
          id="title"
          name="title"
          label="Title for this Note *"
          value={caseNoteTitle}
          onChange={(e) => {
            handleOnChangeTitle(e.target.value);
          }}
          maxLength={100}
          required
        />
        <ErrorMessage
          className={styles.error}
          error={'Please fill in the title of this note'}
          visible={isValidated && caseNoteTitle.length <= 0}
        />
      </div>
      {!helmFeatureToggle && caseNoteFieldData.find((data) => data.name === 'Safeguarding Alert') && (
        <div className={styles.safeguardingContainer}>
          <MultipleChoiceWrapper
            field={safeguardingAlertState}
            isValidated={isValidated}
            onChangeFieldInput={handleOnChangeSafeguardingAlert}
          />
          {safeguardingAlertState.value === 'Yes' && (
            <div className={styles.safeguardingReason}>
              <MaterialInput
                id="safeguardingReason"
                name="safeguardingReason"
                label={`${process.env.REACT_APP_GAE_REGION === 'au' ? 'Issue Identified' : 'Safeguarding Reason'} *`}
                value={safeguardingReasonState}
                onChange={(e) => handleOnChangeSafeguardingReason(e.target.value)}
                maxLength={200}
                required
              />
              <ErrorMessage
                className={styles.error}
                error={'Please fill in this field'}
                visible={isValidated && safeguardingAlertState.value === 'Yes' && _.isEmpty(safeguardingReasonState)}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.fieldContainer}>
        {caseNoteFieldData
          .filter((data) => data.name !== 'Safeguarding Alert')
          .map((obj, index) => (
            <CaseNoteFieldType
              key={index}
              recordId={recordId}
              caseNoteFieldData={obj}
              handleOnChangeFieldInput={handleOnChangeFieldInput}
              handleChangeSelectCount={handleChangeSelectCount}
              isValidated={isValidated}
            />
          ))}
      </div>
    </div>
  );
};

export default CaseNoteHeaderOption;
