import Button from 'components/v2/Button/Button';
import { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import EventOwnerAndFormatField from '../EventOwnerAndFormatField/EventOwnerAndFormatField';
import styles from './BespokeForm.module.scss';
import AppointmentTitle from './components/AppointmentTitle/AppointmentTitle';
import Details from './components/Details/Details';
import Schedule from './components/Schedule/Schedule';
import { initialValues, appointmentBeSpokeSchema } from './constants';
import { notification } from 'antd';
import { postBespokeAppointment } from 'utils/http/appointment';
import { useGetAccessToken } from 'utils/hooks/token';
import moment from 'moment';
import Location from './components/Location/Location';
import Attendees from './components/Attendees/Attendees';
import { AppointmentSlots, Attendee } from 'interfaces/Schedule/Appointment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { AccessRight } from 'interfaces/Clients/clinician';

type BespokeFormProps = {
  isEdit?: boolean;
  appointment?: AppointmentSlots;
  eventType: string;
  date?: Date;
  onClose: () => void;
  setEventType: (eventType: string) => void;
  onCreateEditSuccess?: () => void;
  handleUpdateAppointment?: (payload: any, setSubmitStatus: Function) => Promise<void>;
};

const BespokeForm = ({
  isEdit = false,
  appointment,
  eventType,
  date,
  setEventType,
  onClose,
  onCreateEditSuccess,
  handleUpdateAppointment
}: BespokeFormProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const [clinician, setClinician] = useState<Partial<ClinicianListInterface>>();
  const [createEvent, setCreateEvent] = useState<'' | 'active' | 'finished'>('');
  const [selectedAttendees, setSelectedAttendees] = useState<Attendee[]>([]);

  useEffect(() => {
    if (isEdit && appointment) {
      setSelectedAttendees(
        appointment.attendees || [
          {
            ...appointment?.clientRecord?.clientProfiles[0],
            clientRecordId: appointment.clientRecord?._id,
            clientProfileId: appointment?.clientRecord?.clientProfiles[0]._id
          } as Attendee
        ]
      );
    }
  }, [isEdit, appointment]);

  const handleSelectClinician = (clinician?: Partial<PractitionersDetailsInterface>) => {
    setClinician(clinician);

    if (clinician?.accessRight === AccessRight.Mentor) {
      setSelectedAttendees((selectedAttendees) =>
        selectedAttendees.filter((attendee) => attendee.clinicianId && attendee.clinicianId === clinician._id)
      );
    }
  };

  const handleBeSpokeSubmit = async (value: typeof initialValues) => {
    try {
      setCreateEvent('active');
      const payload = {
        recurringAppointment: {
          frequency: value.isRecurring ? value.frequency : initialValues.frequency,
          amount: value.isRecurring ? value.occurrences : initialValues.occurrences
        },
        title: value.title,
        notes: value.notes,
        eventOwner: value.eventOwner,
        date: value.date,
        startTime: moment(value.startTime, 'hh:mm a').format('HH:mm'),
        endTime: moment(value.endTime, 'hh:mm a').format('HH:mm'),
        faceToFaceLocation: value.faceToFaceLocation,
        attendees: selectedAttendees,
        asAdmin: isEdgeAdminView || isEdgeReceptionistView
      };

      if (value.bookRoom && value.roomInfo) {
        Object.assign(payload, {
          room: JSON.parse(value.roomInfo)
        });
      } else {
        Object.assign(payload, {
          room: undefined
        });
      }

      if (isEdit) {
        Object.assign(payload, {
          id: appointment?._id
        });
        await handleUpdateAppointment!(payload, setCreateEvent);
      } else {
        const response = await postBespokeAppointment(payload, token);
        if (response.statusCode === 201 && onCreateEditSuccess) {
          onCreateEditSuccess();

          notification.success({
            message: 'Bespoke appointment created.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          onClose();
        } else if (response.statusCode === 409) {
          notification.error({ message: (await response.json()).message ?? 'Something went wrong.' });
        }
      }
    } catch (err) {
      notification.error({ message: 'Something went wrong.' });
      console.error(err);
    } finally {
      setCreateEvent('');
    }
  };

  const recurringDayDifference =
    appointment?.recurrings && appointment.recurrings.length > 1
      ? moment(appointment.recurrings[1].date, 'YYYY-MM-DD').diff(
          moment(appointment.recurrings[0].date, 'YYYY-MM-DD'),
          'd'
        )
      : 0;

  const formattedInitialValues =
    isEdit && appointment
      ? {
          eventOwner: appointment.clinicianId || '',
          isRecurring: !!appointment.recurringAppointmentId,
          frequency: recurringDayDifference.toString(),
          occurrences: appointment.recurrings?.length.toString(),
          title: appointment.title,
          notes: appointment.notes || '',
          date: appointment.date || '',
          startTime: appointment.startTime,
          endTime: appointment.endTime,
          faceToFaceLocation: appointment.faceToFaceLocation || '',
          bookRoom: !!appointment.room,
          roomInfo: JSON.stringify(appointment.room) || '',
          attendees: []
        }
      : initialValues;

  return (
    <Formik
      initialValues={formattedInitialValues}
      validationSchema={appointmentBeSpokeSchema}
      onSubmit={handleBeSpokeSubmit}
    >
      {({ submitForm }) => {
        return (
          <div className={styles.container}>
            <EventOwnerAndFormatField
              eventType={eventType}
              setClinician={handleSelectClinician}
              setEventType={setEventType}
              selectedClinician={clinician || {}}
              hideAppointmentFormat={isEdit}
            />
            <div className={styles.formContainer}>
              <div className={styles.eventTitleContainer}>
                <AppointmentTitle />
              </div>
              <div>
                <div className={styles.label}>When</div>
                <Schedule isEdit={isEdit} date={date} />
              </div>
              <div>
                <div className={styles.label}>Where</div>
                <Location bookedAppointmentId={appointment?._id} />
              </div>
              <div>
                <div className={styles.label}>Invite attendees</div>
                <Attendees
                  clinicianId={clinician?.accessRight === AccessRight.Mentor ? clinician._id || '' : ''}
                  selectedAttendees={selectedAttendees}
                  setSelectedAttendees={setSelectedAttendees}
                />
              </div>
              <div>
                <div className={styles.label}>Description and details</div>
                <Details />
              </div>
              <div className={styles.footer}>
                <div>
                  <Button className={styles.saveButtonContainer} status={createEvent} onClick={submitForm}>
                    Save Event
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default BespokeForm;
