import styles from './TeacherList.module.scss';
import { PractitionersDetailsWithSelected } from 'interfaces/Practitioners/practitionersListing';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';

interface TeacherListProps {
  list: PractitionersDetailsWithSelected[];
  onChangeSelectedList: (selectedList: PractitionersDetailsWithSelected[]) => void;
  selectedList: PractitionersDetailsWithSelected[];
}

const TeacherList = ({ list: teacherList, onChangeSelectedList: setSelectedList, selectedList }: TeacherListProps) => {
  const handleAdd = (id: string) => {
    let newList = [];
    for (let data of teacherList) {
      newList.push({
        ...data,
        isSelected: data.isSelected ? data.isSelected : data._id === id
      });
    }

    const selectedList = newList.filter((obj) => obj.isSelected);
    setSelectedList(selectedList);
  };

  const handleRemove = (id: string) => {
    let newList = [];
    for (let data of teacherList) {
      newList.push({
        ...data,
        isSelected: data._id === id ? false : data.isSelected
      });
    }

    const selectedList = newList.filter((obj) => obj.isSelected);
    setSelectedList(selectedList);
  };

  return (
    <div className={styles.container}>
      <div className={styles.clientListContainer}>
        <div className={styles.cardTitle}>Select who to take part in this intervention</div>
        <div className={styles.clientListWrapper}>
          {teacherList.map(({ _id, isSelected, avatar, name }, index: number) => {
            return (
              !isSelected && (
                <div className={styles.clientListCard} key={index}>
                  <div className={styles.clientRecordWrapper}>
                    <ClinicianAvatar
                      avatarUrl={avatar}
                      avatarSize={40}
                      isEllipsisName
                      name={name}
                      customNameClass={styles.name}
                      customContainerClass={styles.avatar}
                    />
                  </div>
                  <span className={styles.addBtn} onClick={() => handleAdd(_id)}>
                    + Add
                  </span>
                </div>
              )
            );
          })}
        </div>
      </div>
      <div className={styles.clientCartContainer}>
        <div className={styles.cardTitle}>
          <span className={styles.highlight}>{selectedList.length}</span> Selected trainers
        </div>
        <div className={styles.clientListWrapper}>
          {teacherList.map(
            ({ _id, isSelected, avatar, name }, index: number) =>
              isSelected && (
                <div className={styles.clientListCard} key={index}>
                  <div className={styles.clientRecordWrapper}>
                    <ClinicianAvatar
                      avatarUrl={avatar}
                      avatarSize={40}
                      isEllipsisName
                      name={name}
                      customNameClass={styles.name}
                      customContainerClass={styles.avatar}
                    />
                  </div>
                  <span className={styles.removeBtn} onClick={() => handleRemove(_id)}>
                    Remove
                  </span>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherList;
