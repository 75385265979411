import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { GroupAssessmentClient } from 'pages/Groups/Interfaces/Groups';
import { getGroupAssessmentClients } from 'utils/http/CheckInService/Assessment/groupAssessment';

export const useFetchGroupAssessmentClients = (
  token: string,
  groupId: string,
  groupAdHocAssessmentId: string,
  clinicalAssessmentId: string
) => {
  const [groupAssessmentClients, setGroupAssessmentClients] = useState<GroupAssessmentClient[]>([]);
  const [isGroupAssessmentClientsLoading, setIsGroupAssessmentClientsLoading] = useState(true);

  const fetchGroupAssessmentClients = useCallback(
    async (token: string) => {
      if (token && groupId && groupAdHocAssessmentId && clinicalAssessmentId) {
        try {
          setIsGroupAssessmentClientsLoading(true);
          const getGroupAssessmentClientsData = await getGroupAssessmentClients(
            token,
            groupId,
            groupAdHocAssessmentId,
            clinicalAssessmentId
          );
          const groupAssessmentClientsData = await getGroupAssessmentClientsData.json();
          setGroupAssessmentClients(groupAssessmentClientsData);
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to get your groups assessment clients' });
        }
      }
      setIsGroupAssessmentClientsLoading(false);
    },
    [groupId, groupAdHocAssessmentId, clinicalAssessmentId]
  );

  useEffect(() => {
    if (token) {
      fetchGroupAssessmentClients(token);
    }
  }, [fetchGroupAssessmentClients, token]);

  return { groupAssessmentClients, isGroupAssessmentClientsLoading, fetchGroupAssessmentClients };
};
