import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import ConsentContent from './components/ConsentContent/ConsentContent';

import styles from './ConsentForm.module.scss';

const ConsentForm = () => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const backBtn = () => {
    navigate(FORMS.BASE);
  };

  return (
    <HelmetWrapper title={'CORDS - Consent form'}>
      <ContentLayout>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Consent Form Editor</div>
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={backBtn}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                To Forms Hub
              </div>
            </div>
          </div>
        </div>
        <ConsentContent />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ConsentForm;
