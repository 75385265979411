import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';

export enum AppointmentAlertType {
  Cancelled = 'cancelled',
  Tagged = 'tagged',
  Changed = 'changed'
}

export interface AppointmentAlertInterface {
  _id: string;
  accountId: string;
  title: string;
  appointment: {
    _id: string;
    date: string;
    startTime: string;
    endTime: string;
    dayOfWeek: string;
    sessionTypeName: string;
    clientRecord: {
      _id: string;
      clientProfiles: Pick<
        clientProfilesInterface,
        '_id' | 'clientAuth0Id' | 'avatar' | 'initials' | 'initialsBackgroundColor' | 'name'
      >[];
    };
    isRecurring: boolean;
    includeRecurring?: boolean;
    clientRescheduleDetails?: {
      date: string;
      startTime: string;
      endTime: string;
    };
  };
  action: {
    type: AppointmentAlertType;
    createdAt: Date;
    actor: string;
    minutesBeforeAppointment?: number;
  };
}
