import styles from './ChangeNote.module.scss';

const MAX_NOTE_LENGTH = 160;

interface ChangeNoteProps {
  title?: string;
  note: string;
  onNoteChange: (note: string) => void;
}

const ChangeNote = ({ title, note, onNoteChange }: ChangeNoteProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title || 'Include a change note'}</div>
      <div className={styles.changeNoteContainer}>
        <textarea
          className={styles.changeNote}
          maxLength={MAX_NOTE_LENGTH}
          rows={3}
          value={note}
          onChange={(e) => onNoteChange(e.target.value)}
        />
        <span className={styles.characterCount}>
          {note.length} / {MAX_NOTE_LENGTH} characters
        </span>
      </div>
    </div>
  );
};

export default ChangeNote;
