import { useFormikContext, FormikValues } from 'formik';

import { useFetchAvailableRoomList } from 'utils/hooks/GetRoomList/useFetchRoomList';

import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { SelectProps } from 'components/v2/Select/Select';

interface RoomSelectProps extends SelectProps {
  bookedAppointmentId?: string;
  preferredRoomId?: string;
}

const RoomSelect = ({ bookedAppointmentId, preferredRoomId, ...selectProps }: RoomSelectProps) => {
  const { values } = useFormikContext<FormikValues>();
  const { availableRoomList, isAvailableRoomListLoading } = useFetchAvailableRoomList(values, bookedAppointmentId);

  const mapRoomList = () => {
    let list = [...availableRoomList];
    if (preferredRoomId) {
      list = list.filter((item) => item._id === preferredRoomId);
    }
    return list.map((room) => ({
      label: room.name,
      value: JSON.stringify({
        roomId: room._id,
        roomName: room.name
      })
    }));
  };

  return isAvailableRoomListLoading ? (
    <div>Loading...</div>
  ) : (
    <FormikSelect name="roomInfo" label="Select available room" options={mapRoomList()} {...selectProps} />
  );
};

export default RoomSelect;
