import { useTranslation } from 'react-i18next';

import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  completePage: boolean;
}

const ModalHeader = ({ completePage }: ModalHeaderProps) => {
  const [t] = useTranslation();

  const lang = {
    title: completePage ? t('title.new_practitioner_invited') : t('title.add_new_practitioner'),
    desc: `They will receive a link to access and complete their profile`
  };

  return (
    <>
      <div id={'modalHeader'} />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{lang.title}</div>
          {lang.desc && (
            <div className={styles.desc}>{'They will receive a link to access and complete their profile'}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalHeader;
