import { MouseEvent } from 'react';
import styles from './CaseNoteListing.module.scss';
import LoadingButton from 'components/v2/Button/Button';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { GroupCaseNoteInterface } from '../../interfaces';
import CaseNoteListItem from '../CaseNoteListItem/CaseNoteListItem';
interface CaseNoteListingProps {
  noteId?: string;
  caseNotes: GroupCaseNoteInterface[];
  searchValue: string;
  addNoteButtonStatus: '' | 'active' | 'finished';
  isAddNoteButtonDisabled: boolean;
  onClickNewNote: () => void;
  onSelectNote: (_id: string) => (e: MouseEvent) => void;
  isEditing: boolean;
}

const CaseNoteListing = ({
  noteId,
  caseNotes,
  searchValue,
  addNoteButtonStatus,
  isAddNoteButtonDisabled,
  onClickNewNote,
  onSelectNote,
  isEditing
}: CaseNoteListingProps) => {
  const { auth0ClinicianId } = useGetClinicianId();

  return (
    <div className={styles.container}>
      {/* Add new button */}
      <LoadingButton
        className={styles.addNoteButton}
        status={addNoteButtonStatus}
        disabled={isAddNoteButtonDisabled}
        onClick={onClickNewNote}
      >
        <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
        NEW NOTE
      </LoadingButton>
      {isEditing && (
        <>
          <div className={styles.warningMessage}>Save or close the current note editing to create a new one.</div>
        </>
      )}

      {/* List of case note */}
      <div className={styles.list}>
        {caseNotes.length > 0
          ? caseNotes.map((caseNote) => (
              <CaseNoteListItem
                key={caseNote._id}
                selectedCaseNoteId={noteId}
                auth0ClinicianId={auth0ClinicianId}
                caseNote={caseNote}
                onSelectNote={onSelectNote}
                isDisabled={isEditing}
              />
            ))
          : searchValue !== '' && <div className={styles.noValue}>No case note match {searchValue}</div>}
      </div>
    </div>
  );
};

export default CaseNoteListing;
