import classNames from 'classnames';
import { CSSProperties } from 'react';
import ReactSelect, { Props } from 'react-select';
import styles from './Select.module.scss';

const propStyles: Props['styles'] = {
  container: (base) => ({ ...base, margin: 0 }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${styles.greyColor}`,
    borderRadius: 0,
    boxShadow: 'none'
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  menu: (base) => ({ ...base, marginTop: 0, width: '100% !important' }),
  placeholder: (base) => ({ ...base, fontSize: 13, lineHeight: '18px' }),
  singleValue: (base) => ({ ...base, color: '#343434' }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: styles.blueColor,
    borderRadius: 25,
    color: styles.whiteColor,
    padding: 4
  }),
  multiValueLabel: (base) => ({ ...base, color: styles.whiteColor }),
  valueContainer: (base) => ({ ...base, padding: '4px 12px' }),
  dropdownIndicator: (base) => ({ ...base, padding: 0 })
};

export type SelectProps = Props & {
  label?: string;
  value?: string;
  filledArrow?: boolean;
  controlStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  containerClass?: string;
  filledArrowStyle?: CSSProperties;
  filledArrowClass?: string;
};

const Select = ({
  label,
  filledArrow,
  valueStyle,
  controlStyle,
  containerClass,
  filledArrowStyle,
  filledArrowClass,
  ...props
}: SelectProps) => {
  return (
    <div className={classNames(styles.container, containerClass, filledArrow && styles.filledArrow)}>
      {label && <div className={styles.label}>{label}</div>}
      <ReactSelect
        {...props}
        styles={{
          ...propStyles,
          option: (base: any, { data, isDisabled, isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? styles.yellowColor
              : isFocused
              ? styles.lightYellowColor
              : undefined,
            ':active': {
              ...base[':active'],
              backgroundColor: !isDisabled ? (isSelected ? data.color : styles.yellowColor) : undefined
            }
          }),
          singleValue: (base) => ({ ...base, width: '100% !important' }),
          ...(controlStyle && { control: (base) => ({ ...base, ...controlStyle }) }),
          ...(valueStyle && { valueContainer: (base) => ({ ...base, ...valueStyle }) }),
          ...(filledArrow && { dropdownIndicator: (base) => ({ ...base, display: 'none' }) })
        }}
        {...props}
        components={{
          ...props.components,
          ...(filledArrow && {
            //eslint-disable-next-line react/no-multi-comp
            DropdownIndicator: () => (
              <div className={classNames(styles.arrow, filledArrowClass)} style={filledArrowStyle}>
                <i className="material-icons-outlined">arrow_drop_down</i>
              </div>
            )
          })
        }}
      />
    </div>
  );
};

export default Select;
