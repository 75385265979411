import bent from 'bent';
import { v4 as uuid } from 'uuid';
const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

const getData = (authKey: string, id: string) => {
  const checkInRequest = bent(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return checkInRequest(`/micro-check-ins/${id}`);
};

const massageQuestion = (data: any) => {
  const massageQ = [];
  for (let obj of data) {
    massageQ.push({
      id: uuid(),
      stem: obj.stem,
      isChecked: obj.isChecked,
      refKey: obj.refKey,
      questionId: uuid(),
      isDraftQ: false,
      ...obj
    });
  }
  return massageQ;
};

const massageQuestionSet = (data: any) => {
  const massageMicroJournalQ = [];

  let num = 0;
  for (let obj of data) {
    const sectionId = num++;
    massageMicroJournalQ.push({
      id: sectionId.toString(),
      sectionName: obj.sectionName,
      askFrequency: obj.askFrequency,
      questions: massageQuestion(obj.questions),
      refKey: obj.refKey
    });
  }

  return massageMicroJournalQ;
};

const massageData = (data: any) => {
  return {
    id: data.id.toString(),
    name: data.name,
    description: data.description || '',
    isExisting: true,
    isPublished: data.isPublished,
    isDefault: data.isDefault,
    assessmentType: 'microCheckIn',
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    questionSets: massageQuestionSet(data.questionSets)
  };
};

export const getMCIAssessmentDetails = async (token: string, id: string) => {
  const mCIAssessmentData = await getData(token, id);

  return massageData(mCIAssessmentData);
};
