import { InvoiceViewProps } from '../../InvoiceView';

import styles from './PaymentMethods.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const CODE_LABEL = GAE_REGION === 'au' ? 'BSB Code' : GAE_REGION === 'gb' ? 'Sort Code' : undefined;

const PAYMENT_TYPE_LABELS = {
  stripe: 'Online Payment',
  bankTransfer: 'Bank Transfer',
  paypal: 'Paypal',
  cash: 'Cash',
  other: 'Other'
};

interface PaymentMethodsProps {
  paymentMethods: Required<InvoiceViewProps>['paymentMethods'];
}

const PaymentMethods = ({ paymentMethods }: PaymentMethodsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Payment via</div>
      {paymentMethods.map(({ accountNumber, code, email, instructions, paymentType, paypalMeLink }, index) => (
        <div className={styles.paymentMethodContainer} key={index}>
          <div className={styles.label}>{PAYMENT_TYPE_LABELS[paymentType]}</div>
          {paymentType === 'bankTransfer' && (
            <>
              <div className={styles.description}>
                Account Number: <span className={styles.value}>{accountNumber}</span>
              </div>
              <div className={styles.description}>
                {CODE_LABEL}: <span className={styles.value}>{code}</span>
              </div>
              {instructions && (
                <div className={styles.description}>
                  Instructions: <span className={styles.value}>{instructions}</span>
                </div>
              )}
            </>
          )}
          {paymentType === 'paypal' && (
            <>
              <div className={styles.description}>
                ID: <span className={styles.value}>{email}</span>
              </div>
              {paypalMeLink && (
                <div className={styles.description}>
                  Link:{' '}
                  <a className={styles.value} href={paypalMeLink}>
                    {paypalMeLink}
                  </a>
                </div>
              )}
              {instructions && (
                <div className={styles.description}>
                  Instructions: <span className={styles.value}>{instructions}</span>
                </div>
              )}
            </>
          )}
          {paymentType === 'cash' && (
            <div className={styles.description}>
              Instructions: <span className={styles.value}>{instructions}</span>
            </div>
          )}
          {paymentType === 'other' && (
            <>
              <div className={styles.description}>
                Instructions: <span className={styles.value}>{instructions}</span>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PaymentMethods;
