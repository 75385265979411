import styles from './NotesWidget.module.scss';

interface NotesWidgetProps {
  data: any;
  isPreview?: boolean;
}

const NotesWidget = ({ data, isPreview }: NotesWidgetProps) => {
  return (
    <div className={`ql-snow`}>
      <div className={`ql-editor ${isPreview ? styles.containerPreview : styles.container}`}>
        <div className={styles.title}>{data.title}</div>
        <div className={styles.desc} dangerouslySetInnerHTML={{ __html: data.body }} />
      </div>
    </div>
  );
};

export default NotesWidget;
