import classNames from 'classnames';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './ButtonSelectAll.module.scss';

interface ButtonSelectAllProps {
  onClick: () => void;
  className?: string;
  ableSelectAll?: boolean;
}

const ButtonSelectAll = ({ className, onClick, ableSelectAll }: ButtonSelectAllProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();

  return (
    <button className={classNames(styles.button, className, isEdgeAdminView && styles.admin)} onClick={onClick}>
      {ableSelectAll ? 'SELECT ALL' : 'DESELECT ALL'}
    </button>
  );
};

export default ButtonSelectAll;
