import { MouseEvent, useEffect } from 'react';
import classnames from 'classnames';
import styles from './GroupPsychometricMenuList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GroupDetailsTab, GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { scrollToView } from 'utils/scrollToView';

interface GroupPsychometricMenuListProps {
  isLoading: boolean;
  groupId: string;
  activeId: string;
  childActiveId: string;
  psychometricList: GroupPsychometricMenu[];
}

const GroupPsychometricMenuList = ({
  isLoading,
  groupId,
  activeId,
  childActiveId,
  psychometricList
}: GroupPsychometricMenuListProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const handleClickPsychometric = (id: string, childActiveId: string) => (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Psychometrics}/${id}/clinicalAssessmentId/${childActiveId}`);
  };

  useEffect(() => {
    scrollToView('selectAssessmentId', true);
  }, [psychometricList]);

  return (
    <>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          {psychometricList && psychometricList?.length > 0 ? (
            <div className={styles.container}>
              {psychometricList.map(({ _id, id, clinicalAssessmentId, createdAt, ...psychoMetricItem }) => (
                <div
                  id={activeId === _id ? `selectAssessmentId` : ''}
                  className={classnames(styles.item, psychoMetricItem.isSent && styles.link)}
                  key={`${_id}_${clinicalAssessmentId}`}
                  {...(psychoMetricItem.isSent && {
                    onClick: handleClickPsychometric(_id, clinicalAssessmentId)
                  })}
                >
                  {psychoMetricItem.isSent ? (
                    <FontAwesomeIcon
                      className={styles.icon}
                      icon={activeId === _id && clinicalAssessmentId === childActiveId ? SolidCircle : RegularCircle}
                    />
                  ) : (
                    <div className={styles.noIcon} />
                  )}
                  <div className={styles.label}>
                    <span className={styles.name}>{id}</span>
                    <span>
                      {psychoMetricItem.isSent
                        ? moment(createdAt).format('DD/MM/YY')
                        : `scheduled for ${moment(psychoMetricItem.sendAt, 'YYYY-MM-DD hh:mmA Z').format('DD/MM/YY')}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No psychometric assessments</div>
          )}
        </>
      )}
    </>
  );
};

export default GroupPsychometricMenuList;
