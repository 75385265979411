import LoadingDot from 'components/LoadingDot/LoadingDot';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import HelmControl from './components/HelmControl/HelmControl';
import { MisConnection } from './components/MisConnection/MisConnection';

import ProfileAboutYou from './components/ProfileAboutYou/ProfileAboutYou';
import ProfileMedicare from './components/ProfileMedicare/ProfileMedicare';
import ProfilePractice from './components/ProfilePractice/ProfilePractice';
import ProfileWorkingSchedule from './components/ProfileWorkingSchedule/ProfileWorkingSchedule';
import SystemConnection from './components/SystemConnection/SystemConnection';
import styles from './ControlPanelContentDisplay.module.scss';

interface ControlPanelContentDisplayProps {
  profile?: ProfileInterface;
  controlPanelTab?: string;
  isLoading: boolean;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
  aboutYouPage: boolean;
  helmControlPage: boolean;
  medicarePage: boolean;
  workingSchedulePage: boolean;
  practiceProfilePage: boolean;
  systemConnectionPage: boolean;
  simpleForm?: boolean;
  misConnectionPage?: boolean;
}

const ControlPanelContentDisplay = ({
  profile,
  controlPanelTab,
  isLoading,
  onUpdateProfile,
  aboutYouPage,
  helmControlPage,
  medicarePage,
  workingSchedulePage,
  practiceProfilePage,
  systemConnectionPage,
  misConnectionPage
}: ControlPanelContentDisplayProps) => {
  const getContentComponents = (profile: ProfileInterface, controlPanelTab?: string) => {
    switch (controlPanelTab) {
      case 'school':
        return practiceProfilePage && <ProfilePractice />;

      case 'schedule':
        return workingSchedulePage && <ProfileWorkingSchedule profile={profile} onUpdateProfile={onUpdateProfile} />;

      case 'system-connections':
        return systemConnectionPage && <SystemConnection />;

      case 'medicare':
        return medicarePage && <ProfileMedicare />;

      case 'intervention-library':
        return (
          helmControlPage && (
            <HelmControl profile={{ ...(profile.helmControl || {}), isProfileListed: profile.isProfileListed }} />
          )
        );
      case 'mis-connection':
        return misConnectionPage && <MisConnection />;
      case 'about-you':
      default:
        return aboutYouPage && <ProfileAboutYou profile={profile} onUpdateProfile={onUpdateProfile} />;
    }
  };

  return isLoading || !profile ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>{getContentComponents(profile, controlPanelTab)}</div>
  );
};

export default ControlPanelContentDisplay;
