import { useAuth0 } from '@auth0/auth0-react';

export const useGetAccountPackage = () => {
  const { user } = useAuth0();
  const userAccessConfig = user?.['https://tacklit.com/accessConfig'];
  const isEdgeReceptionist = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'receptionist';
  const isEdgeAdminUser = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'admin';
  const isEdgeUser = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'user';
  const isEdgePlanUser = userAccessConfig.plan === 'edge';
  const isDefaultPlan = userAccessConfig.plan !== 'edge';
  const isMentorUser = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'mentor';
  return {
    isEdgeAdminUser,
    isEdgePlanUser,
    isEdgeReceptionist,
    isEdgeUser,
    isDefaultPlan,
    userAccessConfig,
    isMentorUser
  };
};
