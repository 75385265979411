import { AppointmentType, AssignmentMode, ParticipantType, RateType, Rule } from 'interfaces/Schedule/AppointmentType';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export const initialValues: Partial<Omit<AppointmentType, 'duration'>> & {
  duration: string;
  customDuration?: string;
} = {
  name: '',
  description: '',
  duration: '30',
  customDuration: '',
  rate: undefined,
  rateType: RateType.PerSession,
  hidePublicRate: false,
  bookingRules: {
    new: {
      available: true,
      rule: Rule.Instant
    },
    existing: {
      available: true,
      rule: Rule.Instant
    }
  },
  paymentRequired: false,
  assignmentMode: AssignmentMode.All,
  participantType: ParticipantType.OneToOne,
  assignedClinicians: []
};

export const initialValuesUpdate = (data: AppointmentType) => {
  const durationString = String(data.duration);

  return {
    ...initialValues,
    name: data.name,
    description: data.description,
    rate: data.rate,
    rateType: data.participantType === ParticipantType.OneToOne ? RateType.PerSession : data.rateType,
    hidePublicRate: data.hidePublicRate || false,
    bookingRules: data.bookingRules,
    paymentRequired: data.paymentRequired || false,
    assignmentMode: data.assignmentMode || AssignmentMode.All,
    participantType: data.participantType || ParticipantType.OneToOne,
    assignedClinicians: data.assignedClinicians || [],
    ...(timeOptions.find(({ value }) => value === durationString)
      ? { duration: durationString }
      : { duration: 'custom', customDuration: durationString })
  };
};

export const appointmentTypeSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required('Please provide a name for this set-up'),
    description: Yup.string(),
    duration: Yup.string()
      .required('Please specify the length of this session')
      .test({
        name: 'durationTest',
        test: (value) => isNaN(value) || value !== 'custom',
        message: 'Please specify the length of this session'
      }),
    customDuration: Yup.string().when('duration', {
      is: 'custom',
      then: Yup.string().test({
        name: 'customDurationTest',
        test: (value) => !isNaN(value),
        message: 'Please specify the length of this session'
      }),
      otherwise: Yup.string()
    }),
    rate: Yup.number()
      .typeError('Please specify the rate of this session')
      .required('Please specify the rate of this session'),
    bookingRules: Yup.object().shape({
      new: Yup.object().shape({
        available: Yup.boolean(),
        rule: Yup.string().oneOf(['instant', 'request'])
      }),
      existing: Yup.object().shape({
        available: Yup.boolean(),
        rule: Yup.string().oneOf(['instant', 'request'])
      })
    }),
    assignmentMode: Yup.string(),
    assignedClinicians: Yup.array()
      .when('assignmentMode', {
        is: AssignmentMode.Selected,
        then: Yup.array().min(1, t('validation.practitioner.min_1')).of(Yup.string())
      })
      .nullable(),
    hidePublicRate: Yup.boolean(),
    paymentRequired: Yup.boolean()
  });

export const timeOptions = [
  { label: '5 minutes', value: '5' },
  { label: '10 minutes', value: '10' },
  { label: '15 minutes', value: '15' },
  { label: '20 minutes', value: '20' },
  { label: '25 minutes', value: '25' },
  { label: '30 minutes', value: '30' },
  { label: '35 minutes', value: '35' },
  { label: '40 minutes', value: '40' },
  { label: '45 minutes', value: '45' },
  { label: '50 minutes', value: '50' },
  { label: '55 minutes', value: '55' },
  { label: '60 minutes', value: '60' },
  { label: '70 minutes', value: '70' },
  { label: '80 minutes', value: '80' },
  { label: '90 minutes', value: '90' },
  { label: '120 minutes', value: '120' },
  { label: 'Custom', value: 'custom' }
];
