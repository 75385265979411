import { notification } from 'antd';
import { useState } from 'react';
import styles from './EditAppointmentStatusModal.module.scss';
import AppointmentStatusForm from './components/AppointmentStatusForm/AppointmentStatusForm';
import Button from 'components/v2/Button/Button';
import { useGetAccessToken } from 'utils/hooks/token';
import ModalV2 from 'components/ModalV2/ModalV2';
import { AppointmentStatusOverviewConfiguration } from 'interfaces/Clients/clientsRecord';
import { postAppointmentStatus } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import AppointmentStatusTagsForm from './components/AppointmentStatusTagsForm/AppointmentStatusTagsForm';

interface EditAppointmentStatusModalProps {
  clientRecordId: string;
  appointmentStatusOverviewConfiguration: AppointmentStatusOverviewConfiguration;
  visible: boolean;
  onSuccess: (val: AppointmentStatusOverviewConfiguration) => void;
  onCloseModal: () => void;
}

const EditAppointmentStatusModal = ({
  clientRecordId,
  appointmentStatusOverviewConfiguration,
  visible,
  onSuccess,
  onCloseModal
}: EditAppointmentStatusModalProps) => {
  const { token } = useGetAccessToken();

  const [appointmentStatus, setAppointmentStatus] = useState(appointmentStatusOverviewConfiguration);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = async () => {
    setPageSubmitStatus('active');

    try {
      const appointmentStatusPayload: AppointmentStatusOverviewConfiguration = {
        target: appointmentStatus.target,
        limit: appointmentStatus.limit,
        displayTagCount: appointmentStatus.displayTagCount
      };

      await postAppointmentStatus(token, clientRecordId, appointmentStatusPayload);
      setPageSubmitStatus('finished');
      notification.success({
        message: 'Appointment status updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setPageSubmitStatus('');
        onSuccess(appointmentStatusPayload);
        onCloseModal();
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setPageSubmitStatus('');
      notification.error({
        message: 'Appointment status fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleChangeValue = (key: string, val: number) => {
    setAppointmentStatus({
      ...appointmentStatus,
      [key]: val
    });
  };

  const handleChangeDisplayTagsValue = (val: string[]) => {
    setAppointmentStatus({
      ...appointmentStatus,
      displayTagCount: val
    });
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={'Edit Appointment Status'}
      onModalClose={onCloseModal}
    >
      <div className={styles.container}>
        <AppointmentStatusForm
          selectedLimitValue={appointmentStatus.limit.toString()}
          selectedTargetValue={appointmentStatus.target.toString()}
          onChangeLimit={(val) => handleChangeValue('limit', val)}
          onChangeTarget={(val) => handleChangeValue('target', val)}
        />
        <AppointmentStatusTagsForm
          displayTagCount={appointmentStatus.displayTagCount}
          onChangeDisplayTag={(val) => handleChangeDisplayTagsValue(val)}
        />
      </div>
      <div className={styles.submitButtonContainer}>
        <Button status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </ModalV2>
  );
};

export default EditAppointmentStatusModal;
