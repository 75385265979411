import Button from 'components/Button/Button';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInitPathGenerator } from 'utils/hooks/Path/pathGenerator';

import {
  ReportNotification as ReportNotificationInterface,
  ReportStatus,
  ReportStatusHistory,
  ReviewType
} from './interfaces';
import styles from './ReportNotification.module.scss';

interface ReportNotificationProps {
  reportNotification: ReportNotificationInterface;
  onDismissNotification: (reportId: string) => Promise<void>;
  onCancel: () => void;
}

const getLabelText = (statusHistory: ReportStatusHistory) => {
  switch (statusHistory.status) {
    case ReportStatus.Draft:
      if (statusHistory.review) {
        if (statusHistory.review.reviewType === ReviewType.ChangesRequested) {
          return {
            title: 'Document changes requested',
            action: 'changes requested',
            by: 'REQUESTED'
          };
        } else {
          return {
            title: 'Document feedback',
            action: 'feedback',
            by: 'FEEDBACK'
          };
        }
      }
      return {
        title: 'Document updated',
        action: '',
        by: ''
      };
    case ReportStatus.InReview:
      return {
        title: 'Document review requested',
        action: 'review requested',
        by: 'REQUESTED'
      };
    case ReportStatus.Approved:
      return {
        title: 'Document approved',
        action: 'approved',
        by: 'APPROVED'
      };
    case ReportStatus.Published:
      return {
        title: 'Document published',
        action: 'published',
        by: 'PUBLISHED'
      };
    default:
      throw new Error('Invalid report status');
  }
};

const ReportNotification = ({ reportNotification, onDismissNotification, onCancel }: ReportNotificationProps) => {
  const navigate = useNavigate();
  const initPath = useInitPathGenerator();

  const goToDetail = (id: string) => {
    navigate(`${initPath}/reports/${id}`);
    onCancel();
  };

  const { _id: reportId, reportName, statusHistory } = reportNotification;
  const [isLoading, setIsLoading] = useState(false);

  const label = getLabelText(statusHistory);

  const onClickDismissNotification = async (reportId: string) => {
    setIsLoading(true);
    await onDismissNotification(reportId);
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{label.title}</div>
        <button
          className={`${styles.clearAlertBtn} ${isLoading ? styles.clearAlertBtnBusy : ''}`}
          onClick={() => onClickDismissNotification(reportId)}
        >
          Dismiss
        </button>
      </div>
      <div className={styles.content}>
        <div className={styles.labelContainer}>
          <div className={styles.avatarAndName}>
            <div className={styles.label}>{`${label.by} BY`}</div>
          </div>
          <div className={styles.reportNameContainer}>
            <div className={styles.label}>TITLE</div>
          </div>
          <div className={styles.commentAndDatetime}>
            <div className={styles.label}>WHEN</div>
          </div>
          <div className={styles.actions} />
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.avatarAndName}>
            <ClinicianAvatar
              name={statusHistory.statusChangedBy.name}
              avatarUrl={statusHistory.statusChangedBy.avatar}
              avatarSize={45}
              customContainerClass={styles.avatarContainer}
              customNameClass={styles.clinicianName}
            />
          </div>
          <div className={styles.reportNameContainer}>
            <div className={styles.reportName}>{reportName}</div>
          </div>
          <div className={styles.commentAndDatetime}>
            <div className={styles.statusUpdated}>
              {`${label.action} on ${moment(statusHistory.statusChangedAt).format('D MMMM YYYY, hh:mmA')}`}
            </div>
          </div>
          <div className={styles.actions}>
            <Button className={styles.button} variant="secondary" onClick={() => goToDetail(reportId)}>
              View Document
            </Button>
          </div>
        </div>
      </div>
      {statusHistory.review?.message && (
        <div className={styles.commentContainer}>
          <div className={styles.commentTitle}>COMMENT</div>
          <div className={styles.comment}>{statusHistory.review.message}</div>
        </div>
      )}
    </div>
  );
};

export default ReportNotification;
