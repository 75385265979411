import { useState } from 'react';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import styles from './GroupInformation.module.scss';
import classnames from 'classnames';
import columnStyles from './GroupInformationColumn.module.scss';
import { AttachedGroup } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import GroupConfigurationActionMenu from 'pages/Groups/GroupDetails/components/GroupContent/components/Common/GroupConfigurationActionMenu/GroupConfigurationActionMenu';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import Button from 'components/v2/Button/Button';
import AvailableGroupsModal from './components/AvailableGroupsModal/AvailableGroupsModal';
import { patchAssignedGroupMember } from 'utils/http/ClinicianProfileService/Groups/GroupMembers';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchAvailableGroups } from '../../hooks/getAvailableGroups';

interface GroupInformationProps {
  clientRecordId: string;
  attachedGroups: AttachedGroup[];
  onRefreshData?: () => void;
}

const GroupInformation = ({ clientRecordId, attachedGroups, onRefreshData }: GroupInformationProps) => {
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { token } = useGetAccessToken();
  const [sortType, setSortType] = useState<SortType>(SortType.noSort);
  const [sortedAtachedGroups, setSortedAtachedGroups] = useState<AttachedGroup[]>(attachedGroups);
  const [isShowGroupsModal, setIsShowGroupsModal] = useState<boolean>(false);
  const [isAssigning, setIsAssigning] = useState<boolean>(false);
  const { accountId } = useGetAccountId();
  const { groupList, isGroupListLoading, fetchAvailableGroups } = useFetchAvailableGroups(token, accountId);

  const onChangeSortType = (sortType: SortType) => {
    setSortType(sortType);
    if (sortType === SortType.noSort) {
      setSortedAtachedGroups(attachedGroups);
    } else {
      setSortedAtachedGroups(
        attachedGroups.sort((a, b) => {
          return sortType === SortType.asc ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
        })
      );
    }
  };

  const onAssignedClientToGroup = async (groupId: string) => {
    try {
      notification.warning({
        key: 'assigningToGroup',
        message: 'Assigning to group...',
        closeIcon: <span className="success">OK</span>,
        duration: 0
      });
      setIsShowGroupsModal(false);
      setIsAssigning(true);
      await patchAssignedGroupMember(token, accountId, groupId, [clientRecordId]);
      onRefreshData && onRefreshData();
      notification.close('assigningToGroup');
      notification.success({
        message: 'Successfully assigned to group.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setIsAssigning(false);
    } catch (ex) {
      console.error(ex);
      notification.close('assigningToGroup');
      notification.error({
        message: 'Fail to assign to group.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setIsAssigning(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Group Information</div>
      <div className={styles.content}>
        {isEdgeAdminView && (
          <div className={styles.assignedButton}>
            <Button
              variant={'secondary'}
              className={styles.button}
              onClick={() => {
                if (isAssigning) {
                  return;
                }
                !groupList && fetchAvailableGroups();
                setIsShowGroupsModal(true);
              }}
              disabled={isAssigning}
            >
              <i className={`material-icons-outlined ${styles.icon}`}>add</i>Assign to group
            </Button>
          </div>
        )}
        <div className={classnames(columnStyles.columnWrapper, styles.header)}>
          <div className={columnStyles.groupName}>Group Name</div>
          {/* <div className={columnStyles.groupTag}>Group Tag</div> */}
          <div className={columnStyles.groupLead}>Group Lead</div>
          <div className={columnStyles.joinedDate}>Joined date</div>
          <div className={classnames(columnStyles.memberStatus, columnStyles.sortActive)}>
            Member Status
            <SortBtn selectedSortName={sortType} onChangeSort={onChangeSortType} />
          </div>
          <div className={columnStyles.groupStage}>Stage</div>
          <div className={columnStyles.action} />
        </div>
        <div className={styles.groupList}>
          {sortedAtachedGroups.length > 0 ? (
            sortedAtachedGroups.map((group, index) => {
              const stageInfo =
                group.stagesConfiguration?.anytime.find((stageObj) => stageObj._id === group.stageId) ||
                group.stagesConfiguration?.sequential.find((stageObj) => stageObj._id === group.stageId);

              const isGroupPractitioner =
                auth0ClinicianId === group?.groupLeadClinicianAuth0Id ||
                (group?.groupClinicianAuth0Ids && group?.groupClinicianAuth0Ids.includes(auth0ClinicianId));

              return (
                <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
                  <div className={classnames(columnStyles.groupName, styles.groupName)}>
                    {group.groupName ? group.groupName : '-'}
                  </div>
                  {/* <div className={classnames(columnStyles.groupTag, styles.groupTag)}>
                {group.groupTags?.map((tag) => (
                  <div className={styles.tagPill}>{tag}</div>
                ))}
              </div> */}
                  <div className={classnames(columnStyles.groupLead, styles.groupLead)}>
                    {group.groupLeadName ? group.groupLeadName : '-'}
                  </div>
                  <div className={classnames(columnStyles.joinedDate, styles.joinedDate)}>
                    <div className={styles.date}>{moment(group.dateJoined).format('D MMM YYYY')}</div>
                    <div className={styles.joinedSince}>{`(${moment().diff(
                      moment(group.dateJoined),
                      'days'
                    )} days)`}</div>
                  </div>
                  <div className={columnStyles.memberStatus}>
                    <div className={classnames(styles.status, styles[group.status])}>{group.status.toUpperCase()}</div>
                  </div>
                  <div className={classnames(columnStyles.groupStage, styles.groupStage)}>
                    {stageInfo ? (
                      <div className={styles.stagePill}>{stageInfo.name}</div>
                    ) : (
                      <div className={styles.groupLead}>-</div>
                    )}
                  </div>
                  <div className={columnStyles.action}>
                    {(isEdgeAdminView || isGroupPractitioner) && (
                      <GroupConfigurationActionMenu
                        groupId={group.groupId}
                        activeStageId={group.stageId}
                        groupStatus={group.status}
                        groupStagesConfiguration={group.stagesConfiguration}
                        clientRecordId={clientRecordId}
                        onRefreshList={onRefreshData}
                        linkClientProfile
                      />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className={styles.grayOutLabel}>No group assigned</div>
          )}
        </div>
      </div>
      <AvailableGroupsModal
        key={`${clientRecordId}-assignedGroupModal`}
        isGroupListLoading={isGroupListLoading}
        isVisible={isShowGroupsModal}
        onAssigned={onAssignedClientToGroup}
        groupList={groupList?.groups.filter(({ _id }) => !attachedGroups.some(({ groupId }) => groupId === _id)) || []}
        onCancel={() => setIsShowGroupsModal(false)}
      />
    </div>
  );
};

export default GroupInformation;
