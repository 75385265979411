import { MouseEvent } from 'react';
import styles from './CaseNoteListItem.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import { GroupCaseNoteInterface } from '../../interfaces';

interface CaseNoteListItemProps {
  selectedCaseNoteId?: string;
  caseNote: GroupCaseNoteInterface;
  auth0ClinicianId: string;
  onSelectNote: (_id: string) => (e: MouseEvent) => void;
  isDisabled: boolean;
}

const CaseNoteListItem = ({
  selectedCaseNoteId,
  caseNote,
  auth0ClinicianId,
  onSelectNote,
  isDisabled
}: CaseNoteListItemProps) => {
  return (
    <div
      className={classNames(
        styles.noteItem,
        caseNote._id === selectedCaseNoteId && styles.selected,
        isDisabled && styles.disabled
      )}
      onClick={!isDisabled ? onSelectNote(caseNote._id) : () => {}}
    >
      <div className={styles.noteTitle}>
        {caseNote.title || 'New Note'}
        {caseNote.clinicianId && caseNote.clinicianId !== auth0ClinicianId && (
          <i className={`material-icons-outlined ${styles.icon}`}>lock</i>
        )}
      </div>
      <div className={styles.noteContent}>
        <div className={styles.noteDate}>{moment(caseNote.updatedAt).format('DD/MM/YYYY hh:mm A')}</div>
        {caseNote.attachments.length > 0 && (
          <div className={styles.noteAttachmentsCount}>
            <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
            {caseNote.attachments.length}
          </div>
        )}
        {caseNote.safeguardingAlert && (
          <div className={styles.safeguardingAlert}>
            <i className={`material-icons ${styles.safeguardingAlertIcon}`}>report_problem</i>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseNoteListItem;
