import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AppointmentTypes from './components/AppointmentTypes/AppointmentTypes';
import classNames from 'classnames';
import AccountLevelSettings from './components/AccountLevelSettings/AccountLevelSettings';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './CalendarSettings.module.scss';
import RoomManagement from './components/RoomManagement/RoomManagement';
import CalendarLinking from './components/CalendarLinking/CalendarLinking';

interface subMenuProps {
  pathName: string;
  name: string;
  desc: string;
  iconName: string;
  active: boolean;
  pathLink: string;
}

const renderSubMenuItem = (index: number, { name, desc, iconName, active, pathLink }: subMenuProps) => {
  return (
    <Link to={pathLink} key={index}>
      <div
        className={classNames(styles.submenu, {
          [styles.active]: active
        })}
      >
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">{iconName}</span>
        </div>
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.subname}>{desc}</div>
        </div>
      </div>
    </Link>
  );
};

const CalendarSettings = () => {
  const navigate = useNavigate();
  const { CALENDAR } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeUser, isNormalUserView } = useGetAccountPackageView();
  const { tab } = useParams<{ tab: string }>();

  const backBtn = () => {
    navigate(CALENDAR.BASE);
  };

  const subMenu = [
    {
      pathName: 'appointmentTypes',
      name: 'Appointment Types',
      desc: 'Create and edit',
      iconName: 'edit_calendar'
    },
    (isEdgeAdminView || isNormalUserView) && {
      pathName: 'accountLevelSettings',
      name: 'Account Level Settings',
      desc: 'Set up rules to manage scheduling',
      iconName: 'settings_suggest'
    },
    isEdgeUser && {
      pathName: 'roomManagement',
      name: 'Room Management',
      desc: 'Set up room calendars',
      iconName: 'meeting_room'
    }
  ] as subMenuProps[];

  const renderContent = () => {
    switch (tab) {
      case 'appointmentTypes':
        return <AppointmentTypes />;
      case 'calendarLinking':
        return (isEdgeAdminView || isEdgeUser || isNormalUserView) && <CalendarLinking />;
      case 'accountLevelSettings':
        return <AccountLevelSettings />;
      case 'roomManagement':
        return isEdgeUser && <RoomManagement />;
    }
  };

  return (
    <HelmetWrapper title="CORDS - Calendar Settings">
      <ContentLayout className={styles.container}>
        <div className={styles.header}>
          <div className={styles.backBtn} onClick={backBtn}>
            <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
            To Calendar
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.submenuContainer}>
            {subMenu
              .filter((obj) => obj.pathName)
              .map((menu, index) =>
                renderSubMenuItem(index, {
                  ...menu,
                  active: tab === menu.pathName,
                  pathLink: `${CALENDAR.SETTINGS}/${menu.pathName}`
                })
              )}
          </div>
          <div className={styles.content}>{renderContent()}</div>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default CalendarSettings;
