import { useState } from 'react';
import styles from './SendConsentFormsModal.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import moment from 'moment';
import { GroupConsentForm, GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import AddNote from 'components/AddNote/AddNote';
import { notification } from 'antd';
import SelectConsentForms from './SelectConsentForms/SelectConsentForms';

interface SendConsentFormsModalProps {
  groupId: string;
  showModal: boolean;
  handleCancel: () => void;
}

const SendConsentFormsModal = ({ groupId, showModal, handleCancel }: SendConsentFormsModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formCompleted, setFormCompleted] = useState(false);
  const [selectedConsentForm, setSelectedConsentForm] = useState<GroupConsentForm | undefined>();
  const [note, setNote] = useState('');
  const [customSendAt, setCustomSendAt] = useState(moment().add(1, 'hour').minute(0).format('YYYY-MM-DD hh:mmA Z'));
  const [sendAt, setSendAt] = useState('now');
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [selectedGroup, setSelectedGroup] = useState<GroupsFromAPI>();

  const onCancel = (e?: MouseEvent) => {
    e?.preventDefault();
    setCustomSendAt(moment().add(1, 'hour').minute(0).format('YYYY-MM-DD hh:mmA Z'));
    setNote('');
    setSendAt('now');
    setCurrentStep(1);
    setSubmitStatus('');
    setSelectedConsentForm(undefined);
    setFormCompleted(false);
    handleCancel();
  };

  const onSelectGroup = (groupData: GroupsFromAPI) => {
    setSelectedConsentForm(undefined);
    setSelectedGroup(groupData);
  };

  const handleSubmit = () => {
    setSubmitStatus('active');
    let sendAtDateTime = sendAt;
    const now = moment().format('HH:mm');
    if (sendAt === '9') {
      if (now > '09:00') {
        sendAtDateTime = moment().add('1', 'day').hour(9).minute(0).format('YYYY-MM-DD hh:mmA Z');
      } else {
        sendAtDateTime = moment().hour(9).minute(0).format('YYYY-MM-DD hh:mmA Z');
      }
    } else if (sendAt === '13') {
      if (now > '13:00') {
        sendAtDateTime = moment().add('1', 'day').hour(13).minute(0).format('YYYY-MM-DD hh:mmA Z');
      } else {
        sendAtDateTime = moment().hour(13).minute(0).format('YYYY-MM-DD hh:mmA Z');
      }
    } else if (sendAt === 'custom') {
      sendAtDateTime = customSendAt;
    }

    //TODO: Update payload generation
    const payload = {
      note,
      groupConsentForm: selectedConsentForm,
      sendAt: sendAtDateTime
    };

    try {
      // TODO: Call post
      console.log('Send Consent form payload: ', payload);
      notification.success({
        message: 'Consent forms sent!',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setFormCompleted(true);
      setSubmitStatus('finished');
      onCancel();
    } catch (ex) {
      setSubmitStatus('');
      notification.error({ message: 'Something went wrong while trying to send this consent form. Please try again' });
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title={'Send Consent Forms'}
      totalStep={2}
      currentStep={currentStep}
      isCompleted={formCompleted}
      onModalClose={onCancel}
    >
      <div className={styles.container}>
        {/* Step 1 */}
        <div className={styles.selectGroup}>
          <SelectClientOrGroup
            enableGroupSelection
            defaultParticipationType={ParticipantType.Group}
            defaultGroupId={groupId}
            handleSelectGroup={onSelectGroup}
          />
        </div>

        {/* Step 2 */}
        <SelectConsentForms
          className={currentStep === 1 ? styles.active : styles.inactive}
          selectedGroup={selectedGroup}
          onNextClick={() => setCurrentStep(currentStep + 1)}
          onSelectConsentForm={setSelectedConsentForm}
          selectedConsentForm={selectedConsentForm}
        />
        {/* Step 3 */}
        <AddNote
          selectedConsentForm={selectedConsentForm}
          participationType={ParticipantType.Group}
          onBackClick={() => setCurrentStep(currentStep - 1)}
          onCustomSendAtChange={setCustomSendAt}
          onNoteChanged={setNote}
          onSendAtChange={setSendAt}
          customSendAt={customSendAt}
          sendAt={sendAt}
          className={currentStep === 2 ? styles.active : styles.inactive}
          selectedGroup={selectedGroup}
          onSubmit={handleSubmit}
          submitStatus={submitStatus}
        />
      </div>
    </ModalV2>
  );
};

export default SendConsentFormsModal;
