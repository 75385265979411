import { useFetchPractitionerList } from 'components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetAccessToken } from 'utils/hooks/token';

import { useFetchMedicareLocations, useFetchMedicareProviders } from '../../hooks/getMedicareData';
import MedicareLocation from './components/MedicareLocation/MedicareLocation';
import MedicareProviderListing from './components/MedicareProviderListing/MedicareProviderListing';
import styles from './MedicareDetails.module.scss';

const MedicareDetails = () => {
  const { token } = useGetAccessToken();
  const {
    locations,
    isLocationsLoading,
    refetchMedicareLocations,
    refetchMedicareLocationsSilently
  } = useFetchMedicareLocations(token);
  const { providers, isProvidersLoading } = useFetchMedicareProviders(token);
  const { practitionersList } = useFetchPractitionerList(token, false, [AccessRight.Admin, AccessRight.User]);

  const massagedProviders = providers.map((provider) => {
    const foundLocation = locations.find(({ minorId }) => minorId === provider.locationMinorId);
    const foundPractitioner = practitionersList.find(({ _id }) => _id === provider.clinicianId);
    return {
      ...provider,
      locationActive: foundLocation?.active ?? false,
      clinicianName: foundPractitioner?.name,
      clinicianRole: foundPractitioner?.medicare?.role
    };
  });

  return (
    <div className={styles.container}>
      <MedicareLocation
        locations={locations}
        isLoading={isLocationsLoading}
        refetchMedicareLocations={refetchMedicareLocations}
        refetchMedicareLocationsSilently={refetchMedicareLocationsSilently}
      />
      <MedicareProviderListing providers={massagedProviders} isLoading={isProvidersLoading} />
    </div>
  );
};

export default MedicareDetails;
