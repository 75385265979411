import styles from './InterventionMonthActivityFeed.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { InterventionActivity } from '../InterventionActivity';
import InterventionActivityItem from './InterventionActivityItem/InterventionActivityItem';

interface InterventionMonthActivityFeedProps {
  items: InterventionActivity[];
  monthString: string;
  profile: ProfileInterface;
}

const InterventionMonthActivityFeed = ({ items, monthString, profile }: InterventionMonthActivityFeedProps) => {
  return (
    <div className={styles.monthGroup}>
      <div className={styles.monthGroupTitle}>{monthString}</div>
      <div className={styles.monthGroupActivityItems}>
        {items.map((item, index) => {
          return (
            <div key={index} className={styles.activityContainer}>
              <InterventionActivityItem item={item} profile={profile} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InterventionMonthActivityFeed;
