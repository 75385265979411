import classNames from 'classnames';
import Badge from 'components/Badge/Badge';

import styles from './AppointmentTagsBadge.module.scss';

interface AppointmentTagsBadgeProps {
  label: string;
  className: string;
}

const AppointmentTagsBadge = ({ label, className }: AppointmentTagsBadgeProps) => {
  return <Badge className={classNames(styles.badge, className)} label={label} />;
};

export default AppointmentTagsBadge;
