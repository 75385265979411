import { useGetAccessToken } from 'utils/hooks/token';
import { clientRecordsInterface, ClientRecordType, RecordStatus } from 'interfaces/Clients/clientsRecord';
import { useFetchAssessmentResponse, useFetchReportList, useFetchSurveyList } from './hooks/actions';

import PatientDetailsInsights from './components/PatientDetailsInsights/PatientDetailsInsights';
import PatientDetailsInvoices from './components/PatientDetailsInvoices/PatientDetailsInvoices';
import PatientDetailsReports from './components/PatientDetailsReports/PatientDetailsReports';
import PatientDetailsAppointments from './components/PatientDetailsAppointments/PatientDetailsAppointments';
import PatientDetailsNotes from './components/PatientDetailsNotes/PatientDetailsNotes';
import PatientDetailsAttachments from './components/PatientDetailsAttachments/PatientDetailsAttachments';
import PatientAssignment from './components/PatientAssignment/PatientAssignment';
import PatientDetailsActivity from './components/PatientDetailsActivity/PatientDetailsActivity';

import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import PatientDetailsProfile from './components/PatientDetailsProfile/PatientDetailsProfile';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './PatientDetailsContent.module.scss';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import PatientDetailsSurveysList from './components/PatientDetailsSurveysList/PatientDetailsSurveysList';
import PatientDetailsSurveys from './components/PatientDetailsSurveys/PatientDetailsSurveys';
import PatientDetailsReferrers from './components/PatientDetailsReferrers/PatientDetailsReferrers';
import PatientDetailsInsightsList from './components/PatientDetailsInsightsList/PatientDetailsInsightsList';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import PatientActionPlan from './components/PatientActionPlan/PatientActionPlan';
import AssessmentResult from './components/AssessmentResult/AssessmentResult';
import { useNavigate } from 'react-router-dom';

interface PatientDetailsContentProps {
  recordId: string;
  profileId: string;
  isClientLoading: boolean;
  clientRecordData: clientRecordsInterface;
  currentTab: string;
  assessmentOrReportOrPlanId?: string;
  refreshActivity?: number;
  allowCreateNewInvoice: boolean;
  fetchClientRecord: (token: string) => Promise<void>;
}

export interface Patient {
  _id: string;
  name: string;
  avatar: string;
  isReferredByGP: boolean;
  status: string;
  signupDate: string;
}

const PatientDetailsContent = ({
  recordId,
  profileId,
  currentTab,
  assessmentOrReportOrPlanId,
  refreshActivity,
  clientRecordData,
  isClientLoading,
  allowCreateNewInvoice,
  fetchClientRecord
}: PatientDetailsContentProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { assessmentResponse, isAssessmentListLoading } = useFetchAssessmentResponse(token, recordId);
  const { reportList, isReportListLoading } = useFetchReportList(token, recordId);
  const { surveyList, isSurveyListLoading } = useFetchSurveyList(token, recordId);
  const { profile, isProfileLoading } = useFetchProfile();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  const getContentComponents = () => {
    switch (currentTab) {
      case 'activity':
        return (
          <PatientDetailsActivity
            clientRecordData={clientRecordData}
            isClientRecordLoading={isClientLoading}
            profile={profile}
            isProfileLoading={isProfileLoading}
            refreshActivity={refreshActivity}
          />
        );
      case 'notes':
        return (
          <PatientDetailsNotes
            recordId={recordId}
            profileId={profileId}
            currentTab={currentTab}
            noteId={assessmentOrReportOrPlanId}
            clientRecordData={clientRecordData}
            token={token}
          />
        );
      case 'assessments':
        return (
          <AssessmentResult
            recordId={recordId}
            assessmentId={assessmentOrReportOrPlanId}
            assessmentList={assessmentResponse}
            isAssessmentListLoading={isAssessmentListLoading}
            clientRecord={clientRecordData}
          />
        );
      case 'checkIn':
        return (
          <PatientDetailsInsights
            clientRecordData={clientRecordData}
            profileId={assessmentOrReportOrPlanId || profileId}
            currentTab={currentTab}
            token={token}
          />
        );
      case 'report':
        return (
          <PatientDetailsReports
            recordId={recordId}
            currentTab={currentTab}
            reportId={assessmentOrReportOrPlanId}
            reportList={reportList}
            isLoadingReportList={isReportListLoading}
            clientRecordData={clientRecordData}
          />
        );
      case 'survey':
        return (
          <PatientDetailsSurveys
            recordId={recordId}
            currentTab={currentTab}
            surveyId={assessmentOrReportOrPlanId}
            surveyList={surveyList}
            isLoadingSurveyList={isSurveyListLoading}
            clientRecordData={clientRecordData}
          />
        );
      case 'appointments':
        return (
          <PatientDetailsAppointments
            recordId={recordId}
            token={token}
            profile={profile}
            clientRecordData={clientRecordData}
            isProfileLoading={isProfileLoading}
          />
        );
      case 'invoices':
        return (
          <PatientDetailsInvoices
            allowCreateNewInvoice={allowCreateNewInvoice && clientRecordData.recordStatus !== RecordStatus.Closed}
            recordId={recordId}
          />
        );
      case 'attachments':
        return (
          <PatientDetailsAttachments
            recordId={recordId}
            token={token}
            isReadOnly={clientRecordData.recordStatus === RecordStatus.Closed}
          />
        );
      case 'profile':
        return (
          <PatientDetailsProfile
            clientRecordData={clientRecordData}
            isClientRecordLoading={isClientLoading}
            recordId={recordId}
            fetchClientRecord={fetchClientRecord}
          />
        );
      case 'assignment':
        return (
          isEdgeAdminView && (
            <PatientAssignment
              clientRecordData={clientRecordData}
              isClientRecordLoading={isClientLoading}
              recordId={recordId}
            />
          )
        );
      case 'action-plans':
        return <PatientActionPlan actionPlanId={assessmentOrReportOrPlanId} recordId={recordId} />;
      case 'referrers':
        return <PatientDetailsReferrers recordId={recordId} />;
    }
  };

  let sideMenuList = [
    {
      id: 'profile',
      icon: 'manage_accounts',
      label: t('sidemenu.client.profile'),
      desc: t('sidemenu.client.profile.description'),
      isActive: false
    },
    {
      id: 'activity',
      icon: 'list',
      label: 'Activity',
      desc: 'Activity feed',
      isActive: false
    },
    !isEdgeReceptionist && {
      id: 'notes',
      icon: 'folder_open',
      label: t('sidemenu.client.notes'),
      desc: t('sidemenu.client.notes.description'),
      isActive: false
    },
    !isEdgeReceptionist && {
      id: 'attachments',
      icon: 'attach_file',
      label: t('sidemenu.client.attachments'),
      desc: t('sidemenu.client.attachments.description'),
      isActive: false
    },
    !isEdgeReceptionist && {
      id: 'survey',
      icon: 'grading',
      label: t('sidemenu.client.survey'),
      desc:
        currentTab === 'survey' ? (
          <PatientDetailsSurveysList
            recordId={recordId}
            currentTab={currentTab}
            assessmentOrReportId={assessmentOrReportOrPlanId}
            clientRecordData={clientRecordData}
            surveyList={surveyList}
            isLoading={isSurveyListLoading}
          />
        ) : (
          t('sidemenu.client.survey.description')
        ),
      isActive: false
    },
    !isEdgeReceptionist && {
      id: 'assessments',
      icon: 'assessment',
      label: 'Assessments',
      desc: 'Scores, trends and compare',
      isActive: false
    },
    !isEdgeReceptionist && {
      id: 'action-plans',
      icon: 'next_plan',
      label: 'Action Plans',
      desc: 'Review and create',
      isActive: false
    },
    !isEdgeReceptionist &&
      clientRecordData.isCompleteRecords && {
        id: 'checkIn',
        icon: 'fact_check',
        label: 'Check-in Insights',
        desc:
          currentTab === 'checkIn' ? (
            <PatientDetailsInsightsList
              clientRecordData={clientRecordData}
              isClientLoading={isClientLoading}
              clientProfileId={assessmentOrReportOrPlanId}
              currentTab={currentTab}
            />
          ) : (
            'Micro-journaling data'
          ),
        isActive: false
      },
    {
      id: 'appointments',
      icon: 'event',
      label: 'Appointments',
      desc: 'History and upcoming',
      isActive: false
    },
    !isEdgeReceptionist &&
      isEdgeAdminView && {
        id: 'assignment',
        icon: 'account_tree',
        label: t('sidemenu.client.practitioner_assignment'),
        desc: t('sidemenu.client.practitioner_assignment.description'),
        isActive: false
      },
    {
      id: 'referrers',
      icon: 'contacts',
      label: 'Referrers and Contacts',
      desc: 'Referral info and key contacts',
      isActive: false
    },
    medicareRebateFeatureToggle &&
      (clientRecordData.recordType === ClientRecordType.Adult ||
        clientRecordData.recordType === ClientRecordType.Child) && {
        id: 'medicare',
        icon: 'payment',
        label: 'Medicare',
        desc: 'Status and rebates',
        isActive: false
      }
  ];

  const mapUrlWithBtn = () => {
    return sideMenuList
      .filter(
        (
          obj
        ): obj is {
          id: string;
          icon: string;
          label: string;
          desc: string;
          isActive: boolean;
        } => !!obj
      )
      .map((obj) => {
        const newSideMenu = { ...obj };

        if (currentTab === obj.id) {
          newSideMenu.isActive = true;
        }

        return newSideMenu;
      });
  };

  const onClickSideMenu = (id: string) => {
    if (currentTab !== id) {
      fetchClientRecord(token);
      navigate(`${CLIENTS.BASE}/${recordId}/${id}`);
    }
  };

  const sideMenu = mapUrlWithBtn();

  return (
    <div className={styles.container}>
      <div className={styles.sideMenuContainer} id={'clientSideMenuContainerId'}>
        {sideMenu.map((obj: any, index: number) => (
          <div
            className={obj.isActive ? styles.menuCardActive : styles.menuCard}
            key={index}
            onClick={() => onClickSideMenu(obj.id)}
            id={obj.id}
          >
            <i className={`${obj.isActive ? 'material-icons' : 'material-icons-outlined'} ${styles.menuIcon}`}>
              {obj.icon}
            </i>
            <div className={styles.labelWrapper}>
              <div className={styles.label}>{obj.label}</div>
              {obj.isActive && <div className={styles.desc}>{obj.desc}</div>}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.contentContainer}>{getContentComponents()}</div>
    </div>
  );
};

export default PatientDetailsContent;
