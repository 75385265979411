import { ChangeEvent, useMemo } from 'react';
import { useField } from 'formik';

import { Question } from '../../../../../../AssessmentInterfaces';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import TextArea from 'components/TextArea/TextArea';
import CheckboxRadio from '../CheckboxRadio/CheckboxRadio';

interface SelectOtherProps {
  question: Question;
}

const OTHER_VALUE = 'other';

const SelectOther = ({ question }: SelectOtherProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField(`${question.id}.value`);
  const [
    { value: otherValue, onBlur: onOtherBlur },
    { error: otherError, touched: otherTouched },
    { setValue: setOtherValue }
  ] = useField(`${question.id}.otherValue`);

  const isOtherSelected = useMemo(() => {
    if (Array.isArray(value)) {
      return value.includes(OTHER_VALUE);
    } else {
      return value === OTHER_VALUE;
    }
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (question.maxSelection === 0) {
      if (Array.isArray(value)) {
        if (value.includes(e.target.value)) {
          setValue(value.filter((value: string) => value !== e.target.value));
        } else {
          setValue([...value, e.target.value]);
        }
      } else {
        setValue([e.target.value]);
      }
    } else {
      setValue(e.target.value);
    }
  };

  const handleOtherChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherValue(e.target.value);
  };

  return (
    <>
      <CheckboxRadio question={question} hasError={touched && !!error} value={value} onChange={handleChange} />
      <ErrorMessage error={error} visible={touched} />
      {isOtherSelected && (
        <>
          <TextArea
            label={'Tell me more'}
            name={`${question.id}.otherValue`}
            hasError={otherTouched && !!otherError}
            value={otherValue}
            onBlur={onOtherBlur}
            onChange={handleOtherChange}
            required
          />
          <ErrorMessage error={otherError} visible={otherTouched} />
        </>
      )}
    </>
  );
};

export default SelectOther;
