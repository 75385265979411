import Badge from 'components/Badge/Badge';
import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import AssessmentsHeader from 'pages/AssessmentDetails/components/AssessmentsHeader/AssessmentsHeader';
import { useRef, useState } from 'react';
import { AssignmentMode, CaseNoteTemplate, ParticipantType } from '../interface';
import styles from './CaseNoteTemplateEditor.module.scss';
import SubmitButton from 'components/v2/Button/Button';
import Radio from 'components/Radio/Radio';
import { PractitionersDetailsInterface, PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { Formik, FormikProps } from 'formik';
import CaseNoteFormatSettings from '../CaseNoteFormatSettings/CaseNoteFormatSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import * as yup from 'yup';
import CaseNoteHeadingsEditor from '../CaseNoteHeadingsEditor/CaseNoteHeadingsEditor';
import useCaseNoteTemplateSubmit, { usePathQuery } from '../hooks/useCaseNoteTemplateSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

const assignmentModeOptions = [
  { value: AssignmentMode.Practice, label: 'Just the practice account' },
  { value: AssignmentMode.All, label: 'All practitioners' },
  { value: AssignmentMode.Selected, label: 'Selected practitioners' }
];

const participantTypeOptions = [
  { value: ParticipantType.Individual, label: 'Individual client' },
  { value: ParticipantType.Group, label: 'Group' },
  { value: ParticipantType.Both, label: 'Both client and group' }
];

interface TemplateEditorProp {
  template: CaseNoteTemplate;
  practitionersListing: PractitionersListing;
}

const CaseNoteTemplateEditor = ({ template, practitionersListing }: TemplateEditorProp) => {
  const path = useParams() as { caseNoteTemplateId: string };
  const query = usePathQuery();
  const [t] = useTranslation();

  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const { createTemplate, updateTemplate } = useCaseNoteTemplateSubmit();
  const { practitionerList } = practitionersListing;

  const formikRef = useRef<FormikProps<CaseNoteTemplate>>(null);

  // status states --------------------------
  const [badgeStatus, setBadgeStatus] = useState<'editing' | 'saved'>('editing');
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const [saveAndPublishStatus, setSaveAndPublishStatus] = useState<'' | 'active' | 'finished'>('');

  // clinicians info list --------------------------
  const cliniciansLists = (type: 'added' | 'nonAdded') => {
    let list: PractitionersDetailsInterface[] = [];
    if (!formikRef.current) {
      // use selectedPractitioners from initial values
      practitionerList.forEach((practitioner) => {
        if (
          (type === 'added' && template.selectedPractitioners.includes(practitioner._id)) ||
          (type === 'nonAdded' && !template.selectedPractitioners.includes(practitioner._id))
        ) {
          list.push(practitioner);
        }
      });
      return list;
    }

    const { selectedPractitioners } = formikRef.current.values;
    practitionerList.forEach((practitioner) => {
      if (
        (type === 'added' && selectedPractitioners.includes(practitioner._id)) ||
        (type === 'nonAdded' && !selectedPractitioners.includes(practitioner._id))
      ) {
        list.push(practitioner);
      }
    });
    return list;
  };

  const addedPractitioners = () => cliniciansLists('added');
  const nonAddedPractitioners = () => cliniciansLists('nonAdded');

  // selectedPractitioners handler -------------------------
  const onChangeSelectedPractitioners = (id: string, type: 'add' | 'remove') => {
    if (!formikRef.current) return;

    const { selectedPractitioners } = formikRef.current.values;
    const { setFieldValue } = formikRef.current;

    if (type === 'add' && !selectedPractitioners.includes(id)) {
      return setFieldValue('selectedPractitioners', [...selectedPractitioners, id]);
    } else if (type === 'remove' && selectedPractitioners.includes(id)) {
      return setFieldValue(
        'selectedPractitioners',
        selectedPractitioners.filter((item) => item !== id)
      );
    }
  };

  // headings checkings ----------------------------
  const isParenthesisMatching = (str: any) => {
    let stack = [];
    let open = {
      '{': '}'
    } as any;
    let closed = {
      '}': true
    } as any;

    for (let i = 0; i < str.length; i++) {
      let char = str[i];
      if (open[char]) {
        stack.push(char);
      } else if (closed[char]) {
        if (open[stack.pop()] !== char) return false;
      }
    }
    return stack.length === 0;
  };

  const checkBodyTemplate = (bodyValue: string) => {
    let bodyErrorCheck = '';
    if (bodyValue.length > 0) {
      if (!isParenthesisMatching(bodyValue)) {
        bodyErrorCheck = 'Curly brackets must be closed';
      } else {
        bodyErrorCheck = '';
      }
    } else {
      bodyErrorCheck = 'Template body cannot be blank';
    }

    return bodyErrorCheck;
  };

  // submit handlers -------------------------
  const handleSaveAndPublish = async () => {
    if (!formikRef.current) return;

    const { handleSubmit, setFieldValue, validateForm } = formikRef.current;

    const validate = await validateForm();
    if (Object.keys(validate).length === 0) {
      setFieldValue('isPublished', true);
    }

    handleSubmit();
  };

  const handleFinalSave = async (values: CaseNoteTemplate) => {
    setSaveStatus('active');
    if (values.isPublished) setSaveAndPublishStatus('active');

    try {
      const queryId = query.get('id');
      if (path.caseNoteTemplateId === 'new' && (!queryId || typeof queryId !== 'string')) {
        const id = await createTemplate(values);
        navigate(`${FORMS.BASE}/caseNotes/new?id=${id}`);
      } else if (path.caseNoteTemplateId === 'new' && queryId && typeof queryId === 'string') {
        await updateTemplate(queryId, values);
      } else {
        await updateTemplate(path.caseNoteTemplateId, values);
      }
      setSaveStatus('finished');

      notification.success({
        message: 'Template saved.',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        onClose: () => {
          setSaveStatus('');
          setSaveAndPublishStatus('');
          setBadgeStatus('saved');
        }
      });
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to save your template.' });
      setSaveStatus('');
      setSaveAndPublishStatus('');
    }
  };

  // form validator schema ----------------------
  const editorSchema = yup.object({
    name: yup.string().required('Template name cannot be blank'),
    description: yup.string(),
    isPublished: yup.boolean().required(),
    // isDefault: yup.boolean().required(), // isDefault is not editable from the template form
    participantType: yup.string().oneOf(
      participantTypeOptions.map((opt) => opt.value),
      'Please select one of the available options'
    ),
    assignmentMode: yup.string().oneOf(
      assignmentModeOptions.map((opt) => opt.value),
      'Please select one of the available options'
    ),
    selectedPractitioners: yup.array().when('assignmentMode', {
      is: AssignmentMode.Selected,
      then: yup.array().min(1, 'Please select at least one practitioner')
    }),
    fields: yup.array(),
    headings: yup
      .string()
      .required('Template body cannot be blank')
      .test('checkBodyTemplate', 'Invalid template body', function (value) {
        if (typeof value !== 'string') return false;
        const bodyValue = value.replace(/<(?!img)(.|\n)*?>/g, '').trim();

        if (bodyValue.length <= 0 || !isParenthesisMatching(value)) {
          const errorText = checkBodyTemplate(bodyValue);
          return this.createError({ message: errorText });
        } else {
          return true;
        }
      })
  });

  return (
    <div>
      <AssessmentsHeader
        title={'Case Notes Template Editor'}
        saveBtn={
          <SubmitButton type="button" status={saveAndPublishStatus} onClick={() => handleSaveAndPublish()}>
            Save and Publish
          </SubmitButton>
        }
        backText={'To Forms Hub'}
        hideVideo
      />
      <div className={styles.container}>
        <div className={styles.badgeWrapper}>
          <Badge
            label={path.caseNoteTemplateId !== 'new' ? 'SAVED' : badgeStatus.toUpperCase()}
            className={path.caseNoteTemplateId !== 'new' || badgeStatus === 'saved' ? styles.newBadge : styles.badge}
          />
        </div>

        <Formik
          innerRef={formikRef}
          validationSchema={editorSchema}
          initialValues={template}
          onSubmit={(values) => handleFinalSave(values)}
        >
          {({ values, handleChange, errors, handleBlur, setFieldValue, touched, handleSubmit }) => (
            <div className={styles.content}>
              <div className={styles.leftWrapper}>
                <div className={styles.titleBox}>
                  <MaterialInput
                    id={'caseNotesTemplateName'}
                    label={'Name of the template *'}
                    name={'name'}
                    className={styles.titleInput}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <MaterialToggleSwitch
                    name={'published'}
                    label={'Published'}
                    value={values.isPublished}
                    customContainerClass={styles.toggleSwitchContainer}
                    onChange={() => setFieldValue('isPublished', !values.isPublished)}
                  />
                </div>
                {errors.name && touched.name && <div className={styles.error}>{errors.name}</div>}
                {errors.isPublished && touched.isPublished && <div className={styles.error}>{errors.isPublished}</div>}

                <div className={styles.descBox}>
                  <MaterialInput
                    id={'desc'}
                    name={'description'}
                    label={'Short description of this Case Note Template'}
                    className={styles.descInput}
                    value={values.description}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className={styles.title}>Assign template to</div>
                <p className={styles.subTitle}>Set who can use this template to who</p>
                {errors.participantType && touched.name && <div className={styles.error}>{errors.participantType}</div>}
                <Radio
                  name={'participantType'}
                  options={participantTypeOptions}
                  vertical
                  value={values.participantType}
                  onChange={handleChange}
                />

                <div style={{ padding: '10px 0' }} />

                <div className={styles.title}>Who can use this template?</div>
                <p className={styles.subTitle}>Set who in your team can use this template</p>
                {errors.assignmentMode && touched.assignmentMode && (
                  <div className={styles.error}>{errors.assignmentMode}</div>
                )}
                <Radio
                  name={'assignmentMode'}
                  options={assignmentModeOptions}
                  vertical
                  value={values.assignmentMode}
                  onChange={handleChange}
                />

                {values.assignmentMode === AssignmentMode.Selected && (
                  <>
                    {errors.selectedPractitioners && touched.selectedPractitioners && (
                      <div className={styles.error}>{errors.selectedPractitioners}</div>
                    )}
                    <div className={styles.list}>
                      <div className={styles.subHeader}>Select who to assign</div>
                      {nonAddedPractitioners().map(({ _id, name, avatar }) => (
                        <div className={styles.clientListCard} key={_id} onClick={() => {}}>
                          <div className={styles.clientList}>
                            <div className={styles.clientListWrapper}>
                              <div className={styles.avatarWrapper}>
                                <ClientProfileAvatar avatarUrl={avatar} />
                              </div>
                            </div>
                            <div className={styles.name}>
                              {name}
                              <div
                                className={styles.addBtnName}
                                onClick={() => onChangeSelectedPractitioners(_id, 'add')}
                              >
                                +Add
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className={styles.list}>
                      <div className={styles.subHeader}>
                        {addedPractitioners().length} clinician
                        {addedPractitioners().length > 1 && 's'} added
                      </div>
                      {addedPractitioners().map(({ _id, name, avatar }) => (
                        <div className={styles.clientListCard} key={_id} onClick={() => {}}>
                          <div className={styles.clientList}>
                            <div className={styles.clientListWrapper}>
                              <div className={styles.avatarWrapper}>
                                <ClientProfileAvatar avatarUrl={avatar} />
                              </div>
                            </div>
                            <div className={styles.name}>
                              {name}
                              <div
                                className={styles.removeBtnName}
                                onClick={() => onChangeSelectedPractitioners(_id, 'remove')}
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className={styles.rightWrapper}>
                <div className={styles.sectionHeading}>
                  {t('client_case_notes.add_specific_fields')}
                  <HelpOutLineWithTooltips
                    id="add-specific-fields"
                    desc={t('client_case_notes.add_specific_fields.description')}
                  />
                </div>
                <div className={styles.description}>
                  Highlight the <FontAwesomeIcon className={styles.starIcon} icon={faAsterisk} /> to make mandatory, and
                  use <i className={`material-icons-outlined ${styles.icon}`}>edit</i> to edit field entry requirements
                </div>
                <CaseNoteFormatSettings />

                <div style={{ padding: '10px 0' }} />

                <div className={styles.sectionHeading}>
                  Create Headings to guide observation data
                  <HelpOutLineWithTooltips id="template-headings" desc={'Create Headings to guide observation data'} />
                </div>
                <CaseNoteHeadingsEditor name={'headings'} />
                {errors.headings && touched.headings && <div className={styles.error}>{errors.headings}</div>}

                <div className={styles.buttonWrapper}>
                  <SubmitButton
                    status={saveStatus}
                    onClick={() => handleSubmit()}
                    className={styles.saveBtnFixed}
                    type="submit"
                  >
                    Save
                  </SubmitButton>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CaseNoteTemplateEditor;
