import { MicroCheckInAssessmentStruct } from 'interfaces/firstAssessment';
import { useEffect, useRef, useState } from 'react';

import styles from './ProgramMenu.module.scss';

export interface QuestionListWithCheckBoxProps {
  value: string | null;
  data: MicroCheckInAssessmentStruct[];
  clientProfileId: string;
  clientRecordId: string;
  onSelectQuestionSet: (recordId: string, profileId: string, questionId?: string) => void;
}

const ProgramMenu = ({
  value,
  data,
  clientProfileId,
  clientRecordId,
  onSelectQuestionSet
}: QuestionListWithCheckBoxProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [questionSetName, setQuestionSetName] = useState(value);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    openList(false);
  };

  const fetchQuestionName = async () => {
    const findDisplayName = data.find((x) => x.id === value);

    setQuestionSetName(findDisplayName ? findDisplayName.name : 'None');
  };

  useEffect(() => {
    fetchQuestionName();

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openList = (val: boolean) => {
    setShowCategoryList(val);
  };

  const selectQuestionSet = (qId: string) => {
    setShowCategoryList(false);

    const findDisplayName = data.find((x) => x.id === qId.toString());

    if (findDisplayName) {
      setQuestionSetName(findDisplayName.name);
      onSelectQuestionSet(clientRecordId, clientProfileId, findDisplayName.id);
    } else if (qId === '') {
      setQuestionSetName('None');
      onSelectQuestionSet(clientRecordId, clientProfileId, undefined);
    }
  };

  return (
    <div ref={node} className={styles.materialSelect}>
      <div className={styles.valueWrapper} onClick={() => openList(!showCategoryList)}>
        {questionSetName && questionSetName.length === 0 ? (
          <div>Please select topic</div>
        ) : (
          <div className={styles.valueBox}>{questionSetName}</div>
        )}
        <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
      </div>
      <div className={showCategoryList ? styles.listWrapperShow : styles.listWrapperHide}>
        <div className={styles.listBox} onClick={() => selectQuestionSet('')}>
          None
        </div>
        {data.map((obj: any, index: number) => {
          return (
            questionSetName !== obj.name && (
              <div className={styles.listBox} key={index} onClick={() => selectQuestionSet(obj.id)}>
                {obj.name}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ProgramMenu;
