import { useEffect, useState } from 'react';
import { CaseNote } from 'interfaces/caseNote';
import {
  getCaseNoteFormatSettingsByRecordId,
  getCaseNoteByCaseNoteId,
  getCaseNoteHeadingTemplates,
  getCaseNotesByRecordId
} from 'utils/http/DocumentService/CaseNotes/caseNotes';
import { notification } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { HeadingTemplate } from '../../../../../../../components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';
import { CaseNoteFormatSettings } from '../PatientDetailsNotes';
import { useTranslation } from 'react-i18next';

export const useFetchCaseNotes = (token: string, recordId: string) => {
  const [caseNotes, setCaseNotes] = useState<CaseNote[]>([]);
  const [isCaseNotesLoading, setIsCaseNotesLoading] = useState(true);
  const [t] = useTranslation();

  const fetchCaseNotes = async (token: string) => {
    try {
      const callGetCaseNotes = await getCaseNotesByRecordId(token, recordId);
      const caseNotes = await callGetCaseNotes.json();

      setCaseNotes(caseNotes);
    } catch (ex) {
      notification.error({ message: t('form.error.fetch_client_case_notes') });
    }

    setIsCaseNotesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCaseNotes(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { caseNotes, isCaseNotesLoading, setCaseNotes, fetchCaseNotes };
};

export const useFetchCaseNote = (recordId: string, noteId?: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const [caseNote, setCaseNote] = useState<CaseNote>();
  const [isCaseNoteLoading, setIsCaseNoteLoading] = useState(true);

  const fetchCaseNote = async (noteId?: string) => {
    setIsCaseNoteLoading(true);

    if (!noteId) {
      setCaseNote(undefined);
    } else if (noteId !== 'new') {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });
        const callGetCaseNote = await getCaseNoteByCaseNoteId(token, recordId, noteId);
        const caseNote = await callGetCaseNote.json();
        setIsCaseNoteLoading(false);
        setCaseNote(caseNote);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get this note' });
        setIsCaseNoteLoading(false);
        setCaseNote(undefined);
      }
    }

    setIsCaseNoteLoading(false);
  };

  useEffect(() => {
    fetchCaseNote(noteId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { caseNote, isCaseNoteLoading, setCaseNote, fetchCaseNote };
};

export const useFetchCaseNoteFormatSettingsByRecordId = (token: string, recordId: string) => {
  const [cNFormatSettingsDetails, setCNFormatSettingsDetails] = useState<CaseNoteFormatSettings>();
  const [isCNFormatSettingsLoading, setIsCNFormatSettingsLoading] = useState(true);
  const [t] = useTranslation();

  const fetchCaseNoteFormatSettings = async (token: string) => {
    try {
      const getCaseNoteFormatSettings = await getCaseNoteFormatSettingsByRecordId(token, recordId);
      if (getCaseNoteFormatSettings.statusCode === 200) {
        const caseNoteFormatSettings = await getCaseNoteFormatSettings.json();
        setCNFormatSettingsDetails(caseNoteFormatSettings);
      } else {
        setCNFormatSettingsDetails(undefined);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: t('form.error.fetch_client_case_note_format')
      });
    }

    setIsCNFormatSettingsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCaseNoteFormatSettings(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    cNFormatSettingsDetails,
    isCNFormatSettingsLoading,
    setCNFormatSettingsDetails,
    fetchCaseNoteFormatSettings
  };
};

export const useFetchCaseNoteHeadingTemplates = (token: string) => {
  const [caseNoteHeadingTemplates, setCaseNoteHeadingTemplates] = useState<HeadingTemplate[]>([]);
  const [areCaseNoteHeadingTemplatesLoading, setAreCaseNoteHeadingTemplatesLoading] = useState(false);

  const fetchCaseNoteHeadingTemplates = async (token: string) => {
    try {
      const callGetCaseNoteHeadingTemplates = await getCaseNoteHeadingTemplates(token);
      const caseNoteHeadingTemplates = await callGetCaseNoteHeadingTemplates.json();

      setCaseNoteHeadingTemplates(caseNoteHeadingTemplates);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your case note heading templates'
      });
    }

    setAreCaseNoteHeadingTemplatesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCaseNoteHeadingTemplates(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { caseNoteHeadingTemplates, areCaseNoteHeadingTemplatesLoading, setCaseNoteHeadingTemplates };
};
