import { useEffect, useState } from 'react';
import styles from './DetailsModal.module.scss';
import { notification } from 'antd';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import Radio from 'components/Radio/Radio';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import TeacherList from './components/TeacherList/TeacherList';
import { PractitionersDetailsWithSelected } from 'interfaces/Practitioners/practitionersListing';
import { putInterventionLibrary } from 'utils/http/ClinicianProfileService/Interventions/interventionLibrary';
import { InterventionLibraryDeliveryMode, InterventionLibraryInterface } from '../../interfaces/InterventionLibrary';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ModalV2 from 'components/ModalV2/ModalV2';
import Select from 'components/Select/CommonSelect/Select';

interface DetailsModalProps {
  practitionerList: PractitionersDetailsWithSelected[];
  isPractitionersListLoading: boolean;
  interventionLibraryItem: InterventionLibraryInterface;
  visible: boolean;
  onClose: () => void;
  onSaveChanges: () => void;
}

const DetailsModal = ({
  visible,
  onClose,
  onSaveChanges,
  interventionLibraryItem,
  practitionerList,
  isPractitionersListLoading
}: DetailsModalProps) => {
  const { token } = useGetAccessToken();
  const [selectedList, setSelectedList] = useState([] as PractitionersDetailsWithSelected[]);

  const [deliveryOption, setDeliveryOption] = useState<InterventionLibraryDeliveryMode>(
    interventionLibraryItem.deliveryMode || InterventionLibraryDeliveryMode.None
  );
  const [error, setError] = useState({
    internal: false,
    external: false
  });
  const [newExternal, setNewExternal] = useState<string>();
  const [externalParty, setExternalParty] = useState<string[]>(
    interventionLibraryItem.externalParty ? interventionLibraryItem.externalParty : []
  );
  const [btnStatus, setBtnStatus] = useState<ButtonStatusType>('');
  const [editIndex, setEditIndex] = useState<number>();
  const [isAdd, setIsAdd] = useState<boolean>(false);

  useEffect(() => {
    if (!isPractitionersListLoading && practitionerList) {
      if (interventionLibraryItem.clinicianAuth0Ids && interventionLibraryItem.clinicianAuth0Ids.length > 0) {
        setSelectedList(
          interventionLibraryItem.clinicianAuth0Ids
            .map((id) => practitionerList.find((i) => i._id === id)!)
            .filter((i) => i)
        );
      }
    }
  }, [isPractitionersListLoading, practitionerList, interventionLibraryItem]);

  const handleChangeSchoolDeliverability = (value: string) => {
    setDeliveryOption(
      value === 'no' ? InterventionLibraryDeliveryMode.None : InterventionLibraryDeliveryMode.WholeOfSchool
    );
  };

  const handleChangeDeliveryMode = (deliveryMode: InterventionLibraryDeliveryMode) => {
    setDeliveryOption(deliveryMode);
    resetField(deliveryMode);
  };

  const handleSelectTeacher = (selectedList: PractitionersDetailsWithSelected[]) => {
    const newError = { ...error };
    newError.internal = !(selectedList.length > 0);
    setError(newError);
    setSelectedList(selectedList);
  };

  const handleAddNewExternalParty = () => {
    setIsAdd(false);

    if (!newExternal) {
      return;
    }

    const newExternals = [...externalParty, newExternal];
    setExternalParty(newExternals);
    setNewExternal('');

    const newError = { ...error };
    newError.external = !(newExternals.length && newExternals.every((item) => item));
    setError(newError);
  };

  const handleEditExternalParty = (index: number) => {
    setEditIndex(undefined);

    if (!externalParty[index]) {
      const newExternals = [...externalParty];
      newExternals.splice(index, 1);
      setExternalParty([...newExternals]);

      const newError = { ...error };
      newError.external = !(newExternals.length && newExternals.every((item) => item));
      setError(newError);
    }
  };

  const resetField = (deliveryMode: InterventionLibraryDeliveryMode) => {
    const newError = { ...error };

    if (
      deliveryMode !== InterventionLibraryDeliveryMode.Internal &&
      deliveryMode !== InterventionLibraryDeliveryMode.InternalAndExternal
    ) {
      setSelectedList(
        interventionLibraryItem.clinicianAuth0Ids
          ?.map((id: string) => practitionerList.find((i) => i._id === id))
          .filter((practitioner): practitioner is PractitionersDetailsWithSelected => !!practitioner) || []
      );
      error.internal = false;
    }

    if (
      deliveryMode !== InterventionLibraryDeliveryMode.External &&
      deliveryMode !== InterventionLibraryDeliveryMode.InternalAndExternal
    ) {
      setExternalParty(interventionLibraryItem.externalParty ? interventionLibraryItem.externalParty : []);
      error.external = false;
    }

    setError(newError);
  };

  const checkValidation = () => {
    const newError = { ...error };
    if (
      deliveryOption === InterventionLibraryDeliveryMode.Internal ||
      deliveryOption === InterventionLibraryDeliveryMode.InternalAndExternal
    ) {
      newError.internal = !(selectedList.length > 0);
    }
    if (
      deliveryOption === InterventionLibraryDeliveryMode.External ||
      deliveryOption === InterventionLibraryDeliveryMode.InternalAndExternal
    ) {
      newError.external = !(externalParty.length && externalParty.every((item) => item));
    }
    setError(newError);

    return !Object.values(newError).some((value) => value);
  };

  const handleSubmit = async () => {
    if (!checkValidation()) {
      return;
    }

    setBtnStatus('active');

    try {
      const payload: {
        deliveryMode: InterventionLibraryDeliveryMode;
        clinicianAuth0Ids: string[];
        externalParty: string[];
      } = { deliveryMode: deliveryOption, clinicianAuth0Ids: [], externalParty: [] };

      switch (deliveryOption) {
        case InterventionLibraryDeliveryMode.Internal:
          payload.clinicianAuth0Ids = selectedList.map((i) => i._id);
          break;
        case InterventionLibraryDeliveryMode.External:
          payload.externalParty = externalParty;
          break;
        case InterventionLibraryDeliveryMode.InternalAndExternal:
          payload.clinicianAuth0Ids = selectedList.map((i) => i._id);
          payload.externalParty = externalParty;
          break;

        case InterventionLibraryDeliveryMode.None:
        case InterventionLibraryDeliveryMode.WholeOfSchool:
        default:
          break;
      }

      await putInterventionLibrary(token, interventionLibraryItem.id, payload);

      setBtnStatus('finished');
      notification.success({ message: 'Delivery type successfully changed!' });
      onSaveChanges?.();

      setTimeout(() => {
        setBtnStatus('');
      }, 1000);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong when trying to save changes' });
      setBtnStatus('');
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={
        <div className={styles.title}>
          <span>{interventionLibraryItem.name}</span>
          {interventionLibraryItem.url && (
            <a className={styles.trainingLink} href={interventionLibraryItem.url} target="_blank" rel="noreferrer">
              <div>{interventionLibraryItem.name} Training</div>
              <i className={`material-icons-outlined ${styles.icon}`}>arrow_forward</i>
            </a>
          )}
        </div>
      }
      onModalClose={onClose}
    >
      <div className={styles.contentContainer}>
        <div className={styles.detailSection}>
          <div className={styles.descTitle}>Description:</div>
          <div className={styles.desc} dangerouslySetInnerHTML={{ __html: interventionLibraryItem.description }} />
        </div>
        <div className={styles.selectionSection}>
          <div className={styles.deliveryContainer}>
            <div className={styles.deliveryTitle}>Can school deliver this?</div>
            <div className={styles.optionsWrapper}>
              <Radio
                labelClassName={styles.labelClassName}
                name={'deliveryType'}
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' }
                ]}
                value={deliveryOption === InterventionLibraryDeliveryMode.None ? 'no' : 'yes'}
                onChange={(e) => {
                  handleChangeSchoolDeliverability(e.target.value);
                  resetField(deliveryOption);
                }}
                highlightActive
                vertical
              />
            </div>
            {deliveryOption !== InterventionLibraryDeliveryMode.None && (
              <div className={styles.deliveryMode}>
                <div className={styles.deliveryTitle}>How does the school deliver this?</div>
                <Select
                  options={[
                    { label: 'Whole of school approach', value: InterventionLibraryDeliveryMode.WholeOfSchool },
                    { label: 'Internal staff', value: InterventionLibraryDeliveryMode.Internal },
                    { label: 'External providers', value: InterventionLibraryDeliveryMode.External },
                    {
                      label: 'Mix of internal and external',
                      value: InterventionLibraryDeliveryMode.InternalAndExternal
                    }
                  ]}
                  value={deliveryOption || ''}
                  placeholder={'Select delivery mode'}
                  onChange={(e) => {
                    handleChangeDeliveryMode(e?.value as InterventionLibraryDeliveryMode);
                  }}
                  isSearchable={false}
                  isClearable={false}
                  smallCaretDown
                  smallCaretDownClass={styles.smallCaretDownClass}
                  styles={{
                    container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
                    valueContainer: (base: any) => ({
                      ...base,
                      minHeight: '30px',
                      padding: '8px'
                    }),
                    control: (controlBase: any) => ({
                      ...controlBase,
                      minHeight: '54px',
                      backgroundColor: 'transparent',
                      border: `1px solid ${styles.greyColor}`,
                      borderRadius: '8px',
                      boxShadow: 'none'
                    }),
                    singleValue: (singleValueBase: any) => ({
                      ...singleValueBase,
                      fontSize: '14px',
                      lineHeight: '22px'
                    })
                  }}
                />
              </div>
            )}

            {(deliveryOption === InterventionLibraryDeliveryMode.Internal ||
              deliveryOption === InterventionLibraryDeliveryMode.InternalAndExternal) && (
              <>
                <div className={styles.grayTitle}>INTERNAL STAFF</div>
                <div className={styles.teacherListContainer}>
                  {isPractitionersListLoading ? (
                    <LoadingCircle />
                  ) : (
                    <TeacherList
                      list={practitionerList.map((i) => ({
                        ...i,
                        isSelected: !!selectedList.find((item) => item._id === i._id)
                      }))}
                      onChangeSelectedList={handleSelectTeacher}
                      selectedList={selectedList}
                    />
                  )}
                </div>
                <ErrorMessage
                  error="Please select at least one member of staff"
                  visible={error.internal && !selectedList.length}
                />
              </>
            )}
            {(deliveryOption === InterventionLibraryDeliveryMode.External ||
              deliveryOption === InterventionLibraryDeliveryMode.InternalAndExternal) && (
              <div className={styles.externalPartyInput}>
                <div className={styles.grayTitle}>EXTERNAL PROVIDERS</div>
                {externalParty.map((item, index) => (
                  <div className={styles.externalItem}>
                    {index !== editIndex && (
                      <>
                        <span>
                          {index + 1}. {item}
                        </span>
                        <div className={styles.button} onClick={() => setEditIndex(index)}>
                          {externalParty[index] ? 'Edit' : 'Remove'}
                        </div>
                      </>
                    )}
                    {index === editIndex && (
                      <>
                        <div className={styles.input}>
                          <MaterialInput
                            label={'External Provider'}
                            id="externalParty"
                            name="externalParty"
                            value={item}
                            onChange={(e) => {
                              const newData = [...externalParty];
                              newData[index] = e.target.value;
                              setExternalParty([...newData]);
                            }}
                          />
                        </div>
                        <div
                          className={styles.button}
                          onClick={() => {
                            handleEditExternalParty(index);
                          }}
                        >
                          Save
                        </div>
                      </>
                    )}
                  </div>
                ))}

                {isAdd && (
                  <div className={styles.addSection}>
                    <div className={styles.input}>
                      <MaterialInput
                        label={'External Provider'}
                        id="externalParty"
                        name="externalParty"
                        onChange={(e) => {
                          setNewExternal(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.button} onClick={handleAddNewExternalParty}>
                      <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                      Add
                    </div>
                  </div>
                )}
                {!isAdd && (
                  <div className={styles.button} onClick={() => setIsAdd(true)}>
                    <i className={`material-icons-outlined ${styles.icon}`}>add</i>
                    Add more providers
                  </div>
                )}

                <ErrorMessage error="Please add at least one external provider" visible={error.external} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.saveChanges}>
          <Button onClick={handleSubmit} status={btnStatus}>
            Save Changes
          </Button>
        </div>
      </div>
    </ModalV2>
  );
};

export default DetailsModal;
