import styles from './WidgetItemWrapper.module.scss';
import { useDrag } from 'react-dnd';
import { v4 as uuid } from 'uuid';

interface WidgetItemWrapperProps {
  data: any;
  widgetType: string;
  children: any;
}

const WidgetItemWrapper = ({ data, widgetType, children }: WidgetItemWrapperProps) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: 'insightsWidget',
    item: {
      id: uuid(),
      type: 'insightsWidget',
      fromList: true,
      dimensions: {
        height: 'auto',
        width: data.width || 300,
        minWidth: 200
      },
      details: {
        type: widgetType,
        ...data
      }
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  return (
    <div ref={dragRef} className={isDragging ? styles.draggingContainer : styles.container}>
      {children}
    </div>
  );
};

export default WidgetItemWrapper;
