import { BentResponse } from 'bent';
import { httpClient } from '../../httpClient';

const url = process.env.REACT_APP_PATIENT_PROFILE_SERVICE_URL;

if (!url) {
  throw new Error('Environment variables does not have REACT_APP_PATIENT_PROFILE_SERVICE_URL');
}

export const getCheckSignedUpProfiles = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}:checkSignedUpProfiles`
  ) as Promise<BentResponse>;
