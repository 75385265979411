export const formatAssessmentResponse = ({
  assessmentId,
  values
}: {
  assessmentId: string;
  values: { [key: string]: Record<string, any> };
}): {
  assessmentId: string;
  questionId: string;
  response: {
    value: any;
    otherValue?: any;
  };
}[] =>
  Object.keys(values)
    .map((valueKey) => {
      if (valueKey in values) {
        const response = values[valueKey];

        return {
          assessmentId,
          questionId: valueKey,
          response: response
        };
      } else {
        return undefined;
      }
    })
    .filter(
      (
        response
      ): response is { assessmentId: string; questionId: string; response: { value: any; otherValue?: any } } =>
        !!response
    );
