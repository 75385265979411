import LoadingDot from 'components/LoadingDot/LoadingDot';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import Button from 'components/v2/Button/Button';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { AssessmentRole, ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useFetchClinician } from 'utils/hooks/clinician';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import styles from './CORDSAssessmentStudentSelect.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

interface Props {
  onSelect: () => void;
  selectedAssessment?: ClinicalAssessmentStruct;
  selectedGroup?: Groups;
  setSelectedGroup: (group: Groups) => void;
}

const CORDSAssessmentGroupSelect = ({ onSelect, selectedAssessment, selectedGroup, setSelectedGroup }: Props) => {
  const { auth0ClinicianId } = useGetClinicianId();
  const { clinician, isClinicianLoading } = useFetchClinician(auth0ClinicianId);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const assessmentRole = selectedAssessment?.metadata?.role;

  return isClinicianLoading ? (
    <LoadingDot />
  ) : (
    <div className={styles.wrapper}>
      <SelectClientOrGroup
        selectedClinician={isEdgeAdminView ? undefined : clinician}
        handleSelectGroup={(item) => setSelectedGroup((item as unknown) as Groups)}
        descriptionRender={(val) => val.class || '--'}
        enableGroupSelection
        defaultParticipationType={ParticipantType.Group}
        communicationPreference={
          assessmentRole !== AssessmentRole.Teacher
            ? [CommunicationPreference.NoPreference, CommunicationPreference.SMS].join(',')
            : ''
        }
        disableSelectionToggle
      />
      <div className={styles.footer}>
        <Button disabled={!selectedGroup} onClick={() => onSelect()}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CORDSAssessmentGroupSelect;
