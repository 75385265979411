import classNames from 'classnames';
import { useField } from 'formik';
import styles from './VideoInstructions.module.scss';

const VideoInstructions = () => {
  const [{ value }, telehealthInstructionsMeta, { setValue }] = useField('videoCallInstructions');
  return (
    <div className={classNames(styles.fieldContainer)}>
      <textarea
        id={'videoCallInstruction'}
        className={styles.textarea}
        rows={4}
        maxLength={320}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {telehealthInstructionsMeta.touched && (
        <div className={styles.fieldError}>{telehealthInstructionsMeta.error}</div>
      )}
    </div>
  );
};

export default VideoInstructions;
