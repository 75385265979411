import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useMemo, useState } from 'react';
import styles from './EditInterventionForm.module.scss';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { EditInterventionFormInterface } from '../../EditInterventionModalInterface';
import { validateInterventionId } from '../../../../../../../../../InterventionsList/components/CreateInterventionModal/components/InterventionForm/validation/InterventionFormValidation';
import { useFetchInterventionLibrary } from 'MHH/pages/InterventionLibrary/hooks/useFetchInterventionLibrary';
import Select from 'components/v2/Select/Select';
import { Skeleton } from 'antd';

export interface EditInterventionValidateField {
  name: string;
  interventionId: string;
  interventionLibraryItemId: string;
}

interface EditInterventionFormProps {
  selectedClinicianId: string;
  practitionerList: string[];
  interventionFormField: EditInterventionFormInterface;
  onChangeInterventionField: any;
  errorMessage: EditInterventionValidateField;
  accountId: string;
  excludeInterventionIdValue?: string;
}

const EditInterventionForm = ({
  selectedClinicianId,
  practitionerList,
  interventionFormField,
  onChangeInterventionField,
  errorMessage,
  accountId,
  excludeInterventionIdValue
}: EditInterventionFormProps) => {
  const { token } = useGetAccessToken();
  const [duplicateInterventionId, setDuplicateInterventionId] = useState(false);
  const [isCheckingInterventionIdDuplicate, setIsCheckingInterventionIdDuplicate] = useState(false);
  const { interventionLibrary, isInterventionLibraryLoading } = useFetchInterventionLibrary(token);

  const debouncedCheckInterventionIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateInterventionId(token, accountId, value);
        setDuplicateInterventionId(duplicate.statusCode !== 200);
        setIsCheckingInterventionIdDuplicate(false);
      }, 1000),
    [token, accountId]
  );

  const validateDuplicateInterventionId = async (idValue: string) => {
    if (excludeInterventionIdValue === idValue) {
      setDuplicateInterventionId(false);
      return;
    }
    if (token) {
      setIsCheckingInterventionIdDuplicate(true);
      await debouncedCheckInterventionIdDuplicate(idValue);
    }
  };

  const onSelectClinician = (selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      const newInterventionClinicianField = {
        ...interventionFormField,
        leadClinicianAuth0Id: selectedClinician._id
      };
      onChangeInterventionField(newInterventionClinicianField);
    }
  };

  const handleChangeFieldValue = async (key: string, val: string) => {
    const newInterventionField = {
      ...interventionFormField,
      [key]: val
    };
    if (key === 'interventionId' && val) {
      await validateDuplicateInterventionId(val);
    }
    onChangeInterventionField(newInterventionField);
  };

  return (
    <>
      <div className={classNames(styles.fieldContainer, duplicateInterventionId && styles.fieldError)}>
        <MaterialInput
          id={`interventionId-${interventionFormField.interventionId}`}
          label={`Intervention ID`}
          maxLength={20}
          isLoading={isCheckingInterventionIdDuplicate}
          onChange={(e) => handleChangeFieldValue('interventionId', e.target.value)}
          value={interventionFormField.interventionId}
          required
        />
        {duplicateInterventionId && <div className={styles.fieldError}>{'This intervention id is already in use'}</div>}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.leadLabel}>Intervention Library*</div>
        {isInterventionLibraryLoading ? (
          <Skeleton.Input active className={styles.inputSkeletonLoading} />
        ) : (
          <Select
            options={interventionLibrary.map((i) => ({ label: i.name, value: i.id }))}
            onChange={(value) => handleChangeFieldValue('interventionLibraryItemId', value?.value || '')}
            placeholder="Select Intervention Library"
            //@ts-ignore
            value={{
              value: interventionFormField.interventionLibraryItemId,
              label:
                interventionLibrary.find((i) => i.id === interventionFormField.interventionLibraryItemId)?.name || ''
            }}
          />
        )}
        {errorMessage.interventionLibraryItemId && (
          <div className={styles.fieldError}>{errorMessage.interventionLibraryItemId}</div>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.leadLabel}>Intervention Lead</div>
        <ClinicianSelect
          includePractice
          selectedId={selectedClinicianId}
          filterRoles={[AccessRight.Admin]}
          onSelect={(clinician) => onSelectClinician(clinician)}
          excludedClinicianIds={practitionerList}
          materialStyle
          hideAvatar
          noInitChange
        />
      </div>
      <div className={classNames(styles.fieldContainer, errorMessage.name && styles.fieldError)}>
        <MaterialInput
          id={`interventionName`}
          label={`Intervention Name*`}
          required
          value={interventionFormField.name}
          onChange={(e) => handleChangeFieldValue('name', e.target.value)}
        />
        {errorMessage.name && <div className={styles.fieldError}>{errorMessage.name}</div>}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Intervention Short Description</div>
        <textarea
          className={styles.textarea}
          value={interventionFormField.description}
          onChange={(e) => handleChangeFieldValue('description', e.target.value)}
        />
      </div>
    </>
  );
};

export default EditInterventionForm;
