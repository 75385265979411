import { notification } from 'antd';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import FormSection from 'components/v2/FormSection/FormSection';
import { CronofyProfile } from 'interfaces/Cronofy/cronofyProfile';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchCronofyProfiles } from 'utils/hooks/GetCronofyProfiles/cronofyCalendars';
import { useGetAccessToken } from 'utils/hooks/token';
import { postCronofyToken } from 'utils/http/ScheduleService/Cronofy/Cronofy';
import { v4 as uuidv4 } from 'uuid';

import styles from './CalendarLinking.module.scss';
import CalendarConnectionModal from './components/CalendarConnectionModal/CalendarConnectionModal';
import ConnectedProfiles from './components/ConnectedProfiles/ConnectedProfiles';

const CRONOFY_AUTH_STATE_LS_KEY = 'cronofy-calendar-link-auth';

const CalendarLinking = () => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { code, state } = queryString.parse(search);
  const { token } = useGetAccessToken();
  const {
    cronofyProfiles: cronofyProfilePayload,
    isCronofyProfilesLoading,
    fetchCronofyProfiles
  } = useFetchCronofyProfiles(token);
  const [connectionModalVisible, setConnectionModalVisible] = useState(false);
  const [newProfile, setNewProfile] = useState<CronofyProfile>();
  const [cronofyProfiles, setCronofyProfile] = useState<CronofyProfile[]>(cronofyProfilePayload);
  const [isNewProfileLoading, setIsNewProfileLoading] = useState(false);

  const fullUrlWithoutSearch = `${window.location.origin}${pathname}`;

  const callPostCronofyToken = async (code: string) => {
    try {
      setIsNewProfileLoading(true);
      const newCronofyProfile = await postCronofyToken(token, {
        code,
        redirectUri: fullUrlWithoutSearch,
        asAdmin: isEdgeAdminView
      });
      setNewProfile(await newCronofyProfile.json());
      navigate(pathname);
      setConnectionModalVisible(true);
      setIsNewProfileLoading(false);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to integrate your calendar.' });
    }
  };

  useEffect(() => {
    const randomizedState = localStorage.getItem(CRONOFY_AUTH_STATE_LS_KEY);

    if (code && state && token) {
      if (state !== randomizedState) {
        notification.error({
          message: `Provided 'state' value does not match originating 'state' value.`,
          duration: 2
        });
      } else {
        callPostCronofyToken(code as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state, token]);

  useEffect(() => {
    setCronofyProfile(cronofyProfilePayload);
  }, [cronofyProfilePayload]);

  const redirectToLinkCalendar = () => {
    const randomizedState = uuidv4();

    localStorage.setItem(CRONOFY_AUTH_STATE_LS_KEY, randomizedState);

    window.location.href = encodeURI(
      `${
        process.env.REACT_APP_CRONOFY_URL ?? 'https://app-uk.cronofy.com'
      }/oauth/authorize?response_type=code&client_id=${
        process.env.REACT_APP_CRONOFY_CLIENT_ID ?? '0Rp6DcLQFeNWnAUwMySDCzw37Bo0Fs41'
      }&redirect_uri=${fullUrlWithoutSearch}&scope=read_write&state=${randomizedState}`
    );
  };

  const onModalCancel = () => {
    fetchCronofyProfiles(token);
    setConnectionModalVisible(false);
    setNewProfile(undefined);
  };

  return (
    <FormSection title="Connect Calendars">
      <div className={styles.container}>
        {newProfile && (
          <CalendarConnectionModal
            cronofyProfile={newProfile}
            visible={connectionModalVisible}
            onCancel={() => onModalCancel()}
          />
        )}
        <div className={styles.calendarLinkingsContainer}>
          {isCronofyProfilesLoading || isNewProfileLoading ? (
            <div className={styles.loading}>
              <LoadingDot />
            </div>
          ) : (
            <>
              <ConnectedProfiles
                profiles={cronofyProfiles}
                saveButton={
                  <Button
                    variant="secondary"
                    icon="add_circle_outline"
                    onClick={() => redirectToLinkCalendar()}
                    className={styles.addButton}
                  >
                    {`Connect ${cronofyProfiles.length > 0 ? 'another' : 'a'} calendar`}
                  </Button>
                }
              />
            </>
          )}
        </div>
      </div>
    </FormSection>
  );
};

export default CalendarLinking;
