import { ActionPlanInterventionItem } from './components/ActionPlanForm/components/InterventionList/InterventionList';

export enum ActionPlanStatus {
  Active = 'active',
  Closed = 'closed'
}

export enum ActionPlanProgress {
  Ongoing = 'ongoing',
  OnTrack = 'onTrack',
  OnHold = 'onHold',
  Completed = 'completed'
}

export interface ActionPlanAttachment {
  title: string;
  attachmentId: string; // attachment._id
}

export interface ActionPlanTask {
  _id?: string;
  title: string;
  description: string;
  dueDate: string; // YYYY-MM-DD
  isCompleted: boolean;
  completedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ActionPlanTaskItem extends ActionPlanTask {
  isEdit?: boolean;
  isOld?: boolean;
  isView?: boolean;
  errorMessage?: { [key: string]: string };
}

export interface ActionPlanAssessment {
  id: string; // ClinicalAssessmentResponse.id
  clinicalAssessmentResponseId: string; // ClinicalAssessmentResponse._id
  submitterName?: string;
  createdAt: string;
}

export interface ActionPlanIntervention {
  _id?: string;
  interventionId?: string; // intervention group's _id
  noAction?: {
    reason: string;
    context?: string;
  };
  name: string;
  description: string;
  focusArea?: string;
  scoreScale?: string[];
  dueDate: string; // YYYY-MM-DD
  isCompleted: boolean;
  completedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ActionPlanInterface {
  _id?: string;
  accountId: string;
  clientRecordId: string;
  ownerAuth0Id: string; // assigned wellbeing team's Auth0 ID
  status: ActionPlanStatus;
  progress: ActionPlanProgress;
  title: string;
  description: string;
  dueDate: string; // YYYY-MM-DD
  attachments: ActionPlanAttachment[];
  tasks: ActionPlanTask[];
  attachedAssessments: ActionPlanAssessment[];
  interventions: ActionPlanIntervention[];
  updatedAt?: string;
  createdAt?: string;
}

export interface ActionPlanFormInterface extends Omit<ActionPlanInterface, 'accountId' | 'clientRecordId'> {
  tasks: ActionPlanTaskItem[];
  attachedAssessments: ActionPlanAssessment[];
  interventions: ActionPlanInterventionItem[];
}

export interface ActionPlanNote {
  clinicianAuth0Id: string;
  clientRecordId: string;
  accountId: string;
  actionPlanId: string;
  clinicianName: string;
  title: string;
  body: string;
  createdAt: Date;
  updatedAt: Date;
}
