import { useEffect, useState } from 'react';

import styles from './QuickNoteForm.module.scss';
import CheckBox from '../../../CheckBox/CheckBox';
import TextEditor from '../../../v2/TextEditor/TextEditor';
import OptionLabel from '../../../OptionLabel/OptionLabel';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { newProspectClientForm } from '../../AddProspectClientInterface';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import { useTranslation } from 'react-i18next';

interface QuickNoteFormProps {
  id: string;
  prospectQuickNote: newProspectClientForm['note'];
  checkValidation: boolean;
  onChangeQuiteNote: any;
}

const QuickNoteForm = ({ id, prospectQuickNote, checkValidation, onChangeQuiteNote }: QuickNoteFormProps) => {
  const [quiteNote, setQuiteNote] = useState(prospectQuickNote);
  const [errorMessage, setErrorMessage] = useState({
    title: false,
    body: false
  });

  useEffect(() => {
    if (checkValidation) {
      setErrorMessage({
        title: quiteNote.title.length < 1,
        body: quiteNote.body.replace(/<(?!img)(.|\n)*?>/g, '').trim().length < 1
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const onChangeField = (val: string) => {
    const newQuickNoteData = {
      ...quiteNote,
      body: val
    };
    setQuiteNote(newQuickNoteData);
    onChangeQuiteNote(newQuickNoteData);
    setErrorMessage({
      ...errorMessage,
      body: val.replace(/<(?!img)(.|\n)*?>/g, '').trim().length < 1
    });
  };

  const onChangeNoteTitle = (val: string) => {
    const newQuickNoteData = {
      ...quiteNote,
      title: val
    };
    setQuiteNote(newQuickNoteData);
    onChangeQuiteNote(newQuickNoteData);
    setErrorMessage({
      ...errorMessage,
      title: val.length < 1
    });
  };

  const onChangeIncludeNote = () => {
    const newQuickNoteData = {
      ...quiteNote,
      includeQuickNote: !quiteNote.includeQuickNote
    };
    setQuiteNote(newQuickNoteData);
    onChangeQuiteNote(newQuickNoteData);
  };

  const [t] = useTranslation();

  const includePrivateNote = quiteNote.includeQuickNote;
  return (
    <div className={includePrivateNote ? styles.highlightContainer : styles.container}>
      <div className={styles.checkBoxContainer}>
        <CheckBox
          id={'clientPrivateNoteCheck'}
          value={includePrivateNote}
          label={<OptionLabel title={t('checkbox.prospective_client_private_note')} />}
          onChange={() => onChangeIncludeNote()}
        />
      </div>
      <div className={includePrivateNote ? styles.noteContainer : styles.noteContainerHidden}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id={`noteTitle`}
            label={'Note Title'}
            onChange={(e) => onChangeNoteTitle(e.target.value)}
            value={quiteNote.title}
          />
          <ErrorMessage className={styles.error} error={'Note title cannot be blank'} visible={errorMessage.title} />
        </div>

        <TextEditor id={`clientPrivateNote-${id}`} value={quiteNote.body} onChange={(e) => onChangeField(e)} />
        <ErrorMessage
          className={styles.error}
          error={'Please have at least one character to create note'}
          visible={errorMessage.body}
        />
      </div>
    </div>
  );
};

export default QuickNoteForm;
