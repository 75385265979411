import { SendOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Button from 'components/v2/Button/Button';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import { NEW_ASSESSMENT_TEMPORARY_ID } from '../../../AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import styles from './AssessmentHeader.module.scss';
import { useNavigate } from 'react-router-dom';

export interface AssessmentHeaderProps {
  onSendAdhocAssessmentClick?: () => void;
}

const AssessmentHeader = ({ onSendAdhocAssessmentClick = () => {} }: AssessmentHeaderProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const [t] = useTranslation();

  const { viewCaseNotesTemplateFeature } = useGetFeatureToggle();

  const node = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const setOpen = (val: boolean) => {
    setShowMenu(val);
  };

  const onSelectNewFa = () => {
    navigate(`${FORMS.BASE}/survey/${NEW_ASSESSMENT_TEMPORARY_ID}`);
  };

  const onSelectNewCa = () => {
    navigate(`${FORMS.BASE}/microCheckIn/newMCI`);
  };

  const onHandleNewConsentForm = () => {
    navigate(`${FORMS.BASE}/consent/new`);
  };

  const onHandleNewCaseNoteTemplate = () => {
    navigate(`${FORMS.BASE}/caseNotes/new`);
  };

  const showOAc = isEdgeAdminView || isNormalUserView;
  const showMCIc = false;
  const showConsent = isEdgeAdminView || isNormalUserView;

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Forms</div>
        </div>
      </div>
      <Row gutter={16}>
        <Col>
          <div ref={node} className={styles.rightSection}>
            {isEdgeAdminView && (
              <div className={styles.addNewBtnWrapper} onClick={() => setOpen(!showMenu)} id={'createNewTemplateId'}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                Create new template
              </div>
            )}
            <div className={styles.menuWrapper}>
              <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                {showOAc && (
                  <div className={styles.listBox} onClick={onSelectNewFa}>
                    <div className={styles.listTitle}>{t('assessment_table.survey')}</div>
                    <div className={styles.listDesc}>{t('assessment_table.survey.description')}</div>
                  </div>
                )}
                {showMCIc && (
                  <div className={styles.listBox} onClick={onSelectNewCa}>
                    <div className={styles.listTitle}>{t('assessment_table.micro_checkin')}</div>
                    <div className={styles.listDesc}>{t('assessment_table.micro_checkin.description')}</div>
                  </div>
                )}
                {showConsent && (
                  <div className={styles.listBox} onClick={onHandleNewConsentForm}>
                    <div className={styles.listTitle}>{t('assessment_table.consent_form')}</div>
                    <div className={styles.listDesc}>{t('assessment_table.consent_form.description')}</div>
                  </div>
                )}
                {viewCaseNotesTemplateFeature && (
                  <div className={styles.listBox} onClick={onHandleNewCaseNoteTemplate}>
                    <div className={styles.listTitle}>Case Note</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
        {isEdgeAdminView && (
          <Col id={'sendAdhocBtnId'}>
            <Button className={styles.sendAdhocBtn} onClick={() => onSendAdhocAssessmentClick()}>
              <Row gutter={12} align="middle">
                <Col className={styles.sendAdhocBtnIcon}>
                  <SendOutlined />
                </Col>
                <Col>{t('assessment_table.send_client_ad_hoc_assessment')}</Col>
              </Row>
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AssessmentHeader;
