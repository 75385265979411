import ContentLayout from 'components/ContentLayout/ContentLayout';

import logo from 'assets/images/CORDS/CORDS-Logo.png';

import styles from './Header.module.scss';

const Header = () => (
  <div className={styles.container}>
    <ContentLayout whiteBg>
      <img alt={'CORDS logo'} className={styles.logo} src={logo} />
    </ContentLayout>
  </div>
);

export default Header;
