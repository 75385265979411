import { stringEnumValidator } from 'utils/helpers/enumValidator';

export enum ClinicianStatus {
  Active = 'active',
  Inactive = 'inactive'
}

interface WorkingSchedule {
  isActive: boolean;
  startTime: string;
  endTime: string;
}

export enum AccessRight {
  Admin = 'admin',
  User = 'user',
  Receptionist = 'receptionist',
  Mentor = 'mentor'
}

export const isValueAccessRight = stringEnumValidator(AccessRight);

export interface clinicianInterface {
  _id: string;
  accountId: string;
  avatar?: string;
  bio?: string;
  name: string;
  title: string;
  pronouns?: string;
  specialisations?: string[];
  email: string;
  mobileNumber: string;
  memberships?: string[];
  partOfPractice?: boolean;
  status?: ClinicianStatus;
  caseLoad?: {
    availableSlot: number;
  };
  workingSchedule?: {
    monday: WorkingSchedule;
    tuesday: WorkingSchedule;
    wednesday: WorkingSchedule;
    thursday: WorkingSchedule;
    friday: WorkingSchedule;
    saturday: WorkingSchedule;
    sunday: WorkingSchedule;
  };
  practices?: {
    name: string;
    mobileNumber: string;
    logo?: string;
    locations?: string[];
  }[];
  consultantLocations?: string[];
  customisations?: Record<string, any>;
}

export type MinifiedClinician = Pick<clinicianInterface, '_id' | 'avatar' | 'title' | 'name'>;

export interface PractitionersListMinifiedResponse {
  clinicians: MinifiedClinician[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}
