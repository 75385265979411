import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const postStage = (token: string, accountId: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/stages-configuration`,
    payload
  ) as Promise<BentResponse>;

export const patchStage = (
  token: string,
  accountId: string,
  groupId: string,
  stageId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/stages-configuration/${stageId}`,
    payload
  ) as Promise<BentResponse>;

export const patchSequentialSequence = (
  token: string,
  accountId: string,
  groupId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/stages-configuration/sequential`,
    payload
  ) as Promise<BentResponse>;

export const getGroupStageConfiguration = (token: string, groupId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/groups/${groupId}/stages-configuration`
  ) as Promise<BentResponse>;

export const deleteGroupStageByStageId = (
  token: string,
  accountId: string,
  groupId: string,
  stageId?: string,
  moveToStageId?: string
) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/stages-configuration/${stageId}?moveToStageId=${moveToStageId ?? ''}`
  ) as Promise<BentResponse>;
