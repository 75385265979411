export const isDevelopmentENV = () => {
  return process.env.REACT_APP_GAE_PROJECT_ID === 'radiance-284806';
};

export const isStagingENV = () => {
  return process.env.REACT_APP_GAE_PROJECT_ID === 'prime-284806';
};

export const isProdAUENV = () => {
  return process.env.REACT_APP_GAE_PROJECT_ID === 'luminance-284806';
};

export const isProdUKENV = () => {
  return process.env.REACT_APP_GAE_PROJECT_ID === 'zenith-284806';
};
