import { useState } from 'react';
import { Modal } from 'antd';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import TextEditor from 'components/v2/TextEditor/TextEditor';
import Button from 'components/v2/Button/Button';

import styles from './NoteModal.module.scss';

export interface Note {
  title: string;
  content: string;
}
interface SimpleNoteProps {
  disabled?: boolean;
  visible?: boolean;
  status: '' | 'active' | 'finished';
  onCreated: (note: Note) => void;
  onClose: () => void;
}

const NoteModal = ({ visible, disabled, status, onCreated, onClose }: SimpleNoteProps) => {
  const [errorMessage, setErrorMessage] = useState({ title: '' });
  const [note, setNote] = useState<Note>({ title: '', content: '' });

  const handleChangeValues = (key: keyof Note, value: string) => {
    setNote({ ...note, [key]: value });
    setErrorMessage({ ...errorMessage, [key]: !value && key === 'title' ? 'Title is required' : '' });
  };

  const saveNote = () => {
    if (!note.title) {
      setErrorMessage({ ...errorMessage, title: 'Title is required' });
      return;
    }
    onCreated(note);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Create Note"
      footer={[
        <div className={styles.buttonWrapper}>
          <Button status={status} onClick={saveNote} disabled={disabled || status === 'active'}>
            {status === 'active' ? 'Saving...' : 'Save Note'}
          </Button>
        </div>
      ]}
    >
      <div>
        <div className={classnames(styles.fieldContainer, errorMessage.title && styles.fieldError)}>
          <MaterialInput
            id="ActionPlanNote"
            label="Note title"
            onChange={(e) => handleChangeValues('title', e.target.value)}
            value={note.title}
            required
          />
          {errorMessage.title ? <div className={styles.fieldError}>{errorMessage.title}</div> : null}
        </div>

        <div className={`${styles.field} ${styles.noteField}`}>
          <TextEditor
            id={'ActionPlanNote'}
            value={note.content}
            onChange={(val) => handleChangeValues('content', val)}
            readOnly={status === 'active'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;
