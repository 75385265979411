import AppointmentTypeSelect from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import { Formik } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { AppointmentType, CreatedAppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useState } from 'react';
import AppointmentFooter from '../AppointmentFooter/AppointmentFooter';
import EventOwnerAndFormatField from '../EventOwnerAndFormatField/EventOwnerAndFormatField';
import styles from './AppointmentForm.module.scss';
import { appointmentSchema, initialValues } from './constants';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export type AppointmentFormProps = {
  eventType: string;
  clinician?: Partial<ClinicianListInterface>;
  defaultGroupId?: string;
  appointmentType?: AppointmentType;
  setEventType: (eventType: string) => void;
  setClinician: (clinician?: Partial<ClinicianListInterface>) => void;
  setSelectedClientRecord: (clientRecordData?: clientRecordsInterface) => void;
  setAppointmentType: (appointmentType?: CreatedAppointmentType) => void;
  onSubmit: (values: any) => void;
  selectedClientRecord?: clientRecordsInterface;
  participationType: ParticipantType;
  setParticipationType: (val: ParticipantType) => void;
  selectedGroup: GroupsFromAPI;
  setSelectedGroup: (groupData: GroupsFromAPI) => void;
};

const AppointmentForm = ({
  eventType,
  clinician,
  defaultGroupId,
  appointmentType,
  setEventType,
  setClinician,
  setSelectedClientRecord,
  setAppointmentType,
  onSubmit,
  selectedClientRecord,
  participationType,
  setParticipationType,
  selectedGroup,
  setSelectedGroup
}: AppointmentFormProps) => {
  const { isGroupsFeatureToggle } = useGetFeatureToggle();
  const [isSelectorListLoading, setIsSelectorListLoading] = useState<boolean>(false);

  const handleSubmit = (values: typeof initialValues) => {
    onSubmit({ ...values });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={appointmentSchema} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <EventOwnerAndFormatField
              eventType={eventType}
              setClinician={setClinician}
              setEventType={setEventType}
              selectedClinician={clinician || {}}
              isDisabled={isSelectorListLoading}
            />
            <div className={styles.clientAppointmentTypeContainer}>
              <SelectClientOrGroup
                selectedClinician={clinician}
                defaultGroupId={defaultGroupId}
                defaultRecordId={selectedClientRecord?._id}
                defaultParticipationType={participationType}
                onChangeSelectedParticipationType={setParticipationType}
                isListLoading={setIsSelectorListLoading}
                handleSelectGroup={setSelectedGroup}
                handleSelectClientRecord={setSelectedClientRecord}
                enableGroupSelection={isGroupsFeatureToggle && clinician?.accessRight !== 'mentor'}
              />
              <AppointmentTypeSelect
                clinicianId={clinician?._id || ''}
                onSelect={setAppointmentType}
                selectedParticipantType={participationType}
              />
            </div>
          </div>
          <AppointmentFooter
            selectedParticipantType={participationType}
            clinician={clinician}
            appointmentType={appointmentType}
            buttonText="Next to select When"
            submitForm={submitForm}
            selectedClientRecord={selectedClientRecord}
            selectedGroup={selectedGroup}
          />
        </div>
      )}
    </Formik>
  );
};

export default AppointmentForm;
