import { Modal, notification } from 'antd';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import Button from 'components/v2/Button/Button';
import { useEffect, useState } from 'react';
import styles from './ImportDataModal.module.scss';
import { capitalize } from 'lodash';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postSchoolDataImportRequest } from 'utils/http/ClinicianProfileService/MisSchools/misSchools';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

interface ImportDataModalProp {
  visible?: boolean;
  onClose: () => void;
  refetchSchoolDataImportHistory: () => void;
}

const getMessages = ({ teacher, student }: { teacher: boolean; student: boolean }) => {
  if (teacher && student) {
    return {
      info: {
        message:
          'Teacher assignment for each student will automatically sync. You can uncheck the teacher assignment to import data without sync any teacher assignment.',
        styles: styles.showInfo
      },
      confirmation: {
        message: 'Student and teacher records will be pulled into CORDS.'
      }
    };
  } else if (teacher || student) {
    const subject = teacher ? 'teacher' : 'student';
    return {
      info: {
        message: `Only ${subject} data will be imported, teacher assignment for each student will not be sync.`,
        styles: styles.showShortInfo
      },
      confirmation: {
        message: `${capitalize(subject)} records will be pulled into CORDS.`
      }
    };
  }
};

const initSelected = { teacher: true, student: true };

export const ImportDataModal = ({ visible, onClose, refetchSchoolDataImportHistory }: ImportDataModalProp) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [selected, setSelected] = useState<{ teacher: boolean; student: boolean }>(initSelected);
  const [isUpdateAssignedTeacherCheck, setIsUpdateAssignedTeacherCheck] = useState<boolean>(true);
  const [isEnableAutoSyncChecked, setIsEnableAutoSyncChecked] = useState<boolean>(true);
  const [step, setStep] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');
  const messages = getMessages(selected);

  useEffect(() => {
    if (!selected.student || !selected.teacher) {
      setIsUpdateAssignedTeacherCheck(false);
    }

    if (!selected.student && !selected.teacher) {
      setIsEnableAutoSyncChecked(false);
    }
  }, [selected.student, selected.teacher]);

  const handleProceed = () => {
    if (isUpdateAssignedTeacherCheck && !(selected.student && selected.teacher)) {
      setErrorMessage('Both student and teacher data need to be imported in order to update teacher assignment');
      return;
    } else if (!selected.teacher && !selected.student) {
      setErrorMessage('Must select either import student or teacher data');
      return;
    }
    setErrorMessage('');
    setStep(2);
  };

  const handleCloseModal = () => {
    setSelected(initSelected);
    setErrorMessage('');
    setStep(1);
    onClose();
  };

  const handleCancelButton = () => {
    if (step === 2) {
      setStep(1);
      return;
    }
    handleCloseModal();
  };

  const handleSubmit = async () => {
    setSubmitButtonState('active');
    try {
      await postSchoolDataImportRequest(token, accountId, {
        student: selected.student,
        teacher: selected.teacher,
        assignedTeacher: isUpdateAssignedTeacherCheck,
        enableAutoSync: isEnableAutoSyncChecked
      });
      notification.success({ message: 'Data import process initiated.' });
      setSubmitButtonState('finished');
      refetchSchoolDataImportHistory();
      handleCloseModal();
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 400) {
        const error = (await ex.json()) as { message: string };
        notification.error({
          message: error.message || 'Failed to initiate data import process.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } else {
        console.error(ex);
        notification.error({
          message: 'Failed to initiate data import process.'
        });
      }
    }
    setSubmitButtonState('');
  };

  const getImportState = () => {
    switch (step) {
      case 1:
        return (
          <>
            <CheckBox
              className={styles.checkboxContainer}
              id={'enableAutoSync'}
              value={isEnableAutoSyncChecked}
              disabled={!(selected.student || selected.teacher)}
              onChange={() => setIsEnableAutoSyncChecked(!isEnableAutoSyncChecked)}
              label={'Enable automatic sync and pulls new data (12am every day)'}
              labelClassName={isEnableAutoSyncChecked ? styles.selectedCheckboxDescription : styles.checkboxDescription}
              disabledClassName={styles.disabledCheckbox}
            />
            <div className={classNames(styles.info, messages?.info && messages.info.styles)}>
              <i className={`material-icons-outlined ${styles.icon}`}>info</i>
              <span>{messages?.info.message}</span>
            </div>
            <div className={styles.selectionContainer}>
              <div
                className={classNames(styles.item, selected.student && styles.selected)}
                onClick={() => setSelected({ ...selected, student: !selected.student })}
                key={'student'}
              >
                <span className={styles.label}>Import Student Data</span>
                <i className={`material-icons ${styles.checkIcon}`}>check_circle</i>
              </div>
              <div
                className={classNames(styles.item, selected.teacher && styles.selected)}
                onClick={() => setSelected({ ...selected, teacher: !selected.teacher })}
                key={'teacher'}
              >
                <span className={styles.label}>Import Teacher Data</span>
                <i className={`material-icons ${styles.checkIcon}`}>check_circle</i>
              </div>
            </div>
            <CheckBox
              className={styles.checkboxContainer}
              id={'updateTeacherAssignment'}
              value={isUpdateAssignedTeacherCheck}
              disabled={!(selected.student && selected.teacher)}
              onChange={() => setIsUpdateAssignedTeacherCheck(!isUpdateAssignedTeacherCheck)}
              label={'Update teacher assignment for student'}
              labelClassName={
                isUpdateAssignedTeacherCheck ? styles.selectedCheckboxDescription : styles.checkboxDescription
              }
              disabledClassName={styles.disabledCheckbox}
            />
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
          </>
        );
      case 2:
        return <span className={styles.label}>{messages?.confirmation.message}</span>;
    }
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      title={<span className={styles.title}>{step === 2 ? 'Import Confirmation' : 'Import Data'}</span>}
      width={550}
      footer={null}
      visible={visible}
      onCancel={handleCloseModal}
      destroyOnClose
    >
      <div className={styles.container}>
        {getImportState()}
        <div className={styles.footer}>
          <Button className={styles.cancelButton} onClick={handleCancelButton} id="cancelButton">
            Cancel
          </Button>
          <Button
            className={styles.nextButton}
            disabled={!selected.student && !selected.teacher}
            onClick={() => (step === 2 ? handleSubmit() : handleProceed())}
            id="nextButton"
            status={submitButtonState}
          >
            {step === 2 ? 'Confirm' : 'Proceed'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
