import { Dropdown, Menu } from 'antd';
import REPORTBUILDERIMG from 'assets/images/reportBuilderImg.png';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { ReportListInterface } from 'interfaces/Reports/report';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ReportItem from './components/ReportItem/ReportItem';
import styles from './ReportList.module.scss';

interface ReportListProps {
  data: ReportListInterface[];
  reportDataLoading?: boolean;
  onClickNewReport: () => void;
}

const ReportList = ({ data, reportDataLoading, onClickNewReport }: ReportListProps) => {
  const [selectedFilter, setSelectedFilter] = useState('allStatus');
  const [reportData, setReportData] = useState([] as ReportListInterface[]);
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeUser } = useGetAccountPackageView();
  const [t] = useTranslation();

  useEffect(() => {
    setReportData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const filterType = isEdgeUser
    ? [
        { label: 'All Status', value: 'allStatus' },
        { label: 'Published', value: 'published' },
        { label: 'Unpublished', value: 'unpublished' },
        { label: 'Approved', value: 'approved' },
        { label: 'In Review', value: 'inReview' },
        { label: 'Draft', value: 'draft' }
      ]
    : [
        { label: 'All Status', value: 'allStatus' },
        { label: 'Published', value: 'published' },
        { label: 'Unpublished', value: 'unpublished' },
        { label: 'Draft', value: 'draft' }
      ];

  const handleFilterSelect = (key: string) => {
    setSelectedFilter(key);
    if (key === 'allStatus') {
      setReportData(data);
    } else {
      const newFilterData = data.filter((obj) => {
        return obj.status === key;
      });

      setReportData(newFilterData);
    }
  };

  return reportDataLoading ? (
    <div className={styles.loadingReport}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.container}>
      {data.length <= 0 ? (
        <div className={styles.noDataWrapper} id={'noReportListingId'}>
          <img className={styles.image} alt={'no report img'} src={REPORTBUILDERIMG} />
          <div className={styles.infoWrapper}>
            <div className={styles.text}>{t('label.no_report_for_any_client')}</div>
            <div className={styles.link} onClick={onClickNewReport}>
              <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
              Create New Report
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.filterWrapper} id={'filterReportId'}>
            <div className={styles.filterWrapperTitle}>Filter:</div>
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => handleFilterSelect(String(key))}>
                  {filterType.map(({ label, value }) => (
                    <Menu.Item key={value}>{label}</Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.filterWrapperFilter} onClick={(e) => e.preventDefault()}>
                {filterType.find((option) => option.value === selectedFilter)?.label}
                <i className={`material-icons ${styles.downDownIcon}`}>keyboard_arrow_down</i>
              </div>
            </Dropdown>
          </div>
          <div className={styles.cardContainer} id={'reportListingId'}>
            {reportData.length > 0 ? (
              <>
                <div className={styles.filterListWrap}>
                  <div className={styles.contentWrapper}>
                    {(isEdgeAdminView || isEdgeReceptionist) && (
                      <div className={styles.authorSection}>
                        <div className={styles.label}>Created as</div>
                      </div>
                    )}
                    <div className={styles.profileSection}>
                      <div className={styles.label}>{t('label.client.capitalize')}</div>
                    </div>
                    <div className={styles.reportNameSection}>
                      <div className={styles.label}>Name</div>
                    </div>
                    <div className={styles.statusSection}>
                      <div className={styles.label}>Status</div>
                    </div>
                    <div className={styles.createdSection}>
                      <div className={styles.label}>Created At</div>
                    </div>
                    <div className={styles.lastAccessedSection}>
                      <div className={styles.label}>Last Updated</div>
                    </div>
                    <div className={styles.shareDetailSection} />
                  </div>
                  <div className={styles.buttonWrapper} />
                </div>
                <ReportItem reportListData={reportData} />
              </>
            ) : (
              <div className={styles.noData}>There is currently no reports and letters data</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReportList;
