import { CronofyCalendar } from 'interfaces/Cronofy/cronofyProfile';
import { useState } from 'react';

import styles from './ConnectedCalendar.module.scss';

interface ConnectedCalendarProps {
  cronofyCalendar: CronofyCalendar;
  index: number;
  handleCalendarChange: (calendar: CronofyCalendar) => void;
}

const ConnectedCalendar = ({ cronofyCalendar, index, handleCalendarChange }: ConnectedCalendarProps) => {
  const [calendar, setCalendar] = useState(cronofyCalendar);

  const handleCalendarConfigurationChange = (type: 'disable' | 'push' | 'pull') => {
    const payload = {
      ...calendar,
      configuration: {
        ...calendar.configuration,
        [type]: !calendar.configuration[type]
      }
    };
    setCalendar(payload);
    handleCalendarChange(payload);
  };
  return (
    <div className={styles.calendarContainer}>
      <div className={styles.calendarTitle}>CALENDAR {index}</div>
      <div className={styles.calendarName}>
        <label className={styles.settingCheckbox}>
          <input
            checked={!calendar.configuration.disable}
            className={styles.checkbox}
            type="checkbox"
            onChange={() => handleCalendarConfigurationChange('disable')}
          />
          <span className={styles.connectSettingLabel}>{calendar.name}</span>
        </label>
      </div>
      <div className={styles.configsContainer}>
        <div className={styles.settingOptionContainer}>
          <label className={calendar.configuration.disable ? styles.disabledSettingCheckbox : styles.settingCheckbox}>
            <input
              disabled={calendar.configuration.disable}
              checked={calendar.configuration.push}
              className={styles.checkbox}
              type="checkbox"
              onChange={() => handleCalendarConfigurationChange('push')}
            />
            <span className={styles.connectSettingLabel}>Push appointments details</span>
          </label>
          {index === 1 && (
            <div className={styles.connectSettingDescription}>
              This will send any confirmed appointment or event from Tacklit straight to your connected calendar.
            </div>
          )}
        </div>
        <div className={styles.settingOptionContainer}>
          <label className={calendar.configuration.disable ? styles.disabledSettingCheckbox : styles.settingCheckbox}>
            <input
              disabled={calendar.configuration.disable}
              checked={calendar.configuration.pull}
              className={styles.checkbox}
              type="checkbox"
              onChange={() => handleCalendarConfigurationChange('pull')}
            />
            <span className={styles.connectSettingLabel}>Read free/busy status</span>
          </label>
          {index === 1 && (
            <div className={styles.connectSettingDescription}>
              This setting will pull in the times of any events in your calendar into Tacklit (not any other information
              beyond the start and end time). This will ensure appointments are not created over other private events
              you have scheduled.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectedCalendar;
