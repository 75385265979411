import { useEffect, useRef, useState, FC } from 'react';
import styles from './StatusSelector.module.scss';
import Badge from '../../Badge/Badge';
import classnames from 'classnames';

export interface StatusInterface {
  label: string;
  value: string;
  color?: string;
}

export interface StatusSelectorProps {
  onSelect: (val: string) => void;
  options: StatusInterface[];
  disabled?: boolean;
  className?: string;
  selectedValue?: string;
  backgroundColor?: string;
}

const StatusSelector: FC<StatusSelectorProps> = ({
  onSelect,
  options,
  disabled,
  className,
  selectedValue,
  backgroundColor
}) => {
  const node = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);

  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof HTMLElement && node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSelectOption = (value: StatusInterface) => {
    onSelect(value.value);
    setShowList(false);
  };

  const selectedOption = options.find(({ value }) => value === selectedValue);

  return (
    <div ref={node} className={classnames(styles.container, className)}>
      <div
        className={classnames(styles.selectedBox, disabled && styles.disabled)}
        onClick={() => {
          !disabled && setShowList(!showList);
        }}
        style={{ backgroundColor }}
      >
        {selectedOption?.value ? (
          <Badge
            label={selectedOption.label}
            className={styles.badge}
            backgroundColor={selectedOption.color || '#3f52ff'}
          />
        ) : (
          <label className={styles.placeHolder}>Select status</label>
        )}
        <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
      </div>
      <div className={styles.listWrapper}>
        <div className={showList ? styles.listShow : styles.listHide}>
          <div className={styles.itemWrapper}>
            {options.map((option, index) => (
              <div
                className={selectedValue === option.value ? styles.itemSelected : styles.item}
                key={index}
                onClick={() => handleSelectOption(option)}
              >
                <Badge label={option.label} className={styles.badge} backgroundColor={option.color || '#3f52ff'} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusSelector;
