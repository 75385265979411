import moment from 'moment';
import { CommentWidgetInterface } from 'pages/Report/ReportDetails/interface';

import styles from './Comment.module.scss';

interface CommentProps {
  id: string;
  comments: CommentWidgetInterface['comments'];
  handleExpandComment: (id: string) => void;
}

const Comment = ({ id, comments, handleExpandComment }: CommentProps) => (
  <div className={styles.container} key={id} onClick={() => handleExpandComment(id)}>
    <div className={styles.titleContainer}>
      <div className={styles.authorContainer}>
        <div className={styles.avatarContainer}>
          <img className={styles.avatar} src={comments[0].commentedBy.avatar} alt={comments[0].commentedBy.name} />
        </div>
        <div className={styles.authorName}>{comments[0].commentedBy.name}</div>
      </div>
      <div className={styles.createdDate}>{moment(comments[0].commentedAt).format('DD/MM/YYYY')}</div>
    </div>
    <div className={styles.message}>{comments[0].content}</div>
    {comments.length > 1 && <div className={styles.replies}>{`${comments.length - 1} replies`}</div>}
  </div>
);

export default Comment;
