import Button from 'components/v2/Button/Button';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './AppointmentTypes.module.scss';
import AppointmentTypeCard from './components/AppointmentTypeCard/AppointmentTypeCard';
import CreateAppointmentTypeModal from './components/CreateAppointmentTypeModal/CreateAppointmentTypeModal';
import { useFetchAppointmentTypes } from './hooks/GetAppointmentTypes';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';

const AppointmentTypes = () => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView } = useGetAccountPackageView();
  const [showCreateAppointmentTypeModal, setShowCreateAppointmentTypeModal] = useState(false);
  const { appointmentTypes, isAppointmentTypesLoading, fetchAppointmentTypes } = useFetchAppointmentTypes(token);

  const generateAppointmentTypes = () => {
    return isAppointmentTypesLoading ? (
      <div className={styles.loading}>
        <LoadingDot />
      </div>
    ) : appointmentTypes.length > 0 ? (
      appointmentTypes.map((data, index) => (
        <div key={index} className={styles.appointmentTypeCard}>
          <AppointmentTypeCard
            appointmentType={data as AppointmentType}
            fetchAppointmentTypes={() => fetchAppointmentTypes(token)}
          />
        </div>
      ))
    ) : (
      <div>There is no data for appointment types</div>
    );
  };

  const onSubmitAppointmentType = () => {
    fetchAppointmentTypes(token);
  };

  return (
    <div className={styles.panel}>
      <div className={styles.header}>
        <div className={styles.title}>
          {isEdgeUserView ? 'Appointment types' : 'Click on appointment type to view and edit'}
        </div>
        {(isEdgeAdminView || isNormalUserView) && (
          <Button
            className={styles.addButton}
            onClick={() => setShowCreateAppointmentTypeModal(true)}
            variant="secondary"
            icon="add_circle_outline"
          >
            New appointment type
          </Button>
        )}
      </div>
      <div className={styles.appointmentTypeList}>{generateAppointmentTypes()}</div>
      <CreateAppointmentTypeModal
        visible={showCreateAppointmentTypeModal}
        onPost={onSubmitAppointmentType}
        onClose={() => setShowCreateAppointmentTypeModal(false)}
      />
    </div>
  );
};

export default AppointmentTypes;
