import { AttachedClientRecordStatus, GroupStage, GroupTag, Groups } from 'pages/Groups/Interfaces/Groups';

import { appointmentStatistics } from './Appointment';
import { ClientEncryptDetailInterface } from './ClientEncryptDetail';
import { clinicianInterface } from './clinician';
import { InterventionStage } from '../../MHH/pages/Interventions/Interfaces/Interventions';
import { Assessment as PatientDetailAssessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';

export enum ClientRecordType {
  Adult = 'adult',
  Child = 'child',
  Couple = 'couple'
}

export enum ChildProfileRole {
  Child = 'child',
  Mother = 'mother',
  Father = 'father',
  StepMother = 'stepMother',
  StepFather = 'stepFather',
  Grandparent = 'grandparent',
  Guardian = 'guardian',
  Sibling = 'sibling',
  Teacher = 'teacher',
  CaseWorker = 'caseWorker',
  Other = 'other'
}

export interface ClientStatus {
  status?: 'active' | 'closed' | 'prospect';
}

export enum ClientStatusEnum {
  Active = 'active'
}

export enum CommunicationPreference {
  NoPreference = 'noPreference',
  Email = 'email',
  SMS = 'sms',
  NONE = 'none'
}
export interface GetClientProfileInterface {
  clientRecords: clientRecordsInterface[];
  recordStatusCounts: {
    active: number;
    closed: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
    totalPage: number;
  };
}
export enum RecordStatus {
  Active = 'active',
  Closed = 'closed',
  Prospect = 'prospect'
}

export interface referralFilesInterface {
  bucketName: string;
  fileName: string;
  fileUrl: string;
  clientProfileId: string;
  questionsResponsesId?: string;
  createdAt?: Date;
}

export interface KeyClientContact {
  _id: string;
  firstName: string;
  lastName: string;
  mobileNumber?: string;
  email?: string;
  tags?: string[];
}

export interface AppointmentStatusOverviewConfiguration {
  target: number;
  limit: number;
  displayTagCount: string[];
}

export interface MedicareDetails {
  active: boolean;
  number: number;
  irn: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  status?: {
    code: number;
    message?: string;
  };
  summary: {
    mbsCodes: {
      individual: string[];
      group: string[];
    };
  };
  createdAt: string;
  updatedAt: string;
}

export interface clientRecordsInterface {
  accountId: string;
  clientProfiles: clientProfilesInterface[];
  createdAt: string;
  recordStatus: ClientStatus['status'];
  recordType: ClientRecordType;
  referral?: {
    sourceTags?: string[];
    detail: string;
    isReferredByGP?: boolean;
    files?: referralFilesInterface[];
    date?: string;
    generalPractitionerId?: string;
  };
  invoiceSummary: {
    count: number;
    order: number;
    settings?: {
      automatedInvoicing: {
        active: boolean;
        dueAt: number;
        includeAppointments: string;
        issueAt: string;
        note: string;
        sendToClient: boolean;
        type: 'appointment' | 'month';
        includeMedicareDetails?: boolean;
        sendToOther: {
          active: boolean;
          emails?: string[];
        };
        setAsFlatFee: {
          active: boolean;
          value: number;
        };
      };
      discount: {
        active: boolean;
        type: 'percent' | 'amount';
        value: number;
      };
    };
  };
  updatedAt: string;
  _id: string;
  isCompleteRecords: boolean;
  clinicians?: clinicianInterface[];
  appointmentStatistics: appointmentStatistics;
  clinicianAuth0Ids: string[];
  mentorIds?: string[];
  mentors?: { _id: string; name: string }[];
  caseId?: string;
  tags?: string[];
  allowCommunicationWithEmail?: boolean;
  allowCommunicationWithSms?: boolean;
  canBeFullProfile: boolean;
  keyClientContacts?: KeyClientContact[];
  attachedGroups?: AttachedGroup[];
  attachedInterventions?: AttachedInterventions[];
  appointmentStatusOverviewConfiguration: AppointmentStatusOverviewConfiguration;
  assessment?: Assessment;
  activeActionPlans?: { actionPlanName: string; ownerName: string }[];
  latestAssessment?: PatientDetailAssessment;
  class?: string;
  assessorIds?: string[];
  assessors?: { _id: string; name: string }[];
  linkTeacherToAssessor?: boolean;
}

export interface clientProfilesInterface {
  clientAuth0Id?: string;
  _id: string;
  createdAt: string;
  hashedMobileNumber: string;
  avatar?: string;
  initials: string;
  initialsBackgroundColor: string;
  isEmailThirdParty: boolean;
  isMobileNumberThirdParty: boolean;
  mobileNumber?: string;
  hasMobileNumber: boolean;
  email?: string;
  hasEmail: boolean;
  name: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  postcode?: string;
  onboardingSettings?: {
    facilitationUrl: string;
    firstAssessmentId: string;
    onboardingMethod: string;
  };
  profileType: 'full' | 'recordOnly' | 'prospect';
  signupInvitation?: {
    isSent: boolean;
    createdAt: string;
    inviteDate: string;
    sendAt: string;
    updatedAt: string;
    signupDate: string;
  };
  updatedAt: string;
  isPrimaryContact?: boolean;
  communicationPreference?: CommunicationPreference;
  role?: ChildProfileRole;
  medicare?: MedicareDetails;
}

export interface clientRecordsWithSelected extends clientRecordsInterface {
  isSelected?: boolean;
}

export interface clientRecordsEncryptedInterface extends clientRecordsInterface {
  caseId?: string;
  clientProfiles: clientProfilesEncrypted[];
}

export interface clientProfilesEncrypted extends clientProfilesInterface {
  mobileNumber: string;
  email: string;
  background: ClientEncryptDetailInterface['background'];
}

export enum ProspectStatus {
  Prospect = 'prospect',
  Closed = 'closed'
}

export interface clientListFilterParameter {
  status?: ClientStatus['status'];
  perPage: number;
  page: number | string;
  q: string;
  prospectStatus?: ProspectStatus;
  sortByClientName?: number;
  sortByClientSince?: number;
  sortByClass?: number;
  sortByLatestAssessmentScore?: number;
  isMHH?: boolean;
  additionalQuery?: string;
}

export interface ClientListingCount {
  active: number;
  closed: number;
  prospect: number;
  testing: number;
}

export interface AttachedGroup {
  groupId: string;
  status: AttachedClientRecordStatus;
  dateJoined: Date;
  updatedAt: Date;
  createdAt: Date;
  groupName: string;
  groupTags: GroupTag[];
  groupLeadClinicianAuth0Id: string;
  groupClinicianAuth0Ids: string[];
  stage: GroupStage;
  stageId: string;
  groupLeadName: string;
  stagesConfiguration: Groups['stagesConfiguration'];
}

export interface AttachedInterventions {
  interventionId: string;
  status: AttachedClientRecordStatus;
  dateJoined: Date;
  updatedAt: Date;
  createdAt: Date;
  interventionName: string;
  interventionTags: GroupTag[];
  interventionLeadClinicianAuth0Id: string;
  interventionClinicianAuth0Ids: string[];
  stage: InterventionStage;
  stageId: string;
  groupLeadName: string;
  stagesConfiguration: Groups['stagesConfiguration'];
}

export interface Assessment {
  _id: string;
  status: AssessmentStatus;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export enum AssessmentStatus {
  Pending = 'pending',
  Done = 'done'
}

export interface MinifiedClientProfile {
  _id: string;
  firstName: string;
  initials: string;
  initialsBackgroundColor: string;
  lastName: string;
  role?: string;
}

export interface MinifiedClientRecord {
  _id: string;
  recordStatus: string;
  recordType: ClientRecordType;
  clientProfiles: MinifiedClientProfile[];
  class?: string;
  tags?: string[];
}

export interface ClientRecordsListMinifiedResponse {
  clientRecords: MinifiedClientRecord[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}
