import React, { useState } from 'react';
import { Skeleton } from 'antd';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import ClosedClientList from './components/ClosedClientList/ClosedClientList';
import SearchBar from 'components/SearchBar/SearchBar';
import Tag from 'components/Tag/Tag';
import ActivePractitionerClientList from './components/ActivePractitionerClientList/ActivePractitionerClientList';
import { SortBy, TabIds } from '../../Patient';
import { ClientListingCount, GetClientProfileInterface, ProspectStatus } from 'interfaces/Clients/clientsRecord';

import styles from './ClientListContent.module.scss';
import { useTranslation } from 'react-i18next';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { MinifiedClinician } from 'interfaces/Clients/clinician';
import Filters from './components/ActivePractitionerClientList/components/Filters';

interface PatientListProps {
  token: string;
  clientRecordList: GetClientProfileInterface;
  isClientRecordListLoading: boolean;
  clientsCount: ClientListingCount;
  onChangeTabFilter: (tabValue: TabIds['tabId']) => void;
  onResendInvitation: (recordId: string) => void;
  onCancelInvitation: (recordId: string) => void;
  recordIdProcessing: string;
  onPageChange: (paging: string, sortBy?: SortBy) => void;
  onSearch: (q: string) => void;
  isPageLoading: boolean;
  isCountLoading: boolean;
  handleContentUpdate: () => void;
  handleProspectStatus: (status: ProspectStatus) => void;
  selectedStudentIds: string[];
  onSelectStudent: (studentId: string, isSelected: boolean) => void;
  onChangeTeacherBulkClick: () => void;
  onChangeWellBeingLeaderBulkClick: () => void;
  classFilteredList: string[];
  setClassFilteredList: React.Dispatch<React.SetStateAction<string[]>>;
  groupFilteredList: GroupsFromAPI[];
  setGroupFilteredList: React.Dispatch<React.SetStateAction<GroupsFromAPI[]>>;
  teacherFilteredList: MinifiedClinician[];
  setTeacherFilteredList: React.Dispatch<React.SetStateAction<MinifiedClinician[]>>;
  assessorFilteredList: MinifiedClinician[];
  setAssessorFilteredList: React.Dispatch<React.SetStateAction<MinifiedClinician[]>>;
}

const ClientListContent = ({
  clientRecordList,
  isClientRecordListLoading,
  clientsCount,
  onChangeTabFilter,
  onResendInvitation,
  onCancelInvitation,
  recordIdProcessing,
  onPageChange,
  onSearch,
  isPageLoading,
  isCountLoading,
  selectedStudentIds,
  onSelectStudent,
  onChangeTeacherBulkClick,
  onChangeWellBeingLeaderBulkClick,
  classFilteredList,
  setClassFilteredList,
  groupFilteredList,
  setGroupFilteredList,
  teacherFilteredList,
  setTeacherFilteredList,
  assessorFilteredList,
  setAssessorFilteredList
}: PatientListProps) => {
  const path = useParams<{ tabId?: TabIds['tabId'] }>();
  const location = useLocation();
  const queryParam: { q?: string; page?: string; prospectStatus?: ProspectStatus } = queryString.parse(location.search);
  const [filterType, setFilterType] = useState<TabIds['tabId']>(path.tabId ?? 'active');

  const listLoading = isCountLoading || isClientRecordListLoading || isPageLoading;

  const onClickMenu = (tabId: TabIds['tabId']) => {
    setFilterType(tabId);
    onChangeTabFilter(tabId);
  };

  const handleSearch = (val: string) => {
    onSearch(val);
  };

  const handlePageChange = (val: string, sortBy?: SortBy) => {
    onPageChange(val, sortBy);
  };

  const resetPage = () => clientRecordList.paging.page > 1 && handlePageChange('1');

  const clientList = clientRecordList ? clientRecordList.clientRecords : [];

  const [t] = useTranslation();
  const generateContentComponents = () => {
    switch (filterType) {
      case 'active':
        return (
          <ActivePractitionerClientList
            clientRecordList={
              !isClientRecordListLoading ? clientList.filter((clientObj) => clientObj.recordStatus === 'active') : []
            }
            isClientRecordListLoading={listLoading}
            onResendInvitation={onResendInvitation}
            onCancelInvitation={onCancelInvitation}
            recordIdProcessing={recordIdProcessing}
            paging={clientRecordList.paging}
            onPageChange={handlePageChange}
            selectedStudentIds={selectedStudentIds}
            onSelectStudent={onSelectStudent}
            onChangeTeacherBulkClick={onChangeTeacherBulkClick}
            onChangeWellBeingLeaderBulkClick={onChangeWellBeingLeaderBulkClick}
          />
        );
      case 'closed':
        return (
          <ClosedClientList
            clientRecordList={
              !isClientRecordListLoading ? clientList.filter((clientObj) => clientObj.recordStatus === 'closed') : []
            }
            isClientRecordListLoading={listLoading}
            paging={clientRecordList.paging}
            onPageChange={handlePageChange}
          />
        );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.filterWrapper} id={'clientFilterStatusId'}>
            <div className={styles.filterWrapperTitle}>Show</div>
            <div className={styles.filterWrapperTags}>
              <div className={styles.tagWrapper}>
                {isClientRecordListLoading ? (
                  <Skeleton.Button active />
                ) : (
                  <Tag
                    label="Active"
                    type={filterType === 'active' ? 'active' : 'normal'}
                    counter={clientsCount.active}
                    onClick={() => onClickMenu('active')}
                    isLoading={isCountLoading}
                  />
                )}
              </div>
              <div className={styles.tagWrapper}>
                {isClientRecordListLoading ? (
                  <Skeleton.Button active />
                ) : (
                  <Tag
                    label="Closed"
                    type={filterType === 'closed' ? 'active' : 'normal'}
                    counter={clientsCount.closed}
                    onClick={() => onClickMenu('closed')}
                    isLoading={isCountLoading}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.searchBarWrapper}>
            <div className={styles.searchBarCard}>
              <SearchBar
                placeholder={t('searchbar.searcy_by_client')}
                value={queryParam.q}
                withSearchButton
                onSearch={handleSearch}
              />
            </div>
          </div>
        </div>
        <Filters
          classFilteredList={classFilteredList}
          setClassFilteredList={setClassFilteredList}
          groupFilteredList={groupFilteredList}
          setGroupFilteredList={setGroupFilteredList}
          teacherFilteredList={teacherFilteredList}
          setTeacherFilteredList={setTeacherFilteredList}
          assessorFilteredList={assessorFilteredList}
          setAssessorFilteredList={setAssessorFilteredList}
          resetPage={resetPage}
        />
        <div className={styles.cardContainer} id={'clientListContentsId'}>
          {generateContentComponents()}
        </div>
      </div>
    </>
  );
};

export default ClientListContent;
