import { HTMLProps, MouseEvent } from 'react';
import moment from 'moment';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import DateDescBox from '../../../Components/DateDescBox/DateDescBox';

import styles from './ActivePractitionerClientItem.module.scss';
import columnStyles from '../../ActivePractitionerClientColumn.module.scss';
import Tags from 'MHH/components/Tags/Tags';
import AssessmentLabel from 'MHH/components/AssessmentLabel/AssessmentLabel';

import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import { MAX_BULK_UPDATE } from '../../constants';

interface ActiveClientItemProps {
  clientRecord: clientRecordsInterface;
  onSelectClient: () => void;
  selectedStudentIds: string[];
  onSelectStudent: (studentId: string, isSelected: boolean) => void;
}

const ActivePractitionerClientItem = ({
  clientRecord,
  onSelectClient,
  selectedStudentIds,
  onSelectStudent
}: ActiveClientItemProps & HTMLProps<HTMLDivElement>) => {
  const profile = clientRecord.clientProfiles[0];
  const groupNames = clientRecord.attachedGroups?.filter((i) => i.status === 'active').map((i) => i.groupName);
  const interventions = clientRecord.attachedInterventions
    ?.filter((i) => i.status === 'active')
    .map((i) => i.interventionName);
  const {
    activeActionPlans,
    latestAssessment: lastAssessment,
    mentors,
    assessors,
    linkTeacherToAssessor
  } = clientRecord;

  const onCheckStudent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectStudent(clientRecord._id, event.target.checked);
  };

  return (
    <div className={styles.container} onClick={() => onSelectClient()}>
      <div
        className={columnStyles.studentCheckBoxSection}
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
        }}
      >
        <CheckBox
          inputClassName={styles.checkBox}
          id={clientRecord._id}
          value={selectedStudentIds.includes(clientRecord._id)}
          onChange={onCheckStudent}
          disabled={selectedStudentIds.length >= MAX_BULK_UPDATE && !selectedStudentIds.includes(clientRecord._id)}
        />
      </div>
      <div className={classNames(columnStyles.nameSection, styles.name)}>
        {profile.firstName + ' ' + profile.lastName}
      </div>
      <div className={columnStyles.classSection}>{clientRecord.class || '-'}</div>
      <div className={columnStyles.tAssignedSection}>{mentors?.[0]?.name || '-'}</div>
      <div className={classNames(columnStyles.tAssignedSection, styles.assessor)}>
        {linkTeacherToAssessor && <span className={`material-icons-outlined ${styles.icon}`}>{'link'}</span>}
        {assessors?.[0]?.name || '-'}
      </div>
      <div className={columnStyles.groupsSection}>
        {
          <div className={styles.groups}>
            {!groupNames || groupNames.length === 0 ? (
              <div className={styles.none}>None</div>
            ) : (
              <Tags tags={groupNames} />
            )}
          </div>
        }
      </div>
      <div className={columnStyles.interventionSection}>
        {
          <div className={styles.interventions}>
            {!interventions || interventions.length === 0 ? (
              <div className={styles.none}>None</div>
            ) : (
              <Tags tags={interventions} />
            )}
          </div>
        }
      </div>
      <div className={columnStyles.actionPlanSection}>
        {!activeActionPlans || activeActionPlans.length === 0 ? (
          <div className={styles.none}>None</div>
        ) : (
          <div className={styles.actionPlans}>
            {activeActionPlans.map((activeActionPlan, index) => (
              <div key={index} className={styles.actionPlan}>
                <div className={styles.left}>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="#FAB000" />
                    <path
                      d="M12.4801 18.3211L8.32466 14.1656L8.0701 13.9111L7.81554 14.1656L6.34554 15.6356L6.09098 15.8902L6.34554 16.1448L12.2255 22.0248L12.4801 22.2793L12.7347 22.0248L25.3347 9.42475L25.5892 9.1702L25.3347 8.91564L23.8647 7.44564L23.6101 7.19108L23.3555 7.44564L12.4801 18.3211Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.72"
                    />
                  </svg>
                </div>
                <div className={styles.right}>
                  <div>{activeActionPlan.actionPlanName}</div>
                  <div className={styles.actionPlanOwnerName}>{activeActionPlan.ownerName}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={columnStyles.lastScoreSection}>
        {lastAssessment ? (
          <div className={styles.lastScore}>
            <div className={styles.left}>
              <div className={styles.assessmentScore}>
                <div className={styles.score}>{lastAssessment?.charts[0]?.total}</div>
                {lastAssessment.id.toLowerCase().includes('sdq') && (
                  <AssessmentLabel
                    label={lastAssessment?.charts[0]?.label}
                    minWidth={'100px'}
                    className={styles.label}
                  />
                )}
              </div>
            </div>
            <div className={styles.right}>
              <DateDescBox
                title={lastAssessment.id}
                desc={moment(lastAssessment.createdAt).format('DD MMMM YYYY')}
                smallSize
              />
            </div>
          </div>
        ) : (
          <div className={styles.none}>None</div>
        )}
      </div>
      <div className={classNames(columnStyles.buttonSection, styles.button)}>
        <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
      </div>
    </div>
  );
};

export default ActivePractitionerClientItem;
