import { v4 as uuid } from 'uuid';
import { ReportWidgetType } from 'interfaces/Reports/report';
import WidgetItemWrapper from '../../../WidgetItemWrapper/WidgetItemWrapper';
import styles from './TextEditorWidget.module.scss';

export const headingITEMS = {
  id: uuid(),
  details: {
    title: 'Add a heading'
  }
};

export const bodyTextITEMS = {
  id: uuid(),
  details: {
    title: 'Add body text',
    width: 400
  }
};

export const footNotesTextITEMS = {
  id: uuid(),
  details: {
    title: 'Add foot notes text'
  }
};

interface TextEditorWidgetProps {
  enableMention?: boolean;
}

const TextEditorWidget = ({ enableMention }: TextEditorWidgetProps) => {
  return (
    <div className={styles.container}>
      <WidgetItemWrapper data={headingITEMS.details} widgetType={ReportWidgetType.HeadingWidget}>
        <div className={styles.inputHeadingWidget}>Add a heading</div>
      </WidgetItemWrapper>
      <WidgetItemWrapper
        data={bodyTextITEMS.details}
        widgetType={enableMention ? ReportWidgetType.BodyTextTemplateWidget : ReportWidgetType.BodyTextWidget}
      >
        <div className={styles.inputBodyWidget}>Add body text</div>
      </WidgetItemWrapper>
      <WidgetItemWrapper data={footNotesTextITEMS.details} widgetType={ReportWidgetType.FootNotesTextWidget}>
        <div className={styles.inputNotesWidget}>Add foot notes text</div>
      </WidgetItemWrapper>
    </div>
  );
};

export default TextEditorWidget;
