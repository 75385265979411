import { notification } from 'antd';
import { AppointmentTypeAvailabilities } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import { useEffect, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentTypeAvailabilities } from 'utils/http/availability';

export const useFetchAppointmentTypeAvailabilities = (
  from: string,
  to: string,
  type: 'booking' | 'reschedule',
  appointmentTypeId: string,
  clinicianId: string,
  selectedRoomId?: string
) => {
  const { token } = useGetAccessToken();
  const [appointmentTypeAvailabilities, setAppointmentTypeAvailabilities] = useState<AppointmentTypeAvailabilities>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchApptTypeAvailabilities = async ({
    token,
    from,
    to,
    type,
    appointmentTypeId,
    clinicianId,
    selectedRoomId
  }: {
    token: string;
    from: string;
    to: string;
    type: 'booking' | 'reschedule';
    appointmentTypeId: string;
    clinicianId: string;
    selectedRoomId?: string;
  }) => {
    setIsLoading(true);
    try {
      const appointmentTypeAvailabilities = await getAppointmentTypeAvailabilities({
        token,
        from,
        to,
        type,
        appointmentTypeId,
        clinicianId,
        selectedRoomId
      });
      setAppointmentTypeAvailabilities(appointmentTypeAvailabilities);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your appointment type records'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token && appointmentTypeId) {
      fetchApptTypeAvailabilities({ token, from, to, type, appointmentTypeId, clinicianId, selectedRoomId });
    }
  }, [token, appointmentTypeId, from, to, clinicianId, type, selectedRoomId]);

  return { appointmentTypeAvailabilities, isLoading };
};
