import { useEffect, useState } from 'react';

import styles from './AppointmentSummary.module.scss';
import AppointmentTagsBadge from '../AppointmentTagsBadge/AppointmentTagsBadge';
import {
  AppointmentMarkOption,
  AppointmentStatusOption
} from 'pages/Calendar/components/Modals/EventInformationModal/components/MarkStatus/MarkStatus';
import Button from 'components/v2/Button/Button';
import EditAppointmentStatusModal from '../EditAppointmentStatusModal/EditAppointmentStatusModal';
import { AppointmentStatusOverviewConfiguration } from 'interfaces/Clients/clientsRecord';
import { Skeleton } from 'antd';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { AppointmentTargetLimitOption } from '../EditAppointmentStatusModal/components/AppointmentStatusTagsForm/AppointmentStatusTagsForm';
import { useTranslation } from 'react-i18next';

export interface TagsCountInterface {
  tags: string;
  tagsCount: number;
}

interface AppointmentSummaryProps {
  clientRecordId: string;
  clientAppointment: AppointmentSlots[];
  appointmentStatusOverviewConfiguration: AppointmentStatusOverviewConfiguration;
  upcomingAppointmentsTotal: number;
  completedAppointmentsTotal: number;
  isLoading: boolean;
}

const AppointmentSummary = ({
  clientRecordId,
  clientAppointment,
  appointmentStatusOverviewConfiguration,
  upcomingAppointmentsTotal,
  completedAppointmentsTotal,
  isLoading
}: AppointmentSummaryProps) => {
  const [statusConfig, setStatusConfig] = useState<AppointmentStatusOverviewConfiguration>(
    appointmentStatusOverviewConfiguration
  );
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setStatusConfig(appointmentStatusOverviewConfiguration);
  }, [appointmentStatusOverviewConfiguration]);

  const appointmentStatusCount: TagsCountInterface[] = AppointmentMarkOption.map((statusObj) => ({
    tags: statusObj.value,
    tagsCount: clientAppointment.filter((clientAppointmentObj) =>
      clientAppointmentObj.markedStatus?.includes(statusObj.value)
    ).length
  }));

  const generateName = (val: string) => {
    switch (val) {
      case AppointmentStatusOption.ClientDidNotAttend:
        return t('label.client_dna');
      case AppointmentStatusOption.ClinicianDidNotAttend:
        return t('label.practitioner_dna');
      default:
        return val;
    }
  };

  const targetLimitOption: string[] = Object.values(AppointmentTargetLimitOption).map((obj) => obj);

  const generateTagsSummary = statusConfig.displayTagCount
    .filter((obj) => !targetLimitOption.includes(obj))
    .map((tagsObj) => ({
      name: generateName(tagsObj),
      count: appointmentStatusCount.find((statusObj) => statusObj.tags === tagsObj)?.tagsCount
    }));

  const summaryCollection = [
    ...(statusConfig.target > 0 && statusConfig.displayTagCount.includes(AppointmentTargetLimitOption.Target)
      ? [
          {
            name: 'Target',
            count: statusConfig.target
          }
        ]
      : []),
    ...(statusConfig.limit > 0 && statusConfig.displayTagCount.includes(AppointmentTargetLimitOption.Limit)
      ? [
          {
            name: 'Limit',
            count: statusConfig.limit
          }
        ]
      : []),
    {
      name: 'Upcoming',
      count: upcomingAppointmentsTotal
    },
    {
      name: 'Past',
      count: completedAppointmentsTotal
    },
    ...generateTagsSummary
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Appointment status overview
        {!isLoading && (
          <Button
            variant={'link'}
            className={styles.editBtn}
            onClick={() => {
              setIsEditModalVisible(true);
            }}
          >
            <span className={`material-icons-outlined ${styles.icon}`}>edit</span>
            Edit
          </Button>
        )}
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          {[...Array(8)].map((obj, i) => (
            <Skeleton.Input key={i} active className={styles.loading} />
          ))}
        </div>
      ) : (
        <>
          <div className={styles.summaryDetails}>
            {summaryCollection.map((summaryObj, index) => (
              <div className={styles.summaryCard} key={index}>
                <AppointmentTagsBadge className={styles.tag} label={summaryObj.name} />
                <div className={styles.count}>{summaryObj.count}</div>
              </div>
            ))}
          </div>
          <EditAppointmentStatusModal
            clientRecordId={clientRecordId}
            appointmentStatusOverviewConfiguration={statusConfig}
            visible={isEditModalVisible}
            onSuccess={(val) => {
              setStatusConfig(val);
            }}
            onCloseModal={() => setIsEditModalVisible(false)}
          />
        </>
      )}
    </div>
  );
};

export default AppointmentSummary;
