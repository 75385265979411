import classnames from 'classnames';
import moment from 'moment';

import { QuickNote } from '../../../../AddQuickNoteModal';

import styles from './QuickNoteCard.module.scss';

interface QuickNoteCardProps {
  quickNote: QuickNote;
  selected?: boolean;
  onClick?: () => void;
}

const QuickNoteCard = ({ quickNote, selected, onClick }: QuickNoteCardProps) => {
  return (
    <div
      className={classnames(styles.container, onClick && styles.selectable, selected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.tick}>
        <i className={`material-icons-outlined ${styles.icon}`}>done</i>
      </div>
      <div className={styles.card} id={`add-quick-note-card-${quickNote._id}`}>
        <div className={styles.header}>
          <div className={styles.title}>{quickNote.title}</div>
          <div className={styles.createdAt}>{moment(quickNote.createdAt).format('DD MMMM YYYY')}</div>
        </div>
        <div className="ql-snow">
          <div className={`ql-editor ${styles.body}`} dangerouslySetInnerHTML={{ __html: quickNote.body }} />
        </div>
      </div>
    </div>
  );
};

export default QuickNoteCard;
