////////////////
// ENUMS ///////
////////////////

export enum DeliveryType {
  BeSpoke = 'bespoke',
  FaceToFace = 'faceToFace',
  PhoneCall = 'phoneCall',
  VideoCall = 'videoCall'
}

export const DELIVERY_TYPE_LABELS: Record<DeliveryType, string> = {
  bespoke: 'Bespoke',
  faceToFace: 'Face to face',
  phoneCall: 'Phone call',
  videoCall: 'Video call'
};

export const DELIVERY_TYPE_ICONS: Record<DeliveryType, string> = {
  bespoke: 'people',
  faceToFace: 'people',
  phoneCall: 'phone',
  videoCall: 'videocam'
};

export enum SessionStartOption {
  OnTheHour = 'onTheHour',
  OnTheHalfHour = 'onTheHalfHour',
  Mixed = 'mixed'
}

export enum Rule {
  Instant = 'instant',
  Request = 'request'
}

export enum ScheduleOption {
  AutoFit = 'auto fit',
  FixedTime = 'fixed time'
}

export enum AssignmentMode {
  All = 'all',
  Practice = 'practice',
  Selected = 'selected',
  AllMentors = 'allMentors',
  AutoAssign = 'autoAssign'
}

export enum ParticipantType {
  OneToOne = 'oneToOne',
  Group = 'group'
}

export enum RateType {
  PerSession = 'perSession',
  PerAttendee = 'perAttendee'
}

//////////////////////////
// APPOINTMENT TYPE /////
//////////////////////////

export interface AutoFitSelection {
  isActive: boolean;
  startTime: string;
  endTime: string;
}

export type FixedTimeSelection = {
  isActive: boolean;
  startTimes: string[];
};

export interface AutoFitSelections {
  Monday: AutoFitSelection;
  Tuesday: AutoFitSelection;
  Wednesday: AutoFitSelection;
  Thursday: AutoFitSelection;
  Friday: AutoFitSelection;
  Saturday: AutoFitSelection;
  Sunday: AutoFitSelection;
}

export interface FixedTimeSelections {
  Monday: FixedTimeSelection;
  Tuesday: FixedTimeSelection;
  Wednesday: FixedTimeSelection;
  Thursday: FixedTimeSelection;
  Friday: FixedTimeSelection;
  Saturday: FixedTimeSelection;
  Sunday: FixedTimeSelection;
}

export interface BookingRulesChildProps {
  available: boolean;
  rule: Rule;
}

export interface DayTimeSelection {
  kind: ScheduleOption; // auto fit || fixed time
  autoFit?: AutoFitSelections;
  fixedTime?: FixedTimeSelections;
}

type PreferredRoomAvailability = AutoFitSelections;
export interface PreferredRoom {
  roomId: string;
  name: string;
  availability?: PreferredRoomAvailability;
}

export interface AppointmentType {
  id?: string; // This is due to a weird hardcode: src/pages/Calendar/components/CalendarSettings/components/AppointmentTypes/components/AppointmentDeliveryForm/constants.tsx
  _id?: string;
  clinicianId?: string;
  name: string;
  isActive: boolean;
  description?: string;
  duration?: number;
  gap?: number;
  rate?: number;
  rateType: RateType;
  deliveryOptions: DeliveryType[];
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  defaultVideoCallInstructions?: string; // set in account level rules, not saved in appointment type
  phoneCallInstructions?: string;
  sessionStartOption?: SessionStartOption;
  bookingRules?: {
    new: BookingRulesChildProps;
    existing: BookingRulesChildProps;
  };
  accountId?: string; // Edge account
  roomSettings?: {
    required: boolean;
    preferredRoom: PreferredRoom; // Room Info
  };
  roomAny: boolean;
  hidePublicRate?: boolean; // Option to hide the price
  assignedClinicians?: string[]; // Can be list of clinician
  dayTimeSelections: DayTimeSelection;
  assignmentMode?: AssignmentMode;
  paymentRequired?: boolean;
  firestoreId?: string;
  participantType: ParticipantType;
}

export interface CreatedAppointmentType extends AppointmentType {
  _id: string;
}
