import styles from './ReportTemplateCard.module.scss';
import classnames from 'classnames';
import { ReportTemplate, ReportTemplateStatus } from 'interfaces/Reports/reportTemplate';
import moment from 'moment';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import SwitchLoading from 'components/MaterialFieldComponent/MaterialToggleSwitch/SwitchLoading/SwitchLoading';
import { GenerateTemplateLoadingInterface } from '../../../../EditTab';

interface ReportTemplateCardProps {
  showSettings: boolean;
  reportTemplateData: ReportTemplate;
  onClickDeleteTemplate: (id: string) => void;
  onClickEditTemplate: (id: string) => void;
  onClickSelectTemplate: (templateData: ReportTemplate) => void;
  generateTemplateLoading: GenerateTemplateLoadingInterface;
}

const ReportTemplateCard = ({
  showSettings,
  reportTemplateData,
  onClickDeleteTemplate,
  onClickEditTemplate,
  onClickSelectTemplate,
  generateTemplateLoading
}: ReportTemplateCardProps) => {
  const { auth0ClinicianId } = useGetClinicianId();
  const reportTemplateId = reportTemplateData._id;
  const updatedDate = moment(reportTemplateData.updatedAt).format('DD/MM/YYYY');
  const authorName =
    reportTemplateData.createdBy?._id === auth0ClinicianId ? 'you' : reportTemplateData.createdBy?.name || '';

  const templateStatus: Record<ReportTemplateStatus, string> = {
    [ReportTemplateStatus.Draft]: 'Draft',
    [ReportTemplateStatus.Published]: 'Published'
  };

  const allowAction =
    !showSettings && reportTemplateData.status === ReportTemplateStatus.Published && !generateTemplateLoading.loading;

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={showSettings ? styles.settingsMenu : styles.settingsMenuHide}>
          <div className={styles.deleteBtn} onClick={() => onClickDeleteTemplate(reportTemplateId)}>
            <i className={`material-icons ${styles.deleteIcon}`}>delete</i>
          </div>
          <div className={styles.editBtn} onClick={() => onClickEditTemplate(reportTemplateId)}>
            <i className={`material-icons ${styles.editIcon}`}>edit</i>
          </div>
        </div>
        <div
          className={classnames(styles.detailsWrapper, allowAction && styles.cursor)}
          onClick={() => allowAction && onClickSelectTemplate(reportTemplateData)}
        >
          <div className={styles.details}>
            <div className={styles.label}>{reportTemplateData.title}</div>
            <div className={styles.desc}>
              {templateStatus[reportTemplateData.status]} by {authorName} on {updatedDate}
            </div>
          </div>
          {reportTemplateData.status === ReportTemplateStatus.Published && (
            <div className={!showSettings ? styles.selectMenu : styles.selectMenuHide}>
              <div className={styles.insertBtn}>
                <i className={`material-icons-outlined ${styles.arrowIcon}`}>arrow_forward</i>
              </div>
            </div>
          )}
        </div>
      </div>
      {generateTemplateLoading.templateId === reportTemplateId && generateTemplateLoading.loading && (
        <div className={styles.overlayContainer}>
          <div className={styles.overlayContent}>
            Applying Template
            <div className={styles.loading}>
              <SwitchLoading circleClass={styles.circle} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTemplateCard;
