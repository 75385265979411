import styles from './TimeAvailable.module.scss';
import DaySelection from './components/DaySelection/DaySelection';

interface TimeAvailableProps {
  dayHeader?: string;
  startTimeHeader?: string;
  endTimeHeader?: string;
}

const TimeAvailable = (props: TimeAvailableProps) => {
  const { dayHeader, startTimeHeader, endTimeHeader } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerCell}>
          <div className={styles.label}>{`${
            dayHeader && dayHeader.length > 0 ? dayHeader : 'Select day available'
          }`}</div>
        </div>
        <div className={styles.showOnDesktop}>
          <div className={styles.headerCell}>
            <div className={styles.label}>{`${
              startTimeHeader && startTimeHeader.length > 0 ? startTimeHeader : 'from'
            }`}</div>
          </div>
          <div className={styles.headerCell}>
            <div className={styles.label}>{`${endTimeHeader && endTimeHeader.length > 0 ? endTimeHeader : 'to'}`}</div>
          </div>
          <div className={styles.headerCell} />
        </div>
      </div>
      <DaySelection day="monday" />
      <DaySelection day="tuesday" />
      <DaySelection day="wednesday" />
      <DaySelection day="thursday" />
      <DaySelection day="friday" />
      <DaySelection day="saturday" />
      <DaySelection day="sunday" />
    </div>
  );
};

export default TimeAvailable;
