import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { FilterCheckListItem } from '../FilterCheckList/FilterCheckList';
import styles from './FilterCheckItem.module.scss';

interface FilterCheckItemProps {
  item: FilterCheckListItem;
  searchValue: string;
  selectedFilterList: FilterCheckListItem[];
  onChangeItem: (value: { item?: FilterCheckListItem; toggleAllValue?: boolean }) => void;
}

const FilterCheckItem = ({ item, searchValue, selectedFilterList, onChangeItem }: FilterCheckItemProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const isActive = selectedFilterList.find((i) => i._id === item._id);
  const matchedIndex = item.name.toLowerCase().indexOf(searchValue.toLowerCase());

  return (
    <div className={classNames(styles.item, isActive && styles.active, isEdgeAdminView && styles.admin)}>
      <div className={styles.text} onClick={(e) => e.bubbles && onChangeItem({ item })}>
        <CheckBox
          id={item._id}
          className={styles.checkBox}
          inputClassName={styles.checkBoxInput}
          value={!!isActive}
          onChange={() => onChangeItem({ item })}
        />
        <div
          className={classNames(styles.label, item.backgroundColor && styles.coloredLabel)}
          style={{ backgroundColor: item.backgroundColor, color: item.fontColor }}
        >
          {item.name.substring(0, matchedIndex)}
          <span className={styles.matchedValue}>
            {item.name.substring(matchedIndex, matchedIndex + searchValue.length)}
          </span>
          {item.name.substring(matchedIndex + searchValue.length)}
        </div>
      </div>
    </div>
  );
};

export default FilterCheckItem;
