import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import { Translation } from 'i18n/types';
import { AvailabilityTimeSlot, NoAvailabilityReason } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { getCurrentWeekAppointmentSlots } from '../../helpers/getCurrentWeekAppointmentSlots';
import styles from './DayCard.module.scss';

const MOMENTJS_FORMAT_TIME = 'HH:mm';
const MOMENTJS_FORMAT_START_TIME_FORMAT = 'hh:mm';
const MOMENTJS_FORMAT_END_TIME_FORMAT = 'hh:mm a';

export interface SelectedAppointmentSlot {
  date: string;
  startTime: string;
  endTime: string;
}

type DayCardProps = {
  isAvailabilitiesViewTypeSimple: boolean;
  data: ReturnType<typeof getCurrentWeekAppointmentSlots>['currentWeekAppointmentSlots'][number];
  selectedAppointmentSlot?: SelectedAppointmentSlot;
  onSelectSlot: (slot: SelectedAppointmentSlot) => void;
};

const noAvailabilityReasonTexts: Record<NoAvailabilityReason, Translation> = {
  [NoAvailabilityReason.ClinicianBusy]: 'calendar.no_availability_reason.clinicion_busy',
  [NoAvailabilityReason.IsPublicHoliday]: 'calendar.no_availability_reason.is_public_holiday',
  [NoAvailabilityReason.MaxForwardAvailability]: 'calendar.no_availability_reason.max_forward_availability',
  [NoAvailabilityReason.MaxSessionReached]: 'calendar.no_availability_reason.max_session_reached',
  [NoAvailabilityReason.MinTimeBufferBeforeBooking]: 'calendar.no_availability_reason.min_time_buffer_before_booking',
  [NoAvailabilityReason.NotInAppointmentSchedule]: 'calendar.no_availability_reason.not_in_appointment_schedule',
  [NoAvailabilityReason.NotInClinicianSchedule]: 'calendar.no_availability_reason.not_in_clinician_schedule',
  [NoAvailabilityReason.NotInRoomSchedule]: 'calendar.no_availability_reason.not_in_room_schedule',
  [NoAvailabilityReason.RoomBusy]: 'calendar.no_availability_reason.room_busy',
  [NoAvailabilityReason.CannotFitGap]: 'calendar.no_availability_reason.cannot_fit_gap',
  [NoAvailabilityReason.TimeHasPassed]: 'calendar.no_availability_reason.time_has_passed'
};

const DayCard = ({ isAvailabilitiesViewTypeSimple, selectedAppointmentSlot, data, onSelectSlot }: DayCardProps) => {
  const date = moment(data.date, MOMENTJS_FORMAT_DATE);
  const formattedDate = date.format(MOMENTJS_FORMAT_DATE);

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const [t] = useTranslation();

  const mapTimes = (timeSlots: AvailabilityTimeSlot[]) => {
    const mappedTimeSlots = timeSlots
      .map((timeSlot, index) => {
        if (isAvailabilitiesViewTypeSimple && !timeSlot.isAvailable) {
          return undefined;
        }

        const formattedStartTime = moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(
          MOMENTJS_FORMAT_START_TIME_FORMAT
        );
        const formattedEndTime = moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_END_TIME_FORMAT);

        return (
          <div
            className={styles.badgeWrapper}
            {...(!timeSlot.isAvailable && {
              'data-tip': noAvailabilityReasonTexts[timeSlot.noAvailabilityReason]
                ? t(noAvailabilityReasonTexts[timeSlot.noAvailabilityReason])
                : timeSlot.noAvailabilityReason,
              'data-for': `unavailable-reason-${formattedDate}`
            })}
          >
            <Button
              disabled={!timeSlot.isAvailable}
              key={index}
              variant="secondary"
              className={classNames(
                styles.timeButton,
                _.isEqual(selectedAppointmentSlot, {
                  date: formattedDate,
                  startTime: timeSlot.startTime,
                  endTime: timeSlot.endTime
                }) && styles.active
              )}
              onClick={() => {
                onSelectSlot({ date: formattedDate, startTime: timeSlot.startTime, endTime: timeSlot.endTime });
              }}
            >
              {`${formattedStartTime} to ${formattedEndTime}`}
            </Button>
          </div>
        );
      })
      .filter((timeComponent) => !!timeComponent);

    if (mappedTimeSlots.length === 0 && isAvailabilitiesViewTypeSimple) {
      return <div className={styles.noAvailability}>No availability</div>;
    }

    return mappedTimeSlots;
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.header, {
          [styles.active]: selectedAppointmentSlot?.date === formattedDate
        })}
      >
        <div className={styles.date}>{date.format('dddd,')}</div>
        <div className={styles.date}>{date.format('Do MMMM')}</div>
      </div>
      <div className={styles.body}>
        {'isAvailable' in data && data.isAvailable ? (
          mapTimes(data.timeSlots)
        ) : !isAvailabilitiesViewTypeSimple ? (
          <div
            data-tip={t(
              noAvailabilityReasonTexts[
                'noAvailabilityReason' in data ? data.noAvailabilityReason : NoAvailabilityReason.TimeHasPassed
              ]
            )}
            data-for={`unavailable-reason-${formattedDate}`}
            className={styles.badgeWrapper}
          >
            <Button disabled variant={'secondary'} className={styles.timeButton}>
              No availability
            </Button>
          </div>
        ) : (
          <div className={styles.noAvailability}>No availability</div>
        )}
      </div>
      <ReactTooltip disable={isAvailabilitiesViewTypeSimple} id={`unavailable-reason-${formattedDate}`} />
    </div>
  );
};

export default DayCard;
