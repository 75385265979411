import { useState, Dispatch, SetStateAction } from 'react';
import { useFormikContext } from 'formik';

import FormikInput from 'components/Input/FormikInput';
import SubmitButton from '../SubmitButton/SubmitButton';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './GetStarted.module.scss';

interface GetStartedProps {
  allowResendEmail?: boolean;
  setHideProgressBar: Dispatch<SetStateAction<boolean>>;
}

const GetStarted = ({ allowResendEmail, setHideProgressBar }: GetStartedProps) => {
  const { submitForm } = useFormikContext<{ email: string }>();

  const [notReceivedLink, setNotReceivedLink] = useState(false);

  const onClickNotReceivedLink = () => {
    setHideProgressBar(true);
    setNotReceivedLink(true);

    if (!allowResendEmail) {
      submitForm();
    }
  };

  return (
    <div className={styles.container}>
      {!notReceivedLink ? (
        <div className={styles.box}>
          <div className={styles.header}>
            <div className={styles.iconWrapper}>
              <div className={styles.iconComplete}>
                <FontAwesomeIcon icon={faCheck} className={styles.checkmark} />
              </div>
            </div>
            <div className={styles.title}>Account Set Up Initiated</div>
          </div>
          <div className={styles.desc}>
            <div className={styles.description}>
              <p>Thank you for completing the first part of your School account creation.</p>
              <p>Our team will now be in touch to configure the rest of the detail required for your school.</p>
              <p>
                For the last part of securing your account we have sent you a one-time verification link via email.
                Please click the verification link to complete your verification.
              </p>
              <p>
                Thanks,
                <br />
                CORDS team
              </p>
            </div>
            <div className={styles.linkWrapper}>
              <span onClick={onClickNotReceivedLink}>I haven't received an email </span>{' '}
              <span className="material-icons-outlined">chevron_right</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.box}>
          {allowResendEmail && (
            <>
              <p>Please check and upate your email address if incorrect.</p>
              <FormikInput className="fullWidth" label="Email" name="resendEmail" noSpacing required />
              <SubmitButton style={{ display: 'flex', alignItems: 'center' }} variant="primary" type="submit">
                <span className="material-icons-outlined">send</span>&nbsp;&nbsp;&nbsp;Resend email
              </SubmitButton>
            </>
          )}
          <p>Still not landed in your inbox?</p>
          <p>Please check your ‘Junk’ or ‘Spam’ folder to ensure it didn’t end up there.</p>
          <p>
            Else please contact us to{' '}
            <a style={{ fontSize: 14, textDecoration: 'underline' }} href="mailto:support@mentalhealthhub.com">
              report this issue
            </a>
            (support@mentalhealthhub.com).
          </p>
        </div>
      )}
    </div>
  );
};

export default GetStarted;
