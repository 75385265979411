import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { Collaborator, Report } from 'interfaces/Reports/report';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetchClinician } from 'utils/hooks/clinician';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchClientRecordList } from 'utils/hooks/GetClient/clientList';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  backToDraft,
  checkInEditSession,
  checkOutEditSession,
  deleteReport,
  getReport,
  getReportByAdmin,
  getReportContactDetails,
  getSignature,
  postReport,
  putPublish,
  putReport,
  putUnpublish,
  requestReview,
  submitReportReview
} from 'utils/http/DocumentService/Reports/reports';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { scrollToView } from 'utils/scrollToView';

import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle';
import { getClinician } from '../../../utils/http/clinician';
import ReportBuilderPreview from '../components/ReportBuilderPreview/ReportBuilderPreview';
import ReportBuilder from './components/ReportBuilder/ReportBuilder';
import styles from './components/ReportBuilder/ReportBuilder.module.scss';
import { getClinicianDetails, initReportDataFunc } from './constants';
import { Session, Signature } from './interface';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';

const useFetchSignature = (token: string) => {
  const [signature, setSignature] = useState<Signature>();
  const [isSignatureLoading, setIsSignatureLoading] = useState(true);

  const fetchSignature = async (token: string) => {
    try {
      const signature = await (await getSignature(token)).json();

      setSignature(signature);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to fetch your signature' });
    }

    setIsSignatureLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchSignature(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { signature, isSignatureLoading };
};

const useFetchContactDetails = (token: string, user: any) => {
  const [contactDetailsData, setContactDetailsData] = useState<Report['contactDetails']>();
  const [isContactDetailsLoading, setIsCDLoading] = useState(true);

  const fetchContactDetails = async (token: string) => {
    try {
      const contactDetailData = await (await getReportContactDetails(token)).json();
      if (contactDetailData.contactDetails) {
        const practiceData = contactDetailData.contactDetails && contactDetailData.contactDetails.practice;
        const clinicianData = contactDetailData.contactDetails && contactDetailData.contactDetails.clinician;

        const contactDetailsValue = {
          practice: {
            address: {
              value: practiceData ? practiceData.address.value : '',
              isVisible: practiceData ? practiceData.address.isVisible : false
            },
            mobileNumber: {
              value: practiceData ? practiceData.mobileNumber.value : '',
              isVisible: practiceData ? practiceData.mobileNumber.isVisible : false
            }
          },
          clinician: {
            mobileNumber: {
              value: clinicianData ? clinicianData.mobileNumber.value : '',
              isVisible: clinicianData ? clinicianData.mobileNumber.isVisible : false
            },
            email: {
              value: clinicianData ? clinicianData.email.value : '',
              isVisible: clinicianData ? clinicianData.email.isVisible : false
            }
          }
        };
        setContactDetailsData(contactDetailsValue);
      } else {
        const clinicianId = user.sub;
        const callGetClinician = await getClinician(clinicianId);
        const clinicianData = await callGetClinician.json();
        const { avatar, name, practice, title } = clinicianData;
        const { email } = user;
        const mobileNumber = user['https://tacklit.com/mobile_number'];
        const firstPractice = practice ? practice : undefined;
        const clinicianDetail = {
          clinician: {
            avatar,
            email,
            mobileNumber,
            name: `${title || ''}${name ? ` ${name}` : ''}`
          },
          practice: firstPractice
            ? {
                address: firstPractice?.locations?.[0],
                logo: firstPractice?.logo,
                mobileNumber: firstPractice?.mobileNumber,
                name: firstPractice?.name
              }
            : undefined
        };

        const contactDetailsValue = {
          practice: {
            address: {
              value: clinicianDetail.practice ? clinicianDetail.practice.address : '',
              isVisible: clinicianDetail.practice ? !!clinicianDetail.practice.address : false
            },
            mobileNumber: {
              value: clinicianDetail.practice ? clinicianDetail.practice.mobileNumber : '',
              isVisible: clinicianDetail.practice ? !!clinicianDetail.practice.mobileNumber : false
            }
          },
          clinician: {
            mobileNumber: {
              value: clinicianDetail.clinician.mobileNumber ? clinicianDetail.clinician.mobileNumber : '',
              isVisible: clinicianDetail.clinician.mobileNumber ? !!clinicianDetail.clinician.mobileNumber : false
            },
            email: {
              value: clinicianDetail.clinician.email ? clinicianDetail.clinician.email : '',
              isVisible: clinicianDetail.clinician.email ? !!clinicianDetail.clinician.email : false
            }
          }
        };
        setContactDetailsData(contactDetailsValue);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to fetch your report contact details' });
    }

    setIsCDLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchContactDetails(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { contactDetailsData, isContactDetailsLoading };
};

const initValidation = {
  clientRecord: false,
  reportName: false,
  items: false,
  clinicianSignature: false,
  reviewers: false
};

const ReportDetails = () => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const location = useLocation();
  const path = useParams() as { reportId: string };
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeUser } = useGetAccountPackageView();
  const isNewReport = location.pathname.includes('newReport');

  const { clientList, isClientListLoading } = useFetchClientRecordList(token, false, true);
  const { clinician, isClinicianLoading } = useFetchClinician(user?.sub);
  const clinicianDetails = getClinicianDetails(user, clinician);
  const { signature, isSignatureLoading } = useFetchSignature(token);
  const { contactDetailsData, isContactDetailsLoading } = useFetchContactDetails(token, user);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const queryParam: { reportName?: string; clientRecordId?: string; refetch?: string } = queryString.parse(
    location.search
  );

  const [reportData, setReportData] = useState({} as any);
  const [validation, setValidation] = useState(initValidation as any);
  const [saveDraftStatus, setSaveDraftStatus] = useState<'' | 'saving' | 'saved'>('');
  const [draftBtnStatus, setDraftBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [actionBtnStatus, setActionDraftBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [reportId, setReportId] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isWaitingForSavingNewReport, setIsWaitingForSavingNewReport] = useState(false);
  const [session, setSession] = useState<Session>();
  const [applyReportTemplateId, setApplyReportTemplateId] = useState<string>('');

  const [t] = useTranslation();

  const fetchReportData = async () => {
    setLoading(true);
    try {
      if (token) {
        const getReportResponse =
          isEdgeAdminView || isEdgeReceptionist
            ? await getReportByAdmin(token, path.reportId)
            : await getReport(token, path.reportId);
        const reportData = await getReportResponse.json();
        let massageReportData = {
          ...reportData,
          isEditing: true
        };

        const clientRecord = clientList.find((client) => client._id === reportData?.clientRecord?._id);
        if (clientRecord) {
          massageReportData.clientRecord = clientRecord;
        }
        setReportData(massageReportData);
      }
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 404) {
        notification.error({ message: 'Report not found.' });
        navigate(REPORTS.BASE);
      } else {
        notification.error({ message: 'Something went wrong while trying to get this report details data.' });
      }
    } finally {
      setLoading(false);
    }
    setReportId(path.reportId);
  };

  const refetchReportItems = async () => {
    try {
      if (token) {
        const getReportResponse =
          isEdgeAdminView || isEdgeReceptionist
            ? await getReportByAdmin(token, path.reportId)
            : await getReport(token, path.reportId);
        const newReportData = await getReportResponse.json();
        setReportData({ ...reportData, items: newReportData.items });
      }
      notification.info({ message: 'Report content updated.' });
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get updated report content' });
    }
  };

  useEffect(() => {
    if (isNewReport) {
      const initReportData = initReportDataFunc(
        queryParam && queryParam.reportName ? queryParam.reportName : '',
        isEdgeAdminView
      );
      setReportData(initReportData);
      setReportId('newReport');
      setLoading(false);
    } else if (clientList && clientList.length > 0 && queryParam.refetch !== 'false') {
      fetchReportData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientList, path.reportId]);

  const handleSaveReport = async (draftData: any, updateBtnStatus?: boolean) => {
    setSubmitting(true);
    if (updateBtnStatus) {
      setDraftBtnStatus('active');
    }
    if (reportId === 'newReport') {
      try {
        const createdReport = await (await postReport(token, draftData)).json();
        navigate(`${REPORTS.BASE}/${createdReport._id}?refetch=false`);
        setReportId(createdReport._id);
        setReportData(createdReport);
        if (updateBtnStatus) {
          setDraftBtnStatus('finished');
        }
        setDraftBtnStatus('');
        setSubmitting(false);
        setIsWaitingForSavingNewReport(false);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to save your report.' });
        setDraftBtnStatus('');
        setSubmitting(false);
        setIsWaitingForSavingNewReport(false);
      }
    } else {
      try {
        // remove these properties to avoid overwriting
        const massagedDraftData = {
          ...draftData,
          status: undefined,
          reviewers: !draftData.approvalRequired ? [] : undefined,
          review: undefined,
          editedByReviewer: undefined,
          comments: undefined,
          statusHistory: undefined,
          sessionLock: undefined
        };
        const updatedReport = await (await putReport(token, reportId, massagedDraftData)).json();
        if (updateBtnStatus) {
          setDraftBtnStatus('finished');
        }
        setSession({ ...updatedReport.sessionLock.by, isOwner: true });
        setDraftBtnStatus('');
        setSubmitting(false);
      } catch (ex) {
        notification.error({
          message:
            isErrorBentStatusError(ex) && ex.statusCode === 409
              ? 'This report has been published. To continue editing please revert the status back to edit mode'
              : 'Something went wrong while trying to update your report.'
        });
        console.error(ex);
        setDraftBtnStatus('');
        setSubmitting(false);
      }
    }
    setSaveDraftStatus(Object.keys(draftData).length !== 0 ? 'saved' : '');
  };

  const onClickSaveDraft = async () => {
    const data = {
      ...reportData,
      lastUpdatedTime: new Date(),
      isEditing: true
    };
    if (reportId === 'newReport') {
      if (!isSubmitting) {
        setReportData(data);
        if (validateField()) {
          await handleSaveReport(reportData, true);
        }
      }
    } else {
      setReportData(data);
      if (validateField()) {
        await handleSaveReport(reportData, true);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      async (draftData: Report) => {
        if (
          draftData.reportName.length > 1 &&
          draftData.clientRecord?._id &&
          draftData.isEditing &&
          draftData.items.length > 0
        ) {
          await handleSaveReport(draftData);
        }
      },
      reportId === 'newReport' ? 100 : 3000
    ),
    [reportId, token]
  );

  useEffect(() => {
    setReportId(location.pathname.includes('newReport') ? 'newReport' : path.reportId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, location]);

  useEffect(() => {
    const clinicianSignature = signature?.signature && {
      type: signature.signature.type,
      textVal: signature.signature.type === 'typeVer' ? signature.signature.value : '',
      drawVal: signature.signature.type === 'drawVer' ? signature.signature.value : ''
    };

    const clientRecord = clientList && clientList.find((client) => client._id === queryParam.clientRecordId);

    const preLoadData = {
      ...reportData,
      ...(clientRecord && { clientRecord }),
      ...(contactDetailsData && { contactDetails: isNewReport ? contactDetailsData : reportData.contactDetails }),
      ...(clinician && { clinician: clinician, practice: clinician.practice ? clinician.practice : {} }),
      ...(clinicianSignature && { clinicianSignature }),
      ...(signature?.extraDetails && { clinicianSignatureExtraDetails: signature.extraDetails })
    };

    setReportData(preLoadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature, clientList, clinician, contactDetailsData]);

  const debouncedCheckOutSession = useMemo(() => debounce(() => setSession(undefined), 300000), []);

  useEffect(() => {
    if (session) {
      debouncedCheckOutSession();
    }
  }, [session, debouncedCheckOutSession]);

  const draftData = (itemObj: any) => {
    const data = {
      ...reportData,
      ...itemObj,
      lastUpdatedTime: new Date(),
      isEditing: true
    };
    setReportData(data);
    if (!isSubmitting && saveDraftStatus !== 'saving' && isNewReport) {
      setSaveDraftStatus(Object.keys(reportData).length !== 0 ? 'saving' : '');
      debouncedSave(data);
    } else if (!isNewReport) {
      setSaveDraftStatus(Object.keys(reportData).length !== 0 ? 'saving' : '');
      debouncedSave(data);
    }
  };

  const onChangeReportName = (nameVal: string) => {
    const data = {
      reportName: nameVal
    };
    draftData(data);
    setValidation({
      ...validation,
      reportName: !(nameVal.length > 0)
    });
  };

  const onChangeApprovalRequired = (bool: boolean) => {
    if (isEdgeUser) {
      const data = {
        approvalRequired: bool
      };
      draftData(data);
    }
  };

  const onSelectClient = (clientVal: any, cleanData?: boolean) => {
    const data = {
      clientRecord: clientVal,
      items: cleanData ? [] : reportData.items
    };
    draftData(data);
    setValidation({
      ...validation,
      clientRecord: !(clientVal._id.length > 0)
    });
  };

  const onChangeSignature = (signVal: any, extraDetailsVal: any) => {
    const data = {
      clinicianSignature: signVal,
      clinicianSignatureExtraDetails: extraDetailsVal
    };
    draftData(data);
    setValidation({
      ...validation,
      clinicianSignature: !Object.keys(signVal).length
    });
  };

  const onChangeContactDetails = (contactDetailsVal: any) => {
    const data = {
      contactDetails: contactDetailsVal
    };
    draftData(data);
  };

  const onChangeDndHeight = ({ height, width }: any) => {
    if (reportData.template.dimensions.height !== height || reportData.template.dimensions.width !== width) {
      const data = {
        template: {
          dimensions: {
            height: height,
            width: width
          }
        }
      };
      draftData(data);
    }
  };

  const onChangeItems = (itemsVal: any) => {
    const data = {
      items: itemsVal
    };
    draftData(data);
    setValidation({
      ...validation,
      items: !(itemsVal.length > 0)
    });
  };

  const onHandleReportTemplate = (newTemplateItems: ReportTemplate['items'], applyTemplateId: string) => {
    const newGroupItems = [...reportData.items, ...newTemplateItems];

    const data = {
      items: newGroupItems
    };

    draftData(data);
    setApplyReportTemplateId(applyTemplateId);
    if (reportId === 'newReport') {
      setIsWaitingForSavingNewReport(true);
    }
    setValidation({
      ...validation,
      items: !(newGroupItems.length > 0)
    });
  };

  const validateField = () => {
    if (!reportData.reportName) {
      notification.error({
        message: 'Please enter name of report'
      });
      setValidation({
        ...validation,
        reportName: true
      });
      scrollToView('nameOfReport');
      return false;
    }

    if (!Object.keys(reportData.clientRecord || {}).length) {
      notification.error({
        message: t('label.please_select_client')
      });
      setValidation({
        ...validation,
        clientRecord: true
      });
      scrollToView('selectClientModal');
      return false;
    }

    if (!reportData.clinicianSignature.type) {
      notification.error({
        message: 'Please sign the signature'
      });
      setValidation({
        ...validation,
        clinicianSignature: true
      });
      scrollToView('signatureSection');
      return false;
    }

    if (reportData.items.length < 1) {
      notification.error({
        message: 'Please add item to report'
      });
      setValidation({
        ...validation,
        items: true
      });
      scrollToView('dndContentSection');
      return false;
    }

    scrollToView('reportView', true);
    return true;
  };

  const onChangeSession = async (bool: boolean) => {
    try {
      if (bool) {
        const [newSession] = await Promise.all([
          (await checkInEditSession(token, reportId)).json(),
          refetchReportItems()
        ]);
        setSession({ ...newSession.by, isOwner: true });
      } else {
        await checkOutEditSession(token, reportId);
        setSession(undefined);
      }
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 423) {
        const newSession = (await ex.json()) as { by: Collaborator };
        setSession({ ...newSession.by, isOwner: false });
        notification.warning({
          message: `Someone else has checked-in the report.`,
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({
          message: `Something went wrong while trying to check-${bool ? 'in' : 'out'} the report.`
        });
        console.error(ex);
        fetchReportData();
      }
    }
  };

  const onPublish = async () => {
    if (validateField()) {
      setActionDraftBtnStatus('active');
      if (!reportData.approvalRequired) {
        await handleSaveReport(reportData);
      }
      try {
        await putPublish(token, reportId);
        setActionDraftBtnStatus('finished');
        notification.success({
          message: 'Report has been published.',
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
          notification.warning({
            message: 'Report status out of sync, refreshing page.',
            duration: 2,
            closeIcon: <span className="notify">OK</span>
          });
        } else {
          notification.error({ message: 'Something went wrong while trying to publish your report.' });
          console.error(ex);
        }
      }
      setActionDraftBtnStatus('');
      fetchReportData();
    }
  };

  const onRequestReview = async (reviewers: Collaborator[]) => {
    if (reviewers.length > 0 && validateField()) {
      setActionDraftBtnStatus('active');
      await handleSaveReport(reportData);
      try {
        await requestReview(token, reportId, reviewers);
        setActionDraftBtnStatus('finished');
        notification.success({
          message: 'Report has been shared for review.',
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to share for review.' });
        console.error(ex);
      }
      setActionDraftBtnStatus('');
      fetchReportData();
    } else {
      setValidation({
        ...validation,
        reviewers: true
      });
    }
  };

  const onUnpublish = async () => {
    try {
      await putUnpublish(token, reportId);
      notification.success({
        message: 'Report has been unpublished.',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
        notification.warning({
          message: 'Report status out of sync, refreshing page.',
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to unpublish the report.' });
        console.error(ex);
      }
    }
    fetchReportData();
  };

  const onDelete = async () => {
    try {
      await deleteReport(token, reportId);
      notification.success({
        message: 'Report has been deleted.',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
      navigate(REPORTS.BASE);
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 400) {
        notification.warning({
          message: 'Unable to delete published report.',
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to delete the report.' });
        console.error(ex);
      }
      fetchReportData();
    }
  };

  const onBackToEditMode = async () => {
    try {
      await backToDraft(token, reportId);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to back to editing mode. Please try again.'
      });
      console.error(ex);
    }
    fetchReportData();
  };

  const onSubmitReview = async ({ reviewType, message }: { reviewType: string; message?: string }) => {
    setActionDraftBtnStatus('active');
    try {
      await submitReportReview(token, reportId, { reviewType, message });
      setActionDraftBtnStatus('finished');
      notification.success({
        message: 'Review submitted.',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
        notification.warning({
          message: 'Report status out of sync, refreshing page.',
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to submit your review.' });
        console.error(ex);
      }
    }
    setActionDraftBtnStatus('');
    fetchReportData();
  };

  return (
    <HelmetWrapper title={'CORDS - Report & Letter Builder'}>
      <ContentLayout>
        <div id={'reportView'} />
        {isLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <>
            {!isPreviewMode ? (
              <DndProvider backend={HTML5Backend}>
                <ReportBuilder
                  clientRecordList={clientList}
                  onSelectedClient={onSelectClient}
                  isClientsLoading={isClientListLoading}
                  isClinicianLoading={isClinicianLoading}
                  isSignatureLoading={isSignatureLoading}
                  isContactDetailsLoading={isContactDetailsLoading}
                  clinicianDetails={clinicianDetails}
                  onChangeReportName={onChangeReportName}
                  data={reportData}
                  onChangeSignature={onChangeSignature}
                  onChangeContactDetails={onChangeContactDetails}
                  onChangeItems={onChangeItems}
                  onChangeDndHeight={onChangeDndHeight}
                  saveDraftStatus={saveDraftStatus}
                  draftBtnStatus={draftBtnStatus}
                  actionBtnStatus={actionBtnStatus}
                  onClickSaveDraft={onClickSaveDraft}
                  validation={validation}
                  session={session}
                  onChangeSession={onChangeSession}
                  onChangeApprovalRequired={onChangeApprovalRequired}
                  onPublish={onPublish}
                  onRequestReview={onRequestReview}
                  onUnpublish={onUnpublish}
                  onDelete={onDelete}
                  onBackToEditMode={onBackToEditMode}
                  onSubmitReview={onSubmitReview}
                  onSelectReportTemplate={onHandleReportTemplate}
                  autoAlignTemplateId={isWaitingForSavingNewReport ? '' : applyReportTemplateId}
                />
              </DndProvider>
            ) : (
              <ReportBuilderPreview
                data={reportData}
                reportId={reportId}
                title={'Report & Letter Builder'}
                token={token}
                backBtnLabel={'Back to editing mode'}
                clinicianDetails={clinicianDetails}
                isClinicianLoading={isClinicianLoading}
                onBackToEditingMode={() => setIsPreviewMode(false)}
              />
            )}
          </>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportDetails;
