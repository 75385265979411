import HeaderControl from 'components/TableList/HeaderControl/HeaderControl';
import { GroupDetailsTab, GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface PsychometricHeaderProps {
  groupId: string;
  psychometricId?: string;
  selectedClinicalAssessmentId?: string;
  psychometricMenuList?: GroupPsychometricMenu[];
}

const PsychometricHeader = ({
  groupId,
  psychometricId,
  selectedClinicalAssessmentId,
  psychometricMenuList
}: PsychometricHeaderProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const onGoToOtherClick = (id: string, clinicalAssessmentId?: string) => {
    navigate(
      `${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Psychometrics}/${id}/clinicalAssessmentId/${clinicalAssessmentId}`
    );
    const element = document.getElementById(`${id}_${clinicalAssessmentId}`);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  const { currentPsychometric, previousPsychometric, nextPsychometric } = useMemo(() => {
    const psychometricHeaderItems: {
      currentPsychometric?: GroupPsychometricMenu;
      previousPsychometric?: GroupPsychometricMenu;
      nextPsychometric?: GroupPsychometricMenu;
    } = { currentPsychometric: undefined, previousPsychometric: undefined, nextPsychometric: undefined };

    if (psychometricMenuList) {
      const filterIsSentOnly = psychometricMenuList.filter((obj) => obj.isSent);
      const currentAssessment = psychometricMenuList.find(
        (psychometricItem) =>
          psychometricItem._id === psychometricId &&
          psychometricItem.clinicalAssessmentId === selectedClinicalAssessmentId
      );

      if (currentAssessment) {
        psychometricHeaderItems.currentPsychometric = currentAssessment;

        const currentIndex = filterIsSentOnly.indexOf(currentAssessment);

        if (currentIndex > -1) {
          psychometricHeaderItems.previousPsychometric = filterIsSentOnly[currentIndex - 1];
          psychometricHeaderItems.nextPsychometric = filterIsSentOnly[currentIndex + 1];
        }
      }
    }

    return psychometricHeaderItems;
  }, [psychometricId, psychometricMenuList, selectedClinicalAssessmentId]);

  return (
    <HeaderControl
      isShowDate
      current={currentPsychometric}
      previous={previousPsychometric}
      next={nextPsychometric}
      onGoToOtherClick={onGoToOtherClick}
    />
  );
};

export default PsychometricHeader;
