import bent, { BentResponse } from 'bent';
import { AppointmentTypeAvailabilities } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import { httpClient } from '../httpClient';
import { generateAuthorizationHeader } from './helpers';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAvailabilityByDate = (date: string, type: 'booking' | 'reschedule', token: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/availability?date=${date}&type=${type}`);

export const getAvailabilityByDateRange = (from: string, to: string, type: 'booking' | 'reschedule', token: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/availability?date=${from}&to=${to}&type=${type}`) as Promise<BentResponse>;

export const getAppointmentTypeAvailabilities = ({
  token,
  from,
  to,
  type,
  appointmentTypeId,
  clinicianId,
  selectedRoomId
}: {
  token: string;
  from: string;
  to: string;
  type: string;
  appointmentTypeId: string;
  clinicianId: string;
  selectedRoomId?: string;
}) => {
  const request = bent<AppointmentTypeAvailabilities>(url!, 'GET', generateAuthorizationHeader(token), 'json', 200);
  return request(
    `/appointment-types/${appointmentTypeId}/availabilities?date=${from}&to=${to}&clinicianId=${clinicianId}&type=${type}${
      selectedRoomId ? `&selectedRoomId=${selectedRoomId}` : ''
    }`
  );
};
