export enum ReportStatus {
  Draft = 'draft',
  InReview = 'inReview',
  Approved = 'approved',
  Published = 'published'
}

export interface ReportCollaborator {
  clinicianAuth0Id: string;
  name: string;
  avatar: string;
  notificationDismissedAt?: Date | string;
}

export enum ReviewType {
  Feedback = 'feedback',
  Approved = 'approved',
  ChangesRequested = 'changesRequested'
}

export interface ReportStatusHistory {
  status: ReportStatus;
  statusChangedAt: Date | string;
  statusChangedBy: ReportCollaborator;
  review?: {
    reviewType: ReviewType;
    message?: string;
  };
}

export interface ReportNotification {
  _id: string;
  reportName: string;
  statusHistory: ReportStatusHistory;
}
