import styles from './SwitchLoading.module.scss';
import classnames from 'classnames';

interface SwitchLoadingProps {
  isCheck?: boolean;
  circleClass?: string;
}

const SwitchLoading = ({ isCheck, circleClass }: SwitchLoadingProps) => {
  return (
    <svg className={styles.container}>
      <circle
        className={classnames(isCheck ? styles.checkCircle : styles.circle, circleClass)}
        cx={13}
        cy={13}
        r={7}
        fill={'none'}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
};

export default SwitchLoading;
