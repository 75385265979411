import { clientProfilesInterface, clientRecordsInterface } from '../Clients/clientsRecord';

export interface ReportDimension {
  width: number | string;
  height: number | string;
}

export interface Report {
  _id: string;
  status: string;
  reportName: string;
  lastUpdatedTime: Date;
  issueDate: string;
  approvalRequired?: boolean;
  isEditing?: boolean;
  isForPractice?: boolean;
  authorDetail?: {
    avatar?: string;
    email: string;
    name: string;
  };
  shareDetails: {
    sendAt?: Date | string;
    sendClient?: boolean;
    sendTo: string[];
    note?: string;
    accessors: {
      _id: string;
      email?: string;
      isClient?: boolean;
      uniqueLink: string;
      sentAt: Date | string;
    }[];
  };
  contactDetails: ReportContactDetails;
  template: {
    dimensions: ReportDimension;
  };
  clientRecord: ClientRecord;
  clinician: {
    _id: string;
    avatar: string;
    email: string;
    mobileNumber: string;
    name: string;
  };
  practice: {
    address: string;
    logo: string;
    mobileNumber: string;
    name: string;
  };
  clinicianSignature: {
    type: string;
    textVal: string;
    drawVal: string;
  };
  clinicianSignatureExtraDetails: {
    valediction: string;
    email: string;
    mobileNumber: string;
    name: string;
    other: string;
  };
  items: {
    id: string;
    position: {
      top: number;
      left: number;
    };
    dimensions: {
      width: number | string;
      height: number | string;
    };
    details: any;
  }[];
  comments?: {
    _id: string;
    comments: {
      content: string;
      commentedBy: Collaborator;
      commentedAt: string;
    }[];
    position: {
      top: number;
      left: number;
    };
  }[];
  reviewers?: Collaborator[];
  statusHistory?: {
    status: string;
    statusChangedAt: Date | string;
    statusChangedBy: Collaborator;
    review?: {
      reviewType: string;
      message?: string;
    };
  }[];
  review?: {
    reviewType: string;
    message?: string;
  };
  editedByReviewer?: boolean;
  sessionLock?: {
    by: Collaborator;
    expiredAt: Date | string;
  };
}

export interface ReportContactDetails {
  practice: {
    address: {
      value: string;
      isVisible: boolean;
    };
    mobileNumber: {
      value: string;
      isVisible: boolean;
    };
  };
  clinician: {
    mobileNumber: {
      value: string;
      isVisible: boolean;
    };
    email: {
      value: string;
      isVisible: boolean;
    };
  };
}

interface ClientRecord {
  _id: string;
  recordType: clientRecordsInterface['recordType'];
  clientProfiles: clientProfilesInterface[];
  allowCommunicationWithEmail?: boolean;
  allowCommunicationWithSms?: boolean;
}

export interface ReportListInterface {
  _id: string;
  clientRecord: ClientRecord;
  clinician: {
    _id: string;
    avatar?: string;
    name?: string;
  };
  practice?: {
    logo: string;
    mobileNumber: string;
    name: string;
  };
  isForPractice?: boolean;
  reportName: string;
  createdAt: string;
  lastUpdatedTime: string;
  status: 'published' | 'approved' | 'inReview' | 'draft' | 'unpublished';
  shareDetails: shareDetails;
  editedByReviewer?: boolean;
  review: {
    reviewType: 'feedback' | 'approved' | 'changesRequested';
    message?: string;
  };
}

interface shareDetails {
  id: string;
  name: string;
  accessors: string[];
}

export interface ReportClientTab {
  _id: string;
  status?: string;
  reportName: string;
  lastUpdatedTime?: Date;
  createdAt: string;
  shareDetails?: {
    accessors: {
      _id: string;
      email?: string;
      isClient?: boolean;
      uniqueLink: string;
      sentAt: Date | string;
    }[];
  }[];
}

export interface SurveyClientTab {
  _id: string;
  status?: string;
  name: string;
  lastUpdatedTime?: Date;
  createdAt: string;
}

export interface Collaborator {
  clinicianAuth0Id: string;
  name: string;
  avatar: string;
  notificationDismissedAt?: string;
}

export enum ReportWidgetType {
  HeadingWidget = 'headingWidget',
  BodyTextWidget = 'bodyTextWidget',
  FootNotesTextWidget = 'footNotesTextWidget',
  JournalCommentWidget = 'journalCommentWidget',
  InsightsChartWidget = 'insightsChartWidget',
  BarLineChartWidget = 'barLineChartWidget',
  AssessmentWidget = 'assessmentWidget',
  BodyTextTemplateWidget = 'bodyTextTemplateWidget',
  AssessmentTemplateWidget = 'assessmentTemplateWidget',
  ClientProfileWidget = 'clientProfileWidget',
  NotesWidget = 'notesWidget',
  CaseNoteQuestionWidget = 'caseNoteQuestionWidget'
}

export enum ReportBuilderWidgetView {
  WidgetMenuView = 'WidgetMenuView',
  NotesWidgetView = 'notesWidgetView',
  PsychometricWidgetView = 'PsychometricWidgetView',
  JournalCommentWidgetView = 'journalCommentWidgetView',
  LiveChartWidgetView = 'liveChartWidgetView',
  ReportTemplateView = 'reportTemplateView'
}
