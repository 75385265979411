import { useEffect, useRef, useState } from 'react';

import styles from './HeadingWidget.module.scss';

interface HeadingWidgetProps {
  data: any;
  id?: any;
  changeValue?: any;
  previewMode?: boolean;
  editMode?: boolean;
}

const HeadingWidget = ({ data, id, changeValue, previewMode, editMode }: HeadingWidgetProps) => {
  const headingRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState(data.title);

  const [isShowInput, setShowInput] = useState(false);

  const handleClick = (e: any) => {
    if (headingRef.current?.contains(e.target)) {
      return;
    }
    setShowInput(false);
    if (changeValue && editMode) {
      changeValue(id, inputValue, false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onClickEditText = () => {
    setShowInput(true);
    changeValue(id, inputValue, true);
  };

  const onChangeValue = (event: any) => {
    setInputValue(event.target.value);
  };

  const onKeyUp = (keyCode: any) => {
    if (keyCode === 13) {
      changeValue(id, inputValue, false);
      setShowInput(false);
    }
  };

  if (previewMode) {
    return (
      <div className={styles.previewContainer}>
        <div className={styles.title}>{inputValue}</div>
      </div>
    );
  }

  return !isShowInput ? (
    <div className={styles.container} id={id} onClick={onClickEditText}>
      <div className={styles.title}>{inputValue}</div>
    </div>
  ) : (
    <div className={styles.editWrapper} ref={headingRef}>
      <div className={styles.editContainer} id={id}>
        <input
          className={styles.input}
          value={inputValue}
          onChange={(e) => onChangeValue(e)}
          onKeyUp={(e) => onKeyUp(e.keyCode)}
          autoFocus
        />
      </div>
    </div>
  );
};

export default HeadingWidget;
