import {
  ConsentFormResponseList,
  GroupDetailsId,
  GroupDetailsTab,
  GroupPsychometricMenu,
  Groups,
  GroupStatus
} from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import styles from './GroupContent.module.scss';
import Details from './components/Details/Details';
import Members from './components/Members/Members';
import GroupAssignment from './components/GroupAssignment/GroupAssignment';
import KeyContacts from './components/KeyContacts/KeyContacts';
import GroupCaseNote from './components/GroupCaseNote/GroupCaseNote';
import GroupAttachments from './components/GroupAttachments/GroupAttachments';
import SessionList from './components/Sessions/SessionList/SessionList';
import { ProfileInterface } from 'interfaces/Profile/Profile';

import Psychometrics from './components/Psychometrics/Psychometrics';
import PathwayManagement from './components/PathwayManagement/PathwayManagement';
import ConsentForms from './components/ConsentForms/ConsentForms';
import SurveyForms from './components/SurveyForms/SurveyForms';

export interface GroupContentProps {
  groupDetails: Groups;
  groupTagList: Groups['tags'];
  activeGroupTab: GroupDetailsId['groupTab'];
  profile: ProfileInterface;
  fetchGroupDetails: (token: string) => void;
  contentId?: string;
  childContentId?: string;
  allowEditAssigned: boolean;
  psychometricMenuList: GroupPsychometricMenu[];
  surveyFormMenuList: SurveyFormResponseList[];
  surveyResponseListLoading: boolean;
  isEdgeReceptionist: boolean;
  consentFormMenuList: ConsentFormResponseList[];
  isGroupDetailsLoading: boolean;
  refetchPsychometricList: () => void;
}

const GroupContent = ({
  groupDetails,
  groupTagList,
  activeGroupTab,
  profile,
  fetchGroupDetails,
  contentId,
  childContentId,
  allowEditAssigned,
  psychometricMenuList,
  surveyFormMenuList,
  surveyResponseListLoading,
  isEdgeReceptionist,
  consentFormMenuList,
  isGroupDetailsLoading,
  refetchPsychometricList
}: GroupContentProps) => {
  const getContentComponents = () => {
    switch (activeGroupTab) {
      case GroupDetailsTab.Details:
        return (
          <Details
            groupDetails={groupDetails}
            groupTagList={groupTagList}
            profile={profile}
            fetchGroupDetails={fetchGroupDetails}
          />
        );
      case GroupDetailsTab.Members:
        return <Members groupDetails={groupDetails} />;
      case GroupDetailsTab.Sessions:
        return <SessionList />;
      case GroupDetailsTab.PractitionerAssigned:
        return (
          allowEditAssigned && (
            <GroupAssignment
              groupDetail={groupDetails}
              fetchGroupDetails={fetchGroupDetails}
              isGroupDetailsLoading={isGroupDetailsLoading}
            />
          )
        );
      case GroupDetailsTab.CaseNotes:
        return (
          !isEdgeReceptionist && (
            <GroupCaseNote activeGroupTab={activeGroupTab} groupDetails={groupDetails} caseNoteId={contentId} />
          )
        );
      case GroupDetailsTab.Contacts:
        return <KeyContacts groupDetail={groupDetails} fetchGroupDetails={fetchGroupDetails} />;
      case GroupDetailsTab.PathwayManagement:
        return (
          !isEdgeReceptionist && <PathwayManagement groupDetail={groupDetails} fetchGroupDetails={fetchGroupDetails} />
        );
      case GroupDetailsTab.Psychometrics:
        return (
          <Psychometrics
            groupDetails={groupDetails}
            psychometricId={contentId}
            psychometricMenuList={psychometricMenuList}
            selectedClinicalAssessmentId={childContentId}
            onCreatePsychometricList={refetchPsychometricList}
          />
        );
      case GroupDetailsTab.Attachments:
        return (
          !isEdgeReceptionist && (
            <GroupAttachments groupId={groupDetails._id} isReadOnly={groupDetails.status === GroupStatus.Closed} />
          )
        );
      case GroupDetailsTab.Survey:
        return (
          <SurveyForms
            surveyFormMenuList={surveyFormMenuList}
            surveyId={contentId}
            surveyResponseListLoading={surveyResponseListLoading}
          />
        );

      case GroupDetailsTab.Consent:
        return <ConsentForms consentFormMenuList={consentFormMenuList} consentId={contentId} />;
    }
  };

  return <div className={styles.container}>{getContentComponents()}</div>;
};

export default GroupContent;
