import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { AppointmentSlot } from 'components/v2/AvailableTimeSelect/Interface';
import Button from 'components/v2/Button/Button';
import { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import _ from 'lodash';
import moment from 'moment';
import styles from './AppointmentFooter.module.scss';
import classnames from 'classnames';

type AppointmentFooterProps = {
  selectedParticipantType: ParticipantType;
  clinician?: Partial<ClinicianListInterface>;
  appointmentType?: AppointmentType;
  timeslot?: AppointmentSlot;
  buttonText?: string;
  showsClinician?: boolean;
  processing?: '' | 'active' | 'finished';
  submitForm: Function;
  selectedClientRecord?: clientRecordsInterface;
  selectedGroup?: GroupsFromAPI;
};

const AppointmentFooter = ({
  selectedParticipantType,
  clinician,
  appointmentType,
  timeslot,
  buttonText,
  showsClinician = false,
  processing = '',
  submitForm,
  selectedClientRecord,
  selectedGroup
}: AppointmentFooterProps) => {
  const checkSelectedData =
    selectedParticipantType === ParticipantType.Group
      ? !_.isEmpty(selectedGroup)
      : !_.isEmpty(selectedClientRecord?.clientProfiles);
  return (
    <>
      {clinician && checkSelectedData && !_.isEmpty(appointmentType) && (!showsClinician || !_.isEmpty(timeslot)) && (
        <div className={styles.container}>
          <div className={styles.label}>SETTING UP</div>
          <div className={styles.textAndButtonContainer}>
            <div className={styles.descContainer}>
              {showsClinician && (
                <div className={styles.cProfileWrapper}>
                  <ProfileBadge
                    name={clinician.name || ''}
                    avatar={clinician.avatar}
                    className={styles.cProfile}
                    nameClassName={styles.blueTextLarge}
                    noEllipsisName
                  />
                  <div className={styles.plusSign}>+</div>
                </div>
              )}
              {selectedParticipantType === ParticipantType.OneToOne ? (
                <ClientAvatar
                  avatarSize={36}
                  containerClassName={styles.clientProfile}
                  nameClassName={classnames(styles.blueTextLarge, styles.clientName)}
                  initialsClassName={styles.initialName}
                  horizontal
                  clientData={selectedClientRecord?.clientProfiles}
                  displayFirstNameOnly={selectedClientRecord?.recordType === 'couple'}
                  displayLimit={selectedClientRecord?.recordType === 'couple' ? 2 : 1}
                  fullAvatarBg
                />
              ) : (
                <div className={styles.blueTextLarge}>{selectedGroup?.name}</div>
              )}
              {showsClinician ? <div className={styles.divider}>&nbsp;</div> : <div className={styles.label}>WITH</div>}
              <div className={classnames(styles.appointmentTypeWrapper, styles.blueTextLarge)}>
                {appointmentType?.name}
              </div>
              {timeslot && (
                <>
                  <div className={styles.label}>ON</div>
                  <div className={classnames(styles.timeWrapper, styles.blueTextLarge)}>
                    {moment(`${timeslot.date} ${timeslot.startTime}`).format(`dddd Do MMMM hh:mm`)}
                    {' to '}
                    {moment(timeslot.endTime, 'HH:mm').format('hh:mm a')}
                  </div>
                </>
              )}
            </div>
            <div className={styles.buttonContainer}>
              <Button className={styles.button} status={processing} onClick={() => submitForm()}>
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmentFooter;
