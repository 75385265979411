import styles from './MemberFilter.module.scss';
import ToggleSwitchV2, { toggleListType } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { AttachedClientRecordStatus, InterventionMemberList } from 'MHH/pages/Interventions/Interfaces/Interventions';
import SearchBar from 'components/SearchBar/SearchBar';

interface MemberFilterProps {
  searchValue: string;
  interventionMembers: InterventionMemberList;
  isInterventionMembersLoading: boolean;
  selectedStatus: AttachedClientRecordStatus;
  handleChangeStatus: (statusValue: toggleListType) => void;
  handleSearch: (searchValue: string) => void;
}

const MemberFilter = ({
  searchValue,
  isInterventionMembersLoading,
  interventionMembers,
  selectedStatus,
  handleChangeStatus,
  handleSearch
}: MemberFilterProps) => {
  const TOGGLE_LIST = [
    {
      id: AttachedClientRecordStatus.All,
      label: 'All',
      count: interventionMembers.count.all,
      isActive: AttachedClientRecordStatus.All === selectedStatus
    },
    {
      id: AttachedClientRecordStatus.Active,
      label: 'Active',
      count: interventionMembers.count.active,
      isActive: AttachedClientRecordStatus.Active === selectedStatus
    },
    {
      id: AttachedClientRecordStatus.Completed,
      label: 'Complete',
      count: interventionMembers.count.completed,
      isActive: AttachedClientRecordStatus.Completed === selectedStatus
    },
    {
      id: AttachedClientRecordStatus.Archived,
      label: 'Archive',
      count: interventionMembers.count.archived,
      isActive: AttachedClientRecordStatus.Archived === selectedStatus
    }
  ];

  return (
    <div className={styles.container}>
      <ToggleSwitchV2
        id={'memberStatus'}
        toggleList={TOGGLE_LIST}
        onChangeStatus={handleChangeStatus}
        isLoading={isInterventionMembersLoading}
      />
      <SearchBar
        containerClassName={styles.search}
        placeholder={'Search by name or ID'}
        value={searchValue}
        withSearchButton
        onSearch={handleSearch}
        label={'SEARCH FOR:'}
      />
    </div>
  );
};

export default MemberFilter;
