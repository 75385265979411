import { Skeleton } from 'antd';
import classNames from 'classnames';

import styles from './WidgetsListMenu.module.scss';

interface WidgetsListMenuProps {
  onClickMenuBtn: any;
  menuItems: { id: string; label: string; count: number; isLoading: boolean }[];
}

const WidgetsListMenu = ({ onClickMenuBtn, menuItems }: WidgetsListMenuProps) => (
  <div className={styles.container}>
    {menuItems.map(({ id, label, count, isLoading }, index) => (
      <div
        key={index}
        className={classNames(styles.itemWrapper, count <= 0 && styles.disabled)}
        onClick={() => count && onClickMenuBtn(id)}
      >
        {isLoading ? (
          <Skeleton.Input className={styles.loading} active />
        ) : (
          <>
            <div className={styles.item}>
              <div className={styles.label}>{label}</div>
              {count > 0 && <div className={styles.count}>{count}</div>}
            </div>
            {count > 0 && <i className={`material-icons ${styles.arrowIcon}`}>navigate_next</i>}
          </>
        )}
      </div>
    ))}
  </div>
);

export default WidgetsListMenu;
