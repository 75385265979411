import { MinifiedClientProfile, MinifiedClientRecord } from 'interfaces/Clients/clientsRecord';
import { MinifiedClinician } from 'interfaces/Clients/clinician';

export enum AllAssessmentTabId {
  Completed = 'completed',
  Outstanding = 'outstanding'
}

export interface AllAssessmentFilters {
  name: string;
}

export enum OutstandingAssessmentType {
  Client = 'client',
  Clinician = 'clinician'
}

export interface OutstandingAssessment {
  _id: string;
  type: OutstandingAssessmentType;
  clientRecordId: string;
  clientProfileId?: string;
  clinicianId?: string;
  clinicalAssessmentIds: string[];
  createdAt: string;
  clientRecord: MinifiedClientRecord;
  clientProfile: MinifiedClientProfile;
  clinicianProfile?: MinifiedClinician;
}
