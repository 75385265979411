import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import BespokeForm from '../../../EventCreationModal/components/EventForm/components/BespokeForm/BespokeForm';
import styles from './EditBespokeForm.module.scss';

type EditBespokeFormProps = {
  appointment: AppointmentSlots;
  onEditComplete: () => void;
  handleUpdateAppointment: (payload: any, setSubmitStatus: Function) => Promise<void>;
};

const EditBespokeForm = ({ appointment, onEditComplete, handleUpdateAppointment }: EditBespokeFormProps) => {
  return (
    <div style={{ background: styles.whiteColor }}>
      <BespokeForm
        isEdit
        appointment={appointment}
        eventType="bespoke"
        setEventType={() => {}}
        onClose={onEditComplete}
        onCreateEditSuccess={onEditComplete}
        handleUpdateAppointment={handleUpdateAppointment}
      />
    </div>
  );
};

export default EditBespokeForm;
