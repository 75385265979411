import { useEffect, useState } from 'react';
import { GroupDetailsTab, GroupPsychometricDetailsId } from 'pages/Groups/Interfaces/Groups';
import { useNavigate, useParams } from 'react-router-dom';
import PsychometricDetailContent from './components/PsychometricDetailContent/PsychometricDetailContent';
import PsychometricDetailHeader from './components/PsychometricDetailHeader/PsychometricDetailHeader';
import styles from './PsychometricDetail.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchClientDetails } from '../../hooks/getClientDetails';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { clientProfilesEncrypted } from 'interfaces/Clients/clientsRecord';
import { useFetchPsychometricDetails } from '../../hooks/getPsychometricDetails';

const PsychometricDetail = () => {
  const { token } = useGetAccessToken();
  const path = useParams<{ [key in keyof GroupPsychometricDetailsId]: string | undefined }>();
  const { GROUPS } = useRoutesGenerator();
  const navigate = useNavigate();

  const groupId = path.groupId ?? '';
  const contentId = path.contentId ?? '';
  const childContentId = path.childContentId ?? '';
  const responseId = path.responseId ?? '';
  const clientRecordId = path.clientRecordId ?? '';

  const { clientEncryptDetails, clientEncryptDetailsLoading } = useFetchClientDetails(token, clientRecordId);
  const [clientProfile, setClientProfile] = useState<clientProfilesEncrypted[]>([]);

  const { psychometricDetails, isPsychometricDetailsLoading, fetchPsychometricDetails } = useFetchPsychometricDetails(
    token,
    responseId,
    clientRecordId
  );

  useEffect(() => {
    if (clientEncryptDetails && psychometricDetails) {
      const selectedProfile = clientEncryptDetails.clientProfiles.filter(
        (clientP) => clientP._id === psychometricDetails.clientProfileId
      );
      setClientProfile(selectedProfile);
    }
  }, [clientEncryptDetails, psychometricDetails]);

  useEffect(() => {
    if (token) {
      fetchPsychometricDetails(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const goBack = () => {
    navigate(
      `${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Psychometrics}/${contentId}/clinicalAssessmentId/${childContentId}`
    );
  };

  return (
    <div className={styles.container}>
      {clientEncryptDetailsLoading || isPsychometricDetailsLoading || !psychometricDetails ? (
        <div className={styles.loadingWrapper}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <PsychometricDetailHeader goBack={goBack} assessmentClientId={childContentId} clientProfile={clientProfile} />
          <PsychometricDetailContent psychometricDetails={psychometricDetails} />
        </>
      )}
    </div>
  );
};

export default PsychometricDetail;
