import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Skeleton, notification } from 'antd';
import noHistoryData from 'assets/images/no-history-data.svg';
import { useFetchCodatTransactions } from 'pages/ControlPanel/ControlPanel/hooks/getCodatData';
import { getProviderLabel } from 'pages/ControlPanel/IntegrationDetails/utils';
import { Integration } from 'pages/ControlPanel/Interfaces/Integration';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { postRetryTransactions } from 'utils/http/BillingService/Invoice/codat';

import HistoryTable from './components/HistoryTable/HistoryTable';
import styles from './IntegrationHistory.module.scss';

const FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Success', value: 'success' },
  { label: 'Failed', value: 'failed' },
  { label: 'Retried', value: 'retried' }
];

export enum TransactionFilter {
  All = 'all',
  Success = 'success',
  Failed = 'failed',
  Retried = 'retried'
}

const IntegrationHistory = ({ integration }: { integration: Integration }) => {
  const { token } = useGetAccessToken();
  const { id, provider } = integration;

  const [selectedFilter, setSelectedFilter] = useState<TransactionFilter>(TransactionFilter.All);
  const { isTransactionsLoading, codatTransactions, refetchCodatTransactions } = useFetchCodatTransactions(
    token,
    id,
    selectedFilter === 'all' ? undefined : selectedFilter
  );
  const [isRetryingTransaction, setIsRetryingTransaction] = useState(false);

  const callPostRetryTransactions = async (_id: string) => {
    setIsRetryingTransaction(true);

    try {
      await postRetryTransactions(token, id, _id);
      await refetchCodatTransactions();
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while retrying transaction' });
    }

    setIsRetryingTransaction(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>History</div>
        <div className={styles.desc}>This should be a chronological list of the elements sent in / out</div>
      </div>
      <div className={styles.filterContainer}>
        <div>Filter</div>
        <Dropdown
          overlay={
            <Menu selectedKeys={[selectedFilter]} onClick={({ key }) => setSelectedFilter(key as TransactionFilter)}>
              {FILTER_OPTIONS.map((option) => (
                <Menu.Item key={option.value}>
                  <div>{option.label}</div>
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <div className={styles.dropdownSelection} onClick={(e) => e.preventDefault()}>
            {FILTER_OPTIONS.find((option) => option.value === selectedFilter)?.label} <DownOutlined />
          </div>
        </Dropdown>
      </div>
      {isTransactionsLoading ? (
        <div className={styles.loadingContainer}>
          <Skeleton.Input className={styles.loading} active />
        </div>
      ) : codatTransactions.length === 0 && selectedFilter === TransactionFilter.All ? (
        <div className={styles.infoContainer}>
          <div className={styles.iconContainer}>
            <img src={noHistoryData} alt={`no-data-history-icon`} className={styles.icon} />
          </div>
          <div className={styles.messageContainer}>
            <div className={styles.message}>{`There are no transactions passed to ${getProviderLabel(
              provider
            )} yet.`}</div>
            <div className={styles.description}>Create an invoice to generate financial data to sync across.</div>
          </div>
        </div>
      ) : (
        <HistoryTable
          integration={integration}
          codatTransactions={codatTransactions}
          isRetryingTransaction={isRetryingTransaction}
          callPostRetryTransactions={callPostRetryTransactions}
          filter={selectedFilter}
        />
      )}
    </div>
  );
};

export default IntegrationHistory;
