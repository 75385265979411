import styles from './TaskItem.module.scss';
import { ActionPlanTask, ActionPlanTaskItem } from '../../../../../../interfaces';
import Button from 'components/v2/Button/Button';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

interface TaskItemProps {
  index: number;
  task: ActionPlanTaskItem;
  isSelected?: boolean;
  onChangeValues: (key: keyof ActionPlanTask, index: number, value: string | ActionPlanTask[]) => void;
  onDeleteTask: (index: number) => void;
  onEditTask: (index: number) => void;
  onSaveTask: (index: number) => void;
}

const TaskItem = ({ index, task, isSelected, onChangeValues, onDeleteTask, onEditTask, onSaveTask }: TaskItemProps) => {
  const handleDueDateChange = (value: Moment | null, index: number) => {
    if (value) {
      const dateString = value.format('YYYY-MM-DD');
      onChangeValues('dueDate', index, dateString);
    } else {
      onChangeValues('dueDate', index, '');
    }
  };

  return (
    <div className={styles.container}>
      {task.isEdit && !task.isCompleted ? (
        <div className={styles.editContainer}>
          <div className={classnames(styles.fieldContainer, task.errorMessage?.title && styles.fieldError)}>
            <MaterialInput
              id={`actionPlanTitle${index}`}
              label={'TITLE'}
              value={task.title}
              required
              onChange={(e) => onChangeValues('title', index, e.target.value)}
            />
            <div className={styles.fieldError}>{task.errorMessage?.title}</div>
          </div>
          <div className={styles.fieldContainer}>
            <MaterialInput
              id={`actionPlanDescription${index}`}
              label={'DESCRIPTION'}
              value={task.description}
              required
              onChange={(e) => onChangeValues('description', index, e.target.value)}
            />
          </div>
          <div className={styles.dueDateWrapper}>
            <div className={styles.label}>REVIEW/DUE DATE</div>
            <DatePicker
              format={'DD MMM YYYY'}
              className={styles.dateInput}
              suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
              value={task.dueDate ? moment(task.dueDate, 'YYYY-MM-DD') : undefined}
              onChange={(e) => handleDueDateChange(e, index)}
              allowClear={false}
            />
            <ErrorMessage
              className={styles.errorMargin}
              visible={!!task.errorMessage?.dueDate}
              error={task.errorMessage?.dueDate}
            />
          </div>
          <span className={styles.deleteButton} onClick={() => onDeleteTask(index)}>
            Delete task
          </span>
          <Button className={styles.addButton} disabled={false} onClick={() => onSaveTask(index)}>
            {`${task.isOld ? 'Update' : 'Add'} task`}
          </Button>
          <ErrorMessage
            className={styles.editModeError}
            visible={!!task.errorMessage?.editMode}
            error={task.errorMessage?.editMode}
          />
        </div>
      ) : (
        <div
          className={classnames(styles.viewContainer, isSelected && styles.selectedTask)}
          onClick={() => onEditTask(index)}
        >
          {task.isCompleted ? (
            <i className={`material-icons ${styles.completedIcon}`}>check_circle</i>
          ) : (
            <i className={`material-icons-outlined ${styles.notCompletedIcon}`}>circle</i>
          )}
          <div className={classnames(styles.description, task.isCompleted && styles.completed)}>
            <div className={styles.title}>{task.title}</div>
            <div className={styles.dueDate}>
              {task.isCompleted ? (
                <>
                  <span className={styles.completedLabel}>COMPLETED</span>:{' '}
                  {task.completedAt ? moment(task.completedAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                </>
              ) : (
                `Due by: ${task.dueDate ? moment(task.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}`
              )}
            </div>
          </div>
          <i className={`material-icons ${styles.chevronRightIcon}`}>chevron_right</i>
        </div>
      )}
    </div>
  );
};

export default TaskItem;
