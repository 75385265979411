import { notification } from 'antd';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useState } from 'react';
import { useFetchAllClientProfileEncryptDetails } from 'utils/hooks/GetClient/clientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  deleteClientRecordTag,
  putCancelInvitation,
  putClientRecordTag,
  putResendInvitation
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import AdultDetails from './AdultDetails/AdultDetails';
import ChildDetails from './ChildDetails/ChildDetails';
import { ProfileTagsOption } from './components/DropdownSearchable/interface';
import { useFetchProfileTags } from './hooks/GetAllProfileTags';
import { useFetchConsentList } from './hooks/GetConsentList';
import styles from './PatientDetailsProfile.module.scss';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { useTranslation } from 'react-i18next';

interface PatientDetailsProfileProps {
  recordId: string;
  clientRecordData: clientRecordsInterface;
  isClientRecordLoading: boolean;
  fetchClientRecord: (token: string) => void;
}

const PatientDetailsProfile = ({
  recordId,
  clientRecordData,
  isClientRecordLoading,
  fetchClientRecord
}: PatientDetailsProfileProps) => {
  const { token } = useGetAccessToken();
  const [isInvitationProcessing, setIsInvitationProcessing] = useState(false);
  const { consentListData, isConsentListLoading } = useFetchConsentList(token, recordId);
  const { profileTags, setProfileTags, isProfileTagsLoading } = useFetchProfileTags(token, clientRecordData.tags);
  const {
    clientEncryptDetails,
    clientEncryptDetailsLoading,
    fetchClientEncryptDetails
  } = useFetchAllClientProfileEncryptDetails(token, recordId);
  const [t] = useTranslation();

  const onResendInvitation = async (recordId: string) => {
    if (token) {
      try {
        setIsInvitationProcessing(true);
        await putResendInvitation(token, recordId);
        fetchClientRecord(token);
        await fetchClientEncryptDetails();
        notification.success({
          message: t('form.success.signup_invitation'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setIsInvitationProcessing(false);
      } catch (ex) {
        if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
          notification.destroy();
          notification.error({
            message: t('form.error.signup_invitation_limited_communication'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else {
          console.error(ex);
          notification.error({ message: t('form.error.signup_invitation') });
        }
        setIsInvitationProcessing(false);
      }
    }
  };

  const onCancelInvitation = async (recordId: string) => {
    if (token) {
      try {
        setIsInvitationProcessing(true);
        await putCancelInvitation(token, recordId);
        fetchClientRecord(token);
        await fetchClientEncryptDetails();
        notification.success({
          message: 'Signup invitation is cancelled',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setIsInvitationProcessing(false);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to cancel this signup invitation' });
        setIsInvitationProcessing(false);
      }
    }
  };

  const onSelectedTags = async (tag: ProfileTagsOption) => {
    if (!token) {
      return;
    }
    try {
      const isSelected = profileTags.filter((tag) => !tag.isSelected).some((profileTag) => profileTag._id === tag._id);
      const newProfileTag = profileTags.find((profileTag) => profileTag._id === tag._id);
      if (isSelected) {
        await putClientRecordTag(token, recordId, tag._id);
        if (newProfileTag) {
          newProfileTag.isLoading = false;
          newProfileTag.isSelected = true;
        }
      } else {
        await deleteClientRecordTag(token, recordId, tag._id);
        if (newProfileTag) {
          newProfileTag.isLoading = false;
          newProfileTag.isSelected = false;
        }
      }
      setProfileTags([...profileTags]);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to update profile tag' });
    }
  };

  const getDetails = () => {
    switch (clientRecordData.recordType) {
      case 'adult':
        return (
          <AdultDetails
            isInvitationProcessing={isInvitationProcessing}
            profileTags={profileTags}
            onResendInvitation={() => onResendInvitation(recordId)}
            onCancelInvitation={() => onCancelInvitation(recordId)}
            onSelectedTags={onSelectedTags}
            clientRecordData={clientEncryptDetails}
            consentListData={consentListData}
            refetchClientDetail={() => {
              fetchClientEncryptDetails();
              fetchClientRecord(token);
            }}
          />
        );
      case 'child':
        return (
          <ChildDetails
            isInvitationProcessing={isInvitationProcessing}
            profileTags={profileTags}
            onResendInvitation={() => onResendInvitation(recordId)}
            onCancelInvitation={() => onCancelInvitation(recordId)}
            onSelectedTags={onSelectedTags}
            clientRecordData={clientEncryptDetails}
            consentListData={consentListData}
            refetchClientDetail={() => {
              fetchClientEncryptDetails();
              fetchClientRecord(token);
            }}
          />
        );
      default:
        return (
          <AdultDetails
            isInvitationProcessing={isInvitationProcessing}
            profileTags={profileTags}
            onResendInvitation={() => onResendInvitation(recordId)}
            onCancelInvitation={() => onCancelInvitation(recordId)}
            onSelectedTags={onSelectedTags}
            clientRecordData={clientEncryptDetails}
            consentListData={consentListData}
            refetchClientDetail={() => {
              fetchClientEncryptDetails();
              fetchClientRecord(token);
            }}
          />
        );
    }
  };
  return (
    <div className={styles.boxContainer}>
      {isClientRecordLoading || isConsentListLoading || clientEncryptDetailsLoading || isProfileTagsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.clientInfoBox}>{getDetails()}</div>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsProfile;
