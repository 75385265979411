import styles from './PlanNoteList.module.scss';
import { Skeleton } from 'antd';
import moment from 'moment';
import ReadMore from 'components/ReadMore/ReadMore';
import { ActionPlanNote } from 'MHH/pages/ActionPlanCreator/interfaces';

interface PlanNoteListProps {
  notes: ActionPlanNote[];
  isLoading: boolean;
}

const PlanNoteList = ({ notes, isLoading }: PlanNoteListProps) => {
  return isLoading ? (
    <Skeleton active />
  ) : (
    <div className={styles.container}>
      {notes.map((note) => (
        <div className={styles.note}>
          <span className={`${styles.icon} material-icons-outlined`}>speaker_notes</span>
          <div className={styles.noteSection}>
            <div className={styles.noteHeader}>Note CREATED by {note.clinicianName}</div>
            <div className={styles.subHeader}>
              {moment(note.createdAt).format('dddd Do MMMM YYYY [at] HH:mm')} - Note titled <b>{note.title}</b>
            </div>
            {note.body && (
              <div className={`ql-snow`}>
                <ReadMore bodyClass={styles.content} text={note.body} maxHeight={100} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlanNoteList;
