import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { MouseEvent, useEffect } from 'react';
import styles from './StudentDetailsAssessmentList.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TeacherPendingAssessmentResponse } from 'interfaces/checkInService/clinicalAssessment';
import { scrollToView } from 'utils/scrollToView';

const StudentDetailsAssessmentList = ({
  recordId,
  assessmentList,
  assessmentId,
  isLoading
}: {
  recordId: string;
  assessmentList?: TeacherPendingAssessmentResponse[];
  assessmentId?: string;
  isLoading: boolean;
}) => {
  const { CLIENTS } = useRoutesGenerator();
  const navigate = useNavigate();
  const handleClickAssessment = (e: MouseEvent, id: string) => {
    e.stopPropagation();
    navigate(`${CLIENTS.BASE}/${recordId}/assessment/${id}`);
  };

  useEffect(() => {
    if (!isLoading && assessmentId) {
      scrollToView(assessmentId);
    }
  }, [isLoading, assessmentId]);

  return isLoading ? (
    <div className={styles.loadingContainer}>
      <LoadingCircle />
    </div>
  ) : (
    <>
      {assessmentList && assessmentList?.length > 0 ? (
        <div className={styles.container}>
          {assessmentList.map(
            ({
              _id,
              assessment: {
                clinicalAssessment: { id, metadata }
              },
              createdAt
            }) => (
              <div id={_id} className={styles.link} key={_id} onClick={(e) => handleClickAssessment(e, _id)}>
                <FontAwesomeIcon className={styles.icon} icon={assessmentId === _id ? SolidCircle : RegularCircle} />
                <div className={styles.label}>
                  <span className={styles.name}>{metadata?.cords?.shortTitle || id}</span>
                  <span>{moment(createdAt).format('DD/MM/YY')}</span>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className={styles.noItems}>No assessments</div>
      )}
    </>
  );
};

export default StudentDetailsAssessmentList;
