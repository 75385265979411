import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
// import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
interface MentorSelectProps {
  className?: string;
  selectedId?: string;
  disabled?: boolean;
  showRemoveWarning?: boolean;
  profileBadgeClassname?: string;
  onSelect: (clinician?: PractitionersDetailsInterface) => void;
}

const MentorSelect = ({
  className,
  selectedId,
  disabled,
  showRemoveWarning = true,
  profileBadgeClassname,
  onSelect
}: MentorSelectProps) => (
  <ClinicianSelect
    className={className}
    selectedId={selectedId}
    disabled={disabled}
    profileBadgeClassname={profileBadgeClassname}
    onSelect={onSelect}
    // filterRoles={[AccessRight.Mentor]}
    allowEmpty
    removeWarningMessage={showRemoveWarning ? 'Are you sure you want to disconnect this staff?' : ''}
  />
);

export default MentorSelect;
