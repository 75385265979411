/* eslint-disable complexity */
import { notification } from 'antd';
import Button from 'components/Button/Button';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import AppointmentTypeSelect from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';
import { AppointmentSlot } from 'components/v2/AvailableTimeSelect/Interface';
import LoadingButton from 'components/v2/Button/Button';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import FormSection from 'components/v2/FormSection/FormSection';
import { Formik } from 'formik';
import {
  CreatedAppointmentType,
  DELIVERY_TYPE_LABELS,
  DeliveryType,
  ParticipantType
} from 'interfaces/Schedule/AppointmentType';
import moment from 'moment';
import RoomOption from 'pages/Calendar/components/Modals/EventCreationModal/components/EventForm/components/ScheduleForm/components/RoomOption/RoomOption';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import {
  postAcceptAppointmentBookingRequest,
  postDeclineAppointmentBookingRequest,
  postSuggestAppointmentBookingRequest
} from 'utils/http/appointment';
import { AppointmentBookingRequest } from '../../../../Notification';

import { AppointmentValues } from '../AppointmentRequest/AppointmentRequest';
import ChangeNote from '../ChangeNote/ChangeNote';
import styles from './AppointmentBookingRequestV2.module.scss';

interface AppointmentBookingRequestV2Props {
  appointmentBookingRequestData: AppointmentBookingRequest['appointmentBookingRequests'][number];
  token: string;
  onRefreshNotifications: () => void;
}

const AppointmentBookingRequestV2 = ({
  appointmentBookingRequestData: { _id, clientRecord, deliveryType, date, startTime, endTime, clinicianId },
  token,
  onRefreshNotifications
}: AppointmentBookingRequestV2Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { isEdgeUser } = useGetAccountPackageView();

  const [declineState, setDeclineState] = useState<'' | 'decline' | 'suggest'>('');
  const [showMenu, setShowMenu] = useState(false);

  const [appointmentType, setAppointmentType] = useState<CreatedAppointmentType>();
  const [selectedSlot, setSelectedSlot] = useState<AppointmentSlot>();
  const [showSelectedSlotError, setShowSelectedSlotError] = useState(false);
  const [note, setNote] = useState('');

  const [acceptButtonState, setAcceptButtonState] = useState<'' | 'active' | 'finished'>('');
  const [declineButtonState, setDeclineButtonState] = useState<'' | 'active' | 'finished'>('');

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleDeclineButtonClick = () => {
    if (showMenu) {
      handleCloseMenu();
    } else {
      document.addEventListener('click', handleCloseMenu);
    }

    setShowMenu(!showMenu);
  };

  const handleDeclineWithNote = () => {
    setDeclineState('decline');
    setShowMenu(false);
  };

  const handleSuggestNewTime = () => {
    setDeclineState('suggest');
    setShowMenu(false);
  };

  const handleChangeSlot = (slot?: SelectedAppointmentSlot) => {
    setSelectedSlot(slot);
    setShowSelectedSlotError(false);
  };

  const handleAcceptRequest = async () => {
    setAcceptButtonState('active');

    try {
      await postAcceptAppointmentBookingRequest(_id, token);
      setAcceptButtonState('finished');
      onRefreshNotifications();
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to accept this appointment request. Please try again'
      });
      setAcceptButtonState('');
    }
  };

  const handleSubmitDecline = async (values: AppointmentValues) => {
    setDeclineButtonState('active');
    const { deliveryType, faceToFaceLocation, videoCallInstructions, phoneCallInstructions, roomInfo } = values;

    if (declineState === 'suggest') {
      if (!selectedSlot?.startTime || !selectedSlot?.endTime || !appointmentType) {
        setShowSelectedSlotError(true);
        setDeclineButtonState('');
        return;
      }
      try {
        await postSuggestAppointmentBookingRequest(
          _id,
          {
            slot: selectedSlot,
            sessionTypeId: appointmentType._id,
            note,
            deliveryType,
            faceToFaceLocation: deliveryType === DeliveryType.FaceToFace ? faceToFaceLocation : undefined,
            videoCallInstructions: deliveryType === DeliveryType.VideoCall ? videoCallInstructions : undefined,
            phoneCallInstructions: deliveryType === DeliveryType.PhoneCall ? phoneCallInstructions : undefined,
            roomInfo: !roomInfo.roomId && !roomInfo.roomName ? undefined : roomInfo
          },
          token
        );
        setDeclineButtonState('finished');
        onRefreshNotifications();
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to suggest a new time. Please try again' });
        setDeclineButtonState('');
      }
    }

    if (declineState === 'decline') {
      try {
        await postDeclineAppointmentBookingRequest(_id, { note }, token);
        setDeclineButtonState('finished');
        onRefreshNotifications();
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to decline this appointment request. Please try again'
        });
        setDeclineButtonState('');
      }
    }
  };

  const [t] = useTranslation();

  return (
    <Formik
      initialValues={{
        deliveryType: '',
        faceToFaceLocation: '',
        videoCallInstructions: '',
        phoneCallInstructions: '',
        roomRequired: '',
        roomInfo: {
          roomId: '',
          roomName: ''
        }
      }}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <>
          <div className={styles.container}>
            <div className={styles.title}>
              Request:{' '}
              {clientRecord.clientProfiles[0].profileType === 'prospect'
                ? t('label.prospect_client')
                : t('label.existing_client')}
            </div>
            <div className={styles.content}>
              <div className={styles.clientInfoWrapper}>
                <div className={styles.avatarAndName}>
                  <ClientAvatar
                    clientData={clientRecord.clientProfiles}
                    avatarSize={40}
                    isEllipsisName={false}
                    displayFirstNameOnly={clientRecord.recordType === 'couple'}
                    displayLimit={2}
                    nameClassName={styles.name}
                    initialsClassName={styles.name}
                    containerClassName={styles.containerClassName}
                  />
                </div>
                <div className={styles.dateTimeWrapper}>
                  <span className={styles.date}>{moment(date).format('dddd DD MMMM')}</span>
                  <span className={styles.time}>
                    {startTime} - {moment(endTime, 'HH:mm').format('HH:mmA')}
                  </span>
                </div>
                <div className={styles.deliveryType}>
                  <span>{DELIVERY_TYPE_LABELS[deliveryType]}</span>
                </div>
              </div>
              <div className={styles.actionWrapper}>
                {acceptButtonState === 'finished' && declineButtonState === 'finished' && (
                  <div className={styles.statusWrapper}>
                    {acceptButtonState === 'finished' && (
                      <div className={styles.booked}>
                        <i className={`material-icons-outlined ${styles.statusIcon}`}>check_circle_outline</i>
                        Booked
                      </div>
                    )}
                    {declineButtonState === 'finished' &&
                      (declineState === 'decline' ? (
                        <div className={styles.declined}>
                          <i className={`material-icons-outlined ${styles.statusIcon}`}>highlight_off</i>
                          Appointment Declined
                        </div>
                      ) : (
                        <div className={styles.suggested}>
                          <i className={`material-icons-outlined ${styles.statusIcon}`}>check_circle_outline</i>
                          New Time Suggested
                        </div>
                      ))}
                  </div>
                )}
                {acceptButtonState !== 'finished' && declineButtonState !== 'finished' && (
                  <>
                    <LoadingButton
                      className={styles.acceptButton}
                      status={acceptButtonState}
                      onClick={handleAcceptRequest}
                    >
                      Accept
                    </LoadingButton>
                    <div ref={menuRef} className={styles.declineButtonContainer}>
                      <Button className={styles.declineButton} variant={'secondary'} onClick={handleDeclineButtonClick}>
                        {declineState === '' && (
                          <>
                            Decline
                            <i className={`material-icons ${styles.declineIcon}`}>arrow_drop_down</i>
                          </>
                        )}
                        {declineState === 'decline' && 'Decline appointment'}
                        {declineState === 'suggest' && 'Suggesting new time'}
                      </Button>
                      <div className={styles.menuWrapper}>
                        <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                          <div className={styles.listBox} onClick={handleDeclineWithNote}>
                            <div className={styles.listTitle}>Decline with note</div>
                          </div>
                          <div className={styles.listBox} onClick={handleSuggestNewTime}>
                            <div className={styles.listTitle}>Suggest new time</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {declineState === 'suggest' && (
              <>
                <AppointmentTypeSelect
                  clinicianId={clinicianId || ''}
                  hideTitle
                  horizontal
                  onSelect={setAppointmentType}
                  selectedParticipantType={appointmentType?.participantType || ParticipantType.OneToOne}
                />
                {appointmentType && (
                  <AvailableTimeSelect
                    appointmentTypeId={appointmentType._id}
                    onSelectSlot={handleChangeSlot}
                    selectedClinicianId={clinicianId || ''}
                    isEditing
                  />
                )}
                <ErrorMessage error="Please select a timeslot for the appointment" visible={showSelectedSlotError} />
                {selectedSlot?.deliveryOptions && (
                  <div className={styles.deliveryModeContainer}>
                    <FormSection title="Delivery Mode">
                      <div className={styles.deliveryModeContainer}>
                        <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
                      </div>
                    </FormSection>
                  </div>
                )}
                {isEdgeUser && selectedSlot?.faceToFaceLocation && appointmentType && (
                  <div className={styles.roomOptionContainer}>
                    <RoomOption appointmentType={appointmentType} />
                  </div>
                )}
              </>
            )}
            {(declineState === 'decline' || declineState === 'suggest') && (
              <>
                <ChangeNote note={note} onNoteChange={setNote} />
                <LoadingButton
                  className={styles.submitButton}
                  status={declineButtonState}
                  onClick={() => handleSubmitDecline(values)}
                >
                  {declineState === 'decline' ? 'Decline Appointment' : 'Suggest New Appointment Time'}
                </LoadingButton>
              </>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default AppointmentBookingRequestV2;
