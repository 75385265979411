import { Formik } from 'formik';
import { memo } from 'react';
import { Props } from 'react-select';
import {
  appointmentDeliverySchema,
  initialValues,
  initialValuesUpdate,
  getPhoneConnectOptions,
  ROOM_REQUIRED_OPTIONS,
  VIDEO_CONNECT_OPTIONS
} from './constants';
import FormSection from 'components/v2/FormSection/FormSection';
import Button from 'components/v2/Button/Button';
import Radio from 'components/Radio/Radio';
import styles from './AppointmentDeliveryForm.module.scss';
import classnames from 'classnames';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import FaceToFaceLocation from './components/FaceToFaceLocation/FaceToFaceLocation';
import { useState } from 'react';
import { useFetchRoomList } from 'utils/hooks/GetRoomList/useFetchRoomList';
import { RoomType } from 'interfaces/Schedule/Room';
import { useEffect } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { AppointmentType, DeliveryType, DELIVERY_TYPE_LABELS } from 'interfaces/Schedule/AppointmentType';
import VideoInstructions from './components/VideoInstructions/VideoInstructions';
import PhoneCallInstructions from './components/PhoneCallInstructions/PhoneCallInstructions';
import { useTranslation } from 'react-i18next';

interface AppointmentDeliveryFormProps {
  data?: AppointmentType;
  errMess?: string;
  onSubmit: (values: Partial<AppointmentType>) => void;
}

const AppointmentDeliveryForm = ({ data, errMess, onSubmit }: AppointmentDeliveryFormProps) => {
  const [isActive, setIsActive] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [openToBook, setOpenToBook] = useState<'' | 'active' | 'finished'>('');
  const [draft, setDraft] = useState<'' | 'active' | 'finished'>('');
  const { roomList, isRoomListLoading } = useFetchRoomList();
  const initialUpdate = data && initialValuesUpdate(data);
  const [isDeliveryOptionsEmpty, setIsDeliveryOptionsEmpty] = useState(
    data
      ? data.deliveryOptions.length === 0
      : !initialValues.telehealthPhoneCallSelected &&
          !initialValues.telehealthVideoCallSelected &&
          !initialValues.faceToFaceSelected
  );
  const [t] = useTranslation();

  useEffect(() => {
    if (errMess) {
      setOpenToBook('');
      setDraft('');
    }
  }, [errMess]);

  const handleSubmit = (values: typeof initialValues) => {
    const deliveryOptions: DeliveryType[] = [];
    let faceToFaceLocation = values.faceToFaceSelected ? values.faceToFaceLocation : '';
    let videoCallInstructions =
      values.telehealthVideoCallSelected && values.videoCallInstructionType === 'custom'
        ? values.videoCallInstructions
        : '';
    let phoneCallInstructions =
      values.telehealthPhoneCallSelected && values.phoneCallInstructionType === 'custom'
        ? values.phoneCallInstructions
        : '';
    if (values.faceToFaceSelected) {
      deliveryOptions.push(DeliveryType.FaceToFace);
    }
    if (values.telehealthPhoneCallSelected) {
      deliveryOptions.push(DeliveryType.PhoneCall);
    }
    if (values.telehealthVideoCallSelected) {
      deliveryOptions.push(DeliveryType.VideoCall);
    }
    if (deliveryOptions.length === 0) {
      setIsDeliveryOptionsEmpty(true);
      setOpenToBook('');
      setDraft('');
      return;
    }

    onSubmit({
      isActive,
      deliveryOptions,
      faceToFaceLocation,
      videoCallInstructions,
      phoneCallInstructions,
      roomSettings: {
        required: values.roomRequired === 'yes',
        preferredRoom: {
          name: roomList.find((room) => room._id === values.roomId)?.name || '',
          roomId: values.roomId
        }
      },
      roomAny: values.roomRequired === 'any'
    });
  };

  const propStyles: Props['styles'] = {
    container: (base) => ({ ...base, margin: 0 }),
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none'
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    dropdownIndicator: (base) => ({ ...base, padding: 0 })
  };

  const mapRoomList = (roomList: RoomType[]) => {
    return roomList.map((room) => ({ label: room.name, value: room._id }));
  };

  const onChangeDeliveryModeOptions = ({
    faceToFaceSelected,
    telehealthVideoCallSelected,
    telehealthPhoneCallSelected
  }: {
    faceToFaceSelected?: boolean;
    telehealthVideoCallSelected?: boolean;
    telehealthPhoneCallSelected?: boolean;
  }) => {
    if (faceToFaceSelected || telehealthVideoCallSelected || telehealthPhoneCallSelected) {
      setIsDeliveryOptionsEmpty(false);
    } else {
      setIsDeliveryOptionsEmpty(true);
    }
  };

  return (
    <Formik
      initialValues={initialUpdate || initialValues}
      validationSchema={appointmentDeliverySchema}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, submitForm, isValid, touched, errors }) => (
        <div className={styles.container}>
          <FormSection
            title={t('appointment.delivery_mode_options')}
            help={t('appointment.delivery_mode_options.description')}
          >
            <div className={styles.telehealthForm}>
              <div className={classnames(styles.optionRow, { [styles.active]: values.faceToFaceSelected })}>
                <label className={styles.checkboxContainer}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.faceToFaceSelected}
                    onChange={() => {
                      onChangeDeliveryModeOptions({
                        faceToFaceSelected: !values.faceToFaceSelected,
                        telehealthVideoCallSelected: values.telehealthVideoCallSelected,
                        telehealthPhoneCallSelected: values.telehealthPhoneCallSelected
                      });
                      setValues({
                        ...values,
                        faceToFaceSelected: !values.faceToFaceSelected
                      });
                    }}
                  />
                  <span className={styles.label}>{DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace]}</span>
                </label>
                <div className={styles.faceToFaceLocation}>{values.faceToFaceSelected && <FaceToFaceLocation />}</div>
              </div>

              <div className={classnames(styles.optionRow, { [styles.active]: values.telehealthVideoCallSelected })}>
                <label className={classnames(styles.checkboxContainer, styles.telehealthCheckbox)}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.telehealthVideoCallSelected}
                    onChange={() => {
                      onChangeDeliveryModeOptions({
                        telehealthVideoCallSelected: !values.telehealthVideoCallSelected,
                        faceToFaceSelected: values.faceToFaceSelected,
                        telehealthPhoneCallSelected: values.telehealthPhoneCallSelected
                      });
                      setValues({
                        ...values,
                        telehealthVideoCallSelected: !values.telehealthVideoCallSelected
                      });
                    }}
                  />
                  <span className={styles.label}>Telehealth video</span>
                </label>
                {values.telehealthVideoCallSelected && (
                  <>
                    <div className={styles.telehealth2ndColumn}>
                      <label className={styles.label}>How to connect</label>
                      <div className={styles.telehealthItem}>
                        <div>
                          <Radio
                            name={'videoRadio'}
                            options={VIDEO_CONNECT_OPTIONS}
                            value={values.videoCallInstructionType}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                videoCallInstructionType: e.target.value
                              })
                            }
                            vertical
                            className={styles.radioClass}
                            labelClassName={styles.radioLabel}
                          />
                          {values.videoCallInstructionType === 'custom' && <VideoInstructions />}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={classnames(styles.optionRow, { [styles.active]: values.telehealthPhoneCallSelected })}>
                <label className={classnames(styles.checkboxContainer, styles.telehealthCheckbox)}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.telehealthPhoneCallSelected}
                    onChange={() => {
                      onChangeDeliveryModeOptions({
                        telehealthVideoCallSelected: values.telehealthVideoCallSelected,
                        faceToFaceSelected: values.faceToFaceSelected,
                        telehealthPhoneCallSelected: !values.telehealthPhoneCallSelected
                      });
                      setValues({
                        ...values,
                        telehealthPhoneCallSelected: !values.telehealthPhoneCallSelected
                      });
                    }}
                  />
                  <span className={styles.label}>Telehealth phone call</span>
                </label>
                {values.telehealthPhoneCallSelected && (
                  <>
                    <div className={styles.telehealth2ndColumn}>
                      <label className={styles.label}>How to connect</label>
                      <div className={styles.telehealthItem}>
                        <div>
                          <Radio
                            name={'phoneRadio'}
                            options={getPhoneConnectOptions(t)}
                            value={values.phoneCallInstructionType}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                phoneCallInstructionType: e.target.value
                              })
                            }
                            vertical
                            className={styles.radioClass}
                            labelClassName={styles.radioLabel}
                          />
                          {values.phoneCallInstructionType === 'custom' && <PhoneCallInstructions />}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {touched && isDeliveryOptionsEmpty && (
              <p className={styles.fieldError}>
                Please select at least one
                <strong> Delivery Mode Option</strong>
              </p>
            )}
          </FormSection>
          {isEdgeAdminView ? (
            <FormSection
              title="Require a room?"
              help="Decide if a room is required for your appointment type. A room can be set up in Room Management under Calendar settings."
            >
              <div className={styles.roomRequiredForm}>
                <Radio
                  options={ROOM_REQUIRED_OPTIONS}
                  value={values.roomRequired}
                  onChange={(e) => setValues({ ...values, roomRequired: e.target.value })}
                  vertical
                  className={styles.radioClass}
                  labelClassName={styles.radioLabel}
                />
                {values.roomRequired === 'yes' &&
                  (isRoomListLoading ? (
                    <div className={styles.loadingBox}>Loading...</div>
                  ) : (
                    <div>
                      <FormikSelect
                        name="roomId"
                        label="Room selection?"
                        options={mapRoomList(roomList)}
                        styles={propStyles}
                        labelClass={styles.labelClass}
                      />
                    </div>
                  ))}
              </div>
            </FormSection>
          ) : (
            <></>
          )}
          <div className={styles.footer}>
            <Button
              status={draft}
              className={styles.draftButton}
              variant="secondary"
              onClick={() => {
                submitForm();
                if (isValid) {
                  setIsActive(false);
                  setDraft('active');
                } else {
                  setDraft('');
                }
              }}
            >
              Save in draft
            </Button>
            <Button
              status={openToBook}
              onClick={() => {
                submitForm();
                if (isValid && !isDeliveryOptionsEmpty) {
                  setIsActive(true);
                  setOpenToBook('active');
                } else {
                  setOpenToBook('');
                }
              }}
            >
              Save and open for booking
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default memo(AppointmentDeliveryForm);
