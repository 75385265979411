import { useState } from 'react';
import moment from 'moment';
import { AssessmentStatus, clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import DateDescBox from '../../../Components/DateDescBox/DateDescBox';
import styles from './ActiveStudentItem.module.scss';
import columnStyles from '../../ActiveStudentColumn.module.scss';
import Button from 'components/v2/Button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCORDSTeacherRoutesGenerator } from 'MHH/utils/Path/CORDSRoutesGenerator';
import queryString from 'query-string';
import { studentListQueryParam } from '../../../../../../StudentListing';
import Tags from 'MHH/components/Tags/Tags';

interface ActiveStudentItemProps {
  clientData: clientProfilesInterface[];
  studentId: string;
  assessment?: clientRecordsInterface['assessment'];
  groupNames?: string[];
  tags?: string[];
  classValue?: string;
}

const ActiveStudentItem = ({
  clientData,
  assessment,
  groupNames,
  tags,
  studentId,
  classValue
}: ActiveStudentItemProps) => {
  const navigate = useNavigate();
  const { STUDENT } = useCORDSTeacherRoutesGenerator();
  const location = useLocation();
  const queryParam: studentListQueryParam = queryString.parse(location.search);
  const [seeMoreGroups, setSeeMoreGroups] = useState(false);

  const onSelectClient = () => {
    const qParam = queryString.stringify(queryParam || {});
    const path = `${qParam ? `?${qParam}` : ''}`;
    localStorage.setItem('studentListPrevPath', path);
    navigate(`/${STUDENT.LISTING}/${studentId}/assessment/${assessment?._id}`);
  };

  return (
    <div className={styles.container} id={`selectClientId${studentId}`}>
      <div className={columnStyles.profileSection}>{clientData[0].firstName + ' ' + clientData[0].lastName}</div>
      <div className={columnStyles.classSection}>{classValue || '-'}</div>
      <div className={columnStyles.pendingAssessmentSection}>
        {assessment?.status === AssessmentStatus.Pending ? (
          <DateDescBox
            title={assessment.name}
            desc={assessment.createdAt ? `(${moment(assessment.createdAt).format('D MMM YYYY')})` : ''}
          />
        ) : (
          '-'
        )}
      </div>
      <div className={columnStyles.groupSection}>
        <div className={styles.groups}>
          {groupNames?.length ? (
            <>
              {(seeMoreGroups ? groupNames : groupNames.slice(0, 3)).map((item, index) => (
                <span className={styles.groupName} key={item}>
                  {item}
                  {groupNames.length > 1 && index !== groupNames.length - 1 && ','}
                </span>
              ))}
              {groupNames.length > 3 && (
                <button
                  className={styles.seeMore}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSeeMoreGroups(!seeMoreGroups);
                  }}
                >
                  {seeMoreGroups ? 'see less' : 'see more'}
                </button>
              )}
            </>
          ) : (
            <div>-</div>
          )}
        </div>
      </div>
      <div className={columnStyles.tagSection}>{tags?.length ? <Tags tags={tags} /> : '-'}</div>
      <div className={columnStyles.buttonSection}>
        {assessment ? (
          assessment.status === AssessmentStatus.Pending ? (
            <Button onClick={() => onSelectClient()} className={styles.completeButton}>
              Complete Assessment
            </Button>
          ) : (
            assessment.status === AssessmentStatus.Done && (
              <div className={styles.completedAssessment}>
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="21" cy="21" r="21" fill="#25BE69" />
                  <path
                    d="M18.4801 25.3211L14.3247 21.1656L14.0701 20.9111L13.8155 21.1656L12.3455 22.6356L12.091 22.8902L12.3455 23.1448L18.2255 29.0248L18.4801 29.2793L18.7347 29.0248L31.3347 16.4248L31.5892 16.1702L31.3347 15.9156L29.8647 14.4456L29.6101 14.1911L29.3555 14.4456L18.4801 25.3211Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.72"
                  />
                </svg>
                <div>
                  <DateDescBox title={'Submitted on'} desc={moment(assessment.updatedAt).format('D MMM YYYY')} />
                </div>
              </div>
            )
          )
        ) : (
          'No assessment requested'
        )}
      </div>
    </div>
  );
};

export default ActiveStudentItem;
