import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

import ClientInfo from '../components/ClientInfo/ClientInfo';
import ConsentDetails from '../components/ConsentDetails/ConsentDetails';
import { ProfileTagsOption } from '../components/DropdownSearchable/interface';
import GroupInformation from '../components/GroupInformation/GroupInformation';
import ProfileTags from '../components/ProfileTags/ProfileTags';
import styles from './ChildDetails.module.scss';

interface ChildDetailsProps {
  clientRecordData: clientRecordsEncryptedInterface;
  profileTags: ProfileTagsOption[];
  onResendInvitation: () => void;
  onCancelInvitation: () => void;
  onSelectedTags: (tag: ProfileTagsOption) => void;
  isInvitationProcessing: boolean;
  consentListData?: ConsentFormListInterface[];
  refetchClientDetail?: () => void;
}

const ChildDetails = ({
  clientRecordData,
  profileTags,
  onResendInvitation,
  onCancelInvitation,
  onSelectedTags,
  isInvitationProcessing,
  consentListData,
  refetchClientDetail
}: ChildDetailsProps) => {
  const { isGroupsFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('title.client_information')}</div>
      <div className={styles.clientInfoSection}>
        <ClientInfo
          clientRecordData={clientRecordData}
          onResendInvitation={onResendInvitation}
          onCancelInvitation={onCancelInvitation}
          isInvitationProcessing={isInvitationProcessing}
          refetchClientDetail={refetchClientDetail}
        />
      </div>
      {isGroupsFeatureToggle && clientRecordData.attachedGroups && (
        <GroupInformation
          clientRecordId={clientRecordData._id}
          attachedGroups={clientRecordData.attachedGroups}
          onRefreshData={refetchClientDetail}
        />
      )}

      <div className={styles.profileTags}>
        <ProfileTags
          profileTags={profileTags}
          onSelectedTags={onSelectedTags}
          isReadOnly={clientRecordData.recordStatus === 'closed'}
        />
      </div>

      <div className={styles.docSection}>
        <ConsentDetails consentListData={consentListData} clientRecordData={clientRecordData} />
      </div>
    </div>
  );
};

export default ChildDetails;
