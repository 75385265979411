import { notification } from 'antd';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { useEffect, useState } from 'react';
import { getSessionTypeById } from 'utils/http/sessionType';

export const useGetAppointmentTypeById = (token: string, appointmentTypeId: string) => {
  const [appointmentType, setAppointmentType] = useState<AppointmentType>();
  const [isAppointmentTypeLoading, setIsAppointmentTypeLoading] = useState<boolean>(true);

  const fetchAppointmentType = async (token: string, appointmentTypeId: string) => {
    try {
      const appointmentType = await (await getSessionTypeById(token, appointmentTypeId)).json();

      setAppointmentType(appointmentType);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to fetch your appointment type' });
    }

    setIsAppointmentTypeLoading(false);
  };

  useEffect(() => {
    if (token && appointmentTypeId) {
      fetchAppointmentType(token, appointmentTypeId);
    } else {
      setAppointmentType(undefined);
    }
  }, [token, appointmentTypeId]);

  return { appointmentType, isAppointmentTypeLoading };
};
