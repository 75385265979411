import { ConsentFormResponseList } from 'pages/Groups/Interfaces/Groups';
import { useCallback, useEffect, useState } from 'react';

const CONSENT_LIST_MOCK = [
  {
    _id: '60fe808f147dc0000a3c501f',
    name: 'Consent Forms A',
    createdAt: '2021-07-26T09:29:51.009Z'
  },
  {
    _id: '621eee8510ae1a000aa0d514',
    name: 'Consent Forms D',
    createdAt: '2021-08-13T09:09:44.779Z'
  },
  {
    _id: '61445c05cb18ca000a658dd5',
    name: 'Consent Forms C',
    createdAt: '2021-09-17T09:12:37.223Z'
  }
] as ConsentFormResponseList[];

export const useFetchConsentMenuList = (token: string, groupId: string) => {
  const [consentFormMenuList, setConsentFormMenuList] = useState<ConsentFormResponseList[]>([]);
  const [isConsentFormMenuListLoading, setIsConsentFormMenuListLoading] = useState(true);

  const fetchConsentMenuList = useCallback(async (token: string) => {
    setIsConsentFormMenuListLoading(true);

    // TODO
    setConsentFormMenuList(CONSENT_LIST_MOCK);
    setIsConsentFormMenuListLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      fetchConsentMenuList(token);
    }
  }, [fetchConsentMenuList, token]);

  return { consentFormMenuList, isConsentFormMenuListLoading, fetchConsentMenuList };
};
