import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button from 'components/v2/Button/Button';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { Attendee, AttendeeParticipationStatus } from 'interfaces/Schedule/Appointment';
import { useState, FormEventHandler, ChangeEvent } from 'react';
import styles from './SelectedAttendees.module.scss';

type SelectedAttendeesProps = {
  attendees: Attendee[];
  addAttendee(attendee: Attendee): void;
  removeAttendee(index: number): void;
};

const SelectedAttendees = ({ attendees, addAttendee, removeAttendee }: SelectedAttendeesProps) => {
  const [input, setInput] = useState<string>('');
  const [inputError, setInputError] = useState<boolean>(false);

  const isValidEmail = (email: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (isValidEmail(input)) {
      addAttendee({
        email: input,
        name: input,
        participationStatus: AttendeeParticipationStatus.Unknown
      });
      setInput('');
      setInputError(false);
    } else {
      setInputError(true);
    }
  };
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setInput(email);
    if (inputError) {
      setInputError(!isValidEmail(email));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.numberOfAttendees}>{attendees.length}</span>
        <span> recipients added</span>
      </div>
      {attendees.map((attendee, index) => {
        return attendee.clinicianId || attendee.clientProfileId || attendee.email ? (
          <div className={styles.attendeeContainer} key={index}>
            <ProfileBadge {...attendee} />
            <Button variant="link" className={styles.removeButton} onClick={() => removeAttendee(index)}>
              Remove
            </Button>
          </div>
        ) : (
          <div className={styles.attendeeContainer} key={index}>
            <div className={styles.emailIconContainer}>
              <span className="material-icons-outlined">email</span>
            </div>
            <div>{attendee.email}</div>
            <Button variant="link" className={styles.removeButton} onClick={() => removeAttendee(index)}>
              Remove
            </Button>
          </div>
        );
      })}
      <form onSubmit={onSubmit}>
        <div className={styles.inputContainer}>
          <MaterialInput label="Add Email Address" value={input} onChange={onChange} />
          <Button variant="link" icon="add" type="submit" />
        </div>
        <ErrorMessage visible={inputError} error="Please insert valid email" />
      </form>
    </div>
  );
};

export default SelectedAttendees;
