import classNames from 'classnames';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import Switch from 'components/Switch/Switch';
import Select from 'components/v2/Select/Select';
import { camelCase } from 'lodash';
import { useFetchVadcQuestions, VadcQuestion } from 'pages/AssessmentDetails/hooks/getVadcQuestions';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccessToken } from 'utils/hooks/token';
import { v4 as uuid } from 'uuid';
import styles from './QuestionForm.module.scss';

export interface QuestionListWithCheckBoxProps {
  onAddQuestion: any;
  onAddDraftQuestion: any;
  onRemoveDraftQuestion: any;
}

const getQuestion = ({
  id,
  isChecked,
  stem,
  questionType,
  optionsString,
  optionsObject,
  maxSelection,
  isDraft
}: {
  id: string;
  isChecked: boolean;
  stem: string;
  questionType: string;
  optionsString?: string;
  optionsObject?: { key?: string; value?: string; description: string }[];
  maxSelection: number;
  isDraft: boolean;
}) => {
  const question = {
    id,
    isChecked,
    isDraftQ: isDraft,
    stem,
    type: questionType,
    ...(questionType !== 'freeText' && {
      maxSelection,
      options:
        questionType !== 'vadc' && optionsString
          ? optionsString
              .split(';')
              .map((option) => option.trim())
              .filter((option) => option)
              .map((option) => ({
                key: camelCase(option),
                description: option,
                value: camelCase(option)
              }))
          : optionsObject || []
    })
  };

  if (questionType === 'vadc') {
    return question;
  }
  if (questionType === 'selectOther') {
    const questionOptions = question.options as { key: string; value: string; description: string }[];

    if (
      questionOptions.filter((option) => typeof option.value !== 'number' && option.value.toLowerCase() === 'other')
        .length === 0
    ) {
      question.options = [
        ...questionOptions,
        {
          key: 'other',
          description: 'Other',
          value: 'other'
        }
      ];
    }
  }

  return question;
};

const QuestionForm = ({ onAddQuestion, onAddDraftQuestion, onRemoveDraftQuestion }: QuestionListWithCheckBoxProps) => {
  const [id, setId] = useState(`newId${uuid()}`);
  const [questionType, setQuestionType] = useState('freeText');
  const [stem, setStem] = useState<string>('');
  const [maxSelection, setMaxSelection] = useState(1);
  const [optionsString, setOptionsString] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [selectedVadc, setSelectedVadc] = useState<VadcQuestion>();
  const { token } = useGetAccessToken();
  const { isVadcFeatureToggle } = useGetFeatureToggle();
  const { vadcQuestions, isVadcQuestionsLoading: loading, fetchVadcQuestions } = useFetchVadcQuestions(token);
  const questionTypes = [
    { value: 'freeText', label: 'Free Text' },
    { value: 'multipleChoice', label: 'Multiple Choice' },
    { value: 'multipleChoiceFreeText', label: 'Multiple Choice Free Text' },
    { value: 'selectOther', label: 'Select Other' },
    ...(isVadcFeatureToggle ? [{ value: 'vadc', label: 'VADC Questions' }] : [])
  ];

  const hijackedSetState = <T,>(dispatch: Dispatch<SetStateAction<T>>, updatedFieldKey: string) => (data: T) => {
    dispatch(data);
    const question = getQuestion({
      id,
      isChecked,
      questionType,
      stem,
      maxSelection,
      optionsString,
      [updatedFieldKey]: data, // Bypass state lag
      isDraft: true
    });
    onAddDraftQuestion(question);
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const onSetStem = hijackedSetState(setStem, 'stem');
  const onSetOptionsString = hijackedSetState(setOptionsString, 'optionsString');
  const onSetMaxSelection = hijackedSetState(setMaxSelection, 'maxSelection');
  const changeQuestionType = (questionType: string) => {
    setQuestionType(questionType);
    if (questionType === 'freeText') {
      setMaxSelection(1);
      setOptionsString('');
    }

    const question = getQuestion({
      id,
      questionType,
      isChecked,
      stem,
      optionsString,
      optionsObject: selectedVadc?.options,
      maxSelection,
      ...(questionType === 'freeText' && {
        optionsString: '',
        maxSelection: 1
      }),
      isDraft: true
    });

    if (questionType === 'vadc') {
      vadcQuestions.length === 0 && fetchVadcQuestions();
    }
    onAddDraftQuestion(question);
  };

  const reset = () => {
    setId(`newId${uuid()}`);
    setQuestionType(questionTypes[0].value);
    setStem('');
    setMaxSelection(1);
    setOptionsString('');
    setIsDirty(false);
    setSelectedVadc(undefined);
  };

  const onSaveQuestion = () => {
    const question = getQuestion({
      id,
      isChecked,
      questionType: questionType === 'vadc' && selectedVadc ? selectedVadc?.questionType : questionType,
      stem,
      maxSelection,
      ...(questionType !== 'vadc' ? { optionsString } : { optionsObject: selectedVadc?.options }),
      isDraft: false
    });
    onAddQuestion({
      ...question,
      ...(questionType === 'vadc' && { structuredQuestionId: selectedVadc?.structuredQuestionId })
    });
    reset();
  };

  const handleVadcChange = (val: string) => {
    const selectedQuestion = vadcQuestions.find(({ id }: { id: string }) => id === val);

    if (!selectedQuestion) {
      return;
    }
    setStem(selectedQuestion.name);
    setMaxSelection(selectedQuestion.maxSelection || 1);
    setSelectedVadc({ ...selectedQuestion, structuredQuestionId: selectedQuestion.id });
  };

  useEffect(() => {
    if (isDirty && stem === '' && maxSelection === 1 && optionsString === '') {
      onRemoveDraftQuestion(id);
      setId(`newId${uuid()}`);
      setIsDirty(false);
    }
  }, [id, isDirty, maxSelection, onRemoveDraftQuestion, optionsString, stem]);

  const isValid = () => {
    const filteredOptions = optionsString
      .split(';')
      .map((option) => option.trim())
      .filter((option) => option);
    return stem && (questionType === 'freeText' || filteredOptions.length > 1 || selectedVadc);
  };

  return (
    <div className={styles.container}>
      <div>
        <input
          className={styles.checkbox}
          id="questionFromCheckbox"
          type="checkbox"
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
      </div>
      <div className={styles.form}>
        <div className={styles.firstRow}>
          <div className={styles.textFieldContainer}>
            {questionType === 'vadc' ? (
              <MaterialSelect
                id={`vadcQuestions${id}`}
                label={'Select question'}
                isSearchable={false}
                options={vadcQuestions.map(({ id, name }) => ({ value: id, label: name }))}
                value={selectedVadc?.id || ''}
                onChange={handleVadcChange}
                loading={loading}
              />
            ) : (
              <input
                className={styles.textField}
                id="stem"
                type="text"
                autoComplete="off"
                value={stem}
                placeholder={'Add a custom question'}
                onChange={(e) => {
                  onSetStem(e.target.value);
                }}
              />
            )}
          </div>
          <div className={styles.typeContainer}>
            <div className={styles.label}>Answer format</div>
            <Select
              {...questionTypes.filter((type) => type.value === questionType)}
              key={`question_type_${id}`}
              options={questionTypes}
              defaultValue={questionTypes[0]}
              onChange={(option) => {
                option && changeQuestionType(option.value);
              }}
              menuPlacement="auto"
            />
          </div>
          <button
            className={classNames(styles.addBtnWrapper, !isValid() && styles.disabled)}
            onClick={onSaveQuestion}
            disabled={!isValid()}
          >
            <i className={`material-icons ${styles.plusIcon}`}>add_circle_outline</i>
            Add
          </button>
        </div>
        {questionType !== 'freeText' && questionType !== 'vadc' && (
          <div className={styles.secondRow}>
            <div>
              <div className={styles.label}>Answers</div>
              <input
                className={styles.textField}
                id="options"
                type="text"
                autoComplete="off"
                value={optionsString}
                onChange={(e) => {
                  onSetOptionsString(e.target.value);
                }}
              />
              <div className={styles.tip}>Seperate answers with semi-colon ;</div>
            </div>
            <div>
              <div className={styles.label}>Accept more than one answer?</div>
              <Switch
                className={classNames(styles.switch, maxSelection === 0 ? styles.checked : '')}
                name="moreThanOne"
                checked={maxSelection === 0}
                onChange={(e) => {
                  onSetMaxSelection(e.target.checked ? 0 : 1);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionForm;
