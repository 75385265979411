import styles from './CompletedCard.module.scss';
import CompletedCardItem from './components/CompletedCardItem/CompletedCardItem';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { Skeleton } from 'antd';

interface CompletedCardProps {
  total: number;
  data: {
    appointments: AppointmentSlots[];
    month: string;
  }[];
  isLoading: boolean;
  refetchAppointment: () => void;
}

const CompletedCard = ({ total, data, isLoading, refetchAppointment }: CompletedCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.title}>Past Appointments</div>
        {isLoading ? (
          <div>
            <Skeleton.Avatar active />
          </div>
        ) : (
          <div className={styles.total}>{total}</div>
        )}
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Skeleton.Input active className={styles.loadingMonth} />
          {[...Array(3)].map((obj, i) => (
            <div key={i} className={styles.loadingWrapper}>
              <Skeleton.Avatar active />
              <div className={styles.loadingContent}>
                <div className={styles.headingLoading}>
                  <Skeleton.Input active className={styles.titleLoading} />
                  <Skeleton.Input active className={styles.tags} />
                </div>
                <Skeleton.Input active className={styles.desc1} />
                <Skeleton.Input active className={styles.desc2} />
              </div>
              <Skeleton.Input active className={styles.selectTagsLoading} />
            </div>
          ))}
        </div>
      ) : (
        data.map((obj, index: number) => {
          return (
            <div className={styles.cardWrapper} key={index}>
              <div className={styles.month}>{obj.month}</div>
              {obj.appointments?.map((item, index: number) => (
                <CompletedCardItem appointments={item} key={index} refetchAppointment={refetchAppointment} />
              ))}
            </div>
          );
        })
      )}
    </div>
  );
};

export default CompletedCard;
