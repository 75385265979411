import moment from 'moment';
import { Integration } from 'pages/ControlPanel/Interfaces/Integration';
import { getDayDiff } from 'utils/helpers/DateDiff';

import Status from './components/Status/Status';
import styles from './ConnectedIntegrationRow.module.scss';

interface ConnectedIntegrationRowProps {
  integration: Integration;
  selectIntegration: (type: string, id: string) => void;
}

const ConnectedIntegrationRow = ({
  integration: { id, label, type, connectedAt, status },
  selectIntegration
}: ConnectedIntegrationRowProps) => {
  return (
    <div className={styles.container} onClick={() => selectIntegration(type, id)}>
      <div className={styles.connectionName}>{label}</div>
      <div className={styles.connectedSince}>
        {connectedAt ? (
          <>
            <div className={styles.date}>{moment(connectedAt).format('DD MMM YYYY')}</div>
            <div className={styles.dateDiff}>{getDayDiff(moment(), moment(connectedAt))}</div>
          </>
        ) : (
          <div className={styles.date}>-</div>
        )}
      </div>
      <div className={styles.status}>
        <Status status={status} />
      </div>
      <div className={styles.action}>
        <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
      </div>
    </div>
  );
};

export default ConnectedIntegrationRow;
