import classnames from 'classnames';

import styles from './MaterialToggleSwitch.module.scss';
import SwitchLoading from './SwitchLoading/SwitchLoading';

export interface MaterialToggleSwitchProps {
  name: string;
  value: boolean;
  label?: string;
  isLoading?: boolean;
  disabled?: boolean;
  customContainerClass?: string;
  falseLabel?: string;
  trueLabel?: string;
  onChange: (bool: boolean) => void;
}

const MaterialToggleSwitch = ({
  name,
  value,
  label,
  isLoading,
  disabled,
  customContainerClass,
  falseLabel,
  trueLabel,
  onChange
}: MaterialToggleSwitchProps) => (
  <div className={classnames(styles.container, customContainerClass && customContainerClass)}>
    {label && <div className={styles.label}>{label}</div>}
    <label className={classnames(styles.switchToggle, disabled && styles.disabled)} htmlFor={name}>
      {falseLabel && <span className={classnames(styles.switchLabel, !value && styles.active)}>{falseLabel}</span>}
      <input
        type={'checkbox'}
        id={name}
        name={name}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {isLoading ? (
        <div className={styles.loadingDot}>
          <div className={styles.loadingSwitch}>
            <SwitchLoading isCheck={value} />
          </div>
        </div>
      ) : (
        <div className={styles.dot} />
      )}
      {trueLabel && <span className={classnames(styles.switchLabel, value && styles.active)}>{trueLabel}</span>}
    </label>
  </div>
);

export default MaterialToggleSwitch;
