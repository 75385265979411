import {
  faCalendarAlt,
  faCaretSquareLeft,
  faFileInvoice,
  faIdBadge,
  faUserFriends,
  faUsers,
  faWrench,
  faChartArea,
  IconDefinition,
  faBrain,
  faBookReader,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from './SideDashboard.module.scss';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { useNavigate } from 'react-router-dom';

interface SideDashboardItem {
  icon: IconDefinition;
  text: string;
  path: string;
  isActive: boolean;
  id?: string;
}

interface SideDashboardProps {
  showSideBar: MouseEventHandler;
  isEdgeReceptionist: boolean;
  isEdgeAdminView: boolean;
  isEdgeUserView: boolean;
  isEdgePlanUser: boolean;
}

const SideDashboard = ({
  showSideBar,
  isEdgeUserView,
  isEdgeReceptionist,
  isEdgeAdminView,
  isEdgePlanUser
}: SideDashboardProps) => {
  const navigate = useNavigate();
  const {
    CLIENTS,
    GROUPS,
    PRACTITIONER,
    CALENDAR,
    FORMS,
    CONTROL_PANEL,
    ANALYTICS,
    INTERVENTIONS,
    INTERVENTION_LIBRARY,
    ASSESSMENT
  } = useRoutesGenerator();
  const { isGroupsFeatureToggle, isInterventionsFeatureToggle } = useGetFeatureToggle();
  const { profileSettings } = useGetAccountSettings();
  const toggleSideDashboardItem = (show: boolean, item: SideDashboardItem) => (show ? [item] : []);

  const analyticContent = (profileSettings?.analyticContent && profileSettings?.analyticContent?.length > 0) || false;

  const [t] = useTranslation();

  const sideBarMenu: SideDashboardItem[] = [
    {
      icon: faUserFriends,
      text: t('sidedashboard.client'),
      path: CLIENTS.BASE,
      isActive: false,
      id: 'clientMenuId'
    },
    ...toggleSideDashboardItem(isGroupsFeatureToggle, {
      icon: faUsers,
      text: 'Groups',
      path: GROUPS.BASE,
      isActive: false,
      id: 'groupMenuId'
    }),
    ...toggleSideDashboardItem(isEdgeAdminView, {
      icon: faChartBar,
      text: 'All Assessments',
      path: ASSESSMENT.BASE,
      isActive: false,
      id: 'allAssessmentsId'
    }),
    ...toggleSideDashboardItem(isEdgeUserView, {
      icon: faChartBar,
      text: 'My Assessments',
      path: ASSESSMENT.BASE,
      isActive: false,
      id: 'myAssessmentsId'
    }),
    ...toggleSideDashboardItem(isInterventionsFeatureToggle, {
      icon: faBrain,
      text: 'Interventions',
      path: INTERVENTIONS.BASE,
      isActive: false,
      id: 'interventionMenuId'
    }),
    ...toggleSideDashboardItem(isEdgeAdminView, {
      icon: faIdBadge,
      text: 'Staff',
      path: PRACTITIONER.BASE,
      isActive: false
    }),
    ...toggleSideDashboardItem(isInterventionsFeatureToggle, {
      icon: faBookReader,
      text: 'Intervention Library',
      path: INTERVENTION_LIBRARY,
      isActive: false,
      id: 'interventionLibraryMenuId'
    }),
    {
      icon: faCalendarAlt,
      text: 'Calendar',
      path: CALENDAR.BASE,
      isActive: false,
      id: 'calendarMenuId'
    },
    ...toggleSideDashboardItem(!isEdgeReceptionist, {
      icon: faFileInvoice,
      text: 'Forms',
      path: FORMS.BASE,
      isActive: false,
      id: 'formMenuId'
    }),
    {
      icon: faWrench,
      text: 'Control Panel',
      path: isEdgeAdminView ? CONTROL_PANEL.SCHOOL : CONTROL_PANEL.BASE,
      isActive: false,
      id: 'controlPanelMenuId'
    },
    ...toggleSideDashboardItem(isEdgeAdminView && analyticContent, {
      icon: faChartArea,
      text: 'Analytics',
      path: ANALYTICS.BASE,
      isActive: false,
      id: 'analyticMenuId'
    })
  ];

  const mapUrlWithBtn = () => {
    const currentPath = isEdgePlanUser
      ? `/${window.location.pathname.split('/')[2]}`
      : `/${window.location.pathname.split('/')[1]}`;

    sideBarMenu.map((obj) => {
      let sideMenu = { ...obj };
      if (
        obj &&
        (obj.path.length > 1
          ? currentPath.includes(isEdgePlanUser ? `/${obj.path.split('/')[2]}` : `/${obj.path.split('/')[1]}`)
          : currentPath === `/${obj.path.split('/')[1]}`)
      ) {
        obj.isActive = true;
      }

      return sideMenu;
    });

    return sideBarMenu;
  };

  const onClickBtn = (val: string) => {
    navigate(val);
  };

  const sideMenu = mapUrlWithBtn();
  const colorTheme = isEdgeAdminView ? styles.edgeSUTheme : styles.defaultTheme;

  return (
    <div className={classnames(styles.container, colorTheme)}>
      <div className={styles.closeMenuWrapper}>
        <div className={styles.closeMenu} onClick={showSideBar}>
          <FontAwesomeIcon icon={faCaretSquareLeft} className={styles.backBtn} />
        </div>
      </div>
      <div className={styles.sideMenuWrapper}>
        <div className={styles.gap} />
        {sideMenu.map((menu, index) => (
          <div
            id={menu.id}
            className={menu.isActive ? styles.btnWrapperActive : styles.btnWrapper}
            key={index}
            onClick={() => onClickBtn(menu.path)}
          >
            <div>
              <FontAwesomeIcon icon={menu.icon} className={styles.icon} />
            </div>
            <div className={styles.text}>{menu.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideDashboard;
