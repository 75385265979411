import { Moment } from 'moment';

export const getDayDiff = (currentDateTime: Moment, diffDateTime: Moment) => {
  let dateTime = '';
  if (currentDateTime.diff(diffDateTime, 'minutes') <= 60) {
    dateTime = `${currentDateTime.diff(diffDateTime, 'minute')} minutes`;
  } else if (currentDateTime.diff(diffDateTime, 'hours') <= 24) {
    dateTime = `${currentDateTime.diff(diffDateTime, 'hour')} hours`;
  } else {
    dateTime = `${currentDateTime.diff(diffDateTime, 'day')} days`;
  }
  return dateTime;
};
