import Select, { SelectProps } from '../CommonSelect/Select';

import styles from './MaterialSelect.module.scss';

export interface MaterialSelectProps extends SelectProps {
  id?: string;
  className?: String;
  label: string;
  isSearchable: boolean;
  value: string;
  onChange: (value: any) => void;
  loading?: boolean;
  boldValue?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
}

const MaterialSelect = ({
  id,
  className,
  label,
  isSearchable,
  value,
  onChange,
  loading,
  boldValue,
  isError,
  isDisabled,
  ...props
}: MaterialSelectProps) => {
  return (
    <Select
      {...props}
      id={id}
      className={className}
      label={label}
      labelClass={styles.selectLabel}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      smallCaretDown
      styles={{
        container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
        valueContainer: (base: any) => ({
          ...base,
          minHeight: '30px',
          padding: '0 8px 0 0',
          cursor: 'pointer',
          fontWeight: boldValue ? 'bold' : 'normal'
        }),
        option: (menuBase: any) => ({
          ...menuBase,
          cursor: 'pointer'
        }),
        control: (controlBase: any) => ({
          ...controlBase,
          minHeight: '30px',
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: isError ? '1px solid #ff4d4f' : `1px solid ${styles.greyColor}`,
          borderRadius: 0,
          boxShadow: 'none'
        }),
        singleValue: (base) => ({
          ...base,
          whiteSpace: 'normal',
          position: 'initial',
          transform: 'none'
        }),
        input: (base) => ({
          ...base,
          position: 'absolute'
        })
      }}
      value={value}
      onChange={(e) => onChange(e?.value)}
      loading={loading}
    />
  );
};

export default MaterialSelect;
