import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  InterventionMemberList,
  InterventionMemberListFilterParameter
} from 'MHH/pages/Interventions/Interfaces/Interventions';
import { getInterventionMembersById } from 'utils/http/ClinicianProfileService/Interventions/InterventionMembers';
import queryString from 'query-string';

export const useFetchInterventionMemberList = (
  token: string,
  interventionId: string,
  filterValue: InterventionMemberListFilterParameter,
  refreshList?: number
) => {
  const [interventionMembers, setInterventionMembers] = useState<InterventionMemberList>({
    clientRecords: [],
    count: {
      active: 0,
      completed: 0,
      archived: 0,
      all: 0
    },
    paging: {
      page: 1,
      perPage: 20,
      totalItem: 0
    }
  });
  const [isInterventionMembersLoading, setIsInterventionMembersLoading] = useState(true);

  const fetchInterventionMemberList = useCallback(
    async (token: string, filterValue: InterventionMemberListFilterParameter) => {
      try {
        setIsInterventionMembersLoading(true);
        const massageParam = {
          ...filterValue,
          ...(filterValue?.status === 'all' && {
            status: undefined
          })
        };
        const qParam = queryString.stringify(massageParam || {});

        const getMemberData = await getInterventionMembersById(token, interventionId, qParam);
        const memberListData: InterventionMemberList = await getMemberData.json();
        const sumCount = Object.values(memberListData.count).reduce((a: number, b: number) => a + b) ?? 0;

        const massageRes = {
          ...memberListData,
          count: {
            ...memberListData.count,
            all: sumCount
          }
        };

        setInterventionMembers(massageRes);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your interventions member list' });
      }
      setIsInterventionMembersLoading(false);
    },
    [interventionId]
  );

  useEffect(() => {
    if (token) {
      fetchInterventionMemberList(token, filterValue);
    }
  }, [fetchInterventionMemberList, token, filterValue, refreshList]);

  return { interventionMembers, isInterventionMembersLoading, fetchInterventionMemberList };
};
