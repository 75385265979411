import { useEffect } from 'react';

import styles from './AddProspectClientForm.module.scss';
import QuickNoteForm from '../QuickNoteForm/QuickNoteForm';
import Button from '../../../v2/Button/Button';
import { newProspectClientForm, prospectValidateField } from '../../AddProspectClientInterface';
import { validationProspectClientForm } from '../validation/ProspectClientFormValidation';
import ProspectFieldForm from '../ProspectFieldForm/ProspectFieldForm';

interface AddProspectClientFormProps {
  id: string;
  prospectClientField: newProspectClientForm;
  onChangeClientField: any;
  handleSubmit: any;
  checkValidation: boolean;
  submitStatus: '' | 'active' | 'finished';
  errorMessage: prospectValidateField;
}

const AddProspectClientForm = ({
  id,
  prospectClientField,
  onChangeClientField,
  handleSubmit,
  checkValidation,
  submitStatus,
  errorMessage
}: AddProspectClientFormProps) => {
  useEffect(() => {
    if (checkValidation) {
      validationProspectClientForm(prospectClientField);
    }
  }, [checkValidation, prospectClientField]);

  const handleChangeQuickNote = (val: newProspectClientForm['note']) => {
    const newProspectField = {
      ...prospectClientField,
      note: val
    };
    onChangeClientField(newProspectField);
  };

  return (
    <div className={styles.container}>
      <ProspectFieldForm
        prospectClientField={prospectClientField}
        checkValidation={checkValidation}
        onChangeClientField={onChangeClientField}
        errorMessage={errorMessage}
      />
      <QuickNoteForm
        id={id}
        prospectQuickNote={prospectClientField.note}
        checkValidation={checkValidation}
        onChangeQuiteNote={handleChangeQuickNote}
      />
      <div className={styles.submitButtonContainer}>
        <Button status={submitStatus} onClick={handleSubmit}>
          Create Prospect
        </Button>
      </div>
    </div>
  );
};

export default AddProspectClientForm;
