import Button from 'components/v2/Button/Button';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ReportHeader.module.scss';
import { useNavigate } from 'react-router-dom';

interface ReportHeaderProps {
  onClick?: Function;
}
const ReportHeader = ({ onClick = () => {} }: ReportHeaderProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Reports & Letters</div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <Button
          className={styles.button}
          variant={'secondary'}
          type="button"
          onClick={() => {
            localStorage.setItem('reportPrevPath', '');
            navigate(REPORTS.NEW_TEMPLATE_EDITOR);
          }}
        >
          <i className={`material-icons ${styles.icon}`}>add</i>
          Add New Template
        </Button>
        <div className={styles.addNewBtnWrapper} onClick={() => onClick()} id={'createNewReportId'}>
          <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
          Create New Report
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
