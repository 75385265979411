import { Formik } from 'formik';
import { memo } from 'react';
import { appointmentScheduleSchema, generateInitValue, initialValues, initialValuesUpdate } from './constants';
import FormSection from 'components/v2/FormSection/FormSection';
import Button from 'components/v2/Button/Button';
import styles from './AppointmentScheduleForm.module.scss';
import ScheduleType from './components/ScheduleType/ScheduleType';
import Gap from './components/Gap/Gap';
import SessionStartAt from './components/SessionStartAt/SessionStartAt';
import AutoFit from './components/AutoFit/AutoFit';
import FixedTime from './components/FixedTime/FixedTime';
import moment from 'moment';
import { AppointmentType, ParticipantType, ScheduleOption } from 'interfaces/Schedule/AppointmentType';

interface AppointmentScheduleFormProps {
  duration: number;
  data?: AppointmentType;
  onSubmit: (values: typeof initialValues) => void;
  formValues?: Partial<AppointmentType>;
}

const AppointmentScheduleForm = ({ data, duration, onSubmit, formValues }: AppointmentScheduleFormProps) => {
  const initialUpdate = data && initialValuesUpdate(data);
  const initValue = generateInitValue(formValues?.participantType) as typeof initialValues;

  const reformatFixedTime = (val?: string[]) => {
    if (val) {
      return val.map((obj) => moment(obj, 'hh:mm A').format('HH:mm'));
    } else {
      return [];
    }
  };

  const reformatAutoFitTIme = (val?: string) => {
    if (val) {
      return moment(val, 'hh:mm').format('HH:mm');
    } else {
      return '';
    }
  };

  const handleSubmit = (values: typeof initialValues) => {
    const massageData = {
      ...values,
      dayTimeSelections: {
        kind: values.dayTimeSelections?.kind,
        autoFit: {
          Monday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Monday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Monday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Monday.isActive
          },
          Tuesday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Tuesday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Tuesday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Tuesday.isActive
          },
          Wednesday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Wednesday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Wednesday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Wednesday.isActive
          },
          Thursday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Thursday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Thursday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Thursday.isActive
          },
          Friday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Friday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Friday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Friday.isActive
          },
          Saturday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Saturday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Saturday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Saturday.isActive
          },
          Sunday: {
            startTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Sunday.startTime),
            endTime: reformatAutoFitTIme(values.dayTimeSelections?.autoFit?.Sunday.endTime),
            isActive: values.dayTimeSelections?.autoFit?.Sunday.isActive
          }
        },
        fixedTime: {
          Monday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Monday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Monday.isActive
          },
          Tuesday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Tuesday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Tuesday.isActive
          },
          Wednesday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Wednesday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Wednesday.isActive
          },
          Thursday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Thursday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Thursday.isActive
          },
          Friday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Friday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Friday.isActive
          },
          Saturday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Saturday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Saturday.isActive
          },
          Sunday: {
            startTimes: reformatFixedTime(values.dayTimeSelections?.fixedTime?.Sunday.startTimes),
            isActive: values.dayTimeSelections?.fixedTime?.Sunday.isActive
          }
        }
      }
    } as Partial<AppointmentType>;

    onSubmit(massageData);
  };

  return (
    <Formik
      initialValues={initialUpdate || initValue}
      validationSchema={appointmentScheduleSchema(duration)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, submitForm }) => (
        <div className={styles.container}>
          <FormSection
            title="Schedule set up"
            help="Auto-fit schedule types maximises your appointment schedule based on prescribed rules. Fixed time schedule types allow manual control over available appointment types."
          >
            <div className={styles.form}>
              <div className={styles.row}>
                {formValues?.participantType === ParticipantType.OneToOne && (
                  <div className={styles.scheduleTypeContainer}>
                    <ScheduleType />
                  </div>
                )}
                <div>{values.dayTimeSelections?.kind === ScheduleOption.AutoFit && <Gap />}</div>
                <div>{values.dayTimeSelections?.kind === ScheduleOption.AutoFit && <SessionStartAt />}</div>
              </div>
              {values.dayTimeSelections?.kind === ScheduleOption.AutoFit ? (
                <AutoFit />
              ) : (
                <FixedTime duration={duration} />
              )}
            </div>
          </FormSection>
          <div className={styles.footer}>
            <Button onClick={submitForm}>Next to set up delivery modes</Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default memo(AppointmentScheduleForm);
