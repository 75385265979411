import { useEffect, useState } from 'react';
import { FILTER_COLOURS, MAX_OF_FILTER } from 'components/v2/CalendarFilter/constants';
import { useFetchFilterList } from './hooks/GetFilterList';
import { useGetAccessToken } from 'utils/hooks/token';
import CalendarFilter from 'components/v2/CalendarFilter/CalendarFilter';
import styles from './CalendarFilterSection.module.scss';
import { CalendarFilterInterface, CalendarFilterSectionProps } from 'components/v2/CalendarFilter/interfaces';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const CalendarFilterSection = (props: CalendarFilterSectionProps) => {
  const { selectedFilters, setSelectedFilters } = props;
  const { isEdgeReceptionist } = useGetAccountPackageView();

  const { token } = useGetAccessToken();
  const { roomList, isRoomListLoading, practitionersList, isPractitionersListLoading } = useFetchFilterList(token);
  const [filterList, setFilterList] = useState<CalendarFilterInterface[]>([]);

  useEffect(() => {
    if (roomList.length >= 0 && practitionersList.length > 0) {
      let data: CalendarFilterInterface[] = [];
      data = practitionersList.map((practitioner) => {
        let filterItem = {} as CalendarFilterInterface;
        filterItem._id = practitioner._id;
        filterItem.name = practitioner.name;
        filterItem.avatar = practitioner.avatar;
        filterItem.isClinician = true;
        return filterItem;
      });
      data = data.concat(
        roomList.map((room) => {
          let filterItem = {} as CalendarFilterInterface;
          filterItem._id = room._id;
          filterItem.name = room.name;
          filterItem.isClinician = false;
          return filterItem;
        })
      );

      setFilterList(data);
    }
  }, [roomList, practitionersList]);

  const onSelectFilter = (index: number, selectedFilter?: CalendarFilterInterface) => {
    if (selectedFilter) {
      if (index === selectedFilters.length && index !== MAX_OF_FILTER) {
        setSelectedFilters([...selectedFilters, selectedFilter]);
      } else {
        setSelectedFilters(selectedFilters.map((filter, i) => (i === index ? selectedFilter : filter)));
      }
    } else {
      setSelectedFilters(selectedFilters.filter((filter, i) => i !== index));
    }
  };

  const isShowCalendarFilter = (index: number) => {
    return index === 0 || (index > 0 && selectedFilters[index - 1] !== undefined);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>VIEW CALENDARS</div>
      <div className={styles.filters}>
        {Array(MAX_OF_FILTER)
          .fill(null)
          .map((item, index) => {
            if (isShowCalendarFilter(index)) {
              return (
                <CalendarFilter
                  key={index}
                  allowEmpty={index > 0}
                  filterList={filterList}
                  selectedId={index === 0 && isEdgeReceptionist ? '' : selectedFilters[index]?._id}
                  isDataFetching={isRoomListLoading || isPractitionersListLoading}
                  onSelectFilter={(filter) => onSelectFilter(index, filter)}
                  backgroundColor={selectedFilters[index]?._id !== undefined ? FILTER_COLOURS[index] : '#fff'}
                  excludedFilterIds={selectedFilters.filter((filter, i) => i !== index).map((filter) => filter._id)}
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default CalendarFilterSection;
