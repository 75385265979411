import styles from './AnalyticsContent.module.scss';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { useState } from 'react';
import Button from 'components/v2/Button/Button';

interface SideMenu {
  id: string;
  icon: string;
  label: string;
  link: string;
  isActive: boolean;
}

const AnalyticsContent = () => {
  const { profileSettings } = useGetAccountSettings();

  const sideMenuList =
    profileSettings?.analyticContent?.map((obj, index) => ({
      id: index.toString(),
      icon: 'analytics',
      label: obj.title,
      link: obj.embedLink,
      isActive: index === 0
    })) || [];
  const [sideMenu, setSideMenu] = useState<SideMenu[]>(sideMenuList);

  const handleSelectAnalytic = (id: SideMenu['id']) => {
    const massageList = sideMenuList.map((obj) => ({
      ...obj,
      isActive: obj.id === id
    }));

    setSideMenu(massageList);
  };

  const onClickRequestNewReport = () => {
    window.location.href = 'mailto:support@mentalhealthhub.com';
  };

  return (
    <div className={styles.container}>
      <div className={styles.sideMenuContainer}>
        {sideMenu.map((obj, index: number) => (
          <div
            className={obj.isActive ? styles.menuCardActive : styles.menuCard}
            key={index}
            onClick={() => handleSelectAnalytic(obj.id)}
          >
            <div className={styles.labelWrapper}>
              <div className={styles.label}>{obj.label}</div>
            </div>
          </div>
        ))}
        <div className={styles.requestReportWrapper}>
          <Button variant={'secondary'} className={styles.requestReportBtn} onClick={onClickRequestNewReport}>
            <div>Request a new report</div>
            <i className={`material-icons`}>equalizer</i>
          </Button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {sideMenu.map(
          (obj, index) =>
            obj.isActive && (
              <div key={index} className={styles.contentBox}>
                <iframe title={'analytic'} width={'100%'} height={'100%'} src={obj.link} frameBorder={0} />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default AnalyticsContent;
