import Badge from 'components/Badge/Badge';
import Button from 'components/v2/Button/Button';
import { useFetchSchoolConnection } from 'pages/ControlPanel/ControlPanel/hooks/getSchoolConnectionData';
import { ImportDataStatus, SchoolConnectionStatus } from 'pages/ControlPanel/Interfaces/MisConnection';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { DisconnectModal } from './components/DisconnectModal/DisconnectModal';
import { ConnectSchoolModal } from './components/ConnectSchoolModal/ConnectSchoolModal';
import { ImportDataModal } from './components/ImportDataModal/ImportDataModal';
import { ImportHistoryTable } from './components/ImportHistoryTable/ImportHistoryTable';
import styles from './MisConnection.module.scss';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import moment from 'moment';
import { useFetchSchoolDataImportHistory } from 'pages/ControlPanel/ControlPanel/hooks/useFetchSchoolDataImportHistory';
import ReactTooltip from 'react-tooltip';
import LoadingDot from 'components/LoadingDot/LoadingDot';

const getStatusColor = (status: SchoolConnectionStatus) => {
  switch (status) {
    case SchoolConnectionStatus.Connected:
      return '#4BC27D';
    case SchoolConnectionStatus.Pending:
      return '#EE704C';
    case SchoolConnectionStatus.Rejected:
    case SchoolConnectionStatus.Revoked:
      return '#C7C9CD';
  }
};

const getAutoSyncEnabledColor = (autoSyncEnabled?: boolean) => {
  return autoSyncEnabled ? '#4BC27D' : '#C7C9CD';
};

export const MisConnection = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [connectVisible, setConnectVisible] = useState<boolean>(false);
  const [importVisible, setImportVisible] = useState<boolean>(false);
  const [disconnectVisible, setDisconnectVisible] = useState<boolean>(false);
  const { schoolConnection, isSchoolConnectionLoading, refetchSchoolConnection } = useFetchSchoolConnection(
    token,
    accountId
  );
  const { dataImportHistory, isHistoryLoading, refetchSchoolDataImportHistory } = useFetchSchoolDataImportHistory(
    token,
    accountId
  );
  const disableImportData = !!dataImportHistory.filter((history) => history.status === ImportDataStatus.Processing)
    .length;

  return (
    <div className={styles.container}>
      {isSchoolConnectionLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.subHeader}>
              <div className={styles.title}>MIS Connection</div>
              <div className={styles.description}>Connect to import student and teacher data.</div>
            </div>
            {schoolConnection?.status === SchoolConnectionStatus.Connected && (
              <Button
                className={styles.cancelButton}
                onClick={() => setDisconnectVisible(true)}
                id="disconnectMisButton"
              >
                Disconnect MIS
              </Button>
            )}
            {schoolConnection?.status &&
              [SchoolConnectionStatus.Revoked, SchoolConnectionStatus.Rejected].includes(schoolConnection.status) && (
                <Button
                  className={styles.connectMisButton}
                  onClick={() => setConnectVisible(true)}
                  id="disconnectMisButton"
                >
                  Reconnect MIS
                </Button>
              )}
          </div>
          <div className={styles.content}>
            {schoolConnection ? (
              <>
                <div className={styles.descriptionContainer}>
                  <div className={styles.statusContainer}>
                    STATUS
                    <Badge
                      label={schoolConnection.status}
                      className={styles.badge}
                      backgroundColor={getStatusColor(schoolConnection.status)}
                    />
                    <Badge
                      label={schoolConnection.autoSync?.enabled ? 'Auto Sync Enabled' : 'Auto Sync Disabled'}
                      className={styles.badge}
                      backgroundColor={getAutoSyncEnabledColor(schoolConnection.autoSync?.enabled)}
                    />
                  </div>
                  <div className={styles.schoolContainer}>
                    SCHOOL DETAIL
                    <div>Name: {schoolConnection.name}</div>
                    <div>Address: {schoolConnection.address || '-'}</div>
                    <div>Postcode: {schoolConnection.postcode || '-'}</div>
                    <div>Establishment Number: {schoolConnection.establishmentNumber || '-'}</div>
                    <div>Unique Reference Number: {schoolConnection.urn || '-'}</div>
                    <div>
                      Auto Syncing:
                      <div className={styles.autoSyncDetails}>
                        <span>Teachers: {schoolConnection.autoSync?.teacher ? 'Yes' : 'No'}</span>
                        <span>Students: {schoolConnection.autoSync?.student ? 'Yes' : 'No'}</span>
                        <span>Assigned Teacher: {schoolConnection.autoSync?.assignedTeacher ? 'Yes' : 'No'}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.connectionDetailsContainer}>
                    <div>Connected since: {moment(schoolConnection.connectedAt).format('DD MMM YYYY')}</div>
                    <div>Set up by: {schoolConnection.connectedBy}</div>
                    <div className={styles.summary}>
                      Summary:
                      <br />
                      MIS connection is to improve connectivity and high-level security through centralised school data
                      management. You can start importing school data including student and teacher data to CORDS to
                      ensure your data is securely maintained, managed and accessible. Auto sync, if enabled, runs every
                      24 hours at 12am.
                    </div>
                  </div>
                </div>
                {schoolConnection.status === SchoolConnectionStatus.Connected && (
                  <>
                    <Button
                      className={styles.connectMisButton}
                      disabled={disableImportData}
                      onClick={() => setImportVisible(true)}
                      id="importButton"
                      data-tip
                      data-for={'disabled-import-data'}
                    >
                      Import Data
                    </Button>
                    {disableImportData && (
                      <ReactTooltip id="disabled-import-data" className={styles.tooltip}>
                        There are one or more data imports ongoing, please wait for it to finish.
                      </ReactTooltip>
                    )}
                  </>
                )}
                {[SchoolConnectionStatus.Connected, SchoolConnectionStatus.Revoked].includes(
                  schoolConnection.status
                ) && <ImportHistoryTable histories={dataImportHistory} isLoading={isHistoryLoading} />}
              </>
            ) : (
              <Button className={styles.connectMisButton} onClick={() => setConnectVisible(true)} id="connectMisButton">
                Connect MIS
              </Button>
            )}
          </div>
          <ConnectSchoolModal
            visible={connectVisible}
            onClose={() => setConnectVisible(false)}
            refetchSchoolConnection={refetchSchoolConnection}
          />
          <ImportDataModal
            visible={importVisible}
            onClose={() => setImportVisible(false)}
            refetchSchoolDataImportHistory={refetchSchoolDataImportHistory}
          />
          <DisconnectModal
            visible={disconnectVisible}
            onClose={() => setDisconnectVisible(false)}
            refetchSchoolConnection={refetchSchoolConnection}
          />
        </>
      )}
    </div>
  );
};
