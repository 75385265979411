import { Dropdown, Menu, Skeleton } from 'antd';
import Button from 'components/Button/Button';
import ParticipationToggle from 'components/SelectClientOrGroup/components/ParticipationHeader/components/ParticipationToggle/ParticipationToggle';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import { Invoice } from '../../interface';
import ClientInvoiceItem from './components/ClientInvoiceItem/ClientInvoiceItem';
import GroupInvoiceItem from './components/GroupInvoiceItem/GroupInvoiceItem';
import InvoiceModal from './components/InvoiceModal/InvoiceModal';
import styles from './InvoiceListing.module.scss';
import NoInvoices from 'assets/images/NoInvoices.png';

const STATUS_FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'draft' },
  { label: 'Issued', value: 'issued' },
  { label: 'Overdue', value: 'overdue' },
  { label: 'Marked Paid', value: 'markedPaid' },
  { label: 'Confirm Paid', value: 'confirmPaid' },
  { label: 'Closed', value: 'closed' }
];

interface InvoiceListingProps {
  invoices: Invoice[];
  groupInvoices?: Invoice[];
  isInvoicesLoading: boolean;
  allowCreateNewInvoice: boolean;
  onChangeStatus: (_id: string, status: 'closed' | 'confirmPaid', groupDetail?: Invoice['group']) => void;
  onResendInvoice: (_id: string) => void;
  invoiceIdProcessing: string;
  defaultGroupId?: string;
  enableGroupSelection?: boolean;
}

const InvoiceListing = ({
  invoices,
  groupInvoices,
  isInvoicesLoading,
  allowCreateNewInvoice,
  onChangeStatus,
  onResendInvoice,
  invoiceIdProcessing,
  defaultGroupId,
  enableGroupSelection
}: InvoiceListingProps) => {
  const { INVOICES } = useRoutesGenerator();
  const { invoiceId } = useParams<{ invoiceId?: string }>();
  const [dateSort, setDateSort] = useState<'ascending' | 'descending'>('descending');
  const [selectedInvoiceId, setSelectedInvoiceId] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);

  const [participationType, setParticipationType] = useState<ParticipantType>(
    defaultGroupId ? ParticipantType.Group : ParticipantType.OneToOne
  );

  const invoiceList = useMemo(() => {
    let invoiceList = invoices;

    if (statusFilter !== 'all') {
      invoiceList = invoiceList.filter((invoice) => invoice.status === statusFilter);
    }

    if (dateSort === 'ascending') {
      invoiceList = invoiceList.sort((a, b) => {
        if (a.issueDate > b.issueDate) {
          return 1;
        } else if (a.issueDate < b.issueDate) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      invoiceList = invoiceList.sort((a, b) => {
        if (a.issueDate > b.issueDate) {
          return -1;
        } else if (a.issueDate < b.issueDate) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return invoiceList;
  }, [dateSort, invoices, statusFilter]);

  const groupInvoiceList = useMemo(() => {
    let groupInvoiceList = groupInvoices || [];

    if (statusFilter !== 'all') {
      groupInvoiceList = groupInvoiceList.filter((invoice) => invoice.status === statusFilter);
    }

    if (dateSort === 'ascending') {
      groupInvoiceList = groupInvoiceList.sort((a, b) => {
        if (a.issueDate > b.issueDate) {
          return 1;
        } else if (a.issueDate < b.issueDate) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      groupInvoiceList = groupInvoiceList.sort((a, b) => {
        if (a.issueDate > b.issueDate) {
          return -1;
        } else if (a.issueDate < b.issueDate) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return groupInvoiceList;
  }, [dateSort, groupInvoices, statusFilter]);

  const handleDateSortClick = () => {
    if (dateSort === 'ascending') {
      setDateSort('descending');
    } else {
      setDateSort('ascending');
    }
  };

  const handleInvoiceItemClick = (invoiceId: string) => () => {
    setSelectedInvoiceId(invoiceId);
    setIsInvoiceModalVisible(true);
  };

  const handleInvoiceModalClose = () => {
    setSelectedInvoiceId('');
    setIsInvoiceModalVisible(false);
  };

  useEffect(() => {
    if (invoiceId) {
      setSelectedInvoiceId(invoiceId);
      setIsInvoiceModalVisible(true);
    }
  }, [invoiceId]);

  return (
    <>
      <InvoiceModal
        invoice={
          participationType === ParticipantType.Group
            ? groupInvoiceList.find((invoice) => invoice._id === selectedInvoiceId)
            : invoiceList.find((invoice) => invoice._id === selectedInvoiceId)
        }
        visible={
          isInvoiceModalVisible &&
          (!!invoiceList.find((invoice) => invoice._id === selectedInvoiceId) ||
            !!groupInvoiceList.find((invoice) => invoice._id === selectedInvoiceId))
        }
        onChangeStatus={onChangeStatus}
        onClose={handleInvoiceModalClose}
        onResendInvoice={onResendInvoice}
        isInvoiceActionProcessing={selectedInvoiceId === invoiceIdProcessing}
      />
      <div className={styles.container}>
        {isInvoicesLoading ? (
          <Skeleton active />
        ) : invoices.length === 0 && groupInvoices?.length === 0 ? (
          <div className={styles.noInvoices}>
            <img className={styles.image} src={NoInvoices} alt="No Invoices" />
            <div className={styles.prompt}>
              <div className={styles.text}>No invoices created</div>
              {allowCreateNewInvoice && (
                <Link className={styles.link} to={INVOICES.NEW}>
                  <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                  Create New Invoice
                </Link>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={styles.filter}>
              <div className={styles.filterLabel}>Filter:</div>
              <Dropdown
                overlay={
                  <Menu onClick={(e) => setStatusFilter(String(e.key))}>
                    {STATUS_FILTER_OPTIONS.map((option) => (
                      <Menu.Item key={option.value}>{option.label}</Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <div className={styles.dropdownText}>
                  {STATUS_FILTER_OPTIONS.find((option) => option.value === statusFilter)?.label}
                  <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
                </div>
              </Dropdown>
              {enableGroupSelection && (
                <div className={styles.toggleWrapper}>
                  <ParticipationToggle
                    selectedParticipantType={participationType}
                    onChangeParticipation={(val) => {
                      setParticipationType(val);
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.listHeader}>
              {participationType === ParticipantType.Group ? (
                <div className={styles.nameLabel}>Name</div>
              ) : (
                <div className={styles.profile} />
              )}
              <span className={styles.invoiceId}>Invoice ID</span>
              <Button className={`${styles.issueDate} ${styles.button}`} onClick={handleDateSortClick}>
                Dated
                <i className={`material-icons-outlined ${styles.icon}`}>
                  {dateSort === 'ascending' ? 'arrow_drop_up' : 'arrow_drop_down'}
                </i>
              </Button>
              <span className={styles.description}>Description</span>
              <span className={styles.amount}>Amount</span>
              <span className={styles.status}>Status</span>
              <span className={styles.action}>Action</span>
            </div>
            {participationType === ParticipantType.Group ? (
              groupInvoiceList?.length === 0 ? (
                <div className={styles.noInvoicesFilter}>
                  <div className={styles.text}>
                    No group invoices{' '}
                    {statusFilter !== 'all' &&
                      STATUS_FILTER_OPTIONS.find((option) => option.value === statusFilter)?.label.toLowerCase()}
                  </div>
                </div>
              ) : (
                groupInvoiceList?.map((invoice, index) => (
                  <GroupInvoiceItem
                    key={index}
                    invoice={invoice}
                    onChangeStatus={onChangeStatus}
                    onInvoiceClick={handleInvoiceItemClick(invoice._id)}
                    onResendInvoice={onResendInvoice}
                    isInvoiceActionProcessing={invoice._id === invoiceIdProcessing}
                  />
                ))
              )
            ) : invoiceList?.length === 0 ? (
              <div className={styles.noInvoicesFilter}>
                <div className={styles.text}>
                  No invoices{' '}
                  {statusFilter !== 'all' &&
                    STATUS_FILTER_OPTIONS.find((option) => option.value === statusFilter)?.label.toLowerCase()}{' '}
                  filter
                </div>
              </div>
            ) : (
              invoiceList.map((invoice, index) => (
                <ClientInvoiceItem
                  key={index}
                  invoice={invoice}
                  onChangeStatus={onChangeStatus}
                  onResendInvoice={onResendInvoice}
                  isInvoiceActionProcessing={invoice._id === invoiceIdProcessing}
                />
              ))
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InvoiceListing;
