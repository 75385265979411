import ActiveStudentList from './components/ActiveStudentList/ActiveStudentList';
import { GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';

import styles from './StudentListContent.module.scss';
import SearchBar from 'components/SearchBar/SearchBar';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { studentListQueryParam } from '../../StudentListing';

interface StudentListContentProps {
  clientRecordList: GetClientProfileInterface;
  isClientRecordListLoading: boolean;
  onPageChange: (paging: string) => void;
  onSearch: (q: string) => void;
  isPageLoading: boolean;
}

const StudentListContent = ({
  clientRecordList,
  isClientRecordListLoading,
  onPageChange,
  onSearch,
  isPageLoading
}: StudentListContentProps) => {
  const location = useLocation();
  const queryParam: studentListQueryParam = queryString.parse(location.search);
  const listLoading = isClientRecordListLoading || isPageLoading;

  const handleSearch = (val: string) => {
    onSearch(val);
  };

  const handlePageChange = (val: string) => {
    onPageChange(val);
  };

  const clientList = clientRecordList ? clientRecordList.clientRecords : [];

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        Your Students
        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarCard}>
            <SearchBar
              placeholder={'Search by student name or ID'}
              value={queryParam.q}
              withSearchButton
              onSearch={handleSearch}
              label={'SEARCH FOR:'}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardContainer} id={'clientListContentsId'}>
        <ActiveStudentList
          clientRecordList={
            !isClientRecordListLoading ? clientList.filter((clientObj) => clientObj.recordStatus === 'active') : []
          }
          isClientRecordListLoading={listLoading}
          paging={clientRecordList.paging}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default StudentListContent;
