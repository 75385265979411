import { useEffect, useMemo, useState } from 'react';
import styles from './EditChildForm.module.scss';
import { v4 as uuid } from 'uuid';
import { EditClientDetails, editClientForm } from '../../EditClientModalInterface';
import ChildClientForm from 'components/AddPatientModalV2/formType/ChildForm/components/ChildClientForm/ChildClientForm';
import ChildGuardianForm from 'components/AddPatientModalV2/formType/ChildForm/components/ChildGuardianForm/ChildGuardianForm';
import Button from 'components/v2/Button/Button';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateCaseId } from '../../../AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
// import EditProfileType from 'components/EditClientModal/components/EditProfileType/EditProfileType';
import { clientProfilesInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import ProfileStatusButtons from '../../components/profileStatusButtons';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';

interface EditChildFormProps {
  clientDetail: editClientForm['clientProfiles'];
  onChangeClientField: any;
  checkValidation: boolean;
  submitStatus: '' | 'active' | 'finished';
  caseIdValue: editClientForm['caseId'];
  onChangeCaseId: (value: editClientForm['caseId']) => void;
  classValue?: editClientForm['class'];
  onChangeClass?: (value: editClientForm['class']) => void;
  onClickSubmit: () => void;
  excludeCaseIdChecking?: string;
  profileType: clientProfilesInterface['profileType'];
  onChangeProfileType: (val: clientProfilesInterface['profileType']) => void;
  recordStatus: editClientForm['recordStatus'];
  onStatusProfileChange: (val: boolean) => void;
  onClickSaveClosedClient: () => void;
}

const maxGuardianField = 5;

const EditChildForm = ({
  clientDetail,
  onChangeClientField,
  checkValidation,
  submitStatus,
  caseIdValue,
  onChangeCaseId,
  classValue,
  onChangeClass,
  onClickSubmit,
  excludeCaseIdChecking,
  recordStatus,
  onStatusProfileChange,
  onClickSaveClosedClient
}: // profileType,
// onChangeProfileType
EditChildFormProps) => {
  const { token } = useGetAccessToken();
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  useEffect(() => {
    const isSameCaseIdValue = excludeCaseIdChecking === caseIdValue;
    if (checkValidation && caseIdValue && caseIdValue.length > 0 && !isSameCaseIdValue) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: EditClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        _id: obj._id,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        role: obj._id === val._id ? val.role : obj.role,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        ...(obj.role === 'child' && {
          dateOfBirth: obj._id === val._id ? val.dateOfBirth : obj.dateOfBirth
        }),
        ...(obj.role !== 'child' && {
          email: obj._id === val._id ? val.email : obj.email,
          mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber
        }),
        communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token]
  );

  const validateDuplicateCaseId = async (newCaseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(newCaseIdValue);
    }
  };

  const handleCaseIdChange = async (newCaseIdValue: string) => {
    onChangeCaseId(newCaseIdValue);
    const isSameCaseIdValue = excludeCaseIdChecking === newCaseIdValue;
    if (checkValidation && newCaseIdValue.length > 0 && !isSameCaseIdValue) {
      await validateDuplicateCaseId(newCaseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const handleRemoveGuardian = (removeId: string) => {
    if (clientDetail.length >= 2) {
      const removeByIdList = clientDetail.filter((q) => q._id !== removeId);
      const childProfile = removeByIdList.filter((cp) => cp.role === 'child');
      const guardianProfile = removeByIdList.filter((cp) => cp.role !== 'child');

      const newGuardianField = [];
      for (let obj of guardianProfile) {
        newGuardianField.push({
          ...obj,
          isPrimaryContact: guardianProfile.length < 2 ? true : obj.isPrimaryContact
        });
      }
      const massageField = [...childProfile, ...newGuardianField];
      onChangeClientField(massageField);
    }
  };

  const handleAddNewGuardian = () => {
    if (clientDetail.length <= maxGuardianField) {
      const newClientProfile = [
        ...clientDetail,
        {
          _id: uuid(),
          isNewContact: true,
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          isPrimaryContact: false,
          role: ''
        }
      ];
      onChangeClientField(newClientProfile);
    }
  };

  const totalGuardian = clientDetail.filter((obj) => obj.role !== 'child').length;
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.subTitle}>PROFILE STATUS</div>
        <ProfileStatusButtons value={recordStatus} onChange={onStatusProfileChange} />
      </div>
      {recordStatus !== RecordStatus.Closed && (
        <>
          <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
            <MaterialInput
              id={`clientCaseId`}
              label={t('form.client_id')}
              onChange={(e) => handleCaseIdChange(e.target.value)}
              isLoading={isCheckingCaseIdDuplicate}
              value={caseIdValue}
              maxLength={20}
              required
            />
            {checkValidation && duplicateCaseId && (
              <div className={styles.fieldError}>{t('form.error.duplicate_client_id')}</div>
            )}
          </div>
          <ChildClientForm
            id={'childField'}
            clientDetail={clientDetail.find((obj) => obj.role === 'child') || clientDetail[0]}
            onChangeClientField={handleChangeClientField}
            checkValidation={checkValidation}
            classValue={classValue}
            onChangeClass={onChangeClass}
          />
          {/* TODO
      <EditProfileType recordOnly={false} profileTypeProps={profileType} onChangeProfileTypeProps={onChangeProfileType} /> */}
          <div className={styles.clientDetailTitle}>CHILD PROFILE DETAILS</div>
          {clientDetail
            .filter((obj) => obj.role !== 'child')
            .map((obj, index) => (
              <div className={styles.guardianContainer} key={index}>
                <div className={styles.guardianHeader}>
                  <div className={styles.guardianTitle}>PARENT OR GUARDIAN {totalGuardian > 1 && index + 1}</div>
                  <Popconfirm
                    title="Are you sure to remove this Parent or Guardian?"
                    onConfirm={() => handleRemoveGuardian(obj._id)}
                    okText="Yes"
                    cancelText="No"
                    placement={'bottomRight'}
                  >
                    <div className={`material-icons-outlined ${styles.removeBtn}`}>close</div>
                  </Popconfirm>
                </div>
                <ChildGuardianForm
                  id={`Guardian${index}`}
                  key={index}
                  clientDetail={obj}
                  onChangeClientField={handleChangeClientField}
                  checkValidation={
                    obj.firstName || obj.lastName || obj.email || obj.mobileNumber ? checkValidation : false
                  }
                  isFirstGuardian={index === 0}
                  updateDetail={clientDetail.length}
                />
              </div>
            ))}
          {clientDetail.length <= maxGuardianField && (
            <div className={styles.addGuardianBtn} onClick={() => handleAddNewGuardian()}>
              <i className={`material-icons-outlined ${styles.icon}`}>add</i>
              Add another parent or guardian
            </div>
          )}
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={recordStatus !== RecordStatus.Closed ? onClickSubmit : onClickSaveClosedClient}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditChildForm;
