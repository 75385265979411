import { Modal, notification } from 'antd';
import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import { useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { deleteStageByStageId } from 'utils/http/ClinicianProfileService/Interventions/InterventionPathway';
import { PathwayStageItem, PathwayStageList } from '../../interface';
import StageSelect from './components/StageSelect/StageSelect';
import styles from './DeleteStageModal.module.scss';

interface DeleteStageProps {
  onCloseModal: () => void;
  stageItemToBeDeleted?: PathwayStageItem;
  stageItemList?: PathwayStageList;
  interventionId: string;
  onRefetchList?: () => void;
}

const DeleteStageModal = ({
  onCloseModal,
  stageItemToBeDeleted,
  stageItemList,
  interventionId,
  onRefetchList
}: DeleteStageProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const [moveToStage, setMoveToStage] = useState<PathwayStageItem | null>();
  const [errorMessage, setErrorMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const sequentialStageList = stageItemList?.sequential || [];
  const anytimeStageList = stageItemList?.anytime || [];
  const stageId = stageItemToBeDeleted?._id;
  const hasMoveToStages =
    !!stageItemToBeDeleted &&
    stageItemToBeDeleted.count > 0 &&
    (sequentialStageList.length > 1 || anytimeStageList.length > 0) &&
    (sequentialStageList.length > 0 || anytimeStageList.length > 1);

  const setMoveToStageAndUnsetError: typeof setMoveToStage = (stage) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setMoveToStage(stage);
  };

  const closeModal = () => {
    setMoveToStage(undefined);
    onCloseModal();
    setErrorMessage('');
  };

  const handleDeleteStage = async () => {
    setSubmitStatus('active');
    try {
      if (hasMoveToStages && moveToStage === undefined) {
        setErrorMessage('Please set where to move stage members');
        setSubmitStatus('');
      } else {
        await deleteStageByStageId(token, accountId, interventionId, stageId, moveToStage?._id);
        setSubmitStatus('finished');
        notification.success({
          message: 'Stage deleted',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setTimeout(() => {
          setSubmitStatus('');
          closeModal();
          onRefetchList && onRefetchList();
        }, 500);
      }
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({ message: 'Something went wrong while trying to delete stage' });
    }
  };

  return (
    <Modal
      className={classNames(hasMoveToStages ? styles.modalContainer : styles.modalNoOptionContainer)}
      visible={!!stageItemToBeDeleted}
      footer={null}
      closable={false}
    >
      <div className={styles.titleWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.iconWrapper}>
            <i className={`material-icons-outlined ${styles.icon}`}>report</i>
          </div>
          <div className={styles.title}>Are you sure you want to delete this stage?</div>
        </div>
      </div>

      {hasMoveToStages && (
        <div className={styles.stageOptions}>
          <div className={styles.nonStageCanBeMoveTitle}>If yes, please choose to move all member to:</div>
          <StageSelect
            stageFormFieldData={stageItemToBeDeleted}
            stageItemList={stageItemList}
            moveToStage={moveToStage}
            onMoveToStage={setMoveToStageAndUnsetError}
          />
          <div className={styles.errorMessage}>{errorMessage}</div>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          className={classNames(hasMoveToStages ? styles.cancelButton : styles.cancelButtonNon)}
          variant="secondary"
          type={'button'}
          onClick={closeModal}
          disabled={submitStatus !== ''}
        >
          Cancel
        </Button>
        <Button
          status={submitStatus}
          disabled={submitStatus !== ''}
          id="deleteStageBtnId"
          className={classNames(!hasMoveToStages && styles.deleteButtonNon)}
          onClick={handleDeleteStage}
        >
          Delete Stage
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteStageModal;
