import TimeRange from '../TimeRange/TimeRange';
import TimeSlotList from '../TimeSlotList/TimeSlotList';
import styles from './FinderWizardRecord.module.scss';
import { ResultInterface } from '../../constants';

interface FinderWizardRecordProps {
  isFilterByPractitioner: boolean;
  listTransformX: number;
  record: ResultInterface;
}

const FinderWizardRecord = (props: FinderWizardRecordProps) => {
  const { record, isFilterByPractitioner, listTransformX } = props;
  const { type, color, name, date, avatar, availableSlots } = record;

  return (
    <div className={styles.container}>
      <div className={styles.mainInfo}>
        <div className={styles.avatar}>
          {type === 'pratitioner' ? (
            <img src={avatar} alt={'avatar'} />
          ) : (
            <div className={styles.roomAvatar} style={{ backgroundColor: color }} />
          )}
        </div>
        <div className={styles.name}>{name}</div>
        <div className={styles.date}>{date}</div>
      </div>
      {isFilterByPractitioner ? (
        <div className={styles.availableSlots}>
          <TimeSlotList availableSlots={availableSlots} />
        </div>
      ) : (
        <div className={styles.availableTimeRange}>
          <TimeRange listTransformX={listTransformX} availableSlots={availableSlots} />
        </div>
      )}
    </div>
  );
};

export default FinderWizardRecord;
