import classNames from 'classnames';
import styles from './RoomOption.module.scss';

interface RoomOptionProps {
  name: string;
  className?: string;
}

const RoomOption = ({ className, name }: RoomOptionProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.iconContainer}>
        <i className={`material-icons ${styles.defaultLogo}`}>meeting_room</i>
      </div>
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default RoomOption;
