import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';

import { getInvoicesByRecordId } from 'utils/http/BillingService/Invoice/invoice';

import { Invoice } from 'pages/Invoices/interface';

export const useFetchInvoices = (token: string, recordId: string) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(true);

  const fetchInvoices = useCallback(
    async (token: string) => {
      try {
        const callInvoices = await getInvoicesByRecordId(token, recordId);

        const invoices = await callInvoices.json();

        if (invoices && Array.isArray(invoices)) {
          setInvoices(invoices);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get your invoices.' });
      }

      setIsInvoicesLoading(false);
    },
    [recordId]
  );

  useEffect(() => {
    if (token) {
      fetchInvoices(token);
    }
  }, [token, fetchInvoices]);

  return { invoices, isInvoicesLoading, setInvoices };
};
