import styles from './InterventionInfo.module.scss';
import { Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import InterventionStatusBadge from './components/InterventionStatusBadge/InterventionStatusBadge';
import moment from 'moment';
import InterventionPractitioner from './components/InterventionPractitioner/InterventionPractitioner';
import InterventionTags from './components/InterventionTags/InterventionTags';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useFetchInterventionLibrary } from 'MHH/pages/InterventionLibrary/hooks/useFetchInterventionLibrary';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

interface InterventionInfoProps {
  interventionDetails: Interventions;
  interventionTagList: Interventions['tags'];
}

const InterventionInfo = ({ interventionDetails, interventionTagList }: InterventionInfoProps) => {
  const { isInterventionsTagsToggle } = useGetFeatureToggle();
  const { token } = useGetAccessToken();
  const { interventionLibrary, isInterventionLibraryLoading } = useFetchInterventionLibrary(token);

  return (
    <div className={styles.container}>
      <div className={styles.detailContainer}>
        <div className={styles.statusWrapper}>
          <div className={styles.label}>STATUS</div>
          <InterventionStatusBadge interventionStatus={interventionDetails.status} />
        </div>
        <div className={styles.nameWrapper}>
          <div className={styles.label}>Name:</div>
          <div className={styles.value}>{interventionDetails.name}</div>
        </div>
        {interventionDetails.interventionId && (
          <div className={styles.idWrapper}>
            <div className={styles.label}>ID:</div>
            <div className={styles.value}>{interventionDetails.interventionId}</div>
          </div>
        )}
        <div className={styles.createdByWrapper}>
          <div className={styles.label}>Created by:</div>
          <div className={styles.value}>{moment(interventionDetails.createdAt).format('DD MMM YYYY')}</div>
        </div>
        <div className={styles.interventionLibraryItemWrapper}>
          <div className={styles.label}>Intervention Library:</div>
          <div className={styles.value}>
            {isInterventionLibraryLoading ? (
              <LoadingCircle />
            ) : (
              interventionLibrary.find((item) => item.id === interventionDetails.interventionLibraryItemId)?.name ||
              '-'
            )}
          </div>
        </div>
        {interventionDetails.description && (
          <div className={styles.descWrapper}>
            <div className={styles.label}>Description:</div>
            <div className={styles.value}>{interventionDetails.description}</div>
          </div>
        )}
        {isInterventionsTagsToggle && (
          <div className={styles.tagsWrapper}>
            <InterventionTags
              interventionSelectedTag={interventionDetails.tags}
              interventionTagList={interventionTagList}
            />
          </div>
        )}
      </div>
      <InterventionPractitioner
        leadClinicianProfile={interventionDetails.leadClinicianProfile}
        clinicianProfiles={interventionDetails.clinicianProfiles}
      />
    </div>
  );
};

export default InterventionInfo;
