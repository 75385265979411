import classNames from 'classnames';
import styles from './WeekdayPills.module.scss';

interface WeekdayPillsProps {
  weekdays: boolean[];
}

const WeekdayPills = ({ weekdays }: WeekdayPillsProps) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  return (
    <div className={styles.dayTimeSelections}>
      {days.map((day, index) => (
        <span
          key={index}
          className={classNames([styles.dayTimeSelectionPill, weekdays[index] ? styles.active : styles.inactive])}
        >
          {day}
          <i className="material-icons">check</i>
        </span>
      ))}
    </div>
  );
};

export default WeekdayPills;
