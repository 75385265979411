import * as Yup from 'yup';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { TFunction } from 'react-i18next';

export type DeliveryTeleHealth = Omit<DeliveryType, DeliveryType.FaceToFace>;

export const getPhoneConnectOptions = (t: TFunction) => [
  { label: t('appointment.phone_connect_options.default'), value: 'default' },
  { label: t('appointment.phone_connect_options.custom'), value: 'custom' }
];

export const VIDEO_CONNECT_OPTIONS = [
  { label: 'Use account level video call instructions', value: 'default' },
  { label: 'Add specific instructions or connection details', value: 'custom' }
];

export const ROOM_REQUIRED_OPTIONS = [
  { label: 'Yes, and room MUST be', value: 'yes' },
  { label: 'Yes, any available room', value: 'any' },
  { label: 'No room required', value: 'no' }
];

interface FormType {
  faceToFaceSelected: boolean;
  faceToFaceLocation: string;
  telehealthVideoCallSelected: boolean;
  telehealthPhoneCallSelected: boolean;
  videoCallInstructions: string;
  phoneCallInstructions: string;
  videoCallInstructionType: string;
  phoneCallInstructionType: string;
  roomRequired: string;
  roomName: string;
  roomId: string;
}

export const initialValues: FormType = {
  faceToFaceSelected: false,
  faceToFaceLocation: '',
  telehealthVideoCallSelected: false,
  telehealthPhoneCallSelected: false,
  videoCallInstructions: '',
  phoneCallInstructions: '',
  videoCallInstructionType: 'default',
  phoneCallInstructionType: 'default',
  roomRequired: 'no',
  roomName: '',
  roomId: ''
};

export const initialValuesUpdate = (data: AppointmentType) => ({
  ...initialValues,
  faceToFaceSelected: data.deliveryOptions.includes(DeliveryType.FaceToFace),
  faceToFaceLocation: data.faceToFaceLocation || '',
  telehealthVideoCallSelected: !!data.deliveryOptions.includes(DeliveryType.VideoCall),
  telehealthPhoneCallSelected: !!data.deliveryOptions.includes(DeliveryType.PhoneCall),
  videoCallInstructions: data.videoCallInstructions || '',
  phoneCallInstructions: data.phoneCallInstructions || '',
  videoCallInstructionType: data.videoCallInstructions ? 'custom' : 'default',
  phoneCallInstructionType: data.phoneCallInstructions ? 'custom' : 'default',
  roomRequired: data.roomAny ? 'any' : data.roomSettings?.required ? 'yes' : 'no',
  roomName: data.roomSettings?.preferredRoom.name || '',
  roomId: data.roomSettings?.preferredRoom.roomId || ''
});

export const appointmentDeliverySchema = Yup.object().shape({
  faceToFaceSelected: Yup.boolean(),
  faceToFaceLocation: Yup.string(),
  telehealthVideoCallSelected: Yup.boolean(),
  telehealthPhoneCallSelected: Yup.boolean(),
  videoCallInstructionType: Yup.string(),
  phoneCallInstructionType: Yup.string(),
  telehealthMethod: Yup.string().when('telehealthSelected', {
    is: true,
    then: Yup.string().required('Please select one telehealth method'),
    otherwise: Yup.string()
  }),
  videoCallInstructions: Yup.string().when('telehealthVideoCallSelected', {
    is: true,
    then: Yup.string().when('videoCallInstructionType', {
      is: (videoCallInstructionType) => videoCallInstructionType === 'custom',
      then: Yup.string().required('Please include instructions for how to connect.'),
      otherwise: Yup.string()
    }),
    otherwise: Yup.string()
  }),
  phoneCallInstructions: Yup.string().when('telehealthPhoneCallSelected', {
    is: true,
    then: Yup.string().when('phoneCallInstructionType', {
      is: (phoneCallInstructionType) => phoneCallInstructionType === 'custom',
      then: Yup.string().required('Please include instructions for how to connect.'),
      otherwise: Yup.string()
    }),
    otherwise: Yup.string()
  }),
  roomRequired: Yup.string().required(),
  roomId: Yup.string().when('roomRequired', {
    is: 'yes',
    then: Yup.string().required('Please choose the room'),
    otherwise: Yup.string()
  }),
  roomName: Yup.string()
});

export const tempValues: Partial<AppointmentType> = {
  id: 'new',
  isActive: false
};

export const tempSchema = Yup.object().shape({
  isActive: Yup.boolean().required()
});
