import styles from './AssessmentGuideLine.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import classnames from 'classnames';
import { BackgroundQuestion } from 'interfaces/firstAssessment';

interface AssessmentGuidelineProps {
  bgQuestions: BackgroundQuestion[];
  onChange: (e: any) => void;
}

const AssessmentGuideline = ({ bgQuestions, onChange }: AssessmentGuidelineProps) => {
  const handleCheck = (val: boolean, index: number) => {
    bgQuestions[index].isChecked = val;
    onChange(bgQuestions);
  };

  return (
    <div className={styles.container}>
      <div className={styles.guideLineTitle}>
        <div className={styles.label}>Common Background Questions</div>
      </div>
      {bgQuestions.map((obj, index) => (
        <div key={`bgQuestion-${index}`} className={classnames(styles.pointDesc, obj.isChecked ? styles.checked : '')}>
          <CheckBox
            className={styles.checkBox}
            id={`bgQuestion-${index}`}
            value={obj.isChecked}
            label={
              <>
                <OptionLabel title={obj.question} /> <div className={styles.subQuestion}>{obj.subQuestion}</div>
              </>
            }
            onChange={(e) => handleCheck(e.target.checked, index)}
          />
        </div>
      ))}
    </div>
  );
};

export default AssessmentGuideline;
