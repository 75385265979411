import { useState } from 'react';
import { toCamelCase } from 'utils/generateCamelCase';

import styles from './SelectCountBtn.module.scss';

interface SelectCountBtnProps {
  id: string;
  countValue: number;
  maxValue: number;
  onChangeValue: any;
}

const SelectCountBtn = ({ id, countValue, maxValue, onChangeValue }: SelectCountBtnProps) => {
  const [countNumber, setCountNumber] = useState(countValue);

  const handleMinus = () => {
    const newNumber = countNumber !== 0 ? countNumber - 1 : countNumber;
    setCountNumber(newNumber);
    onChangeValue(id, newNumber);
  };

  const handlePlus = () => {
    const newNumber = countNumber < maxValue ? countNumber + 1 : countNumber;
    setCountNumber(newNumber);
    onChangeValue(id, newNumber);
  };

  return (
    <div className={styles.countBtnWrap}>
      <div onClick={handleMinus} className={styles.minusBtn}>
        -
      </div>
      <div id={toCamelCase(id)} className={styles.value}>
        {countNumber}
      </div>
      <div onClick={handlePlus} className={styles.plusBtn}>
        +
      </div>
    </div>
  );
};

export default SelectCountBtn;
