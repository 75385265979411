import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';
import { ContactInfo } from 'pages/ControlPanel/Interfaces/MisConnection';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getSchoolList = (token: String, query: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/mis/schools?query=${query}`
  ) as Promise<BentResponse>;

export const getSchoolConnectionInfo = (token: string, accountId: string) =>
  httpClient('GET', url!, [200, 404], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/mis/schools`
  ) as Promise<BentResponse>;

export const postSchoolConnectionRequest = (
  token: string,
  accountId: string,
  payload: { schoolId: string; contact?: ContactInfo }
) =>
  httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/mis/schools/request-access`,
    payload
  ) as Promise<BentResponse>;

export const postRevokeSchoolConnectionRequest = (token: string, accountId: string) =>
  httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/mis/schools/revoke-access`
  ) as Promise<BentResponse>;

export const getSchoolDataImportHistory = (token: string, accountId: string) =>
  httpClient('GET', url!, [200], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/mis/schools/import-data-requests`
  ) as Promise<BentResponse>;

export const postSchoolDataImportRequest = (
  token: string,
  accountId: string,
  payload: { teacher: boolean; student: boolean; assignedTeacher: boolean; enableAutoSync?: boolean }
) =>
  httpClient('POST', url!, [200], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/mis/schools/import-data-requests`,
    payload
  ) as Promise<BentResponse>;
