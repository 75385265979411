import { useMemo, useEffect, useState } from 'react';

import AdultClientForm from './components/AdultClientForm/AdultClientForm';
import CapabilityForm from '../../components/CapabilityForm/CapabilityForm';
import { ClientDetails, newClientForm } from '../../AddPatientModalV2Interface';
import MaterialInput from 'components/MaterialInput/MaterialInput';

import styles from './AdultForm.module.scss';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { validateCaseId } from './components/AdultClientForm/validation/AdultClientFormValidation';
import { useGetAccessToken } from 'utils/hooks/token';
import SearchableSelect, { OptionInterface } from 'components/Select/SearchableSelect/SearchableSelect';
import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { useTranslation } from 'react-i18next';

interface AdultFormProps {
  clientDetail: newClientForm['clientProfiles'];
  clientCapability: newClientForm['clientCapability'];
  referralSourceTags: newClientForm['referralSourceTags'];
  referralDetail: newClientForm['referralDetail'];
  caseIdValue: newClientForm['caseId'];
  onChangeCaseId: (value: newClientForm['caseId']) => void;
  onChangeClientField: any;
  onChangeClientCapability: any;
  onChangeReferral: (value: string[]) => void;
  onChangeReferralDetail: (value: string) => void;
  checkValidation: boolean;
  clientRecordId?: string;
}

const AdultForm = ({
  clientDetail,
  clientCapability,
  referralSourceTags,
  referralDetail,
  caseIdValue,
  onChangeCaseId,
  onChangeClientField,
  onChangeClientCapability,
  onChangeReferral,
  onChangeReferralDetail,
  checkValidation,
  clientRecordId
}: AdultFormProps) => {
  const { token } = useGetAccessToken();
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);
  const [t] = useTranslation();

  const selected = REFERRAL_SOURCE_TAGS.filter((option) => referralSourceTags?.includes(option)).map((item) => ({
    _id: item,
    label: item
  }));
  const [selectedReferralSourceTags, setSelectedReferralSourceTags] = useState<OptionInterface[]>(selected || []);

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: val.firstName,
        lastName: val.lastName,
        email: val.email,
        isEmailThirdParty: val.isEmailThirdParty,
        mobileNumber: val.mobileNumber,
        isMobileNumberThirdParty: val.isMobileNumberThirdParty,
        communicationPreference: val.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const onSelectReferralSourceTags = (selectedOption: OptionInterface) => {
    let newReferralSourceTags = [];
    if (selectedReferralSourceTags.some((item) => item._id === selectedOption._id)) {
      newReferralSourceTags = selectedReferralSourceTags.filter((item) => item._id !== selectedOption._id);
    } else {
      newReferralSourceTags = [...selectedReferralSourceTags, selectedOption];
    }
    setSelectedReferralSourceTags(newReferralSourceTags);
    onChangeReferral(newReferralSourceTags.map((item) => item._id));
  };

  useEffect(() => {
    if (checkValidation && caseIdValue && caseIdValue.length > 0) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value, clientRecordId);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token, clientRecordId]
  );

  const validateDuplicateCaseId = async (caseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(caseIdValue);
    }
  };

  const handleCaseIdChange = async (caseIdValue: string) => {
    onChangeCaseId(caseIdValue);
    if (checkValidation && caseIdValue.length > 0) {
      await validateDuplicateCaseId(caseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  return (
    <>
      {/* Selected Referral Source */}
      {selectedReferralSourceTags.length > 0 && (
        <div className={styles.selectedReferralTypessContainer}>
          {selectedReferralSourceTags.map((referralType, index) => (
            <div key={`referralSource${index}`} className={styles.pill}>
              {referralType.label}
            </div>
          ))}
        </div>
      )}
      {/* Referral Source dropdown */}
      <div className={styles.dropdownContainer}>
        <SearchableSelect
          className={styles.rsDropdown}
          label={'Select Referral Source'}
          options={REFERRAL_SOURCE_TAGS.map((item) => ({ _id: item, label: item }))}
          searchBoxPlaceholder={'Select Source'}
          isMultipleChoice
          selectedOptionsProps={selectedReferralSourceTags}
          onSelecteOptionProps={onSelectReferralSourceTags}
          loadingEnable
        />
      </div>
      <div className={styles.fieldContainer}>
        <MaterialInput
          id={`referralDetail`}
          label={'Referral Detail'}
          value={referralDetail}
          onChange={(event) => {
            onChangeReferralDetail(event.target.value);
          }}
          required
        />
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
        <MaterialInput
          id={`clientCaseId`}
          label={t('form.client_id')}
          onChange={(e) => handleCaseIdChange(e.target.value)}
          isLoading={isCheckingCaseIdDuplicate}
          value={caseIdValue}
          maxLength={20}
          required
        />
        {checkValidation && duplicateCaseId && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_id')}</div>
        )}
      </div>
      {clientDetail.map((obj, index) => (
        <AdultClientForm
          id={obj._id}
          key={index}
          clientDetail={obj}
          capabilityData={clientCapability}
          onChangeClientField={handleChangeClientField}
          checkValidation={checkValidation}
        />
      ))}
      <CapabilityForm
        capabilityData={clientCapability}
        onChangeCapabilityField={onChangeClientCapability}
        fullProfileDesc={t('client_capability.full.description')}
        recordDesc={t('client_capability.record_only.description')}
      />
    </>
  );
};

export default AdultForm;
