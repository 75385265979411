export enum InterventionLibraryDeliveryMode {
  None = 'none',
  WholeOfSchool = 'wholeOfSchool',
  Internal = 'internal',
  External = 'external',
  InternalAndExternal = 'internalAndExternal'
}
export interface InterventionLibraryInterface {
  id: string;
  name: string;
  description: string;
  url?: string;
  tags: string[];
  trainedStaff?: any[];
  externalParty?: string[];
  clinicianAuth0Ids?: string[];
  deliveryMode: InterventionLibraryDeliveryMode;
}
