import { notification } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getAttachmentsByGroupId } from 'utils/http/DocumentService/Attachments/groupAttachment';
import { GroupAttachmentInterface } from '../interfaces';

export const useFetchGroupAttachments = (token: string, recordId: string) => {
  const [groupAttachments, setGroupAttachments] = useState<GroupAttachmentInterface[]>([]);
  const [isGroupAttachmentsLoading, setIsGroupAttachmentsLoading] = useState(true);

  const fetchAttachments = async (token: string) => {
    try {
      const callGetGroupAttachments = await getAttachmentsByGroupId(token, recordId);
      const groupAttachments = (await callGetGroupAttachments.json()) as GroupAttachmentInterface[];

      const sortByCreatedAt = groupAttachments.sort((attObj1, attObj2) =>
        moment(attObj2.createdAt).diff(moment(attObj1.createdAt))
      );

      setGroupAttachments(sortByCreatedAt);
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get this group's attachments" });
    }

    setIsGroupAttachmentsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAttachments(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { groupAttachments, isGroupAttachmentsLoading, setGroupAttachments };
};
