import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const postAdHocAssessmentGroup = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-assessment`,
    payload
  ) as Promise<BentResponse>;

export const postCordsAdHocAssessmentGroup = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 201, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-assessments/cords`,
    payload
  ) as Promise<BentResponse>;

export const getPsychometricListMenu = (token: string, groupId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-assessments`
  ) as Promise<BentResponse>;

export const getGroupAssessmentClients = (
  token: string,
  groupId: string,
  groupAdHocAssessmentId: string,
  clinicalAssessmentId: string
) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-assessments/${groupAdHocAssessmentId}/clinical-assessments/${clinicalAssessmentId}`
  ) as Promise<BentResponse>;

export const getGroupAdHocSurveyList = (token: string, groupId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-surveys`
  ) as Promise<BentResponse>;

export const getGroupAdHocSurveyClients = (token: string, groupId: string, groupSurveyId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-survey/${groupSurveyId}`
  ) as Promise<BentResponse>;

export const postGroupAdHocSurvey = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/ad-hoc-survey`,
    payload
  ) as Promise<BentResponse>;
