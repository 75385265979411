import styles from './AnytimeStageCard.module.scss';
import StageCard from '../StageCard/StageCard';
import StageCardLoading from '../StageCard/StageCardLoading';
import { PathwayStageItem, PathwayStageList } from '../../interface';

interface AnytimeStageCardProps {
  groupId: string;
  anytimeStageList: PathwayStageList['anytime'];
  isStageListingLoading: boolean;
  onRefetchList: () => void;
  onOpenDeleteModal: (stageItemToBeDeleted: PathwayStageItem) => void;
  allowEditStage: boolean;
}

const AnytimeStageCard = ({
  groupId,
  anytimeStageList,
  isStageListingLoading,
  onRefetchList,
  onOpenDeleteModal,
  allowEditStage
}: AnytimeStageCardProps) => {
  return isStageListingLoading ? (
    <div className={styles.container}>
      {[...Array(3)].map((obj, i) => (
        <StageCardLoading key={i} />
      ))}
    </div>
  ) : anytimeStageList.length > 0 ? (
    <div className={styles.container}>
      {anytimeStageList.map((stageObj, index) => (
        <StageCard
          key={index}
          groupId={groupId}
          stageItem={stageObj}
          onRefetchList={onRefetchList}
          onOpenDeleteModal={onOpenDeleteModal}
          allowEditStage={allowEditStage}
        />
      ))}
    </div>
  ) : (
    <div>No anytime stage added yet.</div>
  );
};

export default AnytimeStageCard;
