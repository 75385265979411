import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import Badge from 'components/Badge/Badge';
import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import SubmitButton from 'components/v2/Button/Button';
import { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createMCIAssessments, updateMCIAssessments } from 'store/CheckIn/UpdateMCIAssessment/action';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { v4 as uuid } from 'uuid';

import AssessmentsHeader from '../../../components/AssessmentsHeader/AssessmentsHeader';
import styles from './AssessmentsDetailsContent.module.scss';
import AssessmentGuideLine from './components/AssessmentGuideLine/AssessmentGuideLine';
import AssessmentQuestionSection from './components/AssessmentQuestionSection/AssessmentQuestionSection';
import ClientsList from './components/ClientsList/ClientsList';

interface AssessmentsDetailsContentProps {
  data: any;
  isEdit: boolean;
  qTemplateData: any;
}

const AssessmentsDetailsContent = ({ data, isEdit, qTemplateData }: AssessmentsDetailsContentProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const { getAccessTokenSilently } = useAuth0();
  const [questionSets, setQuestionSet] = useState(data.questionSets);
  const [assessmentName, setAssessmentName] = useState(data.name);
  const [isEditMode, setIsEditMode] = useState(isEdit);
  const [assessmentDesc, setAssessmentDesc] = useState(data.description);
  const [isPublished, setIsPublished] = useState(data.isPublished);
  const [isClickSaveBtn, setClickSaveBtn] = useState(false);
  const [isValidationComplete, setIsValidationComplete] = useState({
    name: true,
    questionSets: true
  });
  const [isSaveAndPublish, setIsSaveAndPublish] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');

  const path = useParams() as { assessmentId: string };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setAssessmentName(e.target.value);
  };

  const onChangeDesc = (e: ChangeEvent<HTMLInputElement>) => {
    setAssessmentDesc(e.target.value);
  };

  const onChangePublish = (publishVal: boolean) => {
    setIsPublished(publishVal);
  };

  const buildNewQuestionList = (items: any) => {
    setQuestionSet(items);
  };

  const checkValidation = (data: any) => {
    setIsValidationComplete({
      name: true,
      questionSets: true
    });
    if (data.name.length > 0 && data.questionSets.length > 0) {
      const checkQuestionValidation = data.questionSets.filter(
        (obj: any) => obj.sectionName === '' || obj.questions.length <= 0
      );

      setIsValidationComplete({
        name: true,
        questionSets: checkQuestionValidation.length <= 0
      });

      return checkQuestionValidation.length <= 0;
    } else {
      if (data.name.length <= 0) {
        setIsValidationComplete({
          ...isValidationComplete,
          name: false
        });
      }

      setIsValidationComplete({
        name: false,
        questionSets: false
      });

      return false;
    }
  };

  const saveFunction = async (data: any) => {
    setSaveStatus('active');
    const validation = checkValidation(data);

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });
    setClickSaveBtn(true);

    if (validation) {
      if (isEditMode) {
        updateMCIAssessments(data, token).then((r) => {
          // @ts-ignore
          if (r.id) {
            notification.success({
              message: 'Assessment updated',
              duration: 5,
              closeIcon: <span className="success">OK</span>
            });
            setSaveStatus('finished');

            setTimeout(() => {
              setSaveStatus('');
            }, 2000);
          } else {
            setSaveStatus('');
          }
        });
        setClickSaveBtn(false);
      } else {
        createMCIAssessments(data, token).then((r: any) => {
          if (r.id) {
            setIsEditMode(true);
            notification.success({
              message: 'Assessment created',
              duration: 5,
              closeIcon: <span className="success">OK</span>
            });
            setSaveStatus('finished');
            setTimeout(() => {
              setSaveStatus('');
              navigate(`${FORMS.BASE}/microCheckIn/${r.id}`);
            }, 2000);
          } else {
            setSaveStatus('');
          }
        });
        setClickSaveBtn(false);
      }
    } else {
      notification.error({ message: 'Assessment failed to update' });
      setSaveStatus('');
      setClickSaveBtn(false);
    }
    setQuestionSet(data.questionSets);
  };

  const onChangeQList = (questionSet: any) => {
    setQuestionSet(questionSet);
  };

  const massageQuestionList = (questionListVal: any) => {
    let newQList = [];
    for (let obj of questionListVal) {
      newQList.push({
        ...obj,
        id: obj.isDraftQ ? `newId${uuid()}` : obj.id,
        isDraftQ: false
      });
    }
    return newQList;
  };

  const onSaveQuestion = (validate: boolean) => {
    if (validate) {
      const filteredQuestionSet = questionSets.reduce((acc: any, questionSetObj: any) => {
        const isEligibleQuestion = questionSetObj.questions.filter(
          (value: any) => !((value.isDraftQ && !value.isChecked) || value.stem.length <= 2)
        );
        acc.push({
          ...questionSetObj,
          questions: massageQuestionList(isEligibleQuestion)
        });
        return acc;
      }, []);

      const saveData = {
        id: path.assessmentId,
        name: assessmentName,
        description: assessmentDesc,
        isPublished: isPublished,
        isDefault: data.isDefault,
        questionSets: filteredQuestionSet
      };

      saveFunction(saveData);
    }
  };

  const onClickSaveNPublish = () => {
    if (questionSets.length > 0) {
      const filteredQuestionSet = questionSets.reduce((acc: any, questionSetObj: any) => {
        const isEligibleQuestion = questionSetObj.questions.filter(
          (value: any) => !((value.isDraftQ && !value.isChecked) || value.stem.length <= 2)
        );
        acc.push({ ...questionSetObj, questions: isEligibleQuestion });
        return acc;
      }, []);

      const saveData = {
        id: path.assessmentId,
        name: assessmentName,
        description: assessmentDesc,
        isPublished: true,
        isDefault: data.isDefault,
        questionSets: filteredQuestionSet
      };

      setIsPublished(true);

      saveFunction(saveData);
    }
  };

  const addNewSection = (validate: boolean) => {
    if (validate) {
      const lastItemId = (questionSets.length + 1).toString();

      let newQuestionSet = [];
      for (let data of questionSets) {
        newQuestionSet.push({
          ...data
        });
      }

      newQuestionSet.push({
        id: lastItemId,
        sectionName: '',
        questions: [],
        refKey: ''
      });
      setQuestionSet(newQuestionSet);
    }
  };

  const checkNewCardExist = questionSets.find((obj: any) => obj.sectionName === '');

  return (
    <div>
      <AssessmentsHeader
        title={'Micro Check-in Editor'}
        saveBtn={
          <SubmitButton
            status={isSaveAndPublish ? saveStatus : ''}
            disabled={!(assessmentName.length > 0 && questionSets.length > 0)}
            onClick={() => {
              setIsSaveAndPublish(true);
              onClickSaveNPublish();
            }}
          >
            Save and Publish
          </SubmitButton>
        }
      />
      <div className={styles.container}>
        <div className={styles.badgeWrapper}>
          <Badge label={isEditMode ? 'EDITING' : 'NEW'} className={isEditMode ? styles.badge : styles.newBadge} />
        </div>
        <div className={styles.content}>
          <div className={styles.leftWrapper}>
            <div className={styles.titleBox}>
              <MaterialInput
                id={'surveyMicroCheckInName'}
                label={'Name of check-in program'}
                className={styles.titleInput}
                value={assessmentName}
                onChange={onChangeName}
                required
              />
              <MaterialToggleSwitch
                name={'publish'}
                label={'Published'}
                value={isPublished}
                customContainerClass={styles.toggleSwitchContainer}
                onChange={onChangePublish}
              />
            </div>
            <div className={styles.descBox}>
              <MaterialInput
                id={'desc'}
                label={'Short description of this assessment'}
                className={styles.descInput}
                value={assessmentDesc}
                onChange={onChangeDesc}
                required
              />
            </div>
            <ClientsList assessmentId={data.id} isEditMode={isEditMode} />
          </div>
          <div className={styles.rightWrapper}>
            {/*<ClinicalAssessment onChange={onChangeCa} data={caSet} />*/}
            <AssessmentGuideLine />
            <AssessmentQuestionSection
              questionList={questionSets}
              onChange={buildNewQuestionList}
              onChangeQuestionList={onChangeQList}
              onTriggerSave={isClickSaveBtn}
              qTemplateData={qTemplateData}
              validation={isValidationComplete.questionSets}
            />
            <div className={styles.buttonWrapper}>
              {
                <div
                  className={!checkNewCardExist ? styles.addQBtn : styles.addQBtnDisable}
                  onClick={() => addNewSection(!checkNewCardExist)}
                >
                  <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                  Add Question Theme
                </div>
              }
              {
                <SubmitButton
                  disabled={!(assessmentName.length > 0 && questionSets.length > 0)}
                  status={isSaveAndPublish ? '' : saveStatus}
                  onClick={() => {
                    setIsSaveAndPublish(false);
                    onSaveQuestion(questionSets.length > 0);
                  }}
                  className={styles.saveBtnFixed}
                >
                  Save
                </SubmitButton>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentsDetailsContent;
