import { useState } from 'react';
import styles from './KeyClientContacts.module.scss';
import columnStyles from './KeyClientContactsColumn.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import Button from 'components/v2/Button/Button';
import NewClientContacts from './components/NewClientContacts/NewClientContacts';
import KeyClientContactList from './components/KeyClientContactList/KeyClientContactList';
import { KeyClientContact } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

export interface KeyClientContactsProps {
  keyClientContacts: KeyClientContact[];
  clientRecordId: string;
  fetchClientEncryptDetails: () => void;
}

const KeyClientContacts = ({
  clientRecordId,
  fetchClientEncryptDetails,
  keyClientContacts
}: KeyClientContactsProps) => {
  const { token } = useGetAccessToken();
  const [isAddRemove, setIsAddRemove] = useState(false);
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>{t('label.key_client_contact')}</div>
        <div className={styles.subTitle}>
          Add the contact information for any family or care team members relating to this profile
        </div>
      </div>
      <div className={styles.listContainer}>
        <div className={columnStyles.listHeader}>
          <div className={columnStyles.firstName}>First name</div>
          <div className={columnStyles.lastName}>Last name</div>
          <div className={columnStyles.mobileNumber}>Mobile number</div>
          <div className={columnStyles.email}>Email</div>
          <div className={columnStyles.contactTags}>Contact Tags</div>
          <div className={columnStyles.actions} />
        </div>
        {keyClientContacts && keyClientContacts.length > 0 ? (
          <KeyClientContactList
            token={token}
            clientRecordId={clientRecordId}
            enableDelete={isAddRemove}
            keyClientContacts={keyClientContacts}
          />
        ) : (
          <div className={styles.noContact}>No contacts added</div>
        )}
      </div>
      <div className={styles.addRemoveContact}>
        {isAddRemove ? (
          <div className={styles.addingRecord}>
            <NewClientContacts
              token={token}
              clientRecordId={clientRecordId}
              onSubmit={() => {
                fetchClientEncryptDetails();
              }}
              onCancel={() => {
                setIsAddRemove(false);
              }}
            />
          </div>
        ) : (
          <div className={styles.addRemoveButton}>
            <Button
              className={styles.button}
              variant="secondary"
              onClick={() => {
                setIsAddRemove(true);
              }}
            >
              <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
              {t('button.add_remove_client_contacts')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default KeyClientContacts;
