import { useEffect, useMemo, useState } from 'react';
import styles from './CoupleForm.module.scss';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import { ClientDetails } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import ClientForm from './components/ClientForm/ClientForm';
import { CoupleFormProps } from './interface';
import CapabilityForm from 'components/AddPatientModalV2/components/CapabilityForm/CapabilityForm';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { validateCaseId } from '../AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { useGetAccessToken } from 'utils/hooks/token';
import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import SearchableSelect, { OptionInterface } from 'components/Select/SearchableSelect/SearchableSelect';
import { useTranslation } from 'react-i18next';

const CoupleForm = (props: CoupleFormProps) => {
  const { token } = useGetAccessToken();
  const {
    referralSourceTags,
    referralDetail,
    clientDetail,
    checkValidation,
    clientCapability,
    caseIdValue,
    onChangeCaseId,
    clientRecordId
  } = props;
  const { onChangeReferral, onChangeReferralDetail, onChangeClientField, onChangeClientCapability } = props;

  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  const selected = REFERRAL_SOURCE_TAGS.filter((option) => referralSourceTags?.includes(option)).map((item) => ({
    _id: item,
    label: item
  }));
  const [selectedReferralSourceTags, setSelectedReferralSourceTags] = useState<OptionInterface[]>(selected || []);

  useEffect(() => {
    if (checkValidation && caseIdValue && caseIdValue.length > 0) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value, clientRecordId);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token, clientRecordId]
  );

  const validateDuplicateCaseId = async (caseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(caseIdValue);
    }
  };

  const onSelectReferralSourceTags = (selectedOption: OptionInterface) => {
    let newReferralSourceTags = [];
    if (selectedReferralSourceTags.some((item) => item._id === selectedOption._id)) {
      newReferralSourceTags = selectedReferralSourceTags.filter((item) => item._id !== selectedOption._id);
    } else {
      newReferralSourceTags = [...selectedReferralSourceTags, selectedOption];
    }
    setSelectedReferralSourceTags(newReferralSourceTags);
    onChangeReferral(newReferralSourceTags.map((item) => item._id));
  };

  const handleCaseIdChange = async (caseIdValue: string) => {
    onChangeCaseId(caseIdValue);
    if (checkValidation && caseIdValue.length > 0) {
      await validateDuplicateCaseId(caseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        email: obj._id === val._id ? val.email : obj.email,
        mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      {/* Selected Referral Source */}
      {selectedReferralSourceTags.length > 0 && (
        <div className={styles.selectedReferralTypessContainer}>
          {selectedReferralSourceTags.map((referralType, index) => (
            <div key={`referralSource${index}`} className={styles.pill}>
              {referralType.label}
            </div>
          ))}
        </div>
      )}
      {/* Referral Source dropdown */}
      <div className={styles.dropdownContainer}>
        <SearchableSelect
          className={styles.rsDropdown}
          label={'Select Referral Source'}
          options={REFERRAL_SOURCE_TAGS.map((item) => ({ _id: item, label: item }))}
          searchBoxPlaceholder={'Select Source'}
          isMultipleChoice
          selectedOptionsProps={selectedReferralSourceTags}
          onSelecteOptionProps={onSelectReferralSourceTags}
          loadingEnable
        />
      </div>
      <div className={styles.fieldContainer}>
        <MaterialInput
          id={`referralDetail`}
          label={'Referral Detail'}
          value={referralDetail}
          onChange={(event) => {
            onChangeReferralDetail(event.target.value);
          }}
          required
        />
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
        <MaterialInput
          id={`clientCaseId`}
          label={t('form.client_id')}
          onChange={(e) => handleCaseIdChange(e.target.value)}
          isLoading={isCheckingCaseIdDuplicate}
          value={caseIdValue}
          maxLength={20}
          required
        />
        {checkValidation && duplicateCaseId && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_id')}</div>
        )}
      </div>
      <div className={styles.clientsContainer}>
        <div className={styles.clientTitle}>{t('label.client.capitalize').toUpperCase()} 1</div>
        <ClientForm
          id={`Client0`}
          clientDetail={clientDetail[0]}
          onChangeClientField={handleChangeClientField}
          checkValidation={checkValidation}
          other={clientDetail[1]}
          isFirstClient
          capabilityData={clientCapability}
        />
      </div>
      <div className={styles.clientsContainer}>
        <div className={styles.clientTitle}>{t('label.client.capitalize').toUpperCase()} 2</div>
        <ClientForm
          id={`Client1`}
          clientDetail={clientDetail[1]}
          onChangeClientField={handleChangeClientField}
          checkValidation={checkValidation}
          other={clientDetail[0]}
          isFirstClient={false}
          capabilityData={clientCapability}
        />
      </div>
      <CapabilityForm
        capabilityData={clientCapability}
        onChangeCapabilityField={onChangeClientCapability}
        fullProfileDesc={t('client_capability.full.description')}
        recordDesc={t('client_capability.record_only.description')}
      />
    </div>
  );
};

export default CoupleForm;
