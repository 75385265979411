import { Modal } from 'antd';
import classnames from 'classnames';
import Button from 'components/v2/Button/Button';
import { useState } from 'react';

import styles from './NextActionButton.module.scss';

interface NextActionButtonProps {
  reportId: string;
  status: string;
  isAuthor: boolean;
  approvalRequired: boolean;
  btnStatus: '' | 'active' | 'finished';
  onPublish: () => void;
  onRequestReview: () => void;
  onUnpublish: () => void;
  onDelete: () => void;
}

const NextActionButton = ({
  status,
  reportId,
  isAuthor,
  approvalRequired,
  btnStatus,
  onPublish,
  onRequestReview,
  onUnpublish,
  onDelete
}: NextActionButtonProps) => {
  const [redButtonStatus, setRedButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const showDeleteButton = reportId && isAuthor;

  const onComfirmUnpublish = async () => {
    setRedButtonStatus('active');
    await onUnpublish();
    setRedButtonStatus('');
  };

  const onConfirmDelete = async () => {
    setRedButtonStatus('active');
    await onDelete();
    setRedButtonStatus('');
  };

  const onClickUnpublish = async () => {
    Modal.confirm({
      title: 'Are you sure you want to unpublish this report? ',
      icon: '',
      content: `By unpublishing this report we will revoke the access to view this report from anyone it is shared with.`,
      okText: 'Unpublish Report',
      onOk: onComfirmUnpublish,
      onCancel: () => {}
    });
  };

  const onClickDelete = async () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this report? ',
      okText: 'Delete Report',
      onOk: onConfirmDelete,
      onCancel: () => {}
    });
  };

  return (
    <div className={styles.container}>
      {!approvalRequired ? (
        <>
          {status === 'draft' && (
            <Button status={btnStatus} className={styles.button} onClick={onPublish}>
              Publish Report
            </Button>
          )}
        </>
      ) : (
        <>
          {status === 'draft' && (
            <Button status={btnStatus} className={styles.button} onClick={onRequestReview}>
              Request Review
            </Button>
          )}
          {(status === 'approved' || status === 'unpublished') && (
            <Button status={btnStatus} className={styles.button} onClick={onPublish}>
              Publish Report
            </Button>
          )}
        </>
      )}
      {status === 'published' ? (
        <div className={classnames(styles.redButton, redButtonStatus === 'active' && styles.loading)}>
          <div className={styles.label} onClick={onClickUnpublish}>
            Unpublish this report
          </div>
        </div>
      ) : (
        showDeleteButton && (
          <div className={classnames(styles.redButton, redButtonStatus === 'active' && styles.loading)}>
            <div className={styles.label} onClick={onClickDelete}>
              Delete this report
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default NextActionButton;
