import { ChangeEvent } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import FormikRadio from 'components/Radio/FormikRadio';
import MaterialInput from 'components/MaterialInput/MaterialInput';

import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';

import styles from './AutomatedInvoicing.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFormikContext } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import PerAppointment from './components/PerAppointment/PerAppointment';
import PerMonth from './components/PerMonth/PerMonth';

interface AutomatedInvoicingProps {
  clientRecordData: clientRecordsInterface;
  invoiceSettingsPaymentMethodsReady: boolean;
  isClientMedicareValid: boolean;
  isPracticeConnectedMedicare: boolean;
}

const AUTOMATED_INVOICING_TYPES_OPTIONS = [
  { label: 'per appointment', value: 'appointment' },
  { label: 'per month', value: 'month' }
];

const AutomatedInvoicing = ({
  clientRecordData,
  invoiceSettingsPaymentMethodsReady,
  isClientMedicareValid,
  isPracticeConnectedMedicare
}: AutomatedInvoicingProps) => {
  const { errors, touched, values, setFieldValue } = useFormikContext<
    Required<clientRecordsInterface['invoiceSummary']>['settings']
  >();
  const [t] = useTranslation();

  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();

  const invoiceSettingsPath =
    (() => {
      if (isEdgeAdminView) {
        return '/admin';
      } else if (isEdgeReceptionistView) {
        return '/receptionist';
      } else {
        return '';
      }
    })() + `/invoices/invoice-settings`;

  const handleAutomatedInvoicingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'appointment') {
      setFieldValue('automatedInvoicing.issueAt', 'onConfirmed');
    } else if (e.target.value === 'month') {
      setFieldValue('automatedInvoicing.issueAt', '1');
    }
  };

  return (
    <>
      <div className={styles.sectionLabel}>
        Automate invoice sending
        <i className={`material-icons ${styles.icon}`} data-tip data-for="automate-invoice">
          help_outline
        </i>
        <ReactTooltip id="automate-invoice" className={styles.tooltip}>
          Automate the send of invoices to save yourself time. Set up the rules to determine what to send and when. If
          you toggle off any future automated invoices will be held. If you turn on invoices will send from that point
          onward.
        </ReactTooltip>
      </div>
      <div
        className={classnames(
          styles.item,
          styles.automatedInvoicing,
          values.automatedInvoicing.active && styles.active
        )}
      >
        {!invoiceSettingsPaymentMethodsReady && (
          <div className={styles.paymentMethodsNotReady}>
            <span className={styles.text}>Your default payment methods have not been set.</span>
            <Link className={styles.link} to={invoiceSettingsPath}>
              <i className={`material-icons ${styles.icon}`}>settings</i>
              Manage account-level invoice settings
            </Link>
          </div>
        )}
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            id="checkbox-automatedInvoicing-active"
            type="checkbox"
            checked={values.automatedInvoicing.active}
            onChange={(e) => setFieldValue('automatedInvoicing.active', e.target.checked)}
          />
          <label className={styles.checkboxLabel} htmlFor="checkbox-automatedInvoicing-active">
            Instant create and send invoice
          </label>
        </div>
        <div className={styles.automatedInvoicingContainer}>
          <FormikRadio
            nowrap
            labelClassName={styles.radioLabel}
            radioClassName={styles.radio}
            name="automatedInvoicing.type"
            options={AUTOMATED_INVOICING_TYPES_OPTIONS}
            onChange={handleAutomatedInvoicingTypeChange}
          />
          <div
            className={classnames(
              styles.sendAtConfig,
              values.automatedInvoicing.type === 'appointment' ? styles.appointment : styles.month
            )}
          >
            {values.automatedInvoicing.type === 'appointment' ? (
              <PerAppointment
                isClientMedicareValid={isClientMedicareValid}
                isPracticeConnectedMedicare={isPracticeConnectedMedicare}
              />
            ) : (
              <PerMonth />
            )}
          </div>
          <div className={styles.sendToContainer}>
            <div className={styles.heading}>Send To</div>
            <label className={styles.checkboxContainer} htmlFor="checkbox-sendToClient">
              <input
                className={styles.checkbox}
                id="checkbox-sendToClient"
                type="checkbox"
                checked={clientRecordData.allowCommunicationWithEmail ? values.automatedInvoicing.sendToClient : false}
                disabled={!clientRecordData.allowCommunicationWithEmail}
                onChange={(e) => setFieldValue('automatedInvoicing.sendToClient', e.target.checked)}
              />
              <div className={styles.checkboxLabel}>
                {t('label.client.capitalize')}
                {!clientRecordData.allowCommunicationWithEmail && (
                  <HelpOutLineWithTooltips
                    id={'sendToClient'}
                    desc={t('form.error.send_invoice_limited_communication')}
                  />
                )}
              </div>
            </label>
            <label className={styles.checkboxContainer} htmlFor="checkbox-sendToOther">
              <input
                className={styles.checkbox}
                id="checkbox-sendToOther"
                type="checkbox"
                checked={values.automatedInvoicing.sendToOther.active}
                onChange={(e) => setFieldValue('automatedInvoicing.sendToOther.active', e.target.checked)}
              />
              <div className={styles.checkboxLabel}>Other</div>
            </label>
            {values.automatedInvoicing.sendToOther.active && (
              <div className={styles.inputContainer}>
                <MaterialInput
                  label="Email address"
                  name="values.automatedInvoicing.sendToOther.emails"
                  value={values.automatedInvoicing.sendToOther.emails?.join(';')}
                  onChange={(e) => setFieldValue('automatedInvoicing.sendToOther.emails', e.target.value.split(';'))}
                />
                <ErrorMessage
                  error={
                    errors.automatedInvoicing?.sendToOther?.emails
                      ? Array.isArray(errors.automatedInvoicing.sendToOther.emails)
                        ? errors.automatedInvoicing.sendToOther.emails.find((error) => !!error)
                        : errors.automatedInvoicing.sendToOther.emails
                      : ''
                  }
                  visible={!!touched.automatedInvoicing?.sendToOther?.emails}
                />
              </div>
            )}
          </div>
          <div className={styles.includeNoteContainer}>
            <div className={styles.heading}>Include a note</div>
            <textarea
              id={'automatedInvoicing.note'}
              className={styles.textarea}
              rows={4}
              maxLength={160}
              value={values.automatedInvoicing.note}
              onChange={(e) => setFieldValue('automatedInvoicing.note', e.target.value)}
            />
            <span className={styles.wordCount}>{values.automatedInvoicing.note.length} / 160</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomatedInvoicing;
