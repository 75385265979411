import { notification } from 'antd';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useFetchClientRecordById } from 'pages/PatientDetails/hooks/GetClientRecord';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { putCompleteStudentPendingAssessment } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import StudentDetailsAssessmentList from './components/StudentDetailsAssessmentList/StudentDetailsAssessmentList';
import styles from './StudentDetailContent.module.scss';
import Assessment from 'components/PatientQuestionForm/components/Assessment/Assessment';
import { TeacherPendingAssessmentResponse } from 'interfaces/checkInService/clinicalAssessment';
import { scrollToView } from 'utils/scrollToView';
import { useCORDSTeacherRoutesGenerator } from 'MHH/utils/Path/CORDSRoutesGenerator';
import NextAssessmentModal from './components/NextAssessmentModal/NextAssessmentModal';
import LastAssessmentModal from './components/LastAssessmentModal/LastAssessmentModal';
import { formatAssessmentResponse } from 'utils/CORDS/formatAssessmentResponse';

interface StudentDetailsContentProps {
  isClientLoading: boolean;
  assessmentList: TeacherPendingAssessmentResponse[];
  isAssessmentListLoading: boolean;
  refetchAssessmentList: () => void;
  clientRecordData: clientRecordsInterface;
}

const StudentDetailContent = ({
  assessmentList,
  isAssessmentListLoading,
  refetchAssessmentList,
  clientRecordData
}: StudentDetailsContentProps) => {
  const { token } = useGetAccessToken();
  const path = useParams();
  const navigate = useNavigate();
  const { STUDENT } = useCORDSTeacherRoutesGenerator();
  const studentListPrevPath = localStorage.getItem('studentListPrevPath');
  const [assessment, setAssessment] = useState<TeacherPendingAssessmentResponse>();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const assessmentId = path.assessmentId;

  useEffect(() => {
    if (assessmentList) {
      setAssessment(assessmentList.find((i) => i._id === assessmentId));
    }
  }, [assessmentList, assessmentId]);

  const [nextAssessment, setNextAssessment] = useState<{
    id: string;
    clientRecordId: string;
  }>();

  const [lastAssessmentModal, setLastAssessmentModal] = useState<boolean>(false);

  const { accountId } = useGetAccountId();

  const { clientRecordData: nextClientRecord, isClientRecordLoading: isNextClientRecordLoading } =
    useFetchClientRecordById(token, nextAssessment?.clientRecordId, accountId, true);

  const handleGoToNextAssessment = () => {
    setNextAssessment(undefined);
    navigate(`/${STUDENT.LISTING}/${nextAssessment?.clientRecordId}/assessment/${nextAssessment?.id}`);
  };

  const handleUpdateAssessment = (responses: { questionId: string; response: { value: any; otherValue?: any } }[]) => {
    const adHocAssessment = assessment;
    if (adHocAssessment) {
      const newAdHocAssessment = { ...adHocAssessment };

      const foundQuestionSet = newAdHocAssessment.assessment.clinicalAssessment;

      if (foundQuestionSet) {
        const questions = foundQuestionSet.sections.map((section) => section.questions).flat();

        for (let i = 0; i < responses.length; i++) {
          const { questionId, response } = responses[i];

          const foundQuestion = questions.find((question) => question.id === questionId);

          foundQuestion && (foundQuestion.response = response);
        }
      }
      setAssessment(newAdHocAssessment);

      return { updatedQuestionSet: foundQuestionSet, updatedAssessment: newAdHocAssessment };
    }
  };

  const handleSubmit = async (assessmentId: string, values: { [key: string]: Record<string, any> }) => {
    setSubmitStatus('active');
    const responses = formatAssessmentResponse({ assessmentId, values });

    try {
      if (!assessment) {
        return;
      }

      const status = 'done';

      if (token) {
        const res = await putCompleteStudentPendingAssessment(token, assessment.clinicianId, assessment._id, {
          status,
          responses
        });
        setSubmitStatus('finished');
        const data = await res.text();
        if (data !== 'OK') {
          const obj = JSON.parse(data);
          if (obj?.id && obj?.clientRecordId) {
            setNextAssessment(obj);
          }
        } else if (data === 'OK') {
          setLastAssessmentModal(true);
        }
        refetchAssessmentList();
        scrollToView('studentDetailContentHeader', true);
      }

      setSubmitStatus('');
      const updated = handleUpdateAssessment(responses);
      return updated?.updatedQuestionSet;
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Something went wrong while trying to submit this assessment' });
    }
  };

  return (
    <div className={styles.container}>
      <NextAssessmentModal
        visible={(nextAssessment && !!nextClientRecord?._id) || false}
        onCancel={() => setNextAssessment(undefined)}
        nextClientRecord={nextClientRecord}
        isNextClientRecordLoading={isNextClientRecordLoading}
        handleGoToNextAssessment={handleGoToNextAssessment}
      />
      <LastAssessmentModal visible={lastAssessmentModal} onCancel={() => setLastAssessmentModal(true)} />
      <div className={styles.header} id={'studentDetailContentHeader'}>
        <button className={styles.backButton} onClick={() => navigate(`/${STUDENT.LISTING}${studentListPrevPath}`)}>
          <i className="material-icons-outlined">arrow_back_ios</i>Back
        </button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.sideBar}>
          <div className={styles.title}>STUDENT DETAILS</div>
          <div className={styles.name}>
            {clientRecordData.clientProfiles[0].firstName + ' ' + clientRecordData.clientProfiles[0].lastName}
          </div>
          <div className={styles.class}>Class: {clientRecordData.class || '-'}</div>
          <div className={styles.group}>
            Group:&nbsp;
            <div>
              {clientRecordData.attachedGroups?.length
                ? clientRecordData.attachedGroups.map(({ groupName }) => groupName).join(', ')
                : '-'}
            </div>
          </div>
          <div className={styles.tags}>
            {clientRecordData.tags?.map((clientObj, index) => (
              <div className={styles.tag} key={clientObj + '' + index}>
                {clientObj}
              </div>
            ))}
          </div>
          <div className={styles.subTitle}>ASSESSMENT</div>
          {path.recordId && (
            <StudentDetailsAssessmentList
              recordId={path.recordId}
              assessmentId={assessmentId}
              assessmentList={assessmentList}
              isLoading={isAssessmentListLoading}
            />
          )}
        </div>
        <div className={styles.content}>
          {assessment ? (
            <div className={'mhh-assessment-question'}>
              <Assessment
                assessment={assessment.assessment.clinicalAssessment}
                completedQuestions={0}
                header={() => <></>}
                reverseStemAndHideTitle={false}
                isSkippable={false}
                onBack={() => {}}
                onSaveAndExit={() => {}}
                isFirstQuestionSet={false}
                onSkip={() => {}}
                onSubmit={handleSubmit}
                submitBtnText={
                  <Button status={submitStatus} className={styles.continueButton}>
                    Complete and go to next assessment
                    <i className={`material-icons-outlined ${styles.contIcon}`}>arrow_circle_right</i>
                  </Button>
                }
                submitBtnClass={styles.continueButtonWrapper}
                isLoading={isAssessmentListLoading}
              />
            </div>
          ) : (
            <div className={styles.noItems}>
              {isAssessmentListLoading ? (
                <LoadingDot />
              ) : !assessmentList || assessmentList.length === 0 ? (
                'No assessment assign'
              ) : (
                'Please select assessment'
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentDetailContent;
