import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { StageForm, StageValidateField } from '../../../../interface';
import styles from './StageFormField.module.scss';
import StageFormType from './components/StageFormType/StageFormType';
import { stageFormValidation } from './components/StageFormType/validation/StageFormValidation';

interface StageFormFieldProps {
  id: string;
  stageFormFieldData: StageForm;
  onChangeStageType: (value: StageForm['stageType']) => void;
  checkValidation: boolean;
  errorMessage: StageValidateField;
  onChangeFieldValue: (key: string, value: string) => void;
}

export const validateStageField = (stageFieldVal: StageForm) => {
  const stageFormVal: StageValidateField = stageFormValidation(stageFieldVal) as StageValidateField;
  const checkFieldHaveError = Object.values(stageFormVal as StageForm).some((value) => value !== '');
  return {
    errorMessages: stageFormVal,
    isValidationPass: !checkFieldHaveError
  };
};

const StageFormField = ({
  id,
  stageFormFieldData,
  onChangeStageType,
  checkValidation,
  errorMessage,
  onChangeFieldValue
}: StageFormFieldProps) => {
  const handleFieldChange = async (key: string, value: string) => {
    onChangeFieldValue(key, value);
  };

  return (
    <>
      <StageFormType
        id={id}
        stageType={stageFormFieldData.stageType}
        onChangeStageType={onChangeStageType}
        disabled={!!stageFormFieldData._id}
        showLockIcon={!!stageFormFieldData._id}
      />
      <div className={classNames(styles.fieldContainer, checkValidation && errorMessage.name && styles.fieldError)}>
        <MaterialInput
          id={`stageNameId`}
          label={'Name*'}
          value={stageFormFieldData.name}
          required
          onChange={(e) => handleFieldChange('name', e.target.value)}
        />
        {checkValidation && errorMessage.name && <div className={styles.fieldError}>{errorMessage.name}</div>}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Description</div>
        <textarea
          className={styles.textarea}
          value={stageFormFieldData.description}
          onChange={(e) => handleFieldChange('description', e.target.value)}
        />
      </div>
    </>
  );
};

export default StageFormField;
