import { useEffect, useRef, useState } from 'react';
import styles from './ListCardMenu.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import Button from 'components/v2/Button/Button';

interface ListCardMenuProp {
  id: string;
  onTogglePublish?: (id: string) => void;
  onDelete?: (id: string) => void;
  isPublished?: boolean;
}

const ListCardMenu = ({ id, onTogglePublish, onDelete, isPublished = true }: ListCardMenuProp) => {
  const [menuExpand, setMenuExpand] = useState(false);
  const menuNode = useRef<HTMLDivElement>(null);

  // delete modal
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitStatus] = useState<'' | 'active' | 'finished'>('');

  const handleExpandMenu = () => {
    setMenuExpand(!menuExpand);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <>
      <div ref={menuNode} className={styles.container}>
        <div onClick={() => handleExpandMenu()} className={styles.buttonWrapper}>
          <i className={`material-icons ${styles.vertIcon}`}>more_vert</i>
        </div>
        <div className={styles.menuWrapper}>
          <div className={menuExpand ? styles.menuShow : styles.menuHide}>
            {isPublished && onTogglePublish && (
              <div
                className={styles.itemCard}
                onClick={() => {
                  setMenuExpand(false);
                  onTogglePublish(id);
                }}
              >
                <i className={`material-icons ${styles.itemIcon}`}>unpublished</i>
                <div className={styles.label}>Unpublish</div>
              </div>
            )}
            {onDelete && (
              <div
                className={styles.itemCard}
                onClick={() => {
                  setMenuExpand(false);
                  setOpenModal(true);
                }}
              >
                <i className={`material-icons ${styles.itemIcon}`}>delete</i>
                <div className={styles.label}>Delete</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <ModalV2
        containerClassName={styles.modalContainer}
        isModalOpen={openModal}
        title={'Delete Template'}
        onModalClose={() => setOpenModal((modal) => !modal)}
      >
        <div className={styles.modalText}>Are you sure you want to delete this template?</div>
        <div className={styles.submitButtonContainer}>
          <Button
            className={styles.cancelButton}
            variant="secondary"
            type={'button'}
            onClick={() => setOpenModal((modal) => !modal)}
          >
            Cancel
          </Button>
          <Button
            status={submitStatus}
            variant="pink"
            disabled={submitStatus !== ''}
            onClick={() => onDelete && onDelete(id)}
            id="deleteTemplate"
          >
            Delete
          </Button>
        </div>
      </ModalV2>
    </>
  );
};

export default ListCardMenu;
