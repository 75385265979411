import { useMemo } from 'react';

import { Question, Option, SubQuestion } from 'components/PatientQuestionForm/AssessmentInterfaces';

import styles from './SimpleSlider.module.scss';
import { Slider } from 'antd';

interface SimpleSliderProps {
  question: Question;
}

const extractSliderParameters = (options: Option[]) => {
  let min: number | undefined = undefined;
  let max: number | undefined = undefined;
  let tooltips: Record<number, string> = {};
  let marks: Record<number, string> = {};

  for (const { value, key, description } of options) {
    if (!Number.isInteger(value) || typeof value !== 'number' || value === undefined) {
      throw new Error('Expect value to be number');
    }

    if (min === undefined || min > value) {
      min = value;
    }

    if (max === undefined || max < value) {
      max = value;
    }

    marks = {
      ...marks,
      [value]: key
    };

    tooltips = {
      ...tooltips,
      [value]: description
    };
  }

  if (max === undefined || min === undefined) {
    throw new Error('Unable too determine min max value from scale');
  }

  return {
    min,
    max,
    marks,
    tooltips
  };
};

const isOptionsOptionType = (options: Option[] | SubQuestion[]): options is Option[] =>
  'key' in options[0] && 'description' in options[0] && 'value' in options[0];

const SimpleSlider = ({ question }: SimpleSliderProps) => {
  const { min, max, marks, value } = useMemo(() => {
    const { options, response } = question;

    if (options === undefined || !isOptionsOptionType(options)) {
      throw new Error('SimpleSlider component does not support question');
    }

    return { ...extractSliderParameters(options), value: response?.value };
  }, [question]);

  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        <div>{question.stem}</div>
        <Slider disabled min={min} max={max} marks={marks} value={value} />
      </div>
    </div>
  );
};

export default SimpleSlider;
