import { useState } from 'react';
import styles from './ClientContactDetails.module.scss';

import { clientProfilesEncrypted, clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { handleCopyContent } from 'utils/CopyFeature';
import { ROLE_TYPE_OPTIONS } from '../../../../../../../../components/AddPatientModalV2/formType/ChildForm/components/ChildGuardianForm/ChildGuardianForm';
import QrCodeModal from '../QrCodeModal/QrCodeModal';
import { getName } from 'utils/general';
import { useTranslation } from 'react-i18next';

interface ClientPersonalDetailsProps {
  clientProfileData: clientProfilesEncrypted;
  caseId?: clientRecordsEncryptedInterface['caseId'];
  classValue?: clientRecordsEncryptedInterface['class'];
  title: string;
}

const ClientContactDetails = ({ clientProfileData, title, caseId, classValue }: ClientPersonalDetailsProps) => {
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const contactDetailsExist = clientProfileData.mobileNumber && clientProfileData.email;
  const fullSignUpProfile = clientProfileData.profileType === 'full' && clientProfileData.signupInvitation?.signupDate;

  const findRoleLabel = (roleVal: string) => {
    const roleLabel = ROLE_TYPE_OPTIONS.find((obj) => obj.value === roleVal);
    return roleLabel ? roleLabel.label : 'Other';
  };

  const facilitatedLink = clientProfileData.onboardingSettings
    ? clientProfileData.onboardingSettings?.facilitationUrl
    : '';

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.pITitleWrapper}>
          <div className={styles.subTitle}>{title}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.fieldLabel}>
            <div>First Name</div>
            <div>Last Name</div>
            {fullSignUpProfile && <div>Nickname</div>}
            {clientProfileData.dateOfBirth && <div>Date of Birth</div>}
            {clientProfileData.role && clientProfileData.role !== 'child' && <div>Relationship</div>}
            {caseId && <div>{t('form.client_id')} #</div>}
            {classValue && <div>Class</div>}
          </div>
          <div className={styles.verticalLine} />
          <div className={styles.fieldBox}>
            <div>{clientProfileData.firstName}</div>
            <div>{clientProfileData.lastName}</div>
            {fullSignUpProfile && <div>{clientProfileData.name}</div>}
            {clientProfileData.dateOfBirth && <div>{clientProfileData.dateOfBirth}</div>}
            {clientProfileData.role && clientProfileData.role !== 'child' && (
              <div>{findRoleLabel(clientProfileData.role)}</div>
            )}
            {caseId && <div>{caseId}</div>}
            {classValue && <div>{classValue}</div>}
          </div>
        </div>
      </div>
      {contactDetailsExist && (
        <div className={styles.infoWrapper}>
          <div className={styles.subTitle}>CONTACT DETAILS</div>
          <div className={styles.details}>
            <div className={styles.fieldLabel}>
              {clientProfileData.mobileNumber && <div>Mobile</div>}
              {clientProfileData.email && <div>Email</div>}
              {clientProfileData?.postcode && <div>Postcode</div>}
            </div>
            <div className={styles.verticalLine} />
            <div className={styles.fieldBox}>
              {clientProfileData.mobileNumber && (
                <div className={styles.fieldValue}>
                  {clientProfileData.mobileNumber}
                  <i
                    className={`material-icons ${styles.icon}`}
                    onClick={() => handleCopyContent(clientProfileData.mobileNumber)}
                  >
                    content_copy
                  </i>
                </div>
              )}
              {clientProfileData.email && (
                <div className={styles.fieldValue}>
                  <div className={styles.widthValue}>{clientProfileData.email}</div>
                  <i
                    className={`material-icons ${styles.icon}`}
                    onClick={() => handleCopyContent(clientProfileData.email)}
                  >
                    content_copy
                  </i>
                </div>
              )}
              {clientProfileData?.postcode && <div className={styles.fieldValue}>{clientProfileData?.postcode}</div>}
            </div>
          </div>
        </div>
      )}

      {facilitatedLink && (
        <div className={styles.infoWrapper}>
          <div className={styles.subTitle}>ONBOARDING</div>
          <div className={styles.details}>
            <div className={styles.fieldLabel}>{facilitatedLink && <div>Link</div>}</div>
            <div className={styles.verticalLine} />
            <div className={styles.fieldBox}>
              {facilitatedLink && (
                <div className={styles.fieldValue}>
                  <div className={styles.facUContainer} onClick={() => setQrCodeModal(true)}>
                    <i className={`material-icons ${styles.qrCodeBtn}`}>qr_code_2</i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <QrCodeModal
        name={getName(clientProfileData)}
        facilitatedUrl={facilitatedLink}
        visibleModal={qrCodeModal}
        onCancel={() => setQrCodeModal(false)}
      />
    </div>
  );
};

export default ClientContactDetails;
