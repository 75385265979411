import { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, notification } from 'antd';
import { Formik, FormikProps } from 'formik';

import { HeadingTemplate } from 'components/HeadingTemplate/HeadingTemplateList/HeadingTemplateList';
import { CaseNoteFormatSettings } from '../../PatientDetailsNotes';

import {
  getCaseNoteFormatAvailableSettingsByRecordId,
  postCaseNoteHeadingTemplate,
  putCaseNoteFormatSettings
} from 'utils/http/DocumentService/CaseNotes/caseNotes';

import Button from 'components/Button/Button';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import LoadingButton from 'components/v2/Button/Button';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/Radio/Radio';
import Select from 'components/Select/CommonSelect/Select';
import WidgetTextEditor from 'components/WidgetTextEditor/WidgetTextEditor';
import PatientDetailsNoteFormatSettingsFields from './components/PatientDetailsNoteFormatSettingsFields/PatientDetailsNoteFormatSettingsFields';

import { fields } from './defaultFormatSettingsFields.json';

import styles from './PatientDetailsNoteFormatSettingsModal.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export const safeGuardingName = 'Safeguarding Alert';

export const useFetchCaseNoteFormatSettingsList = (recordId: string, visible: boolean) => {
  const { getAccessTokenSilently } = useAuth0();

  const [caseNoteFormatSettingsList, setCaseNoteFormatSettingsList] = useState<CaseNoteFormatSettings[]>([]);
  const [isCaseNoteFormatSettingsListLoading, setIsCaseNoteFormatSettingsListLoading] = useState(false);

  const fetchCaseNoteFormatSettingsList = async (recordId: string) => {
    setIsCaseNoteFormatSettingsListLoading(true);

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const getCaseNoteFormatSettingsByRecordId = await getCaseNoteFormatAvailableSettingsByRecordId(token, recordId);
      const caseNoteFormatSettingsList = await getCaseNoteFormatSettingsByRecordId.json();

      setCaseNoteFormatSettingsList(caseNoteFormatSettingsList);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to fetch your list of case notes format settings'
      });
    }

    setIsCaseNoteFormatSettingsListLoading(false);
  };

  useEffect(() => {
    if (visible && recordId) {
      fetchCaseNoteFormatSettingsList(recordId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId, visible]);

  return { caseNoteFormatSettingsList, isCaseNoteFormatSettingsListLoading };
};

interface PatientDetailsNoteFormatSettingsModalProps {
  recordId: string;
  caseNoteHeadingTemplates: HeadingTemplate[];
  defaultCaseNoteFormatSettings?: CaseNoteFormatSettings;
  visible: boolean;
  onCancel: (refetchFormatSettings?: boolean) => void;
  onSubmitTemplate: (newHeadingTemplate: HeadingTemplate) => void;
}

const PatientDetailsNoteFormatSettingsModal = ({
  recordId,
  caseNoteHeadingTemplates,
  defaultCaseNoteFormatSettings,
  visible,
  onCancel,
  onSubmitTemplate
}: PatientDetailsNoteFormatSettingsModalProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [t] = useTranslation();

  const { isHelmFeatureToggle } = useGetFeatureToggle();

  const INITIAL_VALUES: Partial<CaseNoteFormatSettings> = {
    fields: isHelmFeatureToggle
      ? (fields.filter((obj) => obj.name !== safeGuardingName) as CaseNoteFormatSettings['fields'])
      : (fields as CaseNoteFormatSettings['fields'])
  };

  const { caseNoteFormatSettingsList, isCaseNoteFormatSettingsListLoading } = useFetchCaseNoteFormatSettingsList(
    recordId,
    visible
  );

  const formikRef = useRef<FormikProps<Partial<CaseNoteFormatSettings>>>(null);

  const [selectedCaseNoteFormatSettingsId, setSelectedCaseNoteFormatSettingsId] = useState<string>();
  const [selectedFormatType, setSelectedFormatType] = useState('custom');
  const [selectedHeadingTemplateType, setSelectedHeadingTemplateType] = useState('existing');

  const [newSelectedFormatTemplateName, setNewSelectedFormatTemplateName] = useState('');
  const [selectedDefaultTemplateId, setSelectedDefaultTemplateId] = useState('');
  const [headingTemplateName, setHeadingTemplateName] = useState('');
  const [newDefaultTemplateBody, setNewDefaultTemplateBody] = useState('');

  const [errorValidation, setErrorValidation] = useState({
    templateSettingsName: false,
    headingTemplateName: false
  });

  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isEditsLocked, setIsEditsLocked] = useState(false);

  useEffect(() => {
    if (defaultCaseNoteFormatSettings) {
      const { _id, type, defaultHeadingTemplateId, templateName } = defaultCaseNoteFormatSettings;

      setSelectedCaseNoteFormatSettingsId(_id);
      setSelectedFormatType(type);
      setNewSelectedFormatTemplateName(templateName);

      if (type === 'template') {
        setIsEditsLocked(true);
      }

      if (defaultHeadingTemplateId) {
        setSelectedDefaultTemplateId(defaultHeadingTemplateId);
        setSelectedHeadingTemplateType('existing');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCaseNoteFormatSettings]);

  const headingTemplateOptions = useMemo(
    () => caseNoteHeadingTemplates.map((template) => ({ label: template.title, value: template._id })),
    [caseNoteHeadingTemplates]
  );

  const handleCustomSettings = () => {
    setSelectedFormatType('custom');

    const foundCustomFormatSettings = caseNoteFormatSettingsList.find((setting) => setting.type === 'custom');

    if (foundCustomFormatSettings) {
      setSelectedCaseNoteFormatSettingsId(foundCustomFormatSettings._id);
      setSelectedHeadingTemplateType('existing');
      setSelectedDefaultTemplateId(foundCustomFormatSettings.defaultHeadingTemplateId || '');
    } else {
      setSelectedCaseNoteFormatSettingsId(undefined);
    }
  };

  const handleTemplateSettings = () => {
    setSelectedFormatType('template');

    const foundCustomFormatSettings = caseNoteFormatSettingsList.find((setting) => setting.type === 'template');
    if (foundCustomFormatSettings) {
      setSelectedHeadingTemplateType('existing');
      setSelectedCaseNoteFormatSettingsId(foundCustomFormatSettings._id);
      setSelectedDefaultTemplateId(foundCustomFormatSettings.defaultHeadingTemplateId || '');
      setNewSelectedFormatTemplateName(foundCustomFormatSettings.templateName);
      setIsEditsLocked(true);
    } else {
      setSelectedCaseNoteFormatSettingsId('newSettingsTemplate');
      setIsEditsLocked(false);
    }
  };

  const handleTemplateSettingsList = (value?: { label: string; value: string } | null) => {
    setSelectedCaseNoteFormatSettingsId(value?.value);
    const foundCustomFormatSettings = caseNoteFormatSettingsList.find((setting) => setting._id === value?.value);

    if (foundCustomFormatSettings) {
      setSelectedHeadingTemplateType('existing');
      setSelectedCaseNoteFormatSettingsId(foundCustomFormatSettings._id);
      setSelectedDefaultTemplateId(foundCustomFormatSettings.defaultHeadingTemplateId || '');
      setNewSelectedFormatTemplateName(foundCustomFormatSettings.templateName);
    }

    if (value?.value === 'newSettingsTemplate') {
      setIsEditsLocked(false);
      setNewSelectedFormatTemplateName('');
    } else {
      setIsEditsLocked(true);
    }
  };

  const handleChangeSettingsName = (value: string) => {
    setNewSelectedFormatTemplateName(value);
    setErrorValidation({
      ...errorValidation,
      templateSettingsName: !value
    });
  };

  const handleChangeHeadingTemplateName = (value: string) => {
    setHeadingTemplateName(value);
    setErrorValidation({
      ...errorValidation,
      headingTemplateName: !value
    });
  };

  const getHeadingTemplateId = async (token: string) => {
    let headingTemplateId;
    if (selectedHeadingTemplateType !== 'newHeadingTemplate') {
      headingTemplateId = selectedDefaultTemplateId;
    } else {
      const headingTemplateBody = {
        title: headingTemplateName,
        body: newDefaultTemplateBody
      };
      const callPostHeadingTemplate = await postCaseNoteHeadingTemplate(token, headingTemplateBody);
      const createdHeadingTemplate = await callPostHeadingTemplate.json();
      onSubmitTemplate(createdHeadingTemplate);
      headingTemplateId = createdHeadingTemplate._id;
    }
    return headingTemplateId;
  };

  const validationChecking = () => {
    let error = {
      templateSettingsName: selectedFormatType === 'template' ? !newSelectedFormatTemplateName : false,
      headingTemplateName: selectedHeadingTemplateType === 'newHeadingTemplate' && !headingTemplateName
    };

    if (error.templateSettingsName || error.headingTemplateName) {
      setErrorValidation(error);
      setSaveButtonStatus('');
    }

    return !error.templateSettingsName && !error.headingTemplateName;
  };

  const handleSaveFormatSettings = async () => {
    setSaveButtonStatus('active');

    try {
      if (formikRef.current) {
        if (validationChecking()) {
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });
          const { fields } = formikRef.current.values;

          const headingTemplateId = await getHeadingTemplateId(token);

          let payload;
          if (selectedCaseNoteFormatSettingsId === 'newSettingsTemplate') {
            payload = {
              fields,
              type: selectedFormatType,
              ...(selectedFormatType !== 'custom' && {
                templateName: newSelectedFormatTemplateName
              }),
              defaultHeadingTemplateId: headingTemplateId
            };
          } else {
            payload = {
              _id: selectedCaseNoteFormatSettingsId,
              fields,
              type: selectedFormatType,
              ...(selectedFormatType !== 'custom' && {
                templateName: newSelectedFormatTemplateName
              }),
              defaultHeadingTemplateId: headingTemplateId
            };
          }

          await putCaseNoteFormatSettings(token, recordId, payload);
          setSaveButtonStatus('finished');
          onCancel(true);

          setTimeout(() => {
            setHeadingTemplateName('');
            setNewDefaultTemplateBody('');
            setSaveButtonStatus('');
          }, 2000);
        }
      } else {
        throw new Error('Formik not initiated');
      }
    } catch (ex) {
      console.error(ex);

      setSaveButtonStatus('');

      notification.error({ message: 'Something went wrong while trying to save your format settings' });
    }
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      width={768}
      footer={null}
      visible={visible}
      onCancel={() => onCancel()}
      destroyOnClose
    >
      <div className={styles.header}>
        <div className={styles.title}>Case Note Format Setting</div>
        <div className={styles.description}>
          Any change here will only impact{' '}
          <strong>
            <u>NEW</u>
          </strong>{' '}
          notes, not change any existing saved notes
        </div>
      </div>
      {isCaseNoteFormatSettingsListLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <div className={styles.formatTypeContainer}>
            <div className={styles.sectionHeading}>
              {t('client_case_notes.select_format')}
              <HelpOutLineWithTooltips id="select-format" desc={t('client_case_notes.select_format.description')} />
            </div>
            <Radio
              className={styles.radioButtonContainer}
              options={[{ label: t('client_case_notes.select_format_options.custom'), value: 'custom' }]}
              value={selectedFormatType}
              onChange={handleCustomSettings}
              vertical
            />
            <div className={styles.templateFormatTypeContainer}>
              <Radio
                className={styles.templateRadioButton}
                options={[{ label: t('client_case_notes.select_format_options.template'), value: 'template' }]}
                value={selectedFormatType}
                onChange={handleTemplateSettings}
                vertical
              />
              {selectedFormatType === 'template' && (
                <>
                  <Select
                    className={styles.select}
                    labelClass={styles.label}
                    label="Select Format"
                    styles={{ valueContainer: (base) => ({ ...base, paddingLeft: 0 }) }}
                    options={[
                      ...caseNoteFormatSettingsList.map((setting) => ({
                        label: setting.templateName,
                        value: setting._id
                      })),
                      { label: '+ Create New', value: 'newSettingsTemplate' }
                    ]}
                    placeholder="Select from list"
                    value={selectedCaseNoteFormatSettingsId}
                    onChange={handleTemplateSettingsList}
                  />
                  <div className={styles.newFormatName}>
                    {selectedCaseNoteFormatSettingsId === 'newSettingsTemplate' && (
                      <div className={styles.formatName}>
                        <div>
                          <MaterialInput
                            id="new-format-template-name"
                            label="Title for this format"
                            value={newSelectedFormatTemplateName}
                            onChange={(e) => handleChangeSettingsName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    )}
                    <ErrorMessage
                      visible={errorValidation.templateSettingsName}
                      error={'Please insert template format name'}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.fieldsContainer}>
            {selectedFormatType === 'template' &&
              selectedCaseNoteFormatSettingsId !== 'newSettingsTemplate' &&
              isEditsLocked && (
                <div className={styles.lockEdits}>
                  <Button
                    className={styles.unlockEditsButton}
                    variant="secondary"
                    onClick={() => setIsEditsLocked(false)}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>lock</i>
                    {t('client_case_notes.click_to_unlock_and_edit')}
                  </Button>
                  <span className={styles.text}>{t('client_case_notes.click_to_unlock_and_edit.description')}</span>
                </div>
              )}
            <div className={styles.editContainer}>
              {selectedFormatType === 'template' &&
                selectedCaseNoteFormatSettingsId !== 'newSettingsTemplate' &&
                isEditsLocked && <div className={styles.disabled} />}
              <div className={styles.sectionHeading}>
                {t('client_case_notes.add_specific_fields')}
                <HelpOutLineWithTooltips
                  id="add-specific-fields"
                  desc={t('client_case_notes.add_specific_fields.description')}
                />
              </div>
              <div className={styles.description}>
                Highlight the <FontAwesomeIcon className={styles.starIcon} icon={faAsterisk} /> to make mandatory, and
                use <i className={`material-icons-outlined ${styles.icon}`}>edit</i> to edit field entry requirements
              </div>
              <Formik
                innerRef={formikRef}
                initialValues={
                  {
                    ...INITIAL_VALUES,
                    ...caseNoteFormatSettingsList?.find((setting) => setting._id === selectedCaseNoteFormatSettingsId)
                  } || INITIAL_VALUES
                }
                onSubmit={() => {}}
                enableReinitialize
              >
                <PatientDetailsNoteFormatSettingsFields />
              </Formik>
            </div>
          </div>
          <div className={styles.headingTemplateContainer}>
            {selectedFormatType === 'template' &&
              selectedCaseNoteFormatSettingsId !== 'newSettingsTemplate' &&
              isEditsLocked && <div className={styles.disabled} />}
            <div className={styles.sectionHeading}>
              {t('client_case_notes.setup_template')}
              <HelpOutLineWithTooltips id="set-up-template" desc={t('client_case_notes.setup_template.description')} />
            </div>
            <div className={styles.existingTemplateContainer}>
              <Radio
                options={[{ label: 'Use an existing template', value: 'existing' }]}
                value={selectedHeadingTemplateType}
                onChange={(e) => setSelectedHeadingTemplateType(e.target.value)}
              />
              {selectedHeadingTemplateType === 'existing' && (
                <Select
                  className={styles.select}
                  labelClass={styles.label}
                  options={headingTemplateOptions}
                  styles={{ valueContainer: (base) => ({ ...base, paddingLeft: 0 }) }}
                  label="Select template"
                  placeholder="Select from list"
                  value={selectedDefaultTemplateId}
                  onChange={(e) => setSelectedDefaultTemplateId(e?.value || '')}
                />
              )}
            </div>
            <div className={styles.newTemplateContainer}>
              <Radio
                options={[{ label: 'Create a new template', value: 'newHeadingTemplate' }]}
                value={selectedHeadingTemplateType}
                onChange={(e) => setSelectedHeadingTemplateType(e.target.value)}
              />
              {selectedHeadingTemplateType === 'newHeadingTemplate' && (
                <div className={styles.newTemplateEditor}>
                  <MaterialInput
                    id="new-template-name"
                    label="Name"
                    value={headingTemplateName}
                    onChange={(e) => handleChangeHeadingTemplateName(e.target.value)}
                    required
                  />
                  <ErrorMessage visible={errorValidation.headingTemplateName} error={'Please insert template name'} />
                  <WidgetTextEditor
                    className="case-notes-format-settings-template-editor"
                    containerClass={styles.textEditorContainer}
                    value={newDefaultTemplateBody}
                    onChangeValue={(value: string) => setNewDefaultTemplateBody(value)}
                    fullHeightEditor
                    enableMention
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <LoadingButton className={styles.submitButton} status={saveButtonStatus} onClick={handleSaveFormatSettings}>
              Save Settings
            </LoadingButton>
          </div>
        </>
      )}
    </Modal>
  );
};

export default PatientDetailsNoteFormatSettingsModal;
