import { useField } from 'formik';

import styles from './PaymentRequired.module.scss';

const PaymentRequired = () => {
  const [{ value: paymentRequired, onChange: onPaymentRequiredChange }] = useField('paymentRequired');

  return (
    <div className={styles.container}>
      <div className={`${styles.paymentRequired} ${paymentRequired && styles.active}`}>
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            id={`checkbox-paymentRequired`}
            type="checkbox"
            name="paymentRequired"
            checked={paymentRequired}
            onChange={onPaymentRequiredChange}
          />
          <label className={styles.label} htmlFor={`checkbox-paymentRequired`}>
            Require pre-payment online to confirm booking?
          </label>
        </div>
      </div>
    </div>
  );
};

export default PaymentRequired;
