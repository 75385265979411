import Button from 'components/v2/Button/Button';
import { ActionPlanTaskItem } from 'MHH/pages/ActionPlanCreator/interfaces';
import moment from 'moment';
import styles from './TaskDetail.module.scss';

interface TaskDetailProps {
  task: ActionPlanTaskItem;
  saveStatus?: '' | 'active' | 'finished';
  onSaveCompletedStatus: (taskId: string, isCompleted: boolean) => void;
}
const TaskDetail = ({ task, saveStatus, onSaveCompletedStatus }: TaskDetailProps) => {
  return (
    <div className={styles.card}>
      <span className={styles.fieldName}>
        CREATED: <b>{moment(task.createdAt).format('DD/MM/YYYY')}</b>
      </span>
      <span className={styles.title}>{task.title}</span>
      <label className={styles.description}>{task.description}</label>
      <span className={styles.dueDate}>
        <i className={`material-icons-outlined ${styles.icon}`}>alarm</i>
        {moment(task.createdAt).format('dddd, Do MMMM YYYY')}
      </span>
      <Button
        className={styles.addButton}
        disabled={false}
        status={saveStatus}
        onClick={() => onSaveCompletedStatus(task._id || '', !task.isCompleted)}
      >
        <span className={'material-icons-outlined'}>{!task.isCompleted ? 'task_alt' : 'undo'}</span>
        {`Mark as ${task.isCompleted ? 'not ' : ''}done`}
      </Button>
    </div>
  );
};

export default TaskDetail;
