import { useEffect, useMemo, useRef, useState } from 'react';
import { notification, Modal } from 'antd';

import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { deactivatePractitioner, assignRole } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import _ from 'lodash';
import styles from './PractitionerListAction.module.scss';
import { getUserRoles } from 'interfaces/Practitioners/practitionersListing';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { getEmailUsed } from 'utils/http/verification';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { updateClinicianContactDetailsByClinicianId } from 'utils/http/clinician';

interface PractitionerListActionProps {
  id: string;
  practitionerRole: string;
  onDeactivatePractitioner: (id: string) => void;
  onChangePractitionerRole: () => void;
  practitionerName: string;
  lastItem?: boolean;
  showOutstandingAssessmentButton: boolean;
  handleOpenOutstandingAssessmentsModal: () => void;
  email: string;
  mobileNumber: string;
}

const PractitionerListAction = ({
  id,
  practitionerRole,
  onDeactivatePractitioner,
  onChangePractitionerRole,
  practitionerName,
  lastItem,
  showOutstandingAssessmentButton,
  handleOpenOutstandingAssessmentsModal,
  email,
  mobileNumber
}: PractitionerListActionProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isMentorFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  const USER_ROLES = useMemo(() => getUserRoles(t), [t]);

  const node = useRef<HTMLDivElement>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);

  const [updateContactDetailsOpen, setUpdateContactDetailsOpen] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState<ButtonStatusType>('');

  const [newEmail, setNewEmail] = useState(email);
  const [newMobileNumber, setNewMobileNumber] = useState(mobileNumber);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const setOpen = (val: boolean) => {
    setShowActionMenu(val);
  };

  const handleConfirmModal = () => {
    Modal.confirm({
      title: 'Confirm message',
      content: 'Are you sure that you want to deactivate?',
      onOk: async () => {
        try {
          await deactivatePractitioner(token, accountId, id);

          onDeactivatePractitioner(id);

          setShowActionMenu(false);

          notification.success({ message: t('form.success.deactivate_practitioner') });
        } catch (ex) {
          console.error(ex);

          notification.error({ message: t('form.error.deactivate_practitioner') });
        }
      }
    });
  };

  const handleConfirmChangeRoleModal = (newRole: string) => {
    setShowActionMenu(false);
    const getRoleTranslate = USER_ROLES[newRole].toLowerCase();
    Modal.confirm({
      title: 'Confirm message',
      content: `Are you sure that you want to change ${practitionerName} to ${getRoleTranslate} role?`,
      onOk: async () => {
        onHandleChangeRole(newRole);
      }
    });
  };

  const onHandleChangeRole = async (newRole: string) => {
    try {
      await assignRole(token, accountId, id, newRole);
      onChangePractitionerRole();
      notification.success({ message: t('form.success.assign_role_practitioner') });
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: t('form.error.assign_role_practitioner', { role: _.startCase(newRole) })
      });
    }
  };

  const onSelectOutstandingAssessments = () => {
    handleOpenOutstandingAssessmentsModal();
    setShowActionMenu(false);
  };

  // hacky CORDS implementation for changing email quickly
  const handleUpdateContactDetails = async () => {
    setSaveButtonStatus('active');
    try {
      await getEmailUsed(newEmail);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Email already in use' });
      setSaveButtonStatus('');
    }

    try {
      await updateClinicianContactDetailsByClinicianId(token, { email: newEmail, mobileNumber: newMobileNumber }, id);

      setUpdateContactDetailsOpen(false);
      notification.success({ message: 'Contact details updated' });
      setSaveButtonStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Failed to update contact details' });

      setSaveButtonStatus('');
    }

    setTimeout(() => {
      setSaveButtonStatus('');
    }, 2000);
  };

  return (
    <div className={styles.container} ref={node}>
      <div
        className={showActionMenu ? styles.btnWrapperActive : styles.btnWrapper}
        onClick={() => setOpen(!showActionMenu)}
      >
        Actions...
        <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
      </div>
      <div className={styles.menuWrapper}>
        <div
          className={classnames(
            showActionMenu ? styles.menuBoxShow : styles.menuBoxHide,
            lastItem && styles.displayToTop
          )}
        >
          <div className={styles.listBox} onClick={() => handleConfirmModal()}>
            <div className={styles.title}>{t('label.deactivate_practitioner')}</div>
          </div>
          {Object.keys(USER_ROLES)
            .filter((roleKey) => roleKey !== practitionerRole && (roleKey !== 'mentor' || isMentorFeatureToggle))
            .map((role) => (
              <div key={`role_${role}`} className={styles.listBox} onClick={() => handleConfirmChangeRoleModal(role)}>
                <div className={styles.title}>{t('label.change_to_role', { role: USER_ROLES[role] })}</div>
              </div>
            ))}
          {showOutstandingAssessmentButton && (
            <div className={styles.listBox} onClick={onSelectOutstandingAssessments}>
              <div className={styles.title}>View Outstanding Assessments</div>
            </div>
          )}
          <div className={styles.listBox} onClick={() => setUpdateContactDetailsOpen(true)}>
            <div className={styles.title}>Update Contact Details</div>
          </div>
        </div>
      </div>
      <Modal
        title="Update Contact Details"
        open={updateContactDetailsOpen}
        onCancel={() => setUpdateContactDetailsOpen(false)}
        footer={null}
      >
        <MaterialInput
          label=""
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="email@domain.com"
          required
          className={styles.input}
        />
        <MaterialInput
          label=""
          value={newMobileNumber}
          onChange={(e) => setNewMobileNumber(e.target.value)}
          placeholder="+447123456789"
          required
          className={styles.input}
        />
        <Button
          className={styles.saveButton}
          status={saveButtonStatus}
          disabled={!!saveButtonStatus}
          onClick={() => handleUpdateContactDetails()}
        >
          Save
        </Button>
      </Modal>
    </div>
  );
};

export default PractitionerListAction;
