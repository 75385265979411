import WidgetTextEditor from 'components/WidgetTextEditor/WidgetTextEditor';
import { useEffect, useRef, useState } from 'react';

import styles from './BodyTextWidget.module.scss';

interface BodyTextWidgetProps {
  data: any;
  id?: any;
  changeValue?: any;
  previewMode?: boolean;
  editMode?: boolean;
  enableMention?: boolean;
}

const BodyTextWidget = ({ data, id, changeValue, previewMode, editMode, enableMention }: BodyTextWidgetProps) => {
  const bodyTextRef = useRef<HTMLDivElement>(null);
  const [isShowInput, setShowInput] = useState(false);

  const handleClick = (e: any) => {
    if (bodyTextRef.current?.contains(e.target)) {
      return;
    }
    setShowInput(false);
    if (changeValue && editMode) {
      changeValue(id, data.title, false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onClickEditText = () => {
    setShowInput(true);
    changeValue(id, data.title, true);
  };

  const onChangeValue = (val: any) => {
    changeValue(id, val, true);
  };

  if (previewMode) {
    return (
      <div className={`ql-snow ${styles.previewContainer}`}>
        <div className={`ql-editor ${styles.wrapper}`} dangerouslySetInnerHTML={{ __html: data.title }} />
      </div>
    );
  }

  return !isShowInput ? (
    <div className={`ql-snow ${styles.containerWrapper}`} onClick={onClickEditText}>
      <div className={`ql-editor ${styles.container}`} dangerouslySetInnerHTML={{ __html: data.title }} />
    </div>
  ) : (
    <div className={styles.containerWrapper} ref={bodyTextRef}>
      <WidgetTextEditor
        className={styles.editContainer}
        value={data.title}
        onChangeValue={(e: any) => onChangeValue(e)}
        fullHeightEditor
        noBackground
        enableMention={enableMention}
      />
    </div>
  );
};

export default BodyTextWidget;
