import { MouseEvent, useState } from 'react';
import styles from './SendSurveyModal.module.scss';
import moment from 'moment';
import { notification } from 'antd';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import SelectSurveyForms from './components/SelectSurveyForms/SelectSurveyForms';
import ModalV2 from 'components/ModalV2/ModalV2';
import AddNote from 'components/AddNote/AddNote';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import { postGroupAdHocSurvey } from 'utils/http/CheckInService/Assessment/groupAssessment';
import { useGetAccessToken } from 'utils/hooks/token';
import { FirstAssessmentResponse } from 'interfaces/checkInService/firstAssessment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface SendSurveyModalProps {
  groupId: string;
  showModal: boolean;
  handleCancel: () => void;
  handleSuccess: () => void;
}

const SendSurveyModal = ({ groupId, showModal, handleCancel, handleSuccess }: SendSurveyModalProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formCompleted, setFormCompleted] = useState(false);
  const [selectedSurveyForms, setSelectedSurveyForms] = useState<FirstAssessmentResponse[]>([]);
  const [note, setNote] = useState('');
  const [customSendAt, setCustomSendAt] = useState(moment().add(1, 'hour').minute(0).format('YYYY-MM-DD hh:mmA Z'));
  const [sendAt, setSendAt] = useState('now');
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [selectedGroup, setSelectedGroup] = useState<GroupsFromAPI>();

  const onCancel = (e?: MouseEvent) => {
    e?.preventDefault();
    setCustomSendAt(moment().add(1, 'hour').minute(0).format('YYYY-MM-DD hh:mmA Z'));
    setNote('');
    setSendAt('now');
    setCurrentStep(1);
    setSubmitStatus('');
    setSelectedSurveyForms([]);
    handleCancel();
  };

  const onAddSelectedSurveyForms = (group: FirstAssessmentResponse) => {
    const newSelectedSurveyForms = [...selectedSurveyForms, group];
    setSelectedSurveyForms(newSelectedSurveyForms);
  };

  const onRemoveSelectedSurveyForms = (group: FirstAssessmentResponse) => {
    const newSelectedSurveyForms = selectedSurveyForms.filter((item) => item.id !== group.id);
    setSelectedSurveyForms(newSelectedSurveyForms);
  };

  const onNextClick = () => {
    setCurrentStep(2);
  };

  const onSelectGroup = (groupData: GroupsFromAPI) => {
    setSelectedSurveyForms([]);
    setSelectedGroup(groupData);
  };

  const handleSubmit = async () => {
    setSubmitStatus('active');
    let sendAtDateTime: Date;
    const now = moment().format('HH:mm');
    if (sendAt === '9') {
      if (now > '09:00') {
        sendAtDateTime = moment().add('1', 'day').hour(9).minute(0).toDate();
      } else {
        sendAtDateTime = moment().hour(9).minute(0).toDate();
      }
    } else if (sendAt === '13') {
      if (now > '13:00') {
        sendAtDateTime = moment().add('1', 'day').hour(13).minute(0).toDate();
      } else {
        sendAtDateTime = moment().hour(13).minute(0).toDate();
      }
    } else if (sendAt === 'custom') {
      sendAtDateTime = moment(customSendAt, 'YYYY-MM-DD HH:mm').toDate();
    } else {
      sendAtDateTime = moment().toDate();
    }

    const massageSelectedId = selectedSurveyForms.map((surveyFormObj) => surveyFormObj.id);

    const massagePayload = {
      surveyIds: massageSelectedId,
      sendAt: sendAtDateTime,
      sendAsAdmin: isEdgeAdminView,
      ...(note.length > 0 && {
        note: note
      })
    };

    try {
      await postGroupAdHocSurvey(token, groupId, massagePayload);
      notification.success({
        message: 'Survey Forms sent!',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setFormCompleted(true);
      setSubmitStatus('finished');
      handleSuccess();
      setTimeout(() => {
        onCancel();
        setSubmitStatus('');
        setFormCompleted(false);
      }, 2000);
    } catch (ex) {
      setSubmitStatus('');
      notification.error({ message: 'Something went wrong while trying to send this forms. Please try again' });
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title={'Send Survey Forms'}
      totalStep={2}
      currentStep={currentStep}
      isCompleted={formCompleted}
      onModalClose={onCancel}
    >
      <div className={styles.container}>
        <div className={styles.selectGroup}>
          <SelectClientOrGroup
            enableGroupSelection
            defaultParticipationType={ParticipantType.Group}
            defaultGroupId={groupId}
            handleSelectGroup={onSelectGroup}
          />
        </div>
        <SelectSurveyForms
          className={currentStep === 1 ? styles.active : styles.inactive}
          selectedGroup={selectedGroup}
          selectedSurveyForms={selectedSurveyForms}
          onAddSelectedSurveyForms={onAddSelectedSurveyForms}
          onRemoveSelectedSurveyForms={onRemoveSelectedSurveyForms}
          onNextClick={onNextClick}
        />
        <AddNote
          selectedGroup={selectedGroup}
          participationType={ParticipantType.Group}
          onBackClick={() => setCurrentStep(1)}
          onCustomSendAtChange={setCustomSendAt}
          onNoteChanged={setNote}
          onSendAtChange={setSendAt}
          customSendAt={customSendAt}
          sendAt={sendAt}
          className={currentStep === 2 ? styles.active : styles.inactive}
          selectedSurveyForms={selectedSurveyForms}
          onSubmit={handleSubmit}
          submitStatus={submitStatus}
        />
      </div>
    </ModalV2>
  );
};

export default SendSurveyModal;
