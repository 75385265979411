import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const postProspectClientsRecord = (token: string, body: Record<string, any>) => {
  return httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/prospect`,
    body
  );
};

export const putProspectClientsRecord = (token: string, clientRecordId: string, body: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/prospect/${clientRecordId}`,
    body
  ) as Promise<BentResponse>;

export const patchCloseProspectClientsRecord = (token: string, clientRecordId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/prospect/${clientRecordId}:close`
  ) as Promise<BentResponse>;

export const patchConvertProspectClientsRecord = (token: string, clientRecordId: string, body: Record<string, any>) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/prospect/${clientRecordId}:convert`,
    body
  ) as Promise<BentResponse>;
