/* eslint-disable complexity */
import { useState } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PaginationList from 'components/PaginationList/PaginationList';
import ActivePractitionerClientItem from './components/ActiveClientItem/ActivePractitionerClientItem';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { clientRecordsInterface, GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';

import styles from './ActivePractitionerClientList.module.scss';
import columnStyles from './ActivePractitionerClientColumn.module.scss';
import { CLIENT_LIST_SORT_INFO, SortBy } from 'pages/Patient/Patient';
import { useTranslation } from 'react-i18next';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface ActivePractitionerClientListProps {
  clientRecordList: clientRecordsInterface[];
  isClientRecordListLoading: boolean;
  paging: GetClientProfileInterface['paging'];
  onResendInvitation: (recordId: string) => void;
  onCancelInvitation: (recordId: string) => void;
  recordIdProcessing: string;
  onPageChange: (paging: string, sortBy?: SortBy) => void;
  selectedStudentIds: string[];
  onSelectStudent: (studentId: string, isSelected: boolean) => void;
  onChangeTeacherBulkClick: () => void;
  onChangeWellBeingLeaderBulkClick: () => void;
}

const ActivePractitionerClientList = ({
  clientRecordList,
  isClientRecordListLoading,
  paging,
  onPageChange,
  selectedStudentIds,
  onSelectStudent,
  onChangeTeacherBulkClick,
  onChangeWellBeingLeaderBulkClick
}: ActivePractitionerClientListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { CLIENTS } = useRoutesGenerator();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const queryParam: {
    page?: string;
  } = queryString.parse(location.search);
  const savedSort = localStorage.getItem(CLIENT_LIST_SORT_INFO);
  const sort = savedSort ? (JSON.parse(savedSort) as SortBy) : {};
  const [sortBy, setSortBy] = useState<SortBy>({
    clientName: sort.clientName || sort.studentClass ? sort.clientName : 1,
    studentClass: sort.studentClass,
    latestAssessmentScore: sort.latestAssessmentScore
  });
  const [t] = useTranslation();

  const selectPatient = (recordId: string) => {
    const qParam = queryString.stringify(queryParam);
    navigate(`${CLIENTS.BASE}/${recordId}/profile${qParam ? `?${qParam}` : ''}`);
  };

  const handlePageChange = (paging: string, sortBy?: SortBy) => {
    onPageChange(paging, sortBy);
  };

  const onSortClientListByName = (sortType?: SortType) => {
    const newSort = {
      clientName: sortType || -1 * (sortBy.clientName || 1)
    };
    localStorage.setItem(CLIENT_LIST_SORT_INFO, JSON.stringify(newSort));
    setSortBy(newSort);
    handlePageChange(paging.page.toString(), newSort);
  };

  const onSortClientListByClass = (sortType?: SortType) => {
    const newSort = {
      studentClass: sortType || -1 * (sortBy.studentClass || 1)
    };
    localStorage.setItem(CLIENT_LIST_SORT_INFO, JSON.stringify(newSort));
    setSortBy(newSort);
    handlePageChange(paging.page.toString(), newSort);
  };

  return (
    <>
      {isClientRecordListLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : clientRecordList.length > 0 ? (
        <>
          {selectedStudentIds.length > 0 && (
            <div className={styles.bulkButtonWrapper}>
              <Button className={styles.bulkButton} variant={'primary'} onClick={onChangeTeacherBulkClick}>
                Change Assessor
              </Button>
              {isEdgeAdminView && (
                <Button className={styles.bulkButton} variant={'primary'} onClick={onChangeWellBeingLeaderBulkClick}>
                  Change Wellbeing leader
                </Button>
              )}
            </div>
          )}
          <div className={styles.filterContainer}>
            <div className={columnStyles.studentCheckBoxSection} />
            <div
              className={classNames(
                columnStyles.nameSection,
                columnStyles.sortHeader,
                sortBy.clientName && columnStyles.sortHeaderActive
              )}
              onClick={() => onSortClientListByName()}
            >
              Name
              <SortBtn
                selectedColumn={sortBy.clientName ? 'name' : ''}
                id="clientName"
                columnName="name"
                onChangeSort={() => {}}
                selectedSortName={sortBy.clientName}
                filledArrow
                showOnlyActiveArrow
                onlyVisibleForSelectedColumn={false}
              />
            </div>
            <div
              className={classNames(
                columnStyles.classSection,
                columnStyles.sortHeader,
                sortBy.studentClass && columnStyles.sortHeaderActive
              )}
              onClick={() => onSortClientListByClass()}
            >
              Class
              <SortBtn
                selectedColumn={sortBy.studentClass ? 'studentClass' : ''}
                id="clientName"
                columnName="studentClass"
                onChangeSort={() => {}}
                selectedSortName={sortBy.studentClass}
                filledArrow
                showOnlyActiveArrow
                onlyVisibleForSelectedColumn={false}
              />
            </div>
            <div className={columnStyles.tAssignedSection}>Teacher Assigned</div>
            <div className={columnStyles.tAssignedSection}>Assessor Assigned</div>
            <div className={columnStyles.groupsSection}>Groups</div>
            <div className={columnStyles.interventionSection}>Interventions</div>
            <div className={columnStyles.actionPlanSection}>Active Action Plans</div>
            <div className={columnStyles.lastScoreSection}>Latest Assessment Score</div>
            <div className={columnStyles.buttonSection} />
          </div>
          {clientRecordList.map((clientListObj, index) => {
            return (
              <ActivePractitionerClientItem
                key={index}
                clientRecord={clientListObj}
                onSelectClient={() => selectPatient(clientListObj._id)}
                selectedStudentIds={selectedStudentIds}
                onSelectStudent={onSelectStudent}
              />
            );
          })}
          {paging.totalItem > paging.perPage && (
            <div className={styles.paginationWrapper}>
              <PaginationList paging={paging} onPageChange={(paging) => handlePageChange(paging, sortBy)} />
            </div>
          )}
        </>
      ) : (
        <div>{t('label.no_client_record_found')}</div>
      )}
    </>
  );
};

export default ActivePractitionerClientList;
