import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import styles from './NoAuthLayout.module.scss';

interface NoAuthLayoutProps {
  children?: any;
}

const NoAuthLayout = ({ children }: NoAuthLayoutProps) => {
  return (
    <div className={styles.container}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default NoAuthLayout;
