import { useState } from 'react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { CaseNoteFormatSettings } from '../../../../PatientDetailsNotes';

import Button from 'components/Button/Button';
import PatientDetailsNoteFormatSettingsFieldsEditor from './components/PatientDetailsNoteFormatSettingsFieldsEditor/PatientDetailsNoteFormatSettingsFieldsEditor';

import styles from './PatientDetailsNoteFormatSettingsFields.module.scss';

const DEFAULT_FIELD: CaseNoteFormatSettings['fields'][number] = {
  name: 'New Field',
  type: 'text',
  active: true,
  editable: true,
  mandatory: false
};

const QUESTION_TYPE_TEXT: Record<string, string | undefined> = {
  date: 'Date',
  multipleChoice: 'Multiple choice',
  multipleChoiceCount: 'Multiple choice with count',
  number: 'Number',
  text: 'Text',
  time: 'Time'
};

const PatientDetailsNoteFormatSettingsFields = () => {
  const { values, setFieldValue } = useFormikContext<CaseNoteFormatSettings>();

  const [selectedField, setSelectedField] = useState<number>();

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (index: number) => {
    setIsEditing(true);
    setSelectedField(index);
  };

  const handleEditFinish = (index: number) => (newField?: CaseNoteFormatSettings['fields'][number]) => {
    newField && setFieldValue(`fields.${index}`, newField);

    setIsEditing(false);
    setSelectedField(undefined);
  };

  return isEditing && !isNaN(Number(selectedField)) ? (
    <PatientDetailsNoteFormatSettingsFieldsEditor
      selectedField={values.fields[selectedField as number] || DEFAULT_FIELD}
      onEditFinish={handleEditFinish(selectedField as number)}
    />
  ) : (
    <div className={styles.container}>
      <div className={classnames(styles.fieldContainer, styles.disabled)}>
        <label className={styles.checkboxContainer} htmlFor="checkbox-formatSettingsField-noteTitle">
          <input
            className={styles.checkbox}
            id="checkbox-formatSettingsField-noteTitle"
            type="checkbox"
            checked
            onChange={() => {}}
            disabled
          />
          <div className={classnames(styles.text, styles.active)}>
            <div className={styles.label}>Note Title</div>
            <div className={styles.description}>Text</div>
          </div>
        </label>
        <Button className={classnames(styles.button, styles.active)}>
          <FontAwesomeIcon className={styles.starIcon} icon={faAsterisk} />
        </Button>
      </div>

      {values.fields.map((field, index) => (
        <div className={styles.fieldContainer} key={index}>
          <label className={styles.checkboxContainer} htmlFor={`checkbox-formatSettingsField-${index}`}>
            <input
              className={styles.checkbox}
              id={`checkbox-formatSettingsField-${index}`}
              type="checkbox"
              value={index}
              checked={field.active}
              onChange={(e) => setFieldValue(`fields.${index}.active`, e.target.checked)}
            />
            <div className={classnames(styles.text, field.active && styles.active)}>
              <div className={styles.label}>
                {field.name === 'Safeguarding Alert' && process.env.REACT_APP_GAE_REGION === 'au'
                  ? 'Crisis issue identified?'
                  : field.name}
              </div>
              {field.active && <div className={styles.description}>{QUESTION_TYPE_TEXT[field.type]}</div>}
            </div>
          </label>
          {field.active && (
            <>
              <Button
                className={classnames(styles.button, field.mandatory && styles.active)}
                onClick={() => setFieldValue(`fields.${index}.mandatory`, !field.mandatory)}
              >
                <FontAwesomeIcon className={styles.starIcon} icon={faAsterisk} />
              </Button>
              {field.editable && (
                <Button className={styles.button} onClick={() => handleEditClick(index)}>
                  <i className={`material-icons-outlined ${styles.editIcon}`}>edit</i>
                </Button>
              )}
            </>
          )}
        </div>
      ))}
      <div className={styles.createFieldWrapper}>
        <div className={styles.createFieldBtn} onClick={() => handleEditClick(values.fields.length)}>
          <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
          Create Field
        </div>
      </div>
    </div>
  );
};

export default PatientDetailsNoteFormatSettingsFields;
