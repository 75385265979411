import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ControlPanelSidebar.module.scss';
import { useNavigate } from 'react-router-dom';

interface ControlPanelSidebarProps {
  controlPanelTab?: string;
  aboutYouPage: boolean;
  medicarePage: boolean;
  workingSchedulePage: boolean;
  practiceProfilePage: boolean;
  systemConnectionPage: boolean;
  helmControlPage?: boolean;
  misConnectionPage?: boolean;
}

const ControlPanelSidebar = ({
  controlPanelTab,
  aboutYouPage,
  medicarePage,
  workingSchedulePage,
  practiceProfilePage,
  systemConnectionPage,
  helmControlPage,
  misConnectionPage
}: ControlPanelSidebarProps) => {
  const navigate = useNavigate();
  const { CONTROL_PANEL } = useRoutesGenerator();
  const onClickMenu = (id: string) => {
    navigate(`${CONTROL_PANEL.BASE}/${id}`);
  };

  const sideMenuList = [
    {
      id: 'about-you',
      icon: 'manage_accounts',
      label: 'About You',
      desc: 'Your profile',
      isActive: false,
      isDisplay: aboutYouPage
    },
    {
      id: 'helm-control',
      icon: 'web',
      label: 'Helm Public Profile',
      desc: 'Your visible information',
      isActive: false,
      isDisplay: helmControlPage
    },
    {
      id: 'school',
      icon: 'domain',
      label: 'School Information',
      desc: 'Details on your school',
      isActive: false,
      isDisplay: practiceProfilePage
    },
    {
      id: 'medicare',
      icon: 'cases',
      label: 'Medicare',
      desc: 'Add / update Medicare Info',
      isActive: false,
      isDisplay: medicarePage
    },
    {
      id: 'schedule',
      icon: 'pending_actions',
      label: 'Working Schedule',
      desc: 'Set up and manage',
      isActive: false,
      isDisplay: workingSchedulePage
    },
    {
      id: 'mis-connection',
      icon: 'cable',
      label: 'MIS Connection',
      desc: 'Import student and teacher data',
      isActive: false,
      isDisplay: misConnectionPage
    }
  ];

  const mapUrlWithBtn = (controlPanelTab?: string) => {
    const menuList = sideMenuList.filter((obj) => obj.isDisplay);
    return menuList
      .filter((obj) => !!obj)
      .map((obj) => {
        const newSideMenu = { ...obj };

        if (controlPanelTab === obj.id || (!controlPanelTab && obj.id === 'about-you')) {
          newSideMenu.isActive = true;
        }
        return newSideMenu;
      });
  };

  const sideMenu = mapUrlWithBtn(controlPanelTab);

  return (
    <div className={sideMenu.length > 0 ? styles.container : ''}>
      {sideMenu.map((obj: any, index: number) => (
        <div
          className={obj.isActive ? styles.menuCardActive : styles.menuCard}
          key={index}
          onClick={() => onClickMenu(obj.id)}
          id={obj.id}
        >
          <i className={`${obj.isActive ? 'material-icons' : 'material-icons-outlined'} ${styles.menuIcon}`}>
            {obj.icon}
          </i>
          <div className={styles.labelWrapper}>
            <div className={styles.label}>{obj.label}</div>
            <div className={styles.desc}>{obj.desc}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ControlPanelSidebar;
