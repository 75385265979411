import { useEffect, useState } from 'react';

import styles from './ProfileSelect.module.scss';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { CheckBoxListing } from 'components/AddPatientModalV2/AddPatientModalV2Interface';

interface ProfileSelectProps {
  id: string;
  value: string;
  checkValidation: boolean;
  profileList: CheckBoxListing[];
  isListLoading: boolean;
  selectClassName?: string;
  onChangeConsentValue: (b: string) => void;
}

const ProfileSelect = ({
  id,
  value,
  checkValidation,
  profileList,
  isListLoading,
  selectClassName,
  onChangeConsentValue
}: ProfileSelectProps) => {
  const [isShowError, setShowError] = useState(checkValidation && !value);

  useEffect(() => {
    if (checkValidation) {
      setShowError(checkValidation && !value);
    }
  }, [checkValidation, value]);

  const handleConsentChange = (val: string) => {
    onChangeConsentValue(val);
  };
  return (
    <div className={selectClassName}>
      <MaterialSelect
        id={`profile${id}`}
        label={'Select recipient'}
        isSearchable={false}
        options={profileList}
        value={value}
        onChange={(val: string) => handleConsentChange(val)}
        loading={isListLoading}
      />
      <ErrorMessage
        className={styles.error}
        error={'Please select recipient'}
        visible={checkValidation && isShowError}
      />
    </div>
  );
};

export default ProfileSelect;
