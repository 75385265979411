import { ReportTemplateWidgetId } from 'interfaces/Reports/reportTemplate';

const DEFAULT_ASSESSMENT_CHART = {
  ranges: [],
  scales: [
    {
      label: 'Mild',
      min: 0,
      max: 3
    },
    {
      label: 'Moderate',
      min: 4,
      max: 6
    },
    {
      label: 'Severe',
      min: 7,
      max: 9
    }
  ],
  total: 5
};

export const MOST_RECENT_ASSESSMENT = {
  id: 'Most Recent Psychometric',
  label: 'Recent Snapshot',
  widgetId: ReportTemplateWidgetId.RecentPsychometric,
  charts: [DEFAULT_ASSESSMENT_CHART]
};

export const MOST_EARLY_ASSESSMENT = {
  id: 'Earliest Psychometric',
  label: 'Earliest Snapshot',
  widgetId: ReportTemplateWidgetId.EarliestPsychometric,
  charts: [DEFAULT_ASSESSMENT_CHART]
};
