import { notification } from 'antd';
import { ClinicianMedicareRole, ProfileInterface } from 'interfaces/Profile/Profile';
import { useCallback, useEffect, useState } from 'react';
import { getClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';

export const useFetchClinicianMedicareRole = (token: string) => {
  const [role, setRole] = useState<ClinicianMedicareRole>();
  const [isRoleLoading, setIsRoleLoading] = useState(true);

  const fetchClinicianMedicareRole = useCallback(async (token) => {
    setIsRoleLoading(true);
    try {
      const getClinicianProfileResponse = await getClinicianProfile(token);
      const { medicare } = (await getClinicianProfileResponse.json()) as ProfileInterface;

      if (medicare) {
        setRole(medicare.role);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get locations' });
    }
    setIsRoleLoading(false);
  }, []);

  const refetchClinicianMedicareRole = () => {
    fetchClinicianMedicareRole(token);
  };

  useEffect(() => {
    if (token) {
      fetchClinicianMedicareRole(token);
    }
  }, [token, fetchClinicianMedicareRole]);

  return { role, isRoleLoading, refetchClinicianMedicareRole };
};
