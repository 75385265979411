import { useMemo, useState } from 'react';
import styles from './PractitionerCardItem.module.scss';
import columnStyles from '../../PractitionerRowCard.module.scss';
import { getUserRoles, PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import PractitionerCaseLoad from './components/PractitionerCaseLoad/PractitionerCaseLoad';
import PractitionerListAction from './components/PractitionerListAction/PractitionerListAction';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PractitionerAssessments from './components/PractitionerAssessments/PractitionerAssessments';
import PractitionerOutstandingAssessmentsModal from './components/PractitionerOutstandingAssessmentsModal/PractitionerOutstandingAssessmentsModal';
import useGetAdhocAssessmentCountsByClinicianId from 'pages/PractitionersListing/hooks/useGetAdhocAssessmentCountsByClinicianId';

interface PatientProps {
  id: number;
  PractitionerData: PractitionersDetailsInterface;
  onDeactivatePractitioner: (id: string) => void;
  onChangePractitionerRole: () => void;
  lastItem: boolean;
  accountId: string;
  token: string;
}

const PractitionerCardItem = ({
  id,
  PractitionerData,
  onDeactivatePractitioner,
  onChangePractitionerRole,
  lastItem,
  accountId,
  token
}: PatientProps) => {
  const { isLoading: isLoadingClinicalAssessmentCounts, assessmentCounts } = useGetAdhocAssessmentCountsByClinicianId({
    accountId,
    clinicianId: PractitionerData._id,
    token
  });

  const [openModal, setOpenModal] = useState(false);

  const handleCopyContent = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      notification.success({
        message: 'Copied!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const [t] = useTranslation();

  const USER_ROLES = useMemo(() => getUserRoles(t), [t]);

  return (
    <>
      <div className={styles.container}>
        <div className={columnStyles.profileSection}>
          <ClinicianAvatar avatarUrl={PractitionerData.avatar} name={PractitionerData.name} />
        </div>
        <div className={columnStyles.roleSection}>
          <div className={styles.value}>{USER_ROLES[PractitionerData.accessRight]}</div>
        </div>
        <div className={columnStyles.mobileSection}>
          <div className={styles.contentValueWrapper}>
            {PractitionerData.mobileNumber ? (
              <>
                <div className={styles.value}>{PractitionerData.mobileNumber}</div>
                <div className={styles.copyBtn} onClick={() => handleCopyContent(PractitionerData.mobileNumber)}>
                  <i className={`material-icons ${styles.icon}`}>content_copy</i>
                </div>
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className={columnStyles.emailSection}>
          <div className={styles.contentValueWrapper}>
            <div className={styles.value}>{PractitionerData.email}</div>
            <div className={styles.copyBtn} onClick={() => handleCopyContent(PractitionerData.email)}>
              <i className={`material-icons ${styles.icon}`}>content_copy</i>
            </div>
          </div>
        </div>
        {/* <div className={columnStyles.scheduleSection}>
        {PractitionerData.workingSchedule ? (
          <PractitionerSchedule id={id} workingSchedule={PractitionerData.workingSchedule} />
        ) : (
          <div>Working schedule not set.</div>
        )}
      </div> */}
        <div className={classnames(columnStyles.caseLoadSection, styles.caseLoadContainer)}>
          <PractitionerCaseLoad caseLoadData={PractitionerData.caseLoad} />
        </div>
        <div className={classnames(columnStyles.caseLoadSection, styles.caseLoadContainer)}>
          <PractitionerAssessments assessmentCounts={assessmentCounts} isLoading={isLoadingClinicalAssessmentCounts} />
        </div>
        <div className={columnStyles.buttonSection}>
          {PractitionerData.status === 'active' && (
            <PractitionerListAction
              id={PractitionerData._id}
              practitionerRole={PractitionerData.accessRight}
              onDeactivatePractitioner={onDeactivatePractitioner}
              onChangePractitionerRole={onChangePractitionerRole}
              practitionerName={PractitionerData.name}
              lastItem={lastItem}
              showOutstandingAssessmentButton={assessmentCounts.pending > 0}
              handleOpenOutstandingAssessmentsModal={() => setOpenModal(true)}
              email={PractitionerData.email}
              mobileNumber={PractitionerData.mobileNumber}
            />
          )}
        </div>
      </div>
      {PractitionerData.status === 'active' && (
        <PractitionerOutstandingAssessmentsModal
          accountId={accountId}
          practitioner={PractitionerData}
          outstandingAssessmentCounts={assessmentCounts.pending}
          visible={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default PractitionerCardItem;
