import { useState } from 'react';
import { message as antdMessage, notification } from 'antd';

import { AppointmentRequest } from '../../../../Notification';

import { deleteAppointmentRequest, postAcceptAppointmentRequest } from 'utils/http/appointment';

import Button from 'components/Button/Button';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import LoadingButton from 'components/v2/Button/Button';
import ChangeNote from '../ChangeNote/ChangeNote';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import styles from './AppointmentRequest.module.scss';
import { getName } from 'utils/general';
import AppointmentTypeSelect from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { Formik } from 'formik';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import FormSection from 'components/v2/FormSection/FormSection';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import RoomOption from 'pages/Calendar/components/Modals/EventCreationModal/components/EventForm/components/ScheduleForm/components/RoomOption/RoomOption';
import { CreatedAppointmentType, DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';

interface AppointmentRequestProps {
  appointmentRequest: AppointmentRequest;
  token: string;
  onRefreshNotifications: () => void;
}

export interface AppointmentValues {
  deliveryType: string;
  faceToFaceLocation: string;
  videoCallInstructions: string;
  phoneCallInstructions: string;
  roomInfo: {
    roomId: string;
    roomName: string;
  };
}

const AppointmentRequestComponent = ({
  appointmentRequest: { _id, clientRecord, message, clinicianId },
  token,
  onRefreshNotifications
}: AppointmentRequestProps) => {
  const [note, setNote] = useState('');
  const { isEdgeUser } = useGetAccountPackageView();

  const [appointmentType, setAppointmentType] = useState<CreatedAppointmentType>();
  const [selectedSlot, setSelectedSlot] = useState<SelectedAppointmentSlot>();
  const [showSelectedSlotError, setShowSelectedSlotError] = useState(false);
  const [declineButtonState, setDeclineButtonState] = useState<'' | 'active' | 'finished'>('');
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');
  const [showChangeNote, setShowChangeNote] = useState(false);
  const [showScheduleAppointment, setShowScheduleAppointment] = useState(false);

  const handleSubmitAppointment = async (values: AppointmentValues) => {
    setSubmitButtonState('active');
    if (!selectedSlot?.startTime || !selectedSlot?.endTime) {
      setShowSelectedSlotError(true);
      setSubmitButtonState('');
      return;
    }

    const { deliveryType, faceToFaceLocation, videoCallInstructions, phoneCallInstructions, roomInfo } = values;

    try {
      const payload = {
        slot: {
          ...selectedSlot,
          faceToFaceLocation: deliveryType === DeliveryType.FaceToFace ? faceToFaceLocation : undefined,
          videoCallInstructions: deliveryType === DeliveryType.VideoCall ? videoCallInstructions : undefined,
          phoneCallInstructions: deliveryType === DeliveryType.PhoneCall ? phoneCallInstructions : undefined
        },
        deliveryType,
        room: roomInfo
      };

      await postAcceptAppointmentRequest(_id, payload, token);
      setSubmitButtonState('finished');
      onRefreshNotifications();
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to update this appointment request. Please try again.'
      });
      setSubmitButtonState('');
    }
  };

  const handleDeclineAppointmentRequest = async () => {
    setDeclineButtonState('active');

    try {
      await deleteAppointmentRequest(_id, { note }, token);
      setDeclineButtonState('finished');
      onRefreshNotifications();
    } catch (ex) {
      antdMessage.error('Something went wrong while trying to decline this appointment request');
      setDeclineButtonState('');
    }
  };

  const handleChangeSlot = (slot?: SelectedAppointmentSlot) => {
    setSelectedSlot(slot);
    setShowSelectedSlotError(false);
  };

  return (
    <Formik
      initialValues={{
        deliveryType: '',
        faceToFaceLocation: '',
        videoCallInstructions: '',
        phoneCallInstructions: '',
        roomRequired: '',
        roomInfo: {
          roomId: '',
          roomName: ''
        }
      }}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <>
          <div className={styles.container}>
            <div className={styles.avatar}>
              <ClientProfileAvatar
                avatarUrl={clientRecord.clientProfiles[0].avatar}
                initialsName={clientRecord.clientProfiles[0].initials}
              />
            </div>
            <span className={styles.name}>{getName(clientRecord.clientProfiles[0])}</span>
            <div className={styles.message}>
              <div className={styles.title}>MESSAGE</div>
              <div className={styles.text}>{message}</div>
            </div>
            <Button
              className={styles.scheduleAppointmentButton}
              onClick={() => setShowScheduleAppointment(!showScheduleAppointment)}
            >
              <i className={'material-icons'}>insert_invitation</i>
              <div>Schedule Appointment</div>
            </Button>
            <Button className={styles.declineButton} onClick={() => setShowChangeNote(!showChangeNote)}>
              Decline
              <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
            </Button>
          </div>
          {showChangeNote && (
            <>
              <ChangeNote note={note} onNoteChange={setNote} />
              <LoadingButton
                className={styles.submitButton}
                status={declineButtonState}
                onClick={handleDeclineAppointmentRequest}
              >
                Decline Appointment
              </LoadingButton>
            </>
          )}
          {showScheduleAppointment && (
            <div className={styles.scheduleContainer}>
              <AppointmentTypeSelect
                clinicianId={clinicianId || ''}
                hideTitle
                horizontal
                onSelect={setAppointmentType}
                selectedParticipantType={appointmentType?.participantType || ParticipantType.OneToOne}
              />
              {appointmentType && (
                <AvailableTimeSelect
                  appointmentTypeId={appointmentType._id}
                  onSelectSlot={handleChangeSlot}
                  selectedClinicianId={clinicianId || ''}
                  isEditing
                />
              )}
              <ErrorMessage error="Please select a timeslot for the appointment" visible={showSelectedSlotError} />
              {appointmentType?.deliveryOptions && (
                <div className={styles.deliveryModeContainer}>
                  <FormSection title="Delivery Mode">
                    <div className={styles.deliveryModeContainer}>
                      <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
                    </div>
                  </FormSection>
                </div>
              )}
              {isEdgeUser && appointmentType?.faceToFaceLocation && appointmentType && (
                <div className={styles.roomOptionContainer}>
                  <RoomOption appointmentType={appointmentType} />
                </div>
              )}
              <LoadingButton
                className={styles.submitButton}
                status={submitButtonState}
                onClick={() => handleSubmitAppointment(values)}
              >
                Confirm Appointment
              </LoadingButton>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

export default AppointmentRequestComponent;
