import { useCallback, useEffect, useState } from 'react';
import { getGroupSessionDetailsById } from 'utils/http/ScheduleService/Groups/groups';
import { GroupSessionDetails } from 'pages/Groups/Interfaces/GroupsSession';

export const useFetchGroupSessionDetails = (token: string, groupId: string, sessionId: string) => {
  const [groupSessionDetails, setGroupSessionDetails] = useState<GroupSessionDetails>();
  const [isGroupSessionDetailsLoading, setIsGroupSessionDetailsLoading] = useState(true);

  const fetchGroupDetails = useCallback(
    async (token: string) => {
      setIsGroupSessionDetailsLoading(true);

      const getGroupSessionData = await getGroupSessionDetailsById(token, groupId, sessionId);
      const groupSessionData = await getGroupSessionData.json();

      setGroupSessionDetails(groupSessionData);
      setIsGroupSessionDetailsLoading(false);
    },
    [groupId, sessionId]
  );

  useEffect(() => {
    if (token) {
      fetchGroupDetails(token);
    }
  }, [fetchGroupDetails, token]);

  return { groupSessionDetails, isGroupSessionDetailsLoading, fetchGroupDetails };
};
