import PillButtonGroup from 'components/v2/PIllButtonGroup/PIllButtonGroup';

const options = [
  { value: 'appointment', label: 'APPOINTMENT' },
  { value: 'bespoke', label: 'EVENT' }
];

type EventFormProps = {
  value: string;
  onChange: (value: string) => void;
};

const EventFormat = ({ value, onChange }: EventFormProps) => {
  const handleChange = (newValue: string) => {
    onChange(newValue);
  };
  return <PillButtonGroup options={options} value={value} onChange={handleChange} />;
};

export default EventFormat;
