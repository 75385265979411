import { notification } from 'antd';
import { FormListingInterface } from 'interfaces/Form/FormInterface';
import useSetDefault from 'pages/Assessments/hooks/useSetDefault';
import { useState } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { patchFirstAssessmentSetDefault } from 'utils/http/CheckInService/Assessment/firstAssessment';
import {
  deleteCaseNoteTemplate,
  patchCaseNoteTemplateSetDefault,
  patchTogglePublishCaseNoteTemplate
} from 'utils/http/DocumentService/CaseNotes/caseNotesTemplate';
import { patchConsentFormSetDefault } from 'utils/http/DocumentService/ConsentForm/consentForm';

import styles from './AssessmentContent.module.scss';
import ADraftCard from './components/ADraftCard/ADraftCard';
import AssessmentColumnBase from './components/AssessmentColumnBase/AssessmentColumnBase';
import CardList from './components/ListCard/CardList';

interface AssessmentContentProps {
  formListData: FormListingInterface[];
}

const AssessmentContent = ({ formListData }: AssessmentContentProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();

  const { viewCaseNotesTemplateFeature } = useGetFeatureToggle();

  // unpublish/delete loading states
  const [caseNoteCardLoading, setCaseNoteCardLoading] = useState('');

  // filtered formListData based on columns ----------------------
  const [surveyDataList, setSurveyDataList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'survey')
  );
  const [consentList, setConsentList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'consent')
  );
  const [caseNotesTemplatesList, setCaseNotesTemplatesList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'caseNotes')
  );

  const microCheckInDataList = formListData.filter((obj) => obj.isPublished && obj.formType === 'microCheckIn');

  const [nonActiveData, setNonActiveData] = useState(() =>
    formListData.filter((obj) => {
      if (isEdgeAdminView) {
        return (
          !obj.isPublished && (obj.formType === 'survey' || obj.formType === 'consent' || obj.formType === 'caseNotes')
        );
      }
      if (isEdgeUserView) {
        return !obj.isPublished && (obj.formType === 'microCheckIn' || obj.formType === 'consent');
      }
      return !obj.isPublished;
    })
  );

  // setDefault hooks --------------------------
  const { loading: oBDefaultLoading, onSetDefault: onBoardingSetDefault } = useSetDefault({
    listState: surveyDataList,
    setListState: setSurveyDataList,
    patchSetDefault: patchFirstAssessmentSetDefault,
    onSuccess: 'Survey updated.',
    onFail: 'Survey update fail.'
  });

  const { loading: consentDefaultLoading, onSetDefault: consentFormSetDefault } = useSetDefault({
    listState: consentList,
    setListState: setConsentList,
    patchSetDefault: patchConsentFormSetDefault,
    onSuccess: 'Consent form updated.',
    onFail: 'Consent form update fail.'
  });

  const { loading: caseNotesTemplateDefaultLoading, onSetDefault: caseNotesTemplateSetDefault } = useSetDefault({
    listState: caseNotesTemplatesList,
    setListState: setCaseNotesTemplatesList,
    patchSetDefault: patchCaseNoteTemplateSetDefault,
    onSuccess: 'Case note template updated.',
    onFail: 'Case note template update fail.'
  });

  // onDelete handlers ----------------------
  const caseNotesTemplateDelete = async (id: string) => {
    setCaseNoteCardLoading(id);
    try {
      await deleteCaseNoteTemplate(token, id);
      setCaseNotesTemplatesList((list) => list.filter((obj) => obj._id !== id));
      setCaseNoteCardLoading('');
      notification.success({
        message: 'Case note template deleted.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      setCaseNoteCardLoading('');
      notification.error({
        message: 'Fail to delete the case note template.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  // onUnpublish handlers -------------------
  const caseNotesTemplateUnpublish = async (id: string) => {
    setCaseNoteCardLoading(id);
    const unpublishForm = formListData.find((obj) => obj._id === id)!;
    try {
      await patchTogglePublishCaseNoteTemplate(token, id);
      setCaseNotesTemplatesList((list) => list.filter((obj) => obj._id !== id));
      if (isEdgeAdminView) {
        setNonActiveData((list) => [...list, unpublishForm]);
      }
      setCaseNoteCardLoading('');
      notification.success({
        message: 'Case note template updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      setCaseNoteCardLoading('');
      notification.error({
        message: 'Fail to unpublish the case note template',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const showMCI = false;

  return (
    <div className={styles.container}>
      <AssessmentColumnBase
        name={'Survey'}
        columnType={'active'}
        data={surveyDataList}
        viewOnly={isEdgeUserView}
        id={'columnSurveyId'}
      >
        <CardList
          columnId="survey"
          data={surveyDataList}
          onSetDefault={onBoardingSetDefault}
          loadingId={oBDefaultLoading}
          viewOnly={isEdgeUserView}
        />
      </AssessmentColumnBase>

      <AssessmentColumnBase
        name={'Consent Forms'}
        columnType={'active'}
        data={consentList}
        viewOnly={isEdgeUserView}
        id={'columnConsentFormId'}
      >
        <CardList
          columnId="consent"
          data={consentList}
          onSetDefault={consentFormSetDefault}
          loadingId={consentDefaultLoading}
          viewOnly={isEdgeUserView}
        />
      </AssessmentColumnBase>
      {showMCI && (
        <AssessmentColumnBase
          name={'Micro Check-ins'}
          columnType={'active'}
          data={microCheckInDataList}
          id={'columnMCIId'}
        >
          <CardList columnId="microCheckIn" data={microCheckInDataList} />
        </AssessmentColumnBase>
      )}
      {viewCaseNotesTemplateFeature && (
        <AssessmentColumnBase
          name={'Case Notes'}
          columnType={'active'}
          data={caseNotesTemplatesList}
          id={'columnCaseNoteTemplateId'}
        >
          <CardList
            columnId="caseNotes"
            data={caseNotesTemplatesList}
            onSetDefault={caseNotesTemplateSetDefault}
            loadingId={caseNotesTemplateDefaultLoading}
            viewOnly={isEdgeUserView}
            onUnpublish={caseNotesTemplateUnpublish}
            onDelete={caseNotesTemplateDelete}
            circleLoadingId={caseNoteCardLoading}
          />
        </AssessmentColumnBase>
      )}
      {isEdgeAdminView && (
        <AssessmentColumnBase
          name={'Draft & Inactive'}
          columnType={'draft'}
          data={nonActiveData}
          showCreateCard
          id={'columnDAndIId'}
        >
          <ADraftCard data={nonActiveData} />
        </AssessmentColumnBase>
      )}
    </div>
  );
};

export default AssessmentContent;
