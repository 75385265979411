import styles from './SideBar.module.scss';
import ShareReportBox from '../../../PublishNShareSideBox/ShareReportBox/ShareReportBox';
import SelectGroupOrClientModalBox from 'components/SelectClient/SelectGroupOrClientModalBox/SelectGroupOrClientModalBox';
import PreviewShareReportBox from '../../../PublishNShareSideBox/PreviewShareReportBox/PreviewShareReportBox';
import { Report } from 'interfaces/Reports/report';

interface SideBarProps {
  data: Report;
  token: string;
  sendInvoiceButtonStatus: '' | 'active' | 'finished';
  onSendReport: (shareDetails: { sendAt: Date; sendClient: boolean; sendTo?: string[]; note?: string }) => void;
  onSaveReport: () => void;
}

const SideBar = ({ data, token, sendInvoiceButtonStatus, onSendReport, onSaveReport }: SideBarProps) => {
  return (
    <div className={styles.container}>
      <SelectGroupOrClientModalBox clientRecord={data.clientRecord} />
      {data.status === 'published' ? (
        <PreviewShareReportBox
          data={data}
          token={token}
          onSendReport={onSendReport}
          sendInvoiceButtonStatus={sendInvoiceButtonStatus}
        />
      ) : (
        <ShareReportBox
          clientRecord={data.clientRecord}
          recipientList={data.shareDetails.accessors}
          label={'Report'}
          onSaveReport={onSaveReport}
          sendInvoiceButtonStatus={sendInvoiceButtonStatus}
          onSendReport={onSendReport}
        />
      )}
    </div>
  );
};

export default SideBar;
