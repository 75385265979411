import { capitalize } from 'lodash';
import { TransactionsResponse } from 'pages/ControlPanel/Interfaces/CodatResponses';
import { Integration } from 'pages/ControlPanel/Interfaces/Integration';
import { useTranslation } from 'react-i18next';

import { TransactionFilter } from '../../IntegrationHistory';
import HistoryRow from './components/HistoryRow/HistoryRow';
import styles from './HistoryTable.module.scss';

interface HistoryTableProps {
  codatTransactions: TransactionsResponse[];
  integration: Integration;
  isRetryingTransaction: boolean;
  callPostRetryTransactions: (id: string) => void;
  filter: TransactionFilter;
}

const HistoryTable = ({
  codatTransactions,
  integration: { label, provider },
  isRetryingTransaction,
  callPostRetryTransactions,
  filter
}: HistoryTableProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerItem}>INVOICE ID</div>
        <div className={styles.headerItem}>{`${label.toUpperCase()} ID`}</div>
        <div className={styles.headerItem}>{t('form.client_name').toUpperCase()}</div>
        <div className={styles.headerItem}>AMOUNT</div>
        <div className={styles.headerItem}>DATE</div>
        <div className={styles.headerItem}>STATUS</div>
        <div className={styles.headerItem} />
      </div>
      {codatTransactions.length > 0 ? (
        codatTransactions.map((transaction, index) => {
          const {
            _id,
            data: { invoiceId, invoiceRefId, integrationRefId, clientName, amount, codatId },
            createdAt,
            status,
            errorMessage
          } = transaction;
          const data = {
            _id,
            invoiceId,
            invoiceRefId,
            providerId: integrationRefId,
            clientName,
            amount,
            date: createdAt,
            status,
            error: errorMessage,
            codatId
          };
          return (
            <HistoryRow
              key={index}
              data={data}
              callPostRetryTransactions={callPostRetryTransactions}
              isRetryingTransaction={isRetryingTransaction}
              provider={provider}
            />
          );
        })
      ) : (
        <div className={styles.message}>{`There are no transactions with ${capitalize(filter)} status.`}</div>
      )}
    </div>
  );
};

export default HistoryTable;
