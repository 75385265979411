import { useState } from 'react';
import styles from './ChangeWellBeingLeaderBulk.module.scss';
import { Modal, notification } from 'antd';
import Button from 'components/v2/Button/Button';
import { patchReassignClinicianToMultipleClients } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useTranslation } from 'react-i18next';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { AccessRight } from 'interfaces/Clients/clinician';

interface ChangeWellBeingLeaderBulkProps {
  selectedStudentIds: string[];
  visible: boolean;
  onCancel: () => void;
  onUpdateSuccess: () => void;
}

const ChangeWellBeingLeaderBulk = ({
  visible,
  selectedStudentIds,
  onCancel,
  onUpdateSuccess
}: ChangeWellBeingLeaderBulkProps) => {
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [selectedClinician, setSelectedClinician] = useState<PractitionersDetailsInterface | undefined>(undefined);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = async () => {
    setSubmitStatus('active');

    const payload = {
      clientRecordIds: selectedStudentIds,
      clinicianId: selectedClinician?._id || ''
    };

    try {
      await patchReassignClinicianToMultipleClients(token, accountId, payload);
      setSubmitStatus('finished');
      setTimeout(() => {
        notification.success({
          message: selectedClinician
            ? t('form.success.change_wellbeing_leader_bulk', {
                numOfStudent: selectedStudentIds.length.toString(),
                clinicianName: selectedClinician?.name.toString()
              })
            : t('form.success.change_blank_wellbeing_leader_bulk', {
                numOfStudent: selectedStudentIds.length.toString()
              }),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        setSelectedClinician(undefined);
        onUpdateSuccess();
        onCancel();
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: t('form.error.change_teacher_bulk'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleCancel = () => {
    setSelectedClinician(undefined);
    onCancel();
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.header}>Bulk update Wellbeing leader Assignments</div>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={515}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          Change
          <span className={styles.selectedCount}>{` ${selectedStudentIds.length} `}</span>
          students records to be assigned to:
        </div>
        <div className={styles.teacherAssignmentWrapper}>
          <div className={styles.description}>
            <div className={styles.main}>Wellbeing Leader Assignment</div>
            <div className={styles.sub}>Allocate who can access this student</div>
          </div>
          <div className={styles.teacherSelect}>
            <ClinicianSelect
              selectedId={selectedClinician?._id}
              profileBadgeClassname={styles.profileBadgeClassName}
              onSelect={(value) => {
                setSelectedClinician(value as PractitionersDetailsInterface);
              }}
              filterRoles={[AccessRight.Admin, AccessRight.User]}
              allowEmpty
            />
          </div>
        </div>
        <div className={styles.actions}>
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant={'primary'} className={styles.proceedBtn} status={submitStatus} onClick={handleSubmit}>
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeWellBeingLeaderBulk;
