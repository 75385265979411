import styles from './SessionItemList.module.scss';
import columnStyles from './SessionItemListColumn.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Skeleton } from 'antd';
import queryString from 'query-string';
import { DELIVERY_TYPE_LABELS, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { GroupSessionList, GroupSessionListFilterParameter } from 'pages/Groups/Interfaces/GroupsSession';
import moment from 'moment';
import FacilitatorProfile from './components/FacilitatorProfile/FacilitatorProfile';

interface SessionItemListProps {
  groupSessions: GroupSessionList;
  isGroupSessionsLoading: boolean;
  groupId: string;
  contentId: ParticipantType;
  searchQueryParam?: GroupSessionListFilterParameter;
}

const SessionItemList = ({
  groupSessions,
  isGroupSessionsLoading,
  groupId,
  contentId,
  searchQueryParam
}: SessionItemListProps) => {
  const { GROUPS } = useRoutesGenerator();

  const handleStorePath = () => {
    const qParam = queryString.stringify(searchQueryParam || {});
    const path = `${contentId}${qParam ? `?${qParam}` : ''}`;
    localStorage.setItem('groupSessionListPrevPath', path);
  };

  return (
    <div className={styles.container}>
      <div className={classnames(columnStyles.columnWrapper, styles.header)}>
        <div className={columnStyles.label}>LABEL</div>
        <div className={columnStyles.whenDate}>WHEN</div>
        <div className={columnStyles.whenTime} />
        <div className={columnStyles.where}>WHERE</div>
        <div className={columnStyles.who}>WHO</div>
        <div className={columnStyles.participating}>PARTICIPATING</div>
        <div className={columnStyles.action} />
      </div>
      {isGroupSessionsLoading ? (
        <>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))}
        </>
      ) : groupSessions.sessions.length > 0 ? (
        <>
          {groupSessions.sessions.map((sessionsObj, index) => {
            return (
              <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
                <div className={classnames(columnStyles.label, styles.sessionName)}>
                  {sessionsObj.groupDetails.label ? sessionsObj.groupDetails.label : '-'}
                </div>
                <div className={classnames(columnStyles.whenDate, styles.value)}>
                  {moment(sessionsObj.date).format('dddd D MMMM')}
                </div>
                <div className={classnames(columnStyles.whenTime, styles.value)}>
                  {moment(sessionsObj.startTime, 'HH:mm').format('hh:mm A')}
                  {' - '}
                  {moment(sessionsObj.endTime, 'HH:mm').format('hh:mm A')}
                </div>
                <div className={classnames(columnStyles.where, styles.value)}>
                  {DELIVERY_TYPE_LABELS[sessionsObj.deliveryType!]}
                </div>
                <div className={columnStyles.who}>
                  <FacilitatorProfile
                    profile={sessionsObj.groupDetails.deliveringPractitionerProfiles}
                    displayLimit={2}
                  />
                </div>
                <div className={classnames(columnStyles.participating, styles.value)}>
                  {sessionsObj.groupDetails.attendees.length}
                </div>
                <div className={columnStyles.action}>
                  <Link
                    to={`${GROUPS.BASE}/${groupId}/sessionDetail/${sessionsObj._id}?fromList=true`}
                    onClick={() => handleStorePath()}
                  >
                    <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
                  </Link>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.noFoundContainer}>No sessions found</div>
      )}
    </div>
  );
};

export default SessionItemList;
