import styles from './GroupStatusBadge.module.scss';
import { AttachedClientRecordStatus } from '../../pages/Groups/Interfaces/Groups';

interface GroupStatusBadgeProps {
  status: AttachedClientRecordStatus;
}

const GroupStatusBadge = ({ status }: GroupStatusBadgeProps) => {
  const generateType = (recordStatusType: AttachedClientRecordStatus) => {
    switch (recordStatusType) {
      case AttachedClientRecordStatus.Active:
        return {
          label: 'ACTIVE',
          badgeStyles: styles.activeBadge
        };
      case AttachedClientRecordStatus.Completed:
        return {
          label: 'COMPLETE',
          badgeStyles: styles.completeBadge
        };
      case AttachedClientRecordStatus.Archived:
        return {
          label: 'ARCHIVE',
          badgeStyles: styles.archiveBadge
        };
      default:
        console.error('Invalid group status');
        return {
          label: '',
          badgeStyles: ''
        };
    }
  };

  const { badgeStyles, label } = generateType(status);

  return (
    <div className={badgeStyles}>
      <div>{label}</div>
    </div>
  );
};

export default GroupStatusBadge;
