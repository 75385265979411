import MultipleChoiceWrapper from '../MultipleChoiceWrapper/MultipleChoiceWrapper';
import MultipleChoiceCountWrapper from '../MultipleChoiceCountWrapper/MultipleChoiceCountWrapper';
import NumberWrapper from '../NumberWrapper/NumberWrapper';
import DateWrapper from '../DateWrapper/DateWrapper';
import TimeWrapper from '../TimeWrapper/TimeWrapper';
import LinkWrapper from '../LinkWrapper/LinkWrapper';

import classnames from 'classnames';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

import { CaseNoteField } from 'interfaces/caseNote';
import styles from './CaseNoteFieldType.module.scss';

interface CaseNoteFieldTypeProps {
  recordId?: string;
  caseNoteFieldData: CaseNoteField;
  handleOnChangeFieldInput: (fieldId: string, val: string) => void;
  handleChangeSelectCount?: (id: string, val: any) => void;
  isValidated?: boolean;
  fullWidth?: boolean;
  isDisabled?: boolean;
  withPlaceholder?: boolean;
  menuPortalTarget?: string;
}

const timePlaceHolder = moment().format('hh:mm A');
const datePlaceHolder = moment().format(MOMENTJS_DATE_FORMAT);

const CaseNoteFieldType = ({
  recordId,
  caseNoteFieldData,
  handleOnChangeFieldInput,
  handleChangeSelectCount,
  isValidated,
  fullWidth,
  isDisabled,
  withPlaceholder,
  menuPortalTarget
}: CaseNoteFieldTypeProps) => {
  const fullWidthWidget = classnames(fullWidth && styles.fullWidth);
  const caseNoteField = () => {
    switch (caseNoteFieldData.type) {
      case 'multipleChoice':
        return (
          <MultipleChoiceWrapper
            field={caseNoteFieldData}
            isValidated={isValidated || false}
            onChangeFieldInput={handleOnChangeFieldInput}
            className={fullWidthWidget}
            placeholder={withPlaceholder ? 'Select from list' : ''}
            menuPortalTarget={menuPortalTarget}
            isDisabled={isDisabled}
          />
        );
      case 'multipleChoiceCount':
        return (
          handleChangeSelectCount && (
            <MultipleChoiceCountWrapper
              field={caseNoteFieldData}
              isValidated={
                (isValidated &&
                  caseNoteFieldData.mandatory &&
                  caseNoteFieldData.options.filter((countObj: any) => countObj.count > 0).length <= 0) ||
                false
              }
              onChangeFieldInput={handleChangeSelectCount}
              className={fullWidthWidget}
              isDisabled={isDisabled}
            />
          )
        );
      case 'text':
      case 'number':
        return (
          <NumberWrapper
            field={caseNoteFieldData}
            isValidated={isValidated || false}
            onChangeFieldInput={handleOnChangeFieldInput}
            className={fullWidthWidget}
            placeholder={withPlaceholder ? (caseNoteFieldData.type === 'number' ? '0' : 'Type something...') : ''}
            isDisabled={isDisabled}
          />
        );
      case 'date':
        return (
          <DateWrapper
            field={caseNoteFieldData}
            isValidated={isValidated || false}
            onChangeFieldInput={handleOnChangeFieldInput}
            className={fullWidthWidget}
            placeholder={withPlaceholder ? datePlaceHolder : ''}
            isDisabled={isDisabled}
          />
        );
      case 'time':
        return (
          <TimeWrapper
            field={caseNoteFieldData}
            isValidated={isValidated || false}
            onChangeFieldInput={handleOnChangeFieldInput}
            className={fullWidthWidget}
            placeholder={withPlaceholder ? timePlaceHolder : ''}
            isDisabled={isDisabled}
          />
        );
      case 'link':
        return (
          recordId && (
            <LinkWrapper
              recordId={recordId}
              field={caseNoteFieldData}
              isValidated={isValidated || false}
              onChangeFieldInput={handleOnChangeFieldInput}
              className={fullWidthWidget}
              isDisabled={isDisabled}
            />
          )
        );
      default:
        return (
          <div>
            {caseNoteFieldData.name} {caseNoteFieldData.type}
          </div>
        );
    }
  };

  return <>{caseNoteField()}</>;
};

export default CaseNoteFieldType;
