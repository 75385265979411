import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { SurveyFormInterface } from 'pages/Groups/Interfaces/GroupsSurvey';
import { getGroupAdHocSurveyClients } from 'utils/http/CheckInService/Assessment/groupAssessment';

export const useFetchAdHocSurveyClientList = (token: string, groupId: string, surveyId: string) => {
  const [groupSurveyClientList, setGroupSurveyClientList] = useState<SurveyFormInterface[]>([]);
  const [isSurveyFormListLoading, setIsSurveyFormListLoading] = useState(true);

  const fetchSurveyFormList = useCallback(
    async (token: string) => {
      if (surveyId) {
        try {
          setIsSurveyFormListLoading(true);

          const getAdHocSurveyFormClientData = await getGroupAdHocSurveyClients(token, groupId, surveyId);
          const surveyFormListData: SurveyFormInterface[] = await getAdHocSurveyFormClientData.json();

          setGroupSurveyClientList(surveyFormListData);
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to get your groups survey list' });
        }
      }
      setIsSurveyFormListLoading(false);
    },
    [groupId, surveyId]
  );

  useEffect(() => {
    if (token) {
      fetchSurveyFormList(token);
    }
  }, [fetchSurveyFormList, token]);

  return { groupSurveyClientList, isSurveyFormListLoading, fetchSurveyFormList };
};
