import { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { notification, Modal } from 'antd';
import SignaturePad from 'react-signature-canvas';
import ReactTooltip from 'react-tooltip';

import { putSignature } from 'utils/http/DocumentService/Reports/reports';

import Button from 'components/Button/Button';
import Radio from 'components/Radio/Radio';
import SubmitButton from 'components/v2/Button/Button';
import ExtraDetail from './components/ExtraDetail/ExtraDetail';
import MaterialInput from 'components/MaterialInput/MaterialInput';

import styles from './SignatureBox.module.scss';

export interface SignatureBoxProps {
  clinician?: {
    avatar: string;
    email: any;
    mobileNumber: any;
    name: string;
  };
  extraDetailsValue: {
    valediction: string;
    email: string;
    mobileNumber: string;
    name: string;
    other: string;
  };
  value: any;
  visible: boolean;
  onCloseSignature: any;
  onUpdateSignature: any;
}

const SignatureBox = ({
  clinician,
  extraDetailsValue,
  value,
  visible,
  onCloseSignature,
  onUpdateSignature
}: SignatureBoxProps) => {
  const { getAccessTokenSilently } = useAuth0();

  let sigCanvas = useRef<SignaturePad>(null);

  const [textSignatureVal, setTextSignatureVal] = useState({
    textVal: '',
    drawVal: ''
  });
  const [extraDetails, setExtraDetails] = useState({
    valediction: { value: '' },
    email: { active: false, value: '' },
    mobileNumber: { active: false, value: '' },
    name: { active: false, value: '' },
    other: { active: false, value: '' }
  });
  const [signVersion, setSignVersion] = useState(value.textVal || value.drawVal ? 'prevVer' : 'typeVer');
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');

  const extraDetailsValExist = Object.keys(extraDetailsValue || {}).length;

  useEffect(() => {
    if (extraDetailsValExist) {
      const extraDetails = {
        valediction: { value: '' },
        email: { active: false, value: '' },
        mobileNumber: { active: false, value: '' },
        name: { active: false, value: '' },
        other: { active: false, value: '' }
      };

      if (extraDetailsValue.valediction) {
        extraDetails.valediction = { value: extraDetailsValue.valediction };
      }

      if (extraDetailsValue.email) {
        extraDetails.email = { active: true, value: extraDetailsValue.email };
      } else {
        extraDetails.email.value = clinician?.email || '';
      }

      if (extraDetailsValue.mobileNumber) {
        extraDetails.mobileNumber = { active: true, value: extraDetailsValue.mobileNumber };
      } else {
        extraDetails.mobileNumber.value = clinician?.mobileNumber || '';
      }

      if (extraDetailsValue.name) {
        extraDetails.name = { active: true, value: extraDetailsValue.name };
      } else {
        extraDetails.name.value = clinician?.name || '';
      }

      if (extraDetailsValue.other) {
        extraDetails.other = { active: true, value: extraDetailsValue.other };
      }

      setExtraDetails(extraDetails);
    } else if (clinician) {
      setExtraDetails({
        valediction: { value: 'Kind Regards:' },
        email: { active: false, value: clinician.email },
        mobileNumber: { active: false, value: clinician.mobileNumber },
        name: { active: false, value: clinician.name },
        other: { active: false, value: '' }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinician, extraDetailsValExist]);

  const getSignVersionOptions = () => {
    const options = [
      { value: 'typeVer', label: 'Type Signature' },
      { value: 'drawVer', label: 'Draw with mouse or finger' }
    ];

    if (value.textVal || value.drawVal) {
      options.unshift({ value: 'prevVer', label: 'Use previous signature' });
    }

    return options;
  };

  const handleExtraDetailsCheckedChange = (key: keyof typeof extraDetails) => (e: ChangeEvent<HTMLInputElement>) => {
    setExtraDetails({ ...extraDetails, [key]: { ...extraDetails[key], active: e.target.checked } });
  };

  const handleExtraDetailsValueChange = (key: keyof typeof extraDetails) => (e: ChangeEvent<HTMLInputElement>) => {
    setExtraDetails({ ...extraDetails, [key]: { ...extraDetails[key], value: e.target.value } });
  };

  const saveSignature = async () => {
    setSaveStatus('active');

    const typeVal = signVersion === 'typeVer' ? textSignatureVal.textVal : '';
    const signatureVal = signVersion === 'drawVer' ? sigCanvas.current!.getTrimmedCanvas().toDataURL('image/png') : '';

    const signVal = {
      textVal: typeVal,
      drawVal: signatureVal
    };

    const extraDetailsVal = {
      valediction: extraDetails.valediction.value.length > 0 ? extraDetails.valediction.value : '',
      email: extraDetails.email.active ? extraDetails.email.value : '',
      mobileNumber: extraDetails.mobileNumber.active ? extraDetails.mobileNumber.value : '',
      name: extraDetails.name.active ? extraDetails.name.value : '',
      other: extraDetails.other.active ? extraDetails.other.value : ''
    };

    const signature = {
      type: '',
      value: ''
    };

    if (signVersion === 'prevVer') {
      signature.type = value.type;
      signature.value = value.type === 'typeVer' ? value.textVal : value.drawVal;
    } else {
      signature.type = signVersion;
      signature.value = signVersion === 'typeVer' ? typeVal : signatureVal;
    }

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      }).catch(() => '');

      await putSignature(token, { signature, extraDetails: extraDetailsVal });
      setSaveStatus('finished');

      onUpdateSignature(signVersion === 'prevVer' ? value : { type: signVersion, ...signVal }, extraDetailsVal);
      setTextSignatureVal(signVal);

      if (typeVal || signatureVal) {
        setSignVersion('prevVer');
      }
      setTimeout(() => {
        onCloseSignature();
        notification.success({
          message: 'Signature updated',
          duration: 3,
          closeIcon: <span className="success">OK</span>
        });
        setSaveStatus('');
      }, 2000);
    } catch (ex) {
      setSaveStatus('');
      notification.error({ message: 'Something went wrong while trying to save your signature.' });
    }
  };

  const onClearSignature = () => {
    sigCanvas.current!.clear();
  };

  return (
    <Modal
      className={styles.wrapper}
      bodyStyle={{ padding: 0 }}
      footer={null}
      visible={visible}
      onCancel={onCloseSignature}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Add Signature to Report</div>
        </div>
        <div className={styles.content}>
          <div className={styles.signatureTypeWrapper}>
            <Radio
              className={styles.radioBtn}
              options={getSignVersionOptions()}
              onChange={(e) => setSignVersion(e.target.value)}
              labelClassName={styles.radioLabel}
              radioClassName={styles.radioBtn}
              value={signVersion}
            />
          </div>
          <div className={styles.box}>
            {signVersion === 'prevVer' && (
              <>
                <div className={styles.valedictionContainer}>
                  <MaterialInput
                    className={styles.valedictionField}
                    labelClassName={styles.valedictionLabel}
                    id="valediction"
                    value={extraDetails.valediction.value}
                    onChange={handleExtraDetailsValueChange('valediction')}
                    label="Valediction"
                    maxLength={50}
                  />
                </div>
                <div className={styles.previewSignature}>
                  {value.textVal && value.textVal}
                  {value.drawVal && <img className={styles.signatureImg} alt={'signature'} src={value.drawVal} />}
                </div>
              </>
            )}
            {signVersion === 'typeVer' && (
              <div>
                <div className={styles.valedictionContainer}>
                  <MaterialInput
                    className={styles.valedictionField}
                    labelClassName={styles.valedictionLabel}
                    id="valediction"
                    value={extraDetails.valediction.value}
                    onChange={handleExtraDetailsValueChange('valediction')}
                    label="Valediction"
                  />
                </div>
                <input
                  value={textSignatureVal.textVal}
                  className={styles.signatureInput}
                  onChange={(e) => setTextSignatureVal({ ...textSignatureVal, textVal: e.target.value })}
                  placeholder={'Sign here'}
                />
              </div>
            )}
            {signVersion === 'drawVer' && (
              <div>
                <div className={styles.valedictionContainer}>
                  <MaterialInput
                    className={styles.valedictionField}
                    labelClassName={styles.valedictionLabel}
                    id="valediction"
                    value={extraDetails.valediction.value}
                    onChange={handleExtraDetailsValueChange('valediction')}
                    label="Valediction"
                  />
                </div>
                <SignaturePad canvasProps={{ className: styles.signaturePad }} ref={sigCanvas} />
                <div className={styles.signatureBtnWrapper}>
                  <Button className={styles.clearBtn} onClick={onClearSignature} variant="secondary">
                    Clear
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={styles.includeBelowSignature}>
            <div className={styles.title}>
              Include below signature
              <i className={`material-icons ${styles.icon}`} data-tip data-for="include-below-signature">
                help_outline
              </i>
              <ReactTooltip id="include-below-signature" className={styles.tooltip}>
                You can include any relevant information in your signature block by editing these details. We will save
                the latest version as the default to be applied to any future reports.
              </ReactTooltip>
            </div>
            <ExtraDetail
              id="name"
              inputProps={{
                label: 'Name',
                value: extraDetails.name.value,
                onChange: handleExtraDetailsValueChange('name')
              }}
              label="Your name"
              checked={extraDetails.name.active}
              onChange={handleExtraDetailsCheckedChange('name')}
            />
            <ExtraDetail
              id="mobileNumber"
              inputProps={{
                label: 'Phone number',
                value: extraDetails.mobileNumber.value,
                onChange: handleExtraDetailsValueChange('mobileNumber')
              }}
              label="Contact phone number"
              checked={extraDetails.mobileNumber.active}
              onChange={handleExtraDetailsCheckedChange('mobileNumber')}
            />
            <ExtraDetail
              id="email"
              inputProps={{
                label: 'Email',
                value: extraDetails.email.value,
                onChange: handleExtraDetailsValueChange('email')
              }}
              label="Contact email"
              checked={extraDetails.email.active}
              onChange={handleExtraDetailsCheckedChange('email')}
            />
            <ExtraDetail
              id="other"
              inputProps={{
                label: 'Info',
                value: extraDetails.other.value,
                onChange: handleExtraDetailsValueChange('other')
              }}
              label="Other info"
              checked={extraDetails.other.active}
              onChange={handleExtraDetailsCheckedChange('other')}
            />
          </div>
          <div className={styles.btnBox}>
            <SubmitButton status={saveStatus} className={styles.button} onClick={() => saveSignature()}>
              Sign & Save
            </SubmitButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignatureBox;
