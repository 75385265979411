import { Modal, Skeleton } from 'antd';
import classnames from 'classnames';
import { useState } from 'react';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import styles from './AvailableGroupsModal.module.scss';

interface AvailableGroupsModalProps {
  groupList: GroupsFromAPI[];
  isVisible?: boolean;
  isGroupListLoading?: boolean;
  onAssigned: (groupId: string) => void;
  onCancel: () => void;
}

const AvailableGroupsModal = ({
  groupList,
  isVisible,
  isGroupListLoading,
  onAssigned,
  onCancel
}: AvailableGroupsModalProps) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  return (
    <Modal
      key={'availableGroupModal'}
      className={styles.modalContainer}
      visible={isVisible}
      onCancel={onCancel}
      title="Select group"
      onOk={() => (selectedGroup ? onAssigned(selectedGroup) : onCancel())}
      okButtonProps={{
        style: { background: '#3f52ff', borderColor: '#3f52ff', borderRadius: '4px', color: '#ffffff' }
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className={classnames(styles.columnWrapper, styles.header)}>
        <div className={styles.groupNameCol}>Group Name</div>
        <div className={styles.groupLeadCol}>Group Lead</div>
        <div className={styles.groupDesCol}>Group Description</div>
      </div>
      {isGroupListLoading ? (
        <div className={styles.loadingContainer}>
          <Skeleton active />
        </div>
      ) : (
        <div className={styles.groupList}>
          {groupList.length > 0 ? (
            groupList.map((group, index) => (
              <div
                key={index}
                className={classnames(
                  styles.columnWrapper,
                  styles.listItem,
                  selectedGroup === group._id && styles.active
                )}
                onClick={() => setSelectedGroup(group._id)}
              >
                <div className={classnames(styles.groupNameCol, styles.groupName)}>{group.name ? group.name : '-'}</div>
                <div className={classnames(styles.groupLeadCol, styles.groupLead)}>
                  {group.leadClinicianProfile ? group.leadClinicianProfile.name : '-'}
                </div>
                <div className={classnames(styles.groupDesCol, styles.groupDes)}>{group.description}</div>
              </div>
            ))
          ) : (
            <div className={styles.grayOutLabel}>No available group</div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AvailableGroupsModal;
