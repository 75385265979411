import styles from './GroupInfo.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import GroupStatusBadge from './components/GroupStatusBadge/GroupStatusBadge';
import moment from 'moment';
import GroupPractitioner from './components/GroupPractitioner/GroupPractitioner';
import GroupTags from './components/GroupTags/GroupTags';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { GroupTypeBadge } from 'pages/Groups/GroupsList/components/GroupListContent/components/GroupTypeBadge/GroupTypeBadge';

interface GroupInfoProps {
  groupDetails: Groups;
  groupTagList: Groups['tags'];
}

const GroupInfo = ({ groupDetails, groupTagList }: GroupInfoProps) => {
  const { isGroupsTagsToggle } = useGetFeatureToggle();

  return (
    <div className={styles.container}>
      <div className={styles.detailContainer}>
        <div className={styles.groupInfoRow}>
          <div className={styles.statusWrapper}>
            <div className={styles.label}>STATUS</div>
            <GroupStatusBadge groupStatus={groupDetails.status} />
          </div>
          <div className={styles.typeWrapper}>
            <div className={styles.label}>Type</div>
            <GroupTypeBadge type={groupDetails.type} />
          </div>
        </div>

        <div className={styles.nameWrapper}>
          <div className={styles.label}>Name:</div>
          <div className={styles.value}>{groupDetails.name}</div>
        </div>
        {groupDetails.groupId && (
          <div className={styles.idWrapper}>
            <div className={styles.label}>ID:</div>
            <div className={styles.value}>{groupDetails.groupId}</div>
          </div>
        )}
        <div className={styles.createdByWrapper}>
          <div className={styles.label}>Created by:</div>
          <div className={styles.value}>{moment(groupDetails.createdAt).format('DD MMM YYYY')}</div>
        </div>
        {groupDetails.description && (
          <div className={styles.descWrapper}>
            <div className={styles.label}>Description:</div>
            <div className={styles.value}>{groupDetails.description}</div>
          </div>
        )}
        {isGroupsTagsToggle && (
          <div className={styles.tagsWrapper}>
            <GroupTags groupSelectedTag={groupDetails.tags} groupTagList={groupTagList} />
          </div>
        )}
      </div>
      <GroupPractitioner
        leadClinicianProfile={groupDetails.leadClinicianProfile}
        clinicianProfiles={groupDetails.clinicianProfiles}
      />
    </div>
  );
};

export default GroupInfo;
