import { useMemo } from 'react';
import HeaderControl from 'components/TableList/HeaderControl/HeaderControl';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { ConsentFormResponseList, GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';

interface ConsentFormsHeaderProps {
  groupId: string;
  consentFormId?: string;
  consentFormMenuList?: ConsentFormResponseList[];
}

const ConsentFormHeader = ({ groupId, consentFormId, consentFormMenuList }: ConsentFormsHeaderProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const onGoToOtherClick = (id: string) => {
    navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Consent}/${id}`);
    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  const { currentConsentForm, previousConsentForm, nextConsentForm } = useMemo(() => {
    const consentFormHeaderItems: {
      currentConsentForm?: ConsentFormResponseList;
      previousConsentForm?: ConsentFormResponseList;
      nextConsentForm?: ConsentFormResponseList;
    } = { currentConsentForm: undefined, previousConsentForm: undefined, nextConsentForm: undefined };

    if (consentFormMenuList) {
      const currentSurveyForm = consentFormMenuList.find((consentFormItem) => consentFormItem._id === consentFormId);

      if (currentSurveyForm) {
        consentFormHeaderItems.currentConsentForm = currentSurveyForm;

        const currentIndex = consentFormMenuList.indexOf(currentSurveyForm);

        if (currentIndex > -1) {
          consentFormHeaderItems.previousConsentForm = consentFormMenuList[currentIndex - 1];
          consentFormHeaderItems.nextConsentForm = consentFormMenuList[currentIndex + 1];
        }
      }
    }

    return consentFormHeaderItems;
  }, [consentFormId, consentFormMenuList]);

  return (
    <HeaderControl
      current={currentConsentForm}
      previous={previousConsentForm}
      next={nextConsentForm}
      onGoToOtherClick={onGoToOtherClick}
    />
  );
};

export default ConsentFormHeader;
