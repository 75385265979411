import moment from 'moment';

export const validateReferralDate = (referralDate: string) => {
  if (referralDate) {
    const referralMoment = moment(referralDate, 'DD/MM/YYYY');

    if (!referralMoment.isValid() || referralDate.length !== 10) {
      return 'Please enter a valid date in the format DD/MM/YYYY';
    } else if (referralMoment.year() < 1900) {
      return 'Please choose a date after 1900';
    } else if (referralMoment.isAfter(moment(), 'date')) {
      return 'Please choose a date before tomorrow';
    }
  }
};
