import classnames from 'classnames';
import moment from 'moment';
import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { useGetAccountDetailsFromToken } from './hooks/useGetAccountDetailsFromToken';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Input from 'components/Input/Input';

import CordsLogo2 from 'assets/images/CORDS/CORDS-Logo-2.png';
import styles from './SetPassword.module.scss';
import { postGetSetPasswordLink } from 'utils/http/ClinicianProfileService/Accounts/accounts';

const SetPassword = () => {
  const {
    accountDetails: { accountId, practiceName, token },
    expiredTime
  } = useGetAccountDetailsFromToken();

  const [error, setError] = useState('');
  const [shortCode, setShortCode] = useState(['', '', '', '', '', '']);

  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [showError, setShowError] = useState(false);

  const isTokenExpired = moment(moment.unix(expiredTime)).isBefore(moment());

  const callCheckVerification = async (shortCode: string) => {
    setError('');
    setIsVerifying(true);
    setIsErrored(false);
    setIsVerified(false);

    await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000 + 1000));

    try {
      const response = await postGetSetPasswordLink(token, accountId, { shortCode });

      if (response.statusCode === 200) {
        const { url } = await response.json();

        setIsVerified(true);
        setIsErrored(false);

        window.location.href = url;
      } else {
        setIsVerified(false);
        setIsErrored(true);

        if (response.statusCode === 400) {
          setError('Invalid code.');
        } else if (response.statusCode === 403) {
          setError('This link has broken. Please contact an administrator.');
        } else if (response.statusCode === 500) {
          setError(
            'Something went wrong while verifying your code. Please try again, or contact an administrator if the problem persists.'
          );
        }
      }
    } catch (err) {
      setIsVerified(false);
      setIsErrored(true);
      console.error(err);
    } finally {
      setIsVerifying(false);
    }
  };

  const onChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length === 6) {
      setShortCode(inputValue.split(''));

      document.getElementById('shortCode-5')?.focus();

      setShowError(true);

      callCheckVerification(inputValue);
    } else {
      const actualInputValue = inputValue.charAt(inputValue.length - 1)?.toUpperCase();

      const newValue = [...shortCode];
      newValue.splice(index, 1, actualInputValue);
      setShortCode(newValue);

      if (actualInputValue) {
        document.getElementById(`shortCode-${index + 1}`)?.focus();
      }

      const shortCodeValue = newValue.join('');

      if (shortCodeValue.length === 6) {
        setShowError(true);

        callCheckVerification(shortCodeValue);
      }
    }
  };

  const onKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && shortCode[index] === '') {
      e.preventDefault();

      document.getElementById(`shortCode-${index - 1}`)?.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img className={styles.logo} src={CordsLogo2} alt="cords logo" />
        {practiceName && <div className={styles.practiceName}>{practiceName}</div>}
        {isTokenExpired ? (
          <div className={styles.expired}>This link has expired.</div>
        ) : (
          <>
            <div className={styles.prompt}>Enter your school's code</div>
            <div
              className={classnames(
                styles.shortCodeContainer,
                isVerifying && styles.verifying,
                isVerified && styles.verified,
                isErrored && styles.errored
              )}
            >
              <Input
                type="tel"
                id="shortCode-0"
                value={shortCode[0]}
                onKeyDown={onKeyDown(0)}
                onChange={onChange(0)}
                hasError={showError && !!error}
                disabled={isVerifying}
                noSpacing
              />
              <Input
                type="tel"
                id="shortCode-1"
                value={shortCode[1]}
                onKeyDown={onKeyDown(1)}
                onChange={onChange(1)}
                hasError={showError && !!error}
                disabled={isVerifying}
                noSpacing
              />
              <Input
                type="tel"
                id="shortCode-2"
                value={shortCode[2]}
                onKeyDown={onKeyDown(2)}
                onChange={onChange(2)}
                hasError={showError && !!error}
                disabled={isVerifying}
                noSpacing
              />
              <Input
                type="tel"
                id="shortCode-3"
                value={shortCode[3]}
                onKeyDown={onKeyDown(3)}
                onChange={onChange(3)}
                hasError={showError && !!error}
                disabled={isVerifying}
                noSpacing
              />
              <Input
                type="tel"
                id="shortCode-4"
                value={shortCode[4]}
                onKeyDown={onKeyDown(4)}
                onChange={onChange(4)}
                hasError={showError && !!error}
                disabled={isVerifying}
                noSpacing
              />
              <Input
                type="tel"
                id="shortCode-5"
                value={shortCode[5]}
                onKeyDown={onKeyDown(5)}
                onChange={onChange(5)}
                hasError={showError && !!error}
                disabled={isVerifying}
                noSpacing
              />
            </div>
            <ErrorMessage className={styles.error} error={error} visible={showError} />
          </>
        )}
      </div>
    </div>
  );
};

export default SetPassword;
