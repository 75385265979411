import bent from 'bent';
import { FirstAssessmentTemplateResponse, Question, QuestionSet } from 'interfaces/checkInService/firstAssessment';
import { FirstAssessmentStruct } from 'interfaces/firstAssessment';
import { v4 as uuid } from 'uuid';

const getData = (authKey: string) => {
  const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;
  const checkInServiceRequest = bent<FirstAssessmentTemplateResponse>(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return checkInServiceRequest('/first-assessments/templates/me');
};

const massageQuestions = (questions: Question[]) => {
  const massagedQuestions = questions.map((question, index) => ({
    ...question,
    order: index,
    id: uuid(),
    questionId: uuid()
  }));

  return massagedQuestions;
};

const massageQuestionSet = (data: QuestionSet[]) => {
  const massageQSet = [];
  for (let obj of data) {
    massageQSet.push({
      sectionName: obj.sectionName,
      questions: massageQuestions(obj.questions)
    });
  }
  return massageQSet;
};

const massageData = (templateData: FirstAssessmentTemplateResponse) => {
  return {
    questionSets: massageQuestionSet(templateData.questionSets)
  };
};

export const getFirstAssessmentQuestionTemplate = async (
  token: string
): Promise<Pick<FirstAssessmentStruct, 'questionSets'>> => {
  const templateData = await getData(token);
  return massageData(templateData);
};
