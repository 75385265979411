import { useNavigate } from 'react-router-dom';

import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './NotFound404.module.scss';

const NotFound404 = () => {
  const navigate = useNavigate();

  const onClickBackBtn = () => {
    navigate(-1);
  };

  return (
    <ContentLayout>
      <h1>404 Not Found</h1>
      <h2>The resource you are trying to access cannot be found or does not exist.</h2>
      <div className={styles.back}>
        <div className={styles.backBtn} onClick={() => onClickBackBtn()}>
          <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
          Return to the previous page
        </div>
      </div>
    </ContentLayout>
  );
};

export default NotFound404;
