import { useState } from 'react';
import { notification } from 'antd';
import { useField } from 'formik';

import { uploadAvatar } from 'utils/http/clinician';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import Button from 'components/Button/Button';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import AvatarEditor from '../../../AvatarEditor/AvatarEditor';

import styles from './PracticeItem.module.scss';
import FormikPlaceMaterialInput from 'components/FormikPlaceMaterialInput/FormikPlaceMaterialInput';

interface PracticeItemProps {
  originalAvatar?: string;
  showError: boolean;
  onHideError: () => void;
  onRemovePractice: () => void;
}

const PracticeItem = ({ originalAvatar, showError, onHideError, onRemovePractice }: PracticeItemProps) => {
  const { isNormalUserView } = useGetAccountPackageView();
  const [{ value: locations }, , { setValue: setLocationsValue }] = useField<string[]>(`practice.locations`);
  const [{ value: logo }, , { setValue: setLogoValue }] = useField<string | undefined>(`practice.logo`);

  const [isAvatarEditorVisible, setIsAvatarEditorVisible] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  const beforeInitialUploadAvatar = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: `You can only upload JPG or PNG` });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: `Image cannot be bigger than 2MB!` });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleInitialUploadAvatar = async ({ file, onSuccess }: any) => {
    setUploadingAvatar(true);
    const data = new FormData();
    data.append('file', file);
    const res = (await uploadAvatar(data)) as {
      [key: string]: any;
      [key: number]: any;
    };

    const body = await res.json();
    setLogoValue(body.data.url);
    setUploadingAvatar(false);
    onSuccess(res);
  };

  const handleAvatarEditorOpen = () => {
    setIsAvatarEditorVisible(true);
  };

  const handleAvatarEditorClose = () => {
    setIsAvatarEditorVisible(false);
  };

  const handleAvatarEditorCancel = () => {
    setLogoValue(originalAvatar);
    handleAvatarEditorClose();
  };

  const handleInput = () => {
    if (showError) {
      onHideError();
    }
  };

  const handleLocationButtonClick = (locationIndex: number) => {
    if (locations.length === 1) {
      setLocationsValue([...locations, '']);
    } else {
      setLocationsValue(locations.filter((location, index) => index !== locationIndex));
    }
  };

  return (
    <>
      <AvatarEditor
        borderRadius={1000}
        filenameSuffix="profile-avatar"
        imgSrc={logo}
        placeholder="Add your profile picture"
        title="Upload profile picture"
        visible={isAvatarEditorVisible}
        isAvatarUploading={uploadingAvatar}
        onBeforeUpload={beforeInitialUploadAvatar}
        onCancel={handleAvatarEditorCancel}
        onClose={handleAvatarEditorClose}
        onUploadAvatar={handleInitialUploadAvatar}
      />
      <div className={styles.container} id={`practice-item`}>
        {isNormalUserView && (
          <div className={styles.closeIcon} onClick={onRemovePractice}>
            <i className={`material-icons-outlined ${styles.icon}`}>close</i>
          </div>
        )}
        <div className={styles.logoContainer} id={'logoContainerId'}>
          {logo ? (
            <img className={styles.logo} src={logo} alt="Avatar" onClick={handleAvatarEditorOpen} />
          ) : (
            <div className={styles.uploadLogoPrompt} onClick={handleAvatarEditorOpen}>
              <i className={`material-icons-outlined ${styles.icon}`}>add_a_photo</i>
              Add your practice logo
            </div>
          )}
        </div>
        <div className={styles.detailsContainer} id={'detailsContainerId'}>
          <FormikMaterialInput
            className={styles.nameInput}
            name={`practice.name`}
            id={'practiceName'}
            label="School Name"
            required
            onInput={handleInput}
          />
          <FormikMaterialInput
            className={styles.mobileNumberInput}
            name={`practice.mobileNumber`}
            id={'practiceMobileNumber'}
            label="Primary phone number"
            required
            onInput={handleInput}
          />
          {locations.map((location, inx) => (
            <div className={styles.location} key={inx}>
              <FormikPlaceMaterialInput
                name={`practice.locations.${inx}`}
                label={`Location ${locations.length > 0 && (inx === 0 ? '(Primary)' : '(Secondary)')}`}
                placeholder={''}
                containerClassName={styles.inputContainer}
                required
              />
              <Button
                className={styles.removeButton}
                variant="secondary"
                onClick={() => handleLocationButtonClick(inx)}
              >
                {locations.length === 1 ? (
                  <>
                    <i className={`material-icons-outlined ${styles.icon}`}>add</i>
                    Add another location
                  </>
                ) : (
                  <i className={`material-icons-outlined ${styles.icon}`}>close</i>
                )}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PracticeItem;
