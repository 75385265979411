import { useEffect } from 'react';
import styles from './SurveyFormList.module.scss';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import { useGetAccessToken } from 'utils/hooks/token';
import { useNavigate, useParams } from 'react-router-dom';
import SurveyFormListContent from './components/SurveyFormListContent/SurveyFormListContent';
import { useFetchAdHocSurveyClientList } from './hooks/getSurveyFormList';
import SurveyFormHeader from './components/SurveyFormListContent/components/SurveyFormHeader/SurveyFormHeader';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import LoadingDot from 'components/LoadingDot/LoadingDot';

interface SurveyFormListProps {
  surveyId?: string;
  surveyFormMenuList: SurveyFormResponseList[];
  surveyResponseListLoading: boolean;
}

const SurveyFormList = ({ surveyId, surveyFormMenuList, surveyResponseListLoading }: SurveyFormListProps) => {
  const { token } = useGetAccessToken();
  const { GROUPS } = useRoutesGenerator();
  const navigate = useNavigate();
  const { groupId = '' } = useParams<{ groupId: string }>();

  const { groupSurveyClientList, isSurveyFormListLoading } = useFetchAdHocSurveyClientList(token, groupId, surveyId!);

  useEffect(() => {
    if (!surveyId && surveyFormMenuList && surveyFormMenuList.length > 0) {
      const isSentSurveys = surveyFormMenuList.filter((obj) => obj.isSent);
      if (isSentSurveys.length > 0) {
        navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Survey}/${isSentSurveys[0]._id}`);
      }
    }
  }, [surveyFormMenuList, surveyId, GROUPS, groupId, navigate]);

  return (
    <div className={styles.container}>
      {surveyResponseListLoading || (groupSurveyClientList.length === 0 && isSurveyFormListLoading) ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : groupSurveyClientList.length > 0 ? (
        <>
          <SurveyFormHeader groupId={groupId} surveyFormId={surveyId} surveyFormMenuList={surveyFormMenuList} />
          <SurveyFormListContent
            groupSurveyClientList={groupSurveyClientList}
            isSurveyFormListLoading={isSurveyFormListLoading}
          />
        </>
      ) : surveyId ? (
        'No clients were in the group when this survey was sent, or all clients who have been sent this survey have been removed from the group.'
      ) : (
        'No survey selected.'
      )}
    </div>
  );
};

export default SurveyFormList;
