import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getPsychometricListMenu } from 'utils/http/CheckInService/Assessment/groupAssessment';
import { GroupPsychometricMenu } from '../../Interfaces/Groups';
import { getClinicalAssessmentTemplate } from '../../../../store/CheckIn/GetClinicalAssessmentTemplate/action';
import { massageSQDSequence } from '../../../../components/ClientOrGroupPickAssessment/getAssessmentList';

export const useFetchPsychometricList = (token: string, groupId: string) => {
  const [psychometricList, setPsychometricList] = useState<GroupPsychometricMenu[]>([]);
  const [isPsychometricListLoading, setIsPsychometricListLoading] = useState(true);

  const fetchPsychometricList = useCallback(
    async (token: string) => {
      setIsPsychometricListLoading(true);
      try {
        const getPsychometricListMenuData = await getPsychometricListMenu(token, groupId);
        const assessmentTemplateList = await getClinicalAssessmentTemplate(token);
        const assessmentList = massageSQDSequence(assessmentTemplateList);
        const psychometricMenuList: GroupPsychometricMenu[] = await getPsychometricListMenuData.json();

        const massageAssessmentList = psychometricMenuList.map((obj) => ({
          ...obj,
          id:
            assessmentList.find((assessmentObj) => assessmentObj.id === obj.clinicalAssessmentId)?.metadata?.cords
              ?.shortTitle || obj.clinicalAssessmentId
        }));

        setPsychometricList(massageAssessmentList);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get this group assessment list' });
      }
      setIsPsychometricListLoading(false);
    },
    [groupId]
  );

  useEffect(() => {
    if (token) {
      fetchPsychometricList(token);
    }
  }, [fetchPsychometricList, token]);

  return { psychometricList, isPsychometricListLoading, fetchPsychometricList };
};
