import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { CaseNoteFormatSettingsFields, CaseNoteTemplate, ParticipantType } from '../interface';
import { fields } from '../formatSettings.json';
import { AssignmentMode } from '../interface';
import { getCaseNoteTemplateById } from 'utils/http/DocumentService/CaseNotes/caseNotesTemplate';
import { usePathQuery } from './useCaseNoteTemplateSubmit';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

export const DEFAULT_TEMPLATE: CaseNoteTemplate = {
  name: '',
  description: '',
  isPublished: false,
  participantType: ParticipantType.Individual,
  assignmentMode: AssignmentMode.Selected,
  selectedPractitioners: [],
  fields: fields as CaseNoteFormatSettingsFields[],
  headings: ''
};

const useGetCaseNoteTemplate = (token: string, caseNoteTemplateId: string) => {
  const query = usePathQuery();
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const [caseNoteTemplate, setCaseNoteTemplate] = useState<CaseNoteTemplate>();
  const [templateLoading, setTemplateLoading] = useState(false);

  const fetchTemplate = async () => {
    setTemplateLoading(true);

    if (caseNoteTemplateId === 'new') {
      const queryId = query.get('id');
      if (queryId && typeof queryId === 'string') {
        navigate(`${FORMS.BASE}/caseNotes/new`);
      }
      setCaseNoteTemplate(DEFAULT_TEMPLATE);
    } else {
      try {
        const template = (await (await getCaseNoteTemplateById(token, caseNoteTemplateId)).json()) as CaseNoteTemplate;

        setCaseNoteTemplate({
          name: template.name,
          description: template.description,
          isPublished: template.isPublished,
          participantType: template.participantType,
          assignmentMode: template.assignmentMode,
          selectedPractitioners: template.selectedPractitioners,
          fields: template.fields,
          headings: template.headings
        });
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Something went wrong while trying to fetch your case notes template.'
        });
      }
    }

    setTemplateLoading(false);
  };

  useEffect(() => {
    if (token && caseNoteTemplateId) {
      fetchTemplate();
    }
    // eslint-disable-next-line
  }, [caseNoteTemplateId, token]);

  return { caseNoteTemplate, templateLoading };
};

export default useGetCaseNoteTemplate;
