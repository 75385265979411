import classnames from 'classnames';

import styles from './ClinicianAvatar.module.scss';

interface ClinicianAvatarProps {
  avatarUrl?: string;
  name?: string;
  avatarSize?: number;
  showUnassigned?: boolean;
  isEllipsisName?: boolean;
  customContainerClass?: string;
  customNameClass?: string;
}

const ClinicianAvatar = ({
  avatarUrl,
  name,
  avatarSize,
  showUnassigned,
  isEllipsisName,
  customContainerClass,
  customNameClass
}: ClinicianAvatarProps) => {
  return (
    <div className={classnames(styles.container, customContainerClass && customContainerClass)}>
      {showUnassigned ? (
        <>
          <div className={styles.unAssignAvatar}>
            <i
              className={`material-icons ${styles.icon}`}
              style={{ height: `${avatarSize}px`, width: `${avatarSize}px` }}
            >
              person
            </i>
          </div>
          {name && <div className={styles.unAssignLabel}>{name}</div>}
        </>
      ) : (
        <>
          <div className={styles.avatarWrapper} style={{ height: `${avatarSize}px`, width: `${avatarSize}px` }}>
            <img src={avatarUrl} className={styles.imgProfile} alt={'profile'} />
          </div>
          {name && (
            <div
              style={isEllipsisName ? { maxWidth: `${avatarSize ? avatarSize + 30 : 0}px` } : {}}
              className={classnames(
                isEllipsisName ? styles.ellipsisName : styles.name,
                customNameClass && customNameClass
              )}
            >
              {name}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ClinicianAvatar;
