import { Question } from 'components/PatientQuestionForm/AssessmentInterfaces';

import styles from './FreeText.module.scss';

interface FreeTextProps {
  question: Question;
}

const FreeText = ({ question }: FreeTextProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        <div>{question.stem}</div>
        <div className={styles.answerWrapper}>{question.response?.value}</div>
      </div>
    </div>
  );
};

export default FreeText;
