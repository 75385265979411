import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { useParams } from 'react-router-dom';
import styles from './CaseNoteTemplate.module.scss';
import CaseNoteTemplateEditor from './CaseNoteTemplateEditor/CaseNoteTemplateEditor';
import useGetCaseNoteTemplate from './hooks/useGetCaseNoteTemplate';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchClinicians } from 'pages/Calendar/components/CalendarSettings/components/AppointmentTypes/hooks/GetClinicians';

const CaseNoteTemplate = () => {
  const { token } = useGetAccessToken();
  const path = useParams() as { caseNoteTemplateId: string };
  const { caseNoteTemplate, templateLoading } = useGetCaseNoteTemplate(token, path.caseNoteTemplateId);
  const { clinicians, isCliniciansLoading } = useFetchClinicians(token);

  const isLoading = templateLoading || !caseNoteTemplate || !clinicians || isCliniciansLoading;

  return (
    <HelmetWrapper title={'CORDS - Case Notes Template'}>
      <ContentLayout>
        {isLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <div className={styles.container}>
            <CaseNoteTemplateEditor template={caseNoteTemplate} practitionersListing={clinicians} />
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default CaseNoteTemplate;
