import styles from './ClosedInterventionListCard.module.scss';
import columnStyles from './ClosedInterventionColumn.module.scss';
import { Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import ClosedInterventionListItem from './components/ClosedInterventionListItem';

interface ClosedInterventionListCardProps {
  interventionListing: Interventions[];
  searchValue: string;
}

const ClosedInterventionListCard = ({ interventionListing, searchValue }: ClosedInterventionListCardProps) => {
  return interventionListing.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <div className={columnStyles.InterventionNameSection}>Intervention Name</div>
        <div className={columnStyles.GSinceSection}>Intervention Since</div>
        <div className={columnStyles.GSinceSection}>Intervention Closed</div>
        <div className={columnStyles.InterventionLeadSection}>Intervention Lead</div>
      </div>
      {interventionListing.map((listObj, index) => (
        <ClosedInterventionListItem interventionListingItem={listObj} key={index} searchValue={searchValue} />
      ))}
    </>
  ) : (
    <div>There is no data for Intervention</div>
  );
};

export default ClosedInterventionListCard;
