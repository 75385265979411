import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetAccessToken = () => {
  const [token, setToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const fetchAccessToken = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    }).catch(() => '');

    setToken(token);
  };

  useEffect(() => {
    fetchAccessToken();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { token, refetchToken: fetchAccessToken };
};
