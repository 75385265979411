import { CheckBoxListing, ClientDetails } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import Radio from 'components/Radio/Radio';
import { useEffect, useState } from 'react';
import { getName } from 'utils/general';

import ConsentSelect from './components/ConsentSelect/ConsentSelect';
import OnboardingSelect from './components/OnboardingSelect/OnboardingSelect';
import styles from './Onboarding.module.scss';

interface OnboardingProps {
  isListLoading: boolean;
  onboardOption?: boolean;
  checkValidation: boolean;
  clientProfile: ClientDetails;
  consentList: CheckBoxListing[];
  onBoardingList: CheckBoxListing[];
  profileType: 'full' | 'recordOnly';
  onChangeConsentField: any;
  onChangeOnboardField: any;
  handleChangeClientDetail: any;
}

const Onboarding = (props: OnboardingProps) => {
  const {
    clientProfile,
    onBoardingList,
    consentList,
    checkValidation,
    isListLoading,
    onboardOption,
    profileType
  } = props;
  const { onChangeOnboardField, handleChangeClientDetail, onChangeConsentField } = props;

  const [clientOnboardField, setClientOnboardField] = useState(clientProfile.onboarding);
  const [clientConsentField, setClientConsentField] = useState(clientProfile.consentForm);

  useEffect(() => {
    setClientOnboardField(clientProfile.onboarding);
  }, [clientProfile.onboarding]);

  useEffect(() => {
    setClientConsentField(clientProfile.consentForm);
  }, [clientProfile.consentForm]);

  const handleOnboardingChange = (key: string, val: boolean | string) => {
    const onboardField = {
      ...clientOnboardField,
      [key]: val
    };
    setClientOnboardField(onboardField);
    onChangeOnboardField(onboardField, clientProfile._id);
  };

  const handleSurveyChange = (val: string) => {
    let newClientField = {} as ClientDetails;
    newClientField = {
      ...clientProfile,
      onboarding: {
        ...clientProfile.onboarding,
        onboardingSurvey: val
      }
    };
    handleChangeClientDetail(newClientField);
  };

  const handleConsentChange = (key: string, val: boolean | string) => {
    const consentField = {
      ...clientConsentField,
      [key]: val
    };
    setClientConsentField(consentField);
    onChangeConsentField(consentField, clientProfile._id);
  };

  const handleConsentFormChange = (val: string) => {
    let newClientField = {} as ClientDetails;
    newClientField = {
      ...clientProfile,
      consentForm: clientProfile.consentForm && {
        ...clientProfile.consentForm,
        consentFormId: val
      }
    };
    handleChangeClientDetail(newClientField);
  };

  const renderOnBoardingOption = () => {
    const disabledClientOnboardOption = profileType !== 'full';
    return (
      <div
        className={
          clientOnboardField.isCheckOnBoarding || clientConsentField.isCheckOnConsentForm
            ? styles.optionContainer
            : styles.optionContainerHidden
        }
      >
        <div className={styles.onBoardingOption}>
          <div className={styles.subTitle}>
            How should {getName({ ...clientProfile, name: clientProfile.firstName })} onboard?
            <HelpOutLineWithTooltips id={'howOnboard'} desc={'Decide how you like your onboard survey to be fill'} />
          </div>
          <Radio
            name={`clientOnboardingMethod_${clientProfile._id}`}
            options={[
              {
                value: 'sendToClient',
                disabled: disabledClientOnboardOption,
                label: (
                  <OptionLabel
                    isSelected={clientOnboardField.onboardingMethod === 'sendToClient'}
                    title={`Send to ${clientProfile.firstName}`}
                    highlightTitle
                    disabledTitle={disabledClientOnboardOption}
                  />
                )
              },
              {
                value: 'facilitated',
                label: (
                  <OptionLabel
                    isSelected={clientOnboardField.onboardingMethod === 'facilitated'}
                    title={`Facilitated Onboarding`}
                    highlightTitle
                  />
                )
              }
            ]}
            vertical
            value={clientOnboardField.onboardingMethod}
            onChange={(e) => handleOnboardingChange('onboardingMethod', e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Onboarding Options for {getName({ ...clientProfile, name: clientProfile.firstName })}
      </div>
      <div className={styles.content}>
        <div className={styles.onboarding}>
          {onBoardingList.length > 0 ? (
            <div className={clientOnboardField.isCheckOnBoarding ? styles.wrapper : styles.notSelectWrapper}>
              {/* Onboarding section */}
              <CheckBox
                className={styles.checkBox}
                id={`clientOnboardingCheck_${clientProfile._id}`}
                value={clientOnboardField.isCheckOnBoarding}
                label={
                  <OptionLabel
                    title={`Ask ${getName({
                      ...clientProfile,
                      name: clientProfile.firstName
                    })} to complete onboarding survey`}
                  />
                }
                onChange={(e) => handleOnboardingChange('isCheckOnBoarding', e.target.checked)}
              />
              <div className={styles.selection}>
                {clientOnboardField.isCheckOnBoarding && (
                  <OnboardingSelect
                    id={`onboaring${clientProfile._id}`}
                    clientName={getName({ ...clientProfile, name: clientProfile.firstName })}
                    value={clientProfile.onboarding.onboardingSurvey || ''}
                    checkValidation={checkValidation}
                    onBoardingList={onBoardingList}
                    isListLoading={isListLoading}
                    onChangeOnboardValue={(val: string) => handleSurveyChange(val)}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.noListLabel}>
              There are no published surveys to be able to collect data at this time.
            </div>
          )}
        </div>
        {/* Consent */}
        <div className={styles.consent}>
          {consentList.length > 0 ? (
            <div className={clientConsentField?.isCheckOnConsentForm ? styles.wrapper : styles.notSelectWrapper}>
              <CheckBox
                className={styles.checkBox}
                id={`clientConsentFormCheck_${clientProfile._id}`}
                value={clientConsentField?.isCheckOnConsentForm || false}
                label={
                  <OptionLabel
                    title={`Ask ${getName({
                      ...clientProfile,
                      name: clientProfile.firstName
                    })} to agree to confidentiality or consent`}
                  />
                }
                onChange={(e) => handleConsentChange('isCheckOnConsentForm', e.target.checked)}
              />
              <div className={styles.selection}>
                {clientConsentField?.isCheckOnConsentForm && (
                  <ConsentSelect
                    id={`consent${clientProfile._id}`}
                    clientName={clientProfile.firstName}
                    value={clientProfile.consentForm?.consentFormId || ''}
                    checkValidation={checkValidation}
                    consentList={consentList}
                    isListLoading={isListLoading}
                    onChangeConsentValue={(val: string) => handleConsentFormChange(val)}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.noListLabel}>There are no published consent forms to request consent.</div>
            </div>
          )}
        </div>
        {onboardOption && renderOnBoardingOption()}
      </div>
    </div>
  );
};

export default Onboarding;
