import { useEffect, useRef, useState } from 'react';
import styles from './GPSelect.module.scss';
import { GeneralPractitionerInterface } from '../../../../interface';
import classnames from 'classnames';
import { CLIENT_GP_INITIAL } from '../../constants';

interface GPSelectProps {
  preSelectedGP: GeneralPractitionerInterface;
  gpList: GeneralPractitionerInterface[];
  onChange: (selectedGP: GeneralPractitionerInterface) => void;
}

const GPSelect = ({ preSelectedGP, gpList, onChange }: GPSelectProps) => {
  const [showList, setShowList] = useState(false);
  const [selectedGP, setSelectedGP] = useState<GeneralPractitionerInterface>(preSelectedGP);
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedGP(preSelectedGP);
  }, [preSelectedGP]);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSelectOption = (id: string) => {
    const selected = gpList.find((gp) => gp._id === id);
    if (selected) {
      setSelectedGP(selected);
      onChange(selected);
    } else {
      setSelectedGP(CLIENT_GP_INITIAL);
      onChange(CLIENT_GP_INITIAL);
    }
    setShowList(false);
  };

  return (
    <div ref={node} className={styles.container}>
      <div className={styles.selectedBox} onClick={() => setShowList(!showList)}>
        <div className={styles.name}>{selectedGP?.name || 'Select GP details'}</div>
        <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
      </div>
      <div className={classnames(styles.listWrapper, showList ? styles.listShow : styles.listHide)}>
        {gpList.map((gp) => (
          <div
            className={selectedGP?._id === gp._id ? styles.itemSelected : styles.item}
            key={gp._id}
            onClick={() => handleSelectOption(gp._id)}
          >
            {gp.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GPSelect;
