import classnames from 'classnames';
import { IntegrationStatus } from 'pages/ControlPanel/Interfaces/Integration';

import styles from './Status.module.scss';

interface StatusProps {
  status: IntegrationStatus;
}

const Status = ({ status }: StatusProps) => {
  const formattedStatus = [
    IntegrationStatus.Pending,
    IntegrationStatus.PendingAuth,
    IntegrationStatus.PendingMapping
  ].includes(status)
    ? IntegrationStatus.Pending
    : status;
  return (
    <div className={classnames(styles.container, styles[formattedStatus])}>
      {formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1)}
    </div>
  );
};

export default Status;
