import { useAuth0 } from '@auth0/auth0-react';
import { notification, Skeleton } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { putActionPlan } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import ActionPlanForm from '../ActionPlanCreator/components/ActionPlanForm/ActionPlanForm';
import { ActionPlanInterventionItem } from '../ActionPlanCreator/components/ActionPlanForm/components/InterventionList/InterventionList';
import { actionPlanFormErrors, getInitActionPlan } from '../ActionPlanCreator/constants';
import {
  ActionPlanAssessment,
  ActionPlanAttachment,
  ActionPlanFormInterface,
  ActionPlanInterface,
  ActionPlanIntervention,
  ActionPlanTask,
  ActionPlanTaskItem
} from '../ActionPlanCreator/interfaces';

import styles from './ActionPlanEditor.module.scss';
import { useFetchActionPlan } from './hook/getActionPlanById';
import { useFetchInterventionList } from './hook/getInterventionList';

const ActionPlanEditor = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const { recordId = '', actionPlanId = '' } = useParams<{ recordId: string; actionPlanId: string }>();
  const { actionPlanData, isActionPlanLoading } = useFetchActionPlan(token, recordId, actionPlanId);
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const [actionPlan, setActionPlan] = useState<ActionPlanFormInterface>(actionPlanData || getInitActionPlan(user?.sub));
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    ownerAuth0Id: '',
    dueDate: '',
    tasksAndInterventions: ''
  });
  const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
  const [publishButtonStatus, setPublishButtonStatus] = useState<ButtonStatusType>('');
  const { interventionList: interventionOptions } = useFetchInterventionList(token);

  useEffect(() => {
    if (actionPlanData) {
      const groupedInt: ActionPlanInterventionItem[] = [];
      (actionPlanData.interventions || []).forEach((item) => {
        if (item.focusArea) {
          const foundInterventionIndex = groupedInt.findIndex((int) => int.focusArea === item.focusArea);
          if (foundInterventionIndex === -1) {
            groupedInt.unshift({
              ...item,
              selectedInterventionIds: item.interventionId ? [item.interventionId] : [],
              subInterventions: [item],
              isOld: true
            });
          } else {
            groupedInt[foundInterventionIndex] = {
              ...item,
              selectedInterventionIds: [
                ...(groupedInt[foundInterventionIndex].selectedInterventionIds || []),
                ...(item.interventionId ? [item.interventionId] : [])
              ],
              subInterventions: [...(groupedInt[foundInterventionIndex].subInterventions || []), item],
              isOld: true
            };
          }
        } else {
          groupedInt.push({
            ...item,
            selectedInterventionIds: item.interventionId ? [item.interventionId] : [],
            isOld: true
          });
        }
      });
      setActionPlan({
        ...actionPlanData,
        interventions: groupedInt
      });
    }
  }, [actionPlanData]);

  const handleClickBackBtn = () => {
    navigate(`${CLIENTS.BASE}/${recordId}/action-plans/${actionPlanId ?? ''}`);
  };

  const handleChangeValues = (
    key: keyof ActionPlanInterface,
    value:
      | string
      | ActionPlanTask[]
      | ActionPlanAttachment[]
      | undefined
      | ActionPlanAssessment[]
      | ActionPlanIntervention[],
    validateField: boolean = true
  ) => {
    setActionPlan({ ...actionPlan, [key]: value });
    validateField &&
      !['tasks', 'interventions'].includes(key) &&
      setErrorMessage({ ...errorMessage, [key]: !value || value.length === 0 ? actionPlanFormErrors[key] : '' });
  };

  const checkIsValid = () => {
    const newError = {
      ...errorMessage,
      title: !actionPlan.title ? actionPlanFormErrors['title'] : '',
      dueDate: !actionPlan.dueDate ? actionPlanFormErrors['dueDate'] : '',
      ownerAuth0Id: !actionPlan.ownerAuth0Id ? actionPlanFormErrors['ownerAuth0Id'] : '',
      tasksAndInterventions:
        (!actionPlan.tasks || actionPlan.tasks.length === 0) &&
        (!actionPlan.interventions || actionPlan.interventions.length === 0)
          ? actionPlanFormErrors['tasksAndInterventions']
          : ''
    };

    const newTasks = [...actionPlan.tasks].map((task) => {
      const newErr = { ...task.errorMessage };

      if (!task.title) {
        newErr.title = actionPlanFormErrors['title'];
      }

      if (!task.dueDate) {
        newErr.dueDate = actionPlanFormErrors['dueDate'];
      }

      if (task.isEdit) {
        newErr.editMode = task.isOld ? actionPlanFormErrors['editMode'] : actionPlanFormErrors['addMode'];
      }

      task.errorMessage = newErr;

      return task;
    });
    const newInterventions = [...actionPlan.interventions].map((item) => {
      if (item.noAction?.reason) {
        return item;
      }

      const newErr = { ...item.errorMessage };

      if (!item.dueDate) {
        newErr.dueDate = actionPlanFormErrors['dueDate'];
      }
      if (!item.selectedInterventionIds || item.selectedInterventionIds.length === 0) {
        newErr.selectedInterventionIds = actionPlanFormErrors['interventions'];
      }
      item.errorMessage = newErr;

      return item;
    });

    handleChangeValues('interventions', newInterventions, false);
    handleChangeValues('tasks', newTasks, false);
    setErrorMessage(newError);

    return (
      !Object.values(newError).some((err) => err) &&
      !newTasks.some(({ errorMessage }) => Object.values(errorMessage || {}).some((err) => err)) &&
      !newInterventions.some(({ errorMessage }) => Object.values(errorMessage || {}).some((err) => err))
    );
  };

  const checkIsTaskValid = (task: ActionPlanTaskItem, index: number) => {
    const newErr = { ...task.errorMessage };

    newErr.editMode = '';

    if (!task.title) {
      newErr.title = actionPlanFormErrors['title'];
    }

    if (!task.dueDate) {
      newErr.dueDate = actionPlanFormErrors['dueDate'];
    }

    const newTasks = [...actionPlan.tasks];

    newTasks[index].errorMessage = newErr;

    handleChangeValues('tasks', newTasks);

    return !Object.values(newErr).some((err) => !!err);
  };

  const checkIsInterventionValid = (int: ActionPlanInterventionItem, index: number) => {
    if (int.noAction?.reason) {
      return true;
    }

    const newErr = { ...int.errorMessage };

    newErr.editMode = '';

    if (!int.dueDate) {
      newErr.dueDate = actionPlanFormErrors['dueDate'];
    }
    if (!int.selectedInterventionIds || int.selectedInterventionIds.length === 0) {
      newErr.selectedInterventionIds = actionPlanFormErrors['interventions'];
    }

    const newInterventions = [...actionPlan.interventions];

    newInterventions[index].errorMessage = newErr;

    handleChangeValues('interventions', newInterventions);

    return !Object.values(newErr).some((err) => !!err);
  };

  const splitInterventionsToSubmit = (interventions: ActionPlanInterventionItem[]) => {
    const finalInterventions: ActionPlanIntervention[] = [];
    interventions.forEach((item) => {
      if (item.noAction) {
        delete item.interventionId;
        finalInterventions.push(item);
      } else {
        const groupIntervention =
          item.selectedInterventionIds?.map((id, index) => {
            const foundIntervention = interventionOptions.find((int) => int._id === id);
            const obj = {
              ...(item as ActionPlanIntervention),
              ...(item.subInterventions && item.subInterventions[index]),
              name: foundIntervention?.name || item.focusArea || '',
              description: foundIntervention?.description || '',
              interventionId: id
            };
            delete obj._id;
            return obj;
          }) || [];
        finalInterventions.push(...groupIntervention);
      }
    });
    return finalInterventions;
  };

  const onSubmitActionPlan = async () => {
    if (!checkIsValid()) {
      return;
    }

    setPublishButtonStatus('active');

    try {
      const callingPlan = await putActionPlan({
        token,
        clientRecordId: recordId,
        actionPlanId,
        body: { ...actionPlan, interventions: splitInterventionsToSubmit(actionPlan.interventions) }
      });
      const createdActionPlan: ActionPlanInterface = await callingPlan.json();
      notification.success({
        message: `Action plan updated.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setPublishButtonStatus('finished');
      navigate(`${CLIENTS.BASE}/${recordId}/action-plans/${createdActionPlan._id}`);
    } catch (ex) {
      console.error(ex);

      setPublishButtonStatus('');

      notification.error({
        message: 'Fail to update action plan.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };
  return (
    <HelmetWrapper title="CORDS - Action Plan">
      <ContentLayout className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Edit Action Plan</div>
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={handleClickBackBtn}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                View Plan List
              </div>
            </div>
          </div>
          <Button
            className={styles.addButton}
            disabled={isUploadingAttachments && isActionPlanLoading}
            status={publishButtonStatus}
            onClick={onSubmitActionPlan}
          >
            Update Action Plan
          </Button>
        </div>
        {isActionPlanLoading ? (
          <Skeleton />
        ) : (
          <ActionPlanForm
            actionPlan={actionPlan}
            clientRecordId={recordId}
            errorMessage={errorMessage}
            checkIsInterventionValid={checkIsInterventionValid}
            checkIsTaskValid={checkIsTaskValid}
            onChangeValues={handleChangeValues}
            onChangeUploadingAttachments={setIsUploadingAttachments}
          />
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ActionPlanEditor;
