export const massageCORDSActivityLabel = (data: any[]) => {
  const getCORDSActivityLabel = (v: string) => {
    v = v
      .replaceAll('Practitioner', 'Wellbeing Team')
      .replaceAll('Client', 'Student')
      .replaceAll('Patient', 'Student')
      .replaceAll('Clinician', 'Wellbeing Team')
      .replaceAll('Admin', 'School Leader')
      .replaceAll('Practice', 'School')
      .replaceAll('Mentor', 'Staff')
      .replaceAll('practitioner', 'wellbeing team')
      .replaceAll('client', 'student')
      .replaceAll('patient', 'student')
      .replaceAll('clinician', 'wellbeing team')
      .replaceAll('admin', 'school leader')
      .replaceAll('practice', 'school')
      .replaceAll('mentor', 'staff');
    return v;
  };

  return data.map((item) => ({
    ...item,
    actor: getCORDSActivityLabel(item.actor),
    description: getCORDSActivityLabel(item.description),
    object: getCORDSActivityLabel(item.object)
  }));
};
