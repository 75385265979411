import Input from 'components/v2/Input/Input';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useField } from 'formik';
import { Props } from 'react-select';
import styles from './Duration.module.scss';
import { timeOptions } from '../../constants';

const isCustom = (value: string) => value === 'custom';

const Duration = () => {
  const [field] = useField('duration');
  const [customInputProps, customMeta] = useField('customDuration');

  const propStyles: Props['styles'] = {
    container: (base) => ({ ...base, margin: 0 }),
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none',
      minHeight: 0,
      minWidth: '150px'
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    dropdownIndicator: (base) => ({ ...base, padding: 0 })
  };

  return (
    <div className={styles.container}>
      <FormikSelect
        name="duration"
        label="Duration of appointment"
        options={timeOptions}
        styles={propStyles}
        className={styles.selectClass}
        labelClass={styles.labelClass}
        required
      />
      {isCustom(field.value) && (
        <div className={styles.customField}>
          <Input
            error={customMeta.touched ? customMeta.error : ''}
            inputProps={{
              ...customInputProps,
              size: 6
            }}
          />
          <div className={styles.customText}>mins</div>
        </div>
      )}
    </div>
  );
};

export default Duration;
