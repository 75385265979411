import { useMemo } from 'react';
import HeaderControl from 'components/TableList/HeaderControl/HeaderControl';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';

interface SurveyFormHeaderProps {
  groupId: string;
  surveyFormId?: string;
  surveyFormMenuList?: SurveyFormResponseList[];
}

const SurveyFormHeader = ({ groupId, surveyFormId, surveyFormMenuList }: SurveyFormHeaderProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();

  const onGoToOtherClick = (id: string) => {
    navigate(`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Survey}/${id}`);
    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  const { currentSurveyForm, previousSurveyForm, nextSurveyForm } = useMemo(() => {
    const surveyFormHeaderItems: {
      currentSurveyForm?: SurveyFormResponseList;
      previousSurveyForm?: SurveyFormResponseList;
      nextSurveyForm?: SurveyFormResponseList;
    } = { currentSurveyForm: undefined, previousSurveyForm: undefined, nextSurveyForm: undefined };

    if (surveyFormMenuList) {
      const filterIsSentOnly = surveyFormMenuList.filter((obj) => obj.isSent);
      const currentSurveyForm = filterIsSentOnly.find((surveyFormItem) => surveyFormItem._id === surveyFormId);

      if (currentSurveyForm) {
        surveyFormHeaderItems.currentSurveyForm = currentSurveyForm;

        const currentIndex = filterIsSentOnly.indexOf(currentSurveyForm);

        if (currentIndex > -1) {
          surveyFormHeaderItems.previousSurveyForm = filterIsSentOnly[currentIndex - 1];
          surveyFormHeaderItems.nextSurveyForm = filterIsSentOnly[currentIndex + 1];
        }
      }
    }

    return surveyFormHeaderItems;
  }, [surveyFormId, surveyFormMenuList]);

  return (
    <HeaderControl
      current={currentSurveyForm}
      previous={previousSurveyForm}
      next={nextSurveyForm}
      onGoToOtherClick={onGoToOtherClick}
    />
  );
};

export default SurveyFormHeader;
