import { Modal } from 'antd';
import InvoiceView from 'components/InvoiceView/InvoiceView';
import moment from 'moment';
import { useRef } from 'react';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { Invoice } from '../../../../interface';
import InvoiceActionMenu from '../InvoiceActionMenu/InvoiceActionMenu';
import styles from './InvoiceModal.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const MONEY_SYMBOL = GAE_REGION === 'au' ? '$' : GAE_REGION === 'gb' ? '£' : undefined;

const STATUS_LABELS = {
  draft: 'Draft',
  issued: 'Issued',
  overdue: 'Overdue',
  markedPaid: 'Marked Paid',
  confirmPaid: 'Confirm Paid',
  closed: 'Closed'
};

interface InvoiceModalProps {
  invoice?: Invoice;
  visible: boolean;
  onChangeStatus: (_id: string, status: 'closed' | 'confirmPaid', groupDetail?: Invoice['group']) => void;
  onClose: () => void;
  onResendInvoice: (_id: string, groupDetail?: Invoice['group']) => void;
  isInvoiceActionProcessing: boolean;
}

const InvoiceModal = ({
  invoice,
  visible,
  onChangeStatus,
  onClose,
  onResendInvoice,
  isInvoiceActionProcessing
}: InvoiceModalProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <Modal bodyStyle={{ padding: 0 }} width={768} footer={null} visible={visible} destroyOnClose onCancel={onClose}>
      <div className={styles.title}>Invoice Overview</div>
      {invoice && (
        <>
          <div className={styles.invoiceSummary}>
            <div className={styles.titles}>
              <span className={styles.item}>Invoice ID</span>
              <span className={styles.item}>Date sent</span>
              <span className={styles.item}>Description</span>
              <span className={styles.item}>Amount</span>
              <span className={styles.item}>Status</span>
              <span className={styles.item}>Action</span>
            </div>
            <div className={styles.values}>
              <div className={styles.invoiceId}>{invoice.invoiceId}</div>
              <span className={styles.issueDate}>
                {moment(invoice.issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}
              </span>
              <span className={styles.description}>
                {invoice.description
                  ? invoice.description.length > 70
                    ? `${invoice.description.slice(0, 70)}...`
                    : invoice.description
                  : '-'}
              </span>
              <span className={styles.invoiceAmount}>
                {MONEY_SYMBOL}
                {invoice.invoiceAmount.toFixed(2)}
              </span>
              <span className={`${styles.status} ${styles[invoice.status]}`}>{STATUS_LABELS[invoice.status]}</span>
              <div ref={menuRef} className={styles.actionButtonContainer}>
                <InvoiceActionMenu
                  status={invoice.status}
                  invoiceId={invoice._id}
                  isInvoiceActionProcessing={isInvoiceActionProcessing}
                  onChangeStatus={onChangeStatus}
                  onResendInvoice={onResendInvoice}
                  groupInvoiceDetail={invoice.group}
                />
              </div>
            </div>
          </div>
          <div className={styles.invoiceView}>
            <InvoiceView
              clientRecord={invoice.clientRecord}
              clinician={invoice.clinician}
              description={invoice.description}
              discount={invoice.discount}
              dueDate={invoice.dueDate}
              invoiceId={invoice.invoiceId}
              issueDate={invoice.issueDate}
              items={invoice.items}
              paymentMethods={invoice.paymentMethods}
              practice={invoice.practice}
              taxRate={invoice.taxRate}
              selectedGroup={invoice.group}
              medicare={invoice.medicare}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default InvoiceModal;
