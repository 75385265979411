import { Avatar } from 'antd';
import classNames from 'classnames';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SearchBar from 'components/SearchBar/SearchBar';
import { useEffect, useState } from 'react';
import DetailsModal from './components/DetailsModal/DetailsModal';
import styles from './InterventionLibrary.module.scss';
import columnStyles from './InterventionLibraryColumn.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchInterventionLibrary } from './hooks/useFetchInterventionLibrary';
import { useFetchPractitionerList } from 'components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { InterventionLibraryDeliveryMode, InterventionLibraryInterface } from './interfaces/InterventionLibrary';
import ReadMore from 'components/ReadMore/ReadMore';

const InterventionLibrary = () => {
  const { token } = useGetAccessToken();
  const [viewingItem, setViewingItem] = useState<InterventionLibraryInterface>();
  const { practitionersList, isPractitionersListLoading } = useFetchPractitionerList(token);

  const { interventionLibrary, isInterventionLibraryLoading, fetchInterventionLibrary } = useFetchInterventionLibrary(
    token
  );
  const [interventionSearchVal, setInterventionSearchVal] = useState<string>('');
  const [interventionList, setInterventionList] = useState<InterventionLibraryInterface[]>(interventionLibrary);

  const handleSearch = (searchValue: string) => {
    const searchVal = searchValue.toLowerCase() || '';
    setInterventionSearchVal(searchValue);
    if (searchVal.length > 0) {
      const newList = interventionLibrary.filter((interventionObj) =>
        interventionObj.name.toLowerCase().includes(searchVal)
      );
      setInterventionList(newList);
    } else {
      setInterventionList(interventionLibrary);
    }
  };

  useEffect(() => {
    handleSearch(interventionSearchVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interventionLibrary, isInterventionLibraryLoading, interventionSearchVal]);

  return (
    <HelmetWrapper title={'CORDS - Intervention Library'}>
      <ContentLayout className={styles.container}>
        {viewingItem && (
          <DetailsModal
            interventionLibraryItem={viewingItem}
            visible={!!viewingItem}
            onClose={() => setViewingItem(undefined)}
            onSaveChanges={() => {
              fetchInterventionLibrary(token);
              setViewingItem(undefined);
            }}
            practitionerList={practitionersList}
            isPractitionersListLoading={isPractitionersListLoading}
          />
        )}
        <div className={styles.headerContainer}>
          <div className={styles.title}>Intervention Library</div>
          <div className={styles.searchBarWrapper}>
            <div className={styles.searchBarTitle}>SEARCH FOR INTERVENTION:</div>
            <div className={styles.searchBarCard}>
              <SearchBar
                placeholder="Search by name"
                value={interventionSearchVal}
                withSearchButton
                onSearch={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.cardContainer}>
            {isInterventionLibraryLoading ? (
              <div className={styles.loadingContainer}>
                <LoadingCircle />
              </div>
            ) : interventionList.length > 0 ? (
              <>
                <div className={classNames(columnStyles.listTitle, columnStyles.row)}>
                  <div className={classNames(columnStyles.name, columnStyles.title)}>
                    <div className={columnStyles.sortTitleText}>INTERVENTION</div>
                  </div>

                  <div className={classNames(columnStyles.description, columnStyles.title, columnStyles.noSort)}>
                    DESCRIPTION
                  </div>
                  <div className={classNames(columnStyles.suitableFor, columnStyles.title)}>
                    <div className={columnStyles.sortTitleText}>SUITABLE FOR</div>
                  </div>
                  <div className={classNames(columnStyles.capability, columnStyles.title)}>
                    <div className={columnStyles.sortTitleText}>CAPABILITY</div>
                  </div>
                  <div className={classNames(columnStyles.trainedStaff, columnStyles.title)}>
                    <div className={columnStyles.sortTitleText}>TRAINED STAFF</div>
                  </div>
                  <div className={classNames(columnStyles.buttonCol)} />
                </div>
                {interventionList.map((item, index) => (
                  <div key={index} className={columnStyles.row}>
                    <div className={columnStyles.name}>{item.name}</div>
                    <div className={columnStyles.description}>
                      <ReadMore bodyClass={styles.readMoreDescription} text={item.description} maxHeight={60} />
                    </div>
                    <div className={columnStyles.suitableFor}>
                      <div className={columnStyles.pills}>
                        {item.tags.map((i) => (
                          <div className={columnStyles.pill} key={i}>
                            {i.toUpperCase()}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={columnStyles.capability}>
                      <i
                        className={`material-icons ${
                          !item.deliveryMode || item.deliveryMode === InterventionLibraryDeliveryMode.None
                            ? styles.noCheckIcon
                            : styles.checkIcon
                        }`}
                      >
                        done
                      </i>
                    </div>

                    <div className={columnStyles.trainedStaff}>
                      {item.deliveryMode !== InterventionLibraryDeliveryMode.None &&
                        (item.deliveryMode === InterventionLibraryDeliveryMode.WholeOfSchool ? (
                          <>Whole of School Approach</>
                        ) : (
                          <>
                            {item.deliveryMode !== InterventionLibraryDeliveryMode.External && item.clinicianAuth0Ids && (
                              <div className={styles.internalStaff}>
                                {item.clinicianAuth0Ids.length}
                                <Avatar.Group size={40} maxCount={5}>
                                  {item.clinicianAuth0Ids.map((id, index) => {
                                    const profile = practitionersList.find((profile) => profile._id === id);
                                    return profile ? <Avatar key={index} src={profile.avatar} /> : null;
                                  })}
                                </Avatar.Group>
                              </div>
                            )}
                            {item.deliveryMode !== InterventionLibraryDeliveryMode.Internal && item.externalParty && (
                              <div>External: {item.externalParty.join(', ')}</div>
                            )}
                          </>
                        ))}
                    </div>

                    <div className={columnStyles.buttonCol}>
                      <div className={styles.itemButton} onClick={() => setViewingItem(item)}>
                        <i className="material-icons-outlined">arrow_forward</i>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.noData}>No intervention library found</div>
            )}
          </div>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InterventionLibrary;
