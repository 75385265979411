import styles from './PractitionerAssessments.module.scss';
import { Skeleton } from 'antd';
import { ClinicalAssessmentResponseCounts } from 'pages/PractitionersListing/hooks/useGetAdhocAssessmentCountsByClinicianId';

interface Props {
  assessmentCounts: ClinicalAssessmentResponseCounts;
  isLoading: boolean;
}

const PractitionerAssessments = ({ isLoading, assessmentCounts }: Props) => {
  const { done, pending } = assessmentCounts;

  return (
    <div className={styles.container}>
      {isLoading ? (
        <>
          <Skeleton.Button active />
          <Skeleton.Button active />
        </>
      ) : (
        <>
          <div className={styles.outstanding}>
            <div className={styles.value}>{done}</div>
            <div className={styles.label}>Total done</div>
          </div>
          <div className={styles.totalDone}>
            <div className={styles.value}>{pending}</div>
            <div className={styles.label}>Outstanding</div>
          </div>
        </>
      )}
    </div>
  );
};

export default PractitionerAssessments;
