import styles from './UKTerms.module.scss';

const UKTerms = () => {
  // temp host in bucket, future might need to think move to strapi.
  return (
    <div className={styles.container}>
      <iframe
        title={'termOfUse'}
        height={'300px'}
        width={'100%'}
        frameBorder={'0'}
        src={
          'https://storage.googleapis.com/tacklit-email-assets/Tacklit_Practitioner_End_User_Licence_Agreement_v1.1.html'
        }
      />
    </div>
  );
};

export default UKTerms;
