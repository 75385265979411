import { Button, Dropdown } from 'antd';
import classNames from 'classnames';
import FilterCheckList, {
  FilterCheckListItem,
  FilterCheckListProps,
  FilterRadioListItem
} from 'components/FilterCheckList/FilterCheckList';
import { ReactNode, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './FilterDropDown.module.scss';
import ReactTooltip from 'react-tooltip';

interface FilterDropdownProps {
  id: string;
  children: ReactNode;
  enableButtonLabelItemsColor?: boolean;
  hasMoreData?: boolean;
  icon?: ReactNode;
  isInactive?: boolean;
  loading?: boolean;
  loadMore?: () => void;
  menuItems: FilterCheckListItem[];
  onChangeItem: FilterCheckListProps['onChangeItem'];
  searchable?: boolean;
  selectedFilterList: FilterCheckListItem[];
  showSearchIcon?: boolean;
  showToggleAllButtons?: boolean;
  filterRadioListItems?: FilterRadioListItem[];
  selectedRadioItem?: string;
  onChangeRadioItem?: (selected: string) => void;
  /**
   * `isFetchingMore` is the loading state for infinity scroll when they are fetching more
   */
  isFetchingMore?: boolean;
  enableSelectedBackground?: boolean;
  onClearFilter?: () => void;
  setSearchText?: (value: string) => void;
}

const FilterDropdown = ({
  id,
  children,
  enableButtonLabelItemsColor,
  hasMoreData,
  icon,
  isInactive,
  loading,
  loadMore,
  menuItems,
  onChangeItem,
  searchable,
  selectedFilterList,
  showSearchIcon,
  showToggleAllButtons,
  filterRadioListItems,
  selectedRadioItem,
  onChangeRadioItem,
  isFetchingMore,
  enableSelectedBackground,
  onClearFilter,
  setSearchText
}: FilterDropdownProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRender = () => (
    <FilterCheckList
      hasMoreData={hasMoreData}
      items={menuItems}
      loading={loading}
      loadMore={loadMore}
      onChangeItem={onChangeItem}
      searchable={searchable}
      selectedFilterList={selectedFilterList}
      showToggleAllButtons={showToggleAllButtons}
      filterRadioListItems={filterRadioListItems}
      selectedRadioItem={selectedRadioItem}
      onChangeRadioItem={onChangeRadioItem}
      isFetchingMore={isFetchingMore}
      setSearchText={setSearchText}
    />
  );

  const selectedRadioItemObject = filterRadioListItems?.find((i) => i._id === selectedRadioItem);

  let selectedItemsName = enableButtonLabelItemsColor
    ? selectedFilterList.map((i, index) => (
        <div
          key={index}
          className={classNames(styles.buttonLabelItemsColor)}
          style={{ backgroundColor: i.backgroundColor, color: i.fontColor }}
        >
          {i.name}
        </div>
      ))
    : [...selectedFilterList, ...(selectedRadioItemObject ? [selectedRadioItemObject] : [])]
        .map((i) => i?.name)
        .join(' | ');

  const passedInButtonLabelWithCount = (
    <>
      {children}
      <div
        className={classNames(
          styles.selectedItemsCount,
          isEdgeAdminView
            ? classNames(styles.adminColor, isDropdownOpen && styles.adminOpen)
            : classNames(styles.nonAdminColor, isDropdownOpen && styles.nonAdminOpen)
        )}
      >
        {selectedFilterList.length}
      </div>
    </>
  );

  const selectedItems = selectedFilterList.length < 4 ? selectedItemsName : passedInButtonLabelWithCount;

  const baseDropdown = (
    <Dropdown
      disabled={isInactive}
      overlayClassName={styles.container}
      dropdownRender={dropdownRender}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      open={isDropdownOpen}
      trigger={['click']}
    >
      <Button
        shape="round"
        icon={showSearchIcon && !selectedFilterList.length && icon}
        className={classNames(
          styles.button,
          isEdgeAdminView
            ? classNames(
                styles.adminButton,
                isDropdownOpen && styles.adminButtonOpen,
                enableSelectedBackground && selectedFilterList.length > 0 && styles.adminSelected
              )
            : classNames(
                styles.nonAdminButton,
                isDropdownOpen && styles.nonAdminButtonOpen,
                enableSelectedBackground && selectedFilterList.length > 0 && styles.nonAdminSelected
              ),
          isInactive && styles.disabledButton
        )}
      >
        {selectedFilterList.length > 0 ? selectedItems : children}
        <div className={styles.arrowWrapper}>
          {isDropdownOpen ? (
            <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_less</span>
          ) : !onClearFilter || selectedFilterList.length < 1 ? (
            <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_more</span>
          ) : (
            <div
              className={styles.clearButton}
              onClick={(event) => {
                event.stopPropagation();
                onClearFilter && onClearFilter();
              }}
            >
              <i className={`material-icons-outlined ${styles.clearIcon}`}>close</i>
            </div>
          )}
        </div>
      </Button>
    </Dropdown>
  );

  if (isInactive) {
    return (
      <>
        <ReactTooltip
          effect={'solid'}
          id={id}
          backgroundColor={'#DDDDDD'}
          className={styles.tooltips}
          textColor={'#343434'}
          // padding={'6px'}
        >
          You can only filter on activated columns
        </ReactTooltip>
        <div data-tip data-for={id} className={styles.disabledButtonWrapper}>
          {baseDropdown}
        </div>
      </>
    );
  }

  return baseDropdown;
};

export default FilterDropdown;

// for onChangeItem usage
export const onToggleFilter = <T extends FilterCheckListItem>(
  list: T[],
  selectedFilters: T[],
  setSelectedFilters: (v: T[]) => void
) => {
  return (value: { item?: FilterCheckListItem; toggleAllValue?: boolean; color?: string; searchValue?: string }) => {
    if (value.toggleAllValue !== undefined) {
      setSelectedFilters(
        value.toggleAllValue
          ? [
              ...selectedFilters,
              ...list
                .filter((j) => !selectedFilters.find((i) => i._id === j._id))
                .filter((j) => j.name.toLowerCase().includes((value.searchValue || '').toLowerCase()))
            ]
          : [
              ...selectedFilters.filter(
                (item) => !item.name.toLowerCase().includes((value.searchValue || '').toLowerCase())
              )
            ]
      );
    } else if (value.item) {
      if (!selectedFilters.find((i) => i._id === value.item!._id)) {
        const selectedItem = list.find((i) => i._id === value.item!._id);
        const massageItem: any = { ...selectedItem };
        if (selectedItem) {
          setSelectedFilters([...selectedFilters, massageItem]);
        }
      } else {
        setSelectedFilters(selectedFilters.filter((item) => item._id !== value.item!._id));
      }
    }
  };
};
