import { useEffect, useState } from 'react';

import styles from './QuestionListWithInputField.module.scss';

export interface QuestionListWithCheckBoxProps {
  id: string;
  group: string;
  onAddQuestion: any;
  onChangeLastQuestion: any;
  onClickSave: any;
}

const QuestionListWithInputField = ({ id, group, onAddQuestion, onChangeLastQuestion, onClickSave }: QuestionListWithCheckBoxProps) => {
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [inputValue, setInputQValue] = useState('');
  const [showAddBtn, setShowAddBtn] = useState(false);

  useEffect(() => {
    setCheckBoxValue(false);
    setInputQValue('');
  }, [onClickSave]);

  const addQuestion = () => {
    const value = {
      id: id,
      stem: inputValue,
      isChecked: checkBoxValue,
      isDraftQ: false
    };
    onAddQuestion(value);
    setCheckBoxValue(false);
    setInputQValue('');
    setShowAddBtn(false);
  };

  const onChangeInputField = (val: string) => {
    setShowAddBtn(val.length > 2);

    const newQSet = {
      id: id,
      stem: val,
      isChecked: checkBoxValue,
      isDraftQ: true
    };

    setInputQValue(val);
    onChangeLastQuestion(newQSet);
  };

  const onChangeCheckBoxField = (val: boolean) => {
    const newQSet = {
      id: id,
      stem: inputValue,
      isChecked: val,
      isDraftQ: true
    };
    onChangeLastQuestion(newQSet);
    setCheckBoxValue(val);
  };

  return (
    <div className={styles.container}>
      <label className={styles.questionBox} htmlFor={id}>
        <input
          className={styles.checkbox}
          id={id}
          type={'checkbox'}
          name={group}
          checked={checkBoxValue}
          onChange={(e) => onChangeCheckBoxField(e.target.checked)}
        />
      </label>
      <input
        className={styles.textField}
        id={id}
        type={'text'}
        autoComplete={'off'}
        name={group}
        value={inputValue}
        placeholder={'Add a custom question'}
        onChange={(e) => onChangeInputField(e.target.value)}
      />
      {
        showAddBtn &&
        <div className={styles.addBtnWrapper} onClick={() => addQuestion()}>
          <i className={`material-icons ${styles.plusIcon}`}>add_circle_outline</i>
          Add
        </div>
      }
    </div>
  );
};

export default QuestionListWithInputField;
