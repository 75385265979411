import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { getActionPlanById } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { ActionPlanInterface } from 'MHH/pages/ActionPlanCreator/interfaces';

export type ActionPlanResponse = ActionPlanInterface & {
  ownerProfile: { avatar: string; email: string; mobileNumber?: string; name: string };
};
export const useFetchActionPlan = (token: string, recordId: string, actionPlanId: string) => {
  const [actionPlanData, setActionPlanData] = useState<ActionPlanResponse>();
  const [isActionPlanLoading, setIsActionPlanLoading] = useState(true);

  const fetchActionPlan = useCallback(
    async (token: string) => {
      try {
        const callActionPlans = await getActionPlanById(token, recordId, actionPlanId);

        const actionPlan = await callActionPlans.json();

        if (actionPlan) {
          setActionPlanData(actionPlan);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get your action plans.' });
      }

      setIsActionPlanLoading(false);
    },
    [actionPlanId, recordId]
  );

  useEffect(() => {
    if (token) {
      fetchActionPlan(token);
    }
  }, [token, fetchActionPlan]);

  return { actionPlanData, isActionPlanLoading, setActionPlanData, fetchActionPlan };
};
