import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { PathwayStageItem, PathwayStageList, StageType } from '../../../../interface';
import styles from './StageSelect.module.scss';

interface StageSelectProps {
  materialStyle?: boolean;
  stageFormFieldData?: PathwayStageItem;
  stageItemList?: PathwayStageList;
  moveToStage?: PathwayStageItem | null;
  onMoveToStage: (selectStage?: PathwayStageItem | null) => void;
}

const StageSelect = ({
  materialStyle,
  stageFormFieldData,
  stageItemList,
  moveToStage,
  onMoveToStage
}: StageSelectProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);

  const anytimeStageSelectList = stageItemList?.anytime.find((obj) => obj._id === stageFormFieldData?._id)
    ? stageItemList?.anytime.filter((obj) => obj._id !== stageFormFieldData?._id)
    : stageItemList?.anytime;

  const hideDropDown = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', hideDropDown);

    return () => {
      document.removeEventListener('mousedown', hideDropDown);
    };
  });

  const handleSelectStage = (stageId?: string) => {
    if (stageId === undefined) {
      onMoveToStage(null);
    } else {
      const sequentialStageData = stageItemList?.sequential.find((stage) => stage._id === stageId);
      const anytimeStageData = stageItemList?.anytime.find((stage) => stage._id === stageId);
      onMoveToStage(anytimeStageData ?? sequentialStageData);
    }
    setShowList(false);
  };

  const prevStageSequence = stageFormFieldData?.sequence
    ? stageFormFieldData.sequence - 1
    : stageFormFieldData?.sequence;
  const prevStageItem = stageItemList?.sequential.find((sequenceObj) => sequenceObj.sequence === prevStageSequence);

  const nextStageSequence = stageFormFieldData?.sequence
    ? stageFormFieldData.sequence + 1
    : stageFormFieldData?.sequence;
  const nextStageItem = stageItemList?.sequential.find((sequenceObj) => sequenceObj.sequence === nextStageSequence);

  return (
    <div ref={node} className={classNames(styles.container)}>
      <div
        className={classNames(materialStyle ? styles.selectedMaterialBox : styles.selectedBox)}
        onClick={() => {
          setShowList(!showList);
        }}
      >
        {moveToStage === undefined ? 'Select Stage' : moveToStage === null ? 'Leave Stage Blank' : moveToStage.name}
        <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
      </div>
      <div className={styles.listWrapper}>
        <div className={showList ? styles.listShow : styles.listHide}>
          <div className={styles.itemWrapper}>
            <div className={styles.emptyBlank}>
              <div
                className={moveToStage === null ? styles.itemSelected : styles.item}
                onClick={() => {
                  handleSelectStage();
                }}
              >
                Leave Stage Blank
              </div>
            </div>

            <div className={styles.stageTypeContainer}>
              {stageFormFieldData?.stageType === StageType.Sequential ? (
                <>
                  {prevStageItem && (
                    <div className={styles.stageContainer}>
                      <div className={styles.label}>PREVIOUS STAGE</div>
                      <div className={styles.stageOptions}>
                        <div
                          className={moveToStage?._id === prevStageItem._id ? styles.itemSelected : styles.stageItem}
                          onClick={() => handleSelectStage(prevStageItem._id)}
                        >{`${prevStageSequence}. ${prevStageItem.name}`}</div>
                      </div>
                    </div>
                  )}
                  {nextStageItem && (
                    <div className={styles.stageContainer}>
                      <div className={styles.label}>NEXT STAGE</div>
                      <div className={styles.stageOptions}>
                        <div
                          className={moveToStage?._id === nextStageItem._id ? styles.itemSelected : styles.stageItem}
                          onClick={() => handleSelectStage(nextStageItem._id)}
                        >
                          {`${nextStageSequence}. ${nextStageItem.name}`}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {stageItemList?.sequential && stageItemList?.sequential.length > 0 && (
                    <div className={styles.stageContainer}>
                      <div className={styles.label}>SEQUENTIAL STAGE</div>
                      <div className={styles.stageOptions}>
                        {stageItemList?.sequential.map((stageObj, index) => (
                          <div
                            key={index}
                            className={moveToStage?._id === stageObj._id ? styles.itemSelected : styles.stageItem}
                            onClick={() => handleSelectStage(stageObj._id)}
                          >
                            {`${stageObj.sequence}. ${stageObj.name}`}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {anytimeStageSelectList && anytimeStageSelectList.length > 0 && (
              <div className={styles.anytimeStageWrapper}>
                <div className={styles.label}>ANYTIME STAGE</div>
                {anytimeStageSelectList.map((anytimeObj, index) => (
                  <div
                    key={index}
                    className={moveToStage?._id === anytimeObj._id ? styles.itemSelected : styles.anytimeStageItem}
                    onClick={() => handleSelectStage(anytimeObj._id)}
                  >
                    {anytimeObj.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageSelect;
