import { useState, ChangeEvent } from 'react';
import styles from './GroupCaseNoteAttachment.module.scss';
import { GroupCaseNoteInterface } from '../../interfaces';
import { notification } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { resizeAndCropImage } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteAttachmentBtn/components/resizeCropImage';
import {
  uploadAttachmentByGroupId,
  uploadAttachmentThumbnailByGroupId
} from 'utils/http/DocumentService/Attachments/groupAttachment';

interface GroupCaseNoteAttachmentProps {
  caseNoteAttachmentData: GroupCaseNoteInterface['attachments'];
  token: string;
  groupId: string;
  uploadStatus: any;
  onChangeCaseNoteAttachment: any;
}

const GroupCaseNoteAttachment = ({
  caseNoteAttachmentData,
  token,
  groupId,
  uploadStatus,
  onChangeCaseNoteAttachment
}: GroupCaseNoteAttachmentProps) => {
  const [uploadAttachmentLoading, setUploadAttachmentLoading] = useState(false);

  const MAX_FILE_SIZE = 19 * 1024 * 1024;

  const handleUploadAttachmentClick = () => {
    document.getElementById('patient-upload-attachment')?.click();
  };

  const handleUploadAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files.length === 0) {
        return;
      }
      setUploadAttachmentLoading(true);
      uploadStatus(true);

      const uploadedAttachments: GroupCaseNoteInterface['attachments'][number][] = [];

      try {
        for (let i = 0; i < files.length; i++) {
          const currentFile = files[i];

          if (currentFile.size > MAX_FILE_SIZE) {
            notification.error({ message: `File ${currentFile.name} is too big` });
            continue;
          }

          const payload: Record<string, any> = {};

          try {
            const uploadedAttachmentDetail = await uploadAttachmentByGroupId(token, groupId, currentFile, payload);

            if (/(image\/)\w+/g.test(currentFile.type)) {
              const attachmentId = uploadedAttachmentDetail._id || '';
              const dataUrl = await resizeAndCropImage(currentFile).catch((ex) => console.error(ex));

              if (!dataUrl) {
                notification.error({ message: 'Something went wrong while trying to compress your image.' });
                continue;
              }

              const dataArray = dataUrl.split(',');
              const mimeType = dataArray[0].match(/:(.*?);/)?.[1];
              const dataStrings = atob(dataArray[1]);
              let dataLength = dataStrings.length;
              const actualData = new Uint8Array(dataLength);

              while (dataLength--) {
                actualData[dataLength] = dataStrings.charCodeAt(dataLength);
              }

              const thumbnailFile = new File([actualData], `${currentFile.name}-thumbnail`, { type: mimeType });

              const responseWithThumbnail = await uploadAttachmentThumbnailByGroupId(
                token,
                groupId,
                attachmentId,
                thumbnailFile
              );

              uploadedAttachments.push({
                _id: responseWithThumbnail._id,
                title: responseWithThumbnail.title
              });
            } else {
              uploadedAttachments.push({
                _id: uploadedAttachmentDetail._id,
                title: uploadedAttachmentDetail.title
              });
            }
          } catch (ex) {
            notification.error({ message: `Failed to upload file ${currentFile.name}` });
            continue;
          }
        }

        if (uploadedAttachments.length > 0) {
          notification.success({
            message: `Successfully uploaded ${uploadedAttachments.length} ${
              uploadedAttachments.length === 1 ? 'attachment' : 'attachments'
            }.`,
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          const newAttachment = [...caseNoteAttachmentData, ...uploadedAttachments];
          onChangeCaseNoteAttachment(newAttachment);
          setUploadAttachmentLoading(false);
          uploadStatus(false);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to upload your attachments' });
        setUploadAttachmentLoading(false);
        uploadStatus(false);
      }
    }
  };

  return (
    <div className={styles.attachmentsContainer}>
      {caseNoteAttachmentData.length > 0 && (
        <>
          <div className={styles.title}>ATTACHMENTS</div>
          {caseNoteAttachmentData.map((attachment: any) => (
            <div className={styles.attachmentContainer} key={attachment._id}>
              <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
              {attachment.title}
            </div>
          ))}
        </>
      )}
      {uploadAttachmentLoading ? (
        <div className={styles.uploadingLabel}>
          <div className={styles.icon}>
            <LoadingCircle />
          </div>
          Uploading...
        </div>
      ) : (
        <div className={styles.addAttachmentsButton} onClick={handleUploadAttachmentClick}>
          <i className={`material-icons-outlined ${styles.icon}`}>add</i>
          add attachment
        </div>
      )}
      <input
        className={styles.addAttachmentInput}
        id="patient-upload-attachment"
        type="file"
        accept="image/png,image/jpeg,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        multiple
        onChange={handleUploadAttachment}
      />
    </div>
  );
};

export default GroupCaseNoteAttachment;
