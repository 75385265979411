import { BentResponse } from 'bent';
import { Collaborator } from 'interfaces/Reports/report';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

export const getReportList = (token: string, queryParam?: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/reports${queryParam ?? ''}`
  ) as Promise<BentResponse>;

export const getReportListByAdmin = (token: string, queryParam?: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/reports${queryParam ?? ''}`
  ) as Promise<BentResponse>;

export const getReportByAdmin = (token: string, reportId: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(`/reports/${reportId}`) as Promise<BentResponse>;

export const getReport = (token: string, reportId: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/reports/${reportId}`
  ) as Promise<BentResponse>;

export const postReport = (token: string, body: Record<string, any>) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })('/reports', body) as Promise<BentResponse>;

export const putReport = (token: string, reportId: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(
    `/reports/${reportId}`,
    body
  ) as Promise<BentResponse>;

export const putPublish = (token: string, reportId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(`/reports/${reportId}:publish`);

export const putUnpublish = (token: string, reportId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(`/reports/${reportId}:unpublish`);

export const putShare = (token: string, reportId: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(
    `/report/${reportId}/share`,
    body
  ) as Promise<BentResponse>;

export const putRevokeAccessor = (token: string, reportId: string, accessorId: string) =>
  httpClient('PUT', url, 204, { Authorization: `Bearer ${token}` })(
    `/report/${reportId}/accessor/${accessorId}/revoke`
  );

export const putReportContactDetails = (token: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(`/contact-details`, body);

export const getReportContactDetails = (token: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(`/contact-details`) as Promise<BentResponse>;

export const getSignature = (token: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })('/signature') as Promise<BentResponse>;

export const putSignature = (token: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })('/signature', body);

export const getHeadingTemplate = (token: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })('/heading-templates') as Promise<BentResponse>;

export const postHeadingTemplate = (token: string, body: Record<string, any>) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })(
    '/heading-template',
    body
  ) as Promise<BentResponse>;

export const deleteHeadingTemplate = (templateId: any, token: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(`/heading-template/${templateId}`);

export const getReportsNotifications = (token: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })('/reports:notifications') as Promise<BentResponse>;

export const dismissNotificationByReportId = (token: string, reportId: string) =>
  httpClient('PATCH', url, 200, { Authorization: `Bearer ${token}` })(
    `/reports/${reportId}:dismiss-notification`
  ) as Promise<BentResponse>;

export const addNewComment = (
  token: string,
  reportId: string,
  body: { _id: string; content: string; position: { top: number; left: number } }
) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(
    `/reports/${reportId}:comment`,
    body
  ) as Promise<BentResponse>;

export const addNewReply = (token: string, reportId: string, commentId: string, body: { content: string }) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(
    `/reports/${reportId}/comments/${commentId}:reply`,
    body
  ) as Promise<BentResponse>;

export const moveComment = (
  token: string,
  reportId: string,
  commentId: string,
  body: { position: { top: number; left: number } }
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/reports/${reportId}/comments/${commentId}:reposition`,
    body
  ) as Promise<BentResponse>;

export const requestReview = (token: string, reportId: string, body: Collaborator[]) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(`/reports/${reportId}:request-review`, body);

export const submitReportReview = (token: string, reportId: string, body: { reviewType: string; message?: string }) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(`/reports/${reportId}:review`, body);

export const backToDraft = (token: string, reportId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(`/reports/${reportId}:draft`);

export const deleteReport = (token: string, reportId: string) =>
  httpClient('DELETE', url, 204, { Authorization: `Bearer ${token}` })(`/reports/${reportId}`);

export const checkInEditSession = (token: string, reportId: string) =>
  httpClient('PATCH', url, 200, { Authorization: `Bearer ${token}` })(
    `/reports/${reportId}:check-in`
  ) as Promise<BentResponse>;

export const checkOutEditSession = (token: string, reportId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(`/reports/${reportId}:check-out`);
