import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

interface DecodedToken {
  sub: string;
  scope: string;
  exp: number;
  'https://tacklit.com/jwt-claims/clinician-email': string;
}

interface ParsedAccountDetails {
  accountId: string;
  email: string;
  token: string;
}

export const useGetAccountDetailsFromToken = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { practiceSlugUrl } = useParams<{ practiceSlugUrl: string }>();
  const PRACTICE_SLUG_URLS = ['frontline', practiceSlugUrl];

  const [accountDetails, setAccountDetails] = useState<ParsedAccountDetails>({
    accountId: '',
    email: '',
    token: ''
  });

  const [expiredTime, setExpiredTime] = useState<number>(moment().unix());

  const redirectToDefaultSignup = () => {
    navigate('/signup');
  };

  const getAccountDetailsFromToken = () => {
    if (!PRACTICE_SLUG_URLS.includes(practiceSlugUrl)) {
      redirectToDefaultSignup();
    }

    try {
      const { token } = queryString.parse(search);

      if (!token || typeof token !== 'string') {
        redirectToDefaultSignup();
        return;
      }

      const decodedToken = jwt_decode<DecodedToken>(token);
      setExpiredTime(decodedToken.exp);

      if (
        !decodedToken.sub ||
        !decodedToken['https://tacklit.com/jwt-claims/clinician-email'] ||
        decodedToken.scope !== 'write:clinicians'
      ) {
        redirectToDefaultSignup();
        return;
      }

      setAccountDetails({
        accountId: decodedToken.sub,
        email: decodedToken['https://tacklit.com/jwt-claims/clinician-email'],
        token
      });
    } catch (ex) {
      console.error(ex);
      redirectToDefaultSignup();
    }
  };

  useEffect(() => {
    getAccountDetailsFromToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { accountDetails, expiredTime };
};
