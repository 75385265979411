import { Fragment, useMemo } from 'react';

import { Question, SubQuestion } from 'components/PatientQuestionForm/AssessmentInterfaces';

import styles from './MultipleAnswers.module.scss';

interface MultipleAnswersProps {
  question: Question;
}

const MultipleAnswers = ({ question }: MultipleAnswersProps) => {
  const subQuestions = useMemo(() => {
    const { options, response } = question;

    if (options) {
      return (options as SubQuestion[]).map((subQuestion) => ({
        ...subQuestion,
        options: subQuestion.options.map(({ key, description }) => ({
          key,
          description,
          checked: (response?.value || false) && response.value[subQuestion.id] === key
        }))
      }));
    } else {
      return [];
    }
  }, [question]);

  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        <div className="question">{question.stem}</div>
        {subQuestions.map(({ id, options, stem }) => (
          <Fragment key={id}>
            <div className={styles.subQuestion}>{stem}</div>
            <div className={styles.answerWrapper}>
              {options.map(({ key, description, checked }) => (
                <div className={checked ? styles.answerBtnChecked : styles.answerBtn} key={key}>
                  {description}
                </div>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default MultipleAnswers;
