import { useState, ChangeEvent } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './GroupAttachments.module.scss';
import { useFetchGroupAttachments } from './hooks/getGroupAttachments';
import LoadingButton from 'components/v2/Button/Button';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { GroupAttachmentInterface } from './interfaces';
import mimetypes from 'mime-types';
import { Modal, notification } from 'antd';
import {
  deleteAttachmentByGroupId,
  downloadAttachmentByGroupId,
  uploadAttachmentByGroupId,
  uploadAttachmentThumbnailByGroupId
} from 'utils/http/DocumentService/Attachments/groupAttachment';
import NoAttachments from 'assets/images/no-attachments.png';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import AttachmentCard from './components/AttachmentCard/AttachmentCard';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

const MAX_FILE_SIZE = 19 * 1024 * 1024;
const MAX_HEIGHT = 140;
const MAX_WIDTH = 200;

interface GroupAttachmentsProps {
  groupId: string;
  isReadOnly?: boolean;
}

const GroupAttachments = ({ groupId, isReadOnly }: GroupAttachmentsProps) => {
  const { token } = useGetAccessToken();
  const { GROUPS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const { groupAttachments, isGroupAttachmentsLoading, setGroupAttachments } = useFetchGroupAttachments(token, groupId);

  const [addAttachmentButtonStatus, setAddAttachmentButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleUploadAttachmentClick = () => {
    document.getElementById('patient-upload-attachment')?.click();
  };

  const resizeAndCropImage = async (file: File) => {
    return new Promise<string>((resolve, reject) => {
      try {
        const image = new Image();

        const canvas = document.createElement('canvas');

        canvas.style.display = 'none';

        image.onload = () => {
          let resizeHeight = image.height;
          let resizeWidth = image.width;

          if (resizeHeight > MAX_HEIGHT) {
            resizeWidth = (MAX_HEIGHT / resizeHeight) * resizeWidth;
            resizeHeight = MAX_HEIGHT;
          }

          if (resizeWidth > MAX_WIDTH) {
            resizeHeight = (MAX_WIDTH / resizeWidth) * resizeHeight;
            resizeWidth = MAX_WIDTH;
          }

          canvas.height = resizeHeight;
          canvas.width = resizeWidth;

          canvas.getContext('2d')?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

          const dataUrl = canvas.toDataURL();

          canvas.remove();
          image.remove();

          resolve(dataUrl);
        };

        image.src = URL.createObjectURL(file);
      } catch (ex) {
        reject(ex);
      }
    });
  };

  const handleUploadAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setAddAttachmentButtonStatus('active');

      const uploadedAttachments: GroupAttachmentInterface[] = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > MAX_FILE_SIZE) {
          notification.error({ message: `File ${currentFile.name} is too big` });
          continue;
        }

        const payload: Record<string, any> = {};

        try {
          const uploadedAttachmentDetail = await uploadAttachmentByGroupId(token, groupId, currentFile, payload);

          if (/(image\/)\w+/g.test(currentFile.type)) {
            const attachmentId = uploadedAttachmentDetail._id || '';
            const dataUrl = await resizeAndCropImage(currentFile).catch((ex) => console.error(ex));

            if (!dataUrl) {
              notification.error({ message: 'Something went wrong while trying to compress your image.' });
              continue;
            }

            const dataArray = dataUrl.split(',');
            const mimeType = dataArray[0].match(/:(.*?);/)?.[1];
            const dataStrings = atob(dataArray[1]);
            let dataLength = dataStrings.length;
            const actualData = new Uint8Array(dataLength);

            while (dataLength--) {
              actualData[dataLength] = dataStrings.charCodeAt(dataLength);
            }

            const thumbnailFile = new File([actualData], `${currentFile.name}-thumbnail`, { type: mimeType });

            const responseWithThumbnail = await uploadAttachmentThumbnailByGroupId(
              token,
              groupId,
              attachmentId,
              thumbnailFile
            );

            uploadedAttachments.push(responseWithThumbnail);
          } else {
            uploadedAttachments.push(uploadedAttachmentDetail);
          }
        } catch (ex) {
          notification.error({ message: `Failed to upload file ${currentFile.name}` });
          continue;
        }
      }

      if (uploadedAttachments.length > 0) {
        setAddAttachmentButtonStatus('finished');

        notification.success({
          message: `Successfully uploaded ${uploadedAttachments.length} ${
            uploadedAttachments.length === 1 ? 'attachment' : 'attachments'
          }.`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });

        setGroupAttachments([...uploadedAttachments, ...groupAttachments]);

        setTimeout(() => setAddAttachmentButtonStatus(''), 1000);
      } else {
        setAddAttachmentButtonStatus('');
      }
    }
  };

  const onDelete = async (attachmentId: string) => {
    setIsDeleting(true);

    try {
      await deleteAttachmentByGroupId(token, groupId, attachmentId);
      setGroupAttachments(groupAttachments.filter((attachment) => attachment._id !== attachmentId));
    } catch (ex) {
      console.error(ex);
      setIsDeleting(false);
      notification.error({ message: 'Something went wrong while trying to delete this attachment.' });
    }
  };

  const onDeleteAttachmentClick = (attachmentId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this attachment?',
      onOk: () => onDelete(attachmentId)
    });
  };

  const onDownloadAttachmentClick = async (attachmentTitle: string, attachmentId: string) => {
    try {
      const callGetAttachmentById = await downloadAttachmentByGroupId(token, groupId, attachmentId);
      const attachment = await callGetAttachmentById.arrayBuffer();

      const documentBlob = new Blob([attachment], {
        type: mimetypes.lookup(attachmentTitle) || 'application/octet-stream'
      });

      const anchor = document.createElement('a');

      anchor.href = URL.createObjectURL(documentBlob);
      anchor.download = attachmentTitle;
      anchor.click();
      anchor.remove();
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  return (
    <div className={styles.container}>
      {isGroupAttachmentsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.attachmentsCount}>
              {groupAttachments.filter((attachment) => attachment._id).length} attachments saved
            </span>
            <LoadingButton
              variant={'primary'}
              className={styles.uploadButton}
              status={addAttachmentButtonStatus}
              disabled={!!addAttachmentButtonStatus || isReadOnly}
              onClick={handleUploadAttachmentClick}
            >
              <i className={`material-icons-outlined ${styles.icon}`}>upload</i>
              Upload new attachment
            </LoadingButton>
            <input
              className={styles.uploadInput}
              id="patient-upload-attachment"
              type="file"
              accept="image/png,image/jpeg,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
              onChange={handleUploadAttachment}
            />
          </div>
          {groupAttachments.length === 0 ? (
            <div className={styles.noAttachments}>
              <img className={styles.image} src={NoAttachments} alt="No Invoices" />
              <div className={styles.prompt}>
                <div className={styles.text}>There are no attachments added to this group record.</div>
                <LoadingButton
                  className={styles.button}
                  variant={'secondary'}
                  status={addAttachmentButtonStatus}
                  disabled={!!addAttachmentButtonStatus || isReadOnly}
                  onClick={() => isReadOnly || handleUploadAttachmentClick()}
                >
                  <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                  Upload new attachment
                </LoadingButton>
              </div>
            </div>
          ) : (
            <div className={styles.attachmentsContainer}>
              {groupAttachments.map((attachment) => (
                <AttachmentCard
                  key={attachment._id}
                  attachment={attachment}
                  ableToDelete={auth0ClinicianId === attachment.clinicianId && !isReadOnly}
                  baseLink={`${GROUPS.BASE}/${groupId}/notes/${attachment.caseNote?._id || ''}`}
                  onDeleteAttachmentClick={() => onDeleteAttachmentClick(attachment._id)}
                  onDownloadAttachmentClick={() => onDownloadAttachmentClick(attachment.title, attachment._id)}
                  isDeleting={isDeleting}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupAttachments;
