import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { useFetchAssessmentResponse } from 'pages/PatientDetails/components/PatientDetailsContent/hooks/actions';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { postActionPlan } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useFetchInterventionList } from '../ActionPlanEditor/hook/getInterventionList';

import styles from './ActionPlanCreator.module.scss';
import ActionPlanForm from './components/ActionPlanForm/ActionPlanForm';
import { ActionPlanInterventionItem } from './components/ActionPlanForm/components/InterventionList/InterventionList';
import { getInitActionPlan, actionPlanFormErrors } from './constants';
import {
  ActionPlanAssessment,
  ActionPlanAttachment,
  ActionPlanInterface,
  ActionPlanIntervention,
  ActionPlanTask,
  ActionPlanTaskItem
} from './interfaces';

const ActionPlanCreator = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const { recordId = '' } = useParams<{ recordId: string }>();
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const [actionPlan, setActionPlan] = useState(getInitActionPlan(user?.sub));
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    ownerAuth0Id: '',
    dueDate: '',
    tasksAndInterventions: '',
    interventions: ''
  });
  const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
  const [publishButtonStatus, setPublishButtonStatus] = useState<ButtonStatusType>('');
  const { assessmentResponse } = useFetchAssessmentResponse(token, recordId, true);
  const { interventionList: interventionOptions } = useFetchInterventionList(token);

  const handleClickBackBtn = () => {
    navigate(`${CLIENTS.BASE}/${recordId}/action-plans/`);
  };

  const handleChangeValues = (
    key: keyof ActionPlanInterface,
    value:
      | string
      | ActionPlanTask[]
      | ActionPlanAttachment[]
      | undefined
      | ActionPlanAssessment[]
      | ActionPlanIntervention[],
    validateField: boolean = true
  ) => {
    setActionPlan({ ...actionPlan, [key]: value });
    validateField &&
      !['tasks', 'interventions'].includes(key) &&
      setErrorMessage({ ...errorMessage, [key]: !value || value.length === 0 ? actionPlanFormErrors[key] : '' });
  };

  const checkIsValid = () => {
    const newError = {
      ...errorMessage,
      title: !actionPlan.title ? actionPlanFormErrors['title'] : '',
      dueDate: !actionPlan.dueDate ? actionPlanFormErrors['dueDate'] : '',
      ownerAuth0Id: !actionPlan.ownerAuth0Id ? actionPlanFormErrors['ownerAuth0Id'] : '',
      tasksAndInterventions:
        (!actionPlan.tasks || actionPlan.tasks.length === 0) &&
        (!actionPlan.interventions || actionPlan.interventions.length === 0)
          ? actionPlanFormErrors['tasksAndInterventions']
          : ''
    };

    const newTasks = [...actionPlan.tasks].map((task) => {
      const newErr = { ...task.errorMessage };

      if (!task.title) {
        newErr.title = actionPlanFormErrors['title'];
      }

      if (!task.dueDate) {
        newErr.dueDate = actionPlanFormErrors['dueDate'];
      }

      if (task.isEdit) {
        newErr.editMode = task.isOld ? actionPlanFormErrors['editMode'] : actionPlanFormErrors['addMode'];
      }

      task.errorMessage = newErr;

      return task;
    });

    const newInterventions = [...actionPlan.interventions].map((item) => {
      if (item.noAction?.reason) {
        return item;
      }

      const newErr = { ...item.errorMessage };

      if (!item.dueDate) {
        newErr.dueDate = actionPlanFormErrors['dueDate'];
      }
      if (!item.selectedInterventionIds || item.selectedInterventionIds.length === 0) {
        newErr.selectedInterventionIds = actionPlanFormErrors['interventions'];
      }
      item.errorMessage = newErr;

      return item;
    });

    handleChangeValues('tasks', newTasks, false);
    handleChangeValues('interventions', newInterventions, false);
    setErrorMessage(newError);
    return (
      !Object.values(newError).some((err) => err) &&
      !newTasks.some(({ errorMessage }) => Object.values(errorMessage || {}).some((err) => err)) &&
      !newInterventions.some(({ errorMessage }) => Object.values(errorMessage || {}).some((err) => err))
    );
  };

  const checkIsInterventionValid = (int: ActionPlanInterventionItem, index: number) => {
    if (int.noAction?.reason) {
      return true;
    }

    const newErr = { ...int.errorMessage };
    newErr.editMode = '';

    if (!int.dueDate) {
      newErr.dueDate = actionPlanFormErrors['dueDate'];
    }
    if (!int.selectedInterventionIds || int.selectedInterventionIds.length === 0) {
      newErr.selectedInterventionIds = actionPlanFormErrors['interventions'];
    }

    const newInterventions = [...actionPlan.interventions];
    newInterventions[index].errorMessage = newErr;
    handleChangeValues('interventions', newInterventions);

    return !Object.values(newErr).some((err) => !!err);
  };

  const checkIsTaskValid = (task: ActionPlanTaskItem, index: number) => {
    const newErr = { ...task.errorMessage };

    newErr.editMode = '';

    if (!task.title) {
      newErr.title = actionPlanFormErrors['title'];
    }

    if (!task.dueDate) {
      newErr.dueDate = actionPlanFormErrors['dueDate'];
    }

    const newTasks = [...actionPlan.tasks];

    newTasks[index].errorMessage = newErr;

    handleChangeValues('tasks', newTasks);

    return !Object.values(newErr).some((err) => !!err);
  };

  const splitInterventionsToSubmit = (interventions: ActionPlanInterventionItem[]) => {
    const finalInterventions: ActionPlanIntervention[] = [];
    interventions.forEach((item) => {
      if (item.noAction) {
        delete item.interventionId;
        finalInterventions.push({ ...item, name: 'No action' });
      } else {
        const groupIntervention =
          item.selectedInterventionIds?.map((id) => {
            const foundIntervention = interventionOptions.find((int) => int._id === id);

            return {
              ...(item as ActionPlanIntervention),
              interventionId: id,
              name: foundIntervention?.name || item.focusArea || '',
              description: foundIntervention?.description || ''
            };
          }) || [];
        finalInterventions.push(...groupIntervention);
      }
    });
    return finalInterventions;
  };

  const onSubmitActionPlan = async () => {
    if (!checkIsValid()) {
      return;
    }

    setPublishButtonStatus('active');
    try {
      const callingPlan = await postActionPlan(token, recordId, {
        ...actionPlan,
        interventions: splitInterventionsToSubmit(actionPlan.interventions)
      });
      const createdActionPlan: ActionPlanInterface = await callingPlan.json();
      notification.success({
        message: `Action plan created.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setPublishButtonStatus('finished');
      navigate(`${CLIENTS.BASE}/${recordId}/action-plans/${createdActionPlan._id}`);
    } catch (ex) {
      console.error(ex);

      setPublishButtonStatus('');

      notification.error({
        message: 'Fail to create action plan.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <HelmetWrapper title="CORDS - Action Plan">
      <ContentLayout className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Create Action Plan</div>
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={handleClickBackBtn}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                View Plan List
              </div>
            </div>
          </div>
          <Button
            className={styles.addButton}
            disabled={isUploadingAttachments}
            status={publishButtonStatus}
            onClick={onSubmitActionPlan}
          >
            Publish Action Plan
          </Button>
        </div>
        <ActionPlanForm
          actionPlan={actionPlan}
          clientRecordId={recordId}
          errorMessage={errorMessage}
          assessmentList={assessmentResponse}
          checkIsInterventionValid={checkIsInterventionValid}
          checkIsTaskValid={checkIsTaskValid}
          onChangeValues={handleChangeValues}
          onChangeUploadingAttachments={setIsUploadingAttachments}
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ActionPlanCreator;
