import { useState } from 'react';
import styles from './TaskList.module.scss';
import { ActionPlanTask, ActionPlanTaskItem } from '../../../../interfaces';
import Button from 'components/v2/Button/Button';
import TaskItem from './components/TaskItem/TaskItem';
import { actionPlanFormErrors } from '../../../../constants';
import TaskDetail from './components/TaskDetail/TaskDetail';
import classnames from 'classnames';

interface TaskListProps {
  isView?: boolean;
  taskList: ActionPlanTaskItem[];
  checkTaskStatus?: '' | 'active' | 'finished';
  onChangeTasks?: (tasks: ActionPlanTaskItem[]) => void;
  checkIsTaskValid?: (task: ActionPlanTaskItem, index: number) => boolean;
  onCheckTask?: (taskId: string, isCompleted: boolean) => void;
}

const TaskList = ({
  isView,
  taskList,
  checkTaskStatus,
  onChangeTasks,
  checkIsTaskValid,
  onCheckTask
}: TaskListProps) => {
  const [selectedTask, setSelectedTask] = useState<ActionPlanTaskItem>();
  const handleAddNewTask = () => {
    const newTasks = [
      ...taskList,
      {
        title: '',
        description: '',
        dueDate: '',
        isCompleted: false,
        isEdit: true
      }
    ];
    onChangeTasks && onChangeTasks(newTasks);
  };

  const handleCheckTask = (taskId: string, isCompleted: boolean) => {
    onCheckTask && onCheckTask(taskId, isCompleted);
  };

  const handleEditTask = (index: number) => {
    const newTasks = [...taskList];
    if (isView) {
      setSelectedTask(selectedTask?._id === newTasks[index]._id ? undefined : newTasks[index]);
      return;
    } else {
      newTasks[index].isEdit = true;
    }
    onChangeTasks && onChangeTasks(newTasks);
  };

  const handleDeleteTask = (index: number) => {
    const newTasks = [...taskList];
    newTasks.splice(index, 1);
    onChangeTasks && onChangeTasks(newTasks);
  };

  const handleChangeValues = (key: keyof ActionPlanTask, index: number, value: string | ActionPlanTask[]) => {
    const newTasks = [...taskList];
    newTasks[index] = {
      ...newTasks[index],
      [key]: value,
      errorMessage: {
        ...newTasks[index].errorMessage,
        [key]: !value ? actionPlanFormErrors[key] : ''
      }
    };
    onChangeTasks && onChangeTasks(newTasks);
  };

  const handleSaveTask = (index: number) => {
    if (checkIsTaskValid && !checkIsTaskValid(taskList[index], index)) {
      return;
    }
    const newTasks = [...taskList];
    newTasks[index].isEdit = false;
    newTasks[index].isOld = true;
    onChangeTasks &&
      onChangeTasks(
        newTasks.sort((task1, task2) =>
          task1.isOld || (task1.isEdit && task2.isEdit) ? 0 : task1.isEdit && !task2.isEdit ? 1 : -1
        )
      );
  };

  return (
    <>
      {taskList.map((task, index) => (
        <div className={styles.taskContainer} key={index}>
          <div className={classnames(styles.task, isView && styles.viewTask)}>
            <TaskItem
              key={index}
              task={task}
              index={index}
              isSelected={!!selectedTask?._id && selectedTask._id === task._id}
              onChangeValues={handleChangeValues}
              onDeleteTask={handleDeleteTask}
              onEditTask={handleEditTask}
              onSaveTask={handleSaveTask}
            />
          </div>

          {isView && selectedTask?._id === task._id && (
            <div className={styles.taskDetail}>
              <TaskDetail task={task} onSaveCompletedStatus={handleCheckTask} saveStatus={checkTaskStatus} />
            </div>
          )}
        </div>
      ))}
      {!isView && (
        <Button className={styles.addNewTaskButton} disabled={false} onClick={handleAddNewTask} id="addTaskBtnId">
          <i className="material-icons-outlined">add_circle_outline</i>
          {`Add ${taskList?.length ? 'another' : ''} task`}
        </Button>
      )}
    </>
  );
};

export default TaskList;
