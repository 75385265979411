import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
  roomId: Yup.string(),
  roomName: Yup.string(),
  deliveryType: Yup.string(),
  faceToFaceLocation: Yup.string(),
  videoCallInstructions: Yup.string(),
  phoneCallInstructions: Yup.string()
});
