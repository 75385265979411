import styles from './WidgetsListContent.module.scss';
import WidgetCard from './components/WidgetCard/WidgetCard';
import { ReportTemplateWidgetId } from 'interfaces/Reports/reportTemplate';
import { ClientProfileWidgetInterface, PsychometricWidgetInterface, TemplateWidgetView } from '../../interface';

interface WidgetsListMenuProps {
  widgetView: TemplateWidgetView;
  clientProfileWidgetMenu: ClientProfileWidgetInterface;
  psychometricWidgetMenu: PsychometricWidgetInterface;
  templateContent: any;
}

const WidgetsListContent = ({
  widgetView,
  clientProfileWidgetMenu,
  psychometricWidgetMenu,
  templateContent
}: WidgetsListMenuProps) => {
  const findItemIsUsed = (widgetTypeId: ReportTemplateWidgetId) =>
    templateContent.some((obj: any) => obj.details.widgetId === widgetTypeId);

  return (
    <div className={styles.container}>
      {widgetView === TemplateWidgetView.Psychometric && (
        <>
          <div className={styles.title}>{psychometricWidgetMenu.label}</div>
          <div className={styles.widgetListing}>
            {psychometricWidgetMenu.items.map((menuItemObj, index) => (
              <WidgetCard
                key={index}
                data={menuItemObj}
                widgetType={psychometricWidgetMenu.widgetType}
                itemLabel={menuItemObj.label}
                inUsed={findItemIsUsed(menuItemObj.widgetId)}
              />
            ))}
          </div>
        </>
      )}
      {widgetView === TemplateWidgetView.ClientProfile && (
        <>
          <div className={styles.title}>Client Profile</div>
          <div className={styles.widgetListing}>
            {clientProfileWidgetMenu.items.map((menuItemObj, index) => (
              <WidgetCard
                key={index}
                data={{
                  title: menuItemObj.title,
                  widgetId: menuItemObj.widgetId,
                  width: 350
                }}
                widgetType={clientProfileWidgetMenu.widgetType}
                itemLabel={menuItemObj.label}
                inUsed={findItemIsUsed(menuItemObj.widgetId)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetsListContent;
