import styles from './AdultInfoBox.module.scss';
import { clientProfilesEncrypted, clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import ClientContactDetails from '../../../ClientContactDetails/ClientContactDetails';

interface AdultInfoBoxProps {
  clientRecordData: clientRecordsEncryptedInterface;
}

const AdultInfoBox = ({ clientRecordData }: AdultInfoBoxProps) => {
  const clientProfile = clientRecordData.clientProfiles[0] as clientProfilesEncrypted;
  return (
    <div className={styles.contactDetailsWrapper}>
      <ClientContactDetails
        clientProfileData={clientProfile}
        title={`${clientProfile.firstName} Profile`}
        caseId={clientRecordData?.caseId}
      />
    </div>
  );
};

export default AdultInfoBox;
