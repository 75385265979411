import { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import styles from './IntroNoteEditor.module.scss';

export interface IntroNoteEditorProps {
  id?: string;
  className?: string;
  containerClass?: string;
  value: string;
  fullHeightEditor?: boolean;
  noBackground?: boolean;
  enableMention?: boolean;
  readOnly?: boolean;
  onChange: (e: any) => void;
}

const IntroNoteEditor = ({
  id,
  className,
  containerClass,
  value,
  fullHeightEditor,
  noBackground,
  enableMention,
  readOnly,
  onChange,
  ...props
}: IntroNoteEditorProps) => {
  const [wordCount, setWordCount] = useState(0);
  const textInput = useRef<ReactQuill>(null);

  useEffect(() => {
    setWordCount((textInput.current?.getEditor().getLength() || 1) - 1);
  }, []);

  const clientBasicInfo = (searchTerm: string) => {
    const allVariable = [{ id: 1, value: '{{CLIENT_NAME}}' }];
    return allVariable.filter((key) => key.value.toLowerCase().includes(searchTerm.toLowerCase())).splice(0, 6);
  };

  const onChangeNote = (val: string, delta: any, source: any, editor: any) => {
    if (val?.length < 500) {
      setWordCount(editor.getLength() - 1);
      onChange(val);
    } else {
      textInput.current?.getEditor().deleteText(500, editor.getLength());
      setWordCount(editor.getLength() - 1);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          { color: ['red', 'green', 'blue', 'orange', 'violet', '#d0d1d2', styles.blackColor] },
          { align: [] },
          { list: 'ordered' },
          { list: 'bullet' }
        ]
      ],
      ...(enableMention && {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['{{'],
          mentionContainerClass: styles.mentionListContainer,
          listItemClass: styles.mentionList,
          showDenotationChar: false,
          dataAttributes: [],
          source: function (searchTerm: any, renderList: any) {
            const matchedPeople = clientBasicInfo(searchTerm);
            renderList(matchedPeople);
          }
        }
      })
    }),
    [enableMention]
  );

  return (
    <div className={`intro-note-editor ${styles.container}`}>
      <ReactQuill
        ref={textInput}
        id={id}
        modules={modules}
        defaultValue={value}
        onChange={onChangeNote}
        readOnly={readOnly}
        {...props}
      />
      <div className={styles.wordCount}>{`${wordCount} / 500 characters`}</div>
    </div>
  );
};

export default IntroNoteEditor;
