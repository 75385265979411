import styles from './ModalAlert.module.scss';

interface ModalAlertProps {
  isModalOpen: boolean;
  children?: any;
  modalCloseClass: boolean;
}

const ModalAlert = ({ isModalOpen, children, modalCloseClass }: ModalAlertProps) => {
  return isModalOpen ? (
    <div className={styles.container}>
      <div className={`${styles.modalContent} ${modalCloseClass ? styles.hide : null}`}>{children}</div>
    </div>
  ) : null;
};

export default ModalAlert;
