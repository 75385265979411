import {
  AppointmentTypeAvailabilities,
  AvailabilityTimeSlotsByDate
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import { Moment } from 'moment';
import { getAllDatesInWeek } from 'utils/appointment';
import { getNextAvailableDate } from './getNextAvailableDate';

export const getCurrentWeekAppointmentSlots = ({
  currentDate,
  appointmentTypeAvailabilities
}: {
  currentDate: Moment;
  appointmentTypeAvailabilities?: AppointmentTypeAvailabilities;
}) => {
  const currentWeekAppointmentSlots = getAllDatesInWeek(currentDate).reduce(
    (appointmentSlots, date) => [
      ...appointmentSlots,
      {
        ...appointmentTypeAvailabilities?.timeSlots[date],
        date
      }
    ],
    [] as ({ date: string } | ({ date: string } & AvailabilityTimeSlotsByDate[string]))[]
  );

  const currentWeekHasNoAvailability = !currentWeekAppointmentSlots.some(
    (appointmentSlots) =>
      'isAvailable' in appointmentSlots &&
      appointmentSlots.isAvailable &&
      appointmentSlots.timeSlots?.some(({ isAvailable }) => isAvailable)
  );

  const hasNextAvailableSlot = !!getNextAvailableDate({
    currentDate,
    appointmentTypeAvailabilities
  });

  return {
    currentWeekAppointmentSlots,
    currentWeekHasNoAvailability,
    hasNextAvailableSlot
  };
};
