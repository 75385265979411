import { notification } from 'antd';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { AdHocAssessment } from 'interfaces/checkInService/adhocAssessment';
import { useEffect, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAdhocAssessmentsByClinicianIdWithMinifiedClientProfiles } from 'utils/http/CheckInService/Assessment/adhocAssessment';

export interface AdHocAssessmentWithAssignedClient extends AdHocAssessment {
  assignedClient?: Pick<clientProfilesInterface, '_id' | 'avatar' | 'name' | 'firstName' | 'lastName'>;
}

interface Props {
  accountId: string;
  clinicianId: string;
  totalPage: number;
  assessmentsPerPage: number;
  status?: 'done' | 'pending';
  disabled?: boolean;
}

const useGetAdhocAssessmentListsWithPaginationByClinicianId = ({
  accountId,
  clinicianId,
  assessmentsPerPage,
  totalPage,
  status,
  disabled = false
}: Props) => {
  const { token } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [assessmentList, setAssessmentList] = useState<AdHocAssessmentWithAssignedClient[]>([]);

  const fetchAssessments = async (token: string) => {
    setIsLoading(true);
    try {
      const response = await getAdhocAssessmentsByClinicianIdWithMinifiedClientProfiles(token, accountId, clinicianId, {
        perPage: assessmentsPerPage,
        page: currentPage,
        ...(status && { status })
      });
      const data = (await response.json()) as AdHocAssessmentWithAssignedClient[];
      setAssessmentList(data);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to fetch the clinical assessment lists"
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (token && !disabled) {
      fetchAssessments(token);
    }
    // eslint-disable-next-line
  }, [token, disabled, currentPage]); // re-run `fetchClinicalAssessments` when `currentPage` is changed

  const handleNavigate = (number: number) => {
    if (isLoading || number < 1 || number > totalPage) return;
    setCurrentPage(number);
  };

  return {
    isLoading,
    currentPage,
    handleNavigate,
    assessmentList
  };
};

export default useGetAdhocAssessmentListsWithPaginationByClinicianId;
