import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useParams } from 'react-router-dom';

import { useFetchProfile } from '../../../utils/hooks/GetProfileDetails/getProfileInfo';
import styles from './Profile.module.scss';
import ControlPanelContentDisplay from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/ControlPanelContentDisplay';

const Profile = () => {
  const { controlPanelTab } = useParams<{ controlPanelTab?: string }>();
  const { profile, isProfileLoading, setProfile } = useFetchProfile();

  return (
    <HelmetWrapper title="CORDS - Profile">
      <ContentLayout className={styles.container} whiteBg>
        <div className={styles.container}>
          <ControlPanelContentDisplay
            profile={profile}
            controlPanelTab={controlPanelTab}
            isLoading={isProfileLoading}
            onUpdateProfile={setProfile}
            aboutYouPage
            helmControlPage={false}
            medicarePage={false}
            workingSchedulePage={false}
            practiceProfilePage={false}
            systemConnectionPage={false}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Profile;
