import { useField } from 'formik';
import { ChangeEvent, useMemo } from 'react';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FormikInput from '../Input/FormikInput';
import Radio, { RadioProps } from './Radio';

interface FormikRadioProps extends Omit<RadioProps, 'hasError'> {
  name: string;
  hasOthers?: boolean;
  innerClass?: string;
}

const otherOption = {
  label: 'Other',
  value: 'other'
};

const FormikRadio = ({
  className,
  name,
  options,
  hasOthers,
  innerClass,
  onChange,
  value,
  ...props
}: FormikRadioProps) => {
  const [{ onChange: onFormChange, ...field }, { error, touched }] = useField(name);
  const { value: fieldValue } = field;

  const optionsWithOthers = useMemo(() => {
    if (hasOthers) {
      return [...options, otherOption];
    } else {
      return options;
    }
  }, [options, hasOthers]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFormChange(e);
    onChange && onChange(e);
  };

  return (
    <div className={className}>
      <Radio
        className={innerClass}
        {...props}
        {...field}
        value={value || fieldValue}
        hasError={touched && !!error}
        options={optionsWithOthers}
        onChange={handleChange}
      />
      <ErrorMessage error={error} visible={touched} />
      {hasOthers && fieldValue === otherOption.value && (
        <FormikInput
          className="fullWidth"
          name={`${name}-others`}
          label="How should we address you?"
          noSpacing
          required
        />
      )}
    </div>
  );
};

export default FormikRadio;
