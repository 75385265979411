import classNames from 'classnames';

import styles from './ContentLayout.module.scss';

interface ContentLayoutProps {
  children?: any;
  className?: string;
  contentClassName?: string;
  whiteBg?: boolean;
  transparentBg?: boolean;
}

const ContentLayout = ({ children, className, whiteBg, transparentBg, contentClassName }: ContentLayoutProps) => {
  return (
    <div
      className={classNames(
        styles.background,
        whiteBg && styles.whiteBg,
        transparentBg && styles.transparentBg,
        contentClassName
      )}
    >
      <div className={classNames(styles.container, className)}>{children}</div>
    </div>
  );
};

export default ContentLayout;
