import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PaginationList from 'components/PaginationList/PaginationList';
import ClientClosedItem from './components/ClientClosedItem/ClientClosedItem';
import { clientRecordsInterface, GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';
import queryString from 'query-string';

import styles from './ClosedClientList.module.scss';
import columnStyles from './ClosedClientColumn.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useTranslation } from 'react-i18next';

interface ClosedClientListProps {
  clientRecordList: clientRecordsInterface[];
  isClientRecordListLoading: boolean;
  paging: GetClientProfileInterface['paging'];
  onPageChange: (paging: string) => void;
}

const ClosedClientList = ({
  clientRecordList,
  isClientRecordListLoading,
  paging,
  onPageChange
}: ClosedClientListProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParam: { page?: string } = queryString.parse(location.search);
  const [t] = useTranslation();

  const selectPatient = (recordId: string) => {
    if (
      clientRecordList.find((obj) => obj._id === recordId)?.clientProfiles?.some((o) => o.profileType === 'prospect')
    ) {
      return;
    }
    const qParam = queryString.stringify(queryParam);
    navigate(`${CLIENTS.BASE}/${recordId}/profile${qParam ? `?${qParam}` : ''}`);
  };
  return (
    <>
      {isClientRecordListLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : clientRecordList.length > 0 ? (
        <>
          <div className={styles.filterContainer}>
            <div className={columnStyles.profileSection}>Name</div>
            <div className={columnStyles.cSinceSection}>{t('label.client_since')}</div>
            <div className={columnStyles.cSinceSection}>{t('label.client_close')}</div>
          </div>
          {clientRecordList.map((clientListObj, index) => {
            const isPrimaryData = clientListObj.clientProfiles[0];
            return (
              <ClientClosedItem
                clientData={isPrimaryData}
                recordType={clientListObj.recordType}
                clientCreatedAt={clientListObj.createdAt}
                clientUpdatedAt={clientListObj.updatedAt}
                key={index}
                onSelectClient={() => selectPatient(clientListObj._id)}
                clientRecordData={clientListObj}
              />
            );
          })}
          {paging.totalItem > paging.perPage && (
            <div className={styles.paginationWrapper}>
              <PaginationList paging={paging} onPageChange={onPageChange} />
            </div>
          )}
        </>
      ) : (
        <div>{t('label.no_client_record_found')}</div>
      )}
    </>
  );
};

export default ClosedClientList;
