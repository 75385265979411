import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

export const getConsentFormListing = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(`/consent-forms`) as Promise<BentResponse>;

export const getConsentFormByFormId = (token: string, consentFormId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentFormId}`
  ) as Promise<BentResponse>;

export const patchConsentFormSetDefault = (token: string, consentFormId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentFormId}:setDefault`
  ) as Promise<BentResponse>;

export const postConsentForm = (token: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/consent-forms`,
    payload
  ) as Promise<BentResponse>;

export const putConsentForm = (token: string, consentFormId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentFormId}`,
    payload
  ) as Promise<BentResponse>;

export const deleteConsentFormByFormId = (token: string, consentFormId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentFormId}`
  ) as Promise<BentResponse>;

export const getConsentFormResponse = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/consent-forms-responses`
  ) as Promise<BentResponse>;

export const putSendConsentInvitation = (token: string, clientRecordId: string, payload: Record<string, any>) =>
  httpClient('PATCH', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/client-records/${clientRecordId}:sendConsentInvitation`, payload) as Promise<BentResponse>;
