import { useEffect, useRef, useState } from 'react';
import ProfileBadge from '../ProfileBadge/ProfileBadge';
import styles from './CalendarFilter.module.scss';
import { CalendarFilterProps, CalendarFilterInterface } from './interfaces';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import RoomOption from './components/RoomOption/RoomOption';
import { useTranslation } from 'react-i18next';

const CalendarFilter = (props: CalendarFilterProps) => {
  const {
    selectedId,
    allowEmpty,
    filterList,
    isDataFetching,
    excludedFilterIds,
    backgroundColor,
    onSelectFilter,
    onRemove
  } = props;

  const node = useRef<HTMLDivElement>(null);
  const { isEdgeUser } = useGetAccountPackageView();

  const [showList, setShowList] = useState(false);
  const [filters, setFilters] = useState(filterList);
  const [selectedFilter, setSelectedFilter] = useState({} as CalendarFilterInterface);

  const filteredPratitionerOptions = filters.filter(
    (filter) => filter.isClinician && !excludedFilterIds.includes(filter._id)
  );
  const filteredRoomOptions = filters.filter(
    (filter) => !filter.isClinician && !excludedFilterIds.includes(filter._id)
  );

  useEffect(() => {
    if (allowEmpty && selectedId === undefined) {
      setSelectedFilter({} as CalendarFilterInterface);
    } else {
      let data: any = {};
      if (selectedId === '') {
        data = filterList.find((filter) => {
          return filter._id === '';
        });
      } else {
        data = filterList.find((filter) => {
          return filter._id === selectedId;
        });
      }
      setSelectedFilter(data ? data : ({} as CalendarFilterInterface));
    }
    setFilters(filterList);
  }, [filterList, selectedId, allowEmpty]);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSelectOption = (id: string) => {
    const data = filters.find((filter) => filter._id === id);
    if (data) {
      setSelectedFilter(data);
      onSelectFilter(data);
    } else {
      setSelectedFilter({} as CalendarFilterInterface);
      onSelectFilter(undefined);
    }
    setShowList(false);
  };

  const handleRemoveFilter = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedFilter({} as CalendarFilterInterface);
    onSelectFilter(undefined);
    setShowList(false);
    if (onRemove) {
      onRemove();
    }
  };

  const handleChangeSearch = (value: string) => {
    const filterData = filterList?.filter((obj) => obj.name.toLowerCase().includes(value.toLowerCase()));
    setFilters(filterData);
  };

  const [t] = useTranslation();

  return (
    <div ref={node} className={styles.container}>
      {isDataFetching ? (
        <div className={styles.loadingBox}>Loading...</div>
      ) : (
        <>
          <div className={styles.selectedBox} onClick={() => setShowList(!showList)} style={{ backgroundColor }}>
            {selectedFilter.isClinician ? (
              <ProfileBadge name={selectedFilter.name} avatar={selectedFilter.avatar} />
            ) : (
              <RoomOption name={selectedFilter.name} />
            )}
            {selectedFilter._id !== undefined && allowEmpty ? (
              <span onClick={(e) => handleRemoveFilter(e)} className={`material-icons-outlined ${styles.closeIcon}`}>
                close
              </span>
            ) : (
              <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
            )}
          </div>
          <div className={styles.listWrapper}>
            <div className={showList ? styles.listShow : styles.listHide}>
              <div className={styles.searchBox}>
                <span className={`material-icons ${styles.searchIcon}`}>search</span>
                <input
                  type={'text'}
                  autoComplete={'off'}
                  className={styles.searchInput}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <div className={styles.itemWrapper}>
                {/* Practitioners  */}
                {isEdgeUser && filteredPratitionerOptions.length > 0 && (
                  <>
                    <div className={styles.subSection}>
                      <span>{t('label.practitioners.capitalize')}</span>
                    </div>
                    {filteredPratitionerOptions?.map(
                      (filter) =>
                        filter.isClinician && (
                          <div
                            className={selectedFilter._id === filter._id ? styles.itemSelected : styles.item}
                            key={filter._id}
                            onClick={() => handleSelectOption(filter._id)}
                          >
                            <ProfileBadge name={filter.name} avatar={filter.avatar} />
                          </div>
                        )
                    )}
                  </>
                )}
                {/* Rooms  */}
                {allowEmpty && filteredRoomOptions.length > 0 && (
                  <>
                    <div className={styles.subSection}>
                      <span>Rooms</span>
                    </div>
                    {filteredRoomOptions?.map(
                      (filter) =>
                        !filter.isClinician && (
                          <div
                            className={selectedFilter._id === filter._id ? styles.itemSelected : styles.item}
                            key={filter._id}
                            onClick={() => handleSelectOption(filter._id)}
                          >
                            <RoomOption name={filter.name} />
                          </div>
                        )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CalendarFilter;
