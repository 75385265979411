import { useEffect, useState } from 'react';
import { notification } from 'antd';
import {
  filterParameter,
  PractitionersListing,
  PractitionersListingCount
} from 'interfaces/Practitioners/practitionersListing';
import {
  getPractitionerListing,
  getPractitionerListingCount
} from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

export const useFetchPractitioner = (token: string, filterValue: filterParameter) => {
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();

  const [practitionersList, setPractitionersList] = useState<PractitionersListing>({
    practitionerList: {},
    paging: {}
  } as PractitionersListing);
  const [isPractitionersListLoading, setIsPractitionersListLoading] = useState(true);

  const fetchPractitionersList = async (token: string, filterValue: filterParameter) => {
    try {
      const qParam = queryString.stringify(filterValue);
      const practitionersData = await getPractitionerListing(token, accountId, qParam);
      const practitionerListData = await practitionersData.json();

      const massageData = {
        ...practitionerListData,
        paging: {
          ...practitionerListData.paging,
          page: filterValue.page,
          perPage: filterValue.perPage,
          totalPage: Math.ceil(practitionerListData.paging.totalItem / filterValue.perPage)
        }
      };

      setPractitionersList(massageData as PractitionersListing);
    } catch (ex) {
      notification.error({ message: t('form.error.failed_to_retrieve_practitioner_record') });
    }

    setIsPractitionersListLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPractitionersList(token, filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { practitionersList, isPractitionersListLoading, fetchPractitionersList, setPractitionersList };
};

export const useFetchPractitionerListCount = (token: string, filterValue: filterParameter) => {
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();

  const [practitionersCount, setPractitionersCount] = useState<PractitionersListingCount>(
    {} as PractitionersListingCount
  );
  const [isPractitionersCountLoading, setIsPractitionersCountLoading] = useState(true);

  const fetchPractitionersCount = async (token: string, filterValue: filterParameter) => {
    setIsPractitionersCountLoading(true);
    try {
      const qParam = `perPage=${filterValue.perPage}&q=${filterValue.q}`;

      const practitionersCountData = await getPractitionerListingCount(token, accountId, qParam);
      const practitionerListCountData = await practitionersCountData.json();

      setPractitionersCount(practitionerListCountData as PractitionersListingCount);
      setIsPractitionersCountLoading(false);
    } catch (ex) {
      notification.error({ message: t('form.error.failed_to_retrieve_practitioner_count') });
      setIsPractitionersCountLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchPractitionersCount(token, filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { practitionersCount, isPractitionersCountLoading, fetchPractitionersCount, setPractitionersCount };
};
