import { useField } from 'formik';

import Radio from 'components/Radio/Radio';

import styles from './BookingRules.module.scss';
import FormSection from 'components/v2/FormSection/FormSection';
import { useTranslation } from 'react-i18next';

const BOOKING_RULE_OPTIONS = [
  { label: 'Instant book', value: 'instant' },
  { label: 'Request to book', value: 'request' }
];

const BookingRules = () => {
  const [{ value: bookingRuleNewAvailable, onChange: onBookingRuleNewAvailableChange }] = useField(
    'bookingRules.new.available'
  );
  const [{ value: bookingRuleNewRule, onChange: onBookingRuleNewRuleChange }] = useField('bookingRules.new.rule');
  const [{ value: bookingRuleExistingAvailable, onChange: onBookingRuleExistingAvailableChange }] = useField(
    'bookingRules.existing.available'
  );
  const [{ value: bookingRuleExistingRule, onChange: onBookingRuleExistingRuleChange }] = useField(
    'bookingRules.existing.rule'
  );

  const [t] = useTranslation();

  return (
    <FormSection title={t('calendar.form.booking_rules.title')} help={t('calendar.form.booking_rules.help')}>
      <div className={styles.container}>
        <div className={`${styles.bookingRule} ${bookingRuleNewAvailable && styles.active}`}>
          <div className={styles.checkboxContainer}>
            <input
              className={styles.checkbox}
              id={`checkbox-bookingRules-newClients`}
              type="checkbox"
              name="bookingRules.new.available"
              checked={bookingRuleNewAvailable}
              onChange={onBookingRuleNewAvailableChange}
            />
            <label className={styles.label} htmlFor={`checkbox-bookingRules-newClients`}>
              {t('label.new_clients')}
            </label>
          </div>
          <Radio
            className={styles.radioContainer}
            name="bookingRules.new.rule"
            radioClass={styles.radio}
            options={BOOKING_RULE_OPTIONS}
            value={bookingRuleNewRule}
            onChange={onBookingRuleNewRuleChange}
            disabled={!bookingRuleNewAvailable}
          />
        </div>
        <div className={`${styles.bookingRule} ${bookingRuleExistingAvailable && styles.active}`}>
          <div className={styles.checkboxContainer}>
            <input
              className={styles.checkbox}
              id={`checkbox-existingClients-newClients`}
              type="checkbox"
              name="bookingRules.existing.available"
              checked={bookingRuleExistingAvailable}
              onChange={onBookingRuleExistingAvailableChange}
            />
            <label className={styles.label} htmlFor={`checkbox-existingClients-newClients`}>
              {t('label.existing_clients')}
            </label>
          </div>
          <Radio
            className={styles.radioContainer}
            name="bookingRules.existing.rule"
            radioClass={styles.radio}
            options={BOOKING_RULE_OPTIONS}
            value={bookingRuleExistingRule}
            onChange={onBookingRuleExistingRuleChange}
            disabled={!bookingRuleExistingAvailable}
          />
        </div>
      </div>
    </FormSection>
  );
};

export default BookingRules;
