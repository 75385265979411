import { GroupDetailsTab, GroupPsychometricMenu, Groups } from 'pages/Groups/Interfaces/Groups';
import { Route, Routes } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import PsychometricList from './components/PsychometricList/PsychometricList';
import PsychometricDetail from './components/PsychometricDetail/PsychometricDetail';

interface PsychometricsProps {
  groupDetails: Groups;
  psychometricId?: string;
  selectedClinicalAssessmentId?: string;
  psychometricMenuList: GroupPsychometricMenu[];
  onCreatePsychometricList: () => void;
}

const Psychometrics = ({
  groupDetails,
  psychometricId,
  psychometricMenuList,
  selectedClinicalAssessmentId,
  onCreatePsychometricList
}: PsychometricsProps) => {
  const { GROUPS } = useRoutesGenerator();

  return (
    <Routes>
      <Route
        path={`${GROUPS.BASE}/:groupId/${GroupDetailsTab.Psychometrics}/:contentId?/:clinicalAssessmentId?/:childContentId?`}
        element={
          <PsychometricList
            groupDetails={groupDetails}
            psychometricId={psychometricId}
            selectedClinicalAssessmentId={selectedClinicalAssessmentId}
            psychometricMenuList={psychometricMenuList}
            onCreateSuccess={onCreatePsychometricList}
          />
        }
      />
      <Route
        path={
          `${GROUPS.BASE}/:groupId/${GroupDetailsTab.Psychometrics}/:contentId?/clinicalAssessmentId` +
          `/:childContentId/responseId/:responseId/clientRecordId/:clientRecordId`
        }
        element={<PsychometricDetail />}
      />
    </Routes>
  );
};

export default Psychometrics;
