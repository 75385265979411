import { Modal, notification } from 'antd';
import classNames from 'classnames';
import { useFetchAssessmentList } from 'components/ClientOrGroupPickAssessment/getAssessmentList';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { useFetchClientRecordByClinician } from 'components/v2/PatientSelect/hooks/GetClientList';
import { clientRecordsInterface, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { AssessmentRole, ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postAdHocStudentAssessments } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import SendAdhocAssessmentModalV2 from '../SendAdhocAssessmentModalV2/SendAdhocAssessmentModalV2';
import CORDSAssessmentStudentSelect from './components/CORDSAssessmentStudentSelect';
import SDQAssessmentList from './components/SDQAssessmentList';

import styles from './CORDSSendAssessmentModal.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { postCordsAdHocAssessmentGroup } from 'utils/http/CheckInService/Assessment/groupAssessment';
import CORDSAssessmentGroupSelect from './components/CORDSAssessmentGroupSelect';

interface CORDSSendAssessmentModalProps {
  visible: boolean;
  onClose: () => void;
  defaultSelectedStudents?: clientRecordsInterface[];
}

/**
 * TODO: refactor into smaller components to avoid eslint complexity limit error
 */
// eslint-disable-next-line complexity
const CORDSSendAssessmentModal = ({
  visible,
  onClose,
  defaultSelectedStudents = []
}: CORDSSendAssessmentModalProps) => {
  const [expanding, setExpanding] = useState<
    'sendToAll' | 'sendToSelectedStudents' | 'sendToSelectedGroup' | 'sendOtherAssessment' | ''
  >(defaultSelectedStudents.length > 0 ? 'sendToSelectedStudents' : '');
  const [step, setStep] = useState<'selectAssessment' | 'selectStudent' | 'selectGroup' | 'confirm'>(
    'selectAssessment'
  );
  const { token } = useGetAccessToken();
  const { assessmentList, isAssessmentLoading } = useFetchAssessmentList(token);
  const [sendBtnStatus, setSendBtnStatus] = useState<ButtonStatusType>('');

  const { isEdgeAdminView } = useGetAccountPackageView();

  const { auth0ClinicianId } = useGetClinicianId();

  const [selectedAssessmentSendToAll, setSelectedAssessmentSendToAll] = useState<ClinicalAssessmentStruct>();
  const [selectedAssessmentSendToSelected, setSelectedAssessmentSendToSelected] = useState<ClinicalAssessmentStruct>();
  const [selectedStudents, setSelectedStudents] = useState<clientRecordsInterface[]>(defaultSelectedStudents);
  const [selectedGroup, setSelectedGroup] = useState<Groups | undefined>();

  const filterCommunicationPreference =
    selectedAssessmentSendToSelected?.metadata?.role !== AssessmentRole.Teacher &&
    selectedAssessmentSendToAll?.metadata?.role !== AssessmentRole.Teacher
      ? [CommunicationPreference.NoPreference, CommunicationPreference.SMS].join(',')
      : '';

  const {
    bookableClients: clientRecordList,
    isBookableClientsLoading: isClientRecordListLoading
  } = useFetchClientRecordByClinician(
    isEdgeAdminView ? undefined : auth0ClinicianId,
    undefined,
    filterCommunicationPreference
  );

  const isConfirmingSendToAll = expanding === 'sendToAll' && step === 'confirm';
  const isConfirmingSendToSelectedStudents = expanding === 'sendToSelectedStudents' && step === 'confirm';
  const isConfirmingSendToSelectedGroup = expanding === 'sendToSelectedGroup' && step === 'confirm';

  const isSelectingStudent = step === 'selectStudent';
  const isSelectingGroup = step === 'selectGroup';

  const onSendToStudents = async (sendToAll: boolean) => {
    setSendBtnStatus('active');
    try {
      const payload = {
        clinicalAssessmentId: sendToAll ? selectedAssessmentSendToAll?.id : selectedAssessmentSendToSelected?.id,
        ...(!sendToAll && { clientRecordIds: selectedStudents.map((item) => item._id) })
      };
      await postAdHocStudentAssessments(token, payload);

      notification.success({
        message: `You have sent the ${payload.clinicalAssessmentId} to ${
          payload.clientRecordIds ? `${payload.clientRecordIds.length} selected student(s)` : 'all students'
        }.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setSendBtnStatus('finished');
      setTimeout(() => {
        setSendBtnStatus('');
        setSelectedAssessmentSendToAll(undefined);
        setSelectedAssessmentSendToSelected(undefined);
        setSelectedStudents(defaultSelectedStudents);
        setExpanding(defaultSelectedStudents.length > 0 ? 'sendToSelectedStudents' : '');
        handleClose();
      }, 1000);
    } catch (err) {
      console.error(err);
      notification.error({ message: 'Something went wrong while trying to send this assessment. Please try again' });
      setSendBtnStatus('');
    }
  };

  const onSendToGroup = async () => {
    if (!selectedGroup || !selectedAssessmentSendToSelected) return;

    setSendBtnStatus('active');
    try {
      const payload = {
        clinicalAssessmentId: selectedAssessmentSendToSelected.id,
        sendAt: 'now'
      };
      await postCordsAdHocAssessmentGroup(token, selectedGroup._id, payload);

      notification.success({
        message: `You have sent the ${selectedAssessmentSendToSelected.id} to the selected group.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setSendBtnStatus('finished');
      setTimeout(() => {
        setSendBtnStatus('');
        setSelectedAssessmentSendToSelected(undefined);
        setSelectedGroup(undefined);
        setExpanding(defaultSelectedStudents.length > 0 ? 'sendToSelectedStudents' : '');
        handleClose();
      }, 1000);
    } catch (err) {
      console.error(err);
      notification.error({ message: 'Something went wrong while trying to send this assessment. Please try again' });
      setSendBtnStatus('');
    }
  };

  const getConfirmContentForStudentAssessments = () => {
    if (isClientRecordListLoading && isConfirmingSendToAll) {
      return <LoadingCircle />;
    }
    const assessment = isConfirmingSendToAll ? selectedAssessmentSendToAll : selectedAssessmentSendToSelected;
    const students = isConfirmingSendToAll ? clientRecordList : selectedStudents;
    return (
      <div className={styles.confirmContent}>
        You will request <span className={styles.highLight}>{assessment?.id}</span> for{' '}
        <span className={styles.highLight}>
          {assessment?.metadata?.role === AssessmentRole.Teacher
            ? students.filter((item) => item.assessorIds && item.assessorIds.length > 0).length
            : assessment?.metadata?.role === AssessmentRole.Parent
            ? students.filter((item) => item.clientProfiles.find((i) => i.role !== 'child' && i.isPrimaryContact))
                .length
            : students.length}
        </span>{' '}
        selected{' '}
        {assessment?.metadata?.role === AssessmentRole.Student ? (
          'student(s).'
        ) : assessment?.metadata?.role === AssessmentRole.Parent ? (
          "student's parent. It will send to all the primary contact of the selected student."
        ) : assessment?.metadata?.role === AssessmentRole.Teacher ? (
          <>
            students by{' '}
            <span className={styles.highLight}>
              {
                students
                  .reduce((res, item) => [...res, ...(item.assessorIds || [])], [] as string[])
                  .filter((item, idx, arr) => arr.findIndex((item_) => item_ === item) === idx).length
              }
            </span>{' '}
            teacher(s).
          </>
        ) : (
          ''
        )}
        <div className={styles.footer}>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isClientRecordListLoading || sendBtnStatus !== ''}
            status={sendBtnStatus}
            onClick={() => onSendToStudents(isConfirmingSendToAll)}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  const getConfirmContentForGroupAssessments = () => {
    return (
      <div className={styles.confirmContent}>
        You will request <span className={styles.highLight}>{selectedAssessmentSendToSelected?.id}</span> for this
        group.
        <div className={styles.footer}>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={sendBtnStatus !== ''} status={sendBtnStatus} onClick={onSendToGroup}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  const handleClose = () => {
    setStep('selectAssessment');
    onClose();
  };

  return (
    <>
      <Modal
        footer={null}
        title={
          <div className={styles.header}>
            <div className={classNames(selectedStudents.length > 0 && styles.headerTitle)}>
              Request Assessment{(selectedStudents.length > 0 || selectedGroup) && ' for'}
              {expanding === 'sendToSelectedStudents' && selectedStudents.length > 0 && (
                <div className={styles.studentTitle}>
                  {selectedStudents
                    .map((student) => `${student.clientProfiles[0].firstName} ${student.clientProfiles[0].lastName}`)
                    .join(', ')}
                  {step !== 'confirm' && (
                    <Button
                      variant="link"
                      type="button"
                      className={styles.buttonChange}
                      onClick={() => setStep('selectStudent')}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>edit</i>
                      Change Student
                    </Button>
                  )}
                </div>
              )}
              {expanding === 'sendToSelectedGroup' && selectedGroup && (
                <div className={styles.studentTitle}>
                  {selectedGroup?.name}
                  {!['confirm', 'selectGroup'].includes(step) && (
                    <Button
                      variant="link"
                      type="button"
                      className={styles.buttonChange}
                      onClick={() => setStep('selectGroup')}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>edit</i>
                      Change Group
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className={styles.headerRightButtons}>
              {isConfirmingSendToSelectedStudents ? (
                <div className={styles.backToAssessmentList} onClick={() => setStep('selectStudent')}>
                  <i className="material-icons-outlined">arrow_back_ios</i>Select Student
                </div>
              ) : isConfirmingSendToSelectedGroup ? (
                <div className={styles.backToAssessmentList} onClick={() => setStep('selectGroup')}>
                  <i className="material-icons-outlined">arrow_back_ios</i>Select Group
                </div>
              ) : isSelectingStudent || isSelectingGroup ? (
                <div className={styles.backToAssessmentList} onClick={() => setStep('selectAssessment')}>
                  <i className="material-icons-outlined">arrow_back_ios</i>Select Assessment
                </div>
              ) : (
                isConfirmingSendToAll && (
                  <div className={styles.backToAssessmentList} onClick={() => setStep('selectAssessment')}>
                    <i className="material-icons-outlined">arrow_back_ios</i>Select Assessment
                  </div>
                )
              )}

              <i className="material-icons-outlined">close</i>
            </div>
          </div>
        }
        visible={visible && expanding !== 'sendOtherAssessment'}
        onCancel={handleClose}
        className={styles.modal}
        closeIcon={<></>}
      >
        <div
          className={classNames(
            styles.body,
            !isConfirmingSendToAll &&
              !isConfirmingSendToSelectedStudents &&
              !isSelectingStudent &&
              !isConfirmingSendToSelectedGroup &&
              !isSelectingGroup &&
              styles.visible
          )}
        >
          {defaultSelectedStudents.length === 0 && (
            <div className={classNames(styles.expandItem, expanding === 'sendToAll' && styles.expanded)}>
              <div className={styles.title} onClick={() => setExpanding(expanding === 'sendToAll' ? '' : 'sendToAll')}>
                <div className={styles.text}>Request SDQ for all students</div>
                <div className={styles.icon}>
                  {expanding === 'sendToAll' ? (
                    <i className="material-icons-outlined">expand_more</i>
                  ) : (
                    <i className="material-icons-outlined">chevron_right</i>
                  )}
                </div>
              </div>
              {expanding === 'sendToAll' && (
                <div className={styles.content}>
                  <SDQAssessmentList
                    selectedItem={selectedAssessmentSendToAll}
                    assessmentList={assessmentList}
                    isAssessmentLoading={isAssessmentLoading}
                    onChange={(item) => {
                      setSelectedAssessmentSendToAll(item);
                    }}
                    onSelect={() => {
                      setStep('confirm');
                    }}
                  />
                </div>
              )}
            </div>
          )}

          <div className={classNames(styles.expandItem, expanding === 'sendToSelectedStudents' && styles.expanded)}>
            <div
              className={styles.title}
              onClick={() => setExpanding(expanding === 'sendToSelectedStudents' ? '' : 'sendToSelectedStudents')}
            >
              <div className={styles.text}>Send SDQ for selected students</div>
              <div className={styles.icon}>
                {expanding === 'sendToSelectedStudents' ? (
                  <i className="material-icons-outlined">expand_more</i>
                ) : (
                  <i className="material-icons-outlined">chevron_right</i>
                )}
              </div>
            </div>
            {expanding === 'sendToSelectedStudents' && (
              <div className={styles.content}>
                <SDQAssessmentList
                  selectedItem={selectedAssessmentSendToSelected}
                  assessmentList={assessmentList}
                  isAssessmentLoading={isAssessmentLoading}
                  onChange={(item) => {
                    setSelectedAssessmentSendToSelected(item);
                  }}
                  onSelect={() => (defaultSelectedStudents.length > 0 ? setStep('confirm') : setStep('selectStudent'))}
                />
              </div>
            )}
          </div>

          <div className={classNames(styles.expandItem, expanding === 'sendToSelectedGroup' && styles.expanded)}>
            <div
              className={styles.title}
              onClick={() => setExpanding(expanding === 'sendToSelectedGroup' ? '' : 'sendToSelectedGroup')}
            >
              <div className={styles.text}>Send SDQ for selected group</div>
              <div className={styles.icon}>
                {expanding === 'sendToSelectedGroup' ? (
                  <i className="material-icons-outlined">expand_more</i>
                ) : (
                  <i className="material-icons-outlined">chevron_right</i>
                )}
              </div>
            </div>
            {expanding === 'sendToSelectedGroup' && (
              <div className={styles.content}>
                <SDQAssessmentList
                  selectedItem={selectedAssessmentSendToSelected}
                  assessmentList={assessmentList}
                  isAssessmentLoading={isAssessmentLoading}
                  onChange={(item) => {
                    setSelectedAssessmentSendToSelected(item);
                  }}
                  onSelect={() => (selectedGroup ? setStep('confirm') : setStep('selectGroup'))}
                />
              </div>
            )}
          </div>

          <div className={classNames(styles.expandItem, expanding === 'sendOtherAssessment' && styles.expanded)}>
            <div className={styles.title} onClick={() => setExpanding('sendOtherAssessment')}>
              <div className={styles.text}>Send other assessment</div>
              <div className={styles.icon}>
                <i className="material-icons-outlined">chevron_right</i>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.selectStudent, isSelectingStudent && styles.visible)}>
          <CORDSAssessmentStudentSelect
            selectedAssessment={selectedAssessmentSendToSelected}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            onSelect={() => (selectedAssessmentSendToSelected ? setStep('confirm') : setStep('selectAssessment'))}
          />
        </div>
        <div className={classNames(styles.selectStudent, isSelectingGroup && styles.visible)}>
          <CORDSAssessmentGroupSelect
            selectedAssessment={selectedAssessmentSendToSelected}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            onSelect={() => (selectedAssessmentSendToSelected ? setStep('confirm') : setStep('selectAssessment'))}
          />
        </div>
        <div
          className={classNames(
            styles.confirm,
            (isConfirmingSendToAll || isConfirmingSendToSelectedStudents) && styles.visible
          )}
        >
          {getConfirmContentForStudentAssessments()}
        </div>
        <div className={classNames(styles.confirm, isConfirmingSendToSelectedGroup && styles.visible)}>
          {getConfirmContentForGroupAssessments()}
        </div>
      </Modal>
      {expanding === 'sendOtherAssessment' && (
        <div className={styles.content}>
          <SendAdhocAssessmentModalV2
            token={token}
            visible={expanding === 'sendOtherAssessment'}
            onCancel={() => setExpanding('')}
          />
        </div>
      )}
    </>
  );
};

export default CORDSSendAssessmentModal;
