import * as yup from 'yup';
import { StageForm, StageValidateField } from '../../../../../../../interface';

const stageFormSchema = yup.object().shape({
  name: yup.string().required('Please enter the stage name')
});

export const stageFormValidation = (stageVal: StageForm) => {
  const validationErrors: StageValidateField = {
    name: ''
  };
  try {
    stageFormSchema.validateSync(stageVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof StageValidateField] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
