import { useState } from 'react';
import styles from './KeyContacts.module.scss';
import columnStyles from './KeyContactsColumn.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import Button from 'components/v2/Button/Button';
import KeyContactList from './components/KeyContactList/KeyContactList';
import NewContacts from './components/NewContacts/NewContacts';
import { Groups } from 'pages/Groups/Interfaces/Groups';

export interface KeyContactsProps {
  groupDetail: Groups;
  fetchGroupDetails: (token: string) => void;
}

const KeyContacts = ({ groupDetail, fetchGroupDetails }: KeyContactsProps) => {
  const { token } = useGetAccessToken();
  const [isAddRemove, setIsAddRemove] = useState(false);
  const keyContacts = groupDetail.keyContacts;

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>Key Contacts Details</div>
      </div>
      <div className={styles.contentBox}>
        <div className={styles.listContainer}>
          <div className={columnStyles.listHeader}>
            <div className={columnStyles.firstName}>First name</div>
            <div className={columnStyles.lastName}>Last name</div>
            <div className={columnStyles.mobileNumber}>Mobile number</div>
            <div className={columnStyles.email}>Email</div>
            <div className={columnStyles.contactTags}>Contact Tags</div>
            <div className={columnStyles.actions} />
          </div>
          {keyContacts && keyContacts.length > 0 ? (
            <KeyContactList
              token={token}
              groupId={groupDetail._id}
              enableDelete={isAddRemove}
              keyContacts={keyContacts}
            />
          ) : (
            <div className={styles.noContact}>No contacts added</div>
          )}
        </div>
        <div className={styles.addRemoveContact}>
          {isAddRemove ? (
            <div className={styles.addingRecord}>
              <NewContacts
                token={token}
                groupId={groupDetail._id}
                onSubmit={() => {
                  fetchGroupDetails(token);
                }}
                onCancel={() => {
                  setIsAddRemove(false);
                }}
              />
            </div>
          ) : (
            <div className={styles.addRemoveButton}>
              <Button
                className={styles.button}
                variant="secondary"
                onClick={() => {
                  setIsAddRemove(true);
                }}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                Add/Remove contacts
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeyContacts;
