import { ClientDetails } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import * as yup from 'yup';
import { ClientFormPropsErrorInterface } from '../interface';

export interface CoupleClientFormValidationInterface extends ClientDetails {
  profileType?: clientProfilesInterface['profileType'];
  isFirstClient: boolean;
}

const clientFormSchema = yup.object().shape({
  firstName: yup.string().required('Please enter first name'),
  lastName: yup.string().required('Please enter last name'),
  isPrimaryContact: yup.boolean(),
  email: yup.string().when(['profileType', 'isFirstClient'], {
    is: (profileType, isFirstClient) => profileType === 'full' && isFirstClient,
    then: yup.string().required('Please enter email address'),
    otherwise: yup.string().email('Please enter a valid email address').nullable()
  }),
  mobileNumber: yup.string().when(['profileType', 'isFirstClient'], {
    is: (profileType, isFirstClient) => profileType === 'full' && isFirstClient,
    then: yup.string().min(12, 'Mobile number must be 10 digits or longer').required('Please enter mobile number'),
    otherwise: yup.string().nullable()
  })
});

export const validationClientFormField = (value: CoupleClientFormValidationInterface) => {
  const validationErrors: ClientFormPropsErrorInterface = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: ''
  };

  try {
    clientFormSchema.validateSync(value, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
          validationErrors[error.path as keyof ClientFormPropsErrorInterface] = error.message;
        }
        return validationErrors;
      });
      return validationErrors;
    }
  }
};
