import classnames from 'classnames';
import moment from 'moment';

import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';

import ScoreChart from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/components/ScoreChart/ScoreChart';

import styles from './AssessmentCard.module.scss';

interface AssessmentCardProps {
  assessmentData: Assessment;
  selected?: boolean;
  onClick?: () => void;
}

const AssessmentCard = ({ assessmentData, selected, onClick }: AssessmentCardProps) => {
  return (
    <div
      className={classnames(styles.container, onClick && styles.selectable, selected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.tick}>
        <i className={`material-icons-outlined ${styles.icon}`}>done</i>
      </div>
      <div className={styles.card} id={`add-assessment-card-${assessmentData._id}`}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>{assessmentData.id}</div>
          <div className={styles.date}>{moment(assessmentData.createdAt).format(`DD MMMM YYYY`)}</div>
        </div>
        {assessmentData.charts.map((chartData: any, index: number) => (
          <ScoreChart key={index} chart={chartData} showAlternateColor={assessmentData.id === 'CA'} />
        ))}
      </div>
    </div>
  );
};

export default AssessmentCard;
