import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './AssessmentsHeader.module.scss';
import SurveyVideoModal from './components/SurveyVideoModal/SurveyVideoModal';

interface AssessmentsHeaderProps {
  title: string;
  lastUpdateLabel?: string;
  saveBtn?: any;
  backText?: string;
  hideVideo?: boolean;
}

const AssessmentsHeader = ({
  title,
  lastUpdateLabel,
  saveBtn,
  backText = 'To Survey Hub',
  hideVideo = false
}: AssessmentsHeaderProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const { isEdgeUserView } = useGetAccountPackageView();
  const [isShowVideoModal, setShowVideoModal] = useState(false);

  const backBtn = () => {
    navigate(FORMS.BASE);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            {title}
            {!hideVideo && !isEdgeUserView && (
              <>
                <div className={styles.videoBtn} onClick={() => setShowVideoModal(true)}>
                  <i className={`material-icons-outlined ${styles.icon}`}>ondemand_video</i>
                  <div className={styles.label}>Watch short how-to video for creating surveys</div>
                </div>
                <SurveyVideoModal visible={isShowVideoModal} onCancel={() => setShowVideoModal(false)} />
              </>
            )}
          </div>
          <div className={styles.backBtnWrapper}>
            <div className={styles.backBtn} onClick={backBtn}>
              <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
              {backText}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.updateLabel}>{lastUpdateLabel}</div>
        {saveBtn && saveBtn}
      </div>
    </div>
  );
};

export default AssessmentsHeader;
