import { useState } from 'react';
import styles from './ConsentDetails.module.scss';
import moment from 'moment';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import ConsentDetailsModal from './components/ConsentDetailsModal/ConsentDetailsModal';
import { clientRecordsEncryptedInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import Button from 'components/v2/Button/Button';
import SendOutlined from '@ant-design/icons/lib/icons/SendOutlined';
import SendConsentModal from './components/SendConsentModal/SendConsentModal';
import { getName } from 'utils/general';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
interface ConsentDetailsProps {
  clientRecordData: clientRecordsEncryptedInterface;
  consentListData?: ConsentFormListInterface[];
}

const ConsentDetails = ({ clientRecordData, consentListData }: ConsentDetailsProps) => {
  const [showConsentForm, setShowConsentForm] = useState('');
  const [showSendConsent, setShowSendConsent] = useState(false);
  const onSendAdhocConsent = () => {
    setShowSendConsent(true);
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>CONSENT FORM</div>
      <div className={styles.consentContent}>
        {consentListData?.map((consentObj, index) => {
          const clientData = clientRecordData.clientProfiles.find(
            (clientObj) => clientObj._id === consentObj.clientProfileId
          );
          const clientName = clientData ? getName(clientData) : '';
          return (
            <div key={index}>
              <div className={styles.consentList}>
                <div className={styles.consentName}>
                  <i className={`material-icons ${styles.gpCheckIcon}`}>done</i>
                  {consentObj.name}
                </div>
                <div className={styles.consentDetails}>
                  <div className={styles.consentDate}>
                    Signed {moment(consentObj.createdAt).format(MOMENTJS_DATE_FORMAT)}
                  </div>
                  <div className={styles.consentViewBtn} onClick={() => setShowConsentForm(consentObj._id)}>
                    View
                    <i className={`material-icons ${styles.viewIcon}`}>visibility</i>
                  </div>
                </div>
              </div>
              <ConsentDetailsModal
                visible={consentObj._id === showConsentForm}
                onCancel={() => setShowConsentForm('')}
                consentFormDetail={consentObj}
                clientName={clientName}
              />
            </div>
          );
        })}

        <Button
          variant={'secondary'}
          className={styles.sendAdhocBtn}
          onClick={() => onSendAdhocConsent()}
          disabled={clientRecordData.recordStatus === RecordStatus.Closed}
        >
          <SendOutlined />
          <label>Send consent form</label>
        </Button>

        <SendConsentModal
          visible={showSendConsent}
          clientProfileList={clientRecordData.clientProfiles}
          clientRecordId={clientRecordData._id}
          onCancel={() => setShowSendConsent(false)}
        />
      </div>
    </div>
  );
};

export default ConsentDetails;
