import './ant.custom.scss';

import { Popover } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Formik } from 'formik';
import { PracticeInfoInterface } from 'interfaces/Profile/Profile';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { useState } from 'react';

import CancelPopover from '../CancelPopover/CancelPopover';
import MarkStatus from '../MarkStatus/MarkStatus';
import DeliveryMode from './components/DeliveryMode/DeliveryMode';
import Room from './components/Room/Room';
import { formSchema } from './constants';
import styles from './EventDetails.module.scss';
import GroupCard from './components/GroupCard/GroupCard';
import OneToOneCard from './components/OneToOneCard/OneToOneCard';

interface EventDetailsProps {
  groupSessionLabel?: string;
  appointment: AppointmentSlots;
  appointmentType?: AppointmentType;
  practice: PracticeInfoInterface['practice'];
  token: string;
  isLoading: boolean;
  hideEditGroupBtn?: boolean;
  onFinishMarkStatus: () => void;
  handleUpdateAppointment: (payload: any, setSubmitStatus: Function) => Promise<void>;
}

const EventDetails = ({
  groupSessionLabel,
  appointment,
  appointmentType,
  practice,
  token,
  isLoading,
  hideEditGroupBtn,
  onFinishMarkStatus,
  handleUpdateAppointment
}: EventDetailsProps) => {
  const recurrIdx = appointment.recurrings?.findIndex((recurr) => appointment.date === recurr.date);

  const [isEditingRoom, setIsEditingRoom] = useState(false);
  const [isEditingDeliveryMode, setIsEditingDeliveryMode] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const isReservedAppointment = appointment.type === 'reserved';

  const formInitialValues = {
    roomId: appointment.room?.roomId,
    roomName: appointment.room?.roomName,
    deliveryType: appointment.deliveryType,
    faceToFaceLocation: appointment.faceToFaceLocation,
    videoCallInstructions: appointment.videoCallInstructions,
    phoneCallInstructions: appointment.phoneCallInstructions
  };

  const handleUpdate = async (values: typeof formInitialValues) => {
    await handleUpdateAppointment(
      {
        room: values.roomId ? { roomId: values.roomId, roomName: values.roomName } : undefined,
        deliveryType: values.deliveryType,
        faceToFaceLocation: values.faceToFaceLocation,
        videoCallInstructions: values.videoCallInstructions,
        phoneCallInstructions: values.phoneCallInstructions
      },
      setSubmitStatus
    );
    setIsEditingRoom(false);
    setIsEditingDeliveryMode(false);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <Formik initialValues={formInitialValues} validationSchema={formSchema} onSubmit={handleUpdate}>
          {({ values, submitForm }) => (
            <>
              {appointment.groupId ? (
                <GroupCard
                  groupId={appointment.groupId}
                  label={groupSessionLabel || appointment.groupDetails.label}
                  sessionId={appointment._id}
                  hideEditGroupBtn={hideEditGroupBtn}
                />
              ) : (
                <OneToOneCard
                  clinicianId={appointment.clinicianId}
                  clientRecordId={appointment.clientRecord?._id || ''}
                  practice={practice}
                />
              )}
              {appointment.deliveryType !== DeliveryType.BeSpoke.toString() && (
                <div className={styles.eventInformationContainer}>
                  <div className={styles.iconContainer}>
                    <span className="material-icons-outlined">event</span>
                  </div>
                  <div className={styles.description}>
                    <div className={styles.descriptionText}>{appointmentType?.name || appointment.sessionTypeName}</div>
                  </div>
                  <div />
                  {appointmentType?.description && (
                    <div className={styles.subInfoContainer}>{appointmentType.description}</div>
                  )}
                </div>
              )}
              <Room appointment={appointment} isEditing={isEditingRoom} setIsEditing={setIsEditingRoom} />
              {appointmentType?.deliveryOptions && (
                <DeliveryMode
                  appointmentType={appointmentType}
                  isEditing={isEditingDeliveryMode}
                  setIsEditing={setIsEditingDeliveryMode}
                  isReservedAppointment={isReservedAppointment}
                />
              )}
              {appointment.recurrings && (
                <div className={styles.eventInformationContainer}>
                  <div className={styles.iconContainer}>
                    <span className="material-icons-outlined">repeat</span>
                  </div>
                  <div className={styles.description}>
                    <div className={styles.descriptionText}>
                      {recurrIdx + 1} of {appointment.recurrings.length}
                    </div>
                  </div>
                </div>
              )}
              {!isReservedAppointment && (
                <div className={styles.markAppointmentSelectContainer}>
                  <MarkStatus appointment={appointment} token={token} onFinishMarkStatus={onFinishMarkStatus} />
                </div>
              )}
              <div className={styles.footer}>
                {(appointment.room?.roomId !== values.roomId && isEditingRoom) || isEditingDeliveryMode ? (
                  <Button variant="primary" className={styles.button} onClick={submitForm} status={submitStatus}>
                    Update Appointment
                  </Button>
                ) : (
                  <Popover
                    title="Are you sure to cancel this appointment?"
                    content={() => (
                      <CancelPopover appointment={appointment} onFinishMarkStatus={onFinishMarkStatus} token={token} />
                    )}
                    trigger="click"
                  >
                    {!isReservedAppointment && (
                      <Button variant={'secondary'} className={styles.button}>
                        Cancel
                      </Button>
                    )}
                  </Popover>
                )}
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EventDetails;
