import { GroupType } from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';
import styles from './GroupTypeBadge.module.scss';
import Badge from 'components/Badge/Badge';

interface BadgeProps {
  type: GroupType;
  className?: any;
  backgroundColor?: string;
}

const getGroupTypeBadge = (type: GroupType) => {
  switch (type) {
    case GroupType.Registration:
      return { label: 'Registration', backgroundColor: '#4BC27D' };
    case GroupType.Year:
      return { label: 'Year', backgroundColor: '#75559F' };
    case GroupType.Other:
    default:
      return { label: 'Other', backgroundColor: '#1B365C' };
  }
};

export const GroupTypeBadge = ({ type }: BadgeProps) => {
  return (
    <Badge
      className={styles.groupTypeBadge}
      label={getGroupTypeBadge(type).label}
      backgroundColor={getGroupTypeBadge(type).backgroundColor}
    />
  );
};

export default Badge;
