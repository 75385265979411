import moment from 'moment';

export const timeDiff = (currentTime: any, updateTime: any) => {
  const duration = Math.floor(currentTime.diff(updateTime, 'second'));

  if (duration >= 3600) {
    return moment(updateTime).format('lll');
  } else if (duration >= 60 && duration <= 3599) {
    return `${Math.floor(duration / 60)} minute ago`;
  } else if (duration <= 59) {
    return `seconds ago`;
  } else {
    return moment(updateTime).format('lll');
  }
};

export const checkIsTodayOrYesterday = (time: any) => {
  const updateTime = moment(time);
  const TodayDate = moment();
  const TODAY = TodayDate.clone().startOf('day');
  const YESTERDAY = TodayDate.clone().subtract(1, 'days').startOf('day');

  if (moment(updateTime).isSame(TODAY, 'd')) {
    return 'today';
  } else if (moment(updateTime).isSame(YESTERDAY, 'd')) {
    return 'yesterday';
  } else {
    return 'other';
  }
};
