import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useMemo, useState } from 'react';
import styles from './InterventionForm.module.scss';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import {
  CreateInterventionValidateField,
  CreateInterventionRequestPayload
} from '../../CreateInterventionModalInterfaces';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateInterventionId } from './validation/InterventionFormValidation';
import Select from 'components/v2/Select/Select';
import { useFetchInterventionLibrary } from 'MHH/pages/InterventionLibrary/hooks/useFetchInterventionLibrary';
import { Skeleton } from 'antd';

interface InterventionFormProps {
  selectedClinicianId: string;
  checkValidation: boolean;
  onChangeClinician: (b: string) => void;
  errorMessage: CreateInterventionValidateField;
  interventionFormField: CreateInterventionRequestPayload;
  onChangeInterventionField: any;
  accountId: string;
}

const InterventionForm = ({
  selectedClinicianId,
  checkValidation,
  onChangeClinician,
  errorMessage,
  interventionFormField,
  onChangeInterventionField,
  accountId
}: InterventionFormProps) => {
  const { token } = useGetAccessToken();
  const [duplicateInterventionId, setDuplicateInterventionId] = useState(false);
  const [isCheckingInterventionIdDuplicate, setIsCheckingInterventionIdDuplicate] = useState(false);
  const { interventionLibrary, isInterventionLibraryLoading } = useFetchInterventionLibrary(token);

  const debouncedCheckInterventionIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateInterventionId(token, accountId, value);
        setDuplicateInterventionId(duplicate.statusCode !== 200);
        setIsCheckingInterventionIdDuplicate(false);
      }, 1000),
    [token, accountId]
  );

  const validateDuplicateInterventionId = async (interventionIdValue: string) => {
    setIsCheckingInterventionIdDuplicate(true);
    await debouncedCheckInterventionIdDuplicate(interventionIdValue);
  };

  const onSelectClinician = (selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      const newClinicianField = {
        ...interventionFormField,
        leadClinicianAuth0Id: selectedClinician._id
      };
      onChangeClinician(selectedClinician._id);
      onChangeInterventionField(newClinicianField);
    }
  };

  const handleChangeFieldValue = async (key: string, val: string) => {
    const newField = {
      ...interventionFormField,
      [key]: val
    };
    onChangeInterventionField(newField);
    if (key === 'interventionId') {
      if (checkValidation && val) {
        await validateDuplicateInterventionId(val);
      } else {
        setIsCheckingInterventionIdDuplicate(false);
        setDuplicateInterventionId(false);
      }
    }
  };

  return (
    <>
      <div
        className={classNames(styles.fieldContainer, checkValidation && duplicateInterventionId && styles.fieldError)}
      >
        <MaterialInput
          id={`interventionId-${interventionFormField.interventionId}`}
          label={`Intervention ID`}
          maxLength={20}
          isLoading={isCheckingInterventionIdDuplicate}
          onChange={(e) => handleChangeFieldValue('interventionId', e.target.value)}
          value={interventionFormField.interventionId}
          required
        />
        {checkValidation && duplicateInterventionId && (
          <div className={styles.fieldError}>This intervention id is already in use</div>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.leadLabel}>Intervention Library*</div>
        {isInterventionLibraryLoading ? (
          <Skeleton.Input active className={styles.inputSkeletonLoading} />
        ) : (
          <Select
            options={interventionLibrary.map((i) => ({ label: i.name, value: i.id }))}
            onChange={(value) => handleChangeFieldValue('interventionLibraryItemId', value?.value || '')}
            placeholder="Select Intervention Library"
            //@ts-ignore
            value={{
              value: interventionFormField.interventionLibraryItemId,
              label:
                interventionLibrary.find((i) => i.id === interventionFormField.interventionLibraryItemId)?.name || ''
            }}
          />
        )}
        {checkValidation && errorMessage.interventionLibraryItemId && (
          <div className={styles.fieldError}>{errorMessage.interventionLibraryItemId}</div>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.leadLabel}>Intervention Lead</div>
        <ClinicianSelect
          includePractice
          selectedId={selectedClinicianId}
          filterRoles={[AccessRight.Admin]}
          onSelect={(clinician) => onSelectClinician(clinician)}
          materialStyle
          hideAvatar
        />
      </div>
      <div className={classNames(styles.fieldContainer, checkValidation && errorMessage.name && styles.fieldError)}>
        <MaterialInput
          id={`interventionName`}
          label={`Intervention Name*`}
          required
          value={interventionFormField.name}
          onChange={(e) => handleChangeFieldValue('name', e.target.value)}
        />
        {checkValidation && errorMessage.name && <div className={styles.fieldError}>{errorMessage.name}</div>}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Intervention Short Description</div>
        <textarea
          className={styles.textarea}
          value={interventionFormField.description}
          onChange={(e) => handleChangeFieldValue('description', e.target.value)}
        />
      </div>
    </>
  );
};

export default InterventionForm;
