const MAX_HEIGHT = 140;
const MAX_WIDTH = 200;

export const resizeAndCropImage = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    try {
      const image = new Image();

      const canvas = document.createElement('canvas');

      canvas.style.display = 'none';

      image.onload = () => {
        let resizeHeight = image.height;
        let resizeWidth = image.width;

        if (resizeHeight > MAX_HEIGHT) {
          resizeWidth = (MAX_HEIGHT / resizeHeight) * resizeWidth;
          resizeHeight = MAX_HEIGHT;
        }

        if (resizeWidth > MAX_WIDTH) {
          resizeHeight = (MAX_WIDTH / resizeWidth) * resizeHeight;
          resizeWidth = MAX_WIDTH;
        }

        canvas.height = resizeHeight;
        canvas.width = resizeWidth;

        canvas.getContext('2d')?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toDataURL();

        canvas.remove();
        image.remove();

        resolve(dataUrl);
      };

      image.src = URL.createObjectURL(file);
    } catch (ex) {
      reject(ex);
    }
  });
};
