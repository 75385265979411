import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import { useState, Dispatch, SetStateAction } from 'react';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import Button from '../Button/Button';
import styles from './AvailableTimeSelect.module.scss';
import AvailabilitiesViewTypeToggle, {
  AvailabilitiesViewType
} from './components/AvailabilitiesViewTypeToggle/AvailabilitiesViewTypeToggle';
import DayCard, { SelectedAppointmentSlot } from './components/DayCard/DayCard';
import { getCurrentWeekAppointmentSlots } from './helpers/getCurrentWeekAppointmentSlots';
import { getNextAvailableDate } from './helpers/getNextAvailableDate';
import { useFetchAppointmentTypeAvailabilities } from './hooks/GetAvailabilityByAppointmentType';

const MAX_DAYS = 365;

interface AvailableTimeSelectProps {
  appointmentTypeId: string;
  showAppointmentTypeName?: boolean;
  onSelectSlot?: (slot?: SelectedAppointmentSlot) => void;
  selectedClinicianId?: string;
  isEditing: boolean;
  setIsCustomising?: Dispatch<SetStateAction<boolean>>;
  containerClassName?: string;
  selectedRoomId?: string;
}

const AvailableTimeSelect = ({
  appointmentTypeId,
  selectedClinicianId,
  onSelectSlot,
  isEditing,
  setIsCustomising,
  containerClassName,
  selectedRoomId
}: AvailableTimeSelectProps) => {
  const [, , dateHelper] = useField('date');
  const [, , startTimeHelper] = useField('startTime');
  const [, , endTimeHelper] = useField('endTime');

  const { isEdgePractitioner } = useGetAccountPackageView();
  const [firstDay, setFirstDay] = useState<Moment>(moment());
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState<SelectedAppointmentSlot>();
  const [availabilitiesViewType, setAvailabilitiesViewType] = useState<AvailabilitiesViewType>(
    AvailabilitiesViewType.Simple
  );

  const isPrevButtonEnabled = firstDay.diff(moment(), 'd') > 0;
  const isNextButtonEnabled = firstDay.clone().diff(moment(), 'd') < MAX_DAYS;

  const { appointmentTypeAvailabilities, isLoading } = useFetchAppointmentTypeAvailabilities(
    moment().format(MOMENTJS_FORMAT_DATE),
    moment().add(MAX_DAYS, 'd').endOf('isoWeek').format(MOMENTJS_FORMAT_DATE),
    'booking',
    appointmentTypeId || '',
    selectedClinicianId ? selectedClinicianId : '',
    selectedRoomId
  );

  const goPrev = () => {
    if (isPrevButtonEnabled) {
      setFirstDay(firstDay.clone().subtract(1, 'w'));
    }
  };

  const goNext = () => {
    if (isNextButtonEnabled) {
      setFirstDay(firstDay.clone().add(1, 'w'));
    }
  };

  const handleSelectSlot = (slot: SelectedAppointmentSlot) => {
    setSelectedAppointmentSlot(slot);
    if (onSelectSlot) {
      dateHelper.setValue(slot.date);
      startTimeHelper.setValue(slot.startTime);
      endTimeHelper.setValue(slot.endTime);
      onSelectSlot(slot);
    }
  };

  const jumpToNextAvailableSlot = () => {
    const nextAvailableDate = getNextAvailableDate({
      currentDate: firstDay,
      appointmentTypeAvailabilities
    });

    if (nextAvailableDate) {
      setFirstDay(nextAvailableDate.startOf('isoWeek'));
    }
  };

  const {
    currentWeekAppointmentSlots,
    hasNextAvailableSlot,
    currentWeekHasNoAvailability
  } = getCurrentWeekAppointmentSlots({
    currentDate: firstDay,
    appointmentTypeAvailabilities
  });

  const shouldShowTimeSelectionCustomization = !!setIsCustomising && !isEdgePractitioner;
  const shouldShowJumpToNextAvailableSlot = currentWeekHasNoAvailability && hasNextAvailableSlot && isNextButtonEnabled;
  const shouldShowLoading = isLoading || !appointmentTypeAvailabilities;

  return shouldShowLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={styles.header}>
        <div className={styles.appointmentNameContainer}>
          <div className={styles.label}>SHOWING OPEN TIMES FOR</div>
          <div className={styles.text}>{appointmentTypeAvailabilities.appointmentType.name}</div>
        </div>
        {shouldShowTimeSelectionCustomization && (
          <div className={styles.customise} onClick={() => setIsCustomising(true)}>
            Customise
            <i className={`material-icons`}>design_services</i>
          </div>
        )}
        <div className={styles.toggleContainer}>
          <div className={styles.toggle}>
            <AvailabilitiesViewTypeToggle
              currentViewType={availabilitiesViewType}
              onChange={setAvailabilitiesViewType}
            />
          </div>
        </div>
      </div>
      {isEditing && (
        <div className={styles.navContainerEdit}>
          <div className={classNames(styles.leftControl, !isPrevButtonEnabled && styles.disabled)} onClick={goPrev}>
            <span className="material-icons-outlined">keyboard_arrow_left</span>
          </div>
          <div className={classNames(styles.rightControl, !isNextButtonEnabled && styles.disabled)} onClick={goNext}>
            <span className="material-icons-outlined">keyboard_arrow_right</span>
          </div>
        </div>
      )}
      <div className={styles.weeksContainer}>
        <div className={styles.daysContainer}>
          {currentWeekAppointmentSlots.map((appointmentSlots, index) => (
            <DayCard
              isAvailabilitiesViewTypeSimple={availabilitiesViewType === AvailabilitiesViewType.Simple}
              key={index}
              data={appointmentSlots}
              selectedAppointmentSlot={selectedAppointmentSlot}
              onSelectSlot={handleSelectSlot}
            />
          ))}
        </div>
        {!isEditing && (
          <div className={styles.navContainer}>
            <Button variant="link" icon="arrow_back" onClick={goPrev} disabled={!isPrevButtonEnabled}>
              Previous week
            </Button>
            {shouldShowJumpToNextAvailableSlot && (
              <Button className={styles.button} variant="link" onClick={jumpToNextAvailableSlot}>
                JUMP TO NEXT AVAILABLE
                <i className={'material-icons'}>skip_next</i>
              </Button>
            )}
            <Button variant="link" icon="arrow_forward" iconPostFix disabled={!isNextButtonEnabled} onClick={goNext}>
              Next week
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableTimeSelect;
