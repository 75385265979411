import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useField } from 'formik';
import styles from './FaceToFaceLocation.module.scss';

const FaceToFaceLocation = () => {
  const [{ value }, , { setValue }] = useField('faceToFaceLocation');
  return (
    <div className={classNames(styles.fieldContainer)}>
      <MaterialInput
        id={`appointmentTypeLocation`}
        label={'Location'}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        style={{ background: '#f2f9ff' }}
        required
      />
    </div>
  );
};

export default FaceToFaceLocation;
