import { Component, FunctionComponent } from 'react';
import { Route } from 'react-router-dom';

interface AppRouteProps {
  component: typeof Component | FunctionComponent;
  layout: typeof Component | FunctionComponent;
  path: string;
}

const RouteWithLayout = ({ component: Component, layout: Layout, path }: AppRouteProps) => {
  return (
    <Route
      path={path}
      element={
        <Layout>
          <Component />
        </Layout>
      }
    />
  );
};

export default RouteWithLayout;
