import { useEffect, useState } from 'react';

import styles from './OnboardingSelect.module.scss';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { CheckBoxListing } from '../../../../AddPatientModalV2Interface';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

interface OnboardingSelectProps {
  id: string;
  clientName?: string;
  value: string;
  checkValidation: boolean;
  onBoardingList: CheckBoxListing[];
  isListLoading: boolean;
  onChangeOnboardValue: (b: string) => void;
}

const OnboardingSelect = ({
  id,
  clientName,
  value,
  checkValidation,
  onBoardingList,
  isListLoading,
  onChangeOnboardValue
}: OnboardingSelectProps) => {
  const [isShowError, setShowError] = useState(checkValidation && !value);

  useEffect(() => {
    if (checkValidation) {
      setShowError(checkValidation && !value);
    }
  }, [checkValidation, value]);

  const handleSurveyChange = (val: string) => {
    onChangeOnboardValue(val);
  };

  return (
    <div>
      <MaterialSelect
        id={`clientOnboardingSurvey${id}`}
        label={clientName ? `${clientName} survey` : 'Select Survey'}
        isSearchable={false}
        options={onBoardingList}
        value={value}
        onChange={(val: string) => handleSurveyChange(val)}
        loading={isListLoading}
      />
      <ErrorMessage
        className={styles.error}
        error={'Please select an onboarding survey'}
        visible={checkValidation && isShowError}
      />
    </div>
  );
};

export default OnboardingSelect;
