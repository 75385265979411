import { useState, useRef, useEffect, MouseEvent } from 'react';
import styles from './ReadMore.module.scss';
import classnames from 'classnames';

const DEFAULT_EMPTY_TEXT = 'No details added';

interface ReadMoreProps {
  text: string;
  maxHeight: number;
  bodyClass?: string;
  emptyText?: string;
}

const ReadMore = ({ text, maxHeight, emptyText, bodyClass }: ReadMoreProps) => {
  const [bodyHeight, setBodyHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const noteBody = useRef<HTMLDivElement>(null);

  const showText = text.replace(/<\/?[^>]+(>|$)/g, '').length > 0;

  useEffect(() => {
    noteBody && setBodyHeight(noteBody.current?.clientHeight || 0);
  }, [noteBody.current?.clientHeight]);

  const expandTextBody = (event: MouseEvent) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.container}>
      {showText ? (
        <>
          <div
            className={classnames(styles.content, bodyClass)}
            style={{ maxHeight: `${isExpanded ? bodyHeight : maxHeight}px` }}
          >
            <div ref={noteBody} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          {bodyHeight > maxHeight && (
            <span onClick={expandTextBody} className={styles.showHideController}>
              <i className={`material-icons ${styles.icon}`}>{isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
              {isExpanded ? 'Show less' : ' Read all'}
            </span>
          )}
        </>
      ) : (
        <div className={styles.emptyBody}>{emptyText || DEFAULT_EMPTY_TEXT}</div>
      )}
    </div>
  );
};

export default ReadMore;
