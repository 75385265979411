import FormSection from 'components/v2/FormSection/FormSection';
import Radio from 'components/Radio/Radio';
import styles from './AppointmentParticipantType.module.scss';
import { useField } from 'formik';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

const APPOINTMENT_PARTICIPANT = [
  { label: 'One to one appointment', value: ParticipantType.OneToOne },
  { label: 'Group Appointment', value: ParticipantType.Group }
];

interface AppointmentParticipantTypeProps {
  disabled?: boolean;
}

const AppointmentParticipantType = ({ disabled }: AppointmentParticipantTypeProps) => {
  const [{ value, onChange }] = useField('participantType');

  return (
    <FormSection title="Appointment type" help="Choose which appointment type to create">
      <div className={styles.assignmentForm}>
        <Radio
          disabled={disabled}
          name="participantType"
          options={APPOINTMENT_PARTICIPANT}
          value={value}
          onChange={onChange}
          vertical
        />
      </div>
    </FormSection>
  );
};

export default AppointmentParticipantType;
