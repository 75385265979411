import Radio from 'components/Radio/Radio';
import { useField } from 'formik';

const editRoomStatusOptions = [
  { value: 'openForBooking', label: 'Open for booking' },
  { value: 'pauseBooking', label: 'Pause to new bookings, keep any existing' },
  { value: 'pauseAndCancelBooking', label: 'Pause to new bookings, cancel any existing' }
];

const createRoomStatusOptions = [
  { value: 'openForBooking', label: 'Open for booking' },
  { value: 'pauseBooking', label: 'Pause to new bookings' }
];

type RoomStatusProps = {
  isEdit?: boolean;
};

const RoomStatus = ({ isEdit = false }: RoomStatusProps) => {
  const [{ value, onChange }] = useField('roomStatus');

  return (
    <Radio
      name="roomStatus"
      options={isEdit ? editRoomStatusOptions : createRoomStatusOptions}
      value={value}
      onChange={onChange}
      nowrap
    />
  );
};

export default RoomStatus;
