import styles from './WidgetCard.module.scss';
import WidgetItemWrapper from 'pages/Report/ReportDetails/components/ReportBuilder/components/WidgetSideBar/components/EditTab/components/WidgetItemWrapper/WidgetItemWrapper';
import { ReportWidgetType } from 'interfaces/Reports/report';

interface WidgetsListMenuProps {
  data: any;
  widgetType: ReportWidgetType;
  itemLabel: string;
  inUsed: boolean;
}

const WidgetCard = ({ data, widgetType, itemLabel, inUsed }: WidgetsListMenuProps) => (
  <WidgetItemWrapper data={data} widgetType={widgetType}>
    <div className={styles.container}>
      <div className={styles.widgetItem}>{itemLabel}</div>
      {inUsed && <div className={styles.activeDot} />}
    </div>
  </WidgetItemWrapper>
);

export default WidgetCard;
