import { useState, useEffect, useRef } from 'react';
import styles from './ViewAction.module.scss';
import { GroupAssessmentClient, GroupDetailsId, GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import classnames from 'classnames';
import { combineName } from 'utils/general';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface ViewActionProps {
  psychometric: GroupAssessmentClient;
}

const ViewAction = ({ psychometric }: ViewActionProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();
  const path = useParams<{ [key in keyof GroupDetailsId]: string | undefined }>();
  const groupId = path.groupId;
  const psychometricId = path.contentId;
  const selectedClinicalAssessmentId = path.childContentId ?? '';

  const menuNode = useRef<HTMLDivElement>(null);
  const menuWrapperNode = useRef<HTMLDivElement>(null);
  const [menuExpand, setMenuExpand] = useState(false);

  const disabledViewIcon =
    psychometric.clientProfiles.filter((clientP) => clientP._id?.length > 0 && clientP.assessmentComplete).length < 1;

  const handleClick = (e: any) => {
    if (menuWrapperNode.current?.contains(e.target)) {
      return;
    }
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleDetailAction = (responseId: string) => {
    navigate(
      `${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Psychometrics}/${psychometricId}` +
        `/clinicalAssessmentId/${selectedClinicalAssessmentId}/responseId/${responseId}` +
        `/clientRecordId/${psychometric.clientRecordId}`
    );
  };

  const handleExpandMenu = () => {
    if (!disabledViewIcon && psychometric.clientProfiles.length > 1) {
      setMenuExpand(!menuExpand);
    } else if (!disabledViewIcon) {
      handleDetailAction(psychometric.clientProfiles[0]._id);
    }
  };

  return (
    <div ref={menuNode} className={disabledViewIcon ? styles.disabledViewIcon : ''}>
      <div onClick={handleExpandMenu}>
        <i className={`material-icons ${styles.detailIcon}`}>arrow_forward</i>
      </div>
      <div ref={menuWrapperNode} className={styles.menuWrapper}>
        <div className={menuExpand ? styles.menuShow : styles.menuHide}>
          {psychometric.clientProfiles.length > 1 &&
            psychometric.clientProfiles.map((client, index) => (
              <div
                id={client._id}
                key={index}
                className={classnames(
                  styles.itemCard,
                  !(client._id || client.assessmentComplete) ? styles.disabledSubMenu : ''
                )}
                onClick={() => {
                  client._id && client.assessmentComplete && handleDetailAction(client._id);
                }}
              >
                <div className={styles.label}>{combineName([client])}</div>
                <i className={`material-icons ${styles.itemIcon}`}>navigate_next</i>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ViewAction;
