import { useState, useEffect } from 'react';
import styles from './PsychometricListContent.module.scss';
import columnStyles from './PsychometricListContentColumn.module.scss';
import classnames from 'classnames';
import { GroupAssessmentClient } from 'pages/Groups/Interfaces/Groups';
import { Skeleton } from 'antd';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import { combineName } from 'utils/general';
import ViewAction from '../ViewAction/ViewAction';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface PsychometricListContentProps {
  psychometricList: GroupAssessmentClient[];
  isPsychometricListLoading: boolean;
}

const PsychometricListContent = ({ psychometricList, isPsychometricListLoading }: PsychometricListContentProps) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [sortType, setSortType] = useState<SortType>(SortType.noSort);
  const [sortedList, setSortedList] = useState(psychometricList);

  useEffect(() => {
    if (psychometricList) {
      setSortedList(psychometricList);
      setSortType(SortType.noSort);
    }
  }, [psychometricList]);

  const onChangeSortType = (sortType: SortType) => {
    setSortType(sortType);
    const newSortedList = psychometricList.sort((a, b) => {
      const nameA = combineName(a.clientProfiles);
      const nameB = combineName(b.clientProfiles);
      return sortType === SortType.asc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    setSortedList(newSortedList);
  };

  return (
    <div className={styles.container}>
      <div className={classnames(columnStyles.columnWrapper, styles.header)}>
        <div className={columnStyles.avatar}>AVATAR</div>
        <div className={classnames(columnStyles.fullName, sortType !== SortType.noSort ? columnStyles.active : '')}>
          FULL NAME
          <SortBtn selectedSortName={sortType} onChangeSort={onChangeSortType} disabled={isPsychometricListLoading} />
        </div>
        <div className={columnStyles.assessmentSent}>ASSESSMENT SENT</div>
        <div className={columnStyles.assessmentComplete}>ASSESSMENT COMPLETE</div>
        {!isEdgeReceptionist && (
          <>
            <div className={columnStyles.score}>SCORE</div>
            <div className={columnStyles.view}>VIEW</div>
          </>
        )}
      </div>
      {isPsychometricListLoading ? (
        <>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))}
        </>
      ) : sortedList && sortedList.length > 0 ? (
        <>
          {sortedList.map((psychometric, index) => (
            <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
              <div className={columnStyles.avatar}>
                <ClientAvatar
                  clientData={psychometric.clientProfiles}
                  displayLimit={psychometric.clientProfiles.length}
                  hideName
                />
              </div>
              <div className={classnames(columnStyles.fullName, styles.name)}>
                {psychometric.clientProfiles.map((profile, index) => (
                  <div key={index}>{combineName([profile])}</div>
                ))}
              </div>
              <div className={classnames(columnStyles.assessmentSent, styles.isAssessmentSent, styles.isSentText)}>
                {psychometric.clientProfiles.map((profile, index) => (
                  <div key={index}>{profile.assessmentSent ? 'Yes' : 'Preference set to not send'}</div>
                ))}
              </div>
              <div
                className={classnames(
                  columnStyles.assessmentComplete,
                  styles.isAssessmentComplete,
                  styles.isCompleteText
                )}
              >
                {psychometric.clientProfiles.map((profile, index) => (
                  <div key={index}>
                    {!profile.assessmentSent ? 'Preference set to not send' : profile.assessmentComplete ? 'Yes' : 'No'}
                  </div>
                ))}
              </div>
              {!isEdgeReceptionist && (
                <>
                  <div className={classnames(columnStyles.score, styles.scoreText)}>
                    {psychometric.clientProfiles.filter((clientP) => clientP.processedScore).length > 0 ? (
                      psychometric.clientProfiles.map((profile, index) => (
                        <div key={index}>
                          {profile.processedScore ? (
                            <div>
                              {Object.keys(profile.processedScore).map((key, index) => (
                                <div
                                  className={styles.scoreKey}
                                  key={index}
                                >{`${key}: ${profile.processedScore[key]}`}</div>
                              ))}
                            </div>
                          ) : (
                            <div className={styles.noValue}>-</div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className={styles.noValue}>-</div>
                    )}
                  </div>
                  <div className={columnStyles.view}>
                    <ViewAction psychometric={psychometric} />
                  </div>
                </>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className={styles.noPsychometricFoundContainer}>No psychometric found</div>
      )}
    </div>
  );
};

export default PsychometricListContent;
