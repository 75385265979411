import { useMemo, CSSProperties, EventHandler, ReactElement } from 'react';
import ReactQuill from 'react-quill';
import * as Quill from 'quill';

import 'react-quill/dist/quill.snow.css';
import styles from './TextEditor.module.scss';

export interface TextEditorProps {
  id?: string;
  className?: string;
  theme?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  value?: string | Quill.Delta;
  defaultValue?: string | Quill.Delta;
  placeholder?: string;
  tabIndex?: number;
  bounds?: string | HTMLElement;
  scrollingContainer?: string | HTMLElement;
  onChange: (e: any) => void;
  onChangeSelection?: (range: Quill.RangeStatic, source: Quill.Sources, editor: any) => void;
  onFocus?: (range: Quill.RangeStatic, source: Quill.Sources, editor: any) => void;
  onBlur?: (previousRange: Quill.RangeStatic, source: Quill.Sources, editor: any) => void;
  onKeyPress?: EventHandler<any>;
  onKeyDown?: EventHandler<any>;
  onKeyUp?: EventHandler<any>;
  formats?: string[];
  children?: ReactElement<any>;
  modules?: Quill.StringMap;
  preserveWhitespace?: boolean;
}

const TextEditor = ({ id, value, onChange, ...props }: TextEditorProps) => {
  const modules = useMemo(
    () => ({
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          { color: ['red', 'green', 'blue', 'orange', 'violet', '#d0d1d2', styles.blackColor] },
          { align: [] },
          { list: 'ordered' },
          { list: 'bullet' }
        ]
      ]
    }),
    []
  );

  return (
    <div className={`tacklit-text-editor ${styles.container}`}>
      <ReactQuill id={id} modules={modules} value={value} onChange={onChange} {...props} />
    </div>
  );
};

export default TextEditor;
