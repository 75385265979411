import { notification, Skeleton } from 'antd';
import { useFetchAssessmentList } from 'components/ClientOrGroupPickAssessment/getAssessmentList';
import ModalV2 from 'components/ModalV2/ModalV2';
import Assessment from 'components/PatientQuestionForm/components/Assessment/Assessment';
import Button from 'components/v2/Button/Button';
import OptionList from 'pages/Groups/GroupDetails/components/GroupContent/components/Common/OptionList/OptionList';
import { useState } from 'react';
import { formatAssessmentResponse } from 'utils/CORDS/formatAssessmentResponse';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postSubmitClinicianAssessment } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { useFetchAssessment } from '../../hooks/actions';

import styles from './SubmitPsychometricsModal.module.scss';

interface SubmitPsychometricsModalProps {
  visible: boolean;
  clientRecordId: string;
  onCloseModal: () => void;
}

const SubmitPsychometricsModal = ({ visible, clientRecordId, onCloseModal }: SubmitPsychometricsModalProps) => {
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const { assessmentList, isAssessmentLoading: isAssessmentsLoading } = useFetchAssessmentList(token);
  const [step, setStep] = useState(1);
  const [selectedAssessmentId, setselectedAssessmentId] = useState<string>('');
  const { assessment, isAssessmentLoading } = useFetchAssessment(token, selectedAssessmentId);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const onSelectAssessmentHandle = (isChecked: boolean, id: string) => {
    const selected = assessmentList?.filter((assessment) => assessment.id === id)[0];
    setselectedAssessmentId(selected.id);
    setStep(2);
  };

  const listOption = assessmentList?.map((assessment) => ({
    id: assessment.id,
    title: assessment.title || '',
    description: assessment.description || ''
  }));

  const onModalClose = () => {
    onCloseModal();
    setStep(1);
  };

  const handleSubmit = async (assessmentId: string, values: { [key: string]: Record<string, any> }) => {
    setSubmitStatus('active');

    try {
      if (assessment && token) {
        await postSubmitClinicianAssessment({
          token,
          clinicianId: auth0ClinicianId,
          clientRecordId,
          body: {
            assessmentId,
            responses: formatAssessmentResponse({ values, assessmentId })
          }
        });
        setSubmitStatus('finished');
        notification.success({ message: 'Assessment response is submitted' });
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to submit this assessment' });
    }

    setSubmitStatus('');
    onModalClose();

    return undefined;
  };

  const handleBackStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <ModalV2
      containerClassName={styles.container}
      isModalOpen={visible}
      title={'Submit Assessment'}
      onModalClose={onModalClose}
      currentStep={step}
      totalStep={2}
      onClickBackStepBtn={handleBackStep}
    >
      {step === 1 && (
        <>
          {isAssessmentsLoading ? (
            <div className={styles.loadingContainer}>
              {[...Array(6)].map((obj, i) => (
                <div key={i} className={styles.loadingBox}>
                  <Skeleton.Input active className={i === 0 ? styles.loadingSearchbar : styles.loadingName} />
                </div>
              ))}
            </div>
          ) : (
            <OptionList listOption={listOption} selectedOptions={[]} onSelect={onSelectAssessmentHandle} />
          )}
        </>
      )}
      {step === 2 &&
        (isAssessmentLoading ? (
          <div className={styles.loadingContainer}>
            {[...Array(6)].map((obj, i) => (
              <div key={i} className={styles.loadingBox}>
                <Skeleton.Input active className={styles.loadingSearchbar} />
              </div>
            ))}
          </div>
        ) : (
          <>
            {assessment ? (
              <Assessment
                assessment={assessment}
                isLoading={isAssessmentLoading}
                completedQuestions={0}
                submitBtnText={
                  <Button status={submitStatus} className={styles.submitButton}>
                    Submit
                  </Button>
                }
                submitBtnClass={styles.submitButtonContainer}
                onSubmit={handleSubmit}
                reverseStemAndHideTitle={false}
                isSkippable={false}
                isFirstQuestionSet={false}
                header={() => <></>}
                onBack={() => {}}
                onSaveAndExit={() => {}}
                onSkip={() => {}}
                disableFullscreenLoading
              />
            ) : (
              <div>Selected assessment not found</div>
            )}
          </>
        ))}
    </ModalV2>
  );
};

export default SubmitPsychometricsModal;
