import { getProviderLabel } from 'pages/ControlPanel/IntegrationDetails/utils';
import { useState } from 'react';

import { INVOICE_MANDATORY_FIELDS } from '../../constants';
import styles from './MandatoryFieldsInfo.module.scss';

interface MandatoryFieldsInfoProps {
  provider: string;
}

const MandatoryFieldsInfo = ({ provider }: MandatoryFieldsInfoProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer} onClick={() => setIsExpand(!isExpand)}>
        <div className={styles.title}>
          These are the <span className={styles.highlight}>invoice data</span> from Tacklit that automatically push to
          {` ${getProviderLabel(provider)}`}.
        </div>
        <i className={`material-icons ${styles.icon}`}>{isExpand ? 'expand_less' : 'expand_more'}</i>
      </div>
      <div className={isExpand ? styles.fields : styles.hiddenFields}>
        {INVOICE_MANDATORY_FIELDS.map(({ id, selectedField, label }) => (
          <div className={styles.rowContainer} key={id}>
            <div className={styles.checkboxContainer}>
              <input className={styles.checkbox} type="checkbox" checked disabled />
            </div>
            <div className={styles.label}>{label}</div>
            <div className={styles.mapsTo}>maps to</div>
            <div className={styles.selectionContainer}>{selectedField}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MandatoryFieldsInfo;
