import styles from './PolicyContainer.module.scss';

const PolicyContainer = ({ children }: { children: JSX.Element }) => (
  <div className={styles.policyContainer}>
    <div className={styles.content}>
      <div className={styles.scrollContainer}>{children}</div>
    </div>
  </div>
);

export default PolicyContainer;
