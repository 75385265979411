import { Section } from '../../../../../../AssessmentInterfaces';

import Question from '../../../Question/Question';

import styles from './Section.module.scss';

interface SectionComponentProps {
  section: Section;
  defaultPatientDetails?: { name: string; picture: string };
}

const SectionComponent = ({ section, defaultPatientDetails }: SectionComponentProps) => {
  const { name, questions } = section;

  return (
    <>
      {name && <div className={styles.name}>{name}</div>}
      {questions.map((question, index) => (
        <Question key={index} question={question} defaultPatientDetails={defaultPatientDetails} />
      ))}
    </>
  );
};

export default SectionComponent;
