import { createContext, useEffect, ReactNode } from 'react';
import { useFetchProfile } from './hooks/GetProfileDetails/getProfileInfo';
import Loading from '../components/Loading/Loading';
import NoAuthLayout from '../layouts/NoAuthLayout/NoAuthLayout';
import { ProfileInterface } from '../interfaces/Profile/Profile';
import { useTranslation } from 'react-i18next';
import { getTerminology } from 'i18n/resources/en/terminology';

export enum CustomFeature {
  Mentor = 'mentor',
  Analytics = 'analytics',
  Vadc = 'vadc',
  HelmContent = 'helmContent',
  Helm = 'helm',
  MedicareRebate = 'medicareRebate',
  MentalHealthHubLogo = 'mentalHealthHubLogo',
  RequestApprovalByDefault = 'requestApprovalByDefault',
  CaseNotesTemplate = 'caseNotesTemplate'
}

const initialValues = {
  customFeatures: [],
  accountSettings: {},
  profileSettings: {},
  account: {}
};

export const UserContext = createContext<{
  customFeatures: CustomFeature[];
  accountSettings: ProfileInterface['accountSettings'];
  profileSettings: ProfileInterface['profileSettings'];
  account: ProfileInterface['account'];
}>(initialValues);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { profile, isProfileLoading } = useFetchProfile();
  const [, trans] = useTranslation();

  useEffect(() => {
    if (profile && profile.accountSettings) {
      trans.addResourceBundle('en', 'terminology', getTerminology(profile.accountSettings), true, true);
    }
  }, [profile, trans]);
  return isProfileLoading ? (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  ) : (
    <UserContext.Provider
      value={{
        customFeatures: profile.customFeatures || [],
        accountSettings: profile.accountSettings,
        profileSettings: profile.profileSettings,
        account: profile.account
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
