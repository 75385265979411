import { ChangeEvent, createRef, RefObject, useEffect, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';

import styles from './ImageCropper.module.scss';
import classNames from 'classnames';

interface ImageCropperProps {
  className?: string;
  edittorClassName?: string;
  borderRadius?: number;
  imgSrc?: string;
  handleRemove?: () => void;
  handleUploadNew?: () => void;
  showControl?: boolean;
  showRemove?: boolean;
  showSize?: boolean;
  defaultSize?: [number, number];
  originalSize?: boolean;
  setRef?: (ref: RefObject<ReactAvatarEditor>) => void;
  onLoadImgSrc?: (img: HTMLImageElement) => void;
  resize?: boolean;
}

const ImageCropper = ({
  className,
  edittorClassName,
  imgSrc,
  borderRadius,
  handleRemove,
  handleUploadNew,
  showControl,
  showRemove,
  showSize,
  defaultSize,
  originalSize,
  setRef,
  onLoadImgSrc,
  resize
}: ImageCropperProps) => {
  const defaultWidth = defaultSize?.[0] ? defaultSize[0] : 400;
  const defaultHeight = defaultSize?.[1] ? defaultSize[1] : 400;
  const [imageOriginalSize, setImageOriginalSize] = useState<{ width?: number; height?: number }>({});
  const [scale, setScale] = useState('1.00');
  const [rotation, setRotation] = useState(0);
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);
  const editorRef = createRef<ReactAvatarEditor>();
  const handleScaleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setScale(e.target.value);
  };
  const handleRotateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRotation(parseFloat(e.target.value));
  };

  useEffect(() => {
    setRef?.(editorRef);
  });

  useEffect(() => {
    if (imgSrc) {
      const img = new Image();
      img.onload = () => {
        if (resize) {
          if (originalSize) {
            setWidth(img.width);
            setHeight(img.height);
          } else {
            setWidth(Math.min(img.width, width));
            setHeight(Math.min(img.height, height));
          }
        }
        onLoadImgSrc?.(img);
        setImageOriginalSize({ width: img.width, height: img.height });
      };
      img.src = imgSrc;
    }
  }, [originalSize, imgSrc, resize, onLoadImgSrc, width, height]);

  return (
    <div className={`${styles.container} ${className}  clinician-profile-avatar-editor`}>
      <div className={classNames(!showControl && styles.preview, styles.body)}>
        {imgSrc && (
          <>
            <ReactAvatarEditor
              className={`${edittorClassName} ${styles.editor}`}
              ref={editorRef}
              image={imgSrc}
              width={width || 400}
              height={height || 400}
              borderRadius={borderRadius}
              scale={Number(scale)}
              rotate={rotation}
              crossOrigin="anonymous"
              border={!showControl ? 0 : undefined}
            />
            {showSize && <div className={styles.sizeLabel}>{`${width} x ${height}`}</div>}
            {showControl && (
              <div className={styles.controls}>
                <div className={styles.scaleSliderContainer}>
                  <span className={styles.scale}>Scale: {scale}</span>
                  <input
                    className={styles.focusRangeBar}
                    type={'range'}
                    min={0.01}
                    max={10}
                    step={0.01}
                    value={scale}
                    onChange={handleScaleChange}
                  />
                </div>
                <div className={styles.scaleSliderContainer}>
                  <span className={styles.scale}>Rotate: {rotation}</span>
                  <input
                    className={styles.focusRangeBar}
                    type={'range'}
                    min={-180}
                    max={180}
                    step={0.01}
                    value={rotation}
                    onChange={handleRotateChange}
                  />
                </div>
                {resize && (
                  <div className={styles.sizeContainer}>
                    <div className={styles.widthContainer}>
                      <span className={styles.text}>Width:</span>
                      <input
                        className={styles.input}
                        type={'number'}
                        value={width}
                        onChange={(e) =>
                          setWidth(Math.min(imageOriginalSize.width || Infinity, parseInt(e.target.value, 10)))
                        }
                      />
                    </div>
                    <div className={styles.heightContainer}>
                      <span className={styles.text}>Height:</span>
                      <input
                        className={styles.input}
                        type={'number'}
                        value={height}
                        onChange={(e) =>
                          setHeight(Math.min(imageOriginalSize.height || Infinity, parseInt(e.target.value, 10)))
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {showRemove && (
              <div className={styles.uploadNewBtn} onClick={handleUploadNew}>
                Upload new image
              </div>
            )}
            {showRemove && (
              <div className={styles.removeBtn} onClick={handleRemove}>
                Remove
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ImageCropper;
