import moment from 'moment';
import ReportDraftContent from 'pages/Report/components/ReportBuilderPreview/components/ReportDraftContent/ReportDraftContent';
import { ChangeEvent, useEffect, useState } from 'react';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Button from 'components/v2/Button/Button';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { Collaborator, Report } from 'interfaces/Reports/report';
import { timeDiff } from 'utils/timeDifferent';
import { CommentWidgetInterface, Session } from '../../interface';
import ReportContent from './components/ReportContent/ReportContent';
import WidgetSideBar from './components/WidgetSideBar/WidgetSideBar';
import styles from './ReportBuilder.module.scss';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import ModalV2 from 'components/ModalV2/ModalV2';
import { useNavigate } from 'react-router-dom';

export interface ReportBuilderProps {
  clientRecordList: clientRecordsInterface[];
  isClientsLoading: boolean;
  isClinicianLoading: boolean;
  isSignatureLoading: boolean;
  isContactDetailsLoading: boolean;
  clinicianDetails: any;
  onChangeSignature: (
    signatureVal: { drawVal: string; textVal: string; type: string },
    extraDetailsVal: {
      email: string;
      mobileNumber: string;
      name: string;
      other: string;
    }
  ) => void;
  onChangeContactDetails: any;
  onChangeItems: (items: any[]) => void;
  onChangeDndHeight: any;
  onSelectedClient: (findClient: Report['clientRecord'], cleanData: undefined | boolean) => void;
  onChangeReportName: (value: string) => void;
  saveDraftStatus: string;
  draftBtnStatus: '' | 'active' | 'finished';
  actionBtnStatus: '' | 'active' | 'finished';
  onClickSaveDraft: any;
  data: Report;
  validation: any;
  session?: Session;
  onChangeSession: (bool: boolean) => void;
  onChangeApprovalRequired: (bool: boolean) => void;
  onPublish: () => void;
  onRequestReview: (reviewers: Collaborator[]) => void;
  onUnpublish: () => void;
  onDelete: () => void;
  onBackToEditMode: () => void;
  onSubmitReview: ({ reviewType, message }: { reviewType: string; message?: string }) => void;
  onSelectReportTemplate: (val: ReportTemplate['items'], applyTemplateId: string) => void;
  autoAlignTemplateId: string;
}

export const useTime = (refreshCycle: number, updatedTime: Date) => {
  const updateTime = moment(updatedTime);
  const [now, setNow] = useState(updateTime);

  useEffect(() => {
    const intervalId = setInterval(() => setNow(updateTime), refreshCycle);
    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow, updateTime]);

  return now;
};

const ReportBuilder = ({
  clientRecordList,
  isClientsLoading,
  isClinicianLoading,
  isSignatureLoading,
  isContactDetailsLoading,
  clinicianDetails,
  onChangeSignature,
  onChangeContactDetails,
  onChangeItems,
  onChangeDndHeight,
  onSelectedClient,
  onChangeReportName,
  saveDraftStatus,
  draftBtnStatus,
  actionBtnStatus,
  onClickSaveDraft,
  data,
  validation,
  session,
  onChangeSession,
  onChangeApprovalRequired,
  onPublish,
  onRequestReview,
  onUnpublish,
  onDelete,
  onBackToEditMode,
  onSubmitReview,
  onSelectReportTemplate,
  autoAlignTemplateId
}: ReportBuilderProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const isAuthor = (data.clinician && data.clinician._id === auth0ClinicianId) ?? false;
  const [isSelectedClient, setIsSelectedClient] = useState<Report['clientRecord']>({} as Report['clientRecord']);
  const [nameOfReport, setNameOfReport] = useState(data.reportName);
  const [commentMode, setCommentMode] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  const massageCommentData =
    (data.comments
      ?.map((comment) => ({
        ...comment,
        rightAligned: comment.position.left > 840 - 350,
        expand: false
      }))
      .sort((a, b) =>
        moment(b.comments[0].commentedAt).diff(moment(a.comments[0].commentedAt))
      ) as CommentWidgetInterface[]) ?? [];

  const [comments, setComments] = useState<CommentWidgetInterface[]>(massageCommentData);

  const [sharedList, setSharedList] = useState<Report['shareDetails']['accessors']>(
    data.shareDetails?.accessors?.sort((a, b) => (b.isClient ? 1 : -1)) ?? []
  );

  useEffect(() => {
    setIsSelectedClient(data.clientRecord || {});
    setNameOfReport(data.reportName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.clientRecord]);

  useEffect(() => {
    if (isAuthor) {
      setPreviewMode(data.status !== 'draft');
    } else {
      setPreviewMode(data.status !== 'inReview' ? true : !session?.isOwner ?? true);
    }
  }, [session, isAuthor, data.status]);

  const now = useTime(saveDraftStatus === 'saving' ? 1000 : 10000, data.lastUpdatedTime);
  const currentTime = moment();
  const formattedDuration = timeDiff(currentTime, now);

  const changeReportName = (e: ChangeEvent<HTMLInputElement>) => {
    setNameOfReport(e.target.value);
  };

  const handleChangeReportName = () => {
    onChangeReportName(nameOfReport);
  };

  const onSelectClientId = (val: string, cleanData?: boolean) => {
    const findClient = clientRecordList.find((client) => client._id === val);

    setIsSelectedClient(findClient as Report['clientRecord']);
    if (findClient) {
      onSelectedClient(findClient, cleanData);
    }
  };

  const handleChangeCommentMode = (bool: boolean) => setCommentMode(bool);

  const onChangeComments = (comments: CommentWidgetInterface[]) => {
    setComments(
      comments.sort((a, b) =>
        moment(b.comments[0]?.commentedAt ?? moment()).diff(moment(a.comments[0]?.commentedAt) ?? moment())
      )
    );
  };

  const onChangeSharedList = (newSharedList: Report['shareDetails']['accessors']) => {
    setSharedList(newSharedList);
  };

  const handleExpandFromSide = (id: string) => {
    const newExpandComment = comments.map((commentObj) => ({
      ...commentObj,
      from: commentObj._id === id && commentObj.from !== 'side' ? 'side' : 'widget',
      expand: commentObj._id === id && commentObj.from !== 'side' ? !commentObj.expand : false
    }));
    setComments(newExpandComment as CommentWidgetInterface[]);
  };

  const handleBackToReportListing = () => {
    navigate(REPORTS.BASE);
  };

  return (
    <div className={styles.container}>
      {isClientsLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          <div className={styles.leftSection}>
            <div className={styles.titleSection}>
              <div className={styles.title}>Report & Letter Builder</div>
              <div className={styles.backButton} onClick={handleBackToReportListing}>
                <i className={`material-icons-outlined`}>arrow_back_ios</i>
                <div className={styles.backButtonlabel}>Back to report listing</div>
              </div>
            </div>
            <WidgetSideBar
              nameOfReport={nameOfReport}
              data={data}
              selectedClient={isSelectedClient}
              onSelectClient={onSelectClientId}
              changeReportName={changeReportName}
              handleChangeReportName={handleChangeReportName}
              clientValidation={validation.client}
              reportNameValidation={validation.reportName}
              reviewersValidation={validation.reviewers}
              actionBtnStatus={actionBtnStatus}
              previewMode={previewMode}
              handleChangeCommentMode={handleChangeCommentMode}
              comments={comments}
              handleExpandComment={handleExpandFromSide}
              sharedList={sharedList}
              onChangeSharedList={onChangeSharedList}
              isAuthor={isAuthor}
              session={session}
              onChangeApprovalRequired={onChangeApprovalRequired}
              onChangeSession={onChangeSession}
              onPublish={onPublish}
              onRequestReview={onRequestReview}
              onUnpublish={onUnpublish}
              onDelete={onDelete}
              onBackToEditMode={onBackToEditMode}
              onSubmitReview={onSubmitReview}
              onSelectReportTemplate={onSelectReportTemplate}
            />
          </div>
          <div className={styles.rightSection}>
            <div className={styles.templateHeader}>
              {!previewMode && (
                <div className={styles.rightHeadingWrapper}>
                  <div className={styles.buttonsWrapper}>
                    <Button
                      className={styles.draftBtn}
                      variant={'secondary'}
                      onClick={onClickSaveDraft}
                      activeClassName={styles.draftActive}
                      status={draftBtnStatus}
                    >
                      Save Changes
                    </Button>
                    <Button variant={'primary'} className={styles.previewBtn} onClick={() => setShowPreviewModal(true)}>
                      View Preview
                    </Button>
                  </div>
                  {data.isEditing && (
                    <div className={styles.lastEditMessage}>{`Last edit was ${formattedDuration}`}</div>
                  )}
                </div>
              )}
            </div>
            <ReportContent
              data={data}
              reportName={nameOfReport}
              isClinicianLoading={isClinicianLoading}
              isSignatureLoading={isSignatureLoading}
              isContactDetailsLoading={isContactDetailsLoading}
              clinicianDetails={clinicianDetails}
              onChangeSignature={onChangeSignature}
              onChangeContactDetails={onChangeContactDetails}
              onChangeItems={onChangeItems}
              onChangeDndHeight={onChangeDndHeight}
              signatureValidation={validation.clinicianSignature}
              itemValidation={validation.items}
              previewMode={previewMode}
              commentMode={commentMode}
              comments={comments}
              onChangeComments={onChangeComments}
              autoAlignTemplateId={autoAlignTemplateId}
            />
          </div>
          <ModalV2
            containerClassName={styles.previewContainer}
            isModalOpen={showPreviewModal}
            title={'Report Preview'}
            onModalClose={() => setShowPreviewModal(false)}
            bodyStyle={{ padding: '0' }}
          >
            <ReportDraftContent
              data={data}
              isClinicianLoading={isClinicianLoading}
              clinicianDetails={clinicianDetails}
            />
          </ModalV2>
        </>
      )}
    </div>
  );
};

export default ReportBuilder;
