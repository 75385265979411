import { useEffect } from 'react';
import { useState } from 'react';
import styles from './AppointmentTypeSelect.module.scss';
import AppointmentTypeOption from './components/AppointmentTypeOption/AppointmentTypeOption';
import { useFetchAppointmentTypesByClinicianId } from 'pages/Calendar/components/CalendarSettings/components/AppointmentTypes/hooks/GetAppointmentTypes';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import classNames from 'classnames';
import Button from '../Button/Button';
import CreateAppointmentTypeModal from 'pages/Calendar/components/CalendarSettings/components/AppointmentTypes/components/CreateAppointmentTypeModal/CreateAppointmentTypeModal';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';

type AppointmentTypeId = AppointmentType & Required<Pick<AppointmentType, '_id'>>;

interface AppointmentTypeSelectProps {
  clinicianId: string;
  onSelect: (value?: AppointmentTypeId) => void;
  horizontal?: boolean;
  hideTitle?: boolean;
  preSelectedAppointmentTypeId?: string;
  selectedParticipantType: ParticipantType;
}

const AppointmentTypeSelect = ({
  onSelect,
  clinicianId,
  horizontal = false,
  hideTitle = false,
  preSelectedAppointmentTypeId,
  selectedParticipantType
}: AppointmentTypeSelectProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState<string>(
    preSelectedAppointmentTypeId || ''
  );
  const [showCreateAppointmentTypeModal, setShowCreateAppointmentTypeModal] = useState(false);
  const {
    appointmentTypes,
    isAppointmentTypesLoading,
    fetchAppointmentTypesByClinicianId,
    fetchAppointmentTypes
  } = useFetchAppointmentTypesByClinicianId(token, clinicianId, selectedParticipantType);

  const activeAppointmentType = appointmentTypes.filter(
    (apptType) => apptType.isActive && apptType._id
  ) as (AppointmentType & Required<Pick<AppointmentType, '_id' | 'rate'>>)[];

  useEffect(() => {
    if (!preSelectedAppointmentTypeId) {
      setSelectedAppointmentTypeId(activeAppointmentType[0]?._id || '');
      onSelect(activeAppointmentType[0] as AppointmentTypeId | undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypes]);

  const handleClick = (id: string) => {
    setSelectedAppointmentTypeId(id);
    const appointment = appointmentTypes.find((type) => type._id === id);
    if (appointment) {
      // Type assert because necessary checks were done before reaching this point.
      onSelect(appointment as Parameters<AppointmentTypeSelectProps['onSelect']>[0]);
    }
  };

  const handleReloadAppointmentType = () => {
    if (clinicianId) {
      fetchAppointmentTypesByClinicianId(token, clinicianId);
    } else {
      fetchAppointmentTypes(token, selectedParticipantType);
    }
  };

  const next = () => {
    if (horizontalIndex + 3 >= activeAppointmentType.length) {
      return;
    }
    setHorizontalIndex(horizontalIndex + 3);
  };

  const previous = () => {
    if (horizontalIndex === 0) {
      return;
    }
    setHorizontalIndex(horizontalIndex - 3);
  };

  return (
    <div className={classNames(styles.container, horizontal && styles.horizontal)}>
      {!hideTitle && <div className={styles.title}>Select Appointment Type</div>}
      {isAppointmentTypesLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : activeAppointmentType.length > 0 ? (
        <div className={classNames(!horizontal && styles.aTBoxWrapper)}>
          <div className={classNames(styles.aTBox, horizontal && styles.horizontal)}>
            {activeAppointmentType
              .filter((appt, index) => !horizontal || (index >= horizontalIndex && index <= horizontalIndex + 2))
              .map((appointmentType) => (
                <AppointmentTypeOption
                  key={appointmentType._id}
                  {...appointmentType}
                  selected={appointmentType._id === selectedAppointmentTypeId}
                  onClick={handleClick}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className={classNames(styles.noATWrapper, horizontal && styles.horizontal)}>
          No appointment type found
          {(isEdgeAdminView || isNormalUserView) && (
            <>
              <div>Please create one to continue.</div>
              <Button
                className={styles.newATBtn}
                onClick={() => setShowCreateAppointmentTypeModal(true)}
                variant="link"
                icon="add_circle_outline"
              >
                New appointment type
              </Button>
            </>
          )}
          <CreateAppointmentTypeModal
            visible={showCreateAppointmentTypeModal}
            onPost={handleReloadAppointmentType}
            onClose={() => setShowCreateAppointmentTypeModal(false)}
          />
        </div>
      )}
      {horizontal && !isAppointmentTypesLoading && (
        <div className={styles.navContainer}>
          <div
            className={`${styles.leftControl} 
            ${horizontalIndex === 0 ? styles.disabled : ''}`}
            onClick={previous}
          >
            <span className="material-icons-outlined">keyboard_arrow_left</span>
          </div>
          <div
            className={`${styles.rightControl} 
                    ${horizontalIndex + 3 >= activeAppointmentType.length ? styles.disabled : ''}`}
            onClick={next}
          >
            <span className="material-icons-outlined">keyboard_arrow_right</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentTypeSelect;
