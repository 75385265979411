import { useField } from 'formik';
import styles from './FormikTextarea.module.scss';

interface FormikTextareaProps {
  name: string;
  placeholder?: string;
}

const FormikTextarea = ({ name, placeholder }: FormikTextareaProps) => {
  const [, { value }, { setValue }] = useField(name);
  return (
    <textarea
      className={styles.textarea}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default FormikTextarea;
