import classnames from 'classnames';

import { MicroJournalEntry } from 'utils/hooks/microJournal';

import styles from './JournalDetailCard.module.scss';

interface JournalDetailCardProps {
  journalDetail: MicroJournalEntry;
  selected?: boolean;
  onClick?: () => void;
}

const JournalDetailCard = ({ journalDetail, selected, onClick }: JournalDetailCardProps) => {
  return (
    <div
      className={classnames(styles.container, onClick && styles.selectable, selected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.tick}>
        <i className={`material-icons-outlined ${styles.icon}`}>done</i>
      </div>
      <div className={styles.card} id={`add-journal-detail-card-${journalDetail._id}`}>
        <div className={styles.date}>{journalDetail.date}</div>
        <div className={styles.title}>{journalDetail.title}</div>
        <div className={styles.desc}>{journalDetail.response}</div>
        {journalDetail.tags && journalDetail.tags.length > 0 && (
          <div className={styles.emotionWrapper}>
            {journalDetail.tags.map((obj: any, index: any) => (
              <div className={obj.polarity === 'positive' ? styles.badge : styles.badgeNegative} key={index}>
                {obj.title}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JournalDetailCard;
