export const MOMENTJS_DATE_FORMAT = 'DD/MM/YYYY';
export const MOMENTJS_YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';

export const checkDateValue = (str: string, max: number) => {
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str, 10);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();
  }
  return str;
};

export const convertDateFormat = (dateValue: string) => {
  let collect = dateValue.split('/').map(function (v: any) {
    return v.replace(/\D/g, '');
  });
  if (collect[0]) {
    collect[0] = checkDateValue(collect[0], 31);
  }
  if (collect[1]) {
    collect[1] = checkDateValue(collect[1], 12);
  }
  let output = collect.map(function (v: any, i: number) {
    return v.length === 2 && i < 2 ? v + '/' : v;
  });
  return output.join('').substr(0, 14);
};
