import { useState, ChangeEvent, useEffect, useRef } from 'react';
import _ from 'lodash';

import styles from './DropdownSearchable.module.scss';
import classNames from 'classnames';
import { DEFAULT_INPUT_PLACEHOLDER } from './constants';
import { DropdownSearchableProps, ProfileTagsOption } from './interface';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

const DropdownSearchable = (props: DropdownSearchableProps) => {
  const { profileTags, label, optionsListLabel, searchBoxPlaceholder, selectedOptionsProps } = props;
  const { onSelecteOptionProps } = props;
  const placeholder = !_.isEmpty(searchBoxPlaceholder) ? searchBoxPlaceholder : DEFAULT_INPUT_PLACEHOLDER;

  const [searchText, setSearchText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selectedOptionsProps);
  const [optionList, setOptionList] = useState(profileTags);

  const inputNode = useRef<HTMLInputElement>(null);
  const controlIconNode = useRef<HTMLSpanElement>(null);
  const optionsNode = useRef<HTMLDivElement>(null);

  const handleClickOutSide = (event: any) => {
    if (
      inputNode.current?.contains(event.target) ||
      controlIconNode.current?.contains(event.target) ||
      optionsNode.current?.contains(event.target)
    ) {
      return;
    }
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    setSelectedOptions(selectedOptionsProps);
    setOptionList(profileTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionsProps, profileTags]);

  const onOpenOptions = () => {
    if (!showOptions) {
      setShowOptions(!showOptions);
    }
  };

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    const newOptionList = profileTags.filter((item) => {
      return item.label.toLowerCase().includes(value.toLowerCase());
    });
    setOptionList(newOptionList);
  };

  const onOptionSelect = (event: any, optionItem: ProfileTagsOption) => {
    if (optionItem.isLoading) {
      return;
    }
    const profileTag = optionList.find((tag) => tag._id === optionItem._id);
    if (profileTag) {
      profileTag.isLoading = true;
    }
    setOptionList([...optionList]);
    onSelecteOptionProps(optionItem);
  };

  const renderOptionList = () => {
    return (
      <>
        {!_.isEmpty(optionsListLabel) && <div className={styles.optionsListLabel}>Tag options</div>}
        {optionList.map((optionItem, index) => (
          <div
            key={`optionItem_${index}`}
            className={styles.optionItem}
            onClick={(event) => onOptionSelect(event, optionItem)}
          >
            <div className={styles.itemName}>{optionItem.label}</div>
            <div className={styles.selectedMark}>
              {optionItem.isLoading ? (
                <div className={styles.loadingBox}>
                  <LoadingCircle />
                </div>
              ) : (
                selectedOptions.some((item) => item._id === optionItem._id) && (
                  <span className={classNames(styles.selected, 'material-icons-outlined')}>check</span>
                )
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={styles.dropdownSearchableContainer}>
      <div className={styles.label}>{label}</div>
      <div className={styles.dropdownContainer}>
        <div className={styles.controller}>
          <div className={styles.searchBox}>
            <input
              ref={inputNode}
              placeholder={placeholder}
              value={searchText}
              onChange={onSearchTextChange}
              onClick={onOpenOptions}
            />
          </div>
          <div className={styles.controlIcon}>
            <span ref={controlIconNode} className={'material-icons-outlined'} onClick={onOpenOptions}>
              arrow_drop_down
            </span>
          </div>
        </div>
        {showOptions && (
          <div className={styles.optionsContainer} ref={optionsNode}>
            {optionList.length > 0 && renderOptionList()}
            {optionList.length < 1 && <div className={styles.noOption}>No option</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownSearchable;
