import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import { useFetchPractitionerList } from 'components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './RequestApprovalSection.module.scss';

interface RequestApprovalSectionProps {
  approvalRequired: boolean;
  onChangeApprovalRequired: (bool: boolean) => void;
}

const RequestApprovalSection = ({ approvalRequired, onChangeApprovalRequired }: RequestApprovalSectionProps) => {
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const { practitionersList, isPractitionersListLoading } = useFetchPractitionerList(token, false, [AccessRight.Admin]);
  const disabled = practitionersList.filter(({ _id }) => _id !== auth0ClinicianId).length < 1;

  return (
    <div className={styles.container}>
      <div className={styles.toggleMessageContainer}>
        <div className={styles.toggleMessage}>Request approval?</div>
        <MaterialToggleSwitch
          name={'approvalRequired'}
          value={approvalRequired ?? false}
          onChange={(bool) => onChangeApprovalRequired(bool)}
          disabled={disabled}
        />
      </div>
      {!isPractitionersListLoading && (
        <>
          {disabled ? (
            <div className={styles.disabledDescription}>You have sole approval privileges for this account.</div>
          ) : (
            <div className={styles.description}>
              Get the feedback and approval you need to finalise your file. You can add specific reviewers.
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RequestApprovalSection;
