import { useState } from 'react';

import styles from './Details.module.scss';
import { Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import InterventionInfo from './components/InterventionInfo/InterventionInfo';
import InterventionActivity from './components/InterventionActivity/InterventionActivity';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import EditInterventionModal from './components/EditClientModal/EditInterventionModal';
import { useGetAccessToken } from 'utils/hooks/token';
import { ProfileInterface } from 'interfaces/Profile/Profile';

export interface DetailsProps {
  interventionDetails: Interventions;
  interventionTagList: Interventions['tags'];
  profile: ProfileInterface;
  fetchInterventionDetails: (token: string) => void;
}

const Details = ({ interventionDetails, interventionTagList, profile, fetchInterventionDetails }: DetailsProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { token } = useGetAccessToken();
  const [editModal, setEditModal] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      <div className={styles.editLabelWrapper}>
        <div className={styles.title}>Intervention Information</div>
        {isEdgeAdminView && (
          <>
            <div className={styles.editBtn} onClick={() => setEditModal(true)}>
              <span className={`material-icons-outlined ${styles.icon}`}>edit</span>
              Edit
            </div>
            <EditInterventionModal
              interventionDetails={interventionDetails}
              onCancel={() => setEditModal(false)}
              visible={editModal}
              onRefreshData={() => fetchInterventionDetails(token)}
            />
          </>
        )}
      </div>
      <InterventionInfo interventionDetails={interventionDetails} interventionTagList={interventionTagList} />
      <div className={styles.activityWrapper}>
        <div className={styles.title}>Intervention Activity Feed</div>
        <InterventionActivity interventionDetails={interventionDetails} profile={profile} />
      </div>
    </div>
  );
};

export default Details;
