import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ModalV2 from 'components/ModalV2/ModalV2';
import Select from 'components/Select/CommonSelect/Select';
import Button from 'components/v2/Button/Button';
import { ActionPlanIntervention } from 'MHH/pages/ActionPlanCreator/interfaces';
import { useEffect, useState } from 'react';
import styles from './NoActionModal.module.scss';

const DEFAULT_NO_ACTION_VALUE = {
  reason: '',
  context: ''
};

const REASON_OPTIONS = [
  { label: 'No Capacity', value: 'No Capacity' },
  { label: 'Low Priority', value: 'Low Priority' },
  { label: 'Covered in another action', value: 'Covered in another action' },
  { label: "Can't support this issue", value: "Can't support this issue" },
  { label: 'Referring to another service', value: 'Referring to another service' }
];

interface NoActionModalProps {
  visible: boolean;
  noAction?: ActionPlanIntervention['noAction'];
  onClose: () => void;
  onSave: (noAction: Required<ActionPlanIntervention>['noAction']) => void;
}

const NoActionModal = ({ visible, noAction, onClose, onSave }: NoActionModalProps) => {
  const [noActionValue, setNoActionValue] = useState<Required<ActionPlanIntervention>['noAction']>(
    DEFAULT_NO_ACTION_VALUE
  );
  const [showReasonError, setShowReasonError] = useState(false);

  useEffect(() => {
    setNoActionValue(noAction || DEFAULT_NO_ACTION_VALUE);
    setShowReasonError(false);
  }, [noAction, visible]);

  const handleChangeValues = (key: keyof typeof noActionValue, value: string) => {
    setNoActionValue({ ...noActionValue, [key]: value });

    if (key === 'reason' && value) {
      setShowReasonError(false);
    }
  };

  const handleSave = () => {
    if (!noActionValue.reason) {
      setShowReasonError(true);
    } else {
      setShowReasonError(false);
      onSave(noActionValue);
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title="Reason for Taking No Action"
      onModalClose={onClose}
    >
      <div className={styles.label}>REASON</div>
      <Select
        options={REASON_OPTIONS}
        value={noActionValue.reason}
        placeholder="Select reason"
        onChange={(e) => handleChangeValues('reason', (e as { label: string; value: string }).value)}
        isSearchable={false}
        isClearable={false}
        smallCaretDown
        smallCaretDownClass={styles.smallCaretDownClass}
        styles={{
          container: (containerBase: any) => ({ ...containerBase, width: '100%', maxWidth: '300px' }),
          valueContainer: (base: any) => ({
            ...base,
            minHeight: '30px',
            padding: '8px'
          }),
          control: (controlBase: any) => ({
            ...controlBase,
            minHeight: '54px',
            backgroundColor: 'white',
            border: `1px solid ${styles.greyColor}`,
            borderRadius: '4px',
            boxShadow: 'none'
          }),
          singleValue: (singleValueBase: any) => ({
            ...singleValueBase,
            fontSize: '16px',
            fontWeight: 600
          })
        }}
      />
      <ErrorMessage className={styles.reasonError} visible={showReasonError} error="Please select a reason" />
      <div className={styles.contextWrapper}>
        <div className={styles.label}>CONTEXT</div>
        <textarea
          className={styles.textarea}
          value={noActionValue.context}
          onChange={(e) => handleChangeValues('context', e.target.value)}
        />
      </div>
      <Button className={styles.saveButton} onClick={handleSave}>
        Save changes
      </Button>
    </ModalV2>
  );
};

export default NoActionModal;
