import { DatePicker, notification } from 'antd';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/Radio/Radio';
import Button from 'components/v2/Button/Button';
import { Report } from 'interfaces/Reports/report';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccessToken } from 'utils/hooks/token';
import { putShare } from 'utils/http/DocumentService/Reports/reports';
import * as yup from 'yup';

import styles from './ShareSideBox.module.scss';

const emailSchema = yup.array().of(yup.string().email());

interface ShareSideBoxProps {
  reportId: string;
  isClientShared: boolean;
  allowCommunicationWithEmail: boolean | undefined;
  onChangeSharedList: (sharedList: Report['shareDetails']['accessors']) => void;
}

const ShareSideBox = ({
  reportId,
  isClientShared,
  allowCommunicationWithEmail,
  onChangeSharedList
}: ShareSideBoxProps) => {
  const { token } = useGetAccessToken();
  const [errors, setErrors] = useState<{ customSendInvoiceTime?: string; emails?: string; sendTo?: string }>({});
  const [t] = useTranslation();

  const defaultDetail = {
    note: '',
    sendTimeInfo: {
      sendInvoiceType: 'now',
      customSendTime: ''
    },
    sendToInfo: {
      isClientCheck: true,
      isOtherCheck: false,
      otherEmail: ''
    }
  };
  const [shareReportDetail, setShareReportDetail] = useState(defaultDetail);

  const [sendButtonStatus, setSendButtonStatus] = useState<'active' | 'finished' | ''>('');

  const onChangeReportDetails = (val: object) => {
    const massageData = {
      ...shareReportDetail,
      ...val
    };
    setShareReportDetail(massageData);
  };

  const onChangeSendToDetails = (key: string, val: any) => {
    onChangeReportDetails({
      sendToInfo: {
        ...shareReportDetail.sendToInfo,
        [key]: val
      }
    });
  };

  const onChangeSendTime = (key: string, val: any) => {
    onChangeReportDetails({
      sendTimeInfo: {
        ...shareReportDetail.sendTimeInfo,
        [key]: val
      }
    });
  };

  const onChangeNote = (val: any) => {
    onChangeReportDetails({
      note: val
    });
  };

  const handleCustomSendInvoiceTimeChange = (value: Moment | null) => {
    onChangeSendTime('customSendTime', value ? value.format('YYYY-MM-DD HH:mm') : '');

    if (value) {
      if (errors.customSendInvoiceTime) {
        setErrors({ ...errors, customSendInvoiceTime: undefined });
      }
    }
  };

  const handleEmailsChange = (val: string) => {
    onChangeReportDetails({
      sendToInfo: {
        ...shareReportDetail.sendToInfo,
        otherEmail: val
      }
    });

    if (errors.emails) {
      const emailsArray = val.split(';').filter((email) => !!email);

      if (emailsArray.length === 0) {
        setErrors({ ...errors, emails: 'Please enter a list of semi-colon-separated emails' });
      } else {
        try {
          emailSchema.validateSync(emailsArray);
          setErrors({ ...errors, emails: undefined });
        } catch (ex) {
          setErrors({ ...errors, emails: 'Please enter emails in the correct format' });
        }
      }
    }
  };

  const handleSendReport = async (body: any) => {
    setSendButtonStatus('active');
    try {
      const resShare = await putShare(token, reportId, body);
      const updatedSessionType = await resShare.json();
      onChangeSharedList(updatedSessionType.shareDetails.accessors);
      setSendButtonStatus('finished');
      setShareReportDetail(defaultDetail);
      notification.success({
        message: 'Report has been shared',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setSendButtonStatus('');
      }, 2000);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to publish and share your report.' });
      setSendButtonStatus('');
    }
  };

  const onHandleSendReport = () => {
    const newErrors: typeof errors = {};

    if (shareReportDetail.sendTimeInfo.sendInvoiceType === 'custom' && !shareReportDetail.sendTimeInfo.customSendTime) {
      newErrors.customSendInvoiceTime = 'Please select a date and time to send this invoice';
    }

    if (!shareReportDetail.sendToInfo.isClientCheck && !shareReportDetail.sendToInfo.isOtherCheck) {
      newErrors.sendTo = 'Please select a recipient';
    } else if (shareReportDetail.sendToInfo.isOtherCheck) {
      const emailsArray = shareReportDetail.sendToInfo.otherEmail.split(';').filter((email: string) => !!email);

      if (emailsArray.length === 0) {
        newErrors.emails = 'Please enter a list of semi-colon-separated emails';
      } else {
        try {
          emailSchema.validateSync(emailsArray);
        } catch (ex) {
          newErrors.emails = 'Please enter emails in the correct format';
        }
      }
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      const shareDetails: { sendAt: Date; sendClient: boolean; sendTo?: string[]; note?: string } = {
        sendAt: new Date(),
        sendClient: shareReportDetail.sendToInfo.isClientCheck,
        note: shareReportDetail.note
      };

      if (shareReportDetail.sendTimeInfo.sendInvoiceType === 'now') {
        shareDetails.sendAt = moment().toDate();
      } else {
        shareDetails.sendAt = moment(shareReportDetail.sendTimeInfo.customSendTime, 'YYYY-MM-DD HH:mm').toDate();
      }

      if (shareReportDetail.sendToInfo.isOtherCheck) {
        shareDetails.sendTo = shareReportDetail.sendToInfo.otherEmail.split(';').filter((email: string) => !!email);
      }

      handleSendReport(shareDetails);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        <div className={styles.title}>
          Send
          <HelpOutLineWithTooltips
            iconClass={styles.tooltipsIcon}
            id={'sendSection'}
            desc={'Decide whether to send the report or letter now or set a time for it to be sent.'}
          />
        </div>
        <Radio
          className={styles.radioContainer}
          options={[
            { value: 'now', label: 'Now' },
            { value: 'custom', label: 'Custom' }
          ]}
          value={shareReportDetail.sendTimeInfo.sendInvoiceType}
          vertical
          onChange={(e) => onChangeSendTime('sendInvoiceType', e.target.value)}
        />
        {shareReportDetail.sendTimeInfo.sendInvoiceType === 'custom' && (
          <>
            <DatePicker
              className="send-invoice-date-picker"
              disabledDate={(current) => current && current < moment().startOf('day')}
              suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
              showTime={{ format: 'HH:mm', minuteStep: 15 }}
              value={
                shareReportDetail.sendTimeInfo.customSendTime
                  ? moment(shareReportDetail.sendTimeInfo.customSendTime, 'YYYY-MM-DD HH:mm')
                  : undefined
              }
              onChange={(e) => handleCustomSendInvoiceTimeChange(e)}
            />
            <ErrorMessage
              className={styles.sendError}
              error={errors.customSendInvoiceTime}
              visible={!!errors.customSendInvoiceTime}
            />
          </>
        )}
      </div>
      <div className={styles.questionWrapper}>
        <div className={styles.title}>
          Send To
          <HelpOutLineWithTooltips
            iconClass={styles.tooltipsIcon}
            id={'sendToSection'}
            desc={
              'Add any recipients here and they will receive an email with access to the report. You can remove access at any time.'
            }
          />
        </div>
        <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-client">
          <input
            className={styles.checkbox}
            id="checkbox-sendTo-client"
            type="checkbox"
            checked={
              !allowCommunicationWithEmail && !isClientShared ? false : shareReportDetail.sendToInfo.isClientCheck
            }
            disabled={isClientShared || !allowCommunicationWithEmail}
            onChange={(e) => onChangeSendToDetails('isClientCheck', e.target.checked)}
          />
          <div className={styles.text}>
            {t('label.client.capitalize')}
            {!allowCommunicationWithEmail && !isClientShared && (
              <HelpOutLineWithTooltips
                iconClass={styles.tooltipsIcon}
                id={'sendToClient'}
                desc={
                  'Unable to share report due to limited communication preferences. These preferences can be managed in the client profile.'
                }
              />
            )}
          </div>
        </label>
        <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-other">
          <input
            className={styles.checkbox}
            id="checkbox-sendTo-other"
            type="checkbox"
            checked={shareReportDetail.sendToInfo.isOtherCheck}
            onChange={(e) => onChangeSendToDetails('isOtherCheck', e.target.checked)}
          />
          <div className={styles.text}>Other</div>
        </label>
        {shareReportDetail.sendToInfo.isOtherCheck && (
          <div className={styles.inputContainer}>
            <MaterialInput
              label="Email address"
              value={shareReportDetail.sendToInfo.otherEmail}
              onChange={(e) => handleEmailsChange(e.target.value)}
            />
            <ErrorMessage error={errors.emails} visible={!!errors.emails} />
          </div>
        )}
        <ErrorMessage className={styles.sendToError} error={errors.sendTo} visible={!!errors.sendTo} />
      </div>
      <div className={styles.includeNoteContainer}>
        <div className={styles.noteTitle}>
          Include a note for recipients
          <HelpOutLineWithTooltips
            iconClass={styles.tooltipsIcon}
            id={'noteSection'}
            desc={
              'This note will go into the email providing access to the report or letter. This gives you a chance to quickly provide context to the subject of the report and why they are receiving it'
            }
          />
        </div>
        <textarea
          className={styles.textarea}
          placeholder={'This is a short note default set to send report'}
          rows={4}
          maxLength={160}
          value={shareReportDetail.note}
          onChange={(e) => onChangeNote(e.target.value)}
        />
        <span className={styles.wordCount}>{shareReportDetail.note.length} / 160</span>
      </div>
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} status={sendButtonStatus} type="button" onClick={onHandleSendReport}>
          Share Report
        </Button>
      </div>
    </div>
  );
};

export default ShareSideBox;
