import { useEffect } from 'react';
import ClinicianSelect, { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import EventFormat from './components/EventFormat/EventFormat';
import styles from './EventOwnerAndFormatField.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useField } from 'formik';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useAuth0 } from '@auth0/auth0-react';

type EventOwnerAndFormatFieldProps = {
  hideAppointmentFormat?: boolean;
  eventType: string;
  setEventType: (eventType: string) => void;
  selectedClinician: Partial<ClinicianListInterface>;
  setClinician: (clinician: Partial<PractitionersDetailsInterface> | undefined) => void;
  isDisabled?: boolean;
};

const EventOwnerAndFormatField = ({
  hideAppointmentFormat = false,
  eventType,
  setClinician,
  selectedClinician,
  setEventType,
  isDisabled = false
}: EventOwnerAndFormatFieldProps) => {
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [, metaOwner, eventOwnerHelper] = useField('eventOwner');
  const { user } = useAuth0();

  const handleSelectEventOwner = (owner: PractitionersDetailsInterface | undefined) => {
    if (owner) {
      eventOwnerHelper.setValue(owner._id);
      setClinician(owner);
    }
  };

  useEffect(() => {
    if (!isEdgeReceptionist && !isEdgeAdminView && user) {
      const { sub, name, picture } = user;
      eventOwnerHelper.setValue(sub);
      setClinician({
        _id: sub,
        name,
        avatar: picture
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className={styles.container}>
      {isEdgeAdminView || isEdgeReceptionist ? (
        <>
          <div>
            <div className={styles.label}>APPOINTMENT OWNER</div>
            <div className={styles.clinicianSelect}>
              <ClinicianSelect
                profileBadgeClassname={styles.profileBadgeStyle}
                onSelect={handleSelectEventOwner}
                selectedId={selectedClinician._id}
                includePractice
                disabled={isDisabled}
              />
              <ErrorMessage className={styles.customTimeError} error={metaOwner.error} visible={!!metaOwner.error} />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {!hideAppointmentFormat && (
        <div>
          <div className={styles.label}>FORMAT</div>
          <div className={styles.eventFormatContainer}>
            <EventFormat value={eventType} onChange={setEventType} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventOwnerAndFormatField;
