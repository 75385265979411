import { Modal, Skeleton } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { deleteAppointmentAlert } from 'utils/http/appointment';
import { dismissNotificationByReportId } from 'utils/http/DocumentService/Reports/reports';

import { AppointmentBookingRequest, AppointmentRequest } from '../../Notification';
import AppointmentAlert from './components/AppointmentAlert/AppointmentAlert';
import { AppointmentAlertInterface } from './components/AppointmentAlert/interfaces';
import AppointmentBookingRequestV2 from './components/AppointmentBookingRequestV2/AppointmentBookingRequestV2';
import AppointmentRequestComponent from './components/AppointmentRequest/AppointmentRequest';
import AppointmentRescheduleRequest from './components/AppointmentRescheduleRequest/AppointmentRescheduleRequest';
import { ReportNotification as ReportNotificationInterface } from './components/ReportNotification/interfaces';
import ReportNotification from './components/ReportNotification/ReportNotification';
import styles from './NotificationsModal.module.scss';

interface NotificationsModalProps {
  appointmentBookingRequests: AppointmentBookingRequest[];
  appointmentRequests: AppointmentRequest[];
  appointmentAlerts: AppointmentAlertInterface[];
  reportsNotifications: ReportNotificationInterface[];
  token: string;
  visible: boolean;
  isNotificationsLoading: boolean;
  onCancel: () => void;
  onRefreshNotifications: () => void;
}

const NotificationsModal = ({
  appointmentBookingRequests,
  appointmentRequests,
  appointmentAlerts,
  reportsNotifications,
  isNotificationsLoading,
  token,
  visible,
  onCancel,
  onRefreshNotifications
}: NotificationsModalProps) => {
  const { isEdgeUser, isEdgeAdminView, isEdgeUserMentorView } = useGetAccountPackageView();
  const { pathname } = useLocation();
  const firstPath = pathname.split('/')[1];
  const navigate = useNavigate();
  const { CLIENTS, MENTOR } = useRoutesGenerator();

  useEffect(() => {
    if (token) {
      onRefreshNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPath, token]);

  const handleCancel = () => {
    onCancel();
  };

  const onClearAlert = async (alertId: string) => {
    await deleteAppointmentAlert(token, alertId, isEdgeAdminView);
    onRefreshNotifications();
  };

  const onDismissNotification = async (reportId: string) => {
    await dismissNotificationByReportId(token, reportId);
    onRefreshNotifications();
  };

  const onSelectClient = (clientRecordId: string) => {
    navigate(
      isEdgeUserMentorView ? `${MENTOR.CLIENTS}/${clientRecordId}` : `${CLIENTS.BASE}/${clientRecordId}/profile`
    );
    onCancel();
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      width={800}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div className={styles.title}>Notifications</div>
      <div className={styles.content}>
        {isNotificationsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <>
              {appointmentBookingRequests.length === 0 &&
                appointmentRequests.length === 0 &&
                appointmentAlerts.length === 0 &&
                reportsNotifications.length === 0 && <div className={styles.noRequests}>No notifications</div>}
              <div className={styles.appointmentBookingRequestsContainer}>
                {appointmentBookingRequests.map((appReq, index) => (
                  <div className={styles.appointmentBookingRequestItem} key={index}>
                    {appReq.appointmentBookingRequests.map((appointmentObj, index) =>
                      appointmentObj.clientRescheduleDetails ? (
                        <AppointmentRescheduleRequest
                          key={index}
                          appointmentBookingRequestData={appointmentObj}
                          token={token}
                          onRefreshNotifications={onRefreshNotifications}
                        />
                      ) : (
                        <AppointmentBookingRequestV2
                          key={index}
                          appointmentBookingRequestData={appointmentObj}
                          token={token}
                          onRefreshNotifications={onRefreshNotifications}
                        />
                      )
                    )}
                  </div>
                ))}
              </div>
              {appointmentRequests.length > 0 && (
                <>
                  <div className={styles.heading}>Special Requests</div>
                  {appointmentRequests.map((appointmentRequest, index) => (
                    <AppointmentRequestComponent
                      key={index}
                      appointmentRequest={appointmentRequest}
                      token={token}
                      onRefreshNotifications={onRefreshNotifications}
                    />
                  ))}
                </>
              )}
              {isEdgeUser &&
                appointmentAlerts.length > 0 &&
                appointmentAlerts.map((alert, index) => (
                  <AppointmentAlert
                    key={index}
                    alert={alert}
                    token={token}
                    onSelectClient={onSelectClient}
                    onClearAlert={onClearAlert}
                  />
                ))}
              {isEdgeUser &&
                reportsNotifications.length > 0 &&
                reportsNotifications.map((reportNotification, index) => (
                  <ReportNotification
                    key={index}
                    reportNotification={reportNotification}
                    onDismissNotification={onDismissNotification}
                    onCancel={handleCancel}
                  />
                ))}
            </>
          </>
        )}
      </div>
    </Modal>
  );
};

export default NotificationsModal;
