import { useState } from 'react';

import { Skeleton } from 'antd';

import styles from './PractitionersListingHeader.module.scss';
import { PractitionersStatus } from 'interfaces/Practitioners/practitionersListing';
import AddPractitionerModal from 'components/AddPractitionerModal/AddPractitionerModal';
import { useTranslation } from 'react-i18next';

interface PractitionersListingHeaderType {
  total: number;
  isLoading: boolean;
  currentTabFilter: PractitionersStatus['status'];
}

const PractitionersListingHeader = ({ total, isLoading, currentTabFilter }: PractitionersListingHeaderType) => {
  const [showAddPractitioner, setShowAddPractitioner] = useState(false);

  const onAddNewPractitioner = () => {
    setShowAddPractitioner(true);
  };

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          {isLoading ? (
            <Skeleton.Button active className={styles.loading} />
          ) : (
            <div className={styles.title}>{t('header.practitioner', { count: total, status: currentTabFilter })}</div>
          )}
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.addNewBtnWrapper} onClick={() => onAddNewPractitioner()}>
          <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
          {t('button.add_new_practitioner')}
        </div>
        <AddPractitionerModal
          visible={showAddPractitioner}
          onCancel={() => {
            setShowAddPractitioner(false);
          }}
        />
      </div>
    </div>
  );
};

export default PractitionersListingHeader;
