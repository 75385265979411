import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './InterventionListHeader.module.scss';

interface InterventionListHeaderProps {
  onCreateInterventionClick: () => void;
}

const InterventionListHeader = ({ onCreateInterventionClick }: InterventionListHeaderProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Interventions</div>
        </div>
      </div>
      {isEdgeAdminView && (
        <div className={styles.rightSection}>
          <div className={styles.addNewBtnWrapper} onClick={() => onCreateInterventionClick()}>
            <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
            Create New Intervention
          </div>
        </div>
      )}
    </div>
  );
};

export default InterventionListHeader;
