import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';
const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getInterventionLibrary = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/intervention-library`
  ) as Promise<BentResponse>;

export const putInterventionLibrary = (
  token: string,
  interventionLibraryItemid: string,
  payload: Record<string, any>
) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/me/intervention-library/${interventionLibraryItemid}`,
    payload
  ) as Promise<BentResponse>;
