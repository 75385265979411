import comment from 'assets/images/comment.svg';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

import styles from './CommentWrapper.module.scss';

interface CommentWrapperProps {
  id: string;
  children: any;
  position: any;
  dimensions: any;
  allowDrag: boolean;
  data: any;
}

export const CommentWrapper = ({ id, children, position, dimensions, allowDrag, data }: CommentWrapperProps) => {
  const [isAbleDrag, setIsAbleDrag] = useState(allowDrag);
  const [itemDimensions, setItemDimensions] = useState(dimensions);
  useEffect(() => {
    setIsAbleDrag(allowDrag);
    setItemDimensions(dimensions);
  }, [allowDrag, dimensions]);

  const [, drag, preview] = useDrag({
    type: 'commentsWidget',
    item: {
      id,
      position: { left: position.left, top: position.top },
      dimensions,
      type: 'commentsWidget',
      data
    },
    canDrag: allowDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div
      className={allowDrag ? styles.DraggableContainer : styles.container}
      style={{
        left: position.left,
        top: position.top,
        width: itemDimensions.width,
        height: itemDimensions.height
      }}
    >
      <div className={styles.widgetContainer} ref={isAbleDrag ? drag : null}>
        <div ref={preview} className={styles.previewIcon}>
          <img src={comment} alt={'commentIcon'} />
        </div>
        <div className={styles.box}>{children}</div>
      </div>
    </div>
  );
};
