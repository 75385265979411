import { useEffect, useState } from 'react';

import styles from './SessionList.module.scss';
import SessionFilter from './components/SessionFilter/SessionFilter';
import { toggleListType } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import SessionItemList from './components/SessionItemList/SessionItemList';
import { useGetAccessToken } from 'utils/hooks/token';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { GroupSessionListFilterParameter, SessionStatus } from 'pages/Groups/Interfaces/GroupsSession';
import { useFetchGroupSessionList } from './hooks/getGroupSessionList';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

const SessionList = () => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const paramsPath = useParams<{ groupId: string; contentId: string; groupTab: string }>();
  const location = useLocation();
  const queryParam: GroupSessionListFilterParameter = queryString.parse(location.search);
  const participantType: ParticipantType = paramsPath.contentId
    ? (paramsPath.contentId as ParticipantType)
    : ParticipantType.Group;

  const [searchQueryParam, setSearchQueryParam] = useState<GroupSessionListFilterParameter>({
    ...(queryParam.q && { q: queryParam.q || '' }),
    sessionStatus:
      queryParam.sessionStatus && Object.values(SessionStatus).includes(queryParam.sessionStatus as SessionStatus)
        ? queryParam.sessionStatus
        : SessionStatus.Upcoming
  });

  const { groupSessions, isGroupSessionsLoading, fetchGroupSessionList } = useFetchGroupSessionList(
    token,
    paramsPath.groupId!,
    participantType,
    searchQueryParam.sessionStatus
  );

  const setNewParam = (newParamValue: GroupSessionListFilterParameter) => {
    setSearchQueryParam(newParamValue);
    const paramStringify = queryString.stringify(newParamValue);
    navigate(`${GROUPS.BASE}/${paramsPath.groupId}/sessions/${participantType}?${paramStringify}`);
  };

  useEffect(() => {
    if (token && location.search === '?refetch') {
      fetchGroupSessionList(token, searchQueryParam.sessionStatus);
      setNewParam({
        ...searchQueryParam
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChangeStatus = (statusList: toggleListType) => {
    const newParam = {
      ...searchQueryParam,
      sessionStatus: statusList.id as SessionStatus
    };
    setNewParam(newParam);
  };

  const handleSearch = (searchValue: string) => {
    if (searchQueryParam.q !== searchValue) {
      const newParam = {
        ...searchQueryParam,
        q: searchValue || undefined
      };
      setNewParam(newParam);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Sessions</div>
      <div className={styles.content}>
        <SessionFilter
          groupSessions={groupSessions}
          isGroupSessionsLoading={isGroupSessionsLoading}
          searchValue={''}
          selectedStatus={searchQueryParam.sessionStatus as SessionStatus}
          handleChangeStatus={handleChangeStatus}
          handleSearch={handleSearch}
        />
        <SessionItemList
          groupSessions={groupSessions}
          isGroupSessionsLoading={isGroupSessionsLoading}
          groupId={paramsPath.groupId!}
          contentId={participantType}
          searchQueryParam={searchQueryParam}
        />
      </div>
    </div>
  );
};

export default SessionList;
