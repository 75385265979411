import { notification } from 'antd';
import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';
import { OutstandingAssessment, OutstandingAssessmentType } from 'pages/AllAssessments/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { getOutstandingAssessments } from 'utils/http/CheckInService/Assessment/adhocAssessment';
import { getClinicalAssessmentList } from 'utils/http/CheckInService/Assessment/clinicalAssessment';

const ITEMS_PER_PAGE = 8;

type BaseOutstandingAssessment = Omit<OutstandingAssessment, 'clientRecord' | 'clientProfile' | 'clinicianProfile'>;

interface GetOutstandingAssessmentsResponse {
  outstandingAssessments: BaseOutstandingAssessment[];
}

export interface ClientAssessment {
  _id: string;
  isComplete: boolean;
  type?: OutstandingAssessmentType;
  clinicalAssessmentIds: string[];
  createdAt: string;
}

export const useGetAllClientAssessments = ({
  accountId,
  clientRecordId
}: {
  accountId: string;
  clientRecordId: string;
}) => {
  const { token } = useGetAccessToken();
  const { isEdgeUserView } = useGetAccountPackageView();

  const [allAssessments, setAllAssessments] = useState<ClientAssessment[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchAllClientAssessments = useCallback(
    async (token: string) => {
      setIsLoading(true);

      try {
        const [outstandingAssessmentsResponse, completedAssessmentsResponse] = await Promise.all([
          !isEdgeUserView &&
            getOutstandingAssessments(token, accountId, { clientRecordIds: [clientRecordId] }).then(
              (response) => response.json() as Promise<GetOutstandingAssessmentsResponse>
            ),
          getClinicalAssessmentList(token, clientRecordId).then(
            (response) => response.json() as Promise<ClinicalAssessmentResponseList[]>
          )
        ]);

        setAllAssessments(
          [
            ...(outstandingAssessmentsResponse
              ? outstandingAssessmentsResponse.outstandingAssessments.map(
                  ({ _id, type, clinicalAssessmentIds, createdAt }) => ({
                    _id,
                    isComplete: false,
                    type,
                    clinicalAssessmentIds,
                    createdAt
                  })
                )
              : []),
            ...completedAssessmentsResponse.map(({ _id, id, createdAt }) => ({
              _id,
              isComplete: true,
              clinicalAssessmentIds: [id],
              createdAt
            }))
          ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0))
        );
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Something went wrong while trying to fetch outstanding assessments'
        });
      }

      setIsLoading(false);
    },
    [accountId, clientRecordId, isEdgeUserView]
  );

  useEffect(() => {
    if (token) {
      fetchAllClientAssessments(token);
    }
  }, [token, fetchAllClientAssessments]);

  const navigatePage = (page: number) => {
    if (isLoading || page < 1 || page > totalPage) {
      return;
    }
    setCurrentPage(page);
  };

  const paginatedAssessments = useMemo(
    () => allAssessments.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE),
    [currentPage, allAssessments]
  );

  const totalItems = allAssessments.length;
  const totalPage = Math.ceil(totalItems / ITEMS_PER_PAGE);

  return {
    currentPage,
    totalPage,
    totalItems,
    isLoading,
    navigatePage,
    assessments: paginatedAssessments,
    refetchAssessments: () => (token ? fetchAllClientAssessments(token) : undefined)
  };
};
