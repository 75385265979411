export enum DataType {
  Customer = 'customers',
  InvoiceItem = 'items',
  Invoice = 'invoices'
}

export enum Action {
  Create = 'create',
  Update = 'update'
}

export enum TransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Retried = 'retried'
}

export interface TransactionsResponse {
  _id: string;
  accountId: string;
  companyId: string;
  type: DataType;
  action: Action;
  pushOperationKey: string;
  data: {
    clientRecordId?: string;
    clientName?: string;
    emailAddress?: string;
    contact?: string;
    invoiceId?: string;
    invoiceRefId?: string;
    integrationRefId?: string;
    amount?: number;
    clinicianId?: string;
    accountName?: string;
    nominalCode?: string;
    codatId?: string;
  };
  status: TransactionStatus;
  errorMessage?: string;
  createdAt: Date;
}

export interface TrackingCategoriesResponse {
  trackingCategories: {
    id: string;
    parentId: string;
    modifiedDate?: string;
    name: string;
    description?: string;
    hasChildren: boolean;
    status: string;
  }[];
  counts: number;
}

export interface InvoiceItemsResponse {
  items: {
    id: string;
    name?: string;
    code?: string;
    accountRefId?: string;
  }[];
  counts: number;
}

export interface TaxRatesResponse {
  taxRates: {
    id: string;
    name: string;
    effectiveTaxRate: number;
    totalTaxRate: number;
  }[];
  counts: number;
}

export interface AccountsResponse {
  accounts: {
    id: string;
    nominalCode?: string;
    name: string;
    description?: string;
    type: string;
    status: string;
    isBankAccount: boolean;
  }[];
  paymentAccounts: {
    id: string;
    nominalCode?: string;
    name: string;
    description?: string;
    type: string;
    status: string;
    isBankAccount: boolean;
  }[];
  counts: number;
}
