import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { getClientLatestActivity } from 'utils/http/ActivityFeedService/activities';
import { getAppointmentsByClientRecordIds, getPatientNextAppointments } from 'utils/http/appointment';
import { getMicroCheckIns } from 'utils/http/checkIn';
import { LatestClientsActivityInterface } from 'interfaces/Clients/latestClientsActivity';
import { NextAppointment } from 'interfaces/Clients/nextAppointment';
import {
  clientListFilterParameter,
  ClientListingCount,
  GetClientProfileInterface
} from 'interfaces/Clients/clientsRecord';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { appointmentByClientRecordId, AppointmentDocument } from 'interfaces/Clients/Appointment';
import moment from 'moment';
import { sortBy } from 'lodash';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import queryString from 'query-string';

interface MicroCheckInsInterface {
  microCheckIns: {
    name: string;
    isPublished: boolean;
    isDeleted: boolean;
    clients: {
      clientRecordId: string;
      _id: string;
    }[];
  }[];
}

export const useFetchPrograms = (token: string) => {
  const [programs, setPrograms] = useState<MicroCheckInsInterface['microCheckIns'] | undefined>(undefined);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();

  const fetchProgram = async () => {
    if (isEdgeReceptionist) {
      return;
    }
    const callGetMicroCheckIns = await getMicroCheckIns(token);

    const { microCheckIns } = (await callGetMicroCheckIns.json()) as MicroCheckInsInterface;

    setPrograms(microCheckIns.filter((mci) => !mci.isDeleted && mci.isPublished));
    setIsProgramsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchProgram();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { programs, isProgramsLoading };
};

export const useFetchLatestActivity = (token: string) => {
  const [latestClientsActivity, setLatestClientsActivity] = useState<LatestClientsActivityInterface[] | undefined>(
    undefined
  );
  const [isLatestClientsActivityLoading, setIsLatestClientsActivityLoading] = useState(true);

  const fetchLatestActivity = async () => {
    const getClientListLatestActivity = await getClientLatestActivity(token);

    const patientsLatestActivity = (await getClientListLatestActivity.json()) as LatestClientsActivityInterface[];

    setLatestClientsActivity(patientsLatestActivity);
    setIsLatestClientsActivityLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchLatestActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { latestClientsActivity, isLatestClientsActivityLoading };
};

export const useFetchNextAppointments = (token: string, isAdmin: boolean, clientRecordIds: string) => {
  const [nextAppointments, setNextAppointments] = useState<NextAppointment[]>([]);
  const [isNextAppointmentsLoading, setIsNextAppointmentsLoading] = useState(true);

  const fetchNextAppointments = useCallback(
    async (token: string) => {
      setIsNextAppointmentsLoading(true);

      try {
        const callGetPatientNextAppointments = await getPatientNextAppointments(token, isAdmin, clientRecordIds);
        const { patientNextAppointments } = await callGetPatientNextAppointments.json();
        setNextAppointments(patientNextAppointments);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get next appointments' });
      }

      setIsNextAppointmentsLoading(false);
    },
    [clientRecordIds, isAdmin]
  );

  useEffect(() => {
    if (token && clientRecordIds.length > 0) {
      fetchNextAppointments(token);
    }
  }, [token, clientRecordIds, fetchNextAppointments]);

  return { nextAppointments, isNextAppointmentsLoading, fetchNextAppointments };
};

export const useFetchClientsRecord = (token: string, option: clientListFilterParameter, isMHH?: boolean) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [clientRecordList, setClientRecordList] = useState<GetClientProfileInterface>({
    clientRecords: [],
    recordStatusCounts: {
      active: 0,
      closed: 0
    },
    paging: {
      page: 1,
      perPage: 10,
      totalItem: 10,
      totalPage: 1
    }
  } as GetClientProfileInterface);
  const [isClientRecordListLoading, setIsClientRecordListLoading] = useState(true);

  const fetchClientsRecord = async (token: string, option: clientListFilterParameter) => {
    setIsClientRecordListLoading(true);
    try {
      const queryParam = {
        perPage: option.perPage,
        page: option.page,
        recordStatus: option.status === 'prospect' ? option.prospectStatus ?? 'prospect' : option.status,
        q: option.q,
        isMHH: isMHH,
        profileType: option.status === 'prospect' ? 'prospect' : 'full,recordOnly',
        sortByClientName: option.sortByClientName,
        sortByClass: option.sortByClass,
        sortByClientSince: option.sortByClientSince
      };
      const qParam = queryString.stringify(queryParam);
      const qString = qParam
        ? `?${qParam}${option.additionalQuery ? `&${option.additionalQuery}` : ''}`
        : option.additionalQuery
        ? `?${option.additionalQuery}`
        : '';

      if ((isEdgeAdminView || isEdgeReceptionist) && accountId) {
        const clientsData = await getClientsRecordByAccountId(token, accountId, qString);
        const clientsRecordData: GetClientProfileInterface = await clientsData.json();

        if (clientsRecordData.clientRecords.length) {
          const clientRecordIds = clientsRecordData.clientRecords.map((clientListObj) => clientListObj._id).join(',');

          const appointmentsData = await getAppointmentsByClientRecordIds(
            accountId,
            '2020-01-01',
            moment(new Date()).add(3, 'months').format('YYYY-MM-DD'),
            token,
            clientRecordIds
          );

          const appointmentsByClientRecordId: appointmentByClientRecordId[] = await appointmentsData.json();

          for (const key in clientsRecordData.clientRecords) {
            const matchedData = appointmentsByClientRecordId.find(
              (appointment) => appointment._id === clientsRecordData.clientRecords[key]._id
            );

            if (matchedData) {
              clientsRecordData.clientRecords[key].appointmentStatistics = massagePatientAppointments(
                matchedData.totalAppointments
              );
            } else {
              clientsRecordData.clientRecords[key].appointmentStatistics = {
                completedAppointmentsTotal: 0,
                upcomingAppointmentsTotal: 0
              };
            }
          }
        }

        setClientRecordList(clientsRecordData);
      } else {
        const clientsData = await getClientsRecord(token, qString);
        const clientsRecordData = await clientsData.json();

        setClientRecordList(clientsRecordData);
      }
    } catch (ex) {
      console.log(ex);
      notification.error({ message: 'Something went wrong while trying to get your clients record' });
    }

    setIsClientRecordListLoading(false);
  };

  useEffect(() => {
    if (token) {
      setIsClientRecordListLoading(true);
      fetchClientsRecord(token, option);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEdgeAdminView, option.additionalQuery]);

  return { clientRecordList, isClientRecordListLoading, fetchClientsRecord };
};

const massagePatientAppointments = (
  patientAppointments: AppointmentDocument[]
): {
  upcomingAppointmentsTotal: number;
  upcomingAppointments: AppointmentDocument[];
  completedAppointmentsTotal: number;
  completedAppointments: {
    month: string;
    appointments: (AppointmentDocument & { appointmentNo: number; month: string })[];
  }[];
} => {
  const upcomingAppointments = sortBy(
    patientAppointments.filter(
      (patientAppt) => moment(new Date()) <= moment(patientAppt.date + ' ' + patientAppt.startTime)
    ),
    (it) => `${it.date} ${it.startTime}`
  );

  const completedAppointments = patientAppointments
    .filter((patientAppt) => moment(new Date()) >= moment(patientAppt.date + ' ' + patientAppt.startTime))
    .sort((apptA, apptB) => moment(apptB.date + ' ' + apptB.startTime).diff(moment(apptA.date + ' ' + apptA.startTime)))
    .map((completedAppt, index, array) => {
      return {
        ...completedAppt,
        appointmentNo: array.length - index,
        month: moment(completedAppt.date).format('MMMM YYYY')
      };
    });

  const months = [...Array.from(new Set(completedAppointments.map((compAppt) => compAppt.month).values()))];

  const completedAppointmentsGroupedByMonth = months.map((month) => ({
    month,
    appointments: completedAppointments.filter((compAppt) => compAppt.month === month)
  }));

  return {
    upcomingAppointmentsTotal: upcomingAppointments.length,
    upcomingAppointments,
    completedAppointmentsTotal: completedAppointments.length,
    completedAppointments: completedAppointmentsGroupedByMonth
  };
};

export const useFetchClientsRecordCount = (token: string, filterValue: clientListFilterParameter) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [clientsCount, setClientsCount] = useState<ClientListingCount>({} as ClientListingCount);
  const [isClientsCountLoading, setIsClientsCountLoading] = useState(true);

  const fetchClientsCount = async (token: string, filterValue: clientListFilterParameter) => {
    setIsClientsCountLoading(true);
    try {
      const qParam = `?perPage=${filterValue.perPage}&q=${filterValue.q}`;
      const qString = `${qParam}${filterValue.additionalQuery ? `&${filterValue.additionalQuery}` : ''}`;

      const clientsData =
        (isEdgeAdminView || isEdgeReceptionist) && accountId
          ? await getClientsRecordByAccountId(token, accountId, qString)
          : await getClientsRecord(token, qString);
      const clientsRecordData = await clientsData.json();

      const massageCountData = {
        active: clientsRecordData.recordStatusCounts.active || 0,
        closed: clientsRecordData.recordStatusCounts.closed || 0,
        prospect: clientsRecordData.recordStatusCounts.prospect || 0
      } as ClientListingCount;

      setClientsCount(massageCountData);
      setIsClientsCountLoading(false);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your Clients count' });
      setIsClientsCountLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      setIsClientsCountLoading(true);
      fetchClientsCount(token, filterValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEdgeAdminView, filterValue.additionalQuery]);

  return { clientsCount, isClientsCountLoading, fetchClientsCount };
};
