import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PaginationList from 'components/PaginationList/PaginationList';
import ActiveStudentItem from './components/ActiveStudentItem/ActiveStudentItem';
import { clientRecordsInterface, GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';
import styles from './ActiveStudentList.module.scss';
import columnStyles from './ActiveStudentColumn.module.scss';
import { useTranslation } from 'react-i18next';
import { AttachedClientRecordStatus } from 'pages/Groups/Interfaces/Groups';

interface ActiveStudentListProps {
  clientRecordList: clientRecordsInterface[];
  isClientRecordListLoading: boolean;
  paging: GetClientProfileInterface['paging'];
  onPageChange: (paging: string) => void;
}

const ActiveStudentList = ({
  clientRecordList,
  isClientRecordListLoading,
  paging,
  onPageChange
}: ActiveStudentListProps) => {
  const [t] = useTranslation();

  return (
    <>
      {isClientRecordListLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : clientRecordList.length > 0 ? (
        <>
          <div className={styles.filterContainer}>
            <div className={columnStyles.profileSection}>NAME</div>
            <div className={columnStyles.classSection}>CLASS</div>
            <div className={columnStyles.pendingAssessmentSection}>PENDING ASSESSMENTS</div>
            <div className={columnStyles.groupSection}>GROUP</div>
            <div className={columnStyles.tagSection}>TAG</div>
            <div className={columnStyles.buttonSection} />
          </div>
          {clientRecordList.map((clientListObj, index) => {
            const isPrimaryData = clientListObj.clientProfiles;
            return (
              <ActiveStudentItem
                clientData={isPrimaryData}
                key={index}
                studentId={clientListObj._id}
                assessment={clientListObj.assessment}
                tags={clientListObj.tags}
                groupNames={clientListObj.attachedGroups
                  ?.filter((obj) => obj.status === AttachedClientRecordStatus.Active)
                  ?.map((item) => item.groupName)}
                classValue={clientListObj.class}
              />
            );
          })}
          {paging.totalItem > paging.perPage && (
            <div className={styles.paginationWrapper}>
              <PaginationList paging={paging} onPageChange={(paging) => onPageChange(paging)} />
            </div>
          )}
        </>
      ) : (
        <div>{t('label.no_client_record_found')}</div>
      )}
    </>
  );
};

export default ActiveStudentList;
