import { ProfileInterface } from 'interfaces/Profile/Profile';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import { getClientAppointmentsByDateRange } from 'utils/http/ScheduleService/Appointments/Appointments';
import CompletedCard from './components/CompletedCard/CompletedCard';
import UpComingCard from './components/UpComingCard/UpComingCard';
import styles from './PatientDetailsAppointments.module.scss';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';
import AppointmentSummary from './components/AppointmentSummary/AppointmentSummary';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { useNavigate, useLocation } from 'react-router-dom';

export const massageClientAppointments = (
  patientAppointments: AppointmentSlots[]
): {
  upcomingAppointmentsTotal: number;
  upcomingAppointments: AppointmentSlots[];
  completedAppointmentsTotal: number;
  completedAppointments: {
    month: string;
    appointments: AppointmentSlots[];
  }[];
} => {
  const upcomingAppointments = sortBy(
    patientAppointments.filter(
      (patientAppt) => moment(new Date()) <= moment(patientAppt.date + ' ' + patientAppt.startTime)
    ),
    (it) => `${it.date} ${it.startTime}`
  );

  const completedAppointments = patientAppointments
    .filter((patientAppt) => moment(new Date()) >= moment(patientAppt.date + ' ' + patientAppt.startTime))
    .sort((apptA, apptB) => moment(apptB.date + ' ' + apptB.startTime).diff(moment(apptA.date + ' ' + apptA.startTime)))
    .map((completedAppt, index, array) => {
      return {
        ...completedAppt,
        appointmentNo: array.length - index,
        month: moment(completedAppt.date).format('MMMM YYYY')
      };
    });

  const months = [...Array.from(new Set(completedAppointments.map((compAppt) => compAppt.month).values()))];

  const completedAppointmentsGroupedByMonth = months.map((month) => ({
    month,
    appointments: completedAppointments.filter((compAppt) => compAppt.month === month)
  }));

  return {
    upcomingAppointmentsTotal: upcomingAppointments.length,
    upcomingAppointments,
    completedAppointmentsTotal: completedAppointments.length,
    completedAppointments: completedAppointmentsGroupedByMonth
  };
};

export interface AppointmentDocument {
  _id: string;
  createTime: Date;
  updateTime: Date;
  clinicianId: string;
  date: string;
  startTime: string;
  endTime: string;
  gap: number;
  rate: number;
  type: 'block' | 'booking' | 'busy' | 'reserved';
  deliveryType: DeliveryType;
  confirmation: {
    clinician: boolean;
    patient: boolean;
  };
  notes?: string;
  clientRecord: {
    _id: string;
    clientProfiles: {
      _id: string;
      clientAuth0Id?: string;
      avatar?: string;
      initials?: string;
      initialsBackgroundColor?: string;
      name: string;
    }[];
  };
  sessionTypeName: string;
  markedStatus?: string[];
  // [key: string]: unknown;
}

interface PatientDetailsAppointmentsProps {
  recordId: string;
  token: string;
  profile: ProfileInterface;
  clientRecordData: clientRecordsInterface;
  isProfileLoading: boolean;
}

const useAppointmentsData = (
  from: string,
  to: string,
  setIsAppointmentsLoading: Dispatch<SetStateAction<boolean>>,
  clientRecordId: string,
  token: string
) => {
  const [appointmentsData, setAppointmentsData] = useState([]);

  const fetchAppointments = async () => {
    const callGetAppointments = await getClientAppointmentsByDateRange(token, from, to, clientRecordId);

    const data = await callGetAppointments.json();

    setAppointmentsData(data);

    setIsAppointmentsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppointments();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appointmentsData, fetchAppointments };
};

const PatientDetailsAppointments = ({
  recordId,
  token,
  profile,
  clientRecordData,
  isProfileLoading
}: PatientDetailsAppointmentsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const node = useRef<HTMLDivElement>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState(['upcoming', 'past']);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    openFilterList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const openFilterList = (val: boolean) => {
    setShowFilter(val);
  };

  const { appointmentsData, fetchAppointments } = useAppointmentsData(
    '2020-01-01',
    moment(new Date()).add(3, 'months').format('YYYY-MM-DD'),
    setIsAppointmentsLoading,
    recordId,
    token
  );

  useEffect(() => {
    if (location.search === '?refetch') {
      fetchAppointments();
      navigate(location.pathname);
    }
  }, [location.search, fetchAppointments, navigate, location.pathname]);

  const massagedPatientAppointments = massageClientAppointments(appointmentsData);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <AppointmentSummary
          clientRecordId={clientRecordData._id}
          clientAppointment={appointmentsData}
          appointmentStatusOverviewConfiguration={clientRecordData.appointmentStatusOverviewConfiguration}
          upcomingAppointmentsTotal={massagedPatientAppointments.upcomingAppointmentsTotal}
          completedAppointmentsTotal={massagedPatientAppointments.completedAppointmentsTotal}
          isLoading={isAppointmentsLoading || isProfileLoading}
        />
        <div className={styles.title}>Appointment Overview</div>
        <div ref={node}>
          <div className={styles.filterWrapper}>
            <div className={styles.filterLabel}>Filter:</div>
            <div className={styles.filter} onClick={() => openFilterList(!showFilter)}>
              All
              <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
            </div>
          </div>
          <div className={styles.dropDown}>
            <div className={showFilter ? styles.dropDownMenuShow : styles.dropDownMenuHide}>
              <div
                className={styles.listBox}
                onClick={() => {
                  setFilter(['upcoming', 'past']);
                  setShowFilter(false);
                }}
              >
                All
              </div>
              <div
                className={styles.listBox}
                onClick={() => {
                  setFilter(['upcoming']);
                  setShowFilter(false);
                }}
              >
                Upcoming only
              </div>
              <div
                className={styles.listBox}
                onClick={() => {
                  setFilter(['past']);
                  setShowFilter(false);
                }}
              >
                Past only
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {filter.includes('upcoming') && (
          <UpComingCard
            total={massagedPatientAppointments.upcomingAppointmentsTotal}
            data={massagedPatientAppointments.upcomingAppointments}
            profile={profile}
            isLoading={isAppointmentsLoading || isProfileLoading}
            refetchAppointment={() => fetchAppointments()}
          />
        )}
        {filter.includes('upcoming') && filter.includes('past') ? <div className={styles.divider} /> : null}
        {filter.includes('past') && (
          <CompletedCard
            total={massagedPatientAppointments.completedAppointmentsTotal}
            data={massagedPatientAppointments.completedAppointments}
            isLoading={isAppointmentsLoading || isProfileLoading}
            refetchAppointment={() => fetchAppointments()}
          />
        )}
      </div>
    </div>
  );
};

export default PatientDetailsAppointments;
