import parse from 'html-react-parser';
import moment from 'moment';

import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { convertStringToTitleCase } from 'utils/general';

import { ProfileInterface } from 'interfaces/Profile/Profile';

import styles from './InterventionActivityItem.module.scss';
import { InterventionActivityObject, InterventionActivity } from '../../InterventionActivity';
import { ActivityActor } from '../../../../../../../../../../../../pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsActivity/components/ActivityFeed/ActivityFeed';

const getIcon = (object: InterventionActivityObject) => {
  switch (object) {
    case InterventionActivityObject.CaseNote:
      return 'speaker_notes';
    case InterventionActivityObject.Attachment:
      return 'attachment';
    case InterventionActivityObject.Student:
    case InterventionActivityObject.WellbeingTeam:
      return 'person';
    case InterventionActivityObject.Intervention:
      return 'groups';
    case InterventionActivityObject.AdHocAssessment:
      return 'assessment';
    case InterventionActivityObject.AdHocSurvey:
      return 'fact_check';
    case InterventionActivityObject.Invoice:
      return 'receipt';
    default:
      console.warn('Failed to get icon for patient activity');
      return '';
  }
};

const getTitle = (object: InterventionActivityObject) => {
  return convertStringToTitleCase(object);
};

interface InterventionActivityItemProps {
  item: InterventionActivity;
  profile: ProfileInterface;
}

const InterventionActivityItem = ({ item, profile }: InterventionActivityItemProps) => {
  const icon = getIcon(item.object);
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const practiceName = profile.practice?.name;

  return (
    <>
      <div>{icon && <span className={`${styles.activityIcon} material-icons-outlined`}>{icon}</span>}</div>
      <div>
        <div className={styles.activityTitle}>
          <span>{getTitle(item.object)} </span>
          <b>{item.action.toUpperCase()}</b>
          {` by ${
            item.actor === ActivityActor.WellbeingTeam
              ? isEdgeAdminView
                ? item.clinicianId
                  ? item.clinicianName
                  : practiceName
                : isNormalUserView
                ? 'You'
                : item.clinicianId === auth0ClinicianId
                ? 'You'
                : item.clinicianId
                ? item.clinicianName
                : practiceName
              : item.actor === ActivityActor.System
              ? 'CORDS'
              : ''
          }`}
        </div>
        <div>
          {moment(item.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY' })} - {parse(item.description)}
        </div>
      </div>
    </>
  );
};

export default InterventionActivityItem;
