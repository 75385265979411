import classNames from 'classnames';
import { DayTimeSelection, DeliveryType, ScheduleOption } from 'interfaces/Schedule/AppointmentType';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import styles from './AppointmentTypeOption.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;

interface AppointmentTypeOptionProps {
  _id: string;
  name?: string;
  description?: string;
  duration?: number;
  rate: number;
  dayTimeSelections: DayTimeSelection;
  deliveryOptions: DeliveryType[];
  selected: boolean;
  onClick: (id: string) => void;
}

const AppointmentTypeOption = ({
  _id,
  name,
  description,
  duration,
  rate,
  dayTimeSelections,
  deliveryOptions,
  selected,
  onClick
}: AppointmentTypeOptionProps) => {
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();

  const generateDayTimeSelections = () => {
    const availabilities =
      dayTimeSelections.kind === ScheduleOption.AutoFit ? dayTimeSelections.autoFit : dayTimeSelections.fixedTime;
    return (
      <>
        <div className={availabilities?.Monday?.isActive ? styles.active : ''}>M</div>
        <div className={availabilities?.Tuesday?.isActive ? styles.active : ''}>T</div>
        <div className={availabilities?.Wednesday?.isActive ? styles.active : ''}>W</div>
        <div className={availabilities?.Thursday?.isActive ? styles.active : ''}>T</div>
        <div className={availabilities?.Friday?.isActive ? styles.active : ''}>F</div>
        <div className={availabilities?.Saturday?.isActive ? styles.active : ''}>S</div>
        <div className={availabilities?.Sunday?.isActive ? styles.active : ''}>S</div>
      </>
    );
  };

  return (
    <button
      className={classNames(styles.container, {
        [styles.selected]: selected
      })}
      onClick={() => onClick(_id)}
    >
      <div className={styles.name}>{name}</div>
      {description && <div className={styles.description}>{description}</div>}
      <div className={styles.durationAndRateContainer}>
        <div className={styles.iconLabel}>
          <span className="material-icons-outlined">schedule</span>
          <div className={styles.label}>{duration} minutes</div>
        </div>
        {rate && !isAppointmentRateReadDenied && (
          <div className={styles.iconLabel}>
            {GAE_REGION === 'au' ? (
              <i className="material-icons-outlined">monetization_on</i>
            ) : (
              <span className="material-icons-outlined">£</span>
            )}
            <div className={styles.label}>{rate}</div>
          </div>
        )}
      </div>
      <div>
        <div className={styles.label}>AVAILABLE</div>
        <div className={styles.weekdaysContainer}>{generateDayTimeSelections()}</div>
      </div>
      <div>
        <div className={styles.label}>VIA</div>
        <div className={styles.deliveryTypeContainer}>
          <div className={deliveryOptions.includes(DeliveryType.FaceToFace) ? styles.active : ''}>
            <span className="material-icons">supervisor_account</span>
          </div>
          <div className={deliveryOptions.includes(DeliveryType.PhoneCall) ? styles.active : ''}>
            <span className="material-icons">call</span>
          </div>
          <div className={deliveryOptions.includes(DeliveryType.VideoCall) ? styles.active : ''}>
            <span className="material-icons-outlined">videocam</span>
          </div>
        </div>
      </div>
      {selected && (
        <div className={styles.selectedBadge}>
          <span className="material-icons">check_circle</span>
        </div>
      )}
    </button>
  );
};

export default AppointmentTypeOption;
