import styles from './ContactTag.module.scss';

export interface ContactTagProps {
  tags: string[];
}

const ContactTag = ({ tags }: ContactTagProps) => (
  <div className={styles.container}>
    <div className={styles.tags}>
      {tags &&
        tags.length > 0 &&
        tags.map((tag, index) => (
          <div key={`contactTag_${index}`} className={styles.tagPill}>
            {tag}
          </div>
        ))}
    </div>
  </div>
);

export default ContactTag;
