import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { GroupSessionList, GroupSessionListFilterParameter } from 'pages/Groups/Interfaces/GroupsSession';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { getGroupSessionList } from 'utils/http/ScheduleService/Groups/groups';

export const useFetchGroupSessionList = (
  token: string,
  groupId: string,
  contentId: ParticipantType,
  sessionStatus: GroupSessionListFilterParameter['sessionStatus']
) => {
  const [groupSessions, setGroupSessions] = useState<GroupSessionList>({
    sessions: [],
    count: {
      upcoming: 0,
      completed: 0
    }
  });
  const [isGroupSessionsLoading, setIsGroupSessionsLoading] = useState(true);

  const fetchGroupSessionList = useCallback(
    async (token: string, sessionStatus: GroupSessionListFilterParameter['sessionStatus']) => {
      try {
        setIsGroupSessionsLoading(true);
        const getGroupSessionData = await getGroupSessionList(token, groupId, sessionStatus);
        const groupSessionListData: GroupSessionList = await getGroupSessionData.json();

        setGroupSessions(groupSessionListData);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your groups session list' });
      }
      setIsGroupSessionsLoading(false);
    },
    [groupId]
  );

  useEffect(() => {
    if (token) {
      fetchGroupSessionList(token, sessionStatus);
    }
  }, [fetchGroupSessionList, token, sessionStatus]);

  return { groupSessions, isGroupSessionsLoading, fetchGroupSessionList };
};
