import styles from './MemberList.module.scss';
import columnStyles from './MemberListColumn.module.scss';
import classnames from 'classnames';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDesc from 'components/TableList/PaginationDesc/PaginationDesc';
import { InterventionMemberList, Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import { combineName } from 'utils/general';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Skeleton } from 'antd';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import InterventionConfigurationActionMenu from 'MHH/pages/Interventions/InterventionDetails/components/InterventionContent/components/Common/InterventionConfigurationActionMenu/InterventionConfigurationActionMenu';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import moment from 'moment';
import StatusBadge from '../../../../../StatusBadge/StatusBadge';

interface MemberListProps {
  interventionId: string;
  interventionDetails: Interventions;
  interventionMembers: InterventionMemberList;
  isInterventionMembersLoading: boolean;
  selectedPerPage: number;
  selectedSortDateJoined?: SortType;
  selectedSortName?: SortType;
  selectedPage: number;
  onChangeDateJoinedSort: (val: SortType) => void;
  onChangeNameSort: (val: SortType) => void;
  onChangePerPage: (val: number) => void;
  onChangePage: (val: number) => void;
  onRefreshList: () => void;
}

const MemberList = ({
  interventionId,
  interventionDetails,
  interventionMembers,
  isInterventionMembersLoading,
  selectedPerPage,
  selectedSortDateJoined,
  selectedSortName,
  selectedPage,
  onChangeDateJoinedSort,
  onChangeNameSort,
  onChangePerPage,
  onChangePage,
  onRefreshList
}: MemberListProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const TOTAL_ITEM = interventionMembers.paging.totalItem || 0;

  const handleRecordPerPageValue = (perPageVal: number) => {
    onChangePerPage(perPageVal);
  };

  const handlePageChange = (pageVal: number) => {
    onChangePage(pageVal);
  };

  const isInterventionPractitioner =
    auth0ClinicianId === interventionDetails?.leadClinicianAuth0Id ||
    interventionDetails?.clinicianAuth0Ids.includes(auth0ClinicianId);

  return (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        <div className={classnames(columnStyles.columnWrapperHeader, styles.header)}>
          <div className={classnames(columnStyles.dateAdded, columnStyles.sortActive)}>
            DATE ADDED
            <SortBtn
              selectedSortName={selectedSortDateJoined}
              onChangeSort={onChangeDateJoinedSort}
              disabled={isInterventionMembersLoading}
            />
          </div>
          <div className={columnStyles.avatar}>AVATAR</div>
          <div className={classnames(columnStyles.fullName, columnStyles.sortActive)}>
            FULL NAME
            <SortBtn
              selectedSortName={selectedSortName}
              onChangeSort={onChangeNameSort}
              disabled={isInterventionMembersLoading}
            />
          </div>
          <div className={columnStyles.status}>STATUS</div>
          <div className={columnStyles.stage}>STAGE</div>
          <div className={styles.actionHeader}>
            <div className={columnStyles.dropdown} />
            <div className={columnStyles.action} />
          </div>
        </div>
        {isInterventionMembersLoading ? (
          <>
            {[...Array(10)].map((obj, i) => (
              <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
                <Skeleton.Input active className={styles.loading} />
              </div>
            ))}
          </>
        ) : interventionMembers.clientRecords.length > 0 ? (
          <>
            {interventionMembers.clientRecords.map((clientRecordObj, index) => {
              const attachedInterventions = clientRecordObj.attachedInterventions?.filter(
                (interventionObj) => interventionObj.interventionId === interventionId
              )[0];
              const stageInfo =
                interventionDetails.stagesConfiguration.anytime.find(
                  (stageObj) => stageObj._id === attachedInterventions?.stageId
                ) ||
                interventionDetails.stagesConfiguration.sequential.find(
                  (stageObj) => stageObj._id === attachedInterventions?.stageId
                );

              return (
                attachedInterventions && (
                  <div
                    key={index}
                    style={{ zIndex: 100 - index }}
                    className={classnames(columnStyles.columnWrapper, styles.listItem)}
                  >
                    <div className={classnames(columnStyles.dateAdded, styles.dateJoined)}>
                      {moment(attachedInterventions?.dateJoined).format('DD MMM YYYY')}
                    </div>
                    <div className={columnStyles.avatar}>
                      <ClientAvatar
                        clientData={clientRecordObj.clientProfiles}
                        displayLimit={clientRecordObj.recordType === 'couple' ? 2 : 1}
                        hideName
                      />
                    </div>
                    <div className={classnames(columnStyles.fullName, styles.name)}>
                      {combineName(clientRecordObj.clientProfiles.slice(0, 1), true)}
                    </div>
                    <div className={columnStyles.status}>
                      <StatusBadge status={attachedInterventions.status} />
                    </div>
                    <div className={classnames(columnStyles.stage, styles.stage)}>
                      {stageInfo ? (
                        <div className={styles.stagePill}>{stageInfo.name}</div>
                      ) : (
                        <div className={styles.noValue}>-</div>
                      )}
                    </div>
                    <div className={styles.arrowWrapper}>
                      <div className={columnStyles.dropdown}>
                        {(isEdgeAdminView || isInterventionPractitioner) && (
                          <InterventionConfigurationActionMenu
                            interventionId={interventionId}
                            activeStageId={attachedInterventions.stageId}
                            interventionStatus={attachedInterventions.status}
                            interventionStagesConfiguration={interventionDetails.stagesConfiguration}
                            clientRecordId={clientRecordObj._id}
                            onRefreshList={onRefreshList}
                          />
                        )}
                      </div>
                      <div className={columnStyles.action}>
                        <Link to={`${CLIENTS.BASE}/${clientRecordObj._id}/profile`}>
                          <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </>
        ) : (
          <div className={styles.noMemberFoundContainer}>No members found</div>
        )}
      </div>
      <div className={styles.paginationWrapper}>
        <div className={styles.recordPerPageWrapper}>
          <PerRecordDropdown
            totalItem={TOTAL_ITEM}
            selectedPerPage={selectedPerPage}
            selectDropdownValue={handleRecordPerPageValue}
            disabled={isInterventionMembersLoading}
          />
          <div className={styles.label}>records per page</div>
        </div>
        <PaginationDesc
          currentPage={selectedPage}
          totalItem={TOTAL_ITEM}
          perPage={selectedPerPage}
          onPageChange={handlePageChange}
          isLoading={isInterventionMembersLoading}
          label={'members'}
        />
      </div>
    </div>
  );
};

export default MemberList;
