import Badge from 'components/Badge/Badge';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { ReportListInterface } from 'interfaces/Reports/report';
import { startCase } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { checkIsTodayOrYesterday } from 'utils/timeDifferent';

import styles from './ReportItem.module.scss';

interface ReportItemProps {
  reportListData: ReportListInterface[];
}

const ReportItem = ({ reportListData }: ReportItemProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const goToDetail = (status: string, id: string, clinicianId: string) => {
    navigate(`${REPORTS.BASE}/${id}`);
  };

  return (
    <>
      {reportListData.map((item: ReportListInterface, index: number) => {
        const createdTimeCheckToday = checkIsTodayOrYesterday(item.createdAt);
        const updatedTimeCheckToday = checkIsTodayOrYesterday(item.lastUpdatedTime);
        const primaryData = item.clientRecord?.clientProfiles[0];
        const clientProfile = item.clientRecord?.clientProfiles as clientProfilesInterface[];

        return (
          <div
            className={styles.listingWrapper}
            key={index}
            onClick={() => goToDetail(item.status, item._id, item.clinician._id)}
          >
            <div className={styles.contentWrapper}>
              {(isEdgeAdminView || isEdgeReceptionist) && (
                <div className={styles.authorSection}>
                  <ClinicianAvatar
                    avatarSize={60}
                    isEllipsisName
                    avatarUrl={item.isForPractice ? item.practice?.logo : item.clinician?.avatar}
                    name={item.isForPractice ? item.practice?.name : item.clinician?.name}
                  />
                </div>
              )}
              <div className={styles.profileSection}>
                <ClientAvatar
                  clientData={clientProfile}
                  avatarSize={60}
                  isEllipsisName
                  displayFirstNameOnly={item.clientRecord.recordType === 'couple'}
                  displayLimit={item.clientRecord.recordType === 'couple' ? 2 : 1}
                  statusCircle={item.status !== 'published' ? 'inactive' : 'active'}
                />
              </div>
              <div className={styles.reportNameSection}>
                <div className={styles.link}>{item.reportName}</div>
              </div>
              <div className={styles.statusSection}>
                <Badge label={startCase(item.status)} className={styles[item.status]} />
                {item && item.shareDetails?.accessors.length > 0 && (
                  <Badge label={'Shared'} className={styles.shareBadge} />
                )}
                {item && ['changesRequested', 'feedback'].includes(item.review?.reviewType) && (
                  <Badge
                    label={startCase(item.review.reviewType)}
                    className={
                      item.review.reviewType === 'feedback' ? styles.feedbackBadge : styles.changesRequestedBadge
                    }
                  />
                )}
                {item && ['draft', 'approved'].includes(item.status) && item.editedByReviewer && (
                  <Badge label={'Reviewers Edits'} className={styles.reviewerEditsBadge} />
                )}
              </div>
              <div className={styles.createdSection}>
                {createdTimeCheckToday === 'other' ? (
                  <>
                    <div>{moment(item.createdAt).format('D MMM YYYY')}</div>
                    <div>{moment(item.createdAt).format('hh:mm A')}</div>
                  </>
                ) : (
                  <div>
                    <span className={styles.day}>{createdTimeCheckToday} </span>
                    {moment(item.createdAt).format('hh:mm A')}
                  </div>
                )}
              </div>
              <div className={styles.lastAccessedSection}>
                {updatedTimeCheckToday === 'other' ? (
                  <>
                    <div>{moment(item.lastUpdatedTime).format('D MMM YYYY')}</div>
                    <div>{moment(item.lastUpdatedTime).format('hh:mm A')}</div>
                  </>
                ) : (
                  <div>
                    <span className={styles.day}>{updatedTimeCheckToday} </span>
                    {moment(item.lastUpdatedTime).format('hh:mm A')}
                  </div>
                )}
              </div>
              <div className={styles.shareDetailSection}>
                {item && item.shareDetails?.accessors.length > 0 ? (
                  <>
                    <i className={`material-icons-outlined ${styles.shareIcon}`}>share</i>
                    <div className={styles.shareNameContainer}>
                      {item.shareDetails.accessors.slice(0, 3).map((obj: any, index: any) => (
                        <div key={index}>
                          {obj.isClient ? (
                            <div className={styles.shareName}>{primaryData?.name}</div>
                          ) : (
                            <div className={styles.shareName}>{obj.email}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <i className={`material-icons ${styles.icon}`}>
                {item.status !== 'published' ? 'edit' : 'arrow_forward'}
              </i>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ReportItem;
