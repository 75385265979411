import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getProfileTags } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { ProfileTagsInterface } from 'interfaces/Clients/profileTags';
import { ProfileTagsOption } from '../components/DropdownSearchable/interface';

export const useFetchProfileTags = (token: string, selectedTags?: string[]) => {
  const { accountId } = useGetAccountId();
  const [profileTags, setProfileTags] = useState<ProfileTagsOption[]>([] as ProfileTagsOption[]);
  const [isProfileTagsLoading, setIsProfileTagsLoading] = useState(true);

  const fetchProfileTags = async (token: string) => {
    try {
      const res = await getProfileTags(token, accountId);
      const profileTagsData = (await res.json()) as ProfileTagsInterface[];
      const profileTagsOption = profileTagsData.map(({ _id, label }) => ({
        _id,
        label,
        isSelected: selectedTags && selectedTags.length > 0 && selectedTags.includes(_id)
      }));
      setProfileTags(profileTagsOption);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your profile tags' });
    }

    setIsProfileTagsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchProfileTags(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { profileTags, isProfileTagsLoading, setProfileTags };
};
