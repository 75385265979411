import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ClientSelect from 'components/v2/ClientSelect/ClientSelect';
import { ClientRecordType, clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { Report } from 'interfaces/Reports/report';
import { useTranslation } from 'react-i18next';

import Label from '../Label/Label';
import styles from './ClientSection.module.scss';

interface ClientSectionProps {
  clientProfiles: clientProfilesInterface[];
  recordType: ClientRecordType;
  readOnly: boolean;
  selectedClient: Report['clientRecord'];
  clientValidation?: boolean;
  onSelectClient: (selectedClientId: string, b?: boolean) => void;
}

const ClientSection = ({
  clientProfiles,
  recordType,
  readOnly,
  selectedClient,
  clientValidation,
  onSelectClient
}: ClientSectionProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <Label label={'ATTACHED TO'} />
      {readOnly ? (
        <ClientAvatar
          clientData={clientProfiles}
          avatarSize={40}
          initialsClassName={styles.initialName}
          nameClassName={styles.clientName}
          horizontal
          displayFirstNameOnly={recordType === ClientRecordType.Couple}
          displayLimit={recordType === ClientRecordType.Couple ? 2 : 1}
          fullAvatarBg
        />
      ) : (
        <>
          <ClientSelect
            onSelect={onSelectClient}
            selectedId={selectedClient._id}
            allowEmpty={false}
            confirmChangeMessage={t('form.confirm.switch_client_on_report')}
          />
          {clientValidation && <div className={styles.error}>{t('label.please_select_client')}</div>}
        </>
      )}
    </div>
  );
};

export default ClientSection;
