import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';
import { useParams } from 'react-router-dom';
import { getConsentFormByFormId } from 'utils/http/DocumentService/ConsentForm/consentForm';

export const useFetchConsentForm = (token: string) => {
  const path = useParams<{ consentId?: string }>();
  const consentId = path.consentId ?? '';
  const isNewConsentForm = consentId?.includes('new');
  const [consentFormData, setConsentFormData] = useState({} as ConsentFormInterface);
  const [isConsentFormDataLoading, setIsConsentFormDataLoading] = useState(true);

  const fetchConsentDetails = async (token: string) => {
    try {
      if (isNewConsentForm) {
        const consentMockData = {
          _id: '',
          isPublished: false,
          isDefault: false,
          name: '',
          body: ``,
          signatureRequired: {
            other: false,
            client: false
          }
        };
        setConsentFormData(consentMockData as ConsentFormInterface);
      } else {
        const consentData = await (await getConsentFormByFormId(token, consentId)).json();
        setConsentFormData(consentData as ConsentFormInterface);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get form record' });
    }

    setIsConsentFormDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchConsentDetails(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { consentFormData, isConsentFormDataLoading };
};
