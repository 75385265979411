import { useState } from 'react';
import styles from './ParticipationHeader.module.scss';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import Button from 'components/v2/Button/Button';
import AddPatientModalV2 from 'components/AddPatientModalV2/AddPatientModalV2';
import ParticipationToggle from './components/ParticipationToggle/ParticipationToggle';
import { useTranslation } from 'react-i18next';

type ParticipationToggleProps = {
  selectedParticipantType: ParticipantType;
  handleChangeParticipation: (val: ParticipantType) => void;
  selectedSort: boolean;
  onChangeSort: (val: boolean) => void;
  onSuccessAddNewClient?: () => void;
  enableGroupSelection?: boolean;
  disableToggle?: boolean;
};

const ParticipationHeader = ({
  selectedParticipantType,
  handleChangeParticipation,
  selectedSort,
  onChangeSort,
  onSuccessAddNewClient,
  enableGroupSelection,
  disableToggle
}: ParticipationToggleProps) => {
  const [showAddClient, setShowAddClient] = useState(false);

  const onAddNewClientClick = () => {
    setShowAddClient(true);
  };

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <div className={styles.title}>
          Select{' '}
          {selectedParticipantType === ParticipantType.OneToOne
            ? t('label.client.capitalize')
            : t('label.group.capitalize')}
        </div>
        <div className={styles.sortContainer}>
          <div>Sorted by: </div>
          <div className={styles.sort} onClick={() => onChangeSort(!selectedSort)}>
            {selectedSort ? 'A - Z' : 'Z - A'}
            <span className={`material-icons ${styles.arrow}`}>
              {selectedSort ? 'arrow_drop_down' : 'arrow_drop_up'}
            </span>
          </div>
        </div>
      </div>
      {!disableToggle && (
        <>
          {enableGroupSelection ? (
            <ParticipationToggle
              selectedParticipantType={selectedParticipantType}
              onChangeParticipation={handleChangeParticipation}
            />
          ) : (
            <>
              <Button variant={'link'} icon={'add'} onClick={onAddNewClientClick}>
                {t('button.add_new_client')}
              </Button>
              <AddPatientModalV2
                visible={showAddClient}
                onCancel={() => setShowAddClient(false)}
                onResetForm={onSuccessAddNewClient}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ParticipationHeader;
