import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styles from './SequentialStageCard.module.scss';
import { PathwayStageItem, PathwayStageList } from '../../interface';
import StageCard from '../StageCard/StageCard';
import StageCardLoading from '../StageCard/StageCardLoading';
import Button from 'components/v2/Button/Button';
import { patchSequentialSequence } from 'utils/http/ClinicianProfileService/Interventions/InterventionPathway';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

const reorderItem = (list: PathwayStageItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface SequentialStageCardProps {
  interventionId: string;
  sequentialStageList: PathwayStageList['sequential'];
  isStageListingLoading: boolean;
  onRefetchList: () => void;
  onOpenDeleteModal: (stageItemToBeDeleted: PathwayStageItem) => void;
  allowEditStage: boolean;
}

const SequentialStageCard = ({
  interventionId,
  sequentialStageList,
  isStageListingLoading,
  onRefetchList,
  onOpenDeleteModal,
  allowEditStage
}: SequentialStageCardProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [initSequentialStageItem, setInitSequentialStageItem] = useState(sequentialStageList);
  const [sequentialStageItem, setSequentialStageItem] = useState(sequentialStageList);
  const [showButton, setShowButton] = useState(false);
  const [updateStatus, setUpdateStatus] = useState<'' | 'active' | 'finished'>('');

  useEffect(() => {
    setSequentialStageItem(sequentialStageList);
    setInitSequentialStageItem(sequentialStageList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStageListingLoading]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const stageReorder = reorderItem(sequentialStageItem, result.source.index, result.destination.index);
    setSequentialStageItem(stageReorder);
    setShowButton(true);
  };

  const onClickUpdate = async () => {
    setUpdateStatus('active');
    try {
      const massageSequencePayload = sequentialStageItem.map((stageObj) => stageObj._id);
      await patchSequentialSequence(token, accountId, interventionId, massageSequencePayload);
      const massageSequence = sequentialStageItem.map((stageObj, index) => ({
        ...stageObj,
        sequence: index + 1
      }));
      setSequentialStageItem(massageSequence);
      setInitSequentialStageItem(massageSequence);
      notification.success({
        message: 'Sequential Stage Updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setUpdateStatus('finished');
      setTimeout(() => {
        setUpdateStatus('');
        setShowButton(false);
        onRefetchList();
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setUpdateStatus('');
      notification.error({
        message: 'Edit Sequential Stage fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const onClickReset = () => {
    setSequentialStageItem(initSequentialStageItem);
    setShowButton(false);
  };

  return isStageListingLoading ? (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {[...Array(3)].map((obj, i) => (
          <div key={i} className={styles.card}>
            <StageCardLoading />
          </div>
        ))}
      </div>
    </div>
  ) : sequentialStageList.length > 0 ? (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable'} type={'SEQUENTIAL_STAGE'}>
          {(provided) => (
            <div className={styles.container}>
              <div className={styles.wrapper} {...provided.droppableProps} ref={provided.innerRef}>
                {sequentialStageItem.map((stageObj, index) => (
                  <Draggable
                    draggableId={`itemSection-${stageObj._id}`}
                    key={`itemSection-${stageObj._id}`}
                    index={index}
                    isDragDisabled={!allowEditStage}
                  >
                    {(provided) => (
                      <div
                        className={styles.card}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <StageCard
                          interventionId={interventionId}
                          stageItem={stageObj}
                          sequence={stageObj.sequence}
                          onRefetchList={onRefetchList}
                          onOpenDeleteModal={onOpenDeleteModal}
                          allowEditStage={allowEditStage}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showButton && (
        <div className={styles.buttonWrapper}>
          <Button status={updateStatus} onClick={onClickUpdate}>
            Update Sequence
          </Button>
          <Button onClick={onClickReset} variant={'secondary'} disabled={updateStatus !== ''}>
            Cancel Update
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div>No sequential stage added yet.</div>
  );
};

export default SequentialStageCard;
