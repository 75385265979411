import { useEffect, useRef, useState } from 'react';
import styles from './InterventionConfigurationActionMenu.module.scss';
import { AttachedClientRecordStatus, Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import StageOptionList from './components/StageOptionList/StageOptionList';
import { PathwayStageItem } from '../../PathwayManagement/interface';
import {
  patchRemoveClient,
  patchStageIntervention,
  patchUpdateStatus
} from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import { notification } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';
import ContactDetails from './components/ContactDetails/ContactDetails';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface InterventionConfigurationActionMenuProps {
  interventionId: string;
  activeStageId: string;
  interventionStatus: AttachedClientRecordStatus;
  interventionStagesConfiguration?: Interventions['stagesConfiguration'];
  clientRecordId: string;
  linkClientProfile?: boolean;
  onRefreshList?: () => void;
}

const InterventionConfigurationActionMenu = ({
  interventionId,
  activeStageId,
  interventionStatus,
  interventionStagesConfiguration,
  clientRecordId,
  linkClientProfile,
  onRefreshList
}: InterventionConfigurationActionMenuProps) => {
  const navigate = useNavigate();
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const { INTERVENTIONS } = useRoutesGenerator();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);
  const [menuExpand, setMenuExpand] = useState(false);
  const [statusSubMenuExpand, setStatusSubMenuExpand] = useState(false);
  const [stageSubMenuExpand, setStageSubMenuExpand] = useState(false);
  const [contactDetailSubMenuExpand, setContactDetailSubMenuExpand] = useState(false);
  const [t] = useTranslation();

  const handleExpandMenu = () => {
    setMenuExpand(!menuExpand);
    if (statusSubMenuExpand) {
      setStatusSubMenuExpand(!statusSubMenuExpand);
    }
    if (stageSubMenuExpand) {
      setStageSubMenuExpand(!stageSubMenuExpand);
    }
    if (contactDetailSubMenuExpand) {
      setContactDetailSubMenuExpand(!contactDetailSubMenuExpand);
    }
  };

  const handleExpandStatusSubMenu = () => {
    if (stageSubMenuExpand) {
      setStageSubMenuExpand(!stageSubMenuExpand);
    }
    if (contactDetailSubMenuExpand) {
      setContactDetailSubMenuExpand(!contactDetailSubMenuExpand);
    }
    setStatusSubMenuExpand(!statusSubMenuExpand);
  };

  const handleExpandStageSubMenu = () => {
    if (statusSubMenuExpand) {
      setStatusSubMenuExpand(!statusSubMenuExpand);
    }
    if (contactDetailSubMenuExpand) {
      setContactDetailSubMenuExpand(!contactDetailSubMenuExpand);
    }
    setStageSubMenuExpand(!stageSubMenuExpand);
  };

  const handleExpandContactDetailSubMenu = () => {
    if (stageSubMenuExpand) {
      setStageSubMenuExpand(!stageSubMenuExpand);
    }
    if (statusSubMenuExpand) {
      setStatusSubMenuExpand(!statusSubMenuExpand);
    }
    setContactDetailSubMenuExpand(!contactDetailSubMenuExpand);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target) || subMenuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
    setStatusSubMenuExpand(false);
    setStageSubMenuExpand(false);
    setContactDetailSubMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleChangeStatus = async (status: AttachedClientRecordStatus) => {
    handleHideAllMenu();
    const payload = { status };
    try {
      await patchUpdateStatus(token, accountId, interventionId, clientRecordId, payload);
      onRefreshList && onRefreshList();
      notification.success({
        message: 'Status successfully changed!',
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Status fail to change',
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleChangeStage = async (stage: PathwayStageItem) => {
    handleHideAllMenu();
    try {
      const payload = {
        stageId: stage._id
      };
      await patchStageIntervention(token, accountId, interventionId, clientRecordId, payload);
      onRefreshList && onRefreshList();
      notification.success({
        message: 'Stage successfully changed!',
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Stage fail to change',
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleRemoveClient = async () => {
    handleHideAllMenu();
    try {
      await patchRemoveClient(token, accountId, interventionId, clientRecordId);
      onRefreshList && onRefreshList();
      notification.success({
        message: t('form.success.remove_client'),
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: t('form.error.remove_client'),
        duration: 3,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleViewDetail = () => {
    handleHideAllMenu();
    navigate(`${INTERVENTIONS.BASE}/${interventionId}/details`);
  };

  const handleHideAllMenu = () => {
    setMenuExpand(false);
    setStatusSubMenuExpand(false);
    setStageSubMenuExpand(false);
    setContactDetailSubMenuExpand(false);
  };

  const getSequentialList = () => {
    let newSequentialStagesList: PathwayStageItem[] = [];
    if (activeStageId && interventionStagesConfiguration) {
      const findCurrentSequentialStage = interventionStagesConfiguration?.sequential.find(
        (stageObj) => stageObj._id === activeStageId
      );

      const activeSequence = findCurrentSequentialStage?.sequence || 0;
      const aboveNumber = activeSequence > 0 ? activeSequence - 1 : 1;
      const belowNumber = activeSequence + 1;

      newSequentialStagesList = interventionStagesConfiguration?.sequential.filter(
        (stageObj) => stageObj.sequence === aboveNumber || stageObj.sequence === belowNumber
      );
    } else {
      newSequentialStagesList =
        interventionStagesConfiguration && interventionStagesConfiguration?.sequential.length > 0
          ? interventionStagesConfiguration?.sequential.filter((stageObj) => stageObj.sequence === 1)
          : [];
    }
    return newSequentialStagesList;
  };
  const massageSequentialStagesList = getSequentialList();
  const massageAnytimeStagesList = interventionStagesConfiguration
    ? interventionStagesConfiguration?.anytime.filter((stageObj) => stageObj._id !== activeStageId)
    : [];

  return (
    <>
      <div ref={menuNode} className={styles.container}>
        <div onClick={handleExpandMenu} className={styles.buttonWrapper}>
          <i className={`material-icons ${styles.vertIcon}`}>more_vert</i>
        </div>
        <div className={styles.menuWrapper}>
          <div className={menuExpand ? styles.menuShow : styles.menuHide}>
            {isEdgeAdminView && (
              <div
                className={statusSubMenuExpand ? styles.itemCardActive : styles.itemCard}
                onClick={handleExpandStatusSubMenu}
              >
                <i className={`material-icons ${styles.itemIcon}`}>task_alt</i>
                <div className={styles.label}>Change Status</div>
                <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
              </div>
            )}
            {(massageSequentialStagesList.length > 0 || massageAnytimeStagesList.length > 0) && (
              <div
                className={stageSubMenuExpand ? styles.itemCardActive : styles.itemCard}
                onClick={handleExpandStageSubMenu}
              >
                <i className={`material-icons-outlined ${styles.itemIcon}`}>leaderboard</i>
                <div className={styles.label}>Change Stage</div>
                <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
              </div>
            )}
            {!linkClientProfile && (
              <div
                className={contactDetailSubMenuExpand ? styles.itemCardActive : styles.itemCard}
                onClick={handleExpandContactDetailSubMenu}
              >
                <i className={`material-icons-outlined ${styles.itemIcon}`}>chat</i>
                <div className={styles.label}>View Contact Info</div>
                <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
              </div>
            )}
            {isEdgeAdminView && (
              <div className={styles.itemCard} onClick={() => handleRemoveClient()}>
                <i className={`material-icons-outlined ${styles.itemIcon}`}>delete</i>
                <div className={styles.label}>{linkClientProfile ? 'Delete Membership' : 'Remove Member'}</div>
              </div>
            )}
            {linkClientProfile && (
              <div className={styles.itemCard} onClick={() => handleViewDetail()}>
                <i className={`material-icons-outlined ${styles.itemIcon}`}>arrow_forward</i>
                <div className={styles.label}>View Intervention Information</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={subMenuNode}>
        <div className={styles.subMenuWrapper}>
          <div className={statusSubMenuExpand ? styles.subMenuShow : styles.subMenuHide}>
            {interventionStatus !== AttachedClientRecordStatus.Active && (
              <div className={styles.itemCard} onClick={() => handleChangeStatus(AttachedClientRecordStatus.Active)}>
                <div className={styles.label}>Active Member</div>
              </div>
            )}
            {interventionStatus !== AttachedClientRecordStatus.Completed && (
              <div className={styles.itemCard} onClick={() => handleChangeStatus(AttachedClientRecordStatus.Completed)}>
                <div className={styles.label}>Mark as Complete</div>
              </div>
            )}
            {interventionStatus !== AttachedClientRecordStatus.Archived && (
              <div className={styles.itemCard} onClick={() => handleChangeStatus(AttachedClientRecordStatus.Archived)}>
                <div className={styles.label}>Archive Member</div>
              </div>
            )}
          </div>
          {(massageSequentialStagesList.length > 0 || massageAnytimeStagesList.length > 0) && (
            <div className={stageSubMenuExpand ? styles.subMenuShow : styles.subMenuHide}>
              <StageOptionList
                sequentialList={massageSequentialStagesList}
                anytimeList={massageAnytimeStagesList}
                onSelectStage={(stageValue) => handleChangeStage(stageValue)}
              />
            </div>
          )}
          {!linkClientProfile && (
            <div className={contactDetailSubMenuExpand ? styles.subMenuShow : styles.subMenuHide}>
              <ContactDetails clientRecordId={clientRecordId} visible={contactDetailSubMenuExpand} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InterventionConfigurationActionMenu;
