import { ChangeEvent } from 'react';
import { useField } from 'formik';

import { Question } from '../../../../../../AssessmentInterfaces';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Input from 'components/Input/Input';

interface NumericProps {
  question: Question;
}

const Numeric = ({ question }: NumericProps) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(`${question.id}.value`);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Input
        name={`${question.id}.value`}
        placeholder="Please share"
        value={value}
        hasError={touched && !!error}
        noSpacing
        onBlur={onBlur}
        onChange={handleChange}
      />
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default Numeric;
