import WidgetItemWrapper from '../WidgetItemWrapper/WidgetItemWrapper';
import LoadingDot from 'components/LoadingDot/LoadingDot';

import styles from './NotesWrapper.module.scss';
import NotesWidget from '../../../../../../../../../components/WidgetComponents/NotesWidget/NotesWidget';
import { useTranslation } from 'react-i18next';

interface NotesWrapperProps {
  isLoading: boolean;
  notesData: any;
}

const NotesWrapper = ({ isLoading, notesData }: NotesWrapperProps) => {
  const [t] = useTranslation();

  return isLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div>
      {notesData.length > 0 ? (
        notesData.map((notesObj: any, index: any) => (
          <WidgetItemWrapper data={notesObj} widgetType={'notesWidget'} key={index}>
            <NotesWidget data={notesObj} />
          </WidgetItemWrapper>
        ))
      ) : (
        <div>{t('label.no_client_notes_data_create_one')}</div>
      )}
    </div>
  );
};

export default NotesWrapper;
