import classnames from 'classnames';

import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  className?: string;
  error?: string;
  visible: boolean;
}

const ErrorMessage = ({ className, error, visible }: ErrorMessageProps) => {
  return visible && error ? <div className={classnames(styles.error, className)}>{error}</div> : null;
};

export default ErrorMessage;
