import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { toCamelCase } from 'utils/generateCamelCase';
import { CaseNote } from 'interfaces/caseNote';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import _ from 'lodash';

import styles from './NumberWrapper.module.scss';

interface NumberWrapperProps {
  field: CaseNote['fields'][number];
  isValidated: boolean;
  className?: string;
  inputClassName?: string;
  isDisabled?: boolean;
  placeholder?: string;
  onChangeFieldInput: (id: string, value: any) => void;
}

const NumberWrapper = ({
  field,
  isValidated,
  className,
  inputClassName,
  isDisabled,
  placeholder,
  onChangeFieldInput
}: NumberWrapperProps) => {
  const [numberFieldData, setNumberFieldData] = useState(field as CaseNote['fields'][number]);

  const errorString =
    field.type === 'number' && !numberFieldData.value?.match('^[0-9]*$')
      ? `${numberFieldData.name.toLocaleLowerCase()} is not a number`
      : field.value && field.value.toString().length > 0
      ? ''
      : `Please fill in ${numberFieldData.name.toLocaleLowerCase()}`;

  useEffect(() => {
    setNumberFieldData(field);
  }, [field]);

  return (
    <div className={classnames(styles.inputContainer, styles.headingField, className)}>
      <MaterialInput
        className={classnames(styles.inputField, inputClassName)}
        type={numberFieldData.type}
        id={toCamelCase(numberFieldData.name)}
        name={numberFieldData.name}
        label={`${numberFieldData.name} ${numberFieldData.mandatory ? '*' : ''}`}
        value={numberFieldData.value || ''}
        onChange={(e) => onChangeFieldInput(numberFieldData._id, e.target.value)}
        placeholder={placeholder}
        disabled={isDisabled}
        required
      />
      <ErrorMessage
        className={styles.error}
        error={errorString}
        visible={
          isValidated &&
          ((numberFieldData.mandatory && !numberFieldData.value) ||
            (!_.isEmpty(numberFieldData.value) && !numberFieldData.value?.match('^[0-9]*$')))
        }
      />
    </div>
  );
};

export default NumberWrapper;
