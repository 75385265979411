import { useEffect, useState, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './AssessmentQuestionSection.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionCategoryMenu from './components/QuestionCategoryMenu/QuestionCategoryMenu';
import QuestionList from './components/QuestionList/QuestionList';

interface AssessmentQuestionSectionProps {
  questionList: any;
  onChange: any;
  onChangeQuestionList: any;
  onTriggerSave: any;
  qTemplateData?: any;
  validation: boolean;
  disabled?: boolean;
}

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list as any);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AssessmentQuestionSection = ({
  questionList,
  onChange,
  onChangeQuestionList,
  onTriggerSave,
  qTemplateData,
  validation,
  disabled
}: AssessmentQuestionSectionProps) => {
  const [items, setItems] = useState(questionList);
  const [questionTemplate, setQuestionTemplate] = useState(qTemplateData);

  useEffect(() => {
    setItems(questionList);
  }, [questionList]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.type === 'QUESTIONS') {
      const questions = reorder(items as any, result.source.index, result.destination.index);
      setItems(questions as any[]);
      onChange(questions);
    } else {
      const answers = reorder(
        items[parseInt(result.type, 10)].questions as any,
        result.source.index,
        result.destination.index
      );
      const questions = JSON.parse(JSON.stringify(items));
      questions[result.type].questions = answers;
      setItems(questions);
      onChange(questions);
    }
  };

  const onAddNewQuestion = (id: string, val: any) => {
    const addNewQuestion = questionList
      .filter((x: any) => x.id === id)
      .map((x: any) => {
        const addNewQ = [];
        for (let obj of x.questions) {
          addNewQ.push({
            ...obj,
            ...(obj.id === val.id && val),
            isDraftQ: obj.id === val.id ? false : obj.isDraftQ
          });
        }
        return (x.questions = addNewQ);
      });

    let newQList = [];
    for (let data of questionList) {
      newQList.push({
        ...data,
        questions: data.id === id ? addNewQuestion[0] : data.questions
      });
    }

    onChangeQuestionList(newQList);
    setItems(newQList);
  };

  const onChangeNewQuestion = (qGroupId: string, val: any) => {
    questionList
      .filter((x: any) => x.id === qGroupId)
      .map((x: any) => {
        const checkIdExist = x.questions.filter((a: any) => a.id === val.id);
        if (checkIdExist.length > 0) {
          const onChangeNewQ = [];
          for (let obj of x.questions) {
            onChangeNewQ.push(obj.id === val.id ? val : obj);
          }
          return (x.questions = [...onChangeNewQ]);
        } else {
          return (x.questions = [
            ...x.questions,
            {
              ...val,
              id: val.id,
              stem: val.stem,
              isChecked: val.isChecked,
              refKey: val.refKey,
              isDraftQ: true,
              questionId: uuid()
            }
          ]);
        }
      });
    onChangeQuestionList(questionList);
  };

  const checkQuestion = (list: any, value: any) => {
    let newQList = [];
    for (let data of list) {
      newQList.push({
        ...data,
        isChecked: data.id === value.id ? value.isChecked : data.isChecked
      });
    }

    return newQList;
  };

  const onChangeCheckBox = (groupId: string, value: any) => {
    let newQuestionList = [];
    for (let data of items) {
      newQuestionList.push({
        ...data,
        questions: data.id === groupId ? checkQuestion(data.questions, value) : data.questions
      });
    }
    setItems(newQuestionList);
    onChange(newQuestionList);
  };

  const onRemoveItem = (id: string) => {
    const newList = items.filter((q: any) => q.id !== id);
    setItems(newList);
    onChange(newList);
  };

  const onSelectQuestionSet = (qSId: string, qSName: string) => {
    const getTemplateList = questionTemplate.find((obj: any) => obj.sectionName === qSName);

    let updateQuestionList = [];
    for (let data of items) {
      updateQuestionList.push({
        id: data.id,
        sectionName: data.id === qSId ? getTemplateList.sectionName : data.sectionName,
        refKey: data.id === qSId ? getTemplateList.refKey : data.refKey,
        questions: data.id === qSId ? getTemplateList.questions : data.questions
      });
    }

    setItems(updateQuestionList);
    onChange(updateQuestionList);
  };

  const onAddNewTopic = (val: string) => {
    let newTemplateQuestion = [];
    for (let data of questionTemplate) {
      newTemplateQuestion.push({
        ...data
      });
    }

    newTemplateQuestion.push({
      sectionName: val,
      askFrequency: '5',
      questions: [],
      refKey: 'custom'
    });
    setQuestionTemplate(newTemplateQuestion);
  };

  const onDeleteQuestion = (groupId: string, questionId: string) => {
    const newQList = questionList.map((questionSet: any) =>
      questionSet.id !== groupId
        ? questionSet
        : {
            ...questionSet,
            questions: questionSet.questions.filter((question: any) => question.id !== questionId)
          }
    );
    onChangeQuestionList(newQList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'droppable'} type={'QUESTIONS'}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items?.map((item: any, index: any) => (
              <div key={index}>
                <Draggable draggableId={`section-${item.id}`} key={`section-${item.id}`} index={index}>
                  {(provided) => (
                    <Fragment>
                      <div
                        className={
                          !validation && item.sectionName === '' ? styles.questionBoxWithError : styles.questionBox
                        }
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <div className={styles.header}>
                          <QuestionCategoryMenu
                            value={item.sectionName}
                            data={questionTemplate}
                            id={item.id}
                            selectedData={items}
                            onSelectQuestionSet={onSelectQuestionSet}
                            onAddTopic={onAddNewTopic}
                            disabled={disabled}
                          />
                          {!disabled && (
                            <div className={styles.actionsWrapper}>
                              <div className={styles.removeQuestionBtn} onClick={() => onRemoveItem(item.id)}>
                                Remove
                              </div>
                              <div className={styles.upDownWrapper} {...provided.dragHandleProps}>
                                <i className={`material-icons ${styles.upDownBtn}`}>import_export</i>
                              </div>
                            </div>
                          )}
                        </div>
                        <QuestionList
                          questionSet={item}
                          questionNum={index}
                          onAddQuestion={onAddNewQuestion}
                          onChangeCheckBox={onChangeCheckBox}
                          onChangeQuestion={onChangeNewQuestion}
                          onClickSave={onTriggerSave}
                          disabled={disabled}
                          onDeleteQuestion={onDeleteQuestion}
                        />
                        <div className={styles.buttonWrapper}>
                          {item.sectionName !== '' ? (
                            <div className={styles.moreQuestionBtn}>
                              <i className={`material-icons ${styles.arrowIcon}`}>arrow_drop_down</i>
                              More
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Draggable>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default AssessmentQuestionSection;
