import { HTMLAttributes } from 'react';
import styles from './AddNote.module.scss';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import classnames from 'classnames';
import { Input, Button, DatePicker } from 'antd';
import ReactTooltip from 'react-tooltip';
import Radio from 'components/Radio/Radio';
import { getSendInviteOptions } from 'pages/Assessments/components/SendAdhocAssessmentModal/constants';
import SubmitButton from 'components/v2/Button/Button';
import moment from 'moment';
import { GroupConsentForm, GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import ClientOrGroupInfo from './ClientOrGroupSelected/ClientOrGroupInfo';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { PatientAssessment } from 'pages/Assessments/components/SendAdhocAssessmentModal/interfaces';
import { FirstAssessmentResponse } from 'interfaces/checkInService/firstAssessment';
import { useTranslation } from 'react-i18next';

interface AddNoteProps {
  participationType: ParticipantType;
  patientAssessments?: PatientAssessment[];
  selectedAssessments?: ClinicalAssessmentStruct[];
  selectedGroup?: GroupsFromAPI;
  selectedSurveyForms?: FirstAssessmentResponse[];
  selectedConsentForm?: GroupConsentForm;
  customSendAt: string;
  sendAt: string;
  submitStatus: '' | 'active' | 'finished';
  onCustomSendAtChange: (value: string) => void;
  onNoteChanged: (value: string) => void;
  onSendAtChange: (value: string) => void;
  onBackClick: () => void;
  onSubmit: () => void;
}

const AddNote = ({
  patientAssessments,
  selectedAssessments,
  participationType,
  selectedGroup,
  selectedSurveyForms,
  selectedConsentForm,
  customSendAt,
  sendAt,
  className,
  submitStatus,
  onBackClick,
  onCustomSendAtChange,
  onNoteChanged,
  onSendAtChange,
  onSubmit
}: AddNoteProps & HTMLAttributes<HTMLDivElement>) => {
  const selected = selectedAssessments
    ? selectedAssessments.map((item) => item.shortCode)
    : selectedSurveyForms
    ? selectedSurveyForms.map((item) => item.name)
    : [selectedConsentForm ? selectedConsentForm.name : ''];

  const [t] = useTranslation();

  return (
    <div className={classnames(styles.container, className)}>
      <Button disabled={submitStatus !== ''} type="text" className={styles.backBtn} onClick={() => onBackClick()}>
        <i className={`material-icons ${styles.icon}`}>arrow_back_ios_new</i>
        Back
      </Button>
      <ClientOrGroupInfo
        patientAssessments={patientAssessments}
        group={selectedGroup}
        selected={selected}
        participationType={participationType}
      />
      <div className={styles.sendInviteContainer}>
        <div className={styles.sendInviteTitle}>
          {t('label.send_invite')}
          <i className={`material-icons ${styles.icon}`} data-tip data-for="send-invite">
            help_outline
          </i>
          <ReactTooltip id="send-invite" className={styles.tooltip}>
            {t('label.send_invite.tooltip')}
          </ReactTooltip>
        </div>
        <Radio
          name="sendAt"
          options={getSendInviteOptions()}
          value={sendAt}
          onChange={(e) => onSendAtChange(e.target.value)}
          vertical
        />
        {sendAt === 'custom' && (
          <DatePicker
            className="custom-schedule-date-picker"
            disabledDate={(current) => current && current < moment().startOf('day')}
            suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
            showTime={{ format: 'hh:mm A', minuteStep: 15 }}
            value={customSendAt ? moment(customSendAt, 'YYYY-MM-DD HH:mm') : undefined}
            allowClear={false}
            onChange={(moment) => onCustomSendAtChange(moment?.format('YYYY-MM-DD HH:mm') || '')}
          />
        )}
      </div>

      <div className={styles.addNoteTitle}>{t('label.add_note')}</div>

      <div className={styles.addNoteEditor}>
        <Input.TextArea
          className={styles.addNoteTextArea}
          placeholder={t('label.add_note.placeholder')}
          rows={5}
          onChange={(e) => onNoteChanged(e.target.value)}
        />
      </div>
      <div className={styles.submitBtn}>
        <SubmitButton status={submitStatus} onClick={onSubmit}>
          Send Assessment
        </SubmitButton>
      </div>
    </div>
  );
};

export default AddNote;
