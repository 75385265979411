/* eslint-disable react/no-multi-comp */
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import classnames from 'classnames';
import Header from 'components/layout/Header/Header';
import Loading from 'components/Loading/Loading';
import SideDashboard from 'components/SideDashboard/SideDashboard';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { UserContextProvider } from 'utils/UserContextProvider';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import NoAuthLayout from '../NoAuthLayout/NoAuthLayout';

import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
  children?: any;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { user } = useAuth0();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeAdminView, isEdgeUserView, isEdgeReceptionist, isMentorUser } = useGetAccountPackageView();
  const { isEnableStonlyGuide } = useGetFeatureToggle();
  const [showSideBar, setShowSideBar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.['https://tacklit.com/roles'].includes('patient') && process.env.REACT_APP_CLIENT_DOMAIN) {
      window.location.href = process.env.REACT_APP_CLIENT_DOMAIN;
    } else if (!user?.email_verified) {
      navigate('/unverified');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isEnableStonlyGuide) {
      const stonlyScript = document.createElement('script');

      stonlyScript.type = 'text/javascript';
      stonlyScript.innerHTML = `var STONLY_WID = 'd877dd68-99ae-11ec-9fb8-0ae9fa2a18a2';
        !(function (s, t, o, n, l, y, w, g) {
            s.StonlyWidget ||
               (((w = s.StonlyWidget = function () {
                  w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
               }).scriptPath = n),
               (w.queue = []),
               ((y = t.createElement(o)).async = !0),
               (g = new XMLHttpRequest()).open('GET', n + 'version?v=' + Date.now(), !0),
               (g.onreadystatechange = function () {
                  4 === g.readyState &&
                    ((y.src = n + 'stonly-widget.js?v=' + (200 === g.status ? g.responseText : Date.now())),
                    (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
                }),
              g.send());
          })(window, document, 'script', 'https://stonly.com/js/widget/v2/')`;

      document.body.appendChild(stonlyScript);
    }
  }, [isEnableStonlyGuide]);

  const onShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  const backgroundColor = isEdgeAdminView ? styles.edgeSUBackground : styles.defaultBackground;

  return (
    <UserContextProvider>
      <div className={isMentorUser ? styles.mentorContainer : styles.container}>
        <Header showSideBar={onShowSideBar} />
        {!isMentorUser && (
          <aside
            className={classnames(
              showSideBar ? styles.sideDashboardActive : styles.sideDashboardWrapper,
              backgroundColor
            )}
          >
            <SideDashboard
              showSideBar={onShowSideBar}
              isEdgeReceptionist={isEdgeReceptionist}
              isEdgeAdminView={isEdgeAdminView}
              isEdgeUserView={isEdgeUserView}
              isEdgePlanUser={isEdgePlanUser}
            />
          </aside>
        )}
        <main className={isMentorUser ? styles.teacherContentWrapper : styles.contentWrapper}>
          <Outlet />
        </main>
      </div>
    </UserContextProvider>
  );
};

export default withAuthenticationRequired(AuthLayout, {
  onRedirecting: () => (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  )
});
