import { useState } from 'react';

import { useGetAccessToken } from 'utils/hooks/token';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import AssessmentContent from './components/AssessmentContent/AssessmentContent';
import AssessmentHeader from './components/AssessmentHeader/AssessmentHeader';
import { useFetchFormList } from './hooks/GetFormData';
import styles from './Assessments.module.scss';
import CORDSSendAssessmentModal from './components/CORDSSendAssessmentModal/CORDSSendAssessmentModal';

const Assessments = () => {
  const { token } = useGetAccessToken();
  const [sendAdhocAssessmentVisible, setSendAdhocAssessmentVisible] = useState(false);

  const { formListData, isFormListDataLoading } = useFetchFormList(token);

  return (
    <HelmetWrapper title={'CORDS - Forms'}>
      <ContentLayout>
        {isFormListDataLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <div className={styles.container}>
            <AssessmentHeader
              onSendAdhocAssessmentClick={() => setSendAdhocAssessmentVisible(!sendAdhocAssessmentVisible)}
            />
            <AssessmentContent formListData={formListData} />
            <CORDSSendAssessmentModal
              visible={sendAdhocAssessmentVisible}
              onClose={() => setSendAdhocAssessmentVisible(false)}
            />
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Assessments;
