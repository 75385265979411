import { useState, useEffect, useCallback } from 'react';
import styles from './GroupCaseNoteDetail.module.scss';
import Button from 'components/v2/Button/Button';
import { CaseNoteFormatSettings, GroupCaseNoteInterface } from '../../interfaces';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Modal, notification } from 'antd';
import CaseNoteDetailForm from '../CaseNoteDetailForm/CaseNoteDetailForm';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import CaseNoteDetailDisplay from '../CaseNoteDetailDisplay/CaseNoteDetailDisplay';
import { HeadingTemplate } from '../GroupCaseNoteHeadingTemplateList/GroupCaseNoteHeadingTemplateList';
import { downloadCaseNote, reconstructCaseNote } from '../../utils';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import {
  deleteGroupCaseNote,
  postGroupCaseNote,
  putGroupCaseNote
} from 'utils/http/DocumentService/CaseNotes/groupCaseNotes';
import { downloadGroupCaseNoteWithAttachments } from 'utils/http/UtilityService/downloadCaseNote';
import { safeGuardingName } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteFormatSettingsModal/PatientDetailsNoteFormatSettingsModal';

const IS_DEVELOPMENT = isDevelopmentENV();

interface GroupCaseNoteDetailProps {
  groupId: string;
  caseNoteData: GroupCaseNoteInterface;
  caseNoteHeadingTemplates: HeadingTemplate[];
  token: string;
  isEditing: boolean;
  isReadOnly: boolean;
  isClosedClient?: boolean;
  onDeleteNote: () => void;
  onValueChange: (b: boolean) => void;
  onDeleteTemplate: (headingTemplateId: string) => void;
  onEditNoteClick: () => void;
  onAddTemplate: (newHeadingTemplate: HeadingTemplate) => void;
  onUpdateNoteDetails: (newNote: GroupCaseNoteInterface, isDraftSave: boolean) => void;
  formatSettings: CaseNoteFormatSettings[];
}

const GroupCaseNoteDetail = ({
  groupId,
  caseNoteData,
  caseNoteHeadingTemplates,
  token,
  isEditing,
  isReadOnly,
  isClosedClient,
  onValueChange,
  onDeleteNote,
  onDeleteTemplate,
  onEditNoteClick,
  onAddTemplate,
  onUpdateNoteDetails,
  formatSettings
}: GroupCaseNoteDetailProps) => {
  const navigate = useNavigate();
  const { GROUPS } = useRoutesGenerator();
  const path = useParams() as { contentId: string };

  const [isDeleting, setIsDeleting] = useState(false);
  const [caseNoteDetails, setCaseNoteDetails] = useState(caseNoteData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [caseNoteId, setCaseNoteId] = useState(path.contentId);
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseNoteNode, setCaseNoteNode] = useState<Node | null>(null);
  const [isCaseNoteDownloading, setIsCaseNoteDownloading] = useState<boolean>(false);

  const caseNoteRef = useCallback((renderedNode) => {
    if (renderedNode) {
      setCaseNoteNode(renderedNode);
    }
  }, []);

  const checkingSafeGuarding = formatSettings.map((formatSettingsObj) =>
    formatSettingsObj.fields.find((fieldObj) => fieldObj.name === safeGuardingName)
  );
  const safeguardingAlertExistInFormatSettings = checkingSafeGuarding.length > 0;

  useEffect(() => {
    setCaseNoteDetails(caseNoteData);
    setCaseNoteId(caseNoteData._id);
  }, [caseNoteData, caseNoteId]);

  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteGroupCaseNote(token, groupId, caseNoteId);
      onDeleteNote();
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to delete this case note' });
    }
    setIsDeleting(false);
  };

  const onDownloadNoteClick = async (fileName: string, caseNoteNode: Node | null) => {
    if (caseNoteNode) {
      setIsCaseNoteDownloading(true);

      try {
        const htmlNode = reconstructCaseNote(caseNoteNode);
        const retrievedPDF = await downloadGroupCaseNoteWithAttachments(token, groupId, caseNoteId, htmlNode);
        downloadCaseNote(retrievedPDF, fileName);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to download your note' });
      }
      setIsCaseNoteDownloading(false);
    }
  };

  const onClickDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this note?',
      onOk: onDelete
    });
  };

  let isRunning = false;
  const handleSaveFunc = async (values: GroupCaseNoteInterface, triggerBtnSave: boolean) => {
    if (!isRunning) {
      isRunning = true;
      setIsSubmitting(true);
      triggerBtnSave && setSaveButtonStatus('active');

      const bodyPayload = {
        title: values.title,
        fields: values.fields,
        body: values.body,
        attachments: values.attachments,
        safeguardingAlert: values.safeguardingAlert,
        safeguardingReason: values.safeguardingReason
      } as GroupCaseNoteInterface;

      if (caseNoteId === 'new') {
        try {
          const callPostGroupCaseNote = await postGroupCaseNote(token, groupId, bodyPayload);
          const createdGroupCaseNote = await callPostGroupCaseNote.json();

          setCaseNoteId(createdGroupCaseNote._id);
          setCaseNoteDetails(createdGroupCaseNote);
          onUpdateNoteDetails(createdGroupCaseNote, !triggerBtnSave);
          setIsSubmitting(false);
          onValueChange(false);
          if (triggerBtnSave) {
            setSaveButtonStatus('finished');
            setTimeout(() => setSaveButtonStatus(''), 2000);
          } else {
            navigate(`${GROUPS.BASE}/${groupId}/notes/${createdGroupCaseNote._id}`);
          }
        } catch (ex) {
          triggerBtnSave && setSaveButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to save your note.' });
        }
      } else {
        try {
          await putGroupCaseNote(token, groupId, caseNoteId, bodyPayload);
          const massageValueWithUpdatedTime = {
            ...values,
            _id: caseNoteId,
            updatedAt: moment().format()
          };
          onUpdateNoteDetails(massageValueWithUpdatedTime, !triggerBtnSave);
          setIsSubmitting(false);
          onValueChange(false);
          if (triggerBtnSave) {
            setSaveButtonStatus('finished');
            setTimeout(() => setSaveButtonStatus(''), 2000);
          }
        } catch (ex) {
          console.error(ex);
          triggerBtnSave && setSaveButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to update your note.' });
        }
      }

      isRunning = false;
    }
  };

  const handleSubmit = async (values: GroupCaseNoteInterface, draftSave: boolean) => {
    if (!isSubmitting) {
      await handleSaveFunc(values, !draftSave);
    }
  };

  return (
    <div className={styles.container}>
      {isDeleting && (
        <div className={styles.deleting}>
          <LoadingDot />
        </div>
      )}

      {/* Header */}
      <div className={styles.header}>
        <div className={styles.createdAt}>
          <div className={styles.label}>CREATED:</div>
          <div className={styles.value}>{moment(caseNoteDetails.createdAt).format('DD/MM/YYYY hh:mm A')}</div>
        </div>
        {caseNoteDetails.updatedAt !== caseNoteDetails.createdAt && caseNoteId !== 'new' && (
          <div className={styles.updatedAt}>
            <div className={styles.label}>LAST EDIT:</div>
            <div className={styles.value}>{moment(caseNoteDetails.updatedAt).format('DD/MM/YYYY hh:mm A')}</div>
          </div>
        )}
        {isEditing && caseNoteId !== 'new' && (
          <Button className={styles.deleteNoteButton} onClick={onClickDelete}>
            DELETE NOTE
          </Button>
        )}
      </div>
      {isEditing ? (
        <CaseNoteDetailForm
          groupId={groupId}
          noteId={caseNoteId}
          isSubmitting={isSubmitting}
          formData={caseNoteDetails}
          saveButtonStatus={saveButtonStatus}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
          caseNoteHeadingTemplates={caseNoteHeadingTemplates}
          token={token}
          onDeleteTemplate={onDeleteTemplate}
          onAddTemplate={onAddTemplate}
          safeguardingAlertExistInFormatSettings={safeguardingAlertExistInFormatSettings}
        />
      ) : (
        <div className={styles.noteDetails}>
          <div ref={caseNoteRef}>
            <CaseNoteDetailDisplay
              token={token}
              groupId={groupId}
              baseLink={GROUPS.BASE}
              caseNoteDetail={caseNoteDetails}
              displaySafeguardingAlert={Boolean(
                (safeguardingAlertExistInFormatSettings &&
                  caseNoteDetails.fields.find((field) => field.name === 'Safeguarding Alert')) ||
                  !safeguardingAlertExistInFormatSettings
              )}
            />
          </div>
          <div className={styles.buttonContainer}>
            {!isReadOnly && (
              <Button
                className={styles.editButton}
                variant="secondary"
                onClick={() => isClosedClient || onEditNoteClick()}
                disabled={isClosedClient}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>create</i>
                Edit this note
              </Button>
            )}
            <div className={styles.rightButtons}>
              <Button
                className={styles.downloadButton}
                activeClassName={styles.downloadActive}
                variant="secondary"
                disabled={isCaseNoteDownloading}
                onClick={() => {
                  onDownloadNoteClick(caseNoteDetails.title, caseNoteNode);
                }}
              >
                <i className={`material-icons-outlined ${styles.icon}`}>download</i>
                {isCaseNoteDownloading ? 'Downloading...' : 'Download Note'}
              </Button>
              {IS_DEVELOPMENT && (
                <Button className={styles.shareButton} variant="primary" disabled={isClosedClient}>
                  Share Case Note
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupCaseNoteDetail;
