import styles from './SurveyFormDetails.module.scss';
import SurveyFormDetailHeader from './compoenents/SurveyFormDetailHeader/SurveyFormDetailHeader';
import { useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { GroupSurveyDetailsParam } from 'pages/Groups/Interfaces/GroupsSurvey';
import { useFetchSurveyFormDetail } from './hooks/getSurveyFormDetail';
import { useGetAccessToken } from 'utils/hooks/token';
import { Skeleton } from 'antd';
import PatientDetailsSurveysContent from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsSurveys/components/PatientDetailsSurveysContent/PatientDetailsSurveysContent';
import { useFetchClientDetails } from '../../Psychometrics/hooks/getClientDetails';

interface SurveyFormDetailsProps {
  surveyId?: string;
}

const SurveyFormDetails = ({ surveyId }: SurveyFormDetailsProps) => {
  const { token } = useGetAccessToken();
  const { GROUPS } = useRoutesGenerator();
  const path = useParams<{ [key in keyof GroupSurveyDetailsParam]: string | undefined }>();
  const groupId = path.groupId ?? '';
  const surveyResponseId = path.surveyResponseId ?? '';
  const clientRecordId = path.clientRecordId ?? '';

  const { clientEncryptDetails } = useFetchClientDetails(token, clientRecordId);
  const { surveyFormDetails } = useFetchSurveyFormDetail(token, clientRecordId, surveyResponseId);

  const selectedProfile = clientEncryptDetails?.clientProfiles.filter(
    (clientP) => clientP._id === surveyFormDetails?.clientProfileId
  );

  return !selectedProfile || !surveyFormDetails ? (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton.Input active className={styles.headerLoading} />
      </div>
      <div>
        <Skeleton active />
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <SurveyFormDetailHeader
        goBackLink={`${GROUPS.BASE}/${groupId}/${GroupDetailsTab.Survey}/${surveyId}`}
        clientProfiles={selectedProfile}
        title={surveyFormDetails.name}
      />
      <PatientDetailsSurveysContent survey={surveyFormDetails} />
    </div>
  );
};

export default SurveyFormDetails;
