import { useCallback, useEffect, useState } from 'react';
import { Interventions, InterventionsFromAPI, InterventionTag } from 'MHH/pages/Interventions/Interfaces/Interventions';
import {
  getInterventionDetailsByInterventionId,
  getInterventionTags
} from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import { PathwayStageItem, StageType } from '../components/InterventionContent/components/PathwayManagement/interface';

export const useFetchInterventionDetails = (token: string, interventionId: string, accountId: string) => {
  const [interventionDetails, setInterventionDetails] = useState<Interventions>();
  const [interventionTagList, setInterventionTagList] = useState<InterventionTag[]>([]);
  const [isInterventionDetailsLoading, setIsInterventionDetailsLoading] = useState(true);

  const fetchInterventionDetails = useCallback(
    async (token: string) => {
      setIsInterventionDetailsLoading(true);

      const getInterventionData = await getInterventionDetailsByInterventionId(token, interventionId);
      const interventionDetailData: InterventionsFromAPI = await getInterventionData.json();

      const getInterventionTagsData = await getInterventionTags(token, accountId);
      const interventionTagsData: InterventionTag[] = await getInterventionTagsData.json();

      setInterventionTagList(interventionTagsData);

      const massageSequentialStage = (stageData: PathwayStageItem[]) => {
        return stageData.map((stageObj, index) => ({
          ...stageObj,
          sequence: index + 1,
          stageType: StageType.Sequential
        }));
      };

      const MASSAGE_DETAILS: Interventions = {
        ...interventionDetailData,
        leadClinicianProfile: {
          avatar:
            interventionDetailData.leadClinicianProfile.avatar || interventionDetailData.leadClinicianProfile.logo,
          title: interventionDetailData.leadClinicianProfile.title || '',
          name: interventionDetailData.leadClinicianProfile.name,
          mobileNumber: interventionDetailData.leadClinicianProfile.mobileNumber || '',
          email: interventionDetailData.leadClinicianProfile.email || ''
        },
        stagesConfiguration: {
          anytime: interventionDetailData.stagesConfiguration?.anytime || [],
          sequential: interventionDetailData.stagesConfiguration?.sequential
            ? massageSequentialStage(interventionDetailData.stagesConfiguration.sequential)
            : []
        },
        tags: interventionDetailData.tags
          .map((tagsObj) => interventionTagsData.find(({ _id }) => _id === tagsObj))
          .filter((val): val is InterventionTag => val !== undefined)
      };

      setInterventionDetails(MASSAGE_DETAILS);
      setIsInterventionDetailsLoading(false);
    },
    [interventionId, accountId]
  );

  useEffect(() => {
    if (token) {
      fetchInterventionDetails(token);
    }
  }, [fetchInterventionDetails, token]);

  return { interventionDetails, isInterventionDetailsLoading, interventionTagList, fetchInterventionDetails };
};
