import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './InterventionDetails.module.scss';
import { useParams } from 'react-router-dom';
import { InterventionDetailsId, InterventionDetailsTab } from 'MHH/pages/Interventions/Interfaces/Interventions';
import InterventionSideMenu from './components/InterventionSideMenu/InterventionSideMenu';
import InterventionDetailsHeader from './components/InterventionDetailsHeader/InterventionDetailsHeader';
import { useFetchInterventionDetails } from './hooks/getInterventionDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import InterventionContent from './components/InterventionContent/InterventionContent';
import { Skeleton } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const InterventionDetails = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const path = useParams() as unknown as InterventionDetailsId;
  const interventionId = path.interventionId ?? '';
  const interventionTab = path.interventionTab ?? InterventionDetailsTab.Details;
  const contentId = path.contentId ?? '';
  const childContentId = path.childContentId ?? '';

  const { interventionDetails, interventionTagList, isInterventionDetailsLoading, fetchInterventionDetails } =
    useFetchInterventionDetails(token, interventionId, accountId);
  const { profile, isProfileLoading } = useFetchProfile();

  const allowEditAssigned = isEdgeAdminView || auth0ClinicianId === interventionDetails?.leadClinicianAuth0Id;

  return (
    <HelmetWrapper title={'CORDS - Intervention Details'}>
      <ContentLayout>
        <div className={styles.container}>
          <InterventionDetailsHeader
            interventionDetails={interventionDetails}
            isInterventionDetailsLoading={isInterventionDetailsLoading}
            currentInterventionTab={interventionTab}
          />
          {isProfileLoading || !interventionDetails ? (
            <div className={styles.loadingWrapper}>
              <div className={styles.sideBarLoading}>
                {[...Array(3)].map((obj, i) => (
                  <div key={i} className={styles.loadingCard}>
                    <Skeleton.Avatar active />
                    <div className={styles.loadingDescWrapper}>
                      <Skeleton.Input active className={styles.loadingTitle} />
                      <Skeleton.Input active className={styles.loadingDesc} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.contentLoading}>
                <Skeleton active />
              </div>
            </div>
          ) : (
            <div className={styles.content}>
              <InterventionSideMenu
                interventionId={interventionId}
                activeInterventionTab={interventionTab}
                contentId={contentId}
                childContentId={childContentId}
                allowEditAssigned={allowEditAssigned}
              />
              <InterventionContent
                interventionDetails={interventionDetails}
                isInterventionDetailsLoading={isInterventionDetailsLoading}
                interventionTagList={interventionTagList}
                activeInterventionTab={interventionTab}
                fetchInterventionDetails={fetchInterventionDetails}
                profile={profile}
                allowEditAssigned={allowEditAssigned}
                isEdgeReceptionist={isEdgeReceptionist}
              />
            </div>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InterventionDetails;
