import { Skeleton } from 'antd';
import classnames from 'classnames';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useEffect, useRef, useState, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { getName } from 'utils/general';
import { useFetchClientRecordList } from 'utils/hooks/GetClient/clientList';

import { useGetAccessToken } from '../../../utils/hooks/token';
import styles from './ClientSelect.module.scss';

interface ClientSelectProps {
  onSelect: (clientId: string, b?: boolean) => void;
  onRemove?: () => void;
  selectedId?: string;
  backgroundColor?: string;
  materialStyle?: boolean;
  hideAvatar?: boolean;
  allowEmpty?: boolean;
  confirmChangeMessage?: string;
  className?: string;
}

const ClientSelect: FC<ClientSelectProps> = ({
  onSelect,
  onRemove,
  selectedId,
  backgroundColor,
  materialStyle,
  hideAvatar,
  allowEmpty,
  confirmChangeMessage,
  className
}) => {
  const { token } = useGetAccessToken();
  const node = useRef<HTMLDivElement>(null);
  const { clientList, isClientListLoading } = useFetchClientRecordList(token, false, true);
  const initValue = {
    _id: '',
    clientProfiles: [{ firstName: '', lastName: '', name: '', avatar: '' }]
  } as clientRecordsInterface;

  const [selectedClient, setSelectedClient] = useState(initValue);
  const [showList, setShowList] = useState(false);
  const [clients, setClients] = useState(clientList);

  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [tempClientId, setTempClientId] = useState('');
  const [t] = useTranslation();

  useEffect(() => {
    if (!isClientListLoading) {
      if (allowEmpty && selectedId === undefined) {
        setSelectedClient(initValue);
      } else {
        const data = clientList.find((client) => client._id === (selectedId || ''));
        setSelectedClient(data ? data : initValue);
      }
      setClients(clientList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientListLoading, clientList, selectedId, allowEmpty]);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSelectUser = (id?: string) => {
    const data = clients.find((profile) => profile._id === id);
    if (data) {
      if (selectedId && data._id !== selectedId && confirmChangeMessage) {
        setTempClientId(data._id);
        setShowConfirmationBox(true);
      } else {
        setSelectedClient(data);
        onSelect(data._id);
      }
    } else {
      setSelectedClient(initValue);
    }
    setShowList(false);
  };

  const handleRemoveUser = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedClient(initValue);
    setShowList(false);
    if (onRemove) {
      onRemove();
    }
  };

  const handleChangeSearch = (value: string) => {
    const filterData = clientList.filter(({ clientProfiles }) =>
      getName(clientProfiles[0]).toLowerCase().includes(value.toLowerCase())
    );
    setClients(filterData);
  };

  const handleConfirmChangeClient = () => {
    onSelect(tempClientId, true);
    setTempClientId('');
    setShowConfirmationBox(false);
  };

  const handleCancelChangeClient = () => {
    setTempClientId('');
    setShowConfirmationBox(false);
  };

  return (
    <div ref={node} className={classnames(styles.container, className)}>
      {isClientListLoading ? (
        <div className={classnames(materialStyle ? styles.materialLoadingBox : styles.loadingBox)}>
          <Skeleton.Input active className={styles.skeletonLoading} />
        </div>
      ) : (
        <>
          <div
            className={materialStyle ? styles.selectedMaterialBox : styles.selectedBox}
            onClick={() => !(allowEmpty && selectedClient._id) && setShowList(!showList)}
            style={{ backgroundColor }}
          >
            {hideAvatar ? (
              <div>{getName(selectedClient.clientProfiles[0])}</div>
            ) : (
              <ClientAvatar
                name={getName(selectedClient.clientProfiles[0])}
                clientData={selectedClient.clientProfiles}
                initialsName={selectedClient.clientProfiles[0]?.initials || ''}
                avatarUrl={selectedClient.clientProfiles[0]?.avatar}
                avatarSize={36}
                initialsClassName={styles.initialName}
                nameClassName={styles.clientName}
                horizontal
                displayFirstNameOnly={selectedClient.recordType === 'couple'}
                displayLimit={selectedClient.recordType === 'couple' ? 2 : 1}
              />
            )}
            {selectedClient._id && allowEmpty ? (
              <span onClick={(e) => handleRemoveUser(e)} className={`material-icons-outlined ${styles.closeIcon}`}>
                close
              </span>
            ) : (
              <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
            )}
          </div>
          <div className={styles.listWrapper}>
            <div className={showList ? styles.listShow : styles.listHide}>
              <div className={styles.searchBox}>
                <span className={`material-icons ${styles.searchIcon}`}>search</span>
                <input
                  type={'text'}
                  autoComplete={'off'}
                  className={styles.searchInput}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <div className={styles.itemWrapper}>
                {clients.map((client) => (
                  <div
                    className={selectedClient._id === client._id ? styles.itemSelected : styles.item}
                    key={client._id}
                    onClick={() => handleSelectUser(client._id)}
                  >
                    {hideAvatar ? (
                      <div>{getName(client.clientProfiles[0])}</div>
                    ) : (
                      <ClientAvatar
                        name={getName(client.clientProfiles[0])}
                        clientData={client.clientProfiles}
                        initialsName={client.clientProfiles[0]?.initials || ''}
                        avatarUrl={client.clientProfiles[0]?.avatar}
                        avatarSize={36}
                        initialsClassName={styles.initialName}
                        nameClassName={styles.clientName}
                        horizontal
                        displayFirstNameOnly={client.recordType === 'couple'}
                        displayLimit={client.recordType === 'couple' ? 2 : 1}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ConfirmationBox
            visible={showConfirmationBox}
            title={confirmChangeMessage || t('form.confirm.change_client')}
            confirmBtnLabel={'Confirm'}
            cancelBtnLabel={'Cancel'}
            onSubmitConfirm={handleConfirmChangeClient}
            onSubmitCancel={handleCancelChangeClient}
          />
        </>
      )}
    </div>
  );
};

export default ClientSelect;
