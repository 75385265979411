import { useState, ChangeEvent, TextareaHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './TextArea.module.scss';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClass?: string;
  hasError?: boolean;
  label?: string;
  minRows?: number;
}

const TextArea = ({ className, containerClass, hasError, minRows, rows, onChange, label, ...props }: TextAreaProps) => {
  const textAreaClasses = classnames(styles.textArea, hasError && styles.error, className);
  const [textArea, setTextArea] = useState({
    rows: minRows || 1,
    min: 1,
    max: 8
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e);

    if (!(typeof rows === 'number')) {
      const textAreaLineHeight = 24;

      const minRows = textArea.min;
      const maxRows = textArea.max;

      const previousRows = e.target.rows;
      e.target.rows = minRows;

      const currentRows = Math.floor(e.target.scrollHeight / textAreaLineHeight);

      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        e.target.rows = maxRows;
        e.target.scrollTop = e.target.scrollHeight;
      }

      setTextArea({
        ...textArea,
        rows: currentRows < maxRows ? currentRows : maxRows
      });
    }
  };

  return (
    <div className={classnames(styles.container, containerClass)}>
      <textarea className={textAreaClasses} onChange={(e) => handleChange(e)} rows={textArea.rows} {...props} />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default TextArea;
