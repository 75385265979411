import { notification } from 'antd';
import { ChildProfileRole, ClientRecordsListMinifiedResponse, RecordStatus } from 'interfaces/Clients/clientsRecord';
import { PractitionersListMinifiedResponse } from 'interfaces/Clients/clinician';
import { AllAssessmentFilters, OutstandingAssessment, OutstandingAssessmentType } from 'pages/AllAssessments/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { getOutstandingAssessments } from 'utils/http/CheckInService/Assessment/adhocAssessment';
import { getMinifiedClientRecordsByAccountId } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { getMinifiedCliniciansByAccountId } from 'utils/http/ClinicianProfileService/Profile/profile';

const ITEMS_PER_PAGE = 10;

interface GetOutstandingAssessmentsResponse {
  outstandingAssessments: Omit<OutstandingAssessment, 'clientRecord' | 'clientProfile' | 'clinicianProfile'>[];
}

export const useGetOutstandingAssessments = ({
  accountId,
  filters
}: {
  accountId: string;
  filters: AllAssessmentFilters;
}) => {
  const { token } = useGetAccessToken();
  const [allOutstandingAssessments, setAllOutstandingAssessments] = useState<OutstandingAssessment[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchAllOutstandingAssessments = useCallback(
    async (token: string) => {
      setIsLoading(true);

      try {
        const [outstandingAssessmentsResponse, clinicianProfilesResponse, clientRecordsResponse] = await Promise.all([
          getOutstandingAssessments(token, accountId).then(
            (response) => response.json() as Promise<GetOutstandingAssessmentsResponse>
          ),
          getMinifiedCliniciansByAccountId(token, accountId, {
            status: 'active'
          }).then((response) => response.json() as Promise<PractitionersListMinifiedResponse>),
          getMinifiedClientRecordsByAccountId(token, accountId, {
            recordStatus: RecordStatus.Active
          }).then((response) => response.json() as Promise<ClientRecordsListMinifiedResponse>)
        ]);

        setAllOutstandingAssessments(
          outstandingAssessmentsResponse.outstandingAssessments
            .map((assessment) => {
              const foundClientRecord = clientRecordsResponse.clientRecords.find(
                ({ _id, clientProfiles }) =>
                  assessment.clientRecordId === _id &&
                  // check client profile existence for client assessments
                  (assessment.type === OutstandingAssessmentType.Clinician ||
                    clientProfiles.some(({ _id }) => assessment.clientProfileId === _id))
              );
              const foundClientProfile = foundClientRecord?.clientProfiles.find(
                ({ role }) => role === ChildProfileRole.Child
              );
              const foundClinicianProfile =
                assessment.type === OutstandingAssessmentType.Clinician &&
                clinicianProfilesResponse.clinicians.find(({ _id }) => assessment.clinicianId === _id);

              if (
                !foundClientRecord ||
                !foundClientProfile ||
                (assessment.type === OutstandingAssessmentType.Clinician && !foundClinicianProfile)
              ) {
                return null;
              }

              return {
                ...assessment,
                clientRecord: foundClientRecord,
                clientProfile: foundClientProfile,
                ...(foundClinicianProfile && { clinicianProfile: foundClinicianProfile })
              };
            })
            .filter((assessment): assessment is OutstandingAssessment => !!assessment)
        );
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Something went wrong while trying to fetch outstanding assessments'
        });
      }

      setIsLoading(false);
    },
    [accountId]
  );

  useEffect(() => {
    if (token) {
      fetchAllOutstandingAssessments(token);
    }
  }, [token, fetchAllOutstandingAssessments]);

  const navigatePage = (page: number) => {
    if (isLoading || page < 1 || page > totalPage) {
      return;
    }
    setCurrentPage(page);
  };

  const filteredAssessments = useMemo(() => {
    if (!filters.name) {
      return allOutstandingAssessments;
    }

    const nameRegex = new RegExp(filters.name, 'i');

    return allOutstandingAssessments.filter(
      (assessment) =>
        nameRegex.test(assessment.clientProfile.firstName) || nameRegex.test(assessment.clientProfile.lastName)
    );
  }, [allOutstandingAssessments, filters]);

  const paginatedOutstandingAssessments = useMemo(
    () => filteredAssessments.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE),
    [currentPage, filteredAssessments]
  );

  const totalItems = filteredAssessments.length;
  const totalPage = Math.ceil(totalItems / ITEMS_PER_PAGE);

  return {
    currentPage,
    totalPage,
    totalItems,
    isLoading,
    navigatePage,
    assessments: paginatedOutstandingAssessments,
    refetchAssessments: () => (token ? fetchAllOutstandingAssessments(token) : undefined)
  };
};
