import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { Props } from 'react-select';
import styles from './Gap.module.scss';

const gapTimeOptions = [
  { label: '0 minutes', value: 0 },
  { label: '5 minutes', value: 5 },
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 },
  { label: '20 minutes', value: 20 },
  { label: '25 minutes', value: 25 },
  { label: '30 minutes', value: 30 }
];

const propStyles: Props['styles'] = {
  container: (base) => ({ ...base, margin: 0 }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${styles.greyColor}`,
    borderRadius: 0,
    boxShadow: 'none'
  }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  dropdownIndicator: (base) => ({ ...base, padding: 0 })
};

const Gap = () => {
  return (
    <FormikSelect
      name="gap"
      label="Set mandatory gap between appointments"
      options={gapTimeOptions}
      styles={propStyles}
    />
  );
};

export default Gap;
