import { useAuth0 } from '@auth0/auth0-react';

export const useGetAccountPackageView = () => {
  const { user } = useAuth0();
  const userAccessConfig = user?.['https://tacklit.com/accessConfig'];
  const isEdgeAdminUser = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'admin';
  const isEdgeUser = userAccessConfig.plan === 'edge';
  const isDefaultUser = userAccessConfig.plan !== 'edge';
  const isEdgeReceptionist = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'receptionist';
  const isMentorUser = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'mentor';
  const isEdgePractitioner = userAccessConfig.plan === 'edge' && userAccessConfig.accessRight === 'user';

  const firstPath = window.location.pathname.split('/')[1];
  const edgeAdminPath = firstPath === 'admin';
  const edgeUserPath = firstPath === 'user';
  const edgeReceptionistPath = firstPath === 'receptionist';
  const edgeMentorPath = firstPath === 'teacher';

  const isEdgeAdminView = isEdgeAdminUser && edgeAdminPath;
  const isEdgeUserView = isEdgeUser && edgeUserPath;
  const isNormalUserView = isDefaultUser;
  const isEdgeReceptionistView = isEdgeReceptionist && edgeReceptionistPath;
  const isEdgeUserMentorView = isMentorUser && edgeMentorPath;

  return {
    isEdgeAdminView,
    isEdgeUserView,
    isEdgeUser,
    isEdgeReceptionist,
    isEdgeAdminUser,
    isNormalUserView,
    isEdgeReceptionistView,
    isMentorUser,
    isEdgeUserMentorView,
    isEdgePractitioner,
    firstPath
  };
};
