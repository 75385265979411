import { useState } from 'react';
import { Formik } from 'formik';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import PhoneInput from 'react-phone-input-2';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import styles from './NewContacts.module.scss';
import { clientContactValidation, CONTACT_TAG_OPTIONS, NEW_CLIENT_CONTACT_INITIAL } from '../../constants';
import classNames from 'classnames';
import { notification } from 'antd';
import Button from 'components/v2/Button/Button';
import Select from 'components/Select/CommonSelect/Select';
import { postKeyContact } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { KeyContact } from 'pages/Groups/Interfaces/Groups';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useTranslation } from 'react-i18next';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const PREFERRED_COUNTRY = GAE_REGION === 'au' ? 'au' : GAE_REGION === 'gb' ? 'gb' : undefined;

export interface NewContactProps {
  token: string;
  groupId: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const NewContacts = ({ token, groupId, onSubmit, onCancel }: NewContactProps) => {
  const { accountId } = useGetAccountId();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [t] = useTranslation();

  const onSubmitHandle = async (value: KeyContact) => {
    if (selectedTags.length > 5) {
      return;
    }
    setSubmitStatus('active');

    try {
      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        mobileNumber: value.mobileNumber ? `+${value.mobileNumber}` : '',
        email: value.email || undefined,
        tags: selectedTags
      };
      await postKeyContact(token, accountId, groupId, payload);
      onSubmit();
      setSubmitStatus('finished');
      setTimeout(() => {
        notification.success({
          message: t('form.success.add_key_client_contact'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }, 500);
      onCancel();
    } catch (e) {
      notification.error({ message: t('form.error.add_key_client_contact') });
    }
    setSubmitStatus('');
  };

  const onHandleSelectMulti = (value: unknown) => {
    const tags = value as { label: string; value: string }[];
    const selectTags = tags ? tags.map((tag) => tag.value) : [];
    setSelectedTags(selectTags);
  };

  return (
    <Formik
      initialValues={NEW_CLIENT_CONTACT_INITIAL}
      validationSchema={clientContactValidation}
      onSubmit={onSubmitHandle}
      enableReinitialize
    >
      {({ errors, touched, values, setFieldValue, submitForm }) => {
        return (
          <form className={styles.creating}>
            <div className={styles.fieldsContainer}>
              <FormikMaterialInput
                className={classNames(styles.firstName, styles.input)}
                name="firstName"
                label="First name*"
                required
              />
              <FormikMaterialInput
                className={classNames(styles.lastName, styles.input)}
                name="lastName"
                label="Last name*"
                required
              />
              <div className={classNames(styles.mobileNumber, styles.mobileNumberContainer)}>
                <span className={styles.label}>Mobile number</span>
                <PhoneInput
                  inputClass={styles.mobileNumberInput}
                  buttonClass={styles.countrySelect}
                  dropdownClass={styles.countryDropdown}
                  value={values.mobileNumber}
                  onChange={(value, data, event, formattedMobileNumber) => {
                    setFieldValue('mobileNumber', value);
                    setFieldValue('formattedMobileNumber', formattedMobileNumber);
                  }}
                  onBlur={() => document?.getElementById('email')?.focus()}
                  {...(PREFERRED_COUNTRY && {
                    country: PREFERRED_COUNTRY,
                    preferredCountries: [PREFERRED_COUNTRY]
                  })}
                />
                <ErrorMessage error={errors.mobileNumber} visible={!!touched.mobileNumber} />
              </div>
              <FormikMaterialInput
                className={classNames(styles.email, styles.input)}
                name="email"
                label="Email"
                required
              />
              <div className={styles.contactTags}>
                <Select
                  className={styles.tagSelect}
                  isMulti
                  label="Tags"
                  name="tags"
                  labelClass={styles.labelClass}
                  options={CONTACT_TAG_OPTIONS.map((item) => ({ value: item, label: item }))}
                  isClearable={false}
                  menuPlacement={'auto'}
                  onChange={(e) => onHandleSelectMulti(e)}
                  smallCaretDown
                  smallCaretDownClass={styles.smallCaretDownClass}
                  styles={{
                    container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
                    valueContainer: (base: any) => ({
                      ...base,
                      minHeight: '30px',
                      padding: '0 8px 0 0'
                    }),
                    control: (controlBase: any) => ({
                      ...controlBase,
                      minHeight: '30px',
                      backgroundColor: 'transparent',
                      border: 'none',
                      borderBottom: `1px solid ${styles.greyColor}`,
                      borderRadius: 0,
                      boxShadow: 'none'
                    }),
                    singleValue: (singleValueBase: any) => ({
                      ...singleValueBase,
                      fontSize: '10px',
                      lineHeight: '22px'
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: styles.blueColor,
                      borderRadius: 25,
                      color: styles.whiteColor,
                      padding: 4,
                      fontSize: '14px'
                    })
                  }}
                />
                {selectedTags.length > 5 && <label className={styles.errorLabel}>Tag options must less than 5</label>}
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <Button
                disabled={submitStatus !== ''}
                className={styles.cancelButton}
                variant="secondary"
                onClick={onCancel}
                type={'button'}
              >
                Cancel
              </Button>
              <Button
                className={styles.saveButton}
                status={submitStatus}
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                Save
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default NewContacts;
