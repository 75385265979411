import { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Option, Section, SubQuestion } from '../../../../AssessmentInterfaces';

import SectionComponent from './components/Section/Section';
import SubmitButton from '../SubmitButton/SubmitButton';

import { PatientQuestionFormHeaderProps } from '../../../../PatientQuestionForm';
import { scrollToView } from 'utils/scrollToView';

interface QuestionSetProps {
  sections: Section[];
  completedQuestions: number;
  totalQuestions?: number;
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  isSkippable: boolean;
  isFirstQuestionSet: boolean;
  onBack: () => void;
  onSkip: () => void;
  onSubmit: (values: any, setStageToNextQuestion?: boolean) => Promise<unknown>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  onSaveAndExit: () => void;
  submitBtnText?: JSX.Element;
  submitBtnClass?: string;
}

const QuestionSet = ({
  sections,
  completedQuestions,
  totalQuestions,
  defaultPatientDetails,
  header: Header,
  isSkippable,
  isFirstQuestionSet,
  onBack,
  onSkip,
  onSubmit,
  setIsSubmitting,
  onSaveAndExit,
  submitBtnText,
  submitBtnClass
}: QuestionSetProps) => {
  const { initialValues, validationSchema } = useMemo(() => {
    const { initialValues, validationSchema } = sections.reduce(
      // eslint-disable-next-line complexity
      (finalObject, currentSection) => {
        for (let i = 0; i < currentSection.questions.length; i++) {
          const { id, questionType, maxSelection, options, response, scales } = currentSection.questions[i];

          switch (questionType) {
            case 'barSlider':
              finalObject.initialValues[id] = response || { value: 20 };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.number().typeError('Please select an option').required('Please answer the question')
                  : Yup.number().typeError('Please select an option')
              });
              break;
            case 'freeText':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable ? Yup.string().required('Please answer the question') : Yup.string()
              });
              break;
            case 'hexSlider':
              finalObject.initialValues[id] = response || {
                value: scales && scales.length > 1 ? Math.ceil(scales.length / 2) : 6
              };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.number().typeError('Please select an option').required('Please answer the question')
                  : Yup.number().typeError('Please select an option')
              });
              break;
            case 'multipleAnswers':
              finalObject.initialValues[id] = response || {
                value: (options as SubQuestion[]).reduce((initialValues, { id }) => {
                  initialValues[id] = '';

                  return initialValues;
                }, {} as Record<string, any>)
              };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: Yup.object().shape(
                  (options as SubQuestion[]).reduce((validationSchema, { id }) => {
                    validationSchema[id] = !isSkippable
                      ? Yup.string().required('Please select an option')
                      : Yup.string();

                    return validationSchema;
                  }, {} as Record<string, any>)
                )
              });
              break;
            case 'multipleChoice':
              finalObject.initialValues[id] = response || { value: maxSelection === 0 ? [] : '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value:
                  maxSelection === 0
                    ? !isSkippable
                      ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                      : Yup.array().of(Yup.string())
                    : !isSkippable
                    ? Yup.string().required('Please select an option')
                    : Yup.string()
              });
              break;
            case 'multipleChoiceFreeText':
              finalObject.initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value:
                  maxSelection === 0
                    ? !isSkippable
                      ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                      : Yup.array().of(Yup.string())
                    : !isSkippable
                    ? Yup.string().required('Please select an option')
                    : Yup.string(),
                otherValue: Yup.string()
              });
              break;
            case 'numeric':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.number().typeError('Please enter a valid number').required('Please answer the question')
                  : Yup.number().typeError('Please enter a valid number')
              });
              break;
            case 'selectOther':
              finalObject.initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value:
                  maxSelection === 0
                    ? !isSkippable
                      ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                      : Yup.array().of(Yup.string())
                    : !isSkippable
                    ? Yup.string().required('Please select an option')
                    : Yup.string(),
                otherValue: Yup.string().when('value', {
                  is: 'other',
                  then: !isSkippable ? Yup.string().required('Please elaborate') : Yup.string(),
                  otherwise: Yup.string()
                })
              });
              break;
            case 'vote':
              finalObject.initialValues[id] = response || {
                value: (options as Option[]).reduce((initialValues, { key }) => {
                  initialValues[key] = 0;

                  return initialValues;
                }, {} as Record<string, any>)
              };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: Yup.object().shape(
                  (options as Option[]).reduce((validationSchema, { key }) => {
                    validationSchema[key] = !isSkippable
                      ? Yup.number().required('Please vote on this question')
                      : Yup.number();

                    return validationSchema;
                  }, {} as Record<string, any>)
                )
              });
              break;

            default:
              break;
          }
        }

        return finalObject;
      },
      { initialValues: {} as Record<string, any>, validationSchema: {} as Record<string, any> }
    );

    return { initialValues, validationSchema: Yup.object().shape(validationSchema) };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const handleSkip = async (values: any) => {
    await handleSubmit(values);

    onSkip();
  };

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const handleSubmit = async (values: any, setStageToNextQuestion?: boolean) => {
    setIsSubmitting(true);

    await onSubmit(values, setStageToNextQuestion);

    setIsSubmitting(false);
  };

  const goToInvalidQuestion = () => {
    scrollToView('assessment-invalid-question', true);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={isSubmitButtonClicked}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize
      >
        <Form className="question-form-container" noValidate>
          <Header
            completedQuestions={completedQuestions}
            totalQuestions={totalQuestions}
            patientDetails={defaultPatientDetails}
            isFirstQuestionSet={isFirstQuestionSet}
            isSkippable={isSkippable}
            onBack={onBack}
            onSkip={handleSkip}
            onSaveAndExit={onSaveAndExit}
          />
          {sections.map((section, index) => (
            <SectionComponent key={index} defaultPatientDetails={defaultPatientDetails} section={section} />
          ))}
          <SubmitButton
            type="submit"
            className={submitBtnClass}
            onClick={() => {
              setIsSubmitButtonClicked(true);
              setTimeout(goToInvalidQuestion, 400);
            }}
          >
            {submitBtnText || 'Next'}
          </SubmitButton>
        </Form>
      </Formik>
    </>
  );
};

export default QuestionSet;
