import classNames from 'classnames';
import styles from './AssessmentLabel.module.scss';

const AssessmentLabel = ({
  label,
  minWidth,
  fontSize,
  padding,
  className
}: {
  label?: string;
  minWidth?: string;
  fontSize?: number;
  padding?: string;
  className?: string;
}) => {
  return label ? (
    <div
      className={classNames(styles.label, className, styles[label.toLowerCase().replaceAll(' ', '-') || ''])}
      style={{
        minWidth,
        fontSize,
        padding
      }}
    >
      {label}
    </div>
  ) : null;
};

export default AssessmentLabel;
