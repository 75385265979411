import classNames from 'classnames';
import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { InputHTMLAttributes, ReactNode } from 'react';

import styles from './Radio.module.scss';

interface RadioOption {
  id?: string;
  label: string | ReactNode;
  value: string | number;
  subLabel?: string;
  disabled?: boolean;
  tooltip?: string;
}

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  options: RadioOption[];
  radioClass?: string;
  radioClassName?: any;
  labelClassName?: any;
  subLabelClassName?: any;
  hasError?: boolean;
  noSpacing?: boolean;
  vertical?: boolean;
  nowrap?: boolean;
  variant?: string;
  alwaysShowSubLabel?: boolean;
  highlightActive?: boolean;
}

const Radio = ({
  className,
  label,
  name,
  options,
  radioClass,
  hasError,
  noSpacing,
  value: formValue,
  vertical,
  nowrap = false,
  radioClassName,
  labelClassName,
  subLabelClassName,
  variant,
  alwaysShowSubLabel,
  highlightActive,
  ...props
}: RadioProps) => {
  const radioClasses = classnames(
    hasError && styles.error,
    noSpacing && styles.noSpacing,
    variant ? styles[variant] : styles.radioBtn,
    radioClass
  );

  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={classnames(styles.radio, vertical && styles.vertical, nowrap && styles.nowrap)}>
        {options.map(({ label, value, subLabel, disabled, tooltip }, index) => {
          const isChecked = formValue && value.toString() === formValue;
          return (
            <div className={classnames(radioClasses, radioClassName)} key={index}>
              <input
                disabled={disabled}
                type={'radio'}
                id={`radio-${name}-${value}`}
                name={name}
                value={value}
                checked={value.toString() === formValue}
                {...props}
              />
              <label
                htmlFor={`radio-${name}-${value}`}
                className={classnames(styles.label, labelClassName, disabled && styles.disabled)}
              >
                <div
                  className={
                    disabled
                      ? styles.labelWordDisable
                      : classNames(
                          styles.labelWord,
                          highlightActive && value.toString() === formValue && styles.labelActive
                        )
                  }
                >
                  {label}
                  {vertical && tooltip && <HelpOutLineWithTooltips id={`radio-${name}-${value}`} desc={tooltip} />}
                </div>
                {isChecked && variant === 'button' ? (
                  <span className={classNames('material-icons', styles.checkedIcon)}>check</span>
                ) : null}
                {subLabel && (isChecked || alwaysShowSubLabel) && (
                  <div
                    className={classnames(styles.subLabel, subLabelClassName && subLabelClassName)}
                    dangerouslySetInnerHTML={{ __html: subLabel }}
                  />
                )}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Radio;
