import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import QuestionListWithCheckBox from '../QuestionListWithCheckBox/QuestionListWithCheckBox';
import QuestionListWithInputField from '../QuestionListWithInputField/QuestionListWithInputField';

import styles from './QuestionList.module.scss';

export interface QuestionListProps {
  questionSet: any;
  questionNum: number;
  onAddQuestion: any;
  onChangeQuestion: any;
  onChangeCheckBox: any;
  onClickSave: any;
}

const QuestionList = ({ questionSet, onAddQuestion, onChangeQuestion, onChangeCheckBox, onClickSave }: QuestionListProps) => {
  const [draftInputValue, setDraftInputQValue] = useState({
    id: '',
    isDraftQ: true
  });

  const onAddNewQuestion = (newQData: any) => {
    onAddQuestion(questionSet.id, newQData);
    setDraftInputQValue(newQData);
  };

  const onCheckChange = (groupId: string, value: any) => {
    onChangeCheckBox(groupId, value);
    setDraftInputQValue(value);
  };

  const onChangeLastQ = (value: any) => {
    onChangeQuestion(questionSet.id, value);
    setDraftInputQValue(value);
  };

  const newLastId = (draftInputValue.isDraftQ && draftInputValue.id) || `newId${uuid()}`;

  return questionSet.sectionName !== '' ? (
    <div className={styles.container}>
      {questionSet.questions.map((question: any, index: any) => {
        return !question.isDraftQ && (
          <div key={index}>
            <QuestionListWithCheckBox
              label={question.stem}
              id={question.id}
              questionId={question.questionId}
              isDraftQ={question.isDraftQ}
              isChecked={question.isChecked}
              groupId={questionSet.id}
              onCheckChange={onCheckChange}
            />
          </div>
        );
      })}
      <QuestionListWithInputField
        id={newLastId}
        group={'employment'}
        onAddQuestion={onAddNewQuestion}
        onChangeLastQuestion={onChangeLastQ}
        onClickSave={onClickSave}
      />
    </div>
  ) : (
    <div className={styles.noQuestionContainer}>
      Each topic has a question library to which you can add / remove questions
    </div>
  );
};

export default QuestionList;
