import React, { useEffect, useState } from 'react';
import { GroupDetailsTab, GroupPsychometricMenu, Groups } from 'pages/Groups/Interfaces/Groups';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchGroupAssessmentClients } from '../../hooks/getGroupAssessmentClients';
import PsychometricHeader from './components/PsychometricHeader/PsychometricHeader';
import PsychometricListContent from './components/PsychometricListContent/PsychometricListContent';
import styles from './PsychometricList.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';

import NoPsychometrics from './nopsychometric.png';
import Button from 'components/v2/Button/Button';
import CORDSSendGroupAssessmentModal from 'pages/Assessments/components/CORDSSendAssessmentModal/CORDSSendGroupAssessmentModal';

interface PsychometricListProps {
  groupDetails: Groups;
  psychometricId?: string;
  selectedClinicalAssessmentId?: string;
  psychometricMenuList: GroupPsychometricMenu[];
  onCreateSuccess: () => void;
}

const PsychometricList = ({
  groupDetails,
  psychometricId,
  selectedClinicalAssessmentId,
  psychometricMenuList
}: PsychometricListProps) => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { GROUPS } = useRoutesGenerator();
  const groupId = useParams<{ groupId: string }>();
  const [visibleSendAssessment, setVisibleSendAssessment] = useState<boolean>(false);

  const { groupAssessmentClients, isGroupAssessmentClientsLoading } = useFetchGroupAssessmentClients(
    token,
    groupId.groupId!,
    psychometricId || '',
    selectedClinicalAssessmentId || ''
  );

  useEffect(() => {
    if (!psychometricId && psychometricMenuList && psychometricMenuList.length > 0) {
      const isSentPsychometric = psychometricMenuList.filter((obj) => obj.isSent);
      if (isSentPsychometric.length > 0) {
        navigate(
          `${GROUPS.BASE}/${groupId.groupId}/${GroupDetailsTab.Psychometrics}/${isSentPsychometric[0]._id}` +
            `/clinicalAssessmentId/${isSentPsychometric[0].clinicalAssessmentId}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psychometricMenuList]);

  return (
    <div className={styles.container}>
      {groupAssessmentClients.length === 0 && isGroupAssessmentClientsLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : psychometricMenuList.length > 0 && groupAssessmentClients.length > 0 ? (
        <>
          <PsychometricHeader
            selectedClinicalAssessmentId={selectedClinicalAssessmentId}
            groupId={groupId.groupId!}
            psychometricId={psychometricId}
            psychometricMenuList={psychometricMenuList}
          />
          <PsychometricListContent
            psychometricList={groupAssessmentClients}
            isPsychometricListLoading={isGroupAssessmentClientsLoading}
          />
        </>
      ) : psychometricId ? (
        'No clients were in the group when this psychometric assessment was sent, or all clients who have been sent this psychometric assessment have been removed from the group.'
      ) : (
        <div className={styles.noPsychometrics}>
          <img className={styles.image} src={NoPsychometrics} alt="No psychometric" />
          <div className={styles.prompt}>
            <div className={styles.text}>No psychometric assessments have been sent for this group.</div>
            <Button className={styles.button} onClick={() => setVisibleSendAssessment(true)}>
              <i className={`material-icons-outlined ${styles.icon}`}>send</i>Send assessment
            </Button>
            <CORDSSendGroupAssessmentModal
              visible={visibleSendAssessment}
              onClose={() => setVisibleSendAssessment(false)}
              defaultGroup={groupDetails}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PsychometricList;
