import { Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import styles from './ActiveInterventionListingItem.module.scss';
import columnStyles from 'MHH/pages/Interventions/InterventionsList/components/InterventionListContent/components/ActiveInterventionListCard/InterventionRowSection.module.scss';
import moment from 'moment';
import DateDescBox from 'pages/Patient/components/ClientListContent/components/Components/DateDescBox/DateDescBox';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Link } from 'react-router-dom';
import { getDayDiff } from 'utils/helpers/DateDiff';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ActiveInterventionListingItemProps {
  interventionListingItem: Interventions;
  searchValue: string;
}

const ActiveInterventionListingItem = ({
  interventionListingItem,
  searchValue
}: ActiveInterventionListingItemProps) => {
  const { INTERVENTIONS } = useRoutesGenerator();
  const { isInterventionsTagsToggle } = useGetFeatureToggle();

  return (
    <Link
      to={`${INTERVENTIONS.BASE}/${interventionListingItem._id}/details${searchValue ? `?q=${searchValue}` : ''}`}
      className={styles.container}
    >
      <div className={columnStyles.interventionNameSection}>
        <div className={styles.interventionName}>{interventionListingItem.name}</div>
      </div>
      <div className={columnStyles.createdSection}>
        <div className={styles.createdDate}>
          <DateDescBox
            title={moment(interventionListingItem.createdAt).format('D MMM YYYY')}
            desc={getDayDiff(moment(), moment(interventionListingItem.createdAt))}
          />
        </div>
      </div>
      {isInterventionsTagsToggle && (
        <div className={columnStyles.interventionTagSection}>
          <>
            {interventionListingItem.tags.length > 0 ? (
              <>
                {interventionListingItem.tags.map((tagObj, index) => (
                  <div className={styles.tag} key={index}>
                    {tagObj.label}
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.noneText}>none</div>
            )}
          </>
        </div>
      )}
      <div className={columnStyles.interventionMemberSection}>
        <div className={styles.member}>
          {interventionListingItem.clientCount} member
          {interventionListingItem.clientCount !== 1 && 's'}
        </div>
      </div>
      <div className={columnStyles.interventionLeadSection}>
        <div className={styles.lead}>{interventionListingItem.leadClinicianProfile.name}</div>
      </div>
      <div className={columnStyles.descriptionSection}>
        <div className={styles.description}>
          {' '}
          {interventionListingItem.description ? interventionListingItem.description : '-'}
        </div>
      </div>
      <div className={columnStyles.buttonSection}>
        <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
      </div>
    </Link>
  );
};

export default ActiveInterventionListingItem;
