import { notification } from 'antd';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { DELIVERY_TYPE_LABELS } from 'interfaces/Schedule/AppointmentType';
import { debounce } from 'lodash';
import moment from 'moment';
import { AppointmentMarkOption } from 'pages/Calendar/components/Modals/EventInformationModal/components/MarkStatus/MarkStatus';
import { useEffect, useMemo, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateAppointmentStatus } from 'utils/http/appointment';
import styles from './CompletedCardItem.module.scss';
import { useTranslation } from 'react-i18next';

interface CompletedCardItemProps {
  appointments: AppointmentSlots;
  refetchAppointment: () => void;
}

const CompletedCardItem = ({ appointments, refetchAppointment }: CompletedCardItemProps) => {
  const { token } = useGetAccessToken();
  const [appointmentTags, setAppointmentTags] = useState(appointments.markedStatus || []);
  const appointmentTime = moment(appointments.date + ' ' + appointments.startTime).format('dddd DD MMMM \\at hh:mmA');

  const [t] = useTranslation();

  const debouncedSubmitTags = useMemo(
    () =>
      debounce(async (newAppointmentTags) => {
        try {
          await updateAppointmentStatus(token, appointments._id, newAppointmentTags);
          refetchAppointment();
          notification.success({
            message: 'Tags updated',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Tags update fail',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        }
      }, 1000),
    [token, refetchAppointment, appointments._id]
  );

  useEffect(() => {
    setAppointmentTags(appointments.markedStatus || []);
  }, [appointments.markedStatus, setAppointmentTags]);

  const handleAddTags = async (val: string) => {
    const newAppointmentTags = [...appointmentTags, val];
    await debouncedSubmitTags(newAppointmentTags);
    setAppointmentTags(newAppointmentTags);
  };

  const handleRemoveStatus = async (status: string) => {
    if (status) {
      const cache = [...appointmentTags];
      try {
        const foundStatusIdx = appointmentTags.findIndex((stt) => stt === status);
        appointmentTags.splice(foundStatusIdx, 1);
        setAppointmentTags([...appointmentTags]);
        await updateAppointmentStatus(token, appointments._id, appointmentTags);
        refetchAppointment();
      } catch (err) {
        setAppointmentTags([...cache]);
        console.error(err);
        notification.error({
          message: t('client_appointment.event_tag_remove.error_notification')
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.calendarIconWrapper}>
          <i className={`material-icons ${styles.calendarIcon}`}>insert_invitation</i>
        </div>
        <div className={styles.appointmentDetailWrapper}>
          <div className={styles.sessionName}>{appointments.sessionTypeName}</div>
          <div className={styles.appointmentDetail}>{appointmentTime}</div>
          <div className={styles.appointmentDetail}>{DELIVERY_TYPE_LABELS[appointments.deliveryType!]}</div>
        </div>
      </div>
      <div className={styles.tagContainer}>
        {appointmentTags.map((status, index) => (
          <div className={styles.markedStatus} key={index}>
            {status}
            <div className={styles.iconContainer} onClick={() => handleRemoveStatus(status)}>
              <i className={`material-icons-outlined ${styles.icon}`}>close</i>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.actionContainer}>
        <MaterialDropdown
          id={`targetSession`}
          label={'Mark Appointment'}
          dropDownList={AppointmentMarkOption.filter((mark) => appointmentTags?.indexOf(mark.value) === -1)}
          selectedValue={''}
          placeholder={'Select Label'}
          onChangeValue={handleAddTags}
          fieldClassName={styles.reasonSelect}
        />
      </div>
    </div>
  );
};

export default CompletedCardItem;
