import { useField } from 'formik';
import styles from './Details.module.scss';

const Details = () => {
  const [, { value }, noteHelper] = useField('notes');

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span className="material-icons-outlined">segment</span>
        <div className={styles.eventNoteContainer}>
          <div className={styles.label}>Event Note</div>
          <textarea
            className={styles.textarea}
            onBlur={(e) => noteHelper.setValue(e.target.value)}
            defaultValue={value}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
