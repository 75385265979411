import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import styles from './AllAssessments.module.scss';
import AllAssessmentsFilters from './components/AllAssessmentsFilters/AllAssessmentsFilters';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AllAssessmentFilters, AllAssessmentTabId } from './types';
import OutstandingAssessmentsList from './components/OutstandingAssessmentsList/OutstandingAssessmentsList';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

const AllAssessments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = useParams<{ tabId: AllAssessmentTabId }>();
  const queryParam: { name?: string } = queryString.parse(location.search);
  const { accountId } = useGetAccountId();
  const { ASSESSMENT } = useRoutesGenerator();

  const [filterType, setFilterType] = useState<AllAssessmentTabId>(path.tabId ?? AllAssessmentTabId.Outstanding);
  const [filters, setFilters] = useState<AllAssessmentFilters>({ name: queryParam.name || '' });
  const [outstandingAssessmentCount, setOutstandingAssessmentCount] = useState<number>();

  const handleChangeFilterType = (newFilterType: AllAssessmentTabId) => {
    setFilterType(newFilterType);

    const newQParam = {
      ...(filters.name && {
        name: filters.name
      })
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${ASSESSMENT.BASE}/${newFilterType}${qParam ? `?${qParam}` : ''}`);
  };

  const handleChangeFilters = (newFilters: AllAssessmentFilters) => {
    setFilters(newFilters);

    const newQParam = {
      ...(newFilters.name && {
        name: newFilters.name
      })
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${ASSESSMENT.BASE}/${filterType}${qParam ? `?${qParam}` : ''}`);
  };

  return (
    <HelmetWrapper title={'CORDS - All Assessments'}>
      <ContentLayout>
        <AllAssessmentsFilters
          filters={filters}
          filterType={filterType}
          outstandingAssessmentCount={outstandingAssessmentCount}
          onChangeAssessmentTab={handleChangeFilterType}
          onChangeFilters={handleChangeFilters}
        />
        <div className={styles.content}>
          <OutstandingAssessmentsList
            accountId={accountId}
            filters={filters}
            visible={filterType === AllAssessmentTabId.Outstanding}
            updateOutstandingAssessmentsCount={setOutstandingAssessmentCount}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default AllAssessments;
