import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { massageCORDSActivityLabel } from 'utils/CORDS/massageActivityFeedLabel';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getInterventionActivityFeed } from 'utils/http/ActivityFeedService/activities';
import { InterventionActivity } from '../InterventionActivity';

export const useListInterventionActivityFeed = (interventionId: string, selectedFilter: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { accountId } = useGetAccountId();

  const [fetching, setFetching] = useState(false);
  const [activityFeed, setActivityFeed] = useState<InterventionActivity[]>([]);
  const [hasMoreActivity, setHasMoreActivity] = useState(true);
  const [lastActivityId, setLastActivityId] = useState('');

  const pageSize = 20;

  const fetchActivityFeed = useCallback(
    async (fetchNew: boolean) => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        const callGetPatientActivityByPatientId = await getInterventionActivityFeed(
          token,
          accountId,
          interventionId,
          selectedFilter,
          pageSize,
          fetchNew ? '' : lastActivityId
        );
        const { data, count, nextActivityId } = await callGetPatientActivityByPatientId.json();

        if (fetchNew) {
          setActivityFeed(massageCORDSActivityLabel(data));
        } else {
          setActivityFeed((activityFeed) => [...activityFeed, ...massageCORDSActivityLabel(data)]);
        }
        setHasMoreActivity(count === pageSize);
        setLastActivityId(nextActivityId || '');
      } catch (e) {
        console.error(e);
        notification.error({ message: 'Some thing went wrong while trying to fetch this student activity' });
      }
    },
    [accountId, getAccessTokenSilently, interventionId, lastActivityId, selectedFilter]
  );

  useEffect(() => {
    setFetching(true);
    fetchActivityFeed(true).then(() => {
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return { fetching, activityFeed, hasMoreActivity, fetchActivityFeed };
};
