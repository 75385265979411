import { Route, Routes } from 'react-router-dom';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import SurveyFormList from './SurveyFormList/SurveyFormList';
import SurveyFormDetails from './SurveyFormDetails/SurveyFormDetails';

interface SurveyFormsProps {
  surveyId?: string;
  surveyFormMenuList: SurveyFormResponseList[];
  surveyResponseListLoading: boolean;
}

const SurveyForms = ({ surveyId, surveyFormMenuList, surveyResponseListLoading }: SurveyFormsProps) => {
  const { GROUPS } = useRoutesGenerator();

  return (
    <Routes>
      <Route
        path={`${GROUPS.BASE}/:groupId/${GroupDetailsTab.Survey}/:contentId?`}
        element={
          <SurveyFormList
            surveyId={surveyId}
            surveyFormMenuList={surveyFormMenuList}
            surveyResponseListLoading={surveyResponseListLoading}
          />
        }
      />
      <Route
        path={`${GROUPS.BASE}/:groupId/${GroupDetailsTab.Survey}/:contentId/${GROUPS.SURVEY.DETAIL}/:surveyResponseId/clientRecordId/:clientRecordId`}
        element={<SurveyFormDetails surveyId={surveyId} />}
      />
    </Routes>
  );
};

export default SurveyForms;
