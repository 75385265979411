import { useEffect, useState } from 'react';
import styles from './EditClientModal.module.scss';
import { Modal, notification } from 'antd';
import EditChildForm from './formType/EditChildForm/EditChildForm';
import { editClientForm } from './EditClientModalInterface';
import EditAdultForm from './formType/EditAdultForm/EditAdultForm';
import {
  checkIsDuplicateCaseId,
  validateAdultForm,
  validateChildForm,
  validateCoupleForm
} from '../AddPatientModalV2/validation/SubmitFormValidation';
import { useGetAccessToken } from 'utils/hooks/token';
import { putUpdateClientData } from './hooks/PutUpdateClient';
import EditCoupleForm from './formType/EditCoupleForm/EditCoupleForm';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

interface EditClientModalProps {
  clientRecordData: editClientForm;
  onCancel: (isUpdated: boolean) => void;
  visible: boolean;
  onRefreshData?: () => void;
}

const EditClientModal = ({ clientRecordData, visible, onCancel, onRefreshData }: EditClientModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkValidation, setCheckValidation] = useState(false);
  const [clientFormData, setClientFormData] = useState(clientRecordData);
  const [t] = useTranslation();

  useEffect(() => {
    setClientFormData(clientRecordData);
  }, [clientRecordData]);

  const generateEmailCollection = () => {
    let emailCollection: string[] = [];
    for (const obj of clientRecordData.clientProfiles) {
      if (obj.email) {
        emailCollection.push(obj.email);
      }
    }
    return emailCollection;
  };
  const emailCollection = generateEmailCollection();
  const initCaseIdValue = clientRecordData.caseId;

  const handleChangeClientDetail = (clientProfilesData: editClientForm['clientProfiles']) => {
    setClientFormData({
      ...clientFormData,
      clientProfiles: clientProfilesData
    });
  };

  const handleChangeCaseId = (value: editClientForm['caseId']) => {
    setClientFormData({
      ...clientFormData,
      caseId: value
    });
  };

  const handleChangeClass = (value: editClientForm['class']) => {
    setClientFormData({
      ...clientFormData,
      class: value
    });
  };

  const handleChangeProfileType = (value: clientProfilesInterface['profileType']) => {
    const newClientProfile = clientFormData.clientProfiles.map((clientProfile) => ({
      ...clientProfile,
      profileType: value
    }));
    setClientFormData({
      ...clientFormData,
      clientProfiles: newClientProfile,
      clientCapability: {
        profileType: value
      }
    });
  };

  const validateSubmitField = async (recordType: editClientForm['recordType'], clientVal: editClientForm) => {
    const isCaseIdDuplicateExist = await checkIsDuplicateCaseId(token, clientVal.caseId, initCaseIdValue);
    if (recordType === 'adult') {
      const { isAdultFormErrorExist } = await validateAdultForm(
        token,
        clientVal.clientProfiles,
        clientVal.clientProfiles[0].profileType,
        emailCollection
      );
      if (isAdultFormErrorExist || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isAdultFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'child') {
      const { isChildFormErrorExist } = await validateChildForm(clientVal.clientProfiles);

      if (isChildFormErrorExist || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isChildFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'couple') {
      const { isClientFormErrorExist, isDuplicateEmail, isDuplicatePhone } = await validateCoupleForm(
        token,
        clientVal.clientProfiles,
        clientRecordData.clientProfiles,
        clientVal.clientProfiles[0].profileType
      );
      if (isClientFormErrorExist || isDuplicateEmail || isDuplicatePhone || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isClientFormErrorExist && !isDuplicateEmail && !isDuplicatePhone && !isCaseIdDuplicateExist;
    }
  };

  const handleEditSaveClientData = async () => {
    setSubmitStatus('active');
    setCheckValidation(true);
    if (await validateSubmitField(clientFormData.recordType, clientFormData)) {
      try {
        await putUpdateClientData(token, clientFormData);
        onRefreshData && onRefreshData();
        setTimeout(() => {
          setSubmitStatus('finished');
        }, 500);
        setTimeout(() => {
          notification.success({
            message: t('form.success.update_client'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitStatus('');
          onCancel(false);
          setCheckValidation(false);
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: t('form.error.update_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const handleSubmit = async () => {
    const checkCommPreferenceChange = clientRecordData.clientProfiles.filter(
      (oriProfData) =>
        !clientFormData.clientProfiles.some(
          (editProfData) =>
            oriProfData._id === editProfData._id &&
            oriProfData.communicationPreference === editProfData.communicationPreference
        )
    );

    if (checkCommPreferenceChange.length > 0) {
      Modal.confirm({
        title: 'Confirm communication update',
        content: t('form.confirm.update_client_communication'),
        cancelText: 'Cancel',
        okText: 'Yes',
        onOk: handleEditSaveClientData
      });
    } else {
      await handleEditSaveClientData();
    }
  };

  const onStatusProfileChange = (clientStatus: boolean) => {
    setClientFormData({
      ...clientFormData,
      recordStatus: clientStatus ? 'active' : 'closed'
    });
  };

  const getFormType = () => {
    switch (clientRecordData.recordType) {
      case 'adult':
        return (
          <EditAdultForm
            clientRecordId={clientFormData._id}
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            excludeEmailCheckingGroup={emailCollection}
            excludeCaseIdChecking={initCaseIdValue}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientRecordData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
          />
        );
      case 'child':
        return (
          <EditChildForm
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            onChangeClass={handleChangeClass}
            classValue={clientFormData.class}
            excludeCaseIdChecking={initCaseIdValue}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientRecordData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
          />
        );
      case 'couple':
        return (
          <EditCoupleForm
            clientRecordId={clientFormData._id}
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            excludeCaseIdChecking={initCaseIdValue}
            recordStatus={clientRecordData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
          />
        );
      default:
        return (
          <EditChildForm
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            onChangeClass={handleChangeClass}
            classValue={clientFormData.class}
            excludeCaseIdChecking={initCaseIdValue}
            profileType={clientFormData.clientProfiles[0].profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientRecordData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
          />
        );
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.title}>{t('title.edit_client_information')}</div>}
      visible={visible}
      onCancel={() => onCancel(false)}
      footer={null}
    >
      <div>
        <div className={styles.formContainer}>{getFormType()}</div>
      </div>
    </Modal>
  );
};

export default EditClientModal;
