import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';

import styles from './MaterialPhoneInput.module.scss';
import LoadingCircle from '../LoadingCircle/LoadingCircle';

export interface MaterialPhoneInputProps {
  id?: string;
  label: string;
  value: string;
  onChange: any;
  isError: boolean;
  isLoading?: boolean;
}

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const PREFERRED_COUNTRY = GAE_REGION === 'au' ? 'au' : GAE_REGION === 'gb' ? 'gb' : undefined;

const MaterialPhoneInput = ({ id, label, value, onChange, isError, isLoading }: MaterialPhoneInputProps) => {
  return (
    <>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.phoneInputWrapper}>
        <PhoneInput
          inputClass={classnames(styles.mobileNumberInput, isError && styles.redBorderBottom)}
          buttonClass={classnames(styles.countrySelect, isError && styles.redBorderBottom)}
          dropdownClass={classnames(styles.countryDropdown)}
          value={value}
          onChange={(value, data, event, formattedValue) => {
            onChange(formattedValue);
          }}
          inputProps={{
            id
          }}
          {...(PREFERRED_COUNTRY && {
            country: PREFERRED_COUNTRY,
            preferredCountries: [PREFERRED_COUNTRY]
          })}
        />
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingBox}>
              <LoadingCircle />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MaterialPhoneInput;
