import { useContext } from 'react';

import { UserContext } from '../UserContextProvider';
import { useGetAccountPackageView } from '../hooks/GetAccountInfo/accountPackageView';
import { TacklitResource } from 'interfaces/Profile/Profile';
import { isValueAccessRight } from 'interfaces/Clients/clinician';

export const useGetPermissionToggle = () => {
  const { firstPath } = useGetAccountPackageView();
  const { accountSettings } = useContext(UserContext);

  const getIsPermissionDeniedChecker = (action: 'readDenied' | 'writeDenied') => ({
    resourceName
  }: {
    resourceName: TacklitResource;
  }): boolean =>
    isValueAccessRight(firstPath) && !!accountSettings?.permissions?.[firstPath]?.[action]?.includes(resourceName);

  const isInvoiceReadDenied = getIsPermissionDeniedChecker('readDenied')({ resourceName: TacklitResource.Invoice });
  const isAppointmentRateReadDenied = getIsPermissionDeniedChecker('readDenied')({
    resourceName: TacklitResource.AppointmentRate
  });

  return {
    isInvoiceReadDenied,
    isAppointmentRateReadDenied
  };
};
