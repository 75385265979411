import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import { getPractitionerListing } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useTranslation } from 'react-i18next';

export const useFetchClinicians = (token: string) => {
  const { accountId } = useGetAccountId();

  const [t] = useTranslation();

  const [clinicians, setClinicians] = useState<PractitionersListing>({
    practitionerList: {}
  } as PractitionersListing);

  const [isCliniciansLoading, setIsCliniciansLoading] = useState(true);

  const fetchPractitionersList = async (token: string) => {
    try {
      const cliniciansData = await getPractitionerListing(
        token,
        accountId,
        `status=active&withAccessRights=${[AccessRight.Admin, AccessRight.User, AccessRight.Mentor].join(',')}`
      );
      const cliniciansJsonData = await cliniciansData.json();

      setClinicians(cliniciansJsonData as PractitionersListing);
    } catch (ex) {
      notification.error({ message: t('form.error.failed_to_retrieve_practitioner_record') });
    }

    setIsCliniciansLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPractitionersList(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { clinicians, isCliniciansLoading, fetchPractitionersList, setClinicians };
};
