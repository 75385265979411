import { useState, useRef, useEffect } from 'react';
import styles from './FinderWizardMultipleSelect.module.scss';

import { OptionInterface, TIME_OPTIONS, DAY_OPTIONS } from '../../constants';

interface FinderWizardMultipleSelectProps {
  label: string;
  tooltipContent?: string;
  daySelectedProp: OptionInterface[];
  timeSelectedProp?: OptionInterface[];
  isShowTime: boolean;
  onChangeDayProp: (days: OptionInterface[]) => void;
  onChangeTimeProp?: (times: OptionInterface[]) => void;
}

const FinderWizardMultipleSelect = (props: FinderWizardMultipleSelectProps) => {
  const { label, isShowTime, daySelectedProp, timeSelectedProp, onChangeDayProp, onChangeTimeProp } = props;
  const [showOptions, setShowOptions] = useState(false);
  const controlNode = useRef<HTMLDivElement>(null);
  const optionContainerNode = useRef<HTMLDivElement>(null);

  const [daysSelected, setDaysSelected] = useState(daySelectedProp);
  const [daysSelectedLabel, setDaysSelectedLabel] = useState('All days');

  const [timesSelected, setTimesSelected] = useState(timeSelectedProp);
  const [timesSelectedLabel, setTimesSelectedLabel] = useState('All times');

  const onChangeDay = (event: any, date: string) => {
    let newDaysSelected = [...daysSelected];
    // Checked
    if (event.target.checked) {
      if (date === 'allDays') {
        newDaysSelected = DAY_OPTIONS;
      } else {
        const selected = DAY_OPTIONS.find((item) => item.value === date);
        if (selected) {
          newDaysSelected.push(selected);
          if (newDaysSelected.length === 7 && !newDaysSelected.includes(DAY_OPTIONS[0])) {
            newDaysSelected.push(DAY_OPTIONS[0]);
          }
        }
      }
    }
    // Unchecked
    else {
      if (date !== 'allDays') {
        newDaysSelected = daysSelected.filter((item) => item.value !== date && item.value !== 'allDays');
      } else {
        newDaysSelected = daysSelected.filter((item) => item.value !== date);
      }
    }
    setDaysSelected(newDaysSelected);
    onChangeDayProp(newDaysSelected);
  };

  const onChangeTime = (event: any, time: string) => {
    if (timesSelected) {
      let newTimesSelected = [...timesSelected];
      // Checked
      if (event.target.checked) {
        if (time === 'allTimes') {
          newTimesSelected = TIME_OPTIONS;
        } else {
          const selected = TIME_OPTIONS.find((item) => item.value === time);
          if (selected) {
            newTimesSelected.push(selected);
            if (newTimesSelected.length === 7 && !newTimesSelected.includes(TIME_OPTIONS[0])) {
              newTimesSelected.push(TIME_OPTIONS[0]);
            }
          }
        }
      }
      // Unchecked
      else {
        if (time !== 'allTimes') {
          newTimesSelected = timesSelected.filter((item) => item.value !== time && item.value !== 'allTimes');
        } else {
          newTimesSelected = timesSelected.filter((item) => item.value !== time);
        }
      }
      setTimesSelected(newTimesSelected);
      if (onChangeTimeProp) {
        onChangeTimeProp(newTimesSelected);
      }
    }
  };

  const handleClickOutSide = (event: any) => {
    if (controlNode.current?.contains(event.target) || optionContainerNode.current?.contains(event.target)) {
      return;
    }
    // In case user unchecked 'All days' > click outside
    if (daysSelected.length === 7 && !daysSelected.includes(DAY_OPTIONS[0])) {
      setDaysSelected(DAY_OPTIONS);
    }
    if (isShowTime && timesSelected?.length === 3 && !timesSelected.includes(TIME_OPTIONS[0])) {
      setTimesSelected(TIME_OPTIONS);
    }
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    if (!showOptions) {
      let newDaysSelectedLabel = '';
      if (daysSelected.length === 1) {
        newDaysSelectedLabel = daysSelected[0].label;
      } else if (daysSelected.length === 8) {
        newDaysSelectedLabel = DAY_OPTIONS[0].label;
      } else {
        newDaysSelectedLabel = `${daysSelected.length} Days`;
      }
      setDaysSelectedLabel(newDaysSelectedLabel);

      if (timesSelected) {
        let newTimesSelectedLabel = '';
        if (timesSelected.length === 1) {
          newTimesSelectedLabel = timesSelected[0].label;
        } else if (timesSelected.length === 4) {
          newTimesSelectedLabel = TIME_OPTIONS[0].label;
        } else if (timesSelected.includes(TIME_OPTIONS[1]) && timesSelected.includes(TIME_OPTIONS[2])) {
          newTimesSelectedLabel = 'AM + PM times';
        } else if (timesSelected.includes(TIME_OPTIONS[2]) && timesSelected.includes(TIME_OPTIONS[3])) {
          newTimesSelectedLabel = 'PM + Evening';
        } else if (timesSelected.includes(TIME_OPTIONS[1]) && timesSelected.includes(TIME_OPTIONS[3])) {
          newTimesSelectedLabel = 'AM + Evening';
        }
        setTimesSelectedLabel(newTimesSelectedLabel);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const onClickSelectHandle = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{label}</div>
      <div className={styles.content}>
        <div ref={controlNode} className={styles.selectedContainer} onClick={onClickSelectHandle}>
          <div className={styles.selectedValue}>
            <span className={styles.firstValue}>{daysSelectedLabel}</span>
            {isShowTime && (
              <>
                <span> | </span>
                <span className={styles.secondValue}>{timesSelectedLabel}</span>
              </>
            )}
          </div>
          <span className={`material-icons-outlined ${styles.controller}`}>arrow_drop_down</span>
        </div>
        {showOptions && (
          <div ref={optionContainerNode} className={styles.optionsContainer}>
            <div className={styles.leftContainer}>
              {DAY_OPTIONS.map((day, index) => {
                return (
                  <label key={`day-${index}`} className={styles.optionItem}>
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={daysSelected.includes(day)}
                      onChange={(event) => onChangeDay(event, day.value)}
                    />
                    <div className={styles.optionLabel}>
                      <span className={styles.mainLabel}>{day.label}</span>
                    </div>
                  </label>
                );
              })}
            </div>
            {isShowTime && (
              <div className={styles.rightContainer}>
                {TIME_OPTIONS.map((time, index) => {
                  return (
                    <label key={`time-${index}`} className={styles.optionItem}>
                      <input
                        className={styles.checkbox}
                        type="checkbox"
                        checked={timesSelected?.includes(time)}
                        onChange={(event) => onChangeTime(event, time.value)}
                      />
                      <div className={styles.optionLabel}>
                        <span className={styles.mainLabel}>{time.label}</span>
                        <span className={styles.optionSubLabel}>{time.subLabel}</span>
                      </div>
                    </label>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FinderWizardMultipleSelect;
