import styles from './InterventionItem.module.scss';
import Button from 'components/v2/Button/Button';
import classnames from 'classnames';

import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { ActionPlanInterventionItem } from '../../InterventionList';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import { InterventionListData } from 'MHH/pages/Interventions/Interfaces/Interventions';
import Select from 'components/Select/CommonSelect/Select';
import NoActionItem from './components/NoActionItem/NoActionItem';

interface InterventionItemProps {
  index: number;
  isView?: boolean;
  isCreate?: boolean;
  intervention: ActionPlanInterventionItem;
  selectedId?: string;
  interventionOptions: InterventionListData['interventions'];
  focusAreaOptions: string[];
  onChangeValues: (key: keyof ActionPlanInterventionItem, index: number, value: string | string[]) => void;
  onDelete: (index: number) => void;
  onEdit: (index: number, subIntervention?: ActionPlanInterventionItem) => void;
  onTakeNoAction: (index: number) => void;
  onSave: (index: number) => void;
}

const InterventionItem = ({
  index,
  isView,
  intervention,
  selectedId,
  interventionOptions,
  focusAreaOptions,
  onChangeValues,
  onDelete,
  onEdit,
  onTakeNoAction,
  onSave
}: InterventionItemProps) => {
  const handleDueDateChange = (value: Moment | null, index: number) => {
    if (value) {
      const dateString = value.format('YYYY-MM-DD');
      onChangeValues('dueDate', index, dateString);
    } else {
      onChangeValues('dueDate', index, '');
    }
  };

  const handleRemoveIntervention = (selectedIdIndex: number) => {
    const newInterventionIds = [...(intervention.selectedInterventionIds || [])];
    newInterventionIds.splice(selectedIdIndex, 1);
    onChangeValues('selectedInterventionIds', index, newInterventionIds);
  };

  const handleOnCheckRecommend = (id: string, value: boolean) => {
    if (value) {
      onChangeValues('selectedInterventionIds', index, [...(intervention.selectedInterventionIds || []), id]);
    } else {
      const newInterventionIds = [...(intervention.selectedInterventionIds || [])];
      const removedIntIndex = newInterventionIds.findIndex((intId) => intId === id);
      newInterventionIds.splice(removedIntIndex, 1);
      onChangeValues('selectedInterventionIds', index, newInterventionIds);
    }
  };

  const interventionItemList = (interventionIds: string[]) => {
    return interventionIds.map((item, intIndex) => {
      const subIntervention = intervention.subInterventions && intervention.subInterventions[intIndex];
      const currentIntervention = subIntervention || intervention;
      return (
        <div
          className={classnames(
            styles.viewContainer,
            !!selectedId && currentIntervention._id === selectedId && styles.selectedIntervention
          )}
          key={intIndex}
          onClick={() => (!intervention.focusArea || isView) && onEdit(index, subIntervention)}
        >
          {currentIntervention.isCompleted ? (
            <i className={`material-icons ${styles.completedIcon}`}>check_circle</i>
          ) : (
            <i className={`material-icons-outlined ${styles.notCompletedIcon}`}>circle</i>
          )}
          <div className={classnames(styles.description, currentIntervention.isCompleted && styles.completed)}>
            <div className={styles.title}>
              {interventionOptions.find((int) => int._id === item)?.name || currentIntervention.name}
            </div>
            <div className={styles.dueDate}>
              {currentIntervention.isCompleted ? (
                <>
                  <span className={styles.completedLabel}>COMPLETED</span>:{' '}
                  {currentIntervention.completedAt
                    ? moment(currentIntervention.completedAt, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '-'}
                </>
              ) : (
                `Due by: ${
                  currentIntervention.dueDate
                    ? moment(currentIntervention.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '-'
                }`
              )}
            </div>
          </div>
          <i className={`material-icons ${styles.chevronRightIcon}`}>chevron_right</i>
        </div>
      );
    });
  };
  const handleFocusAreaChange = (e: unknown) => {
    const val = e as { label: string; value: string };
    onChangeValues('focusArea', index, val.value);
  };

  return (
    <div className={styles.container}>
      {intervention.isEdit && (!intervention.isCompleted || intervention.focusArea) ? (
        <div className={styles.editContainer}>
          {(intervention.focusArea || focusAreaOptions.length > 0) && (
            <>
              <div className={styles.focusAreaWrapper}>
                <div className={styles.label}>FOCUS AREA</div>
                {focusAreaOptions.length === 0 || intervention.isDefault ? (
                  <span className={styles.title}>
                    {intervention.focusArea}
                    {intervention.scoreScale?.map((score, index) => (
                      <label className={styles.badge} key={index}>
                        {score}
                      </label>
                    ))}
                  </span>
                ) : (
                  <div className={styles.fieldContainer}>
                    <Select
                      options={[...focusAreaOptions, ...(intervention.focusArea ? [intervention.focusArea] : [])]?.map(
                        (item) => ({
                          label: item,
                          value: item
                        })
                      )}
                      value={intervention.focusArea}
                      placeholder={'Select focus area'}
                      onChange={handleFocusAreaChange}
                      isSearchable={false}
                      isClearable={false}
                      smallCaretDown
                      smallCaretDownClass={styles.smallCaretDownClass}
                      styles={{
                        container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
                        valueContainer: (base: any) => ({
                          ...base,
                          minHeight: '30px',
                          padding: '8px'
                        }),
                        control: (controlBase: any) => ({
                          ...controlBase,
                          minHeight: '54px',
                          backgroundColor: 'white',
                          border: `1px solid ${styles.greyColor}`,
                          borderRadius: '4px',
                          boxShadow: 'none'
                        }),
                        singleValue: (singleValueBase: any) => ({
                          ...singleValueBase,
                          fontSize: '16px',
                          fontWeight: 600
                        })
                      }}
                    />
                  </div>
                )}
              </div>
              {intervention.recommendations && intervention.recommendations.length > 0 && (
                <div className={styles.recommendWrapper}>
                  <div className={styles.label}>RECOMMENDED</div>
                  {intervention.recommendations?.map((item) => {
                    const isCompleted = intervention.subInterventions
                      ? intervention.subInterventions.find((sub) => sub.interventionId === item._id)?.isCompleted
                      : intervention.isCompleted;
                    return (
                      <label className={styles.checkboxContainer} key={item._id}>
                        <div className={styles.inputContainer}>
                          <input
                            className={styles.checkbox}
                            type="checkbox"
                            checked={!!intervention.selectedInterventionIds?.includes(item._id)}
                            onChange={(e) => handleOnCheckRecommend(item._id, e.target.checked)}
                            disabled={isCompleted}
                          />
                        </div>
                        <div className={styles.labelContainer}>
                          <div className={classnames(styles.label, isCompleted && styles.completed)}>{item.name}</div>
                          {item.description && <div className={styles.subLabel}>{item.description}</div>}
                        </div>
                      </label>
                    );
                  })}
                </div>
              )}
            </>
          )}
          <div className={classnames(styles.fieldContainer, intervention.errorMessage?.list && styles.fieldError)}>
            <MaterialDropdown
              id={`interventions${index}`}
              label={'Assign Other Interventions'}
              placeholder={'Select from list'}
              dropDownList={
                interventionOptions
                  .filter((item) => !intervention.recommendations?.includes(item))
                  .map((int) => ({ label: int.name, value: int._id })) || []
              }
              selectedValue={''}
              onChangeValue={(value) =>
                onChangeValues('selectedInterventionIds', index, [
                  ...(intervention.selectedInterventionIds || []),
                  value
                ])
              }
            />
            <div className={styles.fieldError}>{intervention.errorMessage?.selectedInterventionIds}</div>
            <div className={styles.interventionNames}>
              {intervention.selectedInterventionIds?.map(
                (id, index) =>
                  !intervention.recommendations?.find((item) => id === item._id) && (
                    <label
                      className={classnames(
                        styles.interventionBadge,
                        (intervention.subInterventions && intervention.subInterventions?.length > 0
                          ? intervention.subInterventions[index]?.isCompleted
                          : intervention.isCompleted) && styles.completedBadge
                      )}
                      key={index}
                    >
                      {interventionOptions.find(({ _id }) => _id === id)?.name}
                      <i
                        className={`material-icons-outlined ${styles.icon}`}
                        onClick={() => handleRemoveIntervention(index)}
                      >
                        close
                      </i>
                    </label>
                  )
              )}
            </div>
          </div>
          <div className={styles.dueDateAndButtonWrapper}>
            <div className={styles.dueDateWrapper}>
              <div className={styles.label}>REVIEW/DUE DATE</div>
              <DatePicker
                format={'DD MMM YYYY'}
                className={styles.dateInput}
                suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
                value={intervention.dueDate ? moment(intervention.dueDate, 'YYYY-MM-DD') : undefined}
                onChange={(e) => handleDueDateChange(e, index)}
                allowClear={false}
              />
              <ErrorMessage
                className={styles.errorMargin}
                visible={!!intervention.errorMessage?.dueDate}
                error={intervention.errorMessage?.dueDate}
              />
            </div>
            <div className={styles.buttonWrapper}>
              {intervention.isDefault && intervention.focusArea && (
                <Button className={styles.noActionButton} onClick={() => onTakeNoAction(index)}>
                  Take no action
                </Button>
              )}
              <div className={styles.saveButtonWrapper}>
                <Button className={styles.saveButton} onClick={() => onSave(index)}>
                  {intervention.isOld ? 'Update' : 'Add'} interventions
                </Button>
                <ErrorMessage
                  className={styles.editModeError}
                  visible={!!intervention.errorMessage?.editMode}
                  error={intervention.errorMessage?.editMode}
                />
              </div>
            </div>
          </div>
          {!intervention.subInterventions?.some(({ isCompleted }) => isCompleted) && (
            <span className={styles.deleteButton} onClick={() => onDelete(index)}>
              Remove intervention
            </span>
          )}
        </div>
      ) : (
        <div
          className={styles.interventionItem}
          onClick={() => ((intervention.focusArea && !isView) || intervention.noAction) && onEdit(index)}
        >
          {intervention.focusArea && (
            <>
              <div className={styles.label}>FOCUS AREA</div>
              <div className={styles.title}>{intervention.focusArea}</div>
            </>
          )}
          {intervention.noAction ? (
            <NoActionItem
              noAction={intervention.noAction}
              isSelected={!!selectedId && intervention._id === selectedId}
            />
          ) : (
            interventionItemList(
              (intervention.focusArea && intervention.selectedInterventionIds) ||
                (intervention.interventionId ? [intervention.interventionId] : [])
            )
          )}
        </div>
      )}
    </div>
  );
};

export default InterventionItem;
