import moment from 'moment';
import { Clinician } from 'utils/hooks/clinician';

export const initReportDataFunc = (reportName: string, isEdgeAdminView: boolean) => {
  return {
    issueDate: moment().format('YYYY-MM-DD'),
    clinician: {},
    practice: {},
    clientRecord: {
      clientProfiles: []
    },
    contactDetails: {},
    reportName: reportName,
    status: 'draft',
    lastUpdatedTime: new Date(),
    isEditing: false,
    isForPractice: isEdgeAdminView,
    template: {
      dimensions: {
        height: 500,
        width: 840
      }
    },
    shareDetails: {
      accessors: []
    },
    items: [],
    clinicianSignature: {
      type: '',
      textVal: '',
      drawVal: ''
    },
    clinicianSignatureExtraDetails: {
      valediction: 'Kind Regards',
      email: '',
      mobileNumber: '',
      name: '',
      other: ''
    }
  };
};

export const getClinicianDetails = (user: any, clinician?: Clinician) => {
  if (clinician) {
    const { avatar, name, practice, title } = clinician;
    const { email } = user;
    const mobileNumber = user['https://tacklit.com/mobile_number'];
    const firstPractice = practice ? practice : undefined;

    return {
      clinician: {
        avatar,
        email,
        mobileNumber,
        name: `${title || ''}${name ? ` ${name}` : ''}`
      },
      practice: firstPractice
        ? {
            address: firstPractice?.locations?.[0],
            logo: firstPractice?.logo,
            mobileNumber: firstPractice?.mobileNumber,
            name: firstPractice?.name
          }
        : undefined
    };
  } else {
    return {};
  }
};
