import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getGroupAdHocSurveyList } from 'utils/http/CheckInService/Assessment/groupAssessment';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';

export const useFetchGroupSurveyResponseList = (token: string, groupId: string) => {
  const [surveyResponseList, setSurveyResponseList] = useState<SurveyFormResponseList[]>([]);
  const [isSurveyResponseListLoading, setIsSurveyResponseListLoading] = useState(true);

  const fetchGroupSurveyResponseList = useCallback(
    async (token: string) => {
      try {
        setIsSurveyResponseListLoading(true);
        const getSurveyListData = await getGroupAdHocSurveyList(token, groupId);
        const surveyListData: SurveyFormResponseList[] = await getSurveyListData.json();
        setSurveyResponseList(surveyListData);
        setIsSurveyResponseListLoading(false);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get this group survey list' });
      }
    },
    [groupId]
  );

  useEffect(() => {
    if (token) {
      fetchGroupSurveyResponseList(token);
    }
  }, [fetchGroupSurveyResponseList, token]);

  return { surveyResponseList, isSurveyResponseListLoading, fetchGroupSurveyResponseList };
};
