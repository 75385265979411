import {
  InterventionDetailsId,
  InterventionDetailsTab,
  Interventions
} from 'MHH/pages/Interventions/Interfaces/Interventions';
import styles from './InterventionContent.module.scss';
import Details from './components/Details/Details';
import Members from './components/Members/Members';
import InterventionAssignment from './components/InterventionAssignment/InterventionAssignment';
import KeyContacts from './components/KeyContacts/KeyContacts';
import { ProfileInterface } from 'interfaces/Profile/Profile';

import PathwayManagement from './components/PathwayManagement/PathwayManagement';

export interface InterventionContentProps {
  interventionDetails: Interventions;
  interventionTagList: Interventions['tags'];
  activeInterventionTab: InterventionDetailsId['interventionTab'] | string;
  profile: ProfileInterface;
  fetchInterventionDetails: (token: string) => void;
  allowEditAssigned: boolean;
  isEdgeReceptionist: boolean;
  isInterventionDetailsLoading: boolean;
}

const InterventionContent = ({
  interventionDetails,
  interventionTagList,
  activeInterventionTab,
  profile,
  fetchInterventionDetails,
  allowEditAssigned,
  isEdgeReceptionist,
  isInterventionDetailsLoading
}: InterventionContentProps) => {
  const getContentComponents = () => {
    switch (activeInterventionTab) {
      case InterventionDetailsTab.Details:
        return (
          <Details
            interventionDetails={interventionDetails}
            interventionTagList={interventionTagList}
            profile={profile}
            fetchInterventionDetails={fetchInterventionDetails}
          />
        );
      case InterventionDetailsTab.Members:
        return <Members interventionDetails={interventionDetails} />;
      case InterventionDetailsTab.PractitionerAssigned:
        return (
          allowEditAssigned && (
            <InterventionAssignment
              interventionDetails={interventionDetails}
              fetchInterventionDetails={fetchInterventionDetails}
              isInterventionDetailsLoading={isInterventionDetailsLoading}
            />
          )
        );
      case InterventionDetailsTab.Contacts:
        return (
          <KeyContacts interventionDetails={interventionDetails} fetchInterventionDetails={fetchInterventionDetails} />
        );
      case InterventionDetailsTab.PathwayManagement:
        return (
          !isEdgeReceptionist && (
            <PathwayManagement
              interventionDetails={interventionDetails}
              fetchInterventionDetails={fetchInterventionDetails}
            />
          )
        );
    }
  };

  return <div className={styles.container}>{getContentComponents()}</div>;
};

export default InterventionContent;
