import classNames from 'classnames';

import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  title: string | JSX.Element;
  currentStep?: number;
  totalSteps?: number;
  backLabel?: string;
  onBack?: () => void;
  onCancel: () => void;
}

const ModalHeader = ({ title, currentStep, totalSteps, backLabel, onBack, onCancel }: ModalHeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      {currentStep && currentStep > 1 && (
        <button className={styles.backButton} onClick={onBack}>
          <span className="material-icons-outlined">arrow_back_ios</span>
          {backLabel || 'BACK'}
        </button>
      )}
      {totalSteps && currentStep && (
        <div className={styles.processContainer}>
          <div className={styles.processText}>
            STEP {currentStep} OF {totalSteps}
          </div>
          <div className={styles.processBarContainer}>
            {Array(totalSteps)
              .fill(null)
              .map((item, index) => (
                <div
                  key={index}
                  className={classNames(styles.processBar, {
                    [styles.active]: index < currentStep
                  })}
                />
              ))}
          </div>
        </div>
      )}
      <button onClick={onCancel} className={styles.closeButton}>
        <span className="material-icons-outlined">close</span>
      </button>
    </div>
  );
};

export default ModalHeader;
