import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { AppointmentSlots } from '../../../interfaces/Schedule/Appointment';
import { ProfileInterface } from '../../../interfaces/Profile/Profile';

export enum SessionStatus {
  Upcoming = 'upcoming',
  Completed = 'completed'
}

export interface GroupSessionList {
  sessions: GroupSessionDetails[];
  count: {
    upcoming: number;
    completed: number;
  };
}

export interface GroupSessionListFilterParameter {
  q?: string;
  sessionStatus?: SessionStatus;
}

export interface GroupSessionDetailsPath {
  groupId: string;
  sessionId: string;
}

export interface GroupSessionAttendee {
  clientRecord: clientRecordsInterface;
  clientRecordId: string;
  invited?: boolean;
  attended?: boolean;
  reasonForNonAttendance?: string;
}

export interface GroupDetails {
  label?: string;
  instructions?: string;
  deliveringPractitionerIds?: string[];
  deliveringPractitionerProfiles?: ProfileInterface[];
  attendees: GroupSessionAttendee[];
}

export interface GroupSessionDetails extends AppointmentSlots {
  groupDetails: GroupDetails;
  sessionTypeDescription: string;
  name: string;
}

interface GroupSessionAttendeeUpdate extends Omit<GroupSessionAttendee, 'clientRecord' | 'invited'> {}

export interface GroupSessionDetailsUpdate extends Omit<GroupDetails, 'deliveringPractitionerProfiles' | 'attendees'> {
  attendees?: GroupSessionAttendeeUpdate[];
}
