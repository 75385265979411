import classNames from 'classnames';
import Select from 'components/v2/Select/Select';
import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import styles from './AssessmentResultHeader.module.scss';

const AssessmentResultHeader = ({
  clientRecord,
  assessmentList,
  isAssessmentListLoading,
  selectedAssessmentId,
  selectedAssessment_Id,
  setSelectedAssessment_Id
}: {
  clientRecord: clientRecordsInterface;
  assessmentList?: ClinicalAssessmentResponseList[];
  isAssessmentListLoading: boolean;
  selectedAssessmentId?: string;
  selectedAssessment_Id?: string;
  setSelectedAssessment_Id: (v?: string) => void;
}) => {
  const selectedItem = assessmentList?.find((i) => i._id === selectedAssessment_Id);
  const renderOption = (item: ClinicalAssessmentResponseList, isValue?: boolean) => (
    <div className={classNames(styles.assessmentListOption, isValue && styles.value)}>
      <div className={styles.assignedByContainer}>{item.assessedBy}</div>
      <div className={styles.assignedOnContainer}>{moment(item.createdAt).format('DD MMMM YYYY')}</div>
    </div>
  );

  return (
    <div className={styles.header}>
      <div className={styles.name}>
        <div className={styles.studentName}>
          {clientRecord.clientProfiles[0].firstName + ' ' + clientRecord.clientProfiles[0].lastName}
        </div>
        <div className={styles.assessmentName}>
          <span className={styles.sameAssessmentIdCount}>
            {assessmentList?.filter((item) => item.id === selectedAssessmentId).length}
          </span>{' '}
          {selectedAssessmentId}
        </div>
      </div>
      <div className={styles.assignment}>
        <div className={styles.labels}>
          <div className={styles.assignedByContainer}>Assessed by</div>
          <div className={styles.assignedOnContainer}>Assessed on</div>
        </div>
        <Select
          filledArrow
          isLoading={isAssessmentListLoading}
          //@ts-ignore
          options={assessmentList
            ?.filter((item) => item.id === selectedAssessmentId)
            .map((i) => ({ value: i._id, label: renderOption(i) }))}
          onChange={(val) => setSelectedAssessment_Id(val?.value)}
          controlStyle={{ border: 'none', boxShadow: 'none', minHeight: '30px' }}
          valueStyle={{ padding: 0 }}
          isSearchable={false}
          //@ts-ignore
          value={
            selectedItem
              ? {
                  value: selectedItem._id,
                  label: renderOption(selectedItem, true)
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default AssessmentResultHeader;
