import { useAuth0 } from '@auth0/auth0-react';
import { Modal, Skeleton, notification } from 'antd';
import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import LoadingButton from 'components/v2/Button/Button';
import { Formik } from 'formik';
import { useState } from 'react';
import { putPaymentMethods } from 'utils/http/BillingService/Invoice/invoice';
import * as yup from 'yup';

import { PaymentMethod } from '../../../../interface';
import { OnlinePayment } from './components/OnlinePayment';
import styles from './PaymentMethodsModal.module.scss';

interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

const PAYMENT_TYPE_OPTIONS: SelectOption[] = [
  { label: 'Bank Transfer', value: 'bankTransfer' },
  { label: 'Online Payment', value: 'stripe' },
  { label: 'Paypal', value: 'paypal' },
  { label: 'Cash', value: 'cash' },
  { label: 'Other', value: 'other' }
];

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const CODE_LABEL = GAE_REGION === 'gb' ? 'Sort Code' : 'BSB Code';

const paymentMethodsSchema = yup.object().shape({
  paymentMethods: yup
    .array()
    .of(
      yup.object().shape({
        paymentType: yup.string().oneOf(['bankTransfer', 'stripe', 'paypal', 'cash', 'other']),
        bankName: yup.string().when('paymentType', {
          is: 'bankTransfer',
          then: yup.string().required('Please enter a bank name'),
          otherwise: yup.string()
        }),
        accountName: yup.string().when('paymentType', {
          is: 'bankTransfer',
          then: yup.string().required('Please enter an account name'),
          otherwise: yup.string()
        }),
        accountNumber: yup.string().when('paymentType', {
          is: 'bankTransfer',
          then: yup
            .string()
            .required('Please enter an account number')
            .test({
              name: 'accountNumberTest',
              message: 'Please enter numbers only',
              test: (value) => !isNaN(Number(value))
            }),
          otherwise: yup.string()
        }),
        code: yup.string().when('paymentType', {
          is: 'bankTransfer',
          then: yup
            .string()
            .required(`Please enter a ${CODE_LABEL}`)
            .test({
              name: 'codeTest',
              message: `Please enter a 6-digit ${CODE_LABEL}`,
              test: (value) => !isNaN(Number(value)) && value.length === 6
            }),
          otherwise: yup.string()
        }),
        email: yup.string().when('paymentType', {
          is: 'paypal',
          then: yup.string().email('Please enter a valid email').required('Please enter your paypal email address'),
          otherwise: yup.string().nullable()
        }),
        paypalMeLink: yup.string(),
        instructions: yup.string(),
        stripeAccountId: yup.string().when('paymentType', {
          is: 'stripe',
          then: yup.string().required('Please register a Stripe account'),
          otherwise: yup.string()
        })
      })
    )
    .min(1, 'Please add at least one payment option.')
});

const validateForm = (values: { paymentMethods: PaymentMethod[] }, submitForm: () => void) => {
  try {
    paymentMethodsSchema.validateSync(values, { abortEarly: false });
  } catch (ex) {
    console.log(ex);
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      const index = ex.inner[0].path.match(/\d+/)?.[0];
      if (index && parseInt(index, 10) > -1) {
        document.getElementById(`payment-method-${index}`)?.scrollIntoView({ inline: 'center' });
      }
    }
  }

  submitForm();
};

interface PaymentMethodsModalProps {
  paymentMethods: PaymentMethod[];
  isPaymentMethodsLoading: boolean;
  visible: boolean;
  onClose: (paymentMethods?: PaymentMethod[]) => void;
}

const PaymentMethodsModal = ({
  paymentMethods,
  isPaymentMethodsLoading,
  visible,
  onClose
}: PaymentMethodsModalProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleAddPaymentOptions = (
    values: { paymentMethods: PaymentMethod[] },
    setFieldValue: (field: string, value: any) => void
  ) => () => {
    setFieldValue('paymentMethods', [
      ...values.paymentMethods,
      {
        paymentType: 'bankTransfer',
        bankName: '',
        accountName: '',
        accountNumber: '',
        code: '',
        instructions: '',
        email: '',
        paypalMeLink: '',
        stripeFeeMethod: 'inclusive',
        stripeAccountId: ''
      }
    ]);
  };

  const handleSubmit = async (values: { paymentMethods: PaymentMethod[] }) => {
    setSaveButtonStatus('active');

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      }).catch(() => '');

      const paymentMethods = values.paymentMethods.map(
        ({
          _id,
          paymentType,
          bankName,
          accountName,
          accountNumber,
          code,
          instructions,
          email,
          paypalMeLink,
          stripeAccountId,
          stripeConnectedBy,
          stripeFeeMethod,
          stripeStatus,
          stripeConnectedBank,
          stripeConnectedAt,
          stripeUpdatedAt
        }) => ({
          _id,
          paymentType,
          ...(paymentType === 'bankTransfer' && {
            accountName,
            accountNumber,
            bankName,
            code
          }),
          ...(paymentType === 'stripe' && {
            stripeAccountId,
            stripeConnectedBy,
            stripeFeeMethod,
            stripeStatus,
            stripeConnectedBank,
            stripeConnectedAt,
            stripeUpdatedAt
          }),
          ...(paymentType === 'paypal' && {
            email,
            paypalMeLink
          }),
          ...(paymentType !== 'stripe' && {
            instructions
          })
        })
      );

      await putPaymentMethods(token, { paymentMethods });

      setSaveButtonStatus('finished');
      notification.success({
        message: 'Payment methods updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        onClose: () => setSaveButtonStatus('')
      });

      onClose(values.paymentMethods);
    } catch (ex) {
      setSaveButtonStatus('');
      notification.error({
        message: 'Something went wrong while trying to update your payment methods',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      footer={null}
      width={768}
      visible={visible}
      onCancel={() => onClose()}
      destroyOnClose
    >
      <div className={styles.title}>Add or Change payment options</div>
      <div className={styles.content}>
        <div className={styles.heading}>
          Set up the different payment options you would like to offer. You can then include or exclude for each invoice
          you create.
        </div>
        <Formik
          initialValues={{ paymentMethods }}
          validationSchema={paymentMethodsSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, values, setFieldValue, submitForm }) => {
            let paymentTypeOption = PAYMENT_TYPE_OPTIONS;
            if (values.paymentMethods.some(({ paymentType }) => paymentType === 'stripe')) {
              paymentTypeOption = PAYMENT_TYPE_OPTIONS.map((option) =>
                option.value === 'stripe'
                  ? {
                      ...option,
                      disabled: true
                    }
                  : option
              );
            }

            return (
              <>
                {!Array.isArray(errors.paymentMethods) && (
                  <ErrorMessage
                    className={styles.paymentMethodsError}
                    error={errors.paymentMethods}
                    visible={!!touched.paymentMethods}
                  />
                )}
                {isPaymentMethodsLoading ? (
                  <Skeleton active />
                ) : (
                  values.paymentMethods.map((paymentMethod, index) => (
                    <div className={styles.paymentMethod} key={index} id={`payment-method-${index}`}>
                      <div className={styles.label}>Select payment type</div>
                      <FormikSelect
                        id={`paymentMethods.${index}.paymentType`}
                        className={`${styles.paymentType} ${paymentMethod.stripeStatus && styles.disabled}`}
                        name={`paymentMethods.${index}.paymentType`}
                        options={paymentTypeOption}
                        isDisabled={paymentMethod.stripeStatus}
                        isOptionDisabled={(option: SelectOption) => option.disabled}
                        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                      />
                      {paymentMethod.paymentType === 'bankTransfer' && (
                        <>
                          <FormikMaterialInput
                            id={`paymentMethods.${index}.bankName`}
                            className={styles.bankName}
                            name={`paymentMethods.${index}.bankName`}
                            label="Bank Name"
                          />
                          <div className={styles.label}>
                            Account Name
                            <HelpOutLineWithTooltips
                              id={`paymentAccountName-${index}`}
                              desc={
                                'Please input your name, or the name of your company account if a business account.'
                              }
                            />
                          </div>
                          <FormikMaterialInput
                            id={`paymentMethods.${index}.accountName`}
                            className={styles.accountName}
                            name={`paymentMethods.${index}.accountName`}
                            label=""
                          />
                          <div className={styles.accountNumberAndCode}>
                            <FormikMaterialInput
                              id={`paymentMethods.${index}.accountNumber`}
                              className={styles.accountNumber}
                              name={`paymentMethods.${index}.accountNumber`}
                              label="Account Number"
                            />
                            <FormikMaterialInput
                              id={`paymentMethods.${index}.code`}
                              className={styles.code}
                              name={`paymentMethods.${index}.code`}
                              label={CODE_LABEL}
                            />
                          </div>
                        </>
                      )}
                      {paymentMethod.paymentType === 'paypal' && (
                        <>
                          <FormikMaterialInput
                            id={`paymentMethods.${index}.email`}
                            className={styles.email}
                            name={`paymentMethods.${index}.email`}
                            label="Email"
                          />
                          <FormikMaterialInput
                            id={`paymentMethods.${index}.paypalMeLink`}
                            className={styles.paypalMeLink}
                            name={`paymentMethods.${index}.paypalMeLink`}
                            label="Paypal.me link"
                          />
                        </>
                      )}
                      {paymentMethod.paymentType !== 'stripe' && (
                        <>
                          <div className={`${styles.label} ${styles.instructionsLabel}`}>
                            Instructions
                            <HelpOutLineWithTooltips
                              id={`paymentInstruction-${index}`}
                              desc={
                                "If you'd like payers to add an ID or reference with their payment please provide details here."
                              }
                            />
                          </div>
                          <FormikMaterialInput
                            id={`paymentMethods.${index}.instructions`}
                            className={styles.instructions}
                            name={`paymentMethods.${index}.instructions`}
                            label=""
                          />
                        </>
                      )}
                      {paymentMethod.paymentType === 'stripe' && (
                        <OnlinePayment paymentMethod={paymentMethods[index]} index={index} />
                      )}
                    </div>
                  ))
                )}
                <div className={styles.buttonRow}>
                  <Button
                    className={styles.addButton}
                    type="button"
                    variant="secondary"
                    onClick={handleAddPaymentOptions(values, setFieldValue)}
                  >
                    <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                    Add another payment option
                  </Button>
                  <LoadingButton
                    className={styles.saveButton}
                    type="submit"
                    status={saveButtonStatus}
                    onClick={() => validateForm(values, submitForm)}
                  >
                    Save
                  </LoadingButton>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default PaymentMethodsModal;
