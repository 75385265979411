import { ChangeEvent } from 'react';

import MaterialInput from 'components/MaterialInput/MaterialInput';

import styles from './ExtraDetail.module.scss';

interface ExtraDetailProps {
  id: string;
  inputProps: {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  label: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ExtraDetail = ({ id, inputProps, label, checked, onChange }: ExtraDetailProps) => {
  return (
    <div className={styles.container}>
      <label className={styles.checkboxContainer} htmlFor={`checkbox-extraDetail-${id}`}>
        <input
          className={styles.checkbox}
          id={`checkbox-extraDetail-${id}`}
          type="checkbox"
          value={id}
          checked={checked}
          onChange={onChange}
        />
        <div className={styles.label}>{label}</div>
      </label>
      {checked && (
        <div className={styles.inputContainer}>
          <MaterialInput id={`input-extraDetail-${id}`} required {...inputProps} />
        </div>
      )}
    </div>
  );
};

export default ExtraDetail;
