import styles from './OptionList.module.scss';
import classnames from 'classnames';
import { Col, Row } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import ReadMore from 'components/ReadMore/ReadMore';
import { useState } from 'react';

interface Option {
  id: string;
  title?: string;
  description?: string;
}

interface OptionListProps {
  clientId?: string;
  title?: string;
  subTitle?: string;
  popularTxt?: string;
  listOption: Option[];
  selectedOptions: Option[];
  hideCheckbox?: boolean;
  onSelect: (isChecked: boolean, id: string) => void;
}

const OptionList = ({
  clientId,
  title,
  subTitle,
  popularTxt,
  listOption,
  selectedOptions,
  hideCheckbox,
  onSelect
}: OptionListProps) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredList = listOption.filter((listObj: Option) =>
    listObj.title?.toLowerCase().includes(searchValue.toLowerCase().trim())
  );

  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subtitle}>{subTitle}</div>}
      {popularTxt && <div className={styles.popular}>{popularTxt}</div>}
      <div className={styles.searchBar}>
        <input
          className={styles.input}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
        />
        <div className={styles.searchBarIcon}>
          <i className="material-icons-outlined">search</i>
        </div>
      </div>
      <div className={styles.optionListWrapper}>
        {filteredList.length > 0 ? (
          filteredList.map((option, index) => {
            const isSelected = !!selectedOptions.find((selected) => option.id === selected.id);
            return (
              <Row key={index} gutter={12} align="top" className={styles.option}>
                <Col span={!hideCheckbox ? 2 : 0}>
                  <CheckBox
                    className={!hideCheckbox ? styles.hiddenCheckbox : ''}
                    id={`${clientId}_${option.id}`}
                    value={isSelected}
                    disabled={!isSelected && selectedOptions.length >= 3}
                    onChange={(e) => onSelect(e.target.checked, option.id)}
                  />
                </Col>
                <Col span={!hideCheckbox ? 24 : 22}>
                  <label htmlFor={`${clientId}_${option.id}`}>
                    <div className={classnames(styles.optionTitle, isSelected ? styles.isSelected : '')}>
                      {option.title}
                    </div>
                    {option.description && <ReadMore maxHeight={60} text={option?.description || ''} />}
                  </label>
                </Col>
              </Row>
            );
          })
        ) : (
          <div className={styles.noItems}>
            <i className="material-icons-outlined">search_off</i>
            No matching assessment
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionList;
