import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ClientDetailsInsightsHeader.module.scss';
import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import ClientAvatar from '../../../../../../../../components/ClientAvatar/ClientAvatar';

interface ClientDetailsInsightsHeaderProps {
  recordId: string;
  currentTab: string;
  pathProfileId?: string;
  clientRecordData: clientRecordsInterface;
}

const ClientDetailsInsightsHeader = ({
  recordId,
  currentTab,
  pathProfileId,
  clientRecordData
}: ClientDetailsInsightsHeaderProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const { currentClientProfile, previousClientProfile, nextClientProfile } = useMemo(() => {
    const reportHeaderItems: {
      currentClientProfile?: clientProfilesInterface;
      previousClientProfile?: clientProfilesInterface;
      nextClientProfile?: clientProfilesInterface;
    } = { currentClientProfile: undefined, previousClientProfile: undefined, nextClientProfile: undefined };

    const clientProfiles = clientRecordData.clientProfiles;

    if (clientProfiles) {
      const currentProfile = clientProfiles.find((clientObj) => clientObj._id === pathProfileId);

      if (currentProfile) {
        reportHeaderItems.currentClientProfile = currentProfile;

        const currentIndex = clientProfiles.indexOf(currentProfile);

        if (currentIndex > -1) {
          reportHeaderItems.previousClientProfile = clientProfiles[currentIndex - 1];
          reportHeaderItems.nextClientProfile = clientProfiles[currentIndex + 1];
        }
      }
    }

    return reportHeaderItems;
  }, [pathProfileId, clientRecordData]);

  const handleSelectClient = (id: string) => {
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${id}`);

    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  return (
    <div className={styles.container}>
      {previousClientProfile && previousClientProfile.signupInvitation?.signupDate && (
        <div
          className={`${styles.btnBox} ${styles.left}`}
          onClick={() => handleSelectClient(previousClientProfile._id)}
        >
          <i className={`material-icons ${styles.icon}`}>chevron_left</i>
          <div className={styles.date}>
            <div className={styles.label}>{previousClientProfile.firstName}</div>{' '}
          </div>
        </div>
      )}
      {currentClientProfile && (
        <div className={styles.titleWrapper}>
          <ClientAvatar
            avatarUrl={currentClientProfile.avatar}
            initialsName={currentClientProfile.initials}
            avatarSize={40}
            horizontal
          />
          <div className={styles.clientNameWrapper}>
            <div>{currentClientProfile.firstName}</div>
            <div>Check-in Insights</div>
          </div>
        </div>
      )}
      {nextClientProfile && nextClientProfile.signupInvitation?.signupDate && (
        <div className={`${styles.btnBox} ${styles.right}`} onClick={() => handleSelectClient(nextClientProfile._id)}>
          <div className={styles.date}>
            <div className={styles.label}>{nextClientProfile.firstName}</div>{' '}
          </div>
          <i className={`material-icons ${styles.icon}`}>chevron_right</i>
        </div>
      )}
    </div>
  );
};

export default ClientDetailsInsightsHeader;
