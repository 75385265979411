import { useMemo, useState } from 'react';
import styles from './CaseNoteDetailForm.module.scss';
import { GroupCaseNoteInterface } from '../../interfaces';
import CaseNoteHeaderOption from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/CaseNoteHeaderOption';
import _, { debounce } from 'lodash';
import WidgetTextEditor from 'components/WidgetTextEditor/WidgetTextEditor';
import LoadingButton from 'components/v2/Button/Button';
import GroupCaseNoteInsert from '../GroupCaseNoteInsert/GroupCaseNoteInsert';
import { HeadingTemplate } from '../GroupCaseNoteHeadingTemplateList/GroupCaseNoteHeadingTemplateList';
import GroupCaseNoteAttachment from '../GroupCaseNoteAttachment/GroupCaseNoteAttachment';

interface CaseNoteDetailFormProps {
  groupId: string;
  caseNoteHeadingTemplates: HeadingTemplate[];
  onDeleteTemplate: (headingTemplateId: string) => void;
  isSubmitting: boolean;
  onAddTemplate: (newHeadingTemplate: HeadingTemplate) => void;
  noteId: string;
  token: string;
  formData: GroupCaseNoteInterface;
  saveButtonStatus: '' | 'active' | 'finished';
  onSubmit: any;
  onValueChange: (b: boolean) => void;
  safeguardingAlertExistInFormatSettings: boolean;
}

const CaseNoteDetailForm = ({
  groupId,
  caseNoteHeadingTemplates,
  formData,
  isSubmitting,
  saveButtonStatus,
  onSubmit,
  noteId,
  token,
  onDeleteTemplate,
  onAddTemplate,
  onValueChange,
  safeguardingAlertExistInFormatSettings
}: CaseNoteDetailFormProps) => {
  const { _id, ...groupCaseNoteInitialData } = formData;
  const [caseNoteDetails, setCaseNoteDetails] = useState(groupCaseNoteInitialData);
  const [caseNoteBody, setCaseNoteBody] = useState(groupCaseNoteInitialData.body);
  const [isShowError, setIsShowError] = useState(false);
  const [startEditing, setIsStartEditing] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);

  const debouncedSave = useMemo(
    () =>
      debounce(
        async (caseNote, startEditingVal) => {
          const safeguarding =
            !caseNote.safeguardingAlert ||
            (caseNote.safeguardingAlert && caseNote.safeguardingReason && caseNote.safeguardingReason.length > 1);
          if (
            validateField(caseNote) &&
            safeguarding &&
            caseNote.title.length > 0 &&
            !isSubmitting &&
            startEditingVal
          ) {
            await onSubmit(caseNote, true);
          }
        },
        noteId === 'new' ? 1000 : 2000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noteId, isSubmitting, onSubmit]
  );

  const validateField = (caseNoteValue: Omit<GroupCaseNoteInterface, '_id'>) => {
    const fieldWithMandatory = caseNoteValue.fields.filter((obj) => obj.mandatory && obj.name !== 'Safeguarding Alert');

    const checkFieldValue = fieldWithMandatory.filter((obj) =>
      obj.type !== 'multipleChoiceCount'
        ? !obj.value
        : obj.options.filter((countObj: any) => countObj.count > 0).length <= 0
    );

    const invalidFieldNumber = fieldWithMandatory.filter(
      (obj) => obj.type === 'number' && !obj.value?.match('^[0-9]*$')
    );

    const isSafeguardingMandatory =
      (safeguardingAlertExistInFormatSettings &&
        caseNoteValue.fields.find((obj) => obj.name === 'Safeguarding Alert')?.mandatory === true) ||
      false;

    const safeguardingValidate =
      ((isSafeguardingMandatory && caseNoteValue && typeof caseNoteValue.safeguardingAlert === 'boolean') ||
        !isSafeguardingMandatory) &&
      (!caseNoteValue.safeguardingAlert ||
        (caseNoteValue.safeguardingAlert && !_.isEmpty(caseNoteValue.safeguardingReason)));

    return (
      caseNoteValue.title.length > 0 &&
      safeguardingValidate &&
      checkFieldValue.length <= 0 &&
      invalidFieldNumber.length === 0
    );
  };

  const onChangeCaseNoteBody = (value: string) => {
    setIsStartEditing(true);
    setCaseNoteBody(value);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: value
    };
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleTitleChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      title: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleSafeguardingAlertChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingAlert: val,
      safeguardingReason: val ? caseNoteDetails.safeguardingReason : '',
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleSafegaurdingReasonChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingReason: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleFieldChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      fields: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, startEditing);
    }
  };

  const handleAttachmentUploadStatus = (uploadStatus: boolean) => {
    setUploadStatus(uploadStatus);
  };

  const handleAttachmentChange = async (attachmentVal: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: caseNoteBody,
      attachments: attachmentVal
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
    }
  };

  const handleSubmit = () => {
    const checkMandatoryFill = validateField(caseNoteDetails);
    setIsShowError(!checkMandatoryFill);
    if (checkMandatoryFill && caseNoteDetails.title) {
      const caseNoteObj = {
        ...caseNoteDetails,
        body: caseNoteBody
      };
      onSubmit(caseNoteObj, false);
    }
  };

  return (
    <>
      <CaseNoteHeaderOption
        noteId={noteId}
        recordId={groupId}
        fieldsData={caseNoteDetails.fields}
        safeguardingAlert={caseNoteDetails.safeguardingAlert}
        safeguardingReason={caseNoteDetails.safeguardingReason}
        title={caseNoteDetails.title}
        onChangeFieldValue={handleFieldChange}
        onChangeTitle={handleTitleChange}
        onChangeSafeguardingAlert={handleSafeguardingAlertChange}
        onChangeSafeguardingReason={handleSafegaurdingReasonChange}
        isValidated={isShowError}
      />
      <div className={styles.editor}>
        <WidgetTextEditor
          className="case-notes-editor"
          containerClass={styles.textEditorContainer}
          value={caseNoteBody}
          onChangeValue={(value: string) => onChangeCaseNoteBody(value)}
          fullHeightEditor
        />
        <GroupCaseNoteInsert
          groupId={groupId}
          caseNoteBodyVal={caseNoteBody}
          onChangeBody={onChangeCaseNoteBody}
          caseNoteHeadingTemplates={caseNoteHeadingTemplates}
          token={token}
          onDeleteTemplate={onDeleteTemplate}
          onAddTemplate={onAddTemplate}
        />
        <GroupCaseNoteAttachment
          token={token}
          groupId={groupId}
          caseNoteAttachmentData={caseNoteDetails.attachments}
          uploadStatus={handleAttachmentUploadStatus}
          onChangeCaseNoteAttachment={handleAttachmentChange}
        />
        <div className={styles.saveButtonContainer}>
          <LoadingButton
            className={styles.saveButton}
            type="submit"
            status={saveButtonStatus}
            disabled={uploadStatus || isSubmitting}
            onClick={!isSubmitting ? handleSubmit : undefined}
          >
            {uploadStatus ? 'Uploading...' : isSubmitting ? 'Saving...' : 'Save Note'}
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default CaseNoteDetailForm;
