import styles from './FacilitatorProfile.module.scss';
import { GroupDetails } from 'pages/Groups/Interfaces/GroupsSession';

interface FacilitatorProfileProps {
  profile: GroupDetails['deliveringPractitionerProfiles'];
  displayLimit?: number;
}

const FacilitatorProfile = ({ profile, displayLimit }: FacilitatorProfileProps) => {
  const limitAvatarDisplay = displayLimit || 1;
  const size = 50;
  const limitProfile = profile ? profile.filter((a, index) => index < limitAvatarDisplay) : [];
  const profileWithAvatar = limitProfile.filter((obj) => obj.avatar);
  const profileNoAvatar = limitProfile.filter((obj) => !obj.avatar);
  const totalClient = limitProfile?.length + profileNoAvatar.length || 0;
  const avatarWidth = size + 30 * (totalClient - 1);
  const leftPosition = size > 40 ? size - 20 : size - 10;
  const overlayCircle = true;
  const showBalanceLabel = true;

  return profile && profile.length > 0 ? (
    <div className={styles.avatarMultipleBox}>
      <div className={styles.avatarMultipleContainer} style={{ width: avatarWidth }}>
        {profileWithAvatar.map((facilitatorObj, index) => (
          <div
            className={styles.avatarMultipleWrapper}
            key={index}
            style={{
              height: `${size}px`,
              width: `${size}px`,
              left: overlayCircle ? `${leftPosition * index + 1}px` : ''
            }}
          >
            <img className={styles.imgProfile} src={facilitatorObj?.avatar} alt={'facilitator profile'} />
          </div>
        ))}
      </div>
      {showBalanceLabel && profile.length - limitAvatarDisplay > 0 && (
        <div className={styles.showBalanceLabel}>+{profile.length - limitAvatarDisplay}</div>
      )}
    </div>
  ) : (
    <div>-</div>
  );
};

export default FacilitatorProfile;
