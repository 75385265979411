import { ActionPlanFormInterface, ActionPlanProgress, ActionPlanStatus } from './interfaces';

export const statusOptions = [
  { value: ActionPlanStatus.Active, label: 'ACTIVE', color: '#C4D600' },
  { value: ActionPlanStatus.Closed, label: 'CLOSED', color: '#E03E52' }
];

export const progressOption = [
  { value: ActionPlanProgress.Ongoing, label: 'ON GOING', color: '#8D9BAE' },
  { value: ActionPlanProgress.OnTrack, label: 'ON TRACK', color: '#6F5091' },
  { value: ActionPlanProgress.OnHold, label: 'ON HOLD', color: '#BBC3CE' },
  { value: ActionPlanProgress.Completed, label: 'COMPLETED', color: '#1B365D' }
];

export const getInitActionPlan = (userId?: string): ActionPlanFormInterface => ({
  title: '',
  status: ActionPlanStatus.Active,
  progress: ActionPlanProgress.Ongoing,
  description: '',
  dueDate: '',
  ownerAuth0Id: userId || '',
  attachments: [],
  tasks: [],
  attachedAssessments: [],
  interventions: []
});

export const actionPlanFormErrors: { [key: string]: string } = {
  title: 'Please enter a title',
  ownerAuth0Id: 'Please select a plan owner',
  dueDate: 'Please select a due date',
  tasksAndInterventions: 'Please add a task or intervention',
  addMode: 'Please add this task to the list',
  editMode: 'Please confirm this change',
  interventions: 'Please add an intervention'
};

export const focusAreaMapping: { [key: string]: string } = {
  'Emotional problems': 'emotion',
  'Conduct problems': 'conduct',
  Hyperactivity: 'hyper',
  'Peer problems': 'peer',
  Prosocial: 'prosocial'
};
