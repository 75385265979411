import { useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { PractitionersStatus } from 'interfaces/Practitioners/practitionersListing';

import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchPractitioner, useFetchPractitionerListCount } from './hooks/GetPractitionersList';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import PractitionersListingHeader from './components/PractitionersListingHeader/PractitionersListingHeader';
import PractitionerListContent from './components/PractitionerListContent/PractitionerListContent';

import styles from './PractitionersListing.module.scss';

const PractitionersListing = () => {
  const { token } = useGetAccessToken();
  const { PRACTITIONER } = useRoutesGenerator();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParam: { q?: string; page?: string; status?: PractitionersStatus['status'] } = queryString.parse(
    location.search
  );

  const [filterType, setFilterType] = useState<PractitionersStatus['status']>(queryParam.status ?? 'active');
  const [searchFilterValue, setSearchFilterValue] = useState(queryParam.q ?? '');
  const [pageLoading, setPageLoading] = useState(false);

  const filterValue = {
    status: filterType,
    perPage: 10,
    page: queryParam.page || 1,
    q: queryParam.q || ''
  };

  const { practitionersList, isPractitionersListLoading, fetchPractitionersList, setPractitionersList } =
    useFetchPractitioner(token, filterValue);
  const { practitionersCount, isPractitionersCountLoading, fetchPractitionersCount, setPractitionersCount } =
    useFetchPractitionerListCount(token, filterValue);

  const onChangeTabStatus = async (tabValue: PractitionersStatus['status']) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.page && {
        page: 1
      }),
      ...(filterValue.q && {
        q: filterValue.q
      }),
      status: tabValue
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${PRACTITIONER.BASE}/${qParam ? `?${qParam}` : ''}`);
    setFilterType(tabValue);
    const newFilterValue = {
      status: tabValue,
      perPage: filterValue.perPage,
      page: 1,
      q: searchFilterValue
    };
    await fetchPractitionersList(token, newFilterValue);
    setPageLoading(false);
  };

  const onHandleSearch = async (value: string) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.status && {
        status: filterValue.status
      }),
      page: 1,
      q: value
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${PRACTITIONER.BASE}/${qParam ? `?${qParam}` : ''}`);
    setSearchFilterValue(value);
    const newFilterValue = {
      status: queryParam.status,
      perPage: filterValue.perPage,
      page: 1,
      q: value
    };
    await fetchPractitionersList(token, newFilterValue);
    await fetchPractitionersCount(token, newFilterValue);
    setPageLoading(false);
  };

  const onHandlePageChange = async (pageValue: string) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.q && {
        q: filterValue.q
      }),
      ...(filterValue.status && {
        status: filterValue.status
      }),
      page: pageValue
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${PRACTITIONER.BASE}/${qParam ? `?${qParam}` : ''}`);
    const newFilterValue = {
      status: filterValue.status,
      perPage: filterValue.perPage,
      page: pageValue,
      q: searchFilterValue
    };
    await fetchPractitionersList(token, newFilterValue);
    await fetchPractitionersCount(token, newFilterValue);
    setPageLoading(false);
  };

  const handleDeactivatePractitioner = (id: string) => {
    setPractitionersList({
      ...practitionersList,
      practitionerList: practitionersList.practitionerList.filter(({ _id }) => _id !== id)
    });
    setPractitionersCount({
      active: practitionersCount.active - 1,
      inactive: practitionersCount.inactive + 1
    });
  };

  const handleChangePractitionerRole = async () => {
    setPageLoading(true);
    await fetchPractitionersList(token, filterValue);
    setPageLoading(false);
  };

  return (
    <HelmetWrapper title={'CORDS - Staffs'}>
      <ContentLayout>
        <div className={styles.container}>
          <PractitionersListingHeader
            total={practitionersList.paging.totalItem || 0}
            isLoading={isPractitionersListLoading || isPractitionersCountLoading || pageLoading}
            currentTabFilter={filterType}
          />
          <PractitionerListContent
            practitionersList={practitionersList}
            isCountLoading={isPractitionersCountLoading}
            isPractitionersListLoading={isPractitionersListLoading}
            isPageLoading={pageLoading}
            onChangeTabStatus={onChangeTabStatus}
            filterValue={filterValue}
            practitionersCount={practitionersCount}
            onHandleSearch={onHandleSearch}
            onHandlePageChange={onHandlePageChange}
            onDeactivatePractitioner={handleDeactivatePractitioner}
            onChangePractitionerRole={handleChangePractitionerRole}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PractitionersListing;
