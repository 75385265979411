import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import { Session } from 'pages/Report/ReportDetails/interface';
import { useState } from 'react';

import styles from './SessionLock.module.scss';

interface SessionLockProps {
  session?: Session;
  onChangeSession: (bool: boolean) => void;
}

const SessionLock = ({ session, onChangeSession }: SessionLockProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const isChecked = (session && session.isOwner) ?? false;

  const handleOnChangeCheckedIn = async (bool: boolean) => {
    setIsLoading(true);
    await onChangeSession(bool);
    setIsLoading(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.messageToggleContainer}>
          <div className={styles.toggleMessage}>
            Take this document to <span>Editing Mode</span>
          </div>
          <MaterialToggleSwitch
            name={'check-in'}
            value={isChecked}
            isLoading={isLoading}
            onChange={(bool) => handleOnChangeCheckedIn(bool)}
          />
        </div>
        {isChecked && (
          <div className={styles.reminder}>
            This will revert back to Review Mode if inactive for 5 minutes. Changes will be autosaved.
          </div>
        )}
      </div>
      {session && !session.isOwner && (
        <div className={styles.sessionLockedContainer}>
          <img className={styles.avatar} src={session.avatar} alt={`${session.name}-avatar`} />
          <div className={styles.lockedMessage}>
            <span>{session.name}</span> is currently making edits. You will be able to edit after they have saved their
            update.
          </div>
        </div>
      )}
    </>
  );
};

export default SessionLock;
