import classNames from 'classnames';
import Checkbox from 'components/v2/Checkbox/Checkbox';
import { useField, useFormikContext } from 'formik';
import { DayType } from '../../../../constants';
import styles from './DaySelection.module.scss';
import TimeSelection from '../TimeSelection/TimeSelection';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { capitalize } from 'lodash';

interface DaySelectionProps {
  day: string;
}

const DEFAULT_TIME_SLOT = { startTime: '08:00 AM', endTime: '06:00 PM' };

const DaySelection = ({ day }: DaySelectionProps) => {
  const [, { value: isAvailable }, { setValue: setIsAvailable }] = useField<DayType['isAvailable']>(
    `availability.${day}.isAvailable`
  );
  const [, { value: timeSlots, error, touched }, { setValue: setTimeSlots }] = useField<DayType['timeSlots']>(
    `availability.${day}.timeSlots`
  );
  const { submitCount } = useFormikContext();
  const showError = typeof error === 'string' && (touched || submitCount > 0);

  const onIsAvailableToggle = () => {
    if (!isAvailable) {
      setTimeSlots([DEFAULT_TIME_SLOT]);
    }
    setIsAvailable(!isAvailable);
  };

  const onSplit = () => {
    setTimeSlots([...(timeSlots || []), DEFAULT_TIME_SLOT]);
  };

  const onRemove = (index: number) => () => {
    setTimeSlots(timeSlots.filter((timeslot, i) => i !== index));
  };

  return (
    <div
      className={classNames(styles.selectionRow, {
        [styles.active]: isAvailable
      })}
    >
      <div className={styles.checkboxContainer}>
        <Checkbox label={capitalize(day)} checked={isAvailable} onChange={onIsAvailableToggle} />
      </div>
      {isAvailable && (
        <>
          <div className={styles.timeSelectionLabel}>
            <div className={styles.timeLabel}>Set earliest appointment start times</div>
            <div className={styles.timeLabel}>Set latest appointment finish times</div>
          </div>
          <div className={styles.timeSelectionsContainer}>
            {timeSlots?.map((timeslot, index) => (
              <TimeSelection key={index} day={day} index={index} onSplit={onSplit} onRemove={onRemove(index)} />
            ))}
            <ErrorMessage error={error} visible={showError} className={styles.errorContainer} />
          </div>
        </>
      )}
    </div>
  );
};

export default DaySelection;
