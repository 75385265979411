import { CheckBoxListing } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';

import styles from './SurveySelect.module.scss';

interface SurveySelectProps {
  id: string;
  clientName?: string;
  value: CheckBoxListing[];
  checkValidation: boolean;
  surveyList: CheckBoxListing[];
  isListLoading: boolean;
  onChangeSurveyValue: (b: string) => void;
  onRemoveSurvey: (value: string) => void;
}

const SurveySelect = ({
  id,
  clientName,
  value,
  checkValidation,
  surveyList,
  isListLoading,
  onChangeSurveyValue,
  onRemoveSurvey
}: SurveySelectProps) => {
  const handleSurveyChange = (val: string) => {
    onChangeSurveyValue(val);
  };
  return (
    <div>
      <MaterialSelect
        id={`profile${id}`}
        label={clientName ? `${clientName}'s survey(s)` : 'Select survey(s)'}
        isSearchable={false}
        isDisabled={value.length >= 3}
        options={surveyList.filter((survey) => !value.find(({ value }) => value === survey.value))}
        value={''}
        onChange={(val: string) => handleSurveyChange(val)}
        loading={isListLoading}
      />
      <ErrorMessage
        className={styles.error}
        error={'Please select at least one survey'}
        visible={checkValidation && !value.length}
      />
      {value.length > 0 && (
        <div className={styles.surveysValueContainer}>
          {value.map(({ label, value }) => (
            <div className={styles.survey} key={value}>
              {label}
              <div className={styles.iconContainer} onClick={() => onRemoveSurvey(value)}>
                <i className={`material-icons-outlined ${styles.icon}`}>close</i>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SurveySelect;
