import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useEffect, useMemo, useState, KeyboardEvent } from 'react';

import CheckBox from '../../../../../CheckBox/CheckBox';
import MaterialInput from '../../../../../MaterialInput/MaterialInput';
import MaterialPhoneInput from '../../../../../MaterialPhoneInput/MaterialPhoneInput';
import { ClientDetails, newClientForm } from '../../../../AddPatientModalV2Interface';

import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { convertDateFormat, MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { checkPhoneNumber } from 'utils/http/NotifService/phoneValidator';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { COMMUNICATION_OPTIONS } from 'components/AddPatientModalV2/constants';
import styles from './AdultClientForm.module.scss';
import { validateEmail, validationClientForm } from './validation/AdultClientFormValidation';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

interface AdultClientFormProps {
  id: string | number;
  label?: string;
  clientDetail: ClientDetails;
  onChangeClientField: any;
  checkValidation: boolean;
  shadowBox?: boolean;
  dateOfBirthField?: boolean;
  disableEmailField?: boolean;
  capabilityData: newClientForm['clientCapability'];
  excludeEmailCheckingGroup?: string[];
}

const AdultClientForm = ({
  id,
  label,
  clientDetail,
  onChangeClientField,
  checkValidation,
  shadowBox,
  dateOfBirthField,
  disableEmailField,
  capabilityData,
  excludeEmailCheckingGroup
}: AdultClientFormProps) => {
  const { token } = useGetAccessToken();
  const [clientField, setClientField] = useState(clientDetail);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobileNumber: ''
  } as any);

  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [isCheckingDuplicate, setIsCheckingDuplicate] = useState(false);

  const [invalidPhone, setInvalidPhone] = useState(false);
  const [isCheckingPhone, setIsCheckingPhone] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (checkValidation) {
      const validate = validatePart1Field(clientField);
      const isNonEditEmailValue = excludeEmailCheckingGroup?.includes(clientField.email as string) || false;
      if (validate?.email === '' && clientField.email && !isNonEditEmailValue) {
        validateDuplicateEmail(clientField.email);
      }
      if (validate?.mobileNumber === '' && clientField.mobileNumber) {
        validatePhoneNumber(clientField.mobileNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation, capabilityData.profileType]);

  const validatePart1Field = (clientVal: ClientDetails) => {
    const validate = validationClientForm({ ...clientVal, profileType: capabilityData.profileType });
    setErrorMessage(validate);
    return validate;
  };

  const debouncedCheckDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateEmail(token, value);
        setDuplicateEmail(duplicate.statusCode !== 200);
        setIsCheckingDuplicate(false);
      }, 1000),
    [token]
  );

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientField,
      [key]: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const validateDuplicateEmail = async (emailValue: string) => {
    if (token) {
      setIsCheckingDuplicate(true);
      await debouncedCheckDuplicate(emailValue);
    }
  };

  const handleChangeEmail = async (value: string) => {
    const newClientField = {
      ...clientField,
      email: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      const validate = validatePart1Field(newClientField);
      const isNonEditEmailValue = excludeEmailCheckingGroup?.includes(value) || false;
      if (validate?.email === '' && !isNonEditEmailValue) {
        await validateDuplicateEmail(value);
      } else {
        setDuplicateEmail(false);
      }
    }
  };

  const debouncedValidatePhoneNumber = useMemo(
    () =>
      debounce(async (value) => {
        try {
          const duplicate = await checkPhoneNumber(value);
          setInvalidPhone(duplicate.statusCode !== 204);
          setIsCheckingPhone(false);
        } catch (e) {
          setInvalidPhone(true);
          setIsCheckingPhone(false);
        }
      }, 1000),
    []
  );

  const validatePhoneNumber = async (value: string) => {
    setIsCheckingPhone(true);
    await debouncedValidatePhoneNumber(value);
  };

  const handleChangePhoneNumber = async (value: string) => {
    const newClientField = {
      ...clientField,
      mobileNumber: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      const validate = validatePart1Field(newClientField);
      if (validate?.mobileNumber === '') {
        await validatePhoneNumber(value);
      }
    }
  };
  const handleKeyDownDateChange = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = clientField.dateOfBirth?.slice(0, clientField.dateOfBirth.length - 1);
      const newClientField = {
        ...clientField,
        dateOfBirth: newValue
      };
      setClientField(newClientField);
      onChangeClientField(newClientField);
      if (checkValidation) {
        validatePart1Field(newClientField);
      }
    }
  };

  const handleDateChange = (dateValue: string) => {
    const newDateFormat = convertDateFormat(dateValue);
    const newClientField = {
      ...clientField,
      dateOfBirth: newDateFormat
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const onCommunicationPreferenceChange = (value: ClientDetails['communicationPreference']) => {
    const newClientField = {
      ...clientField,
      communicationPreference: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
  };

  return (
    <div className={shadowBox ? styles.containerWithBox : styles.container}>
      {shadowBox && <div className={styles.labelContainer}>{label && <div className={styles.label}>{label}</div>}</div>}
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`clientFirstName`}
          label={`${t('form.client_first_name')}*`}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientField.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}>
        <MaterialInput
          id={`clientLastName`}
          label={`${t('form.client_last_name')}*`}
          onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
          value={clientField.lastName}
          required
        />
        {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
      </div>
      {dateOfBirthField && (
        <div
          className={classnames(
            styles.fieldContainer,
            checkValidation && errorMessage.dateOfBirth && styles.fieldError
          )}
        >
          <MaterialInput
            placeholder={MOMENTJS_DATE_FORMAT}
            id={`clientDateOfBirth`}
            label={'Date of Birth'}
            onKeyDown={(e) => handleKeyDownDateChange(e)}
            onChange={(e) => handleDateChange(e.target.value)}
            value={clientField.dateOfBirth}
            type={'tel'}
            pattern={'[0-9\\/]*'}
            maxLength={10}
            autoComplete={'off'}
          />
          {checkValidation && errorMessage.dateOfBirth && (
            <div className={styles.fieldError}>{errorMessage.dateOfBirth}</div>
          )}
        </div>
      )}
      <div className={styles.withCheckBoxContainer}>
        <div
          className={classnames(
            styles.fieldContainer,
            checkValidation && (errorMessage.email || duplicateEmail) && styles.fieldError
          )}
        >
          <MaterialInput
            id={`clientEmail`}
            label={`${t('form.client_email')}${capabilityData.profileType === 'full' ? '*' : ''}`}
            onChange={(e) => handleChangeEmail(e.target.value)}
            value={clientField.email}
            isLoading={isCheckingDuplicate}
            disabled={disableEmailField}
            required
          />
          {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
          {checkValidation && duplicateEmail && (
            <div className={styles.fieldError}>This email address is already in use</div>
          )}
          <div className={styles.checkBoxField}>
            <CheckBox
              id={`clientEmailIsThirdParty`}
              value={clientField.isEmailThirdParty || false}
              label={'Email belongs to third party e.g. friend or family'}
              onChange={(e) => handleChangeFieldValue('isEmailThirdParty', e.target.checked)}
            />
          </div>
        </div>
        {disableEmailField && (
          <div className={styles.disableMessageContainer}>
            <i className={`material-icons ${styles.lockIcon}`}>lock</i>
            <div className={styles.disabledMessage}>
              {t('form.error.client_verified')}
              <HelpOutLineWithTooltips
                id={'emailUpdateDisabled'}
                desc={t('form.error.client_verified.description')}
                iconClass={styles.helpIcon}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.withCheckBoxContainer}>
        <div className={classnames(styles.phoneContainer)}>
          <MaterialPhoneInput
            id={`clientMobileNumber`}
            label={`Mobile Number${capabilityData.profileType === 'full' ? '*' : ''}`}
            onChange={(e: string) => handleChangePhoneNumber(e)}
            value={clientField.mobileNumber || ''}
            isLoading={isCheckingPhone}
            isError={checkValidation && errorMessage.mobileNumber && invalidPhone}
          />
          {checkValidation && errorMessage.mobileNumber && (
            <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
          )}
          {checkValidation && invalidPhone && (
            <div className={styles.fieldError}>Please enter a valid mobile number</div>
          )}
          <div className={styles.checkBoxField}>
            <CheckBox
              id={`clientMobileIsThirdParty`}
              value={clientField.isMobileNumberThirdParty || false}
              label={'Mobile phone belongs to third party e.g. friend or family'}
              onChange={(e) => handleChangeFieldValue('isMobileNumberThirdParty', e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <MaterialSelect
          id={'clientCommunicationPreference'}
          label={'Communication Preference'}
          isSearchable={false}
          options={COMMUNICATION_OPTIONS}
          value={clientDetail.communicationPreference || CommunicationPreference.NoPreference}
          onChange={onCommunicationPreferenceChange}
        />
        {clientDetail.communicationPreference === 'none' && (
          <div className={styles.noneComms}>{t('form.error.client_no_digital_message')}</div>
        )}
      </div>
    </div>
  );
};

export default AdultClientForm;
