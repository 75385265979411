import styles from './NextAssessmentModal.module.scss';
import Button from 'components/v2/Button/Button';
import ModalV2 from 'components/ModalV2/ModalV2';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import LoadingDot from '../../../../../../../../components/LoadingDot/LoadingDot';

const NextAssessmentModal = ({
  visible,
  onCancel,
  nextClientRecord,
  isNextClientRecordLoading,
  handleGoToNextAssessment
}: {
  visible: boolean;
  onCancel: () => void;
  nextClientRecord: clientRecordsInterface;
  isNextClientRecordLoading: boolean;
  handleGoToNextAssessment: () => void;
}) => {
  return (
    <ModalV2 isModalOpen={visible} title={'Next Assessment Details'} onModalClose={() => onCancel()} hideClose>
      {isNextClientRecordLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.nextAssessmentRow}>
              <div className={styles.label}>
                Name<span>:</span>
              </div>
              <div>
                {nextClientRecord.clientProfiles?.[0].firstName + ' ' + nextClientRecord.clientProfiles?.[0].lastName}
              </div>
            </div>
            <div className={styles.nextAssessmentRow}>
              <div className={styles.label}>
                Class<span>:</span>
              </div>
              <div>{nextClientRecord.class || '-'}</div>
            </div>
            <div className={styles.nextAssessmentRow}>
              <div className={styles.label}>
                Group<span>:</span>
              </div>
              <div>
                {nextClientRecord.attachedGroups?.length
                  ? nextClientRecord.attachedGroups.map(({ groupName }) => groupName).join(', ')
                  : '-'}
              </div>
            </div>
            <div className={styles.tags}>
              {nextClientRecord.tags?.map((i) => (
                <div className={styles.tag} key={i}>
                  {i}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.modalFooter}>
            <Button
              className={styles.modalButton}
              disabled={isNextClientRecordLoading}
              onClick={handleGoToNextAssessment}
            >
              OK, Next Assessment
            </Button>
          </div>
        </>
      )}
    </ModalV2>
  );
};

export default NextAssessmentModal;
