import { notification } from 'antd';
import { useEffect, useState } from 'react';
import styles from './CreateInterventionModal.module.scss';
import InterventionForm from './components/InterventionForm/InterventionForm';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import Button from 'components/v2/Button/Button';
import { CreateInterventionRequestPayload, CreateInterventionValidateField } from './CreateInterventionModalInterfaces';
import { postNewIntervention } from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import { useGetAccessToken } from 'utils/hooks/token';
import { scrollToView } from 'utils/scrollToView';
import {
  validateInterventionId,
  validationInterventionNameForm
} from './components/InterventionForm/validation/InterventionFormValidation';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import ModalV2 from 'components/ModalV2/ModalV2';

interface CreateInterventionModalProps {
  visible: boolean;
  onReset: () => void;
  onCloseModal: () => void;
}

const CreateInterventionModal = ({ visible, onReset, onCloseModal }: CreateInterventionModalProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isEdgeReceptionist, isEdgeAdminView } = useGetAccountPackageView();

  const { auth0ClinicianId } = useGetClinicianId();
  const [selectedClinicianId, setSelectedClinicianId] = useState(
    isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId
  );

  const defaultFormValue: CreateInterventionRequestPayload = {
    interventionId: '',
    name: '',
    leadClinicianAuth0Id: '',
    description: '',
    interventionLibraryItemId: ''
  };
  const [interventionField, setInterventionField] = useState(defaultFormValue);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkValidation, setCheckValidation] = useState(false);

  const [errorMessage, setErrorMessage] = useState<CreateInterventionValidateField>({
    name: '',
    interventionLibraryItemId: ''
  });

  useEffect(() => {
    setSelectedClinicianId(isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId);
  }, [isEdgeAdminView, auth0ClinicianId, isEdgeReceptionist]);

  const handleChangeSelectedClinician = (val: string) => {
    setSelectedClinicianId(val);
  };

  const handleChangeInterventionForm = async (val: CreateInterventionRequestPayload) => {
    setInterventionField(val);

    const validate = validationInterventionNameForm(val);
    setErrorMessage(validate as CreateInterventionValidateField);
  };

  const checkIsDuplicateInterventionId = async (
    token: string,
    interventionIdValue?: string,
    excludeInterventionIdValue?: string
  ) => {
    const isSameIdValue = excludeInterventionIdValue === interventionIdValue;
    if (interventionIdValue && !isSameIdValue) {
      const pastValidateInterventionId = await validateInterventionId(token, accountId, interventionIdValue);
      return pastValidateInterventionId.statusCode !== 200;
    } else {
      return false;
    }
  };

  const validateInterventionField = async (interventionFieldVal: CreateInterventionRequestPayload) => {
    const isIdDuplicateExists = await checkIsDuplicateInterventionId(token, interventionFieldVal.interventionId);
    const interventionForm = validationInterventionNameForm(interventionFieldVal);
    setErrorMessage(interventionForm as CreateInterventionValidateField);

    const checkFieldHaveError = Object.values(interventionForm as CreateInterventionRequestPayload).some(
      (value) => value !== ''
    );

    if (checkFieldHaveError || isIdDuplicateExists) {
      setPageSubmitStatus('');
    }

    return !checkFieldHaveError && !isIdDuplicateExists;
  };

  const handleSubmit = async () => {
    setPageSubmitStatus('active');
    setCheckValidation(true);

    if (await validateInterventionField(interventionField)) {
      try {
        const interventionFormData: CreateInterventionRequestPayload = {
          interventionId: interventionField.interventionId,
          name: interventionField.name,
          leadClinicianAuth0Id: interventionField.leadClinicianAuth0Id,
          description: interventionField.description,
          interventionLibraryItemId: interventionField.interventionLibraryItemId
        };
        await postNewIntervention(token, accountId, interventionFormData);
        setPageSubmitStatus('finished');
        setTimeout(() => {
          scrollToView('modalHeader', true);
          notification.success({
            message: 'New intervention created',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setPageSubmitStatus('');
          setInterventionField(defaultFormValue);
          setCheckValidation(false);
          onReset();
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({
          message: 'New intervention fail to create',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={'Create New Intervention'}
      onModalClose={onCloseModal}
    >
      <div className={styles.container}>
        <InterventionForm
          selectedClinicianId={selectedClinicianId}
          onChangeClinician={(selectedId: string) => handleChangeSelectedClinician(selectedId)}
          onChangeInterventionField={handleChangeInterventionForm}
          checkValidation={checkValidation}
          interventionFormField={interventionField}
          errorMessage={errorMessage}
          accountId={accountId}
        />
      </div>
      <div className={styles.submitButtonContainer}>
        <Button status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={handleSubmit}>
          Create Now
        </Button>
      </div>
    </ModalV2>
  );
};

export default CreateInterventionModal;
