import moment from 'moment';
import Badge from 'components/Badge/Badge';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { FormListingInterface } from 'interfaces/Form/FormInterface';

import styles from './ADraftCard.module.scss';

interface AssessmentCardProps {
  data: FormListingInterface[];
}

const ADraftCard = ({ data }: AssessmentCardProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const onSelectAssessment = (id: string, type: string) => {
    navigate(`${FORMS.BASE}/${type}/${id}`);
  };

  const generateLabel = (type: FormListingInterface['formType']) => {
    switch (type) {
      case 'survey':
        return 'Survey';
      case 'microCheckIn':
        return 'Check-in';
      case 'consent':
        return 'Consent';
      case 'caseNotes':
        return 'Case Note';
      default:
        return 'Other';
    }
  };

  return (
    <div className={styles.cardContainer}>
      {data.map((obj, index: number) => {
        return (
          <div className={styles.cardWrapper} key={index} onClick={() => onSelectAssessment(obj._id, obj.formType)}>
            <div className={classnames(styles.container, styles[obj.formType])}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>{obj.name}</div>
                <div className={styles.badgeBox}>
                  <Badge label={generateLabel(obj.formType)} className={styles[`${obj.formType}Badge`]} />
                </div>
              </div>
              <div className={styles.dateWrapper}>
                <span className={styles.dateLabel}>Created: </span>
                <span className={styles.date}>{moment(obj.createdAt).format('MMMM DD, yyyy')}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ADraftCard;
