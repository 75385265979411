import { Modal, notification } from 'antd';
import { checkIsDuplicateCaseId } from 'components/AddPatientModalV2/validation/SubmitFormValidation';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postProspectClientsRecord } from 'utils/http/ClinicianProfileService/ClientRecords/prospectClientRecords';
import { scrollToView } from '../../utils/scrollToView';
import styles from './AddProspectClient.module.scss';
import { newProspectClientForm, prospectValidateField } from './AddProspectClientInterface';
import AddProspectClientForm from './components/AddProspectClientForm/AddProspectClientForm';
import { validationProspectClientForm } from './components/validation/ProspectClientFormValidation';

const initProspectClient: newProspectClientForm = {
  clientRecordId: '',
  clinicianAuth0Ids: [],
  caseId: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: ``,
  sourceTags: [],
  detail: '',
  note: {
    title: '',
    body: '',
    includeQuickNote: false
  }
};

interface AddProspectClientProps {
  onCancel: (isUpdated: boolean) => void;
  visible: boolean;
}

const AddProspectClient = ({ onCancel, visible }: AddProspectClientProps) => {
  const { token } = useGetAccessToken();
  const [prospectClientField, setProspectClientField] = useState(initProspectClient);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkValidation, setCheckValidation] = useState(false);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: ''
  } as prospectValidateField);
  let selectedList: string[] = [];
  const [t] = useTranslation();

  const handleChangeForm = async (val: newProspectClientForm) => {
    setProspectClientField(val);

    const validate = validationProspectClientForm(val);
    setErrorMessage(validate as prospectValidateField);
  };

  const validateField = async (prospectClientVal: newProspectClientForm) => {
    const isCaseIdDuplicateExist =
      prospectClientVal.caseId && (await checkIsDuplicateCaseId(token, prospectClientVal.caseId));
    const prospectForm = validationProspectClientForm(prospectClientVal);
    setErrorMessage(prospectForm as prospectValidateField);

    const checkFieldHaveError = Object.values(prospectForm as newProspectClientForm).some((value) => value !== '');
    const quickNoteChecking =
      !prospectClientVal.note.includeQuickNote ||
      (prospectClientVal.note.body.replace(/<(?!img)(.|\n)*?>/g, '').trim().length > 0 && prospectClientVal.note.title);

    if (checkFieldHaveError || !quickNoteChecking || isCaseIdDuplicateExist) {
      setSubmitStatus('');
    }
    return !checkFieldHaveError && quickNoteChecking && !isCaseIdDuplicateExist;
  };

  const handleSubmit = async () => {
    setSubmitStatus('active');
    setCheckValidation(true);
    if (await validateField(prospectClientField)) {
      if (isEdgeReceptionist || isEdgeAdminView) {
        if (!prospectClientField.clinicianAuth0Ids || !prospectClientField.clinicianAuth0Ids[0]) {
          prospectClientField.clinicianAuth0Ids = [];
        }
      } else {
        prospectClientField.clinicianAuth0Ids = [];
        prospectClientField.clinicianAuth0Ids.push(auth0ClinicianId);
      }
      try {
        const massagePayload = {
          clinicianAuth0Ids: prospectClientField.clinicianAuth0Ids,
          caseId: prospectClientField.caseId,
          firstName: prospectClientField.firstName,
          lastName: prospectClientField.lastName,
          email: prospectClientField.email || undefined,
          mobileNumber: prospectClientField.mobileNumber || undefined,
          referralSourceTags: prospectClientField.sourceTags,
          referralDetail: prospectClientField.detail,
          ...(prospectClientField.note.includeQuickNote && {
            note: {
              title: prospectClientField.note.title,
              body: prospectClientField.note.body
            }
          })
        };
        await postProspectClientsRecord(token, massagePayload);
        setSubmitStatus('finished');
        setTimeout(() => {
          scrollToView('modalHeader', true);
          notification.success({
            message: t('form.success.create_prospect_client'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitStatus('');
          setProspectClientField(initProspectClient);
          setCheckValidation(false);
          onCancel(true);
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: t('form.error.create_prospect_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const onSelectClinician = (selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      prospectClientField.clinicianAuth0Ids = [];
      prospectClientField.clinicianAuth0Ids.push(selectedClinician._id);
      selectedList = [];
      selectedList.push(selectedClinician._id);
    }
  };
  return (
    <Modal
      className={styles.modalContainer}
      title={
        <div className={styles.header}>
          {t('label.add_prospect_client')}
          {(isEdgeAdminView || isEdgeReceptionist) && (
            <>
              &nbsp;for
              <div className={styles.selector}>
                <ClinicianSelect
                  includePractice
                  filterRoles={[AccessRight.Admin, AccessRight.User]}
                  onSelect={(clinician) => onSelectClinician(clinician)}
                  allowEmpty={false}
                  excludedClinicianIds={selectedList}
                />
              </div>
            </>
          )}
        </div>
      }
      visible={visible}
      onCancel={() => onCancel(false)}
      footer={null}
    >
      <AddProspectClientForm
        id={'newProspectClient'}
        prospectClientField={prospectClientField}
        onChangeClientField={handleChangeForm}
        handleSubmit={handleSubmit}
        checkValidation={checkValidation}
        errorMessage={errorMessage}
        submitStatus={submitStatus}
      />
    </Modal>
  );
};

export default AddProspectClient;
