import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const postStage = (token: string, accountId: string, interventionId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/stages-configuration`,
    payload
  ) as Promise<BentResponse>;

export const patchStage = (
  token: string,
  accountId: string,
  interventionId: string,
  stageId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/stages-configuration/${stageId}`,
    payload
  ) as Promise<BentResponse>;

export const patchSequentialSequence = (
  token: string,
  accountId: string,
  interventionId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/stages-configuration/sequential`,
    payload
  ) as Promise<BentResponse>;

export const getInterventionStageConfiguration = (token: string, interventionId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/interventions/${interventionId}/stages-configuration`
  ) as Promise<BentResponse>;

export const deleteStageByStageId = (
  token: string,
  accountId: string,
  interventionId: string,
  stageId?: string,
  moveToStageId?: string
) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/interventions/${interventionId}/stages-configuration/${stageId}?moveToStageId=${
      moveToStageId ?? ''
    }`
  ) as Promise<BentResponse>;
