import styles from './CoupleInfoBox.module.scss';
import { clientProfilesEncrypted, clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import ClientContactDetails from '../../../ClientContactDetails/ClientContactDetails';
import { getShortOrdinalNumber } from 'utils/general';

interface CoupleInfoBoxProps {
  clientRecordData: clientRecordsEncryptedInterface;
  horizontal: boolean;
}

const CoupleInfoBox = ({ clientRecordData, horizontal }: CoupleInfoBoxProps) => {
  const clientProfiles = clientRecordData.clientProfiles as clientProfilesEncrypted[];

  return (
    <div className={horizontal ? styles.contactDetailsWrapper : styles.coupleInfoContainer}>
      {clientProfiles.map((item, index) => (
        <ClientContactDetails
          key={`detail_${item._id}`}
          clientProfileData={item}
          title={`${getShortOrdinalNumber(index + 1)} Contact`}
        />
      ))}
    </div>
  );
};

export default CoupleInfoBox;
