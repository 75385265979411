import { useEffect, useState } from 'react';
import styles from './EditInterventionModal.module.scss';
import { notification } from 'antd';
import EditInterventionForm, {
  EditInterventionValidateField
} from './formType/EditInterventionForm/EditInterventionForm';
import { useGetAccessToken } from 'utils/hooks/token';
import { EditInterventionFormInterface } from './EditInterventionModalInterface';
import {
  AttachedClientRecordStatus,
  Interventions,
  InterventionStatus
} from 'MHH/pages/Interventions/Interfaces/Interventions';
import Button from 'components/v2/Button/Button';
import InterventionStatusButtons from './components/interventionStatusButtons';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { putIntervention } from 'utils/http/ClinicianProfileService/Interventions/Interventions';
import ModalV2 from 'components/ModalV2/ModalV2';
import { validateInterventionId } from '../../../../../../../InterventionsList/components/CreateInterventionModal/components/InterventionForm/validation/InterventionFormValidation';

interface EditInterventionModalProps {
  interventionDetails: Interventions;
  onCancel: (isUpdated: boolean) => void;
  visible: boolean;
  onRefreshData?: () => void;
}

const EditInterventionModal = ({
  interventionDetails,
  visible,
  onCancel,
  onRefreshData
}: EditInterventionModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [interventionFormData, setInterventionFormData] = useState<EditInterventionFormInterface>(interventionDetails);
  const { accountId } = useGetAccountId();
  const [errorMessage, setErrorMessage] = useState<EditInterventionValidateField>({
    name: '',
    interventionId: '',
    interventionLibraryItemId: ''
  });

  useEffect(() => {
    setInterventionFormData(interventionDetails);
  }, [interventionDetails]);

  const handleChangeInterventionDetail = (interventionData: EditInterventionFormInterface) => {
    if (!interventionData.name) {
      setErrorMessage({ ...errorMessage, name: "Please enter Intervention's name" });
    } else {
      setErrorMessage({ ...errorMessage, name: '' });
    }
    if (!interventionData.interventionLibraryItemId) {
      setErrorMessage({ ...errorMessage, interventionLibraryItemId: 'Please select Intervention Library' });
    } else {
      setErrorMessage({ ...errorMessage, interventionLibraryItemId: '' });
    }
    setInterventionFormData(interventionData);
  };

  const checkIsDuplicateId = async (
    token: string,
    interventionIdValue?: string,
    excludeInterventionIdValue?: string
  ) => {
    const isSameInterventionIdValue = excludeInterventionIdValue === interventionIdValue;
    if (interventionIdValue && !isSameInterventionIdValue) {
      const pastValidateId = await validateInterventionId(token, accountId, interventionIdValue);
      return pastValidateId.statusCode !== 200;
    } else {
      return false;
    }
  };

  const validateInterventionField = async (interventionFieldVal: EditInterventionFormInterface) => {
    const isInterventionIdDuplicateExists = await checkIsDuplicateId(
      token,
      interventionFieldVal.interventionId,
      interventionDetails.interventionId
    );
    if (!interventionFieldVal.name) {
      setErrorMessage({ ...errorMessage, name: "Please enter intervention's name" });
      return;
    }
    if (!interventionFieldVal.interventionLibraryItemId) {
      setErrorMessage({ ...errorMessage, interventionLibraryItemId: 'Please select Intervention Library' });
      return;
    }
    const checkFieldHaveError = Object.values(errorMessage).some((value) => value !== '');
    if (checkFieldHaveError || isInterventionIdDuplicateExists) {
      setSubmitStatus('');
    }

    return !checkFieldHaveError && !isInterventionIdDuplicateExists;
  };
  const handleSubmit = async () => {
    if (await validateInterventionField(interventionFormData)) {
      setSubmitStatus('active');
      try {
        const payload = {
          status: interventionFormData.status,
          interventionId: interventionFormData.interventionId,
          name: interventionFormData.name,
          leadClinicianAuth0Id: interventionFormData.leadClinicianAuth0Id,
          description: interventionFormData.description,
          clientStatus: interventionFormData.clientStatus,
          interventionLibraryItemId: interventionFormData.interventionLibraryItemId
        };
        await putIntervention(token, accountId, interventionDetails._id, payload);
        setSubmitStatus('finished');
        setTimeout(() => {
          setSubmitStatus('');
          onRefreshData && onRefreshData();
          onCancel(true);
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: 'New intervention fail to create',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const onStatusInterventionChange = (status: boolean, clientStatus?: AttachedClientRecordStatus) => {
    setInterventionFormData({
      ...interventionFormData,
      status: status ? InterventionStatus.Active : InterventionStatus.Closed,
      ...(clientStatus && { clientStatus })
    });
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      title={'Edit Intervention Information'}
      isModalOpen={visible}
      onModalClose={() => onCancel(false)}
    >
      <div>
        <div>
          <div className={styles.subTitle}>INTERVENTION STATUS</div>
          <InterventionStatusButtons interventionData={interventionDetails} onChange={onStatusInterventionChange} />
        </div>
        {interventionDetails.status !== InterventionStatus.Closed && (
          <div className={styles.formContainer}>
            <EditInterventionForm
              selectedClinicianId={interventionDetails.leadClinicianAuth0Id}
              practitionerList={interventionDetails.clinicianAuth0Ids}
              onChangeInterventionField={handleChangeInterventionDetail}
              interventionFormField={interventionFormData}
              accountId={accountId}
              errorMessage={errorMessage}
              excludeInterventionIdValue={interventionDetails.interventionId}
            />
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            status={submitStatus}
            disabled={submitStatus !== '' && interventionDetails.status === InterventionStatus.Closed}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </ModalV2>
  );
};

export default EditInterventionModal;
