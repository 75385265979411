import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';

import styles from './MaterialPhoneInput.module.scss';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { useField } from 'formik';
import { MaterialInputProps } from '../MaterialInput/MaterialInput';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface FormikMaterialPhoneInputProps extends Omit<MaterialInputProps, 'hasError'> {
  inputClass?: string;
  name: string;
  id?: string;
  label: string;
  onChange: any;
  isLoading?: boolean;
}

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const PREFERRED_COUNTRY = GAE_REGION === 'au' ? 'au' : GAE_REGION === 'gb' ? 'gb' : undefined;

const FormikMaterialPhoneInput = ({
  id,
  label,
  onChange,
  isLoading,
  name,
  inputClass
}: FormikMaterialPhoneInputProps) => {
  const [field, { error, touched }] = useField(name);

  return (
    <>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.phoneInputWrapper}>
        <PhoneInput
          {...field}
          inputClass={classnames(inputClass, styles.mobileNumberInput, error && styles.redBorderBottom)}
          buttonClass={classnames(styles.countrySelect, error && styles.redBorderBottom)}
          dropdownClass={classnames(styles.countryDropdown)}
          onChange={(value) => {
            onChange(`+${value}`);
          }}
          inputProps={{
            id
          }}
          {...(PREFERRED_COUNTRY && {
            country: PREFERRED_COUNTRY,
            preferredCountries: [PREFERRED_COUNTRY]
          })}
        />
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingBox}>
              <LoadingCircle />
            </div>
          </div>
        )}
      </div>
      <ErrorMessage error={error} visible={touched} />
    </>
  );
};

export default FormikMaterialPhoneInput;
