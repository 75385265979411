import { InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClass?: string;
  label?: string;
  labelClass?: string;
  hasError?: boolean;
  noSpacing?: boolean;
}

const Input = ({
  className,
  inputClass,
  label,
  labelClass,
  placeholder,
  hasError,
  noSpacing,
  ...props
}: InputProps) => {
  const containerClasses = classnames(styles.container, className);
  const inputClasses = classnames(styles.input, hasError && styles.error, noSpacing && styles.noSpacing, inputClass);
  const labelClasses = classnames(styles.label, labelClass);

  return (
    <div className={containerClasses}>
      <input className={inputClasses} placeholder={placeholder} {...props} />
      {label && <div className={labelClasses}>{label}</div>}
    </div>
  );
};

Input.defaultProps = {
  className: '',
  label: '',
  placeholder: '',
  type: '',
  value: undefined,
  onChange: () => {},
  onKeyDown: () => {},
  onBlur: () => {}
};

export default Input;
