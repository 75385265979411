import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button from 'components/v2/Button/Button';
import {
  CALENDAR_INTEGRATIONS,
  CLAIM_INTEGRATIONS,
  FINANCE_INTEGRATIONS
} from 'pages/ControlPanel/ControlPanel/constants';
import { useFetchSystemIntegrations } from 'pages/ControlPanel/ControlPanel/hooks/getSystemIntegrations';
import DisconnectIntegrationModal from 'pages/ControlPanel/IntegrationDetails/components/DisconnectIntegrationModal/DisconnectIntegrationModal';
import { IntegrationStatus, IntegrationType } from 'pages/ControlPanel/Interfaces/Integration';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchPracticeInfo } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';

import ConnectedIntegration from './components/ConnectedIntegration/ConnectedIntegration';
import DisconnectWarningModal from './components/DisconnectWarningModal/DisconnectWarningModal';
import IntegrationItem from './components/IntegrationItem/IntegrationItem';
import styles from './SystemConnection.module.scss';

const SystemConnection = () => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const navigate = useNavigate();
  const { CONTROL_PANEL, CALENDAR } = useRoutesGenerator();
  const { practiceData } = useFetchPracticeInfo();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const isAdmin = isEdgeAdminView || isNormalUserView;

  const { integrations, isIntegrationsLoading, isCodatConnected, refetchSystemIntegrations } =
    useFetchSystemIntegrations(token, isAdmin);
  const [showDisconnectWarningModal, setShowDisconnectWarningModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [newIntegrationLabel, setNewIntegrationLabel] = useState('');

  const financeIntegrations = !practiceData.practice.name
    ? FINANCE_INTEGRATIONS.filter(({ type }) => type !== IntegrationType.Account)
    : FINANCE_INTEGRATIONS;

  const selectIntegration = (type: string, id: string) => {
    if (type === 'calendar') {
      navigate(CALENDAR.SETTINGS_CLD_LINKING);
    } else {
      navigate(`${CONTROL_PANEL.SYSTEM_CONNECTIONS}/${id}`);
    }
  };

  const onClickRequestNewIntegration = () => {
    window.location.href = 'mailto:support@tacklit.com';
  };

  return (
    <div className={styles.container}>
      {isIntegrationsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          {integrations.length > 0 && (
            <>
              <div className={styles.header}>
                <div>
                  <div className={styles.headerTitle}>Connect to 3rd party systems</div>
                  <div className={styles.headerDesc}>
                    Integrate your Tacklit experience and data with other systems to streamline your operation
                  </div>
                </div>
              </div>
              <ConnectedIntegration connectedIntegrations={integrations} selectIntegration={selectIntegration} />
            </>
          )}
          <div className={styles.header}>
            <div>
              <div className={styles.headerTitle}>Integration Marketplace</div>
              <div className={styles.headerDesc}>
                Here are the current services we partner with and you can connect to
              </div>
            </div>
            <Button
              className={styles.button}
              variant={integrations.length > 0 ? 'secondary' : undefined}
              onClick={onClickRequestNewIntegration}
            >
              Request New Integration
            </Button>
          </div>
          <div className={styles.integrationContainer}>
            <>
              <div className={styles.integrationTitle}>Calendar Integrations</div>
              <div className={styles.integrationList}>
                {CALENDAR_INTEGRATIONS.map((item, index) => (
                  <IntegrationItem
                    key={index}
                    item={item}
                    integration={integrations.find(({ provider }) => provider === item.provider)}
                    selectIntegration={selectIntegration}
                    setShowDisconnectWarning={setShowDisconnectWarningModal}
                    setNewIntegrationLabel={setNewIntegrationLabel}
                  />
                ))}
              </div>
            </>
            {isAdmin && (
              <>
                <div className={styles.integrationTitle}>Finance Integrations</div>
                <div className={styles.integrationList}>
                  {financeIntegrations.map((item, index) => (
                    <IntegrationItem
                      key={index}
                      item={item}
                      integration={integrations.find(({ provider }) => provider === item.provider)}
                      isCodatConnected={isCodatConnected}
                      selectIntegration={selectIntegration}
                      setShowDisconnectWarning={setShowDisconnectWarningModal}
                      setNewIntegrationLabel={setNewIntegrationLabel}
                    />
                  ))}
                </div>
              </>
            )}
            {isAdmin && medicareRebateFeatureToggle && (
              <>
                <div className={styles.integrationTitle}>Claim Integrations</div>
                <div className={styles.integrationList}>
                  {CLAIM_INTEGRATIONS.map((item, index) => (
                    <IntegrationItem
                      key={index}
                      item={item}
                      integration={integrations.find(({ provider }) => provider === item.provider)}
                      selectIntegration={selectIntegration}
                      setShowDisconnectWarning={setShowDisconnectWarningModal}
                      setNewIntegrationLabel={setNewIntegrationLabel}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
          {isCodatConnected && (
            <>
              <DisconnectWarningModal
                visible={showDisconnectWarningModal}
                currentIntegration={
                  integrations.find(
                    ({ status, type }) => status === IntegrationStatus.Connected && type === IntegrationType.Account
                  )!
                }
                newIntegrationLabel={newIntegrationLabel}
                setShowDisconnectWarningModal={setShowDisconnectWarningModal}
                setShowDisconnectModal={setShowDisconnectModal}
              />
              <DisconnectIntegrationModal
                integration={
                  integrations.find(
                    ({ status, type }) => status === IntegrationStatus.Connected && type === IntegrationType.Account
                  )!
                }
                visible={showDisconnectModal}
                setShowDisconnectModal={setShowDisconnectModal}
                refetchSystemIntegration={refetchSystemIntegrations}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SystemConnection;
