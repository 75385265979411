import Button from 'components/v2/Button/Button';
import { HelmControlInterface } from 'interfaces/Profile/Profile';
import { useState } from 'react';
import { HelmControlUploadedPhotos } from '../../HelmControl';
import ImagesInput from '../ImagesInput/ImagesInput';
import styles from './HelmControlUploadPhoto.module.scss';

interface HelmControlUploadPhotoProps {
  uploadedPhotosObjectURLs: {
    headerPhoto?: string;
    additionalPhotos?: string[];
    replacedAdditionalPhotos?: { [url: string]: string };
    bioPhoto?: string;
    appointmentPhoto?: string;
  };
  uploadedPhotos: HelmControlUploadedPhotos;
  setUploadedPhotos: (value: HelmControlUploadedPhotos) => void;
  setValues: (values: HelmControlInterface) => void;
  photoField: 'headerPhoto' | 'bioPhoto' | 'appointmentPhoto';
  values: HelmControlInterface;
  title: string;
  defaultSize?: [number, number];
}

const HelmControlUploadPhoto = ({
  uploadedPhotosObjectURLs,
  uploadedPhotos,
  photoField,
  values,
  setUploadedPhotos,
  setValues,
  title,
  defaultSize
}: HelmControlUploadPhotoProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  return (
    <>
      {uploadedPhotosObjectURLs[photoField] || values[photoField] ? (
        <div className={styles.photoList}>
          <div className={styles.photoListItem} onClick={() => setShowUploadModal(true)}>
            <img src={uploadedPhotosObjectURLs[photoField] || values[photoField]} alt={photoField} />
          </div>
        </div>
      ) : (
        <Button className={styles.uploadButton} onClick={() => setShowUploadModal(true)}>
          <i className="material-icons-outlined">image</i>
          Upload Photo
        </Button>
      )}
      {showUploadModal && (
        <ImagesInput
          title={title}
          maxImages={1}
          visible
          onUpload={(files: File[]) => setUploadedPhotos({ ...uploadedPhotos, [photoField]: files[0] })}
          onClose={() => setShowUploadModal(false)}
          accept="image/png, image/jpeg"
          defaultSize={defaultSize}
          initFiles={uploadedPhotos[photoField] ? [uploadedPhotos[photoField]!] : undefined}
          initUrls={!uploadedPhotos[photoField] && values[photoField] ? [values[photoField]!] : undefined}
          onRemoveFile={() => {
            if (uploadedPhotos[photoField]) {
              setUploadedPhotos({ ...uploadedPhotos, [photoField]: undefined });
            } else {
              if (values[photoField]) {
                setValues({ ...values, [photoField]: '' });
              }
            }
            setShowUploadModal(false);
          }}
        />
      )}
    </>
  );
};

export default HelmControlUploadPhoto;
