import { Link } from 'react-router-dom';
import styles from './ClosedInterventionListItem.module.scss';
import columnStyles from '../ClosedInterventionColumn.module.scss';
import { Interventions } from 'MHH/pages/Interventions/Interfaces/Interventions';
import DateDescBox from 'pages/Patient/components/ClientListContent/components/Components/DateDescBox/DateDescBox';
import moment from 'moment';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { getDayDiff } from 'utils/helpers/DateDiff';

interface ClosedInterventionListItemProps {
  interventionListingItem: Interventions;
  searchValue: string;
}

const ClosedInterventionListItem = ({ interventionListingItem, searchValue }: ClosedInterventionListItemProps) => {
  const { INTERVENTIONS } = useRoutesGenerator();

  return (
    <Link
      to={`${INTERVENTIONS.BASE}/${interventionListingItem._id}/details${searchValue ? `?q=${searchValue}` : ''}`}
      className={styles.container}
    >
      <div className={columnStyles.InterventionNameSection}>
        <div className={styles.interventionName}>{interventionListingItem.name}</div>
      </div>
      <div className={columnStyles.GSinceSection}>
        <div className={styles.interventionSince}>
          <DateDescBox
            title={moment(interventionListingItem.createdAt).format('D MMM YYYY')}
            desc={getDayDiff(moment(), moment(interventionListingItem.createdAt))}
          />
        </div>
      </div>
      <div className={columnStyles.GSinceSection}>
        <div className={styles.interventionClosed}>
          <DateDescBox title={moment(interventionListingItem.updatedAt).format('D MMM YYYY')} />
        </div>
      </div>
      <div className={columnStyles.InterventionLeadSection}>
        <div className={styles.interventionLead}>{interventionListingItem.leadClinicianProfile.name}</div>
      </div>
      <div className={columnStyles.buttonSection}>
        <div>
          <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
        </div>
      </div>
    </Link>
  );
};
export default ClosedInterventionListItem;
