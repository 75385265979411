import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { TFunction } from 'react-i18next';

export interface PractitionersListing {
  practitionerList: PractitionersDetailsInterface[];
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
    totalPage: number;
  };
}

export interface PractitionersDetailsInterface {
  _id: string;
  avatar: string;
  name: string;
  mobileNumber: string;
  email: string;
  status: PractitionersStatus['status'];
  accessRight: string;
  caseLoad: {
    availableSlot: number;
    activeClient: number;
  };
  workingSchedule: {
    monday: AvailableTime;
    tuesday: AvailableTime;
    wednesday: AvailableTime;
    thursday: AvailableTime;
    friday: AvailableTime;
    saturday: AvailableTime;
    sunday: AvailableTime;
  };
  medicare?: {
    role: ClinicianMedicareRole;
  };
}

export interface PractitionersDetailsWithSelected extends PractitionersDetailsInterface {
  isSelected?: boolean;
}

export interface AvailableTime {
  isActive: boolean;
  timeSlots?: TimeSlot[];
}

interface TimeSlot {
  startTime: string;
  endTime: string;
}

export interface PractitionersStatus {
  status?: 'active' | 'inactive';
}

export interface PractitionersListingCount {
  active: number;
  inactive: number;
}

export interface filterParameter {
  status?: PractitionersStatus['status'];
  perPage: number;
  page: number | string;
  q: string;
}

export const getUserRoles: (t: TFunction) => { [key: string]: string } = (t) => ({
  admin: t('options.roles.super_user'),
  user: t('options.roles.practitioner'),
  receptionist: t('options.roles.administrator'),
  mentor: t('options.roles.mentor')
});
